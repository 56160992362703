import React, { Component, Fragment } from "react"

import Modal from 'react-bootstrap/Modal'
import ComparisonBuilder from "./ComparisonBuilder"

export default class ComparisonModal extends Component {
    constructor(props){
        super(props)
        this.state = {

        }
        this.translate = this.props.translate
    }

    render = () => 
    <Modal show={this.props.isComparisonModalVisible} backdrop="static" onHide={this.props.handleCloseComparisonModal} dialogAs={'div'} dialogClassName="modal-dialog modal-right w-75 white b-t">
        <Modal.Body>
            <div className="d-flex flex-column h-100 bg-blur_w2">
                <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                    <h5 className="h5 m-0 text-center width100">
                    <i className="fa fa-line-chart"></i> {this.translate("DASHBOARD_PERFORMANCE_COMPARISON_COMPARISON_MODAL")}</h5>
                    <a href="/" onClick={e=>this.props.handleCloseComparisonModal(e)} className="ml-auto">
                        <span className="text-lg l-h-1x">×</span>
                    </a>
                </div>
                <div className="row position10 padding-20px">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/" onClick={e=>this.props.handleCloseComparisonModal(e)}>{this.props.dashboardTitle}</a></li>
                                        <li className="breadcrumb-item">{this.translate("DASHBOARD_PERFORMANCE_COMPARISON_COMPARISON_MODAL")}</li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-12">
                                {this.props.comparisonJsonStructure && this.props.comparisonJsonStructure.length > 1 &&
                                    <ComparisonBuilder 
                                        comparisonKey={this.props.comparisonJsonStructure[0].mainMetricKey} 
                                        comparisonJsonStructure={this.props.comparisonJsonStructure}
                                        translate={this.translate}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </Modal.Body>
    </Modal>
}