import React, {Component, Fragment} from 'react';
import MenuMain from './MenuMain';
import HeaderMain from './HeaderMain';
import FooterMain from './FooterMain';
import FeedMain from './FeedMain';
import MountStructureApp from './MountStructureApp';
import MountStructureBackground from './MountStructureBackground';

export class StandardStructure extends Component{
    
    constructor(props){
        super(props);
        document.title = this.props.title

        console.log(this.props.cards)

    }

    getClassElement = () => {
        if(this.props.feed){
            return "col-lg-10 col-md-10 col-sm-12 col-xs-12 no-gutters"
        }else{
            return "col-lg-12 col-md-12 col-sm-12 col-xs-12 no-gutters"
        }
    }

    handleChange = (period, periodName, cardCustom, period_initial, period_end) => {
        this.props.onChange(period, periodName, cardCustom, period_initial, period_end);
    }

    render(){
        return(
            <Fragment>
                <MenuMain />
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    <HeaderMain title={this.props.title} onChange={this.handleChange}/>
                    <div className={"content-main row row-no-margin " + this.props.classCustom} id="content-main">
                        {
                            (this.props.lastUpdatedPhrase)?(
                                <div className="col-12 p-1 text-center bg-blue_w">
                                    <b>
                                        {this.props.lastUpdatedPhrase}
                                    </b>
                                </div>
                            ):(false)
                        }
                        {
                            (this.props.background)?(
                                <MountStructureBackground background={this.props.background}/>
                            ):(
                                false
                            )
                        }
                        <div 
                            className={this.getClassElement()}
                            id={this.props.id}
                        >
                            <MountStructureApp cards={this.props.cards} periodName={this.props.periodName} handleCardActionClick={this.props.handleCardActionClick} cardCustom={this.props.cardCustom}/>
                            <FooterMain />
                        </div>
                        {
                            (this.props.feed)?(
                                <FeedMain />
                            ):(false)
                        }
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default StandardStructure;