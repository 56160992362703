import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'

import $ from "jquery"

import MenuMain from '../../../Structure/MenuMain';
import HeaderMain from '../../../Structure/HeaderMain';
import FooterMain from '../../../Structure/FooterMain';

import CommercialPreAttendance from './Components/CommercialPreAttendance';
import CommercialShopper from './Components/CommercialShopper';
import CommercialDeliveryman from './Components/CommercialDeliveryman';


class CommercialEmksa extends Component{

    constructor(props){
        super(props)

        this.translate = this.props.translate
    }
    
    render() {
        return (
            <React.Fragment>
                <MenuMain />
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    <HeaderMain title={this.translate("COMMERCIAL_EMKSA_TITLE")} onChange={this.handleChange}/>
                    <div className="content-main" id="content-main">
                        <div className="padding">
                            <div className="row pt20">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">

                                    <ul className="nav nav-pills nav-sm mb10" id="logs_tab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-target="#commercial_emksa_pre_attendance" data-toggle="tab" href="#commercial_emksa_pre_attendance" role="tab" aria-controls="pre_attendance" aria-selected="true">{this.translate("COMMERCIAL_EMKSA_PRE_ATTENDANCE")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-target="#commercial_emksa_shopper" data-toggle="tab" href="#commercial_emksa_shopper" role="tab" aria-controls="shopper" aria-selected="true">{this.translate("COMMERCIAL_EMKSA_SHOPPER")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-target="#commercial_emksa_deliveryman" data-toggle="tab" href="#commercial_emksa_deliveryman" role="tab" aria-controls="deliveryman" aria-selected="true">{this.translate("COMMERCIAL_EMKSA_DELIVERYMAN")}</a>
                                        </li>
                                    </ul>

                                    <div className="tab-content" id="logs_tab_content">
                                        <div className="tab-pane pb40 fade show active p-3 white box-shadow" id="commercial_emksa_pre_attendance" role="tabpanel" aria-labelledby="">
                                            <CommercialPreAttendance translate={this.props.translate}/>
                                        </div>
                                        <div className="tab-pane pb40 fade show p-3 white box-shadow" id="commercial_emksa_shopper" role="tabpanel" aria-labelledby="">
                                            <CommercialShopper translate={this.props.translate}/>
                                        </div>
                                        <div className="tab-pane pb40 fade show p-3 white box-shadow" id="commercial_emksa_deliveryman" role="tabpanel" aria-labelledby="">
                                            <CommercialDeliveryman translate={this.props.translate}/>
                                        </div>
                                    </div>
        
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterMain />
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(CommercialEmksa);