import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'

import $ from 'jquery';
import 'jquery-mask-plugin';

import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4'

import {ajaxUrl, ajaxToken} from '../../../Services/Ajax';
import api from '../../../Services/Api';

import Modal from 'react-bootstrap/Modal';

import autocomplete from '../../../Functions/Leads/AutoComplete'

class BusinessVisit extends Component {

    constructor(props){
        super(props)
        this.state = {
            modalResponseShow: false,
            msgResponseShow: false,
            tableLoadVisit: null,
            formVisitRegister: {
                idBusiness: this.props.idBusiness,
                name: '',
                email: '',
                phone: '',
                date: '',
                status: '',
            }
        }
        this.handleCloseModalResponse =  this.handleCloseModalResponse.bind(this)
        this.addNewFieldVisit = this.addNewFieldVisit.bind(this)
        this.reloadDatatable = this.reloadDatatable.bind(this)
        this.handleKeyUp = this.handleKeyUp.bind(this)
        this.translate = this.props.translate
    }

    componentDidMount = async () => {
        let tableLoadVisit = this.state.tableLoadVisit
        const props = this.props
        tableLoadVisit = $('#list_visits').DataTable({
            "searching": true,
            "paging": true,
            "info": true,
            "responsive": true,
            "language": {
                "url": this.translate("DATATABLE_LANGUAGE_URL")
            },
            "ajax": {
                "url": ajaxUrl('/business/' + props.idBusiness + '/visit/table'),
                "type": 'GET',
                "beforeSend": function (request) {
                    request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                }
            }
        });
        console.log(tableLoadVisit)
        var SPMaskBehavior = function (val){
            return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
        },
        spOptions = {
            onComplete: function(val){
                var phone = $('#phone-visit').val();
                this.setState({formVisitRegister: {...this.state.formVisitRegister, phone: phone}})
            }.bind(this),
            onKeyPress: function(val, e, field, options) {
                field.mask(SPMaskBehavior.apply({}, arguments), options);
            }
        };

        $('#phone-visit').mask(SPMaskBehavior, spOptions);
    }

    handleCloseModalResponse(e = null){
        if (e) e.preventDefault()
        this.setState({modalResponseShow: false})
    }

    addNewFieldVisit = async (e) => {
        e.preventDefault();
        this.setState({ msgResponseShow: this.translate("BUSINESS_VISIT_WAIT_STATUS"), modalResponseShow: true });
        const data = this.state.formVisitRegister;
        console.log(data);
        try{
            const response = await api.post("/business/" + this.props.idBusiness + "/visit/register", {data});
            if(response.data.success === true){
                this.setState({msgResponseShow: this.translate("BUSINESS_VISIT_SUCCESS_STATUS")});
                document.getElementById('register-visit-new').reset();
                this.reloadDatatable()
            }else{
                this.setState({msgResponseShow: response.data.msg});
            }
        }catch(err){
            this.setState({msgResponseShow: err});
        }
    }

    reloadDatatable(){
        $('#list_visits').DataTable().ajax.reload();
    }

    handleKeyUp = (e) =>{
        if(e.target.value.length > 5){
            autocomplete(e)
        }
    }

    clickAutoComplete = (e) => {
        if(e.target.classList.contains('autocomplete-dev-click')){
            const data = e.target.dataset
            const {formVisitRegister} = this.state;
            formVisitRegister['name'] = data.name;
            formVisitRegister['email'] = data.email;
            formVisitRegister['phone'] = data.phone;
            this.setState({
                formVisitRegister,
            });
            $('.autocomplete-dev').remove();
        }
    }

    render() {
        return (
            <React.Fragment>
                <div id="accordion-visit-control">
                    <div className="card mb-3">
                        <div className="card-header btn-cursor-pointer dark" id="headingOnes">
                            <h5 className="mb-0" onClick={e=>this.props.toggleCollapse("#collapseOnes")} aria-expanded="false" aria-controls="collapseOnes">
                                {this.translate("BUSINESS_VISIT_REGISTER_VISIT")}
                                <i className="fa fa-caret-down pull-right"></i>
                            </h5>
                        </div>

                        <div id="collapseOnes" className="collapse dark" aria-labelledby="headingOnes" data-parent="#accordion-visit-control">
                            <div className="card-body">
                                <form onSubmit={this.addNewFieldVisit} id="register-visit-new">
                                    <div className="row p-3 white box-shadow pb40">
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group" onClick={this.clickAutoComplete}>
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                    {this.translate("BUSINESS_VISIT_REGISTER_NAME")}:
                                                </label>
                                                <input type="text" name="name" id="name-visit" autoComplete="off" onKeyUp={this.handleKeyUp} onPaste={this.handleKeyUp} onChange={e => this.setState({ formVisitRegister: {...this.state.formVisitRegister, name:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("BUSINESS_VISIT_REGISTER_NAME")} value={this.state.formVisitRegister.name} required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group" onClick={this.clickAutoComplete}>
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                {this.translate("BUSINESS_VISIT_REGISTER_EMAIL")}:
                                                </label>
                                                <input type="email" name="email" id="email-visit" autoComplete="off" onKeyUp={this.handleKeyUp} onPaste={this.handleKeyUp} onChange={e => this.setState({ formVisitRegister: {...this.state.formVisitRegister, email:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("BUSINESS_VISIT_REGISTER_EMAIL")} value={this.state.formVisitRegister.email} required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group" onClick={this.clickAutoComplete}>
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                {this.translate("BUSINESS_VISIT_REGISTER_PHONE")}:
                                                </label>
                                                <input type="text" name="phone" id="phone-visit" autoComplete="off" onKeyUp={this.handleKeyUp} onPaste={this.handleKeyUp} onChange={e => this.setState({ formVisitRegister: {...this.state.formVisitRegister, phone:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("BUSINESS_VISIT_REGISTER_PHONE")} value={this.state.formVisitRegister.phone} required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group">
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                {this.translate("BUSINESS_VISIT_REGISTER_LABEL_DATE")}:
                                                </label>
                                                <input type="datetime-local" onChange={e => this.setState({ formVisitRegister: {...this.state.formVisitRegister, date:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("BUSINESS_VISIT_REGISTER_PLACEHOLDER_DATE")} required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group">
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                {this.translate("BUSINESS_VISIT_REGISTER_LABEL_STATUS")}:
                                                </label>
                                                <select onChange={e => this.setState({ formVisitRegister: {...this.state.formVisitRegister, status:  e.target.value}})} className="form-control box-shadow cool-select" required>
                                                    <option value="">{this.translate("BUSINESS_VISIT_REGISTER_OPTION_DEFAULT")}</option>
                                                    <option value="schedule">{this.translate("BUSINESS_VISIT_REGISTER_OPTION_TO_SCHEDULE")}</option>
                                                    <option value="performed">{this.translate("BUSINESS_VISIT_REGISTER_OPTION_ACCOMPLISHED")}</option>
                                                    <option value="not_performed">{this.translate("BUSINESS_VISIT_REGISTER_OPTION_NOT_ACCOMPLISHED")}</option>
                                                    <option value="canceled">{this.translate("BUSINESS_VISIT_REGISTER_OPTION_CANCELED")}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="box-divider m0 mt20 width100"></div>
                                        <div className="col-sm-12 col-md-12 mt20">
                                            <button type="submit" className="btn btn-info px-5 btn-rounded btn-shadow pull-right">
                                                <i className="fa fa-save"></i> {this.translate("BUSINESS_VISIT_REGISTER_NEW_VISIT")}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                    <div className="card">
                        <div className="card-header btn-cursor-pointer dark" id="headingTwo" onClick={e=>this.props.toggleCollapse("#collapseTwo")} aria-expanded="true" aria-controls="collapseTwo">
                            <h5 className="mb-0">
                                {this.translate("BUSINESS_VISIT_LIST_LIST_OF_VISITS")}
                                <i className="fa fa-caret-down pull-right"></i>
                            </h5>
                        </div>

                        <div id="collapseTwo" className="collapse dark show" aria-labelledby="headingTwo" data-parent="#accordion-visit-control">
                            <div className="card-body">
                                <div className="bootstrap-table">
                                    <div className="fixed-table-container">
                                        <div className="fixed-table-body">
                                            <table id="list_visits" className="table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                                                <thead>
                                                    <tr className="text-left">
                                                        <th>{this.translate("BUSINESS_VISIT_LIST_NAME")}</th>
                                                        <th>{this.translate("BUSINESS_VISIT_LIST_VISIT_DATE")}</th>
                                                        <th>{this.translate("BUSINESS_VISIT_LIST_REGISTER_DATE")}</th>
                                                        <th>{this.translate("TABLE_STATUS_ROW")}</th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <Modal show={this.state.modalResponseShow} onHide={this.handleCloseModalResponse} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-bell"></i> {this.translate("MODAL_STATUS_STATUS")}</h5>
                                <a href="/" onClick={e=>this.handleCloseModalResponse(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                <span className="col-12 text-center py-2 lead">
                                    {this.state.msgResponseShow}
                                </span>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(BusinessVisit);