import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import {withRouter} from 'react-router-dom';
import ListBusiness from '../Structure/ListBusiness';
import ListCompanies from '../Structure/ListCompanies';

import $ from "jquery"
import 'jquery-mask-plugin';
import "bootstrap"

import translate from '../Helpers/Translator';


export class HeaderMain extends Component{

    constructor(props){
        super(props);
        this.handleShow =  this.handleShow.bind(this)
        this.handleClose =  this.handleClose.bind(this)
        this.state = {
            period: 'current_month',
            periodName: 'Mês Atual',
            show: false,
            period_initial: '',
            period_end: ''
        }
        this.translate = translate
    }

    handleChange = e =>{
        e.preventDefault();
        const period = e.currentTarget.dataset.period;
        const periodName = e.currentTarget.dataset.period_name;
        this.setState({period: period, periodName: periodName});
        this.props.onChange(period, periodName, false, false, false)
    }

    handleChangeCustom = e => {
        e.preventDefault();
        const period = e.currentTarget.dataset.period;
        const periodName = e.currentTarget.dataset.period_name;
        this.setState({period: period, periodName: periodName});
        this.handleShow();
    }

    handleClose(e = null){
        if (e) e.preventDefault()
        this.setState({show: false})
    }

    handleShow(){
        this.setState({show: true})
    }

    handleSubmit = async e => {
        e.preventDefault();
        const { period, periodName, period_initial, period_end } = this.state;
        this.props.onChange(period, periodName, false, period_initial, period_end)
        this.handleClose();

        /*
        await api.post('/build/dashboard/main/custom_period', {period_initial: period_initial, period_end: period_end, card: this.props.type})
        .then(async response => {
            console.log(response.data);
            this.props.customChange('custom_date', response.data.periodName, response.data, false)
            this.handleClose();
        })
        */
    }

    componentDidMount(){
        $(document).ready(function() {
            $("a[data-toggle='dropdown']").dropdown();
        });
        
    }
    

    renderPeriodDashboard(){
        const page = document.location.pathname;
        const acceptedPages = ['/dashboard', '/leads', '/dashboard_business'];
        if(acceptedPages.indexOf(page) !== -1){
            return(
                <ul className="navbar-nav mt-2 mt-lg-0 mx-0 mx-lg-2 text-hover-primary">
                    <li className="nav-item dropdown">
                        <a href="/" className="nav-link" data-toggle="dropdown" aria-expanded="false">
                            <i className="fa fa-calendar"></i> - {this.state.periodName}
                        </a>
                        <div className="dropdown-menu dropdown-menu-right mt-2 text-color" role="menu">
                            <a onClick={this.handleChange} data-period="current_month" data-period_name='Mês Atual' className="dropdown-item" href="/">{this.translate('HEADER_MAIN_CURRENT_MONTH')}</a> 
                            <div className="dropdown-divider"></div>
                            <a onClick={this.handleChange} data-period="last_month" data-period_name='Último Mês' className="dropdown-item" href="/">{this.translate('HEADER_MAIN_LAST_MONTH')}</a> 
                            <a onClick={this.handleChange} data-period="today" data-period_name='Hoje' className="dropdown-item" href="/">{this.translate('HEADER_MAIN_TODAY')}</a>
                            <a onClick={this.handleChange} data-period="yesterday" data-period_name='Ontem' className="dropdown-item" href="/">{this.translate('HEADER_MAIN_YESTERDAY')}</a>
                            <a onClick={this.handleChange} data-period="last_7days" data-period_name='Últimos 7 Dias' className="dropdown-item" href="/">{this.translate('HEADER_MAIN_LAST_7_DAYS')}</a>
                            <a onClick={this.handleChange} data-period="last_30days" data-period_name='Últimos 30 Dias' className="dropdown-item" href="/">{this.translate('HEADER_MAIN_LAST_30_DAYS')}</a>
                            <a onClick={this.handleChange} data-period="last_90days" data-period_name='Últimos 90 Dias' className="dropdown-item" href="/">{this.translate('HEADER_MAIN_LAST_90_DAYS')}</a>
                            <div className="dropdown-divider"></div>
                            <a onClick={this.handleChangeCustom} data-period="custom_date" data-period_name='Data Personalizada' className="dropdown-item" href='/'>{this.translate('HEADER_MAIN_CHOOSE_DATES')}</a>
                        </div>
                    </li>
                </ul>
            )
        }
        
    }

    render(){

        const styleElement = {
            form: {
                width: '80%',
                marginLeft: '10%'
            },
            div: {
                padding: '20px'
            }
        }

        return(
            <div className="content-header dark  box-shadow-0" id="content-header">
                <div className="navbar navbar-expand-lg">
                    <a href='/' className="d-lg-none mx-2" data-toggle="modal" data-target="#aside">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512"><path d="M80 304h352v16H80zM80 248h352v16H80zM80 192h352v16H80z"/></svg>
                    </a>
                    <div className="navbar-text nav-title flex" id="pageTitle">{this.props.title}</div>
                    
                    <ListCompanies translate={this.translate}/>

                    <ListBusiness translate={this.translate}/>

                    {this.renderPeriodDashboard()}

                </div>
                <Modal show={this.state.show} onHide={this.handleClose} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink green text-center position10">
                                <h5 className="h5 m-0 text-center width100 line-height-38px">
                                <i className="fa fa-clone"></i> {this.translate('HEADER_MAIN_DATES')}</h5>
                                <a href="/" onClick={e=>this.handleClose(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10" style={styleElement.div}>
                                <form onSubmit={this.handleSubmit} style={styleElement.form}>
                                    <div className="col-sm-12 col-md-10 col-lg-12 mb20">
                                        <label>{this.translate('HEADER_MAIN_INITIAL_DATE')}</label>
                                        <input
                                            onChange={e => this.setState({ period_initial: e.target.value })}
                                            type="datetime-local"
                                            className="form-control"
                                            name="period_initial"
                                            required
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-10 col-lg-12 mb20">
                                        <label>{this.translate('HEADER_MAIN_FINAL_DATE')}</label>


                                        <input
                                            onChange={e => this.setState({ period_end: e.target.value })}
                                            type="datetime-local"
                                            className="form-control"
                                            name="period_end"
                                            required
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-10 col-lg-12 mb20">
                                        <button type="submit" className="btn btn-success">{this.translate('HEADER_MAIN_CONSULT')}</button>
                                    </div>

                                </form> 
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export default withRouter(HeaderMain);