import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import StandardStructure from '../Structure/StandardStructure';

import api from '../Services/Api';

export class DashboardHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            app: null,
            period: 'current_month',
            periodName: 'Mês Atual',
            cardCustom: false,
            id: '',
            classCustom: '',
            lastUpdatedPhrase: false,
            isFeedActive: true,
        }
    }

    async returnResponse(period = this.state.period) {
        let periodPost;
        if (this.state.period !== period) {
            periodPost = period
        } else {
            periodPost = this.state.period
        }
        return await api.post('/build/dashboard/main', { period: periodPost })
            .then(async response => {


                //response.data = this.getEcommerceMockup()
                return response.data
            })
    }

    getEcommerceMockup = () => (
        {
            "app": [
                {
                    "row": "dashboardMiniCard",
                    "cards": [
                        {
                            "type": "card_ecommerce_total_sales",
                            "fields": {
                                "value": 0
                            }
                        },
                        {
                            "type": "card_ecommerce_sections",
                            "fields": {
                                "value": 0
                            }
                        },
                        {
                            "type": "card_ecommerce_speedometer_sales",
                            "fields": {
                                "metaSales": "R$ 0.00",
                                "valueSales": "R$ 0.00",
                                "porMetaSales": 0
                            }
                        }
                    ]
                },
                {
                    "row": "dashboardFunis",
                    "cards": [
                        {
                            "type": "card_ecommerce_funil_mkt_sales",
                            "fields": {
                                "market_value": "0",
                                "reach_value": "0",
                                "segmentation_value": "0",
                                "suspects_value": "0",
                                "journey_value": "0",
                                "leads_value": "0",

                                "por_relative_reach_per_market": "0",
                                "por_relative_segmentation_per_reach": "0",
                                "por_relative_suspects_per_segmentation": "0",
                                "por_relative_journey_per_suspects": "0",
                                "por_relative_leads_per_journey": "0",
                                "por_relative_visit_per_leads": "0",

                                "bid_value": "0",
                                "buy_value": "0",
                                "deal_value": "0",
                                "visit_value": "0",
                                "after_sales_value": "0",
                                "por_relative_buy_per_deal": "0",
                                "por_relative_deal_per_bid": "0",
                                "por_relative_bid_per_visit": "0",
                                "por_relative_after_sales_per_buy": "0"
                            }
                        }
                    ]
                },
                {
                    "row": "dashboardMiniCard",
                    "cards": [
                        {
                            "type": "card_ecommerce_total_investment",
                            "fields": {
                                "value": "R$ 0.00"
                            }
                        },
                        {
                            "type": "card_ecommerce_cost_per_sale",
                            "fields": {
                                "value": "R$ 0.00"
                            }
                        },
                        {
                            "type": "card_ecommerce_sections_per_sale",
                            "fields": {
                                "value": "0.00"
                            }
                        },
                        {
                            "type": "card_ecommerce_total_sold",
                            "fields": {
                                "value": "R$ 0.00"
                            }
                        }
                    ]
                },
                {
                    "row": "dashboardMiniCard",
                    "cards": [
                        {
                            "type": "card_ecommerce_chart_monthly_sales_x_visits",
                            "fields": {
                                "leads": {
                                    "months": [
                                        "Jun",
                                        "Mai",
                                        "Jul",
                                        "Ago",
                                        "Set",
                                        "Out",
                                        "Nov",
                                        "Dez",
                                        "Jan",
                                        "Fev",
                                        "Mar",
                                        "Abr",
                                        "Mai"
                                    ],
                                    "values": [
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "suspects": {
                                    "months": [
                                        "Jun",
                                        "Mai",
                                        "Jul",
                                        "Ago",
                                        "Set",
                                        "Out",
                                        "Nov",
                                        "Dez",
                                        "Jan",
                                        "Fev",
                                        "Mar",
                                        "Abr",
                                        "Mai"
                                    ],
                                    "values": [
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0
                                    ]
                                }
                            }
                        },
                        {
                            "type": "card_ecommerce_chart_monthly_sections",
                            "fields": {
                                "sales": {
                                    "months": [
                                        "Jun",
                                        "Mai",
                                        "Jul",
                                        "Ago",
                                        "Set",
                                        "Out",
                                        "Nov",
                                        "Dez",
                                        "Jan",
                                        "Fev",
                                        "Mar",
                                        "Abr",
                                        "Mai"
                                    ],
                                    "values": [
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0,
                                        0
                                    ]
                                }
                            }
                        }
                    ]
                },
                {
                    "row": "dashboardMiniCard",
                    "cards": [
                        {
                            "type": "card_ecommerce_last_generated_sales",
                            "fields": {
                                "values": [

                                ],
                                "count_records": 0
                            }
                        },
                        {
                            "type": "card_ecommerce_last_open_sections",
                            "fields": {
                                "values": [

                                ],
                                "count_records": 0
                            }
                        }
                    ]
                }
            ],
            "screen": "main_dashboard",
            "last_updated_phrase": "Ultima Atualização: 25/11/2019 12:24, Proxima Atualização: 25/11/2019 12:40."
        }
    )


    async returnResponseCustom(period_initial, period_end) {
        return await api.post('/build/dashboard/main/custom_period', { period_initial: period_initial, period_end: period_end })
            .then(async response => {
                return response.data
            })
    }

    handleChange = (period, periodName, cardCustom, period_initial = false, period_end = false) => {
        if (period !== 'custom_date' && !period_initial && !period_end) {
            const { isFeedActive } = this.state;
            this.returnResponse(period, periodName).then((data) => {
                this.setState({
                    app: data.app,
                    lastUpdatedPhrase: data.last_updated_phrase,
                    period: period,
                    periodName: periodName,
                    cardCustom: cardCustom,
                    isFeedActive: data.is_feed_active ?? isFeedActive,
                });
            })
        } else {
            this.handleChangeCustom(period_initial, period_end, cardCustom)
        }
    }

    handleChangeCustom = (period_initial, period_end, cardCustom) => {
        const { isFeedActive } = this.state;
        this.returnResponseCustom(period_initial, period_end).then((data) => {
            this.setState({
                app: data.app,
                periodName: data.periodName,
                cardCustom: cardCustom,
                isFeedActive: data.is_feed_active ?? isFeedActive,
            });
        })
    }

    UNSAFE_componentWillMount = async () => {
        const { isFeedActive } = this.state;
        await this.returnResponse().then((data) => {
            this.setState({
                app: data.app,
                lastUpdatedPhrase: data.last_updated_phrase,
                isFeedActive: data.is_feed_active ?? isFeedActive,
            });
        })
    }

    componentDidMount = async () => {
        /*
        this.returnResponse().then((data) => {
           console.log(data)
           this.setState({
                 app: data.app,
                 lastUpdatedPhrase: data.last_updated_phrase
           });
        })
        */
        // console.log("teste")
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.state.app !== null ? (
                        <StandardStructure
                            onChange={this.handleChange}
                            title="Dashboard"
                            cards={this.state.app}
                            periodName={this.state.periodName}
                            cardCustom={this.state.cardCustom}
                            id={this.state.id}
                            classCustom={this.state.classCustom}
                            feed={this.state.isFeedActive}
                            lastUpdatedPhrase={this.state.lastUpdatedPhrase}
                        />
                    ) : (
                            <p className="p-2 lead">
                                <i className="fa fa-spinner fa-spin"></i>&nbsp;
                                Carregando...
                            </p>
                        )
                }
            </React.Fragment>
        )
    }
}

export default withRouter(DashboardHome);