import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';

import $ from 'jquery';
import 'jquery-mask-plugin';

import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4'

import {ajaxUrl, ajaxToken} from '../../../../Services/Ajax';
import api from '../../../../Services/Api';

import Modal from 'react-bootstrap/Modal';

class Qualifiers extends Component {

    constructor(props){
        super(props);
        this.state = {
            modalResponseShow: false,
            msgResponseShow: false,
            tableLoadQualifiers: null,
            formRegister: {
                name: null,
                email: null,
                phone: null
            }
        }

        this.handleRegisterQualifier = this.handleRegisterQualifier.bind(this);
        this.handleCloseModalResponse = this.handleCloseModalResponse.bind(this);
        this.reloadDatatable = this.reloadDatatable.bind(this)
        this.translate = this.props.translate
    }

    componentDidMount(){
        //let tableLoadQualifiers = this.state.tableLoadQualifiers
        //const props = this.props
        //let tableLoadQualifiers = $('#list_qualifiers').DataTable({
        $('#list_qualifiers').DataTable({
            "searching": true,
            "paging": true,
            "info": true,
            "responsive": true,
            "language": {
                "url": this.translate('DATATABLE_LANGUAGE_URL')
            },
            "ajax": {
                "url": ajaxUrl('/qualifiers/table'),
                "type": 'GET',
                "beforeSend": function (request) {
                    request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                }
            }
        });

        var SPMaskBehavior = function (val){
            return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
        },
        spOptions = {
            onComplete: function(val){
                var phone = $('#register-qualifiers-phone').val();
                this.setState({formRegister: {...this.state.formRegister, phone: phone}})
            }.bind(this),
            onKeyPress: function(val, e, field, options) {
                field.mask(SPMaskBehavior.apply({}, arguments), options);
            }
        };

        $('#register-qualifiers-phone').mask(SPMaskBehavior, spOptions);
    }

    reloadDatatable(){
        $('#list_qualifiers').DataTable().ajax.reload();
    }

    handleRegisterQualifier = async (e) => {
        e.preventDefault();
        this.setState({ msgResponseShow: this.translate('QUALIFIERS_WAIT_STATUS'), modalResponseShow: true });
        const data = this.state.formRegister;
        try{
            const response = await api.post("/qualifiers/register", {data});
            if(response.data.success === true){
                this.setState({msgResponseShow: this.translate('QUALIFIERS_SUCCESS_STATUS')});
                document.getElementById('register-qualifiers-new').reset();
                this.reloadDatatable()
            }else{
                this.setState({msgResponseShow: response.data.msg});
            }
        }catch(err){
            this.setState({msgResponseShow: err});
        }
    }

    handleCloseModalResponse(e = null){
        if (e) e.preventDefault()
        this.setState({modalResponseShow: false})
    }

    render() {
        return (
            <div>
                <div id="accordion-qualifiers-control">
                    <div className="card mb-3">
                        <div className="card-header btn-cursor-pointer dark" id="headingQualifier">
                            <h5 className="mb-0" onClick={(e)=>this.props.toggleCollapse("#collapseQualifier")} aria-expanded="false" aria-controls="collapseQualifier">
                                {this.translate('QUALIFIERS_REGISTER_REGISTER_QUALIFIER')}
                                <i className="fa fa-caret-down pull-right"></i>
                            </h5>
                        </div>

                        <div id="collapseQualifier" className="collapse dark" aria-labelledby="headingQualifier" data-parent="#accordion-qualifiers-control">
                            <div className="card-body">
                                <form onSubmit={this.handleRegisterQualifier} id="register-qualifiers-new">
                                    <div className="row p-3 white box-shadow pb40">
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group">
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                    {this.translate('QUALIFIERS_REGISTER_LABEL_NAME')}:
                                                </label>
                                                <input type="text" name="name" autoComplete="off" onChange={e => this.setState({ formRegister: {...this.state.formRegister, name:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate('QUALIFIERS_REGISTER_PLACEHOLDER_NAME')} value={this.state.formRegister.name || ''} required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group">
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                    {this.translate('QUALIFIERS_REGISTER_LABEL_EMAIL')}:
                                                </label>
                                                <input type="email" name="email" autoComplete="off" onChange={e => this.setState({ formRegister: {...this.state.formRegister, email:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate('QUALIFIERS_REGISTER_PLACEHOLDER_EMAIL')} value={this.state.formRegister.email || ''} required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group">
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                    {this.translate('QUALIFIERS_REGISTER_LABEL_PHONE')}:
                                                </label>
                                                <input type="text" name="phone" id="register-qualifiers-phone" autoComplete="off" onChange={e => this.setState({ formRegister: {...this.state.formRegister, phone:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate('QUALIFIERS_REGISTER_PLACEHOLDER_PHONE')} value={this.state.formRegister.phone || ''} required/>
                                            </div>
                                        </div>
                                        <div className="box-divider m0 mt20 width100"></div>
                                        <div className="col-sm-12 col-md-12 mt20">
                                            <button type="submit" className="btn btn-info px-5 btn-rounded btn-shadow pull-right">
                                                <i className="fa fa-save"></i> {this.translate('QUALIFIERS_REGISTER_REGISTER_NEW_QUALIFIER')}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                    <div className="card">
                        <div className="card-header btn-cursor-pointer dark" id="headingQualifiersList" onClick={(e)=>this.props.toggleCollapse("#collapseQualifiersList")} aria-expanded="true" aria-controls="collapseQualifiersList">
                            <h5 className="mb-0">
                                {this.translate('QUALIFIERS_LIST_LIST_QUALIFIERS')}
                                <i className="fa fa-caret-down pull-right"></i>
                            </h5>
                        </div>

                        <div id="collapseQualifiersList" className="collapse dark show" aria-labelledby="headingQualifiersList" data-parent="#accordion-qualifiers-control">
                            <div className="card-body">
                                <div className="bootstrap-table">
                                    <div className="fixed-table-container">
                                        <div className="fixed-table-body">
                                            <table id="list_qualifiers" className="table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                                                <thead>
                                                    <tr className="text-left">
                                                        <th>{this.translate('QUALIFIERS_LIST_NAME')}</th>
                                                        <th>{this.translate('QUALIFIERS_LIST_EMAIL')}</th>
                                                        <th>{this.translate('QUALIFIERS_LIST_PHONE')}</th>
                                                        <th>{this.translate('TABLE_STATUS_ROW')}</th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <Modal show={this.state.modalResponseShow} onHide={this.handleCloseModalResponse} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-bell"></i> {this.translate('MODAL_STATUS_STATUS')}</h5>
                                <a href="/" onClick={e=>this.handleCloseModalResponse(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                <span className="col-12 text-center py-2 lead">
                                    {this.state.msgResponseShow}
                                </span>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default withRouter(Qualifiers);