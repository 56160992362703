import React, { Component } from 'react';

class SummaryConversionAndListAll extends Component {
    render() {
        return (
            <div className="col-sm-12 col-md-4">
                <div className="padding box box-shadow">
                    <div className="d-flex">
                        <span className="text-muted text-center w-100">Conversão</span>
                    </div>
                    <div className="py-2 b-b">
                        <div className="float-right lh">
                            <span className="text-md text-primary f700 d-block">{this.props.fields.conversion_segmentation_rate}% </span>
                            <br/>
                            <span className="f10 d-block">de conversão</span>
                        </div>
                        <span className="_600 d-block"> Melhor Segmentação</span>
                        <small className="text-info"><a href="leads_conversao.html">{this.props.fields.conversion_best_segmentation_name}</a></small>
                    </div>
                    <div className="py-2 b-b">
                        <div className="float-right lh">
                            <span className="text-md text-primary f700 d-block">{this.props.fields.conversion_banner_rate}% </span>
                            <br/>
                            <span className="f10 d-block">de conversão</span>
                        </div>
                        <span className="_600 d-block"> Melhor Banner</span>
                        <small className="text-info"><a href="leads_conversao.html">{this.props.fields.conversion_best_banner_name}</a></small>
                    </div>
                    <div className="py-2 b-b">
                        <div className="float-right lh">
                            <span className="text-md text-primary f700 d-block">{this.props.fields.conversion_origin_rate}% </span>
                            <br/>
                            <span className="f10 d-block">de conversão</span>
                        </div>
                        <span className="_600 d-block"> Melhor Origem</span>
                        <small className="text-info"><a href="leads_conversao.html">{this.props.fields.conversion_best_origin_name}</a></small>
                    </div>
                    <div className="py-2 b-b">
                        <div className="float-right lh">
                            <span className="text-md text-primary f700 d-block">{this.props.fields.conversion_landing_page_rate}% </span>
                            <br/>
                            <span className="f10 d-block">de conversão</span>
                        </div>
                        <span className="_600 d-block"> Melhor Landing Page</span>
                        <small className="text-info"><a href="leads_conversao.html">{this.props.fields.conversion_best_landing_page_name}</a></small>
                    </div>
                    <div className="py-2 b-b">
                        <div className="float-right lh">
                            <span className="text-md text-primary f700 d-block">{this.props.fields.conversion_email_rate}% </span>
                            <br/>
                            <span className="f10 d-block">de conversão</span>
                        </div>
                        <span className="_600 d-block"> Melhor E-mail</span>
                        <small className="text-info"><a href="leads_conversao.html">{this.props.fields.conversion_best_email_name}</a></small>
                    </div>
                    <div className="py-2 b-b">
                        <div className="float-right lh">
                            <span className="text-md text-primary f700 d-block">{this.props.fields.conversion_sms_rate}% </span>
                            <br/>
                            <span className="f10 d-block">de conversão</span>
                        </div>
                        <span className="_600 d-block"> Melhor SMS</span>
                        <small className="text-info"><a href="leads_conversao.html">{this.props.fields.conversion_best_sms_name}</a></small>
                    </div>
                    <div className="p-2">
                        <a href="/leads_conversion" className="btn btn-sm btn-block white">Ver tudo</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default SummaryConversionAndListAll;