import React, { Component } from 'react';

class SummaryLastInteractedJourneyAndListAllModal extends Component {
    render() {
        return (
            <div className="col-md-3 col-sm-12">
                <div className="padding box box-shadow">
                    <div className="d-flex">
                        <span className="text-muted text-center w-100">Últimos que interagiram com a jornada</span>
                        <hr className="b-b d-block" />
                    </div>
                    <div className="streamline streamline-dotted">
                        <React.Fragment>
                            {
                                this.props.fields.last_interacted_journey.slice(0,3).map((data, i) => 
                                    <div key={i} className="sl-item b-warning p0">
                                        <div className="sl-content">
                                            <span className="sl-date text-muted">
                                                {data.date}
                                            </span>
                                            <div>
                                                <a href="leads_data" className="text-primary">
                                                    {data.lead_name}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </React.Fragment>
                    </div>
                    <div className="p-2">
                        <a href="/leads_list" className="btn btn-sm btn-block white">Ver todos</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default SummaryLastInteractedJourneyAndListAllModal;