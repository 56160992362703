import React, { Component } from 'react'

export default class AddCard extends Component{
  
    render = () => 
        <div className="col-6 col-sm-6 col-md-3 btn-cursor-pointer hover-shadow active-dark-background" onClick={()=>this.props.handleAddCard(this.props.stageId, this.props.stageKey)}>
            <div className="dashboard_performance_card position10">
                <div className="d-flex">
                    <p className="uppercase text-muted f10">{this.props.translate("DASHBOARD_PERFORMANCE_CARD_ADD_CARD")}</p>
                </div>
                <div className="card-content">
                    <i className={`fa fa-plus text-center text-success f32 text-shadow width100 mt20`}></i>

                    <div className="py-3 text-center text-lg f700 f32 text-shadow">
                        {this.props.translate("DASHBOARD_PERFORMANCE_CARD_ADD_CARD")}
                    </div>
                    <div className="d-flex width100 text-center">
                        <span className="flex f10 width100">
                            {this.props.translate("DASHBOARD_PERFORMANCE_CARD_CLICK_TO_ADD_CARD")}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    
}







