import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import MetricsBuilder from './MetricsBuilder'

export default class MetricsModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isMetricVisible: false,
            metricTitle: "",
            isMetricToBeChosen: false,
            metric: null,
            metricProps: null
        }
        this.translate = this.props.translate

    }

    clearMetric = (e = null) => {
        if (e) e.preventDefault()

        this.setState({
            isMetricVisible: false,
            metricTitle: "",
            metric: null,
            metricProps: null
        })
    }

    closeMetricsModal = (e = null) => {
        if (e) e.preventDefault()
        this.clearMetric()
        this.props.handleCloseMetricsModal()

    }

    renderMetric = () => {
        let Metric = this.state.metric
        let metricProps = this.state.metricProps
        return <Metric {...metricProps} {...this.props} handleChangeMetricTitle={this.handleChangeMetricTitle} isMetricToBeChosen={this.state.isMetricToBeChosen} translate={this.props.translate} />
    }

    handleChangeMetricTitle = title => this.setState({ metricTitle: title })

    chooseMetric = (e = null, chosenMetric, chosenMetricProps, isMetricToBeChosen) => {
        if (e) e.preventDefault()
        console.log("metrics modal", chosenMetricProps, isMetricToBeChosen)

        this.setState({ isMetricVisible: true, isMetricToBeChosen, metricTitle: chosenMetricProps.title, metric: chosenMetric, metricProps: chosenMetricProps })

    }

    render = () => {

        return (
            <Modal show={this.props.isMetricsModalVisible} backdrop="static" onHide={this.closeMetricsModal} dialogAs={'div'} dialogClassName="modal-dialog modal-right w-75 white b-t">
                <Modal.Body>
                    <div className="d-flex flex-column h-100 bg-blur_w2">
                        <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                            <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-line-chart"></i> {this.translate("METRICS_MODAL_METRICS_MODAL")}</h5>
                            <a href="/" onClick={e => this.closeMetricsModal(e)} className="ml-auto">
                                <span className="text-lg l-h-1x">×</span>
                            </a>
                        </div>
                        <div className="row position10 padding-20px">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><a href="/" onClick={e => this.closeMetricsModal(e)}>{this.props.dashboardTitle || this.translate("METRICS_MODAL_DASHBOARD_WITHOUT_TITLE")}</a></li>
                                                <li className="breadcrumb-item"><a href="/" onClick={e => this.closeMetricsModal(e)}>{this.props.cardTitle || this.translate("METRICS_MODAL_CARD_WITHOUT_TITLE")}</a></li>
                                                {this.state.metricTitle ?
                                                    <React.Fragment>
                                                        <li className="breadcrumb-item"><a href="/" onClick={e => this.clearMetric(e)}>{this.translate("METRICS_MODAL_METRICS_MODAL")}</a></li>
                                                        <li className="breadcrumb-item active">{this.state.metricTitle}</li>
                                                    </React.Fragment>
                                                    :
                                                    <li className="breadcrumb-item active">{this.translate("METRICS_MODAL_METRICS_MODAL")}</li>

                                                }
                                            </ol>
                                        </nav>
                                    </div>
                                    <div className="col-12">
                                        {this.state.isMetricVisible ?
                                            this.state.metric ? this.renderMetric() : false
                                            :
                                            this.props.isEditing ?

                                                <React.Fragment>
                                                    <h3>{this.translate('METRICS_MODAL_SELECTED_METRICS')}</h3>
                                                    <div className="list-group">
                                                        {this.props.metrics && this.props.metrics.length > 0 ?
                                                            this.props.metrics.map((metric, metricIndex) =>
                                                                <React.Fragment key={`chosen${this.props.stageId}${this.props.id}${metric.metric_key}${metricIndex}`}>
                                                                    <MetricsBuilder metric={metric} chooseMetric={this.chooseMetric} {...this.props} />
                                                                </React.Fragment>
                                                            )
                                                            :
                                                            <p className="my-2">{this.translate('METRICS_MODAL_SELECT_DESIRED_METRICS')}</p>
                                                        }
                                                    </div>
                                                    <hr />
                                                    <h3>{this.translate('METRICS_MODAL_SELECT_METRICS')}</h3>
                                                    <div className="list-group">
                                                        {this.props.metrics_to_be_chosen && this.props.metrics_to_be_chosen.length ?
                                                            this.props.metrics_to_be_chosen.map((metric, metricIndex) =>
                                                                <React.Fragment key={`add${this.props.stageId}${this.props.id}${metric.metric_key}${metricIndex}`}>
                                                                    <MetricsBuilder metric={metric} chooseMetric={this.chooseMetric} {...this.props} isMetricToBeChosen={true} />
                                                                </React.Fragment>
                                                            )
                                                            :
                                                            <p className="my-2">{this.translate('METRICS_MODAL_THERE_ARENT_ANY_METRICS_TO_BE_SELECTED')}</p>
                                                        }
                                                    </div>
                                                </React.Fragment>
                                                :
                                                <div className="list-group">
                                                    {this.props.metrics && this.props.metrics.length ?
                                                        this.props.metrics.map((metric, metricIndex) =>
                                                            <React.Fragment key={`normal${this.props.stageId}${this.props.id}${metric.metric_key}${metricIndex}`}>
                                                                <MetricsBuilder metric={metric} chooseMetric={this.chooseMetric} {...this.props} />
                                                            </React.Fragment>
                                                        )
                                                        :
                                                        <p className="my-2">{this.translate("METRICS_MODAL_THERE_ARENT_METRICS_REGISTERED_FOR_THIS_CARD")}</p>
                                                    }
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        )

    }

}
