import axios from "axios";
import {getToken, logout} from '../Auth/AuthConfig';

import {urlBase, versionBase} from './Config';

let upload = false;
let download = false;

export const setUpload = function (){
    upload = true;
}

export const setDownload = function (headers){
    download = true;
}

const api = axios.create({
    baseURL: urlBase + versionBase
});

api.interceptors.request.use(
    (config) => {
        const token = getToken();
        if(upload){
            config.headers = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'multipart/form-data'
            }
            upload = false;
        }else if(download){
            config.headers = {
                'Authorization': 'Bearer ' + token
            }
            config.responseType =  'blob'
            download = false;
        }else{
            config.headers = {
                'Authorization': 'Bearer ' + token
            }
        }

        return config;
    },
    (error) => {
        console.log(error);
    }
);

api.interceptors.response.use(
    (response) => {
        const data = response.data;
        if(typeof data !== 'undefined'){
            return response;
        }
    },
    (err) => {
        if(typeof err['response'] !== 'undefined'){
            if(typeof err.response['status'] !== 'undefined' && err.response.status === 401){
                logout();
            }
        }
    }
);

export default api;