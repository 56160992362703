import React, { Component, Fragment } from 'react';
import {withRouter} from 'react-router-dom';

import api from '../../Services/Api';

import $ from 'jquery'

import Modal from 'react-bootstrap/Modal'

import MenuMain from '../../Structure/MenuMain';
import HeaderMain from '../../Structure/HeaderMain';
import FooterMain from '../../Structure/FooterMain';

class LeadPlus extends Component {

    constructor(props){
        super(props);
        this.translate = this.props.translate
        document.title = this.translate('LEAD_PLUS_LEAD_4_0')
        this.state = {
            modalAddParagraphShow: false,
            alertSaveParagraphs: null,
            formCreateAddParagraph: {
                type: null,
                name: null
            },
            lead2v: [],
            lead2vTemp: []
        }
        this.showModalAddParagraphShow = this.showModalAddParagraphShow.bind(this)
        this.closeModalAddParagraphShow = this.closeModalAddParagraphShow.bind(this)
        this.formSubmitCreateAddParagraph = this.formSubmitCreateAddParagraph.bind(this)
        this.changeParagraph = this.changeParagraph.bind(this)
        this.setCloseAlertSaveParagraphs = this.setCloseAlertSaveParagraphs.bind(this)
        this.formSubmitReloadAddParagraph = this.formSubmitReloadAddParagraph.bind(this)
    }

    showModalAddParagraphShow(){
        this.setState({modalAddParagraphShow: true})
    }

    closeModalAddParagraphShow(){
        this.setState({modalAddParagraphShow: false})
    }

    async formSubmitCreateAddParagraph(e){
        e.preventDefault();
        $('#add_paragraph_button_submit').prop('disabled', true).html(`<i className="fa fa-spinner"></i> ${this.translate('LEAD_PLUS_WAIT_ICON')}`)
        
        const data = this.state.formCreateAddParagraph;
        try{
            const response = await api.post("/lead2v/paragraphs/create", {data});
            console.log(response)
            if(response.data.success === true){
                this.loadLead2v()
                this.closeModalAddParagraphShow();
                $('#add_paragraph_button_submit').prop('disabled', false).html(`<i className="fa fa-save"></i> ${this.translate('LEAD_PLUS_REGISTER_ICON')}`)
            }else{
                console.log(response.data.msg);
            }
        }catch(err){
            console.log(err);
        }
    }

    removeTagsNotAccepted(tagsNotAccepted, text){
        let textFinal = text
        tagsNotAccepted.forEach(tag => {
            textFinal = textFinal.replace(tag, '[remove]');
        });
        textFinal = textFinal.replace('[remove]', '');
        return textFinal;
    }

    changeParagraph(e, index, type){
        
        const {lead2vTemp} = this.state;

        let tagsAccepted = []
        let tagsNotAccepted = []

        const match = e.target.value.match(/\[.*?\]+/gm);
        
        let value = e.target.value;

        if(match !== null && match.length){
            switch(type){
                case 'new_lead':
                    tagsAccepted.push('[lead_name]', '[business_name]', '[business_distance]', '[entry_date]', '[entry_time]', '[form_name]', '[source_media]', '[pitch_segmentation]', '[pitch_contents]', '[pitch_campaign]', '[pitch_click]', '[lead_age]', '[businessman]', '[lead_age_range]', '[income_range]', '[occupation]', '[actual_job]', '[amount_business]')
                    break;
                case 'approach_lead':
                    tagsAccepted.push('[lead_name]', '[business_name]', '[entry_date]', '[entry_time]', '[form_name]', '[source_media]', '[pitch_segmentation]', '[pitch_contents]', '[pitch_campaign]', '[pitch_click]', '[champion_interaction]')
                    break;
                case 'clicked_link_email':
                    tagsAccepted.push('[entry_date]', '[entry_time]', '[pitch_click]')
                    break;
                default:
                    break;
            }
            tagsNotAccepted = match.filter((tag) => {
                return tagsAccepted.includes(tag) === false;
            })
            value = this.removeTagsNotAccepted(tagsNotAccepted, value)
            lead2vTemp[index]['text'] = value
        }else{
            lead2vTemp[index]['text'] = value
        }

        lead2vTemp[index]['changed'] = true

        this.setState({lead2vTemp: lead2vTemp})

        e.target.value = lead2vTemp[index]['text']
    }

    setShowAlertSaveParagraphs(value){
        this.setState({
            alertSaveParagraphs: value
        })
    }

    setCloseAlertSaveParagraphs(){
        this.setState({
            alertSaveParagraphs: null
        })
    }

    async formSubmitReloadAddParagraph(e){
        e.preventDefault();
        
        $('#update_paragraphs').prop('disabled', true).html(`<i className="fa fa-spinner"></i> ${this.translate('LEAD_PLUS_WAIT_ICON')}`)
        
        const data = this.state.lead2vTemp;

        try{
            const response = await api.post("/lead2v/paragraphs/update", {data});
            if(response.data.success === true){
                this.loadLead2v()
                this.setShowAlertSaveParagraphs(true)
                $('#update_paragraphs').prop('disabled', false).html(`<i className="fa fa-save"></i> ${this.translate('LEAD_PLUS_UPDATE_ICON')}`)
            }else{
                this.setShowAlertSaveParagraphs(false)
                $('#update_paragraphs').prop('disabled', false).html(`<i className="fa fa-save"></i> ${this.translate('LEAD_PLUS_UPDATE_ICON')}`)
            }
        }catch(err){
            console.log(err);
        }
    }

    async loadLead2v(){
        try{
            const response = await api.get("/lead2v/paragraphs/select");
            this.setState({
                lead2v: response.data,
                lead2vTemp: response.data,
            })
        }catch(err){
            console.log(err);
        }
    }

    componentDidMount(){
        this.loadLead2v()
        /* Resize Textareas */
        $(document).on('input', 'textarea', function(){
            this.setAttribute('style', 'height:' + (this.scrollHeight) + 'px;overflow-y:hidden;');
            this.style.height = 'auto';
            this.style.height = (this.scrollHeight) + 'px';
        });
    }

    componentDidUpdate(){
        let tx = document.getElementsByTagName('textarea');
        for (let i = 0; i < tx.length; i++) {
            tx[i].setAttribute('style', 'height:' + (tx[i].scrollHeight) + 'px;overflow-y:hidden;');
        }
    }

    render() {
        return (
            <Fragment>
                <MenuMain />
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    <HeaderMain title={document.title} onChange={this.handleChange}/>
                    <div className="content-main row row-no-margin" id="content-main">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-gutters mt-4">

                            <div className="row text-right mb-3">
                                <div className="col-md-12">
                                    <button onClick={this.showModalAddParagraphShow} className='btn btn-rounded btn-warning btn-cursor-pointer pull-right' >
                                        <i className="fa fa-book"></i> {this.translate('LEAD_PLUS_ADD_NEW_PARAGRAPH')}
                                    </button>
                                </div>
                            </div>

                            <div className='row dark'>
                                
                                <div className="col-md-4">
                                    <div className="box dark p-3">
                                        <div>
                                            <h5>
                                                <b>{this.translate('')}</b>
                                            </h5>
                                        </div>
                                        <hr />
                                        <div>
                                            <p>
                                                <b>[lead_name]</b>: {this.translate('LEAD_PLUS_TAG_LEAD_NAME')}
                                            </p>
                                            <p>
                                                <b>[lead_age]</b>: {this.translate('LEAD_PLUS_TAG_LEAD_AGE')}
                                            </p>
                                            <p>
                                                <b>[lead_age_range]</b>: {this.translate('LEAD_PLUS_TAG_LEAD_AGE_RANGE')}
                                            </p>
                                            <p>
                                                <b>[income_range]</b>: {this.translate('LEAD_PLUS_TAG_INCOME_RANGE')}
                                            </p>
                                            <p>
                                                <b>[occupation]</b>: {this.translate('LEAD_PLUS_TAG_OCCUPATION')}
                                            </p>
                                            <p>
                                                <b>[actual_job]</b>: {this.translate('LEAD_PLUS_TAG_ACTUAL_JOB')}
                                            </p>
                                            <p>
                                                <b>[businessman]</b>: {this.translate('LEAD_PLUS_TAG_BUSINESSMAN')}
                                            </p>
                                            <p>
                                                <b>[amount_business]</b>: {this.translate('LEAD_PLUS_TAG_AMOUNT_BUSINESS')}
                                            </p>
                                            <p>
                                                <b>[business_name]</b>: {this.translate('LEAD_PLUS_TAG_BUSINESS_NAME')}
                                            </p>
                                            <p>
                                                <b>[business_distance]</b>: {this.translate('LEAD_PLUS_TAG_BUSINESS_DISTANCE')}
                                            </p>
                                            <p>
                                                <b>[entry_date]</b>: {this.translate('LEAD_PLUS_TAG_ENTRY_DATE')}
                                            </p>
                                            <p>
                                                <b>[entry_time]</b>: {this.translate('LEAD_PLUS_TAG_ENTRY_TIME')}
                                            </p>
                                            <p>
                                                <b>[form_name]</b>: {this.translate('LEAD_PLUS_TAG_FORM_NAME')}
                                            </p>
                                            <p>
                                                <b>[source_media]</b>: {this.translate('LEAD_PLUS_TAG_SOURCE_MEDIA')}
                                            </p>
                                            <p>
                                                <b>[pitch_segmentation]</b>: {this.translate('LEAD_PLUS_TAG_PITCH_SEGMENTATION')}
                                            </p>
                                            <p>
                                                <b>[pitch_contents]</b>: {this.translate('LEAD_PLUS_TAG_PITCH_CONTENTS')}
                                            </p>
                                            <p>
                                                <b>[pitch_campaign]</b>: {this.translate('LEAD_PLUS_TAG_PITCH_CAMPAIGN')}
                                            </p>
                                            <p>
                                                <b>[pitch_click]</b>: {this.translate('LEAD_PLUS_TAG_PITCH_CLICK')}
                                            </p>
                                            <p>
                                                <b>[pitch_landing_page]</b>: {this.translate('LEAD_PLUS_TAG_PITCH_LANDING_PAGE')}
                                            </p>
                                            <p>
                                                <b>[pitch_email_marketing]</b>: {this.translate('LEAD_PLUS_TAG_PITCH_EMAIL_MARKETING')}
                                            </p>
                                            <p>
                                                <b>[pitch_banner]</b>: {this.translate('LEAD_PLUS_TAG_PITCH_BANNER')}
                                            </p>
                                            <p>
                                                <b>[pitch_sms]</b>: {this.translate('LEAD_PLUS_TAG_PITCH_SMS')}
                                            </p>
                                            <p>
                                                <b>[champion_interaction]</b>: {this.translate('LEAD_PLUS_TAG_CHAMPION_INTERACTION')}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-8">
                                    <div className="box dark p-3">
                                        {
                                            this.state.alertSaveParagraphs === true ? (
                                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                                    <strong>{this.translate('LEAD_PLUS_SUCCESS')}</strong> {this.translate('LEAD_PLUS_UPDATE_PARAGRAPH')}
                                                    <button type="button" className="close" onClick={this.setCloseAlertSaveParagraphs}>
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                            ):(
                                                this.state.alertSaveParagraphs === false ? (
                                                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                                        <strong>{this.translate('LEAD_PLUS_SUCCESS')}</strong> {this.translate('LEAD_PLUS_UPDATE_PARAGRAPH')}
                                                        <button type="button" className="close" onClick={this.setCloseAlertSaveParagraphs}>
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                ):(
                                                    <Fragment></Fragment>
                                                )
                                            )
                                        }
                                        {
                                            this.state.lead2v.length? (
                                                <Fragment>
                                                    <form onSubmit={this.formSubmitReloadAddParagraph}>
                                                        {
                                                            this.state.lead2v.map((data, i) => 
                                                                <Fragment key={i}>
                                                                    <div className="col-sm-12 mb-3">
                                                                        <label className="black no-text-shadow">{data.name} - (<b>{this.translate('LEAD_PLUS_TYPE')}</b>: {data.type})</label>
                                                                        <textarea className="form-control" defaultValue={data.text} onChange={e => this.changeParagraph(e, i, data.type)}></textarea>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        }
                                                        <div className="col-sm-12 mb-3" style={{height: '70px'}}>
                                                            <button id="update_paragraphs" type="submit" className="btn btn-fw warning btn-rounded mt20 pull-right btn-shadow px-5">
                                                                <i className="fa fa-save"></i> {this.translate('LEAD_PLUS_UPDATE')}
                                                            </button>
                                                        </div>
                                                    </form>
                                                </Fragment>
                                            ):(
                                                <p className="text-center">
                                                    {this.translate('LEAD_PLUS_NO_PARAGRAPH')}
                                                </p>
                                            )
                                        }
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                    <FooterMain />
                </div>
                <Modal show={this.state.modalAddParagraphShow} onHide={this.closeModalAddParagraphShow} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t modal-link-builder">
                    <Modal.Body>
                    <div className="d-flex flex-column h-100 pb-5">
                        <div className="p-3 b-b d-flex no-shrink warning text-center">
                            <h5 className="h5 m-0 text-center width100 line-height-38px">
                                <i className="fa fa-book"></i> {this.translate('LEAD_PLUS_MODAL_NEW_PARAGRAPH')}
                            </h5>
                            <button onClick={this.closeModalAddParagraphShow} className="btn btn-transparent ml-auto">
                                <span className="text-lg l-h-1x">×</span>
                            </button>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-6 offset-md-3">
                                <form onSubmit={this.formSubmitCreateAddParagraph}>
                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('LEAD_PLUS_MODAL_LABEL_PARAGRAPH_NAME')}</label>
                                        <input type="text" onChange={e => this.setState({ formCreateAddParagraph: {...this.state.formCreateAddParagraph, name:  e.target.value}})} className="form-control box-shadow cool-input" placeholder={this.translate('LEAD_PLUS_MODAL_PLACEHOLDER_PARAGRAPH_NAME')} required="required" />
                                    </div>
                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('LEAD_PLUS_MODAL_LABEL_LINK_TYPE')}</label>
                                        <select onChange={e => this.setState({ formCreateAddParagraph: {...this.state.formCreateAddParagraph, type:  e.target.value}})} className="form-control box-shadow cool-select" required="required">
                                            <option value="">{this.translate('LEAD_PLUS_MODAL_OPTION_SELECT_TYPE')}</option>
                                            <option value="new_lead">{this.translate('LEAD_PLUS_MODAL_OPTION_NEW_LEAD')}</option>
                                            <option value="approach_lead">{this.translate('LEAD_PLUS_MODAL_OPTION_APPROACH_LEAD')}</option>
                                            <option value="new_view">{this.translate('LEAD_PLUS_MODAL_OPTION_NEW_VIEW')}</option>
                                            <option value="clicked_link_email">{this.translate('LEAD_PLUS_MODAL_OPTION_CLICKED_EMAIL_LINK')}</option>
                                        </select>
                                    </div>
                                    <button type="submit" id="add_paragraph_button_submit" className="btn btn-fw warning btn-rounded mt20 pull-right btn-shadow px-5"><i className="fa fa-save"></i> {this.translate('LEAD_PLUS_MODAL_REGISTER')}</button>
                                    <br />
                                </form>

                            </div>
                        </div>
                    </div>
                    </Modal.Body>
                </Modal>
            </Fragment>
        );
    }
}

export default withRouter(LeadPlus);