import React, { Component } from 'react';

import api from '../Services/Api';

class Teste extends Component{

    componentDidMount(){
        api.get('/teste')
            .then(response => {
                console.log(response.data);
            })
    }

    render() {
        return (
            <div>
                teste
            </div>
        );
    }
}

export default Teste;