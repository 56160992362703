import React, { Component } from 'react'

import MonthlyLineChart from "./Views/MonthlyLineChart"
import MonthlyBarChart from './Views/MonthlyBarChart'
import ViewNotFound from './Views/ViewNotFound'
import MonthlyRankingList from './Views/MonthlyRankingList'
import PieChart from './Views/PieChart'

const viewSelector = {
    total_info_per_month_line_chart: MonthlyLineChart,
    total_info_per_month_bar_chart: MonthlyBarChart,
    total_info_per_month_ranking_list: MonthlyRankingList,
    pie_chart: PieChart,
}

export default class ViewBuilder extends Component{
    renderView = () => {

        let ViewElement = viewSelector[this.props.viewCode] 
        if(!ViewElement) ViewElement = ViewNotFound
        
        return (
            <ViewElement {...this.props} />
        )
    }
    render = () => {
        return (
            this.renderView()
        )
    }
    
}
