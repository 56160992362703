import React, { Component } from 'react';
import Chart from 'chart.js';

import {app, hexToRGB} from '../Structure/Configs/Theme';

class ChartRadarOrigin extends Component {

    componentDidMount(){
        const myChart = document.getElementById('chart-radar-origem').getContext('2d');
        new Chart(myChart, {
            type: 'radar',
            data: {
                labels: this.props.fields.names_origins,
                datasets: [
                    {
                        label: 'Meta',
                        data: this.props.fields.goals_origins,
                        fill: true,
                        lineTension: 0,
                        backgroundColor: hexToRGB(app.color.accent, 0.4),
                        borderColor: app.color.accent,
                        borderWidth: 2,
                        borderJoinStyle: 'miter',
                        pointBorderColor: app.color.accent,
                        pointBackgroundColor: '#fff',
                        pointBorderWidth: 2,
                        pointHoverRadius: 7,
                        pointHoverBackgroundColor: app.color.accent,
                        pointHoverBorderColor: '#fff',
                        pointHoverBorderWidth: 2,
                        pointRadius: 5
                    },
                    {
                        label: 'Resultado',
                        data: this.props.fields.total_origins,
                        backgroundColor: 'rgba(33,216,115,0.32)',
                        borderColor: 'rgba(20,220,40,0.5)',
                        borderWidth: 2,
                        borderJoinStyle: 'miter',
                        pointBorderColor: 'rgba(20,220,40,0.4)',
                        pointBackgroundColor: '#fff',
                        pointBorderWidth: 2,
                        pointHoverRadius: 7,
                        pointHoverBackgroundColor: 'rgba(20,220,40,0.4)',
                        pointHoverBorderColor: '#fff',
                        pointHoverBorderWidth: 2,
                        pointRadius: 5
                    }
                ]
            },
            options: {
                scale:{
                    angleLines: {
                        color: 'rgba(120,130,140,0.1)',
                        lineWidth: 4
                    }
                }
            }
        })
    }

    render() {
        return (
            <div className="col-md-5 col-sm-12 mt20">
                <div className="padding box box-shadow">
                    <div className="d-flex">
                        <span className="text-muted text-center w-100">Origem</span>
                        <hr className="b-b d-block" />
                    </div>
                    <canvas id="chart-radar-origem" data-plugin="chart" height={250} width="" className="chartjs-render-monitor position10"></canvas>
                </div>
            </div>
        );
    }
}

export default ChartRadarOrigin;