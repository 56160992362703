import React, { Component, Fragment } from 'react';
import {withRouter} from 'react-router-dom';

import MenuMain from '../../Structure/MenuMain';
import HeaderMain from '../../Structure/HeaderMain';
import FeedMain from '../../Structure/FeedMain';

import $ from 'jquery';
import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4'

import {ajaxUrl, ajaxToken} from '../../Services/Ajax';

import Modal from 'react-bootstrap/Modal';

import SuspectModalData from './Components/SuspectModalData'

class SuspectList extends Component {

    constructor(props){
        super(props);
        document.title = "Lista de Suspects"
        this.state = {
            suspectsURL: "/suspects/table",
            formSuspectAdvancedSearch: {
                domain: "",
            },
            domainsSelectList: [],
            tableLoadSuspects: null,
            modalSuspect: false,
            idSuspect: null
        }
        this.showModalSuspect = this.showModalSuspect.bind(this)
        this.closeModalSuspect = this.closeModalSuspect.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.translate = this.props.translate
    }

    showModalSuspect(idSuspect){
        this.setState({
            idSuspect: idSuspect,
            modalSuspect: true,
        })
    }

    toggleCollapse = (selector) => $(selector).collapse("toggle")

    closeModalSuspect(){
        this.setState({
            modalSuspect: false
        })
    }

    handleChange(){
        this.closeModalSuspect()
    }

    handleSubmitAdvancedSearch = (e) => {
        e.preventDefault()
        let {formSuspectAdvancedSearch} = this.state
        console.log(formSuspectAdvancedSearch)
        let urlSearch = this.state.suspectsURL.split("?")[0]
        let i = 0
        for(let [key, value] of Object.entries(formSuspectAdvancedSearch)){
            if(value){
                urlSearch += i === 0 ? "?" : "&"
                urlSearch += `${key}=${value}`
                i++
            }
        }
        urlSearch = encodeURI(urlSearch)
        this.setState({suspectsURL: urlSearch}, this.reloadDatatable)
    }

    handleClearAdvancedSearch = (e) => {
        let urlSearch = this.state.suspectsURL.split("?")[0]
       
        this.setState({
            formSuspectAdvancedSearch: {
                domain: "",
            },
            domainsSelectList: [],
            suspectsURL: urlSearch
        }, this.reloadDatatable)
    }

    reloadDatatable(){
        $('#list_suspects').DataTable().ajax.url(ajaxUrl(this.state.suspectsURL)).load();
    }

    loadDomainsList = async () => {
        try{
            //const response = await api.get("/brokers/domains")
            let response = {
                data: {
                    success: true,
                    data:[
                        /* {"name": "Domínio de teste", code: "teste_1"},
                        {"name": "Domínio de teste 2", code: "teste_2"},
                        {"name": "Domínio de teste 3", code: "teste_3"},
                        {"name": "Domínio de teste 4", code: "teste_4"},  */
                    ]
                }
            }
            if(response && response.data && response.data.data && response.data.data.length){
                this.setState({domainsSelectList: response.data.data})
            } 
        }catch(err){
            console.log(err)
        }
    }


    componentDidMount = async () => {

        let tableLoadSuspects = this.state.tableLoadSuspects
        tableLoadSuspects = $('#list_suspects').DataTable({
            "searching": true,
            "paging": true,
            "info": false,
            "responsive": true,
            "language": {
                "url": this.translate('DATATABLE_LANGUAGE_URL')
            },
            "ajax": {
                "url": ajaxUrl(this.state.suspectsURL),
                "type": 'GET',
                "beforeSend": function (request) {
                    request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                }
            },
            "columnDefs": [{
                "targets": -1,
                "data": null,
                "defaultContent": 
                    `<div class="btn-group btn-shadow">
                        <button class="view btn btn-sm success" title="${this.translate('SUSPECT_LIST_VISUALIZE_SUSPECT')}">
                            <i class="fa fa-clone" title="${this.translate('SUSPECT_LIST_VISUALIZE_SUSPECT')}"></i> ${this.translate('SUSPECT_LIST_VISUALIZE')}
                        </button>
                    </div>`
            }]
        });
        $('#search_suspect').on('keyup change', function(){
            tableLoadSuspects.search(this.value).draw();
        });
        $('#length_change_suspect').on('keyup change', function(){
            tableLoadSuspects.page.len($(this).val()).draw();
        });
        $('#list_suspects tbody').on( 'click', '.view', function (event){
            event.preventDefault();
            let data = [];
            if($(event.target).parents('tr').hasClass('child')){
                data = tableLoadSuspects.row($(event.target).parents('tr').prev('.parent')).data();
            }else{
                data = tableLoadSuspects.row($(event.target).parents('tr')).data();
            }
            this.showModalSuspect(data[4])
        }.bind(this));

        const hash = document.location.hash.replace('#','')
        if(hash.length > 0) this.showModalSuspect(hash)

        this.loadDomainsList()
    }

    render() {
        return (
            <Fragment>
                <MenuMain />
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    <HeaderMain title={this.translate('SUSPECT_LIST_SUSPECTS_LIST')} onChange={this.handleChange}/>
                    <div className="content-main row row-no-margin" id="content-main">

                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 accent" id="suspects">

                            <div className="row bg-blur_w">
                                <h2 className="fwhite text-center width100 mt40"> 
                                    <span className="circle accent ly number-circle f32 text-center f700 box-shadow mb10">
                                        <i className="fa fa-list"></i>
                                    </span>
                                    {this.translate('SUSPECT_LIST_SUSPECTS_LIST')}
                                </h2>
                            </div>

                            <div className="row mt20 mb20">
                                
                                <div className="col-sm-4">
                                    <select id="length_change_suspect" className="custom-select w-sm d-inline v-middle btn-shadow">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                    <label className="">&nbsp; {this.translate('SUSPECT_LIST_RESULTS_PER_PAGE')}</label>                
                                </div>

                                <div className="col-sm-12 col-md-6 advanced_leads_filter">
                                    <div className="input-group light">
                                        <input type="text" id="search_suspect" className="form-control text-dark border-none" placeholder={this.translate('SUSPECT_LIST_SEARCH_A_SUSPECT')}/>
                                        <button className="btn rounded-0 btn-info" type="button" onClick={(e)=>this.toggleCollapse("#collapseSuspect")}  aria-expanded="false" aria-controls="collapseExample">
                                            <span className="fa fa-chevron-down"></span> {this.translate('SUSPECT_LIST_ADVANCED_SEARCH')}
                                        </button>
                                    </div>
                                    <div className="collapse" id="collapseSuspect">
                                        <form id="search_suspect_adv" onSubmit={this.handleSubmitAdvancedSearch}>
                                            <div className="well light p-4 box-shadow">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <p className="m0">{this.translate('SUSPECT_LIST_SEARCH_FOR_DOMAIN')}:</p>
                                                        <select name="domain" className="form-control cool-select" value={this.state.formSuspectAdvancedSearch.domain} onChange={e=>this.setState({formSuspectAdvancedSearch: {...this.state.formSuspectAdvancedSearch, domain: e.target.value}})}>
                                                            <option value="">{this.translate('SUSPECT_LIST_SELECT_THE_DOMAIN')}</option>
                                                            {this.state.domainsSelectList.map((domain, index)=>
                                                                <option key={ "domain" + index + domain.code} value={domain.code}>{domain.name}</option>
                                                            )} 
                                                        </select>
                                                    </div>
                                                    
                                                    <hr className="d-block w-100 mb10 mt10"/>
                                                    <div className="col-12 d-flex justify-content-end">
                                                        <button type="button" className="btn btn-danger btn-rounded btn-shadow btn-cursor-pointer" onClick={this.handleClearAdvancedSearch}><i className="fa fa-trash"></i> {this.translate('LEADS_LIST_CLEAN')} </button>
                                                        <button type="submit" className="btn btn-success btn-rounded btn-shadow ml-2 btn-cursor-pointer"><i className="fa fa-search"></i> {this.translate('LEADS_LIST_SEARCH')}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="">
                                        <div className=" box-shadow">
                                        <div className="bootstrap-table">
                                            <div className="fixed-table-container">
                                                <div className="fixed-table-body">
                                                    
                                                    <table id="list_suspects" className="table table-striped b-t table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                                                        <thead>
                                                            <tr className="text-left">
                                                                <th>{this.translate('SUSPECT_LIST_NUMBER')}</th>
                                                                <th>{this.translate('SUSPECT_LIST_ORIGIN')}</th>
                                                                <th>{this.translate('SUSPECT_LIST_SEGMENTATION')}</th>
                                                                <th>{this.translate('SUSPECT_LIST_ENTRY_DATE')}</th>
                                                                <th>{this.translate('TABLE_ACTIONS_ROW')}</th>
                                                            </tr>
                                                        </thead>
                                                    </table>

                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <FeedMain />
                    </div>
                </div>
                <Modal show={this.state.modalSuspect} onHide={this.closeModalSuspect} dialogAs={'div'} dialogClassName="modal-dialog modal-dialog-susp1 modal-bottom white b-t">
                    <Modal.Body>
                        <SuspectModalData idSuspect={this.state.idSuspect} onChange={this.handleChange} translate={this.translate}/>
                    </Modal.Body>
                </Modal>
            </Fragment>
        );
    }
}

export default withRouter(SuspectList);