import React, { Component, Fragment } from 'react'

import api from '../../../../Services/Api'
import Status from '../../../../Templates/Status'

export default class RefundComponent extends Component {

    constructor(props) {
        super(props)

        this.stageNumber = 3
        this.translate = this.props.translate
        this.state = {
            isLoading: true,
            status: false,
            refundReasons: [],
            refundTypes: [],
            reasonSelect: "",
            typeSelect: "",
            refundDetails: ""
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedItems !== this.props.selectedItems && this.props.currentStage === this.stageNumber) {
            this.loadRefundComponent()
        }
    }

    loadRefundComponent = () => {
        this.loadRefundReasons()
        this.loadRefundTypes()
    }

    loadRefundReasons = async () => {
        this.setState({ isLoading: true })
        let response = await this.getRefundReasons()
        if (response && response.success) {
            this.setState({
                isLoading: false,
                refundReasons: response.refund_reasons || [],
                status: false,
            })
        } else {
            let message = (response && response.message) ? response.message : "Não foi possível carregar os motivos para reembolso"
            this.setState({
                isLoading: false,
                refundReasons: [],
                status: {
                    success: false,
                    message: message
                }
            })
        }
    }

    getRefundReasons = async () => {
        let response = false
        try {
            //let response = await api.get("/order/reverse/refund_reasons")
            response = this.getRefundReasonMockups()
            if (response && response.data.message) {
                response = response.data
            }
        } catch (error) {
            console.log(error)
        }
        return response
    }

    getRefundReasonMockups = () => {
        return {
            data: {
                "success": true,
                "message": "Motivos para reembolso consultados com sucesso",
                "refund_reasons": [
                    {
                        "id": "dont_like",
                        "description": "Não gostei da compra que fiz"
                    },
                    {
                        "id": "missing_items",
                        "description": "A compra não veio com os itens que pedi"
                    },
                    {
                        "id": "others",
                        "description": "Outros motivos"
                    },
                ]
            }
        }
    }

    loadRefundTypes = async () => {
        this.setState({ isLoading: true })
        let response = await this.getRefundTypes()
        if (response && response.success) {
            this.setState({
                isLoading: false,
                refundTypes: response.refund_types || [],
                status: false,
            })
        } else {
            let message = (response && response.message) ? response.message : "Não foi possível carregar os tipos de reembolso"
            this.setState({
                isLoading: false,
                refundTypes: [],
                status: {
                    success: false,
                    message: message
                }
            })
        }
    }

    getRefundTypes = async () => {
        let response = false
        try {
            //let response = await api.get("/order/reverse/refund_types")
            response = this.getRefundTypesMockup()
            if (response && response.data.message) {
                response = response.data
            }
        } catch (error) {
            console.log(error)
        }
        return response
    }

    getRefundTypesMockup = () => {
        return {
            data: {
                "success": true,
                "message": "Motivos para reembolso consultados com sucesso",
                "refund_types": [
                    {
                        "id": "credit",
                        "description": "Reembolso por crédito"
                    },
                    {
                        "id": "another_product",
                        "description": "Reembolso por outro produto"
                    }
                ]
            }
        }
    }

    handleRefundSubmit = (e) => {
        e.preventDefault()
        if (!this.state.reasonSelect || !this.state.typeSelect || !this.state.refundDetails) return
        this.props.checkStageIsCorrect(this.stageNumber)
        this.props.handleGoToNextStage(this.stageNumber + 1, {
            refundGeneralOptions: {
                refund_reason: this.state.reasonSelect,
                refund_type: this.state.typeSelect,
                refund_details: this.state.refundDetails
            }
        })
    }

    render = () =>
        this.props.currentStage === this.stageNumber &&

        <Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="box p-3">
                        <h3>Reembolso</h3>
                        <hr />
                        <Status status={this.state.status} />
                        <div className="row">
                            <div className="col-12">
                                <form onSubmit={this.handleRefundSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="refund_reason">Motivo para reembolso</label>
                                        <select
                                            required
                                            className="form-control cool-select"
                                            id="refund_reason"
                                            value={this.state.reasonSelect}
                                            onChange={(e) => this.setState({ reasonSelect: e.target.value })}
                                        >
                                            <option value="">Selecione o motivo para reembolso</option>
                                            {(this.state.refundReasons && this.state.refundReasons.length > 0 && this.state.refundReasons.map((reason, index) =>
                                                <option key={`reason_${reason.id + index}`} value={reason.id}>{reason.description}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="refund_type">Tipo de Reembolso</label>
                                        <select
                                            required
                                            className="form-control cool-select"
                                            id="refund_type"
                                            value={this.state.typeSelect}
                                            onChange={(e) => this.setState({ typeSelect: e.target.value })}
                                        >
                                            <option value="">Selecione o tipo de reembolso</option>
                                            {(this.state.refundTypes && this.state.refundTypes.length > 0 && this.state.refundTypes.map((type, index) =>
                                                <option key={`type_${type.id + index}`} value={type.id}>{type.description}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="refund_details">Mais detalhes sobre o reembolso</label>
                                        <textarea
                                            id="refund_details"
                                            required
                                            className="form-control"
                                            style={{ resize: "none", height: "10vh" }}
                                            placeholder="Escreva mais detalhes sobre o reembolso"
                                            value={this.state.refundDetails}
                                            onChange={e => this.setState({ refundDetails: e.target.value })}
                                        ></textarea>
                                    </div>
                                    <div className="form-group">
                                        <button className="btn btn-cursor-pointer btn-fw blue btn-rounded btn-shadow ml-2" type="submit">
                                            <i className="fa fa-check"></i>&nbsp;
                                            Avançar
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
}
