import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";

import $ from "jquery";

import Modal from "react-bootstrap/Modal";

import MarketingRDStation from "./Marketing/MarketingRDStation";

class IntegrationMarketingTools extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			marketingToolsTitle: false,
			marketingToolsActive: false,
			code: null,
		};
		this.handleClose = this.handleClose.bind(this);
		this.handleShow = this.handleShow.bind(this);

		this.renderMarketingTools = this.renderMarketingTools.bind(this);
		this.translate = this.props.translate;
	}

	handleClose() {
		this.setState({ show: false, marketingToolsActive: false });
	}
	handleShow(e) {
		this.setState({
			show: true,
			marketingToolsActive: e.target.value,
			marketingToolsTitle: e.target.title,
		});
	}

	toggleCollapse = (selector) => $(selector).collapse("toggle");

	renderMarketingTools(marketingTools) {
		if (marketingTools) {
			let marketingToolsComponent = null;
			switch (marketingTools) {
				case "marketing_rd_station":
					marketingToolsComponent = (
						<MarketingRDStation
							toggleCollapse={this.toggleCollapse}
							translate={this.translate}
						/>
					);
					break;
				default:
					marketingToolsComponent = false;
					break;
			}
			if (marketingToolsComponent) {
				return marketingToolsComponent;
			}
		}
	}

	render() {
		return (
			<Fragment>
				<div className="row pb40">
					<div className="col-sm-12 col-md-12 col-lg-12">
						<h4 className="text-center mt20 mb20">
							<i className="fa fa-plus-square-o"></i> Ferramentas
							de Marketing
						</h4>
					</div>

					<div className="col-sm-12 col-md-4 col-lg-4">
						<div className="text-center box-shadow border-radius red">
							<div className="p-4 ">
								<p className="f64 m0 text-white">
									<i className="fa fa-plus-square-o"></i>
								</p>
								<h5 className="text-md f24 d-block f600 fwhite mb20">
									RD Station Marketing
								</h5>
								<button
									onClick={this.handleShow}
									title="RD Station Marketing"
									value="marketing_rd_station"
									className="btn btn-info info btn-rounded text-white btn-shadow"
								>
									<i className="fa fa-cogs"></i> Configurar RD
									Station Marketing
								</button>
								<br />
								<br />
							</div>
						</div>
					</div>
				</div>

				<Modal
					show={this.state.show}
					onHide={this.handleClose}
					dialogAs={"div"}
					dialogClassName="modal-dialog modal-bottom white b-t"
				>
					<Modal.Body>
						<div className="d-flex flex-column h-100">
							<div className="p-3 b-b d-flex no-shrink blue-grey text-center position10">
								<h5 className="h5 m-0 text-center width100 line-height-38px">
									<i className="fa fa-clone"></i>{" "}
									{this.state.marketingToolsTitle}
								</h5>
								<button
									onClick={this.handleClose}
									className="btn btn-transparent ml-auto"
								>
									<span className="text-lg l-h-1x">×</span>
								</button>
							</div>
							<React.Fragment>
								{this.renderMarketingTools(
									this.state.marketingToolsActive
								)}
							</React.Fragment>
						</div>
					</Modal.Body>
				</Modal>
			</Fragment>
		);
	}
}

export default withRouter(IntegrationMarketingTools);
