import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import { Modal } from 'react-bootstrap';

import $ from 'jquery'
import 'jquery-mask-plugin';
import jzip from 'jszip';

import "bootstrap"
import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import "datatables-buttons"
import "datatables.net-buttons"
import 'datatables.net-buttons/js/buttons.html5.min'
import 'datatables.net-responsive-bs4'

import { ajaxUrl, ajaxToken } from '../../Services/Ajax';

import MenuMain from '../../Structure/MenuMain';
import HeaderMain from '../../Structure/HeaderMain';

import api, { setDownload } from "../../Services/Api"

window.JSZip = jzip;

class ReportsAttendance extends Component {

    constructor(props) {
        super(props)

        this.translate = this.props.translate
        document.title = `Relatório de Atendimentos`

        this.state = {
            reportsUrl: "/report/attendances",
            tableLoadReportAttendances: null,
            storesSelectList: [],
            deliverymanSelectList: [],
            deliveryTypesSelectList: [],
            deliveryStatusSelectList: [],
            isLoadingSearch: true,
            formReportsAttendanceAdvancedSearch: {
                search_report: null,
                status: null,
                date_start: null,
                date_end: null,
                store: null,
                deliveryman: null,
                delivery_type: null,
                delivery_status: null
            }
        }

    }

    showIsLoadingSearch = () => {
        this.setState({ isLoadingSearch: true })
    }

    closeIsLoadingSearch = () => {
        this.setState({ isLoadingSearch: false })
    }

    handleSubmitAdvancedSearch = (e, clear = false) => {

        e.preventDefault()

        let { formReportsAttendanceAdvancedSearch } = this.state

        if (clear) {
            formReportsAttendanceAdvancedSearch = {
                search_report: null,
                status: null,
                date_start: null,
                date_end: null,
                store: null,
                deliveryman: null,
                delivery_type: null
            }
        }

        let urlSearch = this.state.reportsUrl.split("?")[0]

        let i = 0
        for (let [key, value] of Object.entries(formReportsAttendanceAdvancedSearch)) {
            if (value) {
                urlSearch += i === 0 ? "?" : "&"
                urlSearch += `${key}=${value}`
                i++
            }
        }

        urlSearch = encodeURI(urlSearch)

        this.setState({ isLoadingSearch: true, reportsUrl: urlSearch }, this.reloadDatatable)

    }

    reloadDatatable() {
        $('#lista_reports').DataTable().ajax.url(ajaxUrl(this.state.reportsUrl)).load();
    }

    initExportXlsx = async () => {

        this.showIsLoadingSearch()

        let query = $('#lista_reports').DataTable().ajax.json();

        setDownload()

        await api.post(`/report/attendance/export/xlsx`, query)
            .then((response) => {
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
                const link = document.createElement('a');
                const href = window.URL.createObjectURL(blob);
                link.href = href;
                link.download = `download_${new Date().toLocaleDateString().replace(/\//g, '-')}_${new Date().toLocaleTimeString().replace(/\:/g, '_')}.xlsx`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(href)
            })
            .catch((error) => console.log(error));

        this.closeIsLoadingSearch()
    }

    loadUsers = async () => {
        await api.get("/attendance/users")
            .then(response => response.data)
            .then(data => {
                this.setState(
                    {
                        deliverymanSelectList: data.users
                    }
                )
            })
    }

    loadStores = async () => {

        try {

            await api.get("/report/attendance/stores")
                .then(response => response.data)
                .then(data => {
                    this.setState(
                        {
                            storesSelectList: data.stores
                        }
                    )
                })
                .catch((error) => {
                    console.log(error)
                })

        } catch (error) {
            console.log(error)
        }

    }

    loadDeliveryTypes = async () => {

        try {

            await api.get("/report/attendance/delivery/types")
                .then(response => response.data)
                .then(data => {
                    this.setState(
                        {
                            deliveryTypesSelectList: data.types
                        }
                    )
                })
                .catch((error) => {
                    console.log(error)
                })

        } catch (error) {
            console.log(error)
        }

    }

    loadDeliveryStatus = async () => {

        try {

            await api.get("/report/attendance/delivery/status")
                .then(response => response.data)
                .then(data => {
                    this.setState(
                        {
                            deliveryStatusSelectList: data.status
                        }
                    )
                })
                .catch((error) => {
                    console.log(error)
                })

        } catch (error) {
            console.log(error)
        }

    }

    componentDidMount = () => {

        let tableLoadReportAttendances = this.state.tableLoadReportAttendances

        $(document).ready(() => {

            tableLoadReportAttendances = $('#lista_reports').DataTable({
                'processing': false,
                'serverSide': true,
                'serverMethod': 'get',
                "searching": true,
                "paging": true,
                "info": false,
                "responsive": true,
                "language": {
                    "url": this.translate('DATATABLE_LANGUAGE_URL')
                },
                "ajax": {
                    "url": ajaxUrl(this.state.reportsUrl),
                    "beforeSend": function (request) {
                        request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                    }
                },
                'columns': [
                    { data: 'id', orderable: true },
                    { data: 'idOrder', orderable: false },
                    { data: 'sub_orders', orderable: false },
                    { data: 'name', orderable: false },
                    { data: 'stores', orderable: false },
                    { data: 'delivery_type', orderable: false },
                    { data: 'delivery_status', orderable: false },
                    { data: 'deliveryman', orderable: false },
                    { data: 'order_status', orderable: false },
                    { data: 'date', orderable: true },
                    { data: 'more_info', orderable: false },
                ],
                'order': [
                    [0, 'asc']
                ],
                drawCallback: () => {
                    this.closeIsLoadingSearch()
                }
            });

            $('#length_change_report').on('keyup change', function (e) {
                this.showIsLoadingSearch()
                tableLoadReportAttendances.page.len($(e.target).val()).draw();
            }.bind(this));

            var SPMaskBehavior = function (val) {
                return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
            },
                spOptions = {
                    onComplete: function (val) {
                        var phone = $('#advanced_search_phone').val();
                        this.setState({ formReportsAttendanceAdvancedSearch: { ...this.state.formReportsAttendanceAdvancedSearch, phone: phone } })
                    }.bind(this),
                    onKeyPress: function (val, e, field, options) {
                        field.mask(SPMaskBehavior.apply({}, arguments), options);
                    }
                };

            $('#advanced_search_phone').mask(SPMaskBehavior, spOptions);

        })

        this.loadUsers()
        this.loadStores()
        this.loadDeliveryTypes()
        this.loadDeliveryStatus()

    }

    render() {
        return (
            <Fragment>
                <MenuMain addClass="folded" />
                <div id="content" className="app-content box-shadow-0 dark" style={{ overflowX: "hidden" }} role="main">
                    <HeaderMain title={`Relatório de Atendimentos`} onChange={this.handleChange} />
                    <div className="content-main row row-no-margin d-flex flex-grow-1" id="content-main">
                        <div className="col-12 mx-auto px-sm-4 mt-3" id={`report_attendance`}>

                            <div className={`row p-3 white box-shadow`}>

                                <h5 className="fwhite">Filtros de Atendimento</h5>

                                <form onSubmit={this.handleSubmitAdvancedSearch} className={`w-100`} id="search_report_adv">

                                    <div className="form-row">

                                        <div className="form-group col-12 col-md">

                                            <label className="col-form-label text-left p0 mb-1">
                                                {`Busca por nome, e-mail, telefone, numero de pedido`}
                                            </label>
                                            <input type="text" onChange={e => this.setState({ formReportsAttendanceAdvancedSearch: { ...this.state.formReportsAttendanceAdvancedSearch, search_report: e.target.value } })} defaultValue={this.state.formReportsAttendanceAdvancedSearch.search_report} className="form-control box-shadow" placeholder={`Procure um Atendimetno`} />

                                        </div>

                                        <div className="form-group col-12 col-md">
                                            <label className="col-form-label text-left p0 mb-1">
                                                {`Lojista`}
                                            </label>
                                            <select className="form-control cool-select" onChange={e => this.setState({ formReportsAttendanceAdvancedSearch: { ...this.state.formReportsAttendanceAdvancedSearch, store: e.target.value } })} defaultValue={this.state.formReportsAttendanceAdvancedSearch.store}>
                                                <option value="">Seleciona o Lojista</option>
                                                {
                                                    this.state.storesSelectList && (
                                                        <Fragment>
                                                            {
                                                                this.state.storesSelectList.map((store, index) =>
                                                                    <option key={index} value={`${store.id}`}>{`${store.name}`}</option>
                                                                )
                                                            }
                                                        </Fragment>
                                                    )
                                                }
                                            </select>
                                        </div>

                                        <div className="form-group col-12 col-md">
                                            <label className="col-form-label text-left p0 mb-1">
                                                {`Tipo de Entrega`}
                                            </label>
                                            <select className="form-control cool-select" onChange={e => this.setState({ formReportsAttendanceAdvancedSearch: { ...this.state.formReportsAttendanceAdvancedSearch, delivery_type: e.target.value } })} defaultValue={this.state.formReportsAttendanceAdvancedSearch.delivery_type} >
                                                <option value="">Seleciona o tipo</option>
                                                {
                                                    this.state.deliveryTypesSelectList && (
                                                        <Fragment>
                                                            {
                                                                this.state.deliveryTypesSelectList.map((type, index) =>
                                                                    <option key={index} value={`${type.id}`}>{`${type.name}`}</option>
                                                                )
                                                            }
                                                        </Fragment>
                                                    )
                                                }
                                            </select>
                                        </div>

                                        <div className="form-group col-12 col-md">
                                            <label className="col-form-label text-left p0 mb-1">
                                                {`Status da Entrega`}
                                            </label>
                                            <select className="form-control cool-select" onChange={e => this.setState({ formReportsAttendanceAdvancedSearch: { ...this.state.formReportsAttendanceAdvancedSearch, delivery_status: e.target.value } })} defaultValue={this.state.formReportsAttendanceAdvancedSearch.delivery_status}>
                                                <option value="">Seleciona o status</option>
                                                {
                                                    this.state.deliveryStatusSelectList && (
                                                        <Fragment>
                                                            {
                                                                this.state.deliveryStatusSelectList.map((type, index) =>
                                                                    <option key={index} value={`${type.id}`}>{`${type.name}`}</option>
                                                                )
                                                            }
                                                        </Fragment>
                                                    )
                                                }
                                            </select>
                                        </div>

                                        <hr className="d-block w-100 mb10 mt10" />

                                    </div>

                                    <div className="mb-3">

                                        <div className="form-row">

                                            <div className="form-group col-12 col-md">
                                                <label className="col-form-label text-left p0 mb-1">
                                                    {`Entregador`}
                                                </label>
                                                <select className="form-control cool-select" onChange={e => this.setState({ formReportsAttendanceAdvancedSearch: { ...this.state.formReportsAttendanceAdvancedSearch, deliveryman: e.target.value } })} defaultValue={this.state.formReportsAttendanceAdvancedSearch.deliveryman}>
                                                    <option value="">Seleciona o Entregador</option>
                                                    {
                                                        this.state.deliverymanSelectList && (
                                                            <Fragment>
                                                                {
                                                                    this.state.deliverymanSelectList.map((deliveryman, index) =>
                                                                        <option key={index} value={`${deliveryman.id}`}>{`${deliveryman.name}`}</option>
                                                                    )
                                                                }
                                                            </Fragment>
                                                        )
                                                    }
                                                </select>
                                            </div>

                                            <div className="form-group col-12 col-md">
                                                <label className="col-form-label text-left p0 mb-1">
                                                    {`Status do Pedido`}
                                                </label>
                                                <select className="form-control cool-select" onChange={e => this.setState({ formReportsAttendanceAdvancedSearch: { ...this.state.formReportsAttendanceAdvancedSearch, status: e.target.value } })} defaultValue={this.state.formReportsAttendanceAdvancedSearch.status}>
                                                    <option value="">Seleciona o Status</option>
                                                    <option value="payment-pending">payment-pending</option>
                                                    <option value="invoiced">invoiced</option>
                                                    <option value="payment-approved">payment-approved</option>
                                                    <option value="canceled">canceled</option>
                                                </select>
                                            </div>

                                            <div className="form-group col-12 col-md">
                                                <label className="m-0 pr-1" htmlFor="advanced_search_date_start">{`De:`}</label>
                                                <input type="datetime-local" pattern="[0-9]/[0-9]{2}/[0-9]{4}T[0-9]{2}:[0-9]{2}" id="advanced_search_date_start" className="form-control cool-input" data-plugin="datepicker" defaultValue={this.state.formReportsAttendanceAdvancedSearch.date_start} onChange={e => this.setState({ formReportsAttendanceAdvancedSearch: { ...this.state.formReportsAttendanceAdvancedSearch, date_start: e.target.value } })} />
                                            </div>
                                            <div className="form-group col-12 col-md">
                                                <label className="m-0 px-1" htmlFor="advanced_search_date_end">{`Até:`}</label>
                                                <input type="datetime-local" pattern="[0-9]/[0-9]{2}/[0-9]{4}T[0-9]{2}:[0-9]{2}" id="advanced_search_date_end" className="form-control cool-input" data-plugin="datepicker" defaultValue={this.state.formReportsAttendanceAdvancedSearch.date_end} onChange={e => this.setState({ formReportsAttendanceAdvancedSearch: { ...this.state.formReportsAttendanceAdvancedSearch, date_end: e.target.value } })} />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="form-row">

                                        <div className={`col`}>
                                            <label>&nbsp;&nbsp;{`Atendimentos por pagina`}</label>
                                            <select id="length_change_report" className="form-control cool-select" style={{ width: '200px' }}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="-1">Todos</option>
                                            </select>
                                        </div>

                                        <div className={`col d-flex align-self-center justify-content-end align-items-center h-100`}>
                                            <button type="button" className="btn btn-danger btn-rounded btn-shadow btn-cursor-pointer" onClick={e => this.handleSubmitAdvancedSearch(e, true)}><i className="fa fa-trash"></i> {`Limpar`} </button>
                                            <button type="submit" className="btn btn-success btn-rounded btn-shadow ml-2 btn-cursor-pointer"><i className="fa fa-search"></i> {`Buscar`}</button>
                                        </div>

                                    </div>

                                </form>

                            </div>

                            <hr className="d-block w-100 mb10 mt10"></hr>

                            {/* <div className="row mt20 mb20">

                                <div className="col-12 col-md-6">
                                    <label className="">&nbsp;&nbsp;{`Atendimentos por pagina`}</label><br />
                                    <select id="length_change_report" className="custom-select w-sm d-inline v-middle btn-shadow">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="-1">Todos</option>
                                    </select>
                                </div>

                            </div>

                            <hr className="d-block w-100 mb10 mt10"></hr> */}

                            <div className="row d-flex justify-content-end">
                                <button type={`button`} onClick={e => this.initExportXlsx()} className="dt-button buttons-excel buttons-html5 btn btn-default btn-outline-success m-2" tabIndex="0" aria-controls="lista_reports">
                                    <span>
                                        <i className="fa fa-file-excel-o" aria-hidden="true"></i> Exportar Relatório em Excel
                                    </span>
                                </button>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="table-responsive" style={{ width: "93vw", overflowY: "hidden" }}>
                                        <table id="lista_reports" className="table table-striped table-bordered dt-responsive nowrap">
                                            <thead>
                                                <tr className="text-left">
                                                    <th>{`Nº`}</th>
                                                    <th>{`Numero do Pedido`}</th>
                                                    <th>{`Sub Pedidos`}</th>
                                                    <th>{`Nome`}</th>
                                                    <th>{`Logista`}</th>
                                                    <th>{`Tipo Entrega`}</th>
                                                    <th>{`Status Entrega`}</th>
                                                    <th>{`Entregador`}</th>
                                                    <th>{`Status Pedido`}</th>
                                                    <th>{`Data`}</th>
                                                    <th>{`Mais info`}</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <Modal show={this.state.isLoadingSearch} onHide={this.closeIsLoadingSearch} backdrop={`static`} keyboard={false} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink warning text-center position10">
                                <h5 className="h5 m-0 text-center width100 line-height-38px">
                                    <i className="fa fa-sync fa-spin"></i> {`Carregando...`}
                                </h5>
                                {/* <button onClick={this.closeIsLoadingSearch} className="btn btn-transparent ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </button> */}
                            </div>
                            <div className="p-3 b-b d-flex no-shrink text-center position10">
                                <b>Processando o Relatorio, por favor aguarde...</b>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            </Fragment>
        );
    }
}

export default withRouter(ReportsAttendance);