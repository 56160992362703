import React, { Component } from 'react';

class SummaryBusinessLeadQualification extends Component {
    constructor(props){
        super(props);
        this.cardCustom = false;
        this.props.fields.periodName = this.props.periodName
    }

    handleChange = (periodName, values) => {
        this.cardCustom = true
        this.props.fields.note = values.note
        this.props.fields.periodName = periodName
        this.forceUpdate();
    }

    render(){
        (this.cardCustom === false)?(
            this.props.fields.periodName = this.props.periodName
        ):(
            this.cardCustom = false
        )
        return (
            <div className="col-6 col-md-6 col-lg-3">
                <div className="box p-3 box-shadow2">
                    <div className="d-flex">
                        <span className="text-muted">Qualificação do lead</span>
                    </div>
                    <div className="py-3 text-center text-lg text-danger lh" data-toggle="modal" data-target="#m_landings">
                        <a href="/" onClick={(e) => {e.preventDefault()}}>
                            <span className="f36">{this.props.fields.note}</span>
                        </a>            
                    </div>
                </div>
            </div>
        );
    }
}

export default SummaryBusinessLeadQualification;