import React, {Component} from "react"

import $ from "jquery"
import Modal from 'react-bootstrap/Modal';
import api from '../../../Services/Api';

export default class AttendanceOptions extends Component{
    constructor(props){
        super(props)
        this.state = {
            quickMessages: [],
            isLoadingQuickMessages: false,
            isConfirmModalVisible: false,
            confirmModalMessage: "",
        }
        this.confirmModalCallback = null
        this.translate = this.props.translate
    }

    loadQuickMessages = async () => {
        this.setState({isLoadingQuickMessages: true})
        try {
            //let response = await api.get(`/attendance/get/quickMessages/${this.props.idAttendance}`)
           let response = {
                data: {
                    success: true, 
                    quickMessages: []/* [
                        {id: 1, message: "Olá, tudo bem? Como posso ajudar?"},
                        {id: 2, message: "Por favor, digite o seu endereço e os meios para contato."},
                    ]  */
                }
            } 
            if(response && response.data && response.data.quickMessages && response.data.quickMessages.length){
                this.setState({quickMessages: response.data.quickMessages})
            }
        } catch (error) {
            console.log(error)
        }
        this.setState({isLoadingQuickMessages: false})
    }

    sendQuickMessage = message => {
        this.confirmModalCallback = () => {
            this.props.handleMessageSubmit(message, "clientHistory")
            this.setState({confirmModalMessage: "", isConfirmModalVisible: false})
        }
        this.setState({confirmModalMessage: `${this.translate("ATTENDANCE_OPTIONS_DO_YOU_WANT_TO_SEND")}: "${message}"`, isConfirmModalVisible: true})
    }

    handleCloseConfirmModal = (e = null) => {
        if (e) e.preventDefault()
        this.setState({isConfirmModalVisible: false})
    }

    componentDidMount = () => {
        this.loadQuickMessages()
    }

    render = () => (
        <React.Fragment>

            <div className="col">
                <div className="row">
                    <div className="col">
                        <div className="row">
                            <div className="col attendance_main_tab">
                                <span className="main_tab_title">{this.props.translate("ATTENDANCE_OPTIONS_OPTIONS")}</span>
                            </div>
                        </div>
                        <div className="row p-0">
                            <div id="accordion_attendance_quick_message" className="accordion col px-0 my-2">
                                    <div className="card bg-transparent">
                                        <div className="card-header btn-cursor-pointer bg-transparent" id={`attendance_quick_messages`} onClick={()=>this.props.toggleAccordionPannel(`#collapse_attendance_quick_messages`)} aria-expanded="true" aria-controls={`collapse_attendance_quick_messages`}>
                                            <h6 className="mb-0 text-left collapsed">
                                                {this.props.translate("ATTENDANCE_OPTIONS_QUICK_MESSAGES")}
                                                <span className="float-right">
                                                    <i className="fa fa-caret-down"></i>
                                                </span>
                                            </h6>
                                        </div>

                                        <div id={`collapse_attendance_quick_messages`} className="collapse show" aria-labelledby={`attendance_quick_messages`} data-parent="#accordion_attendance_quick_message">
                                            <div className="card-body px-0">
                                                {(this.state.quickMessages && this.state.quickMessages.length > 0) ? 
                                                    <ul className="list-group">
                                                        {this.state.quickMessages.map(message=>
                                                            <li key={`quickMessage${message.id}`} className="text-left list-group-item d-flex align-items-center">
                                                                <span className="flex-grow-1">{message.message}</span>
                                                                <button className="btn btn-outline-primary pl-2 btn-cursor-pointer" style={{maxWidth: 54, height: 36}} onClick={()=>this.sendQuickMessage(message.message)}>
                                                                    <i className="fa fa-paper-plane-o text-white"></i>
                                                                </button>
                                                            </li>
                                                        )}
                                                    </ul>
                                                :
                                                    this.state.isLoadingQuickMessages ?
                                                        <div><i className="fa fa-spinner fa-spin" /> {this.props.translate('ICON_LOADING')}</div>
                                                :
                                                        <p className="my-2 mx-auto text-left px-4">{this.props.translate("ATTENDANCE_OPTIONS_NO_QUICK_MESSAGES")}</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={this.state.isConfirmModalVisible} onHide={this.handleCloseConfirmModal} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                <Modal.Body>
                    <div className="d-flex flex-column h-100 bg-blur_w2">
                        <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                            <h5 className="h5 m-0 text-center width100">
                            <i className="fa fa-check"></i> {this.translate("MODAL_CONFIRM_TITLE")}</h5>
                            <a href="/" onClick={e=>this.handleCloseConfirmModal(e)} className="ml-auto">
                                <span className="text-lg l-h-1x">×</span>
                            </a>
                        </div>
                        <div className="row position10 padding-20px">
                            <div className="col-12">
                                <h3 className="py-2 text-center">{this.state.confirmModalMessage}</h3>
                            </div>
                            <div className="col-4 mx-auto">
                                <button className="btn btn-danger btn-cursor-pointer" style={{ width: '100%' }} onClick={e=>this.handleCloseConfirmModal(e)}>
                                    {this.translate("MODAL_CONFIRM_NO")}
                                </button>
                            </div>
                            <div className="col-4 mx-auto">
                                <button className="btn btn-success btn-cursor-pointer" style={{ width: '100%' }} onClick={(e)=>this.confirmModalCallback()}>
                                    {this.translate("MODAL_CONFIRM_YES")}
                                </button>

                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}