import React, { Component } from 'react';

export class EcommerceLastSalesGeneratedList extends Component {

    handleChange = (period, periodName) => {
        console.log(period, periodName);
    }
    
    render() {
        return (
            <div className="col-md-6">
                <div className="padding bg-blur_w3 position10">
                    <span className="badge success float-right">{this.props.fields.count_leads}</span>
                    <h6 className="mb-3"><i className="fa fa-users"></i> Últimas Vendas geradas</h6>
                    <div className="streamline streamline-dotted" id="countLastLeads">
                        {
                            this.props.fields.values.map(
                                (value, i) => 
                                    <div key={i} className="sl-item  b- ">
                                        <div className="sl-content">
                                            <span className="sl-date text-muted">{value.hourly}</span>
                                            <div>
                                                <a href="/" className="text-primary">{value.name_lead}</a>
                                                &nbsp;mostrou interesse em {value.business}
                                            </div>
                                        </div>
                                    </div>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default EcommerceLastSalesGeneratedList;