import React, { Component } from 'react';

class ChannelsDivisorTitle extends Component {
    render(){
        return (
            <div className="col-sm-12 col-md-12 col-lg-12">
                <button className="btn btn-fw dark text-center w-100 box mt20 mb10">{this.props.translate('DASHBOARD_ATTENDANCES_CARD_CHANNELS')}:</button>
            </div>
        );
    }
}

export default ChannelsDivisorTitle;