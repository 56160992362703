import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'

import api from '../../../Services/Api';

import Modal from 'react-bootstrap/Modal';

class BusinessSocialNetworks extends Component {

    constructor(props){
        super(props)
        this.state = {
            modalResponseShow: false,
            msgResponseShow: false,
            formSocialNetworks: {
                facebook: null,
                twitter: null,
                instagram: null,
                linkedin : null,
                pinterest : null,
                youtube : null,
            }
        }
        this.closeModalResponse =  this.closeModalResponse.bind(this)
        this.formSubmitSocialNetworks = this.formSubmitSocialNetworks.bind(this)
        this.translate = this.props.translate
    }

    closeModalResponse(e = null){
        if (e) e.preventDefault()
        this.setState({
            modalResponseShow: false
        })
    }

    /* componentDidMount = async () => {
        await api.get("/business/" + this.props.idBusiness + "/social_networks/get").then(async response => {
            this.setState({
                formSocialNetworks: response.data.social_networks_data
            })
        });
    } */

    componentDidMount = async () => {
        await api.get("/business/" + this.props.idBusiness + "/social_networks/get").then(async response => {
            this.setState({
                formSocialNetworks: {...this.state.formSocialNetworks, ...response.data.social_networks_data}
            }) 
        });
    }

    async formSubmitSocialNetworks(e){
        e.preventDefault();
        this.setState({ msgResponseShow: this.translate("BUSINESS_SOCIAL_NETWORKS_WAIT_STATUS"), modalResponseShow: true });
        const data = this.state.formSocialNetworks;
        try{
            const response = await api.post("/business/" + this.props.idBusiness + "/social_networks/register", data);
            if(response.data.success === true){
                this.setState({msgResponseShow: this.translate("BUSINESS_SOCIAL_NETWORKS_SUCCESS_STATUS")});
            }else{
                this.setState({msgResponseShow: response.data.msg});
            }
        }catch(err){
            this.setState({msgResponseShow: err});
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <form onSubmit={this.formSubmitSocialNetworks}>
                            <div className="row p-3">
                                <div className="col-sm-12 col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                            Facebook
                                        </label>
                                        <input type="text" onChange={e => this.setState({ formSocialNetworks: {...this.state.formSocialNetworks, facebook:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("BUSINESS_SOCIAL_FACEBOOK_PLACEHOLDER")} defaultValue={this.state.formSocialNetworks.facebook} required/>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                            Instagram
                                        </label>
                                        <input type="text" onChange={e => this.setState({ formSocialNetworks: {...this.state.formSocialNetworks, instagram:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("BUSINESS_SOCIAL_INSTAGRAM_PLACEHOLDER")} defaultValue={this.state.formSocialNetworks.instagram} required/>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                            Twitter
                                        </label>
                                        <input type="text" onChange={e => this.setState({ formSocialNetworks: {...this.state.formSocialNetworks, twitter:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("BUSINESS_SOCIAL_TWITTER_PLACEHOLDER")} defaultValue={this.state.formSocialNetworks.twitter} required/>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                            LinkedIn
                                        </label>
                                        <input type="text" onChange={e => this.setState({ formSocialNetworks: {...this.state.formSocialNetworks, linkedin:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("BUSINESS_SOCIAL_LINKEDIN_PLACEHOLDER")} defaultValue={this.state.formSocialNetworks.linkedin} required/>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                            Pinterest
                                        </label>
                                        <input type="text" onChange={e => this.setState({ formSocialNetworks: {...this.state.formSocialNetworks, pinterest:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("BUSINESS_SOCIAL_PINTEREST_PLACEHOLDER")} defaultValue={this.state.formSocialNetworks.pinterest} required/>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                            Youtube
                                        </label>
                                        <input type="text" onChange={e => this.setState({ formSocialNetworks: {...this.state.formSocialNetworks, youtube:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("BUSINESS_SOCIAL_YOUTUBE_PLACEHOLDER")} defaultValue={this.state.formSocialNetworks.youtube} required/>
                                    </div>
                                </div>
                                <div className="box-divider m0 mt20 width100"></div>
                                <div className="col-sm-12 col-md-12 mt20">
                                    <button type="submit" className="btn btn-info px-5 btn-rounded btn-shadow pull-right">
                                        <i className="fa fa-save"></i> {this.translate("BUSINESS_SOCIAL_REGISTER_SOCIAL_NETWORKS")}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <Modal show={this.state.modalResponseShow} onHide={this.closeModalResponse} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-bell"></i> {this.translate("MODAL_STATUS_STATUS")}</h5>
                                <a href="/" onClick={e=>this.closeModalResponse(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                <span className="col-12 text-center py-2 lead">
                                    {this.state.msgResponseShow}
                                </span>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(BusinessSocialNetworks);