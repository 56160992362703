import React, { Component } from 'react'
import Chart from 'chart.js'
import $ from "jquery"
import {app, hexToRGB} from '../../../../Structure/Configs/Theme'

export default class PieChart extends Component{

    constructor(props){
        super(props)
        this.state = {
            chartId: `${new Date().getTime()}${Math.floor(Math.random() * 9999)}`,
            colors: [
                "#28a745",
                "#dc3545",
                "#007bff",
                "#fd7e14",
                "#6f42c1",
                "#e83e8c",
                "#ffc107",
                "#17a2b8",
                "#343a40",
                "#20c997",
                "#6610f2",
                "#868e96",
            ]
        }
    }

    getRandomColor = () => {
        var letters = '0123456789ABCDEF'.split('');
        var color = '#';
        for (var i = 0; i < 6; i++ ) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    getColors = () => {
        let {colors} = this.state
        
        while(colors.length < this.props.viewValues.values.length){
            colors.push(this.getRandomColor())
        }
        this.setState({colors})
    }

    componentDidMount = () => {
        this.getColors()

        $('[data-toggle="tooltip"]').tooltip('dispose').tooltip({placement: "bottom", trigger: "hover"})

        const myChart = document.getElementById(`chart_${this.state.chartId}`).getContext('2d')

        new Chart(myChart, {
            type: "pie",
            data: {
                labels: this.props.viewValues.label,
                datasets: [
                    {
                        type: 'pie',
                        data: this.props.viewValues.values,
                        fill: true,
                        backgroundColor: this.state.colors,
                        borderColor: hexToRGB(app.color.primary, 1),
                        borderWidth: 2,
                        borderJoinStyle: 'miter',
                        pointBorderColor: hexToRGB(app.color.primary, 1),
                        pointBackgroundColor: '#fff',
                        pointBorderWidth: 2,
                        pointHoverRadius: 2,
                        pointHoverBackgroundColor: hexToRGB(app.color.primary, 1),
                        pointHoverBorderColor: '#fff',
                        pointHoverBorderWidth: 1,
                        pointRadius: 3
                    }
                ]
            },
            
        })
        
    }  

    render() {
        return (
            
            <div className="col-12">
                <div className="padding bg-blur_w no_after_pointer_events position10">
                    <div className="d-flex">
                        <p className="uppercase text-muted f10">{this.props.viewName}</p>
                    </div>
                        <canvas id={`chart_${this.state.chartId}`} width={624} height={300} className="chartjs-render-monitor m-auto"/>
                </div>
            </div>
        )
    }
    
}


