import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';

import $ from 'jquery';

import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4'

import {ajaxUrl, ajaxToken} from '../../../Services/Ajax';

class CompanyList extends Component {

    constructor(props){
        super(props);
        this.state = {
            tableLoadCompany: null,
        }
        this.translate = this.props.translate
    }
    
    componentDidMount = async () => {
        let tableLoadCompany = this.state.tableLoadCompany
        console.log(this.props);
        const props = this.props
        tableLoadCompany = $('#list_company').DataTable({
            "searching": true,
            "paging": true,
            "info": false,
            "responsive": true,
            "language": {
                "url": this.translate("DATATABLE_LANGUAGE_URL")
            },
            "ajax": {
                "url": ajaxUrl('/companies/table'),
                "type": 'GET',
                "beforeSend": function (request) {
                    request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                }
            },
            "columnDefs": [{
                "targets": -1,
                "data": null,
                "defaultContent": '<div class="btn-group btn-shadow">\n'+
                                        `<button class="view btn btn-sm success" title="${this.translate("ADMIN_COMPANY_LIST_ACCESS_BUTTON")}">\n`+
                                            `<i class="fa fa-user-circle" title="${this.translate("TABLE_ACTIONS_ACCESS_BUTTON")}"></i> ${this.translate("TABLE_ACTIONS_ACCESS_BUTTON")}\n`+
                                        '</button>\n'+
                                    '</div>'
            }]
        });
        $('#list_company tbody').on( 'click', '.view', function (event){
            event.preventDefault();
            let data = [];
            if($(this).parents('tr').hasClass('child')){
                data = tableLoadCompany.row($(this).parents('tr').prev('.parent')).data();
            }else{
                data = tableLoadCompany.row($(this).parents('tr')).data();
            }
            
            props.history.push('/admin_business#' + data[2])
            
        });
    }

    render() {
        return (
            <React.Fragment>
                <h5 className="fwhite">{this.translate("ADMIN_COMPANY_LIST_LIST_OF_COMPANIES")}</h5>
                <div className="bootstrap-table">
                    <div className="fixed-table-container">
                        <div className="fixed-table-body min-height100">
                            <table id="list_company" className="table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                                <thead>
                                    <tr className="text-left">
                                        <th>{this.translate("ADMIN_COMPANY_LIST_NAME_OF_COMPANY")}</th>
                                        <th>{this.translate("ADMIN_COMPANY_LIST_NUMBER_OF_BUSINESS")}</th>
                                        <th>{this.translate("TABLE_ACTIONS_ROW")}</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(CompanyList);