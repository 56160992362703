import React, { Component } from 'react'
import $ from "jquery"

import ViewBuilder from "../ViewBuilder"

export default class EditMetricViews extends Component{
    constructor(props){
        super(props)
        this.state = {
            viewValues: null,
            viewName: null,
            viewCode: null
        }
        this.translate = this.props.translate
    }
    
    handleViewChangeClick = (e, view) => {
        e.preventDefault()
        this.setState({
            viewValues: view.view_values,
            viewName: view.view_name,
            viewCode: view.view_code
        })
        this.props.handleChangeMetricTitle(view.view_name)
    }

    disposeTooltip = () => $('[data-toggle="tooltip"]').tooltip('dispose')

    componentDidMount = () => {
        $('[data-toggle="tooltip"]').tooltip('dispose').tooltip({placement: "bottom", trigger: "hover"})
    }

    componentDidUpdate = () => {
        $('[data-toggle="tooltip"]').tooltip('dispose').tooltip({placement: "bottom", trigger: "hover"})
    }


    renderView = () => {
        let {viewCode, viewName, viewValues} = this.state
        return <ViewBuilder translate={this.props.translate} viewCode={viewCode} viewName={viewName} viewValues={viewValues} />
        
        
    }
    
    render = () => {
        let {views, views_to_be_chosen, handleAddMetricView, handleRemoveMetricView, id, stageId, metric_key, isMetricToBeChosen, translate} = this.props
        return (
            <React.Fragment>
                <h3>{this.translate('DASHBOARD_PERFORMANCE_CARD_SELECTED_VIEWS')}</h3>
                <div className="row">
                    {views && views.length ?
                        <div className="col-12">
                            <div className="list-group" style={{maxHeight: 300, overflowY: "auto"}}>
                                {views.map((view, index)=> 
                                    <a key={`normal${id}${stageId}${metric_key}${view.view_code}${index}`} href="/" onClick={e=>e.preventDefault()} className={`dashboard_performance_edit_metric_view list-group-item d-flex align-items-center ${this.state.viewCode === view.view_code ? "active" : ""}`}>
                                        <span className="flex-grow-1">{view.view_name}</span>
                                        
                                        <button className="btn btn-primary btn-cursor-pointer ml-2" style={{width:35, height: 35}} onClick={e=>{this.disposeTooltip();this.handleViewChangeClick(e, view)}} data-toggle="tooltip" title={translate("DASHBOARD_PERFORMANCE_CARD_VISUALIZE_VIEW")}>
                                            <i className="fa fa-eye" />
                                        </button>
                                        <button className="btn btn-danger btn-cursor-pointer ml-2" style={{width:35, height: 35}} onClick={e=>{this.disposeTooltip();handleRemoveMetricView(view, id, stageId, metric_key, isMetricToBeChosen)}} data-toggle="tooltip" title={translate("DASHBOARD_PERFORMANCE_CARD_REMOVE_VIEW")}>
                                            <i className="fa fa-minus" />
                                        </button>
                                    </a>
                                )}
                            </div>
                            
                        </div>
                        
                    :
                        <p className="m-2 px-2">{this.translate('DASHBOARD_PERFORMANCE_CARD_THERE_ARENT_ANY_SELECTED_VIEWS')}</p>
                    }
                </div>
                <hr/>
                <h3>{this.translate('DASHBOARD_PERFORMANCE_CARD_SELECT_VIEWS')}</h3>
                <div className="row">
                    {views_to_be_chosen && views_to_be_chosen.length ?
                        <div className="col-12">
                            <div className="list-group" style={{maxHeight: 300, overflowY: "auto"}}>
                                {views_to_be_chosen.map((view, index)=> 
                                    <a key={`toBechosed${id}${stageId}${metric_key}${view.view_code}${index}`} href="/" onClick={e=>e.preventDefault()} className={`list-group-item d-flex align-items-center ${this.state.viewCode === view.view_code ? "active" : ""}`}>
                                        <span className="flex-grow-1">{view.view_name}</span>
                                        <button className="btn btn-primary btn-cursor-pointer ml-2" style={{width:35, height: 35}} onClick={e=>{this.disposeTooltip();this.handleViewChangeClick(e, view)}} data-toggle="tooltip" title={translate("DASHBOARD_PERFORMANCE_CARD_VISUALIZE_VIEW")}>
                                            <i className="fa fa-eye" />
                                        </button>
                                        <button className="btn btn-success btn-cursor-pointer ml-2" style={{width:35, height: 35}} onClick={e=>{this.disposeTooltip();handleAddMetricView(view, id, stageId, metric_key, isMetricToBeChosen)}} data-toggle="tooltip" title={translate("DASHBOARD_PERFORMANCE_CARD_ADD_VIEW")}>
                                            <i className="fa fa-plus" />
                                        </button>
                                    </a>
                                )}
                            </div>
                            
                        </div>
                        
                    :
                        <p className="m-2 px-2">{this.translate('DASHBOARD_PERFORMANCE_CARD_THERE_ARENT_ANY_VIEWS_TO_BE_SELECTED')}</p>
                    }
                </div>
                <hr/>
                <div className="row">
                    <div className="col-12 col-sm-8 mx-auto mt-2">
                        <div className="row" id="view_row">
                            {this.state.viewCode &&
                                this.renderView()
                            }
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
    
}
