import React, { Component } from 'react';

import MidiaActiveCampaignsAndListAll from './ActiveCampaignsAndListAll';
import SegmentsAndListAll from './SegmentsAndListAll';
import ActiveOriginAndListAll from './ActiveOriginAndListAll';

class SummaryMidiaActiveCampaignsAndListAllMoreSegmentsAndListAllMoreActiveOriginAndListAll extends Component {
    render() {
        return (
            <div className="col-sm-12 col-md-9 mt20">
                <div className="box p-3 box-shadow2 height320">
                    <div className="d-flex">
                        <span className="text-muted text-center w-100">Mídia</span>
                    </div>
                    <div className="row">
                        <MidiaActiveCampaignsAndListAll fields={this.props.fields} type={this.props.type}/>
                        <SegmentsAndListAll fields={this.props.fields} type={this.props.type}/>
                        <ActiveOriginAndListAll fields={this.props.fields} type={this.props.type}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default SummaryMidiaActiveCampaignsAndListAllMoreSegmentsAndListAllMoreActiveOriginAndListAll;