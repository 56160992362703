import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'

//import api from '../../../Services/Api';

import Modal from 'react-bootstrap/Modal';

class AttendanceConfigOpeningHours extends Component {

    constructor(props){
        super(props);
        this.state = {
            isModalResponseVisible: false,
            msgResponseShow: "",
            openingHours: {
                monday: {
                    isChecked: false,
                    hours: []
                },
                tuesday: {
                    isChecked: false,
                    hours: []
                },
                wednesday: {
                    isChecked: false,
                    hours: []
                },
                thursday: {
                    isChecked: false,
                    hours: []
                },
                friday: {
                    isChecked: false,
                    hours: []
                },
                saturday: {
                    isChecked: false,
                    hours: []
                },
                sunday: {
                    isChecked: false,
                    hours: []
                },
            },
            conflictantHoursIds: []
        }
        this.translate = this.props.translate
        this.index = 0
    }

    getIndex = () => ++this.index
   
    handleCloseModalResponse = (e = null) =>{
        if (e) e.preventDefault()
        this.setState({isModalResponseVisible: false})
    }

    toggleCheckOpeningHour = (weekday) => {
        let {openingHours} = this.state
        openingHours[weekday].isChecked = !openingHours[weekday].isChecked
        if(openingHours[weekday].isChecked) this.checkAndAddHourToWeekday(weekday)
        else openingHours[weekday].hours = []
        this.setState({openingHours}, this.checkConflictantHours)
    }

    checkAndAddHourToWeekday = (weekday) => {
        let {openingHours} = this.state
        if(!openingHours[weekday].hours || !openingHours[weekday].hours.length){
            this.addHours(weekday)
        }
    }

    addHours = (weekday) => {
        let {openingHours} = this.state
        if(!openingHours[weekday].hours) openingHours[weekday].hours = []
        
        openingHours[weekday].hours.push({id: `${weekday + this.getIndex()}`, start: "", end: ""})
        
        this.setState({openingHours})
    } 
   
    removeHours = (weekday, id) => {
        let {openingHours} = this.state
        if(!openingHours[weekday].hours || !openingHours[weekday].hours.length) return
        
        openingHours[weekday].hours = openingHours[weekday].hours.filter(hour=>hour.id !== id)
        
        if(openingHours[weekday].hours.length === 0){
            openingHours[weekday].isChecked = false
        }
        
        this.setState({openingHours}, this.checkConflictantHours)
    } 

    handleTimeUpdate = (e, newHour, weekday, type) => {
        let {openingHours} = this.state
        
        openingHours[weekday].hours.forEach(hour=>{
            if(hour.id === newHour.id){
                hour[type] = e.target.value
            }
        })

        this.checkConflictantHours()
        this.setState({openingHours})
    }

    checkConflictantHours = () => {
        let {openingHours} = this.state
        let conflictantHoursIds = []
        Object.values(openingHours).forEach(weekday => {
            let weekConflictantHours = this.checkWeekdayConflictantHours(weekday.hours)
            if(weekConflictantHours && Array.isArray(weekConflictantHours)) conflictantHoursIds = [...conflictantHoursIds, ...weekConflictantHours]
        })

        conflictantHoursIds = [...new Set(conflictantHoursIds)]
        console.log(conflictantHoursIds)
        this.setState({conflictantHoursIds})
    }

    checkWeekdayConflictantHours = (hours) => {
        if(!hours || !hours.length) return
        let weekConflictantHours = []

        hours.forEach((hour, index, allHours)=> {
            let {start, end, id} = hour
            if(!hour || !end) return
            
            allHours.forEach((allHoursHour, allHoursIndex)=>{
                let checkIsConflictant = false
                let allHoursStart = allHoursHour.start
                let allHoursEnd = allHoursHour.end
                let allHoursId = allHoursHour.id

                if(!allHoursStart || !allHoursEnd) return
                if(allHoursStart === start && allHoursEnd === end) return

                if(start >= allHoursStart && start <= allHoursEnd) checkIsConflictant = true
                if(end >= allHoursStart && end <= allHoursEnd) checkIsConflictant = true

                if(checkIsConflictant) weekConflictantHours.push(id, allHoursId)
            })

        })

        return weekConflictantHours
    }

    getOpeningHours = async () => {
        try {
            let response
            //let response = await api.get("")
            if(response && response.data.openingHours){
                this.setState({openingHours: response.data.openingHours})
            }
        } catch (error) {
            console.log(error)
        }
    }

    handleSubmitOpeningHours = async (e) => {
        e.preventDefault()
        if(this.checkSimiliarHoursConflict()) return

        try {
            //let response = await api.post("", {openingHours: this.state.openingHours})
            let response = {data: {success: false}}
            if(response && response.data.success){
                this.setState({msgResponseShow: this.props.translate("ATTENDANCE_CONFIG_OPENING_HOURS_OPENING_HOURS_REGISTERED_WITH_SUCCESS"), isModalResponseVisible: true})
            } else {
                this.setState({msgResponseShow: this.props.translate("ATTENDANCE_CONFIG_OPENING_HOURS_IT_WASNT_POSSIBLE_TO_REGISTER_OPENING_HOURS"), isModalResponseVisible: true})
            }
        } catch (error) {
            console.log(error)
        }
    }

    checkSimiliarHoursConflict = () => {
        let check = false
        if(this.state.conflictantHoursIds && this.state.conflictantHoursIds.length) {
            check = true
            this.setState({msgResponseShow: this.props.translate("ATTENDANCE_CONFIG_OPENING_HOURS_CANT_HAVE_SIMILAR_HOURS_IN_THE_SAME_DAY"), isModalResponseVisible: true})
        }
        return check
    }

    componentDidMount = () => {
       this.getOpeningHours()
    }


    renderHours = (weekday) => 
        (this.state.openingHours[weekday].isChecked && this.state.openingHours[weekday].hours.length > 0 &&
            <React.Fragment>
                {this.state.openingHours[weekday].hours.map((hour, index)=>
                    <div key={`hour_${weekday}${hour.id + index}`} className="form-group row my-3 mx-3">
                        <label className="d-flex mt-1 mx-2 align-items-center"><span className="font-weight-bold">{index + 1}º</span>&nbsp;</label>
                        
                        <input type="time" max={hour.end} required className={`form-control col-3 ${this.state.conflictantHoursIds.indexOf(hour.id) !== -1 ? "border border-danger" : ""}`} id={`hour_start_${weekday}_${index + hour.id}`} value={hour.start} onChange={(e)=>this.handleTimeUpdate(e, hour, weekday, "start")}/>
                        
                        <label className="d-flex mt-1 mx-2 align-items-center">{this.props.translate("ATTENDANCE_CONFIG_OPENING_HOURS_TO")}</label>
                        <input type="time" min={hour.start} required className={`form-control col-3 ${this.state.conflictantHoursIds.indexOf(hour.id) !== -1 ? "border border-danger" : ""}`} id={`hour_end_${weekday}_${index + hour.id}`} value={hour.end} onChange={(e)=>this.handleTimeUpdate(e, hour, weekday, "end")}/>
                        
                        <button type="button" className="col-1 ml-1 btn btn-danger red btn-shadow d-flex justify-content-center align-items-center btn-cursor-pointer" style={{maxWidth: 50}} onClick={()=>this.removeHours(weekday, hour.id)}>
                            <i className="fa fa-times"></i>
                        </button>
                        
                    </div>
                )}
                <button type="button" className="mb-2 mx-3 py-2 btn btn-primary blue btn-shadow d-flex justify-content-center align-items-center btn-cursor-pointer" style={{maxWidth: 50}} onClick={()=>this.addHours(weekday)}>
                    <i className="fa fa-plus"></i>
                </button>
            </React.Fragment>
        )
    

    render() {

        return (
            <React.Fragment>
                <div id="accordion_attendance_opening_hours">
                    <div className="card mb-3">
                        <div className="card-header btn-cursor-pointer dark" onClick={()=>this.props.toggleAccordionPannel(`#collapse_attendance_config_opening_hours`)} id="heading_attendance_config_opening_hours">
                            <h5 className="mb-0" data-toggle="collapse" aria-expanded="true" aria-controls="collapse_attendance_config_opening_hours">
                                {this.translate("ATTENDANCE_CONFIG_OPENING_HOURS")}
                                <i className="fa fa-caret-down pull-right"></i>
                            </h5>
                        </div>

                        <div id="collapse_attendance_config_opening_hours" className="collapse dark show" aria-labelledby="heading_attendance_config_opening_hours" data-parent="#accordion_attendance_opening_hours">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <form className="px-4" onSubmit={this.handleSubmitOpeningHours}>
                                            <div className="form-group">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="opening_hours_check_monday" checked={this.state.openingHours.monday.isChecked} onChange={()=>this.toggleCheckOpeningHour("monday")}/>
                                                    <label className="form-check-label" htmlFor="opening_hours_check_monday">
                                                        {this.props.translate("ATTENDANCE_CONFIG_OPENING_HOURS_MONDAY")}
                                                    </label>
                                                    {this.renderHours("monday")}
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="opening_hours_check_tuesday" checked={this.state.openingHours.tuesday.isChecked} onChange={()=>this.toggleCheckOpeningHour("tuesday")}/>
                                                    <label className="form-check-label" htmlFor="opening_hours_check_tuesday">
                                                        {this.props.translate("ATTENDANCE_CONFIG_OPENING_HOURS_TUESDAY")}
                                                    </label>
                                                    {this.renderHours("tuesday")}
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="opening_hours_check_wednesday" checked={this.state.openingHours.wednesday.isChecked} onChange={()=>this.toggleCheckOpeningHour("wednesday")}/>
                                                    <label className="form-check-label" htmlFor="opening_hours_check_wednesday">
                                                        {this.props.translate("ATTENDANCE_CONFIG_OPENING_HOURS_WEDNESDAY")}
                                                    </label>
                                                    {this.renderHours("wednesday")}
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="opening_hours_check_thursday" checked={this.state.openingHours.thursday.isChecked} onChange={()=>this.toggleCheckOpeningHour("thursday")}/>
                                                    <label className="form-check-label" htmlFor="opening_hours_check_thursday">
                                                        {this.props.translate("ATTENDANCE_CONFIG_OPENING_HOURS_THURSDAY")}
                                                    </label>
                                                    {this.renderHours("thursday")}
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="opening_hours_check_friday" checked={this.state.openingHours.friday.isChecked} onChange={()=>this.toggleCheckOpeningHour("friday")}/>
                                                    <label className="form-check-label" htmlFor="opening_hours_check_friday">
                                                        {this.props.translate("ATTENDANCE_CONFIG_OPENING_HOURS_FRIDAY")}
                                                    </label>
                                                    {this.renderHours("friday")}
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="opening_hours_check_saturday" checked={this.state.openingHours.saturday.isChecked} onChange={()=>this.toggleCheckOpeningHour("saturday")}/>
                                                    <label className="form-check-label" htmlFor="opening_hours_check_saturday">
                                                        {this.props.translate("ATTENDANCE_CONFIG_OPENING_HOURS_SATURDAY")}
                                                    </label>
                                                    {this.renderHours("saturday")}
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="opening_hours_check_sunday" checked={this.state.openingHours.sunday.isChecked} onChange={()=>this.toggleCheckOpeningHour("sunday")}/>
                                                    <label className="form-check-label" htmlFor="opening_hours_check_sunday">
                                                        {this.props.translate("ATTENDANCE_CONFIG_OPENING_HOURS_SUNDAY")}
                                                    </label>
                                                    {this.renderHours("sunday")}
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <button className="col-12 ml-auto btn btn-primary blue btn-shadow d-flex justify-content-center align-items-center btn-cursor-pointer" style={{maxWidth: 250}}>
                                                    <i className="fa fa-floppy-o"></i>&nbsp;{this.props.translate("ATTENDANCE_CONFIG_OPENING_HOURS_REGISTER_OPENING_HOURS")}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.isModalResponseVisible} onHide={this.handleCloseModalResponse} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-bell"></i> Status</h5>
                                <a href="/" onClick={e=>this.handleCloseModalResponse(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                <span className="col-12 text-center py-2 lead">
                                    {this.state.msgResponseShow}
                                </span>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(AttendanceConfigOpeningHours);