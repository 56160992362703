/* global google */

import React, { Component } from 'react';

import Script from 'react-load-script';

class GooglePlaces extends Component {

    handleScriptLoad = () => {
        const options = {
            types: ['address'],
        };

        this.autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('autocomplete-address-business'),
            options,
        );

        this.autocomplete.setFields(['address_components', 'formatted_address']);

        this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
    }

    handlePlaceSelect = () => {

        const addressObject = this.autocomplete.getPlace();
        const address = addressObject.address_components;

        if(address){

            this.setState({
                address: addressObject.formatted_address
            })

            let map;

            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({
                'address': addressObject.formatted_address
            }, function(results, status) {
                if (status === google.maps.GeocoderStatus.OK) {
                    const myOptions = {
                        zoom: 8,
                        center: results[0].geometry.location,
                        mapTypeId: google.maps.MapTypeId.ROADMAP
                    }
                    map = new google.maps.Map(document.getElementById("map"), myOptions);
        
                    const marker = new google.maps.Marker({
                        map: map,
                        position: results[0].geometry.location
                    });

                    map.setZoom(17);
                    map.panTo(marker.position);

                }
            });

            this.props.onChange(addressObject.formatted_address)
        }
    }

    render() {
        return (
            <React.Fragment>
                <Script
                    url="https://maps.googleapis.com/maps/api/js?key=AIzaSyAuuqnU2YqXgdy6OY7ZuNdS77CR4B9iu18&libraries=places"
                    onLoad={this.handleScriptLoad}
                />
                <input onChange={this.props.onChangeInput} defaultValue={this.props.address} type="text" autoComplete="off" id="autocomplete-address-business" hinttext="Search City" className="form-control box-shadow"  placeholder="Endereço / Address" required/>
            </React.Fragment>
        );
    }
}

export default GooglePlaces;