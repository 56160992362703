import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'

import api from '../../../../Services/Api';

import $ from 'jquery';
import Modal from 'react-bootstrap/Modal';

class BrokersPercentage extends Component {

    constructor(props){
        super(props)
        this.state = {
            modalResponseShow: false,
            msgResponseShow: false,
            brokers: []
        }
        this.handleSetPercentage = this.handleSetPercentage.bind(this);
        this.handleCloseModalResponse = this.handleCloseModalResponse.bind(this);
        this.reloadDatatable = this.reloadDatatable.bind(this)
        this.handleReload = this.handleReload.bind(this)
        this.translate = this.props.translate
    }

    async loadBrokersList(){
        api.get('/brokers/list').then(response => {
            const brokers = response.data;
            this.setState({
                brokers
            });
        })
    }

    handleReload(){
        this.loadBrokersList();
    }

    componentDidMount = async (e) => {
        api.get('/brokers/list')
            .then(response => {
                const brokers = response.data;
                this.setState(
                    {
                        brokers
                    }
                );
            })
    }

    reloadDatatable(){
        $('#list_broker').DataTable().ajax.reload();
    }

    handleSetPercentage = async (e) => {
        e.preventDefault();
        this.setState({ msgResponseShow: this.translate("BROKERS_PERCENTAGE_WAIT_WE_ARE_ADJUSTING_THE_PERCENTAGES"), modalResponseShow: true });
        const data = $(e.target).serializeArray();
        try{
            const response = await api.post("/brokers/percentage/set", {data});
            if(response.data.success === true){
                this.setState({msgResponseShow: this.translate("BROKERS_PERCENTAGE_PERCENTAGE_ADJUSTED_WITH_SUCCESS")});
                this.reloadDatatable()
            }else{
                this.setState({msgResponseShow: response.data.msg});
            }
        }catch(err){
            this.setState({msgResponseShow: err});
        }
    }

    handleChange = async (e) => {
        e.preventDefault();
        var form = $('#set_percentage').serializeArray();
        var total = 0;
        $.each(form, function(i, val){
            total = parseFloat(total) + parseFloat(val.value)
        });
        console.log(total);
        if(total < 100 || total > 100 || isNaN(total)){
            $('#por_button_save').prop('disabled', true);
        }else{
            $('#por_button_save').prop('disabled', false);
        }
    }

    handleCloseModalResponse(e = null){
        if (e) e.preventDefault()
        this.setState({modalResponseShow: false})
    }

    render() {
        return (
            <React.Fragment>
                <h5 className="fwhite">
                    {this.translate("BROKERS_PERCENTAGE_ADJUST_PERCENTAGE")}
                    <button onClick={this.handleReload} className="btn btn-transparent float-right">
                        <i className="fa fa-sync"></i>
                    </button>
                </h5>
                <div className="bootstrap-table">
                    <div className="fixed-table-container">
                        <div className="fixed-table-body min-height100">
                            <form id="set_percentage" onSubmit={this.handleSetPercentage} className="width100">
                                <table className="table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                                    <thead>
                                        <tr className="text-left">
                                            <th>{this.translate("BROKERS_PERCENTAGE_NAME_OF_BROKER")}</th>
                                            <th>{this.translate("BROKERS_PERCENTAGE_PERCENTAGE")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.brokers.map((data, i) => (
                                                <tr key={i} className="col-sm-12 col-md-12 col-lg-12">
                                                    <td>
                                                        {data.name}
                                                    </td>
                                                    <td>
                                                        <input type="number" onChange={this.handleChange} name={data.id_broker} step="any" min="0" max="100" className="form-control" defaultValue={data.distribution_percentage} required />
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <div className="box-divider m0 mt20 width100"></div>
                                <div className="col-sm-12 col-md-12 mt20">
                                    <button type="submit" id="por_button_save" className="btn btn-info px-5 btn-rounded btn-shadow pull-right" disabled>
                                        <i className="fa fa-save"></i> {this.translate("BROKERS_PERCENTAGE_SAVE_PERCENTAGE")}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.modalResponseShow} onHide={this.handleCloseModalResponse} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-bell"></i> Status</h5>
                                <a href="/" onClick={e=>this.handleCloseModalResponse(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                <span className="col-12 text-center py-2 lead">
                                    {this.state.msgResponseShow}
                                </span>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(BrokersPercentage);