import React, { Component } from 'react';

import PeriodBoxTool from '../Structure/PeriodBoxTool';

import StageMarket from './FunnelActions/StageMarket'
import StageReach from './FunnelActions/StageReach'
import StageSegmentation from './FunnelActions/StageSegmentation'
import StageSuspect from './FunnelActions/StageSuspect'
import StageJourney from './FunnelActions/StageJourney'
import StageLeads from './FunnelActions/StageLeads'

import Modal from 'react-bootstrap/Modal';

export class FunilMarketing extends Component {

    constructor(props){
        super(props)
        this.state = {
            modalShow: false,
            modalComponents: null
        }
        this.handleClickFunnelStage = this.handleClickFunnelStage.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
    }

    handleChange = (periodName, values) => {
        this.props.fields.market_value = values.market_value
        this.props.fields.reach_value = values.reach_value
        this.props.fields.segmentation_value = values.segmentation_value
        this.props.fields.suspects_value = values.suspects_value
        this.props.fields.journey_value = values.journey_value
        this.props.fields.leads_value = values.leads_value

        this.props.fields.por_relative_reach_per_market = (values.reach_value !== "0" && values.market_value !== "0")?((values.reach_value.replace(/\./gi, "") * 100) / values.market_value.replace(/\./gi, "")).toFixed(1): 0
        this.props.fields.por_relative_segmentation_per_reach = (values.segmentation_value !== "0" && values.reach_value !== "0")?((values.segmentation_value.replace(/\./gi, "") * 100) / values.reach_value.replace(/\./gi, "")).toFixed(1): 0
        this.props.fields.por_relative_suspects_per_segmentation = (values.suspects_value !== "0" && values.segmentation_value !== "0")?((values.suspects_value.replace(/\./gi, "") * 100) / values.segmentation_value.replace(/\./gi, "")).toFixed(1): 0
        this.props.fields.por_relative_journey_per_suspects = (values.journey_value !== "0" && values.suspects_value !== "0")?((values.journey_value.replace(/\./gi, "") * 100) / values.suspects_value.replace(/\./gi, "")).toFixed(1): 0
        this.props.fields.por_relative_leads_per_journey = (values.leads_value !== "0" && values.journey_value !== "0")?((values.leads_value.replace(/\./gi, "") * 100) / values.journey_value.replace(/\./gi, "")).toFixed(1): 0

        /*
        this.props.fields.por_relative_reach_per_market = values.por_relative_reach_per_market
        this.props.fields.por_relative_segmentation_per_reach = values.por_relative_segmentation_per_reach
        this.props.fields.por_relative_suspects_per_segmentation = values.por_relative_suspects_per_segmentation
        this.props.fields.por_relative_journey_per_suspects = values.por_relative_journey_per_suspects
        this.props.fields.por_relative_leads_per_journey = values.por_relative_leads_per_journey
        */
        //
        this.forceUpdate();
    }

    handleChangeModal = (action) => {
        if(action === 'close'){
            this.handleCloseModal();
        }
    }

    handleClickFunnelStage = (e) => {
        e.preventDefault();
        var type = e.currentTarget.dataset.type;
        var checkComponent = false
        switch(type){
            case 'market':
                this.setState({modalComponents: <StageMarket onChange={this.handleChangeModal}/>})
                checkComponent = true;
                break
            case 'reach':
                this.setState({modalComponents: <StageReach onChange={this.handleChangeModal}/>})
                checkComponent = true;
                break
            case 'segmentation':
                this.setState({modalComponents: <StageSegmentation onChange={this.handleChangeModal}/>})
                checkComponent = true;
                break
            case 'suspect':
                this.setState({modalComponents: <StageSuspect onChange={this.handleChangeModal}/>})
                checkComponent = true;
                break
            case 'journey':
                this.setState({modalComponents: <StageJourney onChange={this.handleChangeModal}/>})
                checkComponent = true;
                break
            case 'leads':
                this.setState({modalComponents: <StageLeads onChange={this.handleChangeModal}/>})
                checkComponent = true;
                break
            default:
                checkComponent = false;
                break
        }
        if(checkComponent === true){
            this.setState({modalShow: true})
        }
    }

    handleCloseModal(){
        this.setState({modalShow: false})
    }

    componentDidMount(){
        //this.props.fields.market_value = this.props.fields.market_value
        //this.props.fields.reach_value = this.props.fields.reach_value
        //this.props.fields.segmentation_value = this.props.fields.segmentation_value
        //this.props.fields.suspects_value = this.props.fields.suspects_value
        //this.props.fields.journey_value = this.props.fields.journey_value
        //this.props.fields.leads_value = this.props.fields.leads_value
        
        this.props.fields.por_relative_reach_per_market = (this.props.fields.reach_value !== "0" && this.props.fields.market_value !== "0")?((this.props.fields.reach_value.replace(/\./gi, "") * 100) / this.props.fields.market_value.replace(/\./gi, "")).toFixed(1): 0
        this.props.fields.por_relative_segmentation_per_reach = (this.props.fields.segmentation_value !== "0" && this.props.fields.reach_value !== "0")?((this.props.fields.segmentation_value.replace(/\./gi, "") * 100) / this.props.fields.reach_value.replace(/\./gi, "")).toFixed(1): 0
        this.props.fields.por_relative_suspects_per_segmentation = (this.props.fields.suspects_value !== "0" && this.props.fields.segmentation_value !== "0")?((this.props.fields.suspects_value.replace(/\./gi, "") * 100) / this.props.fields.segmentation_value.replace(/\./gi, "")).toFixed(1): 0
        this.props.fields.por_relative_journey_per_suspects = (this.props.fields.journey_value !== "0" && this.props.fields.suspects_value !== "0")?((this.props.fields.journey_value.replace(/\./gi, "") * 100) / this.props.fields.suspects_value.replace(/\./gi, "")).toFixed(1): 0
        this.props.fields.por_relative_leads_per_journey = (this.props.fields.leads_value !== "0" && this.props.fields.journey_value !== "0")?((this.props.fields.leads_value.replace(/\./gi, "") * 100) / this.props.fields.journey_value.replace(/\./gi, "")).toFixed(1): 0
        this.forceUpdate();
    }

    render() {
        return (
            <div className="col-sm-6" id="funnel01">
                <div className="p-lg-3">
                    <div className="d-flex">
                        <p className="uppercase text-muted f10">Funil de Marketing</p>
                    </div>
                    <div className="funnel">
                        <div onClick={this.handleClickFunnelStage} className="layer1" data-type="market" data-placement="top">
                            <h5 className="fwhite">
                                Mercado 
                                <span id="funilMercadoValue" className="f900 pull-right">
                                    <React.Fragment>
                                        {this.props.fields.market_value}
                                    </React.Fragment>
                                </span>
                            </h5>
                        </div>
                        <div onClick={this.handleClickFunnelStage} className="layer2" data-type="reach" data-placement="top">
                            <h5 className="fwhite">
                                Alcance
                                <span  className="f900 pull-right">
                                    <React.Fragment>
                                        {this.props.fields.reach_value}
                                    </React.Fragment>
                                    <span className="ls f600"> 
                                        <i className="fa fa-circle text-blue m0"></i>&nbsp;&nbsp;
                                        <React.Fragment>
                                            {this.props.fields.por_relative_reach_per_market}%
                                        </React.Fragment>
                                    </span>
                                </span>
                            </h5>
                        </div>
                        <div onClick={this.handleClickFunnelStage} className="layer3" data-type="segmentation" data-placement="top">
                            <h5 className="fwhite">
                                Segmentação
                                <span className="f900 pull-right">
                                    <React.Fragment>
                                        {this.props.fields.segmentation_value}
                                    </React.Fragment>
                                    <span className="ls f600"> 
                                        <i className="fa fa-circle text-blue m0"></i>&nbsp;&nbsp;
                                        <React.Fragment>
                                            {this.props.fields.por_relative_segmentation_per_reach}%
                                        </React.Fragment>
                                    </span>
                                </span>
                            </h5>
                        </div>
                        <div onClick={this.handleClickFunnelStage} className="layer4" data-type="suspect" data-placement="top">
                            <h5 className="fwhite">
                                Suspect 
                                <span className="f900 pull-right">
                                    <React.Fragment>
                                        {this.props.fields.suspects_value}
                                    </React.Fragment>
                                    <span className="ls f600"> 
                                        <i className="fa fa-circle text-blue m0"></i>&nbsp;&nbsp;
                                        <React.Fragment>
                                            {this.props.fields.por_relative_suspects_per_segmentation}%
                                        </React.Fragment>
                                    </span>
                                </span>
                            </h5>
                        </div>
                        <div onClick={this.handleClickFunnelStage} className="layer5" data-type="journey" data-placement="top">
                            <h5 className="fwhite">
                                Jornada 
                                <span className="f900 pull-right">
                                    <React.Fragment>
                                        {this.props.fields.journey_value}
                                    </React.Fragment>
                                    <span className="ls f600"> 
                                        <i className="fa fa-circle text-blue m0"></i>&nbsp;&nbsp;
                                        <React.Fragment>
                                            {this.props.fields.por_relative_journey_per_suspects}%
                                        </React.Fragment>
                                    </span>
                                </span>
                            </h5>
                        </div>
                        <div onClick={this.handleClickFunnelStage} className="layer6" data-type="leads" data-placement="top">
                            <h5 className="fwhite">
                                Leads 
                                <span className="f900 pull-right">
                                    <React.Fragment>
                                        {this.props.fields.leads_value}
                                    </React.Fragment>
                                    <span className="ls f600"> 
                                        <i className="fa fa-circle text-blue m0"></i>&nbsp;&nbsp;
                                        <React.Fragment>
                                            {this.props.fields.por_relative_leads_per_journey}%
                                        </React.Fragment>
                                    </span>
                                </span>
                            </h5>
                        </div>
                    </div>
                    <p className="small legend lh"><span className="f800">Dica: </span>Clique sobre cada etapa para mais informações.</p>
                </div>
                <PeriodBoxTool onChange={this.handleChange} type={this.props.type}/>
                <Modal show={this.state.modalShow} onHide={this.handleCloseModal} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t modal-all-generic">
                    <Modal.Body>
                        {this.state.modalComponents}
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default FunilMarketing;