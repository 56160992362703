import React, {Component} from 'react';

import PeriodBoxTool from '../../Structure/PeriodBoxTool';

export class EcommerceSpeedometerSales extends Component{

    handleChange = (periodName, values) => {
        this.cardCustom = true
        this.props.fields.metaSales = values.metaSales;
        this.props.fields.porMetaSales = values.porMetaSales
        this.props.fields.valueSales = values.valueSales
        this.forceUpdate();
    }

    render(){

        const styleElement = {
            countVendasValue:{
                background: 'none',
                margin: '10px auto'
            },
            divBackground:{
                width: '100%',
                height: '100%',
                position: 'absolute',
                left: '0px',
                top: '0px',
                zIndex: '0'
            },
            divBackgroundImg:{
                width: '100%',
                height: 'auto',
                maxHeight: '180px',
                maxWidth: '340px',
                margin: '0 auto',
                display: 'block'
            }
        }

        const por = (this.props.fields.porMetaSales > 100)? 100: this.props.fields.porMetaSales;
        const img = '/assets/app/img/gauge_' + por + '.png'

        console.log(por);

        return (
            <div className="col-6 col-sm-6 col-md-6">
                <div className="padding position10">
                    <p className="uppercase text-muted f10">Meta de Vendas</p>
                    
                    <div id="countVendasValue" className="gauge gauge_90" style={styleElement.countVendasValue}>
                        <div id="background" style={styleElement.divBackground}>
                            <img src={img} className="stretch" alt="" style={styleElement.divBackgroundImg} />
                        </div>
                        <span className="badge indigo pos-rlt f24 box-shadow2">{this.props.fields.porMetaSales}%</span>
                    </div>
                    <h6 id="countVendasAlcance" className="mb-4 text-center m0">
                        <span className="f700 fwhite">{this.props.fields.valueSales} </span> 
                        <span className="f300">vendidos</span>
                    </h6>
                    <p id="countVendasMeta" className="mb-4 text-center mt10 mb20">
                        Meta de vendas: {this.props.fields.metaSales}
                    </p>
                </div>
                <PeriodBoxTool onChange={this.handleChange} type={this.props.type}/>
            </div>
        )
    }
}

export default EcommerceSpeedometerSales;