import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'

import $ from 'jquery';

import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4'

import {ajaxUrl, ajaxToken} from '../../../../Services/Ajax';

class BrokersList extends Component {

    constructor(props){
        super(props);
        this.state = {
            tableLoadBroker: null,
        }
        this.translate = this.props.translate
    }

    componentDidMount = async () => {
        let tableLoadBroker = this.state.tableLoadBroker
        const props = this.props;
        tableLoadBroker = $('#list_broker').DataTable({
            "searching": true,
            "paging": true,
            "info": false,
            "responsive": true,
            "language": {
                "url": this.translate("DATATABLE_LANGUAGE_URL")
            },
            "ajax": {
                "url": ajaxUrl('/brokers/table'),
                "type": 'GET',
                "beforeSend": function (request) {
                    request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                }
            },
            "columnDefs": [{
                "targets": -1,
                "data": null,
                "defaultContent":  `<div class="btn-group btn-shadow">
                                        <button class="view btn btn-sm success" title="${this.translate("BROKERS_LIST_TABLE_ACCESS_BROKER_TITLE")}">
                                            <i class="fa fa-user-circle" title="${this.translate("BROKERS_LIST_TABLE_ACCESS_BROKER_BUTTON")}"></i> ${this.translate("BROKERS_LIST_TABLE_ACCESS_BROKER_BUTTON")}
                                        </button>
                                    </div>`
            }]
        });
        $('#list_broker tbody').on( 'click', '.view', function (event){
            event.preventDefault();
            let data = [];
            if($(this).parents('tr').hasClass('child')){
                data = tableLoadBroker.row($(this).parents('tr').prev('.parent')).data();
            }else{
                data = tableLoadBroker.row($(this).parents('tr')).data();
            }
            props.history.push('/commercial_management_agents#' + data[6]);
        });
    }

    render() {
      
        return (
            <React.Fragment>
                <h5 className="fwhite">{this.translate("BROKERS_LIST_LIST_OF_BROKERS")}</h5>
                <div className="bootstrap-table">
                    <div className="fixed-table-container">
                        <div className="fixed-table-body min-height100">
                            <table id="list_broker" className="table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                                <thead>
                                    <tr className="text-left">
                                        <th>{this.translate("BROKERS_LIST_NAME_OF_BROKER")}</th>
                                        <th>{this.translate("BROKERS_LIST_EMAIL_OF_BROKER")}</th>
                                        <th>{this.translate("BROKERS_LIST_PHONE_OF_BROKER")}</th>
                                        <th>{this.translate("BROKERS_LIST_STATUS")}</th>
                                        <th>{this.translate("BROKERS_LIST_PERCENTAGE")}</th>
                                        <th>{this.translate("BROKERS_LIST_NUMBER_OF_CALLS")}</th>
                                        <th>{this.translate("BROKERS_LIST_ACTIONS")}</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(BrokersList);