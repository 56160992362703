import React, { Component } from 'react'
import $ from "jquery"

import GenericMetricComponent from "./Metrics/GenericMetricComponent"
import EditMetricViews from './Metrics/EditMetricViews'

export default class MetricsBuilder extends Component{
    
    disposeTooltip = () => $('[data-toggle="tooltip"]').tooltip('dispose')

    componentDidMount = () => {
        $('[data-toggle="tooltip"]').tooltip('dispose').tooltip({placement: "bottom", trigger: "hover"})
    }

    componentDidUpdate = () => {
        $('[data-toggle="tooltip"]').tooltip('dispose').tooltip({placement: "bottom", trigger: "hover"})
    }

    

    render = () => {

        return (
            <a href="/" className="list-group-item d-flex align-items-center" onClick={
                (e)=>{
                    e.preventDefault()
                    if(!this.props.isEditing) this.props.chooseMetric(e, this.props.isEditing ? EditMetricViews : GenericMetricComponent, this.props.metric, false)
                }}>
                <span className="flex-grow-1">{this.props.metric.title}</span>
                    
                    {this.props.isEditing &&
                     
                            this.props.isMetricToBeChosen ?
                                <button className="btn btn-success btn-cursor-pointer ml-2" style={{width:35, height: 35}} onClick={e=>{this.disposeTooltip();this.props.handleAddMetric(this.props.metric, this.props.id, this.props.stageId)}} data-toggle="tooltip" title={this.props.translate("DASHBOARD_PERFORMANCE_CARD_ADD_METRIC")}>
                                    <i className="fa fa-plus" />
                                </button>
                            :
                            <React.Fragment>
                                <button className="btn btn-primary btn-cursor-pointer ml-2" style={{width:35, height: 35}} onClick={e=>{this.disposeTooltip();this.props.chooseMetric(e, this.props.isEditing ? EditMetricViews : GenericMetricComponent, this.props.metric, this.props.isMetricToBeChosen)}} data-toggle="tooltip" title={this.props.translate("DASHBOARD_PERFORMANCE_CARD_VISUALIZE_METRIC")}>
                                    <i className="fa fa-eye" />
                                </button>
                                
                                <button className="btn btn-danger btn-cursor-pointer ml-2" style={{width:35, height: 35}} onClick={e=>{this.disposeTooltip();this.props.handleRemoveMetric(this.props.metric, this.props.id, this.props.stageId)}} data-toggle="tooltip" title={this.props.translate("DASHBOARD_PERFORMANCE_CARD_REMOVE_METRIC")}>
                                    <i className="fa fa-minus" />
                                </button>
                                
                            </React.Fragment>
                            

                    }
            </a>
        )
    }
    
}
