import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'

//import api from '../../../Services/Api';

import Modal from 'react-bootstrap/Modal';

class DevelopmentLogs extends Component {

    constructor(props){
        super(props);
        this.state = {
            modalResponseShow: false,
            msgResponseShow: "",
            developmentSuccessLogs:[],
            developmentWarningLogs:[],
            developmentErrorLogs:[]
        }
    }
    handleCloseModalResponse = (e = null) =>{
        if (e) e.preventDefault()
        this.setState({modalResponseShow: false})
    }
    getDevelopmentLogs = async () => {
        /* await api.get("/business/" + this.props.idBusiness + "/qualification/get").then(async response => {
            developmentLogs = response.data
        }) */

        let developmentLogs = [
            [
                "2015-03-27 11:16",
                "success", 
                "Mensagem de teste do Desenvolvimento 5", 
                "idTeste12345" 
            ],
            [
                "2017-04-28 11:16",
                "warning", 
                "Mensagem de teste do Desenvolvimento 2", 
                "idTeste12" 
            ],
            [
                "2020-03-27 11:16",
                "warning", 
                "Mensagem de teste do Desenvolvimento 4", 
                "idTeste1234" 
            ],
            [
                "2020-03-27 11:16",
                "error", 
                "Mensagem de teste do Desenvolvimento 3", 
                "idTeste123" 
            ],
            [
                "2019-03-27 11:16",
                "success", 
                "Mensagem de teste do Desenvolvimento 6", 
                "idTeste123456" 
            ],
            [
                "2018-03-27 11:16",
                "success", 
                "Mensagem de teste do Desenvolvimento 1", 
                "idTeste1" 
            ]
        ]
        developmentLogs = this.sort(developmentLogs, "date")
        let developmentSuccessLogs = developmentLogs.filter((developmentLog)=>developmentLog[1] ==="success")
        let developmentWarningLogs = developmentLogs.filter((developmentLog)=>developmentLog[1] ==="warning")
        let developmentErrorLogs = developmentLogs.filter((developmentLog)=>developmentLog[1] ==="error")
        this.setState({ developmentSuccessLogs, developmentWarningLogs, developmentErrorLogs })
    }
    sort(developmentLogs, sortingType){
        if(sortingType === "date"){
            developmentLogs = developmentLogs.slice(0).sort((a, b) => new Date(b[0]) - new Date(a[0]))
        }
        return developmentLogs
    }
    concludeLog = (type, id) =>{
        console.log(type, id)
    }
    componentDidMount = () => {
        this.getDevelopmentLogs()
    }

    render() {
        let uniqueIndex = 1
        const getUniqueIndex = () => uniqueIndex++

        return (
            <React.Fragment>
                <div id="accordion-development-logs">
                    <div className="card mb-3">
                        <div className="card-header btn-cursor-pointer dark" id="heading_development_success_logs">
                            <h5 className="mb-0" data-toggle="collapse" data-target="#collapse_development_success_logs" aria-expanded="true" aria-controls="collapse_development_success_logs">
                                Development Success
                                <i className="fa fa-caret-down pull-right"></i>
                            </h5>
                        </div>

                        <div id="collapse_development_success_logs" className="collapse dark show" aria-labelledby="heading_development_success_logs" data-parent="#accordion-development-logs">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th colSpan="4">Logs</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.developmentSuccessLogs.length > 0 ? (
                                                    this.state.developmentSuccessLogs.map((developmentLog, index)=>
                                                        <tr key={`trLog${index}${getUniqueIndex()}`}>
                                                            <td>
                                                                { developmentLog[0] }
                                                            </td>
                                                            <td>
                                                                { developmentLog[1] }
                                                            </td>
                                                            <td>
                                                                { developmentLog[2] }
                                                            </td>
                                                            <td>
                                                                <button type="button" onClick={e=>this.concludeLog("success", developmentLog[3])} className="btn btn-default blue btn-cursor-pointer">Concluir</button>
                                                            </td>
                                                            
                                                        </tr>
                                                    )
                                                ) : (
                                                    <tr>
                                                        <td>
                                                            Carregando...
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                            
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-header btn-cursor-pointer dark" id="heading_development_warning_logs">
                            <h5 className="mb-0" data-toggle="collapse" data-target="#collapse_development_warning_logs" aria-expanded="true" aria-controls="collapse_development_warning_logs">
                                Development Warning
                                <i className="fa fa-caret-down pull-right"></i>
                            </h5>
                        </div>

                        <div id="collapse_development_warning_logs" className="collapse dark" aria-labelledby="heading_development_warning_logs" data-parent="#accordion-development-logs">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th colSpan="4">Logs</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.developmentWarningLogs.length > 0 ? (
                                                    this.state.developmentWarningLogs.map((developmentLog, index)=>
                                                        <tr key={`trLog${index}${getUniqueIndex()}`}>
                                                            <td>
                                                                { developmentLog[0] }
                                                            </td>
                                                            <td>
                                                                { developmentLog[1] }
                                                            </td>
                                                            <td>
                                                                { developmentLog[2] }
                                                            </td>
                                                            <td>
                                                                <button type="button" onClick={e=>this.concludeLog("warning", developmentLog[3])} className="btn btn-default blue btn-cursor-pointer">Concluir</button>
                                                            </td>
                                                            
                                                        </tr>
                                                    )
                                                ) : (
                                                    <tr>
                                                        <td>
                                                            Não há logs registrados
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                            
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-header btn-cursor-pointer dark" id="heading_development_error_logs">
                            <h5 className="mb-0" data-toggle="collapse" data-target="#collapse_development_error_logs" aria-expanded="true" aria-controls="collapse_development_error_logs">
                                Development Error
                                <i className="fa fa-caret-down pull-right"></i>
                            </h5>
                        </div>

                        <div id="collapse_development_error_logs" className="collapse dark" aria-labelledby="heading_development_error_logs" data-parent="#accordion-development-logs">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th colSpan="4">Logs</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.developmentErrorLogs.length > 0 ? (
                                                    this.state.developmentErrorLogs.map((developmentLog, index)=>
                                                        <tr key={`trLog${index}${getUniqueIndex()}`}>
                                                            <td>
                                                                { developmentLog[0] }
                                                            </td>
                                                            <td>
                                                                { developmentLog[1] }
                                                            </td>
                                                            <td>
                                                                { developmentLog[2] }
                                                            </td>
                                                            <td>
                                                                <button type="button" onClick={e=>this.concludeLog("error", developmentLog[3])} className="btn btn-default blue btn-cursor-pointer">Concluir</button>
                                                            </td>
                                                            
                                                        </tr>
                                                    )
                                                ) : (
                                                    <tr>
                                                        <td>
                                                            Carregando...
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                            
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.modalResponseShow} onHide={this.handleCloseModalResponse} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-bell"></i> Status</h5>
                                <a href="/" onClick={e=>this.handleCloseModalResponse(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                <span className="col-12 text-center py-2 lead">
                                    {this.state.msgResponseShow}
                                </span>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}

export default withRouter(DevelopmentLogs)