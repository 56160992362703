import React, { Component } from 'react';

class BackgroundVideoBuylitics1 extends Component {
    render() {
        return (
            <div className="bg_scheme">
                <div className="video-container">
                    <video id="video-bg" autoPlay loop onLoadedData={() => {}}>
                        <source src="/assets/app/img/video_bg06.mp4" type="video/mp4"/>
                    </video>
                </div>
            </div>
        );
    }
}

export default BackgroundVideoBuylitics1;