import React, { Component } from 'react';
import Chart from 'chart.js';

import {app} from '../Structure/Configs/Theme';

class LeadsSummaryFeedbacks extends Component {

    componentDidMount(){
        const chartLeadsComparativeFeedbacks = document.getElementById('chart-leads-comparative-feedbacks').getContext('2d');
        new Chart(chartLeadsComparativeFeedbacks, {
            type: 'doughnut',
            data: {
                labels: ['Com feedback','Sem feedback' ],
                datasets: [
                    {
                        data: [
                            this.props.fields.total_leads_with_feedback,
                            this.props.fields.total_leads_without_feedback
                        ],
                        borderColor: 'transparent',
                        backgroundColor: [
                            'rgba(238,40,82,1)',
                            app.color.primary,
                            'rgba(255,193,7,1)',
                            'rgba(247,81,0,1)',
                            'rgba(76,175,80,1)',
                            'rgba(116,32,230,1)'
                        ],
                        label: 'Acessos'
                    }
                ]
            },
            options: {
                legend: {
                    position: 'bottom',
                    fontSize: '12',
                    labels:{
                        boxWidth: 10
                    }
                },
                cutoutPercentage: 40
            }
        });
    }

    render() {
        return (
            <div className="col-sm-12 col-md-6">
                <div className="box p-3 box-shadow2">
                    <div className="d-flex">
                        <span className="text-muted text-center w-100">Leads</span>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-5">
                            <div className=" text-center text-info lh mt10 mb10">
                                <a href="lista_leads.html" className="">
                                    <i className="fa fa-users f14 opacity90"></i>&nbsp;
                                    <span className="f12 f400">
                                        Total de leads: {this.props.fields.leads_total}
                                    </span>
                                </a>
                            </div>
                            <canvas id="chart-leads-comparative-feedbacks" data-plugin="chart" height={136}></canvas>
                        </div>
                        <div className="col-sm-6 col-md-6">
                            <div className="py-2 b-b">
                                <div className="float-right lh">
                                    <span className="text-md text-success f700 d-block">
                                        {this.props.fields.leads_percentage_of_positive_feedback}%
                                    </span>
                                    <br/>
                                    <span className="f10 d-block">
                                        {this.props.fields.leads_total_of_positive_feedback} leads
                                    </span>
                                </div>
                                <span className="_600 d-block">
                                    <i className="fa fa-thumbs-up"></i>&nbsp;
                                    <a href="leads_feedbacks">Feedback positivo</a>
                                </span>
                                <small className="text-muted">
                                    Prime time: {this.props.fields.leads_prime_time}
                                </small>
                            </div>
                            <div className="py-2 b-b">
                                <div className="float-right lh">
                                    <span className="text-md text-danger f700 d-block">
                                        {this.props.fields.leads_percentage_of_negative_feedback}%
                                    </span>
                                    <br/>
                                    <span className="f10 d-block">
                                        {this.props.fields.leads_total_of_negative_feedback} leads
                                    </span>
                                </div>
                                <span className="_600 d-block">
                                    <i className="fa fa-thumbs-down"></i>&nbsp;
                                    <a href="leads_feedbacks">Feedback negativo</a>
                                </span>
                                <small className="text-muted">
                                    Danger time: {this.props.fields.leads_danger_time}
                                </small>
                            </div>
                            <div className="py-2 b-b">
                                <div className="float-right lh">
                                    <span className="text-md text-warning f700 d-block">
                                        {this.props.fields.leads_percentage_of_audited_feedback}%
                                    </span>
                                    <br/>
                                    <span className="f10 d-block">
                                        {this.props.fields.leads_total_of_audited_feedback} leads
                                    </span>
                                </div>
                                <span className="_600 d-block">
                                    <i className="fa fa-check"></i>&nbsp;
                                    <a href="leads_feedbacks">Feedback auditado</a>
                                </span>
                                <small className="text-muted">
                                    Recuperados: {this.props.fields.leads_recovered}
                                </small>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default LeadsSummaryFeedbacks;