import React, { Component } from 'react';

class LeadsActionBar extends Component {
    render() {
        return (
            <div className="col-md-12 col-sm-12">
                <div className="mr5 mt10">
                    <a className="btn btn-shadow lime px-5 pull-left no-border-radius" href="/leads_list">
                        <i className="fa fa-list f16"></i> <span className="f12"> Ver a lista de leads</span>
                    </a>
                    <a className="btn btn-shadow light-blue pull-left no-border-radius" href="/leads_feedback">
                        <i className="fa fa-thumbs-up f16"></i> <span className="f12"> Feedbacks</span>
                    </a>
                    <a className="btn btn-shadow blue pull-left no-border-radius" href="/leads_midia">
                        <i className="fa fa-image f16"></i> <span className="f12"> Mídia</span>
                    </a>
                    <a className="btn btn-shadow indigo pull-left no-border-radius" href="/leads_conversion">
                        <i className="fa fa-check f16"></i> <span className="f12"> Conversão</span>
                    </a>
                </div>
            </div>
        );
    }
}

export default LeadsActionBar;