import React, { Component, Fragment } from 'react';

import $ from 'jquery';

import api from '../../../Services/Api';

class ScheduledVisit extends Component {
    _isMounted = false;

    constructor(props){
        super(props)
        this.state = {
            idAction: this.props.values.idAction,
            loaded: false,
            alertResponse: null,
            listEmails: [],
            listSMSs: [],
            formValuesAction: {
                remember_visit: false,
                remember_hours_before: null,
                email_id: null,
                sms_id: null
            }
        }
        this.formSubmitSave = this.formSubmitSave.bind(this)
        this.translate = this.props.translate

    }

    async loadDependencies(){
        const smss = await api.get('/sms/all_to_select').then(async response => {
            console.log(response);
            return response.data;
        })
        const emails = await api.get('/email_marketing/all_to_select').then(async response => {
            console.log(response);
            return response.data;
        })
        this.setState({listSMSs: smss, listEmails: emails, dependencies: true})
    }

    componentDidMount(){
        this.loadDependencies()
        api.get('/journey/action/' + this.props.values.idAction).then(async response => {
            this.setState({formValuesAction: response.data, loaded: true})
            console.log(response);
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async formSubmitSave(e){
        e.preventDefault()
        $('#button_submit_form_action').prop('disabled', true).html(`<i className="fa fa-spinner"></i> ${this.translate('BUTTON_WAIT')}...`)
        this.setState({alertResponse: null})
        const data = this.state.formValuesAction
        try{
            const response = await api.post("/journey/action/" + this.props.values.idAction, data);
            if(response.data.success){
                this.setState({alertResponse: true})
                $('#button_submit_form_action').prop('disabled', false).html(`<i className="fa fa-save"></i> ${this.translate('BUTTON_REGISTER')}`)
            }else{
                this.setState({alertResponse: false})
            }
        }catch(err){
            console.log(err)
        }
    }

    render() {
        return (
            <Fragment>
                {
                    (this.state.alertResponse === false)?(
                        <div className="alert alert-danger alert-dismissible" role="alert">
                            {this.translate('SCHEDULED_VISIT_ERROR_REGISTERING_CONDITION')}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    ):(
                        <React.Fragment>
                            {
                                (this.state.alertResponse)?(
                                    <div className="alert alert-success alert-dismissible" role="alert">
                                        {this.translate('SCHEDULED_VISIT_SUCCESS_REGISTERING_CONDITION')}
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                ):(false)
                            }
                        </React.Fragment>
                    )
                }
                {
                    (this.state.loaded && this.state.dependencies)?(
                        <form onSubmit={this.formSubmitSave}>
                            <div className="form-group">
                                <label htmlFor="condition_scheduled_visit_remember_visit_false">
                                    <input type="radio" onChange={e => this.setState({formValuesAction: {...this.state.formValuesAction, remember_visit: false}})} name="remember_visit" id="condition_scheduled_visit_remember_visit_false" className="mr-1" defaultChecked={this.state.formValuesAction.remember_visit === false}/>
                                    {this.translate('SCHEDULED_VISIT_DONT_REMEMBER_LEAD_OF_THE_VISIT')}
                                </label>
                            </div>
                            <div className="form-group">
                                <label htmlFor="condition_scheduled_visit_remember_visit_true">
                                    <input type="radio" onChange={e => this.setState({formValuesAction: {...this.state.formValuesAction, remember_visit:  true}})} name="remember_visit" id="condition_scheduled_visit_remember_visit_true" className="mr-1" defaultChecked={this.state.formValuesAction.remember_visit === true}/>
                                    {this.translate('SCHEDULED_VISIT_REMEMBER_LEAD_OF_THE_VISIT')}
                                </label>
                            </div>
                            {
                                (this.state.formValuesAction.remember_visit)?(
                                    <React.Fragment>
                                        <div className="form-group">
                                            <label htmlFor="condition_scheduled_visit_remember_hours_before">{this.translate('SCHEDULED_VISIT_LABEL_HOURS_BEFORE')}</label>
                                            <input type="text" onChange={e => this.setState({formValuesAction: {...this.state.formValuesAction, remember_hours_before:  e.target.value}})} id="condition_scheduled_visit_remember_hours_before" className="form-control" placeholder={this.translate('SCHEDULED_VISIT_PLACEHOLDER_HOURS_BEFORE')} defaultValue={this.state.formValuesAction.remember_hours_before} required/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="condition_scheduled_visit_remember_visit_email">{this.translate('SCHEDULED_VISIT_LABEL_EMAIL')}</label>
                                            <select onChange={e => this.setState({formValuesAction: {...this.state.formValuesAction, email_id:  e.target.value}})} defaultValue={this.state.formValuesAction.email_id} className="form-control cool-select" id="condition_scheduled_visit_remember_visit_email" required>
                                                <option value="">{this.translate('SCHEDULED_VISIT_OPTION_SELECT_THE_EMAIL')}</option>
                                                {
                                                    this.state.listEmails.map((data, i) => (
                                                        <option key={i} value={data.value}>{data.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="condition_scheduled_visit_remember_visit_sms">{this.translate('SCHEDULED_VISIT_LABEL_SMS')}</label>
                                            <select onChange={e => this.setState({formValuesAction: {...this.state.formValuesAction, sms_id:  e.target.value}})} defaultValue={this.state.formValuesAction.sms_id} className="form-control cool-select" id="condition_scheduled_visit_remember_visit_sms" required>
                                                <option value="">{this.translate('SCHEDULED_VISIT_OPTION_SELECT_THE_SMS')}</option>
                                                {
                                                    this.state.listSMSs.map((data, i) => (
                                                        <option key={i} value={data.value}>{data.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </React.Fragment>
                                ):(false)
                            }
                            <div className="form-group">
                                <button type="submit" id="button_submit_form_action" className="btn btn-cursor-pointer btn-success float-right" style={{width: '100%'}}>
                                    <i className="fa fa-save"></i> {this.translate('BUTTON_REGISTER')}
                                </button>
                            </div>
                        </form>
                    ):(
                        <div>
                            {this.translate('SCHEDULED_VISIT_LOADING_CONFIG_FORM')}
                        </div>
                    )
                }
            </Fragment>
        );
    }
}

export default ScheduledVisit;