import React, { Component, Fragment } from 'react';
import {withRouter} from 'react-router-dom';

import MenuMain from '../../../Structure/MenuMain';
import HeaderMain from '../../../Structure/HeaderMain';
import FooterMain from '../../../Structure/FooterMain';

import BrokersRegister from './Components/BrokersRegister';
import BrokersList from './Components/BrokersList';
import BrokersPercentage from './Components/BrokersPercentage';

class Brokers extends Component {

    constructor(props){
        super(props);
        this.translate = this.props.translate
        document.title = this.translate("BROKERS_BROKERS")       
    }

    componentDidMount(){
        const link = document.querySelector('a[data-target="'+ document.location.hash +'"]')
        if(link){
            link.click();
        }
    }

    render() {
        return (
            <Fragment>
                <MenuMain />
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    <HeaderMain title={this.translate("BROKERS_BROKERS")} onChange={this.handleChange}/>
                    <div className="content-main admin" id="content-main">
                        <div className="padding">
                            <div className="row pt20">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">

                                    <ul className="nav nav-pills nav-sm mb10" id="admin_tab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-target="#tab_list_brokers" data-toggle="tab" href="#tab_list_brokers" role="tab" aria-controls="list" aria-selected="false">{this.translate("BROKERS_LIST_OF_BROKERS")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-target="#tab_register_broker" data-toggle="tab" href="#tab_register_broker" role="tab" aria-controls="register" aria-selected="true">{this.translate("BROKERS_REGISTER_BROKER")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-target="#tab_percentage_broker" data-toggle="tab" href="#tab_percentage_broker" role="tab" aria-controls="register" aria-selected="true">{this.translate("BROKERS_ADJUST_PERCENTAGE")}</a>
                                        </li>
                                    </ul>

                                    <div className="tab-content" id="brokers_tab_content">
                                        <div className="tab-pane pb40 fade show active p-3 white box-shadow" id="tab_list_brokers" role="tabpanel" aria-labelledby="">
                                            <BrokersList translate={this.translate} />
                                        </div>
                                        <div className="tab-pane pb40 fade p-3 white box-shadow" id="tab_register_broker" role="tabpanel" aria-labelledby="">
                                            <BrokersRegister translate={this.translate}/>
                                        </div>
                                        <div className="tab-pane pb40 fade p-3 white box-shadow" id="tab_percentage_broker" role="tabpanel" aria-labelledby="">
                                            <BrokersPercentage translate={this.translate}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterMain />
                </div>
            </Fragment>
        );
    }
}

export default withRouter(Brokers);