import React, { Component, Fragment } from 'react';

export default class PrePurchaseAndAttendanceAttendanceAverageTime extends Component {

    render = () =>
        <Fragment>
            <div className="col-6 col-md-6 col-lg-4">
                <div className="box p-3 box-shadow2">
                    <div className="d-flex">
                        <span className="text-muted">{this.props.translate('DASHBOARD_ATTENDANCES_CARD_ATTENDANCE_AVERAGE_TIME')}</span>
                    </div>
                    <div className="py-3 text-center text-lg text-light lh">
                        <a href="/" onClick={(e) => {e.preventDefault();this.props.handleCardActionClick({type: this.props.type, title: this.props.translate('DASHBOARD_ATTENDANCES_CARD_ATTENDANCE_AVERAGE_TIME')})}}>
                            <i className="fa fa-handshake-o f48"></i>
                            <br/>
                            <span className="f16">{this.props.fields.average_time} {this.props.translate('DASHBOARD_ATTENDANCES_CARD_AVERAGE')}</span>
                        <br/>
                        </a>            
                    </div>
                </div>
                <div className="box-tool">
                    <ul className="nav nav-xs mr-1">
                        <li className="nav-item dropdown">
                            <a href="/" className="nav-link text-muted" onClick={(e)=>{e.preventDefault(); this.props.handleCardActionClick({type: this.props.type, title: this.props.translate('DASHBOARD_ATTENDANCES_CARD_ATTENDANCE_AVERAGE_TIME')})}}>
                                <i className="fa fa-info"></i>
                            </a>
                            
                        </li>
                    </ul>
                </div>
            </div>
        </Fragment>        
}