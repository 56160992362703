import React, { Component } from 'react';

class AttendancesFGECOC extends Component {

    constructor(props){
        super(props)
        this.state = {
            showGoals: false,
        }
    }

    render() {
        return (
            <div className="col-md-12 col-sm-12">
                <div className="row">
                    <div className="col-md-12 col-sm-12 p0 text-center">
                        <h6 className="f300">
                            <span className="f600">
                                <i className="fa fa-handshake"></i> &nbsp;
                                {this.props.translate("DASHBOARD_ATTENDANCES_CARD_ATTENDANCES_DASHBOARD")}
                            </span>
                            
                        </h6>
                        <hr/>
                    </div>
                </div>
            </div>
        );
    }
}

export default AttendancesFGECOC;