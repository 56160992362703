import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'

import $ from 'jquery';
import 'jquery-mask-plugin';

import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4'

import {ajaxUrl, ajaxToken} from '../../../Services/Ajax';
import api from '../../../Services/Api';

import Modal from 'react-bootstrap/Modal';

class BusinessGoals extends Component{

    constructor(props){
        super(props);
        this.state = {
            tableLoadGoalsVGV: null,
            formGoalsSales: {
                sales_value: []
            }
        }
        this.handleCloseModalResponse =  this.handleCloseModalResponse.bind(this)
        this.saveGoalsSales = this.saveGoalsSales.bind(this)
        this.onChangeVGVGoals = this.onChangeVGVGoals.bind(this)
        this.translate = this.props.translate
    }

    componentDidMount = async () => {
        try{
            const response = await api.get("/business/"+ this.props.idBusiness +"/goals/12_month_period");
            console.log(response.data);
            if(response.data.value !== null){
                this.setState({
                    formGoalsSales: response.data.values
                });
            }
        }catch(err){
            console.log(err);
        }

        //let tableLoadGoalsVGV = this.state.tableLoadGoalsVGV
        const props = this.props
        //tableLoadGoalsVGV = $('#list_goals_vgv').DataTable({
        $('#list_goals_vgv').DataTable({
            "searching": false,
            "paging": false,
            "info": false,
            "responsive": true,
            "language": {
                "url": this.translate("DATATABLE_LANGUAGE_URL")
            },
            "ajax": {
                "url": ajaxUrl('/business/' + props.idBusiness + '/goals/others_months'),
                "type": 'GET',
                "beforeSend": function (request) {
                    request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                }
            }
        });
        console.log(this.state)
    }

    handleCloseModalResponse(e = null){
        if (e) e.preventDefault()
        this.setState({modalResponseShow: false})
    }

    onChangeVGVGoals(e){
        e.preventDefault();

        const index = e.target.dataset.index;

        const {formGoalsSales} = this.state

        var moneyMaskBehavior = function (){
            return "#.##0,00";
        },
        moneyOptions = {
            reverse: true,
            onKeyPress: function(val, e, field, options){
                formGoalsSales['sales_value'][index]['value'] = e.target.value
                this.setState({
                    formGoalsSales: formGoalsSales
                })
                //this.setState({formPosRegister: {...this.state.formPosRegister, amount: val}})
                field.mask(moneyMaskBehavior.apply({}, arguments), options);
            }.bind(this)
        };
        $('#goals_sales_control_' + index).mask(moneyMaskBehavior, moneyOptions);

    }

    saveGoalsSales = async (e) => {
        e.preventDefault();
        this.setState({ msgResponseShow: this.translate("BUSINESS_GOALS_WAIT_STATUS"), modalResponseShow: true });
        const data = this.state.formGoalsSales;
        try{
            const response = await api.post("/business/" + this.props.idBusiness + "/goals/current_month/sales/12_month_period", {data});
            console.log(response.data);
            if(response.data.success === true){
                this.setState({msgResponseShow: this.translate("BUSINESS_GOALS_SUCCESS_STATUS")});
            }else{
                this.setState({msgResponseShow: response.data.msg});
            }
        }catch(err){
            this.setState({msgResponseShow: err});
        }
    }

    render() {
        return (
            <React.Fragment>
                <div id="accordion-goals">
                    <div className="card mb-3">
                        <div className="card-header btn-cursor-pointer dark" id="headingRegisterGoalsSales">
                            <h5 className="mb-0" onClick={e=>this.props.toggleCollapse("#collapseRegisterGoalsSales")} aria-expanded="true" aria-controls="collapseRegisterGoalsSales">
                                {this.translate("BUSINESS_GOALS_SALES_FUNNEL")}
                                <i className="fa fa-caret-down pull-right"></i>
                            </h5>
                        </div>

                        <div id="collapseRegisterGoalsSales" className="collapse dark show" aria-labelledby="headingRegisterGoalsSales" data-parent="#accordion-goals">
                            <div className="card-body">
                                <form onSubmit={this.saveGoalsSales} id="save-goals-sales">
                                    <div className="row p-3 white box-shadow pb40">
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group">
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                    <b>{this.translate("BUSINESS_GOALS_VGV")}:</b>
                                                </label>
                                                <br />
                                                <div className="row">
                                                    {
                                                        this.state.formGoalsSales.sales_value.map((data, i) => (
                                                            <div className="col-sm-3 col-md-3 col-lg-3" key={i}>
                                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                                    {data.month}
                                                                </label>
                                                                <input type="text" id={'goals_sales_control_' + i} data-index={i} data-month={data.month} onChange={this.onChangeVGVGoals} defaultValue={data.value} className="form-control box-shadow" placeholder={this.translate("BUSINESS_GOALS_GOAL_SALES_CONTROL_PLACEHOLDER")} required/>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-divider m0 mt20 width100"></div>
                                        <div className="col-sm-12 col-md-12 mt20">
                                            <button type="submit" className="btn btn-info px-5 btn-rounded btn-shadow pull-right">
                                                <i className="fa fa-save"></i> {this.translate("BUSINESS_GOALS_REGISTER_GOALS")}
                                            </button>
                                        </div>

                                        <div className="col-12">

                                            <div id="accordion-goals-vgv">
                                                <div className="card mt-4 mb-3">
                                                    <div className="card-header btn-cursor-pointer dark" id="headingRegisterGoalsVGV">
                                                        <h6 className="mb-0" onClick={e=>this.props.toggleCollapse("#collapseRegisterGoalsVGV")} aria-expanded="true" aria-controls="collapseRegisterGoalsVGV">
                                                            {this.translate("BUSINESS_GOALS_PREVIOUS_MONTHS_VGV")}
                                                            <i className="fa fa-caret-down pull-right"></i>
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div id="collapseRegisterGoalsVGV" className="collapse dark" aria-labelledby="headingRegisterGoalsVGV" data-parent="#accordion-goals-vgv">
                                                    <div className="bootstrap-table" style={{width: '100%'}}>
                                                        <div className="fixed-table-container">
                                                            <div className="fixed-table-body">
                                                                <table id="list_goals_vgv" className="table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                                                                    <thead>
                                                                        <tr className="text-left">
                                                                            <th>{this.translate("BUSINESS_GOALS_TABLE_MONTH")}</th>
                                                                            <th>{this.translate("BUSINESS_GOALS_TABLE_VALUE")}</th>
                                                                        </tr>
                                                                    </thead>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
                <Modal show={this.state.modalResponseShow} onHide={this.handleCloseModalResponse} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-bell"></i> {this.translate("MODAL_STATUS_STATUS")}</h5>
                                <a href="/" onClick={e=>this.handleCloseModalResponse(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                <span className="col-12 text-center py-2 lead">
                                    {this.state.msgResponseShow}
                                </span>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(BusinessGoals);