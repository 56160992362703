import React, { Component } from "react";

import $ from "jquery";

import "datatables.net-dt";
import "datatables.net-bs4";
import "datatables.net-responsive";
import "datatables.net-responsive-bs4";

import api from "../../../../Services/Api";
import { ajaxUrl, ajaxToken } from "../../../../Services/Ajax";

class MarketingRDStation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tableMarketingRDStation: null,
			formSaveMarketingRDStation: {},
		};

		this.formSubmitMarketingRDStation =
			this.formSubmitMarketingRDStation.bind(this);
	}

	reloadDataTableMarketingRDStation() {
		$("#marketing_rdstation_integration").DataTable().ajax.reload();
	}

	async deleteMarketingRDStation(id) {
		const data = { id: id };
		try {
			const response = await api.post("/marketing_tools/update", data);
			if (response.data.success === true) {
				this.reloadDataTableMarketingRDStation();
			} else {
				console.log("error");
			}
		} catch (err) {
			console.log(err);
		}
	}

	async formSubmitMarketingRDStation(e) {
		e.preventDefault();
		$("#add_marketing_rd_station")
			.prop("disabled", true)
			.html(`<i className="fa fa-spinner"></i> Aguarde...`);

		const data = this.state.formSaveMarketingRDStation;

		try {
			const response = await api.post(
				"/marketing_tools/create/rdstation",
				data
			);
			if (response.data.success === true && response.data?.callback) {
				const rdstationAuth = window.open(response.data?.callback);

				rdstationAuth.onbeforeunload = () => {
					this.reloadDataTableMarketingRDStation();
					alert("new window closed");
				};

				this.reloadDataTableMarketingRDStation();
				$("#add_marketing_rd_station")
					.prop("disabled", false)
					.html(`<i className="fa fa-save"></i> Adicionar`);
			} else {
				this.setState({ msgResponseShow: response.data.msg });
			}
		} catch (err) {
			this.setState({ msgResponseShow: err });
		}
	}

	componentDidMount() {
		let TableMarketingRDStation = this.state.TableMarketingRDStation;
		TableMarketingRDStation = $(
			"#marketing_rdstation_integration"
		).DataTable({
			searching: true,
			paging: false,
			info: false,
			responsive: true,
			language: {
				url: "pt-BR",
			},
			ajax: {
				url: ajaxUrl("/marketing_tools/table"),
				type: "GET",
				beforeSend: function (request) {
					request.setRequestHeader(
						"Authorization",
						"Bearer " + ajaxToken()
					);
				},
			},
			columnDefs: [
				{
					targets: -1,
					data: null,
					defaultContent: `<div class="btn-group btn-shadow">
                        <button class="delete btn btn-sm danger btn-cursor-pointer" title="Remover RD Station Marketing">
                            <i class="fa fa-trash" title="Remover RD Station Marketing"></i>
                        </button>
                    </div>`,
				},
			],
		});

		$("#marketing_rdstation_integration tbody").on(
			"click",
			".delete",
			function (e) {
				e.preventDefault();

				let data = [];

				$(e.target)
					.prop("disabled", true)
					.html('<i className="fa fa-spinner"></i>');

				if ($(e.target).parents("tr").hasClass("child")) {
					data = TableMarketingRDStation.row(
						$(e.target).parents("tr").prev(".parent")
					).data();
				} else {
					data = TableMarketingRDStation.row(
						$(e.target).parents("tr")
					).data();
				}

				this.deleteMarketingRDStation(data[1]);
			}.bind(this)
		);
	}

	render() {
		return (
			<React.Fragment>
				<div className="d-flex flex-column h-100">
					<div className="row">
						<div
							id="accordionMarketingRDStation"
							style={{ width: "100%" }}
						>
							<div
								className="panel box no-border mb-2"
								style={{ width: "100%" }}
							>
								<div className="box-header b-b p-y-sm fwhite">
									<form
										onSubmit={
											this.formSubmitMarketingRDStation
										}
									>
										<br />
										<div>
											<div className="col-sm-12 col-md-12 mb20">
												<input
													type="text"
													onChange={(e) =>
														this.setState({
															formSaveMarketingRDStation:
																{
																	...this
																		.state
																		.formSaveMarketingRDStation,
																	client_id:
																		e.target
																			.value,
																},
														})
													}
													name="client_id"
													placeholder={`Informe o client_id do app RD Station`}
													className="form-control"
													required="required"
												/>
											</div>
										</div>
										<div>
											<div className="col-sm-12 col-md-12 mb20">
												<input
													type="password"
													onChange={(e) =>
														this.setState({
															formSaveMarketingRDStation:
																{
																	...this
																		.state
																		.formSaveMarketingRDStation,
																	client_secret:
																		e.target
																			.value,
																},
														})
													}
													name="client_secret"
													placeholder={`Informe o client_secret do app RD Station`}
													className="form-control"
													required="required"
												/>
											</div>
										</div>
										<div>
											<div className="col-sm-12 col-md-12 mb20">
												<button
													id="add_marketing_rd_station"
													type="submit"
													className="pull-right btn btn-success px-5 btn-rounded btn-shadow"
												>
													<i className="fa fa-plus"></i>{" "}
													Adicionar
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>

					<div className="col-12 mt-3 p-3 text-left dark">
						<table
							id="marketing_rdstation_integration"
							className="table table-striped table-bordered dt-responsive nowrap"
							style={{ width: "100%" }}
						>
							<thead className="dark">
								<tr>
									<th>Conta</th>
									<th>Ações</th>
								</tr>
							</thead>
						</table>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default MarketingRDStation;
