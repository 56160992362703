import React, { Component } from 'react'
import {withRouter} from 'react-router-dom'

import $ from 'jquery'
import 'jquery-mask-plugin'

import api from '../../../Services/Api'

import Modal from 'react-bootstrap/Modal'

class UserRegister extends Component {

    constructor(props){
        super(props)
        this.translate = this.props.translate
        document.title =  this.translate("ADMIN_USERS_TITLE")
        this.state = {
            modalResponseShow: false,
            msgResponseShow: false,
            formRegister: {
                name: null,
                email: null,
                password: null,
                access_level: ""
            }
        }
    }

    handleCloseModalResponse = (e = null) => {
        if (e) e.preventDefault()
        this.setState({modalResponseShow: false})
    }

    reloadDatatable = () => $('#list_user').DataTable().ajax.reload()

    handleRegisterUser = async (e) => {
        e.preventDefault()
        this.setState({ msgResponseShow: this.translate("ADMIN_USERS_REGISTER_WAIT_STATUS"), modalResponseShow: true })
        const data = this.state.formRegister
 
        try{
            const response = await api.post("/admin/user/insert", data)
            if(response.data.success === true){
                this.setState({msgResponseShow: this.translate("ADMIN_USERS_REGISTER_SUCCESS_STATUS")})
                document.getElementById('register-user-new').reset()
                this.reloadDatatable()
                //$('#click_tab_register_company').click()
            }else{
                this.setState({msgResponseShow: response.data.error })
            }
        }catch(err){
            this.setState({msgResponseShow: err})
        }
    }

    render = () => 
        <React.Fragment>
            <h5 className="fwhite">{this.translate("ADMIN_USERS_REGISTER_USER")}</h5>
            <form id="register-user-new" onSubmit={this.handleRegisterUser} className="width100">
                <div className="row p-3 white box-shadow pb40">
                    <div className="col-12 col-sm-6">
                        <div className="form-group">
                            <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                {this.translate("ADMIN_USERS_REGISTER_LABEL_NAME")}:
                            </label>
                            <input type="text" onChange={e => this.setState({ formRegister: {...this.state.formRegister, name:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("ADMIN_USERS_REGISTER_PLACEHOLDER_NAME")} required/>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6">
                        <div className="form-group">
                            <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                {this.translate("ADMIN_USERS_REGISTER_LABEL_ACCESS_LEVEL")}:
                            </label>
                            <select onChange={e => this.setState({formRegister: {...this.state.formRegister, access_level: e.target.value}})} defaultValue={this.state.formRegister.access_level} className="form-control box-shadow cool-select" required>
                                <option value="">{this.translate("ADMIN_USERS_REGISTER_SELECT_ACCESS_LEVEL")}</option>
                                <option value="admin">{this.translate("ADMIN_USERS_REGISTER_SELECT_ADMIN")}</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6">
                        <div className="form-group">
                            <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                {this.translate("ADMIN_USERS_REGISTER_LABEL_EMAIL")}:
                            </label>
                            <input type="email" onChange={e => this.setState({formRegister: {...this.state.formRegister, email: e.target.value}})} className="form-control box-shadow" placeholder={this.translate("ADMIN_USERS_REGISTER_PLACEHOLDER_EMAIL")} required/>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6">
                        <div className="form-group">
                            <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                {this.translate("ADMIN_USERS_REGISTER_LABEL_PASSWORD")}:
                            </label>
                            <input type="password" onChange={e => this.setState({formRegister: {...this.state.formRegister, password: e.target.value}})} className="form-control box-shadow" placeholder={this.translate("ADMIN_USERS_REGISTER_PLACEHOLDER_PASSWORD")} required/>
                        </div>
                    </div>
                    <div className="box-divider m0 mt20 width100"></div>
                    <div className="col-sm-12 col-md-12 mt20">
                        <button type="submit" className="btn btn-info px-5 btn-rounded btn-shadow pull-right">
                            <i className="fa fa-save"></i> {this.translate("ADMIN_USERS_REGISTER_REGISTER_USER")}
                        </button>
                    </div>
                </div>
            </form>
            <Modal show={this.state.modalResponseShow} onHide={this.handleCloseModalResponse} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                <Modal.Body>
                <div className="d-flex flex-column h-100 bg-blur_w2">
                    <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                        <h5 className="h5 m-0 text-center width100">
                        <i className="fa fa-bell"></i> {this.translate("MODAL_STATUS_STATUS")}</h5>
                        <a href="/" onClick={e=>this.handleCloseModalResponse(e)} className="ml-auto">
                            <span className="text-lg l-h-1x">×</span>
                        </a>
                    </div>
                    <div className="row position10 padding-20px">
                        <span className="col-12 text-center py-2 lead">
                            {this.state.msgResponseShow}
                        </span>
                    </div>
                </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    
}

export default withRouter(UserRegister)