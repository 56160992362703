import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'

//import api from '../../../Services/Api';

import Modal from 'react-bootstrap/Modal';

class AttendanceConfigAutomaticAnswer extends Component {

    constructor(props){
        super(props);
        this.state = {
            isModalResponseVisible: false,
            msgResponseShow: "",
            automaticAnswer: ""
        }
        this.translate = this.props.translate
    }
   
    handleCloseModalResponse = (e = null) =>{
        if (e) e.preventDefault()
        this.setState({isModalResponseVisible: false})
    }

    getAutomaticAnswer = async () => {
        let response
        try {
            //let response = await api.get("")
            if(response && response.data.automaticAnswer){
                this.setState({automaticAnswer: response.data.automaticAnswer})
            }
            
        } catch (error) {
            console.log(error)
        }
    }

    handleSubmitAutomaticAnswer = async (e) => {
        e.preventDefault()

        try {
            //let response = await api.post("", {automaticAnswer: this.state.automaticAnswer})
            let response = {data: {success: false}}
            if(response && response.data.success){
                this.setState({msgResponseShow: this.props.translate("ATTENDANCE_CONFIG_AUTOMATIC_ANSWER_AUTOMATIC_ANSWER_REGISTERED_WITH_SUCCESS"), isModalResponseVisible: true})
            } else {
                this.setState({msgResponseShow: this.props.translate("ATTENDANCE_CONFIG_AUTOMATIC_ANSWER_IT_WASNT_POSSIBLE_TO_REGISTER_AUTOMATIC_ANSWER"), isModalResponseVisible: true})
            }
        } catch (error) {
            console.log(error)
        }
    }

    componentDidMount = () => {
        this.getAutomaticAnswer()
    }

    render() {

        return (
            <React.Fragment>
                <div id="accordion_attendance_automatic_answer">
                    <div className="card mb-3">
                        <div className="card-header btn-cursor-pointer dark" onClick={()=>this.props.toggleAccordionPannel(`#collapse_attendance_config_automatic_answer`)} id="heading_attendance_config_automatic_answer">
                            <h5 className="mb-0" data-toggle="collapse"  aria-expanded="true" aria-controls="collapse_attendance_config_automatic_answer">
                                {this.translate("ATTENDANCE_CONFIG_AUTOMATIC_ANSWER_OPENING_HOURS")}
                                <i className="fa fa-caret-down pull-right"></i>
                            </h5>
                        </div>

                        <div id="collapse_attendance_config_automatic_answer" className="collapse dark show" aria-labelledby="heading_attendance_config_automatic_answer" data-parent="#accordion_attendance_automatic_answer">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <form className="px-4" onSubmit={this.handleSubmitAutomaticAnswer}>
                                            <div className="form-group">
                                                <label htmlFor="textarea_automatic_answer">{this.translate("ATTENDANCE_CONFIG_AUTOMATIC_ANSWER_OUTSIDE_OPENING_HOURS")}</label>
                                                <textarea value={this.state.automaticAnswer} onChange={(e)=>this.setState({automaticAnswer: e.target.value})} className="form-control" id="textarea_automatic_answer" rows="3" placeholder={this.translate("ATTENDANCE_CONFIG_AUTOMATIC_ANSWER_ENTER_THE_AUTOMATIC_ANSWER")} required></textarea>
                                            </div>
                                            <div className="form-group">
                                                <button className="col-12 ml-auto btn btn-primary blue btn-shadow d-flex justify-content-center align-items-center btn-cursor-pointer" style={{maxWidth: 300}}>
                                                    <i className="fa fa-floppy-o"></i>&nbsp;{this.translate("ATTENDANCE_CONFIG_AUTOMATIC_ANSWER_REGISTER_OUTSIDE_OPENING_HOURS")}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.isModalResponseVisible} onHide={this.handleCloseModalResponse} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-bell"></i> Status</h5>
                                <a href="/" onClick={e=>this.handleCloseModalResponse(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                <span className="col-12 text-center py-2 lead">
                                    {this.state.msgResponseShow}
                                </span>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(AttendanceConfigAutomaticAnswer);