import React, { Component } from "react";

import $ from "jquery";

import "datatables.net-dt";
import "datatables.net-bs4";
import "datatables.net-responsive";
import "datatables.net-responsive-bs4";

import api from "../../../../Services/Api";
import { ajaxUrl, ajaxToken } from "../../../../Services/Ajax";

class ConstrutorDeVendas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableConstrutorDeVendas: null,
      imobs: [],
      formSaveConstrutorDeVendas: {
        subdominio: null,
        token: null,
        modulo: null,
        idempreendimento: null,
        broker: null,
      },
    };

    this.formSubmitSaveConstrutorDeVendas =
      this.formSubmitSaveConstrutorDeVendas.bind(this);
    this.deleteCRM = this.deleteCRM.bind(this);
    this.translate = this.props.translate;
  }

  async loadImobs() {
    try {
      const response = await api.get("/brokers/selects");
      this.setState({ imobs: response.data });
    } catch (err) {
      console.log(err);
    }
  }

  componentDidMount() {
    this.loadImobs();

    let tableConstrutorDeVendas = this.state.tableConstrutorDeVendas;
    tableConstrutorDeVendas = $("#brokers_construtordevendas").DataTable({
      searching: true,
      paging: false,
      info: false,
      responsive: true,
      language: {
        url: this.translate("DATATABLE_LANGUAGE_URL"),
      },
      ajax: {
        url: ajaxUrl("/crms/table/all/construtordevendas/active"),
        type: "GET",
        beforeSend: function (request) {
          request.setRequestHeader("Authorization", "Bearer " + ajaxToken());
        },
      },
      columnDefs: [
        {
          targets: -1,
          data: null,
          defaultContent: `<div class="btn-group btn-shadow">
                        <button class="delete btn btn-sm danger btn-cursor-pointer" title="${this.translate(
                          "CONSTRUTOR_DE_VENDAS_REMOVE_INTEGRATION"
                        )}">
                            <i class="fa fa-trash" title="${this.translate(
                              "CONSTRUTOR_DE_VENDAS_REMOVE_INTEGRATION"
                            )}"></i>
                        </button>
                    </div>`,
        },
      ],
    });

    $("#brokers_construtordevendas tbody").on(
      "click",
      ".delete",
      function (e) {
        e.preventDefault();

        let data = [];

        $(e.target)
          .prop("disabled", true)
          .html('<i className="fa fa-spinner"></i>');

        if ($(e.target).parents("tr").hasClass("child")) {
          data = tableConstrutorDeVendas
            .row($(e.target).parents("tr").prev(".parent"))
            .data();
        } else {
          data = tableConstrutorDeVendas.row($(e.target).parents("tr")).data();
        }

        this.deleteCRM(data[1]);
      }.bind(this)
    );
  }

  async deleteCRM(id) {
    const data = { id: id };
    try {
      const response = await api.post("/crms/update", data);
      if (response.data.success === true) {
        this.reloadDatatableCRMs();
      } else {
        console.log("error");
      }
    } catch (err) {
      console.log(err);
    }
  }

  reloadDatatableCRMs() {
    $("#brokers_construtordevendas").DataTable().ajax.reload();
  }

  async formSubmitSaveConstrutorDeVendas(e) {
    e.preventDefault();
    $("#add_construtordevendas")
      .prop("disabled", true)
      .html(`<i className="fa fa-spinner"></i> ${this.translate("ICON_WAIT")}`);
    const data = this.state.formSaveConstrutorDeVendas;
    try {
      const response = await api.post("/crms/create/construtordevendas", data);
      if (response.data.success === true) {
        this.reloadDatatableCRMs();
        $("#add_construtordevendas")
          .prop("disabled", false)
          .html(`<i className="fa fa-save"></i> ${this.translate("ICON_ADD")}`);
      } else {
        console.log("error");
      }
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div id="accordionConstrutorDeVendas" style={{ width: "100%" }}>
            <div
              className="col-12 panel box no-border mb-2"
              style={{ width: "100%" }}
            >
              <div className="box-header b-b p-y-sm fwhite">
                <button
                  type="button"
                  style={{ width: "100%" }}
                  className="btn btn-transparent"
                  data-parent="#accordionConstrutorDeVendas"
                  onClick={(e) =>
                    this.props.toggleCollapse("#formConstrutorDeVendas")
                  }
                  aria-expanded="true"
                >
                  {this.translate(
                    "CONSTRUTOR_DE_VENDAS_ADD_CONSTRUTOR_DE_VENDAS_ACCOUNT"
                  )}
                  <span className="float-right badge text-sm">
                    <i className="fa fa-angle-down"></i>
                  </span>
                </button>
              </div>

              <div id="formConstrutorDeVendas" className="in collapse">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <form
                    onSubmit={this.formSubmitSaveConstrutorDeVendas}
                    id="form_config_construtordevendas"
                  >
                    <br />
                    <div>
                      <div className="col-sm-12 col-md-12 mb20">
                        <input
                          type="text"
                          onChange={(e) =>
                            this.setState({
                              formSaveConstrutorDeVendas: {
                                ...this.state.formSaveConstrutorDeVendas,
                                subdominio: e.target.value,
                              },
                            })
                          }
                          name="subdominio"
                          id="config_crmConstrutorDeVendas_subdominio"
                          placeholder={this.translate(
                            "CONSTRUTOR_DE_VENDAS_COMPANY_URL"
                          )}
                          className="form-control fwhite"
                          required="required"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="col-sm-12 col-md-12 mb20">
                        <input
                          type="text"
                          onChange={(e) =>
                            this.setState({
                              formSaveConstrutorDeVendas: {
                                ...this.state.formSaveConstrutorDeVendas,
                                token: e.target.value,
                              },
                            })
                          }
                          name="token"
                          id="config_crmConstrutorDeVendas_token"
                          placeholder={this.translate(
                            "CONSTRUTOR_DE_VENDAS_COMPANY_TOKEN"
                          )}
                          className="form-control fwhite"
                          required="required"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="col-sm-12 col-md-12 mb20">
                        <input
                          type="text"
                          onChange={(e) =>
                            this.setState({
                              formSaveConstrutorDeVendas: {
                                ...this.state.formSaveConstrutorDeVendas,
                                modulo: e.target.value,
                              },
                            })
                          }
                          name="modulo"
                          id="config_crmConstrutorDeVendas_modulo"
                          placeholder={this.translate(
                            "CONSTRUTOR_DE_VENDAS_CRM_MODULE"
                          )}
                          className="form-control fwhite"
                          required="required"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="col-sm-12 col-md-12 mb20">
                        <input
                          onChange={(e) =>
                            this.setState({
                              formSaveConstrutorDeVendas: {
                                ...this.state.formSaveConstrutorDeVendas,
                                idempreendimento: e.target.value,
                              },
                            })
                          }
                          name="idempreendimento"
                          id="config_crmConstrutorDeVendas_idempreendimento"
                          placeholder={this.translate(
                            "CONSTRUTOR_DE_VENDAS_PRODUCT_ID"
                          )}
                          className="form-control fwhite"
                          type="text"
                          required="required"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="col-sm-12 col-md-12 mb20">
                        <select
                          onChange={(e) =>
                            this.setState({
                              formSaveConstrutorDeVendas: {
                                ...this.state.formSaveConstrutorDeVendas,
                                broker: e.target.value,
                              },
                            })
                          }
                          name="broker"
                          id="imobhypnobox"
                          className="form-control box-shadow cool-select"
                          required="required"
                        >
                          <option value="">
                            {this.translate(
                              "CONSTRUTOR_DE_VENDAS_SELECT_BROKER"
                            )}
                          </option>
                          {this.state.imobs.map((data, i) => (
                            <option key={i} value={data.id}>
                              {data.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div>
                      <div className="col-sm-12 col-md-12 mb20">
                        <button
                          id="add_construtordevendas"
                          type="submit"
                          className="pull-right btn btn-success px-5 btn-rounded btn-shadow"
                        >
                          <i className="fa fa-plus"></i>{" "}
                          {this.translate("ICON_ADD")}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 p-1 text-left dark">
            <table
              id="brokers_construtordevendas"
              className="table table-striped table-bordered dt-responsive nowrap"
              style={{ width: "100%" }}
            >
              <thead className="dark">
                <tr className="text-left">
                  <th>{this.translate("CONSTRUTOR_DE_VENDAS_BROKER")}</th>
                  <th>{this.translate("TABLE_ACTIONS_ROW")}</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ConstrutorDeVendas;
