import React, {Component} from 'react';
import MetricsModal from "../MetricsModal"

import $ from "jquery"
import Chart from 'chart.js'
import {app, hexToRGB} from '../../../../Structure/Configs/Theme'

export default class PieChartMedium extends Component{

    constructor(props){
        super(props)
        this.state = {
            isMetricsModalVisible: false, 
            isEditingCard: false,
            iconFilter: "",
            isIconDropdownVisible: false,
            chartId: `${new Date().getTime()}${Math.floor(Math.random() * 9999)}`,
            colors: [
                "#28a745",
                "#dc3545",
                "#007bff",
                "#fd7e14",
                "#6f42c1",
                "#e83e8c",
                "#ffc107",
                "#17a2b8",
                "#343a40",
                "#20c997",
                "#6610f2",
                "#868e96",
            ]
        }
    }

    handleCloseMetricsModal = (e = null) => {
        if(e) e.preventDefault()
        this.setState({isMetricsModalVisible: false})
    }
    
    handleShowMetricsModal = (e = null) => {
        if(this.state.isEditingCard) return
        if(e) e.preventDefault()
        this.setState({isMetricsModalVisible: true})
    }
    

    handleCardEditButtonClick = () => {
        this.setState({isMetricsModalVisible: false})
    }

    disposeTooltip = () => $('[data-toggle="tooltip"]').tooltip('dispose')

    getRandomColor = () => {
        var letters = '0123456789ABCDEF'.split('');
        var color = '#';
        for (var i = 0; i < 6; i++ ) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    getColors = () => {
        let {colors} = this.state
        
        while(colors.length < this.props.values.value.values.length){
            colors.push(this.getRandomColor())
        }
        this.setState({colors})
    }

    componentDidMount = () => {
        this.getColors()
        $('[data-toggle="tooltip"]').tooltip('dispose').tooltip({placement: "bottom", trigger: "hover"})

        const myChart = document.getElementById(`chart_${this.state.chartId}`).getContext('2d')

        new Chart(myChart, {
            type: "pie",
            data: {
                labels: this.props.values.value.label,
                datasets: [
                    {
                        type: 'pie',
                        data: this.props.values.value.values,
                        fill: true,
                        backgroundColor: this.state.colors,
                        borderColor: hexToRGB(app.color.primary, 1),
                        borderWidth: 2,
                        borderJoinStyle: 'miter',
                        pointBorderColor: hexToRGB(app.color.primary, 1),
                        pointBackgroundColor: '#fff',
                        pointBorderWidth: 2,
                        pointHoverRadius: 2,
                        pointHoverBackgroundColor: hexToRGB(app.color.primary, 1),
                        pointHoverBorderColor: '#fff',
                        pointHoverBorderWidth: 1,
                        pointRadius: 3
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false
            }
        })
        
    }

    componentDidUpdate = () => {
        $('[data-toggle="tooltip"]').tooltip('dispose').tooltip({placement: "bottom", trigger: "hover"})
    }
    
    
    render = () =>{
        
        return(
            <React.Fragment>
                <div className={`col-12 col-md-6 btn-cursor-pointer hover-shadow active-dark-background ${this.props.className ? this.props.className : ""}`}>
                    <div className="dashboard_performance_card position10">
                        {this.props.isEditing ?
                            <div className="d-flex align-items-center">
                                <p className="uppercase text-muted f10 flex-grow-1 m-0">{this.props.values.title || this.props.translate("DASHBOARD_PERFORMANCE_CARD_ENTER_THE_CARDS_TITLE")}</p>
                                <div>
                                    {this.state.isEditingCard ?
                                        <React.Fragment>
                                            <button onClick={e=>this.props.handleCardChangeMainMetric(this.props.id, this.props.stageId)} className="btn btn-sm ml-1 btn-warning btn-cursor-pointer" data-toggle="tooltip" title={this.props.translate("DASHBOARD_PERFORMANCE_CARD_CHANGE_CARD_MAIN_METRIC")}>
                                                <i className="fa fa-line-chart"></i>
                                            </button>

                                            <button onClick={e=>this.props.handleCardRemove(this.props.id, this.props.stageId)} className="btn btn-sm ml-1 btn-danger btn-cursor-pointer" data-toggle="tooltip" title={this.props.translate("DASHBOARD_PERFORMANCE_CARD_REMOVE_CARD")}>
                                                <i className="fa fa-trash"></i>
                                            </button>

                                            <button onClick={e=>{this.disposeTooltip();this.setState({isEditingCard: false})}} className="btn btn-sm ml-1 btn-success btn-cursor-pointer" data-toggle="tooltip" title={this.props.translate("DASHBOARD_PERFORMANCE_CARD_CONFIRM_CHANGES")}>
                                                <i className="fa fa-check"></i>
                                            </button>
                                        </React.Fragment>
                                    
                                    :
                                        <button onClick={e=>{this.disposeTooltip();this.setState({isEditingCard: true})}} className="btn btn-sm btn-warning btn-cursor-pointer" data-toggle="tooltip" title={this.props.translate("DASHBOARD_PERFORMANCE_CARD_EDIT_CARD")}>
                                            <i className="fa fa-edit"></i>
                                        </button>
                                    }
                                        
                                </div>
                            </div>
                        :
                            <div className="d-flex align-items-center">
                                <p className="uppercase text-muted f10 flex-grow-1 m-0">{this.props.values.title || this.props.translate("DASHBOARD_PERFORMANCE_CARD_ENTER_THE_CARDS_TITLE")}</p>
                                {this.props.checkIsAlreadyBeingCompared ?
                                    <button onClick={e=>{this.disposeTooltip();this.props.handleRemoveFromComparison(this.props.id, this.props.stageId)}} className="btn btn-sm btn-danger btn-cursor-pointer" data-toggle="tooltip" title={this.props.translate("DASHBOARD_PERFORMANCE_CARD_REMOVE_FROM_COMPARISON")}>
                                        <i className="fa fa-pie-chart"></i>
                                    </button>
                                :
                                    this.props.checkIfCardCanBeCompared &&
                                        <button onClick={e=>{this.disposeTooltip();this.props.handleAddToComparison(this.props.id, this.props.stageId, this.props.main_metric_key, this.props.values, this.props.comparison)}} className="btn btn-sm btn-primary btn-cursor-pointer" data-toggle="tooltip" title={this.props.translate("DASHBOARD_PERFORMANCE_CARD_COMPARE_CARD")}>
                                            <i className="fa fa-pie-chart"></i>
                                        </button>
                                }
                                
                            </div>
                        }

                        <div className="card-content" onClick={()=>{
                            if(!this.props.onClick) {
                                this.handleShowMetricsModal()
                            } else {
                                this.props.onClick()
                            }
                        }}>
                            <div className="d-flex width100 text-center">
                                {this.state.isEditingCard &&
                                    <div className="flex-grow-1">
                                        <input 
                                            type="text" 
                                            value={this.props.values.title} 
                                            onChange={e=>this.props.handleCardChange(e, "title", this.props.id, this.props.stageId)}
                                            placeholder={this.props.translate("DASHBOARD_PERFORMANCE_CARD_ENTER_THE_CARDS_TITLE")} 
                                            className="form-control col-12 flex-grow-1 mb-2"
                                        />
                                    </div>
                                }
                            </div>
                            <div className="col-12 px-0">
                                <div className="padding bg-blur_w no_after_pointer_events position10">
                                    <canvas id={`chart_${this.state.chartId}`} height={300} className="chartjs-render-monitor m-auto"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <MetricsModal handleCloseMetricsModal={this.handleCloseMetricsModal} isMetricsModalVisible={this.state.isMetricsModalVisible} cardTitle={this.props.values.title} {...this.props} />
            </React.Fragment>
        )
    }
}



