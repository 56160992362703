import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'

import MenuMain from '../../Structure/MenuMain';
import HeaderMain from '../../Structure/HeaderMain';
import FooterMain from '../../Structure/FooterMain';
import api from '../../Services/Api';
import CardBuilder from './Components/CardBuilder';
import ComparisonModal from './Components/ComparisonModal';

class DashboardPerformance extends Component{

    constructor(props){
        super(props)
        this.state = {
            dashboard: {},
            comparisonJsonStructure: [],
            isComparisonModalVisible: false,
        }
        this.translate = this.props.translate
        document.title = this.translate("DASHBOARD_PERFORMANCE_DASHBOARD_PERFORMANCE")
    }

    handleCloseComparisonModal = (e = null) => {
        if (e) e.preventDefault()
        this.setState({ isComparisonModalVisible: false })
    }

    getDashboardMockup = () => {
        return {
            "title": "Dashboard de teste 12",
            "short_description": "Apenas uma dashboard de teste que estas sendo testado",
            "color": "#2a9bcb",
            "app": [
              {
                "title": "Todas as Segmentações",
                "id": 1600101353539,
                "cards": [
                  {
                    "id": 1600101402611,
                    "type": "card_medium_total",
                    "main_metric_key": "total_leads",
                    "values": {
                      "title": "Número total de leads",
                      "icon": "fa-users",
                      "value": 30,
                      "short_description": "Exemplo de Descrição",
                      "color": "#17a2b8"
                    },
                    "comparison": [
                      "total_suspects"
                    ],
                    "metrics_to_be_chosen": [],
                    "metrics": [
                      {
                        "metric_key": "total_info_per_months",
                        "title": "Total de leads no periodo de X meses",
                        "views": [
                          {
                            "view_code": "total_info_per_month_ranking_list",
                            "view_name": "Ranking com o total de leads por mês",
                            "view_values": [
                              {
                                "date": "Mai",
                                "value": 70
                              },
                              {
                                "date": "Abr",
                                "value": 50
                              },
                              {
                                "date": "Mar",
                                "value": 40
                              },
                              {
                                "date": "Fev",
                                "value": 30
                              },
                              {
                                "date": "jan",
                                "value": 20
                              }
                            ]
                          }
                        ],
                        "views_to_be_chosen": [
                          {
                            "view_code": "total_info_per_month_line_chart",
                            "view_name": "Gráfico de linha mês a mês com total de leads por mês",
                            "view_values": {
                              "label": [
                                "Jan",
                                "Fev",
                                "Mar",
                                "Abr",
                                "Mai"
                              ],
                              "values": [
                                20,
                                30,
                                40,
                                50,
                                70
                              ]
                            }
                          },
                          {
                            "view_code": "total_info_per_month_bar_chart",
                            "view_name": "Gráfico de barras mês a mês com total de leads por mês",
                            "view_values": {
                              "label": [
                                "Jan",
                                "Fev",
                                "Mar",
                                "Abr",
                                "Mai"
                              ],
                              "values": [
                                20,
                                30,
                                40,
                                50,
                                70
                              ]
                            }
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "id": 1600101647761,
                    "type": "card_medium_total",
                    "main_metric_key": "total_suspects",
                    "values": {
                      "title": "Número total de suspects",
                      "icon": "fa-hashtag",
                      "value": 30,
                      "short_description": "Card de Suspects",
                      "color": "#1b1230"
                    },
                    "comparison": [
                      "total_leads"
                    ],
                    "metrics_to_be_chosen": [
                      {
                        "metric_key": "total_info_per_years",
                        "title": "Total de suspects no periodo de X anos",
                        "views": [],
                        "views_to_be_chosen": [
                          {
                            "view_code": "total_info_per_month_line_chart",
                            "view_name": "Gráfico de linha ano a ano com total de suspects por anos",
                            "view_values": {
                              "label": [
                                "2007",
                                "2008",
                                "2009",
                                "2010",
                                "2011"
                              ],
                              "values": [
                                20,
                                30,
                                40,
                                50,
                                70
                              ]
                            }
                          },
                          {
                            "view_code": "total_info_per_month_bar_chart",
                            "view_name": "Gráfico de barras ano a ano com total de suspects por ano",
                            "view_values": {
                              "label": [
                                "2007",
                                "2008",
                                "2009",
                                "2010",
                                "2011"
                              ],
                              "values": [
                                20,
                                30,
                                40,
                                50,
                                70
                              ]
                            }
                          },
                          {
                            "view_code": "total_info_per_month_ranking_list",
                            "view_name": "Ranking com o total de suspects por ano",
                            "view_values": [
                              {
                                "date": "2007",
                                "value": 70
                              },
                              {
                                "date": "2008",
                                "value": 50
                              },
                              {
                                "date": "2009",
                                "value": 40
                              },
                              {
                                "date": "2010",
                                "value": 30
                              },
                              {
                                "date": "2011",
                                "value": 20
                              }
                            ]
                          }
                        ]
                      }
                    ],
                    "metrics": [
                      {
                        "metric_key": "total_info_per_months",
                        "title": "Total de suspects no periodo de Y meses",
                        "views": [
                            {
                                "view_code": "pie_chart",
                                "view_name": "Gráfico de pizza ano a ano com total de suspects por anos",
                                "view_values": {
                                  "label": [
                                    "2007",
                                    "2008",
                                    "2009",
                                    "2010",
                                    "2011"
                                  ],
                                  "values": [
                                    20,
                                    30,
                                    40,
                                    50,
                                    70
                                  ]
                                }
                            },
                          {
                            "view_code": "total_info_per_month_ranking_list",
                            "view_name": "Ranking com o total de suspects por mês",
                            "view_values": [
                              {
                                "date": "Mai",
                                "value": 70
                              },
                              {
                                "date": "Abr",
                                "value": 50
                              },
                              {
                                "date": "Mar",
                                "value": 40
                              },
                              {
                                "date": "Fev",
                                "value": 30
                              },
                              {
                                "date": "jan",
                                "value": 20
                              }
                            ]
                          },
                          {
                            "view_code": "total_info_per_month_bar_chart",
                            "view_name": "Gráfico de barras mês a mês com total de suspects por mês",
                            "view_values": {
                              "label": [
                                "Jan",
                                "Fev",
                                "Mar",
                                "Abr",
                                "Mai"
                              ],
                              "values": [
                                20,
                                30,
                                40,
                                50,
                                70
                              ]
                            }
                          }
                        ],
                        "views_to_be_chosen": [
                          {
                            "view_code": "total_info_per_month_line_chart",
                            "view_name": "Gráfico de linha mês a mês com total de suspects por mês",
                            "view_values": {
                              "label": [
                                "Jan",
                                "Fev",
                                "Mar",
                                "Abr",
                                "Mai"
                              ],
                              "values": [
                                20,
                                30,
                                40,
                                50,
                                70
                              ]
                            }
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "id": 1600101667458,
                    "type": "card_small_total",
                    "main_metric_key": "total_suspects",
                    "values": {
                      "title": "Número total de suspects",
                      "icon": "fa-hashtag",
                      "value": 552,
                      "short_description": "Card de Suspects",
                      "color": "#ffa99a"
                    },
                    "comparison": [
                      "total_leads"
                    ],
                    "metrics_to_be_chosen": [
                      {
                        "metric_key": "total_info_per_months",
                        "title": "Total de suspects no periodo de Y meses",
                        "views": [],
                        "views_to_be_chosen": [
                          {
                            "view_code": "total_info_per_month_line_chart",
                            "view_name": "Gráfico de linha mês a mês com total de suspects por mês",
                            "view_values": {
                              "label": [
                                "Jan",
                                "Fev",
                                "Mar",
                                "Abr",
                                "Mai"
                              ],
                              "values": [
                                20,
                                30,
                                40,
                                50,
                                70
                              ]
                            }
                          },
                          {
                            "view_code": "total_info_per_month_bar_chart",
                            "view_name": "Gráfico de barras mês a mês com total de suspects por mês",
                            "view_values": {
                              "label": [
                                "Jan",
                                "Fev",
                                "Mar",
                                "Abr",
                                "Mai"
                              ],
                              "values": [
                                20,
                                30,
                                40,
                                50,
                                70
                              ]
                            }
                          },
                          {
                            "view_code": "total_info_per_month_ranking_list",
                            "view_name": "Ranking com o total de suspects por mês",
                            "view_values": [
                              {
                                "date": "Mai",
                                "value": 70
                              },
                              {
                                "date": "Abr",
                                "value": 50
                              },
                              {
                                "date": "Mar",
                                "value": 40
                              },
                              {
                                "date": "Fev",
                                "value": 30
                              },
                              {
                                "date": "jan",
                                "value": 20
                              }
                            ]
                          }
                        ]
                      }
                    ],
                    "metrics": [
                      {
                        "metric_key": "total_info_per_years",
                        "title": "Total de suspects no periodo de X anos",
                        "views": [
                          {
                            "view_code": "total_info_per_month_line_chart",
                            "view_name": "Gráfico de linha ano a ano com total de suspects por anos",
                            "view_values": {
                              "label": [
                                "2007",
                                "2008",
                                "2009",
                                "2010",
                                "2011"
                              ],
                              "values": [
                                20,
                                30,
                                40,
                                50,
                                70
                              ]
                            }
                          }
                        ],
                        "views_to_be_chosen": [
                          {
                            "view_code": "total_info_per_month_bar_chart",
                            "view_name": "Gráfico de barras ano a ano com total de suspects por ano",
                            "view_values": {
                              "label": [
                                "2007",
                                "2008",
                                "2009",
                                "2010",
                                "2011"
                              ],
                              "values": [
                                20,
                                30,
                                40,
                                50,
                                70
                              ]
                            }
                          },
                          {
                            "view_code": "total_info_per_month_ranking_list",
                            "view_name": "Ranking com o total de suspects por ano",
                            "view_values": [
                              {
                                "date": "2007",
                                "value": 70
                              },
                              {
                                "date": "2008",
                                "value": 50
                              },
                              {
                                "date": "2009",
                                "value": 40
                              },
                              {
                                "date": "2010",
                                "value": 30
                              },
                              {
                                "date": "2011",
                                "value": 20
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ],
                "key": "all_segmentation"
              }
            ]
          }
    }

    loadDashboardPerformance = async (dashboardId) => {
        if(!dashboardId) return

        try {
            //let response = await api.get(`/attendances/dashboard_performance/${dashboardId}`)
            let response = {
                data: {
                    success: true,
                    dashboard: this.getDashboardMockup()
                }
            }
            if(response && response.data && response.data.dashboard){
                this.setState({
                    dashboard: response.data.dashboard
                })
            }
        } catch(error) {
            console.log(error)
        }
    }

    renderDashboardPerformance = () => {
        if(!this.state.dashboard.app || !this.state.dashboard.app.length) return
        return (
            this.state.dashboard.app.map((stage, index)=>
                <React.Fragment key={`${stage.title}${index}`}>
                    <div className="row">
                        <CardBuilder translate={this.translate} type={"stage"} stage={stage} />
                    </div>
                    <div className="row">
                        {stage.cards && stage.cards.length > 0 &&
                            stage.cards.map((card, cardIndex)=>
                                <CardBuilder key={`${card.type}${cardIndex}`}
                                {...card} 
                                stageId={stage.id}
                                dashboardTitle={this.state.dashboard.title} 
                                checkIfCardCanBeCompared={this.checkIfCardCanBeCompared(card, stage)}
                                checkIsAlreadyBeingCompared={this.checkIsAlreadyBeingCompared(card.id, stage.id)}
                                handleAddToComparison={this.handleAddToComparison}
                                handleRemoveFromComparison={this.handleRemoveFromComparison}
                                translate={this.translate} />
                            )
                        }
                    </div>
                </React.Fragment>
            )
        )
    }

    checkIfCardCanBeCompared = (card, stage) => {
        let {comparisonJsonStructure} = this.state
        let checkCanBeCompared = true
        let comparison = card.comparison
        let metricKey = card.main_metric_key

        if(!comparison || comparison.length === 0) checkCanBeCompared = false
        if(comparisonJsonStructure && comparisonJsonStructure.length > 3) checkCanBeCompared = false
        if(this.checkIsAlreadyBeingCompared(card.id, stage.id)) checkCanBeCompared = false

        let comparisonArray = this.getComparisonArray()

        if(comparisonArray && comparisonArray.length > 0 && comparisonArray.indexOf(metricKey) === -1) checkCanBeCompared = false
        else if(comparisonArray && comparisonArray.length === 0) checkCanBeCompared = false
        return checkCanBeCompared
    }

    getComparisonArray = () => {
        let {comparisonJsonStructure} = this.state
        if(!comparisonJsonStructure || comparisonJsonStructure.length === 0) return false

        let arrayOfComparisonArrays = comparisonJsonStructure.map(comparison=>comparison.comparison)

        let intersectionArray = arrayOfComparisonArrays.reduce((lastArray, nextArray)=>{
            let intersection = lastArray.filter(x => nextArray.includes(x))
            return intersection
        })

        return intersectionArray
    }

    checkIsAlreadyBeingCompared = (cardId, stageId) => {
        let {comparisonJsonStructure} = this.state
        let checkIsAlreadyBeingCompared = false
        
        if(!comparisonJsonStructure || comparisonJsonStructure.length === 0) return false

        comparisonJsonStructure.forEach(cardComparison=>{
            if(cardComparison.stageId === stageId && cardComparison.cardId === cardId){
                checkIsAlreadyBeingCompared = true
            }
        })
        return checkIsAlreadyBeingCompared
    }

    handleAddToComparison = (cardId, stageId, mainMetricKey, cardValues, comparison) => {
        let {comparisonJsonStructure} = this.state


        comparisonJsonStructure.push({
            cardId,
            stageId,
            mainMetricKey,
            values: cardValues,
            comparison: comparison
        })
        console.log(JSON.stringify(comparisonJsonStructure))
        this.setState({comparisonJsonStructure})
    }

    handleRemoveFromComparison = (cardId, stageId) => {
       let {comparisonJsonStructure} = this.state

       console.log(comparisonJsonStructure)
       comparisonJsonStructure = comparisonJsonStructure.filter(cardComparison=>{
           if(cardComparison.cardId === cardId && cardComparison.stageId === stageId){
               return false
           } else {
               return true
           }
       })
       console.log(comparisonJsonStructure)
       this.setState({comparisonJsonStructure})

    }

    componentDidMount = () => {
        let dashboardId = document.location.hash.replace('#','')
        if(!dashboardId) this.props.history.push('/choose_dashboard_performance')
        else this.loadDashboardPerformance(dashboardId)
        
    }
    
    render() {
        return (
            <React.Fragment>
                <MenuMain />
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    <HeaderMain title={this.translate("DASHBOARD_PERFORMANCE_DASHBOARD_PERFORMANCE")} onChange={this.handleChange}/>
                    <div className="content-main" id="content-main">
                        {this.state.comparisonJsonStructure && this.state.comparisonJsonStructure.length > 1 &&
                            <div className="dashboard_comparison_float_button">
                                <button className="btn btn-primary btn-cursor-pointer" onClick={()=>this.setState({isComparisonModalVisible: true})}>
                                    <i className="fa fa-pie-chart" />&nbsp;
                                    {this.translate("DASHBOARD_PERFORMANCE_COMPARISON_CARDS_COMPARISON")}
                                </button>
                            </div>
                        }
                        <div className="padding pt-0">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row box-shadow padding">
                                      <div className="col-12 d-flex align-items-center">
                                        <div className="d-flex flex-column mr-5 flex-grow-1">
                                          <h3 className="pr-2">
                                              <i className={`fa fa-tachometer f32 text-center`} style={{color: this.state.dashboard.color}}></i> &nbsp;
                                              {this.state.dashboard.title}
                                          </h3>
                                          
                                          <h5>{this.state.dashboard.short_description}</h5>
                                        </div>
                                        <a href="/choose_dashboard_performance" className="btn btn-default green btn-cursor-pointer">
                                            <i className="fa fa-arrow-circle-left" /> &nbsp;
                                            {this.translate("DASHBOARD_PERFORMANCE_GO_BACK")}
                                        </a>
                                      </div>
                                    </div>
                                    <hr />  
                                </div>
                                <div className="col-12 mx-1">
                                   {this.renderDashboardPerformance()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterMain />
                </div>
                <ComparisonModal 
                    dashboardTitle={this.state.dashboard.title} 
                    comparisonJsonStructure={this.state.comparisonJsonStructure}
                    isComparisonModalVisible={this.state.isComparisonModalVisible} 
                    handleCloseComparisonModal={this.handleCloseComparisonModal} 
                    translate={this.translate}
                />
            </React.Fragment>
        );
    }
}

export default withRouter(DashboardPerformance)