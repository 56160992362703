import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'

import $ from 'jquery';

import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4'

import {ajaxUrl, ajaxToken} from '../../../../Services/Ajax';

import api from '../../../../Services/Api';

class AgentsList extends Component {

    constructor(props){
        super(props);
        this.state = {
            tableLoadAgents: null,
        }
        this.translate = this.props.translate
    }

    componentDidMount = async () => {
        let tableLoadAgents = this.state.tableLoadAgents
        const props = this.props;
        tableLoadAgents = $('#list_agents').DataTable({
            "searching": true,
            "paging": true,
            "info": false,
            "responsive": true,
            "language": {
                "url": this.translate("DATATABLE_LANGUAGE_URL")
            },
            "ajax": {
                "url": ajaxUrl('/agents/'+ props.idBroker +'/table'),
                "type": 'GET',
                "beforeSend": function (request) {
                    request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                }
            },
            "columnDefs": [{
                "targets": -1,
                "data": null,
                "defaultContent": 
                    `<div class="btn-group btn-shadow">
                        <button class="inactivate btn btn-sm btn-cursor-pointer warning" title="${this.translate("AGENTS_LIST_CHANGE_STATUS_BUTTON")}" role="button">
                            <i class="fa fa-ban" title="${this.translate("AGENTS_LIST_CHANGE_STATUS_BUTTON")}"></i> ${this.translate("AGENTS_LIST_CHANGE_STATUS_BUTTON")}
                        </button>
                        <button class="delete btn btn-sm btn-cursor-pointer danger" title="${this.translate("AGENTS_LIST_DELETE_AGENT_TITLE")}" role="button">
                            <i class="fa fa-user-times" title="${this.translate("TABLE_DELETE_BUTTON")}"></i> ${this.translate("TABLE_DELETE_BUTTON")}
                        </button>
                    </div>`
            }]
        });
        $('#list_agents tbody').on( 'click', '.view', function (event){
            event.preventDefault();
            let data = [];
            if($(this).parents('tr').hasClass('child')){
                data = tableLoadAgents.row($(this).parents('tr').prev('.parent')).data();
            }else{
                data = tableLoadAgents.row($(this).parents('tr')).data();
            }
            alert(data);
            //props.history.push('/admin_business#' + data[2]);
        });
        $('#list_agents tbody').on( 'click', '.inactivate', function (e){
            e.preventDefault();
            let data = [];
            if($(e.target).parents('tr').hasClass('child')){
                data = tableLoadAgents.row($(e.target).parents('tr').prev('.parent')).data();
            }else{
                data = tableLoadAgents.row($(e.target).parents('tr')).data();
            }

            const values = {
                action: 'set_status'
            };

            api.post('/agent/'+ data[7] +'/set_status', values).then(async response => {
                if(response.data.success){
                    $('#list_agents').DataTable().ajax.reload();
                }else{
                    alert(response.data.msg)
                }
            })

        });
        $('#list_agents tbody').on( 'click', '.delete', function (e){
            e.preventDefault();
            let data = [];
            if($(e.target).parents('tr').hasClass('child')){
                data = tableLoadAgents.row($(e.target).parents('tr').prev('.parent')).data();
            }else{
                data = tableLoadAgents.row($(e.target).parents('tr')).data();
            }

            const values = {
                action: 'disabled'
            };

            api.post('/agent/'+ data[7] +'/disabled', values).then(async response => {
                if(response.data.success){
                    $('#list_agents').DataTable().ajax.reload();
                }else{
                    alert(response.data.msg)
                }
            })
        });
    }

    render() {
        return (
            <React.Fragment>
                <h5 className="fwhite">{this.translate("AGENTS_LIST_LIST_OF_AGENTS")}</h5>
                <div className="bootstrap-table">
                    <div className="fixed-table-container">
                        <div className="fixed-table-body min-height100">
                            <table id="list_agents" className="table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                                <thead>
                                    <tr className="text-left">
                                        <th>{this.translate("AGENTS_LIST_NAME_OF_AGENT")}</th>
                                        <th>{this.translate("AGENTS_LIST_EMAIL_OF_AGENT")}</th>
                                        <th>{this.translate("AGENTS_LIST_PHONE_OF_AGENT")}</th>
                                        <th>{this.translate("AGENTS_LIST_NUMBER_OF_CALLS")}</th>
                                        <th>{this.translate("AGENTS_LIST_DATE_LAST_LEAD")}</th>
                                        <th>{this.translate("AGENTS_LIST_BROKER")}</th>
                                        <th>{this.translate("TABLE_STATUS_ROW")}</th>
                                        <th>{this.translate("TABLE_ACTIONS_ROW")}</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(AgentsList);