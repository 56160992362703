import api from '../../Services/Api'

import $ from 'jquery'

export const autocomplete = async (event) => {
    const element = document.getElementById(event.target.id)
    const name = event.target.getAttribute('name')
    const value = (event.type === 'paste')? event.clipboardData.getData('text/plain'): event.target.value;
    const data = {
        'field': name,
        'value': value
    }

    if(data.value.length > 5){
        try{
            const response = await api.post("/lead/autocomplete", {data});
            if(response.data.success === true){
                
                let html = '<div class="autocomplete-dev" style="background: #fff; padding: 5px; color: #000;">';
                response.data.leads.map((data) => {
                    html += '<div ref="autocomplete-dev-click" class="autocomplete-dev-click" data-name="'+ data.name +'" data-email="'+ data.email +'" data-phone="'+ data.phone +'" style="cursor: pointer; border-bottom: 1px solid #000; padding-bottom: 5px;">'+
                                'Nome: '+ data.name +'<br/>' +
                                'E-mail: '+ data.email +'<br/>' +
                                'Telefone: '+ data.phone +'<br/>' +
                            '</div>';
                    return true;
                }, {})
                html += '</div>';
                $('.autocomplete-dev').remove();
                $(element).closest('div').append(html);
                return response.data.leads
            }else{
                $('.autocomplete-dev').remove();
            }
        }catch(err){
            console.log('Falha na requisição do autocomplete')
        }
    }
}

export default autocomplete