import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';

import Modal from 'react-bootstrap/Modal';


import StandardStructure from '../Structure/StandardStructure';

//import api from '../Services/Api';

class DashboardBusiness extends Component {

    constructor(props){
        super(props);
        this.state = {
            app: null,
            background: true,
            id: 'content-main',
            classCustom: 'dash_client',
            showModal: false,
            selectedCard: {}
        }
    }

    getCards = async () => {
        try {
            //let response = await api.get("/build/dashboard/attendances")

            let response = {data: this.getDataMockup()}

            if(response.data && response.data.app){
                this.setState({
                    app: response.data.app,
                    background: response.data.background
                });
            }
        } catch (error) {
            console.log(error)
        }
    }

    handleCardActionClick = (cardType) => this.setState({showModal: true, selectedCard: cardType})

    renderModalContent = (e) => {
        try {
            //let response = await api.post("/build/dashboard/attendances/card", {card_type: cardType})
            let htmlMockup = `
            <div class="col-12 bg-dark">teste</div>
            
            `
            let response = {data: {card_content: htmlMockup}}
            

            if(response.data && response.data.card_content){
                document.querySelector("#modalContent").innerHTML = response.data.card_content
            }
        } catch (error) {
            console.log(error)
        }  
    }

    getDataMockup = () => (
        {
        "app": [
            {
                "row": "dashboardBannerPb10Pt20Mb20",
                "cards": [
                  {
                    "type": "card_attendances_fgecoc",
                    "fields": []
                  }
                ]
            },
            {
            "row": "simples",
            "cards": [
                {
                    "type": "card_pre_purchase_and_attendance_divisor_title",
                    "fields": []
                },
                {
                    "type": "card_pre_purchase_and_attendance_total",
                    "fields": {
                      "count_total": 250,
            
                    }
                },
                {
                    "type": "card_pre_purchase_and_attendance_attendance_average_time",
                    "fields": {
                      "average_time": "25 min",
            
                    }
                },
                {
                    "type": "card_pre_purchase_and_attendance_purchase_average_time",
                    "fields": {
                      "average_time": "15 min",
            
                    }
                },
                {
                    "type": "card_pre_purchase_and_attendance_attendance_maximum_time",
                    "fields": {
                      "maximum_time": "55 min",
            
                    }
                },
                {
                    "type": "card_pre_purchase_and_attendance_purchase_maximum_time",
                    "fields": {
                      "maximum_time": "35 min",
            
                    }
                },
                {
                    "type": "card_pre_purchase_and_attendance_average_evaluation_score",
                    "fields": {
                      "evaluation_score": 7.5,
            
                    }
                },
                {
                    "type": "card_pre_purchase_and_attendance_new_clients",
                    "fields": {
                      "count_new_clients": 300,
            
                    }
                },
                {
                    "type": "card_pre_purchase_and_attendance_loyal_clients",
                    "fields": {
                      "count_loyal_clients": 23,
            
                    }
                },
            ]
            },
            {
                "row": "simples",
                "cards": [
                    {
                        "type": "card_channels_divisor_title",
                        "fields": []
                    },
                    {
                        "type": "card_channels_channel_most_used",
                        "fields": {
                          "most_used_social_media": "Whatsapp",
                
                        }
                    },
                    {
                        "type": "card_channels_count_facebook",
                        "fields": {
                          "count_social_media": 14,
                        }
                    },
                    {
                        "type": "card_channels_count_instagram",
                        "fields": {
                            "count_social_media": 33,
                        }
                    },
                    {
                        "type": "card_channels_count_whatsapp",
                        "fields": {
                            "count_social_media": 70,
                        }
                    },
                    {
                        "type": "card_channels_count_twitter",
                        "fields": {
                          "count_social_media": 22,
                        }
                    },
                    {
                        "type": "card_channels_count_email",
                        "fields": {
                          "count_social_media": 12,
                        }
                    },
                    {
                        "type": "card_channels_count_chat",
                        "fields": {
                          "count_social_media": 24,
                        }
                    },
                    {
                        "type": "card_channels_count_phone",
                        "fields": {
                          "count_social_media": 43,
                        }
                    },
                ]
            },
            {
                "row": "simples",
                "cards": [
                    {
                        "type": "card_purchase_divisor_title",
                        "fields": []
                    },
                    {
                        "type": "card_purchase_purchases_total",
                        "fields": {
                          "count_total": 450,
                
                        }
                    },
                    {
                        "type": "card_purchase_new_clients",
                        "fields": {
                          "count_new_clients": 25,
                
                        }
                    },
                    {
                        "type": "card_purchase_shopper_maximum_time",
                        "fields": {
                          "maximum_time": "2 horas",
                        }
                    },
                    {
                        "type": "card_purchase_shopper_average_time",
                        "fields": {
                          "average_time": "1 hora",
                        }
                    },
                    {
                        "type": "card_purchase_average_ticket",
                        "fields": {
                          "average_ticket": "R$ 580",
                        }
                    },
                    {
                        "type": "card_purchase_maximum_ticket",
                        "fields": {
                          "maximum_ticket": "R$ 1580",
                        }
                    },
                    {
                        "type": "card_purchase_minimum_ticket",
                        "fields": {
                          "minimum_ticket": "R$ 100",
                        }
                    },
                    {
                        "type": "card_purchase_all_waiting_delivery",
                        "fields": {
                          "waiting_delivey": 150,
                        }
                    },
                    {
                        "type": "card_purchase_pop_waiting_delivery",
                        "fields": {
                          "waiting_delivey": 50,
                        }
                    },
                    {
                        "type": "card_purchase_prime_waiting_delivery",
                        "fields": {
                          "waiting_delivey": 60,
                        }
                    },
                    {
                        "type": "card_purchase_programmed_waiting_delivery",
                        "fields": {
                          "waiting_delivey": 40,
                        }
                    },
                ]
            },
            {
                "row": "simples",
                "cards": [
                    {
                        "type": "card_delivery_pop_divisor_title",
                        "fields": []
                    },
                    {
                        "type": "card_delivery_pop_total",
                        "fields": {
                          "count_total": 450,
                
                        }
                    },
                    {
                        "type": "card_delivery_pop_average_kilometer",
                        "fields": {
                          "average_kilometer": "20 km",
                
                        }
                    },
                    {
                        "type": "card_delivery_pop_average_shipping_ticket",
                        "fields": {
                          "average_ticket": "R$ 8,00",
                        }
                    },
                    {
                        "type": "card_delivery_pop_average_time",
                        "fields": {
                          "average_time": "2 horas",
                        }
                    },
                    {
                        "type": "card_delivery_pop_maximum_time",
                        "fields": {
                          "maximum_time": "4 horas",
                        }
                    },
                    {
                        "type": "card_delivery_pop_minimum_time",
                        "fields": {
                          "minimum_time": "1 hora",
                        }
                    }
                ]
            },
            {
                "row": "simples",
                "cards": [
                    {
                        "type": "card_delivery_prime_divisor_title",
                        "fields": []
                    },
                    {
                        "type": "card_delivery_prime_total",
                        "fields": {
                          "count_total": 450,
                
                        }
                    },
                    {
                        "type": "card_delivery_prime_average_kilometer",
                        "fields": {
                          "average_kilometer": "10 km",
                
                        }
                    },
                    {
                        "type": "card_delivery_prime_average_shipping_ticket",
                        "fields": {
                          "average_ticket": "R$ 8,00",
                        }
                    },
                    {
                        "type": "card_delivery_prime_average_time",
                        "fields": {
                          "average_time": "2 horas",
                        }
                    },
                    {
                        "type": "card_delivery_prime_maximum_time",
                        "fields": {
                          "maximum_time": "4 horas",
                        }
                    },
                    {
                        "type": "card_delivery_prime_minimum_time",
                        "fields": {
                          "minimum_time": "1 hora",
                        }
                    }
                ]
            },
            {
                "row": "simples",
                "cards": [
                    {
                        "type": "card_delivery_programmed_divisor_title",
                        "fields": []
                    },
                    {
                        "type": "card_delivery_programmed_total",
                        "fields": {
                          "count_total": 450,
                
                        }
                    },
                    {
                        "type": "card_delivery_programmed_average_kilometer",
                        "fields": {
                          "average_kilometer": "25 km",
                
                        }
                    },
                    {
                        "type": "card_delivery_programmed_average_shipping_ticket",
                        "fields": {
                          "average_ticket": "R$ 8,00",
                        }
                    },
                    {
                        "type": "card_delivery_programmed_average_time",
                        "fields": {
                          "average_time": "2 horas",
                        }
                    },
                    {
                        "type": "card_delivery_programmed_maximum_time",
                        "fields": {
                          "maximum_time": "4 horas",
                        }
                    },
                    {
                        "type": "card_delivery_programmed_minimum_time",
                        "fields": {
                          "minimum_time": "1 hora",
                        }
                    }
                ]
            },
            {
                "row": "simples",
                "cards": [
                    {
                        "type": "card_wait_divisor_title",
                        "fields": []
                    },
                    {
                        "type": "card_wait_attendance_average_time",
                        "fields": {
                          "average_time": "3 horas",
                
                        }
                    },
                    {
                        "type": "card_wait_attendance_maximum_time",
                        "fields": {
                          "maximum_time": "3 horas",
                
                        }
                    },
                    {
                        "type": "card_wait_attendance_minimum_time",
                        "fields": {
                          "minimum_time": "3 horas",
                
                        }
                    },
                    {
                        "type": "card_wait_pre_purchase_total",
                        "fields": {
                          "count_total": 25,
                        }
                    },
                    {
                        "type": "card_wait_shopper_total",
                        "fields": {
                          "count_total": 26,
                        }
                    },
                    {
                        "type": "card_wait_deliver_total",
                        "fields": {
                          "count_total": 27,
                        }
                    },
                ]
            }
        ],
        "screen": "dashboard_business",
        "background": "card_background_buylitics_1"
        }
    )

    componentDidMount =  () => {
        this.getCards()
    }

    componentDidUpdate = () => {
        if(this.state.showModal) this.renderModalContent()
    }

    render() {
        
        return(
            <React.Fragment>
                {
                    this.state.app != null ? (
                        <StandardStructure
                            onChange={this.handleChange}
                            title="Dashboard Attendances"
                            cards={this.state.app}
                            periodName={this.state.periodName}
                            cardCustom={this.state.cardCustom}
                            id={this.state.id}
                            classCustom={this.state.classCustom}
                            background={this.state.background}
                            handleCardActionClick={this.handleCardActionClick}
                        />
                    ):(
                        <div>
                            Carregando...
                        </div>
                    )
                }
                <Modal show={this.state.showModal} onHide={() => { this.setState({ showModal: false }) }} dialogAs={'div'} dialogClassName="modal-top w-100 dark b-l box-shadow">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100">
                            <div className="p-3 b-b d-flex no-shrink">
                                <h5 className="h5 m-0 fwhite">{this.state.selectedCard.title}</h5>
                                <br />
                                
                                <a href="/" onClick={(e) => { e.preventDefault(); this.setState({ showModal: false }) }} data-dismiss="modal" className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            {this.state.selectedCard.type}
                            <div id="modalContent" onLoad={(e)=>this.renderModalContent()}></div>
                        </div>
                    </Modal.Body>
                </Modal>

            </React.Fragment>
        )
    }
}

export default withRouter(DashboardBusiness);


