import React, { Component, Fragment } from 'react';
import {withRouter} from 'react-router-dom'

import $ from 'jquery'

import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4'

import {ajaxUrl, ajaxToken} from '../../../Services/Ajax';

import Modal from 'react-bootstrap/Modal';

import api from '../../../Services/Api';

import {urlBase} from '../../../Services/Config'

class IntegrationFacebook extends Component {

    constructor(props){
        super(props)
        this.state = {
            showPage: false,
            showAds: false,
            showForm: false,
            urlFacebookLogin: false,
            logged: null,
            listPages: [],
            facebookCreatePage: null,
            listAds: [],
            facebookCreateAdAccount: null,
            listForms: {
                pages: []
            },
            targets: [],
            facebookCreateForms: null,
            code: null,
            business_code: null
        }
        this.loadDatatableFacebookPage = this.loadDatatableFacebookPage.bind(this)
        this.loadDatatableFacebookAds = this.loadDatatableFacebookAds.bind(this)
        this.loadDatatableFacebookForm = this.loadDatatableFacebookForm.bind(this)
        
        this.handleShowPage = this.handleShowPage.bind(this)
        this.handleClosePage = this.handleClosePage.bind(this)
        this.formSubmitFacebookPage = this.formSubmitFacebookPage.bind(this)
        this.removeFacebookPage = this.removeFacebookPage.bind(this)

        this.handleShowAds = this.handleShowAds.bind(this)
        this.handleCloseAds = this.handleCloseAds.bind(this)
        this.formSubmitFacebookAdAccounts = this.formSubmitFacebookAdAccounts.bind(this)
        this.removeFacebookAdAccounts = this.removeFacebookAdAccounts.bind(this)

        this.handleShowForm = this.handleShowForm.bind(this)
        this.handleCloseForm = this.handleCloseForm.bind(this)
        this.formSubmitFacebookForms = this.formSubmitFacebookForms.bind(this)
        this.removeFacebookForms = this.removeFacebookForms.bind(this)

        this.openLoginFacebook = this.openLoginFacebook.bind(this)
        this.translate = this.props.translate
    }

    /* Facebook Pages */
    handleShowPage(){
        this.loadFacebookPages();
        this.setState({showPage: true})
    }

    loadDatatableFacebookPage(e){
        $('#list_facebook_page').DataTable({
            "searching": true,
            "paging": false,
            "info": false,
            "responsive": true,
            "language": {
                "url": this.translate('DATATABLE_LANGUAGE_URL')
            },
            "ajax": {
                "url": ajaxUrl('/integration/facebook/pages/tables'),
                "type": 'GET',
                "beforeSend": function (request) {
                    request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                }
            },
            "columnDefs": [{
                "targets": -1,
                "data": null,
                "defaultContent":
                    `<div class="btn-group btn-shadow">
                        <button class="delete btn btn-sm red btn-cursor-pointer" title="${this.translate('INTEGRATION_FACEBOOK_DELETE_PAGE')}">
                            <i class="fa fa-trash" title="${this.translate('INTEGRATION_FACEBOOK_DELETE_PAGE')}"></i>
                        </button>
                    </div>`
            }]
        });

    }

    reloadDatatableFacebookPage(e){
        $('#list_facebook_page').DataTable().ajax.reload();
    }

    handleClosePage(){
        this.setState({showPage: false, logged: null})
    }

    async formSubmitFacebookPage(e){
        e.preventDefault();

        $('#facebook_create_page_button_submit').prop('disabled', true).html(`<i className="fa fa-spinner"></i> ${this.translate('ICON_WAIT')}`)
        
        const data = {
            facebook_create_page: this.state.facebookCreatePage
        };

        try{
            const response = await api.post("/integration/facebook/pages/create", {data});
            if(response.data.success){
                this.reloadDatatableFacebookPage();
                $('#facebook_create_page_button_submit').prop('disabled', false).html(`<i className="fa fa-save"></i> ${this.translate('ICON_REGISTER')}`)
            }
        }catch(err){
            console.log(err)
        }
    }

    async removeFacebookPage(id){
        const data = {
            id: id
        };

        try{
            const response = await api.post("/integration/facebook/pages/disable", {data});
            if(response.data.success){
                this.reloadDatatableFacebookPage();
            }
        }catch(err){
            console.log(err)
        }
    }
    


    /* Facebook Ads */
    handleShowAds(){
        this.loadFacebookAds();
        this.setState({showAds: true})
    }

    loadDatatableFacebookAds(e){
        $('#list_facebook_ads').DataTable({
            "searching": true,
            "paging": false,
            "info": false,
            "responsive": true,
            "language": {
                "url": this.translate('DATATABLE_LANGUAGE_URL')
            },
            "ajax": {
                "url": ajaxUrl('/integration/facebook/ads/tables'),
                "type": 'GET',
                "beforeSend": function (request) {
                    request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                }
            },
            "columnDefs": [{
                "targets": -1,
                "data": null,
                "defaultContent":
                    `<div class="btn-group btn-shadow">
                        <button class="delete btn btn-sm red btn-cursor-pointer" title="${this.translate('INTEGRATION_FACEBOOK_DELETE_PAGE')}">
                            <i class="fa fa-trash" title="${this.translate('INTEGRATION_FACEBOOK_DELETE_PAGE')}"></i>
                        </button>
                    </div>`
            }]
        });

    }
    reloadDatatableFacebookAds(e){
        $('#list_facebook_ads').DataTable().ajax.reload();
    }
    handleCloseAds(){
        this.setState({showAds: false, logged: null})
    }
    async formSubmitFacebookAdAccounts(e){
        e.preventDefault();

        $('#facebook_create_ads_button_submit').prop('disabled', true).html(`<i className="fa fa-spinner"></i> ${this.translate('ICON_WAIT')}`)
        
        const data = {
            facebook_create_ads: this.state.facebookCreateAdAccount
        };

        try{
            const response = await api.post("/integration/facebook/ads/create", {data});
            if(response.data.success){
                this.reloadDatatableFacebookAds();
                $('#facebook_create_ads_button_submit').prop('disabled', false).html(`<i className="fa fa-save"></i> ${this.translate('ICON_REGISTER')}`)
            }
        }catch(err){
            console.log(err)
        }
    }
    async removeFacebookAdAccounts(id){
        const data = {
            id: id
        };

        try{
            const response = await api.post("/integration/facebook/ads/disable", {data});
            if(response.data.success){
                this.reloadDatatableFacebookAds();
            }
        }catch(err){
            console.log(err)
        }
    }

    /* Facebook Forms */

    addSegmentation(e){
        e.preventDefault();
        this.props.history.push("/targets")
    }

    async loadTargets(){
        try{
            const response = await api.get("/segmentation/json");
            this.setState({
                targets: response.data
            })
        }catch(err){
            console.log(err);
        }
    }

    handleShowForm(){
        this.loadFacebookForms();
        this.setState({showForm: true})
    }
    loadDatatableFacebookForm(e){
        $('#list_facebook_form').DataTable({
            "searching": true,
            "paging": false,
            "info": false,
            "responsive": true,
            "language": {
                "url": this.translate('DATATABLE_LANGUAGE_URL')
            },
            "ajax": {
                "url": ajaxUrl('/integration/facebook/forms/tables'),
                "type": 'GET',
                "beforeSend": function (request) {
                    request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                }
            },
            "columnDefs": [{
                "targets": -1,
                "data": null,
                "defaultContent": 
                    `<div class="btn-group btn-shadow">
                        <button class="delete btn btn-sm red btn-cursor-pointer" title="${this.translate('INTEGRATION_FACEBOOK_DELETE_PAGE')}">
                            <i class="fa fa-trash" title="${this.translate('INTEGRATION_FACEBOOK_DELETE_PAGE')}"></i>
                        </button>
                    </div>`
            }]
        });

    }
    reloadDatatableFacebookForm(e){
        $('#list_facebook_form').DataTable().ajax.reload();
    }
    handleCloseForm(){
        this.setState({showForm: false, logged: null})
    }
    async formSubmitFacebookForms(e){
        e.preventDefault();

        $('#facebook_create_forms_button_submit').prop('disabled', true).html(`<i className="fa fa-spinner"></i> ${this.translate('ICON_WAIT')}`)
        
        const data = this.state.facebookCreateForms;

        try{
            const response = await api.post("/integration/facebook/forms/create", {data});
            if(response.data.success){
                this.reloadDatatableFacebookForm();
                $('#facebook_create_forms_button_submit').prop('disabled', false).html(`<i className="fa fa-save"></i> ${this.translate('ICON_REGISTER')}`)
            }
        }catch(err){
            console.log(err)
        }
    }
    async removeFacebookForms(id){
        const data = {id: id};
        try{
            const response = await api.post("/integration/facebook/forms/disable", {data});
            if(response.data.success){
                this.reloadDatatableFacebookForm();
            }
        }catch(err){
            console.log(err)
        }
    }
    

    openLoginFacebook(e){
        e.preventDefault()

        let loginFacebook = window.open(urlBase + '/v1/integration/facebook/get_login?c=' + this.state.business_code);
        loginFacebook.focus();
        


        this.handleClosePage();
        this.handleCloseAds();
        this.handleCloseForm();

    }

    loadFacebookPages(){
        api.get('/integration/facebook/pages').then(async response => {
            console.log(response)
            if(response.data.success){
                this.setState({
                    logged: response.data.success,
                    listPages: response.data.pages
                });
            }else{
                this.setState({
                    logged: response.data.success
                });
            }
        })
    }

    loadFacebookAds(){
        api.get('/integration/facebook/ads').then(async response => {
            if(response.data.success){
                this.setState({
                    logged: response.data.success,
                    listAds: response.data.adAccounts
                });
            }else{
                this.setState({
                    logged: response.data.success
                });
            }
        })
    }

    loadFacebookForms(){
        api.get('/integration/facebook/forms').then(async response => {
            if(response.data.success){
                this.setState({
                    logged: response.data.success,
                    listForms: response.data
                });
            }else{
                this.setState({
                    logged: response.data.success
                });
            }
        })
    }

    componentDidMount(){
        api.get('/company/bussines/code/monitoring').then(async response => {
            this.setState({code: response.data.code, business_code: response.data.business_code});
        })

        this.loadTargets();

        /* Delete Facebook Page */
        $(document).on('click', '#list_facebook_page tbody .delete', function (e){
            e.preventDefault();
            let data = [];
            if($(e.target).parents('tr').hasClass('child')){
                data = $('#list_facebook_page').DataTable().row($(e.target).parents('tr').prev('.parent')).data();
            }else{
                data = $('#list_facebook_page').DataTable().row($(e.target).parents('tr')).data();
            }
            if(typeof data[1] !== 'undefined' && data[1] !== null){
                console.log(data);
                this.removeFacebookPage(data[1])
            }
        }.bind(this));

        /* Delete Facebook Ads */
        $(document).on('click', '#list_facebook_ads tbody .delete', function (e){
            e.preventDefault();
            let data = [];
            if($(e.target).parents('tr').hasClass('child')){
                data = $('#list_facebook_ads').DataTable().row($(e.target).parents('tr').prev('.parent')).data();
            }else{
                data = $('#list_facebook_ads').DataTable().row($(e.target).parents('tr')).data();
            }
            if(typeof data[1] !== 'undefined' && data[1] !== null){
                this.removeFacebookAdAccounts(data[1])
            }
        }.bind(this));

        /* Delete Facebook Forms */
        $(document).on('click', '#list_facebook_form tbody .delete', function (e){
            e.preventDefault();
            let data = [];
            if($(e.target).parents('tr').hasClass('child')){
                data = $('#list_facebook_form').DataTable().row($(e.target).parents('tr').prev('.parent')).data();
            }else{
                data = $('#list_facebook_form').DataTable().row($(e.target).parents('tr')).data();
            }
            if(typeof data[1] !== 'undefined' && data[1] !== null){
                this.removeFacebookForms(data[1])
            }
        }.bind(this));

        
    }

    toggleCollapse = (selector) => $(selector).collapse("toggle")

    render() {
        return (
            <Fragment>
                <div className="row pb40 white">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <h4 className="text-center mt20 mb20">
                            <i className="fa fa-facebook"></i> {this.translate('INTEGRATION_FACEBOOK_FACEBOOK')}
                        </h4>
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <div className="text-center box-shadow border-radius cyan">
                            <div className="p-4 ">
                                <p className="f64 m0 text-white">
                                    <i className="fa fa-list-alt"></i>
                                </p>
                                <h5 className="text-md f24 d-block f600 fwhite mb20">
                                    {this.translate('INTEGRATION_FACEBOOK_FACEBOOK_PAGES')}
                                </h5>
                                <button onClick={this.handleShowPage} className="btn btn-info info btn-rounded text-white btn-shadow">
                                    <i className="fa fa-cogs"></i> {this.translate('INTEGRATION_FACEBOOK_CONFIGURE_FACEBOOK_PAGES')}
                                </button>
                                <br/><br/>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <div className="text-center box-shadow border-radius blue">
                            <div className="p-4 ">
                                <p className="f64 m0 text-white">
                                    <i className="fa fa-list-alt"></i>
                                </p>
                                <h5 className="text-md f24 d-block f600 fwhite mb20">
                                    {this.translate('INTEGRATION_FACEBOOK_FACEBOOK_ADS')}
                                </h5>
                                <button onClick={this.handleShowAds} className="btn btn-info info btn-rounded text-white btn-shadow">
                                    <i className="fa fa-cogs"></i> {this.translate('INTEGRATION_FACEBOOK_CONFIGURE_FACEBOOK_ADS')}
                                </button>
                                <br/><br/>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <div className="text-center box-shadow border-radius indigo">
                            <div className="p-4 ">
                                <p className="f64 m0 text-white">
                                    <i className="fa fa-list-alt"></i>
                                </p>
                                <h5 className="text-md f24 d-block f600 fwhite mb20">
                                    {this.translate('INTEGRATION_FACEBOOK_FACEBOOK_FORMS')}
                                </h5>
                                <button onClick={this.handleShowForm} className="btn btn-info info btn-rounded text-white btn-shadow">
                                    <i className="fa fa-cogs"></i> {this.translate('INTEGRATION_FACEBOOK_CONFIGURE_FACEBOOK_FORMS')}
                                </button>
                                <br/><br/>
                            </div>
                        </div>
                    </div>

                </div>

                <Modal show={this.state.showPage} onEntered={this.loadDatatableFacebookPage} onHide={this.handleClosePage} dialogAs={'div'} dialogClassName="modal-dialog-height100vh modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            
                            <div className="p-3 b-b d-flex no-shrink cyan text-center position10">
                                <h5 className="h5 m-0 text-center width100 line-height-38px">
                                    <i className="fa fa-facebook"></i> {this.translate('INTEGRATION_FACEBOOK_FACEBOOK_PAGES')}
                                </h5>
                                <button onClick={this.handleClosePage} className="btn btn-transparent ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </button>
                            </div>

                            <div className="b-b d-flex no-shrink text-center position10 dark">
                                <div className="col-12">
                                    <div id="accordionAddFacebookPage">
                                        <div className="panel box no-border mb-2" style={{width: '100%'}}>
                                            <div className="dark box-header b-b p-y-sm fwhite">
                                                <button className="btn btn-transparent" style={{width: '100%'}} data-parent="#accordionAddFacebookPage" onClick={(e)=>this.toggleCollapse("#form_facebook_page")} aria-expanded="true">
                                                    {this.translate('INTEGRATION_FACEBOOK_ADD_FACEBOOK_PAGE')}
                                                    <span className="float-right badge text-sm">
                                                        <i className="fa fa-angle-down"></i>
                                                    </span>
                                                </button>
                                            </div>
                                            <div id="form_facebook_page" className="dark in collapse text-left show">

                                                <div className="col-sm-12 col-md-12 col-lg-12">

                                                    {
                                                        (this.state.logged === false)?(
                                                            <button className="btn blue info btn-rounded text-white btn-shadow" style={{width: '100%'}} onClick={this.openLoginFacebook}>{this.translate('INTEGRATION_FACEBOOK_LOG_IN_ON_FACEBOOK')}</button>
                                                        ):(
                                                            (this.state.logged === true)?(
                                                                <Fragment>
                                                                    <form onSubmit={this.formSubmitFacebookPage} id="form_config_facebook_page">
                                                                        <br/>
                                                                        <div className="col-sm-12 col-md-12 mb20">
                                                                            {
                                                                                (this.state.logged)?(
                                                                                    <Fragment>
                                                                                        <label>{this.translate('INTEGRATION_FACEBOOK_SELECT_FACEBOOK_PAGE')}</label>
                                                                                        <select onChange={e => this.setState({facebookCreatePage:  e.target.value})} className="form-control box-shadow cool-select" required="required">
                                                                                            <option value="">{this.translate('INTEGRATION_FACEBOOK_SELECT_PAGE')}</option>
                                                                                            {
                                                                                                this.state.listPages.map(
                                                                                                    (data, i) => (
                                                                                                        <option key={i} value={data.value}>
                                                                                                            {data.name}
                                                                                                        </option>
                                                                                                    )
                                                                                                )
                                                                                            }
                                                                                        </select>
                                                                                    </Fragment>
                                                                                ):(
                                                                                    <button className="btn blue info btn-rounded text-white btn-shadow" style={{width: '100%'}} onClick={this.openLoginFacebook}>{this.translate('INTEGRATION_FACEBOOK_LOG_IN_ON_FACEBOOK')}</button>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div className="col-sm-12 col-md-12 mb20">
                                                                            <button type="submit" id="facebook_create_page_button_submit" className="pull-right btn cyan px-5 btn-rounded btn-shadow">
                                                                                <i className="fa fa-plus"></i> {this.translate('ICON_ADD')}
                                                                            </button>
                                                                            <br/><br/>
                                                                        </div>
                                                                    </form>
                                                                </Fragment>
                                                            ):(
                                                                <div className="col-sm-12 col-md-12 mb20 text-center" style={{fontWeight: 'bold'}}>
                                                                    {this.translate('INTEGRATION_FACEBOOK_WAIT_LOADING')}
                                                                </div>
                                                            )
                                                        )
                                                    }

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="b-b d-flex no-shrink text-left position10 white">
                                <div className="col-md-12">
                                    <div className="box p-2 mt-3">
                                        <table id="list_facebook_page" className="dark table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                                            <thead>
                                                <tr className="text-left">
                                                    <th>{this.translate('INTEGRATION_FACEBOOK_PAGE_NAME')}</th>
                                                    <th>{this.translate('TABLE_ACTIONS_ROW')}</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showAds} onEntered={this.loadDatatableFacebookAds} onHide={this.handleCloseAds} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100 line-height-38px">
                                    <i className="fa fa-facebook"></i> {this.translate('INTEGRATION_FACEBOOK_FACEBOOK_ADS')}
                                </h5>
                                <button onClick={this.handleCloseAds} className="btn btn-transparent ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </button>
                            </div>
                            
                            <div className="pr-3 pl-3 b-b d-flex no-shrink text-center position10 dark">
                                <div className="col-12">
                                    <div id="accordionAds">
                                        <div className="panel box no-border mb-2" style={{width: '100%'}}>
                                            <div className="dark box-header b-b p-y-sm fwhite">
                                                <button className="btn btn-transparent" style={{width: '100%'}} data-parent="#accordionAds" onClick={(e)=>this.toggleCollapse("#form_facebook_ads")}  aria-expanded="true">
                                                    {this.translate('INTEGRATION_FACEBOOK_ADD_FACEBOOK_ADS_ACCOUNT')}
                                                    <span className="float-right badge text-sm">
                                                        <i className="fa fa-angle-down"></i>
                                                    </span>
                                                </button>
                                            </div>
                                            <div id="form_facebook_ads" className="dark in collapse text-left show">

                                                <div className="col-sm-12 col-md-12 col-lg-12">

                                                    {
                                                        (this.state.logged === false)?(

                                                            <button className="btn blue info btn-rounded text-white btn-shadow" style={{width: '100%'}} onClick={this.openLoginFacebook}>{this.translate('INTEGRATION_FACEBOOK_LOG_IN_ON_FACEBOOK')}</button>

                                                        ):(
                                                            (this.state.logged === true)?(
                                                                <Fragment>
                                                                    <form onSubmit={this.formSubmitFacebookAdAccounts} id="form_config_facebook_ads">
                                                                        <br/>
                                                                        <div className="col-sm-12 col-md-12 mb20">
                                                                            <Fragment>
                                                                                <label>{this.translate('INTEGRATION_FACEBOOK_SELECT_FACEBOOK_ADS_ACCOUNT')}</label>
                                                                                <select onChange={e => this.setState({facebookCreateAdAccount:  e.target.value})} className="form-control box-shadow cool-select" required="required">
                                                                                    <option value="">{this.translate('INTEGRATION_FACEBOOK_SELECT_ACCOUNT')}</option>
                                                                                    {
                                                                                        this.state.listAds.map(
                                                                                            (data, i) => (
                                                                                                <option key={i} value={data.value}>
                                                                                                    {data.name}
                                                                                                </option>
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                </select>
                                                                            </Fragment>
                                                                        </div>
                                                                        <div className="col-sm-12 col-md-12 mb20">
                                                                            <button id="facebook_create_ads_button_submit" type="submit" className="pull-right btn blue px-5 btn-rounded btn-shadow">
                                                                                <i className="fa fa-plus"></i> {this.translate('ICON_ADD')}
                                                                            </button>
                                                                            <br/><br/>
                                                                        </div>
                                                                    </form>
                                                                </Fragment>
                                                            ):(
                                                                <div className="col-sm-12 col-md-12 mb20 text-center" style={{fontWeight: 'bold'}}>
                                                                    {this.translate('INTEGRATION_FACEBOOK_WAIT_LOADING')}
                                                                </div>
                                                            )
                                                        )
                                                    }

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="b-b d-flex no-shrink text-left position10 white">
                                <div className="col-md-12">
                                    <div className="box p-2 mt-3">
                                        <table id="list_facebook_ads" className="dark table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                                            <thead>
                                                <tr className="text-left">
                                                    <th>{this.translate('INTEGRATION_FACEBOOK_ADS_ACCOUNT_NAME')}</th>
                                                    <th>{this.translate('TABLE_ACTIONS_ROW')}</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showForm} onEntered={this.loadDatatableFacebookForm} onHide={this.handleCloseForm} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            
                            <div className="p-3 b-b d-flex no-shrink indigo text-center position10">
                                <h5 className="h5 m-0 text-center width100 line-height-38px">
                                    <i className="fa fa-clone"></i> {this.translate('INTEGRATION_FACEBOOK_FACEBOOK_FORMS')}
                                </h5>
                                <button onClick={this.handleCloseForm} className="btn btn-transparent ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </button>
                            </div>
                            

                            
                            <div className="pr-3 pl-3 b-b d-flex no-shrink text-center position10 dark">
                                <div className="col-12">
                                    <div id="accordion">
                                        <div className="panel box no-border mb-2" style={{width: '100%'}}>
                                            <div className="dark box-header b-b p-y-sm fwhite dark">
                                                <button className="btn btn-transparent" style={{width: '100%'}} data-parent="#accordion" onClick={(e)=>this.toggleCollapse("#form_facebook_forms")} aria-expanded="true">
                                                    {this.translate('INTEGRATION_FACEBOOK_ADD_FACEBOOK_FORMS')}
                                                    <span className="float-right badge text-sm">
                                                        <i className="fa fa-angle-down"></i>
                                                    </span>
                                                </button>
                                            </div>
                                            <div id="form_facebook_forms" className="dark in collapse text-left show">

                                                <div className="col-sm-12 col-md-12 col-lg-12">

                                                    {
                                                        (this.state.logged === false)?(
                                                            <button className="btn blue info btn-rounded text-white btn-shadow" style={{width: '100%'}} onClick={this.openLoginFacebook}>{this.translate('INTEGRATION_FACEBOOK_LOG_IN_ON_FACEBOOK')}</button>
                                                        ):(
                                                            (this.state.logged === true)?(
                                                                (this.state.listForms.pages.length)?(
                                                                    <Fragment>
                                                                        <form onSubmit={this.formSubmitFacebookForms} id="form_config_facebook_forms">
                                                                            <br/>
                                                                            <div className="col-sm-12 col-md-12 mb20">
                                                                                <Fragment>
                                                                                    <label>{this.translate('INTEGRATION_FACEBOOK_SELECT_FACEBOOK_FORM')}</label>
                                                                                    <select onChange={e => this.setState({ facebookCreateForms: {...this.state.facebookCreateForms, facebook_create_forms:  e.target.value}})} className="form-control box-shadow cool-select" required="required">
                                                                                        <option value="">{this.translate('INTEGRATION_FACEBOOK_SELECT_FORM')}</option>
                                                                                        {
                                                                                            this.state.listForms.pages.map(
                                                                                                (data, i) => (
                                                                                                    <optgroup key={i} label={data.facebook_name_page}>
                                                                                                        {
                                                                                                            data.facebook_forms_page.map(
                                                                                                                (dataF, iF) => (
                                                                                                                    <option key={iF} value={dataF.value}>
                                                                                                                        {dataF.name}
                                                                                                                    </option>
                                                                                                                )
                                                                                                            )
                                                                                                        }
                                                                                                    </optgroup>
                                                                                                )
                                                                                            )
                                                                                        }
                                                                                    </select>
                                                                                </Fragment>
                                                                            </div>

                                                                            <div className="col-sm-12 mb20">
                                                                                <label className="black no-text-shadow">{this.translate('INTEGRATION_FACEBOOK_SEGMENTATION')}</label>
                                                                                {
                                                                                    this.state.targets.length?(
                                                                                        <select onChange={e => this.setState({ facebookCreateForms: {...this.state.facebookCreateForms, segmentation:  e.target.value}})} className="form-control box-shadow cool-select" required="required">
                                                                                            <option value="">{this.translate('INTEGRATION_FACEBOOK_SELECT_SEGMENTATION')}</option>
                                                                                            {
                                                                                                this.state.targets.map((data, i) => 
                                                                                                    (
                                                                                                        <option value={data.code} key={i}>{data.name}</option>
                                                                                                    )
                                                                                                )
                                                                                            }
                                                                                        </select>
                                                                                    ):(
                                                                                        <div>
                                                                                            <button onClick={this.addSegmentation} style={{width: "100%"}} className="btn btn-cursor-pointer red btn-shadow" value="">{this.translate('INTEGRATION_FACEBOOK_NO_SEGMENTATION_REGISTERED_CLICK_TO_ADD')}</button>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                            <div className="col-sm-12 mb20">
                                                                                <label className="black no-text-shadow">{this.translate('INTEGRATION_FACEBOOK_CAMPAIGN_PITCH')}</label>
                                                                                <input type="text" onChange={e => this.setState({ facebookCreateForms: {...this.state.facebookCreateForms, pitch_campaign:  e.target.value}})} className="form-control box-shadow cool-input" placeholder={this.translate('INTEGRATION_FACEBOOK_CAMPAIGN_PITCH')} required="required" />
                                                                            </div>
                                                                            <div className="col-sm-12 mb20">
                                                                                <label className="black no-text-shadow">{this.translate('INTEGRATION_FACEBOOK_CONTENT_PITCH')}</label>
                                                                                <input type="text" onChange={e => this.setState({ facebookCreateForms: {...this.state.facebookCreateForms, pitch_contents:  e.target.value}})} className="form-control box-shadow cool-input" placeholder={this.translate('INTEGRATION_FACEBOOK_CONTENT_PITCH')} required="required" />
                                                                            </div>
                                                                            <div className="col-sm-12 mb20">
                                                                                <label className="black no-text-shadow">{this.translate('INTEGRATION_FACEBOOK_CLICK_PITCH')}</label>
                                                                                <input type="text" onChange={e => this.setState({ facebookCreateForms: {...this.state.facebookCreateForms, pitch_click:  e.target.value}})} className="form-control box-shadow cool-input" placeholder={this.translate('INTEGRATION_FACEBOOK_CLICK_PITCH')} required="required" />
                                                                            </div>

                                                                            <div className="col-sm-12 col-md-12 mb20">
                                                                                <button type="submit" className="pull-right btn indigo px-5 btn-rounded btn-shadow">
                                                                                    <i className="fa fa-plus"></i> {this.translate('ICON_ADD')}
                                                                                </button>
                                                                                <br/><br/>
                                                                            </div>
                                                                        </form>
                                                                    </Fragment>
                                                                ):(
                                                                    <span>{this.translate('INTEGRATION_FACEBOOK_NO_PAGES_FOUND_INTEGRATE_THE_PAGES_FIRST')}</span>
                                                                )
                                                            ):(
                                                                <div className="col-sm-12 col-md-12 mb20 text-center" style={{fontWeight: 'bold'}}>
                                                                    {this.translate('INTEGRATION_FACEBOOK_WAIT_LOADING')}
                                                                </div>
                                                            )
                                                        )
                                                    }

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="b-b d-flex no-shrink text-left position10 white">
                                <div className="col-md-12">
                                    <div className="box p-2 mt-3">
                                        <table id="list_facebook_form" className="dark table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                                            <thead>
                                                <tr className="text-left">
                                                    <th>{this.translate('INTEGRATION_FACEBOOK_FORM_NAME')}</th>
                                                    <th>{this.translate('TABLE_ACTIONS_ROW')}</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Modal.Body>
                </Modal>

            </Fragment>
        );
    }
}

export default withRouter(IntegrationFacebook);