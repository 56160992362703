import React, { Component, Fragment } from 'react';
import {withRouter} from 'react-router-dom'

import Modal from 'react-bootstrap/Modal';

import $ from 'jquery'

import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4'

import api from '../../../Services/Api';
import {ajaxUrl, ajaxToken} from '../../../Services/Ajax';

import MenuMain from '../../../Structure/MenuMain';
import HeaderMain from '../../../Structure/HeaderMain';
import FooterMain from '../../../Structure/FooterMain';

class LandingPageList extends Component {

    constructor(props){
        super(props)
        this.translate = this.props.translate
        document.title = this.translate('LANDING_PAGE_LANDING_PAGES')
        this.state = {
            tableLoadLandingPages: null,
            modalCreateLandingPages: false,
            formCreateLandingPages: {
                name: null,
                pitch_landing_page: null,
                url: null,
                type: null
            }
        }
        this.showModalCreateLandingPages = this.showModalCreateLandingPages.bind(this)
        this.closeModalCreateLandingPages = this.closeModalCreateLandingPages.bind(this)
        this.formSubmitCreateLandingPages = this.formSubmitCreateLandingPages.bind(this)
        this.reloadDatatableLandingPages = this.reloadDatatableLandingPages.bind(this)
    }

    showModalCreateLandingPages(){
        this.setState({modalCreateLandingPages: true})
    }

    closeModalCreateLandingPages(){
        this.setState({modalCreateLandingPages: false})
    }

    reloadDatatableLandingPages(){
        $('#landing_page_list').DataTable().ajax.reload();
    }

    async formSubmitCreateLandingPages(e){
        e.preventDefault();
        
        $('#landing_page_button_submit').prop('disabled', true).html(`<i className="fa fa-spinner"></i> ${this.translate('LANDING_PAGE_WAIT')}`)
        
        const data = this.state.formCreateLandingPages;
        try{
            const response = await api.post("/landing_page/create", {data});
            if(response.data.success === true){
                this.reloadDatatableLandingPages()
                this.closeModalCreateLandingPages();
                $('#landing_page_button_submit').prop('disabled', false).html(`<i className="fa fa-save"></i> ${this.translate('LANDING_PAGE_REGISTER')}`)
            }else{
                this.setState({msgResponseShow: response.data.msg});
            }
        }catch(err){
            this.setState({msgResponseShow: err});
        }
    }

    componentDidMount(){

        let tableLoadLandingPages = this.state.tableLoadLandingPages
        const props = this.props;
        console.log(props)

        tableLoadLandingPages = $('#landing_page_list').DataTable({
            "searching": true,
            "paging": false,
            "info": false,
            "responsive": true,
            "language": {
                "url": this.translate('DATATABLE_LANGUAGE_URL')
            },
            "ajax": {
                "url": ajaxUrl('/landing_page/table'),
                "type": 'GET',
                "beforeSend": function (request) {
                    request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                }
            },
            "columnDefs": [{
                "targets": -1,
                "data": null,
                "defaultContent": 
                    `<div class="btn-group btn-shadow">
                        <button class="view btn btn-sm blue btn-cursor-pointer" title="${this.translate('LANDING_PAGE_VIEW_LANDING_PAGE')}">
                            <i class="fa fa-eye" title="${this.translate('LANDING_PAGE_VIEW_LANDING_PAGE')}"></i>
                        </button>
                    </div>`
            }]
        });

        $('#landing_page_list tbody').on( 'click', '.view', function (e){
            
            e.preventDefault();

            let data = [];
            
            if($(e.target).parents('tr').hasClass('child')){
                data = tableLoadLandingPages.row($(e.target).parents('tr').prev('.parent')).data();
            }else{
                data = tableLoadLandingPages.row($(e.target).parents('tr')).data();
            }

            if(data[3] === "external_link"){
                let landing = window.open(data[1]);
                landing.focus();
            }
            
        });

    }

    render() {
        return (
            <Fragment>
                <MenuMain />
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    <HeaderMain title={document.title} onChange={this.handleChange}/>
                    <div className="content-main row row-no-margin" id="content-main">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" id="conteudo">
                            <div className="row text-right mb-3 p-2">
                                <div className="col-md-12">
                                    <button className="btn btn-fw blue btn-rounded mt20 pull-right btn-shadow btn-cursor-pointer" onClick={this.showModalCreateLandingPages}>
                                        <i className="fa fa-columns"></i> {this.translate('LANDING_PAGE_CREATE_NEW_LANDING_PAGE')}
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="box p-2">
                                        <table id="landing_page_list" className="table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                                            <thead>
                                                <tr className="text-left">
                                                    <th>{this.translate('LANDING_PAGE_TABLE_NAME')}</th>
                                                    <th>{this.translate('LANDING_PAGE_TABLE_URL')}</th>
                                                    <th>{this.translate('TABLE_ACTIONS_ROW')}</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterMain />
                </div>
                <Modal show={this.state.modalCreateLandingPages} onHide={this.closeModalCreateLandingPages} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                    <div className="d-flex flex-column h-100 pb-5">
                        <div className="p-3 b-b d-flex no-shrink blue text-center">
                            <h5 className="h5 m-0 text-center width100 line-height-38px">
                                <i className="fa fa-columns"></i> {this.translate('LANDING_PAGE_MODAL_CREATE_NEW_LANDING_PAGE')}
                            </h5>
                            <button onClick={this.closeModalCreateLandingPages} className="btn btn-transparent ml-auto">
                                <span className="text-lg l-h-1x">×</span>
                            </button>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-6 offset-md-3">

                                <form onSubmit={this.formSubmitCreateLandingPages}>
                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('LANDING_PAGE_MODAL_LABEL_NAME')}</label>
                                        <input type="text" onChange={e => this.setState({ formCreateLandingPages: {...this.state.formCreateLandingPages, name:  e.target.value}})} className="form-control box-shadow cool-input" placeholder={this.translate('LANDING_PAGE_MODAL_PLACEHOLDER_NAME')} required="required" />
                                    </div>
                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('LANDING_PAGE_MODAL_LABEL_PITCH')}</label>
                                        <input type="text" onChange={e => this.setState({ formCreateLandingPages: {...this.state.formCreateLandingPages, pitch_landing_page:  e.target.value}})} className="form-control box-shadow cool-input" placeholder={this.translate('LANDING_PAGE_MODAL_PLACEHOLDER_PITCH')} required="required" />
                                    </div>
                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('LANDING_PAGE_MODAL_LABEL_URL')}</label>
                                        <input type="text" onChange={e => this.setState({ formCreateLandingPages: {...this.state.formCreateLandingPages, url:  e.target.value}})} className="form-control box-shadow cool-input" placeholder={this.translate('LANDING_PAGE_MODAL_PLACEHOLDER_URL')} required="required" />
                                    </div>
                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('LANDING_PAGE_MODAL_LABEL_LANDING_PAGE_TYPE')}:</label>
                                        <select onChange={e => this.setState({ formCreateLandingPages: {...this.state.formCreateLandingPages, type:  e.target.value}})} className="form-control box-shadow cool-select" required="required">
                                            <option value="">{this.translate('LANDING_PAGE_MODAL_OPTION_SELECT_LANDING_PAGE')}</option>
                                            <option value="external_link">{this.translate('LANDING_PAGE_MODAL_OPTION_EXTERNAL_LINK')}</option>
                                            <option value="buylitics_landing_page_creator">{this.translate('LANDING_PAGE_MODAL_OPTION_BUYLITICS_LANDING_PAGE')}</option>
                                        </select>
                                    </div>
                                    
                                    <button type="submit" id="landing_page_button_submit" className="btn btn-fw blue btn-rounded mt20 pull-right btn-shadow px-5"><i className="fa fa-save"></i> {this.translate('LANDING_PAGE_MODAL_REGISTER')}</button>
                                </form>

                            </div>
                        </div>
                    </div>
                    </Modal.Body>
                </Modal>
            </Fragment>
        );
    }
}

export default withRouter(LandingPageList);