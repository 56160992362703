import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';

import $ from 'jquery';

import MenuMain from '../../Structure/MenuMain';

import api from '../../Services/Api';

import Modal from 'react-bootstrap/Modal';

import VisitedPage from './Triggers/VisitedPage';
import FacebookLeads from './Triggers/FacebookLeads';
import ExportedLeads from './Triggers/ExportedLeads';
import EntryRuler from './Triggers/EntryRuler';
import Leads99vendas from './Triggers/Leads99vendas';
import InputLeadPortals from './Triggers/InputLeadPortals';
import AlertView from './Actions/AlertView';
import ReportBroker from './Actions/ReportBroker';
import AddDelay from './Actions/AddDelay';
import AddTag from './Actions/AddTag';
import LeadScore from './Actions/LeadScore';
import DynamicImage from './Actions/DynamicImage';
import ModalBanner from './Actions/ModalBanner';
import SendEmail from './Actions/SendEmail';
import SendSms from './Actions/SendSms';
import CaptureForm from './Actions/CaptureForm';
import LeadActions from './Actions/LeadActions';
import NextJourney from './Actions/NextJourney';
import QuestOfInterest from './Actions/QuestOfInterest';
import TestAB from './Actions/TestAB';
import PutOnHold from './Actions/PutOnHold';
import PitchRelationshipRuler from './Actions/PitchRelationshipRuler';
import ScheduledVisit from './Conditions/ScheduledVisit';
import CheckVisit from './Conditions/CheckVisit';
import CheckProposal from './Conditions/CheckProposal';
import ProposedStatus from './Conditions/ProposedStatus';
import EmailStatus from './Conditions/EmailStatus';
import CheckContact from './Conditions/CheckContact';
import CheckSale from './Conditions/CheckSale';
import CheckReferrals from './Conditions/CheckReferrals';
import VisitedPageCondition from './Conditions/VisitedPageCondition';
import ViewSource from './Conditions/ViewSource';
import CheckLead from './Conditions/CheckLead';
import CheckFeedback from './Conditions/CheckFeedback';

class JourneyDesk extends Component {

    constructor(props){
        super(props);
        this.state = {
            canvasJourney: null,
            canvasDrag: {},
            canvasMouseDown: false,
            journeyJson: [],
            actionsJson: {
                triggers: [],
                actions: [],
                conditions: []
            },
            journeyDataJson: {
                name: null,
                funnel: null,
                funnel_placement: null
            },
            nameTypeConfig: null,
            zoomJourneyWorkbench: 100,
            modalAction: false,
            modalCallAction: null
        }
        this.drawCanvasElements = this.drawCanvasElements.bind(this)
        this.drawCanvasJourney = this.drawCanvasJourney.bind(this)

        this.mouseDownCanvasJourney = this.mouseDownCanvasJourney.bind(this)
        this.mouseUpCanvasJourney = this.mouseUpCanvasJourney.bind(this)
        this.mouseMoveJourneyWorkbench = this.mouseMoveJourneyWorkbench.bind(this)

        this.dragActions = this.dragActions.bind(this)
        this.dropJourney = this.dropJourney.bind(this)
        
        this.clickCanvasAction = this.clickCanvasAction.bind(this)
        
        this.moveActionsWorkbench = this.moveActionsWorkbench.bind(this)
        
        this.applyZoomOutJourney = this.applyZoomOutJourney.bind(this)
        this.applyZoomInJourney = this.applyZoomInJourney.bind(this)

        this.updateJsonJourney = this.updateJsonJourney.bind(this)

        this.showModalAction = this.showModalAction.bind(this)
        this.loadModalTool = this.loadModalTool.bind(this)
        this.closeModalAction = this.closeModalAction.bind(this)

        this.translate = this.props.translate
    }

    journeyJson = []

    canvasDrag = false
    canvasDragTemp = false
    canvasMouseDown = false
    canvasMouseMove = false
    canvasLineMove = false
    elementTargetLine = false
    
    canvasDragTarget = false
    canvasDragTargetTemp = false
    canvasMouseDownTarget = false

    zoomJourneyWorkbench = 100
    
    journeyStatus = false

    componentDidMount(){

        this.setState({canvasJourney: document.getElementById('journey_workbench')})
        
        /* Load Journey */
        this.loadJourney();

        /* Modal Actions */
        $(document).on('click', '.open_modal_action', function(e){
            e.preventDefault();

            const journeyJson = this.journeyJson
            let index = $(e.currentTarget).data('index');
            if(typeof journeyJson[index]['idAction'] !== 'undefined'){
                this.showModalAction(journeyJson[index]);
            }

        }.bind(this));

        /* Remove Actions */
        $(document).on('click', '.remove_button_journey', function(e){

            e.preventDefault();
            
            const journeyJson = this.journeyJson
            
            let index = $(e.currentTarget).data('index');
            
            const elementSource = journeyJson[index].id;

            journeyJson.forEach(function(element, i){
                if(element.id !== elementSource){
                    journeyJson[i].targets.forEach(function(elementT, iT){
                        if(elementT.elementTarget.id === elementSource){
                            journeyJson[i].targets.splice(iT, 1)
                        }
                    })
                }
            })

            journeyJson.forEach((element) =>{
                element.connections = element.connections.filter(connection=>elementSource !== connection.elementSource)
            })

            journeyJson.splice(index, 1);

            this.journeyJson = journeyJson
            
            $('.actions-tools-' + index).remove();
            
            this.drawCanvasJourney()
            this.updateJsonJourney();
        }.bind(this));
        
        $(document).on('click', '.remove_button_target_journey', (e) => {
            e.preventDefault();
            
            const journeyJson = this.journeyJson

            let elementIndex = $(e.currentTarget).data('index').split("-")[0]
            let targetIndex = $(e.currentTarget).data('index').split("-")[1]
           
            const removedLineId = journeyJson[elementIndex]["targets"][targetIndex].id
            journeyJson.forEach((element) =>{
                element.targets = element.targets.filter(target=> removedLineId !== target.id)
                element.connections = element.connections.filter(connection => removedLineId !== connection.idLine)
            })

            this.journeyJson = journeyJson
            
            $('.target-actions-tools').remove()
            
            this.drawCanvasJourney()
            this.updateJsonJourney()

        })
        
        $(document).on('mousedown', '.start_arrow', function(e){
            
            e.preventDefault()

            if (e.target.parentNode.getAttribute("disabled") === "disabled") return
            
            let index = $(e.currentTarget).data('index');

            const journeyJson = this.journeyJson

            const element1 = this.journeyJson[index];
            
            const idLine = 'line' + Date.now();

            const indexLine = journeyJson[index].targets.push({
                id: idLine,
                color: '#007fff',
                type: 'line',
                topSource: element1.top,
                leftSource: element1.left,
                topTarget: e.pageY,
                leftTarget: e.pageX,
                elementSource: element1.id,
                elementTarget: null
            }) - 1;

            this.journeyJson = journeyJson
            
            this.canvasDrag = false;

            this.canvasLineMove = {
                id: idLine,
                lineMove: true,
                elementSource: element1.id,
                elementTarget: null,
                indexSource: index,
                indexLine: indexLine
            }
            this.disableElementOptions()

        }.bind(this))

        $(document).on('mousedown', '.start_arrow_true', function(e){
            e.preventDefault();
            
            if (e.target.parentNode.getAttribute("disabled") === "disabled") return


            let index = $(e.currentTarget).data('index');

            const journeyJson = this.journeyJson

            const element1 = this.journeyJson[index];
            
            const idLine = 'line' + Date.now();

            const indexLine = journeyJson[index].targets.push({
                id: idLine,
                condition: true,
                color: '#4cae4c',
                type: 'line',
                topSource: element1.top,
                leftSource: element1.left,
                topTarget: e.pageY,
                leftTarget: e.pageX,
                elementSource: element1.id,
                elementTarget: null
            }) - 1;

            this.journeyJson = journeyJson
            
            this.canvasDrag = false;

            this.canvasLineMove = {
                id: idLine,
                lineMove: true,
                elementSource: element1.id,
                elementTarget: null,
                indexSource: index,
                indexLine: indexLine
            }
            this.disableElementOptions()
        }.bind(this))

        $(document).on('mousedown', '.start_arrow_false', function(e){
            e.preventDefault();
            
            if (e.target.parentNode.getAttribute("disabled") === "disabled") return

            let index = $(e.currentTarget).data('index');

            const journeyJson = this.journeyJson

            const element1 = this.journeyJson[index];
            
            const idLine = 'line' + Date.now();

            const indexLine = journeyJson[index].targets.push({
                id: idLine,
                condition: false,
                color: '#d43f3a',
                type: 'line',
                topSource: element1.top,
                leftSource: element1.left,
                topTarget: e.pageY,
                leftTarget: e.pageX,
                elementSource: element1.id,
                elementTarget: null
            }) - 1;

            this.journeyJson = journeyJson
            
            this.canvasDrag = false;

            this.canvasLineMove = {
                id: idLine,
                lineMove: true,
                elementSource: element1.id,
                elementTarget: null,
                indexSource: index,
                indexLine: indexLine
            }
            this.disableElementOptions()
        }.bind(this))
        
    }

    disableElementOptions = () => {
        $(".actions-tools button").attr("disabled", "disabled")
        $(".actions-tools button").addClass("bg-secondary")
    }

    async loadJourney(){
        const journey_id = document.location.hash.replace('#','');
        try{
            const response = await api.get("/journey/select/" + journey_id);
            if(response.data.success){
                this.setState({
                    journeyDataJson: response.data.journey_data_json,
                    actionsJson: response.data.actions_json,
                })
                this.journeyStatus = response.data.journey_data_json.status;
                this.journeyJson = response.data.journey_json
                this.drawCanvasJourney()
            }
        }catch(error){
            console.log(error);
        }
    }

    async updateJsonJourney(){
        const journey_id = document.location.hash.replace('#','');
        try{
            const journeyJson = this.journeyJson;
            //const response = await api.post("/journey/update/" + journey_id, journeyJson);
            await api.post("/journey/update/" + journey_id, journeyJson);
        }catch(error){
            console.log(error);
        }
    }

    async buttonPublishJourney(){
        const journey_id = document.location.hash.replace('#','');
        try{
            const response = await api.get("/journey/publish/" + journey_id);
            if(response.data.success){
                window.location.reload();
            }else{
                alert(response.data.error)
            }
        }catch(error){
            console.log(error);
        }
    }

    dragActions(e){
        let data = {
            type: e.target.dataset.type,
            code: e.target.dataset.code,
            name: e.target.dataset.name
        }
        e.dataTransfer.setData("text/plain",JSON.stringify(data));
    }

    allowDropJourney(e){
        e.preventDefault();
    }

    drawCanvasElements(type, color, left, top, names, leftSource = 0, topSource = 0, iTeste = null){

        function wrapText(context, text, x, y, maxWidth, lineHeight){
            var words = text.split(' ');
            
            var line = '';

            for(var n = 0; n < words.length; n++){
                var testLine = line + words[n] + ' ';
                var metrics = context.measureText(testLine);
                var testWidth = metrics.width;
                if (testWidth > maxWidth && n > 0){
                    context.fillText(line, x, y);
                    line = words[n] + ' ';
                    y += lineHeight;
                }else{
                    line = testLine;
                }
            }
            context.fillText(line, x, y);
        }

        let canvas = this.state.canvasJourney;
        let context = canvas.getContext('2d');
        //const zoom = this.zoomJourneyWorkbench
        let fontSize = 0;
        let topTitle = 0;
        let topDesc = 0;

        let name = names

        if(iTeste !== null){
            name = names + ' - ' + iTeste
        }else{
            name = names
        }

        switch(type){
            case "trigger":
                context.beginPath();
                context.fillStyle = color;
                context.rect(left-70, top-70, 140, 140);
                //context.rect((left * zoom) - (70 * zoom), (top * zoom)-(70 * zoom), (140 * zoom), (140 * zoom));
                context.fill();

                context.lineJoin = "round";
                context.lineWidth = 10;
                context.strokeStyle = color;
                context.stroke();

                //fontSize = 16 * zoom;
                fontSize = 16;
                context.font = '500 '+ fontSize +'px Verdana';
                context.textAlign = 'center';
                context.fillStyle = '#fff';

                topTitle = (name.length > 10)? (top - 20): (top - 10)
                wrapText(context, name, (left + 5), topTitle, 110, 25)

                //wrapText(context, name, (left + 5), (top - 20), 110, 25)
                //wrapText(context, name, (left * zoom) + (5 * zoom), (top * zoom) - (20 * zoom), 110 * zoom, 25 * zoom)
                
                //fontSize = 11 * zoom;
                fontSize = 11;
                context.font = '500 '+ fontSize +'px Verdana';
                context.textAlign = 'center';
                context.fillStyle = '#000';
                //wrapText(context, 'Clique para Interagir', (left * zoom) + (2.5 * zoom), (top * zoom) - (30 * zoom), 130 * zoom, 25 * zoom)
                //wrapText(context, 'Clique para Interagir', left + (2.5 * zoom), top - (30 * zoom), 130 * zoom, 25 * zoom)
                topDesc = (name.length > 10)? (top + 25): (top + 15)
                wrapText(context, this.translate('JOURNEY_DESK_CLICK_TO_INTERACT'), left + 2.5, topDesc, 130, 0)

                break;
            case 'action':
                context.beginPath();
                //context.arc(left * zoom, top * zoom, 70 * zoom, 0, 2 * Math.PI, false);
                context.arc(left, top, 70, 0, 2 * Math.PI, false);
                context.fillStyle = color;
                context.fill();

                //fontSize = 16 * zoom;
                fontSize = 16;
                context.font = '500 '+ fontSize +'px Verdana';
                context.textAlign = 'center';
                context.fillStyle = '#fff';
                //wrapText(context, name, (left * zoom) + (5 * zoom), (top * zoom) - (20 * zoom), 110 * zoom, 25 * zoom)
                topTitle = (name.length > 10)? (top - 20): (top - 10)
                wrapText(context, name, (left + 5), topTitle, 110, 25)

                //fontSize = 11 * zoom;
                fontSize = 11;
                context.font = '500 '+ fontSize +'px Verdana';
                context.textAlign = 'center';
                context.fillStyle = '#000';
                //wrapText(context, 'Clique para Interagir', (left * zoom) + (2.5 * zoom), (top * zoom) - (30 * zoom), 130 * zoom, 25 * zoom)
                //wrapText(context, 'Clique para Interagir', left + (2.5 * zoom), top - (30 * zoom), 130 * zoom, 25 * zoom)
                topDesc = (name.length > 10)? (top + 25): (top + 15)
                wrapText(context, this.translate('JOURNEY_DESK_CLICK_TO_INTERACT'), left + 2.5, topDesc, 130, 0)
                break;
            case "condition":
                context.beginPath();
                context.fillStyle = color;
                context.rect(left-70, top-70, 140, 140);
                //context.rect((left * zoom) - (70 * zoom), (top * zoom)-(70 * zoom), (140 * zoom), (140 * zoom));
                context.fill();

                context.lineJoin = "round";
                context.lineWidth = 10;
                context.strokeStyle = color;
                context.stroke();

                //fontSize = 16 * zoom;
                fontSize = 16;
                context.font = '500 '+ fontSize +'px Verdana';
                context.textAlign = 'center';
                context.fillStyle = '#fff';
                wrapText(context, name, (left + 5), (top - 20), 110, 25)
                //wrapText(context, name, (left * zoom) + (5 * zoom), (top * zoom) - (20 * zoom), 110 * zoom, 25 * zoom)

                //fontSize = 11 * zoom;
                fontSize = 11;
                context.font = '500 '+ fontSize +'px Verdana';
                context.textAlign = 'center';
                context.fillStyle = '#000';
                //wrapText(context, 'Clique para Interagir', (left * zoom) + (2.5 * zoom), (top * zoom) - (30 * zoom), 130 * zoom, 25 * zoom)
                //wrapText(context, 'Clique para Interagir', left + (2.5 * zoom), top - (30 * zoom), 130 * zoom, 25 * zoom)
                topDesc = (name.length > 10)? (top + 25): (top + 15)
                wrapText(context, this.translate('JOURNEY_DESK_CLICK_TO_INTERACT'), left + 2.5, topDesc, 130, 0)

                break;
            case "line":
                context.beginPath()
                context.moveTo(leftSource, topSource);
                context.lineTo(left, top);
                context.lineWidth = 10;

                context.strokeStyle = color;

                context.stroke();
                break;
            default:
                break;
        }
    }

    drawCanvasJourney(){
        let canvas = this.state.canvasJourney;
        let context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);
        if(this.journeyJson.length){
            this.journeyJson.forEach(function(element){
                element.targets.forEach(function(line){
                    if(line !== null){
                        
                        this.drawCanvasElements(line.type, line.color, line.leftTarget, line.topTarget, line.name, line.leftSource, line.topSource)
                    }
                }.bind(this))
            }.bind(this))

            this.journeyJson.forEach(function(element, i){
                //this.drawCanvasElements(element.type, element.color, element.leftDisplay, element.topDisplay, element.name)
                //this.drawCanvasElements(element.type, element.color, element.left, element.top, element.name, '', '', i)
                this.drawCanvasElements(element.type, element.color, element.left, element.top, element.name)
            }.bind(this))

        }
    }

    async dropJourney(e){
        e.persist();

        if(this.journeyStatus !== 'unpublished'){
            alert(this.translate('JOURNEY_DESK_PUBLISHED_BUT_CANT_EDIT_ANYMORE'))
            return;
        }

        let data = JSON.parse(e.dataTransfer.getData("text/plain"))

        const journey_id = document.location.hash.replace('#','');

        const response = await api.post("/journey/new_action/" + journey_id, data);
        
        if(response.data.success){
            const idAction = response.data.id_action;

            const id = 'element' + Date.now();

            const journeyJson = this.journeyJson

            const pageX = e.pageX - 80;
            const pageY = e.pageY;
            
            let bgColor = '';

            switch(data.type){
                case "trigger":
                    bgColor = '#007fff'
                    this.drawCanvasElements(data.type, '#007fff', pageX, pageY, data.name)
                    break;
                case 'action':
                    bgColor = '#d43f3a'
                    this.drawCanvasElements(data.type, '#d43f3a', pageX, pageY, data.name)
                    break;
                case 'condition':
                    bgColor = '#4cae4c'
                    this.drawCanvasElements(data.type, '#4cae4c', pageX, pageY, data.name)
                    break;
                default:
                    break;
            }

            journeyJson.push({
                id: id,
                idAction: idAction,
                color: bgColor,
                type: data.type,
                code: data.code,
                name: data.name,
                width: 140,
                height: 140,
                widthDisplay: 140,
                heightDisplay: 140,
                top: pageY,
                left: pageX,
                topDisplay: pageY,
                leftDisplay: pageX,
                targets: [],
                connections: []
            });

            this.journeyJson = journeyJson

            this.updateJsonJourney();
        }

    }
    
    checkMouseDownTarget(x, y){
        const checkCollinearity = (a, b, c) => {
            let d = a
            let rightDiagonal = (a[0] * b[1]) + (b[0] * c[1]) + (c[0] * d[1])
            let leftDiagonal = (a[1] * b[0]) + (b[1] * c[0]) + (c[1] * d[0])
            let checkCollinearity = rightDiagonal - leftDiagonal

            return (checkCollinearity >= -3000 && checkCollinearity <= 3000) ? true : false 
        }
        
        const getLowestAndHighestPositions = (lineBegin, lineEnd) => {
            let lowestX = (lineBegin[0] < lineEnd[0]) ? lineBegin[0] : lineEnd[0]
            let highestX = (lineBegin[0] < lineEnd[0]) ? lineEnd[0] : lineBegin[0]
            let lowestY = (lineBegin[1] < lineEnd[1]) ? lineBegin[1] : lineEnd[1]
            let highestY = (lineBegin[1] < lineEnd[1]) ? lineEnd[1] : lineBegin[1]
            return {lowestX, highestX, lowestY, highestY}
        }

        const checkIfClickIsInsideLine = (pos, click) => {
            let checkInside = true
            if(click[0] < pos.lowestX - 6 || click[0] > pos.highestX + 6 ) checkInside = false
            if(click[1] < pos.lowestY - 6 || click[1] > pos.highestY + 6 ) checkInside = false
            return checkInside
        }

        const getMidPoint = (pos) => {
            let midPointX = (pos.highestX + pos.lowestX) / 2
            let midPointY = (pos.highestY + pos.lowestY) / 2
            return [midPointX, midPointY]
        }
      
        let canvasDragTargetTemp = false

        this.journeyJson.forEach((element, index) => {
            element.targets.forEach((target, i) => {
                
                let {leftSource, topSource, leftTarget, topTarget} = target
                let pos = getLowestAndHighestPositions([leftSource, topSource], [leftTarget, topTarget])
                let checkAreCollinear = checkCollinearity([leftSource, topSource], [leftTarget, topTarget], [x, y])
                let checkInside = checkIfClickIsInsideLine(pos, [x, y])
                
                if(checkAreCollinear && checkInside){
                    this.canvasMouseDownTarget = true
                    canvasDragTargetTemp = target
                    canvasDragTargetTemp.dragTarget = true
                    canvasDragTargetTemp.index = `${index}-${i}`
                    canvasDragTargetTemp.midPoint = getMidPoint(pos)
                    return
                }
            })
        })
        return canvasDragTargetTemp
    }

    checkMouseDownElement(x, y){
        
        this.elementTargetLine = false;

        let canvasDragTemp = false;
        
        this.journeyJson.forEach(function(element, i){
            
            let elementTop = element.height / 2;
            //let elementTop = element.heightDisplay / 2;
            let elementLeft = element.width / 2;
            //let elementLeft = element.widthDisplay / 2;
            
            if(y > element.top - elementTop && y < element.top + elementTop && x > element.left - elementLeft && x < element.left + elementLeft){
            //if(y > element.topDisplay - elementTop && y < element.topDisplay + elementTop && x > element.leftDisplay - elementLeft && x < element.leftDisplay + elementLeft){
                this.canvasMouseDown = true

                canvasDragTemp = {
                    id: element.id,
                    canvasDrag: true,
                    top: element.top,
                    //top: element.topDisplay,
                    left: element.left,
                    //left: element.leftDisplay,
                    width: element.widthDisplay,
                    height: element.heightDisplay,
                    index: i,
                    type: element.type
                }

                this.elementTargetLine = {
                    id: element.id,
                    target: true,
                    top: element.top,
                    //top: element.topDisplay,
                    left: element.left,
                    //left: element.leftDisplay,
                    width: element.widthDisplay,
                    height: element.heightDisplay,
                    index: i,
                    type: element.type
                }
                return
            }
            
            this.canvasMouseMove = false

        }.bind(this));

        return canvasDragTemp

    }

    mouseDownCanvasJourney(e){

        e.preventDefault()
        if(this.journeyStatus !== 'unpublished'){
            alert(this.translate('JOURNEY_DESK_PUBLISHED_BUT_CANT_EDIT_ANYMORE'))
            return;
        }


        let x = e.pageX - 80;
        let y = e.pageY;

        this.canvasDragTemp = false
        this.elementTargetLine = false
        this.canvasDragTargetTemp = false
        this.canvasDragTemp = this.checkMouseDownElement(x, y)
        this.canvasDragTargetTemp = (this.canvasDragTemp === false) ? this.checkMouseDownTarget(x, y) : false
        
        if(!this.canvasLineMove.lineMove){

            this.canvasMouseDown = true
            this.canvasDrag = {
                drag: this.canvasDragTemp.canvasDrag,
                top: this.canvasDragTemp.top,
                left: this.canvasDragTemp.left,
                width: this.canvasDragTemp.width,
                height: this.canvasDragTemp.height,
                index: this.canvasDragTemp.index,
                type: this.canvasDragTemp.type
            }
            this.canvasDragTemp = false

            this.canvasMouseDownTarget = true
            this.canvasDragTarget = this.canvasDragTargetTemp
            this.canvasDragTargetTemp = false            
            
        }else if(this.canvasLineMove.lineMove){
            
            if(this.elementTargetLine){
                this.connectCanvasLinesTarget(this.elementTargetLine)
            }else{
                this.removeCanvasLines()
            }
        }
        
    }
    
    mouseMoveJourneyWorkbench(e){
        e.preventDefault()
        if(this.canvasDrag.drag){
            $(".target-actions-tools").remove()
            this.canvasMouseMove = true;
            this.moveActionsWorkbench(e)
        }else if(this.canvasLineMove.lineMove){
            this.drawCanvasLines(e)
        }

    }

    mouseUpCanvasJourney(e){

        e.preventDefault();
        if(this.journeyStatus !== 'unpublished'){
            alert(this.translate('JOURNEY_DESK_PUBLISHED_BUT_CANT_EDIT_ANYMORE'))
            return;
        }

        let x = e.pageX - 80;
        let y = e.pageY;

        /*Element */
        if(this.canvasDrag.drag && this.canvasMouseMove !== true && this.canvasLineMove.lineMove !== true){
            
            $(".actions-tools").remove()
            this.clickCanvasAction(e, this.canvasDrag);
            this.canvasDrag = false;
        
        }else if(this.canvasLineMove.lineMove){
            this.canvasDragTemp = this.checkMouseDownElement(x, y);
            if(this.elementTargetLine.target){
                this.connectCanvasLinesTarget(this.elementTargetLine)
            }else{
                this.removeCanvasLines()
            }
        }else if(this.canvasMouseDown && this.canvasDrag.drag){
            this.updateJsonJourney();
        }else{
            $(".actions-tools").remove()
            this.canvasDrag = false;
        }

        /*Line */
        
        if(this.canvasDragTarget.dragTarget) {
            $(".target-actions-tools").remove()
            this.clickCanvasTargetAction(e, this.canvasDragTarget)
            this.canvasDragTarget = false
        } else {
            $(".target-actions-tools").remove()
            this.canvasDrag = false
        }

        this.canvasMouseDown = false
        this.canvasMouseDownTarget = false
        
    }

    moveActionsWorkbench(e){
        let x = e.pageX - 80;
        let y = e.pageY;
        if(this.canvasMouseDown && this.canvasDrag.drag){
            const drag = this.canvasDrag
            const journeyJson = this.journeyJson
            journeyJson[drag.index].left = x
            journeyJson[drag.index].top = y
            journeyJson[drag.index].targets.forEach(function(element, i){
                if(element !== null){
                    journeyJson[drag.index].targets[i].leftSource = x
                    journeyJson[drag.index].targets[i].topSource = y
                }
            })
            journeyJson[drag.index].connections.forEach(function(element, i){
                if(element !== null){
                    journeyJson.forEach(function(elementT, iT){
                        if(elementT.id === element.elementSource){
                            journeyJson[iT].targets.forEach(function(elementC, iC){
                                if(elementC.elementTarget.id === journeyJson[drag.index].id){
                                    journeyJson[iT].targets[iC].leftTarget = x
                                    journeyJson[iT].targets[iC].topTarget = y
                                }
                            })
                        }
                    })
                }
                /*
                if(element !== null){
                    if(typeof journeyJson[element.indexSource] !== 'undefined' && typeof journeyJson[element.indexSource]['targets'] !== 'undefined'){
                        journeyJson[element.indexSource].targets.forEach(function(elementS, i){
                            if(elementS.elementTarget !== null && elementS.elementTarget.index === element.indexTarget){
                                journeyJson[element.indexSource].targets[i].leftTarget = x
                                journeyJson[element.indexSource].targets[i].topTarget = y
                            }
                        })
                    }
                }
                */
            })
            this.journeyJson = journeyJson
            this.drawCanvasJourney()
        }
    }

    drawCanvasLines(e){

        const journeyJson = this.journeyJson

        journeyJson[this.canvasLineMove.indexSource].targets[this.canvasLineMove.indexLine].leftTarget = e.pageX - 80
        journeyJson[this.canvasLineMove.indexSource].targets[this.canvasLineMove.indexLine].topTarget = e.pageY

        this.journeyJson = journeyJson
        
        this.drawCanvasJourney();
    }

    removeCanvasLines(){
        const journeyJson = this.journeyJson
        let indexSource = this.canvasLineMove.indexSource
        let indexLine = this.canvasLineMove.indexLine
        journeyJson[indexSource].targets.splice(indexLine, 1);
        this.journeyJson = journeyJson;
        this.canvasLineMove = false
        this.drawCanvasJourney();
        this.updateJsonJourney();
    }

    connectCanvasLinesTarget(elementTarget){
        
        const journeyJson = this.journeyJson
        
        const checkIfConnectedToTheSameElementMoreThanOnce = () => {
            let targetIndex = elementTarget.index
            let sourceId = journeyJson[[this.canvasLineMove.indexSource]].id
            let check = journeyJson[targetIndex].connections.filter(connection=>connection.elementSource === sourceId)
            
            return check.length > 0 ? true : false 
        }
        
        
        if (checkIfConnectedToTheSameElementMoreThanOnce()) return
 
        journeyJson[this.canvasLineMove.indexSource].targets[this.canvasLineMove.indexLine].elementTarget = elementTarget
        journeyJson[this.canvasLineMove.indexSource].targets[this.canvasLineMove.indexLine].elementTarget['indexSource'] = this.canvasLineMove.indexSource
        journeyJson[this.canvasLineMove.indexSource].targets[this.canvasLineMove.indexLine].elementTarget['indexLine'] = this.canvasLineMove.indexLine

        journeyJson[elementTarget.index].connections.push({
            elementSource: this.canvasLineMove.elementSource,
            indexSource: this.canvasLineMove.indexSource,
            indexTarget: elementTarget.index,
            indexLine: this.canvasLineMove.indexLine,
            idLine: this.canvasLineMove.id
        })

        this.journeyJson = journeyJson;
        this.canvasLineMove = false
        this.drawCanvasJourney();
        this.updateJsonJourney();
    }

    createDivToolsActions(data){
        let x = data.left;
        let y = data.top;

        let div = document.createElement("div");

        div.className = 'actions-tools actions-tools-' + data.index;
        div.style.position = "absolute";
        div.style.left =  (x + 10) +"px";
        div.style.top = (y - 70) + "px";
        div.style.width = "140px";
        //div.style.width = data.width + "px";
        div.style.height = "140px";
        //div.style.height = data.height + "px";
        div.style.color = "blue";

        /* Inicio Botão de Remove */
        let buttonDelete = document.createElement("button");
        buttonDelete.type = "button"
        buttonDelete.className = 'remove_button_journey btn btn-warning'
        buttonDelete.dataset.index = data.index
        
        let iconDelete = document.createElement("i");
        iconDelete.className="fa fa-trash"

        buttonDelete.appendChild(iconDelete)
        /* Fim Botão de Remove */

        /* Inicio Botão de Seta */
        let buttonArrow = document.createElement("button");
        buttonArrow.type = "button"
        buttonArrow.className = 'start_arrow btn btn-default'
        buttonArrow.dataset.index = data.index
        
        let iconArrow = document.createElement("i");
        iconArrow.className="fa fa-arrow-circle-o-right fa-2x"

        buttonArrow.appendChild(iconArrow)
        /* Fim Botão de Seta */

        /* Inicio Tools */
        let buttonTool = document.createElement("button");
        buttonTool.type = "button"
        buttonTool.className = 'open_modal_action btn btn-default'
        buttonTool.dataset.index = data.index
        
        let iconTool = document.createElement("i");
        iconTool.className="fa fa-cog fa-2x"

        buttonTool.appendChild(iconTool)
        /* Fim Tools */

        div.appendChild(buttonDelete)
        div.appendChild(buttonArrow)
        div.appendChild(buttonTool)

        document.body.appendChild(div);
    }

    createTargetDivToolsActions(data){
        let x = data.midPoint[0]
        let y = data.midPoint[1]

        let div = document.createElement("div");

        div.className = `target-actions-tools target-actions-tools-${data.index}`
        div.style.position = "absolute"
        div.style.left =  `${x + 65 }px`
        div.style.top = `${y - 17.5 }px`
        div.style.width = "140px"
        div.style.height = "140px"
        div.style.color = "red"

        /* Inicio Botão de Remove */
        let buttonDelete = document.createElement("button");
        buttonDelete.type = "button"
        buttonDelete.className = 'remove_button_target_journey btn btn-danger rounded-circle'
        
        buttonDelete.dataset.index = data.index
        
        let iconDelete = document.createElement("i")
        iconDelete.className="fa fa-trash"

        buttonDelete.appendChild(iconDelete)
        /* Fim Botão de Remove */

        div.appendChild(buttonDelete)

        document.body.appendChild(div);
    }

    createDivToolsCondictions(data){
        let x = data.left;
        let y = data.top;

        let div = document.createElement("div");

        div.className = 'actions-tools actions-tools-' + data.index;
        div.style.position = "absolute";
        div.style.left =  (x + 10) +"px";
        div.style.top = (y - 70) + "px";
        div.style.width = "140px";
        div.style.height = "140px";
        div.style.color = "blue";

        let buttonDelete = document.createElement("button");
        buttonDelete.type = "button"
        buttonDelete.className = 'condition_remove_button_journey remove_button_journey btn btn-warning'
        buttonDelete.dataset.index = data.index
        
        let iconDelete = document.createElement("i");
        iconDelete.className="fa fa-trash"

        buttonDelete.appendChild(iconDelete)



        let buttonArrowTrue = document.createElement("button");
        buttonArrowTrue.type = "button"
        buttonArrowTrue.className = 'start_arrow_true btn btn-default'
        buttonArrowTrue.dataset.index = data.index
        
        let iconArrowTrue = document.createElement("i");
        iconArrowTrue.className="fa fa-thumbs-up"

        buttonArrowTrue.appendChild(iconArrowTrue)


        let buttonArrowFalse = document.createElement("button");
        buttonArrowFalse.type = "button"
        buttonArrowFalse.className = 'start_arrow_false btn btn-default'
        buttonArrowFalse.dataset.index = data.index
        
        let iconArrowFalse = document.createElement("i");
        iconArrowFalse.className="fa fa-thumbs-down"

        buttonArrowFalse.appendChild(iconArrowFalse)


        /* Inicio Tools */
        let buttonTool = document.createElement("button");
        buttonTool.type = "button"
        buttonTool.className = 'condition_tools open_modal_action btn btn-default'
        buttonTool.dataset.index = data.index
        
        let iconTool = document.createElement("i");
        iconTool.className="fa fa-cog fa-2x"

        buttonTool.appendChild(iconTool)
        /* Fim Tools */


        div.appendChild(buttonDelete)
        div.appendChild(buttonArrowTrue)
        div.appendChild(buttonArrowFalse)
        div.appendChild(buttonTool)

        document.body.appendChild(div);
    }

    

    clickCanvasAction(e, data){
        if(typeof data['type'] !== 'undefined'){
            if(data.type !== "condition"){
                this.createDivToolsActions(data)
            }else{
                this.createDivToolsCondictions(data)
            }
        }
    }

    clickCanvasTargetAction(e, data){
        if(typeof data !== 'undefined'){
            this.createTargetDivToolsActions(data)
        }
    }

    applyZoomOutJourney(){
        if(this.state.zoomJourneyWorkbench > 50){
            this.zoomJourneyWorkbench = this.state.zoomJourneyWorkbench - 10
            this.runReDrawAfterZoom(this.zoomJourneyWorkbench)
        }
    }

    applyZoomInJourney(){
        if(this.state.zoomJourneyWorkbench < 100){
            this.zoomJourneyWorkbench = this.state.zoomJourneyWorkbench + 10
            this.runReDrawAfterZoom(this.zoomJourneyWorkbench)
        }
    }

    runReDrawAfterZoom(zoom){

        /*
        this.journeyJson.forEach(function(element, i){
            element.targets.forEach(function(line, il){
                this.journeyJson[i].targets[il].leftTarget = this.journeyJson[i].targets[il].leftTarget * zoom
                this.journeyJson[i].targets[il].topTarget = this.journeyJson[i].targets[il].topTarget * zoom
                this.journeyJson[i].targets[il].leftSource = this.journeyJson[i].targets[il].leftSource * zoom
                this.journeyJson[i].targets[il].topSource = this.journeyJson[i].targets[il].topSource * zoom
            }.bind(this))
        }.bind(this))
        */

        this.journeyJson.forEach(function(element, i){
            this.journeyJson[i].leftDisplay = this.journeyJson[i].left * (zoom / 100)
            this.journeyJson[i].topDisplay = this.journeyJson[i].top * (zoom / 100)
            this.journeyJson[i].widthDisplay = this.journeyJson[i].width * (zoom / 100)
            this.journeyJson[i].heightDisplay = this.journeyJson[i].height * (zoom / 100)
        }.bind(this))

        this.setState({
            zoomJourneyWorkbench: zoom
        });

        this.drawCanvasJourney()
    }

    showModalAction(values){
        let nameTypeConfig;
        switch(values.type){
            case 'trigger':
                nameTypeConfig = 'Trigger'
                break;
            case 'action':
                nameTypeConfig = 'Action'
                break;
            case 'condition':
                nameTypeConfig = 'Condition'
                break;
            default:
                break;
        }
        this.setState({
            modalAction: true,
            nameTypeConfig: nameTypeConfig
        })
        this.loadModalTool(values)
    }

    loadModalTool(values){
        let componentAction = null;
        switch(values.code){
            case "page_visited":
                componentAction = <VisitedPage values={values} translate={this.translate}/>
                break;
            case "facebook_leads":
                componentAction = <FacebookLeads values={values} translate={this.translate}/>
                break;
            case "exported_leads":
                componentAction = <ExportedLeads values={values} translate={this.translate}/>
                break;
            case "journey_entrance":
                componentAction = <EntryRuler values={values} translate={this.translate}/>
                break;
            case "lead_sourced_99sales":
                componentAction = <Leads99vendas values={values} translate={this.translate}/>
                break;
            case "portal_sourced_leads":
                componentAction = <InputLeadPortals values={values} translate={this.translate}/>
                break;
            case "alert_view":
                componentAction = <AlertView values={values} translate={this.translate}/>
                break;
            case "report_broker":
                componentAction = <ReportBroker values={values} translate={this.translate}/>
                break;
            case "add_delay":
                componentAction = <AddDelay values={values} translate={this.translate}/>
                break;
            case "add_tag":
                componentAction = <AddTag values={values} translate={this.translate}/>
                break;
            case "lead_score":
                componentAction = <LeadScore values={values} translate={this.translate}/>
                break;
            case "dynamic_image":
                componentAction = <DynamicImage values={values} translate={this.translate}/>
                break;
            case "modal_banner":
                componentAction = <ModalBanner values={values} translate={this.translate}/>
                break;
            case "send_email":
                componentAction = <SendEmail values={values} translate={this.translate}/>
                break;
            case "send_sms":
                componentAction = <SendSms values={values} translate={this.translate}/>
                break;
            case "capture_form":
                componentAction = <CaptureForm values={values} translate={this.translate}/>
                break;
            case "lead_actions":
                componentAction = <LeadActions values={values} translate={this.translate}/>
                break;
            case "next_journey":
                componentAction = <NextJourney values={values} translate={this.translate}/>
                break;
            case "quest_of_interest":
                componentAction = <QuestOfInterest values={values} translate={this.translate}/>
                break;
            case "test_a_b":
                componentAction = <TestAB values={values} translate={this.translate}/>
                break;
            case "put_on_hold":
                componentAction = <PutOnHold values={values} translate={this.translate}/>
                break;
            case "pitch_relationship_ruler":
                componentAction = <PitchRelationshipRuler values={values} translate={this.translate}/>
                break;
            case "scheduled_visit":
                componentAction = <ScheduledVisit values={values} translate={this.translate}/>
                break;
            case "check_visit":
                componentAction = <CheckVisit values={values} translate={this.translate}/>
                break;
            case "check_proposal":
                componentAction = <CheckProposal values={values} translate={this.translate}/>
                break;
            case "proposed_status":
                componentAction = <ProposedStatus values={values} translate={this.translate}/>
                break;
            case "email_status":
                componentAction = <EmailStatus values={values} translate={this.translate}/>
                break;
            case "check_contact":
                componentAction = <CheckContact values={values} translate={this.translate}/>
                break;
            case "check_sale":
                componentAction = <CheckSale values={values} translate={this.translate}/>
                break;
            case "check_referrals":
                componentAction = <CheckReferrals values={values} translate={this.translate}/>
                break;
            case "visited_page":
                componentAction = <VisitedPageCondition values={values} translate={this.translate}/>
                break;
            case "view_source":
                componentAction = <ViewSource values={values} translate={this.translate}/>
                break;
            case "check_lead":
                componentAction = <CheckLead values={values} translate={this.translate}/>
                break;
            case "check_feedback":
                componentAction = <CheckFeedback values={values} translate={this.translate}/>
                break;
            default:
                componentAction = null
        }
        if(componentAction !== null){
            this.setState({modalCallAction: componentAction})
        }
    }

    closeModalAction(){
        this.setState({
            modalAction: false,
            modalCallAction: null
        })
    }

    render() {
        const zoonPercentage = this.state.zoomJourneyWorkbench / 100;
        return (
            
            <React.Fragment>
                <MenuMain addClass="folded"/>
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    
                    <canvas id="journey_workbench" width="15000" height="15000" onDrop={this.dropJourney} onDragOver={this.allowDropJourney} onMouseDown={this.mouseDownCanvasJourney} onMouseMove={this.mouseMoveJourneyWorkbench} onMouseUp={this.mouseUpCanvasJourney} style={{zoom: zoonPercentage}}></canvas>
                    
                    <div id="actions_workbench">

                        <button type="button" id="disable_journey" onClick={this.buttonPublishJourney} className={(this.state.journeyDataJson.status === 'unpublished')? 'btn btn-success': 'btn btn-danger'}>
                            <i className="fa fa-ban" aria-hidden="true"></i> {(this.state.journeyDataJson.status === 'unpublished')? this.translate('JOURNEY_DESK_PUBLISH_JOURNEY'): this.translate('JOURNEY_DESK_UNPUBLISH_JOURNEY')}
                        </button>

                        <p className="status">
                            <i className="fa fa-check-circle" aria-hidden="true"></i> {this.translate('JOURNEY_DESK_PUBLISHED')}
                        </p>

                        <hr></hr>

                        <div className="row trigger_group">
                            <h5>
                                <a className="" data-toggle="collapse" href="#t_triggers" aria-expanded="true" aria-controls="t_triggers">
                                    {this.translate('JOURNEY_DESK_TRIGGERS')} <i className="fa fa-chevron-down"></i>
                                </a>
                            </h5>
                            <div className="collapse show" id="t_triggers" data-target="t_triggers" aria-expanded="true" aria-controls="t_triggers">
                                {
                                    this.state.actionsJson.triggers.map((data, i) => (
                                        <div key={i} draggable="true" data-type="trigger" data-code={data.code} data-name={data.name} onDragStart={this.dragActions} className="action_journey text-center col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                            <i className={data.icon} aria-hidden="true"></i>
                                            <p>{data.name}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="row action_group mt-3">
                            <h5>
                                <a className="" data-toggle="collapse" href="#t_actions" role="button" aria-expanded="true" aria-controls="t_actions">
                                    {this.translate('JOURNEY_DESK_ACTIONS')} <i className="fa fa-chevron-down"></i>
                                </a>
                            </h5>
                            <div className="collapse multi-collapse collapsed" id="t_actions" aria-expanded="true">
                                {
                                    this.state.actionsJson.actions.map((data, i) => (
                                        <div key={i} draggable="true" data-type="action" data-code={data.code} data-name={data.name} onDragStart={this.dragActions} className="action_journey text-center col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                            <i className={data.icon} aria-hidden="true"></i>
                                            <p>{data.name}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="row condition_group mt-3">
                            <h5>
                                <a className="" data-toggle="collapse" href="#t_conditions" role="button" aria-expanded="true" aria-controls="t_conditions">
                                    {this.translate('JOURNEY_DESK_CONDITIONS')} <i className="fa fa-chevron-down"></i>
                                </a>
                            </h5>
                            <div className="collapse multi-collapse collapsed" id="t_conditions" aria-expanded="true">
                                {
                                    this.state.actionsJson.conditions.map((data, i) => (
                                        <div key={i} draggable="true" data-type="condition" data-code={data.code} data-name={data.name} onDragStart={this.dragActions} className="action_journey text-center col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                            <i className={data.icon} aria-hidden="true"></i>
                                            <p>{data.name}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                    </div>
                    <div id="tools_workbench"></div>
                    <div id="data_workbench">
                        <form>
                            <div className="row">
                                <div className="col-4 text-center">
                                    {this.state.journeyDataJson.name}
                                </div>
                                <div className="col-4">
                                    <input className="form-control" disabled style={{color: '#000'}} defaultValue={this.state.journeyDataJson.funnel}/>
                                </div>
                                <div className="col-4">
                                    <input className="form-control" disabled style={{color: '#000'}} defaultValue={this.state.journeyDataJson.funnel_placement}/>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <Modal show={this.state.modalAction} onHide={this.closeModalAction} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 pb-5">
                            <div className="p-3 b-b d-flex no-shrink green text-center">
                                <h5 className="h5 m-0 text-center width100 line-height-38px">
                            <i className="fa fa-cog"></i> {this.translate('JOURNEY_DESK_CONFIG')} {this.state.nameTypeConfig}
                                </h5>
                                <button onClick={this.closeModalAction} className="btn btn-transparent ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </button>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-10 offset-md-1">
                                    {
                                        (this.state.modalCallAction)
                                    }
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            </React.Fragment>

        );
    }
}

export default withRouter(JourneyDesk);