import React, { Component } from 'react'

import MetricComparisonTotalBarChart from './Comparisons/MetricComparisonTotalBarChart'


export default class ComparisonBuilder extends Component{

    getComparisonElement = (comparisonKey) => {
        let ComparisonElement
        switch (comparisonKey) {
            case "total_leads":
            case "total_suspects":
                ComparisonElement = MetricComparisonTotalBarChart
                break
            default:
                ComparisonElement = <p>Comparação não encontrada</p>
                break
        }
        return ComparisonElement
    }

    renderComparison = () => {

        let ComparisonElement = this.getComparisonElement(this.props.comparisonKey)

        return (
            <ComparisonElement {...this.props} />
        )
    }
  
    render = () => this.renderComparison()
    
}
