import React, { Component } from 'react';

import $ from 'jquery';

import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4'

import api from '../../../../Services/Api';
import {ajaxUrl, ajaxToken} from '../../../../Services/Ajax';

class Lopes extends Component {

    constructor(props){
        super(props);
        this.state = {
            tableLopes: null,
            imobs: [],
            formSaveLopes: {
                codigo_da_empresa: null,
                codigo_do_produto: null,
                broker: null
            }
        }

        this.formSubmitSaveLopes = this.formSubmitSaveLopes.bind(this)
        this.deleteCRM = this.deleteCRM.bind(this)
        this.translate = this.props.translate
    }

    async loadImobs(){
        try{
            const response = await api.get("/brokers/selects");
            this.setState({imobs: response.data})
        }catch(err){
            console.log(err)
        }
    }

    componentDidMount(){

        this.loadImobs();

        let tableLopes = this.state.tableLopes
        tableLopes = $('#brokers_lopes').DataTable({
            "searching": true,
            "paging": false,
            "info": false,
            "responsive": true,
            "language": {
                "url": this.translate('DATATABLE_LANGUAGE_URL')
            },
            "ajax": {
                "url": ajaxUrl('/crms/table/all/lopes/active'),
                "type": 'GET',
                "beforeSend": function (request) {
                    request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                }
            },
            "columnDefs": [{
                "targets": -1,
                "data": null,
                "defaultContent": 
                    `<div class="btn-group btn-shadow">
                        <button class="delete btn btn-sm danger btn-cursor-pointer" title="${this.translate('LOPES_REMOVE_INTEGRATION')}">
                            <i class="fa fa-trash" title="${this.translate('LOPES_REMOVE_INTEGRATION')}"></i>
                        </button>
                    </div>`
            }]
        });

        $('#brokers_lopes tbody').on( 'click', '.delete', function (e){
            
            e.preventDefault();

            let data = [];
            
            $(e.target).prop('disabled', true).html('<i className="fa fa-spinner"></i>')

            if($(e.target).parents('tr').hasClass('child')){
                data = tableLopes.row($(e.target).parents('tr').prev('.parent')).data();
            }else{
                data = tableLopes.row($(e.target).parents('tr')).data();
            }
            
            this.deleteCRM(data[1]);
            
        }.bind(this));
    }

    async deleteCRM(id){
        const data = {id: id}
        try{
            const response = await api.post("/crms/update", data);
            if(response.data.success === true){
                this.reloadDatatableCRMs()
            }else{
                console.log('error');
            }
        }catch(err){
            console.log(err);
        }
    }

    reloadDatatableCRMs(){
        $('#brokers_lopes').DataTable().ajax.reload();
    }

    async formSubmitSaveLopes(e){
        e.preventDefault();
        $('#add_lopes').prop('disabled', true).html(`<i className="fa fa-spinner"></i> ${this.translate('ICON_WAIT')}`)
        const data = this.state.formSaveLopes;
        try{
            const response = await api.post("/crms/create/lopes", data);
            if(response.data.success === true){
                this.reloadDatatableCRMs()
                $('#add_lopes').prop('disabled', false).html(`<i className="fa fa-save"></i> ${this.translate('ICON_ADD')}`)
            }else{
                console.log('error');
            }
        }catch(err){
            console.log(err)
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div id="accordionLopes" style={{width: '100%'}}>
                        <div className="col-12 panel box no-border mb-2" style={{width: '100%'}}>
                            <div className="box-header b-b p-y-sm fwhite">
                                <button type="button" style={{width: '100%'}} className="btn btn-transparent" data-parent="#accordionLopes" onClick={(e)=>this.props.toggleCollapse("#formlopes")} aria-expanded="true">
                                    {this.translate('LOPES_ADD_LOPES_ACCOUNT')}
                                    <span className="float-right badge text-sm">
                                        <i className="fa fa-angle-down"></i>
                                    </span>
                                </button>
                            </div>

                            <div id="formlopes" className="in collapse">

                                <div className="col-sm-12 col-md-12 col-lg-12">

                                    <form onSubmit={this.formSubmitSaveLopes} id="form_config_lopes">
                                        <br/>
                                        <div>
                                            <div className="col-sm-12 col-md-12 mb20">
                                                <input type="text" onChange={e => this.setState({ formSaveLopes: {...this.state.formSaveLopes, codigo_da_empresa:  e.target.value}})} name="codigo_da_empresa" id="config_crmlopes_codigo_da_empresa" placeholder={this.translate('LOPES_COMPANY_CODE')} className="form-control" required="required" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="col-sm-12 col-md-12 mb20">
                                                <input onChange={e => this.setState({ formSaveLopes: {...this.state.formSaveLopes, codigo_do_produto:  e.target.value}})} name="codigo_do_produto" id="config_crmlopes_codigo_do_produto" placeholder={this.translate('LOPES_PRODUCT_CODE')} className="form-control fwhite" type="text" required="required" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="col-sm-12 col-md-12 mb20">
                                                <select onChange={e => this.setState({ formSaveLopes: {...this.state.formSaveLopes, broker:  e.target.value}})} name="broker" id="imobhypnobox" className="form-control box-shadow cool-select" required="required">
                                                    <option value="">{this.translate('LOPES_SELECT_BROKER')}</option>
                                                    {
                                                        this.state.imobs.map((data, i) => (
                                                            <option key={i} value={data.id}>{data.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="col-sm-12 col-md-12 mb20">
                                                <button id="add_lopes" type="submit" className="pull-right btn btn-success px-5 btn-rounded btn-shadow">
                                                    <i className="fa fa-plus"></i> {this.translate('ICON_ADD')}
                                                </button>
                                            </div>
                                        </div>
                                    </form>

                                </div>

                            </div>

                        </div>
                    </div>

                    <div className="col-12 p-1 text-left dark">

                        <table id="brokers_lopes" className="table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                            <thead className="dark">
                                <tr className="text-left">
                                    <th>{this.translate('LOPES_BROKER')}</th>
                                    <th>{this.translate('TABLE_ACTIONS_ROW')}</th>
                                </tr>
                            </thead>
                        </table>

                    </div>

                </div>
            </React.Fragment>
        );
    }
}

export default Lopes;