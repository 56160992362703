import React, {Component, Fragment} from 'react';
import MenuMain from '../../Structure/MenuMain';
import HeaderMain from '../../Structure/HeaderMain';
import FooterMain from '../../Structure/FooterMain';
import FeedMain from '../../Structure/FeedMain';
import {withRouter} from 'react-router-dom';

import api from '../../Services/Api';

class LeadsFeedbacks extends Component {
    
    constructor(props){
        super(props);
        document.title = "Feedback Leads"
        this.state = {
            list_feedback_positive: [],
            list_feedback_negative: [],
            list_feedback_audited: []
        }
    }

    async returnResponse(){
        return await api.get('/leads/feedbacks')
        .then(async response => {
            return response.data;
        })
    }

    async componentDidMount(){
        this.returnResponse().then((data) => {
            this.setState({
                list_feedback_positive: [],
                list_feedback_negative: [],
                list_feedback_audited: [],
            });
        })
        const link = document.querySelector('a[data-target="'+ document.location.hash +'"]')
        if(link){
            link.click();
        }
    }
    
    render() {
        return (
            <Fragment>
                <MenuMain />
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    <HeaderMain title="Feedback Leads" onChange={this.handleChange}/>
                    <div className="content-main row row-no-margin" id="content-main">
                        <div className=" col-lg-10 col-md-10 col-sm-12 col-xs-12 no-gutters" id="leads">
                            
                            <div className="white bg b-b px-3">
                                <div className="row ">
                                    <div className="col-sm-5 order-sm-1 text-center p0">
                                        <div className="py-4 clearfix nav-active-theme">
                                            <ul className="nav nav-pills nav-sm p-2 pb20 pt20">
                                                <li className="nav-item f700 w-100">
                                                    <a className="nav-link f-700 text-left f20" href="/">
                                                        Feedbacks
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-sm-7 order-sm-1 text-center p0">
                                        <div className="py-4 clearfix nav-active-theme ">
                                            <ul className="nav nav-pills nav-sm p-2 pb20 pt20 pull-right">
                                                <li className="nav-item">
                                                    <a className="nav-link active" href="/" data-toggle="tab" data-target="#tab_1">
                                                        <i className="fa fa-thumbs-up"></i> Positivos
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" href="/" data-toggle="tab" data-target="#tab_2">
                                                        <i className="fa fa-thumbs-down"></i> Negativos
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" href="/" data-toggle="tab" data-target="#tab_3">
                                                        <i className="fa fa-hand-stop-o"></i> Auditados
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="padding">
                                <div className="row">
                                    <div className="col-sm-12 col-lg-12">
                                        <div className="tab-content">

                                            <div className="tab-pane fade active show" id="tab_1">
                                                <div className="white p-3">
                                                    <div className="box-header">
                                                        <h3> Feedbacks Positivos</h3>
                                                    </div>
                                                    <div className="list inset">
                                                        
                                                        {
                                                            this.state.list_feedback_positive.map((value) => 

                                                                <div className="list-item " data-id="item-2">
                                                                    <span className="w-40 circle green">
                                                                        <i className="fa fa-thumbs-up f20 w-100 text-center" style={{lineHeight: "35px"}}></i>
                                                                    </span>
                                                                    <div className="list-body">
                                                                        <a href="/" className="item-title _500">
                                                                            {value.lead_name}
                                                                        </a>
                                                                        <div className="text-sm text-muted">
                                                                            {value.feedback}
                                                                        </div>
                                                                        <div className="item-tag tag hide"></div>
                                                                    </div>
                                                                    <div>
                                                                        <span className="item-date text-xs text-muted">
                                                                            {value.date}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                            )
                                                        }

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tab-pane fade" id="tab_2">
                                                <div className="white p-3">
                                                    <div className="box-header">
                                                        <h3> Feedbacks Negativos</h3>
                                                    </div>
                                                    <div className="list inset">
                                                        
                                                        {
                                                            this.state.list_feedback_negative.map((value) => 

                                                                <div className="list-item " data-id="item-2">
                                                                    <span className="w-40 circle red">
                                                                        <i className="fa fa-thumbs-down f20 w-100 text-center" style={{lineHeight: '35px'}}></i>
                                                                    </span>
                                                                    <div className="list-body">
                                                                        <a href="/" className="item-title _500">
                                                                            {value.lead_name}
                                                                        </a>
                                                                        <div className="text-sm text-muted">
                                                                            {value.feedback} 
                                                                        </div>
                                                                        <div className="item-tag tag hide"></div>
                                                                    </div>
                                                                    <div>
                                                                        <span className="item-date text-xs text-muted">
                                                                            {value.date}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                            )
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tab-pane fade" id="tab_3">
                                                <div className="white p-3">
                                                    <div className="box-header">
                                                        <h3> Feedbacks Auditados</h3>
                                                    </div>
                                                    <div className="list inset">
                                                        
                                                        {
                                                            this.state.list_feedback_audited.map((value) => 

                                                                <div className="list-item " data-id="item-2">
                                                                    <span className="w-40 circle yellow">
                                                                        <i className="fa fa-hand-stop-o f20 w-100 text-center" style={{lineHeight: '35px'}}></i>
                                                                    </span>
                                                                    <div className="list-body">
                                                                        <a href="/" className="item-title _500">
                                                                            {value.lead_name}
                                                                        </a>
                                                                        <div className="text-sm text-muted">
                                                                            {value.feedback}  
                                                                        </div>
                                                                        <div className="item-tag tag hide"></div>
                                                                    </div>
                                                                    <div>
                                                                        <span className="item-date text-xs text-muted">
                                                                            {value.date}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                            )
                                                        }

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <FeedMain />
                    </div>
                    <FooterMain />
                </div>
            </Fragment>
        );
    }
}

export default withRouter(LeadsFeedbacks);