import React, { Component } from "react"

import Modal from 'react-bootstrap/Modal';

import $ from 'jquery';
import 'jquery-mask-plugin';

import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4'
import api from "../../../../Services/Api";

//import api from '../../../Services/Api';
//import {ajaxUrl, ajaxToken} from '../../../Services/Ajax';

export default class CommercialDeliveryman extends Component {

    constructor(props){
        super(props)
        this.state = {
            msgResponseShow: "",
            isModalResponseVisible: false,
            isModalTeamVisible: false,
            editTeam: false,
            emksaTeam: {
                name: "",
                email: "",
                phone: "",
                supermarkets: [
                    { id: 1, value: ""}
                ]
            },
            supermarketsList: []
        }
        this.translate = this.props.translate
        this.index = 1
    }

    getIndex = () => ++this.index

    loadEmksaTeamTable = () => {

        let tableEmksaTeam = $('#emksa_deliveryman_table').DataTable({
            "searching": true,
            "paging": false,
            "info": false,
            "responsive": true,
            "language": {
                "url": this.translate('DATATABLE_LANGUAGE_URL')
            },
            "data": [
                      
                [
                  "João Henrique 2",
                  "joao@emksa.com.br",
                  "(11) 9999-9999",
                  "Supermercado 1, Supermercado 2",
                  "NWsadasdU3NTBjY2VkNGE0ZGM3ZWMzMzVjOTY1"
                ]
              ],
            /* "ajax": {
                "url": ajaxUrl('/email_marketing/table'),
                "type": 'GET',
                "beforeSend": function (request) {
                    request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                },
                "dataSrc": function ( json ) {
                    //Make your callback here.
                    console.log(json.data)
                    return json.data;
                }
            }, */
            "columnDefs": [{
                "targets": -1,
                "data": null,
                "defaultContent": 
                    `<div class="btn-group btn-shadow">
                        <button class="edit btn btn-sm orange btn-cursor-pointer" title="${this.translate("COMMERCIAL_EMKSA_EDIT")}">
                            <i class="fa fa-edit" title="${this.translate("COMMERCIAL_EMKSA_EDIT")}"></i>
                        </button>
                       
                    </div>` 
            }]
        });

        $('#emksa_deliveryman_table tbody').on( 'click', '.edit',(e) =>{
            
            e.preventDefault();

            let data = [];
            
            if($(e.target).parents('tr').hasClass('child')){
                data = tableEmksaTeam.row($(e.target).parents('tr').prev('.parent')).data();
            }else{
                data = tableEmksaTeam.row($(e.target).parents('tr')).data();
            }
            this.handleEditEmksaTeam(data)
        });
    }

    reloadEmksaTeamTable = () => {
        $('#emksa_deliveryman_table').DataTable().ajax.reload();
    }

    handleCloseModalResponse = (e = null) =>{
        if (e) e.preventDefault()
        this.setState({isModalResponseVisible: false})
    }

    handleCloseModalTeam = (e = null) =>{
        if (e) e.preventDefault()
        this.setState({isModalTeamVisible: false})
    }

    handleChangeSelectSupermarket = (e, id) => {
        let value = e.target.value
   
        let {emksaTeam} = this.state

        emksaTeam.supermarkets.forEach(supermarket=>{
            if(supermarket.id === id){
                supermarket.value = value
            }
        })

        this.setState({emksaTeam})
    }

    addSupermarket = () => {
        let {emksaTeam} = this.state
        emksaTeam.supermarkets.push({id: this.getIndex(), value: ""})
        this.setState({emksaTeam})
    }

    removeSupermarket = id => {
        let emksaTeam = this.state.emksaTeam
        if(emksaTeam.supermarkets.length === 1) return

        let newEmksaTeam = emksaTeam
        
        newEmksaTeam.supermarkets = newEmksaTeam.supermarkets.filter(supermarket=>supermarket.id !== id)
        this.setState({emksaTeam: newEmksaTeam})
    }

    getSupermarketList = async () => {
        try{
            //let response = await api.get("/")
            let response = {data: {success: true, supermarkets: [
                {value: "asd1", name: "Supermercado 1"},
                {value: "asd2", name: "Supermercado 2"},
                {value: "asd3", name: "Supermercado 3"},
            ]}}
            if(response && response.data.supermarkets && response.data.supermarkets.length) {
                this.setState({supermarketsList: response.data.supermarkets})
            }
        } catch(err){
            console.log("emksa team", err)
        }
    }

    handleTeamSubmit = async (e) => {
        e.preventDefault()
        let {emksaTeam} = this.state
        let postURL = this.state.editTeam ? "/attendance/edit" : "/attendance/post"
        try {
            //let response = await api.post(postURL, {emksaTeam: emksaTeam})
            let response = {data: {success: false}}
            if(response && response.data.success) {
                this.setState({
                    isModalTeamVisible: false, 
                    isModalResponseVisible: true, 
                    msgResponseShow: this.translate("COMMERCIAL_DELIVERYMAN_REGISTERED_WITH_SUCCESS") , 
                    emksaTeam: {
                        name: "",
                        email: "",
                        phone: "",
                        supermarkets: [
                            { id: 1, value: ""}
                        ]
                    }
                })
            } else {
                this.setState({
                    isModalResponseVisible: true, 
                    msgResponseShow: this.translate("COMMERCIAL_DELIVERYMAN_IT_WASNT_POSSIVLE_TO_REGISTER_DELIVERYMAN") , 
                    
                })
                
            }
        } catch (error) {
            
        }

    }

    handleAddEmksaTeam = () => {
        this.setState({
            isModalTeamVisible: true, 
            editTeam: false, 
            emksaTeam: {
                name: "",
                email: "",
                phone: "",
                supermarkets: [
                    { id: 1, value: ""}
                ]
            }
        })
    }
    
    handleEditEmksaTeam = async (data) => {
        console.log(data)
        //let emksaTeam = await this.getEmksaTeam(data[4])
        let emksaTeam = {
            name: data[0],
            email: data[1],
            phone: data[2],
            supermarkets: []
        }
        this.index = emksaTeam.supermarkets.length
        
        this.setState({emksaTeam, editTeam: true, isModalTeamVisible: true})
    }

    getEmksaTeam = async (id) => {
        let emksaTeam = null
        /* try{
            let response = await api.get(`/${id}`,)
            if(response && response.data.emksaTeam){
                emksaTeam = response.data.emksaTeam
            }
        } catch(err){
            console.log(err)
        } */

        return emksaTeam
    }

    componentDidMount = () => {
        this.loadEmksaTeamTable()
        this.getSupermarketList()

    }

    componentDidUpdate = (prevProps, prevState) => {
        if(prevState.isModalTeamVisible !== this.state.isModalTeamVisible && this.state.isModalTeamVisible){
            var SPMaskBehavior = function (val){
                return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00000';
            },
            spOptions = {
                onComplete: function(val){
                    var phone = $('#deliveryman_phone').val();
                    this.setState({emksaTeam: {...this.state.emksaTeam, phone: phone}})
                }.bind(this),
                onKeyPress: function(val, e, field, options) {
                    field.mask(SPMaskBehavior.apply({}, arguments), options);
                }
            };
    
            $('#deliveryman_phone').mask(SPMaskBehavior, spOptions);
        }
    }

    render = () => 
        <div>
            <div className="d-flex align-items-center px-2">
                <h5 className="fwhite flex-grow-1">{this.translate("COMMERCIAL_DELIVERYMAN_DELIVERYMAN_LIST")}</h5>
                <button className="btn btn-primary btn-cursor-pointer" onClick={()=>this.handleAddEmksaTeam()}>
                    <i className="fa fa-plus"></i>
                    &nbsp;{this.translate("COMMERCIAL_DELIVERYMAN_ADD_DELIVERYMAN")}
                </button>
            </div>
                <hr style={{width: "100%"}}/>
            <div className="box p-2">
                <table id="emksa_deliveryman_table" className="table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                    <thead>
                        <tr className="text-left">
                            <th>{this.translate("COMMERCIAL_DELIVERYMAN_NAME")}</th>
                            <th>{this.translate("COMMERCIAL_DELIVERYMAN_EMAIL")}</th>
                            <th>{this.translate("COMMERCIAL_DELIVERYMAN_PHONE")}</th>
                            <th>{this.translate("COMMERCIAL_DELIVERYMAN_SUPERMARKETS")}</th>
                            <th>{this.translate('TABLE_ACTIONS_ROW')}</th>
                        </tr>
                    </thead>
                </table>
            </div>
            <Modal show={this.state.isModalResponseVisible} onHide={this.handleCloseModalResponse} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                <Modal.Body>
                    <div className="d-flex flex-column h-100 bg-blur_w2">
                        <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                            <h5 className="h5 m-0 text-center width100">
                            <i className="fa fa-bell"></i> Status</h5>
                            <a href="/" onClick={e=>this.handleCloseModalResponse(e)} className="ml-auto">
                                <span className="text-lg l-h-1x">×</span>
                            </a>
                        </div>
                        <div className="row position10 padding-20px">
                            <span className="col-12 text-center py-2 lead">
                                {this.state.msgResponseShow}
                            </span>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={this.state.isModalTeamVisible} onHide={this.handleCloseModalTeam} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                <Modal.Body>
                    <div className="d-flex flex-column h-100 bg-blur_w2">
                        <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                            <h5 className="h5 m-0 text-center width100">
                            <i className={`fa ${this.state.editTeam ? "fa-edit" : "fa-plus"}`}></i> {this.translate(this.state.editTeam ? "COMMERCIAL_DELIVERYMAN_EDIT_DELIVERYMAN" : "COMMERCIAL_DELIVERYMAN_ADD_DELIVERYMAN")}</h5>
                            <a href="/" onClick={e=>this.handleCloseModalTeam(e)} className="ml-auto">
                                <span className="text-lg l-h-1x">×</span>
                            </a>
                        </div>
                        <div className="row position10 padding-20px">
                            <div className="col-12 col-md-6 mx-auto py-2 lead">
                                <div className="h5">{this.translate(this.state.editTeam ? "COMMERCIAL_DELIVERYMAN_EDIT_DELIVERYMAN" : "COMMERCIAL_DELIVERYMAN_ADD_DELIVERYMAN")}</div>
                                <hr />
                                <form onSubmit={this.handleTeamSubmit}>
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="deliveryman_name">{this.translate("COMMERCIAL_DELIVERYMAN_NAME")}</label>
                                            <input id="deliveryman_name" className="form-control" placeholder={this.translate("COMMERCIAL_DELIVERYMAN_LABEL_NAME")} type="text" value={this.state.emksaTeam.name} onChange={e=>this.setState({emksaTeam: {...this.state.emksaTeam, name: e.target.value}})} required />
                                        </div>
                                        <div className="form-group col-12">
                                            <label htmlFor="deliveryman_email">{this.translate("COMMERCIAL_DELIVERYMAN_EMAIL")}</label>
                                            <input id="deliveryman_email" className="form-control" placeholder={this.translate("COMMERCIAL_DELIVERYMAN_LABEL_EMAIL")} type="email" value={this.state.emksaTeam.email} onChange={e=>this.setState({emksaTeam: {...this.state.emksaTeam, email: e.target.value}})} required />
                                        </div>
                                        <div className="form-group col-12">
                                            <label htmlFor="deliveryman_phone">{this.translate("COMMERCIAL_DELIVERYMAN_PHONE")}</label>
                                            <input id="deliveryman_phone" className="form-control" placeholder={this.translate("COMMERCIAL_DELIVERYMAN_LABEL_PHONE")} type="text" onChange={e=>this.setState({emksaTeam: {...this.state.emksaTeam, phone: e.target.value}})} required />
                                        </div>
                                        {(this.state.emksaTeam.supermarkets && this.state.emksaTeam.supermarkets.length ) ? this.state.emksaTeam.supermarkets.map((supermarket, index)=>
                                            <div className="form-group col-12" key={`deliveryman_select_supermarket_${supermarket.id}`}>
                                                <div className="row px-3">
                                                    <label htmlFor={`deliveryman_select_supermarket_${supermarket.id}`}>{index + 1}° {this.translate("COMMERCIAL_EMKSA_SUPERMARKET")}</label>
                                                    <select id={`deliveryman_select_supermarket_${supermarket.id}`} className="form-control cool-select col-11" value={supermarket.value} onChange={e=>this.handleChangeSelectSupermarket(e, supermarket.id)} required>
                                                        <option value="">{this.translate("COMMERCIAL_EMKSA_SELECT_THE_SUPERMARKET")}</option>
                                                        <option value="all">{this.translate("COMMERCIAL_EMKSA_ALL_THE_SUPERMARKET")}</option>
                                                        {(this.state.supermarketsList && this.state.supermarketsList.length && this.state.supermarketsList.map((supermarketList, supermarketIndex)=>
                                                            <option key={`list_supermarket_${supermarketList.value + supermarketIndex}`} value={supermarketList.value}>{supermarketList.name}</option>
                                                        ))}
                                                    </select>
                                                    <div className="col-1 pr-0 d-flex justify-content-center align-items-center">
                                                        <button type="button" className="ml-auto btn btn-danger red btn-shadow btn-cursor-pointer d-flex justify-content-center align-items-center" disabled={this.state.emksaTeam.supermarkets.length === 1 ? "disabled" : ""} style={{height: "100%"}} onClick={()=>this.removeSupermarket(supermarket.id)}>
                                                            <i className="fa fa-times"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : false}
                                        <div className="form-group col-12">
                                            <button type="button" className="col-12 btn btn-primary blue btn-shadow d-flex justify-content-center align-items-center btn-cursor-pointer" style={{maxWidth: 200}} onClick={()=>this.addSupermarket()}>
                                                <i className="fa fa-plus"></i>&nbsp;{this.props.translate("COMMERCIAL_EMKSA_ADD_SUPERMARKET")}
                                            </button>
                                        </div>
                                        <div className="form-group col-12">
                                            <hr />
                                            <button className="col-12 ml-auto btn btn-primary blue btn-shadow d-flex justify-content-center align-items-center btn-cursor-pointer" style={{maxWidth: 250}}>
                                                <i className="fa fa-floppy-o"></i>&nbsp;{this.translate(this.state.editTeam ? "COMMERCIAL_DELIVERYMAN_EDIT_DELIVERYMAN" : "COMMERCIAL_DELIVERYMAN_ADD_DELIVERYMAN")}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
}