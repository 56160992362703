import React, { Component } from 'react'
import $ from "jquery"


export default class Stage extends Component{
    constructor(props){
        super(props)
        this.state = {
            isEditingTitle: false,
            stagesList: []
        }
    }

    loadStagesList = async () => {
        try {
            //let response = await api.get("")
            let response = {data:{
                success: true,
                stages: [
                    {
                        type: "optgroup",
                        title: "Segmentação",
                        options: [
                            {key: "all_segmentation", title: "Todas as Segmentações"},
                            {key: "segmentation_1", title: "Segmentação 1"},
                            
                        ]
                    },
                    {
                        type: "optgroup",
                        title: "Teste",
                        options: [
                            {key: "teste_1", title: "Teste 1"},
                            {key: "teste_2", title: "Teste 2"},
                            
                        ]
                    }
                    
                ]
            }}
            if(response && response.data.success && response.data.stages && response.data.stages.length){
                this.setState({stagesList: response.data.stages})
            }
        } catch (error) {
            console.log(error)
        }
    }


    disposeTooltip = () => $('[data-toggle="tooltip"]').tooltip('dispose')

    componentDidMount = () => {
        this.loadStagesList()

        $('[data-toggle="tooltip"]').tooltip('dispose').tooltip({placement: "bottom", trigger: "hover"})
    }

    componentDidUpdate = () => {
        $('[data-toggle="tooltip"]').tooltip('dispose').tooltip({placement: "bottom", trigger: "hover"})
    }

    
    render = () => {
        let { stage, translate, handleStageTitleChange, isEditing, handleStageRemove } = this.props
        return (
            <div className="col-12">
                <div className=" dark text-left w-100 box mt20 mb10 btn cursor-pointer">
                    {this.state.isEditingTitle ?
                            <div className="row">
                                <div className="col-12 d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <select className="form-control cool-select col-4" onChange={e=>handleStageTitleChange(e, stage.id, this.state.stagesList)} value={stage.key}>
                                            <option value="">Selecione a Etapa</option>
                                            {this.state.stagesList && this.state.stagesList.length && this.state.stagesList.map((stageMap, index)=>
                                                <optgroup key={`${stageMap.title}${index}`}  label={stageMap.title}>
                                                    {stageMap.options.map((option, indexOption)=>
                                                        <option key={`${option.key}${indexOption}`} value={option.key}>{option.title}</option>
                                                    )}
                                                </optgroup>

                                            )}
                                        </select>
                                    </div>

                                    <div className="d-flex align-items-center">
                                        <button type="button" onClick={()=>handleStageRemove(stage.id)} className="btn btn-danger btn-cursor-pointer d-flex justify-content-center align-items-center" style={{width: 35, height: 35}} data-toggle="tooltip" title={translate("DASHBOARD_PERFORMANCE_CARD_REMOVE_STAGE")}>
                                            <i className="fa fa-trash"></i>
                                        </button>
                                        <button type="button" onClick={()=>{this.disposeTooltip();this.setState({isEditingTitle: false})}} className="btn btn-success btn-cursor-pointer ml-2 d-flex justify-content-center align-items-center" style={{width: 35, height: 35}} data-toggle="tooltip" title={translate("DASHBOARD_PERFORMANCE_CARD_CONFIRM_CHANGES")}>
                                            <i className="fa fa-check"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            
                    :
                        <div className="d-flex align-items-center">
                            <span className="flex-grow-1">{stage.title || translate("CREATE_DASHBOARD_PERFORMANCE_ENTER_THE_NAME_THE_STAGE_NAME")}</span>
                            {isEditing &&
                                <button onClick={()=>{this.disposeTooltip();this.setState({isEditingTitle: true})}} className="ml-2 btn btn-warning btn-cursor-pointer" data-toggle="tooltip" title={translate("DASHBOARD_PERFORMANCE_CARD_EDIT_STAGE")}>
                                    <i className="fa fa-edit"></i>
                                </button>
                            }
                    </div>
                    }
                </div>
            </div>
        )
    }
    
}
