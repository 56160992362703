import React, { Component, Fragment } from 'react'

import $ from 'jquery'
import "bootstrap"
import 'datatables.net-dt'
import 'datatables.net-bs4'
import 'datatables.net-responsive'
import "datatables-buttons"
import "datatables.net-buttons"
import 'datatables.net-buttons/js/buttons.html5.min'
import 'datatables.net-responsive-bs4'

import api from '../../../../Services/Api'
import Status from '../../../../Templates/Status'


export default class OrderComponent extends Component {

    constructor(props) {
        super(props)

        this.stageNumber = 1
        this.translate = this.props.translate
        this.state = {
            isLoading: false,
            orderCode: "",
            status: false
        }
        this.table = false
    }

    updateDatatableData = (data) => {
        this.table.clear()
        this.table.rows.add(data)
        this.table.draw()
    }

    handleFormSubmit = async (e) => {
        e.preventDefault()
        if (this.state.isLoading || !this.state.orderCode) return
        this.setState({ isLoading: true })
        this.props.checkStageIsCorrect(this.stageNumber)
        let response = await this.searchOrder()
        if (!response) {
            this.setState({ isLoading: false, status: { success: false, message: "Não foi possível consultar as compras" } })
            return
        }
        let orders = this.formatOrders(response.orders)
        this.loadDatatable(orders)
        if (response.success) {
            this.setState({
                status: false,
                isLoading: false
            })
        } else {
            this.setState({
                status: {
                    success: response.success,
                    message: response.message
                },
                isLoading: false
            })
        }
    }

    searchOrder = async () => {
        let response = false
        try {
            //let response = await api.post("/order/reverse/filter",{ order_code: this.state.orderCode })
            response = this.getOrderMockup()
            if (response && response.data.message) {
                response = response.data
            }
        } catch (error) {
            console.log(error)
        }
        return response
    }

    formatOrders = (orders) => {

        let newOrders = orders.map((order, index) => {
            delete order._id
            return [index + 1, ...Object.values(order)]
        })
        return newOrders
    }


    getOrderMockup = () => {
        return {
            data: {
                success: true,
                message: "Compras consultadas com sucesso!",
                orders: [
                    {
                        _id: "adasdasd",
                        date: "27/10/2020 01:56:38",
                        idOrder: "1071962536746",
                        buyer_name: "Julio Marcos Piotroni",
                        stores: "Buylitics",
                    },
                    {
                        _id: "adas3dasd",
                        date: "27/10/2020 01:56:38",
                        idOrder: "1071962536746",
                        buyer_name: "Herinque Júnior Perez",
                        stores: ["DGBZ", "buylitics"],
                    },
                ]
            }
        }
    }

    loadDatatable(data) {
        if (this.table) {
            this.updateDatatableData(data)
            return
        }
        this.table = $('#table_order').DataTable({
            "searching": true,
            "paging": true,
            "info": false,
            "responsive": true,
            "data": data,
            "language": {
                "url": this.translate('DATATABLE_LANGUAGE_URL')
            },
            "columnDefs": [{
                "targets": -1,
                "data": null,
                "defaultContent":
                    `<div class="btn-group d-flex justify-content-center">
                        <button class="button_select btn btn-sm success btn-cursor-pointer" title="Escolher Compra">
                            <i class="fa fa-check" title="Escolher Compra"></i> Escolher
                        </button>
                    </div>`
            }],
        })

        let componentScope = this

        $('#table_order tbody').on('click', '.button_select', function (event) {
            event.preventDefault()
            let data = []
            if ($(this).parents('tr').hasClass('child')) {
                data = componentScope.table.row($(this).parents('tr').prev('.parent')).data()
            } else {
                data = componentScope.table.row($(this).parents('tr')).data();
            }
            componentScope.props.handleGoToNextStage(componentScope.stageNumber + 1, { orderId: data[2] })
        })
    }

    render = () =>
        this.props.currentStage === this.stageNumber &&

        <Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="box p-3">
                        <h3>Encontrar a Compra</h3>
                        <hr />
                        <Status status={this.state.status} />
                        <form onSubmit={this.handleFormSubmit}>
                            <div className="form-group">
                                <label htmlFor="search_order">Pesquisar Compra</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="search_order"
                                    placeholder="Insira o número da compra ou CPF do comprador"
                                    value={this.state.orderCode}
                                    onChange={e => this.setState({ orderCode: e.target.value })}
                                />
                            </div>
                            <div className="form-group">
                                {this.state.isLoading ?
                                    <button className="btn btn-fw blue btn-rounded btn-shadow disabled" type="button">
                                        <i className="fa fa-spin fa-spinner"></i>&nbsp;
                                        Carregando
                                    </button>
                                    :
                                    <button className="btn btn-fw blue btn-rounded btn-shadow btn-cursor-pointer" disabled={!this.state.orderCode} type="submit">
                                        <i className="fa fa-search"></i>&nbsp;
                                        Pesquisar Compra
                                    </button>
                                }
                            </div>
                        </form>

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="table-responsive">
                        <table id="table_order" className="table table-striped table-bordered">
                            <thead>
                                <tr className="text-left">
                                    <th>{`Nº`}</th>
                                    <th>{`Data`}</th>
                                    <th>{`Número da compra`}</th>
                                    <th>{`Comprador`}</th>
                                    <th>{`Loja`}</th>
                                    <th>{`Ação`}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan={6}>Pesquise pelo número da compra ou CPF do comprador</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </Fragment>

}
