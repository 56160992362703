import React, { Component } from 'react';

export class ActiveOriginAndListAll extends Component {

    returnElementNPN = (feedback_code, index) => {
        let NPN = {
            class: null,
            iconClass: null,
            buttonClass: null
        }
        switch(feedback_code){
            case 'positive':
                NPN.class = 'text_success opacity50'
                NPN.iconClass = 'fa fa-thumbs-up'
                NPN.buttonClass = 'btn btn-icon btn-sm btn-rounded btn-social success text-white'
                break
            case 'negative':
                NPN.class = 'text_danger'
                NPN.iconClass = 'fa fa-thumbs-down'
                NPN.buttonClass = 'btn btn-icon btn-sm btn-rounded btn-social danger text-white'
                break
            default:
                NPN.class = 'text-default'
                NPN.iconClass = 'fa fa-stop'
                NPN.buttonClass = 'btn btn-icon btn-sm btn-rounded btn-social default text-white'
                break
        }
        let response = null;
        switch(index){
            case 'class':
                response = NPN.class
                break
            case 'icon':
                response = NPN.iconClass
                break
            case 'button':
                response = NPN.buttonClass
                break
            default:
                break;
        }
        return response;
    }

    render() {
        return (
            <div className="col-sm-12 col-md-4">
                <h6 className="text-center dark f14 p-1 mb10">Campanhas ativas:</h6>
                <React.Fragment>
                    {
                        this.props.fields.active_campaigns.slice(0,3).map((data, i) => 
                            <div key={i} className="py-2 b-b">
                                <div className="float-right lh">
                                    <span href="#" className={this.returnElementNPN(data.campaign_feedback_code, 'button')} title="">
                                        <i className={this.returnElementNPN(data.campaign_feedback_code, 'icon')}></i>
                                        <i className={this.returnElementNPN(data.campaign_feedback_code, 'icon')}></i>
                                    </span>
                                </div>
                                <span className="_600 d-block m0">
                                    <a href="/">
                                        {data.campaign_name}
                                    </a>
                                </span>
                                <small className={this.returnElementNPN(data.campaign_feedback_code, 'class')}>
                                    Feedback: {data.campaign_feedback}
                                </small>
                            </div>
                        )
                    }
                </React.Fragment>
                <div className="p-2">
                    <a href="/leads_midia#tab_1" className="btn btn-sm btn-block white">Ver todas</a>
                </div>
            </div>
        );
    }
}

export default ActiveOriginAndListAll;