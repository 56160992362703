import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter as Router } from "react-router-dom";

import App from './App';

if (document.location.protocol !== 'https:') {
  document.location.replace(`https:${document.location.href.substring(document.location.protocol.length)}`);
}

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("app")
);