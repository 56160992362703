import React, { Component, Fragment } from "react";
import MenuMain from "../../../Structure/MenuMain";
import HeaderMain from "../../../Structure/HeaderMain";
import { withRouter } from "react-router-dom";
import api from "../../../Services/Api";

class BigLogger extends Component {
  constructor(props) {
    super(props);
    document.title = `Mapeamento para Big Data`;

    this.state = {
      limit: 100,
      skip: 1,
      logs: [],
      fetch: true,
      buttonName: "Carregar Mais",
    };
  }

  fetchMapping = async () => {
    this.setState({ fetch: true });

    try {
      const response = await api.get(
        `/data/mapping?limit=${this.state.limit}&skip=${this.state.skip}`
      );
      if (response && response.data && response.data.logger) {
        if (response.data.logger.length) {
          this.setState({
            logs: [...this.state.logs, ...response.data.logger],
            skip: this.state.skip + this.state.limit,
            fetch: false,
          });
        } else {
          this.setState({
            buttonName: "Nenhum Resultado",
          });
        }
      }
    } catch (err) {
      this.setState({ fetch: false });
      console.log(err);
    }
  };

  componentDidMount = async () => {
    await this.fetchMapping();
  };

  render() {
    return (
      <Fragment>
        <MenuMain />
        <div id="content" className="app-content box-shadow-0 dark" role="main">
          <HeaderMain
            title={`Mapeamento para Big Data`}
            onChange={this.handleChange}
          />
          <div className="content-main row row-no-margin" id="content-main">
            <div
              className=" col-lg-12 col-md-12 col-sm-12 col-xs-12 no-gutters"
              id="leads"
            >
              {this.state.logs.length ? (
                <>
                  {this.state.logs.map((log, index) => (
                    <div
                      className="text-white"
                      style={{
                        wordBreak: "break-all",
                        color: "#fff !important",
                        marginBottom: "5px",
                        paddingBottom: "5px",
                        borderBottom: "1px solid #111",
                        fontSize: "13px",
                      }}
                      key={this.state.limit + `-` + index}
                    >
                      {log}
                    </div>
                  ))}
                  <div className="text-center mt-4">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this.fetchMapping}
                      disabled={this.state.fetch}
                    >
                      {this.state.buttonName}
                    </button>
                  </div>
                </>
              ) : (
                <div>Carregando...</div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(BigLogger);
