import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';

import Modal from 'react-bootstrap/Modal';

import $ from 'jquery';

import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4'

import api from '../../../Services/Api';
//import {ajaxUrl, ajaxToken} from '../../../Services/Ajax';

import MenuMain from '../../../Structure/MenuMain';
import HeaderMain from '../../../Structure/HeaderMain';
import FooterMain from '../../../Structure/FooterMain';

class TriggeredSMS extends Component {

    constructor(props){
        super(props);
        this.translate = this.props.translate
        document.title = this.translate('TRIGGERED_SMS_TRIGGERED_SMSS')
        this.state = {
            isModalAddBaseVisible: false,
            modalResponseShow: false,
            msgResponseShow: "",
            tableBaseCsv: {
                thValues:[],
                thValuesAux:[],
                thSelectOptions: ["name", "phone"],
                baseJsonData:[]
            },
            baseFileUpload: {},
            smsId: null,
            formNewSMSTrigger: {
                name: "",
                trigger_date: "",
                chooseExistentBusinessBase: true,
                uploadedBaseFile: null,
                origin: "",
                broker: "",
                agent: "",
                date_start: "",
                date_end: ""
                
            },
            brokersSelectList: [],
            agentsSelectList: [],
            originSelectList: [],
        }
       
    }

    closeModalAddBase = (e = null) => {
        if (e) e.preventDefault()
        this.setState({isModalAddBaseVisible: false})
    }

    handleCloseModalResponse = (e = null) => {
        if (e) e.preventDefault()
        this.setState({modalResponseShow: false})
    }

    checkSMSId =  () => {
        let smsId = document.location.hash.replace('#','')
        if(smsId) this.setState({smsId})
    }

    reloadTriggeredSMSSDataTable = () => {
        $('#triggered_sms_table').DataTable().ajax.reload();
    }

    loadTriggeredSMSSDataTable = () => {
        //let tableTriggeredSMSs = this.state.tableTriggeredSMSs

        //let tableTriggeredSMSs = $('#triggered_sms_table').DataTable({
        $('#triggered_sms_table').DataTable({
            "searching": true,
            "paging": false,
            "info": false,
            "responsive": true,
            "language": {
                "url": this.translate('DATATABLE_LANGUAGE_URL')
            },
            "data": [
                      
                [
                  "Disparo de teste 1",
                  "24/07/2020",
                  "25",
                  "NWsadasdU3NTBjY2VkNGE0ZGM3ZWMzMzVjOTY1"
                ]
              ],
            /* "ajax": {
                "url": ajaxUrl('/email_marketing/table'),
                "type": 'GET',
                "beforeSend": function (request) {
                    request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                },
                "dataSrc": function ( json ) {
                    //Make your callback here.
                    console.log(json.data)
                    return json.data;
                }
            }, */
            "columnDefs": [{
                "targets": -1,
                "data": null,
                "defaultContent": 
                    `<div class="btn-group btn-shadow">
                        <button class="view btn btn-sm orange btn-cursor-pointer" title="Ação teste">
                            <i class="fa fa-eye" title="Ação teste"></i>
                        </button>
                       
                    </div>` 
            }]
        });

        $('#triggered_sms_table tbody').on( 'click', '.view', function (e){
            
            e.preventDefault();

            /* let data = [];
            
            if($(e.target).parents('tr').hasClass('child')){
                data = tableTriggeredSMSs.row($(e.target).parents('tr').prev('.parent')).data();
            }else{
                data = tableTriggeredSMSs.row($(e.target).parents('tr')).data();
            }
            let email = window.open('/email_marketing_preview#' + data[2]);
            email.focus(); */
            alert("Em desenvolvimento.")
        });
    }

    updateBrokersSelect = (e) => {
        let brokerId = e.target.value

        if(brokerId){
            this.loadAgentsSelectList(brokerId)
            this.setState({formNewSMSTrigger: {...this.state.formNewSMSTrigger, agent: "", broker: brokerId}})
        } else {
            this.setState({agentsSelectList: [], formNewSMSTrigger: {...this.state.formNewSMSTrigger, agent: "", broker: "" }})
        }
    }

    updateFormNewTriggerChooseExistentBusinessBase = (value) => {
        let {formNewSMSTrigger} = this.state

        formNewSMSTrigger = {
            name: "",
            trigger_date: "",
            chooseExistentBusinessBase: value,
            uploadedBaseFile: null,
            origin: "",
            broker: "",
            agent: "",
            date_start: "",
            date_end: ""
        }
        this.setState({formNewSMSTrigger})
    }

    loadBrokersSelectList = async () => {
        try{
            const response = await api.get("/brokers/table")
            if(response && response.data && response.data.data && response.data.data.length){
                this.setState({brokersSelectList: response.data.data})
                
            } 
        }catch(err){
            console.log(err)
        }
    }
    
    loadAgentsSelectList = async (brokerId) => {
        try{
            const response = await api.get(`/agents/${brokerId}/table`)
            console.log(response.data.data)
            if(response && response.data && response.data.data && response.data.data.length){
                this.setState({agentsSelectList: response.data.data })
            } else {
                this.setState({agentsSelectList: [] }) 
            } 
        }catch(err){
            console.log(err)
        } 
    }
    
    loadOriginSelectList = async () => {
        try{
            const response = await api.get(`/business/${localStorage.getItem("@buylitics-business_id")}/media/json`)
            if(response && response.data && response.data.length){
                this.setState({originSelectList: response.data})
            }  
        }catch(err){
            console.log(err)
        } 
    }

    handleFormNewSMSTriggerSubmit = async (e) => {
        e.preventDefault()

        if(this.checkNewSMSTriggerFieldsAreEmpty()) return

        //let {formNewSMSTrigger} = this.state
        try{
            //const response = await api.post(``)
            let response = { data: { success: true } }
            if(response && response.data && response.data.success){
                this.cleanFormNewSMSTrigger()
                this.setState({modalResponseShow: true, msgResponseShow: this.translate('TRIGGERED_SMS_TRIGGERED_SMS_CREATED_WITH_SUCCESS')})
                //this.reloadTriggeredSMSSDataTable()
            }  
        }catch(err){
            console.log(err)
        } 
    }

    checkNewSMSTriggerFieldsAreEmpty = () => {
        let checkIsEmpty = false

        let {formNewSMSTrigger} = this.state

        if(!formNewSMSTrigger.name) checkIsEmpty = true
        if(!formNewSMSTrigger.trigger_date) checkIsEmpty = true

        if(formNewSMSTrigger.chooseExistentBusinessBase){
            if(!formNewSMSTrigger.origin) checkIsEmpty = true
            if(!formNewSMSTrigger.broker) checkIsEmpty = true
            if(!formNewSMSTrigger.agent) checkIsEmpty = true
            if(!formNewSMSTrigger.date_start) checkIsEmpty = true
            if(!formNewSMSTrigger.date_end) checkIsEmpty = true
        } else {
            if(!formNewSMSTrigger.uploadedBaseFile) checkIsEmpty = true
        }
        return checkIsEmpty
    }



    cleanFormNewSMSTrigger = () => {
        this.setState({
            formNewSMSTrigger: {
                name: "",
                trigger_date: "",
                chooseExistentBusinessBase: true,
                uploadedBaseFile: null,
                origin: "",
                broker: "",
                agent: "",
                date_start: "",
                date_end: ""
            },
        })
    }

    handleAddBaseFormSubmit = async (e) => {
        e.preventDefault()
        let baseJsonData = await this.uploadFileAndGetJson()
        if(baseJsonData) this.validBaseJsonData(baseJsonData)
    }

    uploadFileAndGetJson = async () => {
        //let {baseFileUpload} = this.state

        let baseJsonData = [
            {name:"Joao", phone:"(00) 19 00000-0000", campo_teste:"123"},
            {name:"Joao", phone:"(00) 19 00000-0001", campo_teste:"321"},
            {name:"Joao", phone:"(00) 19 00000-0001", campo_teste:"321"},
            {name:"Joao", phone:"(00) 19 00000-0000", campo_teste:"123"},
            {name:"Joao", phone:"(00) 19 00000-0001", campo_teste:"321"},
        ]

        /* try {
            const response = await api.post(``, baseFileUpload)
            if(response && response.data && response.data.json){
               baseJsonData = response.data.json
            }  
        } catch (error) {
            
        } */

        return baseJsonData
    }

    validBaseJsonData = (baseJsonData) => {
        let thValues = Object.keys(baseJsonData[0])
        thValues = this.sanitizeThValues(thValues)
        this.updateThSelectOptions(thValues)
        let thValuesAux = thValues
        baseJsonData = this.replaceBaseKeysForThValues(baseJsonData, thValues)
        this.setBaseData(baseJsonData, thValues, thValuesAux)
    }

    updateThSelectOptions = (thValues) => {
        let {tableBaseCsv} = this.state
        let {thSelectOptions} = this.state.tableBaseCsv
        thSelectOptions = [...thSelectOptions, ...thValues]
        thSelectOptions = [...new Set(thSelectOptions)]
        tableBaseCsv["thSelectOptions"] = thSelectOptions
        this.setState({
            tableBaseCsv
        })
    }

    sanitizeThValues = (thValues) => {
        let customCounter = 1
        return thValues.map((value)=>{
            switch(value){
                case "name":
                case "phone":
                case "email":
                    break
                default:
                    value = `${this.translate("TRIGGERED_EMAIL_CUSTOM")}_${customCounter}`
                    customCounter++
                    break  
            }
            return value
        })
    }

    replaceBaseKeysForThValues = (baseJsonData, thValues) => {
        return baseJsonData.map((sale)=>{
            let newSale = {}
            Object.entries(sale).forEach(([key, value], index)=>{
                let obj = {}
                key = thValues[index]
                obj[key] = value
                newSale = {...newSale, ...obj}
            })
            return newSale
        })
    }

    setBaseData = (baseJsonData, thValues, thValuesAux) => {
        this.setState({
            tableBaseCsv: {
                ...this.state.tableBaseCsv,
                baseJsonData,
                thValues,
                thValuesAux
            }
        })
    }
    changeThOptionBaseCsv = (newValue, selectIndex) => {
        let {thValues, thValuesAux, baseJsonData} = this.state.tableBaseCsv
        let oldValue = thValuesAux[selectIndex]
        let oldValueIndex = thValuesAux.indexOf(newValue)
        if(oldValueIndex !== -1) {
            thValues[oldValueIndex] = oldValue
        }
        thValues[selectIndex] = newValue
        thValuesAux = thValues
        this.updateThSelectOptions(thValues)
        baseJsonData = this.replaceBaseKeysForThValues(baseJsonData, thValues)
        this.setState({
            tableBaseCsv: {
                ...this.state.tableBaseCsv,
                thValues,
                thValuesAux,
                baseJsonData
            }
        }) 
    }
    changeInputDataSalesCsv = (value, index, saleFieldIndex) => {
        let {baseJsonData, thValues} = this.state.tableBaseCsv
        let field = thValues[saleFieldIndex]
        baseJsonData[index][field] = value
        this.setState({
            tableBaseCsv: {
                ...this.state.tableBaseCsv,
                baseJsonData
            }
        })
    }
   
    removeLine = (index) => {
        let {baseJsonData} = this.state.tableBaseCsv
        baseJsonData = baseJsonData.filter((sale, saleIndex)=> saleIndex !== index)
        this.setState({
            tableBaseCsv: {
                ...this.state.tableBaseCsv,
                baseJsonData
            }
        })
    }
    removeColumn = (columnIndex) => {
        let {baseJsonData, thValues, thValuesAux} = this.state.tableBaseCsv
        baseJsonData = baseJsonData.map((sale)=>{
            let newBase = {}
            Object.entries(sale).forEach(([key, value], index)=>{
                if(index !== columnIndex){
                    let obj = {}
                    key = thValues[index]
                    obj[key] = value
                    newBase = {...newBase, ...obj}
                }
            })
            return newBase
        })
        thValues = Object.keys(baseJsonData[0])
        thValuesAux = thValues

        this.setState({
            tableBaseCsv: {
                ...this.state.tableBaseCsv,
                baseJsonData,
                thValues,
                thValuesAux
            }
        })
    }
    turnToSelect = (target) => {
        let div = target.parentNode
        div.classList.remove("d-flex")
        div.classList.add("d-none")
        let select = target.parentNode.nextSibling
        select.classList.remove("d-none")
        select.classList.add("d-block")
    }

    submitTableBaseCsv = () => {
        this.setState({formNewSMSTrigger: {...this.state.formNewSMSTrigger, uploadedBaseFile: this.state.tableBaseCsv.baseJsonData}})
        this.closeModalAddBase()
    }

    getMinDate = () => {
        let choosenDate = new Date()

        const date = (new Date(choosenDate).toLocaleDateString()).split('/')
        const time = (new Date(choosenDate).toLocaleTimeString()).split(':')
        let minDate = `${date[2]}-${date[1]}-${date[0]}T${time[0]}:${time[1]}`

        return minDate 
    }

    componentDidMount = () => {
        this.checkSMSId()
        this.loadTriggeredSMSSDataTable()
        this.loadBrokersSelectList()
        this.loadOriginSelectList()
    }

    render() {
        let uniqueIndex = 0
        const getUniqueIndex =() => uniqueIndex++

        return (
            <React.Fragment>
                <MenuMain />
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    <HeaderMain title={this.translate('TRIGGERED_SMS_TRIGGERED_SMSS')} onChange={this.handleChange}/>
                    <div className="content-main" id="content-main">
                        <div className="padding">
                            <div className="row pt20">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">

                                    <ul className="nav nav-pills nav-sm mb10" id="triggered_sms" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-target="#tab_triggered_sms" data-toggle="tab" href="#tab_triggered_sms" role="tab" aria-controls="Triggered" aria-selected="true">{this.translate('TRIGGERED_SMS_TRIGGERS_MADE')}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-target="#tab_trigger_new_sms" data-toggle="tab" href="#tab_trigger_new_sms" role="tab" aria-controls="Trigger" aria-selected="true">{this.translate('TRIGGERED_SMS_NEW_TRIGGER')}</a>
                                        </li>
                                    </ul>

                                    <div className="tab-content" id="triggered_sms_content">
                                        <div className="tab-pane pb40 fade show active p-3 white box-shadow" id="tab_triggered_sms" role="tabpanel" aria-labelledby="">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="box p-2">
                                                        <table id="triggered_sms_table" className="table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                                                            <thead>
                                                                <tr className="text-left">
                                                                    <th>{this.translate('TRIGGERED_SMS_TRIGGER_NAME')}</th>
                                                                    <th>{this.translate('TRIGGERED_SMS_TRIGGER_DATE')}</th>
                                                                    <th>{this.translate('TRIGGERED_SMS_TOTAL')}</th>
                                                                    <th>{this.translate('TABLE_ACTIONS_ROW')}</th>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane pb40 fade show p-3 white box-shadow" id="tab_trigger_new_sms" role="tabpanel" aria-labelledby="">
                                            <form className="width100" onSubmit={e=>this.handleFormNewSMSTriggerSubmit(e)}>
                                                <div className="form-row">
                                                    <div className="form-group col-12 col-lg">
                                                        <label htmlFor="radio_form_new_trigger_name">{this.translate('TRIGGERED_SMS_TRIGGER_NAME')}</label>
                                                        <input id="radio_form_new_trigger_name" name="radio_form_new_trigger_name" type="text" onChange={e => this.setState({ formNewSMSTrigger: {...this.state.formNewSMSTrigger, name:  e.target.value}})} value={this.state.formNewSMSTrigger.name} className="form-control box-shadow" placeholder={this.translate('TRIGGERED_SMS_TRIGGER_NAME')} required/>
                                                    </div>
                                                    <div className="form-group col-12 col-lg">
                                                        <label  htmlFor="form_new_trigger_trigger_date">{this.translate('TRIGGERED_SMS_TRIGGER_DATE')}</label>
                                                        <input type="datetime-local" min={this.getMinDate()} pattern="[0-9]/[0-9]{2}/[0-9]{4}T[0-9]{2}:[0-9]{2}" id="form_new_trigger_trigger_date" className="form-control" data-plugin="datepicker" value={this.state.formNewSMSTrigger.trigger_date} onChange={e=>this.setState({formNewSMSTrigger: {...this.state.formNewSMSTrigger, trigger_date: e.target.value}})}/>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-12 py-0 my-0">
                                                        <label>{this.translate('TRIGGERED_SMS_TRIGGER_BASE')}</label>
                                                    </div>
                                                    <div className="form-group col-12 pl-4">
                                                        <div className="form-check-inline mr-5">
                                                            <input className="form-check-input align-center" type="radio" id="radio_form_existing_trigger_base" name="radio_form_new_trigger_base" checked={this.state.formNewSMSTrigger.chooseExistentBusinessBase} onChange={()=>this.updateFormNewTriggerChooseExistentBusinessBase(true)} />
                                                            <label className="form-check-label pl-0" htmlFor="radio_form_existing_trigger_base">{this.translate('TRIGGERED_SMS_CHOOSE_EXISTENT_TRIGGER_BASE')}</label>
                                                        </div>
                                                        <div className="form-check-inline">
                                                            <input className="form-check-input align-center" type="radio" id="radio_form_upload_new_trigger_base" name="radio_form_new_trigger_base" checked={!this.state.formNewSMSTrigger.chooseExistentBusinessBase} onChange={()=>this.updateFormNewTriggerChooseExistentBusinessBase(false)} />
                                                            <label className="form-check-label pl-0" htmlFor="radio_form_upload_new_trigger_base">{this.translate('TRIGGERED_SMS_UPLOAD_TRIGGER_BASE_FILE')}</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                {this.state.formNewSMSTrigger.chooseExistentBusinessBase ? 
                                                    <div className="form-row">
                                                        <div className="form-group col-12 col-lg">
                                                            <label htmlFor="select_form_new_trigger_origin">{this.translate('TRIGGERED_SMS_SELECT_THE_ORIGIN')}</label>
                                                            <select id="select_form_new_trigger_origin" name="select_form_new_trigger_origin" className="form-control box-shadow cool-select" onChange={e => this.setState({ formNewSMSTrigger: {...this.state.formNewSMSTrigger, origin:  e.target.value}})} value={this.state.formNewSMSTrigger.origin}>
                                                                <option value="">{this.translate('TRIGGERED_SMS_SELECT_THE_ORIGIN')}</option>
                                                                {this.state.originSelectList.map((origin, index)=>
                                                                    <option key={ index + origin.code} value={origin.code}>{origin.name}</option>
                                                                )} 
                                                            </select>
                                                        </div>
                                                        <div className="form-group col-12 col-lg">
                                                            <label htmlFor="select_form_new_trigger_broker">{this.translate('TRIGGERED_SMS_SELECT_THE_BROKER')}</label>
                                                            <select id="select_form_new_trigger_broker" name="select_form_new_trigger_broker" className="form-control box-shadow cool-select" onChange={e => this.updateBrokersSelect(e)} value={this.state.formNewSMSTrigger.broker}>
                                                                <option value="">{this.translate('TRIGGERED_SMS_SELECT_THE_BROKER')}</option>
                                                                {this.state.brokersSelectList.map((broker, index)=>
                                                                    <option key={ index + broker[6]} value={broker[6]}>{broker[0]}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                        <div className="form-group col-12 col-lg">
                                                            <label htmlFor="select_form_new_trigger_agent">{this.translate('TRIGGERED_SMS_SELECT_THE_AGENT')}</label>
                                                            <select id="select_form_new_trigger_agent" name="select_form_new_trigger_agent" className="form-control box-shadow cool-select" onChange={e => this.setState({ formNewSMSTrigger: {...this.state.formNewSMSTrigger, agent:  e.target.value}})} value={this.state.formNewSMSTrigger.agent}>
                                                                <option value="">{this.translate('TRIGGERED_SMS_SELECT_THE_AGENT')}</option>
                                                                {this.state.agentsSelectList.map((agent, index)=>
                                                                    <option key={ index + agent[7]} value={agent[7]}>{agent[0]}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                        <div className="form-group col-12 col-lg">
                                                            <label  htmlFor="form_new_trigger_date_start">{this.translate('LEADS_LIST_FROM')}</label>
                                                            <input type="datetime-local" pattern="[0-9]/[0-9]{2}/[0-9]{4}T[0-9]{2}:[0-9]{2}" id="form_new_trigger_date_start" className="form-control" data-plugin="datepicker" value={this.state.formNewSMSTrigger.date_start} onChange={e=>this.setState({formNewSMSTrigger: {...this.state.formNewSMSTrigger, date_start: e.target.value}})}/>
                                                        </div>

                                                        <div className="form-group col-12 col-lg">
                                                            <label htmlFor="form_new_trigger_date_end">{this.translate('LEADS_LIST_TO')}</label>
                                                            <input type="datetime-local" pattern="[0-9]/[0-9]{2}/[0-9]{4}T[0-9]{2}:[0-9]{2}" id="form_new_trigger_date_end" className="form-control" data-plugin="datepicker" value={this.state.formNewSMSTrigger.date_end} onChange={e=>this.setState({formNewSMSTrigger: {...this.state.formNewSMSTrigger, date_end: e.target.value}})}/>
                                                        </div>
                                                    </div>
                                                : 
                                                    <React.Fragment>
                                                    {this.state.formNewSMSTrigger.uploadedBaseFile ?
                                                        <div className="form-row">
                                                            <div className="form-group col-12">
                                                                <label htmlFor="radio_form_new_trigger_name">
                                                                    <i className="fa fa-check text-success"></i> {this.translate('TRIGGERED_SMS_BASE_FILE_UPLOADED_WITH_SUCCESS')}
                                                                </label>
                                                            </div>
                                                            <button type="button" onClick={e=>this.setState({isModalAddBaseVisible: true})} className="btn blue px-3 btn-rounded btn-shadow btn-cursor-pointer">
                                                                <i className="fa fa-edit"></i> {this.translate('TRIGGERED_SMS_EDIT_TRIGGER_BASE_FILE')}
                                                            </button>
                                                        </div>
                                                    :
                                                        <button type="button" onClick={e=>this.setState({isModalAddBaseVisible: true})} className="btn blue px-3 btn-rounded btn-shadow btn-cursor-pointer">
                                                            <i className="fa fa-upload"></i> {this.translate('TRIGGERED_SMS_UPLOAD_TRIGGER_BASE_FILE')}
                                                        </button>
                                                    }

                                                    </React.Fragment>
                                                }
                                                <div className="form-row">
                                                    <div className="form-group col-12 d-flex justify-content-end">
                                                        <button type="button" onClick={this.cleanFormNewSMSTrigger} className="btn btn-danger px-4 btn-rounded btn-shadow btn-cursor-pointer">
                                                            <i className="fa fa-trash"></i> {this.translate('TRIGGERED_SMS_CLEAN')}
                                                        </button>
                                                        <button type="submit" disabled={this.checkNewSMSTriggerFieldsAreEmpty() ? "disabled" : ""} className="btn btn-info px-4 ml-2 btn-rounded btn-shadow btn-cursor-pointer">
                                                            <i className="fa fa-save"></i> {this.translate('TRIGGERED_SMS_REGISTER_NEW_TRIGGER')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                            
                                        </div>
                                        
                                    </div>
        
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterMain />
                </div>

                <Modal show={this.state.isModalAddBaseVisible} onHide={this.closeModalAddBase} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t pb-5">
                    <Modal.Body>
                        <React.Fragment>
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-upload" aria-hidden="true"></i> {this.translate('TRIGGERED_SMS_BASE_FILE_UPLOAD')}</h5>
                                <a href="/" onClick={e=>this.closeModalAddBase(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row">
                                <form onSubmit={e=>this.handleAddBaseFormSubmit(e)} encType="multipart/form-data" className="form-inline col-8 py-3 mx-auto justify-content-center">
                                    <div className="form-group px-5">
                                        <input id="form-base-upload-csv" onChange={e=>this.setState({baseFileUpload: e.target.files[0]})} type="file" className="form-control-file"/>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-info px-5 btn-rounded btn-shadow">
                                            <i className="fa fa-save"></i> {this.translate('TRIGGERED_SMS_UPLOAD')}
                                        </button>
                                    </div>
                                </form>
                                
                            </div>
                            <div className="row">
                                <form className="col-8 py-3 mx-auto">
                                    <table className="table">
                                        <thead>
                                                {this.state.tableBaseCsv.thValues.length > 0 && this.state.tableBaseCsv.baseJsonData.length > 0 && (
                                                    <React.Fragment>
                                                        <tr>
                                                            {this.state.tableBaseCsv.thValues.map((th, index)=>
                                                                <th key={`remove${index}`} onClick={e=>this.removeColumn(index)} className="text-center btn-cursor-pointer"><i className="fa fa-close text-danger"></i> {this.translate("BUSINESS_SALES_CONTROL_MODAL_COLUMN_BUTTON")}</th>
                                                            )}
                                                            <th>&nbsp;</th>
                                                        </tr>
                                                        <tr>
                                                            {this.state.tableBaseCsv.thValues.map((th, index)=>
                                                                <th key={`${getUniqueIndex()}${th}tableSalesTh${index}`}>
                                                                    <div className={`form-group ${th.includes(this.translate('TRIGGERED_SMS_CUSTOM')) ? "d-flex m-0" : "d-none"}`}>
                                                                        <input onBlur={e=>this.changeThOptionBaseCsv(e.target.value, index)} type="text" className="form-control" defaultValue={th}/>
                                                                        <i onClick={e=>this.turnToSelect(e.target)} className="fa fa-undo my-auto pl-2 btn-cursor-pointer"></i>
                                                                    </div>
                                                                    <select onChange={e=>this.changeThOptionBaseCsv(e.target.value, index)} className={`form-control cool-select ${th.includes(this.translate('TRIGGERED_SMS_CUSTOM')) ? "d-none" : "d-block"}`} defaultValue={th}>
                                                                        {this.state.tableBaseCsv.thSelectOptions.map((option)=>
                                                                            <option key={`${option}${index}${th}${getUniqueIndex()}`} value={option}>{option}</option>
                                                                        )}
                                                                    </select>
                                                                </th>   
                                                            )}
                                                            <th>Action</th>
                                                        </tr>
                                                    </React.Fragment>
                                                )}
                                        </thead>
                                        <tbody>
                                            {this.state.tableBaseCsv.baseJsonData.length > 0 && this.state.tableBaseCsv.thValues.length > 0 &&(
                                                this.state.tableBaseCsv.baseJsonData.map((sale, index)=>
                                                    <tr key={`baseJsonData${index}`}>
                                                        {Object.values(sale).map((saleField, saleFieldIndex)=>
                                                            <td key={`${getUniqueIndex()}${saleField}${saleFieldIndex}`}>
                                                                <input onBlur={e=>this.changeInputDataSalesCsv(e.target.value, index, saleFieldIndex)} className="form-control" type="text" defaultValue={saleField}/>
                                                            </td>
                                                        )}
                                                        <td onClick={e=>this.removeLine(index)} className="text-center btn-cursor-pointer"><i className="fa fa-close text-danger"></i> {this.translate("BUSINESS_SALES_CONTROL_MODAL_ROW_BUTTON")}</td>
                                                    </tr>
                                                )
                                                
                                            )}
                                        </tbody>
                                    </table>
                                </form>
                                
                            </div>
                            <div className="row">
                                <div className="col-8 mx-auto">
                                    {this.state.tableBaseCsv.baseJsonData.length > 0 && this.state.tableBaseCsv.thValues.length > 0 &&(
                                        this.state.formNewSMSTrigger.uploadedBaseFile ? 
                                            <button type="button" onClick={e=>this.submitTableBaseCsv()} className="btn btn-success px-5 btn-rounded btn-shadow pull-right btn-cursor-pointer">
                                                <i className="fa fa-edit"></i> {this.translate('TRIGGERED_SMS_EDIT_TRIGGER_BASE_FILE')}
                                            </button>
                                        : 
                                        <button type="button" onClick={e=>this.submitTableBaseCsv()} className="btn btn-success px-5 btn-rounded btn-shadow pull-right btn-cursor-pointer">
                                            <i className="fa fa-plus"></i> {this.translate('TRIGGERED_SMS_ADD_TRIGGER_BASE_FILE')}
                                        </button>
                                    )}

                                </div>

                            </div>
                        </React.Fragment>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.modalResponseShow} onHide={this.handleCloseModalResponse} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-bell"></i> {this.translate("MODAL_STATUS_STATUS")}</h5>
                                <a href="/" onClick={e=>this.handleCloseModalResponse(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                <span className="col-12 text-center py-2 lead">
                                    {this.state.msgResponseShow}
                                </span>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(TriggeredSMS);