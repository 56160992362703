import React, { Component } from 'react';

class SummaryCommercialFunnel extends Component {
    render() {

        const styleElement = {
            div: {
                margin: '7px 0'
            }
        }

        const colors = ['danger','red','deep-orange','orange','amber','yellow','lime','light-green','green'];

        return (
            <div className="col-md-4 col-sm-12">
                <div className="padding box box-shadow">
                    <div className="d-flex">
                        <span className="text-muted text-center w-100 mb20">Funil comercial</span>
                    </div>
                    <React.Fragment>
                    {
                        this.props.fields.funnel.map((data, i) => 
                            <div key={i} className={'box-color box-shadow2 '+ colors[i] +' pos-rlt'}  style={styleElement.div}>
                                <span className="arrow bottom b-danger"></span>
                                <div className="box-body p-1">
                                    <span className="f14 f700">{data.funnel_total_leads}</span>
                                    <span className=" pull-right">{data.funnel_step_name}</span>
                                </div>
                            </div>
                        )
                    }
                    </React.Fragment>
                </div>
            </div>
        );
    }
}

export default SummaryCommercialFunnel;