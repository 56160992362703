import React, { Component } from 'react';

class AverageResponseTime extends Component {
    render() {
        return (
            <div className="col-md-12 col-sm-12">
                <div className="d-flex">
                    <span className="text-muted text-center w-100">Tempo médio de resposta</span>
                </div>
                <div className="py-3 text-center text-lg text-warn lh" data-toggle="modal" data-target="#">
                    <a href="/" className="">
                        <i className="fa fa-clock-o f32 opacity90"></i>
                        <br/>
                        <span className="f20 f700">
                           {this.props.fields.average_response_time}
                        </span>
                        <br/>
                    </a>
                </div>
            </div>
        );
    }
}

export default AverageResponseTime;