export const LEADS_DOWNLOAD = "@buylitics-leads-download";
export const TOKEN_KEY = "@buylitics-token-access-key";
export const COMPANY_ID = "@buylitics-company_id";
export const COMPANY_NAME = "@buylitics-company_name";
export const BUSINESS_ID = "@buylitics-business_id";
export const BUSINESS_CODE = "@buylitics-business_code";
export const BUSINESS_NAME = "@buylitics-business_name";
export const USER_COMPANIES = "@buylitics-user_companies";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = (token) => {
  if (typeof token !== "undefined" && token !== false && token !== "null") {
    localStorage.setItem(TOKEN_KEY, token);
    return true;
  } else {
    clear();
    return false;
  }
};

export const setLeadsDownload = (lead_download) => {
  localStorage.setItem(LEADS_DOWNLOAD, lead_download);
};
export const getLeadsDownload = () => {
  return localStorage.getItem(LEADS_DOWNLOAD);
};
export const setToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const setUserCompanies = (list) => {
  localStorage.setItem(USER_COMPANIES, JSON.stringify(list || []));
};
export const getUserCompanies = () => {
  let list = [];
  try {
    const r = localStorage.getItem(USER_COMPANIES) || [];
    list = JSON.parse(r) || [];
  } catch (e) {}
  return list;
};
export const setCompany = (company_id, company_name) => {
  localStorage.setItem(COMPANY_ID, company_id);
  localStorage.setItem(COMPANY_NAME, company_name);
};
export const getCompany = {
  company_id: localStorage.getItem(COMPANY_ID),
  company_name: localStorage.getItem(COMPANY_NAME),
};
export const getCompanyName = () => {
  return localStorage.getItem(COMPANY_NAME);
};
export const setBusiness = (business_id, business_name, business_code) => {
  localStorage.setItem(BUSINESS_ID, business_id);
  localStorage.setItem(BUSINESS_CODE, business_code);
  localStorage.setItem(BUSINESS_NAME, business_name);
};
export const getBusiness = {
  business_id: localStorage.getItem(BUSINESS_ID),
  business_code: localStorage.getItem(BUSINESS_CODE),
  business_name: localStorage.getItem(BUSINESS_NAME),
};
export const getBusinessName = () => {
  return localStorage.getItem(BUSINESS_NAME);
};
export const getBusinessCode = () => {
  return localStorage.getItem(BUSINESS_CODE);
};
export const clear = () => {
  localStorage.removeItem(LEADS_DOWNLOAD);
  localStorage.removeItem(COMPANY_ID);
  localStorage.removeItem(COMPANY_NAME);
  localStorage.removeItem(BUSINESS_ID);
  localStorage.removeItem(BUSINESS_CODE);
  localStorage.removeItem(BUSINESS_NAME);
  localStorage.removeItem(TOKEN_KEY);
};
export const logout = () => {
  clear();
  window.location.href = "/";
};
