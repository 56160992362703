import React, { Component } from 'react';

export default class AttendanceChat extends Component {
    constructor(props){
        super(props)
        this.state = {
            messages: null
        }
        this.translate = this.props.translate
    }

    updateChatMessages = () => {
        switch(this.props.currentChannel){
            case "clientHistory": 
                this.setState({messages: this.props.messages.clientHistory})
                break
            case "shopperHistory":
                this.setState({messages: this.props.messages.shopperHistory})
                break
            case "deliverymanHistory":
                this.setState({messages: this.props.messages.deliverymanHistory})
                break
            case "teamHistory":
                this.setState({messages: this.props.messages.teamHistory})
                break
            default:
                break;
        }
    }

    showMediaModal = (url) => {
        let modal = document.querySelector("#attendance_room_media_modal")
        modal.style.display = "block"
        let span = document.querySelector("#attendance_room_media_modal_close_button")
        
        modal.children[1].setAttribute("src", url)

        span.onclick = function () {
            modal.style.display = "none"
        } 
    } 

    getChatHeight = () => {
        if(document.body.clientWidth < 768){
            return 500
        }
    }
    componentDidMount(){
        this.updateChatMessages()
    }
    componentDidUpdate(prevProps){
        if((prevProps.currentChannel !== this.props.currentChannel) || (prevProps.messages !== this.props.messages)){
            this.updateChatMessages()
        }
    }
    render = () =>
        <div id="attendance_row_messages_chat" className="row d-flex flex-column flex-grow-1" style={{height: this.getChatHeight()}}>
            <div className="col d-flex flex-column">
                {(this.state.messages !== null && this.state.messages.length > 0) ? (
                    this.state.messages.map((message)=>
                        <React.Fragment key={message.date}>
                            <div className="row attendance_row_messages_date_divider">
                                <div className="col text-center py-3">
                                    <span>{this.props.formatDateToBrazilianFormat(message.date)}</span>
                                </div>
                            </div>
                            {message.msgs.map((msg, index)=>
                                <div className="row" key={`${index}`}>
                                    
                                    {(msg.message || msg.msg) && (
                                        msg.is_system ? 
                                            <div className="col row_message_system">
                                                {msg.message && (msg.message)}
                                                {msg.msg && (msg.msg)}
                                            </div>
                                        :
                                        <div className={`col ${(msg.is_support || msg.is_attendance) ? "row_message_user" : "row_message_contact"}`}>
                                            <div className="row">
                                                <div className="col">
                                                    <p className={`mb-0 ${(msg.is_support || msg.is_attendance) ? "ml-auto" : ""}`}>
                                                        {msg.message && (msg.message)}
                                                        {msg.msg && (msg.msg)}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className={`col ${(msg.is_support || msg.is_attendance) ? "text-right" : ""}`}>
                                                    <small>{`${new Date(msg.date).getHours()}:${new Date(msg.date).getMinutes() < 10 ? "0" : ""}${new Date(msg.date).getMinutes()}`}</small>
                                                    <small>&nbsp;-&nbsp;{this.props.getOriginIcon(msg.origin)}</small>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {msg.image && (
                                        <div className={`col ${(msg.is_support || msg.is_attendance) ? "row_message_user" : "row_message_contact"}`}>
                                            <div className="row">
                                                <div className="col">
                                                    <p className={`mb-0 ${(msg.is_support || msg.is_attendance) ? "ml-auto" : ""}`}>
                                                        <img alt="support" className="img img-fluid bg-white attendance_message_image" onClick={()=>this.showMediaModal(msg.image)} src={msg.image} />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className={`col ${(msg.is_support || msg.is_attendance) ? "text-right" : ""}`}>
                                                    <small>{`${new Date(msg.date).getHours()}:${new Date(msg.date).getMinutes()}`}</small>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    
                                    {msg.video && (
                                        <div className={`col ${(msg.is_support || msg.is_attendance) ? "row_message_user" : "row_message_contact"}`}>
                                            <div className="row">
                                                <div className="col">
                                                    <p className={`mb-0 ${(msg.is_support || msg.is_attendance) ? "ml-auto" : ""}`}>
                                                        <video controls className="img img-fluid">
                                                            <source src={msg.video} />
                                                            {this.translate('ATTENDANCE_ROOM_BROWSER_DOESNT_SUPPORT_VIDEO')}
                                                        </video>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className={`col ${(msg.is_support || msg.is_attendance) ? "text-right" : ""}`}>
                                                    <small>{`${new Date(msg.date).getHours()}:${new Date(msg.date).getMinutes()}`}</small>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {msg.audio && (
                                        <div className={`col ${(msg.is_support || msg.is_attendance) ? "row_message_user" : "row_message_contact"}`}>
                                            <div className="row">
                                                <div className="col">
                                                    <div className={`mb-0 ${(msg.is_support || msg.is_attendance) ? "d-flex justify-content-end text-right" : ""}`}>
                                                        <audio controls>
                                                            <source src={msg.audio} />
                                                            {this.translate('ATTENDANCE_ROOM_BROWSER_DOESNT_SUPPORT_AUDIO')}
                                                        </audio>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className={`col ${(msg.is_support || msg.is_attendance) ? "text-right" : ""}`}>
                                                    <small>{`${new Date(msg.date).getHours()}:${new Date(msg.date).getMinutes()}`}</small>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            
                        </React.Fragment>
                    )
                ) : (
                    <p className="no_message_status lead text-center pt-1">{this.translate('ATTENDANCE_ROOM_START_CHATTING_WITH')}</p>
                )}
            </div>

        </div>

}