import React, { Component, Fragment } from "react";
import MenuMain from "../../Structure/MenuMain";
import HeaderMain from "../../Structure/HeaderMain";
import { withRouter } from "react-router-dom";

import $ from "jquery";

import api from "../../Services/Api";

import { urlBaseLead } from "../../Services/Config";

import GoogleDirections from "../../Structure/GoogleDirections";

class LeadData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      directionsAddresses: { origins: [], destinations: [] },
      lead: {
        lead_name: null,
        full_address: null,
        lead_score: null,
        prime_time_initial: null,
        prime_time_final: null,
        danger_time_initial: null,
        danger_time_final: null,
        entry_date: null,
        response_time: null,
        interaction_time: null,
        segmentation: { segmentation_code: null, public_code: null },
        agent: {
          full_name: null,
          full_phone: null,
          email: null,
          agent_previous: [
            {
              full_name: null,
              email: null,
              full_phone: null,
            },
          ],
        },
        broker: {
          broker_name: null,
          broker_email: null,
          broker_phone: null,
          broker_previous: [
            {
              broker_name: null,
              broker_email: null,
              broker_phone: null,
            },
          ],
        },
        current_stage_funnel: null,
        conversions: {
          first_conversion: {
            descriptive: null,
            date: null,
          },
          last_conversion: {
            descriptive: null,
            date: null,
          },
        },
        lead2v: [],
        lead4v: null,
        actions: {
          banners: [],
          funnel_stage: [],
          landing_pages: [],
          emails: [],
        },
        feedbacks: [],
        follow_ups: [],
        tasks: [],
        journey: [],
        forms: [],
        last_smartlook_shared_url: false,
      },
      external_information: [],
      formNote: {
        feedback_message: null,
        evaluation_type: "positive",
        note_type: null,
      },
      formTask: {
        task_action_type: "call",
        task_target_type: null,
        task_meeting: null,
        task_hour: null,
        task_date: null,
        task_duration: null,
        task_note: null,
      },
    };
    this.openLead4v = this.openLead4v.bind(this);

    this.formSubmitNote = this.formSubmitNote.bind(this);
    this.formSubmitTask = this.formSubmitTask.bind(this);
    this.translate = this.props.translate;
  }

  openLead4v(e) {
    e.preventDefault();
    console.log(this.state.lead.lead4v);
    let lead4v = window.open(urlBaseLead + "/lead#" + this.state.lead.lead4v);
    lead4v.focus();
  }

  returnClassIconTimeline = (action) => {
    let classIcon = "";
    switch (action) {
      case "leadin":
      case "facebookleads":
      case "npe":
        classIcon = "b-success";
        break;
      case "delay":
      case "onhold":
        classIcon = "b-warning";
        break;
      case "bdjornadain":
        classIcon = "b-warn";
        break;
      case "leadscore_up":
        classIcon = "b-primary";
        break;
      case "leadscore_down":
        classIcon = "b-danger";
        break;
      case "agendouvisita":
      default:
        classIcon = "b-default";
        break;
    }
    return classIcon;
  };

  async componentDidMount() {
    const hash = document.location.hash.replace("#", "");
    await api.get("/lead/" + hash + "").then((response) => {
      const lead = response.data;
      console.log(response.data);
      this.setState({
        lead,
      });
    });

    await api
      .get("/lead/external_information/" + hash + "")
      .then((response) => {
        const external_information = response.data;
        this.buildDirectionsMap(external_information);
        this.setState({
          external_information,
        });
      });
  }

  buildDirectionsMap = (external_information) => {
    let addresses =
      this.findAddressesInsideExternalInformation(external_information);
    if (!addresses) return;

    const { directionsAddresses } = this.state;
    let formatedLeadAddresses = this.formatLeadAddresses(addresses);
    let formatedBusinessAddress =
      "R. Arnaldo Borghi, 241 - Jardim Antonio Von Zuben, Campinas - SP, 13044-510, Brasil";
    let destinations = formatedLeadAddresses.map(() => formatedBusinessAddress);
    directionsAddresses["origins"] = formatedLeadAddresses;
    directionsAddresses["destinations"] = destinations;
    this.setState({ directionsAddresses });
  };
  findAddressesInsideExternalInformation(external_information) {
    let addressesIndex = external_information.findIndex(
      (singleExternal) => singleExternal.code === "address"
    );
    let addresses =
      addressesIndex !== -1 ? external_information[addressesIndex].data : false;
    return addresses;
  }

  formatLeadAddresses = (addresses) => {
    let formatedAddresses = [];
    let formatedAddress = "";
    addresses.forEach((address) => {
      formatedAddress = `${address[2].value}, ${address[1].value}, ${address[3].value}, ${address[4].value}, ${address[5].value}, ${address[8].value} `;
      formatedAddresses.push(formatedAddress);
    });
    return formatedAddresses;
  };

  async formSubmitNote(e) {
    e.preventDefault();
    const hash = document.location.hash.replace("#", "");
    if (this.state.formNote.note_type === "feedback") {
      const data = this.state.formNote;
      await api.post("/feedback/register/" + hash, data).then((response) => {
        console.log(response);
      });
    } else if (this.state.formNote.note_type === "followup") {
      const data = this.state.formNote;
      await api.post("/followup/register/" + hash, data).then((response) => {
        console.log(response);
      });
    }
  }

  async formSubmitTask(e) {
    e.preventDefault();
    const hash = document.location.hash.replace("#", "");
    const data = this.state.formTask;
    await api.post("/task_lead/register/" + hash, data).then((response) => {
      console.log(response);
    });
  }

  toggleCollapse = (selector) => $(selector).collapse("toggle");

  render() {
    return (
      <Fragment>
        <MenuMain />
        <div id="content" className="app-content box-shadow-0 dark" role="main">
          <HeaderMain
            title={this.translate("LEADS_DATA_LEADS_LIST")}
            onChange={this.handleChange}
          />
          <div className="content-main row row-no-margin" id="content-main">
            <div
              className=" col-lg-12 col-md-12 col-sm-12 col-xs-12 no-gutters"
              id="leads"
            >
              <div>
                <div className="item">
                  <div className="item-bg">
                    <img
                      src="/assets/app/img/dash_clients_bg05.png"
                      alt="."
                      className="blur"
                    />
                  </div>
                  <div className="p-4">
                    <div className="row mt-3">
                      <div className="col-sm-7">
                        <div className="media">
                          <div className="media-body mx-3 mb-2">
                            <h4 className="fwhite">
                              {this.state.lead.lead_name}
                            </h4>
                            <p>
                              <i className="fa fa-map-marker mr-2"></i>
                              {this.state.lead.full_address}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="white bg b-b px-3">
                  <div className="row ">
                    <div className="col-sm-5 order-sm-1 text-center p0">
                      <div className="py-4 clearfix nav-active-theme p0">
                        <ul className="nav nav-pills nav-sm p-2 deep-purple">
                          <li className="nav-item f700 w-100">
                            <a
                              className="nav-link f-700 text-center"
                              href="/"
                              data-toggle="tab"
                              data-target="#external_information"
                            >
                              {this.translate(
                                "LEADS_DATA_INFORMATIONS_ABOUT_THE_LEAD"
                              )}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-sm-7 order-sm-1 text-center p0">
                      <div className="py-4 clearfix nav-active-theme p0">
                        <ul className="nav nav-pills nav-sm p-2 deep-orange">
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              href="/"
                              data-toggle="tab"
                              data-target="#external_information"
                            >
                              <i className="fa fa-user"></i>{" "}
                              {this.translate("LEADS_DATA_INFO")}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              href="/"
                              data-toggle="tab"
                              data-target="#tab_2"
                            >
                              <i className="fa fa-clock-o"></i>{" "}
                              {this.translate("LEADS_DATA_TIMELINE")}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              href="/"
                              data-toggle="tab"
                              data-target="#tab_1"
                            >
                              <i className="fa fa-clone"></i>{" "}
                              {this.translate("LEADS_DATA_BANNERS")}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              href="/"
                              data-toggle="tab"
                              data-target="#tab_3"
                            >
                              <i className="fa fa-envelope"></i>{" "}
                              {this.translate("LEADS_DATA_EMAILS")}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              href="/"
                              data-toggle="tab"
                              data-target="#tab_4"
                            >
                              <i className="fa fa-user"></i>{" "}
                              {this.translate("LEADS_DATA_AGENTS")}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              href="/"
                              data-toggle="tab"
                              data-target="#tab_6"
                            >
                              <i className="fa fa-mouse-pointer"></i>{" "}
                              {this.translate("LEADS_DATA_SITE_VISITS")}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              href="/"
                              data-toggle="tab"
                              data-target="#tab_5"
                            >
                              <i className="fa fa-sitemap"></i>{" "}
                              {this.translate("LEADS_DATA_STAGE_OF_FUNNEL")}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              href="/"
                              data-toggle="tab"
                              data-target="#tab_7"
                            >
                              <i className="fa fa-comment"></i>{" "}
                              {this.translate("LEADS_DATA_AUDIT")}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="padding">
                  <div className="row">
                    <div className="col-sm-12 col-lg-5">
                      <div>
                        <div className="box box-shadow">
                          <div className="box-header">
                            <h2 style={{ lineHeight: "inherit" }}>
                              {this.translate("LEADS_DATA_LEADS_INFORMATIONS")}:
                              <button
                                onClick={this.openLead4v}
                                className="btn btn-cursor-pointer btn-primary float-right"
                                style={{ color: "#fff" }}
                              >
                                <i className="fa fa-user"></i>{" "}
                                {this.translate("LEADS_DATA_OPEN_LEAD_4_0")}
                              </button>
                            </h2>
                          </div>
                          <div className="box-divider"></div>
                          <ul className="list no-border">
                            <li className="list-item">
                              <div className="list-body">
                                <div className="f16">
                                  <i className="fa fa-id-badge"></i>{" "}
                                  {this.translate("LEADS_DATA_LEAD_SCORE")}:
                                </div>
                              </div>
                              <div className="list-body text-right">
                                <div className="f16 text-info">
                                  {/* {this.translate('LEADS_DATA_LEARNING')} */}
                                  {this.state.lead.lead_score}
                                </div>
                              </div>
                            </li>
                            <li className="list-item">
                              <div className="list-body">
                                <div className="f16">
                                  <i className="fa fa-clock-o"></i>{" "}
                                  {this.translate("LEADS_DATA_PRIME_TIME")}:
                                </div>
                              </div>
                              <div className="list-body text-right">
                                <div className="f16 text-success">
                                  {this.state.lead.prime_time_initial} até{" "}
                                  {this.state.lead.prime_time_final}
                                </div>
                              </div>
                            </li>
                            <div className="box-divider"></div>
                            <li className="list-item">
                              <div className="list-body">
                                <div className="f16">
                                  <i className="fa fa-clock-o"></i>{" "}
                                  {this.translate("LEADS_DATA_DANGER_TIME")}:
                                </div>
                              </div>
                              <div className="list-body text-right">
                                <div className="f16 text-danger">
                                  {/*  {this.translate('LEADS_DATA_CALCULATING')} */}
                                  {this.state.lead.danger_time_initial} até{" "}
                                  {this.state.lead.danger_time_final}
                                </div>
                              </div>
                            </li>
                            <div className="box-divider"></div>
                            <li className="list-item">
                              <div className="list-body">
                                <div className="f16">
                                  <i className="fa fa-calendar"></i>{" "}
                                  {this.translate(
                                    "LEADS_DATA_DATE_AND_HOUR_OF_CREATION"
                                  )}
                                  :
                                </div>
                              </div>
                              <div className="list-body text-right">
                                <div className="f16 text-info">
                                  {this.state.lead.entry_date}
                                </div>
                              </div>
                            </li>
                            <div className="box-divider"></div>
                            <li className="list-item">
                              <div className="list-body">
                                <div className="f16">
                                  <i className="fa fa-history"></i>{" "}
                                  {this.translate("LEADS_DATA_RESPONSE_TIME")}:
                                </div>
                              </div>
                              <div className="list-body text-right">
                                <div className="f16 text-info">
                                  {this.translate("LEADS_DATA_CALCULATING")}
                                </div>
                              </div>
                            </li>
                            <div className="box-divider"></div>
                            <li className="list-item">
                              <div className="list-body">
                                <div className="f16">
                                  <i className="fa fa-mouse-pointer"></i>{" "}
                                  {this.translate("LEADS_DATA_NAVIGATION_TIME")}
                                  :
                                </div>
                              </div>
                              <div className="list-body text-right">
                                <div className="f16 text-warn">
                                  {this.state.lead.interaction_time}
                                </div>
                              </div>
                            </li>
                            {this.state.lead.last_smartlook_shared_url &&
                            this.state.lead.last_smartlook_shared_url !==
                              null ? (
                              <React.Fragment>
                                <div className="box-divider"></div>
                                <li className="list-item">
                                  <div className="list-body">
                                    <div className="f16">
                                      <i className="fa fa-eye"></i>{" "}
                                      {this.translate(
                                        "LEADS_DATA_LAST_NAVIGATION"
                                      )}
                                    </div>
                                  </div>
                                  <div className="list-body text-right">
                                    <div className="f16">
                                      <a
                                        className="btn btn-rounded red"
                                        href={
                                          this.state.lead
                                            .last_smartlook_shared_url
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <i className="fa fa-play"></i>
                                      </a>
                                    </div>
                                  </div>
                                </li>
                              </React.Fragment>
                            ) : (
                              false
                            )}
                          </ul>
                        </div>
                        <div className="box box-shadow">
                          <ul className="list no-border">
                            <li className="list-item pb0">
                              <div className="list-body">
                                <div className="f16">
                                  <i className="fa fa-user"></i>{" "}
                                  {this.translate("LEADS_DATA_SEGMENTATION")}:
                                </div>
                              </div>
                              <div className="list-body text-right">
                                <div className="f16 text-info">
                                  {
                                    this.state.lead.segmentation
                                      .segmentation_code
                                  }
                                </div>
                              </div>
                            </li>
                            <li className="list-item">
                              <div className="list-body">
                                <div className="f16 text-muted">
                                  <i className="fa fa-users"></i>{" "}
                                  {this.translate("LEADS_DATA_PUBLIC")}:
                                </div>
                              </div>
                              <div className="list-body text-right">
                                <div className="f16 text-info text-muted">
                                  {this.state.lead.segmentation.public_code}
                                </div>
                              </div>
                            </li>
                            <li className="list-item">
                              <div className="list-body">
                                <div className="f16">
                                  <i className="fa fa-circle-o-notch"></i>{" "}
                                  {this.translate("LEADS_DATA_JOURNEY_MOMENT")}:
                                </div>
                              </div>
                              <div className="list-body text-right">
                                <div className="f16 text-info text-muted">
                                  {this.state.lead.segmentation.journey_moment}
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="box box-shadow">
                          <ul className="list no-border">
                            <li className="list-item pb0">
                              <div className="list-body">
                                <div className="f16">
                                  <i className="fa fa-user"></i>{" "}
                                  {this.translate("LEADS_DATA_CURRENT_AGENT")}:
                                </div>
                              </div>
                              <div className="list-body text-right">
                                <div className="f16 text-info">
                                  {this.state.lead.agent.full_name}
                                </div>
                              </div>
                            </li>
                            <li className="list-item">
                              <div className="list-body">
                                <div className="f16 text-muted">
                                  <i className="fa fa-user"></i>{" "}
                                  {this.translate("LEADS_DATA_PAST_AGENT")}:
                                </div>
                              </div>
                              <div className="list-body text-right">
                                <div className="f16 text-info text-muted">
                                  {
                                    this.state.lead.agent.agent_previous[0]
                                      .full_name
                                  }
                                </div>
                              </div>
                            </li>
                            <div className="box-divider"></div>

                            <li className="list-item">
                              <div className="list-body">
                                <div className="f16">
                                  <i className="fa fa-sitemap"></i>{" "}
                                  {this.translate(
                                    "LEADS_DATA_CURRENT_FUNNEL_STAGE"
                                  )}
                                  :
                                </div>
                              </div>
                              <div className="list-body text-right">
                                <div className="f16 text-warning">
                                  {this.translate("LEADS_DATA_LEADS")}
                                  {
                                    //this.state.lead.current_stage_funnel
                                  }
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <div className="box">
                          <div className="box-header">
                            <h2>{this.translate("LEADS_DATA_CONVERSIONS")}</h2>
                          </div>
                          <div className="box-divider"></div>

                          <ul className="list">
                            <li className="list-item">
                              <div className="list-body">
                                <div className="f16 text-info">
                                  <i className="fa fa-check"></i>{" "}
                                  {this.translate(
                                    "LEADS_DATA_FIRST_CONVERSION"
                                  )}
                                  :
                                </div>
                                <p className="mb10">
                                  {
                                    this.state.lead.conversions.first_conversion
                                      .descriptive
                                  }
                                </p>
                                <small className="d-block text-muted">
                                  <i className="fa fa-fw fa-clock-o"></i>{" "}
                                  {
                                    this.state.lead.conversions.first_conversion
                                      .date
                                  }
                                </small>
                              </div>
                            </li>
                            <li className="list-item">
                              <div className="list-body">
                                <div className="f16 text-info">
                                  <i className="fa fa-check"></i>{" "}
                                  {this.translate("LEADS_DATA_LAST_CONVERSION")}
                                  :
                                </div>
                                <p className="mb10">
                                  {
                                    this.state.lead.conversions.last_conversion
                                      .descriptive
                                  }
                                </p>
                                <small className="d-block text-muted">
                                  <i className="fa fa-fw fa-clock-o"></i>{" "}
                                  {
                                    this.state.lead.conversions.last_conversion
                                      .date
                                  }
                                </small>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <div className="box">
                          <div className="box-header">
                            <h2 className="text-info">
                              {this.translate("LEADS_DATA_LEAD_4_0")}
                            </h2>
                          </div>
                          <div className="box-divider"></div>

                          <ul className="list">
                            <li className="list-item">
                              <div className="list-body">
                                {this.state.lead.lead2v.map((data, i) => (
                                  <p key={i} className="mb10">
                                    {" "}
                                    {data.text}
                                  </p>
                                ))}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-lg-7">
                      <div className="tab-content">
                        <div
                          className="tab-pane fade active show"
                          id="external_information"
                        >
                          <div className="row mb-2">
                            <div className="col-12">
                              <div
                                className="accordion"
                                id="accordion_external_information"
                              >
                                <div className="card dark">
                                  <div
                                    className="card-header card-header-lead-page"
                                    style={{ padding: "15px !important" }}
                                    id={"heading-" + 99}
                                  >
                                    <h2 className="mb-0">
                                      <button
                                        style={{ width: "100%" }}
                                        className="btn btn-link text-left btn-cursor-pointer"
                                        type="button"
                                        onClick={(e) =>
                                          this.toggleCollapse(
                                            `${"#collapse-" + 99}`
                                          )
                                        }
                                        aria-expanded="true"
                                        aria-controls={"collapse-" + 99}
                                      >
                                        <h6
                                          style={{
                                            color: "#a0b4c8d9 !important",
                                          }}
                                        >
                                          {this.translate(
                                            "LEADS_DATA_FORM_FILLS"
                                          )}
                                        </h6>
                                      </button>
                                    </h2>
                                  </div>
                                  <div
                                    id={"collapse-" + 99}
                                    className="collapse show"
                                    aria-labelledby={"heading-" + 99}
                                    data-parent="#accordion_external_information"
                                  >
                                    <div className="card-body">
                                      {this.state.lead.forms.map((data, i) => (
                                        <div
                                          key={i}
                                          className="card-header-lead-page-div"
                                        >
                                          {data.map((datas, iss) => (
                                            <React.Fragment key={iss}>
                                              {typeof datas.value ===
                                              "string" ? (
                                                <React.Fragment>
                                                  <p>
                                                    <b>
                                                      {typeof datas.name ===
                                                      "string"
                                                        ? `${datas.name.replace(
                                                            "[]",
                                                            ""
                                                          )}`
                                                        : datas.name}
                                                    </b>
                                                    : {datas.value}
                                                  </p>
                                                  <hr />
                                                </React.Fragment>
                                              ) : (
                                                false
                                              )}
                                              {typeof datas.value ===
                                              "object" ? (
                                                <React.Fragment>
                                                  <p>
                                                    <b>
                                                      {typeof datas.name ===
                                                      "string"
                                                        ? `${datas.name.replace(
                                                            "[]",
                                                            ""
                                                          )}`
                                                        : datas.name}
                                                    </b>
                                                    :{" "}
                                                    {Object.values(
                                                      datas.value
                                                    ).join(",")}
                                                  </p>
                                                  <hr />
                                                </React.Fragment>
                                              ) : (
                                                false
                                              )}
                                            </React.Fragment>
                                          ))}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                {this.state.external_information.map(
                                  (item, i) => (
                                    <div key={i} className="card dark">
                                      <div
                                        className="card-header card-header-lead-page"
                                        style={{ padding: "15px !important" }}
                                        id={"heading-" + i}
                                      >
                                        <h2 className="mb-0">
                                          <button
                                            style={{ width: "100%" }}
                                            className="btn btn-link text-left btn-cursor-pointer"
                                            type="button"
                                            onClick={(e) =>
                                              this.toggleCollapse(
                                                `${"#collapse-" + i}`
                                              )
                                            }
                                            aria-expanded="true"
                                            aria-controls={"collapse-" + i}
                                          >
                                            <h6
                                              style={{
                                                color: "#a0b4c8d9 !important",
                                              }}
                                            >
                                              {item.name}
                                            </h6>
                                          </button>
                                        </h2>
                                      </div>

                                      <div
                                        id={"collapse-" + i}
                                        className={
                                          i === 0 ? "collapse" : "collapse"
                                        }
                                        aria-labelledby={"heading-" + i}
                                        data-parent="#accordion_external_information"
                                      >
                                        <div className="card-body">
                                          {item.code === "address" && (
                                            <div className="card-header-lead-page-div">
                                              <div>
                                                <GoogleDirections
                                                  translate={this.translate}
                                                  origin={
                                                    this.state
                                                      .directionsAddresses
                                                      .origins
                                                  }
                                                  originName={this.translate(
                                                    "LEADS_DATA_LEAD"
                                                  )}
                                                  destination={
                                                    this.state
                                                      .directionsAddresses
                                                      .destinations
                                                  }
                                                  destinationName={this.translate(
                                                    "LEADS_DATA_BUSINESS"
                                                  )}
                                                  hasAnotherGoogleImported={
                                                    false
                                                  }
                                                />
                                              </div>
                                            </div>
                                          )}

                                          {item.code === "emails" ||
                                          item.code === "cell_phones" ||
                                          item.code === "landline"
                                            ? item.data.map((d, is) => (
                                                <React.Fragment key={is}>
                                                  <p>{d}</p>
                                                  <hr />
                                                </React.Fragment>
                                              ))
                                            : item.code === "address" ||
                                              item.code ===
                                                "companies_holdings" ||
                                              item.code === "jobs"
                                            ? item.data.map((d, is) => (
                                                <div
                                                  key={is}
                                                  className="card-header-lead-page-div"
                                                >
                                                  {d.map((ds, iss) => (
                                                    <React.Fragment key={iss}>
                                                      <p>
                                                        <b>{ds.name}</b>:{" "}
                                                        {ds.value}
                                                      </p>
                                                      <hr />
                                                    </React.Fragment>
                                                  ))}
                                                </div>
                                              ))
                                            : item.data.map((d, is) => (
                                                <React.Fragment key={is}>
                                                  <p>
                                                    <b>{d.name}</b>: {d.value}
                                                  </p>
                                                  <hr />
                                                </React.Fragment>
                                              ))}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="tab-pane fade" id="tab_1">
                          <div className="streamline">
                            {this.state.lead.actions.banners.map((data, i) => (
                              <div key={i} className="sl-item b-danger">
                                <div className="sl-content">
                                  <div className="sl-date text-muted">
                                    {data.banner_date}
                                  </div>
                                  <h6>
                                    {this.translate(
                                      "LEADS_DATA_VISUALIZED_THE_BANNER"
                                    )}{" "}
                                    -{" "}
                                    <a
                                      href={data.banner_img}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <i className="fa fa-external-link text-primary"></i>
                                    </a>
                                  </h6>
                                  <span className="d-inline-block p-1 b-a">
                                    <img
                                      src={data.banner_img}
                                      alt="."
                                      className="w-96"
                                    />
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="tab-pane fade" id="tab_2">
                          <div className="streamline">
                            {this.state.lead.journey.map((journey, i) => (
                              <Fragment key={i}>
                                <h6
                                  style={{
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {journey.journey_name}
                                </h6>
                                {journey.journey_moments.map((data, i) => (
                                  <div
                                    key={i}
                                    className={
                                      "sl-item " +
                                      this.returnClassIconTimeline(
                                        data.moment_action
                                      )
                                    }
                                  >
                                    <div className="sl-content">
                                      <div className="sl-date text-muted">
                                        {data.moment_date}
                                      </div>
                                      <h6>{data.moment_name}</h6>
                                    </div>
                                  </div>
                                ))}
                              </Fragment>
                            ))}
                          </div>
                        </div>
                        <div className="tab-pane fade" id="tab_3">
                          <div className="streamline">
                            {this.state.lead.actions.emails.map((data, i) => (
                              <div key={i} className="sl-item b-success">
                                <div className="sl-content">
                                  <div className="sl-date text-muted">
                                    {data.date}
                                  </div>
                                  <h6>
                                    {this.translate("LEADS_DATA_EMAIL")}{" "}
                                    <b>{data.name}</b>{" "}
                                    {this.translate("LEADS_DATA_RECEIVED")}
                                  </h6>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="tab-pane fade" id="tab_4">
                          <div className="row mb-2">
                            <div className="col-12">
                              <h6 className="text-success">
                                <i className="fa fa-check"></i>{" "}
                                {this.translate("LEADS_DATA_CURRENT_AGENT")}
                              </h6>
                            </div>
                            <div className="col-6">
                              <small className="text-muted">
                                {this.translate("LEADS_DATA_AGENT_NAME")}
                              </small>
                              <div className="_500">
                                {this.state.lead.agent.full_name}
                              </div>
                            </div>
                            <div className="col-6">
                              <small className="text-muted">
                                {this.translate("LEADS_DATA_BROKER")}
                              </small>
                              <div className="_500">
                                {this.state.lead.broker.broker_name}
                              </div>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-6">
                              <small className="text-muted">
                                {this.translate("LEADS_DATA_PHONE")}
                              </small>
                              <div className="_500">
                                {this.state.lead.agent.full_phone}
                              </div>
                            </div>
                            <div className="col-6">
                              <small className="text-muted">
                                {this.translate("LEADS_DATA_EMAIL")}
                              </small>
                              <div className="_500">
                                {this.state.lead.agent.email}
                              </div>
                            </div>
                          </div>
                          <div>
                            <small className="text-muted">
                              {this.translate("LEADS_DATA_INTERACTION")}
                            </small>
                            <div>
                              {this.state.lead.feedbacks.map((data, i) => (
                                <p key={i}>{data.feedback_message}</p>
                              ))}
                            </div>
                          </div>
                          <div className="box-divider w-100 mt10 mb10"></div>

                          <div className="row mb-2">
                            <div className="col-12">
                              <h6 className="text-danger text-muted">
                                <i className="fa fa-circle"></i>{" "}
                                {this.translate("LEADS_DATA_PREVIOUS_AGENT")}
                              </h6>
                            </div>
                            <div className="col-6">
                              <small className="text-muted">
                                {this.translate("LEADS_DATA_AGENT_NAME")}
                              </small>
                              <div className="_500">
                                {
                                  this.state.lead.agent.agent_previous[0]
                                    .full_name
                                }
                              </div>
                            </div>
                            <div className="col-6">
                              <small className="text-muted">
                                {this.translate("LEADS_DATA_BROKER")}
                              </small>
                              <div className="_500">
                                {
                                  this.state.lead.broker.broker_previous[0]
                                    .broker_name
                                }
                              </div>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-6">
                              <small className="text-muted">
                                {this.translate("LEADS_DATA_PHONE")}
                              </small>
                              <div className="_500">
                                {
                                  this.state.lead.agent.agent_previous[0]
                                    .full_phone
                                }
                              </div>
                            </div>
                            <div className="col-6">
                              <small className="text-muted">
                                {this.translate("LEADS_DATA_EMAIL")}
                              </small>
                              <div className="_500">
                                {this.state.lead.agent.agent_previous[0].email}
                              </div>
                            </div>
                          </div>
                          <div>
                            <small className="text-muted">
                              {this.translate("LEADS_DATA_INTERACTION")}
                            </small>
                            <div>
                              {this.state.lead.feedbacks.map((data, i) => (
                                <p key={i}>{data.feedback_message}</p>
                              ))}
                            </div>
                          </div>
                          <div className="box-divider w-100 mt10 mb10"></div>
                        </div>
                        <div className="tab-pane fade" id="tab_5">
                          <div className="streamline">
                            {this.state.lead.actions.funnel_stage.map(
                              (data, i) => (
                                <div key={i} className="sl-item b-warning">
                                  <div className="sl-content">
                                    <div className="sl-date text-muted">
                                      {data.date}
                                    </div>
                                    <h6>
                                      {this.translate(
                                        "LEADS_DATA_FUNNEL_STAGE"
                                      )}
                                      : {data.funnel_stage}
                                    </h6>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                        <div className="tab-pane fade" id="tab_6">
                          <div className="streamline">
                            {this.state.lead.actions.landing_pages.map(
                              (data, i) => (
                                <div key={i} className="sl-item b-warn">
                                  <div className="sl-content">
                                    <div className="sl-date text-muted">
                                      {data.date}
                                    </div>
                                    <h6 className="m0 p0">{data.name}</h6>
                                    {typeof data["smartlook_shared_url"] !==
                                      "undefined" &&
                                    data.smartlook_shared_url ? (
                                      <p>
                                        <a
                                          href={data.smartlook_shared_url}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <i className="fa fa-eyes"></i>{" "}
                                          {this.translate(
                                            "LEADS_DATA_VISUALIZE_THIS_LEAD_NAVIGATION"
                                          )}
                                        </a>
                                      </p>
                                    ) : (
                                      false
                                    )}
                                    <p className="text-muted">
                                      {data.interaction_time}
                                    </p>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                        <div className="tab-pane fade" id="tab_7">
                          <div className="streamline">
                            {this.state.lead.feedbacks.map((data, i) => (
                              <div key={i} className="sl-item b-warning">
                                <div className="sl-content">
                                  <div className="sl-date text-muted">
                                    {data.feedback_date}
                                  </div>
                                  <h6>
                                    {this.translate(
                                      "LEADS_DATA_FEEDBACK_NOTE_CREATED_BY"
                                    )}{" "}
                                    <a
                                      href={document.location.href}
                                      className="text-info"
                                    >
                                      {data.feedback_creative_user_name}
                                    </a>{" "}
                                    - &nbsp;
                                    <a
                                      href={document.location.href}
                                      data-container="body"
                                      data-toggle="popover"
                                      data-placement="top"
                                      data-title={this.translate(
                                        "LEADS_DATA_FEEDBACK_NOTE"
                                      )}
                                      data-content={data.feedback_message}
                                      data-original-title=""
                                      title=""
                                    >
                                      <i className="fa fa-file text-warning"></i>
                                    </a>
                                  </h6>
                                </div>
                              </div>
                            ))}
                            {this.state.lead.follow_ups.map((data, i) => (
                              <div key={i} className="sl-item b-warning">
                                <div className="sl-content">
                                  <div className="sl-date text-muted">
                                    {data.follow_up_date}
                                  </div>
                                  <h6>
                                    {this.translate(
                                      "LEADS_DATA_FOLLOW_UP_NOTE_CREATED_BY"
                                    )}{" "}
                                    <a
                                      href={document.location.href}
                                      className="text-info"
                                    >
                                      {data.follow_up_creative_user_name}
                                    </a>{" "}
                                    - &nbsp;
                                    <a
                                      href={document.location.href}
                                      data-container="body"
                                      data-toggle="popover"
                                      data-placement="top"
                                      data-title={this.translate(
                                        "LEADS_DATA_FOLLOW_UP_NOTE"
                                      )}
                                      data-content={data.follow_up_message}
                                      data-original-title=""
                                      title=""
                                    >
                                      <i className="fa fa-file text-warning"></i>
                                    </a>
                                  </h6>
                                </div>
                              </div>
                            ))}
                            {this.state.lead.tasks.map((data, i) => (
                              <div key={i} className="sl-item b-warning">
                                <div className="sl-content">
                                  <div className="sl-date text-muted">
                                    {data.task_date}
                                  </div>
                                  <h6>
                                    {this.translate(
                                      "LEADS_DATA_TASK_CREATED_BY"
                                    )}{" "}
                                    <a
                                      href={document.location.href}
                                      className="text-info"
                                    >
                                      {data.task_creative_user_name}
                                    </a>{" "}
                                    -
                                    <a
                                      href={document.location.href}
                                      data-container="body"
                                      data-toggle="popover"
                                      data-placement="top"
                                      data-title={data.task_meeting}
                                      data-content={data.task_note}
                                      data-original-title=""
                                      title=""
                                    >
                                      <i className="fa fa-file text-warning"></i>
                                    </a>
                                  </h6>
                                </div>
                              </div>
                            ))}
                          </div>
                          <hr className="hr_w" />
                          <div className="streamline">
                            <div className="col-sm-12 col-md-12">
                              <div className="b-b b-primary nav-active-primary">
                                <ul className="nav nav-tabs dark">
                                  <li className="nav-item">
                                    <a
                                      className="nav-link no-border-radius active show"
                                      href="/"
                                      data-toggle="tab"
                                      data-target="#tabx"
                                    >
                                      <i className="fa fa-edit"> </i>{" "}
                                      {this.translate(
                                        "LEADS_DATA_CREATE_NEW_NOTE"
                                      )}
                                    </a>
                                  </li>
                                  <li className="nav-item">
                                    <a
                                      className="nav-link no-border-radius"
                                      href="/"
                                      data-toggle="tab"
                                      data-target="#taby"
                                    >
                                      <i className="fa fa-briefcase"> </i>{" "}
                                      {this.translate(
                                        "LEADS_DATA_CREATE_NEW_TASK"
                                      )}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="tab-content mt20 p0 mb-3">
                                <div
                                  className="tab-pane animate fadeIn text-muted active show"
                                  id="tabx"
                                >
                                  <form
                                    onSubmit={this.formSubmitNote}
                                    className="padding white no-box-shadow"
                                  >
                                    <div className="form-group row m0 mb10 p0">
                                      <div className="col-sm-4 col-md-4 p0">
                                        <select
                                          onChange={(e) =>
                                            this.setState({
                                              formNote: {
                                                ...this.state.formNote,
                                                note_type: e.target.value,
                                              },
                                            })
                                          }
                                          className="form-control box-shadow2 light cool-select"
                                          required
                                        >
                                          <option value="">
                                            {this.translate(
                                              "LEADS_DATA_NOTE_TYPE"
                                            )}
                                            :
                                          </option>
                                          <option value="feedback">
                                            {this.translate(
                                              "LEADS_DATA_FEEDBACK"
                                            )}
                                          </option>
                                          <option value="followup">
                                            {this.translate(
                                              "LEADS_DATA_FOLLOW_UP"
                                            )}
                                          </option>
                                        </select>
                                      </div>
                                      <div className="col-sm-6 col-md-6 d-flex">
                                        <div className="btn-group btn-shadow">
                                          <button
                                            type="button"
                                            onChange={(e) =>
                                              this.setState({
                                                formNote: {
                                                  ...this.state.formNote,
                                                  evaluation_type: "positive",
                                                },
                                              })
                                            }
                                            className="btn btn-sm success pr-1"
                                          >
                                            <i className="fa fa-thumbs-up"></i>{" "}
                                            {this.translate(
                                              "LEADS_DATA_POSITIVE"
                                            )}
                                          </button>
                                          <button
                                            type="button"
                                            onChange={(e) =>
                                              this.setState({
                                                formNote: {
                                                  ...this.state.formNote,
                                                  evaluation_type: "negative",
                                                },
                                              })
                                            }
                                            className="btn btn-sm danger pl-1"
                                          >
                                            <i className="fa fa-thumbs-down"></i>{" "}
                                            {this.translate(
                                              "LEADS_DATA_NEGATIVE"
                                            )}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group row m0 p0">
                                      <div className="col-sm-12 col-md-12 p0">
                                        <textarea
                                          onChange={(e) =>
                                            this.setState({
                                              formNote: {
                                                ...this.state.formNote,
                                                feedback_message:
                                                  e.target.value,
                                              },
                                            })
                                          }
                                          className="form-control width100"
                                          rows="5"
                                          placeholder={this.translate(
                                            "LEADS_DATA_WRITE_YOUR_NOTES"
                                          )}
                                          required
                                        ></textarea>
                                      </div>
                                    </div>
                                    <div className="form-group row m0 p0">
                                      <div className="col-sm-12 col-md-12">
                                        <button
                                          type="submit"
                                          className="btn btn-success btn-shadow btn-rounded mt10"
                                        >
                                          {this.translate("BUTTON_REGISTER")}
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane animate fadeIn text-muted mt10"
                                  id="taby"
                                >
                                  <form
                                    onSubmit={this.formSubmitTask}
                                    className="mt20"
                                  >
                                    <div
                                      className="btn-group width100 box-shadow2"
                                      role="group"
                                      aria-label="Basic example"
                                    >
                                      <button
                                        type="button"
                                        onChange={(e) =>
                                          this.setState({
                                            formTask: {
                                              ...this.state.formTask,
                                              task_action_type: "call",
                                            },
                                          })
                                        }
                                        style={{ width: "33.33%" }}
                                        className="btn btn-secondary blue f14"
                                      >
                                        <i className="fa fa-phone"></i>{" "}
                                        {this.translate("LEADS_DATA_CALL")}
                                      </button>
                                      <button
                                        type="button"
                                        onChange={(e) =>
                                          this.setState({
                                            formTask: {
                                              ...this.state.formTask,
                                              task_action_type: "visit",
                                            },
                                          })
                                        }
                                        style={{ width: "33.33%" }}
                                        className="btn btn-secondary green f14"
                                      >
                                        <i className="fa fa-home"></i>{" "}
                                        {this.translate("LEADS_DATA_VISIT")}
                                      </button>
                                      <button
                                        type="button"
                                        onChange={(e) =>
                                          this.setState({
                                            formTask: {
                                              ...this.state.formTask,
                                              task_action_type: "term",
                                            },
                                          })
                                        }
                                        style={{ width: "33.33%" }}
                                        className="btn btn-secondary red f14"
                                      >
                                        <i className="fa fa-arrow-right"></i>{" "}
                                        {this.translate("LEADS_DATA_TERM")}
                                      </button>
                                    </div>
                                    <div className="form-group row">
                                      <div className="col-sm-4 col-sm-4 mt20">
                                        <select
                                          onChange={(e) =>
                                            this.setState({
                                              formTask: {
                                                ...this.state.formTask,
                                                task_target_type:
                                                  e.target.value,
                                              },
                                            })
                                          }
                                          className="form-control box-shadow2 cool-select"
                                          required
                                        >
                                          <option value="">
                                            {this.translate("LEADS_DATA_TYPE")}
                                          </option>
                                          <option value="management">
                                            {this.translate(
                                              "LEADS_DATA_MANAGEMENT"
                                            )}
                                          </option>
                                          <option value="client">
                                            {this.translate(
                                              "LEADS_DATA_CLIENT"
                                            )}
                                          </option>
                                        </select>
                                      </div>
                                      <div className="col-sm-8 col-sm-8 mt20">
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            this.setState({
                                              formTask: {
                                                ...this.state.formTask,
                                                task_meeting: e.target.value,
                                              },
                                            })
                                          }
                                          className="form-control box-shadow2"
                                          placeholder={this.translate(
                                            "LEADS_DATA_MEETING"
                                          )}
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <div className="col-sm-4 col-md-4">
                                        <div className="input-group mb-2 mr-sm-2 box-shadow2">
                                          <div className="input-group-prepend">
                                            <div
                                              className="input-group-text dark"
                                              style={{
                                                padding: "7px",
                                                borderRadius: "4px 0 0 4px",
                                              }}
                                            >
                                              <i className="fa fa-clock-o"></i>
                                            </div>
                                          </div>
                                          <input
                                            type="time"
                                            onChange={(e) =>
                                              this.setState({
                                                formTask: {
                                                  ...this.state.formTask,
                                                  task_hour: e.target.value,
                                                },
                                              })
                                            }
                                            className="form-control"
                                            id=""
                                            placeholder={this.translate(
                                              "LEADS_DATA_HOUR"
                                            )}
                                            required
                                          />
                                        </div>
                                      </div>
                                      <div className="col-sm-4 col-md-4">
                                        <div className="input-group mb-2 mr-sm-2 box-shadow2">
                                          <div className="input-group-prepend">
                                            <div
                                              className="input-group-text dark"
                                              style={{
                                                padding: "7px",
                                                borderRadius: "4px 0 0 4px",
                                              }}
                                            >
                                              <i className="fa fa-calendar-o"></i>
                                            </div>
                                          </div>
                                          <input
                                            type="date"
                                            onChange={(e) =>
                                              this.setState({
                                                formTask: {
                                                  ...this.state.formTask,
                                                  task_date: e.target.value,
                                                },
                                              })
                                            }
                                            className="form-control"
                                            id=""
                                            placeholder={this.translate(
                                              "LEADS_DATA_DATE"
                                            )}
                                            required
                                          />
                                        </div>
                                      </div>
                                      <div className="col-sm-4 col-md-4">
                                        <div className="input-group mb-2 mr-sm-2 box-shadow2">
                                          <div className="input-group-prepend">
                                            <div
                                              className="input-group-text dark"
                                              style={{
                                                padding: "7px",
                                                borderRadius: "4px 0 0 4px",
                                              }}
                                            >
                                              <i className="fa fa-arrow-right"></i>
                                            </div>
                                          </div>
                                          <input
                                            type="number"
                                            onChange={(e) =>
                                              this.setState({
                                                formTask: {
                                                  ...this.state.formTask,
                                                  task_duration: e.target.value,
                                                },
                                              })
                                            }
                                            className="form-control"
                                            id=""
                                            placeholder={this.translate(
                                              "LEADS_DATA_DURATION"
                                            )}
                                            required
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <div className="col-sm-12 col-md-12">
                                        <textarea
                                          className="form-control box-shadow2"
                                          onChange={(e) =>
                                            this.setState({
                                              formTask: {
                                                ...this.state.formTask,
                                                task_note: e.target.value,
                                              },
                                            })
                                          }
                                          placeholder={this.translate(
                                            "LEADS_DATA_NOTES"
                                          )}
                                          style={{ minHeight: "80px" }}
                                          required
                                        ></textarea>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <div className="col-sm-12 col-md-12">
                                        <button
                                          type="submit"
                                          className="btn btn-success btn-rounded btn-shadow"
                                        >
                                          {this.translate("BUTTON_REGISTER")}
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(LeadData);
