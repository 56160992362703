import React, {Component} from "react"

import $ from "jquery"
import Modal from 'react-bootstrap/Modal';
import api from '../../../Services/Api';

export default class AttendanceLeadMerge extends Component{

    constructor(props){
        super(props)
        this.state = {
            leadMergeFilter: "",
            isLoadingFilter: false,
            leadMergeList: [],
            isLeadMergeListVisible: false,
            leadMergePosition: [null, null, null],
            leadWasSelected: false,
            selectedLead: {
                name: "",
                email: "",
                phone: "",
            },
            leadMerge: {
                name: "",
                email: "",
                phone: "",
            }
        }
        this.translate =  this.props.translate
    }

    filterLeadMergeList = (e) => {
        let filterValue = e.target.value
        this.setState({leadMergeFilter: filterValue, isLeadMergeListVisible: filterValue.length > 2 ? true : false}, this.loadLeadMergeList)
        
    }

    loadLeadMergeList = async () => {
        let {leadMergeFilter} = this.state
        
        this.setState({isLoadingFilter: true})
        try {
            let response = await api.post("/attendance/contact/leads/list", {filter: leadMergeFilter})
            
            console.log(response.data)
            if(response && response.data && response.data.success && response.data.leads.length){
                this.setState({leadMergeList: response.data.leads})
            }
            
        } catch (error) {
            console.log(error)
        }

        this.setState({isLoadingFilter: false})

    }

    chooseLeadToMerge = (e, lead) => {
        e.preventDefault()
        this.setState({
            selectedLead: lead,
            leadWasSelected: true,
            isLeadMergeListVisible: false,
            leadMergeFilter: ""
        })
        this.checkEmptyFieldsAndSelectIt(lead)
    }

    checkEmptyFieldsAndSelectIt = (selectedLead) => {
        let {lead_data} = this.props
        let { leadMerge, leadMergePosition} = this.state

        this.selectEmptyField("name", lead_data.name, selectedLead.name, leadMerge, leadMergePosition)
        this.selectEmptyField("email", lead_data.email, selectedLead.email, leadMerge, leadMergePosition)
        this.selectEmptyField("phone", lead_data.phone, selectedLead.phone, leadMerge, leadMergePosition)
        
    }

    selectEmptyField = (fieldName, firstField, secondField, leadMerge, leadMergePosition) => {
        if(!firstField || !secondField){
            if(!secondField){
                leadMerge[fieldName] = firstField
                leadMergePosition[this.getFieldPositionByItsName(fieldName)] = 0
            } else {
                leadMerge[fieldName] = secondField
                leadMergePosition[this.getFieldPositionByItsName(fieldName)] = 1
            }
        }
    }

    getFieldPositionByItsName = (fieldName) => {
        let position = null
        switch(fieldName){
            case "name": 
                position = 0
                break
            case "email": 
                position = 1
                break
            default:
                position = 2
                break
        }
        return position
    }


    handleMergeInputClick = (e, fieldName, fieldPosition) => {
        let fieldValue = e.target.value
        if(!this.state.leadWasSelected || !fieldValue) return
        let {leadMergePosition, leadMerge} = this.state
        switch(fieldName){
            case "name": 
                leadMerge["name"] = fieldValue
                leadMergePosition[0] = fieldPosition
                break
            case "email": 
                leadMerge["email"] = fieldValue
                leadMergePosition[1] = fieldPosition
                break
            default:
                leadMerge["phone"] = fieldValue
                leadMergePosition[2] = fieldPosition
                break
        }
        this.setState({leadMerge, leadMergePosition})
    }

    clearLeadMerge = () => this.setState({
        leadMergeFilter: "",
        leadMergeList: [],
        isLeadMergeListVisible: false,
        leadMergePosition: [null, null, null],
        leadWasSelected: false,
        selectedLead: {
            name: "",
            email: "",
            phone: "",
            
        },
        leadMerge: {
            name: "",
            email: "",
            phone: "",
        }
    })

    checkLeadMergeHasEmptyFields = () => {
        let checkEmpty = false
        let {leadMergePosition} = this.state

        leadMergePosition.forEach(position => {
            if(position === null) checkEmpty = true
        })

        return checkEmpty
    }

    handleLeadMergeSubmit = async() => {
        let {leadMerge} = this.state
        let primaryIdAttendance = this.props.idAttendance
        let secondaryIdAttendance = this.state.selectedLead.id

        try{
            let response = await api.post("/attendance/contact/leads/update", {choosenValues: leadMerge, primaryIdAttendance: primaryIdAttendance, secondaryIdAttendance: secondaryIdAttendance})
            if(response && response.data.success){
                this.props.handleCloseModalLeadMerge()
                this.clearLeadMerge()
                this.props.refreshAttendanceContacts()
                this.props.handleShowMessageModalResponse(this.translate("ATTENDANCE_LEAD_MERGE_MERGE_SUCCESS"))
            } else {
                this.props.handleShowMessageModalResponse(this.translate("ATTENDANCE_LEAD_MERGE_MERGE_ERROR"))
            }
        } catch(error){
            console.log(error)
        }
    }

    componentDidMount = () => {
        $("body").click((e) => {
            if (e.target.id !== "attendance_room_filter_list" && !$(e.target).parents("#attendance_room_filter_list").length) {
                if (e.target.id !== "attendance_room_filter_input" && !$(e.target).parents("#attendance_room_filter_input").length) {
                    this.setState({isLeadMergeListVisible: false})
                } else{
                    if(this.state.leadMergeFilter.length > 2) {
                        this.setState({isLeadMergeListVisible: true})
                    }
                }
            }
        })
    }

    componentDidUpdate(prevProps){
        if(prevProps.lead_data !== this.props.lead_data) this.clearLeadMerge()
    }

    render = () =>
        <Modal show={this.props.isModalLeadMergeVisible} onHide={this.props.handleCloseModalLeadMerge} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
            <Modal.Body>
                <div className="d-flex flex-column h-100 bg-blur_w2" id="attendance_lead_merge_div">
                    <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                        <h5 className="h5 m-0 text-center width100">
                        <i className="fa fa-compress"></i> {this.translate('ATTENDANCE_LEAD_MERGE_LEAD_MERGE')}</h5>
                        <a href="/" onClick={e=>this.props.handleCloseModalLeadMerge(e)} className="ml-auto">
                            <span className="text-lg l-h-1x">×</span>
                        </a>
                    </div>
                    <div className="row position10 padding-20px">
                        <div className="col-12">
                            <form id="attendance_room_filter_input" onSubmit={(e) => e.preventDefault()}>
                                <div className="form-group mb-0">
                                    <input type="text" className="form-control col" placeholder={this.translate("ATTENDANCE_LEAD_MERGE_SEARCH_LEAD")} value={this.state.leadMergeFilter} onChange={e=>this.filterLeadMergeList(e)} />
                                </div>
                            </form>
                            <div className="col-12">
                                <div className="row" style={{position: "relative"}}>
                                    <div id="attendance_room_filter_list" className={`col-12 list-group lead_merge_list bg-white text-dark ${this.state.isLeadMergeListVisible ? "d-block" : "d-none"}`}>
                                        {this.state.leadMergeList.length > 0 ? 
                                            this.state.leadMergeList.map((lead, index)=>
                                                <a key={`${lead.id}${index}`} onClick={e=>this.chooseLeadToMerge(e, lead)} href="/" className="list-group-item list-group-item-action">{lead.name}</a>
                                            )
                                        :
                                        this.state.isLoadingFilter ?
                                            <p className="my-2 mx-auto text-center"><i className="fa fa-spin fa-spinner"></i></p>
                                        :
                                            <p className="my-2 mx-auto text-center">{this.props.translate("ATTENDANCE_LEAD_MERGE_NO_LEADS")}</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <form>
                                <div className="form-group">
                                    <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_TABLE_LABEL_NAME')}</label>
                                    <input type="text" readOnly defaultValue={this.props.lead_data.name} onClick={e=>this.handleMergeInputClick(e, "name", 0)} className={`form-control box-shadow dark-important btn-cursor-pointer ${this.state.leadMergePosition[0] === 0 ? "attendance_lead_merge_active_input" : ""}`} />
                                </div>
                                <div className="form-group">
                                    <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_TABLE_LABEL_EMAIL')}</label>
                                    <input type="text" readOnly defaultValue={this.props.lead_data.email} onClick={e=>this.handleMergeInputClick(e, "email", 0)} className={`form-control box-shadow dark-important btn-cursor-pointer ${this.state.leadMergePosition[1] === 0 ? "attendance_lead_merge_active_input" : ""}`} />
                                </div>
                                <div className="form-group">
                                    <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_TABLE_LABEL_PHONE')}</label>
                                    <input type="text" readOnly defaultValue={this.props.lead_data.phone} onClick={e=>this.handleMergeInputClick(e, "phone", 0)} className={`form-control box-shadow dark-important btn-cursor-pointer ${this.state.leadMergePosition[2] === 0 ? "attendance_lead_merge_active_input" : ""}`} />
                                </div>
                            </form>
                        </div>
                        <div className="col-3 col-md-2 rounded text-center d-flex align-items-center">
                            <div>
                                {!this.state.leadWasSelected ?
                                    <div className="d-flex flex-column">
                                        <i className="fa fa-search text-lg" style={{fontSize: 35}}></i>
                                        <span className="lead">
                                            {this.translate('ATTENDANCE_LEAD_MERGE_SEARCH_FOR_THE_LEAD_TO_BE_MERGED')}
                                        </span>
                                    </div>
                                :
                                    <div className="d-flex flex-column">
                                        <i className="fa fa-arrows-h text-lg" style={{fontSize: 35}}></i>
                                        <span className="lead">
                                            {this.translate('ATTENDANCE_LEAD_MERGE_CLICK_IN_THE_FIELDS_THAT_SHOULD_BE_KEPT')}
                                        </span>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col">
                            <form>
                                <div className="form-group">
                                    <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_TABLE_LABEL_NAME')}</label>
                                    <input type="text" readOnly defaultValue={this.state.selectedLead.name} onClick={e=>this.handleMergeInputClick(e, "name", 1)} placeholder={this.translate("ATTENDANCE_LEAD_MERGE_SEARCH_FOR_THE_LEAD_TO_BE_MERGED")} className={`form-control box-shadow dark-important btn-cursor-pointer ${this.state.leadMergePosition[0] === 1 ? "attendance_lead_merge_active_input" : ""}`} />
                                </div>
                                <div className="form-group">
                                    <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_TABLE_LABEL_EMAIL')}</label>
                                    <input type="text" readOnly defaultValue={this.state.selectedLead.email} onClick={e=>this.handleMergeInputClick(e, "email", 1)} placeholder={this.translate("ATTENDANCE_LEAD_MERGE_SEARCH_FOR_THE_LEAD_TO_BE_MERGED")} className={`form-control box-shadow dark-important btn-cursor-pointer ${this.state.leadMergePosition[1] === 1 ? "attendance_lead_merge_active_input" : ""}`} />
                                </div>
                                <div className="form-group">
                                    <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_TABLE_LABEL_PHONE')}</label>
                                    <input type="text" readOnly defaultValue={this.state.selectedLead.phone} onClick={e=>this.handleMergeInputClick(e, "phone", 1)} placeholder={this.translate("ATTENDANCE_LEAD_MERGE_SEARCH_FOR_THE_LEAD_TO_BE_MERGED")} className={`form-control box-shadow dark-important btn-cursor-pointer ${this.state.leadMergePosition[2] === 1 ? "attendance_lead_merge_active_input" : ""}`} />
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="row position10 padding-20px">
                        <div className="col-12 text-center">
                            <button disabled={this.checkLeadMergeHasEmptyFields() ? "disabled" : ""} onClick={this.handleLeadMergeSubmit} className="btn btn-info btn-shadow btn-cursor-pointer mx-auto w-100">
                                <i className="fa fa-compress"></i> {this.translate('ATTENDANCE_LEAD_MERGE_LEADS')}
                            </button>
                        </div>

                    </div>
                </div>
            </Modal.Body>
        </Modal>


}
