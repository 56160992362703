import React, {Component, Fragment} from 'react';
import MenuMain from '../../Structure/MenuMain';
import HeaderMain from '../../Structure/HeaderMain';
import FooterMain from '../../Structure/FooterMain';
import FeedMain from '../../Structure/FeedMain';
import {withRouter} from 'react-router-dom';

class LeadsConversion extends Component {
    constructor(props){
        super(props);
        document.title = 'Conversão Leads'
        this.state = {
            list_segmentation: [],
            list_banner: [],
            list_landing_pages: [],
            list_origins: [],
            list_emails: [],
            list_sms: []
        }
    }
    
    componentDidMount(){
        const link = document.querySelector('a[data-target="'+ document.location.hash +'"]')
        if(link){
            link.click();
        }
    }

    render() {
        return (
            <Fragment>
                <MenuMain />
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    <HeaderMain title="Conversão Leads" onChange={this.handleChange}/>
                    <div className="content-main row row-no-margin" id="content-main">
                        <div className=" col-lg-10 col-md-10 col-sm-12 col-xs-12 no-gutters" id="leads">
                            
                        <div className="white bg b-b px-3">
                                <div className="row ">
                                    <div className="col-sm-5 order-sm-1 text-center p0">
                                        <div className="py-4 clearfix nav-active-theme">
                                            <ul className="nav nav-pills nav-sm p-2 pb20 pt20">
                                                <li className="nav-item f700 w-100">
                                                    <a className="nav-link f-700 text-left f20" href="/">
                                                        Conversão
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-sm-7 order-sm-1 text-center p0">
                                        <div className="py-4 clearfix nav-active-theme ">
                                            <ul className="nav nav-pills nav-sm p-2 pb20 pt20 pull-right">
                                                <li className="nav-item">
                                                    <a className="nav-link active" href="/" data-toggle="tab" data-target="#tab_1">
                                                        <i className="fa fa-sitemap"></i> Segmentação
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" href="/" data-toggle="tab" data-target="#tab_2">
                                                        <i className="fa fa-clone"></i> Banner
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" href="/" data-toggle="tab" data-target="#tab_3">
                                                        <i className="fa fa-laptop"></i> Landing page
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" href="/" data-toggle="tab" data-target="#tab_4">
                                                        <i className="fa fa-external-link"></i> Origem
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" href="/" data-toggle="tab" data-target="#tab_5">
                                                        <i className="fa fa-envelope"></i> E-mail
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" href="/" data-toggle="tab" data-target="#tab_6">
                                                        <i className="fa fa-mobile"></i> SMS
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="padding">
                                <div className="row">
                                    <div className="col-sm-12 col-lg-12">
                                        <div className="tab-content">

                                            <div className="tab-pane fade active show" id="tab_1">
                                                <div className="white p-3">
                                                    <div className="box-header pb0">
                                                        <h3> Segmentações</h3>
                                                        <p className="text-muted mt10">Listadas por ordem de conversão</p>
                                                        <div className="box-divider m0 mt10 mb10 w-100"></div>
                                                    </div>
                                                    <div className="box p-3">
                                                        
                                                        {
                                                            this.state.list_segmentation.map((value, i) => 

                                                                <div key={i} className="py-2 b-b">
                                                                    <div className="float-right lh mt10">
                                                                        <span className="text-md text-primary f700 d-block">{value.segmentation_conversion_rate}% </span>
                                                                        <br/>
                                                                        <span className="f10 d-block">de conversão</span>
                                                                    </div>
                                                                    <span className="_600 d-block text-info"> {value.segmentation_name}</span>
                                                                    <small className="">{value.segmentation_pitch}</small>
                                                                </div>

                                                            )
                                                        }

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tab-pane fade" id="tab_2">
                                                <div className="white p-3">
                                                    <div className="box-header pb0">
                                                        <h3> Banner</h3>
                                                        <p className="text-muted mt10">Listados por ordem de conversão</p>
                                                        <div className="box-divider m0 mt10 mb10 w-100"></div>
                                                    </div>
                                                    <div className="box p-3">
                                                        
                                                        {
                                                            this.state.list_banner.map((value, i) => 

                                                                <div key={i} className="py-2 b-b">
                                                                    <div className="float-right lh mt10">
                                                                        <span className="text-md text-primary f700 d-block">{value.banner_conversion_rate}% </span>
                                                                        <br/>
                                                                        <span className="f10 d-block">de conversão</span>
                                                                    </div>
                                                                    <span className="d-inline-block p-1 b-a">
                                                                        <img src={value.banner_url_img} alt="." className="w-md"/>
                                                                    </span>
                                                                    <span className="_600 d-block text-info"> {value.banner_name}</span>
                                                                    <small className="">{value.banner_pitch}</small>
                                                                </div>

                                                            )
                                                        }

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tab-pane fade" id="tab_3">
                                                <div className="white p-3">
                                                    <div className="box-header pb0">
                                                        <h3> Landing Page</h3>
                                                        <p className="text-muted mt10">Listadas por ordem de conversão</p>
                                                        <div className="box-divider m0 mt10 mb10 w-100"></div>
                                                    </div>
                                                    <div className="box p-3">
                                                        
                                                        {
                                                            this.state.list_landing_pages.map((value, i) => 

                                                                <div key={i} className="py-2 b-b">
                                                                    <div className="float-right lh mt10">
                                                                        <span className="text-md text-primary f700 d-block">{value.landing_page_conversion_rate}% </span>
                                                                        <br/>
                                                                        <span className="f10 d-block">de conversão</span>
                                                                    </div>
                                                                    <span className="_600 d-block text-info"> {value.landing_page_name}</span>
                                                                    <a href={value.landing_page_url} target="_blank" class="text-primary" rel="noopener noreferrer">
                                                                        <i class="fa fa-external-link"></i> {value.landing_page_pretty_url}
                                                                    </a>
                                                                    <br/>
                                                                    <small className="">{value.landing_page_pitch}</small>
                                                                </div>

                                                            )
                                                        }

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tab-pane fade" id="tab_4">
                                                <div className="white p-3">
                                                    <div className="box-header pb0">
                                                        <h3> Origem</h3>
                                                        <p className="text-muted mt10">Listadas por ordem de conversão</p>
                                                        <div className="box-divider m0 mt10 mb10 w-100"></div>
                                                    </div>
                                                    <div className="box p-3">
                                                        
                                                        {
                                                            this.state.list_origins.map((value, i) => 

                                                                <div key={i} className="py-2 b-b">
                                                                    <div className="float-right lh mt10">
                                                                        <span className="text-md text-primary f700 d-block">{value.origin_conversion_rate}% </span>
                                                                        <br/>
                                                                        <span className="f10 d-block">de conversão</span>
                                                                    </div>
                                                                    <span className="_600 d-block text-info"> {value.origin_name}</span>
                                                                    <small className="">{value.origin_pitch}</small>
                                                                </div>

                                                            )
                                                        }

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tab-pane fade" id="tab_5">
                                                <div className="white p-3">
                                                    <div className="box-header pb0">
                                                        <h3> E-mails</h3>
                                                        <p className="text-muted mt10">Listados por ordem de conversão</p>
                                                        <div className="box-divider m0 mt10 mb10 w-100"></div>
                                                    </div>
                                                    <div className="box p-3">
                                                        
                                                        {
                                                            this.state.list_emails.map((value, i) => 

                                                                <div key={i} className="py-2 b-b">
                                                                    <div className="float-right lh mt10">
                                                                        <span className="text-md text-primary f700 d-block">{value.emails_conversion_rate}% </span>
                                                                        <br/>
                                                                        <span className="f10 d-block">de conversão</span>
                                                                    </div>
                                                                    <span className="_600 d-block text-info"> {value.emails_name}</span>
                                                                    <small className="">{value.emails_pitch}</small>
                                                                </div>

                                                            )
                                                        }

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tab-pane fade" id="tab_6">
                                                <div className="white p-3">
                                                    <div className="box-header pb0">
                                                        <h3> SMS</h3>
                                                        <p className="text-muted mt10">Listados por ordem de conversão</p>
                                                        <div className="box-divider m0 mt10 mb10 w-100"></div>
                                                    </div>
                                                    <div className="box p-3">
                                                        
                                                        {
                                                            this.state.list_sms.map((value, i) => 

                                                                <div key={i} className="py-2 b-b">
                                                                    <div className="float-right lh mt10">
                                                                        <span className="text-md text-primary f700 d-block">{value.sms_conversion_rate}% </span>
                                                                        <br/>
                                                                        <span className="f10 d-block">de conversão</span>
                                                                    </div>
                                                                    <span className="_600 d-block text-info"> {value.sms_name}</span>
                                                                    <small className="">{value.sms_pitch}</small>
                                                                </div>

                                                            )
                                                        }

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <FeedMain />
                    </div>
                    <FooterMain />
                </div>
            </Fragment>
        );
    }
}

export default withRouter(LeadsConversion);