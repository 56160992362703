import React, { Component } from 'react';
import PeriodBoxTool from '../Structure/PeriodBoxTool';

class SummaryBusinessScheduledVisits extends Component {
    constructor(props){
        super(props);
        this.cardCustom = false;
        this.props.fields.periodName = this.props.periodName
    }

    handleChange = (periodName, values) => {
        this.cardCustom = true
        this.props.fields.count = values.count
        this.props.fields.clicks_count = values.clicks_count
        this.props.fields.percentage_meta_clicks_current = values.percentage_meta_clicks_current
        this.props.fields.percentage_meta_clicks_previous_period_reached = values.percentage_meta_clicks_previous_period_reached
        this.props.fields.conversions_count = values.conversions_count
        this.props.fields.percentage_meta_conversions_current = values.percentage_meta_conversions_current
        this.props.fields.percentage_meta_conversions_previous_period_reached = values.percentage_meta_conversions_previous_period_reached
        this.props.fields.periodName = periodName
        this.forceUpdate();
    }

    render(){
        (this.cardCustom === false)?(
            this.props.fields.periodName = this.props.periodName
        ):(
            this.cardCustom = false
        )
        return (
            <div className="col-6 col-sm-12 col-md-3 col-lg-3">
                <div className="box p-3 box-shadow2">
                    <div className="d-flex">
                        <span className="text-muted">Visitas agendadas</span>
                    </div>
                    <div className="py-3 text-center text-lg text-warn">
                        {this.props.fields.percentage}%
                    </div>
                    <div className="progress my-1" style={{height:"6px"}}>
                        <div className="progress-bar warning" style={{width: this.props.fields.percentage_meta_conversions_current +"%"}}></div>
                    </div>
                    <div className="d-flex">
                        <span className="flex text-muted">
                            {this.props.fields.percentage_meta_conversions_current}% da meta
                        </span>
                        <span title="Período anterior">
                            <i className="fa fa-caret-up text-success"></i> {this.props.fields.percentage_meta_promotional_campaigns_previous_period_reached}%
                        </span>
                    </div>
                    <PeriodBoxTool onChange={this.handleChange} type={this.props.type} comment_box={true}/>
                </div>
            </div>
        );
    }
}

export default SummaryBusinessScheduledVisits;