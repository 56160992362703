import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'

import $ from "jquery"
 
import MenuMain from '../../Structure/MenuMain';
import HeaderMain from '../../Structure/HeaderMain';
import FooterMain from '../../Structure/FooterMain';

import BusinessCompetitors from './Components/BusinessCompetitors';
import BusinessMidiaPlan from './Components/BusinessMidiaPlan';
import BusinessSalesControl from './Components/BusinessSalesControl';
import BusinessGoals from './Components/BusinessGoals';
import BusinessVisit from './Components/BusinessVisit';
import BusinessBid from './Components/BusinessBid';
import BusinessPos from './Components/BusinessPos';
import BusinessDeal from './Components/BusinessDeal';
import BusinessConfigs from './Components/BusinessConfigs';
import BusinessSocialNetworks from './Components/BusinessSocialNetworks';
import BusinessLeads from './Components/BusinessLeads';

class AdminBusinessEdit extends Component{
    constructor(props){
        super(props)
        this.translate = this.props.translate
    }

    toggleCollapse = (selector) => $(selector).collapse("toggle")

    render() {
        return (
            <React.Fragment>
                <MenuMain />
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    <HeaderMain title={this.translate("ADMIN_BUSINESS_TITLE")} onChange={this.handleChange}/>
                    <div className="content-main admin" id="content-main">
                        <div className="padding">
                            <div className="row pt20">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">

                                    <ul className="nav nav-pills nav-sm mb10" id="admin_tab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-target="#tab_goals_business" data-toggle="tab" href="#tab_goals_business" role="tab" aria-controls="goals" aria-selected="true">{this.translate("ADMIN_BUSINESS_EDIT_GOALS")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-target="#tab_midia_business" data-toggle="tab" href="#tab_midia_business" role="tab" aria-controls="midia" aria-selected="true">{this.translate("ADMIN_BUSINESS_EDIT_MEDIA")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-target="#tab_visit_business" data-toggle="tab" href="#tab_visit_business" role="tab" aria-controls="visit" aria-selected="true">{this.translate("ADMIN_BUSINESS_EDIT_VISIT")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-target="#tab_bid_business" data-toggle="tab" href="#tab_bid_business" role="tab" aria-controls="bid" aria-selected="true">{this.translate("ADMIN_BUSINESS_EDIT_BID")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-target="#tab_deal_business" data-toggle="tab" href="#tab_deal_business" role="tab" aria-controls="deal" aria-selected="true">{this.translate("ADMIN_BUSINESS_EDIT_DEAL")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-target="#tab_sales_business" data-toggle="tab" href="#tab_sales_business" role="tab" aria-controls="sales" aria-selected="true">{this.translate("ADMIN_BUSINESS_EDIT_SALES")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-target="#tab_pos_business" data-toggle="tab" href="#tab_pos_business" role="tab" aria-controls="pos" aria-selected="true">{this.translate("ADMIN_BUSINESS_EDIT_POS_BUSINESS")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-target="#tab_competitors_business" data-toggle="tab" href="#tab_competitors_business" role="tab" aria-controls="competitors" aria-selected="true">{this.translate("ADMIN_BUSINESS_EDIT_COMPETITORS")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-target="#tab_social_networks_business" data-toggle="tab" href="#tab_social_networks_business" role="tab" aria-controls="social_networks" aria-selected="true">{this.translate("ADMIN_BUSINESS_EDIT_NETWORKS")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-target="#tab_leads_business" data-toggle="tab" href="#tab_leads_business" role="tab" aria-controls="lead" aria-selected="true">{this.translate("ADMIN_BUSINESS_EDIT_LEADS")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-target="#tab_configs_business" data-toggle="tab" href="#tab_configs_business" role="tab" aria-controls="config" aria-selected="true">{this.translate("ADMIN_BUSINESS_EDIT_CONFIG")}</a>
                                        </li>
                                    </ul>

                                    <div className="tab-content" id="admin_tab_content">
                                        <div className="tab-pane pb40 fade show active p-3 white box-shadow" id="tab_goals_business" role="tabpanel" aria-labelledby="">
                                            <BusinessGoals toggleCollapse={this.toggleCollapse} translate={this.translate} idBusiness={document.location.hash.replace('#','')}/>
                                        </div>
                                        <div className="tab-pane pb40 fade p-3 white box-shadow" id="tab_midia_business" role="tabpanel" aria-labelledby="">
                                            <BusinessMidiaPlan toggleCollapse={this.toggleCollapse} translate={this.translate} idBusiness={document.location.hash.replace('#','')}/>
                                        </div>
                                        <div className="tab-pane pb40 fade p-3 white box-shadow" id="tab_visit_business" role="tabpanel" aria-labelledby="">
                                            <BusinessVisit toggleCollapse={this.toggleCollapse} translate={this.translate} idBusiness={document.location.hash.replace('#','')}/>
                                        </div>
                                        <div className="tab-pane pb40 fade p-3 white box-shadow" id="tab_bid_business" role="tabpanel" aria-labelledby="">
                                            <BusinessBid toggleCollapse={this.toggleCollapse} translate={this.translate} idBusiness={document.location.hash.replace('#','')}/>
                                        </div>
                                        <div className="tab-pane pb40 fade p-3 white box-shadow" id="tab_deal_business" role="tabpanel" aria-labelledby="">
                                            <BusinessDeal toggleCollapse={this.toggleCollapse} translate={this.translate} idBusiness={document.location.hash.replace('#','')}/>
                                        </div>
                                        <div className="tab-pane pb40 fade p-3 white box-shadow" id="tab_sales_business" role="tabpanel" aria-labelledby="">
                                            <BusinessSalesControl toggleCollapse={this.toggleCollapse} translate={this.translate} idBusiness={document.location.hash.replace('#','')}/>
                                        </div>
                                        <div className="tab-pane pb40 fade p-3 white box-shadow" id="tab_pos_business" role="tabpanel" aria-labelledby="">
                                            <BusinessPos toggleCollapse={this.toggleCollapse} translate={this.translate} idBusiness={document.location.hash.replace('#','')}/>
                                        </div>
                                        <div className="tab-pane pb40 fade p-3 white box-shadow" id="tab_competitors_business" role="tabpanel" aria-labelledby="">
                                            <BusinessCompetitors toggleCollapse={this.toggleCollapse} translate={this.translate} idBusiness={document.location.hash.replace('#','')}/>
                                        </div>
                                        <div className="tab-pane pb40 fade p-3 white box-shadow" id="tab_social_networks_business" role="tabpanel" aria-labelledby="">
                                            <BusinessSocialNetworks toggleCollapse={this.toggleCollapse} translate={this.translate} idBusiness={document.location.hash.replace('#','')}/>
                                        </div>
                                        <div className="tab-pane pb40 fade p-3 white box-shadow" id="tab_leads_business" role="tabpanel" aria-labelledby="">
                                            <BusinessLeads toggleCollapse={this.toggleCollapse} translate={this.translate} idBusiness={document.location.hash.replace('#','')}/>
                                        </div>
                                        <div className="tab-pane pb40 fade p-3 white box-shadow" id="tab_configs_business" role="tabpanel" aria-labelledby="">
                                            <BusinessConfigs toggleCollapse={this.toggleCollapse} translate={this.translate} idBusiness={document.location.hash.replace('#','')}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterMain />
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(AdminBusinessEdit);