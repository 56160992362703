import React, {Component} from 'react';

import {getBusinessName} from '../Auth/AuthConfig';

export class ListBusiness extends Component{

    constructor(props){
        super(props);
        this.state = {
            business_name: getBusinessName()
        }
        this.translate = this.props.translate
    }

    render(){
        return(
            <ul className="navbar-nav mt-2 mt-lg-0 mx-0 mx-lg-2 text-hover-primary">
                <li>
                    <b>{this.translate('LIST_BUSINESS_BUSINESS')}:</b> {this.state.business_name}
                </li>
            </ul>
        )
    }
}

export default ListBusiness;