import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'

import MenuMain from '../../Structure/MenuMain';
import HeaderMain from '../../Structure/HeaderMain';
import FooterMain from '../../Structure/FooterMain';

import MarketingLogs from './Components/MarketingLogs';
import CommercialLogs from './Components/CommercialLogs';
import DevelopmentLogs from './Components/DevelopmentLogs';


class Logs extends Component{

    render() {
        return (
            <React.Fragment>
                <MenuMain />
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    <HeaderMain title="Logs" onChange={this.handleChange}/>
                    <div className="content-main" id="content-main">
                        <div className="padding">
                            <div className="row pt20">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">

                                    <ul className="nav nav-pills nav-sm mb10" id="logs_tab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-target="#tab_logs_marketing" data-toggle="tab" href="#tab_logs_marketing" role="tab" aria-controls="marketing" aria-selected="true">Marketing</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-target="#tab_logs_commercial" data-toggle="tab" href="#tab_logs_commercial" role="tab" aria-controls="commercial" aria-selected="true">Commercial</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-target="#tab_logs_development" data-toggle="tab" href="#tab_logs_development" role="tab" aria-controls="development" aria-selected="true">Desenvolvimento</a>
                                        </li>
                                    </ul>

                                    <div className="tab-content" id="logs_tab_content">
                                        <div className="tab-pane pb40 fade show active p-3 white box-shadow" id="tab_logs_marketing" role="tabpanel" aria-labelledby="">
                                            <MarketingLogs />
                                        </div>
                                        <div className="tab-pane pb40 fade show p-3 white box-shadow" id="tab_logs_commercial" role="tabpanel" aria-labelledby="">
                                            <CommercialLogs />
                                        </div>
                                        <div className="tab-pane pb40 fade show p-3 white box-shadow" id="tab_logs_development" role="tabpanel" aria-labelledby="">
                                            <DevelopmentLogs />
                                        </div>
                                    </div>
        
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterMain />
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(Logs);