import React, { Component, Fragment } from 'react';

import $ from 'jquery';

import api from '../../../Services/Api';

class AddDelay extends Component {
    //_isMounted = false;

    constructor(props){
        super(props)
        this.state = {
            idAction: this.props.values.idAction,
            loaded: false,
            alertResponse: null,
            formValuesAction: {
                time_value: null,
                period_type: null
            }
        }
        this.formSubmitSave = this.formSubmitSave.bind(this)
        this.translate = this.props.translate
    }

    componentDidMount(){
        api.get('/journey/action/' + this.props.values.idAction).then(async response => {
            this.setState({formValuesAction: response.data, loaded: true})
            console.log(response);
        })
    }

    /*
    componentWillUnmount() {
        this._isMounted = false;
    }
    */

    async formSubmitSave(e){
        e.preventDefault()
        $(`#button_submit_form_action`).prop(`disabled`, true).html(`<i className="fa fa-spinner"></i> ${this.translate('BUTTON_WAIT')}...`)
        this.setState({alertResponse: null})
        const data = this.state.formValuesAction
        try{
            const response = await api.post("/journey/action/" + this.props.values.idAction, data);
            if(response.data.success){
                this.setState({alertResponse: true})
                $(`#button_submit_form_action`).prop(`disabled`, false).html(`<i className="fa fa-save"></i> ${this.translate('BUTTON_REGISTER')}`)
            }else{
                this.setState({alertResponse: false})
            }
        }catch(err){
            console.log(err)
        }
    }

    render() {
        return (
            <Fragment>
                {
                    (this.state.alertResponse === false)?(
                        <div className="alert alert-danger alert-dismissible" role="alert">
                            {this.translate('ADD_DELAY_ERROR_REGISTERING_ACTION')}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    ):(
                        <React.Fragment>
                            {
                                (this.state.alertResponse)?(
                                    <div className="alert alert-success alert-dismissible" role="alert">
                                        {this.translate('ADD_DELAY_SUCCESS_REGISTERING_ACTION')}
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                ):(false)
                            }
                        </React.Fragment>
                    )
                }
                {
                    (this.state.loaded)?(
                        <form onSubmit={this.formSubmitSave}>
                            <div className="form-group">
                                <label>{this.translate('ADD_DELAY_AMOUNT_OF_TIME')}</label>
                                <input type="text" onChange={e => this.setState({formValuesAction: {...this.state.formValuesAction, time_value:  e.target.value}})} className="form-control" placeholder={this.translate('ADD_DELAY_AMOUNT_OF_TIME')} defaultValue={this.state.formValuesAction.time_value} required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="trigger_add_delay_period_type">{this.translate('ADD_DELAY_DELAY_PERIOD')}</label>
                                <select onChange={e => this.setState({formValuesAction: {...this.state.formValuesAction, period_type:  e.target.value}})} defaultValue={this.state.formValuesAction.period_type} className="form-control cool-select" id="trigger_add_delay_period_type" required>
                                    <option value="">{this.translate('ADD_DELAY_OPTION_SELECT_TIME_MEASUREMENTS')}</option>
                                    <option value="seconds">{this.translate('ADD_DELAY_OPTION_SECONDS')}</option>
                                    <option value="minutes">{this.translate('ADD_DELAY_OPTION_MINUTES')}</option>
                                    <option value="hours">{this.translate('ADD_DELAY_OPTION_HOURS')}</option>
                                    <option value="days">{this.translate('ADD_DELAY_OPTION_DAYS')}</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <button type="submit" id="button_submit_form_action" className="btn btn-cursor-pointer btn-success float-right" style={{width: '100%'}}>
                                    <i className="fa fa-save"></i> {this.translate('BUTTON_REGISTER')}
                                </button>
                            </div>
                        </form>
                    ):(
                        <div>
                            {this.translate('ADD_DELAY_LOADING_CONFIG_FORM')}
                        </div>
                    )
                }
            </Fragment>
        );
    }
}

export default AddDelay;