import React, { Component, Fragment } from 'react';
import {withRouter} from 'react-router-dom';

import MenuMain from '../../Structure/MenuMain';
import HeaderMain from '../../Structure/HeaderMain';
import FooterMain from '../../Structure/FooterMain';

import UserRegister from './Components/UserRegister';
import UserList from './Components/UserList';

class AdminUsers extends Component {

    constructor(props){
        super(props);
        this.translate = this.props.translate

        document.title =  this.translate("ADMIN_COMPANY_TITLE")
    }

    componentDidMount(){
        console.log(this.props);
        const link = document.querySelector('a[data-target="'+ document.location.hash +'"]')
        if(link){
            link.click();
        }
    }

    render() {
        return (
            <Fragment>
                <MenuMain />
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    <HeaderMain title={this.translate("ADMIN_USERS_TITLE")} onChange={this.handleChange}/>
                    <div className="content-main admin" id="content-main">
                        <div className="padding">
                            <div className="row pt20">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">

                                    <ul className="nav nav-pills nav-sm mb10" id="admin_tab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-target="#tab_list_user" data-toggle="tab" href="#tab_list_user" role="tab" aria-controls="list" aria-selected="false">{this.translate("ADMIN_USERS_LIST_USERS")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="click_tab_register_user" data-target="#tab_register_user" data-toggle="tab" href="#tab_register_user" role="tab" aria-controls="register" aria-selected="true">{this.translate("ADMIN_USERS_REGISTER_USER")}</a>
                                        </li>
                                    </ul>

                                    <div className="tab-content" id="admin_tab_content">
                                        <div className="tab-pane pb40 fade show active p-3 white box-shadow" id="tab_list_user" role="tabpanel" aria-labelledby="">
                                            <UserList translate={this.translate}/>
                                        </div>
                                        <div className="tab-pane pb40 fade p-3 white box-shadow" id="tab_register_user" role="tabpanel" aria-labelledby="">
                                            <UserRegister translate={this.translate}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterMain />
                </div>
            </Fragment>
        );
    }
}

export default withRouter(AdminUsers);