import React, { Component } from 'react'


export default class AddStage extends Component{
    
    render = () => 
        <div className="col-12">
            <div className=" dark text-left w-100 box mt20 mb10 btn cursor-pointer btn-cursor-pointer px-2 py-2 d-flex align-items-center" onClick={e=>this.props.handleAddStage()}>
                <i className="text-success fa fa-plus text-md py-1" />&nbsp;
                <span>{this.props.translate("DASHBOARD_PERFORMANCE_CARD_ADD_STAGE")}</span>
            </div>
        </div>
        
    
}
