import React, { Component, Fragment } from 'react';
import {withRouter} from 'react-router-dom'

import $ from 'jquery'

import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4'

import api from '../../Services/Api';
import {ajaxUrl, ajaxToken} from '../../Services/Ajax';

import Modal from 'react-bootstrap/Modal';

import MenuMain from '../../Structure/MenuMain';
import HeaderMain from '../../Structure/HeaderMain';
import FooterMain from '../../Structure/FooterMain';

class Journey extends Component{

    constructor(props){
        super(props)
        this.translate = this.props.translate
        document.title = this.translate('JOURNEY_RELATIONSHIP_RULER_LIST')
        this.state = {
            tableLoadJourney: null,
            modalCreateJourney: false,
            formCreateJourney: {
                name: null,
                ruler_type: null,
                funnel_position: null,
                final_date: null,
                pitch_relationship_ruler: null,
            }
        }
        this.showModalCreateJourney = this.showModalCreateJourney.bind(this)
        this.closeModalCreateJourney = this.closeModalCreateJourney.bind(this)
        this.formSubmitCreateJourney = this.formSubmitCreateJourney.bind(this)
        this.reloadDatatableJourney = this.reloadDatatableJourney.bind(this)
    }

    showModalCreateJourney(){
        this.setState({modalCreateJourney: true})
    }

    closeModalCreateJourney(){
        this.setState({modalCreateJourney: false})
    }

    reloadDatatableJourney(){
        $('#list_journey').DataTable().ajax.reload();
    }

    async formSubmitCreateJourney(e){
        e.preventDefault();
        
        $('#journey_button_submit').prop('disabled', true).html(`<i className="fa fa-spinner"></i> ${this.translate('JOURNEY_WAIT')}`)
        
        const data = this.state.formCreateJourney;
        try{
            const response = await api.post("/journey/create", {data});
            if(response.data.success === true){
                this.reloadDatatableJourney()
                this.closeModalCreateJourney();
                $(`#journey_button_submit`).prop(`disabled`, false).html(`<i className="fa fa-save"></i> ${this.translate('JOURNEY_REGISTER')}`)
            }else{
                this.setState({msgResponseShow: response.data.msg});
            }
        }catch(err){
            this.setState({msgResponseShow: err});
        }
    }

    componentDidMount(){

        let tableLoadJourney = this.state.tableLoadJourney
        const props = this.props;
        console.log(props)

        tableLoadJourney = $('#list_journey').DataTable({
            "searching": true,
            "paging": false,
            "info": false,
            "responsive": true,
            "language": {
                "url": this.translate('DATATABLE_LANGUAGE_URL')
            },
            "ajax": {
                "url": ajaxUrl('/journey/table'),
                "type": 'GET',
                "beforeSend": function (request) {
                    request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                }
            },
            "columnDefs": [{
                "targets": -1,
                "data": null,
                "defaultContent": 
                    `<div class="btn-group btn-shadow">
                        <button class="view btn btn-sm purple btn-cursor-pointer" title="${this.translate('JOURNEY_EDIT')}">
                            <i class="fa fa-cogs" title="${this.translate('JOURNEY_EDIT')}"></i>
                        </button>
                    </div>`
            }]
        });

        $('#list_journey tbody').on( 'click', '.view', function (e){
            
            e.preventDefault();

            let data = [];
            
            if($(e.target).parents('tr').hasClass('child')){
                data = tableLoadJourney.row($(e.target).parents('tr').prev('.parent')).data();
            }else{
                data = tableLoadJourney.row($(e.target).parents('tr')).data();
            }
            
            this.props.history.push('/journey_desk#' + data[6]);
            
        }.bind(this));

    }

    render(){
        return(
            <Fragment>
                <MenuMain />
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    <HeaderMain title={this.translate('JOURNEY_RELATIONSHIP_RULER_LIST')} onChange={this.handleChange}/>
                    <div className="content-main row row-no-margin" id="content-main">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" id="conteudo">
                            <div className="row text-right mb-3 p-2">
                                <div className="col-md-12">
                                    <button className="btn btn-fw amber btn-rounded mt20 pull-right btn-shadow btn-cursor-pointer" onClick={this.showModalCreateJourney}>
                                        <i className="fa fa-plus"></i> {this.translate('JOURNEY_NEW_RELATIONSHIP_RULER')}
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="box p-2">
                                        <table id="list_journey" className="table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                                            <thead>
                                                <tr className="text-left">
                                                    <th>{this.translate('JOURNEY_TABLE_NAME')}</th>
                                                    <th>{this.translate('JOURNEY_TABLE_CREATED')}</th>
                                                    <th>{this.translate('JOURNEY_TABLE_CHANGED')}</th>
                                                    <th>{this.translate('JOURNEY_TABLE_RULER_TYPE')}</th>
                                                    <th>{this.translate('JOURNEY_TABLE_FUNNEL_STAGE')}</th>
                                                    <th>{this.translate('TABLE_STATUS_ROW')}</th>
                                                    <th>{this.translate('TABLE_ACTIONS_ROW')}</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterMain />
                </div>
                <Modal show={this.state.modalCreateJourney} onHide={this.closeModalCreateJourney} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                    <div className="d-flex flex-column h-100 pb-5">
                        <div className="p-3 b-b d-flex no-shrink warning text-center">
                            <h5 className="h5 m-0 text-center width100 line-height-38px">
                                <i className="fa fa-plus"></i> {this.translate('JOURNEY_NEW_RELATIONSHIP_RULER')}
                            </h5>
                            <button onClick={this.closeModalCreateJourney} className="btn btn-transparent ml-auto">
                                <span className="text-lg l-h-1x">×</span>
                            </button>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-6 offset-md-3">

                                <form onSubmit={this.formSubmitCreateJourney}>
                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('JOURNEY_LABEL_INTERNAL_RULER_CONTROL_NAME')}:</label>
                                        <input type="text" onChange={e => this.setState({ formCreateJourney: {...this.state.formCreateJourney, name:  e.target.value}})} className="form-control box-shadow cool-input" placeholder={this.translate('JOURNEY_PLACEHOLDER_INTERNAL_RULER_CONTROL_NAME')} required="required" />
                                    </div>
                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('JOURNEY_LABEL_RELATIONSHIP_RULER_PITCH')}:</label>
                                        <input type="text" onChange={e => this.setState({ formCreateJourney: {...this.state.formCreateJourney, pitch_relationship_ruler:  e.target.value}})} className="form-control box-shadow cool-input" placeholder={this.translate('JOURNEY_PLACEHOLDER_RELATIONSHIP_RULER_PITCH')} required="required" />
                                    </div>
                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('JOURNEY_LABEL_RELATIONSHIP_RULER_TYPE')}:</label>
                                        <select onChange={e => this.setState({ formCreateJourney: {...this.state.formCreateJourney, ruler_type:  e.target.value}})} className="form-control box-shadow cool-select" required="required">
                                            <option value="">{this.translate('JOURNEY_OPTION_SELECT_THE_TYPE')}</option>
                                            <option value="lead">{this.translate('JOURNEY_OPTION_LEAD')}</option>
                                            <option value="suspect">{this.translate('JOURNEY_OPTION_SUSPECT')}</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('JOURNEY_LABEL_FUNNEL_POSITION')}:</label>
                                        <select onChange={e => this.setState({ formCreateJourney: {...this.state.formCreateJourney, funnel_position:  e.target.value}})} className="form-control box-shadow cool-select" required="required">
                                            <option value="">{this.translate('JOURNEY_OPTION_SELECT_FUNNEL_POSITION')}</option>
                                            <option value="marketing_funnel_target_market">Funil MKT - Mercado Alvo</option>
                                            <option value="marketing_funnel_reach">Funil MKT - Alcance</option>
                                            <option value="marketing_funnel_segmentation">Funil MKT - Segmentação</option>
                                            <option value="marketing_funnel_suspect">Funil MKT - Suspect</option>
                                            <option value="marketing_funnel_journey">Funil MKT - Jornada</option>
                                            <option value="marketing_funnel_lead">Funil MKT - Lead</option>
                                            <option value="sales_funnel_lead_attendance">Funil Venda - Lead / Atendimento</option>
                                            <option value="sales_funnel_visit">Funil Venda - Visita</option>
                                            <option value="sales_funnel_bid">Funil Venda - Proposta</option>
                                            <option value="sales_funnel_deal">Funil Venda - Negociação</option>
                                            <option value="sales_funnel_buy">Funil Venda - Compra</option>
                                            <option value="sales_funnel_pos">Funil Venda - Compra Futura</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('JOURNEY_JOURNEY_FINAL_DATE')}:</label>
                                        <input type="datetime-local" onChange={e => this.setState({ formCreateJourney: {...this.state.formCreateJourney, final_date:  e.target.value}})} className="form-control box-shadow cool-input" placeholder={this.translate('JOURNEY_JOURNEY_FINAL_DATE')} />
                                    </div>
                                    <button type="submit" id="journey_button_submit" className="btn btn-fw warning btn-rounded mt20 pull-right btn-shadow px-5"><i className="fa fa-save"></i> {this.translate('JOURNEY_REGISTER')}</button>
                                </form>

                            </div>
                        </div>
                    </div>
                    </Modal.Body>
                </Modal>
            </Fragment>
        );
    }
}

export default withRouter(Journey);