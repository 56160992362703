import React, {Component} from 'react';

import {urlWebSocket} from '../Services/Config';

import {getBusinessCode} from '../Auth/AuthConfig';

import api from '../Services/Api';

export class FeedMain extends Component{
    
    constructor(props){
        super(props);
        this.state = {
            feed_values: [],
            feed_values_processed: [],
            count_interactions: 0,
        }
    }
    
    ws = new WebSocket(urlWebSocket)

    componentDidMount(){

        this.ws.onopen = () => {
            this.ws.send(JSON.stringify({
                action: 'connect',
                type: "viewers_feed",
                bC: getBusinessCode()
            }))
        }

        this.ws.onmessage = evt => {
            const message = JSON.parse(evt.data)
            const {feed_values} = this.state;
            feed_values.push(message)
            const feed_values_new = feed_values.splice(0, 16)
            this.setState({
                feed_values: feed_values_new.reverse(),
                count_interactions: this.state.count_interactions + 1
                //count_interactions: this.state.count_interactions + feed_values_new.length
            })
        }

        this.ws.onclose = () => {
            console.log('disconnected')
        }

        api.get('/feed/main/websocket')
            .then(response => {
                const feed = response.data.feed;
                this.setState(
                    {
                        feed_values: feed.values,
                        count_interactions: this.state.count_interactions + feed.count_interactions
                    }
                );
            })
        
        api.get('/feed/main/processed')
            .then(response => {
                const feed = response.data.feed;
                console.log(feed)
                this.setState(
                    {
                        feed_values_processed: feed.values,
                        count_interactions: this.state.count_interactions + feed.count_interactions
                    }
                )
            })
    }

    loadFeedLink(value){
        let id = value.id
        let link
        if(value.lead_name === "Suspect"){
            link = `/suspects_list#${id}`
        } else{
            link = `/lead_Data#${id}`
        }
        return <a href={link} target="_blank" className="text-primary"rel="noopener noreferrer">{value.lead_name}</a>
    }

    render(){
        return(
            <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 no-gutters">
                <div className="row">
                    <div id="containerFeedBuylitics" className="col-md-12 white lt">
                        <div className="padding bg-blur_w3 position10">
                            <span className="badge info float-right">
                                <div id="feedBuyliticsCount">
                                    {this.state.count_interactions}
                                </div>
                            </span>
                            <h6 className="mb-3">
                                Feed
                            </h6>
                            <p className="f600">
                                <i className="fa fa-bar-chart"></i> Interações:
                            </p>
                            <div id="feedBuylitics" className="streamline streamline-dotted">
                                <React.Fragment>
                                    {
                                        this.state.feed_values.map(
                                            (value, i) => 
                                                <div key={value.id} data-id={value.id} className="listFeed sl-item  b- ">
                                                    <div className="sl-content">
                                                        <span className="sl-date text-muted">{value.hourly}</span>
                                                        <div>
                                                            {this.loadFeedLink(value)}
                                                            <br></br>
                                                            {value.info}
                                                        </div>
                                                    </div>
                                                </div>
                                        )
                                    }
                                    {
                                        this.state.feed_values_processed.map(
                                            (value, i) => 
                                                <div key={value.id} data-id={value.id} className="listFeed sl-item  b- ">
                                                    <div className="sl-content">
                                                        <span className="sl-date text-muted">{value.hourly}</span>
                                                        <div>
                                                
                                                            {this.loadFeedLink(value)} 
                                                            <br></br>
                                                            {value.info}
                                                        </div>
                                                    </div>
                                                </div>
                                        )
                                    }
                                </React.Fragment>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FeedMain;