export const urlBase = process.env.REACT_APP_API_URL_BASE;//"https://api.development.buylitics.in";

export const urlBaseLead = process.env.REACT_APP_DISPLAY_LEAD_URL_BASE;//"http://lead.development.buylitics.in";

export const attendanceUrlBase = process.env.REACT_APP_API_ATTENDANCE_URL_BASE;//"https://attendance.development.buylitics.in";

export const getUrlWebSocket = () => {
    return (window.location.protocol === 'https:')? process.env.REACT_APP_WEBSOCKET_MONITORING_URL_BASE_WSS: process.env.REACT_APP_WEBSOCKET_MONITORING_URL_BASE_WS; //'wss://dev.ii1.in/wss/': 'ws://dev.ii1.in/ws/';
};
export const urlWebSocket = getUrlWebSocket();

export const getAttendanceUrlWebSocket = () => {
    return (window.location.protocol === 'https:')? process.env.REACT_APP_WEBSOCKET_ATTENDANCE_URL_BASE_WSS: process.env.REACT_APP_WEBSOCKET_ATTENDANCE_URL_BASE_WS; //'wss://attendance.development.buylitics.in/wss/': 'ws://attendance.development.buylitics.in/ws/';
};
export const attendanceUrlWebSocket = getAttendanceUrlWebSocket();

export const versionBase = "/v1";
