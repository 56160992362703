import React, { Component } from 'react';
import Chart from 'chart.js';

import {app, hexToRGB} from '../Structure/Configs/Theme';

export class ChartMonthlyLeadsVisits extends Component {

    componentDidMount(){
        const myChart = document.getElementById('chart-monthly-leads-visits').getContext('2d');
        new Chart(myChart, {
            type: "bar",
            data: {
                labels: this.props.fields.suspects.months,
                datasets: [
                    {
                        label: 'Leads',
                        type: 'line',
                        data: this.props.fields.leads.values,
                        fill: true,
                        backgroundColor: hexToRGB(app.color.primary, 0.2),
                        borderColor: hexToRGB(app.color.primary, 1),
                        borderWidth: 2,
                        borderJoinStyle: 'miter',
                        pointBorderColor: hexToRGB(app.color.primary, 1),
                        pointBackgroundColor: '#fff',
                        pointBorderWidth: 2,
                        pointHoverRadius: 2,
                        pointHoverBackgroundColor: hexToRGB(app.color.primary, 1),
                        pointHoverBorderColor: '#fff',
                        pointHoverBorderWidth: 1,
                        pointRadius: 3
                    },
                    /*
                    {
                        label: 'Suspects',
                        type: 'line',
                        data: this.props.fields.suspects.values,
                        fill: false,
                        borderDash: [3, 3],
                        backgroundColor: hexToRGB(app.color.accent, 0.2),
                        borderColor: hexToRGB(app.color.accent, 1),
                        borderWidth: 2,
                        borderJoinStyle: 'miter',
                        pointBorderColor: '#fff',
                        pointBackgroundColor: hexToRGB(app.color.accent, 1),
                        pointBorderWidth: 2,
                        pointHoverRadius: 2,
                        pointHoverBackgroundColor: hexToRGB(app.color.accent, 1),
                        pointHoverBorderColor: '#fff',
                        pointHoverBorderWidth: 1,
                        pointRadius: 3
                    }
                    */
                ]
            },
            options: {}
        })

        const myChartSuspect = document.getElementById('chart-monthly-sales').getContext('2d');
        new Chart(myChartSuspect, {
            type: "bar",
            data: {
                labels: this.props.fields.suspects.months,
                datasets: [{
                        label: 'Suspects',
                        type: 'line',
                        data: this.props.fields.suspects.values,
                        fill: false,
                        borderDash: [3, 3],
                        backgroundColor: hexToRGB(app.color.accent, 0.2),
                        borderColor: hexToRGB(app.color.accent, 1),
                        borderWidth: 2,
                        borderJoinStyle: 'miter',
                        pointBorderColor: '#fff',
                        pointBackgroundColor: hexToRGB(app.color.accent, 1),
                        pointBorderWidth: 2,
                        pointHoverRadius: 2,
                        pointHoverBackgroundColor: hexToRGB(app.color.accent, 1),
                        pointHoverBorderColor: '#fff',
                        pointHoverBorderWidth: 1,
                        pointRadius: 3
                    }
                ]
            },
            options: {}
        })

    }

    render() {
        return (
            <div className="col-md-6 col-sm-12">
                <div className="padding bg-blur_w position10">
                    <div className="d-flex">
                        <p className="uppercase text-muted f10">Leads</p>
                    </div>
                    <canvas id="chart-monthly-leads-visits" width={624} height={300} className="chartjs-render-monitor position10"/>
                </div>
            </div>
        )
    }
}

export default ChartMonthlyLeadsVisits;