import React, {Component} from "react"

//import $ from "jquery"
//import Modal from 'react-bootstrap/Modal';
import api from '../../../Services/Api';

export default class AttendanceLog extends Component{
    constructor(props){
        super(props)
        this.state = {
            logs: [],
            isLoadingLogs: false
        }
    }

    loadAttendanceLogs = async () => {
        this.setState({isLoadingLogs: true})
        try {
            let response = await api.get(`/attendance/get/logs/${this.props.idAttendance}`)
            console.log(response.data)
            if(response && response.data && response.data.logs && response.data.logs.length){
                this.setState({logs: response.data.logs})
            }

            this.loadAttendanceLogsImages()

        } catch (error) {
            console.log(error)
        }
        this.setState({isLoadingLogs: false})
    }

    loadAttendanceLogsImages = async () => {
        this.setState({isLoadingLogs: true})
        try {
            let response = await api.get(`/attendance/get/images/logs/${this.props.idAttendance}`)
            console.log(response.data)
            if(response && response.data && response.data.logs && response.data.logs.length){
                let {logs} = this.state
                response.data.logs.map((log) => {
                    logs.push(log)
                })
                this.setState({logs})
            }
        } catch (error) {
            console.log(error)
        }
        this.setState({isLoadingLogs: false})
    }

    componentDidMount = () => {
        this.loadAttendanceLogs()
    }

    render = () => (
        <div className="col">
            <div className="row">
                <div className="col">
                    <div className="row">
                        <div className="col attendance_main_tab">
                            <span className="main_tab_title">{this.props.translate("ATTENDANCE_LOGS_ATTENDANCE_LOG")}</span>
                        </div>
                    </div>
                    <div className="row p-0">
                        <div id="accordion_attendance_log" className="accordion col px-0 my-2">
                            {this.state.logs && this.state.logs.length ? 
                                this.state.logs.map((log, index)=>

                                    <div className="card bg-transparent" key={`${index}date`}>
                                        <div className="card-header btn-cursor-pointer bg-transparent" id={`attendance_logs_option_${index}`} onClick={()=>this.props.toggleAccordionPannel(`#collapse_attendance_logs_options_${index}`)} aria-expanded="true" aria-controls={`collapse_attendance_logs_options_${index}`}>
                                            <h6 className="mb-0 text-left collapsed">
                                                {log.date}
                                                <span className="float-right">
                                                    <i className="fa fa-caret-down"></i>
                                                </span>
                                            </h6>
                                        </div>
                                        <div id={`collapse_attendance_logs_options_${index}`} className="collapse show" aria-labelledby={`attendance_logs_option_${index}`} data-parent="#accordion_attendance_log">
                                            <div className="card-body text-left">
                                                {log.logs.map(dailyLog=>
                                                    <React.Fragment key={`${index}${dailyLog.id}`}>
                                                        <div className="col-12 justify-content-around">
                                                            <h5>{dailyLog.title}</h5>
                                                            <p>{dailyLog.description}</p>
                                                            {
                                                                dailyLog.image && (
                                                                    <img src={`${dailyLog.image}`} className={`img-fluid`} />
                                                                )
                                                            }
                                                        </div>
                                                        <hr />
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )
                            :
                                this.state.isLoadingLogs ?
                                    <div><i className="fa fa-spinner fa-spin" /> {this.props.translate('ICON_LOADING')}</div>
                                :
                                    <p className="my-2 mx-auto text-center">{this.props.translate("ATTENDANCE_LOGS_NO_LOGS")}</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}