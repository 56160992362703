import React, { Component, Fragment } from 'react';
import {withRouter} from 'react-router-dom'

import api from '../../Services/Api';

import MenuMain from '../../Structure/MenuMain';
import HeaderMain from '../../Structure/HeaderMain';
import FooterMain from '../../Structure/FooterMain';

class Contents extends Component {

    constructor(props){
        super(props);
        this.translate = this.props.translate
        document.title = this.translate('CONTENT_CONTENT')
        this.state = {
            counts: {
                landingsCreateCount: 0,
                landingsActiveCount: 0,
                emailCreateCount: 0,
                emailActiveCount: 0,
                bannersCreateCount: 0,
                bannersActiveCount: 0,
                smsCreateCount: 0,
                smsActiveCount: 0,
            }
        }
    }

    async loadCounts(){
        try{
            const response = await api.get("/contents/count_all");
            if(response.data.success === true){
                this.setState({
                    counts: response.data
                })
            }
        }catch(err){
            console.log(err)
        }
    }

    componentDidMount(){
        this.loadCounts();
    }

    render() {
        return (
            <Fragment>
                <MenuMain />
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    <HeaderMain title={document.title} onChange={this.handleChange}/>
                    <div className="content-main row row-no-margin" id="content-main">
                        <div className=" col-lg-12 col-md-12 col-sm-12 col-xs-12" id="conteudo">
                            <div className="">
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <div className="box text-center box-shadow border-radius">
                                            <div className="p-4">
                                                <p className="f84 m0 text-blue">
                                                    <i className="fa fa-columns text-shadow"></i>
                                                </p>
                                                <a href="landing_page_list" className="text-md f24 d-block f600">
                                                    {this.translate('CONTENT_LANDING_PAGE')}
                                                </a>
                                                <a href="landing_page_list" className="btn btn-outline btn-rounded b-primary text-blue mt20 btn-shadow">
                                                    <i className="fa fa-plus"></i> {this.translate('CONTENT_VIEW_MORE')}
                                                </a>
                                            </div>
                                            <div className="row row-col no-gutters b-t rounded-bottom text-center">
                                                <div className="col-6 b-r">
                                                    <a href="landing_page_list" className="py-3 d-block" data-toggle-class="">
                                                        <strong className="d-block f32 text-blue mb0 lh mb10">
                                                            <i className="fa fa-list"></i>
                                                        </strong>
                                                        <strong className="d-block f20 fwhite mb0 lh">
                                                            {this.state.counts.landingsCreateCount}
                                                        </strong>
                                                        <span className="d-block">
                                                            {this.translate('CONTENT_CREATED')}
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="col-6">
                                                    <a href="landing_page_list" className="py-3 d-block" data-toggle-class="">
                                                        <strong className="d-block f32 text-blue mb0 lh mb10">
                                                            <i className="fa fa-check"></i>
                                                        </strong>
                                                        <strong className="d-block f20 fwhite mb0 lh">
                                                            {this.state.counts.landingsActiveCount}
                                                        </strong>
                                                        <span className="d-block">
                                                            {this.translate('CONTENT_ACTIVE')}
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <div className="box text-center box-shadow border-radius">
                                            <div className="p-4">
                                                <p className="f84 m0 text-warning">
                                                    <i className="fa fa-envelope text-shadow"></i>
                                                </p>
                                                <a href="email_marketing_list" className="text-md f24 d-block f600">
                                                    {this.translate('CONTENT_EMAIL_MARKETING')}
                                                </a>
                                                <a href="email_marketing_list" className="btn btn-outline btn-rounded b-warning text-warning mt20 btn-shadow">
                                                    <i className="fa fa-plus"></i> {this.translate('CONTENT_VIEW_MORE')}
                                                </a>
                                            </div>
                                            <div className="row row-col no-gutters b-t rounded-bottom text-center">
                                                <div className="col-6 b-r">
                                                    <a href="email_marketing_list" className="py-3 d-block" data-toggle-class="">
                                                        <strong className="d-block f32 text-warning mb0 lh mb10">
                                                            <i className="fa fa-list"></i>
                                                        </strong>
                                                        <strong className="d-block f20 fwhite mb0 lh">
                                                            {this.state.counts.emailCreateCount}
                                                        </strong>
                                                        <span className="d-block">
                                                            {this.translate('CONTENT_CREATED')}
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="col-6">
                                                    <a href="email_marketing_list" className="py-3 d-block" data-toggle-class="">
                                                        <strong className="d-block f32 text-warning mb0 lh mb10">
                                                            <i className="fa fa-check"></i>
                                                        </strong>
                                                        <strong className="d-block f20 fwhite mb0 lh">
                                                            {this.state.counts.emailActiveCount}
                                                        </strong>
                                                        <span className="d-block">
                                                            {this.translate('CONTENT_ACTIVE')}
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <div className="box text-center box-shadow border-radius">
                                            <div className="p-4">
                                                <p className="f84 m0 text-danger">
                                                    <i className="fa fa-window-maximize text-shadow"></i>
                                                </p>
                                                <a href="banner_list" className="text-md f24 d-block f600">
                                                    Banners
                                                </a>
                                                <a href="banner_list" className="btn btn-outline btn-rounded b-danger text-danger mt20 btn-shadow">
                                                    <i className="fa fa-plus"></i> {this.translate('CONTENT_VIEW_MORE')}
                                                </a>
                                            </div>
                                            <div className="row row-col no-gutters b-t rounded-bottom text-center">
                                                <div className="col-6 b-r">
                                                    <a href="banner_list" className="py-3 d-block" data-toggle-class="">
                                                        <strong className="d-block f32 text-danger mb0 lh mb10">
                                                            <i className="fa fa-list"></i>
                                                        </strong>
                                                        <strong className="d-block f20 fwhite mb0 lh">
                                                            {this.state.counts.bannersCreateCount}
                                                        </strong>
                                                        <span className="d-block">
                                                            {this.translate('CONTENT_CREATED')}
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="col-6">
                                                    <a href="banner_list" className="py-3 d-block" data-toggle-class="">
                                                        <strong className="d-block f32 text-danger mb0 lh mb10">
                                                            <i className="fa fa-check"></i>
                                                        </strong>
                                                        <strong className="d-block f20 fwhite mb0 lh">
                                                            {this.state.counts.bannersActiveCount}
                                                        </strong>
                                                        <span className="d-block">
                                                            {this.translate('CONTENT_ACTIVE')}
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <div className="box text-center box-shadow border-radius">
                                            <div className="p-4">
                                                <p className="f84 m0 text-warn">
                                                    <i className="fa fa-commenting-o text-shadow"></i>
                                                </p>
                                                <a href="sms_list" className="text-md f24 d-block f600">
                                                    {this.translate('CONTENT_SMS')}
                                                </a>
                                                <a href="sms_list" className="btn btn-outline btn-rounded b-warn text-warn mt20 btn-shadow">
                                                    <i className="fa fa-plus"></i> {this.translate('CONTENT_VIEW_MORE')}
                                                </a>
                                            </div>
                                            <div className="row row-col no-gutters b-t rounded-bottom text-center">
                                                <div className="col-6 b-r">
                                                    <a href="sms_list" className="py-3 d-block" data-toggle-class="">
                                                        <strong className="d-block f32 text-warn mb0 lh mb10">
                                                            <i className="fa fa-list"></i>
                                                        </strong>
                                                        <strong className="d-block f20 fwhite mb0 lh">
                                                            {this.state.counts.smsCreateCount}
                                                        </strong>
                                                        <span className="d-block">
                                                            {this.translate('CONTENT_CREATED')}
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="col-6">
                                                    <a href="sms_list" className="py-3 d-block" data-toggle-class="">
                                                        <strong className="d-block f32 text-warn mb0 lh mb10">
                                                            <i className="fa fa-check"></i>
                                                        </strong>
                                                        <strong className="d-block f20 fwhite mb0 lh">
                                                            {this.state.counts.smsActiveCount}
                                                        </strong>
                                                        <span className="d-block">
                                                            {this.translate('CONTENT_ACTIVE')}
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterMain />
                </div>
            </Fragment>
        );
    }
}

export default withRouter(Contents);