import React, {Component, Fragment} from 'react';
import MenuMain from '../../Structure/MenuMain';
import HeaderMain from '../../Structure/HeaderMain';
import FooterMain from '../../Structure/FooterMain';
import FeedMain from '../../Structure/FeedMain';
import {withRouter} from 'react-router-dom';

class LeadsMidia extends Component {
    constructor(props){
        super(props);
        document.title = 'Midia Leads'
        this.state = {
            list_active_campaigns: [],
            list_segmentations: [],
            list_origins: []
        }
    }

    componentDidMount(){
        const link = document.querySelector('a[data-target="'+ document.location.hash +'"]')
        if(link){
            link.click();
        }
    }
    
    returnClassElement(feedback_code){
        let classElement = null;
        switch(feedback_code){
            case 'positive':
                classElement = 'fa-thumbs-up'
                break;
            case 'negative':
                classElement = 'fa-thumbs-down'
                break;
            default:
                classElement = 'fa-stop'
                break;
        }
        return classElement;
    }

    returnColorElement(feedback_code){
        let colorElement = null;
        switch(feedback_code){
            case 'positive':
                colorElement = 'green'
                break;
            case 'negative':
                colorElement = 'red'
                break;
            default:
                colorElement = 'default'
                break;
        }
        return colorElement;
    }

    render() {
        return (
            <Fragment>
                <MenuMain />
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    <HeaderMain title="Midia Leads" onChange={this.handleChange}/>
                    <div className="content-main row row-no-margin" id="content-main">
                        <div className=" col-lg-10 col-md-10 col-sm-12 col-xs-12 no-gutters" id="leads">
                            
                        <div className="white bg b-b px-3">
                            <div className="row ">
                                <div className="col-sm-5 order-sm-1 text-center p0">
                                    <div className="py-4 clearfix nav-active-theme">
                                        <ul className="nav nav-pills nav-sm p-2 pb20 pt20">
                                            <li className="nav-item f700 w-100">
                                                <a className="nav-link f-700 text-left f20" href="/">Mídia</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-sm-7 order-sm-1 text-center p0">
                                    <div className="py-4 clearfix nav-active-theme ">
                                        <ul className="nav nav-pills nav-sm p-2 pb20 pt20 pull-right">
                                            <li className="nav-item">
                                                <a className="nav-link active" href="/" data-toggle="tab" data-target="#tab_1">
                                                    <i className="fa fa-bullhorn"></i> Campanhas ativas
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="/" data-toggle="tab" data-target="#tab_2">
                                                    <i className="fa fa-code-fork"></i> Segmentações
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="/" data-toggle="tab" data-target="#tab_3">
                                                    <i className="fa fa-share-square-o"></i> Origem
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                                
                        </div>

                            <div className="padding">
                                <div className="row">
                                    <div className="col-sm-12 col-lg-12">
                                        <div className="tab-content">

                                            <div className="tab-pane fade active show" id="tab_1">
                                                <div className="white p-3">
                                                    <div className="box-header pb0">
                                                        <h3> Campanhas Ativas</h3>
                                                        <p className="text-muted mt10">Listadas por ordem de conversão</p>
                                                        <div className="box-divider m0 mt10 mb10 w-100"></div>
                                                    </div>
                                                    <div className="list inset">
                                                        
                                                        {
                                                            this.state.list_segmentations.map((value, i) => 
                                                                <div className="list-item " data-id="item-2">
                                                                    <div className="list-body">
                                                                        <a href="/" className="item-title _500">
                                                                            {value.campaign_name}
                                                                        </a>
                                                                        <div className="text-sm text-muted">
                                                                            {value.campaign_pitch} 
                                                                        </div>
                                                                    </div>
                                                                    <div className="mt10">
                                                                        <span className="item-date text-xs text-muted">Feedback:</span>
                                                                    </div>
                                                                    <span className={"w-40 circle " + this.returnColorElement(value.campaign_feedback_code)}>
                                                                        <i class={"fa " + this.returnClassElement(value.campaign_feedback_code) +" f20 w-100 text-center"} style={{lineHeight: "35px"}}></i>
                                                                    </span>
                                                                </div>
                                                            )
                                                        }

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tab-pane fade" id="tab_1">
                                                <div className="white p-3">
                                                    <div className="box-header pb0">
                                                        <h3> Segmentações</h3>
                                                        <p className="text-muted mt10">Listadas por ordem de conversão</p>
                                                        <div className="box-divider m0 mt10 mb10 w-100"></div>
                                                    </div>
                                                    <div className="list inset">
                                                        
                                                        {
                                                            this.state.list_segmentations.map((value, i) => 
                                                                <div className="list-item " data-id="item-2">
                                                                    <div className="list-body">
                                                                        <a href="/" className="item-title _500">
                                                                            {value.segmentation_name}
                                                                        </a>
                                                                        <div className="text-sm text-muted">
                                                                            {value.segmentation_pitch} 
                                                                        </div>
                                                                    </div>
                                                                    <div className="mt10">
                                                                        <span className="item-date text-xs text-muted">Feedback:</span>
                                                                    </div>
                                                                    <span className={"w-40 circle " + this.returnColorElement(value.segmentation_feedback_code)}>
                                                                        <i class={"fa " + this.returnClassElement(value.segmentation_feedback_code) +" f20 w-100 text-center"} style={{lineHeight: "35px"}}></i>
                                                                    </span>
                                                                </div>
                                                            )
                                                        }

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tab-pane fade" id="tab_1">
                                                <div className="white p-3">
                                                    <div className="box-header">
                                                        <h3> Origem</h3>
                                                        <p className="text-muted mt10">Listadas por ordem de conversão</p>
                                                        <div className="box-divider m0 mt10 mb10 w-100"></div>
                                                    </div>
                                                    <div className="list inset">
                                                        
                                                        {
                                                            this.state.list_origins.map((value, i) => 
                                                                <div className="list-item " data-id="item-2">
                                                                    <div className="list-body">
                                                                        <a href="/" className="item-title _500">
                                                                            {value.origin_name}
                                                                        </a>
                                                                        <div className="text-sm text-muted">
                                                                            {value.origin_pitch} 
                                                                        </div>
                                                                    </div>
                                                                    <div className="mt10">
                                                                        <span className="item-date text-xs text-muted">Feedback:</span>
                                                                    </div>
                                                                    <span className={"w-40 circle " + this.returnColorElement(value.segmentation_feedback_code)}>
                                                                        <i class={"fa " + this.returnClassElement(value.origin_feedback_code) +" f20 w-100 text-center"} style={{lineHeight: "35px"}}></i>
                                                                    </span>
                                                                </div>
                                                            )
                                                        }

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <FeedMain />
                    </div>
                    <FooterMain />
                </div>
            </Fragment>
        );
    }
}

export default withRouter(LeadsMidia);