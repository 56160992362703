import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'

import $ from 'jquery';
import 'jquery-mask-plugin';

import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4'

import {ajaxUrl, ajaxToken} from '../../../Services/Ajax';
import api from '../../../Services/Api';

import Modal from 'react-bootstrap/Modal';

import autocomplete from '../../../Functions/Leads/AutoComplete'

class BusinessSalesControl extends Component{

    constructor(props){
        super(props)
        this.state = {
            modalAddSalesShow: false,
            modalResponseShow: false,
            modalInfoSales: false,
            msgResponseShow: false,
            tableLoadBusiness: null,
            formSalesRegister: {
                idBusiness: this.props.idBusiness,
                name: '',
                phone: '',
                email: '',
                cpf: '',
                buyers: [],
                unity: '',
                amount: '',
            },
            infoSales: {
                unity: null,
                amount: null,
                buyers: []
            },
            salesFileUpload: [],
            tableSalesCsv: {
                thValues:[],
                thValuesAux:[],
                thSelectOptions: ["name", "email", "cpf", "unity", "value"],
                salesJsonData:[]
            }
        }
        this.handleCloseModalAddSales =  this.handleCloseModalAddSales.bind(this)
        this.handleCloseModalResponse =  this.handleCloseModalResponse.bind(this)
        this.handleCloseModalInfoSales =  this.handleCloseModalInfoSales.bind(this)
        this.showModalInfoSales =  this.showModalInfoSales.bind(this)
        this.addNewBuyer = this.addNewBuyer.bind(this)
        this.addNewFieldMediaPlan = this.addNewFieldMediaPlan.bind(this)
        this.reloadDatatable = this.reloadDatatable.bind(this)
        this.handleKeyUp = this.handleKeyUp.bind(this)
        this.onChangeMoney = this.onChangeMoney.bind(this)

        this.getInfoSales = this.getInfoSales.bind(this)
        this.translate = this.props.translate
    }

    componentDidMount = async () => {
        let tableLoadBusiness = this.state.tableLoadBusiness
        const props = this.props
        tableLoadBusiness = $('#list_sales').DataTable({
            "searching": true,
            "paging": true,
            "info": true,
            "responsive": true,
            "language": {
                "url": this.translate("DATATABLE_LANGUAGE_URL")
            },
            "ajax": {
                "url": ajaxUrl('/business/' + props.idBusiness + '/sales_control/table'),
                "type": 'GET',
                "beforeSend": function (request) {
                    request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                }
            },
            "columnDefs": [{
                "targets": -1,
                "data": null,
                "defaultContent": '<div class="btn-group btn-shadow">\n'+
                                        `<button class="view btn btn-sm success" title="${this.translate("BUSINESS_SALES_CONTROL_VISUALIZE_BUTTON_TITLE")}">\n`+
                                            `<i class="fa fa-user-circle" title="${this.translate("BUSINESS_SALES_CONTROL_VISUALIZE_BUTTON")}"></i> ${this.translate("BUSINESS_SALES_CONTROL_VISUALIZE_BUTTON")}\n`+
                                        '</button>\n'+
                                    '</div>'
            }]
        });

        $('#list_sales tbody').on( 'click', '.view', function (event){
            event.preventDefault();
            let data = [];
            if($(event.target).parents('tr').hasClass('child')){
                data = tableLoadBusiness.row($(event.target).parents('tr').prev('.parent')).data();
            }else{
                data = tableLoadBusiness.row($(event.target).parents('tr')).data();
            }
            console.log(data)

            this.getInfoSales(data[4]);

        }.bind(this));

        var SPMaskBehavior = function (val){
            return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
        },
        spOptions = {
            onComplete: function(val){
                var phone = $('#phone-sales').val();
                this.setState({formSalesRegister: {...this.state.formSalesRegister, phone: phone}})
            }.bind(this),
            onKeyPress: function(val, e, field, options) {
                field.mask(SPMaskBehavior.apply({}, arguments), options);
            }
        };

        $('#phone-sales').mask(SPMaskBehavior, spOptions);
        $('#cpf-sales-control').mask('000.000.000-00');

    }

    async getInfoSales(id){
        try{
            const response = await api.get("/business/" + this.props.idBusiness + "/sales_control/information/" + id);
            console.log(response)

            const {infoSales} = this.state

            infoSales['amount'] = response.data.amount
            infoSales['unity'] = response.data.unity
            infoSales['buyers'] = response.data.buyers

            this.showModalInfoSales();
        }catch(err){
            this.setState({msgResponseShow: err});
        }
    }

    handleCloseModalAddSales(e = null){
        if (e) e.preventDefault()
        this.setState({modalAddSalesShow: false})
    }

    handleCloseModalResponse(e = null){
        if (e) e.preventDefault()
        this.setState({modalResponseShow: false})
    }

    showModalInfoSales(){
        this.setState({modalInfoSales: true})
    }

    handleCloseModalInfoSales(){
        this.setState({modalInfoSales: false})
    }

    addNewBuyer = async (e) => {
        e.preventDefault();
        const {formSalesRegister} = this.state;
        formSalesRegister['buyers'].push({
            "name": document.getElementById('name-sales').value,
            "email": document.getElementById('email-sales').value,
            "phone": document.getElementById('phone-sales').value,
            "cpf": document.getElementById('cpf-sales-control').value,
        })
        
        formSalesRegister['name'] = ''
        formSalesRegister['email'] = ''
        formSalesRegister['phone'] = ''
        formSalesRegister['cpf'] = ''

        this.setState({
            formSalesRegister,
        });
        document.getElementById('form-new-buyer').reset();
    }

    addNewFieldMediaPlan = async (e) => {
        e.preventDefault();
        this.setState({ msgResponseShow: this.translate("BUSINESS_SALES_CONTROL_WAIT_STATUS"), modalResponseShow: true });
        const data = this.state.formSalesRegister;
        if(data.buyers.length){
            try{
                const response = await api.post("/business/" + this.props.idBusiness + "/sales_control/register", {data});
                if(response.data.success === true){
                    this.setState({msgResponseShow: this.translate("BUSINESS_SALES_CONTROL_SUCCESS_STATUS")});
                    document.getElementById('register-sales-new').reset();

                    const {formSalesRegister} = this.state;
                    formSalesRegister['buyers'] = []
                    this.setState({
                        formSalesRegister,
                    });

                    this.reloadDatatable()
                }else{
                    this.setState({msgResponseShow: response.data.msg});
                }
            }catch(err){
                this.setState({msgResponseShow: err});
            }
        }else{
            this.setState({ msgResponseShow: this.translate("BUSINESS_SALES_CONTROL_ADD_BUYERS_STATUS"), modalResponseShow: true });
        }
    }

    reloadDatatable(){
        $('#list_sales').DataTable().ajax.reload();
    }

    handleKeyUp = (e) =>{
        if(e.target.value.length > 5){
            autocomplete(e)
        }
    }

    clickAutoComplete = (e) => {
        if(e.target.classList.contains('autocomplete-dev-click')){
            const data = e.target.dataset
            const {formSalesRegister} = this.state;
            formSalesRegister['name'] = data.name;
            formSalesRegister['email'] = data.email;
            formSalesRegister['phone'] = data.phone;
            this.setState({
                formSalesRegister,
            });
            $('.autocomplete-dev').remove();
        }
    }

    onChangeMoney(e){
        e.preventDefault();
        var moneyMaskBehavior = function (){
            return "#.##0,00";
        },
        moneyOptions = {
            reverse: true,
            onKeyPress: function(val, e, field, options){
                this.setState({formSalesRegister: {...this.state.formSalesRegister, amount: val}})
                field.mask(moneyMaskBehavior.apply({}, arguments), options);
            }.bind(this)
        };
        $('#value-sales-control').mask(moneyMaskBehavior, moneyOptions);
    }

    submitSalesCsvUpload(e){
        e.preventDefault()
        //console.log("Submit", this.state.salesFileUpload)
        let salesJsonData = [
            {name:"Joao", email:"joao@j.com", asd:"55555", aasd:"55555", unity: 5, value: 22},
            {name:"Joao", email:"joao@j.com", asd:"55555", aasd:"55555", unity: 5, value: 22},
        ]
        this.validSalesJsonData(salesJsonData)
    }

    validSalesJsonData(salesJsonData){
        let thValues = Object.keys(salesJsonData[0])
        thValues = this.sanitizeThValues(thValues)
        this.updateThSelectOptions(thValues)
        let thValuesAux = thValues
        salesJsonData = this.replaceSalesKeysForThValues(salesJsonData, thValues)
        this.setSalesData(salesJsonData, thValues, thValuesAux)
    }

    updateThSelectOptions(thValues){
        let {tableSalesCsv} = this.state
        let {thSelectOptions} = this.state.tableSalesCsv
        thSelectOptions = [...thSelectOptions, ...thValues]
        thSelectOptions = [...new Set(thSelectOptions)]
        tableSalesCsv["thSelectOptions"] = thSelectOptions
        this.setState({
            tableSalesCsv
        })
    }
    sanitizeThValues(thValues){
        let customCounter = 1
        return thValues.map((value)=>{
            switch(value){
                case "name":
                case "email":
                case "cpf":
                case "unity":
                case "value":
                    break
                default:
                    value = `customizavel_${customCounter}`
                    customCounter++
                    break  
            }
            return value
        })
    }

    replaceSalesKeysForThValues(salesJsonData, thValues){
        return salesJsonData.map((sale)=>{
            let newSale = {}
            Object.entries(sale).forEach(([key, value], index)=>{
                let obj = {}
                key = thValues[index]
                obj[key] = value
                newSale = {...newSale, ...obj}
            })
            return newSale
        })
    }

    setSalesData(salesJsonData, thValues, thValuesAux){
        this.setState({
            tableSalesCsv: {
                ...this.state.tableSalesCsv,
                salesJsonData,
                thValues,
                thValuesAux
            }
        })
    }
    changeThOptionSalesCsv(newValue, selectIndex){
        let {thValues, thValuesAux, salesJsonData} = this.state.tableSalesCsv
        let oldValue = thValuesAux[selectIndex]
        let oldValueIndex = thValuesAux.indexOf(newValue)
        if(oldValueIndex !== -1) {
            thValues[oldValueIndex] = oldValue
        }
        thValues[selectIndex] = newValue
        thValuesAux = thValues
        this.updateThSelectOptions(thValues)
        salesJsonData = this.replaceSalesKeysForThValues(salesJsonData, thValues)
        this.setState({
            tableSalesCsv: {
                ...this.state.tableSalesCsv,
                thValues,
                thValuesAux,
                salesJsonData
            }
        }) 
    }
    changeInputDataSalesCsv(value, index, saleFieldIndex){
        let {salesJsonData, thValues} = this.state.tableSalesCsv
        let field = thValues[saleFieldIndex]
        salesJsonData[index][field] = value
        this.setState({
            tableSalesCsv: {
                ...this.state.tableSalesCsv,
                salesJsonData
            }
        })
    }
   
    removeLine(index){
        let {salesJsonData} = this.state.tableSalesCsv
        salesJsonData = salesJsonData.filter((sale, saleIndex)=> saleIndex !== index)
        this.setState({
            tableSalesCsv: {
                ...this.state.tableSalesCsv,
                salesJsonData
            }
        })
    }
    removeColumn(columnIndex){
        let {salesJsonData, thValues, thValuesAux} = this.state.tableSalesCsv
        salesJsonData = salesJsonData.map((sale)=>{
            let newSale = {}
            Object.entries(sale).forEach(([key, value], index)=>{
                if(index !== columnIndex){
                    let obj = {}
                    key = thValues[index]
                    obj[key] = value
                    newSale = {...newSale, ...obj}
                }
            })
            return newSale
        })
        thValues = Object.keys(salesJsonData[0])
        thValuesAux = thValues

        this.setState({
            tableSalesCsv: {
                ...this.state.tableSalesCsv,
                salesJsonData,
                thValues,
                thValuesAux
            }
        })
    }
    turnToSelect(target){
        let div = target.parentNode
        div.classList.remove("d-flex")
        div.classList.add("d-none")
        let select = target.parentNode.nextSibling
        select.classList.remove("d-none")
        select.classList.add("d-block")
    }
    submitTableSales = async () => {
        try {
            //let response = await api.post("", {tableSales: this.state.tableSalesCsv.salesJsonData})
            let response = {data: {success: true, message: "Função em desenvolvimento"}}
            if(response && response.data && response.data.success){
                this.handleCloseModalAddSales()
                this.setState({msgResponseShow: response.data.message, modalResponseShow: true})
            }
        } catch (error) {
            console.log(error)
        } 
    }   
    

    render() {
        let uniqueIndex = 0
        const getUniqueIndex =() => uniqueIndex++
        return (
            <React.Fragment>
                <div id="accordion-sales-control">
                    <div className="card mb-3">
                        <div className="card-header btn-cursor-pointer dark" id="headingOnes">
                            <h5 className="mb-0" onClick={e=>this.props.toggleCollapse("#collapseRegisterSales")} aria-expanded="false" aria-controls="collapseRegisterSales">
                                {this.translate("BUSINESS_SALES_CONTROL_REGISTER_REGISTER_SALE")}
                                <i className="fa fa-caret-down pull-right"></i>
                            </h5>
                        </div>

                        <div id="collapseRegisterSales" className="collapse dark" aria-labelledby="headingOnes" data-parent="#accordion-sales-control">
                            <div className="card-body">
                                <form onSubmit={this.addNewBuyer} id="form-new-buyer">
                                    <div className="row p-3 white box-shadow pb40">
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group" onClick={this.clickAutoComplete}>
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                    {this.translate("BUSINESS_SALES_CONTROL_REGISTER_BUYER_NAME")}:
                                                </label>
                                                <input type="text" name="name" id="name-sales" autoComplete="off" onKeyUp={this.handleKeyUp} onPaste={this.handleKeyUp} onChange={e => this.setState({ formSalesRegister: {...this.state.formSalesRegister, name:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("BUSINESS_SALES_CONTROL_REGISTER_BUYER_NAME")} value={this.state.formSalesRegister.name} required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group" onClick={this.clickAutoComplete}>
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                {this.translate("BUSINESS_SALES_CONTROL_REGISTER_BUYER_EMAIL")}:
                                                </label>
                                                <input type="email" name="email" id="email-sales" autoComplete="off" onKeyUp={this.handleKeyUp} onPaste={this.handleKeyUp} onChange={e => this.setState({ formSalesRegister: {...this.state.formSalesRegister, email:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("BUSINESS_SALES_CONTROL_REGISTER_BUYER_EMAIL")} value={this.state.formSalesRegister.email} required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                            <div className="form-group" onClick={this.clickAutoComplete}>
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                {this.translate("BUSINESS_SALES_CONTROL_REGISTER_BUYER_PHONE")}:
                                                </label>
                                                <input type="text" name="phone" id="phone-sales" autoComplete="off" onKeyUp={this.handleKeyUp} onPaste={this.handleKeyUp} onChange={e => this.setState({ formSalesRegister: {...this.state.formSalesRegister, phone:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("BUSINESS_SALES_CONTROL_REGISTER_BUYER_PHONE")} value={this.state.formSalesRegister.phone} required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                {this.translate("BUSINESS_SALES_CONTROL_REGISTER_BUYER_CPF")}:
                                                </label>
                                                <input type="text" id="cpf-sales-control" onChange={e => this.setState({ formSalesRegister: {...this.state.formSalesRegister, cpf:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("BUSINESS_SALES_CONTROL_REGISTER_BUYER_CPF")} required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 mt20">
                                            <button type="submit" className="btn btn-success px-5 btn-rounded btn-shadow pull-right btn-cursor-pointer">
                                                <i className="fa fa-plus"></i> {this.translate("BUSINESS_SALES_CONTROL_REGISTER_BUYER_ADD_BUYER")}
                                            </button>
                                        </div>
                                        <div className="box-divider m0 mt20 width100"></div>
                                    </div>
                                </form>
                                <form onSubmit={this.addNewFieldMediaPlan} id="register-sales-new">
                                    <table className="table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                                            <thead>
                                                <tr className="text-left">
                                                    <th>{this.translate("BUSINESS_SALES_CONTROL_LIST_BUYER_NAME")}</th>
                                                    <th>{this.translate("BUSINESS_SALES_CONTROL_LIST_BUYER_EMAIL")}</th>
                                                    <th>{this.translate("BUSINESS_SALES_CONTROL_LIST_BUYER_PHONE")}</th>
                                                    <th>{this.translate("BUSINESS_SALES_CONTROL_LIST_BUYER_CPF")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.formSalesRegister.buyers.map((data, i) => (
                                                        <tr key={i}>
                                                            <th>{data.name}</th>
                                                            <th>{data.email}</th>
                                                            <th>{data.phone}</th>
                                                            <th>{data.cpf}</th>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                    </table>
                                    <div className="row p-3 white box-shadow pb40">
                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                    {this.translate("BUSINESS_SALES_CONTROL_REGISTER_UNITY")}:
                                                </label>
                                                <input type="text" onChange={e => this.setState({ formSalesRegister: {...this.state.formSalesRegister, unity:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("BUSINESS_SALES_CONTROL_REGISTER_UNITY")} required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                    {this.translate("BUSINESS_SALES_CONTROL_REGISTER_VALUE")}:
                                                </label>
                                                <input type="text" id="value-sales-control" onChange={this.onChangeMoney} defaultValue={this.state.formSalesRegister.amount} className="form-control box-shadow" placeholder={this.translate("BUSINESS_SALES_CONTROL_REGISTER_VALUE")} required/>
                                            </div>
                                        </div>
                                        <div className="box-divider m0 mt20 width100"></div>
                                        <div className="col-sm-12 col-md-12 mt20">
                                             <button type="button" onClick={e=>this.setState({modalAddSalesShow: true})} className="btn blue px-5 btn-rounded btn-shadow btn-cursor-pointer">
                                                <i className="fa fa-upload"></i> {this.translate("BUSINESS_SALES_CONTROL_REGISTER_SALES_UPLOAD")}
                                            </button>
                                            <button type="submit" className="btn btn-info px-5 btn-rounded btn-shadow pull-right btn-cursor-pointer">
                                                <i className="fa fa-save"></i> {this.translate("BUSINESS_SALES_CONTROL_REGISTER_REGISTER_NEW_SALE")}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                    <div className="card">
                        <div className="card-header btn-cursor-pointer dark" id="headingSalesList" onClick={e=>this.props.toggleCollapse("#collapseSalesList")} aria-expanded="true" aria-controls="collapseSalesList">
                            <h5 className="mb-0">
                                {this.translate("BUSINESS_SALES_CONTROL_LIST_LIST_OF_SALES")}
                                <i className="fa fa-caret-down pull-right"></i>
                            </h5>
                        </div>

                        <div id="collapseSalesList" className="collapse dark show" aria-labelledby="headingSalesList" data-parent="#accordion-sales-control">
                            <div className="card-body">
                                <div className="bootstrap-table">
                                    <div className="fixed-table-container">
                                        <div className="fixed-table-body">
                                            <table id="list_sales" className="table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                                                <thead>
                                                    <tr className="text-left">
                                                        <th>{this.translate("BUSINESS_SALES_CONTROL_LIST_BUYERS_QUANTITY")}</th>
                                                        <th>{this.translate("BUSINESS_SALES_CONTROL_LIST_MAIN_BUYER")}</th>
                                                        <th>{this.translate("BUSINESS_SALES_CONTROL_LIST_UNITY")}</th>
                                                        <th>{this.translate("BUSINESS_SALES_CONTROL_LIST_VALUE")}</th>
                                                        <th>{this.translate("TABLE_ACTIONS_ROW")}</th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <Modal show={this.state.modalAddSalesShow} onHide={this.handleCloseModalAddSales} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t pb-5">
                    <Modal.Body>
                        <div id="modalBusinessAddSales" className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-upload" aria-hidden="true"></i> {this.translate("BUSINESS_SALES_CONTROL_MODAL_SALES_UPLOAD")}</h5>
                                <a href="/" onClick={e=>this.handleCloseModalAddSales(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row">
                                <form onSubmit={e=>this.submitSalesCsvUpload(e)} encType="multipart/form-data" className="form-inline col-8 py-3 mx-auto justify-content-center">
                                    <div className="form-group px-5">
                                        
                                        <input id="business-sales-csv-upload" onChange={e=>this.setState({salesFileUpload: e.target.files[0]})} type="file" className="form-control-file"/>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-info px-5 btn-rounded btn-shadow btn-cursor-pointer">
                                            <i className="fa fa-save"></i> {this.translate("BUSINESS_SALES_CONTROL_MODAL_SUBMIT_BUTTON")}
                                        </button>
                                    </div>
                                </form>
                                
                            </div>
                            <div className="row">
                                <form className="col-8 py-3 mx-auto">
                                    <table className="table">
                                        <thead>
                                            
                                                {this.state.tableSalesCsv.thValues.length > 0 && this.state.tableSalesCsv.salesJsonData.length > 0 && (
                                                    <React.Fragment>
                                                        <tr>
                                                            {this.state.tableSalesCsv.thValues.map((th, index)=>
                                                                <th key={`remove${index}`} onClick={e=>this.removeColumn(index)} className="text-center"><i className="fa fa-close text-danger"></i> {this.translate("BUSINESS_SALES_CONTROL_MODAL_COLUMN_BUTTON")}</th>
                                                            )}
                                                            <th>&nbsp;</th>
                                                        </tr>
                                                        <tr>
                                                            {this.state.tableSalesCsv.thValues.map((th, index)=>
                                                                <th key={`${getUniqueIndex()}${th}tableSalesTh${index}`}>
                                                                    <div className={`form-group ${th.includes("customizavel") ? "d-flex m-0" : "d-none"}`}>
                                                                        <input onBlur={e=>this.changeThOptionSalesCsv(e.target.value, index)} type="text" className="form-control" defaultValue={th}/>
                                                                        <i onClick={e=>this.turnToSelect(e.target)} className="fa fa-undo my-auto pl-2"></i>
                                                                    </div>
                                                                    <select onChange={e=>this.changeThOptionSalesCsv(e.target.value, index)} className={`form-control cool-select ${th.includes("customizavel") ? "d-none" : "d-block"}`} defaultValue={th}>
                                                                        {this.state.tableSalesCsv.thSelectOptions.map((option)=>
                                                                            <option key={`${option}${index}${th}${getUniqueIndex()}`} value={option}>{option}</option>
                                                                        )}
                                                                    </select>
                                                                </th>   
                                                            )}
                                                            <th>Action</th>
                                                        </tr>
                                                    </React.Fragment>
                                                )}
                                        </thead>
                                        <tbody>
                                            {this.state.tableSalesCsv.salesJsonData.length > 0 && this.state.tableSalesCsv.thValues.length > 0 &&(
                                                this.state.tableSalesCsv.salesJsonData.map((sale, index)=>
                                                    <tr key={`salesJsonData${index}`}>
                                                        {Object.values(sale).map((saleField, saleFieldIndex)=>
                                                            <td key={`${getUniqueIndex()}${saleField}${saleFieldIndex}`}>
                                                                <input onBlur={e=>this.changeInputDataSalesCsv(e.target.value, index, saleFieldIndex)} className="form-control" type="text" defaultValue={saleField}/>
                                                            </td>
                                                        )}
                                                        <td onClick={e=>this.removeLine(index)} className="text-center"><i className="fa fa-close text-danger"></i> {this.translate("BUSINESS_SALES_CONTROL_MODAL_ROW_BUTTON")}</td>
                                                    </tr>
                                                )
                                                
                                            )}
                                        </tbody>
                                    </table>
                                </form>
                                
                            </div>
                            <div className="row">
                                <div className="col-8 mx-auto">
                                    {this.state.tableSalesCsv.salesJsonData.length > 0 && this.state.tableSalesCsv.thValues.length > 0 &&(
                                        <button type="button" onClick={e=>this.submitTableSales()} className="btn btn-success px-5 btn-rounded btn-shadow pull-right btn-cursor-pointer">
                                            <i className="fa fa-plus"></i> {this.translate("BUSINESS_SALES_CONTROL_MODAL_REGISTER_BUTTON")}
                                        </button>
                                    )}

                                </div>

                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.modalResponseShow} onHide={this.handleCloseModalResponse} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-bell"></i> {this.translate("MODAL_STATUS_STATUS")}</h5>
                                <a href="/" onClick={e=>this.handleCloseModalResponse(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                <span className="col-12 text-center py-2 lead">
                                    {this.state.msgResponseShow}
                                </span>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.modalInfoSales} onHide={this.handleCloseModalInfoSales} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 pb-5">
                            <div className="p-3 b-b d-flex no-shrink warning text-center">
                                <h5 className="h5 m-0 text-center width100 line-height-38px">
                                    <i className="fa fa-plus"></i> {this.translate("BUSINESS_SALES_CONTROL_MODAL_SALES_INFO")}
                                </h5>
                                <button onClick={this.handleCloseModalInfoSales} className="btn btn-transparent ml-auto btn-cursor-pointer">
                                    <span className="text-lg l-h-1x">×</span>
                                </button>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-6 offset-md-3">

                                    {
                                        (this.state.infoSales !== null)?(
                                            <React.Fragment>
                                                <div>
                                                    <b>{this.translate("BUSINESS_SALES_CONTROL_MODAL_SALES_UNITY")}:</b> {this.state.infoSales.unity}
                                                    <br/>
                                                    <b>{this.translate("BUSINESS_SALES_CONTROL_MODAL_SALES_VALUE")}:</b> {this.state.infoSales.amount}
                                                </div>
                                                <br/>
                                                {
                                                    this.state.infoSales.buyers.map((data, i) => (
                                                        <div key={i}>
                                                            <h6>{this.translate("BUSINESS_SALES_CONTROL_MODAL_SALES_BUYER")} {(i + 1)}</h6>
                                                            <div>
                                                                <b>{this.translate("BUSINESS_SALES_CONTROL_MODAL_SALES_BUYER_NAME")}:</b> {data.name}
                                                            </div>
                                                            <div>
                                                                <b>{this.translate("BUSINESS_SALES_CONTROL_MODAL_SALES_BUYER_EMAIL")}:</b> {data.email}
                                                            </div>
                                                            <div>
                                                                <b>{this.translate("BUSINESS_SALES_CONTROL_MODAL_SALES_BUYER_PHONE")}:</b> {data.phone}
                                                            </div>
                                                            <div>
                                                                <b>{this.translate("BUSINESS_SALES_CONTROL_MODAL_SALES_BUYER_CPF")}:</b> {data.cpf}
                                                            </div>
                                                            <br/>
                                                        </div>
                                                    ))
                                                }
                                            </React.Fragment>
                                        ):(
                                            <span></span>
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(BusinessSalesControl);