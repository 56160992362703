import React, { Component } from 'react'
import $ from "jquery"

export default class Dashboard1 extends Component{

    constructor(props){
        super(props)
        this.state = {
            isEditing: false
        }
    }
    
    confirmChanges = (id) => {
        this.props.handleConfirmDashboardChanges(id)
    }

    disposeTooltip = () => $('[data-toggle="tooltip"]').tooltip('dispose')

    checkConfirmIsDisabled = () => {
        let check = false

        if(!this.props.values.title) check = true
        if(!this.props.values.short_description) check = true
        if(!this.props.values.color) check = true

        return check
    }

    componentDidMount = () => {
        this.setState({isEditing: this.props.isEditing})

        $('[data-toggle="tooltip"]').tooltip('dispose').tooltip({placement: "bottom", trigger: "hover"})
    }

    componentDidUpdate = () => {
        if(this.props.isEditing !== this.state.isEditing) this.setState({isEditing: this.props.isEditing})
    }

    componentDidUpdate = () => {
        $('[data-toggle="tooltip"]').tooltip('dispose').tooltip({placement: "bottom", trigger: "hover"})
    }

    render = () => {
        let {id, values, handleViewDashboard, handleEditDashboard, handleDashboardValueChanges, handleRemoveDashboard, translate, isLoading} = this.props
        let {title, short_description, color} = values
        return (
            <div className="col-6 col-sm-6 col-md-3 btn-cursor-pointer hover-shadow active-dark-background">
                <div className="dashboard_performance_card position10">
                    <div className="d-flex align-items-center">
                        <p className="uppercase text-muted f10 flex-grow-1 mb-0">{title || translate("DASHBOARD_PERFORMANCE_CARD_ENTER_THE_DASHBOARD_TITLE")}</p>
                        {this.state.isEditing ?
                            <React.Fragment>
                                <button onClick={e=>{this.disposeTooltip();handleRemoveDashboard(id)}} disabled={isLoading} className="btn btn-sm btn-danger btn-cursor-pointer ml-2" data-toggle="tooltip" title={translate("DASHBOARD_PERFORMANCE_CARD_REMOVE_DASHBOARD")}>
                                    <i className="fa fa-trash"></i>
                                </button>
                                {isLoading ? 
                                    <button disabled={true} className="btn btn-sm btn-success btn-cursor-pointer ml-2" data-toggle="tooltip" title={translate("ICON_LOADING")}>
                                        <i className="fa fa-spin fa-spinner"></i>
                                    </button>
                                :
                                    <button onClick={e=>{this.disposeTooltip();this.confirmChanges(id)}} disabled={this.checkConfirmIsDisabled()} className="btn btn-sm btn-success btn-cursor-pointer ml-2" data-toggle="tooltip" title={translate("DASHBOARD_PERFORMANCE_CARD_CONFIRM_CHANGES")}>
                                        <i className="fa fa-check"></i>
                                    </button>
                                }
                            </React.Fragment>
                        :
                                <button onClick={e=>{this.disposeTooltip();handleEditDashboard(id)}} className="btn btn-sm btn-warning btn-cursor-pointer ml-2" data-toggle="tooltip" title={translate("DASHBOARD_PERFORMANCE_CARD_EDIT_DASHBOARD")}>
                                    <i className="fa fa-edit"></i>
                                </button>
                        }
                            
                    </div>
                    <div className="card-content" onClick={()=>{
                        if(this.state.isEditing) return
                        handleViewDashboard(id)
                    }}>

                        {this.state.isEditing ?
                            <div className="d-flex justify-content-center">
                                
                                <i className={`fa fa-tachometer f32 text-center`} style={{color: color}}></i>
                                <input 
                                    type="color" 
                                    value={color} 
                                    disabled={isLoading}
                                    onChange={e=>handleDashboardValueChanges(id, "color", e.target.value)}
                                    className="btn-cursor-pointer bg-dark border-0 ml-3 border-dark"
                                    style={{height: 30}}
                                />
                            </div>
                        :
                            <i className={`fa fa-tachometer text-center f32 text-shadow width100 mt20`} style={{color: color}}></i>
                        
                        }

                        <div className="py-3 text-center text-lg f700 f32 text-shadow">
                            {this.state.isEditing ?
                                <div className="flex-grow-1">
                                    <input 
                                        type="text" 
                                        value={title} 
                                        disabled={isLoading}
                                        onChange={e=>handleDashboardValueChanges(id, "title", e.target.value)}
                                        placeholder={translate("DASHBOARD_PERFORMANCE_CARD_ENTER_THE_DASHBOARD_TITLE")} 
                                        className="form-control col-12 flex-grow-1 mb-2"
                                    />
                                </div>
                            :
                                <span className="small">{title || translate("DASHBOARD_PERFORMANCE_CARD_ENTER_THE_DASHBOARD_TITLE")}</span>
                            }
                        </div>

                        <div className="d-flex width100 text-center">
                            {this.state.isEditing ?
                                <div className="flex-grow-1">
                                    <input 
                                        type="text" 
                                        value={short_description} 
                                        disabled={isLoading}
                                        onChange={e=>handleDashboardValueChanges(id, "short_description", e.target.value)}
                                        placeholder={translate("DASHBOARD_PERFORMANCE_CARD_ENTER_THE_DASHBOARD_SHORT_DESCRIPTION")} 
                                        className="form-control col-12"
                                    />
                                </div>
                            :
                                <span className="flex width100">
                                    {short_description || translate("DASHBOARD_PERFORMANCE_CARD_ENTER_THE_DASHBOARD_SHORT_DESCRIPTION")}
                                </span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    } 
    
}






