import React, { Component } from 'react';

import $ from 'jquery';

import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4'

import api from '../../../../Services/Api';
import {ajaxUrl, ajaxToken} from '../../../../Services/Ajax';

class EmailNotification extends Component {

    constructor(props){
        super(props)

        this.emailId = 0

        this.state = {
            tableEmailNotification: null,
            formSaveEmailNotification: {
                formId: "",
                department: "",
                emails: [
                    {id: this.emailId, email: ""}
                ]
            },
            departments: []
        }

        this.translate = this.props.translate
        
    }

    removeEmail = (emailIdToBeRemoved) => {
        let {emails} = this.state.formSaveEmailNotification
        if(emails.length === 1) return
        emails = emails.filter((email)=>email.id !== emailIdToBeRemoved)
        this.setState({formSaveEmailNotification: {...this.state.formSaveEmailNotification, emails: emails}})
    }
    
    addEmail = () => {
        let {emails} = this.state.formSaveEmailNotification
        
        this.emailId = this.emailId += 1
        emails.push({id: this.emailId, email: ""})

        this.setState({formSaveEmailNotification: {...this.state.formSaveEmailNotification, emails: emails}})
    }

    updateEmail = (e, email, index) => {
        
        let {emails} = this.state.formSaveEmailNotification
        let value = e.target.value

        emails.forEach(emailForEach=>{
            if(emailForEach.id === email.id) emailForEach.email = value
        })

        this.setState({formSaveEmailNotification: {...this.state.formSaveEmailNotification, emails: emails}})
    }

    fetchDepartments = async () => {
        try{
            const response = await api.get("/integration/email_notification/departments");
            if(response.data.success === true && response.data.departments && response.data.departments.length){
                this.setState({ departments: response.data.departments})
            }
        }catch(err){
            console.log(err);
        } 
    }

    clearEmailNotificationForm = () => this.setState({
        formSaveEmailNotification: {
            formId: "",
            departments: [],
            department: "",
            emails: [
                {id: this.emailId, email: ""}
            ]
        }
    })

    reloadDatatableEmailNotification = () => {
        $('#email_notification_integration').DataTable().ajax.reload();
    }

    deleteEmailNotification = async (id) => {
        const data = {id: id}
        try{
            const response = await api.post("/integration/email_notification/delete", data);
            if(response.data.success === true){
                this.reloadDatatableEmailNotification()
            }else{
                alert(response.data.msg)
            }
        }catch(err){
            console.log(err);
        } 
    }

    formSubmitSaveEmailNotification = async (e) => {
        e.preventDefault();
        $('#add_email_notification').prop('disabled', true).html(`<i className="fa fa-spinner"></i> ${this.translate('ICON_WAIT')}`)
        
        let data = this.state.formSaveEmailNotification;
        data.emails = [...new Set(data.emails.map(email=>email.email))] 

        
        try{
            const response = await api.post("/integration/email_notification/create", data);
            if(response.data.success === true){
                this.reloadDatatableEmailNotification()
                this.clearEmailNotificationForm()
            }else{
                alert(response.data.msg)
            }
        }catch(err){
            alert(err)
        }
        $('#add_email_notification').prop('disabled', false).html(`<i className="fa fa-save"></i> ${this.translate('ICON_ADD')}`) 
    }

    componentDidMount = () => {
        let tableEmailNotification = this.state.tableEmailNotification
        tableEmailNotification = $('#email_notification_integration').DataTable({
            "searching": true,
            "paging": false,
            "info": false,
            "responsive": true,
            "language": {
                "url": this.translate('DATATABLE_LANGUAGE_URL')
            },
            "ajax": {
                "url": ajaxUrl('/integration/email_notification/list'),
                "type": 'GET',
                "beforeSend": function (request) {
                    request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                } 
            },  
            "columnDefs": [{
                "targets": -1,
                "data": null,
                "defaultContent": 
                    `<div class="btn-group btn-shadow">
                        <button class="delete btn btn-sm danger btn-cursor-pointer" title="${this.translate('EMAIL_NOTIFICATION_REMOVE_NOTIFICATION')}">
                            <i class="fa fa-trash" title="${this.translate('EMAIL_NOTIFICATION_REMOVE_NOTIFICATION')}"></i>
                        </button>
                    </div>`
            }]
        });
        
        $('#email_notification_integration tbody').on( 'click', '.delete', function (e){
            e.preventDefault();
            let data = [];
            
            
            if($(e.target).parents('tr').hasClass('child')){
                data = tableEmailNotification.row($(e.target).parents('tr').prev('.parent')).data();
            }else{
                data = tableEmailNotification.row($(e.target).parents('tr')).data();
            }
            
            $(e.currentTarget).prop('disabled', true).html('<i class="fa fa-spinner"></i>')
            
            this.deleteEmailNotification(data[3]);
            
        }.bind(this));
        
        this.fetchDepartments()
    }

    render() {
        return (
            <React.Fragment>
                <div className="d-flex flex-column h-100">
                    <div className="p-3 b-b d-flex no-shrink info text-center position10">
                        <h5 className="h5 m-0 text-center width100 line-height-38px">
                            <i className="fa fa-plus-square-o"></i> {this.translate('EMAIL_NOTIFICATION_REGISTER_EMAIL_NOTIFICATION')}
                        </h5>
                        <button onClick={this.props.handleCloseEmailNotification} className="btn btn-transparent ml-auto">
                            <span className="text-lg l-h-1x">×</span>
                        </button>
                    </div>
                    
                    <div className="row">
                        <div id="accordionEmailNotification" style={{width: '100%'}}>
                            <div className="panel box no-border mb-2" style={{width: '100%'}}>

                                <div className="box-header b-b p-y-sm fwhite">
                                    <button style={{width: '100%'}} onClick={(e)=>this.props.toggleCollapse("#formEmailNotification")} data-parent="#accordionEmailNotification" className="btn btn-transparent btn-cursor-pointer" aria-expanded="true">
                                        {this.translate('EMAIL_NOTIFICATION_ADD_NEW_NOTIFICATION')}
                                        <span className="float-right badge text-sm">
                                            <i className="fa fa-angle-down"></i>
                                        </span>
                                    </button>
                                </div>
                                <div id="formEmailNotification" className="in collapse">
                                    <div className="col-sm-12 col-md-12 col-lg-12">
                                        <form onSubmit={this.formSubmitSaveEmailNotification} id="form_add_email_notification">
                                            <br />
                                            <div className="col-sm-12 mb20">
                                                <label className="black no-text-shadow">{this.translate('EMAIL_NOTIFICATION_FORM_ID')}</label>
                                                <input type="text" onChange={e => this.setState({ formSaveEmailNotification: {...this.state.formSaveEmailNotification, formId:  e.target.value}})} value={this.state.formSaveEmailNotification.formId} className="form-control box-shadow cool-input" placeholder={this.translate('EMAIL_NOTIFICATION_FORM_ID')} required="required" />
                                            </div>

                                            <div className="col-sm-12 mb20">
                                                <label className="black no-text-shadow">{this.translate('EMAIL_NOTIFICATION_DEPARTMENT')}</label>
                                                <select onChange={e => this.setState({ formSaveEmailNotification: {...this.state.formSaveEmailNotification, department:  e.target.value}})} value={this.state.formSaveEmailNotification.department} className="form-control box-shadow cool-select" required="required">
                                                    <option value="">{this.translate('EMAIL_NOTIFICATION_SELECT_THE_DEPARTMENT')}</option>
                                                    {(this.state.departments && this.state.departments.length) && (
                                                        this.state.departments.map(department=>
                                                            <option key={department.department_id} value={department.department_id}>{department.department_name}</option>
                                                        )
                                                    )}
                                                </select>
                                            </div> 
                                            {this.state.formSaveEmailNotification.emails && this.state.formSaveEmailNotification.emails.length && (
                                                this.state.formSaveEmailNotification.emails.map((email, index)=>
                                                    <div key={index + email + email.id} className="col-sm-12 mb20">
                                                        <label className="black no-text-shadow">{index + 1}º {this.translate('EMAIL_NOTIFICATION_EMAIL_TO_BE_NOTIFIED')}</label>
                                                        <div className="d-flex flex-row">
                                                            <input type="email" onChange={e => this.updateEmail(e, email)} value={email.email} className="form-control box-shadow cool-input" placeholder={this.translate('EMAIL_NOTIFICATION_ENTER_THE_EMAIL')} required="required" />
                                                            <i className="fa fa-close red p-2 rounded mx-2 align-self-center btn-cursor-pointer" style={{opacity: this.state.formSaveEmailNotification.emails.length === 1 ? 0.7 : 1}} onClick={e=>this.removeEmail(email.id)}></i>
                                                        </div>
                                                    </div>
                                                )
                                            )}

                                            <div className="col-sm-12 mb20">
                                                <button type="button" className="btn btn-primary btn-cursor-pointer px-5 btn-rounded btn-shadow" onClick={this.addEmail}>
                                                    <i className="fa fa-plus"></i> {this.translate('EMAIL_NOTIFICATION_ADD_EMAIL')}
                                                </button> 
                                            </div>

                                            <div className="col-sm-12 mb20">
                                                <button id="add_email_notification" type="submit" className="pull-right btn btn-success px-5 btn-rounded btn-shadow">
                                                    <i className="fa fa-plus"></i> {this.translate('EMAIL_NOTIFICATION_ADD_EMAIL_NOTIFICATION')}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="col-12 mt-3 p-3 text-left dark">
                        <table id="email_notification_integration" className="table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                            <thead className="dark">
                                <tr>
                                    <th>{this.translate('EMAIL_NOTIFICATION_FORM_ID')}</th>
                                    <th>{this.translate('EMAIL_NOTIFICATION_DEPARTMENT')}</th>
                                    <th>{this.translate('EMAIL_NOTIFICATION_EMAILS')}</th>
                                    <th>{this.translate('TABLE_ACTIONS_ROW')}</th>
                                </tr>
                            </thead>
                        </table>
                    </div>

                </div>
            </React.Fragment>
        );
    }
}

export default EmailNotification;