import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'

import $ from 'jquery';
import 'jquery-mask-plugin';

import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4'

import {ajaxUrl, ajaxToken} from '../../../Services/Ajax';
import api from '../../../Services/Api';

import Modal from 'react-bootstrap/Modal';

import GooglePlaces from '../../../Structure/GooglePlaces';
import GoogleDirections from '../../../Structure/GoogleDirections';

class BusinessCompetitors extends Component {

    constructor(props){
        super(props)
        this.state = {
            modalResponseShow: false,
            msgResponseShow: false,
            modalViewCompetitor: false,
            infoCompetitor: {
                competitor_name: null,
                business_name: null,
                business_address : null,
                competitor_address: null  
            },
            tableLoadCompetitors: null,
            formCompetitorsRegister: {
                company_name: null,
                business_name: null,
                address: null
            }
        }
        this.handleCloseModalResponse =  this.handleCloseModalResponse.bind(this)
        this.registerCompetitors = this.registerCompetitors.bind(this)
        this.reloadDatatable = this.reloadDatatable.bind(this)
        this.handleAddress = this.handleAddress.bind(this)
        this.showModalViewCompetitor = this.showModalViewCompetitor.bind(this)
        this.closeModalViewCompetitor = this.closeModalViewCompetitor.bind(this)
        this.translate = this.props.translate
    }

    componentDidMount(){
        const props = this.props
        let tableLoadCompetitors = this.state.tableLoadCompetitors
        tableLoadCompetitors = $('#list_competitors').DataTable({
            "searching": true,
            "paging": true,
            "info": true,
            "responsive": true,
            "language": {
                "url": this.translate("DATATABLE_LANGUAGE_URL")
            },
            "ajax": {
                "url": ajaxUrl('/business/' + props.idBusiness + '/competitors/table'),
                "type": 'GET',
                "beforeSend": function (request) {
                    request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                }
            },
            "columnDefs": [{
                "targets": -1,
                "data": null,
                "defaultContent": '<div class="btn-group btn-shadow">\n'+
                                        `<button class="view btn btn-sm blue" title="${this.translate("BUSINESS_COMPETITORS_LIST_VISUALIZE_BUTTON_TITLE")}">\n`+
                                            `<i class="fa fa-eye" title="Visualizar"></i> ${this.translate("BUSINESS_COMPETITORS_LIST_VISUALIZE_BUTTON")}\n`+
                                        '</button>\n'+
                                    '</div>'
            }]
        })
        $('#list_competitors tbody').on( 'click', '.view', function (e){
            e.preventDefault();
            let data = [];
            if($(e.target).parents('tr').hasClass('child')){
                data = tableLoadCompetitors.row($(e.target).parents('tr').prev('.parent')).data();
            }else{
                data = tableLoadCompetitors.row($(e.target).parents('tr')).data();
            }
            const { infoCompetitor } = this.state
            infoCompetitor["competitor_name"] = data[0]
            infoCompetitor["business_name"] = data[1]
            infoCompetitor["competitor_address"] = data[2]
            infoCompetitor["business_address"] = data[3]
            
            this.setState({infoCompetitor, modalViewCompetitor: true })
        }.bind(this))
    }

    handleCloseModalResponse(e = null){
        if (e) e.preventDefault()
        this.setState({modalResponseShow: false})
    }

    showModalViewCompetitor(){
        this.setState({modalViewCompetitor: true})
    }

    closeModalViewCompetitor(e = null){
        if (e) e.preventDefault()
        this.setState({modalViewCompetitor: false})
    }

    registerCompetitors = async (e) => {
        e.preventDefault();
        this.setState({ msgResponseShow: this.translate("BUSINESS_COMPETITORS_WAIT_STATUS"), modalResponseShow: true });
        const data = this.state.formCompetitorsRegister;
        try{
            const response = await api.post("/business/" + this.props.idBusiness + "/competitors/register", {data});
            if(response.data.success === true){
                this.handleAddress(null)
                this.setState({
                    formCompetitorsRegister:{ company_name: null, business_name: null}, 
                    msgResponseShow: this.translate("BUSINESS_COMPETITORS_SUCCESS_STATUS")
                });
                document.getElementById('register-competitors-new').reset();
                this.reloadDatatable()
            }else{
                this.setState({msgResponseShow: response.data.msg});
            }
        }catch(err){
            this.setState({msgResponseShow: err});
        }
    }

    reloadDatatable(){
        $('#list_competitors').DataTable().ajax.reload();
    }

    handleAddress(address){
        this.setState(
            {
                formCompetitorsRegister: {
                    ...this.state.formCompetitorsRegister, address: address
                }
            }
        )
        if(address !== null){
            $('#map').css('display', 'block');
        } else {
            $('#map').css('display', 'none');
        }
        
    }
    handleAddressInput(e){
        this.setState({ formCompetitorsRegister: {...this.state.formCompetitorsRegister, address:  e.target.value}})
        if(e.target.value === "") this.handleAddress(null)
    }
    render() {
        return (
            <React.Fragment>
                <div id="accordion-competitors-control">

                    <div className="card mb-3">
                        <div className="card-header btn-cursor-pointer dark" id="headingOnesCompetitors">
                            <h5 className="mb-0" onClick={e=>this.props.toggleCollapse("#collapseOnesCompetitors")} aria-expanded="false" aria-controls="collapseOnesCompetitors">
                                {this.translate("BUSINESS_COMPETITORS_REGISTER_REGISTER_COMPETITOR")}
                                <i className="fa fa-caret-down pull-right"></i>
                            </h5>
                        </div>

                        <div id="collapseOnesCompetitors" className="collapse dark" aria-labelledby="headingOnesCompetitors" data-parent="#accordion-competitors-control">
                            <div className="card-body">
                                <form onSubmit={this.registerCompetitors} id="register-competitors-new">
                                    <div className="row p-3 white box-shadow pb40">
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group">
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                    {this.translate("BUSINESS_COMPETITORS_REGISTER_COMPANY_NAME")}:
                                                </label>
                                                <input type="text" name="company_name" autoComplete="off" onChange={e => this.setState({ formCompetitorsRegister: {...this.state.formCompetitorsRegister, company_name:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("BUSINESS_COMPETITORS_REGISTER_COMPANY_NAME")} defaultValue={this.state.formCompetitorsRegister.company_name} required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group">
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                {this.translate("BUSINESS_COMPETITORS_REGISTER_BUSINESS_NAME")}:
                                                </label>
                                                <input type="text" name="business_name" autoComplete="off" onChange={e => this.setState({ formCompetitorsRegister: {...this.state.formCompetitorsRegister, business_name:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("BUSINESS_COMPETITORS_REGISTER_BUSINESS_NAME")} defaultValue={this.state.formCompetitorsRegister.business_name} required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group">
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                    {this.translate("BUSINESS_COMPETITORS_REGISTER_BUSINESS_ADDRESS")}:
                                                </label>
                                                <GooglePlaces businessAddress={this.state.businessAddress} address={this.state.formCompetitorsRegister.address} onChangeInput={e => this.handleAddressInput(e)} onChange={this.handleAddress} />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 mt20">
                                            <div id="map" style={{display: 'none', width: '100%', height: '400px'}}></div>
                                        </div>
                                        <div className="box-divider m0 mt20 width100"></div>
                                        <div className="col-sm-12 col-md-12 mt20">
                                            <button type="submit" className="btn btn-info px-5 btn-rounded btn-shadow pull-right">
                                                <i className="fa fa-save"></i> {this.translate("BUSINESS_COMPETITORS_REGISTER_REGISTER_NEW_COMPETITOR")}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>

                    <div className="card">
                        <div className="card-header btn-cursor-pointer dark" id="headingTwoCompetitors" onClick={e=>this.props.toggleCollapse("#collapseTwoCompetitors")} aria-expanded="true" aria-controls="collapseTwoCompetitors">
                            <h5 className="mb-0">
                                {this.translate("BUSINESS_COMPETITORS_LIST_LIST_OF_COMPETITORS")}
                                <i className="fa fa-caret-down pull-right"></i>
                            </h5>
                        </div>

                        <div id="collapseTwoCompetitors" className="collapse dark show" aria-labelledby="headingTwoCompetitors" data-parent="#accordion-competitors-control">
                            <div className="card-body">
                                <div className="bootstrap-table">
                                    <div className="fixed-table-container">
                                        <div className="fixed-table-body">
                                            <table id="list_competitors" className="table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                                                <thead>
                                                    <tr className="text-left">
                                                        <th>{this.translate("BUSINESS_COMPETITORS_LIST_COMPANY")}</th>
                                                        <th>{this.translate("BUSINESS_COMPETITORS_LIST_BUSINESS")}</th>
                                                        <th>{this.translate("BUSINESS_COMPETITORS_LIST_ADDRESS")}</th>
                                                        <th>{this.translate("TABLE_ACTIONS_ROW")}</th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <Modal show={this.state.modalViewCompetitor} onHide={this.closeModalViewCompetitor} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                    <div className="d-flex flex-column h-100 pb-5">
                        <div className="p-3 b-b d-flex no-shrink blue text-center">
                            <h5 className="h5 m-0 text-center width100 line-height-38px">
                                <i className="fa fa-eye"></i> {this.translate("BUSINESS_COMPETITORS_MODAL_VISUALIZE_COMPETITOR")}
                            </h5>
                            <button onClick={this.closeModalViewCompetitor} className="btn btn-transparent ml-auto">
                                <span className="text-lg l-h-1x">×</span>
                            </button>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-6 offset-md-3">
                                <h5>{this.translate("BUSINESS_COMPETITORS_MODAL_COMPETITOR_INFO")}</h5>
                                <p>
                                    <b>{this.translate("BUSINESS_COMPETITORS_MODAL_COMPETITOR_INFO_NAME")}: </b> {this.state.infoCompetitor.competitor_name}
                                </p>

                                <GoogleDirections 
                                    translate={this.translate}
                                    origin={this.state.infoCompetitor.business_address}
                                    originName={this.translate("BUSINESS_COMPETITORS_MODAL_COMPETITOR_INFO_ORIGIN_NAME")} 
                                    destination={this.state.infoCompetitor.competitor_address}
                                    destinationName={this.translate("BUSINESS_COMPETITORS_MODAL_COMPETITOR_INFO_DESTINATION_NAME")}
                                />
                            </div>
                        </div>
                    </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.modalResponseShow} onHide={this.handleCloseModalResponse} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-bell"></i> {this.translate("MODAL_STATUS_STATUS")}</h5>
                                <a href="/" onClick={e=>this.handleCloseModalResponse(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                <span className="col-12 text-center py-2 lead">
                                    {this.state.msgResponseShow}
                                </span>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }

}

export default withRouter(BusinessCompetitors);