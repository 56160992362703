import {hexToRGB} from '../../../../Structure/Configs/Theme';


export function getColor(index){
    const color = {
        primary: '#2499ee',
        accent: '#6284f3',
        warn: '#907eec',
        blue: '#007bff',
        indigo: '#6610f2',
        purple: '#6f42c1',
        pink: '#e83e8c',
        red: '#dc3545',
        orange: '#fd7e14',
        yellow: '#ffc107',
        green: '#28a745',
        teal: '#20c997',
        cyan: '#17a2b8',
        white: '#fff',
        gray: '#868e96',
        gray_dark: '#343a40',
        primary2: '#007bff',
        secondary: '#868e96',
        success: '#28a745',
        info: '#17a2b8',
        warning: '#ffc107',
        danger: '#dc3545',
        light: '#f8f9fa',
        dark: '#343a40',
    }
    return color[index]
}
export function getColorIndex(index){
    const color = [
        '#2499ee',
        '#6284f3',
        '#907eec',
        '#007bff',
        '#6610f2',
        '#6f42c1',
        '#e83e8c',
        '#dc3545',
        '#fd7e14',
        '#ffc107',
        '#28a745',
        '#20c997',
        '#17a2b8',
        '#fff',
        '#868e96',
        '#343a40',
        '#007bff',
        '#868e96',
        '#28a745',
        '#17a2b8',
        '#ffc107',
        '#dc3545',
        '#f8f9fa',
        '#343a40',
    ]
    return color[index]
}
export function configChart(label, index, data, hidden = false){
    let color = (typeof index === 'string')? getColor(index) : getColorIndex(index);
    return {
        label: label,
        type: 'line',
        data: data,
        fill: false,
        borderDash: [3, 3],
        backgroundColor: hexToRGB(color, 0.2),
        borderColor: hexToRGB(color, 1),
        borderWidth: 2,
        borderJoinStyle: 'miter',
        pointBorderColor: hexToRGB(color, 1),
        pointBackgroundColor: '#fff',
        pointBorderWidth: 2,
        pointHoverRadius: 2,
        pointHoverBackgroundColor: hexToRGB(color, 1),
        pointHoverBorderColor: '#fff',
        pointHoverBorderWidth: 1,
        pointRadius: 3,
        hidden: hidden
    }
}
export function configBarChart(data){
    const config = {
        label: 'Segmentações',
        data: [],
        backgroundColor: [],
        borderWidth: 1
    };
    
    data.labels.forEach((element, i) => {
        config.data.push(data.values.total[i]);
        config.backgroundColor.push(hexToRGB(getColorIndex(i), 0.2));
    });
    
    console.log(config);

    return config
}
export function optionsChart(){
    return {
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero:true
                }
            }]
        },
    }
}