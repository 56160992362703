import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { ReactComponent as SvgContainer } from "./assets/bot.svg";

import api from "../Services/Api";

import {
  login,
  clear,
  isAuthenticated,
  setLeadsDownload,
} from "../Auth/AuthConfig";

export class Login extends Component {
  constructor(props) {
    super(props);
    isAuthenticated()
      ? this.props.history.push("/choose_company")
      : console.log("");
  }

  state = {
    username: "",
    password: "",
    error: "",
  };

  handleSignIn = async (e) => {
    e.preventDefault();
    this.setState({ error: "Aguarde..." });
    const { username, password } = this.state;
    if (!username || !password) {
      this.setState({ error: "Preencha e-mail e senha para continuar!" });
    } else {
      try {
        const response = await api.post("/login", { username, password });
        console.log(response);
        if (
          response.data.success === true &&
          (await login(response.data.token))
        ) {
          setLeadsDownload(response.data.leads_download);
          //console.log(response.data.leads_download);
          this.props.history.push("/choose_company");
        } else {
          clear();
          this.setState({
            error: "Houve um problema com o login, verifique suas credenciais.",
          });
        }
      } catch (err) {
        clear();
        this.setState({
          error: "Houve um problema com o login, verifique suas credenciais.",
        });
      }
    }
  };

  render() {
    window.title = "Log in | Buylitics";
    return (
      <div className="app-content box-shadow-0 light" role="main" id="login">
        <div className="left">
          <div className="brand">
            <img
              src="/assets/app/img/favicon-buylitics-1024x1024.png"
              alt="Logo Buylitics"
            />
            <span className="f16 fblack">
              Buylitics
              <br />
            </span>
          </div>

          <form onSubmit={this.handleSignIn} className="form_face">
            <div className="svgContainer">
              <div>
                <SvgContainer />
              </div>
            </div>
            {this.state.error && <p>{this.state.error}</p>}
            <div className="inputGroup inputGroup1 form-group mt20">
              <input
                type="text"
                id="email"
                name="username"
                className="email form-control"
                placeholder="E-mail"
                onChange={(e) => this.setState({ username: e.target.value })}
              />
              <span className="indicator"></span>
            </div>
            <div className="inputGroup inputGroup2 form-group">
              <input
                type="password"
                name="senha"
                id="password"
                className="password form-control"
                placeholder="Senha"
                onChange={(e) => this.setState({ password: e.target.value })}
              />
            </div>
            <div className="inputGroup inputGroup3">
              <button
                id="login"
                className="btn btn-info px-5 mt10 btn-rounded btn-shadow"
              >
                Entrar
              </button>
            </div>
          </form>
        </div>

        <div className="right">
          <div
            id="login_carousel"
            className="carousel carousel-fade slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators">
              <li
                data-target="#login_carousel"
                data-slide-to="0"
                className="active"
              ></li>
              <li data-target="#login_carousel" data-slide-to="1"></li>
              <li data-target="#login_carousel" data-slide-to="2"></li>
            </ol>
            <div className="carousel-inner">
              <div className="carousel-item teal active">
                <div className="cont">
                  <img
                    className="d-block box-shadow"
                    src="assets/app/img/screen_02.jpg"
                    alt="First slide"
                  />
                </div>
              </div>
              <div className="carousel-item blue">
                <div className="cont">
                  <img
                    className="d-block box-shadow"
                    src="assets/app/img/screen_03.jpg"
                    alt="Second slide"
                  />
                </div>
              </div>
              <div className="carousel-item purple">
                <div className="cont">
                  <img
                    className="d-block box-shadow"
                    src="assets/app/img/screen_01.jpg"
                    alt="Third slide"
                  />
                </div>
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#login_carousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#login_carousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
        <div className="version" style={{ display: "none" }}>
          version: 0.1.1
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
