import React, { Component } from 'react';

class SummaryJourneyWithNumberOfLeadsAndListAll extends Component {
    render() {
        return (
            <div className="col-sm-12 col-md-4">
                <div className="padding box box-shadow">
                    <div className="d-flex">
                        <span className="text-muted text-center w-100">Jornadas</span>
                    </div>
                    <div className="py-1 text-center text-lg text-danger lh" data-toggle="modal" data-target="#">
                        <a href="/" className="">
                            <i className="fa fa-sitemap f64 mt10 mb10 opacity90"></i>
                            <br />
                        </a>
                    </div>
                    <h6 className="text-center f300 mb10">Leads em cada jornada:</h6>
                    <React.Fragment>
                        {
                            this.props.fields.journey.map((data, i) => 
                                <div key={i} className="py-1 b-b mt5">
                                    <div className="float-right lh">
                                        <span className="text-md text-primary f700 d-block f14 mt5">{data.journey_total_leads}</span>
                                    </div>
                                    <span className="_600 d-block"> {data.journey_name}</span>
                                </div>
                            )
                        }
                    </React.Fragment>
                </div>
            </div>
        );
    }
}

export default SummaryJourneyWithNumberOfLeadsAndListAll;