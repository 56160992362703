import React, { Component } from 'react';

class PrePurchaseAndAttendanceDivisorTitle extends Component {
    render(){
        return (
            <div className="col-sm-12 col-md-12 col-lg-12">
                <button className="btn btn-fw dark text-center w-100 box mt20 mb10">{this.props.translate('DASHBOARD_ATTENDANCES_CARD_PRE_PURCHASE_AND_ATTENDANCE')}:</button>
            </div>
        );
    }
}

export default PrePurchaseAndAttendanceDivisorTitle;