import React, { Component } from 'react'
import {withRouter} from 'react-router-dom'

import $ from 'jquery';
import 'jquery-mask-plugin';

import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4'

import api from '../../../Services/Api';

import Modal from 'react-bootstrap/Modal';

import Checkbox from '../../../Structure/Checkbox'

class BusinessMidiaPlan extends Component {

    constructor(props){
        super(props)
        this.state = {
            
            modalResponseShow: false,
            msgResponseShow: false,
            modalRenewShow: false,
            valueRenewShow: [],
            media_renew: {
                months: null,
                value: []
            },
            media_current_month: [],
            media_other_months: [],
            current_screen_id: Date.now(),
            medias: [],
            mediasList: ""
        }
        this.handleCloseModalResponse =  this.handleCloseModalResponse.bind(this)
        this.handleChangeInputs = this.handleChangeInputs.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.addNewFieldMediaPlan = this.addNewFieldMediaPlan.bind(this);

        this.loadPlanCurrentMonth = this.loadPlanCurrentMonth.bind(this)
        
        this.handleCloseModalRenew  = this.handleCloseModalRenew.bind(this)
        this.openRenew = this.openRenew.bind(this)
        this.addRenewFieldMediaPlan = this.addRenewFieldMediaPlan.bind(this);
        this.setRenewMonth = this.setRenewMonth.bind(this);
        this.handleRenewSubmit = this.handleRenewSubmit.bind(this);
        this.handleRenewChangeInputs = this.handleRenewChangeInputs.bind(this)
        this.translate = this.props.translate
    }

    loadPlanCurrentMonth = async () => {
        try{
            await api.get("/business/"+ this.props.idBusiness +"/media_plan/current_month")
            .then(async response => {
                if(response.data.value !== null){
                    this.setState({
                        media_current_month: response.data.value.media_in_use
                    });
                }
            })
        }catch(err){
            console.log(err);
        }
    }

    loadPlanOtherMonth = async () => {
        try{
            await api.get("/business/"+ this.props.idBusiness +"/media_plan/other_months")
            .then(async response => {
                if(response.data.value !== null){
                    
                    this.setState({
                        media_other_months: response.data.value
                    });
                }
            })
        }catch(err){
            console.log(err);
        }
    }

    componentDidMount = async () => {
        this.loadMedias()
        this.loadPlanCurrentMonth()
        this.loadPlanOtherMonth()
    }

    handleCloseModalResponse(e = null){
        if (e) e.preventDefault()
        this.setState({modalResponseShow: false})
    }

    handleCloseModalRenew(){
        this.setState({modalRenewShow: false})
    }

    maskWhenLoaded(){
        let elementsToMask = document.getElementsByClassName("money-media-mask")
        for(let i = 0; i < elementsToMask.length; i++){
            let moneyMaskBehavior = function (){
                return "#.##0,00";
            },
            moneyOptions = {
                reverse: true,
                onKeyPress: function(val, e, field, options){
                    let value = e.target.value
                    elementsToMask[i].value = value
                    let callback = e.currentTarget.getAttribute("data-mask-callback")
                    let elementIndex = Number(e.currentTarget.getAttribute("data-index"))
                    let elementKey = e.currentTarget.getAttribute("data-key")
                    if(callback === "handleChangeInputs"){
                        this.handleChangeInputs(e, elementIndex, elementKey)
                    } else if(callback === "handleRenewChangeInputs"){
                        this.handleRenewChangeInputs(e, elementIndex, elementKey)
                    }
                   
                    field.mask(moneyMaskBehavior.apply({}, arguments), options);
                }.bind(this)
            };
            $(elementsToMask[i]).mask(moneyMaskBehavior, moneyOptions)
        }
    }

    handleChangeInputs = async (e, index, key) =>{
       
        let {media_current_month} = this.state;
        let valuex = ""

        switch(key){
            case "estimate":
                e.preventDefault()
                valuex = this.removeMask(e.target.value)
                break
            case 'value':
                e.preventDefault()
                if (e.target.value !== "") {
                    valuex = parseFloat(this.removeMask(e.target.value)).toFixed(2)
                    
                } else {
                    valuex = parseFloat("0")
                }
                break;
            case 'status':
                valuex = (e.target.checked)? true: false
                break;
            default:
                e.preventDefault();
                valuex = e.target.value
                break;
        }
        media_current_month[index]['change_history'].push({
            "name": key,
            "from_value": media_current_month[index][key],
            "to_value": valuex,
            "edited_on": Date.now(),
            "current_screen_id": this.state.current_screen_id
        })
        media_current_month[index][key] = valuex;
        this.setState({
            media_current_month,
        })
    }

    removeMask(value){
        value = value.split('.').join("")
        value = value.replace(",", ".")
        return value       
    }

    handleSubmit = async (e) =>{
        e.preventDefault();
        this.setState({ msgResponseShow: this.translate("BUSINESS_MEDIA_PLAN_WAIT_STATUS"), modalResponseShow: true });
        const data = this.state.media_current_month;
        console.log(data)
        try{
            const response = await api.post("/business/"+ this.props.idBusiness +"/media_plan/current_month", {data});
            if(response.data.success){
                this.setState({msgResponseShow: this.translate("BUSINESS_MEDIA_PLAN_SUCCESS_STATUS")});
                this.loadPlanCurrentMonth()
                this.loadPlanOtherMonth()
            }else{
                this.setState({msgResponseShow: this.translate("BUSINESS_MEDIA_PLAN_ERROR_STATUS")});
            }
        }catch(err){
            this.setState({msgResponseShow: this.translate("BUSINESS_MEDIA_PLAN_ERROR_STATUS")});
        }
    }

    addNewFieldMediaPlan = async (e) => {
        e.preventDefault();
        const element = document.getElementById('new-field-media-plan').value;
        if(element){

            let element_code = element.replace(/[\s]/gi, '-');
            element_code = element_code.toLowerCase();
            element_code = element_code.replace(/[^a-z0-9--]/gi, '');

            const {media_current_month} = this.state;
            media_current_month.push({
                "code": element_code,
                "name": element,
                "value": 0,
                "estimate": 0,
                "payment_method": false,
                "status": false,
                "editable": true,
                "change_history": []
            })
            this.setState({
                media_current_month,
            });
            document.getElementById('form-new-field-media-plan').reset();
        }
    }

    openRenew(e){
        e.preventDefault();
        const {media_renew} = this.state;
        media_renew['value'] = this.state.media_current_month
        this.setState({
            media_renew, modalRenewShow: true
        });
        console.log(this.state);
    }

    addRenewFieldMediaPlan = async (e) => {
        e.preventDefault();
        const element = document.getElementById('renew-field-media-plan').value;
        if(element){

            let element_code = element.replace(/[\s]/gi, '-');
            element_code = element_code.toLowerCase();
            element_code = element_code.replace(/[^a-z0-9--]/gi, '');

            const {media_renew} = this.state;
            media_renew.value.push({
                "code": element_code,
                "name": element,
                "value": 0,
                "estimate": 0,
                "payment_method": false,
                "status": false,
                "editable": true,
                "change_history": []
            })
            this.setState({
                media_renew,
            });
            document.getElementById('form-renew-field-media-plan').reset();
        }
    }

    setRenewMonth = async (e) => {
        e.preventDefault();
        const {media_renew} = this.state;
        media_renew.months = e.target.value
        this.setState({
            media_renew,
        });
    }
    
    handleRenewChangeInputs = async (e, index, key) =>{
        console.log(e.target, index, key)
        const {media_renew} = this.state;
        let valuex = '';
        switch(key){
            case 'estimate': 
                valuex = this.removeMask(e.target.value)
                break
            case 'value':
                e.preventDefault();
                if (e.target.value !== "") {
                    valuex = parseFloat(this.removeMask(e.target.value)).toFixed(2)
                } else {
                    valuex = parseFloat("0")
                }
                break;
            case 'status':
                valuex = (e.target.checked)? true: false
                break;
            default:
                e.preventDefault();
                valuex = e.target.value
                break;
        }
        media_renew['value'][index]['change_history'].push({
            "name": key,
            "from_value": media_renew['value'][index][key],
            "to_value": valuex,
            "edited_on": Date.now(),
            "current_screen_id": this.state.current_screen_id
        })
        media_renew['value'][index][key] = valuex;
        this.setState({
            media_renew,
        });
    }

    handleRenewSubmit = async (e) =>{
        e.preventDefault();
        this.setState({ msgResponseShow: this.translate("BUSINESS_MEDIA_PLAN_WAIT_STATUS"), modalResponseShow: true });
        const data = this.state.media_renew;
        try{
            const response = await api.post("/business/"+ this.props.idBusiness +"/media_plan/renew", {data});
            if(response.data.success){
                this.setState({msgResponseShow: this.translate("BUSINESS_MEDIA_PLAN_SUCCESS_STATUS"), modalRenewShow: false});
                this.loadPlanCurrentMonth()
                this.loadPlanOtherMonth()
            }else{
                this.setState({msgResponseShow: this.translate("BUSINESS_MEDIA_PLAN_ERROR_STATUS")});
            }
        }catch(err){
            this.setState({msgResponseShow: this.translate("BUSINESS_MEDIA_PLAN_ERROR_STATUS")});
        }
    }

    returnPaymentMethod(method){
        let name;
        switch(method){
            case 'card':
                name = this.translate("BUSINESS_MEDIA_PLAN_PAYMENT_METHOD_CARD")
                break;
            case 'billet':
                name = this.translate("BUSINESS_MEDIA_PLAN_PAYMENT_METHOD_BILLET")
                break;
            case 'credit_line':
                name = this.translate("BUSINESS_MEDIA_PLAN_PAYMENT_METHOD_CREDIT_LINE")
                break;
            case 'others':
                name = this.translate("BUSINESS_MEDIA_PLAN_PAYMENT_METHOD_OTHERS")
                break;
            default:
                name = null
                break;
        }
        return name;
    }

    loadMedias = async () => {
        try{
            const response = await api.get(`/business/${this.props.idBusiness}/media/json`);
            this.setState({
                medias: response.data
            })
        }catch(err){
            console.log(err);
        }
    }

    handleMediaAutoComplete = (e) => {
        let value = e.target.value
        let { medias } = this.state
        medias = medias.filter(media=>media.name.toLowerCase().includes(value.toLowerCase()))
        this.updateMediasList(medias)
        e.target.nextSibling.classList.remove("d-none")
    }
    
    handleMediasSuggestionsListBlur = (e) =>{
        if(e.currentTarget.nextSibling.getAttribute("data-mouse-is-over") !== "true"){
            e.target.nextSibling.classList.add("d-none")
        }
    }
    updateMediasList = (medias) => {
        let mediasList = medias.map((media, index)=>
            <li onClick={e=> this.handleMediasListSuggestionClick(e, media.name)} className="list-group-item btn-cursor-pointer" key={`${index}${media.key}`}>{media.name}</li>)
        this.setState({mediasList})
    }
    handleMediasListSuggestionClick = (e, mediaName) => {
        e.target.parentElement.previousSibling.value = mediaName
        e.target.parentElement.classList.add("d-none")
    }

    componentDidUpdate(){
        this.maskWhenLoaded()
        
    }
    render() {
        return (
            <React.Fragment>
                <h5 className="fwhite">{this.translate("BUSINESS_MEDIA_PLAN_MEDIA_PLAN")}</h5>
                <div id="accordion-midia-plan">
                    <div className="card">
                        <div className="card-header btn-cursor-pointer dark" id="headingOne" onClick={e=>this.props.toggleCollapse("#collapseOne")} aria-expanded="true" aria-controls="collapseOne">
                            <h5 className="mb-0">
                                {this.translate("BUSINESS_MEDIA_PLAN_CURRENT_MONTH")}
                                <i className="fa fa-caret-down pull-right"></i>
                            </h5>
                        </div>

                        <div id="collapseOne" className="collapse show dark" aria-labelledby="headingOne" data-parent="#accordion-midia-plan">
                            <div className="card-body">
                                <form onSubmit={this.addNewFieldMediaPlan} id="form-new-field-media-plan">
                                    <table className="table table-striped table-bordered dt-responsive nowrap" style={{border: "none", width:"100%"}}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <input type="text" id="new-field-media-plan" 
                                                        onKeyUp={e=>this.handleMediaAutoComplete(e)}
                                                        onBlur={e=>this.handleMediasSuggestionsListBlur(e)}
                                                        className="form-control" placeholder={this.translate("BUSINESS_MEDIA_PLAN_NEW_MEDIA_PLAN_PLACEHOLDER")}
                                                        autoComplete="off" 
                                                        required/>
                                                    <ul  
                                                        onMouseLeave={e=>e.currentTarget.setAttribute("data-mouse-is-over", false)}
                                                        onMouseEnter={e=>e.currentTarget.setAttribute("data-mouse-is-over", true)}
                                                        className="d-none list-group">{this.state.mediasList}
                                                    </ul>
                                                </td>
                                                <td style={{width:"40px"}}>
                                                    <button type="submit" className="btn btn-info btn-rounded btn-shadow pull-right">
                                                        <i className="fa fa-plus"></i>
                                                    </button>
                                                </td>
                                            </tr>                              
                                        </tbody>
                                    </table>
                                </form>
                                <form onSubmit={this.handleSubmit} className="width100" noValidate>
                                    <table className="table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                                        <thead>
                                            <tr className="text-left">
                                                <th>{this.translate("BUSINESS_MEDIA_PLAN_TABLE_MEDIA_NAME")}</th>
                                                <th>{this.translate("BUSINESS_MEDIA_PLAN_TABLE_PLANNED_INVESTMENT")}</th>
                                                <th>{this.translate("BUSINESS_MEDIA_PLAN_TABLE_MADE_INVESTMENT")}</th>
                                                <th>{this.translate("BUSINESS_MEDIA_PLAN_TABLE_PAYMENT_METHOD")}</th>
                                                <th>{this.translate("BUSINESS_MEDIA_PLAN_TABLE_PLAY_PAUSE")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.media_current_month.map(
                                                    (data, i) => (
                                                        <tr key={i} className="col-sm-12 col-md-12 col-lg-12">
                                                            <td>
                                                                <input
                                                                    onChange={e => this.handleChangeInputs(e, i, 'name')}
                                                                    type="text"
                                                                    className="form-control"
                                                                    defaultValue={data.name}
                                                                    disabled
                                                                    required
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    
                                                                    data-index={i}
                                                                    data-key="estimate"
                                                                    data-mask-callback="handleChangeInputs"
                                                                    type="text"
                                                                    className="form-control money-media-mask"
                                                                    step="any"
                                                                    min="0"
                                                                    defaultValue={data.estimate}
                                                                    disabled={(data.editable)?false:true}
                                                                    required
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    data-index={i}
                                                                    data-key="value"
                                                                    data-mask-callback="handleChangeInputs"
                                                                    type="text"
                                                                    className="form-control money-media-mask"
                                                                    step="any"
                                                                    min="0"
                                                                    defaultValue={data.value}
                                                                    disabled={(data.editable)?false:true}
                                                                    required
                                                                />
                                                            </td>
                                                            <td>
                                                                <select
                                                                    onChange={e => this.handleChangeInputs(e, i, 'payment_method')}
                                                                    className="form-control cool-select"
                                                                    disabled={(data.editable)?false:true}
                                                                    defaultValue={data.payment_method}
                                                                    required
                                                                >
                                                                        <option value="">{this.translate("FORM_SELECT_SELECT")}</option>
                                                                        <option value="card">{this.translate("BUSINESS_MEDIA_PLAN_PAYMENT_METHOD_CARD")}</option>
                                                                        <option value="billet">{this.translate("BUSINESS_MEDIA_PLAN_PAYMENT_METHOD_BILLET")}</option>
                                                                        <option value="credit_line">{this.translate("BUSINESS_MEDIA_PLAN_PAYMENT_METHOD_CREDIT_LINE")}</option>
                                                                        <option value="others">{this.translate("BUSINESS_MEDIA_PLAN_PAYMENT_METHOD_OTHERS")}</option>
                                                                </select>
                                                            </td>
                                                            <td className="playpause">
                                                                <Checkbox onChange={e => this.handleChangeInputs(e, i, 'status')} index={i} data={data}/>
                                                            </td>
                                                        </tr>
                                                    )
                                                )
                                            }
                                        </tbody>
                                    </table>
                                    <div className="box-divider m0 mt20 width100"></div>
                                    <br/>
                                    <div className="row">
                                        <div className="col-sm-6 col-md-6 col-lg-6 text-left">
                                            <button onClick={this.openRenew} type="button" className="btn btn-success btn-rounded btn-shadow">
                                                <i className="fa fa-new"></i> {this.translate("BUSINESS_MEDIA_PLAN_RENEW")}
                                            </button>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6 text-right">
                                            <button type="submit" className="btn btn-info btn-rounded btn-shadow pull-right">
                                                <i className="fa fa-save"></i> {this.translate("BUSINESS_MEDIA_PLAN_REGISTER_MEDIA_PLAN")}
                                            </button>
                                        </div>
                                    </div>
                                    <br/><br/>
                                </form>
                            </div>
                        </div>
                    </div>

                {
                    this.state.media_other_months.map((data, i) => (
                        <div key={i} className="card mt-3">

                            <div className="card-header btn-cursor-pointer dark" id={"MediaPlan" + i} onClick={e=>this.props.toggleCollapse("#collapseMediaPlan" + i)} aria-expanded="false" aria-controls={"collapseMediaPlan" + i}>
                                <h5 className="mb-0">
                                    {data.month_creation}
                                    <i className="fa fa-caret-down pull-right"></i>
                                </h5>
                            </div>

                            <div id={"collapseMediaPlan" + i} className="collapse dark" aria-labelledby={"MediaPlan" + i} data-parent="#accordion-midia-plan">
                                <div className="card-body">
                                    <table className="table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                                        <thead>
                                            <tr className="text-left">
                                                <th>{this.translate("BUSINESS_MEDIA_PLAN_TABLE_MEDIA_NAME")}</th>
                                                <th>{this.translate("BUSINESS_MEDIA_PLAN_TABLE_PLANNED_INVESTMENT")}</th>
                                                <th>{this.translate("BUSINESS_MEDIA_PLAN_TABLE_MADE_INVESTMENT")}</th>
                                                <th>{this.translate("BUSINESS_MEDIA_PLAN_TABLE_PAYMENT_METHOD")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            data.media_in_use.map(
                                                (month, iM) => (
                                                    <tr key={iM} className="col-sm-12 col-md-12 col-lg-12">
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={month.name}
                                                                disabled
                                                                required
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control money-media-mask"
                                                                step="any"
                                                                min="0"
                                                                defaultValue={month.estimate}
                                                                disabled
                                                                required
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control money-media-mask"
                                                                step="any"
                                                                min="0"
                                                                defaultValue={month.value}
                                                                disabled
                                                                required
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={this.returnPaymentMethod(month.payment_method)}
                                                                disabled
                                                                required
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            )
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    ))
                }
                
                </div>

                <Modal show={this.state.modalResponseShow} onHide={this.handleCloseModalResponse} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-bell"></i> {this.translate("MODAL_STATUS_STATUS")}</h5>
                                <a href="/" onClick={e=>this.handleCloseModalResponse(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                <span className="col-12 text-center py-2 lead">
                                    {this.state.msgResponseShow}
                                </span>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.modalRenewShow} onHide={this.handleCloseModalRenew} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <form onSubmit={this.addRenewFieldMediaPlan} id="form-renew-field-media-plan">
                            <table className="table table-striped table-bordered dt-responsive nowrap" style={{border: "none", width:"100%"}}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <input type="text" 
                                            id="renew-field-media-plan" 
                                            onKeyUp={e=>this.handleMediaAutoComplete(e)}
                                            onBlur={e=>this.handleMediasSuggestionsListBlur(e)}
                                            autoComplete="off"
                                            className="form-control" 
                                            placeholder={this.translate("BUSINESS_MEDIA_PLAN_NEW_MEDIA_PLAN_PLACEHOLDER")} required/>
                                            <ul  
                                                onMouseLeave={e=>e.currentTarget.setAttribute("data-mouse-is-over", false)}
                                                onMouseEnter={e=>e.currentTarget.setAttribute("data-mouse-is-over", true)}
                                                className="d-none list-group">{this.state.mediasList}
                                            </ul>
                                        </td>
                                        <td style={{width:"40px"}}>
                                            <button type="submit" className="btn btn-success btn-rounded btn-shadow pull-right">
                                                <i className="fa fa-plus"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                        <form onSubmit={this.handleRenewSubmit} className="width100" noValidate>
                            <table className="table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                                <thead>
                                    <tr className="text-left">
                                        <th>{this.translate("BUSINESS_MEDIA_PLAN_TABLE_MEDIA_NAME")}</th>
                                        <th>{this.translate("BUSINESS_MEDIA_PLAN_TABLE_PLANNED_INVESTMENT")}</th>
                                        <th>{this.translate("BUSINESS_MEDIA_PLAN_TABLE_MADE_INVESTMENT")}</th>
                                        <th>{this.translate("BUSINESS_MEDIA_PLAN_TABLE_PAYMENT_METHOD")}</th>
                                        <th>{this.translate("BUSINESS_MEDIA_PLAN_TABLE_PLAY_PAUSE")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.media_renew.value.map(
                                            (data, i) => (
                                                <tr key={i} className="col-sm-12 col-md-12 col-lg-12">
                                                    <td>
                                                        <input
                                                            onChange={e => this.handleRenewChangeInputs(e, i, 'name')}
                                                            type="text"
                                                            className="form-control"
                                                            defaultValue={data.name}
                                                            disabled
                                                            required
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            data-index={i}
                                                            data-key="estimate"
                                                            data-mask-callback="handleRenewChangeInputs"
                                                            type="text"
                                                            className="form-control money-media-mask"
                                                            step="any"
                                                            min="0"
                                                            defaultValue={data.estimate}
                                                            disabled={(data.editable)?false:true}
                                                            required
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            data-index={i}
                                                            data-key="value"
                                                            data-mask-callback="handleRenewChangeInputs"
                                                            type="text"
                                                            className="form-control money-media-mask"
                                                            step="any"
                                                            min="0"
                                                            defaultValue={data.value}
                                                            disabled={(data.editable)?false:true}
                                                            required
                                                        />
                                                    </td>
                                                    <td>
                                                        <select
                                                            onChange={e => this.handleRenewChangeInputs(e, i, 'payment_method')}
                                                            className="form-control cool-select"
                                                            disabled={(data.editable)?false:true}
                                                            defaultValue={data.payment_method}
                                                        >
                                                                <option value="card">{this.translate("BUSINESS_MEDIA_PLAN_PAYMENT_METHOD_CARD")}</option>
                                                                <option value="billet">{this.translate("BUSINESS_MEDIA_PLAN_PAYMENT_METHOD_BILLET")}</option>
                                                                <option value="credit_line">{this.translate("BUSINESS_MEDIA_PLAN_PAYMENT_METHOD_CREDIT_LINE")}</option>
                                                                <option value="others">{this.translate("BUSINESS_MEDIA_PLAN_PAYMENT_METHOD_OTHERS")}</option>
                                                        </select>
                                                    </td>
                                                    <td className="playpause">
                                                        <Checkbox onChange={e => this.handleRenewChangeInputs(e, i, 'status')} index={i} data={data} is={i}/>
                                                    </td>
                                                </tr>
                                            )
                                        )
                                    }
                                </tbody>
                            </table>
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <select onChange={this.setRenewMonth} className="form-control cool-select" required>
                                        <option value="">{this.translate("BUSINESS_MEDIA_PLAN_RENEW_MONTH_OPTION_DEFAULT")}</option>
                                        <option value="1">{this.translate("BUSINESS_MEDIA_PLAN_RENEW_MONTH_OPTION_1")}</option>
                                        <option value="2">{this.translate("BUSINESS_MEDIA_PLAN_RENEW_MONTH_OPTION_2")}</option>
                                        <option value="3">{this.translate("BUSINESS_MEDIA_PLAN_RENEW_MONTH_OPTION_3")}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="box-divider m0 mt20 width100"></div>
                            <br/>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12 text-right">
                                    <button type="submit" className="btn btn-info btn-rounded btn-shadow pull-right">
                                        <i className="fa fa-save"></i> {this.translate("BUSINESS_MEDIA_PLAN_REGISTER_MEDIA_PLAN")}
                                    </button>
                                </div>
                            </div>
                            <br/><br/>
                        </form>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(BusinessMidiaPlan);