import React, { Component, Fragment } from 'react'

import api from '../../../../Services/Api'
import Status from '../../../../Templates/Status'
import { maskPhone, unmaskPhone, maskPostalCode, unmaskPostalCode } from '../../../../Helpers/Masks'

export default class ContactComponent extends Component {

    constructor(props) {
        super(props)

        this.stageNumber = 4
        this.translate = this.props.translate
        this.state = {
            isLoading: true,
            status: false,
            name: "",
            phone: "",
            email: "",
            postalCode: "",
            homeNumber: "",
            state: "",
            city: "",
            street: "",
            neighborhood: ""
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.refundGeneralOptions !== this.props.refundGeneralOptions && this.props.currentStage === this.stageNumber) {
            this.loadContactInformation()
        }
    }

    loadContactInformation = async () => {
        this.setState({ isLoading: true })
        let response = await this.getContactInformation()
        if (response && response.success) {
            let phone = maskPhone(document.querySelector("#contact_phone"), response.contact_information.phone) || ""
            this.updatePostalCode(document.querySelector("#contact_postal_code"), response.contact_information.postal_code, false)
            this.setState({
                isLoading: false,
                status: false,
                phone: phone,
                name: response.contact_information.name,
                email: response.contact_information.email,
                homeNumber: response.contact_information.home_number,
                state: response.contact_information.state,
                city: response.contact_information.city,
                street: response.contact_information.street,
                neighborhood: response.contact_information.neighborhood,
            })

        } else {
            let message = (response && response.message) ? response.message : "Não foi possível carregar as informações de contato"
            this.clearFields()
            this.setState({
                isLoading: false,
                status: {
                    success: false,
                    message: message
                }
            })
        }
    }

    clearFields = () => {
        this.setState({
            name: "",
            email: "",
            phone: "",
            homeNumber: "",
            postalCode: "",
            state: "",
            city: "",
            street: "",
            neighborhood: "",
        })
    }

    getContactInformation = async () => {
        let response = false
        try {
            //let response = await api.post("/order/reverse/contact_information", {orderId: orderId})
            response = this.getContactInformationMockup()
            if (response && response.data.message) {
                response = response.data
            }
        } catch (error) {
            console.log(error)
        }
        return response
    }

    getContactInformationMockup = () => {
        return {
            data: {
                "success": true,
                "message": "Informações para contato consultadas com sucesso",
                "contact_information": {
                    "name": "Julio marcos piotronni",
                    "email": "petronni@julio.com.br",
                    "phone": "16999999999",
                    "postal_code": "13059581",
                    "street": "Rua teste do miguel da Silva",
                    "home_number": "221",
                    "neighborhood": "Parque São Luís",
                    "city": "Campinas",
                    "state": "São Paulo",
                }
            }
        }
    }

    updatePostalCode = async (element, value, shouldFetchAddress = true) => {
        let postalCode = maskPostalCode(element, value)
        if (!postalCode) return

        if (!shouldFetchAddress) {
            this.setState({ postalCode })
            return
        }

        if (postalCode.length >= 9) {
            this.updateAddressAfterPostalCodeChange(postalCode)
        }
    }

    updateAddressAfterPostalCodeChange = async (postalCode) => {
        let fetchedAddress = await this.fetchAddressPostalCode(postalCode)
        if (fetchedAddress && !fetchedAddress.errors) {
            let { state, city, street, neighborhood } = fetchedAddress
            this.setState({ state, city, street, neighborhood })
        }
    }

    fetchAddressPostalCode = async (postalCode) => {
        let response = null

        try {
            response = await fetch(`https://api.pagar.me/1/zipcodes/${unmaskPostalCode(postalCode)}`)
            response = await response.json()
        } catch (error) {
            response = null
            console.log(error)
        }

        return response
    }

    handleContactSubmit = (e) => {
        e.preventDefault()
        console.log("Teste")
        if (!this.validateForm()) return
        console.log("Teste2")
        this.props.checkStageIsCorrect(this.stageNumber)

        let phone = unmaskPhone(this.state.phone)
        this.props.handleGoToNextStage(this.stageNumber + 1, {
            refundContactOptions: {
                name: this.state.name,
                email: this.state.email,
                phone: phone,
                home_number: this.state.homeNumber,
                postal_code: unmaskPostalCode(this.state.postalCode),
                state: this.state.state,
                city: this.state.city,
                street: this.state.street,
                neighborhood: this.state.neighborhood,
            }
        })
    }

    validateForm = () => {
        console.log("alo")
        if (!this.state.name) return false
        if (!this.state.email) return false
        if (!this.state.phone) return false
        if (!this.state.postalCode) return false
        if (!this.state.homeNumber) return false
        if (!this.state.state) return false
        if (!this.state.city) return false
        if (!this.state.street) return false
        if (!this.state.neighborhood) return false
        return true
    }

    render = () =>
        this.props.currentStage === this.stageNumber &&

        <Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="box p-3">
                        <h3>Contato</h3>
                        <hr />
                        <Status status={this.state.status} />
                        <div className="row">
                            <div className="col-12">
                                <form className="form-row" onSubmit={this.handleContactSubmit}>
                                    <div className="col-12 mt-4">
                                        <h5>Informações para contato</h5>
                                        <hr />
                                    </div>
                                    <div className="form-group col-12 col-md-4">
                                        <label htmlFor="contact_name">Nome</label>
                                        <input
                                            id="contact_name"
                                            required
                                            type="text"
                                            className="form-control"
                                            placeholder="Digite o nome"
                                            value={this.state.name}
                                            onChange={e => this.setState({ name: e.target.value })}
                                        />
                                    </div>
                                    <div className="form-group col-12 col-md-4">
                                        <label htmlFor="contact_email">E-mail</label>
                                        <input
                                            id="contact_email"
                                            required
                                            type="email"
                                            className="form-control"
                                            placeholder="Digite o e-mail"
                                            value={this.state.email}
                                            onChange={e => this.setState({ email: e.target.value })}
                                        />
                                    </div>
                                    <div className="form-group col-12 col-md-4">
                                        <label htmlFor="contact_phone">Telefone</label>
                                        <input
                                            id="contact_phone"
                                            required
                                            type="text"
                                            className="form-control"
                                            placeholder="Digite o telefone"
                                            onChange={e => this.setState({ phone: maskPhone(e.target, e.target.value) })}
                                        />
                                    </div>
                                    <div className="col-12 mt-4">
                                        <h5>Informações de Endereço</h5>
                                        <hr />
                                    </div>
                                    <div className="form-group col-12 col-md-4">
                                        <label htmlFor="contact_postal_code">CEP</label>
                                        <input
                                            id="contact_postal_code"
                                            required
                                            type="text"
                                            className="form-control"
                                            placeholder="Digite o CEP"
                                            onChange={e => this.updatePostalCode(e.target, e.target.value)}
                                        />
                                    </div>

                                    <div className="form-group col-12 col-md-4">
                                        <label htmlFor="contact_street">Rua</label>
                                        <input
                                            id="contact_street"
                                            required
                                            type="text"
                                            className="form-control"
                                            placeholder="Digite o nome da rua"
                                            value={this.state.street}
                                            onChange={e => this.setState({ street: e.target.value })}
                                        />
                                    </div>
                                    <div className="form-group col-12 col-md-4">
                                        <label htmlFor="contact_neighborhood">Bairro</label>
                                        <input
                                            id="contact_neighborhood"
                                            required
                                            type="text"
                                            className="form-control"
                                            placeholder="Digite o nome do bairro"
                                            value={this.state.neighborhood}
                                            onChange={e => this.setState({ neighborhood: e.target.value })}
                                        />
                                    </div>
                                    <div className="form-group col-12 col-md-4">
                                        <label htmlFor="contact_home_number">Número da Residência</label>
                                        <input
                                            id="contact_home_number"
                                            required
                                            type="number"
                                            className="form-control"
                                            placeholder="Digite o número da residência"
                                            value={this.state.homeNumber}
                                            onChange={e => this.setState({ homeNumber: e.target.value })}
                                        />
                                    </div>
                                    <div className="form-group col-12 col-md-4">
                                        <label htmlFor="contact_city">Cidade</label>
                                        <input
                                            id="contact_city"
                                            required
                                            type="text"
                                            className="form-control"
                                            placeholder="Digite o nome da Cidade"
                                            value={this.state.city}
                                            onChange={e => this.setState({ city: e.target.value })}
                                        />
                                    </div>
                                    <div className="form-group col-12 col-md-4">
                                        <label htmlFor="contact_state">Estado</label>
                                        <input
                                            id="contact_state"
                                            required
                                            type="text"
                                            className="form-control"
                                            placeholder="Digite o nome do estado"
                                            value={this.state.state}
                                            onChange={e => this.setState({ state: e.target.value })}
                                        />
                                    </div>
                                    <div className="form-group col-12">
                                        <button className="btn btn-cursor-pointer btn-fw blue btn-rounded btn-shadow ml-2" type="submit">
                                            <i className="fa fa-check"></i>&nbsp;
                                            Avançar
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
}
