import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';

import $ from "jquery"

import MenuMain from '../../../Structure/MenuMain';
import HeaderMain from '../../../Structure/HeaderMain';
import FooterMain from '../../../Structure/FooterMain';
import Managers from './Components/Managers';
import Qualifiers from './Components/Qualifiers';

class Qualification extends Component {

    constructor(props){
        super(props)
        this.translate = this.props.translate
        document.title = this.translate("QUALIFICATION_QUALIFICATION_TEAM")
    }
    toggleCollapse = (selector) => $(selector).collapse("toggle")
   
    render(){
        return(
            <React.Fragment>
                <MenuMain />
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    <HeaderMain title={document.title} onChange={this.handleChange}/>
                    <div className="content-main admin" id="content-main">
                        <div className="padding">
                            <div className="row pt20">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">

                                    <ul className="nav nav-pills nav-sm mb10" id="admin_tab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-target="#tab_managers" data-toggle="tab" href="#tab_managers" role="tab" aria-controls="tab_managers" aria-selected="false">{this.translate("QUALIFICATION_MANAGERS")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-target="#tab_qualifiers" data-toggle="tab" href="#tab_qualifiers" role="tab" aria-controls="tab_qualifiers" aria-selected="true">{this.translate("QUALIFICATION_QUALIFIERS")}</a>
                                        </li>
                                    </ul>

                                    <div className="tab-content" id="qualification_tab">
                                        <div className="tab-pane pb40 fade show active p-3 white box-shadow" id="tab_managers" role="tabpanel" aria-labelledby="">
                                            <Managers toggleCollapse={this.toggleCollapse} translate={this.translate}/>
                                        </div>
                                        <div className="tab-pane pb40 fade p-3 white box-shadow" id="tab_qualifiers" role="tabpanel" aria-labelledby="">
                                            <Qualifiers toggleCollapse={this.toggleCollapse} translate={this.translate}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterMain />
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(Qualification);