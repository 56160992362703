import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import api from '../Services/Api';

import translate from "../Helpers/Translator"

import { getUserCompanies } from "../Auth/AuthConfig"

export class MenuMain extends Component {

    constructor(props) {
        super(props)
        this.state = {
            links: [],
            userCompanies: []
        }
        this.translate = translate
    }

    async componentDidMount() {
        await api.get('/account/menu')
            .then(response => {
                const links = response.data;
                const userCompanies = getUserCompanies();
                this.setState(
                    {
                        links,
                        userCompanies
                    }
                );
            })
    }

    checkSubLink = (link) => {
        if (typeof link['children'] !== 'undefined' && link['children'] && link['routes'] !== 'undefined' && link['routes'].length) {
            return true;
        } else {
            return false;
        }
    }

    getSubLink = (link) => {
        if (typeof link['children'] !== 'undefined' && link['children'] && link['routes'] !== 'undefined' && link['routes'].length) {
            return '#';
        } else {
            return link.route;
        }
    }

    renderLink = () => {
        return this.state.links.map((link, i) =>
            <li key={i}>
                <a href={this.getSubLink(link)} data-pjax-click-state="external">
                    <span className="nav-icon">
                        <i className={link.classIcon}></i>
                    </span>
                    {
                        (this.checkSubLink(link)) ? (
                            <span className="nav-caret">
                                <i className="fa fa-caret-down"></i>
                            </span>
                        ) : (
                                false
                            )
                    }
                    <span className="nav-text">{link.label}</span>
                </a>
                {
                    (this.checkSubLink(link)) ? (
                        <ul className="nav-sub">
                            {
                                link.routes.map(
                                    (subLink, sI) => <li key={sI}>
                                        <a href={subLink.route} data-pjax-click-state="external">
                                            <span className="nav-icon">
                                                <i className={subLink.classIcon}></i>
                                            </span>
                                            <span className="nav-text">{subLink.label}</span>
                                        </a>
                                    </li>
                                )
                            }
                        </ul>
                    ) : (
                            false
                        )
                }
            </li>
        )
    }

    //onClick={(e) =>{e.preventDefault();alert('Em Manutenção, Aguarde!');}}

    render() {
        return (
            <div id="aside" className={"app-aside fade box-shadow-x nav-expand white " + this.props.addClass} aria-hidden="true">
                <div className="sidenav modal-dialog dk" ui-class="dark">
                    <div className="navbar lt dark" ui-class="dark">
                        <a href="/dashboard" className="navbar-brand">
                            <img src="/assets/app/img/favicon-buylitics-full.png" alt="." className="" />
                            <span className="hidden-folded d-inline">{this.translate('MENU_MAIN_BUYLITICS')}</span>
                        </a>
                    </div>

                    <div className="flex hide-scroll">
                        <div className="scroll">
                            <div className="nav-border b-primary" data-nav="">
                                <ul className="nav bg">
                                    {(this.state.userCompanies.length !== 1) && <li className="nav-header">
                                        <div className="py-3">
                                            <Link to="/choose_company" className="btn btn-sm success theme-accent btn-block">
                                                <i className="fa fa-fw fa-refresh"></i>
                                                <span className="hidden-folded d-inline">{this.translate('MENU_MAIN_CHANGE')}</span>
                                            </Link>
                                        </div>
                                        <span className="text-xs hidden-folded">{this.translate('MENU_MAIN_MAIN')}</span>
                                    </li>}
                                    {this.renderLink()}
                                </ul>
                                <ul className="nav bg mt-2">
                                    {/* <li>
                                        <a href="/config" onClick={(e) =>{e.preventDefault();alert(this.translate('MENU_MAIN_IN_MAINTENANCE_WAIT'));}}>
                                            <span className="nav-icon">
                                                <i className="fa fa-wrench"></i>
                                            </span>
                                            <span className="nav-text">{this.translate('MENU_MAIN_CONFIG')}</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/support" onClick={(e) =>{e.preventDefault();alert(this.translate('MENU_MAIN_COMING_SOON'));}}>
                                            <span className="nav-icon">
                                                <i className="fa fa-commenting-o"></i>
                                            </span>
                                            <span className="nav-text">{this.translate('MENU_MAIN_SUPPORT_COMING_SOON')}</span>
                                        </a>
                                    </li> */}
                                    <li>
                                        <Link to='/logout'>
                                            <span className="nav-icon">
                                                <i className="fa fa-times"></i>
                                            </span>
                                            <span className="nav-text">{this.translate('MENU_MAIN_SIGN_OUT')}</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

}

export default withRouter(MenuMain);