import React, { Component } from 'react';
import Chart from 'chart.js';

import {app, hexToRGB} from '../Structure/Configs/Theme';

class ChartLastInteractedJourney extends Component {

    componentDidMount(){
        const myChart = document.getElementById('chart-line-leads').getContext('2d');
        new Chart(myChart, {
            type: 'bar',
            data: {
                labels: this.props.fields.months,
                datasets: [
                    {
                        label: 'Leads',
                        type: 'line',
                        data: this.props.fields.lead_amounts,
                        fill: true,
                        backgroundColor: hexToRGB(app.color.primary, 0.4),
                        borderColor: hexToRGB(app.color.primary, 1),
                        borderWidth: 2,
                        borderJoinStyle: 'miter',
                        pointBorderColor: hexToRGB(app.color.primary, 1),
                        pointBackgroundColor: '#fff',
                        pointBorderWidth: 2,
                        pointHoverRadius: 2,
                        pointHoverBackgroundColor: hexToRGB(app.color.primary, 1),
                        pointHoverBorderColor: '#fff',
                        pointHoverBorderWidth: 1,
                        pointRadius: 3
                    }
                ]
            },
            options: {
            }
        })
    }

    render() {
        return (
            <div className="col-md-7 col-sm-12 mt20">
                <div className="padding box box-shadow">
                    <div className="d-flex">
                        <span className="text-muted text-center w-100">Últimos que interagiram com a jornada</span>
                        <hr className="b-b d-block" />
                    </div>
                    <canvas id="chart-line-leads" data-plugin="chart" height={180} width="" className="chartjs-render-monitor position10"></canvas>
                </div>
            </div>
        );
    }
}

export default ChartLastInteractedJourney;