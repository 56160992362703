import React, { Component, Fragment } from 'react';

import $ from 'jquery';

import api from '../../../Services/Api';

class VisitedPage extends Component {
    _isMounted = false;

    constructor(props){
        super(props)
        this.state = {
            idAction: this.props.values.idAction,
            loaded: false,
            alertResponse: null,
            formValuesAction: {
                page_type: 'any_page',
                url_page: null
            }
        }
        this.formSubmitSave = this.formSubmitSave.bind(this)
        this.translate =  this.props.translate
    }

    componentDidMount(){
        api.get('/journey/action/' + this.props.values.idAction).then(async response => {
            this.setState({formValuesAction: response.data, loaded: true})
            console.log(response);
            console.log(this.state.formValuesAction)
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async formSubmitSave(e){
        e.preventDefault()
        $('#button_submit_form_action').prop('disabled', true).html(`<i className="fa fa-spinner"></i> ${this.translate('BUTTON_WAIT')} ...`)
        this.setState({alertResponse: null})
        const data = this.state.formValuesAction
        try{
            const response = await api.post("/journey/action/" + this.props.values.idAction, data);
            if(response.data.success){
                this.setState({alertResponse: true})
                $('#button_submit_form_action').prop('disabled', false).html(`<i className="fa fa-save"></i> ${this.translate('BUTTON_REGISTER')}`)
            }else{
                this.setState({alertResponse: false})
            }
        }catch(err){
            console.log(err)
        }
    }

    render() {
        return (
            <Fragment>
                {
                    (this.state.alertResponse === false)?(
                        <div className="alert alert-danger alert-dismissible" role="alert">
                            {this.translate('VISITED_PAGE_ERROR_REGISTERING_TRIGGER')}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    ):(
                        <React.Fragment>
                            {
                                (this.state.alertResponse)?(
                                    <div className="alert alert-success alert-dismissible" role="alert">
                                        {this.translate('VISITED_PAGE_SUCCESS_REGISTERING_TRIGGER')}
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                ):(false)
                            }
                        </React.Fragment>
                    )
                }
                {
                    (this.state.loaded)?(
                        <form onSubmit={this.formSubmitSave}>
                            <div className="form-group">
                                <label htmlFor="trigger_visited_page_any_page">
                                    <input type="radio" onChange={e => this.setState({formValuesAction: {...this.state.formValuesAction, page_type:  e.target.value}})} name="page_type" id="trigger_visited_page_any_page" className="mr-1" defaultValue="any_page" defaultChecked={this.state.formValuesAction.page_type === 'any_page'}/>
                                    {this.translate('VISITED_PAGE_ANY_PAGE')}
                                </label>
                            </div>
                            <div className="form-group">
                                <label htmlFor="trigger_visited_page_specific_page">
                                    <input type="radio" onChange={e => this.setState({formValuesAction: {...this.state.formValuesAction, page_type:  e.target.value}})} name="page_type" id="trigger_visited_page_specific_page" className="mr-1" defaultValue="specific_page" defaultChecked={this.state.formValuesAction.page_type === 'specific_page'}/>
                                    {this.translate('VISITED_PAGE_SPECIFIC_PAGE')}
                                    
                                </label>
                            </div>
                            {
                                (this.state.formValuesAction.page_type === 'specific_page')?(
                                    <div className="form-group">
                                        <input type="type" onChange={e => this.setState({formValuesAction: {...this.state.formValuesAction, url_page:  e.target.value}})} id="trigger_visited_page_url_page" className="form-control" placeholder={this.translate('VISITED_PAGE_PLACEHOLDER_URL_SPECIFIC_PAGE')} defaultValue={this.state.formValuesAction.url_page} required/>
                                    </div>
                                ):(false)
                            }
                            <div className="form-group">
                                <button type="submit" id="button_submit_form_action" className="btn btn-cursor-pointer btn-success float-right" style={{width: '100%'}}>
                                    <i className="fa fa-save"></i> {this.translate('BUTTON_REGISTER')}
                                </button>
                            </div>
                        </form>
                    ):(
                        <div>
                            {this.translate('VISITED_PAGE_LOADING_VISITED_PAGE_FORM')}
                        </div>
                    )
                }
            </Fragment>
        );
    }
}

export default VisitedPage;