import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';

import StandardStructure from '../Structure/StandardStructure';

import api from '../Services/Api';

class DashboardBusiness extends Component {

    constructor(props){
        super(props);
        this.state = {
            app: null,
            background: true,
            period: 'current_month',
            periodName: 'Mês Atual',
            cardCustom: false,
            id: 'content-main',
            classCustom: 'dash_client'
        }
    }

    async returnResponse(period = this.state.period){
        let periodPost;
        if(this.state.period !== period){
            periodPost = period
        }else{
            periodPost = this.state.period
        }
        return await api.post('/build/dashboard/business', {period: periodPost})
        .then(async response => {
            return response.data;
        })
    }

    async returnResponseCustom(period_initial, period_end){
        return await api.post('/build/dashboard/business/custom_period', {period_initial: period_initial, period_end: period_end})
        .then(async response => {
            return response.data;
        })
    }
    
    handleChange = (period, periodName, cardCustom, period_initial = false, period_end = false) => {
        console.log("alo")
        if(period !== 'custom_date' && !period_initial && !period_end){
            this.returnResponse(period, periodName).then((data) => {
                this.setState({
                    app: data.app,
                    background: data.background,
                    period: period,
                    periodName: periodName,
                    cardCustom: cardCustom
                });
            })
        }else{
            this.handleChangeCustom(period_initial, period_end, cardCustom)
        }
    }

    handleChangeCustom = (period_initial, period_end, cardCustom) => {
       
        this.returnResponseCustom(period_initial, period_end).then((data) => {
            this.setState({
                app: data.app,
                background: data.background,
                periodName: data.periodName,
                cardCustom: cardCustom
            });
        })
    }
    
    componentDidMount = async () => {
        this.returnResponse().then((data) => {
            console.log(data)
            this.setState({
                app: data.app,
                background: data.background
            });
        })
    }

    /*
    async componentWillMount(){
        this.returnResponse().then((data) => {
            this.setState({
                app: data.app,
                background: data.background
            });
        })
    }
    */

    render() {
        return(
            <React.Fragment>
                {
                    this.state.app != null ? (
                        <StandardStructure
                            onChange={this.handleChange}
                            title="Dashboard Business"
                            cards={this.state.app}
                            periodName={this.state.periodName}
                            cardCustom={this.state.cardCustom}
                            id={this.state.id}
                            classCustom={this.state.classCustom}
                            background={this.state.background}
                        />
                    ):(
                        <div>
                            Carregando...
                        </div>
                    )
                }
            </React.Fragment>
        )
    }
}

export default withRouter(DashboardBusiness);