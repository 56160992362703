import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';

import Chart from 'chart.js';
import {configChart, optionsChart} from './_Configs/ChartConfigs';

import $ from 'jquery';
import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4'

import {ajaxUrl, ajaxToken} from '../../../Services/Ajax';

import api from '../../../Services/Api';

class StageSalesBuy extends Component {

    constructor(props){
        super(props);
        this.state = {
            tableLoad: null
        }
    }

    componentDidMount = async () =>{

        await api.get('/dashboard/funnel/sales/buy/chart/monthly').then(async response => {
            const myChartMonthly = document.getElementById('stage_sales_buy_chart_monthly').getContext('2d');
            new Chart(myChartMonthly, {
                type: "bar",
                data: {
                    labels: response.data.monthly,
                    datasets: [
                        configChart('Total de Compras', 'primary', response.data.values.total),
                    ]
                },
                options: optionsChart()
            })
        })

        await api.get('/dashboard/funnel/sales/buy/chart/daily').then(async response => {
            const myChartDaily = document.getElementById('stage_sales_buy_chart_daily').getContext('2d');
            new Chart(myChartDaily, {
                type: "bar",
                data: {
                    labels: response.data.daily,
                    datasets: [
                        configChart('Total de Compras', 'primary', response.data.values.total),
                    ]
                },
                options: optionsChart()
            })
        })

        let tableLoad = this.state.tableLoad
        tableLoad = $('#stage_sales_buy_table').DataTable({
            "searching": false,
            "paging": false,
            "info": false,
            "responsive": true,
            "language": {
                "url": "//cdn.datatables.net/plug-ins/1.10.13/i18n/Portuguese-Brasil.json"
            },
            "ajax": {
                "url": ajaxUrl('/dashboard/funnel/sales/buy/table'),
                "type": 'GET',
                "beforeSend": function (request) {
                    request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                }
            }
        });

        console.log(tableLoad)
    }
    
    
    handleClose = e =>{
        e.preventDefault();
        this.props.onChange('close')
    }

    render(){
        return (
            <div className="d-flex flex-column h-100 bg-blur_w2">
                <div className="p-3 b-b d-flex no-shrink green text-center position10">
                    <h5 className="h5 m-0 text-center width100 line-height-38px">
                    <i className="fa fa-clone"></i> Compras</h5>
                    <button onClick={this.handleClose} className="btn btn-transparent ml-auto">
                        <span className="text-lg l-h-1x">×</span>
                    </button>
                </div>
                <div className="row position10" style={{padding: '15px'}}>
                    <div className="col-12 col-md-6">
                        <div className="padding bg-blur_w position10">
                            <div className="d-flex">
                                <p className="uppercase text-muted f10">Grafico Mensal</p>
                            </div>
                            <canvas id="stage_sales_buy_chart_monthly" width={624} height={300} className="chartjs-render-monitor position10"/>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="padding bg-blur_w position10">
                            <div className="d-flex">
                                <p className="uppercase text-muted f10">Grafico Diário</p>
                            </div>
                            <canvas id="stage_sales_buy_chart_daily" width={624} height={300} className="chartjs-render-monitor position10"/>
                        </div>
                    </div>
                    <div className="col-12 col-md-12">
                        <div className="bootstrap-table">
                            <div className="fixed-table-container">
                                <div className="fixed-table-body">
                                    <table id="stage_sales_buy_table" className="table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                                        <thead>
                                            <tr className="text-left">
                                                <th>Lead</th>
                                                <th>Segmentação</th>
                                                <th>Origem</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(StageSalesBuy);