import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'

import $ from 'jquery'

import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4'

import api from '../../Services/Api';
import {ajaxUrl, ajaxToken} from '../../Services/Ajax';

import Modal from 'react-bootstrap/Modal';

import MenuMain from '../../Structure/MenuMain';
import FooterMain from '../../Structure/FooterMain';

class HomeQualification extends Component{

    constructor(props){
        super(props);
        this.translate = this.props.translate
        document.title = this.translate('HOME_QUALIFICATION_LEADS_QUALIFICATION')
        this.state = {
            tableLeadsWaiting: null,
            tableLeadsQualified: null,
            tableLeadsDiscarded: null,
            modalEditLead: false,
            modalEditStatus: false,
            modalInviteLead: false,
            modalDiscardLead: false,
            modalViewLeadQualified: false,
            modalViewLeadDiscard: false,
            listQualifiers: [],
            listCommercial: [],
            formValuesEditLead: {
                qualifier: null,
                name: null,
                email: null,
                phone: null,
                additional_values: []
            },
            formValuesEditStatus: {
                status: null,
            },
            formValuesInviteLead: {
                distribution_type: null,
                commercial: null
            },
            formValuesDiscardLead: {
                reason_discard: null
            },
            viewLeadQualified: {},
            viewLeadDiscard: {},
        }

        this.closeModalEditLead = this.closeModalEditLead.bind(this)
        this.showModalEditLead = this.showModalEditLead.bind(this)
        this.formSubmitEditLead = this.formSubmitEditLead.bind(this)
        this.addNewFieldFormValuesEditLead = this.addNewFieldFormValuesEditLead.bind(this)
        this.changeValueAdditionalValues = this.changeValueAdditionalValues.bind(this)

        this.closeModalEditStatus = this.closeModalEditStatus.bind(this)
        this.showModalEditStatus = this.showModalEditStatus.bind(this)
        this.formSubmitEditStatus = this.formSubmitEditStatus.bind(this)
        
        this.closeModalInviteLead = this.closeModalInviteLead.bind(this)
        this.showModalInviteLead = this.showModalInviteLead.bind(this)
        this.formSubmitInviteLead = this.formSubmitInviteLead.bind(this)
        
        this.closeModalDiscardLead = this.closeModalDiscardLead.bind(this)
        this.showModalDiscardLead = this.showModalDiscardLead.bind(this)
        this.formSubmitDiscardLead = this.formSubmitDiscardLead.bind(this)

        this.closeModalViewLeadQualified = this.closeModalViewLeadQualified.bind(this)
        this.showModalViewLeadQualified = this.showModalViewLeadQualified.bind(this)
        
        this.closeModalViewLeadDiscard = this.closeModalViewLeadDiscard.bind(this)
        this.showModalViewLeadDiscard = this.showModalViewLeadDiscard.bind(this)

    }

    showModalEditLead(form_id){
        try{
            api.get("/qualification/lead/form/" + form_id).then(async response => {
                if(response.data.success){
                    this.setState({
                        formValuesEditLead: response.data.values,
                        modalEditLead: true,
                    })
                }
            })
        }catch(error){
            console.log(error);
        }
    }
    closeModalEditLead(){
        this.setState({modalEditLead: false})
    }
    formSubmitEditLead(e){
        e.preventDefault()
        try{
            const data = this.state.formValuesEditLead;
            api.post("/qualification/lead/edit/values", data).then(async response => {
                if(response.data.success){
                    alert(this.translate('HOME_QUALIFICATION_LEADS_SUCCESS_STATUS'));
                }else{
                    alert(response.data.msg);
                }
            })
        }catch(error){
            console.log(error);
        }
    }
    addNewFieldFormValuesEditLead(e){
        e.preventDefault();
        const {formValuesEditLead} = this.state;
        formValuesEditLead['additional_values'].push({
            name: 'additional',
            value: null
        })
        this.setState({
            formValuesEditLead: formValuesEditLead
        })
    }
    changeValueAdditionalValues(e){
        const index = e.target.dataset['index'];
        const {formValuesEditLead} = this.state;
        formValuesEditLead['additional_values'][index]['value'] = e.target.value
        this.setState({
            formValuesEditLead: formValuesEditLead
        })
    }

    showModalEditStatus(form_id){
        this.setState(
            {
                formValuesEditStatus: {...this.state.formValuesEditStatus, form_id: form_id},
                modalEditStatus: true
            }
        )
    }
    closeModalEditStatus(){
        this.setState({modalEditStatus: false})
    }
    formSubmitEditStatus(e){
        e.preventDefault()
        try{
            const data = this.state.formValuesEditStatus;
            api.post("/qualification/lead/edit/status", data).then(async response => {
                if(response.data.success){
                    $('#list_leads_waiting').DataTable().ajax.reload();
                    alert(this.translate('HOME_QUALIFICATION_STATUS_SUCCESS_STATUS'));
                    this.closeModalEditStatus();
                }else{
                    alert(response.data.msg);
                }
            })
        }catch(error){
            console.log(error);
        }
    }

    showModalInviteLead(form_id){
        this.setState(
            {
                formValuesInviteLead: {...this.state.formValuesInviteLead, form_id: form_id},
                modalInviteLead: true
            }
        )
    }
    closeModalInviteLead(){
        this.setState({modalInviteLead: false})
    }
    formSubmitInviteLead(e){
        e.preventDefault()
        try{
            const data = this.state.formValuesInviteLead;
            api.post("/qualification/lead/invite", data).then(async response => {
                if(response.data.success){
                    $('#list_leads_waiting').DataTable().ajax.reload();
                    $('#list_leads_qualified').DataTable().ajax.reload();
                    alert(this.translate('HOME_QUALIFICATION_LEADS_SUCCESS_DISTRIBUTION_STATUS'));
                    this.closeModalDiscardLead();
                }else{
                    alert(response.data.msg);
                }
            })
        }catch(error){
            console.log(error);
        }
    }

    showModalDiscardLead(form_id){
        this.setState(
            {
                formValuesDiscardLead: {...this.state.formValuesDiscardLead, form_id: form_id},
                modalDiscardLead: true
            }
        )
    }
    closeModalDiscardLead(){
        this.setState({modalDiscardLead: false})
    }
    formSubmitDiscardLead(e){
        e.preventDefault()
        try{
            const data = this.state.formValuesDiscardLead;
            api.post("/qualification/lead/discard", data).then(async response => {
                if(response.data.success){
                    $('#list_leads_waiting').DataTable().ajax.reload();
                    $('#list_leads_discarded').DataTable().ajax.reload();
                    alert(this.translate('HOME_QUALIFICATION_LEADS_SUCCESS_DISCARD_STATUS'));
                    this.closeModalDiscardLead();
                }else{
                    alert(response.data.msg);
                }
            })
        }catch(error){
            console.log(error);
        }
    }

    showModalViewLeadQualified(idLead){
        this.setState({modalViewLeadQualified: true})
    }
    closeModalViewLeadQualified(){
        this.setState({modalViewLeadQualified: false})
    }

    showModalViewLeadDiscard(idLead){
        this.setState({modalViewLeadDiscard: true})
    }
    closeModalViewLeadDiscard(){
        this.setState({modalViewLeadDiscard: false})
    }

    componentDidMount(){

        api.get("/qualifiers/select").then(async response => {
            this.setState({
                listQualifiers: response.data,
            })
        })

        api.get("/qualifiers/commercial/select").then(async response => {
            this.setState({
                listCommercial: response.data,
            })
        })

        let tableLeadsWaiting = this.state.tableLeadsWaiting
        let tableLeadsQualified = this.state.tableLeadsQualified
        let tableLeadsDiscarded = this.state.tableLeadsDiscarded

        tableLeadsWaiting = $('#list_leads_waiting').DataTable({
            "searching": true,
            "paging": true,
            "info": true,
            "responsive": true,
            "language": {
                "url": this.translate('DATATABLE_LANGUAGE_URL')
            },
            "ajax": {
                "url": ajaxUrl('/qualifiers/waiting/table'),
                "type": 'GET',
                "beforeSend": function (request) {
                    request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                }
            },
            "columnDefs": [{
                "targets": -1,
                "data": null,
                "defaultContent": 
                    `<div class="btn-group btn-shadow">
                        <button class="edit_lead btn btn-sm black green btn-cursor-pointer" title="${this.translate('HOME_QUALIFICATION_EDIT_LEAD_BUTTON')}">
                            <i class="fa fa-cogs" title="${this.translate('HOME_QUALIFICATION_EDIT_LEAD_BUTTON')}"></i>
                        </button>
                    </div>
                    <div class="btn-group btn-shadow">
                        <button class="set_status btn btn-sm blue btn-cursor-pointer" title="${this.translate('HOME_QUALIFICATION_EDIT_STATUS_BUTTON')}">
                            <i class="fa fa-signal" title="${this.translate('HOME_QUALIFICATION_EDIT_STATUS_BUTTON')}"></i>
                        </button>
                    </div>
                    <div class="btn-group btn-shadow">
                        <button class="invite btn btn-sm purple btn-cursor-pointer" title="${this.translate('HOME_QUALIFICATION_SEND_AGENT_BUTTON')}">
                            <i class="fa fa-send-o" title="${this.translate('HOME_QUALIFICATION_SEND_AGENT_BUTTON')}"></i>
                        </button>
                    </div>
                    <div class="btn-group btn-shadow">
                        <button class="discard btn btn-sm red btn-cursor-pointer" title="${this.translate('HOME_QUALIFICATION_DISCARD_BUTTON')}">
                            <i class="fa fa-ban" title="${this.translate('HOME_QUALIFICATION_DISCARD_BUTTON')}"></i>
                        </button>
                    </div>`
            }]
        });

        $('#list_leads_waiting tbody').on( 'click', '.edit_lead', function (e){
            e.preventDefault();
            let data = [];
            if($(e.target).parents('tr').hasClass('child')){
                data = tableLeadsWaiting.row($(e.target).parents('tr').prev('.parent')).data();
            }else{
                data = tableLeadsWaiting.row($(e.target).parents('tr')).data();
            }
            this.showModalEditLead(data[7]);
        }.bind(this));

        $('#list_leads_waiting tbody').on( 'click', '.set_status', function (e){
            e.preventDefault();
            let data = [];
            if($(e.target).parents('tr').hasClass('child')){
                data = tableLeadsWaiting.row($(e.target).parents('tr').prev('.parent')).data();
            }else{
                data = tableLeadsWaiting.row($(e.target).parents('tr')).data();
            }
            this.showModalEditStatus(data[7]);
        }.bind(this));

        $('#list_leads_waiting tbody').on( 'click', '.invite', function (e){
            e.preventDefault();
            let data = [];
            if($(e.target).parents('tr').hasClass('child')){
                data = tableLeadsWaiting.row($(e.target).parents('tr').prev('.parent')).data();
            }else{
                data = tableLeadsWaiting.row($(e.target).parents('tr')).data();
            }
            this.showModalInviteLead(data[7]);
        }.bind(this));

        $('#list_leads_waiting tbody').on( 'click', '.discard', function (e){
            e.preventDefault();
            let data = [];
            if($(e.target).parents('tr').hasClass('child')){
                data = tableLeadsWaiting.row($(e.target).parents('tr').prev('.parent')).data();
            }else{
                data = tableLeadsWaiting.row($(e.target).parents('tr')).data();
            }
            this.showModalDiscardLead(data[7]);
        }.bind(this));

        tableLeadsQualified = $('#list_leads_qualified').DataTable({
            "searching": true,
            "paging": true,
            "info": true,
            "responsive": true,
            "language": {
                "url": this.translate('DATATABLE_LANGUAGE_URL')
            },
            "ajax": {
                "url": ajaxUrl('/qualifiers/qualified/table'),
                "type": 'GET',
                "beforeSend": function (request) {
                    request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                }
            },
            "columnDefs": [{
                "targets": -1,
                "data": null,
                "defaultContent": 
                    `<div class="btn-group btn-shadow">
                        <button class="view_lead_qualified btn btn-sm purple btn-cursor-pointer" title="${this.translate('HOME_QUALIFICATION_VISUALIZE_BUTTON')}">
                            <i class="fa fa-eye" title="${this.translate('HOME_QUALIFICATION_VISUALIZE_BUTTON')}"></i>
                        </button>
                    </div>`
            }]
        });

        $('#list_leads_qualified tbody').on( 'click', '.view_lead_qualified', function (e){
            e.preventDefault();
            let data = [];
            if($(e.target).parents('tr').hasClass('child')){
                data = tableLeadsQualified.row($(e.target).parents('tr').prev('.parent')).data();
            }else{
                data = tableLeadsQualified.row($(e.target).parents('tr')).data();
            }
            this.showModalViewLeadQualified(data);
        }.bind(this));

        tableLeadsDiscarded = $('#list_leads_discarded').DataTable({
            "searching": true,
            "paging": true,
            "info": true,
            "responsive": true,
            "language": {
                "url": this.translate('DATATABE_LANGUAGE_URL')
            },
            "ajax": {
                "url": ajaxUrl('/qualifiers/discarded/table'),
                "type": 'GET',
                "beforeSend": function (request) {
                    request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                }
            },
            "columnDefs": [{
                "targets": -1,
                "data": null,
                "defaultContent": 
                    `<div class="btn-group btn-shadow">
                        <button class="view_leads_discarded btn btn-sm purple btn-cursor-pointer" title="${this.translate('HOME_QUALIFICATION_VISUALIZE_BUTTON')}">
                            <i class="fa fa-eye" title="${this.translate('HOME_QUALIFICATION_VISUALIZE_BUTTON')}"></i>
                        </button>
                    </div>`
            }]
        });

        $('#list_leads_discarded tbody').on( 'click', '.view_leads_discarded', function (e){
            e.preventDefault();
            let data = [];
            if($(e.target).parents('tr').hasClass('child')){
                data = tableLeadsDiscarded.row($(e.target).parents('tr').prev('.parent')).data();
            }else{
                data = tableLeadsDiscarded.row($(e.target).parents('tr')).data();
            }
            this.showModalViewLeadDiscard(data);
        }.bind(this));

    }

    render() {
        return (
            <React.Fragment>
                <MenuMain />
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    <div className="content-header dark  box-shadow-0" id="content-header">
                        <div className="navbar navbar-expand-lg">
                            <a href='/' className="d-lg-none mx-2" data-toggle="modal" data-target="#aside">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512"><path d="M80 304h352v16H80zM80 248h352v16H80zM80 192h352v16H80z"/></svg>
                            </a>
                            <div className="navbar-text nav-title flex" id="pageTitle">
                                {document.title}
                            </div>
                        </div>
                    </div>
                    <div className="content-main row row-no-margin" id="content-main">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" id="conteudo">
                            
                            <div className="row mt-3">
                                <div className="col-12">
                                    <div className="navbar-text nav-title flex">
                                        <i className="fa fa-users"></i> {this.translate('HOME_QUALIFICATION_IN_QUALIFICATION')}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="box p-2">
                                        <table id="list_leads_waiting" className="table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                                            <thead>
                                                <tr className="text-left">
                                                    <th>{this.translate('HOME_QUALIFICATION_IN_QUALIFICATION_NAME')}</th>
                                                    <th>{this.translate('HOME_QUALIFICATION_IN_QUALIFICATION_EMAIL')}</th>
                                                    <th>{this.translate('HOME_QUALIFICATION_IN_QUALIFICATION_PHONE')}</th>
                                                    <th>{this.translate('HOME_QUALIFICATION_IN_QUALIFICATION_RESPONSIBLE_QUALIFIER')}</th>
                                                    <th>{this.translate('HOME_QUALIFICATION_IN_QUALIFICATION_ENTRY_DATE')}</th>
                                                    <th>{this.translate('HOME_QUALIFICATION_IN_QUALIFICATION_BUSINESS')}</th>
                                                    <th>{this.translate('TABLE_STATUS_ROW')}</th>
                                                    <th>{this.translate('TABLE_ACTIONS_ROW')}</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-12">
                                    <div className="navbar-text nav-title flex">
                                        <i className="fa fa-users"></i> {this.translate('HOME_QUALIFICATION_QUALIFIED_LEADS')}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="box p-2">
                                        <table id="list_leads_qualified" className="table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                                            <thead>
                                                <tr className="text-left">
                                                    <th>{this.translate('HOME_QUALIFICATION_QUALIFIED_LEADS_NAME')}</th>
                                                    <th>{this.translate('HOME_QUALIFICATION_QUALIFIED_LEADS_EMAIL')}</th>
                                                    <th>{this.translate('HOME_QUALIFICATION_QUALIFIED_LEADS_PHONE')}</th>
                                                    <th>{this.translate('HOME_QUALIFICATION_QUALIFIED_LEADS_RESPONSIBLE_QUALIFIER')}</th>
                                                    <th>{this.translate('HOME_QUALIFICATION_QUALIFIED_LEADS_AGENT')}</th>
                                                    <th>{this.translate('HOME_QUALIFICATION_QUALIFIED_LEADS_AGENT_PHONE')}</th>
                                                    <th>{this.translate('HOME_QUALIFICATION_QUALIFIED_LEADS_AGENT_EMAIL')}</th>
                                                    <th>{this.translate('HOME_QUALIFICATION_QUALIFIED_LEADS_ENTRY_DATE')}</th>
                                                    <th>{this.translate('HOME_QUALIFICATION_QUALIFIED_LEADS_BUSINESS')}</th>
                                                    <th>{this.translate('TABLE_STATUS_ROW')}</th>
                                                    <th>{this.translate('TABLE_ACTIONS_ROW')}</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-12">
                                    <div className="navbar-text nav-title flex">
                                        <i className="fa fa-users"></i> {this.translate('HOME_QUALIFICATION_DISCARDED_LEADS')}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="box p-2">
                                        <table id="list_leads_discarded" className="table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                                            <thead>
                                                <tr className="text-left">
                                                    <th>{this.translate('HOME_QUALIFICATION_DISCARDED_LEADS_NAME')}</th>
                                                    <th>{this.translate('HOME_QUALIFICATION_DISCARDED_LEADS_EMAIL')}</th>
                                                    <th>{this.translate('HOME_QUALIFICATION_DISCARDED_LEADS_PHONE')}</th>
                                                    <th>{this.translate('HOME_QUALIFICATION_DISCARDED_LEADS_RESPONSIBLE_QUALIFIER')}</th>
                                                    <th>{this.translate('HOME_QUALIFICATION_DISCARDED_LEADS_ENTRY_DATE')}</th>
                                                    <th>{this.translate('HOME_QUALIFICATION_DISCARDED_LEADS_BUSINESS')}</th>
                                                    <th>{this.translate('HOME_QUALIFICATION_DISCARDED_LEADS_DISCARD_REASON')}</th>
                                                    <th>{this.translate('TABLE_ACTIONS_ROW')}</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <FooterMain />
                </div>
                <Modal show={this.state.modalEditLead} onHide={this.closeModalEditLead} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 pb-5">
                            <div className="p-3 b-b d-flex no-shrink green text-center">
                                <h5 className="h5 m-0 text-center width100 line-height-38px">
                                    <i className="fa fa-plus"></i> {this.translate('HOME_QUALIFICATION_MODAL_EDIT_LEAD')}
                                </h5>
                                <button onClick={this.closeModalEditLead} className="btn btn-transparent ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </button>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-10 offset-md-1">
                                    <form onSubmit={this.formSubmitEditLead}>
                                        <div className="col-12">
                                            <label>{this.translate('HOME_QUALIFICATION_MODAL_EDIT_LEAD_RESPONSIBLE_QUALIFIER')}:</label>
                                            <select className="form-control box-shadow cool-select" onChange={e => this.setState({formValuesEditLead: {...this.state.formValuesEditLead, qualifier: e.target.value}})} defaultValue={this.state.formValuesEditLead.qualifier} required>
                                                <option value="">{this.translate('HOME_QUALIFICATION_MODAL_EDIT_LEAD_SELECT_RESPONSIBLE_QUALIFIER')}</option>
                                                {
                                                    this.state.listQualifiers.map((data, i) => (
                                                        <option key={i} value={data.value}>{data.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>
                                                    {this.translate('HOME_QUALIFICATION_MODAL_EDIT_LEAD_NAME')}:
                                                </label>
                                                <input type="text" onChange={e => this.setState({ formValuesEditLead: {...this.state.formValuesEditLead, name:  e.target.value}})} defaultValue={this.state.formValuesEditLead.name} className="form-control box-shadow" placeholder={this.translate('HOME_QUALIFICATION_MODAL_EDIT_LEAD_NAME')} required/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>
                                                    {this.translate('HOME_QUALIFICATION_MODAL_EDIT_LEAD_EMAIL')}:
                                                </label>
                                                <input type="email" onChange={e => this.setState({ formValuesEditLead: {...this.state.formValuesEditLead, email:  e.target.value}})} defaultValue={this.state.formValuesEditLead.email} className="form-control box-shadow" placeholder={this.translate('HOME_QUALIFICATION_MODAL_EDIT_LEAD_EMAIL')} required/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>
                                                    {this.translate('HOME_QUALIFICATION_MODAL_EDIT_LEAD_PHONE')}:
                                                </label>
                                                <input type="text" onChange={e => this.setState({ formValuesEditLead: {...this.state.formValuesEditLead, phone:  e.target.value}})} defaultValue={this.state.formValuesEditLead.phone} className="form-control box-shadow" placeholder={this.translate('HOME_QUALIFICATION_MODAL_EDIT_LEAD_PHONE')} required/>
                                            </div>
                                        </div>
                                        {
                                            this.state.formValuesEditLead.additional_values.map((data, i) => (
                                                <div key={i} className="col-12">
                                                    <div className="form-group">
                                                        <label>
                                                            {this.translate('HOME_QUALIFICATION_MODAL_EDIT_LABEL_ADDITIONAL_INFO')}:
                                                        </label>
                                                        <input type="text" onChange={this.changeValueAdditionalValues} className="form-control box-shadow" data-index={i} placeholder={this.translate('HOME_QUALIFICATION_MODAL_EDIT_PLACEHOLDER_ADDITIONAL_INFO')} defaultValue={data.value} required/>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                        <div className="box-divider m0 mt20 width100"></div>
                                        <div className="col-sm-12 col-md-12 mt20">
                                            <button type="submit" onClick={this.addNewFieldFormValuesEditLead} className="btn btn-info px-5 btn-rounded btn-shadow pull-left">
                                                <i className="fa fa-plus"></i> {this.translate('HOME_QUALIFICATION_MODAL_EDIT_NEW_INFO')}
                                            </button>
                                            <button type="submit" className="btn btn-info px-5 btn-rounded btn-shadow pull-right">
                                                <i className="fa fa-save"></i> {this.translate('HOME_QUALIFICATION_MODAL_EDIT_REGISTER_NEW_INFO')}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.modalEditStatus} onHide={this.closeModalEditStatus} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 pb-5">
                            <div className="p-3 b-b d-flex no-shrink blue text-center">
                                <h5 className="h5 m-0 text-center width100 line-height-38px">
                                    <i className="fa fa-plus"></i> {this.translate('HOME_QUALIFICATION_MODAL_EDIT_STATUS_LEAD')}
                                </h5>
                                <button onClick={this.closeModalEditStatus} className="btn btn-transparent ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </button>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-6 offset-md-3">
                                    <form onSubmit={this.formSubmitEditStatus}>
                                        <div className="col-12">
                                            <select className="form-control box-shadow cool-select" onChange={e => this.setState({formValuesEditStatus: {...this.state.formValuesEditStatus, status: e.target.value}})} required="required">
                                                <option value="">{this.translate('HOME_QUALIFICATION_MODAL_EDIT_STATUS_LEAD_OPTION_SELECT_STATUS')}</option>
                                                <option value="without_contact">{this.translate('HOME_QUALIFICATION_MODAL_EDIT_STATUS_LEAD_OPTION_NO_CONTACT')}</option>
                                                <option value="scheduled_task">{this.translate('HOME_QUALIFICATION_MODAL_EDIT_STATUS_LEAD_OPTION_TASK_SCHEDULED')}</option>
                                            </select>
                                        </div>
                                        <div className="box-divider m0 mt20 width100"></div>
                                        <div className="col-sm-12 col-md-12 mt20">
                                            <button type="submit" className="btn btn-info px-5 btn-rounded btn-shadow pull-right">
                                                <i className="fa fa-save"></i> {this.translate('HOME_QUALIFICATION_MODAL_EDIT_STATUS_LEAD_CHANGE_STATUS')}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.modalInviteLead} onHide={this.closeModalInviteLead} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 pb-5">
                            <div className="p-3 b-b d-flex no-shrink purple text-center">
                                <h5 className="h5 m-0 text-center width100 line-height-38px">
                                    <i className="fa fa-plus"></i> {this.translate('HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION')}
                                </h5>
                                <button onClick={this.closeModalInviteLead} className="btn btn-transparent ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </button>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-6 offset-md-3">
                                <form onSubmit={this.formSubmitInviteLead}>
                                        <div className="col-12 mb-3">
                                            <label>{this.translate('HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_OPTION_CHOOSE_DISTRIBUTION_TYPE')}</label>
                                            <select className="form-control box-shadow cool-select" onChange={e => this.setState({formValuesInviteLead: {...this.state.formValuesInviteLead, distribution_type: e.target.value}})} required="required">
                                                <option value="">{this.translate('HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_OPTION_SELECT_DISTRIBUTION_TYPE')}</option>
                                                <option value="normal_distribution">{this.translate('HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_OPTION_NORMAL_DISTRIBUTION')}</option>
                                                <option value="specific_broker">{this.translate('HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_OPTION_SPECIFIC_AGENT')}</option>
                                            </select>
                                        </div>
                                        {
                                            (this.state.formValuesInviteLead.distribution_type !== null && this.state.formValuesInviteLead.distribution_type === 'specific_broker')?(
                                                <div className="col-12">
                                                    <label>{this.translate('HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_CHOOSE_RESPONSIBLE_AGENT')}</label>
                                                    <select className="form-control box-shadow cool-select" onChange={e => this.setState({formValuesInviteLead: {...this.state.formValuesInviteLead, commercial: e.target.value}})} required="required">
                                                        <option value="">{this.translate('HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_OPTION_SELECT_AGENT')}</option>
                                                        {
                                                            this.state.listCommercial.map((data, i) => (
                                                                <option value={data.value}>{data.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            ):(false)
                                        }
                                        <div className="box-divider m0 mt20 width100"></div>
                                        <div className="col-sm-12 col-md-12 mt20">
                                            <button type="submit" className="btn btn-info px-5 btn-rounded btn-shadow pull-right">
                                                <i className="fa fa-save"></i> {this.translate('HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_SEND_DISTRIBUTION')}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.modalDiscardLead} onHide={this.closeModalDiscardLead} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 pb-5">
                            <div className="p-3 b-b d-flex no-shrink danger text-center">
                                <h5 className="h5 m-0 text-center width100 line-height-38px">
                                    <i className="fa fa-plus"></i> {this.translate('HOME_QUALIFICATION_MODAL_DISCARD_LEAD')}
                                </h5>
                                <button onClick={this.closeModalDiscardLead} className="btn btn-transparent ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </button>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-6 offset-md-3">
                                    <form onSubmit={this.formSubmitDiscardLead}>
                                        <div className="col-12">
                                            <select className="form-control box-shadow cool-select" onChange={e => this.setState({formValuesDiscardLead: {...this.state.formValuesDiscardLead, reason_discard: e.target.value}})} required="required">
                                                <option value="">{this.translate('HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_SELECT_DISCARD_REASON')}</option>
                                                <option value="never_heard">{this.translate('HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_NEVER_HEARD')}</option>
                                                <option value="impossible_to_contact">{this.translate('HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_IMPOSSIBLE_TO_CONTACT')}</option>
                                                <option value="not_a_customer">{this.translate('HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_NOT_A_CUSTOMER')}</option>
                                                <option value="has_no_interest">{this.translate('HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_HAS_NO_INTEREST')}</option>
                                                <option value="without_profile">{this.translate('HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_WITHOUT_PROFILE')}</option>
                                                <option value="service_provider">{this.translate('HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_SERVICE_PROVIDER')}</option>
                                                <option value="duplicate_email">{this.translate('HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_DUPLICATED_EMAIL')}</option>
                                            </select>
                                        </div>
                                        <div className="box-divider m0 mt20 width100"></div>
                                        <div className="col-sm-12 col-md-12 mt20">
                                            <button type="submit" className="btn btn-info px-5 btn-rounded btn-shadow pull-right">
                                                <i className="fa fa-save"></i> {this.translate('HOME_QUALIFICATION_MODAL_DISCARD_LEAD')}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.modalViewLeadQualified} onHide={this.closeModalViewLeadQualified} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 pb-5">
                            <div className="p-3 b-b d-flex no-shrink purple text-center">
                                <h5 className="h5 m-0 text-center width100 line-height-38px">
                                    <i className="fa fa-plus"></i> {this.translate('HOME_QUALIFICATION_MODAL_VISUALIZE_QUALIFIED_LEAD')}
                                </h5>
                                <button onClick={this.closeModalViewLeadQualified} className="btn btn-transparent ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </button>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-6 offset-md-3">
                                    {this.translate('HOME_QUALIFICATION_MODAL_LOADING_QUALIFIED_LEAD_INFO')}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.modalViewLeadDiscard} onHide={this.closeModalViewLeadDiscard} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 pb-5">
                            <div className="p-3 b-b d-flex no-shrink purple text-center">
                                <h5 className="h5 m-0 text-center width100 line-height-38px">
                                    <i className="fa fa-plus"></i> {this.translate('HOME_QUALIFICATION_MODAL_VISUALIZE_DISCARDED_LEAD')}
                                </h5>
                                <button onClick={this.closeModalViewLeadDiscard} className="btn btn-transparent ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </button>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-6 offset-md-3">
                                    {this.translate('HOME_QUALIFICATION_MODAL_LOADING_DISCARDED_LEAD_INFO')}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(HomeQualification);