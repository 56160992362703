export const app = {
    color: {
        primary: '#2499ee',
        accent: '#6284f3',
        warn: '#907eec'
    },
    setting: {
        ajax: true,
        folded: false,
        container: false,
        theme: 'primary',
        aside: 'dark',
        brand: 'dark',
        header: 'white',
        fixedContent: true,
        fixedAside: true,
        bg: 'dark'
    }
}


export function hexToRGB(hex, alpha){
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
}