import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'

import $ from 'jquery';
import 'jquery-mask-plugin';

import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4'

import {ajaxUrl, ajaxToken} from '../../../Services/Ajax';
import api from '../../../Services/Api';

import Modal from 'react-bootstrap/Modal';

import autocomplete from '../../../Functions/Leads/AutoComplete'

class BusinessBid extends Component {

    constructor(props){
        super(props)
        this.state = {
            modalResponseShow: false,
            msgResponseShow: false,
            tableLoadBid: null,
            formBidRegister: {
                name: '',
                email: '',
                phone: '',
                unity: '',
                value: '',
            }
        }
        this.handleCloseModalResponse =  this.handleCloseModalResponse.bind(this)
        this.addNewFormBid = this.addNewFormBid.bind(this)
        this.reloadDatatable = this.reloadDatatable.bind(this)
        this.handleKeyUp = this.handleKeyUp.bind(this)
        this.translate = this.props.translate
    }

    componentDidMount = async () => {
        let tableLoadBid = this.state.tableLoadBid
        const props = this.props
        tableLoadBid = $('#list_bid').DataTable({
            "searching": true,
            "paging": true,
            "info": true,
            "responsive": true,
            "language": {
                "url": this.translate("DATATABLE_LANGUAGE_URL")
            },
            "ajax": {
                "url": ajaxUrl('/business/' + props.idBusiness + '/bid/table'),
                "type": 'GET',
                "beforeSend": function (request) {
                    request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                }
            }
        });

        this.formatPhoneField();

        console.log(tableLoadBid)

    }

    handleCloseModalResponse(e = null){
        if (e) e.preventDefault()
        this.setState({modalResponseShow: false})
    }

    formatPhoneField = () => {
        var SPMaskBehavior = function (val){
            return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
        },
        spOptions = {
            onKeyPress: function(val, e, field, options) {
                this.setState({ formBidRegister: {...this.state.formBidRegister, phone: val}})
                field.mask(SPMaskBehavior.apply({}, arguments), options);
            }.bind(this)
        };
        //$('#phone-bid').unmask();
        $('#phone-bid').mask(SPMaskBehavior, spOptions);
    }

    resetDataForm = () =>{
        const {formBidRegister} = this.state;
        formBidRegister['name'] = '';
        formBidRegister['email'] = '';
        formBidRegister['phone'] = '';
        formBidRegister['unity'] = '';
        formBidRegister['value'] = '';
        this.setState({
            formBidRegister,
        });
        document.getElementById('register-bid-form').reset();
    }

    addNewFormBid = async (e) => {
        e.preventDefault();
        this.setState({ msgResponseShow: this.translate("BUSINESS_BID_WAIT_STATUS"), modalResponseShow: true });
        const data = this.state.formBidRegister;
        console.log(data);
        try{
            const response = await api.post("/business/" + this.props.idBusiness + "/bid/register", {data});
            if(response.data.success === true){
                this.setState({msgResponseShow: this.translate("BUSINESS_BID_SUCCESS_STATUS")});
                document.getElementById('register-bid-form').reset();
                this.reloadDatatable()
            }else{
                this.setState({msgResponseShow: response.data.msg});
            }
        }catch(err){
            this.setState({msgResponseShow: err});
        }
    }

    reloadDatatable(){
        $('#list_bid').DataTable().ajax.reload();
    }

    handleKeyUp = (e) =>{
        if(e.target.value.length > 5){
            autocomplete(e)
        }
    }

    clickAutoComplete = (e) => {
        if(e.target.classList.contains('autocomplete-dev-click')){
            const data = e.target.dataset
            const {formBidRegister} = this.state;
            formBidRegister['name'] = data.name;
            formBidRegister['email'] = data.email;
            formBidRegister['phone'] = data.phone;
            this.setState({
                formBidRegister,
            });
            this.formatPhoneField();
            $('.autocomplete-dev').remove();
        }
    }

    render() {
        return (
            <React.Fragment>
                <div id="accordion-bid">
                    <div className="card mb-3">
                        <div className="card-header btn-cursor-pointer dark" id="headingRegisterBid">
                            <h5 className="mb-0" onClick={e=>this.props.toggleCollapse("#collapseRegisterBid")} aria-expanded="false" aria-controls="collapseRegisterBid">
                                {this.translate("BUSINESS_BID_REGISTER_REGISTER_BID")}
                                <i className="fa fa-caret-down pull-right"></i>
                            </h5>
                        </div>

                        <div id="collapseRegisterBid" className="collapse dark" aria-labelledby="headingRegisterBid" data-parent="#accordion-bid">
                            <div className="card-body">
                                <form onSubmit={this.addNewFormBid} id="register-bid-form">
                                    <div className="row p-3 white box-shadow pb40">
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group" onClick={this.clickAutoComplete}>
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                    {this.translate("BUSINESS_BID_REGISTER_NAME")}:
                                                </label>
                                                <input type="text" name="name" id="name-bid" autoComplete="off" onKeyUp={this.handleKeyUp} onPaste={this.handleKeyUp} onChange={e => this.setState({ formBidRegister: {...this.state.formBidRegister, name:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("BUSINESS_BID_REGISTER_NAME")} value={this.state.formBidRegister.name} required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group" onClick={this.clickAutoComplete}>
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                    {this.translate("BUSINESS_BID_REGISTER_EMAIL")}:
                                                </label>
                                                <input type="email" name="email" id="email-bid" autoComplete="off" onKeyUp={this.handleKeyUp} onPaste={this.handleKeyUp} onChange={e => this.setState({ formBidRegister: {...this.state.formBidRegister, email:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("BUSINESS_BID_REGISTER_EMAIL")} value={this.state.formBidRegister.email} required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group" onClick={this.clickAutoComplete}>
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                    {this.translate("BUSINESS_BID_REGISTER_PHONE")}:
                                                </label>
                                                <input type="text" name="phone" id="phone-bid" autoComplete="off" onKeyUp={this.handleKeyUp} onPaste={this.handleKeyUp} className="form-control box-shadow" placeholder={this.translate("BUSINESS_BID_REGISTER_PHONE")} defaultValue={this.state.formBidRegister.phone} required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                    {this.translate("BUSINESS_BID_REGISTER_UNITY")}:
                                                </label>
                                                <input type="text" name="unity" id="unity-bid" autoComplete="off" onChange={e => this.setState({ formBidRegister: {...this.state.formBidRegister, unity:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("BUSINESS_BID_REGISTER_UNITY")} value={this.state.formBidRegister.unity} required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                    {this.translate("BUSINESS_BID_REGISTER_VALUE")}:
                                                </label>
                                                <input type="text" name="value" id="value-bid" autoComplete="off" onChange={e => this.setState({ formBidRegister: {...this.state.formBidRegister, value:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("BUSINESS_BID_REGISTER_VALUE")} value={this.state.formBidRegister.value} required/>
                                            </div>
                                        </div>
                                        <div className="box-divider m0 mt20 width100"></div>
                                        <div className="col-sm-12 col-md-12 mt20">
                                            <button type="submit" className="btn btn-info px-5 btn-rounded btn-shadow pull-right">
                                                <i className="fa fa-save"></i> {this.translate("BUSINESS_BID_REGISTER_REGISTER_BID")}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                    <div className="card">
                        <div className="card-header btn-cursor-pointer dark" id="headingBidList" onClick={e=>this.props.toggleCollapse("#collapseBidList")} aria-expanded="true" aria-controls="collapseBidList">
                            <h5 className="mb-0">
                                {this.translate("BUSINESS_BID_LIST_BIDS_LIST")}
                                <i className="fa fa-caret-down pull-right"></i>
                            </h5>
                        </div>

                        <div id="collapseBidList" className="collapse dark show" aria-labelledby="headingBidList" data-parent="#accordion-bid">
                            <div className="card-body">
                                <div className="bootstrap-table">
                                    <div className="fixed-table-container">
                                        <div className="fixed-table-body">
                                            <table id="list_bid" className="table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                                                <thead>
                                                    <tr className="text-left">
                                                        <th>{this.translate("BUSINESS_BID_LIST_NAME")}</th>
                                                        <th>{this.translate("BUSINESS_BID_LIST_EMAIL")}</th>
                                                        <th>{this.translate("BUSINESS_BID_LIST_PHONE")}</th>
                                                        <th>{this.translate("BUSINESS_BID_LIST_UNITY")}</th>
                                                        <th>{this.translate("BUSINESS_BID_LIST_BIDS_VALUE")}</th>
                                                        <th>{this.translate("BUSINESS_BID_LIST_BIDS_REGISTER_DATE")}</th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <Modal show={this.state.modalResponseShow} onHide={this.handleCloseModalResponse} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t"> 
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-bell"></i> {this.translate("MODAL_STATUS_STATUS")}</h5>
                                <a href="/" onClick={e=>this.handleCloseModalResponse(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                <span className="col-12 text-center py-2 lead">
                                    {this.state.msgResponseShow}
                                </span>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(BusinessBid);
