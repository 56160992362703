import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import Modal from 'react-bootstrap/Modal';

import $ from 'jquery';

import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4'

import { Editor } from '@tinymce/tinymce-react';

import api from '../../../Services/Api';
import { ajaxUrl, ajaxToken } from '../../../Services/Ajax';

import MenuMain from '../../../Structure/MenuMain';
import HeaderMain from '../../../Structure/HeaderMain';
import FooterMain from '../../../Structure/FooterMain';

class EmailMarketingList extends Component {

    constructor(props) {
        super(props);
        this.translate = this.props.translate
        document.title = this.translate('EMAIL_MARKETING_LIST_EMAIL_MARKETING')
        this.state = {
            dataTableData: [],
            tableLoadEmailMKT: null,
            modalCreateEmailMKT: false,
            modalSendEmailMKT: false,
            formCreateEmailMKT: {
                name: null,
                pitch_email_marketing: null,
                subject: null,
                body: null
            },
            formEditEmailMKT: {
                id: null,
                name: null,
                pitch_email_marketing: null,
                subject: null,
                body: null
            },
            formSendEmailMKT: {
                name: null,
                recipient_name: null,
                recipient_email: null,
                id: null
            }
        }
        this.showModalCreateEmailMKT = this.showModalCreateEmailMKT.bind(this)
        this.closeModalCreateEmailMKT = this.closeModalCreateEmailMKT.bind(this)
        this.formSubmitCreateEmailMKT = this.formSubmitCreateEmailMKT.bind(this)
        this.reloadDatatableEmailMKT = this.reloadDatatableEmailMKT.bind(this)

        this.showModalEditEmailMKT = this.showModalEditEmailMKT.bind(this)
        this.closeModalEditEmailMKT = this.closeModalEditEmailMKT.bind(this)
        this.formSubmitEditEmailMKT = this.formSubmitEditEmailMKT.bind(this)

        this.handleChangeBodyEmailMarketing = this.handleChangeBodyEmailMarketing.bind(this)
        this.handleChangeBodyEmailEditMarketing = this.handleChangeBodyEmailEditMarketing.bind(this)

        this.showModalSendEmailMKT = this.showModalSendEmailMKT.bind(this)
        this.closeModalSendEmailMKT = this.closeModalSendEmailMKT.bind(this)
        this.formSubmitSendEmailMKT = this.formSubmitSendEmailMKT.bind(this)

    }

    showModalCreateEmailMKT() {
        this.setState({ modalCreateEmailMKT: true })
    }

    closeModalCreateEmailMKT() {
        this.setState({ modalCreateEmailMKT: false })
    }

    showModalEditEmailMKT() {
        this.setState({ modalEditEmailMKT: true })
    }

    closeModalEditEmailMKT() {
        this.setState({ modalEditEmailMKT: false })
    }

    reloadDatatableEmailMKT() {
        $('#email_list').DataTable().ajax.reload();
    }

    handleChangeBodyEmailMarketing(data) {
        this.setState({ formCreateEmailMKT: { ...this.state.formCreateEmailMKT, body: data } })
    }

    handleChangeBodyEmailEditMarketing(data) {
        this.setState({ formEditEmailMKT: { ...this.state.formEditEmailMKT, body: data } })
    }

    handleChangeBodyEmailEditMarketingNew(e) {
        if(typeof e.preventDefault === 'function')
            e.preventDefault()

        let html = e.target.value

        this.setState({ formEditEmailMKT: { ...this.state.formEditEmailMKT, body: html } })

    }

    async formSubmitCreateEmailMKT(e) {
        e.preventDefault();

        $('#email_button_submit').prop('disabled', true).html(`<i className="fa fa-spinner"></i> ${this.translate('EMAIL_MARKETING_LIST_WAIT')}`)

        const data = this.state.formCreateEmailMKT;
        try {
            const response = await api.post("/email_marketing/create", { data });
            if (response.data.success === true) {
                this.reloadDatatableEmailMKT()
                this.closeModalCreateEmailMKT();
                $(`#email_button_submit`).prop(`disabled`, false).html(`<i className="fa fa-save"></i> ${this.translate('EMAIL_MARKETING_LIST_REGISTER')}`)
            } else {
                this.setState({ msgResponseShow: response.data.msg });
            }
        } catch (err) {
            this.setState({ msgResponseShow: err });
        }
    }

    async formSubmitEditEmailMKT(e) {
        e.preventDefault();

        $(`#email_button_submit`).prop(`disabled`, true).html(`<i className="fa fa-spinner"></i> ${this.translate('EMAIL_MARKETING_LIST_WAIT')}`)

        const data = this.state.formEditEmailMKT;
        try {
            const response = await api.post(`/email_marketing/edit/${data["id"]}`, { data });
            if (response.data.success === true) {
                this.reloadDatatableEmailMKT()
                this.closeModalEditEmailMKT();
                console.log(response.data)
                $(`#email_button_submit`).prop(`disabled`, false).html(`<i className="fa fa-save"></i> ${this.translate('EMAIL_MARKETING_LIST_REGISTER')}`)
            } else {
                this.setState({ msgResponseShow: response.data.msg });
            }
        } catch (err) {
            this.setState({ msgResponseShow: err });
        }
    }

    showModalSendEmailMKT() {
        this.setState({ modalSendEmailMKT: true })
    }

    closeModalSendEmailMKT() {
        $('#email_send_status').html('');
        this.setState({ modalSendEmailMKT: false })
    }

    async formSubmitSendEmailMKT(e) {
        e.preventDefault();

        $(`#email_button_submit`).prop(`disabled`, true).html(`<i className="fa fa-spinner"></i> ${this.translate('EMAIL_MARKETING_LIST_WAIT')}`)

        const data = this.state.formSendEmailMKT;
        try {
            const response = await api.post("/email_marketing/send", { data });
            if (response.data.success === true) {
                $(`#email_send_status`).html(`${this.translate('EMAIL_MARKETING_LIST_SUCCESS_ADDING_TO_SUBMISSION_QUEUE')}`);
                $(`#email_send_button_submit`).prop(`disabled`, false).html(`<i className="fa fa-paper-plane"></i> ${this.translate('EMAIL_MARKETING_LIST_SEND')}`)
            } else {
                $(`#email_send_status`).html(`${this.translate('EMAIL_MARKETING_LIST_SUCCESS_ERROR_TO_SUBMISSION_QUEUE')}`);
            }
        } catch (err) {
            console.log(err)
        }
    }

    componentDidMount() {

        let tableLoadEmailMKT = this.state.tableLoadEmailMKT

        tableLoadEmailMKT = $('#email_list').DataTable({
            "searching": true,
            "paging": false,
            "info": false,
            "responsive": true,
            "language": {
                "url": this.translate('DATATABLE_LANGUAGE_URL')
            },
            "ajax": {
                "url": ajaxUrl('/email_marketing/table'),
                "type": 'GET',
                "beforeSend": function (request) {
                    request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                },
                "dataSrc": function (json) {
                    //Make your callback here.
                    console.log(json.data)
                    return json.data;
                }
            },
            "columnDefs": [{
                "targets": -1,
                "data": null,
                "defaultContent":
                    `<div class="btn-group btn-shadow">
                        <button class="view btn btn-sm orange btn-cursor-pointer" title="${this.translate('EMAIL_MARKETING_LIST_EMAIL_VISUALIZE')}">
                            <i class="fa fa-eye" title="${this.translate('EMAIL_MARKETING_LIST_EMAIL_VISUALIZE')}"></i>
                        </button>
                        <button class="edit btn btn-sm amber btn-cursor-pointer" title="${this.translate('EMAIL_MARKETING_LIST_EMAIL_EDIT')}">
                            <i class="fa fa-cogs" title="${this.translate('EMAIL_MARKETING_LIST_EMAIL_EDIT')}"></i>
                        </button>
                        <button class="send_test btn btn-sm orange btn-cursor-pointer" title="${this.translate('EMAIL_MARKETING_LIST_EMAIL_SEND_TEST')}">
                        <i class="fa fa-share" title="${this.translate('EMAIL_MARKETING_LIST_EMAIL_SEND_TEST')}"></i>
                        </button>
                        <button class="trigger btn btn-sm amber btn-cursor-pointer" title="Disparo de E-mails">
                            <i class="fa fa-envelope-o" title="Disparo de E-mails"></i>
                        </button>
                    </div>`
            }]
        });

        $('#email_list tbody').on('click', '.view', function (e) {

            e.preventDefault();

            let data = [];

            if ($(e.target).parents('tr').hasClass('child')) {
                data = tableLoadEmailMKT.row($(e.target).parents('tr').prev('.parent')).data();
            } else {
                data = tableLoadEmailMKT.row($(e.target).parents('tr')).data();
            }
            let email = window.open('/email_marketing_preview#' + data[2]);
            email.focus();

        });

        $('#email_list tbody').on('click', '.edit', function (e) {
            e.preventDefault();
            let data = []
            if ($(e.target).parents('tr').hasClass('child')) {
                data = tableLoadEmailMKT.row($(e.target).parents('tr').prev('.parent')).data();
            } else {
                data = tableLoadEmailMKT.row($(e.target).parents('tr')).data();
            }
            this.setState({ formEditEmailMKT: data[3] })
            this.showModalEditEmailMKT()

        }.bind(this))

        $('#email_list tbody').on('click', '.send_test', function (e) {

            e.preventDefault();

            let data = [];

            if ($(e.target).parents('tr').hasClass('child')) {
                data = tableLoadEmailMKT.row($(e.target).parents('tr').prev('.parent')).data();
            } else {
                data = tableLoadEmailMKT.row($(e.target).parents('tr')).data();
            }

            const { formSendEmailMKT } = this.state;

            formSendEmailMKT.name = data[0]
            formSendEmailMKT.id = data[2]

            this.setState({ formSendEmailMKT })
            this.showModalSendEmailMKT();

        }.bind(this));

        $('#email_list tbody').on('click', '.trigger', function (e) {

            e.preventDefault();

            let data = [];

            if ($(e.target).parents('tr').hasClass('child')) {
                data = tableLoadEmailMKT.row($(e.target).parents('tr').prev('.parent')).data();
            } else {
                data = tableLoadEmailMKT.row($(e.target).parents('tr')).data();
            }

            window.location.href = "/triggered_emails#" + data[2];

        });

    }

    render() {
        return (
            <Fragment>
                <MenuMain />
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    <HeaderMain title={document.title} onChange={this.handleChange} />
                    <div className="content-main row row-no-margin" id="content-main">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" id="conteudo">
                            <div className="row text-right mb-3 p-2">
                                <div className="col-md-12">
                                    <button className="btn btn-fw amber btn-rounded mt20 pull-right btn-shadow btn-cursor-pointer" onClick={this.showModalCreateEmailMKT}>
                                        <i className="fa fa-envelope"></i> {this.translate('EMAIL_MARKETING_CREATE_NEW_EMAIL_MARKETING')}
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="box p-2">
                                        <table id="email_list" className="table table-striped table-bordered dt-responsive nowrap" style={{ width: "100%" }}>
                                            <thead>
                                                <tr className="text-left">
                                                    <th>{this.translate('EMAIL_MARKETING_TABLE_NAME')}</th>
                                                    <th>{this.translate('EMAIL_MARKETING_TABLE_SUBJECT')}</th>
                                                    <th>{this.translate('TABLE_ACTIONS_ROW')}</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterMain />
                </div>
                <Modal show={this.state.modalCreateEmailMKT} onHide={this.closeModalCreateEmailMKT} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 pb-5">
                            <div className="p-3 b-b d-flex no-shrink amber text-center">
                                <h5 className="h5 m-0 text-center width100 line-height-38px">
                                    <i className="fa fa-envelope"></i> {this.translate('EMAIL_MARKETING_CREATE_NEW_EMAIL_MARKETING')}
                                </h5>
                                <button onClick={this.closeModalCreateEmailMKT} className="btn btn-transparent ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </button>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-10 offset-md-1">

                                    <form onSubmit={this.formSubmitCreateEmailMKT}>
                                        <div className="col-sm-12 mb20">
                                            <label className="black no-text-shadow">{this.translate('EMAIL_MARKETING_MODAL_LABEL_EMAIL_NAME')}</label>
                                            <input type="text" name="name" onChange={e => this.setState({ formCreateEmailMKT: { ...this.state.formCreateEmailMKT, name: e.target.value } })} className="form-control box-shadow cool-input" placeholder={this.translate('EMAIL_MARKETING_MODAL_PLACEHOLDER_EMAIL_NAME')} required="required" />
                                        </div>
                                        <div className="col-sm-12 mb20">
                                            <label className="black no-text-shadow">{this.translate('EMAIL_MARKETING_MODAL_LABEL_PITCH_EMAIL_MARKETING')}</label>
                                            <input type="text" onChange={e => this.setState({ formCreateEmailMKT: { ...this.state.formCreateEmailMKT, pitch_email_marketing: e.target.value } })} className="form-control box-shadow cool-input" placeholder={this.translate('EMAIL_MARKETING_MODAL_LABEL_PITCH_EMAIL_MARKETING')} required="required" />
                                        </div>
                                        <div className="col-sm-12 mb20">
                                            <label className="black no-text-shadow">{this.translate('EMAIL_MARKETING_MODAL_LABEL_SUBJECT_EMAIL')}</label>
                                            <input type="text" name="subject" onChange={e => this.setState({ formCreateEmailMKT: { ...this.state.formCreateEmailMKT, subject: e.target.value } })} className="form-control box-shadow cool-input" placeholder={this.translate('EMAIL_MARKETING_MODAL_PLACEHOLDER_SUBJECT_EMAIL')} required="required" />
                                        </div>
                                        <div className="col-sm-12 mb20">
                                            <label className="black no-text-shadow">{this.translate('EMAIL_MARKETING_MODAL_LABEL_CONTENT_EMAIL')}</label>
                                            <Editor
                                                apiKey='8icl8j77xzjuf2yblc8nir6dmi891frinavx8n7rd63t7sav'
                                                init={{
                                                    selector: "textarea",
                                                    branding: false,
                                                    statusbar: false,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code preview',
                                                        'insertdatetime media table paste help'
                                                    ],
                                                    toolbar:
                                                        'undo redo | formatselect | bold italic backcolor | \n' +
                                                        'alignleft aligncenter alignright alignjustify | \n' +
                                                        'bullist numlist outdent indent | code preview'
                                                }}
                                                onEditorChange={this.handleChangeBodyEmailMarketing}
                                            />
                                        </div>
                                        <button type="submit" id="email_button_submit" className="btn btn-fw amber btn-rounded mt20 pull-right btn-shadow px-5"><i className="fa fa-save"></i> {this.translate('EMAIL_MARKETING_LIST_REGISTER')}</button>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.modalEditEmailMKT} onHide={this.closeModalEditEmailMKT} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 pb-5">
                            <div className="p-3 b-b d-flex no-shrink amber text-center">
                                <h5 className="h5 m-0 text-center width100 line-height-38px">
                                    <i className="fa fa-envelope"></i> {this.translate('EMAIL_MARKETING_MODAL_EDIT_EMAIL_MARKETING')}
                                </h5>
                                <button onClick={this.closeModalEditEmailMKT} className="btn btn-transparent ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </button>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-10 offset-md-1">

                                    <form onSubmit={this.formSubmitEditEmailMKT}>
                                        <div className="col-sm-12 mb20">
                                            <label className="black no-text-shadow">{this.translate('EMAIL_MARKETING_MODAL_LABEL_EMAIL_NAME')}</label>
                                            <input type="text" name="name" onChange={e => this.setState({ formEditEmailMKT: { ...this.state.formEditEmailMKT, name: e.target.value } })} defaultValue={this.state.formEditEmailMKT.name} className="form-control box-shadow cool-input" placeholder={this.translate('EMAIL_MARKETING_MODAL_PLACEHOLDER_EMAIL_NAME')} required="required" />
                                        </div>
                                        <div className="col-sm-12 mb20">
                                            <label className="black no-text-shadow">{this.translate('EMAIL_MARKETING_MODAL_LABEL_PITCH_EMAIL_MARKETING')}</label>
                                            <input type="text" onChange={e => this.setState({ formEditEmailMKT: { ...this.state.formEditEmailMKT, pitch_email_marketing: e.target.value } })} defaultValue={this.state.formEditEmailMKT.pitch_email_marketing} className="form-control box-shadow cool-input" placeholder={this.translate('EMAIL_MARKETING_MODAL_PLACEHOLDER_PITCH_EMAIL_MARKETING')} required="required" />
                                        </div>
                                        <div className="col-sm-12 mb20">
                                            <label className="black no-text-shadow">{this.translate('EMAIL_MARKETING_MODAL_LABEL_SUBJECT_EMAIL')}</label>
                                            <input type="text" name="subject" onChange={e => this.setState({ formEditEmailMKT: { ...this.state.formEditEmailMKT, subject: e.target.value } })} defaultValue={this.state.formEditEmailMKT.subject} className="form-control box-shadow cool-input" placeholder={this.translate('EMAIL_MARKETING_MODAL_PLACEHOLDER_SUBJECT_EMAIL')} required="required" />
                                        </div>
                                        <div className="col-sm-12 mb20">
                                            <label className="black no-text-shadow">{this.translate('EMAIL_MARKETING_MODAL_LABEL_CONTENT_EMAIL')}</label>
                                            {/* {
                                                <Editor
                                                    initialValue={this.state.formEditEmailMKT.body}
                                                    apiKey='8icl8j77xzjuf2yblc8nir6dmi891frinavx8n7rd63t7sav'
                                                    init={{
                                                        selector: "textarea",
                                                        branding: false,
                                                        statusbar: false,
                                                        menubar: false,
                                                        plugins: [
                                                            'advlist autolink lists link image charmap print preview anchor',
                                                            'searchreplace visualblocks code preview',
                                                            'insertdatetime media table paste help'
                                                        ],
                                                        toolbar:
                                                            'undo redo | formatselect | bold italic backcolor | \n' +
                                                            'alignleft aligncenter alignright alignjustify | \n' +
                                                            'bullist numlist outdent indent | code preview'
                                                    }}
                                                    onEditorChange={(e) => this.handleChangeBodyEmailEditMarketingNew(e)}
                                                />
                                            } */}
                                        </div>
                                        <div className="col-sm-12">
                                            <textarea onChange={(e) => this.handleChangeBodyEmailEditMarketingNew(e)} col={3} rol={10} value={this.state.formEditEmailMKT.body}>{this.state.formEditEmailMKT.body}</textarea>
                                        </div>
                                        <button type="submit" id="email_button_submit" className="btn btn-fw amber btn-rounded mt20 pull-right btn-shadow px-5"><i className="fa fa-save"></i> {this.translate('EMAIL_MARKETING_LIST_REGISTER')}</button>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.modalSendEmailMKT} onHide={this.closeModalSendEmailMKT} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 pb-5">
                            <div className="p-3 b-b d-flex no-shrink orange text-center">
                                <h5 className="h5 m-0 text-center width100 line-height-38px">
                                    <i className="fa fa-envelope"></i> {this.translate('EMAIL_MARKETING_LIST_EMAIL_SEND_TEST')}
                                </h5>
                                <button onClick={this.closeModalSendEmailMKT} className="btn btn-transparent ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </button>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-6 offset-md-3">

                                    <div className="col-sm-12 mb20" id="email_send_status" style={{ fontWeight: 'bold' }}></div>

                                    <form onSubmit={this.formSubmitSendEmailMKT}>
                                        <div className="col-sm-12 mb20">
                                            <input type="text" className="form-control box-shadow cool-input" defaultValue={this.state.formSendEmailMKT.name} disabled />
                                        </div>
                                        <div className="col-sm-12 mb20">
                                            <input type="text" onChange={e => this.setState({ formSendEmailMKT: { ...this.state.formSendEmailMKT, recipient_name: e.target.value } })} className="form-control box-shadow cool-input" placeholder={this.translate('EMAIL_MARKETING_MODAL_RECIPIENT_NAME')} required="required" />
                                        </div>
                                        <div className="col-sm-12 mb20">
                                            <input type="email" onChange={e => this.setState({ formSendEmailMKT: { ...this.state.formSendEmailMKT, recipient_email: e.target.value } })} className="form-control box-shadow cool-input" placeholder={this.translate('EMAIL_MARKETING_MODAL_RECIPIENT_EMAIL')} required="required" />
                                        </div>
                                        <button type="submit" id="email_send_button_submit" className="btn btn-fw orange btn-rounded mt20 pull-right btn-shadow px-5 btn-cursor-pointer"><i className="fa fa-paper-plane"></i> {this.translate('EMAIL_MARKETING_LIST_SEND')}</button>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </Fragment>
        );
    }
}

export default withRouter(EmailMarketingList);