import React, { Component, Fragment } from 'react';
import {withRouter} from 'react-router-dom';

import $ from 'jquery'

import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4'

import api from '../../Services/Api';
import {ajaxUrl, ajaxToken} from '../../Services/Ajax';

import Modal from 'react-bootstrap/Modal';

import MenuMain from '../../Structure/MenuMain';
import HeaderMain from '../../Structure/HeaderMain';
import FooterMain from '../../Structure/FooterMain';

class LinkBuilder extends Component {

    constructor(props){
        super(props)
        document.title = "Link Builder"
        this.state = {
            tableLoadLink: null,
            modalCreateLink: false,
            formCreateLink: {
                name: null,
                media: null,
                segmentation: null,
                public: null,
                link: null,
                journey_moment: null,
                pitch_contents: null,
                pitch_campaign: null,
                pitch_click: null,
                type: 'buylitics_url_shortener',
            },
            isModalInfoVisible: false,
            linkBuilderInfo: {},
            mediasList: [],
            selectedMediasList: [],

            isLoadingSegmentations: false,
            segmentations: [],
            selectedSegmentations: [],

            publics: [],
            selectedPublics: [],

            isModalResponseVisible: false,
            msgResponseShow: ""
        }
        this.showModalCreateLink = this.showModalCreateLink.bind(this)
        this.closeModalCreateLink = this.closeModalCreateLink.bind(this)
        this.formSubmitCreateLink = this.formSubmitCreateLink.bind(this)
        this.reloadDatatableLink = this.reloadDatatableLink.bind(this)
        
        this.addSegmentation = this.addSegmentation.bind(this)
        this.translate = this.props.translate
    }

    handleCloseModalResponse = (e = null) => {
        if (e) e.preventDefault()
        this.setState({ isModalResponseVisible: false })
    }

    showModalCreateLink(){
        this.setState({modalCreateLink: true})
    }

    closeModalCreateLink(){
        this.setState({modalCreateLink: false})
    }

    closeModalInfo = () =>{
        this.setState({isModalInfoVisible: false})
    }

    reloadDatatableLink(){
        $('#links_list').DataTable().ajax.reload();
    }

    addSegmentation(e){
        e.preventDefault();
        this.props.history.push("/targets")
    }

    async loadMediasList(){
        try{
            //const response = await api.get("/segmentation/json");
            const response = await api.get(`/business/get/media/json`);
            
            if(response && response.data.length > 0){
                this.setState({
                    mediasList: response.data.map(media=>{
                        return {value: media.name, key: media.code}
                    })
                })
            }
        }catch(err){
            console.log(err);
        }
    }

    async loadSegmentations(){
        this.setState({isLoadingSegmentations: true})
        try{
            const response = await api.get("/segmentation/json");
            this.setState({
                segmentations: response.data
            })
        }catch(err){
            console.log(err);
        }
        this.setState({isLoadingSegmentations: false})
    }

    handleMediaClick=(media, type)=>{
        let {selectedMediasList, mediasList} = this.state
        if(type === "remove"){
            let newSelectedMediasList = selectedMediasList.filter(selectedMedia=>selectedMedia.key !== media.key)
            selectedMediasList = newSelectedMediasList
            mediasList.push(media)
        } else {
            let newMediasList = mediasList.filter(newMedia=>newMedia.key !== media.key)
            mediasList = newMediasList
            selectedMediasList.push(media) 
            
        }
        this.setState({selectedMediasList, mediasList})
        this.updateFormCreateLinkMedias(selectedMediasList)
    }

    updateFormCreateLinkMedias = (selectedMediasList) => {
        let formCreateLink = this.state.formCreateLink
        let createLinkMedias = selectedMediasList.map(selectedMedia=>selectedMedia.key)
        formCreateLink["media"] = createLinkMedias
        this.setState({formCreateLink})

    }


    handleSegmentationClick=(segmentation, type)=>{
        let {selectedSegmentations, segmentations} = this.state
        if(type === "remove"){
            if(this.checkCanRemoveSegmentation(segmentation)) return
            let newSelectedSegmentations = selectedSegmentations.filter(selectedSegmentation=>selectedSegmentation.code !== segmentation.code)
            selectedSegmentations = newSelectedSegmentations
            segmentations.push(segmentation)
          
        } else {
            let newSegmentations = segmentations.filter(newSegmentation=>newSegmentation.code !== segmentation.code)
            segmentations = newSegmentations
            selectedSegmentations.push(segmentation) 
            
        }
        this.setState({selectedSegmentations, segmentations})
        this.updateFormCreateLinkSegmentationsAndChangePublics(selectedSegmentations)
    }

    checkCanRemoveSegmentation = (segmentation) => {
        let checkIsInSelectedPublics = false
        let {selectedPublics} = this.state
        if(selectedPublics && selectedPublics.length > 0 && selectedPublics.filter(selected=>selected.code === segmentation.code).length > 0){
            checkIsInSelectedPublics = true
        }
        return checkIsInSelectedPublics
    }

    updateFormCreateLinkSegmentationsAndChangePublics = (selectedSegmentations) =>{
        let formCreateLink = this.state.formCreateLink
        let createLinkSegmentations = selectedSegmentations.map(selectedSegmentation=>selectedSegmentation.code)
        formCreateLink["segmentation"] = createLinkSegmentations
        
        let publics = selectedSegmentations
        publics = this.filterEmptyPublics(publics)
        publics = this.filterPublicsThatAreSelected(publics)
        publics = this.filterEmptyPublics(publics)
        this.setState({formCreateLink, publics})
    }

    filterEmptyPublics = (publics) => {
        return publics.reduce((previous, segmentation)=>{
            if(segmentation.public_data && segmentation.public_data.length){
                previous.push(segmentation)
            } 
            return previous
        }, [])
    }

    filterPublicsThatAreSelected = (publics) => {
        let selectedPublicsCodes = this.getSelectedPublicsCodes()
        let newPublics = []
        publics.forEach(segmentation=>{
            let newPublicDatas = []
            segmentation.public_data.forEach(segmentationPublic=>{
                if(selectedPublicsCodes.indexOf(segmentationPublic.public_code) === -1) {
                    newPublicDatas.push(segmentationPublic)
                }
            })

            newPublics.push({...segmentation, public_data: newPublicDatas})
            
        })

        return newPublics
    }

    getSelectedPublicsCodes = () => {
        let {selectedPublics} = this.state
        let selectedPublicsCodes = []
        selectedPublics.forEach(selectedPublic=>{
            selectedPublicsCodes = [...selectedPublicsCodes, ...selectedPublic.public_data.map(singlePublic=>singlePublic.public_code)]
        })
        return selectedPublicsCodes
    }

    handlePublicClick=(segmentation, segmentationPublic, type)=>{
        let {selectedPublics, publics} = this.state
        
        if(type === "remove"){
            selectedPublics = this.removePublicFrom(selectedPublics, segmentationPublic)
            publics = this.addPublicIn(publics, segmentation, segmentationPublic)
        } else {
            publics = this.removePublicFrom(publics, segmentationPublic)
            selectedPublics = this.addPublicIn(selectedPublics, segmentation, segmentationPublic)
        }
        
        this.setState({selectedPublics, publics})
        this.updateFormCreateLinkPublics(selectedPublics)
    }

    removePublicFrom = (chosenPublic, segmentationPublic) => {
        let newSegmentation = []
        chosenPublic.forEach(singleSegmentation=>{
            let newPublicData = []
            singleSegmentation.public_data.forEach(singlePublic=>{
                if(singlePublic.public_code !== segmentationPublic.public_code){
                    newPublicData.push(singlePublic)
                }
            })
            newSegmentation.push({...singleSegmentation, public_data: newPublicData})
       })
       return this.abstractSegmentationsWithEmptyPublics(newSegmentation)
    }

    abstractSegmentationsWithEmptyPublics = (segmentations) => segmentations.filter(segmentation=>segmentation.public_data.length > 0)

    addPublicIn = (chosenPublic, segmentation, segmentationPublic) => {
        let checkSegmentationExists = false
        for(let selectedSegmentation of chosenPublic){
            if(selectedSegmentation.code === segmentation.code){
                checkSegmentationExists = true
                selectedSegmentation.public_data.push(segmentationPublic)
            }
        }
        if(!checkSegmentationExists){
            let newSegmentation = segmentation
            let newPublicData = newSegmentation.public_data.filter(selectedPublic=>selectedPublic.public_code === segmentationPublic.public_code)
            newSegmentation["public_data"] = newPublicData
            chosenPublic.push(newSegmentation)
        } 
        return chosenPublic
    }

    updateFormCreateLinkPublics = (selectedPublics) =>{
        let formCreateLink = this.state.formCreateLink
    
        formCreateLink["public"] = selectedPublics
        this.setState({formCreateLink}) 
    } 

    async formSubmitCreateLink(e){
        e.preventDefault();
        if(!this.state.formCreateLink.media || this.state.formCreateLink.media.length === 0){
            this.setState({isModalResponseVisible: true, msgResponseShow: this.translate('LINK_BUILDER_MODAL_SELECT_MEDIA')})
            return
        } else if(!this.state.formCreateLink.segmentation || this.state.formCreateLink.segmentation.length === 0){
            this.setState({isModalResponseVisible: true, msgResponseShow: this.translate('LINK_BUILDER_REQUIRED_INFORM_SEGMENTATION')})
            return
        }   else if(!this.state.formCreateLink.public || this.state.formCreateLink.public.length === 0){
            this.setState({isModalResponseVisible: true, msgResponseShow: this.translate('LINK_BUILDER_MODAL_SELECT_PUBLIC')})
            return
        } 

        $('#links_button_submit').prop('disabled', true).html(`<i className="fa fa-spinner"></i> ${this.translate('LINK_BUILDER_WAIT')}`)
        
        const data = this.state.formCreateLink;
        try{
            const response = await api.post("/link_builder/create", {data});
            if(response.data.success === true){
                this.reloadDatatableLink()
                this.closeModalCreateLink();
                $('#links_button_submit').prop('disabled', false).html(`<i className="fa fa-save"></i> ${this.translate('LINK_BUILDER_REGISTER')}`)
                this.setState({
                    mediasList: [],
                    segmentations: [],
                    selectedMediasList: [],
                    selectedSegmentations: [],
                    publics: [],
                    selectedPublics: [],
                    formCreateLink: {
                        name: null,
                        media: null,
                        segmentation: null,
                        public: null,
                        link: null,
                        journey_moment: null,
                        pitch_contents: null,
                        pitch_campaign: null,
                        pitch_click: null,
                        type: 'buylitics_url_shortener',
                    },
                }, ()=> {
                    this.loadMediasList()
                    this.loadSegmentations()
                })
            }else{
                console.log(response.data.msg);
            }
        }catch(err){
            console.log(err);
        }
    }

    
    componentDidMount(){

        this.loadMediasList()
        this.loadSegmentations();

        let tableLoadLink = this.state.tableLoadLink
        //const props = this.props;

        tableLoadLink = $('#links_list').DataTable({
            "searching": true,
            "paging": false,
            "info": false,
            "responsive": true,
            "language": {
                "url": this.translate('DATATABLE_LANGUAGE_URL')
            },
            "ajax": {
                "url": ajaxUrl('/link_builder/table'),
                "type": 'GET',
                "beforeSend": function (request) {
                    request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                }
            },
            "columnDefs": [{
                "targets": -1,
                "data": null,
                "defaultContent": 
                    `<div class="btn-group btn-shadow">
                        <button class="show_info btn blue btn-sm btn-cursor-pointer btn-table" title="${this.translate('LINK_BUILDER_MODAL_INFO_LINK_BUILDER_INFO')}">
                            <i class="fa fa-info" title="${this.translate('LINK_BUILDER_MODAL_INFO_LINK_BUILDER_INFO')}"></i>
                        </button>
                        <button class="view btn btn-sm red btn-cursor-pointer btn-table" title="${this.translate('LINK_BUILDER_VISUALIZE_LINK_BUTTON')}">
                            <i class="fa fa-eye" title="${this.translate('LINK_BUILDER_VISUALIZE_LINK_BUTTON')}"></i>
                        </button>
                        <button class="copy_link btn btn-sm danger btn-cursor-pointer btn-table" title="${this.translate('LINK_BUILDER_COPY_LINK_BUTTON')}">
                            <i class="fa fa-copy" title="${this.translate('LINK_BUILDER_COPY_LINK_BUTTON')}"></i>
                        </button>
                        <button class="open_qrcode btn btn-sm purple btn-cursor-pointer btn-table" title="QR Code">
                            <i class="fa fa-qrcode" title="QR Code"></i>
                        </button>
                    </div>`
            }]
        });

        $('#links_list tbody').on( 'click', '.show_info', (e)=>{
            
            e.preventDefault();

            let data = [];
            
            if($(e.target).parents('tr').hasClass('child')){
                data = tableLoadLink.row($(e.target).parents('tr').prev('.parent')).data();
            }else{
                data = tableLoadLink.row($(e.target).parents('tr')).data();
            }
            
            let linkBuilderInfo = data[9]
            this.setState({linkBuilderInfo: linkBuilderInfo, isModalInfoVisible: true})
            
        })

        $('#links_list tbody').on( 'click', '.view', function (e){
            
            e.preventDefault();

            let data = [];
            
            if($(e.target).parents('tr').hasClass('child')){
                data = tableLoadLink.row($(e.target).parents('tr').prev('.parent')).data();
            }else{
                data = tableLoadLink.row($(e.target).parents('tr')).data();
            }
            
            //alert(data[4])

            let link = window.open(data[6]);
            link.focus();
            
        });

        $('#links_list tbody').on('click', '.copy_link', function(e){
            e.preventDefault();
            let data = [];
            
            if($(e.target).parents('tr').hasClass('child')){
                data = tableLoadLink.row($(e.target).parents('tr').prev('.parent')).data();
            }else{
                data = tableLoadLink.row($(e.target).parents('tr')).data();
            }

            $('#copy_link_input').val(data[6]);

            var $temp = $("<input>");
            $("body").append($temp);
            $temp.val($('#copy_link_input').val()).select();
            document.execCommand("copy");
            $temp.remove();
        });
       
        $('#links_list tbody').on('click', '.open_qrcode', function(e){
            e.preventDefault();

            let data = [];
            
            if($(e.target).parents('tr').hasClass('child')){
                data = tableLoadLink.row($(e.target).parents('tr').prev('.parent')).data();
            }else{
                data = tableLoadLink.row($(e.target).parents('tr')).data();
            }
            
            let link = window.open(`http://chart.googleapis.com/chart?chs=500&cht=qr&chld=Q|1&chl=${data[6]}`);
            link.focus()
        });


    }

    render() {
        return (
            <Fragment>
                <MenuMain />
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    <HeaderMain title={document.title} onChange={this.handleChange}/>
                    <div className="content-main row row-no-margin" id="content-main">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-gutters mt-4">
                            <div className="row text-right mb-3">
                                <div className="col-md-12">
                                    <button className="btn btn-fw red btn-rounded pull-right btn-shadow btn-cursor-pointer" onClick={this.showModalCreateLink}>
                                        <i className="fa fa-link"></i> {this.translate('LINK_BUILDER_CREATE_NEW_LINK')}
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="box p-2">
                                        <input type="text" id="copy_link_input" style={{display: "none"}}></input>
                                        <table id="links_list" className="table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                                            <thead>
                                                <tr className="text-left">
                                                    <th>{this.translate('LINK_BUILDER_TABLE_NUMBER')}</th>
                                                    <th>{this.translate('LINK_BUILDER_TABLE_NAME')}</th>
                                                    <th>{this.translate('LINK_BUILDER_TABLE_MEDIA')}</th>
                                                    <th>{this.translate('LINK_BUILDER_TABLE_SEGMENTATION')}</th>
                                                    <th>{this.translate('LINK_BUILDER_TABLE_PUBLIC')}</th>
                                                    <th>{this.translate('LINK_BUILDER_TABLE_ORIGINAL_URL')}</th>
                                                    <th>{this.translate('LINK_BUILDER_TABLE_SHAREABLE_URL')}</th>
                                                    <th>{this.translate('LINK_BUILDER_TABLE_DESTINATION_URL')}</th>
                                                    <th>{this.translate('TABLE_ACTIONS_ROW')}</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterMain />
                </div>
                <Modal show={this.state.modalCreateLink} onHide={this.closeModalCreateLink} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t modal-link-builder">
                    <Modal.Body>
                    <div className="d-flex flex-column h-100 pb-5">
                        <div className="p-3 b-b d-flex no-shrink red text-center">
                            <h5 className="h5 m-0 text-center width100 line-height-38px">
                                <i className="fa fa-link"></i> {this.translate('LINK_BUILDER_CREATE_NEW_LINK')}
                            </h5>
                            <button onClick={this.closeModalCreateLink} className="btn btn-transparent ml-auto">
                                <span className="text-lg l-h-1x">×</span>
                            </button>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-6 offset-md-3">

                                <form onSubmit={this.formSubmitCreateLink}>
                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('LINK_BUILDER_MODAL_LABEL_LINK_NAME')}</label>
                                        <input type="text" onChange={e => this.setState({ formCreateLink: {...this.state.formCreateLink, name:  e.target.value}})} className="form-control box-shadow cool-input" placeholder={this.translate('LINK_BUILDER_MODAL_PLACEHOLDER_LINK_NAME')} required="required" />
                                    </div>
                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('LINK_BUILDER_MODAL_LABEL_MEDIA')}</label>
                                        <div className="row">
                                            <div className="col-12 mx-2 multiple_selections_modal">
                                                <div className="row">
                                                    <div className="px-2 col-6">
                                                        <h5 className="text-light mt-3">{this.translate("LINK_BUILDER_MODAL_ALL_MEDIAS")}</h5>
                                                        <hr  className="my-2"/>
                                                        <ul className="list-group">
                                                            {this.state.mediasList && this.state.mediasList.length ?
                                                                this.state.mediasList.map((media, index)=>
                                                                    <li key={`media${index} + ${media.key}`} className={`d-flex align-items-center list-group-item ${index % 2 === 0 ? "multiple_selections_modal_odd" : "multiple_selections_modal_even"}`}>
                                                                        <span className="flex-grow-1">{media.value}</span>
                                                                        <button type="button" className="btn btn-primary btn-cursor-pointer" onClick={()=>this.handleMediaClick(media, "add")}>
                                                                            <i className="fa fa-plus" />
                                                                        </button>
                                                                    </li>    
                                                                )
                                                            :
                                                                <li className="list-group-item multiple_selections_modal_even">{this.translate("LINK_BUILDER_MODAL_THERE_ARENT_MORE_MEDIAS")}</li>
                                                            }
                                                        </ul>
                                                    </div>
                                                    <div className="px-2 col-6">
                                                        <h5 className="text-light mt-3">{this.translate("LINK_BUILDER_MODAL_OPTION_SELECTED_MEDIAS")}</h5>
                                                        <hr  className="my-2"/>
                                                        <ul className="list-group">
                                                            {this.state.selectedMediasList && this.state.selectedMediasList.length ?
                                                                this.state.selectedMediasList.map((media, index)=>
                                                                    <li key={`selected_media${index} + ${media.key}`} className={`d-flex align-items-center list-group-item ${index % 2 === 0 ? "multiple_selections_modal_odd" : "multiple_selections_modal_even"}`}>
                                                                        <span className="flex-grow-1">{media.value}</span>
                                                                        <button type="button" className="btn btn-danger btn-cursor-pointer" onClick={()=>this.handleMediaClick(media, "remove")}>
                                                                            <i className="fa fa-trash" />
                                                                        </button>
                                                                    </li>    
                                                                )
                                                            :
                                                                <li className="list-group-item multiple_selections_modal_even">{this.translate('LINK_BUILDER_MODAL_OPTION_SELECT_MEDIAS')}</li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('LINK_BUILDER_MODAL_OPTION_SELECT_SEGMENTATION')} &nbsp;</label>
                                        {(this.state.segmentations && this.state.segmentations.length || this.state.selectedSegmentations && this.state.selectedSegmentations.length ) ?
                                        
                                            <div className="row">
                                                <div className="col-12 mx-2 multiple_selections_modal">
                                                    <div className="row">
                                                        <div className="px-2 col-6">
                                                            <h5 className="text-light mt-3">{this.translate("LINK_BUILDER_MODAL_LABEL_ALL_SEGMENTATIONS")}</h5>
                                                            <hr  className="my-2"/>
                                                            <ul className="list-group">
                                                                {this.state.segmentations && this.state.segmentations.length ?
                                                                    this.state.segmentations.map((target, index)=>
                                                                        <li key={`target${index} + ${target.code}`} className={`d-flex align-items-center list-group-item ${index % 2 === 0 ? "multiple_selections_modal_odd" : "multiple_selections_modal_even"}`}>
                                                                            <span className="flex-grow-1">{target.name}</span>
                                                                            <button type="button" className="btn btn-primary btn-cursor-pointer" onClick={()=>this.handleSegmentationClick(target, "add")}>
                                                                                <i className="fa fa-plus" />
                                                                            </button>
                                                                        </li>    
                                                                    )
                                                                :
                                                                    <li className={`d-flex align-items-center list-group-item multiple_selections_modal_odd`}>
                                                                        <span className="flex-grow-1">{this.translate("LINK_BUILDER_MODAL_THERE_ARENT_MORE_SEGMENTATIONS")}</span>
                                                                    </li> 
                                                                }
                                                            </ul>
                                                        </div>
                                                        <div className="px-2 col-6">
                                                            <h5 className="text-light mt-3">{this.translate("LINK_BUILDER_MODAL_LABEL_SELECTED_SEGMENTATIONS")}</h5>
                                                            <hr  className="my-2"/>
                                                            <ul className="list-group">
                                                                {this.state.selectedSegmentations && this.state.selectedSegmentations.length ?
                                                                    this.state.selectedSegmentations.map((target, index)=>
                                                                        <li key={`selected_target${index} + ${target.code}`} className={`d-flex align-items-center list-group-item ${index % 2 === 0 ? "multiple_selections_modal_odd" : "multiple_selections_modal_even"}`}>
                                                                            <span className="flex-grow-1">{target.name}</span>
                                                                            <button type="button" className="btn btn-danger btn-cursor-pointer" disabled={this.checkCanRemoveSegmentation(target)} onClick={()=>this.handleSegmentationClick(target, "remove")}>
                                                                                <i className="fa fa-trash" />
                                                                            </button>
                                                                        </li>    
                                                                    )
                                                                :
                                                                    <li className="list-group-item multiple_selections_modal_even">{this.translate('LINK_BUILDER_MODAL_OPTION_SELECT_SEGMENTATION')}</li>
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        :
                                            this.state.isLoadingSegmentations ? 
                                                <i className="fa fa-spin fa-spinner" />
                                            :
                                            <div>
                                                <button onClick={this.addSegmentation} style={{width: "100%"}} className="btn btn-cursor-pointer red btn-shadow" value="">{this.translate('LINK_BUILDER_MODAL_NO_SEGMENTATION_CLICK_TO_ADD')}</button>
                                            </div>
                                        }
                                    </div>
                                    

                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('LINK_BUILDER_MODAL_LABEL_PUBLIC')}</label>
                                        <div className="row">
                                            <div className="col-12 mx-2 multiple_selections_modal">
                                                <div className="row">
                                                    <div className="px-2 col-6 multiple_selections_responsive_column">
                                                        <h5 className="text-light mt-3">{this.translate("LINK_BUILDER_MODAL_LABEL_ALL_PUBLICS")}</h5>
                                                        {this.state.publics && this.state.publics.length ?
                                                            this.state.publics.map((segmentation, index)=>
                                                                <div className="mt-2" key={`public_segmentation_${index}${segmentation.code}`}>
                                                                    <span>{segmentation.name}</span>
                                                                    <hr  className="my-1"/>
                                                                    <ul className="list-group">
                                                                        {segmentation.public_data.map((segmentationPublic, indexPublic)=>
                                                                            <li key={`public${indexPublic} + ${segmentationPublic.public_code}`} className={`d-flex align-items-center list-group-item ${indexPublic % 2 === 0 ? "multiple_selections_modal_odd" : "multiple_selections_modal_even"}`}>
                                                                                <span className="flex-grow-1">{segmentationPublic.public_name}</span>
                                                                                <button type="button" className="btn btn-primary btn-cursor-pointer" onClick={()=>this.handlePublicClick(segmentation, segmentationPublic, "add")}>
                                                                                    <i className="fa fa-plus" />
                                                                                </button>
                                                                            </li>    
                                                                        )}
                                                                        
                                                                    </ul>
                                                                </div>
                                                            
                                                            )
                                                        :
                                                        <li className={`d-flex align-items-center list-group-item multiple_selections_modal_odd`}>
                                                            <span className="flex-grow-1">{this.translate("LINK_BUILDER_MODAL_NO_PUBLIC")}</span>
                                                        </li> 
                                                        }
                                                        
                                                    </div>
                                                    <div className="px-2 col-6 multiple_selections_responsive_column">
                                                        <h5 className="text-light mt-3">{this.translate("LINK_BUILDER_MODAL_LABEL_SELECTED_PUBLICS")}</h5>
                                                        {this.state.selectedPublics && this.state.selectedPublics.length ?
                                                            this.state.selectedPublics.map((segmentation, index)=>
                                                                <div className="mt-2" key={`public_segmentation_${index}${segmentation.code}`}>
                                                                    <span>{segmentation.name}</span>
                                                                    <hr  className="my-1"/>
                                                                    <ul className="list-group">
                                                                        {segmentation.public_data.map((segmentationPublic, indexPublic)=>
                                                                            <li key={`public${indexPublic} + ${segmentationPublic.public_code}`} className={`d-flex align-items-center list-group-item ${indexPublic % 2 === 0 ? "multiple_selections_modal_odd" : "multiple_selections_modal_even"}`}>
                                                                                <span className="flex-grow-1">{segmentationPublic.public_name}</span>
                                                                                <button type="button" className="btn btn-danger btn-cursor-pointer" onClick={()=>this.handlePublicClick(segmentation, segmentationPublic, "remove")}>
                                                                                    <i className="fa fa-trash" />
                                                                                </button>
                                                                            </li>    
                                                                        )}
                                                                        
                                                                    </ul>
                                                                </div>
                                                            
                                                            )
                                                        :
                                                        <li className={`d-flex align-items-center list-group-item multiple_selections_modal_odd`}>
                                                            <span className="flex-grow-1">{this.translate("LINK_BUILDER_MODAL_OPTION_SELECT_PUBLICS")}</span>
                                                        </li> 
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 


                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('LINK_BUILDER_MODAL_LABEL_LEAD_JOURNEY_MOMENT')}</label>
                                        <select onChange={e => this.setState({ formCreateLink: {...this.state.formCreateLink, journey_moment: e.target.value}})} className="form-control box-shadow cool-select" required="required">
                                            <option value="">{this.translate('LINK_BUILDER_MODAL_OPTION_SELECT_LEAD_JOURNEY_MOMENT')}</option>
                                            <option value="start">{this.translate('LINK_BUILDER_MODAL_OPTION_LEAD_JOURNEY_MOMENT_START')}</option>
                                            <option value="middle">{this.translate('LINK_BUILDER_MODAL_OPTION_LEAD_JOURNEY_MOMENT_MIDDLE')}</option>
                                            <option value="end">{this.translate('LINK_BUILDER_MODAL_OPTION_LEAD_JOURNEY_MOMENT_END')}</option>
                              
                                        </select>
                                    </div>
                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('LINK_BUILDER_MODAL_CAMPAIGN_PITCH')}</label>
                                        <input type="text" onChange={e => this.setState({ formCreateLink: {...this.state.formCreateLink, pitch_campaign:  e.target.value}})} className="form-control box-shadow cool-input" placeholder={this.translate('LINK_BUILDER_MODAL_CAMPAIGN_PITCH')} required="required" />
                                    </div>
                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('LINK_BUILDER_MODAL_CONTENT_PITCH')}</label>
                                        <input type="text" onChange={e => this.setState({ formCreateLink: {...this.state.formCreateLink, pitch_contents:  e.target.value}})} className="form-control box-shadow cool-input" placeholder={this.translate('LINK_BUILDER_MODAL_CONTENT_PITCH')} required="required" />
                                    </div>
                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('LINK_BUILDER_MODAL_CLICK_PITCH')}</label>
                                        <input type="text" onChange={e => this.setState({ formCreateLink: {...this.state.formCreateLink, pitch_click:  e.target.value}})} className="form-control box-shadow cool-input" placeholder={this.translate('LINK_BUILDER_MODAL_CLICK_PITCH')} required="required" />
                                    </div>
                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('LINK_BUILDER_MODAL_LABEL_GENERATED_LINK_TYPE')}</label>
                                        <select onChange={e => this.setState({ formCreateLink: {...this.state.formCreateLink, type:  e.target.value}})} className="form-control box-shadow cool-select" required="required">
                                            <option value="buylitics_url_shortener">{this.translate('LINK_BUILDER_MODAL_OPTION_BUYLITICS_URL_SHORTENER')}</option>
                                            <option value="add_trace_parameters">{this.translate('LINK_BUILDER_MODAL_OPTION_ADD_TRACE_PARAMETERS')}</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('LINK_BUILDER_MODAL_DESTINATION_LINK')}</label>
                                        <input type="text" onChange={e => this.setState({ formCreateLink: {...this.state.formCreateLink, link:  e.target.value}})} className="form-control box-shadow cool-input" placeholder={this.translate('LINK_BUILDER_MODAL_DESTINATION_LINK')} required="required" />
                                    </div>
                                    <button type="submit" id="links_button_submit" className="btn btn-fw red btn-rounded mt20 pull-right btn-shadow px-5"><i className="fa fa-save"></i> {this.translate('LINK_BUILDER_REGISTER')}</button>
                                    <br />
                                </form>

                            </div>
                        </div>
                    </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.isModalInfoVisible} onHide={this.closeModalInfo} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                    <div className="d-flex flex-column h-100 pb-5">
                        <div className="p-3 b-b d-flex no-shrink blue text-center">
                            <h5 className="h5 m-0 text-center width100 line-height-38px">
                                <i className="fa fa-info"></i> {this.translate('LINK_BUILDER_MODAL_INFO_LINK_BUILDER_INFO')}
                            </h5>
                            <button onClick={this.closeModalInfo} className="btn btn-transparent ml-auto">
                                <span className="text-lg l-h-1x">×</span>
                            </button>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-6 offset-md-3">
                            
                                <h5>{this.translate('LINK_BUILDER_MODAL_INFO_LINK_BUILDER_INFO')}</h5>
                                

                                <p>
                                    <b>{this.translate('LINK_BUILDER_MODAL_INFO_NAME')}: </b> {this.state.linkBuilderInfo.name || this.translate("LINK_BUILDER_MODAL_UNKNOWN")}
                                </p>
                                <p>
                                    <b>{this.translate("LINK_BUILDER_MODAL_INFO_MEDIA")}: </b> {this.state.linkBuilderInfo.media || this.translate("LINK_BUILDER_MODAL_UNKNOWN")}
                                </p>
                                <p>
                                    <b>{this.translate("LINK_BUILDER_MODAL_INFO_SEGMENTATION")}: </b> {this.state.linkBuilderInfo.segmentation || this.translate("LINK_BUILDER_MODAL_UNKNOWN")}
                                </p>
                                <p>
                                    <b>{this.translate("LINK_BUILDER_MODAL_INFO_PUBLIC")}: </b> {this.state.linkBuilderInfo.public || this.translate("LINK_BUILDER_MODAL_UNKNOWN")}
                                </p>
                                <p>
                                    <b>{this.translate('LINK_BUILDER_MODAL_INFO_CAMPAIGN_PITCH')}: </b> {this.state.linkBuilderInfo.pitch_campaign || this.translate("LINK_BUILDER_MODAL_UNKNOWN")}
                                </p>
                                <p>
                                    <b>{this.translate('LINK_BUILDER_MODAL_INFO_CONTENT_PITCH')}: </b> {this.state.linkBuilderInfo.pitch_contents || this.translate("LINK_BUILDER_MODAL_UNKNOWN")}
                                </p>
                                <p>
                                    <b>{this.translate("LINK_BUILDER_MODAL_INFO_CLICK_PITCH")}: </b> {this.state.linkBuilderInfo.pitch_click || this.translate("LINK_BUILDER_MODAL_UNKNOWN")}
                                </p>
                                <p>
                                    <b>{this.translate('LINK_BUILDER_MODAL_INFO_SEGMENTATION_PITCH')}: </b> {this.state.linkBuilderInfo.pitch_segmentation || this.translate("LINK_BUILDER_MODAL_UNKNOWN")}
                                </p>
                                <p>
                                    <b>{this.translate('LINK_BUILDER_MODAL_INFO_PUBLIC_PITCH')}: </b> {this.state.linkBuilderInfo.pitch_public || this.translate("LINK_BUILDER_MODAL_UNKNOWN")}
                                </p>
                                <hr />
                                <p>
                                    <b>{this.translate("LINK_BUILDER_MODAL_INFO_ORIGINAL_LINK")}: </b> <a style={{textDecoration: "underline"}} href={this.state.linkBuilderInfo.original_link} target="_blank">{this.state.linkBuilderInfo.original_link || this.translate("LINK_BUILDER_MODAL_UNKNOWN")}</a>
                                </p>
                                <p>
                                    <b>{this.translate("LINK_BUILDER_MODAL_INFO_SHAREABLE_LINK")}: </b> <a style={{textDecoration: "underline"}} href={this.state.linkBuilderInfo.source_link} target="_blank">{this.state.linkBuilderInfo.source_link || this.translate("LINK_BUILDER_MODAL_UNKNOWN")}</a>
                                </p>
                                <p>
                                    <b>{this.translate("LINK_BUILDER_MODAL_INFO_DESTINATION_LINK")}: </b> <a style={{textDecoration: "underline"}} href={this.state.linkBuilderInfo.destination_link} target="_blank">{this.state.linkBuilderInfo.destination_link || this.translate("LINK_BUILDER_MODAL_UNKNOWN")}</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.isModalResponseVisible} onHide={this.handleCloseModalResponse} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                    <i className="fa fa-bell"></i> {this.translate("MODAL_STATUS_STATUS")}</h5>
                                <a href="/" onClick={e => this.handleCloseModalResponse(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                <span className="col-12 text-center py-2 lead">
                                    {this.state.msgResponseShow}
                                </span>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </Fragment>
        );
    }
}

export default withRouter(LinkBuilder);