import React, { Component } from 'react';

import Modal from 'react-bootstrap/Modal';

class BusinessFGECOC extends Component {

    constructor(props){
        super(props)
        this.state = {
            showGoals: false,
        }
    }

    render() {
        return (
            <div className="col-md-12 col-sm-12">
                <div className="row">
                    <div className="col-md-12 col-sm-12 p0 text-center">
                        <h6 className="f300">
                            <span className="f600">
                                <i className="fa fa-bullseye"></i>
                                Foco do trabalho: 
                            </span>
                            <input type="text" id="input_foco_objetivos" name="foco"/>
                            <button type="button" id="button_foco_objetivos">
                                <i className="fa fa-send-o"></i>
                            </button>
                        </h6>
                        <hr/>
                    </div>
                    <div className="col-md-12 col-sm-12">
                        <div className="b-b nav-active-bg pull-left w-100">
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <a className="nav-link" href="/" data-toggle="tab" data-target="#tab2">
                                        <span className="btn btn-icon btn-rounded btn-social blue text-white">
                                            <i className="fa fa-sitemap"></i>
                                            <i className="fa fa-check"></i>
                                        </span>
                                        Objetivos
                                    </a>
                                </li>
                                <li className="nav-item pull-right">
                                    <a className="nav-link" href="/" data-toggle="tab" data-target="#tab6">
                                        <span className="btn btn-icon btn-rounded btn-social blue text-white">
                                            <i className="fa fa-calendar"></i>
                                            <i className="fa fa-check"></i>
                                        </span>
                                        Datas importantes
                                    </a>
                                </li>
                                <li className="nav-item nav-item3">
                                    <a className="nav-link" href="/" data-toggle="tab" data-target="#tab4">
                                        <span className="btn btn-icon btn-rounded btn-social danger text-white">
                                            <i className="fa fa-bomb"></i>
                                            <i className="fa fa-check"></i>
                                        </span>
                                        Desafios
                                    </a>
                                </li>
                                <li className="nav-item nav-item2">
                                    <a className="nav-link" href="/" data-toggle="tab" data-target="#tab5">
                                        <span className="btn btn-icon btn-rounded btn-social green text-white">
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-check"></i>
                                        </span>
                                        Oportunidades
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="b-b nav-active-bg pull-right">
                            <ul className="nav nav-tabs"></ul>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="tab-content p-3 mb-3 pull-right w-100 p0 mt10 mb10">
                            <div className="tab-pane animate fadeIn pt20" id="tab6">
                                <div className="row m0" id="list_datas">

                                </div>
                                <div className="row m0">
                                    <div className="col-md-9 col-sm-12">
                                        <button className="btn btn-sm teal btn-shadow btn-cursor" data-toggle="modal" data-target="#criar_data" data-toggle-class="modal-open-aside">
                                            <i className="fa fa-plus"></i> Criar novo evento
                                        </button>
                                    </div>
                                </div>

                            </div>
                            <div className="tab-pane animate fadeIn pt20" id="tab2">
                                <div className="row m0 list_objectives mb-2" id="accordion">

                                </div>
                                <div className="row m0">
                                    <div className="col-md-9 col-sm-12">
                                        <button onClick={() => {this.setState({showCreateGoals: true})}} className="btn btn-sm teal btn-shadow btn-cursor" data-toggle="modal" data-target="#objetivo" data-toggle-class="modal-open-aside">
                                            <i className="fa fa-plus"></i> Criar Novo Objetivo
                                        </button>
                                    </div>
                                </div>

                                <Modal show={this.state.showGoals} onHide={() => {this.setState({showGoals: false})}} dialogAs={'div'} dialogClassName="modal-right w-50 dark b-l box-shadow">
                                    <Modal.Body>
                                        <div className="d-flex flex-column h-100">
                                            <div className="p-3 b-b d-flex no-shrink">
                                                <h5 className="h5 m-0 fwhite">Mensagens Objetivo</h5>
                                                <br/>
                                                <a href="/" onClick={(e) => {e.preventDefault();this.setState({showGoals: false})}} data-dismiss="modal" className="ml-auto">
                                                    <span className="text-lg l-h-1x">×</span>
                                                </a>
                                            </div>
                                            <div className="p-3 b-b d-flex no-shrink">
                                                <h6 id="historico_titulo_objetivo">Teste</h6>
                                            </div>
                                            <div className="p-3 white lt b-t mt-auto mt10" id="chat-form">
                                                <form id="form_historico_objetivo" className="input-group">
                                                    <input type="hidden" name="id" id="historico_titulo_id"/>
                                                    <input type="text" name="msg" className="form-control" placeholder="Diga alguma coisa..."/>
                                                    <span className="input-group-btn">
                                                    <button className="btn white b-a no-shadow" type="submit">
                                                        <i className="fa fa-send text-primary"></i>
                                                    </button>
                                                </span>
                                                </form>
                                            </div>
                                            <div className="p-3 b-t scroll-y">
                                                <div className="streamline streamline-xs streamline-dotted" id="list_historico_objetivos"></div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>

                                <Modal show={this.state.showCreateGoals} onHide={() => {this.setState({showCreateGoals: false})}} dialogAs={'div'} dialogClassName="modal-right w-50 dark b-l box-shadow">
                                    <Modal.Body>
                                        <div className="d-flex flex-column h-100">
                                            <div className="p-3 b-b d-flex no-shrink">
                                                <h5 className="h5 m-0 fwhite">Criar Objetivo</h5>
                                                <br/>
                                                <a href="/" onClick={(e) => {e.preventDefault();this.setState({showCreateGoals: false})}} data-dismiss="modal" className="ml-auto">
                                                    <span className="text-lg l-h-1x">×</span>
                                                </a>
                                            </div>
                                            <div className="p-3 white lt b-t mt-auto mt10  scroll-y">
                                                <form id="form_criar_objetivo" className="padding">
                                                    <div className="row">
                                                        <div className="col-md-10 col-sm-12">
                                                            <div className="form-group">
                                                                <label className="m0">Título do objetivo:</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="criar_objetivo_title"
                                                                    name="title"
                                                                    placeholder="Informe o titulo do novo objetivo!"
                                                                    required="required"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-5 col-sm-12">
                                                            <div className="form-group">
                                                                <label className="m0">Data inicial:</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control mb-3"
                                                                    id="criar_objetivo_date_initial"
                                                                    name="date_initial"
                                                                    placeholder="de:"
                                                                    data-plugin="datepicker"
                                                                    data-option="{format: 'dd/mm/yyyy'}"
                                                                    required="required"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-5 col-sm-12">
                                                            <div className="form-group">
                                                                <label className="m0">Data final:</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control mb-3"
                                                                    id="criar_objetivo_date_end"
                                                                    name="date_end"
                                                                    placeholder="até:"
                                                                    data-plugin="datepicker" 
                                                                    data-option="{format: 'dd/mm/yyyy'}"
                                                                    required="required"
                                                                />
                                                            </div>
                                                        </div>
                                                        
                                                        <React.Fragment>
                                                            <div></div>
                                                        </React.Fragment>

                                                        <div className="col-md-12 col-sm-12">
                                                            <button className="md-btn md-flat mb-2 text-accent" id="add_new_objetive">+ Adicionar outro resultado-chave</button>
                                                        </div>
                                                        <div className="col-md-12 col-sm-12">
                                                            <button type="submit" className="btn btn-success">Salvar objetivo</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    </Modal.Body>
                                </Modal>

                                <Modal show={this.state.showEditGoals} onHide={() => {this.setState({showEditGoals: false})}} dialogAs={'div'} dialogClassName="modal-right w-50 dark b-l box-shadow">
                                    <Modal.Body>

                                    </Modal.Body>
                                </Modal>

                            </div>

                            <div className="tab-pane animate fadeIn pt20" id="tab4">
                                <div className="row m0" id="list_desafio">

                                </div>
                                <div className="row m0">
                                    <div className="col-md-9 col-sm-12">
                                        <button className="btn btn-sm teal btn-shadow btn-cursor" data-toggle="modal" data-target="#criar_desafio" data-toggle-class="modal-open-aside">
                                            <i className="fa fa-plus"></i> Criar novo desafio
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane animate fadeIn pt20" id="tab5">
                                <div className="row m0" id="list_oportunidade">
                                    
                                </div>
                                <div className="row m0">
                                    <div className="col-md-9 col-sm-12">
                                        <button className="btn btn-sm teal btn-shadow btn-cursor" data-toggle="modal" data-target="#criar_oportunidade" data-toggle-class="modal-open-aside">
                                            <i className="fa fa-plus"></i> Criar nova oportunidade
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr/>
                    </div>
                </div>
            </div>
        );
    }
}

export default BusinessFGECOC;