import React, { Component, Fragment } from 'react';
import {withRouter} from 'react-router-dom';

import Modal from 'react-bootstrap/Modal';

import $ from 'jquery';
import 'jquery-mask-plugin';

import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4'

import api from '../../../Services/Api';
import {ajaxUrl, ajaxToken} from '../../../Services/Ajax';

import MenuMain from '../../../Structure/MenuMain';
import HeaderMain from '../../../Structure/HeaderMain';
import FooterMain from '../../../Structure/FooterMain';

class SMSList extends Component {

    constructor(props){
        super(props)
        this.translate = this.props.translate
        document.title = this.translate('SMS_LIST_SMS')
        this.state = {
            tableLoadSMS: null,
            modalCreateSMS: false,
            modalSendSMS: false,
            formCreateSMS: {
                name: null,
                text: null,
                pitch_sms: null
            },
            formSendSMS: {
                number: null,
                id: null,
                name: null
            }
        }
        this.showModalCreateSMS = this.showModalCreateSMS.bind(this)
        this.closeModalCreateSMS = this.closeModalCreateSMS.bind(this)
        this.formSubmitCreateSMS = this.formSubmitCreateSMS.bind(this)
        this.reloadDatatableSMS = this.reloadDatatableSMS.bind(this)

        this.showModalSendSMS = this.showModalSendSMS.bind(this)
        this.closeModalSendSMS = this.closeModalSendSMS.bind(this)
        this.formSubmitSendSMS = this.formSubmitSendSMS.bind(this)
    }

    showModalCreateSMS(){
        this.setState({modalCreateSMS: true})
    }

    closeModalCreateSMS(){
        this.setState({modalCreateSMS: false})
    }

    reloadDatatableSMS(){
        $('#sms_list').DataTable().ajax.reload();
    }

    async formSubmitCreateSMS(e){
        e.preventDefault();
        
        $('#sms_button_submit').prop('disabled', true).html(`<i className="fa fa-spinner"></i> ${this.translate('SMS_LIST_WAIT')}`)
        
        const data = this.state.formCreateSMS;
        try{
            const response = await api.post("/sms/create", {data});
            if(response.data.success === true){
                this.reloadDatatableSMS()
                this.closeModalCreateSMS();
                $('#sms_button_submit').prop('disabled', false).html(`<i className="fa fa-save"></i> ${this.translate('SMS_LIST_REGISTER')}`)
            }else{
                this.setState({msgResponseShow: response.data.msg});
            }
        }catch(err){
            this.setState({msgResponseShow: err});
        }
    }

    /* test send sms */

    showModalSendSMS(){
        this.setState({modalSendSMS: true})
        $('#sms_send_number').mask('(00) 00000-0000')
    }

    closeModalSendSMS(){
        $('#sms_send_status').html('');
        this.setState({modalSendSMS: false})
    }

    async formSubmitSendSMS(e){
        e.preventDefault();
        
        $('#sms_send_button_submit').prop('disabled', true).html(`<i className="fa fa-spinner"></i> ${this.translate('SMS_LIST_WAIT')}`)
        
        const data = this.state.formSendSMS;
        try{
            const response = await api.post("/sms/send", {data});
            if(response.data.success === true){
                $('#sms_send_status').html(`${this.translate('SMS_LIST_SUCCESS_ADDING_TO_SUBMISSION_QUEUE')}`);
                $('#sms_send_button_submit').prop('disabled', false).html(`<i className="fa fa-paper-plane"></i> ${this.translate('SMS_LIST_SEND')}`)
            }else{
                $(`#sms_send_status`).html(`${this.translate('SMS_LIST_ERROR_ADDING_TO_SUBMISSION_QUEUE')}`);
            }
        }catch(err){
            console.log(err);
        }
    }

    componentDidMount(){

        let tableLoadSMS = this.state.tableLoadSMS
        const props = this.props;
        console.log(props)

        tableLoadSMS = $('#sms_list').DataTable({
            "searching": true,
            "paging": false,
            "info": false,
            "responsive": true,
            "language": {
                "url": this.translate('DATATABLE_LANGUAGE_URL')
            },
            "ajax": {
                "url": ajaxUrl('/sms/table'),
                "type": 'GET',
                "beforeSend": function (request) {
                    request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                }
            },
            "columnDefs": [{
                "targets": -1,
                "data": null,
                "defaultContent": 
                    `<div class="btn-group btn-shadow">
                        <button class="edit btn btn-sm purple btn-cursor-pointer" title="${this.translate('SMS_LIST_EDIT_SMS')}">
                            <i class="fa fa-cogs" title="${this.translate('SMS_LIST_EDIT_SMS')}"></i>
                        </button>
                        <button class="send_test btn btn-sm deep-purple btn-cursor-pointer" title="${this.translate('SMS_LIST_SEND_SMS_TEST')}">
                            <i class="fa fa-share" title="${this.translate('SMS_LIST_SEND_SMS_TEST')}"></i>
                        </button>
                        <button class="trigger btn btn-sm amber btn-cursor-pointer" title="${this.translate('SMS_LIST_SMS_SMS_TRIGGER')}">
                            <i class="fa fa-comments" title="${this.translate('SMS_LIST_SMS_SMS_TRIGGER')}"></i>
                        </button>
                    </div>`
            }]
        });

        $('#sms_list tbody').on( 'click', '.edit', function (e){
            
            e.preventDefault();

            let data = [];
            
            if($(e.target).parents('tr').hasClass('child')){
                data = tableLoadSMS.row($(e.target).parents('tr').prev('.parent')).data();
            }else{
                data = tableLoadSMS.row($(e.target).parents('tr')).data();
            }

            console.log(data)
            
        });

        $('#sms_list tbody').on( 'click', '.send_test', function (e){
            
            e.preventDefault();

            let data = [];
            
            if($(e.target).parents('tr').hasClass('child')){
                data = tableLoadSMS.row($(e.target).parents('tr').prev('.parent')).data();
            }else{
                data = tableLoadSMS.row($(e.target).parents('tr')).data();
            }

            const {formSendSMS} = this.state;

            formSendSMS.name = data[0]
            formSendSMS.id = data[2]

            this.setState({formSendSMS})
            this.showModalSendSMS();
            
        }.bind(this));

        $('#sms_list tbody').on( 'click', '.trigger', function (e){
            
            e.preventDefault();

            let data = [];
            
            if($(e.target).parents('tr').hasClass('child')){
                data = tableLoadSMS.row($(e.target).parents('tr').prev('.parent')).data();
            }else{
                data = tableLoadSMS.row($(e.target).parents('tr')).data();
            }
            let email = window.open('/triggered_sms#' + data[2]);
            email.focus();
            
        });

    }

    render() {
        return (
            <Fragment>
                <MenuMain />
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    <HeaderMain title={document.title} onChange={this.handleChange}/>
                    <div className="content-main row row-no-margin" id="content-main">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" id="conteudo">
                            <div className="row text-right mb-3 p-2">
                                <div className="col-md-12">
                                    <button className="btn btn-fw purple btn-rounded mt20 pull-right btn-shadow btn-cursor-pointer" onClick={this.showModalCreateSMS}>
                                        <i className="fa fa-commenting-o"></i> {this.translate('SMS_LIST_CREATE_NEW_SMS')}
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="box p-2">
                                        <table id="sms_list" className="table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                                            <thead>
                                                <tr className="text-left">
                                                    <th>{this.translate('SMS_LIST_TABLE_NAME')}</th>
                                                    <th>{this.translate('SMS_LIST_TABLE_TEXT')}</th>
                                                    <th>{this.translate('TABLE_ACTIONS_ROW')}</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterMain />
                </div>
                <Modal show={this.state.modalCreateSMS} onHide={this.closeModalCreateSMS} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                    <div className="d-flex flex-column h-100 pb-5">
                        <div className="p-3 b-b d-flex no-shrink purple text-center">
                            <h5 className="h5 m-0 text-center width100 line-height-38px">
                                <i className="fa fa-commenting-o"></i> {this.translate('SMS_LIST_CREATE_NEW_SMS')}
                            </h5>
                            <button onClick={this.closeModalCreateSMS} className="btn btn-transparent ml-auto">
                                <span className="text-lg l-h-1x">×</span>
                            </button>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-6 offset-md-3">

                                <form onSubmit={this.formSubmitCreateSMS}>
                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('SMS_LIST_MODAL_LABEL_SMS_NAME')}</label>
                                        <input type="text" name="name" onChange={e => this.setState({ formCreateSMS: {...this.state.formCreateSMS, name:  e.target.value}})} className="form-control box-shadow cool-input" placeholder={this.translate('SMS_LIST_MODAL_PLACEHOLDER_SMS_NAME')} required="required" />
                                    </div>
                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('SMS_LIST_MODAL_SMS_PITCH')}</label>
                                        <input type="text" onChange={e => this.setState({ formCreateSMS: {...this.state.formCreateSMS, pitch_sms:  e.target.value}})} className="form-control box-shadow cool-input" placeholder={this.translate('SMS_LIST_MODAL_SMS_PITCH')} required="required" />
                                    </div>
                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('SMS_LIST_MODAL_SMS_TEXT')}</label>
                                        <textarea onChange={e => this.setState({ formCreateSMS: {...this.state.formCreateSMS, text:  e.target.value}})} maxLength="160" className="form-control box-shadow cool-input" placeholder={this.translate('')} required="required"></textarea>
                                        {this.state.formCreateSMS.text? (160 - this.state.formCreateSMS.text.length): 160}
                                    </div>
                                    <button type="submit" id="sms_button_submit" className="btn btn-fw purple btn-rounded mt20 pull-right btn-shadow px-5"><i className="fa fa-save"></i> {this.translate('SMS_LIST_REGISTER')}</button>
                                </form>

                            </div>
                        </div>
                    </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.modalSendSMS} onHide={this.closeModalSendSMS} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                    <div className="d-flex flex-column h-100 pb-5">
                        <div className="p-3 b-b d-flex no-shrink purple text-center">
                            <h5 className="h5 m-0 text-center width100 line-height-38px">
                                <i className="fa fa-commenting-o"></i> {this.translate('SMS_LIST_SEND_SMS_TEST')}
                            </h5>
                            <button onClick={this.closeModalSendSMS} className="btn btn-transparent ml-auto">
                                <span className="text-lg l-h-1x">×</span>
                            </button>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-6 offset-md-3">
                                <div className="col-sm-12 mb20" id="sms_send_status" style={{fontWeight: 'bold'}}></div>
                                <form onSubmit={this.formSubmitSendSMS}>
                                    <div className="col-sm-12 mb20">
                                        <input type="text" className="form-control box-shadow cool-input" defaultValue={this.state.formSendSMS.name} disabled />
                                    </div>
                                    <div className="col-sm-12 mb20">
                                        <input type="text" id="sms_send_number" onChange={e => this.setState({ formSendSMS: {...this.state.formSendSMS, number:  e.target.value}})} className="form-control box-shadow cool-input" placeholder={this.translate('SMS_LIST_MODAL_RECIPIENT_NUMBER')} required="required" />
                                    </div>
                                    <button type="submit" id="sms_send_button_submit" className="btn btn-fw purple btn-rounded mt20 pull-right btn-shadow px-5 btn-cursor-pointer"><i className="fa fa-paper-plane"></i> {this.translate('SMS_LIST_SEND')}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    </Modal.Body>
                </Modal>
            </Fragment>
        );
    }
}

export default withRouter(SMSList);