import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import MenuMain from '../../Structure/MenuMain'
import HeaderMain from '../../Structure/HeaderMain'
import FooterMain from '../../Structure/FooterMain'
import CardBuilder from './Components/CardBuilder'
import api from '../../Services/Api'

import $ from "jquery"

class UpdateDashboardPerformance extends Component {
    constructor(props) {
        super(props)
        this.translate = this.props.translate
        this.state = {
            dashboardId: "",
            isLoadingDashboard: true,

            isCardModalVisible: false,
            cardMainMetric: "",
            cardType: "",
            cardUpdateId: null,
            cardValues: {},
            cardComparison: {},
            cardMetricsToBeChosen: [],
            cardStageId: null,
            cardTypesList: [],
            mainMetricList: [],
            cardIdsWithProblems: [],
            dashboard: {
                title: "",
                app: [
                    {
                        "title": "",
                        "id": 1,
                        "cards": []
                    }
                ]
            },

            stagesList: [],

            isEditingDashboardTitle: false,

            isEditingStageTitleWithId: -1,

            isConfirmModalVisible: false,
            confirmModalMessage: "",

            isModalResponseVisible: false,
            msgResponseShow: ""

        }
        this.index = 1
        this.confirmModalCallback = null
        this.modalResponseOnCloseCallback = null

        document.title = this.translate("CREATE_DASHBOARD_PERFORMANCE_CREATE_DASHBOARD_PERFORMANCE")
    }

    getIndex = () => ++this.index

    handleCloseConfirmModal = (e = null) => {
        if (e) e.preventDefault()
        this.setState({ isConfirmModalVisible: false })
    }

    handleCloseModalResponse = (e = null) => {
        if (e) e.preventDefault()
        this.setState({ isModalResponseVisible: false })
        if (this.modalResponseOnCloseCallback) this.modalResponseOnCloseCallback()
    }


    closeCardModal = (e = null) => {
        if (e) e.preventDefault()
        this.cleanCardModal()
        this.setState({ isCardModalVisible: false })
    }

    handleStageTitleChange = (e, stageId, stagesList) => {
        let optionKey = e.target.value
        let stageTitle = ""
        let filteredStage
        stagesList.forEach(stage => {
            stage.options.forEach(option=>{
                if(option.key === optionKey){
                    filteredStage = option
                }
            })
        })
         
       
        if(filteredStage && filteredStage.title) stageTitle = filteredStage.title 

        let { dashboard } = this.state

        dashboard.app.forEach((stage, index) => {
            if (stage.id === stageId) {
                stage.title = stageTitle
                stage.key = optionKey
            }
        })

        this.setState({ dashboard })
    }


    handleCardChange = (e, type, cardId, stageId) => {
        let { dashboard } = this.state

        dashboard.app.forEach((stage, index) => {
            if (stage.id === stageId) {
                stage.cards.forEach(card => {
                    if (card.id === cardId) {
                        card["values"][type] = e.target.value
                    }
                })

            }
        })
        this.setState({ dashboard })
    }

    handleCardRemove = (cardId, stageId) => {
        this.confirmModalCallback = () => { this.removeCard(cardId, stageId) }
        this.setState({ confirmModalMessage: this.translate("CREATE_DASHBOARD_PERFORMANCE_ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_CARD"), isConfirmModalVisible: true })

    }

    removeCard = (cardId, stageId) => {
        let { dashboard } = this.state

        dashboard.app.forEach((stage, index) => {
            if (stage.id === stageId) {
                stage.cards = stage.cards.filter(card => card.id !== cardId)
            }
        })
        this.setState({ dashboard, isConfirmModalVisible: false })
    }



    handleStageRemove = (stageId) => {
        this.confirmModalCallback = () => { this.removeStage(stageId) }
        this.setState({ confirmModalMessage: this.translate("CREATE_DASHBOARD_PERFORMANCE_ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_STAGE"), isConfirmModalVisible: true })

    }

    removeStage = (stageId) => {
        let { dashboard } = this.state

        dashboard.app = dashboard.app.filter((stage, index) => stage.id !== stageId)

        this.setState({ dashboard, isConfirmModalVisible: false })
    }


    handleCardChangeMainMetric = (cardId, stageId) => {
        let cardChange
        let { dashboard } = this.state

        dashboard.app.forEach((stage, index) => {
            if (stage.id === stageId) {
                stage.cards.forEach(card => {
                    if (card.id === cardId) {
                        cardChange = card
                    }
                })

            }
        })

        this.setState({
            cardMainMetric: cardChange.main_metric_key,
            cardType: cardChange.type,
            cardValues: { ...cardChange.values },
            cardMetricsToBeChosen: [...cardChange.metrics_to_be_chosen],
            cardStageId: stageId,
            cardUpdateId: cardId,
            cardComparison: cardChange.comparison && cardChange.comparison.length ? [...cardChange.comparison] : [],
            isCardModalVisible: true
        }, () => this.loadCardTypesList(cardChange.main_metric_key))
    }

    handleAddStage = () => {
        let { dashboard } = this.state

        dashboard.app.push({
            "title": "",
            "id": /* this.getIndex()  */ new Date().getTime(),
            "cards": []
        })

        this.setState({ dashboard })
    }

    handleAddCard = (id, key) => {
        if(key){
            this.setState({ isCardModalVisible: true, cardStageId: id })
        } else {
            this.setState({isModalResponseVisible: true, msgResponseShow: this.translate("CREATE_DASHBOARD_METRICS_ITS_REQUIRED_TO_SELECT_THE_STAGE_BEFORE_ADDING_A_CARD")})
        }
    }

    checkIdAndFetchDashboard = (dashboardId) => {
        if (dashboardId) {
            this.loadDashboardPerformance(dashboardId)
        } else {
            this.props.history.replace("/choose_dashboard_performance")
        }
    }

    loadDashboardPerformance = async (dashboardId) => {
        if (!dashboardId) return
        let couldNotLoad = false
        try {
            //let response = await api.get(`/attendances/dashboard_performance/${dashboardId}`)
            let response = {
                data: {
                    success: true,
                    dashboard: this.getDashboardMockup()
                }
            }
            if (response && response.data && response.data.success && response.data.dashboard) {
                if (response.data.dashboard && response.data.dashboard.app && response.data.dashboard.app.length > 0) {
                    //To make sure there won't be similar ids
                    this.index = 10000 * response.data.dashboard.app.length
                }
                this.setState({
                    dashboard: response.data.dashboard,
                    dashboardId: dashboardId,
                    isLoadingDashboard: false
                })
            } else {
                couldNotLoad = true
            }
        } catch (error) {
            console.log(error)
            couldNotLoad = true
        }
        if (couldNotLoad) {
            this.setState({ msgResponseShow: this.translate("CREATE_DASHBOARD_PERFORMANCE_IT_WASNT_POSSIBLE_TO_LOAD_THE_DASHBOARD"), isModalResponseVisible: true })
            this.modalResponseOnCloseCallback = () => {
                this.props.history.replace("/choose_dashboard_performance")
            }

        }
    }

    getDashboardMockup = () => {
        return {
            "title": "Dashboard de teste",
            "short_description": "Apenas uma dashboard de teste",
            "color": "#ff00ff",
            "app": [
                {
                    "title": "etapa de segmentação",
                    "id": 1,
                    "cards": [
                        {
                            "id": 2,
                            "type": "card_small_total",
                            "main_metric_key": "total_leads",
                            "values": {
                                "title": "Número total de leads",
                                "icon": "fa-users",
                                "value": "0",
                                "short_description": "Exemplo de Descrição",
                                "color": "#17a2b8"
                            },
                            "metrics_to_be_chosen": [],
                            "metrics": [
                                {
                                    "metric_key": "total_info_per_years",
                                    "title": "Total de leads no periodo de X anos",
                                    "views": [
                                        {
                                            "view_code": "total_info_per_month_line_chart",
                                            "view_name": "Gráfico de linha ano a ano com total de leads por ano",
                                            "view_values": {
                                                "label": [
                                                    "2007",
                                                    "2008",
                                                    "2009",
                                                    "2010",
                                                    "2011"
                                                ],
                                                "values": [
                                                    20,
                                                    30,
                                                    40,
                                                    50,
                                                    70
                                                ]
                                            }
                                        }
                                    ],
                                    "views_to_be_chosen": [
                                        {
                                            "view_code": "total_info_per_month_bar_chart",
                                            "view_name": "Gráfico de barras ano a ano com total de leads por ano",
                                            "view_values": {
                                                "label": [
                                                    "2007",
                                                    "2008",
                                                    "2009",
                                                    "2010",
                                                    "2011"
                                                ],
                                                "values": [
                                                    20,
                                                    30,
                                                    40,
                                                    50,
                                                    70
                                                ]
                                            }
                                        },
                                        {
                                            "view_code": "total_info_per_month_ranking_list",
                                            "view_name": "Ranking com o total de leads por ano",
                                            "view_values": [
                                                {
                                                    "date": "2007",
                                                    "value": 70
                                                },
                                                {
                                                    "date": "2008",
                                                    "value": 50
                                                },
                                                {
                                                    "date": "2009",
                                                    "value": 40
                                                },
                                                {
                                                    "date": "2010",
                                                    "value": 30
                                                },
                                                {
                                                    "date": "2011",
                                                    "value": 20
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "metric_key": "total_info_per_months",
                                    "title": "Total de leads no periodo de X meses",
                                    "views": [
                                        {
                                            "view_code": "total_info_per_month_line_chart",
                                            "view_name": "Gráfico de linha mês a mês com total de leads por mês",
                                            "view_values": {
                                                "label": [
                                                    "Jan",
                                                    "Fev",
                                                    "Mar",
                                                    "Abr",
                                                    "Mai"
                                                ],
                                                "values": [
                                                    20,
                                                    30,
                                                    40,
                                                    50,
                                                    70
                                                ]
                                            }
                                        },
                                        {
                                            "view_code": "total_info_per_month_ranking_list",
                                            "view_name": "Ranking com o total de leads por mês",
                                            "view_values": [
                                                {
                                                    "date": "Mai",
                                                    "value": 70
                                                },
                                                {
                                                    "date": "Abr",
                                                    "value": 50
                                                },
                                                {
                                                    "date": "Mar",
                                                    "value": 40
                                                },
                                                {
                                                    "date": "Fev",
                                                    "value": 30
                                                },
                                                {
                                                    "date": "jan",
                                                    "value": 20
                                                }
                                            ]
                                        }
                                    ],
                                    "views_to_be_chosen": [
                                        {
                                            "view_code": "total_info_per_month_bar_chart",
                                            "view_name": "Gráfico de barras mês a mês com total de leads por mês",
                                            "view_values": {
                                                "label": [
                                                    "Jan",
                                                    "Fev",
                                                    "Mar",
                                                    "Abr",
                                                    "Mai"
                                                ],
                                                "values": [
                                                    20,
                                                    30,
                                                    40,
                                                    50,
                                                    70
                                                ]
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "id": 3,
                            "type": "card_medium_total",
                            "main_metric_key": "total_leads",
                            "values": {
                                "title": "Número total de leads",
                                "icon": "fa-users",
                                "value": "0",
                                "short_description": "Exemplo de Descrição",
                                "color": "#17a2b8"
                            },
                            "metrics_to_be_chosen": [
                                {
                                    "metric_key": "total_info_per_years",
                                    "title": "Total de leads no periodo de X anos",
                                    "views": [
                                        {
                                            "view_code": "total_info_per_month_line_chart",
                                            "view_name": "Gráfico de linha ano a ano com total de leads por ano",
                                            "view_values": {
                                                "label": [
                                                    "2007",
                                                    "2008",
                                                    "2009",
                                                    "2010",
                                                    "2011"
                                                ],
                                                "values": [
                                                    20,
                                                    30,
                                                    40,
                                                    50,
                                                    70
                                                ]
                                            }
                                        }
                                    ],
                                    "views_to_be_chosen": [
                                        {
                                            "view_code": "total_info_per_month_bar_chart",
                                            "view_name": "Gráfico de barras ano a ano com total de leads por ano",
                                            "view_values": {
                                                "label": [
                                                    "2007",
                                                    "2008",
                                                    "2009",
                                                    "2010",
                                                    "2011"
                                                ],
                                                "values": [
                                                    20,
                                                    30,
                                                    40,
                                                    50,
                                                    70
                                                ]
                                            }
                                        },
                                        {
                                            "view_code": "total_info_per_month_ranking_list",
                                            "view_name": "Ranking com o total de leads por ano",
                                            "view_values": [
                                                {
                                                    "date": "2007",
                                                    "value": 70
                                                },
                                                {
                                                    "date": "2008",
                                                    "value": 50
                                                },
                                                {
                                                    "date": "2009",
                                                    "value": 40
                                                },
                                                {
                                                    "date": "2010",
                                                    "value": 30
                                                },
                                                {
                                                    "date": "2011",
                                                    "value": 20
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "metric_key": "total_info_per_months",
                                    "title": "Total de leads no periodo de X meses",
                                    "views": [
                                        {
                                            "view_code": "total_info_per_month_line_chart",
                                            "view_name": "Gráfico de linha mês a mês com total de leads por mês",
                                            "view_values": {
                                                "label": [
                                                    "Jan",
                                                    "Fev",
                                                    "Mar",
                                                    "Abr",
                                                    "Mai"
                                                ],
                                                "values": [
                                                    20,
                                                    30,
                                                    40,
                                                    50,
                                                    70
                                                ]
                                            }
                                        },
                                        {
                                            "view_code": "total_info_per_month_ranking_list",
                                            "view_name": "Ranking com o total de leads por mês",
                                            "view_values": [
                                                {
                                                    "date": "Mai",
                                                    "value": 70
                                                },
                                                {
                                                    "date": "Abr",
                                                    "value": 50
                                                },
                                                {
                                                    "date": "Mar",
                                                    "value": 40
                                                },
                                                {
                                                    "date": "Fev",
                                                    "value": 30
                                                },
                                                {
                                                    "date": "jan",
                                                    "value": 20
                                                }
                                            ]
                                        }
                                    ],
                                    "views_to_be_chosen": [
                                        {
                                            "view_code": "total_info_per_month_bar_chart",
                                            "view_name": "Gráfico de barras mês a mês com total de leads por mês",
                                            "view_values": {
                                                "label": [
                                                    "Jan",
                                                    "Fev",
                                                    "Mar",
                                                    "Abr",
                                                    "Mai"
                                                ],
                                                "values": [
                                                    20,
                                                    30,
                                                    40,
                                                    50,
                                                    70
                                                ]
                                            }
                                        }
                                    ]
                                }
                            ],
                            "metrics": []
                        },
                        {
                            "id": 4,
                            "type": "card_small_total",
                            "main_metric_key": "total_suspects",
                            "values": {
                                "title": "Número total de suspects",
                                "icon": "fa-hashtag",
                                "value": "25",
                                "short_description": "Card de Suspects",
                                "color": "#ffa99a"
                            },
                            "metrics_to_be_chosen": [
                                {
                                    "metric_key": "total_info_per_years",
                                    "title": "Total de suspects no periodo de X anos",
                                    "views": [
                                        {
                                            "view_code": "total_info_per_month_line_chart",
                                            "view_name": "Gráfico de linha ano a ano com total de suspects por anos",
                                            "view_values": {
                                                "label": [
                                                    "2007",
                                                    "2008",
                                                    "2009",
                                                    "2010",
                                                    "2011"
                                                ],
                                                "values": [
                                                    20,
                                                    30,
                                                    40,
                                                    50,
                                                    70
                                                ]
                                            }
                                        }
                                    ],
                                    "views_to_be_chosen": [
                                        {
                                            "view_code": "total_info_per_month_bar_chart",
                                            "view_name": "Gráfico de barras ano a ano com total de suspects por ano",
                                            "view_values": {
                                                "label": [
                                                    "2007",
                                                    "2008",
                                                    "2009",
                                                    "2010",
                                                    "2011"
                                                ],
                                                "values": [
                                                    20,
                                                    30,
                                                    40,
                                                    50,
                                                    70
                                                ]
                                            }
                                        },
                                        {
                                            "view_code": "total_info_per_month_ranking_list",
                                            "view_name": "Ranking com o total de suspects por ano",
                                            "view_values": [
                                                {
                                                    "date": "2007",
                                                    "value": 70
                                                },
                                                {
                                                    "date": "2008",
                                                    "value": 50
                                                },
                                                {
                                                    "date": "2009",
                                                    "value": 40
                                                },
                                                {
                                                    "date": "2010",
                                                    "value": 30
                                                },
                                                {
                                                    "date": "2011",
                                                    "value": 20
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "metric_key": "total_info_per_months",
                                    "title": "Total de suspects no periodo de Y meses",
                                    "views": [],
                                    "views_to_be_chosen": [
                                        {
                                            "view_code": "total_info_per_month_line_chart",
                                            "view_name": "Gráfico de linha mês a mês com total de suspects por mês",
                                            "view_values": {
                                                "label": [
                                                    "Jan",
                                                    "Fev",
                                                    "Mar",
                                                    "Abr",
                                                    "Mai"
                                                ],
                                                "values": [
                                                    20,
                                                    30,
                                                    40,
                                                    50,
                                                    70
                                                ]
                                            }
                                        },
                                        {
                                            "view_code": "total_info_per_month_bar_chart",
                                            "view_name": "Gráfico de barras mês a mês com total de suspects por mês",
                                            "view_values": {
                                                "label": [
                                                    "Jan",
                                                    "Fev",
                                                    "Mar",
                                                    "Abr",
                                                    "Mai"
                                                ],
                                                "values": [
                                                    20,
                                                    30,
                                                    40,
                                                    50,
                                                    70
                                                ]
                                            }
                                        },
                                        {
                                            "view_code": "total_info_per_month_ranking_list",
                                            "view_name": "Ranking com o total de suspects por mês",
                                            "view_values": [
                                                {
                                                    "date": "Mai",
                                                    "value": 70
                                                },
                                                {
                                                    "date": "Abr",
                                                    "value": 50
                                                },
                                                {
                                                    "date": "Mar",
                                                    "value": 40
                                                },
                                                {
                                                    "date": "Fev",
                                                    "value": 30
                                                },
                                                {
                                                    "date": "jan",
                                                    "value": 20
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ],
                            "metrics": []
                        }
                    ],
                    "key": "etapa_de_teste_segmentacao"
                },
                {
                    "title": "etapa de teste 1",
                    "id": 5,
                    "cards": [
                        {
                            "id": 6,
                            "type": "card_small_total",
                            "main_metric_key": "total_suspects",
                            "values": {
                                "title": "Número total de suspects",
                                "icon": "fa-hashtag",
                                "value": "25",
                                "short_description": "Card de Suspects",
                                "color": "#ffa99a"
                            },
                            "metrics_to_be_chosen": [
                                {
                                    "metric_key": "total_info_per_months",
                                    "title": "Total de suspects no periodo de Y meses",
                                    "views": [],
                                    "views_to_be_chosen": [
                                        {
                                            "view_code": "total_info_per_month_line_chart",
                                            "view_name": "Gráfico de linha mês a mês com total de suspects por mês",
                                            "view_values": {
                                                "label": [
                                                    "Jan",
                                                    "Fev",
                                                    "Mar",
                                                    "Abr",
                                                    "Mai"
                                                ],
                                                "values": [
                                                    20,
                                                    30,
                                                    40,
                                                    50,
                                                    70
                                                ]
                                            }
                                        },
                                        {
                                            "view_code": "total_info_per_month_bar_chart",
                                            "view_name": "Gráfico de barras mês a mês com total de suspects por mês",
                                            "view_values": {
                                                "label": [
                                                    "Jan",
                                                    "Fev",
                                                    "Mar",
                                                    "Abr",
                                                    "Mai"
                                                ],
                                                "values": [
                                                    20,
                                                    30,
                                                    40,
                                                    50,
                                                    70
                                                ]
                                            }
                                        },
                                        {
                                            "view_code": "total_info_per_month_ranking_list",
                                            "view_name": "Ranking com o total de suspects por mês",
                                            "view_values": [
                                                {
                                                    "date": "Mai",
                                                    "value": 70
                                                },
                                                {
                                                    "date": "Abr",
                                                    "value": 50
                                                },
                                                {
                                                    "date": "Mar",
                                                    "value": 40
                                                },
                                                {
                                                    "date": "Fev",
                                                    "value": 30
                                                },
                                                {
                                                    "date": "jan",
                                                    "value": 20
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ],
                            "metrics": [
                                {
                                    "metric_key": "total_info_per_years",
                                    "title": "Total de suspects no periodo de X anos",
                                    "views": [
                                        {
                                            "view_code": "total_info_per_month_line_chart",
                                            "view_name": "Gráfico de linha ano a ano com total de suspects por anos",
                                            "view_values": {
                                                "label": [
                                                    "2007",
                                                    "2008",
                                                    "2009",
                                                    "2010",
                                                    "2011"
                                                ],
                                                "values": [
                                                    20,
                                                    30,
                                                    40,
                                                    50,
                                                    70
                                                ]
                                            }
                                        }
                                    ],
                                    "views_to_be_chosen": [
                                        {
                                            "view_code": "total_info_per_month_bar_chart",
                                            "view_name": "Gráfico de barras ano a ano com total de suspects por ano",
                                            "view_values": {
                                                "label": [
                                                    "2007",
                                                    "2008",
                                                    "2009",
                                                    "2010",
                                                    "2011"
                                                ],
                                                "values": [
                                                    20,
                                                    30,
                                                    40,
                                                    50,
                                                    70
                                                ]
                                            }
                                        },
                                        {
                                            "view_code": "total_info_per_month_ranking_list",
                                            "view_name": "Ranking com o total de suspects por ano",
                                            "view_values": [
                                                {
                                                    "date": "2007",
                                                    "value": 70
                                                },
                                                {
                                                    "date": "2008",
                                                    "value": 50
                                                },
                                                {
                                                    "date": "2009",
                                                    "value": 40
                                                },
                                                {
                                                    "date": "2010",
                                                    "value": 30
                                                },
                                                {
                                                    "date": "2011",
                                                    "value": 20
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "id": 7,
                            "type": "card_medium_total",
                            "main_metric_key": "total_suspects",
                            "values": {
                                "title": "Número total de suspects",
                                "icon": "fa-hashtag",
                                "value": "25",
                                "short_description": "Card de Suspects",
                                "color": "#ffa99a"
                            },
                            "metrics_to_be_chosen": [
                                {
                                    "metric_key": "total_info_per_months",
                                    "title": "Total de suspects no periodo de Y meses",
                                    "views": [],
                                    "views_to_be_chosen": [
                                        {
                                            "view_code": "total_info_per_month_line_chart",
                                            "view_name": "Gráfico de linha mês a mês com total de suspects por mês",
                                            "view_values": {
                                                "label": [
                                                    "Jan",
                                                    "Fev",
                                                    "Mar",
                                                    "Abr",
                                                    "Mai"
                                                ],
                                                "values": [
                                                    20,
                                                    30,
                                                    40,
                                                    50,
                                                    70
                                                ]
                                            }
                                        },
                                        {
                                            "view_code": "total_info_per_month_bar_chart",
                                            "view_name": "Gráfico de barras mês a mês com total de suspects por mês",
                                            "view_values": {
                                                "label": [
                                                    "Jan",
                                                    "Fev",
                                                    "Mar",
                                                    "Abr",
                                                    "Mai"
                                                ],
                                                "values": [
                                                    20,
                                                    30,
                                                    40,
                                                    50,
                                                    70
                                                ]
                                            }
                                        },
                                        {
                                            "view_code": "total_info_per_month_ranking_list",
                                            "view_name": "Ranking com o total de suspects por mês",
                                            "view_values": [
                                                {
                                                    "date": "Mai",
                                                    "value": 70
                                                },
                                                {
                                                    "date": "Abr",
                                                    "value": 50
                                                },
                                                {
                                                    "date": "Mar",
                                                    "value": 40
                                                },
                                                {
                                                    "date": "Fev",
                                                    "value": 30
                                                },
                                                {
                                                    "date": "jan",
                                                    "value": 20
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ],
                            "metrics": [
                                {
                                    "metric_key": "total_info_per_years",
                                    "title": "Total de suspects no periodo de X anos",
                                    "views": [
                                        {
                                            "view_code": "total_info_per_month_line_chart",
                                            "view_name": "Gráfico de linha ano a ano com total de suspects por anos",
                                            "view_values": {
                                                "label": [
                                                    "2007",
                                                    "2008",
                                                    "2009",
                                                    "2010",
                                                    "2011"
                                                ],
                                                "values": [
                                                    20,
                                                    30,
                                                    40,
                                                    50,
                                                    70
                                                ]
                                            }
                                        }
                                    ],
                                    "views_to_be_chosen": [
                                        {
                                            "view_code": "total_info_per_month_bar_chart",
                                            "view_name": "Gráfico de barras ano a ano com total de suspects por ano",
                                            "view_values": {
                                                "label": [
                                                    "2007",
                                                    "2008",
                                                    "2009",
                                                    "2010",
                                                    "2011"
                                                ],
                                                "values": [
                                                    20,
                                                    30,
                                                    40,
                                                    50,
                                                    70
                                                ]
                                            }
                                        },
                                        {
                                            "view_code": "total_info_per_month_ranking_list",
                                            "view_name": "Ranking com o total de suspects por ano",
                                            "view_values": [
                                                {
                                                    "date": "2007",
                                                    "value": 70
                                                },
                                                {
                                                    "date": "2008",
                                                    "value": 50
                                                },
                                                {
                                                    "date": "2009",
                                                    "value": 40
                                                },
                                                {
                                                    "date": "2010",
                                                    "value": 30
                                                },
                                                {
                                                    "date": "2011",
                                                    "value": 20
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ],
                    "key": "etapa_de_teste_1"
                }
            ]
        }
    }

    loadMainMetricList = async () => {
        try {
            //let response = await api.get(`/attendances/main_metrics_list/`)
            let main_metric_list = this.getMainMetricMockup()
            let response = {
                data: {
                    success: true,
                    main_metric_list: main_metric_list.main_metric_list
                }
            }
            if (response && response.data.success && response.data.main_metric_list && response.data.main_metric_list.length) {
                this.setState({
                    mainMetricList: response.data.main_metric_list
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    getMainMetricMockup = () => {
        return {
            "main_metric_list": [
                {
                    "main_metric_key": "total_leads",
                    "title": "Número total de leads",
                    "values": {
                        "title": "Número total de leads",
                        "icon": "fa-users",
                        "value": "0",
                        "short_description": "Exemplo de Descrição",
                        "color": "#17a2b8"
                    },
                    "comparison" : ["total_leads"],
                    "metrics_to_be_chosen": [
                        {
                            "metric_key": "total_info_per_years",
                            "title": "Total de leads no periodo de X anos",
                            "views": [],
                            "views_to_be_chosen": [
                                {
                                    "view_code": "total_info_per_month_line_chart",
                                    "view_name": "Gráfico de linha ano a ano com total de leads por ano",
                                    "view_values": {
                                        "label": ["2007", "2008", "2009", "2010", "2011"],
                                        "values": [20, 30, 40, 50, 70]
                                    }
                                },
                                {
                                    "view_code": "total_info_per_month_bar_chart",
                                    "view_name": "Gráfico de barras ano a ano com total de leads por ano",
                                    "view_values": {
                                        "label": ["2007", "2008", "2009", "2010", "2011"],
                                        "values": [20, 30, 40, 50, 70]
                                    }
                                },
                                {
                                    "view_code": "total_info_per_month_ranking_list",
                                    "view_name": "Ranking com o total de leads por ano",
                                    "view_values": [
                                        { "date": "2007", "value": 70 },
                                        { "date": "2008", "value": 50 },
                                        { "date": "2009", "value": 40 },
                                        { "date": "2010", "value": 30 },
                                        { "date": "2011", "value": 20 }
                                    ]
                                }
                            ]
                        },
                        {
                            "metric_key": "total_info_per_months",
                            "title": "Total de leads no periodo de X meses",
                            "views": [],
                            "views_to_be_chosen": [
                                {
                                    "view_code": "total_info_per_month_line_chart",
                                    "view_name": "Gráfico de linha mês a mês com total de leads por mês",
                                    "view_values": {
                                        "label": ["Jan", "Fev", "Mar", "Abr", "Mai"],
                                        "values": [20, 30, 40, 50, 70]
                                    }
                                },
                                {
                                    "view_code": "total_info_per_month_bar_chart",
                                    "view_name": "Gráfico de barras mês a mês com total de leads por mês",
                                    "view_values": {
                                        "label": ["Jan", "Fev", "Mar", "Abr", "Mai"],
                                        "values": [20, 30, 40, 50, 70]
                                    }
                                },
                                {
                                    "view_code": "total_info_per_month_ranking_list",
                                    "view_name": "Ranking com o total de leads por mês",
                                    "view_values": [
                                        { "date": "Mai", "value": 70 },
                                        { "date": "Abr", "value": 50 },
                                        { "date": "Mar", "value": 40 },
                                        { "date": "Fev", "value": 30 },
                                        { "date": "jan", "value": 20 }
                                    ]
                                }
                            ]
                        }
                    ]

                },
                {
                    "main_metric_key": "total_suspects",
                    "title": "Número total de suspects",
                    "comparison": ["total_suspects"],
                    "values": {
                        "title": "Número total de suspects",
                        "icon": "fa-users",
                        "value": "25",
                        "short_description": "Card de Suspects",
                        "color": "#ffa99a"
                    },
                    "metrics_to_be_chosen": [
                        {
                            "metric_key": "total_info_per_years",
                            "title": "Total de suspects no periodo de X anos",
                            "views": [],
                            "views_to_be_chosen": [
                                {
                                    "view_code": "total_info_per_month_line_chart",
                                    "view_name": "Gráfico de linha ano a ano com total de suspects por anos",
                                    "view_values": {
                                        "label": ["2007", "2008", "2009", "2010", "2011"],
                                        "values": [20, 30, 40, 50, 70]
                                    }
                                },
                                {
                                    "view_code": "total_info_per_month_bar_chart",
                                    "view_name": "Gráfico de barras ano a ano com total de suspects por ano",
                                    "view_values": {
                                        "label": ["2007", "2008", "2009", "2010", "2011"],
                                        "values": [20, 30, 40, 50, 70]
                                    }
                                },
                                {
                                    "view_code": "total_info_per_month_ranking_list",
                                    "view_name": "Ranking com o total de suspects por ano",
                                    "view_values": [
                                        { "date": "2007", "value": 70 },
                                        { "date": "2008", "value": 50 },
                                        { "date": "2009", "value": 40 },
                                        { "date": "2010", "value": 30 },
                                        { "date": "2011", "value": 20 }
                                    ]
                                }
                            ]
                        },
                        {
                            "metric_key": "total_info_per_months",
                            "title": "Total de suspects no periodo de Y meses",
                            "views": [],
                            "views_to_be_chosen": [
                                {
                                    "view_code": "total_info_per_month_line_chart",
                                    "view_name": "Gráfico de linha mês a mês com total de suspects por mês",
                                    "view_values": {
                                        "label": ["Jan", "Fev", "Mar", "Abr", "Mai"],
                                        "values": [20, 30, 40, 50, 70]
                                    }
                                },
                                {
                                    "view_code": "total_info_per_month_bar_chart",
                                    "view_name": "Gráfico de barras mês a mês com total de suspects por mês",
                                    "view_values": {
                                        "label": ["Jan", "Fev", "Mar", "Abr", "Mai"],
                                        "values": [20, 30, 40, 50, 70]
                                    }
                                },
                                {
                                    "view_code": "total_info_per_month_ranking_list",
                                    "view_name": "Ranking com o total de suspects por mês",
                                    "view_values": [
                                        { "date": "Mai", "value": 70 },
                                        { "date": "Abr", "value": 50 },
                                        { "date": "Mar", "value": 40 },
                                        { "date": "Fev", "value": 30 },
                                        { "date": "jan", "value": 20 }
                                    ]
                                }
                            ]
                        }
                    ]
                },

                {
                    "main_metric_key": "conversion_sales_leads",
                    "title": "Taxa de Conversão - Vendas / Leads",
                    "comparison": [],
                    "values": {
                        "title": "Taxa de Conversão - Vendas / Leads",
                        "icon": "fa-line-chart",
                        "value": "25",
                        "short_description": "Taxa de Conversão de Venda dos Leads",
                        "color": "#00daaa"
                    },
                    "metrics_to_be_chosen": []
                },

                {
                    "main_metric_key": "vgv_sales_leads",
                    "title": "VGV Vendido Total",
                    "comparison": [],
                    "values": {
                        "title": "VGV Vendido Total",
                        "icon": "fa-usd",
                        "value": "25",
                        "short_description": "VGV Vendido Total",
                        "color": "#ffff00"
                    },
                    "metrics_to_be_chosen": []
                },

                {
                    "main_metric_key": "ranking_suspects",
                    "title": "Ranking dos Suspects",
                    "comparison": [],
                    "values": {
                        "title": "Ranking dos Suspects",
                        "icon": "fa-hashtag",
                        "value": [
                            { "Data": "Mai", "Valor": 70, "teste": "teste", "teste2": "teste2", "teste3": "teste3" },
                            { "Data": "Abr", "Valor": 50, "teste": "teste", "teste2": "teste2", "teste3": "teste3" },
                            { "Data": "Mar", "Valor": 40, "teste": "teste", "teste2": "teste2", "teste3": "teste3" },
                            { "Data": "Fev", "Valor": 30, "teste": "teste", "teste2": "teste2", "teste3": "teste3" },
                            { "Data": "jan", "Valor": 20, "teste": "teste", "teste2": "teste2", "teste3": "teste3" }
                        ],
                        "short_description": "Card de Suspects",
                        "color": "#2faaaa"
                    },
                    "metrics_to_be_chosen": [
                        {
                            "metric_key": "ranking_Suspects",
                            "title": "Ranking dos Suspects",
                            "views": [],
                            "views_to_be_chosen": [
                                {
                                    "view_code": "total_info_per_month_ranking_list",
                                    "view_name": "Ranking com o total de suspects por mês",
                                    "view_values": [
                                        { "Data": "Mai", "Valor": 70 },
                                        { "Data": "Abr", "Valor": 50 },
                                        { "Data": "Mar", "Valor": 40 },
                                        { "Data": "Fev", "Valor": 30 },
                                        { "Data": "jan", "Valor": 20 }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "main_metric_key": "chart_suspects",
                    "title": "Gráficos dos Suspects",
                    "comparison": [],
                    "values": {
                        "title": "Gráficos dos Suspects",
                        "icon": "fa-hashtag",
                        "value": {
                            "label": ["Jan", "Fev", "Mar", "Abr", "Mai"],
                            "values": [20, 30, 40, 50, 70]
                        },
                        "short_description": "Card de Suspects",
                        "color": "#3399aa"
                    },
                    "metrics_to_be_chosen": [
                        {
                            "metric_key": "ranking_Suspects",
                            "title": "Gráficos dos Suspects",
                            "views": [],
                            "views_to_be_chosen": [
                                {
                                    "view_code": "total_info_per_month_line_chart",
                                    "view_name": "Gráfico de linha mês a mês com total de suspects por mês",
                                    "view_values": {
                                        "label": ["Jan", "Fev", "Mar", "Abr", "Mai"],
                                        "values": [20, 30, 40, 50, 70]
                                    }
                                },
                                {
                                    "view_code": "total_info_per_month_bar_chart",
                                    "view_name": "Gráfico de barras mês a mês com total de suspects por mês",
                                    "view_values": {
                                        "label": ["Jan", "Fev", "Mar", "Abr", "Mai"],
                                        "values": [20, 30, 40, 50, 70]
                                    }
                                },
                            ]
                        }
                    ]
                }
            ]
        }
    }

    handleMainMetricChange = async (mainMetricKey) => {
        if (mainMetricKey) {


            let { mainMetricList } = this.state
            let newCardValues = {}
            for(const metric of mainMetricList){
                if(metric.main_metric_key === mainMetricKey){
                    newCardValues = metric
                }
                
            }
            let metricsToBeChosen = newCardValues.metrics_to_be_chosen
            let cardComparison = newCardValues.comparison
            newCardValues = newCardValues.values

            this.setState({ cardMainMetric: mainMetricKey, cardValues: newCardValues, cardComparison, cardMetricsToBeChosen: metricsToBeChosen, cardType: "" }, () => this.loadCardTypesList(mainMetricKey))

        } else {
            this.cleanCardModal()
        }
    }

    loadCardTypesList = async (mainMetricKey) => {
        try {
            //let response = await api.get(`/attendances/card_types_list/${mainMetricKey}`)
            let cards
            
            switch(mainMetricKey){
                case "chart_suspects":
                    cards = this.getCardTypesMockupCharts()
                    break
                case "ranking_suspects":
                    cards = this.getCardTypesMockupRanking()
                    break
                case "conversion_sales_leads":
                    cards = this.getCardTypesMockupConversion()
                    break
                case "vgv_sales_leads":
                    cards = this.getCardTypesMockupVGV()
                    break
                default:
                    cards = this.getCardTypesMockupTotal()
                    break
            }

            


            let response = {
                data: {
                    success: true,
                    ...cards
                }
            }
            if (response && response.data.success && response.data.cards && response.data.cards.length) {
                let cards = response.data.cards
                cards = cards.map(card => {
                    return { ...card, values: {...this.state.cardValues} }
                })

                this.setState({
                    cardTypesList: cards
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    getCardTypesMockupTotal = () => {
        return {
            "cards": [
                {"type": "card_large_total"},
                {"type": "card_medium_total"},
                {"type": "card_small_total"},
            ]
        }
    }
    getCardTypesMockupConversion = () => {
        return {
            "cards": [
                {"type": "card_large_percentage"},
                {"type": "card_medium_percentage"},
                {"type": "card_small_percentage"},
            ]
        }
    }
    getCardTypesMockupVGV = () => {
        return {
            "cards": [
                {"type": "card_large_money"},
                {"type": "card_medium_money"},
                {"type": "card_small_money"},
            ]
        }
    }

    getCardTypesMockupRanking = () => {
        return {
            "cards": [
                {"type": "card_large_ranking"},
                {"type": "card_medium_ranking"},
                {"type": "card_small_ranking"},
                
            ]
        }
    }
    
    getCardTypesMockupCharts = () => {
        return {
            "cards": [
                {"type": "card_large_bar_chart", title: "Gráfico de Barra"},
                {"type": "card_medium_bar_chart"},
                {"type": "card_small_bar_chart"},
                {"type": "card_large_line_chart", title: "Gráfico de Linha"},
                {"type": "card_medium_line_chart"},
                {"type": "card_small_line_chart"},
                {"type": "card_large_pie_chart", title: "Gráfico de Pizza"},
                {"type": "card_medium_pie_chart"},
                {"type": "card_small_pie_chart"}, 
                
            ]
        }
    }

    handleCreateOrUpdateCard = async () => {
        if (!this.state.cardMainMetric || !this.state.cardType) return false

        let { dashboard, cardValues, cardComparison, cardMetricsToBeChosen, cardMainMetric, cardType, cardStageId, cardUpdateId } = this.state

        let newCard = {
            "id": /* this.getIndex()  */ new Date().getTime(),
            "type": cardType,
            "main_metric_key": cardMainMetric,
            "values": cardValues,
            "comparison": cardComparison,
            "metrics_to_be_chosen": cardMetricsToBeChosen,
            "metrics": []
        }

        newCard = JSON.stringify(newCard)
        let anotherNewCard = JSON.parse(newCard)
        
        let app = dashboard["app"]
        let newApp = []
        for(let stage of app){
            let newStage = stage
            
            if(stage.id === cardStageId){
                if(cardUpdateId){
                    let cards = newStage["cards"]
                    let newCards = []
                    for(let card of cards){
                        if(card.id === cardUpdateId) newCards.push(anotherNewCard)
                        else newCards.push(card)
                    }
                    newStage["cards"] = newCards
                } else {
                    newStage["cards"].push(anotherNewCard)
                }
            }
            newApp.push(newStage)
        }
        
        dashboard["app"] = newApp

        this.setState({ dashboard: dashboard, isCardModalVisible: false })
        this.cleanCardModal()
    }

    handleUpdateMetricCard = async () => {
        if (!this.state.cardMainMetric || !this.state.cardType) return false

        let { dashboard, cardValues, cardComparison, cardMainMetric, cardMetricsToBeChosen, cardType, cardStageId, cardUpdateId } = this.state

        dashboard.app.forEach((stage, index) => {
            if (stage.id === cardStageId) {
                stage.cards.forEach(card => {
                    if (card.id === cardUpdateId) {
                        card.type = cardType
                        card.main_metric_key = cardMainMetric
                        card.values = { ...cardValues }
                        card.comparison = [...cardComparison]
                        card.metrics_to_be_chosen = [...cardMetricsToBeChosen]
                    }
                })
            }
        })

        this.setState({ dashboard, isCardModalVisible: false })
        this.cleanCardModal()
    } 

    cleanCardModal = () => this.setState({ cardMainMetric: "", cardMetricsToBeChosen: [], cardType: "", cardValues: {}, cardComparison: [], cardStageId: null, cardUpdateId: null, cardTypesList: [] })

    handleAddMetric = (metric, cardId, stageId) => {
        let { dashboard } = this.state

        dashboard.app.forEach((stage, index) => {
            if (stage.id === stageId) {
                stage.cards.forEach(card => {
                    if (card.id === cardId) {
                        card.metrics.push(metric)
                        let metricsToBeChosen = card.metrics_to_be_chosen.filter(chosenMetric => chosenMetric.metric_key !== metric.metric_key)
                        card.metrics_to_be_chosen = [...metricsToBeChosen]
                    }
                })
            }
        })
        this.setState({ dashboard })
    }

    handleRemoveMetric = (metric, cardId, stageId) => {
        let { dashboard } = this.state

        dashboard.app.forEach((stage, index) => {
            if (stage.id === stageId) {
                stage.cards.forEach(card => {
                    if (card.id === cardId) {
                        card.metrics = card.metrics.filter(chosenMetric => chosenMetric.metric_key !== metric.metric_key)
                        card.metrics_to_be_chosen.push(metric)
                    }
                })
            }
        })
        this.setState({ dashboard })
    }

    handleAddMetricView = (view, cardId, stageId, metricKey, isMetricToBeChosen) => {

        let metricType = isMetricToBeChosen ? "metrics_to_be_chosen" : "metrics"
        let { dashboard } = this.state
        dashboard.app.forEach((stage, index) => {
            if (stage.id === stageId) {
                stage.cards.forEach((card, indexCard) => {
                    if (card.id === cardId) {
                        card[metricType].forEach((metric, indexMetric) => {
                            if (metric.metric_key === metricKey) {
                                metric.views.push(view)
                                let metricsToBeChosen = metric.views_to_be_chosen.filter(chosenView => chosenView.view_code !== view.view_code)
                                metric.views_to_be_chosen = [...metricsToBeChosen]
                            }
                        })
                    }
                })
            }
        })

        this.setState({ dashboard })
    }

    handleRemoveMetricView = (view, cardId, stageId, metricKey, isMetricToBeChosen) => {
        let metricType = isMetricToBeChosen ? "metrics_to_be_chosen" : "metrics"
        let { dashboard } = this.state
        dashboard.app.forEach((stage, index) => {
            if (stage.id === stageId) {
                stage.cards.forEach(card => {
                    if (card.id === cardId) {
                        card[metricType].forEach(metric => {
                            if (metric.metric_key === metricKey) {
                                metric.views = metric.views.filter(chosenView => chosenView.view_code !== view.view_code)
                                metric.views_to_be_chosen.push(view)
                            }
                        })
                    }
                })
            }
        })
        this.setState({ dashboard })
    }

    postDashboardUpdate = async () => {
        if (!this.checkCanPostDashboardUpdate()) return
        let couldNotUpdate = false
        try {
            //let response = await api.post("/", {dashboard: this.state.dashboard})
            let response = { data: {success: false}}
            if(response && response.data.success){
                this.modalResponseOnCloseCallback = () => {
                    this.props.history.replace("/choose_dashboard_performance")
                }
                this.setState({msgResponseShow: "Dashboard Atualizada com Sucesso", isModalResponseVisible: true})
            } else {
                couldNotUpdate = true
            }
        } catch (error) {
            couldNotUpdate = true
        }
        if(couldNotUpdate) {
            this.setState({msgResponseShow: "Não foi possível atualizar a dashboard", isModalResponseVisible: true})
        }
    }

    checkCanPostDashboardUpdate = () => {
        let errorType = []
        let emptyStages = 0
        let unselectedStages = 0
        let emptyCards = 0
        let cardIdsWithProblems = []
        let { dashboard } = this.state

        if (!dashboard.title) errorType.push("dashboard_title")
        if (!dashboard.short_description) errorType.push("dashboard_short_description")
        if (!dashboard.color) errorType.push("dashboard_color")

        if (!dashboard.app || dashboard.app.length === 0) errorType.push("dashboard_at_least_one_stage")
        else {
            dashboard.app.forEach((stage, index) => {
                if (!stage.key) unselectedStages++
                if (!stage.cards || stage.cards.length === 0) {
                    emptyStages++
                }
                else {
                    stage.cards.forEach(card => {
                        if (!card.values || card.values.length === 0) {
                            emptyCards++
                        }
                        else {
                            if (this.checkThereAreCardEmptyFields(card.values)) {
                                emptyCards++
                                cardIdsWithProblems = this.addToCardIdsWithProblems(cardIdsWithProblems, stage.id, card.id, "card_fields")
                            }
                            if (!card.metrics || card.metrics.length === 0) {
                                errorType.push("dashboard_card_at_least_one_metric")
                                cardIdsWithProblems = this.addToCardIdsWithProblems(cardIdsWithProblems, stage.id, card.id, "metric")
                            }
                            else {
                                card.metrics.forEach(metric=>{
                                    if (!metric.views || metric.views.length === 0) {
                                        errorType.push("dashboard_metric_at_least_one_view")
                                        cardIdsWithProblems = this.addToCardIdsWithProblems(cardIdsWithProblems, stage.id, card.id, "stage")
                                    }
                                })
                            }
                        }
                    })
                }
            })
        }

        if (unselectedStages) errorType.push("dashboard_stage")
        if (emptyStages) errorType.push("dashboard_empty_stage")
        if (emptyCards) errorType.push("dashboard_card")

        errorType = [...new Set(errorType)]

        if (errorType.length) {
            this.setState({ isModalResponseVisible: true, msgResponseShow: this.buildProblemsMessage(errorType), cardIdsWithProblems})
        } else {
            this.setState({cardIdsWithProblems: []})
        }

        return errorType ? false : true
    }

    addToCardIdsWithProblems = (cardIdsWithProblems, stageId, cardId, type) => {
        let checkHasDuplicate = false
        cardIdsWithProblems.forEach(problem=>{
            if(problem.stageId === stageId && problem.cardId === cardId) checkHasDuplicate = true
        })

        if(!checkHasDuplicate) cardIdsWithProblems.push({stageId, cardId, type})
        return cardIdsWithProblems
    }

    checkCardHasProblems = (stageId, cardId) => {
        let problemClassName = ""
        
        if(!this.state.cardIdsWithProblems || this.state.cardIdsWithProblems.length === 0) return ""
        this.state.cardIdsWithProblems.forEach(problem=>{
            if(problem.stageId === stageId && problem.cardId === cardId){
                if(problem.type === "stage")  problemClassName = "border border-warning rounded-2"
                else if(problem.type === "metric") problemClassName = "border border-danger rounded-2"
                else if(problem.type === "card_fields") problemClassName = "border-1-solid-orange rounded-2"
            }
        })
        return problemClassName 
    }

    checkThereAreCardEmptyFields = (values) => {
        let checkEmptyFields = false
        Object.values(values).forEach(value => {
            if (!value && value !== 0) checkEmptyFields = true
        })
        return checkEmptyFields
    }

    buildProblemsMessage = (errorType) => errorType.map((error, index)=> 
        <div className="d-flex justify-content-center align-items-center" key={`${error}${index}`}>
            <i className={` fa fa-exclamation-triangle 
                ${error === "dashboard_metric_at_least_one_view" ? "text-warning" : ""} 
                ${error === "dashboard_card_at_least_one_metric" ? "text-danger" : ""} 
                ${error === "dashboard_card" ? "text-orange" : ""} 
            `} />
            &nbsp;
            <span className="mb-0"> {this.getErrorMessage(error)}</span>
            &nbsp;
            <i className={` fa fa-exclamation-triangle 
                ${error === "dashboard_metric_at_least_one_view" ? "text-warning" : ""} 
                ${error === "dashboard_card_at_least_one_metric" ? "text-danger" : ""} 
                ${error === "dashboard_card" ? "text-orange" : ""} 
            `} />
        </div>
    )

    getErrorMessage = errorType => {
        let errorMessage = ""
        switch (errorType) {
            case "dashboard_title":
                errorMessage = this.translate("CREATE_DASHBOARD_DASHBOARD_TITLE_CANT_BE_EMPTY")
                break
            case "dashboard_short_description":
                errorMessage = this.translate("CREATE_DASHBOARD_DASHBOARD_SHORT_DESCRIPTION_CANT_BE_EMPTY")
                break
            case "dashboard_color":
                errorMessage = this.translate("CREATE_DASHBOARD_DASHBOARD_ICON_COLOR_CANT_BE_EMPTY")
                break
            case "dashboard_stage":
                errorMessage = this.translate("CREATE_DASHBOARD_THERE_ARE_UNSELECTED_STAGES")
                break
            case "dashboard_empty_stage":
                errorMessage = this.translate("CREATE_DASHBOARD_DASHBOARD_STAGES_CANT_BE_EMPTY")
                break
            case "dashboard_at_least_one_stage":
                errorMessage = this.translate("CREATE_DASHBOARD_DASHBOARD_MUST_HAVE_AT_LEAST_ONE_STAGE")
                break
            case "dashboard_card":
                errorMessage = this.translate("CREATE_DASHBOARD_THERE_ARE_CARDS_WITH_INCOMPLETE_INFORMATION")
                break
            case "dashboard_card_at_least_one_metric":
                errorMessage = this.translate("CREATE_DASHBOARD_CARDS_MUST_HAVE_AT_LEAST_ONE_METRIC")
                break
            case "dashboard_metric_at_least_one_view":
                errorMessage = this.translate("CREATE_DASHBOARD_METRICS_MUST_HAVE_AT_LEAST_ONE_VIEW")
                break
            default:
                errorMessage = this.translate("CREATE_DASHBOARD_THERE_ARE_FIELDS_INCOMPLETE_INFORMATION")
                break
        }
        return errorMessage
    }

    disposeTooltip = () => $('[data-toggle="tooltip"]').tooltip('dispose')

    componentDidMount = () => {
        this.checkIdAndFetchDashboard(document.location.hash.replace('#', ''))
        this.loadMainMetricList()

        $('[data-toggle="tooltip"]').tooltip('dispose').tooltip({ placement: "bottom", trigger: "hover" })
    }

    componentDidUpdate = () => {
        $('[data-toggle="tooltip"]').tooltip('dispose').tooltip({ placement: "bottom", trigger: "hover" })

        //console.log(JSON.stringify(this.state.dashboard))

    }

    renderDashboardPerformance = () => {
        if (!this.state.dashboard.app || !this.state.dashboard.app.length) return
        return (
            this.state.dashboard.app.map((stage, index) =>
                <React.Fragment key={`${stage.id}`}>
                    <div className="row">
                        <CardBuilder translate={this.translate} type={"stage"} stage={stage} isEditing={true} handleStageTitleChange={this.handleStageTitleChange} handleStageRemove={this.handleStageRemove} />
                    </div>
                    <div className="row">
                        {stage.cards && stage.cards.length > 0 &&
                            stage.cards.map((card, cardIndex) =>
                                <CardBuilder
                                    key={`${stage.id}${card.id}`}
                                    {...card}
                                    isEditing={true}
                                    stageId={stage.id}
                                    handleCardChange={this.handleCardChange}
                                    handleCardRemove={this.handleCardRemove}
                                    handleCardChangeMainMetric={this.handleCardChangeMainMetric}
                                    handleAddMetric={this.handleAddMetric}
                                    handleRemoveMetric={this.handleRemoveMetric}
                                    handleAddMetricView={this.handleAddMetricView}
                                    handleRemoveMetricView={this.handleRemoveMetricView}
                                    dashboardTitle={this.state.dashboard.title}
                                    translate={this.translate}
                                    className={this.checkCardHasProblems(stage.id, card.id)}
                                />
                            )

                        }

                        <CardBuilder translate={this.translate} type={"add_card"} stageKey={stage.key} stageId={stage.id} handleAddCard={this.handleAddCard} />
                    </div>
                </React.Fragment>
            )
        )
    }

    render() {
        return (
            <React.Fragment>
                <MenuMain />
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    <HeaderMain title={this.translate("CREATE_DASHBOARD_PERFORMANCE_CREATE_DASHBOARD_PERFORMANCE")} onChange={this.handleChange} />
                    <div className="content-main" id="content-main">
                        <div className="padding pt-0">
                            {this.state.isLoadingDashboard ?
                                <p className="d-flex flex-grow-1 justify-content-center align-items-center text-md">
                                    <i className="fa fa-spin fa-spinner" />&nbsp;
                                    {this.translate('ICON_LOADING')}
                                </p>

                                :

                                <div className="row">
                                    <div className="col-12">
                                        <div className="row box-shadow padding">
                                            {this.state.isEditingDashboardTitle ?
                                                <React.Fragment>
                                                    <div className="col-3">
                                                        <div className="row">
                                                            <div className="form-group col-12">
                                                                <i className={`fa fa-tachometer f32 text-center`} style={{ color: this.state.dashboard.color }}></i>
                                                                <input
                                                                    type="color"
                                                                    value={this.state.dashboard.color}
                                                                    onChange={e => this.setState({ dashboard: { ...this.state.dashboard, color: e.target.value } })}
                                                                    className="btn-cursor-pointer bg-dark border-0 ml-3 border-dark"
                                                                    style={{ height: 30 }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="form-group col-12">
                                                                <input type="text"
                                                                    placeholder={this.translate('CREATE_DASHBOARD_PERFORMANCE_ENTER_THE_NAME_OF_THE_DASHBOARD')}
                                                                    value={this.state.dashboard.title}
                                                                    onChange={e => this.setState({ dashboard: { ...this.state.dashboard, title: e.target.value } })}
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="form-group col-12">
                                                                <input type="text"
                                                                    placeholder={this.translate('CREATE_DASHBOARD_PERFORMANCE_ENTER_THE_SHORT_DESCRIPTION_OF_THE_DASHBOARD')}
                                                                    value={this.state.dashboard.short_description}
                                                                    onChange={e => this.setState({ dashboard: { ...this.state.dashboard, short_description: e.target.value } })}
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-9 d-flex align-items-center justify-content-end">
                                                        <button type="button" onClick={() => { this.disposeTooltip(); this.setState({ isEditingDashboardTitle: false }) }} className="btn btn-success btn-cursor-pointer" data-toggle="tooltip" title={this.translate("CREATE_DASHBOARD_PERFORMANCE_CONFIRM_CHANGES")}>
                                                            <i className="fa fa-check"></i>
                                                        </button>

                                                        <a href="/choose_dashboard_performance" className="btn btn-default green btn-cursor-pointer ml-2">
                                                            <i className="fa fa-arrow-circle-left" /> &nbsp;
                                                            {this.translate("DASHBOARD_PERFORMANCE_GO_BACK")}
                                                        </a>
                                                    </div>
                                                </React.Fragment>

                                                :
                                                <div className="col-12 d-flex align-items-center">
                                                    <div className="d-flex flex-column mr-5 flex-grow-1">
                                                        <h3 className="pr-2">
                                                            <i className={`fa fa-tachometer f32 text-center`} style={{ color: this.state.dashboard.color }}></i> &nbsp;
                                                            {this.state.dashboard.title || this.translate('CREATE_DASHBOARD_PERFORMANCE_ENTER_THE_NAME_OF_THE_DASHBOARD')}
                                                        </h3>

                                                        <h5>{this.state.dashboard.short_description || this.translate('CREATE_DASHBOARD_PERFORMANCE_ENTER_THE_SHORT_DESCRIPTION_OF_THE_DASHBOARD')}</h5>
                                                    </div>
                                                    <button onClick={() => { this.disposeTooltip(); this.setState({ isEditingDashboardTitle: true }) }} className="btn btn-warning btn-cursor-pointer ml-5" data-toggle="tooltip" title={this.translate("CREATE_DASHBOARD_PERFORMANCE_EDIT_DASHBOARD_TITLE")}>
                                                        <i className="fa fa-edit"></i>
                                                    </button>

                                                    <a href="/choose_dashboard_performance" className="btn btn-default green btn-cursor-pointer ml-2">
                                                        <i className="fa fa-arrow-circle-left" /> &nbsp;
                                                        {this.translate("DASHBOARD_PERFORMANCE_GO_BACK")}
                                                    </a>
                                                </div>

                                            }
                                        </div>
                                    </div>

                                    <div className="col-12 mx-1">
                                        {this.state.dashboard.app && this.state.dashboard.app.length > 0 &&
                                            this.renderDashboardPerformance()
                                        }
                                        <hr />
                                    </div>

                                    <CardBuilder translate={this.translate} type={"add_stage"} handleAddStage={this.handleAddStage} />

                                    <div className="col-12">
                                        <hr />
                                        <button onClick={() => this.postDashboardUpdate()} className="btn btn-success btn-cursor-pointer my-3" >
                                            <i className="fa fa-edit"></i>&nbsp;
                                            {this.translate("CREATE_DASHBOARD_PERFORMANCE_UPDATE_DASHBOARD_PERFORMANCE")}
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <FooterMain />
                </div>
                <Modal show={this.state.isCardModalVisible} onHide={this.closeCardModal} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                    <i className="fa fa-line-chart"></i> {this.translate('CREATE_DASHBOARD_PERFORMANCE_ADD_CARD')}</h5>
                                <a href="/" onClick={e => this.closeCardModal(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                <div className="col-12 col-sm-8 mx-auto">
                                    <form className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="modal_add_card_main_metric">{this.translate('CREATE_DASHBOARD_PERFORMANCE_MAIN_METRIC')}</label>
                                            <select id="modal_add_card_main_metric" value={this.state.cardMainMetric} onChange={e => this.handleMainMetricChange(e.target.value)} className="form-control box-shadow cool-select">
                                                <option value="">{this.translate('CREATE_DASHBOARD_PERFORMANCE_SELECT_THE_MAIN_METRIC')}</option>
                                                {(this.state.mainMetricList && this.state.mainMetricList.length > 0) &&
                                                    this.state.mainMetricList.map((metric, index) =>
                                                        <option key={`mainmetric${metric.main_metric_key}${index}`} value={metric.main_metric_key}>{metric.title}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                        {(this.state.cardMainMetric && this.state.cardTypesList && this.state.cardTypesList.length > 0) &&
                                            <div className="form-group col-12">
                                                <label htmlFor="modal_add_card_main_metric">{this.translate('CREATE_DASHBOARD_PERFORMANCE_SELECT_THE_CARD_TYPE')}</label>
                                                <div className="row">
                                                    {this.state.cardTypesList.map((card, index) =>
                                                        <React.Fragment key={`cardList${card.type}${index}`}>
                                                            {card.title && 
                                                                <React.Fragment>
                                                                    <div className="col-12 mt-2">
                                                                    </div>
                                                                    <h6 className="ml-3">{card.title}</h6>
                                                                </React.Fragment>
                                                            
                                                            }
                                                            <CardBuilder {...card} onClick={() => this.setState({ cardType: card.type })} translate={this.translate} className={this.state.cardType === card.type ? "new_card_selected_check" : ""} />
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                            </div>

                                        }
                                        <div className="form-group col-12">
                                            <hr />
                                            {this.state.cardUpdateId ?
                                                <button type="button" className="btn btn-primary btn-cursor-pointer pull-right" style={{ width: 170 }} onClick={this.handleCreateOrUpdateCard} disabled={(this.state.cardMainMetric && this.state.cardType) ? false : true}>
                                                    <i className="fa fa-edit" />&nbsp;
                                                    {this.translate('CREATE_DASHBOARD_PERFORMANCE_EDIT_CARD')}
                                                </button>
                                            :
                                                <button type="button" className="btn btn-primary btn-cursor-pointer pull-right" style={{ width: 170 }} onClick={this.handleCreateOrUpdateCard} disabled={(this.state.cardMainMetric && this.state.cardType) ? false : true}>
                                                    <i className="fa fa-plus" />&nbsp;
                                                    {this.translate('CREATE_DASHBOARD_PERFORMANCE_ADD_CARD')}
                                                </button>
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.isConfirmModalVisible} onHide={this.handleCloseConfirmModal} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                    <i className="fa fa-check"></i> {this.translate("MODAL_CONFIRM_TITLE")}</h5>
                                <a href="/" onClick={e => this.handleCloseConfirmModal(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                <div className="col-12">
                                    <h3 className="py-2 text-center">{this.state.confirmModalMessage}</h3>
                                </div>
                                <div className="col-4 mx-auto">
                                    <button className="btn btn-danger btn-cursor-pointer" style={{ width: '100%' }} onClick={e => this.handleCloseConfirmModal(e)}>
                                        {this.translate("MODAL_CONFIRM_NO")}
                                    </button>
                                </div>
                                <div className="col-4 mx-auto">
                                    <button className="btn btn-success btn-cursor-pointer" style={{ width: '100%' }} onClick={(e) => this.confirmModalCallback()}>
                                        {this.translate("MODAL_CONFIRM_YES")}
                                    </button>

                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.isModalResponseVisible} onHide={this.handleCloseModalResponse} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                    <i className="fa fa-bell"></i> {this.translate("MODAL_STATUS_STATUS")}</h5>
                                <a href="/" onClick={e => this.handleCloseModalResponse(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                <span className="col-12 text-center py-2 lead">
                                    {this.state.msgResponseShow}
                                </span>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(UpdateDashboardPerformance)