import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'

import $ from 'jquery';
import 'jquery-mask-plugin';

import api from '../../../Services/Api';

import Modal from 'react-bootstrap/Modal';

import GooglePlaces from '../../../Structure/GooglePlaces';

class BusinessRegister extends Component {

    constructor(props){
        super(props)
        this.state = {
            modalBusinessShow: false,
            msgBusinessShow: false,
            formBusinessRegister: {
                idCompany: this.props.idCompany,
                name: null,
                type: null,
                email: null,
                ddi: null, 
                phone: null,
                address: null
            }
        }
        this.handleCloseModalResponse =  this.handleCloseModalResponse.bind(this)
        this.reloadDatatable = this.reloadDatatable.bind(this)
        this.handleAddress = this.handleAddress.bind(this)
        this.translate = this.props.translate
    }

    componentDidMount(){
        var SPMaskBehavior = function (val){
            var ddi = $('#register-ddi').val();
            if(ddi === "+55"){
                return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
            }else{
                return val.replace(/\D/g, '').length === 11 ? '(000) 000-0000' : '(000) 000-0000';
            }
        },
        spOptions = {
            onComplete: function(val){
                var phone = $('#register-phone').val();
                this.setState({formBusinessRegister: {...this.state.formBusinessRegister, phone: phone}})
            }.bind(this),
            onKeyPress: function(val, e, field, options) {
                field.mask(SPMaskBehavior.apply({}, arguments), options);
            }
        };

        $('#register-ddi').mask('+099');
        $('#register-phone').mask(SPMaskBehavior, spOptions);
    }

    handleCloseModalResponse(e = null){
        if (e) e.preventDefault()
        this.setState({modalBusinessShow: false})
    }
    
    handleAddress(address){
       this.setState(
            {
                formBusinessRegister: {
                    ...this.state.formBusinessRegister, address: address
                }
            }
        )
        if(address !== null){
            $('#map').css('display', 'block');
        } else {
            $('#map').css('display', 'none');
        }
    }
    handleAddressInput(e){
        this.setState({ formBusinessRegister: {...this.state.formBusinessRegister, address:  e.target.value}})
        if(e.target.value === "") this.handleAddress(null)
    }

    handleBusinessRegister = async e => {
        e.preventDefault();
        this.setState({ msgBusinessShow: this.translate("ADMIN_BUSINESS_REGISTER_WAIT_STATUS"), modalBusinessShow: true });
        const data = this.state.formBusinessRegister;
        try{
            const response = await api.post("/business/register", {data});
            if(response.data.success === true){
                this.handleAddress(null)
                this.setState({msgBusinessShow: this.translate("ADMIN_BUSINESS_REGISTER_SUCCESS_STATUS")});
                document.getElementById('register-business-new').reset();
                this.reloadDatatable()
            }else{
                this.setState({msgBusinessShow: response.data.msg});
            }
        }catch(err){
            this.setState({msgBusinessShow: err});
        }
    }

    reloadDatatable(){
        $('#list_business').DataTable().ajax.reload();
    }

    render() {
        return (
            <React.Fragment>
                <h5 className="fwhite">{this.translate("ADMIN_BUSINESS_REGISTER_BUSINESS")}</h5>
                <form onSubmit={this.handleBusinessRegister} id="register-business-new" className="width100">
                    <div className="row p-3 white box-shadow pb40">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="form-group">
                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                    {this.translate("ADMIN_BUSINESS_REGISTER_LABEL_NAME")}:
                                </label>
                                <input type="text" onChange={e => this.setState({ formBusinessRegister: {...this.state.formBusinessRegister, name:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("ADMIN_BUSINESS_REGISTER_PLACEHOLDER_NAME")} required/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                {this.translate("ADMIN_BUSINESS_REGISTER_LABEL_EMAIL")}:
                                </label>
                                <input type="email" onChange={e => this.setState({formBusinessRegister: {...this.state.formBusinessRegister, email: e.target.value}})} className="form-control box-shadow" placeholder={this.translate("ADMIN_BUSINESS_REGISTER_PLACEHOLDER_EMAIL")} required/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-2">
                            <div className="form-group">
                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                    {this.translate("ADMIN_BUSINESS_REGISTER_LABEL_DDI")}:
                                </label>
                                <input type="text" onChange={e => this.setState({formBusinessRegister: {...this.state.formBusinessRegister, ddi: e.target.value}})} id="register-ddi" className="form-control box-shadow" placeholder={this.translate("ADMIN_BUSINESS_REGISTER_PLACEHOLDER_DDI")} required/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <div className="form-group">
                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                {this.translate("ADMIN_BUSINESS_REGISTER_LABEL_PHONE")}:
                                </label>
                                <input type="text" onChange={e => this.setState({formBusinessRegister: {...this.state.formBusinessRegister, phone: e.target.value}})} id="register-phone" className="form-control box-shadow" placeholder={this.translate("ADMIN_BUSINESS_REGISTER_PLACEHOLDER_PHONE")} required/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                    {this.translate("ADMIN_BUSINESS_REGISTER_LABEL_ADDRESS")}:
                                </label>
                                <GooglePlaces address={this.state.formBusinessRegister.address} onChangeInput={e => this.handleAddressInput(e)} onChange={this.handleAddress} />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                    {this.translate("ADMIN_BUSINESS_REGISTER_LABEL_TYPE")}:
                                </label>
                                <select onChange={e => this.setState({ formBusinessRegister: {...this.state.formBusinessRegister, type:  e.target.value}})} className="form-control box-shadow cool-select" required>
                                    <option value="">{this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTION_1")}</option>
                                    <optgroup label={this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTGROUP_APARTMENT")}>
                                        <option value="apartment_default">{this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTION_APARTMENT_DEFAULT")}</option>
                                        <option value="apartment_penthouse">{this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTION_APARTMENT_PENTHOUSE")}</option>
                                        <option value="apartment_flat">{this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTION_APARTMENT_FLAT")}</option>
                                        <option value="apartment_kitnet_studio">{this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTION_APARTMENT_KITNET_STUDIO")}</option>
                                        <option value="apartment_loft">{this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTION_APARTMENT_LOFT")}</option>
                                    </optgroup>
                                    <optgroup label={this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTGROUP_HOUSES")}>
                                        <option value="house_district">{this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTION_HOUSE_DISTRICT")}</option>
                                        <option value="house_condominium">{this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTION_HOUSE_CONDOMINIUM")}</option>
                                        <option value="house_loft">{this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTION_HOUSE_LOFT")}</option>
                                        <option value="house_villa">{this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTION_HOUSE_VILLA")}</option>
                                    </optgroup>
                                    <optgroup label={this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTGROUP_LANDS")}>
                                        <option value="land_condominium">{this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTION_LAND_CONDOMINIUM")}</option>
                                        <option value="land_district">{this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTION_LAND_DISTRICT")}</option>
                                    </optgroup>
                                    <optgroup label={this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTGROUP_RURALS")}>
                                        <option value="rural_small_farm">{this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTION_RURAL_SMALL_FARM")}</option>
                                        <option value="rural_farm">{this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTION_RURAL_FARM")}</option>
                                        <option value="rural_land">{this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTION_RURAL_LAND")}</option>
                                    </optgroup>
                                    <optgroup label={this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTGROUP_COMMERCIAL")}>
                                        <option value="commercial_house">{this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_HOUSE")}</option>
                                        <option value="commercial_box_garage">{this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_BOX_GARAGE")}</option>
                                        <option value="commercial_office_living_room">{this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_OFFICE")}</option>
                                        <option value="commercial_warehouse">{this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_WAREHOUSE")}</option>
                                        <option value="commercial_hotel">{this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_HOTEL")}</option>
                                        <option value="commercial_industry">{this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_INDUSTRY")}</option>
                                        <option value="commercial_shopping_store">{this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_SHOPPING")}</option>
                                        <option value="commercial_store">{this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_STORE")}</option>
                                        <option value="commercial_motel">{this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_MOTEL")}</option>
                                        <option value="commercial_hostel">{this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_HOSTEL")}</option>
                                        <option value="commercial_building">{this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_BUILDING")}</option>
                                        <option value="commercial_studio">{this.translate("ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_STUDIO")}</option>
                                    </optgroup>
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 mt20">
                            <div id="map" style={{display: 'none', width: '100%', height: '400px'}}></div>
                        </div>
                        <div className="box-divider m0 mt20 width100"></div>
                        <div className="col-sm-12 col-md-12 mt20">
                            <button type="submit" className="btn btn-info px-5 btn-rounded btn-shadow pull-right">
                                <i className="fa fa-save"></i> {this.translate("ADMIN_BUSINESS_REGISTER_REGISTER_BUSINESS")}
                            </button>
                        </div>
                    </div>
                </form>
                <Modal show={this.state.modalBusinessShow} onHide={this.handleCloseModalResponse} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-bell"></i> {this.translate("MODAL_STATUS_STATUS")}</h5>
                                <a href="/" onClick={e=>this.handleCloseModalResponse(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                <span className="col-12 text-center py-2 lead">
                                    {this.state.msgBusinessShow}
                                </span>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(BusinessRegister);