import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";

import $ from "jquery";

import Modal from "react-bootstrap/Modal";

import Hypnobox from "./CRMs/Hypnobox";
import Anapro from "./CRMs/Anapro";
import Pipedrive from "./CRMs/Pipedrive";
import FunilDeVendas from "./CRMs/FunilDeVendas";
import SuaHouse from "./CRMs/SuaHouse";
import Lopes from "./CRMs/Lopes";
import ConstrutorDeVendas from "./CRMs/ConstrutorDeVendas";

class IntegrationCRMs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      crmTitle: false,
      crmActive: false,
      code: null,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);

    this.renderCRM = this.renderCRM.bind(this);
    this.translate = this.props.translate;
  }

  handleClose() {
    this.setState({ show: false, crmActive: false });
  }
  handleShow(e) {
    this.setState({
      show: true,
      crmActive: e.target.value,
      crmTitle: e.target.title,
    });
  }

  toggleCollapse = (selector) => $(selector).collapse("toggle");

  renderCRM(crm) {
    if (crm) {
      let crmComponent = null;
      switch (crm) {
        case "hypnobox":
          crmComponent = (
            <Hypnobox
              toggleCollapse={this.toggleCollapse}
              translate={this.translate}
            />
          );
          break;
        case "sua_house":
          crmComponent = (
            <SuaHouse
              toggleCollapse={this.toggleCollapse}
              translate={this.translate}
            />
          );
          break;
        case "anapro":
          crmComponent = (
            <Anapro
              toggleCollapse={this.toggleCollapse}
              translate={this.translate}
            />
          );
          break;
        case "pipedrive":
          crmComponent = (
            <Pipedrive
              toggleCollapse={this.toggleCollapse}
              translate={this.translate}
            />
          );
          break;
        case "funil_de_vendas":
          crmComponent = (
            <FunilDeVendas
              toggleCollapse={this.toggleCollapse}
              translate={this.translate}
            />
          );
          break;
        case "lopes":
          crmComponent = (
            <Lopes
              toggleCollapse={this.toggleCollapse}
              translate={this.translate}
            />
          );
          break;
        case "construtordevendas":
          crmComponent = (
            <ConstrutorDeVendas
              toggleCollapse={this.toggleCollapse}
              translate={this.translate}
            />
          );
          break;
        default:
          crmComponent = false;
          break;
      }
      if (crmComponent) {
        return crmComponent;
      }
    }
  }

  render() {
    return (
      <Fragment>
        <div className="row pb40">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <h4 className="text-center mt20 mb20">
              <i className="fa fa-plus-square-o"></i>{" "}
              {this.translate("INTEGRATION_CRM_CRMS")}
            </h4>
          </div>

          <div className="col-sm-12 col-md-4 col-lg-4">
            <div className="text-center box-shadow border-radius blue-grey ">
              <div className="p-4 ">
                <p className="f64 m0 text-white">
                  <i className="fa fa-plus-square-o"></i>
                </p>
                <h5 className="text-md f24 d-block f600 fwhite mb20">
                  {this.translate("INTEGRATION_CRM_HYPNOBOX")}
                </h5>
                <button
                  onClick={this.handleShow}
                  value="hypnobox"
                  title={this.translate("INTEGRATION_CRM_HYPNOBOX")}
                  className="btn btn-info info btn-rounded text-white btn-shadow"
                >
                  <i className="fa fa-cogs"></i>{" "}
                  {this.translate("INTEGRATION_CRM_CONFIGURE_HYPNOBOX_ACCOUNT")}
                </button>
                <br />
                <br />
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-4 col-lg-4">
            <div className="text-center box-shadow border-radius blue-grey ">
              <div className="p-4 ">
                <p className="f64 m0 text-white">
                  <i className="fa fa-plus-square-o"></i>
                </p>
                <h5 className="text-md f24 d-block f600 fwhite mb20">
                  {this.translate("INTEGRATION_CRM_SUA_HOUSE")}
                </h5>
                <button
                  onClick={this.handleShow}
                  value="sua_house"
                  title={this.translate("INTEGRATION_CRM_SUA_HOUSE")}
                  className="btn btn-info info btn-rounded text-white btn-shadow"
                >
                  <i className="fa fa-cogs"></i>{" "}
                  {this.translate(
                    "INTEGRATION_CRM_CONFIGURE_SUA_HOUSE_ACCOUNT"
                  )}
                </button>
                <br />
                <br />
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-4 col-lg-4">
            <div className="text-center box-shadow border-radius blue-grey ">
              <div className="p-4 ">
                <p className="f64 m0 text-white">
                  <i className="fa fa-plus-square-o"></i>
                </p>
                <h5 className="text-md f24 d-block f600 fwhite mb20">
                  {this.translate("INTEGRATION_CRM_ANAPRO")}
                </h5>
                <button
                  onClick={this.handleShow}
                  value="anapro"
                  title={this.translate("INTEGRATION_CRM_ANAPRO")}
                  className="btn btn-info info btn-rounded text-white btn-shadow"
                >
                  <i className="fa fa-cogs"></i>{" "}
                  {this.translate("INTEGRATION_CRM_CONFIGURE_ANAPRO_ACCOUNT")}
                </button>
                <br />
                <br />
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-12 col-lg-12 mb-5"></div>

          <div className="col-sm-12 col-md-4 col-lg-4">
            <div className="text-center box-shadow border-radius blue-grey ">
              <div className="p-4 ">
                <p className="f64 m0 text-white">
                  <i className="fa fa-plus-square-o"></i>
                </p>
                <h5 className="text-md f24 d-block f600 fwhite mb20">
                  {this.translate("INTEGRATION_CRM_PIPEDRIVE")}
                </h5>
                <button
                  onClick={this.handleShow}
                  value="pipedrive"
                  title={this.translate("INTEGRATION_CRM_PIPEDRIVE")}
                  className="btn btn-info info btn-rounded text-white btn-shadow"
                >
                  <i className="fa fa-cogs"></i>{" "}
                  {this.translate(
                    "INTEGRATION_CRM_CONFIGURE_PIPEDRIVE_ACCOUNT"
                  )}
                </button>
                <br />
                <br />
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-4 col-lg-4">
            <div className="text-center box-shadow border-radius blue-grey ">
              <div className="p-4 ">
                <p className="f64 m0 text-white">
                  <i className="fa fa-plus-square-o"></i>
                </p>
                <h5 className="text-md f24 d-block f600 fwhite mb20">
                  {this.translate("INTEGRATION_CRM_SALES_FUNNEL")}
                </h5>
                <button
                  onClick={this.handleShow}
                  value="funil_de_vendas"
                  title={this.translate("INTEGRATION_CRM_SALES_FUNNEL")}
                  className="btn btn-info info btn-rounded text-white btn-shadow"
                >
                  <i className="fa fa-cogs"></i>{" "}
                  {this.translate(
                    "INTEGRATION_CRM_CONFIGURE_SALES_FUNNEL_ACCOUNT"
                  )}
                </button>
                <br />
                <br />
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-4 col-lg-4">
            <div className="text-center box-shadow border-radius blue-grey ">
              <div className="p-4 ">
                <p className="f64 m0 text-white">
                  <i className="fa fa-plus-square-o"></i>
                </p>
                <h5 className="text-md f24 d-block f600 fwhite mb20">
                  {this.translate("INTEGRATION_CRM_LOPES")}
                </h5>
                <button
                  onClick={this.handleShow}
                  value="lopes"
                  title={this.translate("INTEGRATION_CRM_LOPES")}
                  className="btn btn-info info btn-rounded text-white btn-shadow"
                >
                  <i className="fa fa-cogs"></i>{" "}
                  {this.translate("INTEGRATION_CRM_CONFIGURE_LOPES_ACCOUNT")}
                </button>
                <br />
                <br />
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-12 col-lg-12 mb-5"></div>

          <div className="col-sm-12 col-md-4 col-lg-4">
            <div className="text-center box-shadow border-radius blue-grey ">
              <div className="p-4 ">
                <p className="f64 m0 text-white">
                  <i className="fa fa-plus-square-o"></i>
                </p>
                <h5 className="text-md f24 d-block f600 fwhite mb20">
                  {this.translate("INTEGRATION_CRM_CONSTRUTOR_DE_VENDAS")}
                </h5>
                <button
                  onClick={this.handleShow}
                  value="construtordevendas"
                  title={this.translate("INTEGRATION_CRM_CONSTRUTOR_DE_VENDAS")}
                  className="btn btn-info info btn-rounded text-white btn-shadow"
                >
                  <i className="fa fa-cogs"></i>{" "}
                  {this.translate(
                    "INTEGRATION_CRM_CONFIGURE_CRM_CONSTRUTOR_DE_VENDAS_ACCOUNT"
                  )}
                </button>
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          dialogAs={"div"}
          dialogClassName="modal-dialog modal-bottom white b-t"
        >
          <Modal.Body>
            <div className="d-flex flex-column h-100">
              <div className="p-3 b-b d-flex no-shrink blue-grey text-center position10">
                <h5 className="h5 m-0 text-center width100 line-height-38px">
                  <i className="fa fa-clone"></i> {this.state.crmTitle}
                </h5>
                <button
                  onClick={this.handleClose}
                  className="btn btn-transparent ml-auto"
                >
                  <span className="text-lg l-h-1x">×</span>
                </button>
              </div>
              <React.Fragment>
                {this.renderCRM(this.state.crmActive)}
              </React.Fragment>
            </div>
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  }
}

export default withRouter(IntegrationCRMs);
