import React, { Component, Fragment } from 'react';
import {withRouter} from 'react-router-dom';

import MenuMain from '../../Structure/MenuMain';
import HeaderMain from '../../Structure/HeaderMain';
import FooterMain from '../../Structure/FooterMain';

class Tools extends Component {

    constructor(props){
        super(props)
        this.translate = this.props.translate
        document.title = this.translate('TOOLS_TOOLS')
    }

    render() {
        return (
            <Fragment>
                <MenuMain />
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    <HeaderMain title={document.title} onChange={this.handleChange}/>
                    <div className="content-main row row-no-margin" id="content-main">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-gutters" id="ferramentas">
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="box text-center box-shadow border-radius">
                                        <div className="p-4">
                                            <p className="f84 m0 text-blue"><i className="fa fa-bullseye text-shadow"></i></p>
                                            <h5 className="text-md f24 d-block f600 fwhite mb20">{this.translate('TOOLS_ADMINISTRATE_SEGMENTATIONS')}</h5>
                                            <a href="targets" className="btn btn-outline btn-rounded b-primary text-primary btn-shadow">
                                                <i className="fa fa-bullseye"></i> {this.translate('TOOLS_ADMINISTRATE_SEGMENTATIONS')}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="box text-center box-shadow border-radius">
                                        <div className="p-4">
                                            <p className="f84 m0 text-warning"><i className="fa fa-users text-shadow"></i></p>
                                            <h5 className="text-md f24 d-block f600 fwhite mb20">{this.translate('TOOLS_LEAD_4_0')}</h5>
                                            <a href="tools_lead_plus" className="btn btn-outline btn-rounded b-warning text-warning btn-shadow">
                                                <i className="fa fa-users"></i> {this.translate('TOOLS_ADMINISTRATE_LEAD_4_0')}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="box text-center box-shadow border-radius">
                                        <div className="p-4">
                                            <p className="f84 m0 text-danger"><i className="fa fa-link text-shadow"></i></p>
                                            <h5 className="text-md f24 d-block f600 fwhite mb20">{this.translate('TOOLS_LINK_BUILDER')}</h5>
                                            <a href="tools_link_builder" className="btn btn-outline btn-rounded b-danger text-danger btn-shadow">
                                                <i className="fa fa-link"></i> {this.translate('TOOLS_LINK_BUILDER')}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterMain />
                </div>
            </Fragment>
        );
    }
}

export default withRouter(Tools);