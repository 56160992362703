import React, { Component } from 'react';
import Chart from 'chart.js';

class LeadsSummaryAuditedFeedbacks extends Component {
    
    componentDidMount(){
        const chartLeadsComparativeFeedbacksPositiveNegative = document.getElementById('chart-leads-comparative-feedbacks-positive-negative').getContext('2d');
        new Chart(chartLeadsComparativeFeedbacksPositiveNegative, {
            type: 'doughnut',
            data: {
                labels: ['Positivos','Negativos' ],
                datasets: [
                    {
                        data: [
                            this.props.fields.total_feedback_audited_positive,
                            this.props.fields.total_feedback_audited_negative
                        ],
                        borderColor: 'transparent',
                        backgroundColor: [
                            'rgba(23,216,115,1)',
                            'rgba(116,32,230,1)'
                        ],
                        label: 'Acessos'
                    }
                ]
            },
            options: {
                legend: {
                    position: 'bottom',
                    fontSize: '12',
                    labels:{
                        boxWidth: 10
                    }
                },
                cutoutPercentage: 40
            }
        });
    }

    render() {
        return (
            <div className="col-sm-12 col-md-6">
                <div className="box p-3 box-shadow2">
                    <div className="d-flex">
                        <span className="text-muted text-center w-100">Feedbacks auditados</span>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-5">
                            <div className=" text-center text-info lh mt10 mb10">
                                <a href="lista_leads.html">
                                    <i className="fa fa-check f14 opacity90"></i>
                                    <span className="f12 f400">
                                        Feedbacks auditados: {this.props.fields.total_feedback_audited}
                                    </span>
                                </a>
                            </div>
                            <canvas id="chart-leads-comparative-feedbacks-positive-negative" data-plugin="chart" height={136}></canvas>
                        </div>
                        <div className="col-sm-6 col-md-6">
                            <div className="py-2 b-b">
                                <div className="float-right lh">
                                    <span className="text-md text-success f700 d-block">
                                        {this.props.fields.leads_percent_feedback_audited_positive}%
                                    </span>
                                </div>
                                <span className="_600 d-block">
                                    <i className="fa fa-thumbs-up"></i>&nbsp;
                                    <a href="leads_feedbacks">Auditados positivos</a>
                                </span>
                            </div>
                            <div className="py-2 b-b">
                                <div className="float-right lh">
                                    <span className="text-md text-danger f700 d-block">
                                        {this.props.fields.leads_percent_feedback_audited_negative}%
                                    </span>
                                </div>
                                <span className="_600 d-block">
                                    <i className="fa fa-thumbs-down"></i>&nbsp;
                                    <a href="leads_feedbacks">Auditados negativos</a>
                                </span>
                            </div>
                            <div className="box list-item info p0 mt20">
                                <span className="avatar w-40 text-center lt">
                                    <span className="fa fa-phone f20"></span>
                                </span>
                                <div className="list-body">
                                    <h4 className="m-0 text-md">
                                        <a href="/">
                                            {this.props.fields.leads_total_calls}
                                            <span className="text-sm text-muted"></span>
                                        </a>
                                    </h4>
                                    <small className="text-muted">Ligações para leads</small>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default LeadsSummaryAuditedFeedbacks;