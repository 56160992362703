import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'

import api from '../../../Services/Api';

import Modal from 'react-bootstrap/Modal';

class BusinessLeads extends Component {

    constructor(props){
        super(props)
        this.state = {
            modalResponse: false,
            msgModalResponse: null,
            formAddLead: {
                name: null,
                email: null,
                phone: null,
                origin: null,
                comment: null
            }
        }

        this.showModalResponse = this.showModalResponse.bind(this)
        this.closeModalResponse = this.closeModalResponse.bind(this)
        this.submitFormAddLead = this.submitFormAddLead.bind(this)
        this.translate = this.props.translate
    }

    showModalResponse(){
        this.setState({
            modalResponse: true
        })
    }

    closeModalResponse(e = null){
        if (e) e.preventDefault()
        this.setState({
            modalResponse: false
        })
    }

    submitFormAddLead = async (e) => {
        e.preventDefault()
        this.setState({ msgModalResponse: this.translate("BUSINESS_LEADS_WAIT_STATUS"), modalResponse: true });
        const data = this.state.formAddLead
        try{
            const response = await api.post("/business/" + this.props.idBusiness + "/leads/register", data);
            if(response.data.success){
                this.setState({msgModalResponse: this.translate("BUSINESS_LEADS_SUCCESS_STATUS")});
                document.getElementById('register-leads-new').reset();
            }else{
                this.setState({msgModalResponse: this.translate("BUSINESS_LEADS_ERROR_STATUS")});
            }
        }catch(error){
            this.setState({msgModalResponse: error});
        }
    }

    render() {
        return (
            <React.Fragment>

                <div id="accordion-leads-control">

                    <div className="card mb-3">
                        <div className="card-header btn-cursor-pointer dark" id="headingOnesLeads">
                            <h5 className="mb-0" onClick={e=>this.props.toggleCollapse("#collapseOnesLeads")} aria-expanded="false" aria-controls="collapseOnesLeads">
                                {this.translate("BUSINESS_LEADS_REGISTER_REGISTER_LEAD")}
                                <i className="fa fa-caret-down pull-right"></i>
                            </h5>
                        </div>

                        <div id="collapseOnesLeads" className="collapse show dark" aria-labelledby="headingOnesLeads" data-parent="#accordion-leads-control">
                            <div className="card-body">
                                <form onSubmit={this.submitFormAddLead} id="register-leads-new">
                                    <div className="row p-3 white box-shadow pb40">
                                        
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group">
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                    {this.translate("BUSINESS_LEADS_REGISTER_NAME")}:
                                                </label>
                                                <input type="text" name="lead_name" autoComplete="off" onChange={e => this.setState({ formAddLead: {...this.state.formAddLead, name:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("BUSINESS_LEADS_REGISTER_NAME")}/>
                                            </div>
                                        </div>
                                        
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group">
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                    {this.translate("BUSINESS_LEADS_REGISTER_EMAIL")}:
                                                </label>
                                                <input type="email" name="lead_email" autoComplete="off" onChange={e => this.setState({ formAddLead: {...this.state.formAddLead, email:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("BUSINESS_LEADS_REGISTER_EMAIL")}/>
                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group">
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                {this.translate("BUSINESS_LEADS_REGISTER_PHONE")}:
                                                </label>
                                                <input type="text" name="lead_phone" autoComplete="off" onChange={e => this.setState({ formAddLead: {...this.state.formAddLead, phone:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("BUSINESS_LEADS_REGISTER_PHONE")}/>
                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group">
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                    {this.translate("BUSINESS_LEADS_REGISTER_ORIGIN_OF_LEAD")}:
                                                </label>
                                                <select name="lead_origin" onChange={e => this.setState({formAddLead: {...this.state.formAddLead, origin: e.target.value}})} className="form-control box-shadow cool-select" required>
                                                    <option value="">{this.translate("BUSINESS_LEADS_REGISTER_SELECT_ORIGIN_OF_LEAD")}</option>
                                                    <optgroup label="Twitter">
                                                        <option value="twitter_ads">Twitter Ads</option>
                                                        <option value="twitter_organic">Twitter Orgânico</option>
                                                    </optgroup>
                                                    <optgroup label="Facebook">
                                                        <option value="facebook_ads">Facebook Ads Tráfego</option>
                                                        <option value="facebook_ads_leads">Facebook Ads Leads</option>
                                                    </optgroup>
                                                    <optgroup label="Instagram">
                                                        <option value="instagram_ads">Instagram Ads Tráfego</option>
                                                        <option value="instagram_ads_leads">Instagram Ads Leads</option>
                                                        <option value="instagram_stories">Instagram Stories</option>
                                                    </optgroup>
                                                    <optgroup label="Google">
                                                        <option value="google_adwords">Google Campanha Search</option>
                                                        <option value="google_adwords_gmail">Google Campanha Gmail</option>
                                                        <option value="google_adwords_display">Google Campanha Display</option>
                                                        <option value="google_adwords_videos">Google Campanha Video</option>
                                                        <option value="google_adwords_shopping">Google Shopping</option>
                                                        <option value="google_adwords_apps">Google Campanha universal para apps</option>
                                                    </optgroup>
                                                    <optgroup label="Linkedin">
                                                        <option value="linkedin_leads">LinkedIn Leads</option>
                                                        <option value="linkedin_traffic">LinkedIn Trafego</option>
                                                    </optgroup>
                                                    <optgroup label="Websites">
                                                        <option value="institutional_website_landing_page">Landing Page</option>
                                                        <option value="institutional_website_banner">Banner Site Institucional</option>
                                                    </optgroup>
                                                    <optgroup label="Portais de Imóveis">
                                                        <option value="real_estate_portal_viva_real">Viva Real</option>
                                                        <option value="real_estate_portal_moving">Moving</option>
                                                        <option value="real_estate_portal_zap">Zap</option>
                                                        <option value="real_estate_portal_imovel_k">Imóvel K</option>
                                                        <option value="real_estate_portal_imovel_web">Imóvel Web</option>
                                                        <option value="real_estate_portal_zl_imovel">ZL Imóvel</option>
                                                        <option value="real_estate_portal_zo_imovel">ZO Imóvel</option>
                                                        <option value="real_estate_portal_apto_vc">Apto.vc</option>
                                                    </optgroup>
                                                    <optgroup label="Ferramentas de Marketing">
                                                        <option value="email_marketing">E-mail Marketing</option>
                                                        <option value="sms_marketing">SMS Marketing</option>
                                                    </optgroup>
                                                    <optgroup label="Outros">
                                                        <option value="influencer">Influenciador</option>
                                                        <option value="recommendation">Indicação</option>
                                                    </optgroup>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group">
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                    {this.translate("BUSINESS_LEADS_REGISTER_COMMENT")}:
                                                </label>
                                                <textarea name="lead_source" onChange={e => this.setState({formAddLead: {...this.state.formAddLead, comment: e.target.value}})} className="form-control box-shadow" placeholder={this.translate("BUSINESS_LEADS_REGISTER_COMMENT")} required></textarea>
                                            </div>
                                        </div>
                                        
                                        <div className="box-divider m0 mt20 width100"></div>
                                        <div className="col-sm-12 col-md-12 mt20">
                                            <button type="submit" className="btn btn-info px-5 btn-rounded btn-shadow pull-right">
                                                <i className="fa fa-save"></i> {this.translate("BUSINESS_LEADS_REGISTER_NEW_LEAD")}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>

                </div>

                <Modal show={this.state.modalResponse} onHide={this.closeModalResponse} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-bell"></i> {this.translate("MODAL_STATUS_STATUS")}</h5>
                                <a href="/" onClick={e=>this.closeModalResponse(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                <span className="col-12 text-center py-2 lead">
                                    {this.state.msgModalResponse}
                                </span>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(BusinessLeads);