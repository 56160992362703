import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'

import $ from 'jquery';
import 'jquery-mask-plugin';

import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4'

import {ajaxUrl, ajaxToken} from '../../../Services/Ajax';
import api from '../../../Services/Api';

import Modal from 'react-bootstrap/Modal';

import autocomplete from '../../../Functions/Leads/AutoComplete'

class BusinessPos extends Component {

    constructor(props){
        super(props)
        this.state = {
            modalResponseShow: false,
            msgResponseShow: false,
            tableLoadVisit: null,
            formPosRegister: {
                name: null,
                email: null,
                phone: null,
                unity: null,
                amount: null
            }
        }
        this.handleCloseModalResponse =  this.handleCloseModalResponse.bind(this)
        this.addNewFieldPos = this.addNewFieldPos.bind(this)
        this.reloadDatatable = this.reloadDatatable.bind(this)
        this.handleKeyUp = this.handleKeyUp.bind(this)
        this.onChangeMoney = this.onChangeMoney.bind(this)
        this.translate = this.props.translate
    }

    componentDidMount = async () => {
        let tableLoadVisit = this.state.tableLoadVisit
        const props = this.props
        tableLoadVisit = $('#list_pos').DataTable({
            "searching": true,
            "paging": true,
            "info": true,
            "responsive": true,
            "language": {
                "url": this.translate("DATATABLE_LANGUAGE_URL")
            },
            "ajax": {
                "url": ajaxUrl('/business/' + props.idBusiness + '/pos/table'),
                "type": 'GET',
                "beforeSend": function (request) {
                    request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                }
            }
        });

        this.formatPhoneField();

        console.log(tableLoadVisit)

    }

    onChangeMoney(e){
        e.preventDefault();
        var moneyMaskBehavior = function (){
            return "#.##0,00";
        },
        moneyOptions = {
            reverse: true,
            onKeyPress: function(val, e, field, options){
                this.setState({formPosRegister: {...this.state.formPosRegister, amount: val}})
                field.mask(moneyMaskBehavior.apply({}, arguments), options);
            }.bind(this)
        };
        $('#amount-pos').mask(moneyMaskBehavior, moneyOptions);
    }

    handleCloseModalResponse(e = null){
        if (e) e.preventDefault()
        this.setState({modalResponseShow: false})
    }

    formatPhoneField = () => {
        var SPMaskBehavior = function (val){
            return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
        },
        spOptions = {
            onKeyPress: function(val, e, field, options){
                this.setState({formPosRegister: {...this.state.formPosRegister, phone: val}})
                field.mask(SPMaskBehavior.apply({}, arguments), options);
            }.bind(this)
        };
        //$('#phone-pos').unmask();
        $('#phone-pos').mask(SPMaskBehavior, spOptions);
    }

    resetDataForm = () =>{
        const {formPosRegister} = this.state;
        formPosRegister['name'] = '';
        formPosRegister['email'] = '';
        formPosRegister['phone'] = '';
        this.setState({
            formPosRegister,
        });
        document.getElementById('register-pos-new').reset();
    }

    addNewFieldPos = async (e) => {
        e.preventDefault();
        this.setState({ msgResponseShow: this.translate("BUSINESS_POS_WAIT_STATUS"), modalResponseShow: true });
        const data = this.state.formPosRegister;
        console.log(data);
        try{
            const response = await api.post("/business/" + this.props.idBusiness + "/pos/register", {data});
            if(response.data.success === true){
                this.setState({msgResponseShow: this.translate("BUSINESS_POS_SUCCESS_STATUS")});
                document.getElementById('register-pos-new').reset();
                this.reloadDatatable()
            }else{
                this.setState({msgResponseShow: response.data.msg});
            }
        }catch(err){
            this.setState({msgResponseShow: err});
        }
    }

    reloadDatatable(){
        $('#list_pos').DataTable().ajax.reload();
    }

    handleKeyUp = (e) =>{
        if(e.target.value.length > 5){
            autocomplete(e)
        }
    }

    clickAutoComplete = (e) => {
        if(e.target.classList.contains('autocomplete-dev-click')){
            const data = e.target.dataset
            const {formPosRegister} = this.state;
            formPosRegister['name'] = data.name;
            formPosRegister['email'] = data.email;
            formPosRegister['phone'] = data.phone;
            this.setState({
                formPosRegister,
            });
            this.formatPhoneField();
            $('.autocomplete-dev').remove();
        }
    }

    render(){
        return(
            <React.Fragment>
                <div id="accordion-pos-control">
                    <div className="card mb-3">
                        <div className="card-header btn-cursor-pointer dark" id="headingRegisterPos">
                            <h5 className="mb-0" onClick={e=>this.props.toggleCollapse("#collapseRegisterPos")} aria-expanded="false" aria-controls="collapseRegisterPos">
                                {this.translate("BUSINESS_POS_REGISTER_REGISTER_FUTURE_PURCHASE")}
                                <i className="fa fa-caret-down pull-right"></i>
                            </h5>
                        </div>

                        <div id="collapseRegisterPos" className="collapse dark" aria-labelledby="headingRegisterPos" data-parent="#accordion-pos-control">
                            <div className="card-body">
                                <form onSubmit={this.addNewFieldPos} id="register-pos-new">
                                    <div className="row p-3 white box-shadow pb40">
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group" onClick={this.clickAutoComplete}>
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                    {this.translate("BUSINESS_POS_REGISTER_NAME")}:
                                                </label>
                                                <input type="text" name="name" id="name-pos" autoComplete="off" onKeyUp={this.handleKeyUp} onPaste={this.handleKeyUp} onChange={e => this.setState({ formPosRegister: {...this.state.formPosRegister, name:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("BUSINESS_POS_REGISTER_NAME")} defaultValue={this.state.formPosRegister.name} required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group" onClick={this.clickAutoComplete}>
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                    {this.translate("BUSINESS_POS_REGISTER_EMAIL")}:
                                                </label>
                                                <input type="email" name="email" id="email-pos" autoComplete="off" onKeyUp={this.handleKeyUp} onPaste={this.handleKeyUp} onChange={e => this.setState({ formPosRegister: {...this.state.formPosRegister, email:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("BUSINESS_POS_REGISTER_EMAIL")} defaultValue={this.state.formPosRegister.email} required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group" onClick={this.clickAutoComplete}>
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                    {this.translate("BUSINESS_POS_REGISTER_PHONE")}:
                                                </label>
                                                <input type="text" name="phone" id="phone-pos" autoComplete="off" onKeyUp={this.handleKeyUp} onPaste={this.handleKeyUp} className="form-control box-shadow" placeholder={this.translate("BUSINESS_POS_REGISTER_PHONE")} defaultValue={this.state.formPosRegister.phone} required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                    {this.translate("BUSINESS_POS_REGISTER_UNITY")}:
                                                </label>
                                                <input type="text" id="unity-pos" onChange={e => this.setState({ formPosRegister: {...this.state.formPosRegister, unity:  e.target.value}})} defaultValue={this.state.formPosRegister.unity} className="form-control box-shadow" placeholder={this.translate("BUSINESS_POS_REGISTER_UNITY")}/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                                    {this.translate("BUSINESS_POS_REGISTER_VALUE")}:
                                                </label>
                                                <input type="text" id="amount-pos" onChange={this.onChangeMoney} defaultValue={this.state.formPosRegister.amount} className="form-control box-shadow" placeholder={this.translate("BUSINESS_POS_REGISTER_VALUE")}/>
                                            </div>
                                        </div>
                                        <div className="box-divider m0 mt20 width100"></div>
                                        <div className="col-sm-12 col-md-12 mt20">
                                            <button type="submit" className="btn btn-info px-5 btn-rounded btn-shadow pull-right">
                                                <i className="fa fa-save"></i> {this.translate("BUSINESS_POS_REGISTER_REGISTER_FUTURE_PURCHASE")}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                    <div className="card">
                        <div className="card-header btn-cursor-pointer dark" id="headingListPos" onClick={e=>this.props.toggleCollapse("#collapseListPos")} aria-expanded="true" aria-controls="collapseListPos">
                            <h5 className="mb-0">
                                {this.translate("BUSINESS_POS_LIST_LIST_OF_FUTURE_PURCHASES")}
                                <i className="fa fa-caret-down pull-right"></i>
                            </h5>
                        </div>

                        <div id="collapseListPos" className="collapse dark show" aria-labelledby="headingListPos" data-parent="#accordion-pos-control">
                            <div className="card-body">
                                <div className="bootstrap-table">
                                    <div className="fixed-table-container">
                                        <div className="fixed-table-body">
                                            <table id="list_pos" className="table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                                                <thead>
                                                    <tr className="text-left">
                                                        <th>{this.translate("BUSINESS_POS_LIST_NAME")}</th>
                                                        <th>{this.translate("BUSINESS_POS_LIST_EMAIL")}</th>
                                                        <th>{this.translate("BUSINESS_POS_LIST_PHONE")}</th>
                                                        <th>{this.translate("BUSINESS_POS_LIST_UNITY")}</th>
                                                        <th>{this.translate("BUSINESS_POS_LIST_VALUE")}</th>
                                                        <th>{this.translate("BUSINESS_POS_LIST_REGISTER_DATE")}</th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <Modal show={this.state.modalResponseShow} onHide={this.handleCloseModalResponse} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-bell"></i> {this.translate("MODAL_STATUS_STATUS")}</h5>
                                <a href="/" onClick={e=>this.handleCloseModalResponse(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                <span className="col-12 text-center py-2 lead">
                                    {this.state.msgResponseShow}
                                </span>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(BusinessPos);