import React, { Component, Fragment} from 'react';

import $ from 'jquery';

import api from '../../../Services/Api';

class CheckSale extends Component {
    _isMounted = false;

    constructor(props){
        super(props)
        this.state = {
            idAction: this.props.values.idAction,
            loaded: false,
            alertResponse: null,
            formValuesAction: {
                id: this.props.values.idAction
            }
        }
        this.formSubmitSave = this.formSubmitSave.bind(this)
        this.translate = this.props.translate
    }

    componentDidMount(){
        api.get('/journey/action/' + this.props.values.idAction).then(async response => {
            this.setState({formValuesAction: response.data, loaded: true})
            console.log(response);
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async formSubmitSave(e){
        e.preventDefault()
        $('#button_submit_form_action').prop('disabled', true).html(`<i className="fa fa-spinner"></i> ${this.translate('BUTTON_WAIT')}...`)
        this.setState({alertResponse: null})
        const data = this.state.formValuesAction
        try{
            const response = await api.post("/journey/action/" + this.props.values.idAction, data);
            if(response.data.success){
                this.setState({alertResponse: true})
                $('#button_submit_form_action').prop('disabled', false).html(`<i className="fa fa-save"></i> ${this.translate('BUTTON_REGISTER')}`)
            }else{
                this.setState({alertResponse: false})
            }
        }catch(err){
            console.log(err)
        }
    }

    render() {
        return (
            <Fragment>
                {
                    (this.state.alertResponse === false)?(
                        <div className="alert alert-danger alert-dismissible" role="alert">
                            {this.translate('CHECK_SALE_ERROR_REGISTERING_CONDITION')}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    ):(
                        <React.Fragment>
                            {
                                (this.state.alertResponse)?(
                                    <div className="alert alert-success alert-dismissible" role="alert">
                                        {this.translate('CHECK_SALE_SUCCESS_REGISTERING_CONDITION')}
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                ):(false)
                            }
                        </React.Fragment>
                    )
                }
                {
                    (this.state.loaded)?(
                        <form onSubmit={this.formSubmitSave}>
                            <div className="form-group">
                                {this.translate('CHECK_SALE_NO_NEED_TO_CONFIG')}
                            </div>
                            <div className="form-group">
                                <button type="submit" id="button_submit_form_action" className="btn btn-cursor-pointer btn-success float-right" style={{width: '100%'}}>
                                    <i className="fa fa-save"></i> {this.translate('BUTTON_REGISTER')}
                                </button>
                            </div>
                        </form>
                    ):(
                        <div>
                            {this.translate('CHECK_SALE_LOADING_CONFIG_FORM')}
                        </div>
                    )
                }
            </Fragment>
        );
    }
}

export default CheckSale;