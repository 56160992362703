import React, { Component } from 'react'
import {withRouter} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'

import MenuMain from '../../Structure/MenuMain'
import HeaderMain from '../../Structure/HeaderMain'
import FooterMain from '../../Structure/FooterMain'
import CardBuilder from "./Components/CardBuilder"
import api from '../../Services/Api'

class ChooseDashboardPerformance extends Component{
    constructor(props){
        super(props)
        this.state = {
            dashboards: [],

            isConfirmModalVisible: false,
            confirmModalMessage: "",

            isModalResponseVisible: false,
            msgResponseShow: ""
        }
        this.translate = this.props.translate
        document.title = this.translate("DASHBOARD_PERFORMANCE_DASHBOARD_PERFORMANCE")
        this.index = 0
        this.confirmModalCallback = null
    }

    getIndex = () => ++this.index

    handleCloseConfirmModal = (e = null) => {
        if (e) e.preventDefault()
        this.setState({isConfirmModalVisible: false})
    }

    handleCloseModalResponse = (e = null) => {
        if (e) e.preventDefault()
        this.setState({isModalResponseVisible: false})
    }

    loadDashboards = async () => {
        try {
            //let response = await api.get("/attendances/dashboard_performance")
            let response = {
                data: {
                    success: true,
                    dashboards: [
                      {
                        "id": "adadassad123",
                        "type": "card_dashboard_1",
                        "values": {
                            "title": "Dashboard 1",
                            "short_description": "Esse é um dashboard de teste",
                            "color": "#6f42c1",
                        }
                        
                      },
                      {
                        "id": "adadassad1asd23",
                        "type": "card_dashboard_1",
                        "values": {
                            "title": "Dashboard 2",
                            "short_description": "Essa é uma dashboard de teste de número 2",
                            "color": "#ffedab",
                        }
                      },
                    ]
                }
            }
            if(response && response.data.success && response.data.dashboards && response.data.dashboards.length){
                this.setState({dashboards: response.data.dashboards})
            }
        } catch(error) {
            console.log(error)
        }
    }

    handleViewDashboard = (dashboardId) => {
        this.props.history.push("dashboard_performance#" + dashboardId)
    }

    handleEditDashboard = dashboardId => {
        this.props.history.push("update_dashboard_performance#" + dashboardId)
    }

    handleAddDashboard = () => {
        let dashboards = this.state.dashboards

        dashboards.push({
            "id": this.getIndex(),
            "type": "card_dashboard_1",
            "isEditing": true,
            "isLoading": false,
            "values": {
                "title": "",
                "short_description": "",
                "color": "#17a2b8",
            }
        })

        this.setState({dashboards})
    }

    handleDashboardValueChanges = (dashboardId, valueType, newValue) => {

        let newDashboards = this.state.dashboards
        newDashboards.forEach(dashboard=>{
            if(dashboard.id === dashboardId){
                console.log("encontrou")
                dashboard["values"][valueType] = newValue
            } 
        })
        this.setState({dashboards: newDashboards})

    }

    handleRemoveDashboard = (dashboardId) => {
        this.confirmModalCallback = ()=> {
            this.removeDashboard(dashboardId)
        }
        this.setState({isConfirmModalVisible: true, confirmModalMessage: this.translate("CHOOSE_DASHBOARD_PERFORMANCE_ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_DASHBOARD")})
    }

    removeDashboard = (dashboardId) => {
        this.confirmModalCallback = null

        let newDashboards = this.state.dashboards
        newDashboards = newDashboards.filter(dashboard=> dashboard.id !== dashboardId)

        this.setState({dashboards: newDashboards, confirmModalMessage: "", isConfirmModalVisible: false})
    }

    handleConfirmDashboardChanges = async (dashboardId) => {
        let dashboard = this.getDashboardAndSetItIsLoading(dashboardId)

        if(!this.checkCanSubmit(dashboard)) return
        
        let newDashboardId = await this.postNewDashboard(dashboard)
        
        this.updateDashboardAfterBeingCreated(dashboardId, newDashboardId)

    }

    getDashboardAndSetItIsLoading = (dashboardId) => {
        let newDashboards = this.state.dashboards
        let chosenDashboard
        newDashboards.forEach(dashboard=>{
            if(dashboard.id === dashboardId){
                dashboard["isLoading"] = true
                chosenDashboard = dashboard
            } 
        })
        this.setState({dashboards: newDashboards})
        return chosenDashboard
    }

    checkCanSubmit = dashboard => {
        let check = true
        let {title, short_description, color} = dashboard.values
        if(!title) check = false
        if(!short_description) check = false
        if(!color) check = false
        console.log("check", check)
        return check
    }

    postNewDashboard = async (dashboard) => {
        let newDashboardId = null
        try {
            //let response = await api.post("/dashboards", {dashboard: dashboard})
            let response = {data: {
                success: true,
                dashboard_id: "asdas12312dvfhbfgnyt"
            }}
            if(response && response.data.success && response.data.dashboard_id){
                newDashboardId = response.data.dashboard_id
            }
        } catch (error) {
            console.log(error)
        }
        return newDashboardId
    }

    updateDashboardAfterBeingCreated = (dashboardId, newDashboardId) => {
        let newDashboards = this.state.dashboards
        newDashboards.forEach(dashboard=>{
            if(dashboard.id === dashboardId){
                dashboard["isLoading"] = false
                if(newDashboardId) {
                    dashboard["isEditing"] = false
                    dashboard["id"] = newDashboardId
                } 
            } 
        })
        
        if(!newDashboardId){
            this.setState({dashboards: newDashboards, isModalResponseVisible: true, msgResponseShow: this.translate("CHOOSE_DASHBOARD_PERFORMANCE_AN_ERROR_OCURRED_WHILE_TRYING_TO_CREATE_THE_DASHBOARD")})
        } else {
            this.setState({dashboards: newDashboards}, ()=>{
                this.props.history.replace("/update_dashboard_performance#" + newDashboardId)
            })
        }
    }

    componentDidMount = () => {
        this.loadDashboards()
    }


    render() {
        return (
            <React.Fragment>
                <MenuMain />
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    <HeaderMain title={this.translate("CHOOSE_DASHBOARD_PERFORMANCE_CHOOSE_DASHBOARD_PERFORMANCE")} onChange={this.handleChange}/>
                    <div className="content-main" id="content-main">
                        <div className="padding">
                            <div className="row pt20">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12 col-sm-6">
                                            <h3>{this.translate("CHOOSE_DASHBOARD_PERFORMANCE_CHOOSE_DASHBOARD_PERFORMANCE")}</h3>
                                        </div>
                                    </div>
                                    <hr />  
                                </div>
                                <div className="col-12 mx-1">
                                    <div className="row">
                                        <React.Fragment>
                                            {this.state.dashboards && this.state.dashboards.length > 0 ?
                                                this.state.dashboards.map((card, index) =>
                                                    <CardBuilder 
                                                        {...card} 
                                                        key={`dash${card.id}${index}`}
                                                        handleViewDashboard={this.handleViewDashboard}
                                                        handleEditDashboard={this.handleEditDashboard}
                                                        handleDashboardValueChanges={this.handleDashboardValueChanges}
                                                        handleConfirmDashboardChanges={this.handleConfirmDashboardChanges}
                                                        handleRemoveDashboard={this.handleRemoveDashboard}
                                                        translate={this.translate} 
                                                    />
                                                )
                                            :
                                                <div className="col-12">
                                                    <p>{this.translate("CHOOSE_DASHBOARD_PERFORMANCE_THERE_ARENT_ANY_DASHBOARDS_REGISTERED")}</p>
                                                </div>
                                            }
                                            <CardBuilder type="add_dashboard" handleAddDashboard={this.handleAddDashboard} translate={this.translate} />
                                        </React.Fragment>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterMain />
                </div>
                <Modal show={this.state.isConfirmModalVisible} onHide={this.handleCloseConfirmModal} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-check"></i> {this.translate("MODAL_CONFIRM_TITLE")}</h5>
                                <a href="/" onClick={e=>this.handleCloseConfirmModal(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                <div className="col-12">
                                    <h3 className="py-2 text-center">{this.state.confirmModalMessage}</h3>
                                </div>
                                <div className="col-4 mx-auto">
                                    <button className="btn btn-danger btn-cursor-pointer" style={{ width: '100%' }} onClick={e=>this.handleCloseConfirmModal(e)}>
                                        {this.translate("MODAL_CONFIRM_NO")}
                                    </button>
                                </div>
                                <div className="col-4 mx-auto">
                                    <button className="btn btn-success btn-cursor-pointer" style={{ width: '100%' }} onClick={(e)=>this.confirmModalCallback()}>
                                        {this.translate("MODAL_CONFIRM_YES")}
                                    </button>

                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.isModalResponseVisible} onHide={this.handleCloseModalResponse} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-bell"></i> {this.translate("MODAL_STATUS_STATUS")}</h5>
                                <a href="/" onClick={e=>this.handleCloseModalResponse(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                <span className="col-12 text-center py-2 lead">
                                    {this.state.msgResponseShow}
                                </span>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(ChooseDashboardPerformance)