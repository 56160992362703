import React, { Component } from 'react';

class ActiveOriginAndListAll extends Component {
    render() {
        return (
            <div className="col-sm-12 col-md-4">
                <h6 className="text-center dark f14 p-1 mb10">Origens ativas:</h6>
                <React.Fragment>
                    {
                        this.props.fields.active_origins.slice(0,3).map((data, i) => 
                            <div key={i} className="py-2 b-b">
                                <span className="_600 d-block m0">
                                    <a href="/">
                                        {data.origin_name}
                                    </a>
                                </span>
                                <small className="text-primary opacity50">
                                    Origem: {data.origin_name}
                                </small>
                            </div>
                        )
                    }
                </React.Fragment>
                <div className="p-2">
                    <a href="/leads_midia#tab_3" className="btn btn-sm btn-block white">Ver todas</a>
                </div>
            </div>
        );
    }
}

export default ActiveOriginAndListAll;