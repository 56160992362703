import React, { Component } from 'react'

export default class Status extends Component {
    render = () => {
        return this.props.status ?
            <div className="row">
                <div className="col-12">
                    <div className={`col-12 p-1 my-1 text-light ${this.props.status.success ? "bg-success" : "bg-warning"}`}>
                        <span className="text-md">{this.props.status.message}</span>
                    </div>
                </div>
            </div>
            : false
    }
}