import {getToken} from '../Auth/AuthConfig';
import {urlBase, versionBase} from './Config';

export const ajaxUrl = (path) => {
    const url = urlBase + versionBase + path;
    return url;
}

export const ajaxToken = () => {
    return getToken();
}