import React, { Component } from 'react';

import $ from 'jquery'

import api from '../../../../Services/Api';

class Smartlook extends Component {

    constructor(props){
        super(props)
        this.state = {
            listProjects: [],
            formSaveSmartlook: {
                project_id: null
            }
        }

        this.formSubmitSmartlook = this.formSubmitSmartlook.bind(this)
        this.translate = this.props.translate
    }

    async formSubmitSmartlook(e){
        e.preventDefault();
        $('#form_add_smartlook_button').prop('disabled', true).html(`<i className="fa fa-spinner"></i> ${this.translate('ICON_WAIT')}`)
        const data = this.state.formSaveSmartlook;
        try{
            const response = await api.post("/integration/smartlook/projects/update", data);
            if(response.data.success === true){
                $('#form_add_smartlook_button').prop('disabled', false).html(`<i className="fa fa-save"></i> ${this.translate('SMARTLOOK_REGISTER_SMARTLOOK_INTEGRATION')}`)
            }else{
                alert(response.data.msg);
            }
        }catch(err){
            alert(err);
        }
    }

    loadProjects(){
        api.get("/integration/smartlook/projects/select").then(async response => {
            if(response.data.success){
                this.setState({
                    listProjects: response.data.projects
                })
            }
        })
    }

    componentDidMount(){
        this.loadProjects();
    }

    render() {
        return (
            <React.Fragment>
                <div className="d-flex flex-column h-100">
                    <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                        <h5 className="h5 m-0 text-center width100 line-height-38px">
                            <i className="fa fa-plus-square-o"></i> {this.translate('SMARTLOOK_CONFIGURE_SMARTLOOK_INTEGRATION')}
                        </h5>
                        <button onClick={this.props.handleCloseSmartlook} className="btn btn-transparent ml-auto">
                            <span className="text-lg l-h-1x">×</span>
                        </button>
                    </div>
                    
                    <div className="row p-5">

                        <div className="col-sm-12 col-md-8 offset-md-2 col-lg-8 offset-lg-2">
                            <form onSubmit={this.formSubmitSmartlook} id="form_add_smartlook">
                                <div>
                                    <div className="col-sm-12 col-md-12 mb20">
                                        <label>{this.translate('SMARTLOOK_CHOOSE_THE_PROJECT_YOU_WANT_TO_USE')}</label>
                                        <select onChange={e => this.setState({ formSaveSmartlook: {...this.state.formSaveSmartlook, project_id:  e.target.value}})} defaultValue={this.state.formSaveSmartlook.project_id} className="form-control box-shadow cool-select" required="required">
                                            <option value="">{this.translate('SMARTLOOK_SELECT_THE_PROJECT')}</option>
                                            {
                                                this.state.listProjects.map((data, i) => (
                                                    <option key={i} value={data.id}>{data.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <div className="col-sm-12 col-md-12 mb20">
                                        <button id="form_add_smartlook_button" type="submit" className="pull-right btn btn-success px-5 btn-rounded btn-shadow">
                                            <i className="fa fa-save"></i> {this.translate('SMARTLOOK_REGISTER_SMARTLOOK_INTEGRATION')}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>

                </div>
            </React.Fragment>
        );
    }
}

export default Smartlook;