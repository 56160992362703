import React, { Component, Fragment } from 'react';
import {withRouter} from 'react-router-dom'

import Modal from 'react-bootstrap/Modal';

import api from '../../../Services/Api';

import $ from "jquery"

class IntegrationChatbots extends Component {

    constructor(props){
        super(props)
        this.state = {
            showUrlWebhookLeadster: false,
            urlWebhookLeadster: null,
        }
        this.handleShowUrlWebhookLeadster = this.handleShowUrlWebhookLeadster.bind(this)
        this.handleCloseCodigo = this.handleCloseCodigo.bind(this)

        this.translate = this.props.translate
    }

    handleShowUrlWebhookLeadster(){
        this.setState({showUrlWebhookLeadster: true})
    }

    handleCloseCodigo(){
        this.setState({showUrlWebhookLeadster: false})
    }

    toggleCollapse = (selector) => $(selector).collapse("toggle")

    componentDidMount(){
        api.get('/company/bussines/webhook/leadster').then(async response => {
            this.setState({urlWebhookLeadster: response.data.url});
        })
    }

    render() {
        return (
            <Fragment>
                <div className="row pb40">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <h4 className="text-center mt20 mb20">
                            <i className="fa fa-cubes"></i> {this.translate('INTEGRATION_CHATBOT_TITLE')}
                        </h4>
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <div className="text-center box-shadow border-radius blue ">
                            <div className="p-4 ">
                                <p className="f64 m0 text-white">
                                    <i className="fa fa-list-alt"></i>
                                </p>
                                <h5 className="text-md f24 d-block f600 fwhite mb20">
                                    {this.translate('INTEGRATION_CHATBOT_WEBHOOK_LEADSTER')}
                                </h5>
                                <button onClick={this.handleShowUrlWebhookLeadster} className="btn btn-info info btn-rounded text-white btn-shadow">
                                    <i className="fa fa-plus"></i> {this.translate('INTEGRATION_CHATBOT_VISUALIZE_URL')}
                                </button>
                                <br/><br/>
                            </div>
                        </div>
                    </div>
                    
                </div>

                <Modal show={this.state.showUrlWebhookLeadster} onHide={this.handleCloseCodigo} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100 line-height-38px">
                                    <i className="fa fa-clone"></i> {this.translate('INTEGRATION_CHATBOT_WEBHOOK_LEADSTER')}
                                </h5>
                                <button onClick={this.handleCloseCodigo} className="btn btn-transparent ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </button>
                            </div>
                            <div className="p-3 b-b d-flex no-shrink text-center position10">
                                <textarea className="form-control" rows="1" defaultValue={this.state.urlWebhookLeadster} disabled></textarea>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            </Fragment>
        );
    }
}

export default withRouter(IntegrationChatbots);