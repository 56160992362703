import React, { Component } from 'react';

import $ from 'jquery';

import api from '../../../Services/Api';

import './Styles/EmailMarketingPreview.css'

class EmailMarketingPreview extends Component {

    constructor(props){
        super(props)
        this.state = {
            body: null
        }
        this.loadEmail = this.loadEmail.bind(this)
    }

    async loadEmail(){
        const id = document.location.hash.replace('#','');
        try{
            const response = await api.get("/email_marketing/select/" + id);
            if(response.data.success === true){
                this.setState({
                    body: response.data.email_data.body
                })
                $('head').html('')
                $("body").removeAttr('class');
                $("body").removeAttr('ui-class');
                $('body').html(response.data.email_data.body)
            }
        }catch(err){
            console.log(err)
        }
    }

    componentDidMount(){
        this.loadEmail();
    }

    render(){
        return (
            <React.Fragment>
                <div id="email" className="vw-100"></div>
            </React.Fragment>
        );
    }
}

export default EmailMarketingPreview;