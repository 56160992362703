import React, { Component } from 'react'

import Dashboard1 from './Cards/Dashboard1'
import Stage from './Cards/Stage'
import AddStage from './Cards/AddStage'
import AddCard from './Cards/AddCard'
import AddDashboard from './Cards/AddDashboard'
import CardNotFound from './Cards/CardNotFound'

import Total from './Cards/Total'
import TotalMedium from './Cards/TotalMedium'
import TotalLarge from './Cards/TotalLarge'

import Percentage from './Cards/Percentage'
import PercentageMedium from './Cards/PercentageMedium'
import PercentageLarge from './Cards/PercentageLarge'

import Money from './Cards/Money'
import MoneyMedium from './Cards/MoneyMedium'
import MoneyLarge from './Cards/MoneyLarge'

import Ranking from './Cards/Ranking'
import RankingMedium from './Cards/RankingMedium'
import RankingLarge from './Cards/RankingLarge'

import BarChart from './Cards/BarChart'
import BarChartMedium from './Cards/BarChartMedium'
import BarChartLarge from './Cards/BarChartLarge'

import LineChart from './Cards/LineChart'
import LineChartMedium from './Cards/LineChartMedium'
import LineChartLarge from './Cards/LineChartLarge'

import PieChart from './Cards/PieChart'
import PieChartMedium from './Cards/PieChartMedium'
import PieChartLarge from './Cards/PieChartLarge'

const cardSelector = {
    card_dashboard_1: Dashboard1,
    stage: Stage,
    add_stage: AddStage,
    add_card: AddCard,
    add_dashboard: AddDashboard,

    card_small_total: Total,
    card_medium_total: TotalMedium,
    card_large_total: TotalLarge,

    card_small_percentage: Percentage,
    card_medium_percentage: PercentageMedium,
    card_large_percentage: PercentageLarge,

    card_small_money: Money,
    card_medium_money: MoneyMedium,
    card_large_money: MoneyLarge,
    
    card_small_ranking: Ranking,
    card_medium_ranking: RankingMedium,
    card_large_ranking: RankingLarge,
    
    card_small_bar_chart: BarChart,
    card_medium_bar_chart: BarChartMedium,
    card_large_bar_chart: BarChartLarge,
    
    card_small_line_chart: LineChart,
    card_medium_line_chart: LineChartMedium,
    card_large_line_chart: LineChartLarge,
    
    card_small_pie_chart: PieChart,
    card_medium_pie_chart: PieChartMedium,
    card_large_pie_chart: PieChartLarge,  
    
    
}

export default class CardBuilder extends Component{

    renderCardBuilder = () => {
        let CardElement = cardSelector[this.props.type]
        if(!CardElement) CardElement = CardNotFound

        return (
            <CardElement {...this.props} />
        )
    }
  
    render = () => this.renderCardBuilder()
    
}
