import React, { Component } from 'react';

import PeriodBoxTool from '../../Structure/PeriodBoxTool';

import StageSalesStartedSections from './FunnelActions/StageSalesStartedSections'
import StageSalesPpvSections from './FunnelActions/StageSalesPpvSections'
import StageSalesA2cSections from './FunnelActions/StageSalesA2cSections'
import StageSalesCheckout from './FunnelActions/StageSalesCheckout'
import StageSalesBuy from './FunnelActions/StageSalesBuy'

import Modal from 'react-bootstrap/Modal';

export class EcommerceFunilSales extends Component {

    constructor(props){
        super(props)
        this.state = {
            modalShow: false,
            modalComponents: null
        }
        this.handleClickFunnelStage = this.handleClickFunnelStage.bind(this)
        this.handleChangeModal = this.handleChangeModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
    }

    handleChange = (periodName, values) => {
        this.props.fields.leads_value = values.leads_value
        this.props.fields.visit_value = values.visit_value
        this.props.fields.bid_value = values.bid_value
        this.props.fields.deal_value = values.deal_value
        this.props.fields.buy_value = values.buy_value
        this.props.fields.after_sales_value = values.after_sales_value

        this.props.fields.por_relative_visit_per_leads = (values.visit_value !== "0" && values.leads_value !== "0")?((values.visit_value.replace(/\./gi, "") * 100) / values.leads_value.replace(/\./gi, "")).toFixed(1): 0
        this.props.fields.por_relative_bid_per_visit = (values.bid_value !== "0" && values.visit_value !== "0")?((values.bid_value.replace(/\./gi, "") * 100) / values.visit_value.replace(/\./gi, "")).toFixed(1): 0
        this.props.fields.por_relative_deal_per_bid = (values.deal_value !== "0" && values.bid_value !== "0")?((values.deal_value.replace(/\./gi, "") * 100) / values.bid_value.replace(/\./gi, "")).toFixed(1): 0
        this.props.fields.por_relative_buy_per_deal = (values.buy_value !== "0" && values.deal_value !== "0")?((values.buy_value.replace(/\./gi, "") * 100) / values.deal_value.replace(/\./gi, "")).toFixed(1): 0
        this.props.fields.por_relative_after_sales_per_buy = (values.after_sales_value !== "0" && values.buy_value !== "0")?((values.after_sales_value.replace(/\./gi, "") * 100) / values.buy_value.replace(/\./gi, "")).toFixed(1): 0

   
        this.forceUpdate();
    }

    handleChangeModal = (action) => {
        if(action === 'close'){
            this.handleCloseModal();
        }
    }

    handleClickFunnelStage = (e) => {
        e.preventDefault();
        var type = e.currentTarget.dataset.type;
        var checkComponent = false
        switch(type){
            case 'sales_started':
                this.setState({modalComponents: <StageSalesStartedSections onChange={this.handleChangeModal}/>})
                checkComponent = true;
                break
            case 'sales_ppv':
                this.setState({modalComponents: <StageSalesPpvSections onChange={this.handleChangeModal}/>})
                checkComponent = true;
                break
            case 'sales_a2c':
                this.setState({modalComponents: <StageSalesA2cSections onChange={this.handleChangeModal}/>})
                checkComponent = true;
                break
            case 'sales_checkout':
                this.setState({modalComponents: <StageSalesCheckout onChange={this.handleChangeModal}/>})
                checkComponent = true;
                break
            case 'sales_buy':
                this.setState({modalComponents: <StageSalesBuy onChange={this.handleChangeModal}/>})
                checkComponent = true;
                break
            default:
                checkComponent = false;
                break
        }
        if(checkComponent === true){
            this.setState({modalShow: true})
        }
    }

    handleCloseModal(){
        this.setState({modalShow: false})
    }
    
    componentDidMount(){

        this.props.fields.por_relative_visit_per_leads = (this.props.fields.visit_value !== "0" && this.props.fields.leads_value !== "0")?((this.props.fields.visit_value.replace(/\./gi, "") * 100) / this.props.fields.leads_value.replace(/\./gi, "")).toFixed(1): 0
        this.props.fields.por_relative_bid_per_visit = (this.props.fields.bid_value !== "0" && this.props.fields.visit_value !== "0")?((this.props.fields.bid_value.replace(/\./gi, "") * 100) / this.props.fields.visit_value.replace(/\./gi, "")).toFixed(1): 0
        this.props.fields.por_relative_deal_per_bid = (this.props.fields.deal_value !== "0" && this.props.fields.bid_value !== "0")?((this.props.fields.deal_value.replace(/\./gi, "") * 100) / this.props.fields.bid_value.replace(/\./gi, "")).toFixed(1): 0
        this.props.fields.por_relative_buy_per_deal = (this.props.fields.buy_value !== "0" && this.props.fields.deal_value !== "0")?((this.props.fields.buy_value.replace(/\./gi, "") * 100) / this.props.fields.deal_value.replace(/\./gi, "")).toFixed(1): 0
        this.props.fields.por_relative_after_sales_per_buy = (this.props.fields.after_sales_value !== "0" && this.props.fields.buy_value !== "0")?((this.props.fields.after_sales_value.replace(/\./gi, "") * 100) / this.props.fields.buy_value.replace(/\./gi, "")).toFixed(1): 0

        this.forceUpdate();
    }

    render() {
        return (
            <div className="col-sm-6" id="funnel02">
                <div className="p-lg-3">
                    <div className="d-flex">
                        <p className="uppercase text-muted f10">Funil de Vendas</p>
                    </div>
                    <div className="funnel" data-type="leads">
                        <div onClick={this.handleClickFunnelStage} className="layer1 funilLeadsSpan" data-type="sales_started" >
                            <h5 className="fwhite">
                                Seções Iniciadas
                                <span className="f900 pull-right">
                                    <React.Fragment>
                                        {this.props.fields.leads_value}
                                    </React.Fragment>
                                </span>
                            </h5>
                        </div>
                        <div onClick={this.handleClickFunnelStage} className="layer2" data-type="sales_ppv" >
                            <h5 className="fwhite">
                                Seções PPV (Product Page View)
                                <span className="f900 pull-right">
                                    <React.Fragment>
                                        {this.props.fields.visit_value}
                                    </React.Fragment>
                                    <span className="ls f600"> 
                                        <i className="fa fa-circle text-blue m0"></i>&nbsp;&nbsp;
                                        <React.Fragment>
                                            {this.props.fields.por_relative_visit_per_leads}%
                                        </React.Fragment>
                                    </span>
                                </span>
                            </h5>
                        </div>
                        <div onClick={this.handleClickFunnelStage} className="layer3" data-type="sales_a2c" >
                            <h5 className="fwhite">
                                Seções A2C (Add-to-Cart)
                                <span className="f900 pull-right">
                                    <React.Fragment>
                                        {this.props.fields.bid_value}
                                    </React.Fragment>
                                    <span className="ls f600"> 
                                        <i className="fa fa-circle text-blue m0"></i>&nbsp;&nbsp;
                                        <React.Fragment>
                                            {this.props.fields.por_relative_bid_per_visit}%
                                        </React.Fragment>
                                    </span>
                                </span>
                            </h5>
                        </div>
                        <div onClick={this.handleClickFunnelStage} className="layer4" data-type="sales_checkout" >
                            <h5 className="fwhite">
                                Checkout  
                                <span className="f900 pull-right">
                                    <React.Fragment>
                                        {this.props.fields.deal_value}
                                    </React.Fragment>
                                    <span className="ls f600"> 
                                        <i className="fa fa-circle text-blue m0"></i>&nbsp;&nbsp;
                                        <React.Fragment>
                                            {this.props.fields.por_relative_deal_per_bid}%
                                        </React.Fragment>
                                    </span>
                                </span>
                            </h5>
                        </div>
                        <div onClick={this.handleClickFunnelStage} className="layer5" data-type="sales_buy" >
                            <h5 className="fwhite">
                                Compras 
                                <span className="f900 pull-right">
                                    <React.Fragment>
                                        {this.props.fields.buy_value}
                                    </React.Fragment>
                                    <span className="ls f600"> 
                                        <i className="fa fa-circle text-blue m0"></i>&nbsp;&nbsp;
                                        <React.Fragment>
                                            {this.props.fields.por_relative_buy_per_deal}%
                                        </React.Fragment>
                                    </span>
                                </span>
                            </h5>
                        </div>
                    </div>
                    <p className="small legend lh"><span className="f800">Dica: </span>Clique sobre cada etapa para mais informações.</p>
                </div>
                <PeriodBoxTool onChange={this.handleChange} type={this.props.type}/>
                <Modal show={this.state.modalShow} onHide={this.handleCloseModal} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t modal-all-generic">
                    <Modal.Body>
                        {this.state.modalComponents}
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default EcommerceFunilSales;