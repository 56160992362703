import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'

import $ from 'jquery';
import 'jquery-mask-plugin';

import api from '../../../../Services/Api';

import Modal from 'react-bootstrap/Modal';

class BrokersRegister extends Component {

    constructor(props){
        super(props);
        this.state = {
            isFormAddBrokerVisible: false,
            brokersSelectList: [],
            modalResponseShow: false,
            msgResponseShow: false,
            formRegister: {
                name: null,
                email: null,
                ddi: null,
                phone: null
            },
            formSelectBroker: {
                selected_imob: ""
            }
        }
        this.handleRegisterBroker = this.handleRegisterBroker.bind(this);
        this.handleCloseModalResponse = this.handleCloseModalResponse.bind(this);
        this.reloadDatatable = this.reloadDatatable.bind(this)
        this.translate = this.props.translate
    }

    componentDidMount(){

        this.loadBrokersSelectList()
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.isFormAddBrokerVisible !== this.state.isFormAddBrokerVisible && this.state.isFormAddBrokerVisible){

            var SPMaskBehavior = function (val){
                var ddi = $('#register-ddi').val();
                if(ddi === "+55"){
                    return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
                }else{
                    return val.replace(/\D/g, '').length === 11 ? '(000) 000-0000' : '(000) 000-0000';
                }
            },
            spOptions = {
                onComplete: function(val){
                    var phone = $('#register-phone').val();
                    this.setState({formRegister: {...this.state.formRegister, phone: phone}})
                }.bind(this),
                onKeyPress: function(val, e, field, options) {
                    field.mask(SPMaskBehavior.apply({}, arguments), options);
                }
            };
    
            $('#register-ddi').mask('+099');
            $('#register-phone').mask(SPMaskBehavior, spOptions);
        }
    }

    reloadDatatable(){
        $('#list_broker').DataTable().ajax.reload();
    }

    handleChooseBroker = async (e) => {
        e.preventDefault()
        this.setState({ msgResponseShow: this.translate("BROKERS_REGISTER_REGISTER_STATUS_WAIT"), modalResponseShow: true });
        const data = this.state.formSelectBroker
        try{
            const response = await api.post("/broker/register", data);
            if(response.data.success === true){
                this.setState({msgResponseShow: this.translate("BROKERS_REGISTER_REGISTER_STATUS_SUCCESS"), formSelectBroker: {selected_imob: ""}});
            }else{
                this.setState({msgResponseShow: response.data.msg});
            }
        }catch(err){
            this.setState({msgResponseShow: err});
        }
    }

    handleRegisterBroker = async (e) => {
        e.preventDefault();
        this.setState({ msgResponseShow: this.translate("BROKERS_REGISTER_REGISTER_STATUS_WAIT"), modalResponseShow: true });
        const data = this.state.formRegister;
        try{
            const response = await api.post("/broker/register", {data});
            if(response.data.success === true){
                this.setState({msgResponseShow: this.translate("BROKERS_REGISTER_REGISTER_STATUS_SUCCESS")});
                document.getElementById('register-broker-new').reset();
                this.reloadDatatable()
            }else{
                this.setState({msgResponseShow: response.data.msg});
            }
        }catch(err){
            this.setState({msgResponseShow: err});
        }
    }

    loadBrokersSelectList = async () => {

        try{
            const response = await api.get("/brokers/table_all")
            console.log(response)
            if(response && response.data && response.data.data && response.data.data.length){
                this.setState({brokersSelectList: response.data.data})
               
            } 
        }catch(err){
            console.log(err)
        }
    }

    handleCloseModalResponse(e = null){
        if (e) e.preventDefault()
        this.setState({modalResponseShow: false})
    }

    onChangePhoneNumber(e){
        e.preventDefault()

        var SPMaskBehavior = function (val){
            return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
        },
        spOptions = {
            onComplete: function(val){
                var phone = $('#register-phone').val();
                this.setState({formRegister: {...this.state.formRegister, phone: phone}})
            }.bind(this),
            onKeyPress: function(val, e, field, options) {
                field.mask(SPMaskBehavior.apply({}, arguments), options);
            }
        };

        $('#register-phone').mask(SPMaskBehavior, spOptions);
    }

    render() {
        return (
            <React.Fragment>
                {this.state.isFormAddBrokerVisible ? (
                    <div className="d-flex justify-content-between">
                        <h5 className="fwhite">{this.translate("BROKERS_REGISTER_REGISTER_BROKER")}</h5>
                        <button onClick={()=>this.setState({isFormAddBrokerVisible: false})} className="btn btn-success btn-cursor-pointer px-5 btn-rounded btn-shadow pull-right">
                            <i className="fa fa-list"></i> {this.translate('BROKERS_REGISTER_CHOOSE_EXSISTING_BROKER')}
                        </button>
                    </div>
                ) : (
                    <div className="d-flex justify-content-between">
                        <h5 className="fwhite">{this.translate("BROKERS_REGISTER_ADD_BROKER")}</h5>
                        <button onClick={()=>this.setState({isFormAddBrokerVisible: true})} className="btn btn-success btn-cursor-pointer px-5 btn-rounded btn-shadow pull-right">
                            <i className="fa fa-plus"></i> {this.translate("BROKERS_REGISTER_REGISTER_NEW_BROKER")}
                        </button>
                    </div>
                )}
                {this.state.isFormAddBrokerVisible ? (
                    <form id="register-broker-new" onSubmit={this.handleRegisterBroker} className="width100">
                        <div className="row p-3 white box-shadow pb40">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <div className="form-group">
                                    <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                        {this.translate("BROKERS_REGISTER_LABEL_NAME")}:
                                    </label>
                                    <input type="text" onChange={e => this.setState({ formRegister: {...this.state.formRegister, name:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("BROKERS_REGISTER_PLACEHOLDER_NAME_OF_BROKER")} required/>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-2 col-lg-2">
                                <div className="form-group">
                                    <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                        {this.translate("BROKERS_REGISTER_LABEL_DDI")}:
                                    </label>
                                    <input type="text" onChange={e => this.setState({formRegister: {...this.state.formRegister, ddi: e.target.value}})} id="register-ddi" className="form-control box-shadow" placeholder={this.translate("BROKERS_REGISTER_PLACEHOLDER_DDI")} required/>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4">
                                <div className="form-group">
                                    <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                        {this.translate("BROKERS_REGISTER_LABEL_PHONE")}:
                                    </label>
                                    <input type="text" onChange={e => this.setState({formRegister: {...this.state.formRegister, phone: e.target.value}})} id="register-phone" className="form-control box-shadow" placeholder={this.translate("BROKERS_REGISTER_PLACEHOLDER_PHONE_OF_BROKER")} required/>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                        {this.translate("BROKERS_REGISTER_LABEL_EMAIL")}:
                                    </label>
                                    <input type="email" onChange={e => this.setState({formRegister: {...this.state.formRegister, email: e.target.value}})} className="form-control box-shadow" placeholder={this.translate("BROKERS_REGISTER_PLACEHOLDER_EMAIL_OF_BROKER")} required/>
                                </div>
                            </div>
                            <div className="box-divider m0 mt20 width100"></div>
                            <div className="col-sm-12 col-md-12 mt20">
                                <button type="submit" className="btn btn-info px-5 btn-rounded btn-shadow pull-right btn-cursor-pointer">
                                    <i className="fa fa-save"></i> {this.translate("BROKERS_REGISTER_REGISTER_BROKER")}
                                </button>
                            </div>
                        </div>
                    </form>
                ) : (
                    <form id="register-broker-new" onSubmit={this.handleChooseBroker} className="width100">
                        <div className="row p-3 white box-shadow pb40">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <div className="form-group">
                                    <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                        {this.translate('BROKERS_REGISTER_CHOOSE_THE_BROKER')}
                                    </label>
                                    <select onChange={e => this.setState({ formSelectBroker: {...this.state.formSelectBroker, selected_imob:  e.target.value}})} value={this.state.formSelectBroker.selected_imob} className="form-control box-shadow cool-select" required>
                                        <option value="">{this.translate('BROKERS_REGISTER_SELECT_THE_BROKER')}</option>
                                        {this.state.brokersSelectList.map((broker, index)=>
                                            <option key={ index + broker[6]} value={broker[6]}>{broker[0]}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            
                            <div className="box-divider m0 mt20 width100"></div>
                            <div className="col-sm-12 col-md-12 mt20">
                                <button type="submit" className="btn btn-info px-5 btn-rounded btn-shadow pull-right btn-cursor-pointer">
                                    <i className="fa fa-save"></i> {this.translate("BROKERS_REGISTER_ADD_BROKER")}
                                </button>
                            </div>
                        </div>
                    </form>
                )}
                <Modal show={this.state.modalResponseShow} onHide={this.handleCloseModalResponse} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-bell"></i> Status</h5>
                                <a href="/" onClick={e=>this.handleCloseModalResponse(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                <span className="col-12 text-center py-2 lead">
                                    {this.state.msgResponseShow}
                                </span>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(BrokersRegister);