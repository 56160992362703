import React, { Component } from 'react';

class CommentsBoxTool extends Component {

    constructor(props){
        super(props);
        this.state = {
            card_comment: []
        }
    }

    handleClick = (e) => {
        e.preventDefault();
    }

    render() {
        return (
            <li className="nav-item dropdown">
                <a href="/" onClick={e=>this.handleClick(e)} className="nav-link text-muted" data-toggle="dropdown">
                    <i className="fa fa-comment-o"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-scale pull-right light f10 box-shadow chat_window">
                    <div className="scrollable hover">
                        <div className="p-3">
                            <div className="chat-list">
                                {
                                    (this.state.card_comment.length)?(
                                        this.state.card_comment.map(
                                            (comment, i) => <div key={i} className="chat-item" data-className="null">
                                                                <div className="chat-body">
                                                                    <span className="badge badge-pill primary mb5">Barack  Obama</span>
                                                                    <div className="chat-content rounded msg primary">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</div>
                                                                    <div className="chat-date date">01.03.2018</div>
                                                                </div>
                                                            </div>
                                        )
                                    ):(
                                        <div>
                                            Nenhum comentario para ser exibido.
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        );
    }
}

export default CommentsBoxTool;