import React, { Component } from 'react';
//import Chart from 'chart.js';
//import {app, hexToRGB} from '../Structure/Configs/Theme';

export class ChartMonthlySales extends Component {

    componentDidMount(){
        /*
        const myChart = document.getElementById('chart-monthly-sales').getContext('2d');
        new Chart(myChart, {
            type: "line",
            data: {
                labels: this.props.fields.suspects.months,
                datasets: [
                    {
                        label: 'Suspects',
                        data: this.props.fields.suspects.values,
                        fill: true,
                        backgroundColor: hexToRGB(app.color.accent, 0.4),
                        borderColor: app.color.warn,
                        borderWidth: 2,
                        borderCapStyle: 'butt',
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: 'miter',
                        pointBorderColor: app.color.primary,
                        pointBackgroundColor: '#fff',
                        pointBorderWidth: 2,
                        pointHoverRadius: 4,
                        pointHoverBackgroundColor: app.color.primary,
                        pointHoverBorderColor: '#fff',
                        pointHoverBorderWidth: 2,
                        pointRadius: [0,4,0,0,4,0,0],
                        pointHitRadius: 10,
                    }
                ]
            },
            options: {}
        });
        */
    }

    render() {
        return (
            <div className="col-md-6 col-sm-12">
                <div className="padding bg-blur_w position10">
                    <div className="d-flex">
                        <p className="uppercase text-muted f10">Suspects</p>
                    </div>
                    <canvas id="chart-monthly-sales" width={624} height={300} className='chartjs-render-monitor position10'/>
                </div>
            </div>
        );
    }
}

export default ChartMonthlySales;