import React, { Component } from 'react'


export default class CardNotFound extends Component{
  
    render = () => 
        <div className="col-6 col-sm-6 col-md-3 btn-cursor-pointer hover-shadow active-dark-background">
            <div className="padding position10">
                <div className="d-flex">
                    <p className="uppercase text-muted f10">{this.props.translate("DASHBOARD_PERFORMANCE_CARD_CARD_NOT_FOUND")}</p>
                </div>

                <i className={`fa fa-question text-center text-warning f32 text-shadow width100 mt20`}></i>

                <div className="d-flex width100 text-center">
                    <span className="flex f16 width100 fwhite">{this.props.translate("DASHBOARD_PERFORMANCE_CARD_CARD_NOT_FOUND")}</span>
                </div>
                <div className="d-flex width100 text-center">
                    <span className="flex f10 width100">
                        {`${this.props.type} `}{this.props.translate("DASHBOARD_PERFORMANCE_CARD_NOT_FOUND")}
                    </span>
                </div>
            </div>
        </div>
    
}







