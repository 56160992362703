import React, { Component, Fragment } from 'react';

import $ from 'jquery';

import api from '../../../Services/Api';

class CaptureForm extends Component {
    _isMounted = false;

    constructor(props){
        super(props)
        this.state = {
            idAction: this.props.values.idAction,
            loaded: false,
            alertResponse: null,
            formValuesAction: {
                form_mark: null,
                form_type: null,
                form_mark_value: null,
                follow_up_email: null,
                redirect_page: null
            }
        }
        this.formSubmitSave = this.formSubmitSave.bind(this)
        this.translate = this.props.translate
    }

    componentDidMount(){
        api.get('/journey/action/' + this.props.values.idAction).then(async response => {
            this.setState({formValuesAction: response.data, loaded: true})
            console.log(response);
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async formSubmitSave(e){
        e.preventDefault()
        $('#button_submit_form_action').prop('disabled', true).html(`<i className="fa fa-spinner"></i> ${this.translate('BUTTON_WAIT')}...`)
        this.setState({alertResponse: null})
        const data = this.state.formValuesAction
        try{
            const response = await api.post("/journey/action/" + this.props.values.idAction, data);
            if(response.data.success){
                this.setState({alertResponse: true})
                $('#button_submit_form_action').prop('disabled', false).html(`<i className="fa fa-save"></i> ${this.translate('BUTTON_REGISTER')}`)
            }else{
                this.setState({alertResponse: false})
            }
        }catch(err){
            console.log(err)
        }
    }

    render() {
        return (
            <Fragment>
                {
                    (this.state.alertResponse === false)?(
                        <div className="alert alert-danger alert-dismissible" role="alert">
                            {this.translate('CAPTURE_FORM_ERROR_REGISTERING_ACTION')}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    ):(
                        <React.Fragment>
                            {
                                (this.state.alertResponse)?(
                                    <div className="alert alert-success alert-dismissible" role="alert">
                                        {this.translate('CAPTURE_FORM_SUCCESS_REGISTERING_ACTION')}
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                ):(false)
                            }
                        </React.Fragment>
                    )
                }
                {
                    (this.state.loaded)?(
                        <form onSubmit={this.formSubmitSave}>
                            <div className="form-group">
                                <label htmlFor="action_capture_form_form_mark">{this.translate('CAPTURE_FORM_LABEL_FORM_IDENTIFIER_TYPE')}</label>
                                <select onChange={e => this.setState({formValuesAction: {...this.state.formValuesAction, form_mark:  e.target.value}})} defaultValue={this.state.formValuesAction.form_mark} className="form-control cool-select" id="action_capture_form_form_mark" required>
                                    <option value="">{this.translate('CAPTURE_FORM_OPTION_SELECT_THE_IDENTIFIER')}</option>
                                    <option value="id">{this.translate('CAPTURE_FORM_OPTION_FORM_ID')}</option>
                                    <option value="class">{this.translate('CAPTURE_FORM_OPTION_FORM_CLASS')}</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="action_capture_form_form_type">{this.translate('CAPTURE_FORM_LABEL_FORM_TYPE')}</label>
                                <select onChange={e => this.setState({formValuesAction: {...this.state.formValuesAction, form_type:  e.target.value}})} defaultValue={this.state.formValuesAction.form_type} className="form-control cool-select" id="action_capture_form_form_type" required>
                                    <option value="">{this.translate('CAPTURE_FORM_OPTION_SELECT_THE_TYPE')}</option>
                                    <option value="new_lead">{this.translate('CAPTURE_FORM_OPTION_NEW_LEAD')}</option>
                                    <option value="new_visit">{this.translate('CAPTURE_FORM_OPTION_NEW_VISIT')}</option>
                                    <option value="new_contact">{this.translate('CAPTURE_FORM_OPTION_NEW_CONTACT')}</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="action_capture_form_form_mark_value">{this.translate('CAPTURE_FORM_FORM_IDENTIFIER')}</label>
                                <input type="text" onChange={e => this.setState({formValuesAction: {...this.state.formValuesAction, form_mark_value:  e.target.value}})} id="action_capture_form_form_mark_value" className="form-control" placeholder={this.translate('CAPTURE_FORM_FORM_IDENTIFIER')} defaultValue={this.state.formValuesAction.form_mark_value} required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="action_capture_form_follow_up_email">{this.translate('CAPTURE_FORM_FOLLOW_UP_EMAIL')}</label>
                                <input type="text" onChange={e => this.setState({formValuesAction: {...this.state.formValuesAction, follow_up_email:  e.target.value}})} id="action_capture_form_follow_up_email" className="form-control" placeholder={this.translate('CAPTURE_FORM_FOLLOW_UP_EMAIL')} defaultValue={this.state.formValuesAction.follow_up_email}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="action_capture_form_redirect_page">{this.translate('CAPTURE_FORM_REDIRECT_PAGE')}</label>
                                <input type="text" onChange={e => this.setState({formValuesAction: {...this.state.formValuesAction, redirect_page:  e.target.value}})} id="action_capture_form_redirect_page" className="form-control" placeholder={this.translate('CAPTURE_FORM_REDIRECT_PAGE')} defaultValue={this.state.formValuesAction.redirect_page}/>
                            </div>
                            <div className="form-group">
                                <button type="submit" id="button_submit_form_action" className="btn btn-cursor-pointer btn-success float-right" style={{width: '100%'}}>
                                    <i className="fa fa-save"></i> {this.translate('BUTTON_REGISTER')}
                                </button>
                            </div>
                        </form>
                    ):(
                        <div>
                            {this.translate('CAPTURE_FORM_LOADING_CONFIG_FORM')}
                        </div>
                    )
                }
            </Fragment>
        );
    }
}

export default CaptureForm;