import React, { Component } from 'react';

import EcommerceFunilMarketing from './EcommerceFunilMarketing';
import EcommerceFunilSales from './EcommerceFunilSales';

export class EcommerceFunilMKTSales extends Component {
    render() {
        return (
            <div className="row no-gutters position10">
                <EcommerceFunilMarketing fields={this.props.fields} type={this.props.type}/>
                <EcommerceFunilSales fields={this.props.fields} type={this.props.type}/>
            </div>
        );
    }
}

export default EcommerceFunilMKTSales;