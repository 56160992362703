import React, { Component, Fragment } from 'react';
import {withRouter} from 'react-router-dom'

import $ from 'jquery'

import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4'

import api from '../../Services/Api';
import {ajaxUrl, ajaxToken} from '../../Services/Ajax';

import Modal from 'react-bootstrap/Modal';

import MenuMain from '../../Structure/MenuMain';
import HeaderMain from '../../Structure/HeaderMain';
import FooterMain from '../../Structure/FooterMain';

class SegmentationList extends Component{

    constructor(props){
        super(props)
        this.translate = this.props.translate
        document.title = this.translate('SEGMENTATION_LIST_ADMINISTRATE_SEGMENTATIONS')
        this.state = {
            tableLoadSegmentation: null,
            modalCreateSegmentation: false,
            modalViewSegmentation: false,
            infoSegmentation: {
                name: null,
                code: null,
                pitch_segmentation: null,
                description: null,
                public_data: [],
            },
            formCreateSegmentation: {
                name: null,
                code: null,
                pitch_segmentation: null,
                publics: [{"public_name": "", "public_pitch": "", "public_code": "", id: new Date().getTime()}],
                description: null,
            },
            formEditSegmentation: {
                name: null,
                code: null,
                pitch_segmentation: null,
                publics: [{"public_name": "", "public_pitch": "", "public_code": ""}],
                description: null,
            },
            modalEditSegmentation: false,
        }
        this.showModalCreateSegmentation = this.showModalCreateSegmentation.bind(this)
        this.closeModalCreateSegmentation = this.closeModalCreateSegmentation.bind(this)
        this.closeModalEditSegmentation = this.closeModalEditSegmentation.bind(this)
        this.formSubmitCreateSegmentation = this.formSubmitCreateSegmentation.bind(this)
        this.reloadDatatableSegmentation = this.reloadDatatableSegmentation.bind(this)

        this.setNameSegmentation = this.setNameSegmentation.bind(this)

        this.showModalViewSegmentation = this.showModalViewSegmentation.bind(this)
        this.closeModalViewSegmentation = this.closeModalViewSegmentation.bind(this)
        this.addPublicSegmentation = this.addPublicSegmentation.bind(this)

    }

    showModalCreateSegmentation(){
        this.setState({modalCreateSegmentation: true})
    }

    closeModalCreateSegmentation(){
        this.setState({modalCreateSegmentation: false})
    }

    closeModalEditSegmentation(){
        this.setState({modalEditSegmentation: false})
    }

    showModalViewSegmentation(){
        this.setState({modalViewSegmentation: true})
    }

    closeModalViewSegmentation(){
        this.setState({modalViewSegmentation: false})
    }

    reloadDatatableSegmentation(){
        $('#segmentation_list').DataTable().ajax.reload();
    }

    setNameSegmentation(e){
        var text =  e.target.value
        
        let code = this.sanitizeNameToCode(text)
        
        document.getElementById('code_seg').value = code.toLowerCase()

        const {formCreateSegmentation} = this.state

        formCreateSegmentation.name = e.target.value;
        formCreateSegmentation.code = code.toLowerCase();

        this.setState({
            formCreateSegmentation
        })
    }

    sanitizeNameToCode(text){
        var strAccents = text.split('');
        //var strAccentsOut = new Array();
        var strAccentsOut = [];
        var strAccentsLen = strAccents.length;
        var accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
        var accentsOut = "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
        for(var y = 0; y < strAccentsLen; y++){
            if (accents.indexOf(strAccents[y]) !== -1){
                strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
            }else{
                strAccentsOut[y] = strAccents[y];
            }
        }
        strAccentsOut = strAccentsOut.join('');
        
        var code = strAccentsOut.split(' ').join('_');
        return code
    }

    async formSubmitCreateSegmentation(e){
        e.preventDefault();

        $('#segmentation_button_submit').prop('disabled', true).html(`<i className="fa fa-spinner"></i> ${this.translate('SEGMENTATION_LIST_WAIT')}`)
        
        const data = this.state.formCreateSegmentation;
        try{
            const response = await api.post("/segmentation/create", {data});
            if(response.data.success === true){
                this.reloadDatatableSegmentation()
                this.closeModalCreateSegmentation();
                $('#segmentation_button_submit').prop('disabled', false).html(`<i className="fa fa-save"></i> ${this.translate('SEGMENTATION_LIST_REGISTER')}`)
            }else{
                console.log(response.data.msg);
            }
        }catch(err){
            console.log(err);
        }
    }

    changePublicByIndex(e, i, key){
        const {formCreateSegmentation} = this.state
        if(key === "public_name"){
            var text =  e.target.value
        
            let code = this.sanitizeNameToCode(text)
            formCreateSegmentation["publics"][i]["public_code"] = code.toLowerCase()
        } 
        formCreateSegmentation["publics"][i][key] = e.target.value
        this.setState({formCreateSegmentation: formCreateSegmentation}) 
        console.log(this.state.formCreateSegmentation)
    }

    addPublicSegmentation(){
        const {formCreateSegmentation} = this.state
        formCreateSegmentation["publics"].push({"public_name": "", "public_pitch": "", "public_code": "", id: new Date().getTime()})
        this.setState({formCreateSegmentation: formCreateSegmentation}) 
    }

    removePublicSegmentation = (data, index) => {
        let {formCreateSegmentation} = this.state

        formCreateSegmentation["publics"] = formCreateSegmentation.publics.filter(singlePublic=>`${singlePublic.public_code}${index}${singlePublic.id}` !== `${data.public_code}${index}${data.id}`)
        
        this.setState({formCreateSegmentation: formCreateSegmentation}) 
    }

    loadFormEditSegmentation = async (segmentation, segmentationId) => {
        this.setState({
            formEditSegmentation: {
                segmentation_id: segmentationId,
                name: segmentation.name,
                code: segmentation.code,
                pitch_segmentation: segmentation.pitch_segmentation,
                public_data: segmentation.public_data,
                description: segmentation.description
            }, 
            modalEditSegmentation: true
        })
        
    }

    formSubmitEditSegmentation = async (e) => {
        e.preventDefault();

        $('#segmentation_edit_button_submit').prop('disabled', true).html(`<i className="fa fa-spinner"></i> ${this.translate('SEGMENTATION_LIST_WAIT')}`)
        
        const data = this.state.formEditSegmentation;
        try{
            const response = await api.post(`/segmentation/edit`, {data})
            console.log(response.data)
            if(response.data.success === true){
                this.reloadDatatableSegmentation()
                this.closeModalEditSegmentation();
            }else{
                console.log(response.data.msg);
            }
        }catch(err){
            console.log(err);
        }
        $('#segmentation_edit_button_submit').prop('disabled', false).html(`<i className="fa fa-save"></i> ${this.translate('SEGMENTATION_LIST_REGISTER')}`)
    }

    changePublicByIndexFormEditSegmentation = (e, i, key) =>{
        const {formEditSegmentation} = this.state
        if(key === "public_name"){
            var text =  e.target.value
        
            let code = this.sanitizeNameToCode(text)
            formEditSegmentation["public_data"][i]["public_code"] = code.toLowerCase()
        } 
        formEditSegmentation["public_data"][i][key] = e.target.value
        this.setState({formEditSegmentation: formEditSegmentation}) 
        console.log(this.state.formEditSegmentation)
    }

    removePublicFormEditSegmentation = (data, index) => {
        let {formEditSegmentation} = this.state

        formEditSegmentation["public_data"] = formEditSegmentation.public_data.filter(singlePublic=>`${singlePublic.public_code}${index}${singlePublic.id}` !== `${data.public_code}${index}${data.id}`)
        
        this.setState({formEditSegmentation: formEditSegmentation}) 
    }
    
    addPublicFormEditSegmentation = () => {
        const {formEditSegmentation} = this.state
        formEditSegmentation["public_data"].push({"public_name": "", "public_pitch": "", "public_code": "", "edit": true, id: new Date().getTime()})
        this.setState({formEditSegmentation: formEditSegmentation}) 
    }
    


    componentDidMount(){

        let tableLoadSegmentation = this.state.tableLoadSegmentation
        //const props = this.props;

        tableLoadSegmentation = $('#segmentation_list').DataTable({
            "searching": true,
            "paging": false,
            "info": false,
            "responsive": true,
            "language": {
                "url": this.translate('DATATABLE_LANGUAGE_URL')
            },
            "ajax": {
                "url": ajaxUrl('/segmentation/table'),
                "type": 'GET',
                "beforeSend": function (request) {
                    request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                }
            },
            "columnDefs": [{
                "targets": -1,
                "data": null,
                "defaultContent": 
                    `<div class="btn-group btn-shadow">
                        <button class="view btn btn-sm blue btn-cursor-pointer" title="${this.translate('SEGMENTATION_LIST_VISUALIZE_SEGMENTATION')}">
                            <i class="fa fa-eye" title="${this.translate('SEGMENTATION_LIST_VISUALIZE_SEGMENTATION')}"></i>
                        </button>
                        <button class="edit btn btn-warning btn-sm btn-cursor-pointer" title="${this.translate('SEGMENTATION_LIST_EDIT_SEGMENTATION')}">
                            <i class="fa fa-edit" title="${this.translate('SEGMENTATION_LIST_EDIT_SEGMENTATION')}"></i>
                        </button>
                    </div>`
            }]
        });

        $('#segmentation_list tbody').on( 'click', '.view', function (e){
            
            e.preventDefault();

            let data = [];
            
            if($(e.target).parents('tr').hasClass('child')){
                data = tableLoadSegmentation.row($(e.target).parents('tr').prev('.parent')).data();
            }else{
                data = tableLoadSegmentation.row($(e.target).parents('tr')).data();
            }
            
            this.setState({
                infoSegmentation: data[4],
            })
            this.showModalViewSegmentation();
            
        }.bind(this));
        
        $('#segmentation_list tbody').on( 'click', '.edit', function (e){
            
            e.preventDefault();

            let data = [];
            
            if($(e.target).parents('tr').hasClass('child')){
                data = tableLoadSegmentation.row($(e.target).parents('tr').prev('.parent')).data();
            }else{
                data = tableLoadSegmentation.row($(e.target).parents('tr')).data();
            }
            
            this.loadFormEditSegmentation(data[4], data[3]);
            
        }.bind(this));

    }

    render(){
        return (
            <Fragment>
                <MenuMain />
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    <HeaderMain title={document.title} onChange={this.handleChange}/>
                    <div className="content-main row row-no-margin" id="content-main">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-gutters mt-4">
                            <div className="row text-right mb-3">
                                <div className="col-md-12">
                                    <button className="btn btn-fw blue btn-rounded pull-right btn-shadow btn-cursor-pointer" onClick={this.showModalCreateSegmentation}>
                                        <i className="fa fa-bullseye"></i> {this.translate('SEGMENTATION_LIST_CREATE_NEW_SEGMENTATION')}
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="box p-2">
                                        <table id="segmentation_list" className="table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                                            <thead>
                                                <tr className="text-left">
                                                    <th>{this.translate('SEGMENTATION_LIST_TABLE_NUMBER')}</th>
                                                    <th>{this.translate('SEGMENTATION_LIST_TABLE_SEGMENTATION_NAME')}</th>
                                                    <th>{this.translate('SEGMENTATION_LIST_TABLE_INDENTIFIER')}</th>
                                                    <th>{this.translate('TABLE_ACTIONS_ROW')}</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterMain />
                </div>
                <Modal show={this.state.modalCreateSegmentation} onHide={this.closeModalCreateSegmentation} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t modal-all-generic">
                    <Modal.Body>
                    <div className="d-flex flex-column h-100 pb-5">
                        <div className="p-3 b-b d-flex no-shrink blue text-center">
                            <h5 className="h5 m-0 text-center width100 line-height-38px">
                                <i className="fa fa-bullseye"></i> {this.translate('SEGMENTATION_LIST_CREATE_NEW_SEGMENTATION')}
                            </h5>
                            <button onClick={this.closeModalCreateSegmentation} className="btn btn-transparent ml-auto">
                                <span className="text-lg l-h-1x">×</span>
                            </button>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-6 offset-md-3">

                                <form onSubmit={this.formSubmitCreateSegmentation}>
                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('SEGMENTATION_LIST_MODAL_LABEL_SEGMENTATION_NAME')}</label>
                                        <input type="text" onChange={this.setNameSegmentation} className="form-control box-shadow cool-input" placeholder={this.translate('SEGMENTATION_LIST_MODAL_PLACEHOLDER_SEGMENTATION_NAME')} required="required" />
                                    </div>
                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('SEGMENTATION_LIST_MODAL_LABEL_SEGMENTATION_DESCRIPTION')}</label>
                                        <input type="text" onChange={e => this.setState({ formCreateSegmentation: {...this.state.formCreateSegmentation, description:  e.target.value}})} className="form-control box-shadow cool-input" placeholder={this.translate('SEGMENTATION_LIST_MODAL_PLACEHOLDER_SEGMENTATION_DESCRIPTION')} required="required" />
                                    </div>
                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('SEGMENTATION_LIST_MODAL_SEGMENTATION_PITCH')}</label>
                                        <input type="text" onChange={e => this.setState({ formCreateSegmentation: {...this.state.formCreateSegmentation, pitch_segmentation:  e.target.value}})} className="form-control box-shadow cool-input" placeholder={this.translate('SEGMENTATION_LIST_MODAL_SEGMENTATION_PITCH')} required="required" />
                                    </div>
                                    
                                    
                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('SEGMENTATION_LIST_MODAL_SEGMENTATION_PUBLIC')}</label>
                                        {this.state.formCreateSegmentation.publics.map((data, i)=>
                                            <div key={`create${data.id}${i}`}>
                                                <div className="form-group row">
                                                    <label htmlFor={`public_name_${i}`} className="col-sm-2 col-form-label black no-text-shadow">{this.translate('SEGMENTATION_LIST_MODAL_SEGMENTATION_PUBLIC_NAME')}</label>
                                                    <div className="col-sm-10">
                                                        <input id={`public_name_${i}`} type="text" value={data.public_name} onChange={e=>this.changePublicByIndex(e, i, "public_name")} className="form-control box-shadow cool-input" placeholder={this.translate('SEGMENTATION_LIST_MODAL_SEGMENTATION_PUBLIC_NAME')} required/>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor={`public_pitch_${i}`} className="col-sm-2 col-form-label black no-text-shadow">{this.translate('SEGMENTATION_LIST_MODAL_SEGMENTATION_PUBLIC_PITCH')}</label>
                                                    <div className="col-sm-10">
                                                        <input id={`public_pitch_${i}`} type="text" value={data.public_pitch} onChange={e=>this.changePublicByIndex(e, i, "public_pitch")} className="form-control box-shadow cool-input" placeholder={this.translate('SEGMENTATION_LIST_MODAL_SEGMENTATION_PUBLIC_PITCH')} required/>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className=" col-12 d-flex justify-content-end">
                                                        <button type="button" onClick={()=>this.removePublicSegmentation(data, i)} disabled={this.state.formCreateSegmentation.publics.length > 1  ? "" : "disabled"} className=" btn btn-fw danger btn-rounded btn-shadow btn-cursor-pointer"><i className="fa fa-trash"></i>&nbsp;{this.translate('SEGMENTATION_LIST_MODAL_SEGMENTATION_REMOVE_PUBLIC')}</button>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        )}

                                        <button type="button" id="segmentation_button_add_public" onClick={this.addPublicSegmentation} className="btn btn-fw blue btn-rounded btn-shadow "><i className="fa fa-plus"></i>{this.translate('SEGMENTATION_LIST_MODAL_SEGMENTATION_ADD_PUBLIC')}</button>
                                    </div>


                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('SEGMENTATION_LIST_MODAL_SEGMENTATION_INDENTIFIER')}</label>
                                        <input type="text" id="code_seg" onChange={e => this.setState({ formCreateSegmentation: {...this.state.formCreateSegmentation, code:  e.target.value}})} className="form-control box-shadow cool-input" placeholder={this.translate('SEGMENTATION_LIST_MODAL_SEGMENTATION_INDENTIFIER')} required="required" disabled/>
                                    </div>
                                    
                                    <button type="submit" id="segmentation_button_submit" className="btn btn-fw blue btn-rounded mt20 pull-right btn-shadow px-5"><i className="fa fa-save"></i> {this.translate('SEGMENTATION_LIST_REGISTER')}</button>
                                </form>

                            </div>
                        </div>
                    </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.modalViewSegmentation} onHide={this.closeModalViewSegmentation} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                    <div className="d-flex flex-column h-100 pb-5">
                        <div className="p-3 b-b d-flex no-shrink blue text-center">
                            <h5 className="h5 m-0 text-center width100 line-height-38px">
                                <i className="fa fa-bullseye"></i> {this.translate('SEGMENTATION_LIST_MODAL_DETAILS_SEGMENTATION_DETAILS')}
                            </h5>
                            <button onClick={this.closeModalViewSegmentation} className="btn btn-transparent ml-auto">
                                <span className="text-lg l-h-1x">×</span>
                            </button>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-6 offset-md-3">
                                <h5>{this.translate('SEGMENTATION_LIST_MODAL_DETAILS_SEGMENTATION_INFO')}</h5>
                                <p>
                                    <b>{this.translate('SEGMENTATION_LIST_MODAL_DETAILS_SEGMENTATION_NAME')}: </b> {this.state.infoSegmentation.name}
                                </p>
                                <p>
                                    <b>{this.translate('SEGMENTATION_LIST_MODAL_DETAILS_SEGMENTATION_CODE')}: </b> {this.state.infoSegmentation.code}
                                </p>
                                <p>
                                    <b>{this.translate('SEGMENTATION_LIST_MODAL_DETAILS_SEGMENTATION_DESCRIPTION')}: </b> {this.state.infoSegmentation.description}
                                </p>
                                <p>
                                    <b>{this.translate('SEGMENTATION_LIST_MODAL_DETAILS_SEGMENTATION_PITCH')}: </b> {this.state.infoSegmentation.pitch_segmentation}
                                </p>
                                {
                                    this.state.infoSegmentation.public_data.length ? (
                                        <React.Fragment>
                                            <div>
                                                <h5>{this.translate('SEGMENTATION_LIST_MODAL_DETAILS_SEGMENTATION_PUBLICS')}</h5>
                                                {
                                                    this.state.infoSegmentation.public_data.map((data, i) => 
                                                        <div key={i}>
                                                            <p><b>{this.translate('SEGMENTATION_LIST_MODAL_DETAILS_SEGMENTATION_PUBLIC_NAME')}</b>: {data.public_name}</p>
                                                            <p><b>{this.translate('SEGMENTATION_LIST_MODAL_DETAILS_SEGMENTATION_PUBLIC_PITCH')}</b>: {data.public_pitch}</p>
                                                            <p><b>{this.translate('SEGMENTATION_LIST_MODAL_DETAILS_SEGMENTATION_PUBLIC_CODE')}</b>: {data.public_code}</p>
                                                            <hr />
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </React.Fragment>
                                    ):(false)
                                }
                            </div>
                        </div>
                    </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.modalEditSegmentation} onHide={this.closeModalEditSegmentation} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t modal-all-generic">
                    <Modal.Body>
                    <div className="d-flex flex-column h-100 pb-5">
                        <div className="p-3 b-b d-flex no-shrink blue text-center">
                            <h5 className="h5 m-0 text-center width100 line-height-38px">
                                <i className="fa fa-bullseye"></i> {this.translate('SEGMENTATION_LIST_EDIT_SEGMENTATION')}
                            </h5>
                            <button onClick={this.closeModalEditSegmentation} className="btn btn-transparent ml-auto">
                                <span className="text-lg l-h-1x">×</span>
                            </button>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-6 offset-md-3">

                                <form onSubmit={this.formSubmitEditSegmentation}>
                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('SEGMENTATION_LIST_MODAL_LABEL_SEGMENTATION_NAME')}</label>
                                        <input type="text" readOnly value={this.state.formEditSegmentation.name} className="form-control box-shadow cool-input" placeholder={this.translate('SEGMENTATION_LIST_MODAL_PLACEHOLDER_SEGMENTATION_NAME')} required />
                                    </div>
                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('SEGMENTATION_LIST_MODAL_LABEL_SEGMENTATION_DESCRIPTION')}</label>
                                        <input type="text" readOnly value={this.state.formEditSegmentation.description} className="form-control box-shadow cool-input" placeholder={this.translate('SEGMENTATION_LIST_MODAL_PLACEHOLDER_SEGMENTATION_DESCRIPTION')} required />
                                    </div>
                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('SEGMENTATION_LIST_MODAL_SEGMENTATION_PITCH')}</label>
                                        <input type="text" readOnly value={this.state.formEditSegmentation.pitch_segmentation} className="form-control box-shadow cool-input" placeholder={this.translate('SEGMENTATION_LIST_MODAL_SEGMENTATION_PITCH')} required />
                                    </div>
                                    
                                    {this.state.formEditSegmentation && this.state.formEditSegmentation.public_data && this.state.formEditSegmentation.public_data.length > 0 &&
                                        <div className="col-sm-12 mb20">
                                            <label className="black no-text-shadow">{this.translate('SEGMENTATION_LIST_MODAL_SEGMENTATION_PUBLIC')}</label>
                                            {this.state.formEditSegmentation.public_data.map((data, i)=>
                                                <div key={`edit${data.id}${i}`}>
                                                    {!data.edit &&
                                                        <React.Fragment>
                                                            <div className="form-row">
                                                                <div className="form-group col-12 col-sm-6">
                                                                    <label htmlFor={`public_name_${i}`} className="col-form-label black no-text-shadow">{this.translate('SEGMENTATION_LIST_MODAL_SEGMENTATION_PUBLIC_NAME')}</label>
                                                                    <div>
                                                                        <input id={`public_name_${i}`} type="text" value={data.public_name}  className="form-control box-shadow cool-input" readOnly placeholder={this.translate('SEGMENTATION_LIST_MODAL_SEGMENTATION_PUBLIC_NAME')} required/>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group col-12 col-sm-6">
                                                                    <label htmlFor={`public_pitch_${i}`} className="col-form-label black no-text-shadow">{this.translate('SEGMENTATION_LIST_MODAL_SEGMENTATION_PUBLIC_PITCH')}</label>
                                                                    <div>
                                                                        <input id={`public_pitch_${i}`} type="text" value={data.public_pitch} className="form-control box-shadow cool-input" readOnly placeholder={this.translate('SEGMENTATION_LIST_MODAL_SEGMENTATION_PUBLIC_PITCH')} required/>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            )}
                                        </div>
                                    }
                                    
                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('SEGMENTATION_LIST_MODAL_NEW_SEGMENTATION_PUBLICS')}</label>
                                        {this.state.formEditSegmentation && this.state.formEditSegmentation.public_data && this.state.formEditSegmentation.public_data.length > 0 ?
                                            <React.Fragment>
                                                {this.state.formEditSegmentation.public_data.filter(singlePublic=>singlePublic.edit).length === 0 &&
                                                    <p>{this.translate("SEGMENTATION_LIST_MODAL_CLICK_TO_ADD_NEW_SEGMENTATION_PUBLICS")}</p>
                                                }
                                                {this.state.formEditSegmentation.public_data.map((data, i)=>
                                                    <div key={`edit${data.id}${i}`}>
                                                        {data.edit &&
                                                            <React.Fragment>
                                                                <div className="form-group row">
                                                                    <label htmlFor={`public_name_${i}`} className="col-sm-2 col-form-label black no-text-shadow">{this.translate('SEGMENTATION_LIST_MODAL_SEGMENTATION_PUBLIC_NAME')}</label>
                                                                    <div className="col-sm-10">
                                                                        <input id={`public_name_${i}`} type="text" value={data.public_name}  onChange={e=>this.changePublicByIndexFormEditSegmentation(e, i, "public_name")} className="form-control box-shadow cool-input" readOnly={data.edit ? "" : "readOnly"} placeholder={this.translate('SEGMENTATION_LIST_MODAL_SEGMENTATION_PUBLIC_NAME')} required/>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label htmlFor={`public_pitch_${i}`} className="col-sm-2 col-form-label black no-text-shadow">{this.translate('SEGMENTATION_LIST_MODAL_SEGMENTATION_PUBLIC_PITCH')}</label>
                                                                    <div className="col-sm-10">
                                                                        <input id={`public_pitch_${i}`} type="text" value={data.public_pitch} onChange={e=>this.changePublicByIndexFormEditSegmentation(e, i, "public_pitch")} className="form-control box-shadow cool-input" readOnly={data.edit ? "" : "readOnly"} placeholder={this.translate('SEGMENTATION_LIST_MODAL_SEGMENTATION_PUBLIC_PITCH')} required/>
                                                                        
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12 d-flex justify-content-end mb-4">
                                                                        <button type="button" onClick={()=>this.removePublicFormEditSegmentation(data, i)} className=" btn btn-fw danger btn-rounded btn-shadow btn-cursor-pointer"><i className="fa fa-trash"></i>&nbsp;{this.translate('SEGMENTATION_LIST_MODAL_SEGMENTATION_REMOVE_PUBLIC')}</button>
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                    
                                                        }
                                                    </div>
                                                )}

                                            </React.Fragment>
                                        :
                                            <p>{this.translate("SEGMENTATION_LIST_MODAL_CLICK_TO_ADD_NEW_SEGMENTATION_PUBLICS")}</p>
                                        }
                                        
                                        <button type="button"  onClick={this.addPublicFormEditSegmentation} className="btn btn-fw blue btn-rounded btn-shadow btn-cursor-pointer"><i className="fa fa-plus"></i>{this.translate('SEGMENTATION_LIST_MODAL_SEGMENTATION_ADD_PUBLIC')}</button>
                                    </div>


                                    <div className="col-sm-12 mb20">
                                        <label className="black no-text-shadow">{this.translate('SEGMENTATION_LIST_MODAL_SEGMENTATION_INDENTIFIER')}</label>
                                        <input type="text" id="code_seg" readOnly value={this.state.formEditSegmentation.code} className="form-control box-shadow cool-input" placeholder={this.translate('SEGMENTATION_LIST_MODAL_SEGMENTATION_INDENTIFIER')} required="required" disabled/>
                                    </div>
                                    
                                    <button type="submit" id="segmentation_edit_button_submit" className="btn btn-fw blue btn-rounded mt20 pull-right btn-shadow px-5"><i className="fa fa-save"></i> {this.translate('SEGMENTATION_LIST_REGISTER')}</button>
                                </form>

                            </div>
                        </div>
                    </div>
                    </Modal.Body>
                </Modal>
            </Fragment>
        );
    }
}

export default withRouter(SegmentationList);