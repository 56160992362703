import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';

import $ from 'jquery';
import 'jquery-mask-plugin';

import api from '../../../Services/Api';

import Modal from 'react-bootstrap/Modal';

class CompanyRegister extends Component {

    constructor(props){
        super(props);
        this.translate = this.props.translate
        document.title =  this.translate("ADMIN_COMPANY_TITLE")
        this.state = {
            modalResponseShow: false,
            msgResponseShow: false,
            formRegister: {
                name: null,
                cnpj: null,
                ddi: null,
                phone: null,
                email: null,
                cep: null,
                country: null,
                state: null,
                city: null,
                district: null,
                place: null,
                number: null,
                total_places: null
            }
        }
        this.handleRegisterCompany = this.handleRegisterCompany.bind(this);
        this.handleCloseModalResponse =  this.handleCloseModalResponse.bind(this)
        this.reloadDatatable = this.reloadDatatable.bind(this)
        this.handleCepChange = this.handleCepChange.bind(this)
        
    }

    componentDidMount(){
        var SPMaskBehavior = function (val){
            var ddi = $('#register-ddi').val();
            if(ddi === "+55"){
                return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
            }else{
                return val.replace(/\D/g, '').length === 11 ? '(000) 000-0000' : '(000) 000-0000';
            }
        },
        spOptions = {
            onComplete: function(val){
                var phone = $('#register-phone').val();
                this.setState({formRegister: {...this.state.formRegister, phone: phone}})
            }.bind(this),
            onKeyPress: function(val, e, field, options) {
                field.mask(SPMaskBehavior.apply({}, arguments), options);
            }
        };

        $('#register-ddi').mask('+099');
        $('#register-phone').mask(SPMaskBehavior, spOptions);

        $('#register-company-cnpj').mask('00.000.000/0000-00');

    }

    handleCountryChange = async country => {
        var options =  {
            onComplete: function(cep){
                $('#register-state, #register-city, #register-district, #register-place').prop('disabled', false);
            }
        };
        switch(country){
            case 'BR':
                $('#register-cep').mask('00000-000', options);
                $('#register-cep').prop('disabled', false);
                break;
            case 'US':
                $('#register-cep').mask('00000', options);
                $('#register-cep').prop('disabled', false);
                break;
            default:
                $('#register-cep').unmask();
                $('#register-cep').val('');
                $('#register-cep').prop('disabled', true);
                break;
        }
        this.setState({formRegister: {...this.state.formRegister, country: country}})
    }

    handleCepChange = async(e) => {
        let cep = e.target.value
        if(cep.length >= 9){
            fetch(`https://api.pagar.me/1/zipcodes/${cep.replace("-", "")}`, { method: 'get' })
            .then(response => response.json())
            .then((response)=>{
                let newState = {
                    cep,
                    city: response.city,
                    place: response.street,
                    district: response.neighborhood,
                    state: response.state
                }
                this.setState(
                    {
                        formRegister: {...this.state.formRegister, ...newState}
                    }
                )
            })
        } else{
            this.setState({formRegister: {...this.state.formRegister, cep: e.target.value}})
        }
        
       
    }
    handleCloseModalResponse(e = null){
        if (e) e.preventDefault()
        this.setState({modalResponseShow: false})
    }

    reloadDatatable(){
        $('#list_company').DataTable().ajax.reload();
    }

    async handleRegisterCompany(e){
        e.preventDefault();
        this.setState({ msgResponseShow: this.translate("ADMIN_COMPANY_REGISTER_WAIT_STATUS"), modalResponseShow: true });
        const data = this.state.formRegister;
        try{
            const response = await api.post("/company/register", {data});
            if(response.data.success === true){
                this.setState({msgResponseShow: this.translate("ADMIN_COMPANY_REGISTER_SUCCESS_STATUS")});
                document.getElementById('register-company-new').reset();
                this.reloadDatatable()
                //$('#click_tab_register_company').click();
            }else{
                this.setState({msgResponseShow: response.data.msg});
            }
        }catch(err){
            this.setState({msgResponseShow: err});
        }
    };

    render() {
        return (
            <React.Fragment>
                <h5 className="fwhite">{this.translate("ADMIN_COMPANY_REGISTER_COMPANY")}</h5>
                <form id="register-company-new" onSubmit={this.handleRegisterCompany} className="width100">
                    <div className="row p-3 white box-shadow pb40">
                        <div className="col-sm-12 col-md-9 col-lg-9">
                            <div className="form-group">
                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                    {this.translate("ADMIN_COMPANY_REGISTER_LABEL_NAME")}:
                                </label>
                                <input type="text" onChange={e => this.setState({ formRegister: {...this.state.formRegister, name:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("ADMIN_COMPANY_REGISTER_PLACEHOLDER_NAME")} required/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3">
                            <div className="form-group">
                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                    {this.translate("ADMIN_COMPANY_REGISTER_LABEL_CNPJ")}:
                                </label>
                                <input type="text" id="register-company-cnpj" onChange={e => this.setState({formRegister: {...this.state.formRegister, cnpj: e.target.value}})} className="form-control box-shadow" placeholder={this.translate("ADMIN_COMPANY_REGISTER_PLACEHOLDER_CNPJ")} required/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-2">
                            <div className="form-group">
                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                    {this.translate("ADMIN_COMPANY_REGISTER_LABEL_DDI")}:
                                </label>
                                <input type="text" onChange={e => this.setState({formRegister: {...this.state.formRegister, ddi: e.target.value}})} id="register-ddi" className="form-control box-shadow" placeholder={this.translate("ADMIN_COMPANY_REGISTER_PLACEHOLDER_DDI")} required/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <div className="form-group">
                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                    {this.translate("ADMIN_COMPANY_REGISTER_LABEL_PHONE")}:
                                </label>
                                <input type="text" onChange={e => this.setState({formRegister: {...this.state.formRegister, phone: e.target.value}})} id="register-phone" className="form-control box-shadow" placeholder={this.translate("ADMIN_COMPANY_REGISTER_PLACEHOLDER_PHONE")} required/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                {this.translate("ADMIN_COMPANY_REGISTER_LABEL_EMAIL")}:
                                </label>
                                <input type="email" onChange={e => this.setState({formRegister: {...this.state.formRegister, email: e.target.value}})} className="form-control box-shadow" placeholder={this.translate("ADMIN_COMPANY_REGISTER_PLACEHOLDER_EMAIL")} required/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3">
                            <div className="form-group">
                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                {this.translate("ADMIN_COMPANY_REGISTER_LABEL_COUNTRY")}:
                                </label>
                                <select onChange={e => this.handleCountryChange(e.target.value)} className="form-control box-shadow select-company-register" required>
                                    <option value="">{this.translate("ADMIN_COMPANY_REGISTER_SELECT_OPTION_1")}</option>
                                    <option value="BR">{this.translate("ADMIN_COMPANY_REGISTER_SELECT_OPTION_2")}</option>
                                    <option value="US">{this.translate("ADMIN_COMPANY_REGISTER_SELECT_OPTION_3")}</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3">
                            <div className="form-group">
                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                {this.translate("ADMIN_COMPANY_REGISTER_LABEL_CEP")}:
                                </label>
                                <input type="text" onChange={e => this.handleCepChange(e)} id="register-cep" className="form-control box-shadow" placeholder={this.translate("ADMIN_COMPANY_REGISTER_PLACEHOLDER_CEP")} disabled required/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3">
                            <div className="form-group">
                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                {this.translate("ADMIN_COMPANY_REGISTER_LABEL_STATE")}:
                                </label>
                                <input type="text" onChange={e => this.setState({formRegister: {...this.state.formRegister, state: e.target.value}})} defaultValue={this.state.formRegister.state} id="register-state" className="form-control box-shadow" placeholder={this.translate("ADMIN_COMPANY_REGISTER_PLACEHOLDER_STATE")} disabled required/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3">
                            <div className="form-group">
                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                    {this.translate("ADMIN_COMPANY_REGISTER_LABEL_CITY")}:
                                </label>
                                <input type="text" onChange={e => this.setState({formRegister: {...this.state.formRegister, city: e.target.value}})} defaultValue={this.state.formRegister.city} id="register-city" className="form-control box-shadow" placeholder={this.translate("ADMIN_COMPANY_REGISTER_PLACEHOLDER_CITY")} disabled required/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <div className="form-group">
                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                    {this.translate("ADMIN_COMPANY_REGISTER_LABEL_NEIGHBORHOOD")}:
                                </label>
                                <input type="text" onChange={e => this.setState({formRegister: {...this.state.formRegister, district: e.target.value}})} defaultValue={this.state.formRegister.district} id="register-district" className="form-control box-shadow" placeholder={this.translate("ADMIN_COMPANY_REGISTER_PLACEHOLDER_NEIGHBORHOOD")} disabled required/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                {this.translate("ADMIN_COMPANY_REGISTER_LABEL_ADDRESS")}:
                                </label>
                                <input type="text" onChange={e => this.setState({formRegister: {...this.state.formRegister, place: e.target.value}})} defaultValue={this.state.formRegister.place} id="register-place" className="form-control box-shadow" placeholder={this.translate("ADMIN_COMPANY_REGISTER_PLACEHOLDER_ADDRESS")} disabled required/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-2">
                            <div className="form-group">
                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                    {this.translate("ADMIN_COMPANY_REGISTER_LABEL_NUMBER")}:
                                </label>
                                <input type="number" min="1" onChange={e => this.setState({formRegister: {...this.state.formRegister, number: e.target.value}})} className="form-control box-shadow" placeholder={this.translate("ADMIN_COMPANY_REGISTER_PLACEHOLDER_NUMBER")} required/>
                            </div>
                        </div>
                        <div className="box-divider mb10 mt10 width100"></div>
                        <div className="col-sm-12 col-md-3 col-lg-3">
                            <div className="form-group">
                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                    {this.translate("ADMIN_COMPANY_REGISTER_LABEL_TOTAL_OF_BUSINESS")}:
                                </label>
                                <input type="number" min="1" onChange={e => this.setState({formRegister: {...this.state.formRegister, total_places: e.target.value}})} className="form-control box-shadow" placeholder={this.translate("ADMIN_COMPANY_REGISTER_PLACEHOLDER_TOTAL_OF_BUSINESS")} required/>
                            </div>
                        </div>
                        <div className="box-divider m0 mt20 width100"></div>
                        <div className="col-sm-12 col-md-12 mt20">
                            <button type="submit" className="btn btn-info px-5 btn-rounded btn-shadow pull-right">
                                <i className="fa fa-save"></i> {this.translate("ADMIN_COMPANY_REGISTER_REGISTER_COMPANY")}
                            </button>
                        </div>
                    </div>
                </form>
                <Modal show={this.state.modalResponseShow} onHide={this.handleCloseModalResponse} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                    <div className="d-flex flex-column h-100 bg-blur_w2">
                        <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                            <h5 className="h5 m-0 text-center width100">
                            <i className="fa fa-bell"></i> {this.translate("MODAL_STATUS_STATUS")}</h5>
                            <a href="/" onClick={e=>this.handleCloseModalResponse(e)} className="ml-auto">
                                <span className="text-lg l-h-1x">×</span>
                            </a>
                        </div>
                        <div className="row position10 padding-20px">
                            <span className="col-12 text-center py-2 lead">
                                {this.state.msgResponseShow}
                            </span>
                        </div>
                    </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(CompanyRegister);