import React, { Component } from 'react';
import PeriodBoxTool from '../Structure/PeriodBoxTool';

class SummaryBusinessContentPortals extends Component {
    constructor(props){
        super(props);
        this.cardCustom = false;
        this.props.fields.periodName = this.props.periodName
    }

    handleChange = (periodName, values) => {
        this.cardCustom = true
        this.props.fields.count = values.count
        this.props.fields.visits_count = values.visits_count
        this.props.fields.percentage_meta_visits_current = values.percentage_meta_visits_current
        this.props.fields.percentage_meta_visits_previous_period_reached = values.percentage_meta_visits_previous_period_reached
        this.props.fields.conversions_count = values.conversions_count
        this.props.fields.percentage_meta_conversions_current = values.percentage_meta_conversions_current
        this.props.fields.percentage_meta_conversions_previous_period_reached = values.percentage_meta_conversions_previous_period_reached
        this.props.fields.periodName = periodName
        this.forceUpdate();
    }

    render(){
        (this.cardCustom === false)?(
            this.props.fields.periodName = this.props.periodName
        ):(
            this.cardCustom = false
        )
        return (
            <div className="col-6 col-md-6 col-lg-4">
                <div className="box p-3 box-shadow2">
                    <div className="d-flex">
                        <span className="text-muted">Portais imobiliários</span>
                    </div>
                    <div className="py-3 text-center text-lg text-warning lh" data-toggle="modal" data-target="#m_landings">
                        <a href="/" onClick={(e) => {e.preventDefault()}}>
                            <i className="fa fa-building-o f48"></i>
                            <br/>
                            <span className="f16">{this.props.fields.count} portais</span>
                            <br/>
                        </a>            
                    </div>
                    <div className="progress my-1" style={{height:"6px"}}>
                        <div className="progress-bar info" style={{width: this.props.fields.percentage_meta_visits_current +"%"}}></div>
                    </div>
                    <div className="d-flex">
                        <span className="flex text-muted f10">Visitas: {this.props.fields.visits_count} ({this.props.fields.percentage_meta_visits_current}% da meta)</span>
                        <span title="Período anterior">
                            <i className="fa fa-caret-up text-success"></i> {this.props.fields.percentage_meta_visits_previous_period_reached}%
                        </span>
                    </div>
                    <hr className="hr_w mt5 mb5"/>
                    <div className="progress my-1" style={{height:"6px"}}>
                        <div className="progress-bar danger" style={{width: this.props.fields.percentage_meta_conversions_current + "%"}}></div>
                    </div>
                    <div className="d-flex">
                        <span className="flex text-muted f10">Conversões: {this.props.fields.conversions_count} ({this.props.fields.percentage_meta_conversions_current}% da meta)</span>
                        <span title="Período anterior">
                            <i className="fa fa-caret-down text-danger"></i> {this.props.fields.percentage_meta_conversions_previous_period_reached}%
                        </span>
                    </div>
                    <PeriodBoxTool onChange={this.handleChange} type={this.props.type} comment_box={true}/>
                </div>
            </div>
        );
    }
}

export default SummaryBusinessContentPortals;