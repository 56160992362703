import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';

import '../Structure/Styles/Common.css'

class Maintenance extends Component {
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 text-center mt-5" style={{fontSize: "18px"}}>
                        Pagina em manutenção aguarde!
                        <br/>
                        <a href="/dashboard" style={{fontWeight: "bold"}}>Clique aqui para voltar</a>!
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Maintenance);