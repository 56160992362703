import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'

//import api from '../../../Services/Api';

import Modal from 'react-bootstrap/Modal';
import api from '../../../Services/Api';

class AttendanceConfigOptions extends Component {

    constructor(props){
        super(props);
        this.state = {
            isModalResponseVisible: false,
            isModalCreateQuickMessageVisible: false,
            isLoadingCreateQuickMessage: false,
            isConfirmModalVisible: false,
            confirmModalMessage: "",
            waitingTime: [
                {
                    id: "asd12313",
                    name: "in_attendance",
                    maximumWaitingTime: "02:52",
                    action: "asd3"
                
                },
                {
                    id: "232323adasda",
                    name: "sent_to_deliveryman",
                    maximumWaitingTime: "01:50",
                    action: "asd"
                
                }
            ],
            actions: [
                {value: "asd", name: "ação de teste"},
                {value: "asd2", name: "ação de teste 2"},
                {value: "asd3", name: "ação de teste 3"},
            ],
            quickMessages: [],
            quickMessageEditId: null,
            quickMessageRemoveId: null,
            
        }
        this.translate = this.props.translate
        this.index = 0
        this.confirmModalCallback = null
    }

    getIndex = () => ++this.index

    getStatusName = (key) => {
        let name = ""
        switch (key) {
            case "in_attendance":
                name = this.props.translate("ATTENDANCE_CONFIG_CONFIG_OPTIONS_IN_ATTENDANCE")
                break
            case "sent_to_deliveryman":
                name = this.props.translate("ATTENDANCE_CONFIG_CONFIG_OPTIONS_SENT_TO_DELIVERYMAN")
                break
            default:
                name = key
                break
        }
        return name

    }
   
    handleCloseModalResponse = (e = null) =>{
        if (e) e.preventDefault()
        this.setState({isModalResponseVisible: false})
    }

    handleCloseCreateQuickMessage = (e = null) =>{
        if (e) e.preventDefault()
        this.setState({isModalCreateQuickMessageVisible: false})
    }
    
    handleCloseConfirmModal = (e = null) =>{
        if (e) e.preventDefault()
        this.setState({isConfirmModalVisible: false})
    }

    loadActions = async () => {
        try {
            let response
            //let response = await api.get("")
            if(response && response.data.actions){
                this.setState({actions: response.data.actions})
            }
        } catch (error) {
            console.log(error)
        }
    }

    getWaitingTime = async () => {
        try {
            let response
            //let response = await api.get("")
            if(response && response.data.waitingTime){
                this.setState({waitingTime: response.data.waitingTime})
            }
        } catch (error) {
            console.log(error)
        }
    }

    updateField = (e, newStatus, field) => {
        let value = e.target.value
        let {waitingTime} = this.state

        waitingTime.forEach(status=> {
            if(status.id === newStatus.id){
                status[field] = value
            }
        })

        this.setState({waitingTime})
    }

    handleSubmitWaitingTime = async (e) => {
        e.preventDefault()
        try {
            //let response = await api.post("", {automaticAnswer: this.state.automaticAnswer})
            let response = {data: {success: false}}
            if(response && response.data.success){
                this.setState({msgResponseShow: this.props.translate("ATTENDANCE_CONFIG_CONFIG_OPTIONS_WAITING_TIME_REGISTERED_WITH_SUCCESS"), isModalResponseVisible: true})
            } else {
                this.setState({msgResponseShow: this.props.translate("ATTENDANCE_CONFIG_CONFIG_OPTIONS_IT_WASNT_POSSIBLE_TO_REGISTER_WAITING_TIME"), isModalResponseVisible: true})
            }
        } catch (error) {
            console.log(error)
        }
    }

    loadQuickMessages = async () => {
        try {
            //let response = await api.get(`/attendance/get/quickMessages/`)
           let response = {
                data: {
                    success: true, 
                    quickMessages: [
                        {id: 1, message: "Teste de mensagem rápida 1"},
                        {id: 2, message: "Este é um teste de mensagem rápida e, no entanto, longa. Isso, para o auxiliar no  processo de atendimento ao usuário"},
                    ]
                }
            } 
            if(response && response.data && response.data.quickMessages && response.data.quickMessages.length){
                this.setState({quickMessages: response.data.quickMessages})
                this.index = response.data.quickMessages.length
            }
        } catch (error) {
            console.log(error)
        }
    }

    handleChangeQuickMessage = (e, newMessage) => {
        let {quickMessages} = this.state

        quickMessages.forEach(message=>{
            if(message.id === newMessage.id) message.message = e.target.value
        })
        console.log(e.target.value)
        this.setState({quickMessages})
    }

    handleConfirmUpdateQuickMessage = async id => {
        let {quickMessages} = this.state
        let messageToUpdate = ""
        quickMessages.forEach(message=>{
            if(message.id === id) messageToUpdate = message.message
        })
        
        this.setState({quickMessageEditId: null})
        
        let success = await this.updateQuickMessage(id, "edit", messageToUpdate)
        if(!success){
            this.loadQuickMessages()
            this.setState({msgResponseShow: this.translate('ATTENDANCE_CONFIG_CONFIG_OPTIONS_IT_WASNT_POSSIBLE_TO_EDIT_THE_MESSAGE'), isModalResponseVisible: true, quickMessageEditId: null, quickMessageRemoveId: null})
        }
    }
    
    handleRemoveMessageClick = (id) => {
        let quickMessageRemoveId = id
        this.confirmModalCallback = this.handleConfirmRemoveQuickMessage
        this.setState({quickMessageRemoveId, isConfirmModalVisible: true, confirmModalMessage: this.translate('ATTENDANCE_CONFIG_CONFIG_OPTIONS_ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_QUICK_MESSAGE')})
    }

    handleConfirmRemoveQuickMessage = async () => {
        let {quickMessages, quickMessageRemoveId, quickMessageEditId} = this.state
        let id = quickMessageRemoveId
        let newQuickMessages = quickMessages.filter(message=>message.id !== quickMessageRemoveId)
        
        if(quickMessageEditId === quickMessageRemoveId) {
            quickMessageRemoveId = null
            quickMessageEditId = null
        }

        this.setState({quickMessages: newQuickMessages, quickMessageEditId, quickMessageRemoveId, confirmModalMessage: "", isConfirmModalVisible: false})
        
        let success = await this.updateQuickMessage(id, "remove")
        if(!success){
            this.loadQuickMessages()
            this.setState({msgResponseShow: this.translate('ATTENDANCE_CONFIG_CONFIG_OPTIONS_IT_WASNT_POSSIBLE_TO_REMOVE_THE_MESSAGE'), isModalResponseVisible: true, quickMessageEditId: null, quickMessageRemoveId: null})
        }
    }

    updateQuickMessage = async (id, action, message = null) => {
        let success = false
        try {
            //let response = await api.post("/attendance/", {action: action, message: message, id: id})
            let response = {data:{success: true}}
            if(response && response.data.success){
                success = true
            }
        } catch (err) {
            console.log("updateQuickMessage", err)
        }
        return success
    }

    handleCreateQuickMessage = async() => {
        let {newQuickMessage, quickMessages} = this.state
        let id = this.getIndex()
        let message = newQuickMessage

        this.setState({isLoadingCreateQuickMessage: true})

        let success = await this.updateQuickMessage(id, "create", message)
        
        if(success){
            quickMessages.push({id, message})
            this.setState({newQuickMessage: "", isModalCreateQuickMessageVisible: false, quickMessages, msgResponseShow: this.translate('ATTENDANCE_CONFIG_CONFIG_OPTIONS_QUICK_MESSAGE_CREATED_WITH_SUCCESS'), isModalResponseVisible: true })
        } else{
            this.setState({msgResponseShow: this.translate('ATTENDANCE_CONFIG_CONFIG_OPTIONS_IT_WASNT_POSSIBLE_TO_CREATE_THE_MESSAGE'), isModalResponseVisible: true})
        }

        this.setState({isLoadingCreateQuickMessage: false})
    }

    componentDidMount = () => {
        this.loadActions()
        this.getWaitingTime()
        this.loadQuickMessages()
    }

    componentDidUpdate = () => {
        //console.log(this.state.waitingTime)
    }

    render() {

        return (
            <React.Fragment>
                <div id="accordion_attendance_config">
                    <div className="card mb-3">
                        <div className="card-header btn-cursor-pointer dark" onClick={()=>this.props.toggleAccordionPannel(`#collapse_attendance_config_waiting_time`)} id="heading_attendance_config_waiting_time">
                            <h5 className="mb-0" data-toggle="collapse" aria-expanded="true" aria-controls="collapse_attendance_config_waiting_time">
                                {this.translate("ATTENDANCE_CONFIG_CONFIG_OPTIONS_WAITING_TIME")}
                                <i className="fa fa-caret-down pull-right"></i>
                            </h5>
                        </div>

                        <div id="collapse_attendance_config_waiting_time" className="collapse dark show" aria-labelledby="heading_attendance_config_waiting_time" data-parent="#accordion_attendance_config">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <form className="px-4" onSubmit={this.handleSubmitWaitingTime}>
                                            {(this.state.waitingTime && this.state.waitingTime.length > 0 && this.state.waitingTime.map((status, index)=> 
                                                <div className="row" key={`waiting_time_${index + status.id}`}>
                                                    <div className="col-12 col-lg-4">
                                                        <div className="form-group">
                                                            <label htmlFor={`waiting_time_status${index}`}>{this.props.translate("ATTENDANCE_CONFIG_CONFIG_OPTIONS_ATTENDANCE_STATUS")}</label>
                                                            <input type="text" id={`waiting_time_status${index}`} value={this.getStatusName(status.name)} readOnly className="form-control" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-4">
                                                        <div className="form-group">
                                                            <label htmlFor={`waiting_time_max_time${index}`}>{this.props.translate("ATTENDANCE_CONFIG_CONFIG_OPTIONS_MAXIMUM_WAITING_TIME")}</label>
                                                            <input type="time" id={`waiting_time_max_time${index}`} value={status.maximumWaitingTime} onChange={e=>this.updateField(e, status, "maximumWaitingTime")} placeholder={this.props.translate("ATTENDANCE_CONFIG_CONFIG_OPTIONS_ENTER_THE_MAXIMUM_WAITING_TIME")} className="form-control" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-4">
                                                        <div className="form-group">
                                                            <label htmlFor={`waiting_time_action${index}`}>{this.props.translate("ATTENDANCE_CONFIG_CONFIG_OPTIONS_ACTION")}</label>
                                                            <select className="form-control cool-select" value={status.action} onChange={e=>this.updateField(e, status, "action")} >
                                                                <option>{this.props.translate("ATTENDANCE_CONFIG_CONFIG_OPTIONS_SELECT_THE_ACTION")}</option>
                                                                {(this.state.actions && this.state.actions.length > 0 && this.state.actions.map(action=>
                                                                    <option key={`option${index + action.value}`} value={action.value}>{action.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <hr style={{width: "100%"}} />
                                                </div>
                                            ))}
                                            
                                            <div className="form-group">
                                                <button className="col-12 ml-auto btn btn-primary blue btn-shadow d-flex justify-content-center align-items-center btn-cursor-pointer" style={{maxWidth: 250}}>
                                                    <i className="fa fa-floppy-o"></i>&nbsp;{this.props.translate("ATTENDANCE_CONFIG_CONFIG_OPTIONS_REGISTER_WAITING_TIME")}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-header btn-cursor-pointer dark" onClick={()=>this.props.toggleAccordionPannel(`#collapse_attendance_config_quick_messages`)} id="heading_attendance_config_quick_messages">
                            <h5 className="mb-0" data-toggle="collapse" aria-expanded="true" aria-controls="collapse_attendance_config_quick_messages">
                                {this.translate("ATTENDANCE_CONFIG_CONFIG_OPTIONS_QUICK_MESSAGES")}
                                <i className="fa fa-caret-down pull-right"></i>
                            </h5>
                        </div>

                        <div id="collapse_attendance_config_quick_messages" className="collapse dark" aria-labelledby="heading_attendance_config_quick_messages" data-parent="#accordion_attendance_config">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <form className="px-4">
                                            {(this.state.quickMessages && this.state.quickMessages.length > 0 && this.state.quickMessages.map((message, index)=> 
                                                <div className="row" key={`quick_messages_${index + message.id}`}>
                                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                                        <div className="form-group flex-grow-1 mb-0">
                                                            <textarea id={`quick_messages_status${index}`} value={message.message} onChange={e=>this.handleChangeQuickMessage(e, message)} disabled={this.state.quickMessageEditId === message.id ? "" : "disabled"} className={`form-control ${this.state.quickMessageEditId === message.id ? "border border-primary" : ""}`} rows="1"></textarea>
                                                        </div>

                                                        {this.state.quickMessageEditId === message.id ?
                                                            <button type="button" className="btn btn-success btn-shadow d-flex justify-content-center align-items-center btn-cursor-pointer ml-2" onClick={()=>this.handleConfirmUpdateQuickMessage(message.id)} style={{width: 50, height: 35}}>
                                                                <i className="fa fa-check"></i>
                                                            </button>
                                                        
                                                        :
                                                            <button type="button" className="btn btn-warning orange btn-shadow d-flex justify-content-center align-items-center btn-cursor-pointer ml-2" onClick={()=>this.setState({quickMessageEditId: message.id})} style={{width: 50, height: 35}}>
                                                                <i className="fa fa-edit"></i>
                                                            </button>

                                                        }

                                                        <button type="button" className="btn btn-danger red btn-shadow d-flex justify-content-center align-items-center btn-cursor-pointer ml-2" style={{width: 50, height: 35}} onClick={()=>this.handleRemoveMessageClick(message.id)}>
                                                            <i className="fa fa-trash-o"></i>
                                                        </button>
                                                    </div>
                                                    
                                                    <hr style={{width: "100%"}} />
                                                </div>
                                            ))}
                                            
                                            <div className="form-group">
                                                <button type="button" className="col-12 btn btn-primary blue btn-shadow d-flex justify-content-center align-items-center btn-cursor-pointer" onClick={()=>this.setState({isModalCreateQuickMessageVisible: true})} style={{maxWidth: 250}}>
                                                    <i className="fa fa-plus"></i>&nbsp;{this.props.translate("ATTENDANCE_CONFIG_CONFIG_OPTIONS_ADD_A_QUICK_MESSAGE")}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <Modal show={this.state.isModalResponseVisible} onHide={this.handleCloseModalResponse} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-bell"></i> Status</h5>
                                <a href="/" onClick={e=>this.handleCloseModalResponse(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                <span className="col-12 text-center py-2 lead">
                                    {this.state.msgResponseShow}
                                </span>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.isModalCreateQuickMessageVisible} onHide={this.handleCloseCreateQuickMessage} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-plus"></i> {this.translate("ATTENDANCE_CONFIG_CONFIG_OPTIONS_CREATE_QUICK_MESSAGE")}</h5>
                                <a href="/" onClick={e=>this.handleCloseCreateQuickMessage(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                <form className="col-12 col-md-6 mx-auto">
                                    <div className="form-group">
                                        <label htmlFor="textarea_create_quick_message">{this.translate('ATTENDANCE_CONFIG_CONFIG_OPTIONS_QUICK_MESSAGE')}</label>
                                       <textarea id="textarea_create_quick_message" placeholder={this.translate('ATTENDANCE_CONFIG_CONFIG_OPTIONS_ENTER_THE_QUICK_MESSAGE')} value={this.state.newQuickMessage} onChange={(e)=>this.setState({newQuickMessage: e.target.value})} className="form-control" rows="3"></textarea>
                                    </div> 
                                    <div className="form-group">
                                        {this.state.isLoadingCreateQuickMessage ?
                                            <button type="button" disabled={true} className="btn btn-primary blue btn-shadow d-flex justify-content-center align-items-center btn-cursor-pointer">
                                                <i className="fa fa-spin fa-spinner"></i>&nbsp;{this.props.translate("ICON_LOADING")}
                                            </button>
                                        : 
                                            <button type="button" className="btn btn-primary blue btn-shadow d-flex justify-content-center align-items-center btn-cursor-pointer" onClick={()=>this.handleCreateQuickMessage()}>
                                                <i className="fa fa-plus"></i>&nbsp;{this.props.translate("ATTENDANCE_CONFIG_CONFIG_OPTIONS_CREATE_QUICK_MESSAGE")}
                                            </button>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.isConfirmModalVisible} onHide={this.handleCloseConfirmModal} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-check"></i> {this.translate("MODAL_CONFIRM_TITLE")}</h5>
                                <a href="/" onClick={e=>this.handleCloseConfirmModal(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                <div className="col-12">
                                    <h3 className="py-2 text-center">{this.state.confirmModalMessage}</h3>
                                </div>
                                <div className="col-4 mx-auto">
                                    <button className="btn btn-danger btn-cursor-pointer" style={{ width: '100%' }} onClick={e=>this.handleCloseConfirmModal(e)}>
                                        {this.translate("MODAL_CONFIRM_NO")}
                                    </button>
                                </div>
                                <div className="col-4 mx-auto">
                                    <button className="btn btn-success btn-cursor-pointer" style={{ width: '100%' }} onClick={(e)=>this.confirmModalCallback()}>
                                        {this.translate("MODAL_CONFIRM_YES")}
                                    </button>

                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(AttendanceConfigOptions);