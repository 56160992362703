class Translator {
  constructor() {
    this.setInfoFromLocalStorage();
    this.setTranslations();
  }
  setInfoFromLocalStorage = () => {
    this.type = "real_estate";
    //this.type = "supermarket"
    //this.type = localStorage.getItem("real_estate")
    this.language = navigator.language || navigator.userLanguage;
    //this.language = "pt-BR"
    //this.language = "en-US"

    if (this.language.includes("pt")) {
      this.language = "pt-BR";
    } else if (this.language.includes("en")) {
      this.language = "en-US";
    } else {
      this.language = "pt-BR";
    }

    this.setTranslations();
  };

  setTranslations = () => {
    this.real_estate = {
      "pt-BR": {
        BROKERS_BROKERS: "Imobiliárias",
        BROKERS_LIST_OF_BROKERS: "Lista de Imobiliárias",
        BROKERS_REGISTER_BROKER: "Adicionar Imobiliária",
        BROKERS_ADJUST_PERCENTAGE: "Ajuste Porcentagem",
        BROKERS_LIST_LIST_OF_BROKERS: "Lista de Imobiliárias",
        BROKERS_LIST_TABLE_ACCESS_BROKER_TITLE: "Acessar Imobiliária",
        BROKERS_LIST_TABLE_ACCESS_BROKER_BUTTON: "Acessar",
        BROKERS_LIST_NAME_OF_BROKER: "Nome da Imobiliária",
        BROKERS_LIST_EMAIL_OF_BROKER: "E-mail da Imobiliária",
        BROKERS_LIST_PHONE_OF_BROKER: "Telefone da Imobiliária",
        BROKERS_LIST_STATUS: "Status",
        BROKERS_LIST_PERCENTAGE: "Porcentagem",
        BROKERS_LIST_NUMBER_OF_CALLS: "Qtd. Atendimentos",
        BROKERS_LIST_ACTIONS: "Ações",
        BROKERS_PERCENTAGE_WAIT_WE_ARE_ADJUSTING_THE_PERCENTAGES:
          "Aguarde Estamos Ajustando as Porcentagens.",
        BROKERS_PERCENTAGE_PERCENTAGE_ADJUSTED_WITH_SUCCESS:
          "Porcentagem Ajustada Com Sucesso!",
        BROKERS_PERCENTAGE_ADJUST_PERCENTAGE: "Ajustar Porcentagem",
        BROKERS_PERCENTAGE_NAME_OF_BROKER: "Nome da Imobiliária",
        BROKERS_PERCENTAGE_PERCENTAGE: "Porcentagem",
        BROKERS_PERCENTAGE_SAVE_PERCENTAGE: "Salvar Porcentagem",
        BROKERS_REGISTER_REGISTER_BROKER: "Cadastrar Imobiliária",
        BROKERS_REGISTER_ADD_BROKER: "Adicionar Imobiliária",
        BROKERS_REGISTER_CHOOSE_THE_BROKER: "Escolha a Imobiliária",
        BROKERS_REGISTER_SELECT_THE_BROKER: "Selecione a Imobiliária",
        BROKERS_REGISTER_CHOOSE_EXSISTING_BROKER:
          "Escolher Imobiliária Existente",
        BROKERS_REGISTER_LABEL_NAME: "Nome",
        BROKERS_REGISTER_PLACEHOLDER_NAME_OF_BROKER: "Nome da Imobiliária",
        BROKERS_REGISTER_LABEL_DDI: "DDI",
        BROKERS_REGISTER_PLACEHOLDER_DDI: "DDI",
        BROKERS_REGISTER_LABEL_PHONE: "Telefone",
        BROKERS_REGISTER_PLACEHOLDER_PHONE_OF_BROKER: "Telefone da Imobiliária",
        BROKERS_REGISTER_LABEL_EMAIL: "Email",
        BROKERS_REGISTER_PLACEHOLDER_EMAIL_OF_BROKER: "Email da Imobiliária",
        BROKERS_REGISTER_REGISTER_NEW_BROKER: "Cadastrar Nova Imobiliária",
        BROKERS_REGISTER_REGISTER_STATUS_WAIT:
          "Aguarde Estamos Cadastrando a Imobiliária.",
        BROKERS_REGISTER_REGISTER_STATUS_SUCCESS:
          "Imobiliária Cadastrada Com Sucesso!",

        AGENTS_AGENTS: "Corretores",
        AGENTS_LIST_AGENTS: "Lista de Corretores",
        AGENTS_LIST_DELETE_AGENT_TITLE: "Deletar Corretor",
        AGENTS_LIST_LIST_OF_AGENTS: "Lista de Corretores",
        AGENTS_LIST_NAME_OF_AGENT: "Nome do Corretor",
        AGENTS_LIST_EMAIL_OF_AGENT: "E-mail Corretor",
        AGENTS_LIST_PHONE_OF_AGENT: "Telefone Corretor",
        AGENTS_LIST_NUMBER_OF_CALLS: "Qtd. Atendimentos",
        AGENTS_LIST_DATE_LAST_LEAD: "Data Ultimo Lead",
        AGENTS_LIST_BROKER: "Imobiliária",
        AGENTS_REGISTER_AGENT: "Cadastrar Corretor",
        AGENTS_REGISTER_WAIT_STATUS: "Aguarde Estamos Cadastrando o Corretor.",
        AGENTS_REGISTER_SUCCESS_STATUS: "Corretor Cadastrado Com Sucesso!",
        AGENTS_REGISTER_LABEL_NAME_OF_AGENT: "Nome do Corretor",
        AGENTS_REGISTER_LABEL_OFFICE_OF_AGENT: "Cargo do Corretor",
        AGENTS_REGISTER_LABEL_DDI_OF_AGENT: "DDI",
        AGENTS_REGISTER_LABEL_PHONE_OF_AGENT: "Telefone",
        AGENTS_REGISTER_LABEL_EMAIL_OF_AGENT: "E-mail",
        AGENTS_REGISTER_PLACEHOLDER_NAME_OF_AGENT: "Nome do Corretor",
        AGENTS_REGISTER_PLACEHOLDER_OFFICE_OF_AGENT: "Cargo do Corretor",
        AGENTS_REGISTER_PLACEHOLDER_DDI_OF_AGENT: "DDI",
        AGENTS_REGISTER_PLACEHOLDER_PHONE_OF_AGENT: "Telefone do Corretor",
        AGENTS_REGISTER_PLACEHOLDER_EMAIL_OF_AGENT: "E-mail do Corretor",
        AGENTS_REGISTER_REGISTER_AGENT: "Cadastrar Novo Corretor",

        ADMIN_COMPANY_TITLE: "Admin Incorporadora",
        ADMIN_COMPANY_LIST_COMPANIES: "Listar Incorporadoras",
        ADMIN_COMPANY_REGISTER_COMPANY: "Cadastrar Incorporadora",
        ADMIN_COMPANY_LIST_LIST_OF_COMPANIES: "Listar Incorporadoras",
        ADMIN_COMPANY_LIST_OF_COMPANIES: "Lista de Incorporadoras",
        ADMIN_COMPANY_LIST_ACCESS_BUTTON: "Acessar Companhia",
        ADMIN_COMPANY_LIST_NAME_OF_COMPANY: "Nome da Incorporadora",
        ADMIN_COMPANY_LIST_NUMBER_OF_BUSINESS: "Qtd. Empreendimentos",
        ADMIN_COMPANY_REGISTER_WAIT_STATUS:
          "Aguarde Estamos Cadastrando a Incorporadora.",
        ADMIN_COMPANY_REGISTER_SUCCESS_STATUS:
          "Incorporadora Cadastrada Com Sucesso!",
        ADMIN_COMPANY_REGISTER_LABEL_NAME: "Nome",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_NAME: "Nome da Incorporadora",
        ADMIN_COMPANY_REGISTER_LABEL_CNPJ: "CNPJ",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_CNPJ: "CNPJ da Incorporadora",
        ADMIN_COMPANY_REGISTER_LABEL_DDI: "DDI",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_DDI: "DDI",
        ADMIN_COMPANY_REGISTER_LABEL_PHONE: "Telefone",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_PHONE: "Telefone da Incorporadora",
        ADMIN_COMPANY_REGISTER_LABEL_EMAIL: "E-mail",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_EMAIL: "E-mail da Incorporadora",
        ADMIN_COMPANY_REGISTER_LABEL_COUNTRY: "País",
        ADMIN_COMPANY_REGISTER_SELECT_OPTION_1:
          "Selecione o País da Incorporadora",
        ADMIN_COMPANY_REGISTER_SELECT_OPTION_2: "Brasil",
        ADMIN_COMPANY_REGISTER_SELECT_OPTION_3: "Estados Unidos da América",
        ADMIN_COMPANY_REGISTER_LABEL_CEP: "CEP",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_CEP: "CEP da Incorporadora",
        ADMIN_COMPANY_REGISTER_LABEL_STATE: "Estado",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_STATE: "Estado da Incorporadora",
        ADMIN_COMPANY_REGISTER_LABEL_CITY: "Cidade",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_CITY: "Cidade da Incorporadora",
        ADMIN_COMPANY_REGISTER_LABEL_NEIGHBORHOOD: "Bairro",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_NEIGHBORHOOD:
          "Bairro da Incorporadora",
        ADMIN_COMPANY_REGISTER_LABEL_ADDRESS: "Logradouro",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_ADDRESS:
          "Logradouro da Incorporadora",
        ADMIN_COMPANY_REGISTER_LABEL_NUMBER: "Número",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_NUMBER: "Número da Incorporadora",
        ADMIN_COMPANY_REGISTER_LABEL_TOTAL_OF_BUSINESS:
          "Total de empreendimentos",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_TOTAL_OF_BUSINESS:
          "Informe a quantidade!",
        ADMIN_COMPANY_REGISTER_REGISTER_COMPANY: "Cadastrar Nova Incorporadora",

        ADMIN_BUSINESS_TITLE: "Admin Empreendimentos",
        ADMIN_BUSINESS_LIST_BUSINESS: "Listar Empreendimentos",
        ADMIN_BUSINESS_REGISTER_BUSINESS: "Cadastrar Empreendimento",
        ADMIN_BUSINESS_LIST_TABLE_VISUALIZE_BUTTON: "Visualizar Companhia",
        ADMIN_BUSINESS_LIST_LIST_OF_BUSINESS: "Lista de Empreendimentos",
        ADMIN_BUSINESS_LIST_NAME_OF_BUSINESS: "Nome do Empreendimento",
        ADMIN_BUSINESS_REGISTER_WAIT_STATUS:
          "Aguarde, Estamos Cadastrando o Empreendimento.",
        ADMIN_BUSINESS_REGISTER_SUCCESS_STATUS:
          "Empreendimento Cadastrado Com Sucesso!",
        ADMIN_BUSINESS_REGISTER_LABEL_NAME: "Nome",
        ADMIN_BUSINESS_REGISTER_PLACEHOLDER_NAME: "Nome do Empreendimento",
        ADMIN_BUSINESS_REGISTER_LABEL_EMAIL: "E-mail",
        ADMIN_BUSINESS_REGISTER_PLACEHOLDER_EMAIL: "E-mail do Empreendimento",
        ADMIN_BUSINESS_REGISTER_LABEL_DDI: "DDI",
        ADMIN_BUSINESS_REGISTER_PLACEHOLDER_DDI: "DDI do Empreendimento",
        ADMIN_BUSINESS_REGISTER_LABEL_PHONE: "Telefone",
        ADMIN_BUSINESS_REGISTER_PLACEHOLDER_PHONE: "Telefone do Empreendimento",
        ADMIN_BUSINESS_REGISTER_LABEL_ADDRESS: "Endereço do Empreendimento",
        ADMIN_BUSINESS_REGISTER_LABEL_TYPE: "Tipo de Empreendimento",
        ADMIN_BUSINESS_REGISTER_PLACEHOLDER_TYPE: "Tipo de Empreendimento",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_1:
          "Selecione o Tipo do Empreendimento",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTGROUP_APARTMENT: "Apartamento",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_APARTMENT_DEFAULT:
          "Apartamento Padrão",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_APARTMENT_PENTHOUSE: "Cobertura",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_APARTMENT_FLAT: "Flat",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_APARTMENT_KITNET_STUDIO:
          "Kitnet / Studio",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_APARTMENT_LOFT: "Loft",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTGROUP_HOUSES: "Casas",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_HOUSE_DISTRICT: "Casa Bairro",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_HOUSE_CONDOMINIUM:
          "Casa de Condomínio",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_HOUSE_LOFT: "Sobrado",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_HOUSE_VILLA: "Casa de Vila",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_LAND: "Casa de Terreno",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTGROUP_LANDS: "Terrenos",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_LAND_CONDOMINIUM:
          "Terreno em Condomínio",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_LAND_DISTRICT:
          "Terreno em Bairro Aberto",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTGROUP_RURALS: "Rurais",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_RURAL_SMALL_FARM: "Chácara",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_RURAL_FARM: "Fazenda",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_RURAL_LAND: "Terreno",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTGROUP_COMMERCIAL: "Comercial",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_HOUSE:
          "Casa Comercial",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_BOX_GARAGE:
          "Box/Garagem",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_OFFICE:
          "Conjunto Comercial/Sala",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_WAREHOUSE:
          "Galpão/Depósito/Armazém",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_HOTEL: "Hotel",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_INDUSTRY: "Indústria",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_SHOPPING:
          "Loja Shopping / Centro Comercial",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_STORE: "Loja / Salão",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_MOTEL: "Motel",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_HOSTEL:
          "Pousada/Chalé",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_BUILDING:
          "Prédio Inteiro",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_STUDIO: "Studio",
        ADMIN_BUSINESS_REGISTER_REGISTER_BUSINESS:
          "Cadastrar Novo Empreendimento",

        BUSINESS_COMPETITORS_LIST_VISUALIZE_BUTTON_TITLE:
          "Visualizar Concorrente",
        BUSINESS_COMPETITORS_LIST_VISUALIZE_BUTTON: "Visualizar",
        BUSINESS_COMPETITORS_WAIT_STATUS:
          "Aguarde, Estamos Cadastrando o Concorrente.",
        BUSINESS_COMPETITORS_SUCCESS_STATUS:
          "Concorrente cadastrado com sucesso!",
        BUSINESS_COMPETITORS_REGISTER_REGISTER_COMPETITOR:
          "Cadastrar Concorrente",
        BUSINESS_COMPETITORS_REGISTER_COMPANY_NAME: "Nome da Incorporadora",
        BUSINESS_COMPETITORS_REGISTER_BUSINESS_NAME: "Nome do Empreendimento",
        BUSINESS_COMPETITORS_REGISTER_BUSINESS_ADDRESS: "Endereço",
        BUSINESS_COMPETITORS_REGISTER_REGISTER_NEW_COMPETITOR:
          "Cadastrar Novo Concorrente",
        BUSINESS_COMPETITORS_LIST_LIST_OF_COMPETITORS: "Lista de Concorrente",
        BUSINESS_COMPETITORS_LIST_COMPANY: "Incorporadora",
        BUSINESS_COMPETITORS_LIST_BUSINESS: "Empreendimento",
        BUSINESS_COMPETITORS_LIST_ADDRESS: "Endereço",
        BUSINESS_COMPETITORS_MODAL_VISUALIZE_COMPETITOR:
          "Visualizar Concorrente",
        BUSINESS_COMPETITORS_MODAL_COMPETITOR_INFO:
          "Informações do Concorrente",
        BUSINESS_COMPETITORS_MODAL_COMPETITOR_INFO_NAME: "Nome",
        BUSINESS_COMPETITORS_MODAL_COMPETITOR_INFO_ORIGIN_NAME:
          "Empreendimento",
        BUSINESS_COMPETITORS_MODAL_COMPETITOR_INFO_DESTINATION_NAME:
          "Concorrente",

        HOME_QUALIFICATION_LEADS_QUALIFICATION: "Qualificação Leads",
        HOME_QUALIFICATION_LEADS_SUCCESS_STATUS: "Sucesso ao editar o Lead!",
        HOME_QUALIFICATION_STATUS_SUCCESS_STATUS: "Sucesso ao editar o status!",
        HOME_QUALIFICATION_LEADS_SUCCESS_DISTRIBUTION_STATUS:
          "Sucesso ao enviar o lead para distribuição!",
        HOME_QUALIFICATION_LEADS_SUCCESS_DISCARD_STATUS:
          "Sucesso ao descatar o lead!",
        HOME_QUALIFICATION_EDIT_LEAD_BUTTON: "Editar Lead",
        HOME_QUALIFICATION_EDIT_STATUS_BUTTON: "Editar Status",
        HOME_QUALIFICATION_SEND_AGENT_BUTTON: "Enviar para o Corretor",
        HOME_QUALIFICATION_DISCARD_BUTTON: "Descartar",
        HOME_QUALIFICATION_VISUALIZE_BUTTON: "Visualizar",
        HOME_QUALIFICATION_IN_QUALIFICATION: "Em Qualificação",
        HOME_QUALIFICATION_IN_QUALIFICATION_NAME: "Nome",
        HOME_QUALIFICATION_IN_QUALIFICATION_EMAIL: "E-mail",
        HOME_QUALIFICATION_IN_QUALIFICATION_PHONE: "Telefone",
        HOME_QUALIFICATION_IN_QUALIFICATION_RESPONSIBLE_QUALIFIER:
          "Qualificador Responsável",
        HOME_QUALIFICATION_IN_QUALIFICATION_ENTRY_DATE: "Data de Entrada",
        HOME_QUALIFICATION_IN_QUALIFICATION_BUSINESS: "Empreendimento",
        HOME_QUALIFICATION_QUALIFIED_LEADS: "Leads Qualificados",
        HOME_QUALIFICATION_QUALIFIED_LEADS_NAME: "Nome",
        HOME_QUALIFICATION_QUALIFIED_LEADS_EMAIL: "E-mail",
        HOME_QUALIFICATION_QUALIFIED_LEADS_PHONE: "Telefone",
        HOME_QUALIFICATION_QUALIFIED_LEADS_RESPONSIBLE_QUALIFIER:
          "Qualificador Responsável",
        HOME_QUALIFICATION_QUALIFIED_LEADS_AGENT: "Corretor",
        HOME_QUALIFICATION_QUALIFIED_LEADS_AGENT_PHONE: "Telefone do Corretor",
        HOME_QUALIFICATION_QUALIFIED_LEADS_AGENT_EMAIL: "E-mail do Corretor",
        HOME_QUALIFICATION_QUALIFIED_LEADS_ENTRY_DATE: "Data de Entrada",
        HOME_QUALIFICATION_QUALIFIED_LEADS_BUSINESS: "Empreendimento",
        HOME_QUALIFICATION_DISCARDED_LEADS: "Leads Descartados",
        HOME_QUALIFICATION_DISCARDED_LEADS_NAME: "Nome",
        HOME_QUALIFICATION_DISCARDED_LEADS_EMAIL: "E-mail",
        HOME_QUALIFICATION_DISCARDED_LEADS_PHONE: "Telefone",
        HOME_QUALIFICATION_DISCARDED_LEADS_RESPONSIBLE_QUALIFIER:
          "Qualificador Responsável",
        HOME_QUALIFICATION_DISCARDED_LEADS_ENTRY_DATE: "Data de Entrada",
        HOME_QUALIFICATION_DISCARDED_LEADS_BUSINESS: "Empreendimento",
        HOME_QUALIFICATION_DISCARDED_LEADS_DISCARD_REASON: "Motivo Descarte",
        HOME_QUALIFICATION_MODAL_EDIT_LEAD: "Editar Lead",
        HOME_QUALIFICATION_MODAL_EDIT_LEAD_RESPONSIBLE_QUALIFIER:
          "Qualificador Responsavel",
        HOME_QUALIFICATION_MODAL_EDIT_LEAD_SELECT_RESPONSIBLE_QUALIFIER:
          "Selecione o Qualificador",
        HOME_QUALIFICATION_MODAL_EDIT_LEAD_NAME: "Nome do Lead",
        HOME_QUALIFICATION_MODAL_EDIT_LEAD_EMAIL: "E-mail do Lead",
        HOME_QUALIFICATION_MODAL_EDIT_LEAD_PHONE: "Telefone do Lead",
        HOME_QUALIFICATION_MODAL_EDIT_LABEL_ADDITIONAL_INFO:
          "Informações Adicionais",
        HOME_QUALIFICATION_MODAL_EDIT_PLACEHOLDER_ADDITIONAL_INFO:
          "Adicione a informação que deseja ao lead.",
        HOME_QUALIFICATION_MODAL_EDIT_NEW_INFO: "Novas Informações",
        HOME_QUALIFICATION_MODAL_EDIT_REGISTER_NEW_INFO: "Salvar Informações",
        HOME_QUALIFICATION_MODAL_EDIT_STATUS_LEAD: "Editar Status do Lead",
        HOME_QUALIFICATION_MODAL_EDIT_STATUS_LEAD_OPTION_SELECT_STATUS:
          "Selecione o Status",
        HOME_QUALIFICATION_MODAL_EDIT_STATUS_LEAD_OPTION_NO_CONTACT:
          "Sem contato",
        HOME_QUALIFICATION_MODAL_EDIT_STATUS_LEAD_OPTION_TASK_SCHEDULED:
          "Tarefa agendada",
        HOME_QUALIFICATION_MODAL_EDIT_STATUS_LEAD_CHANGE_STATUS:
          "Alterar Status",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION:
          "Enviar Lead Para Distribuição",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_OPTION_CHOOSE_DISTRIBUTION_TYPE:
          "Escolha o Tipo de Distribuição",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_OPTION_SELECT_DISTRIBUTION_TYPE:
          "Selecione o Tipo de Distribuição",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_OPTION_NORMAL_DISTRIBUTION:
          "Distribuição Normal",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_OPTION_SPECIFIC_AGENT:
          "Corretor Específico",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_CHOOSE_RESPONSIBLE_AGENT:
          "Escolha o Corretor Responsável",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_OPTION_SELECT_AGENT:
          "Selecione o Corretor",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_SEND_DISTRIBUTION:
          "Enviar para Distribuição",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD: "Descartar Lead",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_SELECT_DISCARD_REASON:
          "Selecione o Motivo de Descarte",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_NEVER_HEARD:
          "Nunca ouviu falar",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_IMPOSSIBLE_TO_CONTACT:
          "Impossível contatar",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_NOT_A_CUSTOMER:
          "Não é cliente",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_HAS_NO_INTEREST:
          "Não tem interesse",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_WITHOUT_PROFILE:
          "Sem perfil",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_SERVICE_PROVIDER:
          "Prestador de serviço",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_DUPLICATED_EMAIL:
          "Email em duplicidade",
        HOME_QUALIFICATION_MODAL_VISUALIZE_QUALIFIED_LEAD:
          "Visualizar Informações do Lead Qualificado",
        HOME_QUALIFICATION_MODAL_LOADING_QUALIFIED_LEAD_INFO:
          "Carregando Modal de Visualizar Informações do Lead Qualificado...",
        HOME_QUALIFICATION_MODAL_VISUALIZE_DISCARDED_LEAD:
          "Visualizar Informações do Lead Desqualificado",
        HOME_QUALIFICATION_MODAL_LOADING_DISCARDED_LEAD_INFO:
          "Carregando Modal de Visualizar Informações do Lead Desqualificado...",

        LEADS_LIST_LEADS_LIST: "Lista de Leads",
        LEADS_LIST_VISUALIZE_LIST: "Visualizar lead",
        LEADS_LIST_VISUALIZE: "Visualizar",
        LEADS_LIST_RESULTS_PER_PAGE: "Resultados por página",
        LEADS_LIST_SEARCH_A_LEAD: "Procure um lead",
        LEADS_LIST_ADVANCED_SEARCH: "Busca avançada",
        LEADS_LIST_SEARCH_BY_DOMAIN: "Busque por Domínio",
        LEADS_LIST_SELECT_THE_DOMAIN: "Selecione o Domínio",
        LEADS_LIST_SEARCH_BY_ORIGIN: "Busque por origem",
        LEADS_LIST_SELECT_THE_ORIGIN: "Selecione a Origem",
        LEADS_LIST_SEARCH_BY_AGENT: "Busque por Corretor",
        LEADS_LIST_SELECT_THE_AGENT: "Selecione o Corretor",
        LEADS_LIST_DATE: "Data",
        LEADS_LIST_FROM: "De:",
        LEADS_LIST_TO: "Até:",
        LEADS_SEARCH_FOR_BROKER: "Busque por imobiliária",
        LEADS_LIST_SELECT_THE_BROKER: "Selecione a Imobiliária",
        LEADS_LIST_BY_PHONE: "Por telefone",
        LEADS_LIST_ENTER_PHONE: "Informe o Telefone",
        LEADS_LIST_SEARCH_BY_NAME: "Busque Por Nome",
        LEADS_LIST_ENTER_THE_NAME: "Informe o Nome",
        LEADS_LIST_SEARCH: "Buscar",
        LEADS_LIST_CLEAN: "Limpar",
        LEADS_LIST_NUMBER: "Nº",
        LEADS_LIST_NAME: "Nome",
        LEADS_LIST_EMAIL: "E-mail",
        LEADS_LIST_PHONE: "Telefone",
        LEADS_LIST_ORIGIN: "Origem",
        LEADS_LIST_SCORE: "Score",
        LEADS_LIST_SEGMENTATION: "Segmentação",
        LEADS_LIST_ENTRY_DATE: "Data de Entrada",

        LEADS_DATA_LEADS_LIST: "Lista de Leads",
        LEADS_DATA_INFORMATIONS_ABOUT_THE_LEAD: "Informações sobre o lead",
        LEADS_DATA_INFO: "Info",
        LEADS_DATA_TIMELINE: "Timeline",
        LEADS_DATA_BANNERS: "Banners",
        LEADS_DATA_EMAILS: "E-mails",
        LEADS_DATA_AGENTS: "Corretores",
        LEADS_DATA_SITE_VISITS: "Visitas ao site",
        LEADS_DATA_STAGE_OF_FUNNEL: "Etapa do funil",
        LEADS_DATA_AUDIT: "Auditoria",
        LEADS_DATA_LEADS_INFORMATIONS: "Informações do lead",
        LEADS_DATA_OPEN_LEAD_4_0: "Abrir Lead 4.0",
        LEADS_DATA_LEAD_SCORE: "Lead score",
        LEADS_DATA_LEARNING: "Aprendendo...",
        LEADS_DATA_PRIME_TIME: "Prime time",
        LEADS_DATA_DANGER_TIME: "Danger time",
        LEADS_DATA_CALCULATING: "Calculando...",
        LEADS_DATA_DATE_AND_HOUR_OF_CREATION: "Data e hora da criação",
        LEADS_DATA_RESPONSE_TIME: "Tempo de resposta",
        LEADS_DATA_NAVIGATION_TIME: "Tempo de navegação",
        LEADS_DATA_LAST_NAVIGATION: "Última Navegação",
        LEADS_DATA_SEGMENTATION: "Segmentação",
        LEADS_DATA_PUBLIC: "Público",
        LEADS_DATA_JOURNEY_MOMENT: "Momento da Jornada",
        LEADS_DATA_CURRENT_AGENT: "Corretor atual",
        LEADS_DATA_PAST_AGENT: "Corretor Passado",
        LEADS_DATA_CURRENT_FUNNEL_STAGE: "Etapa Atual do Fúnil",
        LEADS_DATA_LEADS: "Leads",
        LEADS_DATA_CONVERSIONS: "Conversões",
        LEADS_DATA_FIRST_CONVERSION: "Primeira conversão",
        LEADS_DATA_LAST_CONVERSION: "Última conversão",
        LEADS_DATA_LEAD_4_0: "Lead 4.0",
        LEADS_DATA_FORM_FILLS: "Preenchimentos de Formulário",
        LEADS_DATA_LEAD: "Lead",
        LEADS_DATA_BUSINESS: "Empreendimento",
        LEADS_DATA_VISUALIZED_THE_BANNER: "Visualizou o Banner",
        LEADS_DATA_EMAIL: "E-mail",
        LEADS_DATA_RECEIVED: "Recebido",
        LEADS_DATA_AGENT_NAME: "Nome do corretor",
        LEADS_DATA_BROKER: "Imobiliária",
        LEADS_DATA_PHONE: "Telefone",
        LEADS_DATA_INTERACTION: "Interação",
        LEADS_DATA_PREVIOUS_AGENT: "Corretor Prévio",
        LEADS_DATA_FUNNEL_STAGE: "Etapa no funil",
        LEADS_DATA_VISUALIZE_THIS_LEAD_NAVIGATION:
          "Visualizar Essa Navegação do Lead",
        LEADS_DATA_FEEDBACK_NOTE_CREATED_BY: "Nota de Feedback criada por",
        LEADS_DATA_FEEDBACK_NOTE: "Nota de Feedback",
        LEADS_DATA_FOLLOW_UP_NOTE_CREATED_BY: "Nota de Follow-up criada por",
        LEADS_DATA_FOLLOW_UP_NOTE: "Nota de Follow-ups",
        LEADS_DATA_TASK_CREATED_BY: "Tarefa criada por",
        LEADS_DATA_CREATE_NEW_NOTE: "Criar nova nota",
        LEADS_DATA_CREATE_NEW_TASK: "Criar nova Tarefa",
        LEADS_DATA_NOTE_TYPE: "Tipo de Nota",
        LEADS_DATA_FEEDBACK: "Feedback",
        LEADS_DATA_FOLLOW_UP: "Follow-up",
        LEADS_DATA_POSITIVE: "Positivo",
        LEADS_DATA_NEGATIVE: "Negativo",
        LEADS_DATA_WRITE_YOUR_NOTES: "Escreva suas notas...",
        LEADS_DATA_CALL: "Ligar",
        LEADS_DATA_VISIT: "Visitar",
        LEADS_DATA_TERM: "Prazo",
        LEADS_DATA_TYPE: "Tipo",
        LEADS_DATA_MANAGEMENT: "Gestão",
        LEADS_DATA_CLIENT: "Cliente",
        LEADS_DATA_MEETING: "Reunião",
        LEADS_DATA_HOUR: "Hora",
        LEADS_DATA_DATE: "Data",
        LEADS_DATA_DURATION: "Duração",
        LEADS_DATA_NOTES: "Notas",

        TRIGGERED_EMAIL_SELECT_THE_BROKER: "Selecione a Imobiliária",
        TRIGGERED_EMAIL_SELECT_THE_AGENT: "Selecione o Corretor",

        TRIGGERED_SMS_SELECT_THE_BROKER: "Selecione a Imobiliária",
        TRIGGERED_SMS_SELECT_THE_AGENT: "Selecione o Corretor",
      },
      "en-US": {
        BROKERS_BROKERS: "Real Estates",
        BROKERS_LIST_OF_BROKERS: "List of Real Estates",
        BROKERS_REGISTER_BROKER: "Add Real Estate",
        BROKERS_ADJUST_PERCENTAGE: "Adjust Percentage",
        BROKERS_LIST_LIST_OF_BROKERS: "List of Real Estates",
        BROKERS_LIST_TABLE_ACCESS_BROKER_TITLE: "Access Real Estate",
        BROKERS_LIST_TABLE_ACCESS_BROKER_BUTTON: "Access",
        BROKERS_LIST_NAME_OF_BROKER: "Real Estate Name",
        BROKERS_LIST_EMAIL_OF_BROKER: "Real Estate E-mail",
        BROKERS_LIST_PHONE_OF_BROKER: "Real Estate Phone",
        BROKERS_LIST_STATUS: "Status",
        BROKERS_LIST_PERCENTAGE: "Percentage",
        BROKERS_LIST_NUMBER_OF_CALLS: "Number of Calls",
        BROKERS_LIST_ACTIONS: "Actions",
        BROKERS_PERCENTAGE_WAIT_WE_ARE_ADJUSTING_THE_PERCENTAGES:
          "Wait, We're Adjusting the Percentages.",
        BROKERS_PERCENTAGE_PERCENTAGE_ADJUSTED_WITH_SUCCESS:
          "Percentage Adjusted With Success!",
        BROKERS_PERCENTAGE_ADJUST_PERCENTAGE: "Adjust Percentage",
        BROKERS_PERCENTAGE_NAME_OF_BROKER: "Real Estate Name",
        BROKERS_PERCENTAGE_PERCENTAGE: "Percentage",
        BROKERS_PERCENTAGE_SAVE_PERCENTAGE: "Save Percentage",
        BROKERS_REGISTER_REGISTER_BROKER: "Register Real Estate",
        BROKERS_REGISTER_ADD_BROKER: "Add Real Estate",
        BROKERS_REGISTER_CHOOSE_THE_BROKER: "Choose the Real Estate",
        BROKERS_REGISTER_SELECT_THE_BROKER: "Select the Real Estate",
        BROKERS_REGISTER_CHOOSE_EXSISTING_BROKER: "Choose Existing Real Estate",
        BROKERS_REGISTER_LABEL_NAME: "Name",
        BROKERS_REGISTER_PLACEHOLDER_NAME_OF_BROKER: "Real Estate Name",
        BROKERS_REGISTER_LABEL_DDI: "Country Code",
        BROKERS_REGISTER_PLACEHOLDER_DDI: "Country Code",
        BROKERS_REGISTER_LABEL_PHONE: "Phone",
        BROKERS_REGISTER_PLACEHOLDER_PHONE_OF_BROKER: "Real Estate Phone",
        BROKERS_REGISTER_LABEL_EMAIL: "E-mail",
        BROKERS_REGISTER_PLACEHOLDER_EMAIL_OF_BROKER: "Real Estate E-mail",
        BROKERS_REGISTER_REGISTER_NEW_BROKER: "Register new Real Estate",
        BROKERS_REGISTER_REGISTER_STATUS_WAIT:
          "Wait, We're Registering the Real Estate.",
        BROKERS_REGISTER_REGISTER_STATUS_SUCCESS:
          "Real Estate Registered with Success!",

        AGENTS_AGENTS: "Agents",
        AGENTS_LIST_AGENTS: "List Agents",
        AGENTS_LIST_DELETE_AGENT_TITLE: "Delete Agent",
        AGENTS_LIST_LIST_OF_AGENTS: "List of Agents",
        AGENTS_LIST_NAME_OF_AGENT: "Agent Name",
        AGENTS_LIST_EMAIL_OF_AGENT: "Agent E-mail",
        AGENTS_LIST_PHONE_OF_AGENT: "Agent Phone",
        AGENTS_LIST_NUMBER_OF_CALLS: "Number of Calls",
        AGENTS_LIST_DATE_LAST_LEAD: "Last Lead Date",
        AGENTS_LIST_BROKER: "Broker",
        AGENTS_REGISTER_AGENT: "Register Agent",
        AGENTS_REGISTER_WAIT_STATUS: "Wait, We're Registering the Agent.",
        AGENTS_REGISTER_SUCCESS_STATUS: "Agent Registered with Success!",
        AGENTS_REGISTER_LABEL_NAME_OF_AGENT: "Agent Name",
        AGENTS_REGISTER_LABEL_OFFICE_OF_AGENT: "Agent Office",
        AGENTS_REGISTER_LABEL_DDI_OF_AGENT: "Country Code",
        AGENTS_REGISTER_LABEL_PHONE_OF_AGENT: "Phone",
        AGENTS_REGISTER_LABEL_EMAIL_OF_AGENT: "E-mail",
        AGENTS_REGISTER_PLACEHOLDER_NAME_OF_AGENT: "Agent Name",
        AGENTS_REGISTER_PLACEHOLDER_OFFICE_OF_AGENT: "Agent Office",
        AGENTS_REGISTER_PLACEHOLDER_DDI_OF_AGENT: "Country Code",
        AGENTS_REGISTER_PLACEHOLDER_PHONE_OF_AGENT: "Agent Phone",
        AGENTS_REGISTER_PLACEHOLDER_EMAIL_OF_AGENT: "Agent E-mail",
        AGENTS_REGISTER_REGISTER_AGENT: "Register new Agent",

        ADMIN_COMPANY_TITLE: "Admin Company",
        ADMIN_COMPANY_LIST_COMPANIES: "List Companies",
        ADMIN_COMPANY_REGISTER_COMPANY: "Register Company",
        ADMIN_COMPANY_LIST_LIST_OF_COMPANIES: "List of Companies",
        ADMIN_COMPANY_LIST_OF_COMPANIES: "List of Companies",
        ADMIN_COMPANY_LIST_ACCESS_BUTTON: "Access Company",
        ADMIN_COMPANY_LIST_NAME_OF_COMPANY: "Name of Company",
        ADMIN_COMPANY_LIST_NUMBER_OF_BUSINESS: "Number of Business",
        ADMIN_COMPANY_REGISTER_WAIT_STATUS:
          "Wait, We're Registering the Company.",
        ADMIN_COMPANY_REGISTER_SUCCESS_STATUS:
          "Company Registered with Success!",
        ADMIN_COMPANY_REGISTER_LABEL_NAME: "Name",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_NAME: "Company Name",
        ADMIN_COMPANY_REGISTER_LABEL_CNPJ: "CNPJ",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_CNPJ: "Company CNPJ",
        ADMIN_COMPANY_REGISTER_LABEL_DDI: "Country Code",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_DDI: "Country Code",
        ADMIN_COMPANY_REGISTER_LABEL_PHONE: "Phone",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_PHONE: "Company Phone",
        ADMIN_COMPANY_REGISTER_LABEL_EMAIL: "E-mail",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_EMAIL: "Company E-mail",
        ADMIN_COMPANY_REGISTER_LABEL_COUNTRY: "Country",
        ADMIN_COMPANY_REGISTER_SELECT_OPTION_1: "Select the Country",
        ADMIN_COMPANY_REGISTER_SELECT_OPTION_2: "Brazil",
        ADMIN_COMPANY_REGISTER_SELECT_OPTION_3: "United States Of America",
        ADMIN_COMPANY_REGISTER_LABEL_CEP: "Postcode",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_CEP: "Company Postcode",
        ADMIN_COMPANY_REGISTER_LABEL_STATE: "State",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_STATE: "Company State",
        ADMIN_COMPANY_REGISTER_LABEL_CITY: "City",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_CITY: "Company City",
        ADMIN_COMPANY_REGISTER_LABEL_NEIGHBORHOOD: "Neighborhood",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_NEIGHBORHOOD: "Company Neighborhood",
        ADMIN_COMPANY_REGISTER_LABEL_ADDRESS: "Street Address",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_ADDRESS: "Company Street Address",
        ADMIN_COMPANY_REGISTER_LABEL_NUMBER: "Number",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_NUMBER: "Company Number",
        ADMIN_COMPANY_REGISTER_LABEL_TOTAL_OF_BUSINESS: "Total of Business",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_TOTAL_OF_BUSINESS:
          "Insert the quantity!",
        ADMIN_COMPANY_REGISTER_REGISTER_COMPANY: "Register new Company",

        ADMIN_BUSINESS_TITLE: "Admin Business",
        ADMIN_BUSINESS_LIST_BUSINESS: "List Business",
        ADMIN_BUSINESS_REGISTER_BUSINESS: "Register Business",
        ADMIN_BUSINESS_LIST_TABLE_VISUALIZE_BUTTON: "Visualize Business",
        ADMIN_BUSINESS_LIST_LIST_OF_BUSINESS: "List of Business",
        ADMIN_BUSINESS_LIST_NAME_OF_BUSINESS: "Business Name ",
        ADMIN_BUSINESS_REGISTER_WAIT_STATUS:
          "Wait, We're Registering the Business.",
        ADMIN_BUSINESS_REGISTER_SUCCESS_STATUS:
          "Business Registered With Success!",
        ADMIN_BUSINESS_REGISTER_LABEL_NAME: "Name",
        ADMIN_BUSINESS_REGISTER_PLACEHOLDER_NAME: "Business Name",
        ADMIN_BUSINESS_REGISTER_LABEL_EMAIL: "E-mail",
        ADMIN_BUSINESS_REGISTER_PLACEHOLDER_EMAIL: "Business E-mail",
        ADMIN_BUSINESS_REGISTER_LABEL_DDI: "Country Code",
        ADMIN_BUSINESS_REGISTER_PLACEHOLDER_DDI: "Country Code",
        ADMIN_BUSINESS_REGISTER_LABEL_PHONE: "Phone",
        ADMIN_BUSINESS_REGISTER_PLACEHOLDER_PHONE: "Business Phone",
        ADMIN_BUSINESS_REGISTER_LABEL_ADDRESS: "Business Address",
        ADMIN_BUSINESS_REGISTER_LABEL_TYPE: "Business Type",
        ADMIN_BUSINESS_REGISTER_PLACEHOLDER_TYPE: "Business Type",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_1: "Select the Business Type",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTGROUP_APARTMENT: "Apartment",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_APARTMENT_DEFAULT:
          "Default Apartment",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_APARTMENT_PENTHOUSE: "Penthhouse",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_APARTMENT_FLAT: "Flat",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_APARTMENT_KITNET_STUDIO:
          "Kitnet / Studio",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_APARTMENT_LOFT: "Loft",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTGROUP_HOUSES: "Houses",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_HOUSE_DISTRICT: "House District",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_HOUSE_CONDOMINIUM:
          "House of Condominium",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_HOUSE_LOFT: "Loft",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_HOUSE_VILLA: "Casa de Vila",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_LAND: "Casa de Terreno",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTGROUP_LANDS: "Lands",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_LAND_CONDOMINIUM:
          "Land in Condominium",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_LAND_DISTRICT:
          "Land In Open District",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTGROUP_RURALS: "Rurals",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_RURAL_SMALL_FARM: "Small Farm",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_RURAL_FARM: "Farm",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_RURAL_LAND: "Land",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTGROUP_COMMERCIAL: "Commercial",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_HOUSE:
          "Commercial House",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_BOX_GARAGE:
          "Box/Garage",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_OFFICE:
          "Commercial / Living Room",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_WAREHOUSE:
          "Shed / Warehouse / Warehouse",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_HOTEL: "Hotel",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_INDUSTRY: "Industry",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_SHOPPING:
          "Shopping / Mall",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_STORE: "Shop / Lounge",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_MOTEL: "Motel",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_HOSTEL:
          "Hostel/Chalet",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_BUILDING:
          "Entire Building",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_STUDIO: "Studio",
        ADMIN_BUSINESS_REGISTER_REGISTER_BUSINESS: "Register New Business",

        BUSINESS_COMPETITORS_LIST_VISUALIZE_BUTTON_TITLE:
          "Visualize Competitor",
        BUSINESS_COMPETITORS_LIST_VISUALIZE_BUTTON: "Visualize",
        BUSINESS_COMPETITORS_WAIT_STATUS:
          "Wait, We're Registering the Competitor.",
        BUSINESS_COMPETITORS_SUCCESS_STATUS:
          "Competitor Registered With Success!",
        BUSINESS_COMPETITORS_REGISTER_REGISTER_COMPETITOR:
          "Register Competitor",
        BUSINESS_COMPETITORS_REGISTER_COMPANY_NAME: "Name of the Company",
        BUSINESS_COMPETITORS_REGISTER_BUSINESS_NAME: "Name of the Business",
        BUSINESS_COMPETITORS_REGISTER_BUSINESS_ADDRESS: "Address",
        BUSINESS_COMPETITORS_REGISTER_REGISTER_NEW_COMPETITOR:
          "Register New Competitor",
        BUSINESS_COMPETITORS_LIST_LIST_OF_COMPETITORS: "List of Competitors",
        BUSINESS_COMPETITORS_LIST_COMPANY: "Company",
        BUSINESS_COMPETITORS_LIST_BUSINESS: "Business",
        BUSINESS_COMPETITORS_LIST_ADDRESS: "Address",
        BUSINESS_COMPETITORS_MODAL_VISUALIZE_COMPETITOR: "Visualize Competitor",
        BUSINESS_COMPETITORS_MODAL_COMPETITOR_INFO: "Competitor Information",
        BUSINESS_COMPETITORS_MODAL_COMPETITOR_INFO_NAME: "Name",
        BUSINESS_COMPETITORS_MODAL_COMPETITOR_INFO_ORIGIN_NAME: "Business",
        BUSINESS_COMPETITORS_MODAL_COMPETITOR_INFO_DESTINATION_NAME:
          "Competitor",

        HOME_QUALIFICATION_LEADS_QUALIFICATION: "Leads Qualification",
        HOME_QUALIFICATION_LEADS_SUCCESS_STATUS: "Lead Edited with Success!",
        HOME_QUALIFICATION_STATUS_SUCCESS_STATUS: "Status Edited with Success!",
        HOME_QUALIFICATION_LEADS_SUCCESS_DISTRIBUTION_STATUS:
          "Lead Sent to the Distribution With Success!",
        HOME_QUALIFICATION_LEADS_SUCCESS_DISCARD_STATUS:
          "Lead Discarded With Success!",
        HOME_QUALIFICATION_EDIT_LEAD_BUTTON: "Edit Lead",
        HOME_QUALIFICATION_EDIT_STATUS_BUTTON: "Edit Status",
        HOME_QUALIFICATION_SEND_AGENT_BUTTON: "Send to Agent",
        HOME_QUALIFICATION_DISCARD_BUTTON: "Discard",
        HOME_QUALIFICATION_VISUALIZE_BUTTON: "Visualize",
        HOME_QUALIFICATION_IN_QUALIFICATION: "In Qualification",
        HOME_QUALIFICATION_IN_QUALIFICATION_NAME: "Name",
        HOME_QUALIFICATION_IN_QUALIFICATION_EMAIL: "E-mail",
        HOME_QUALIFICATION_IN_QUALIFICATION_PHONE: "Phone",
        HOME_QUALIFICATION_IN_QUALIFICATION_RESPONSIBLE_QUALIFIER:
          "Responsible Qualifier",
        HOME_QUALIFICATION_IN_QUALIFICATION_ENTRY_DATE: "Entry Date",
        HOME_QUALIFICATION_IN_QUALIFICATION_BUSINESS: "Business",
        HOME_QUALIFICATION_QUALIFIED_LEADS: "Qualified Leads",
        HOME_QUALIFICATION_QUALIFIED_LEADS_NAME: "Name",
        HOME_QUALIFICATION_QUALIFIED_LEADS_EMAIL: "E-mail",
        HOME_QUALIFICATION_QUALIFIED_LEADS_PHONE: "Phone",
        HOME_QUALIFICATION_QUALIFIED_LEADS_RESPONSIBLE_QUALIFIER:
          "Responsible Qualifier",
        HOME_QUALIFICATION_QUALIFIED_LEADS_AGENT: "Agent",
        HOME_QUALIFICATION_QUALIFIED_LEADS_AGENT_PHONE: "Agent Phone",
        HOME_QUALIFICATION_QUALIFIED_LEADS_AGENT_EMAIL: "Agent E-mail",
        HOME_QUALIFICATION_QUALIFIED_LEADS_ENTRY_DATE: "Entry Date",
        HOME_QUALIFICATION_QUALIFIED_LEADS_BUSINESS: "Business",
        HOME_QUALIFICATION_DISCARDED_LEADS: "Discarded Leads",
        HOME_QUALIFICATION_DISCARDED_LEADS_NAME: "Name",
        HOME_QUALIFICATION_DISCARDED_LEADS_EMAIL: "E-mail",
        HOME_QUALIFICATION_DISCARDED_LEADS_PHONE: "Phone",
        HOME_QUALIFICATION_DISCARDED_LEADS_RESPONSIBLE_QUALIFIER:
          "Responsible Qualifier",
        HOME_QUALIFICATION_DISCARDED_LEADS_ENTRY_DATE: "Entry Date",
        HOME_QUALIFICATION_DISCARDED_LEADS_BUSINESS: "Business",
        HOME_QUALIFICATION_DISCARDED_LEADS_DISCARD_REASON: "Discard Reason",
        HOME_QUALIFICATION_MODAL_EDIT_LEAD: "Edit Lead",
        HOME_QUALIFICATION_MODAL_EDIT_LEAD_RESPONSIBLE_QUALIFIER:
          "Responsible Qualifier",
        HOME_QUALIFICATION_MODAL_EDIT_LEAD_SELECT_RESPONSIBLE_QUALIFIER:
          "Select the Qualifier",
        HOME_QUALIFICATION_MODAL_EDIT_LEAD_NAME: "Lead Name",
        HOME_QUALIFICATION_MODAL_EDIT_LEAD_EMAIL: "Lead E-mail",
        HOME_QUALIFICATION_MODAL_EDIT_LEAD_PHONE: "Lead Phone",
        HOME_QUALIFICATION_MODAL_EDIT_LABEL_ADDITIONAL_INFO:
          "Additional Information",
        HOME_QUALIFICATION_MODAL_EDIT_PLACEHOLDER_ADDITIONAL_INFO:
          "Add to the lead the information you want.",
        HOME_QUALIFICATION_MODAL_EDIT_NEW_INFO: "New Information",
        HOME_QUALIFICATION_MODAL_EDIT_REGISTER_NEW_INFO: "Register Information",
        HOME_QUALIFICATION_MODAL_EDIT_STATUS_LEAD: "Edit Lead Status",
        HOME_QUALIFICATION_MODAL_EDIT_STATUS_LEAD_OPTION_SELECT_STATUS:
          "Select the Status",
        HOME_QUALIFICATION_MODAL_EDIT_STATUS_LEAD_OPTION_NO_CONTACT:
          "No contact",
        HOME_QUALIFICATION_MODAL_EDIT_STATUS_LEAD_OPTION_TASK_SCHEDULED:
          "Task Scheduled",
        HOME_QUALIFICATION_MODAL_EDIT_STATUS_LEAD_CHANGE_STATUS:
          "Change Status",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION: "Send Lead to Distribution",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_OPTION_CHOOSE_DISTRIBUTION_TYPE:
          "Choose the Distribution Type",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_OPTION_SELECT_DISTRIBUTION_TYPE:
          "Select the Distribution Type",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_OPTION_NORMAL_DISTRIBUTION:
          "Normal Distribution",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_OPTION_SPECIFIC_AGENT:
          "Specific Agent",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_CHOOSE_RESPONSIBLE_AGENT:
          "Choose the Responsible Agent",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_OPTION_SELECT_AGENT:
          "Select the Agent",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_SEND_DISTRIBUTION:
          "Send to Distribution",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD: "Discard Lead",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_SELECT_DISCARD_REASON:
          "Select the Discard Reason",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_NEVER_HEARD:
          "Never heard about",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_IMPOSSIBLE_TO_CONTACT:
          "Impossible to Contat",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_NOT_A_CUSTOMER:
          "Not a Customer",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_HAS_NO_INTEREST:
          "Has no Interest",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_WITHOUT_PROFILE:
          "Without Profile",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_SERVICE_PROVIDER:
          "Service Provider",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_DUPLICATED_EMAIL:
          "Duplicated E-mail",
        HOME_QUALIFICATION_MODAL_VISUALIZE_QUALIFIED_LEAD:
          "Visualize the Qualified Lead Information",
        HOME_QUALIFICATION_MODAL_LOADING_QUALIFIED_LEAD_INFO:
          "Loading Modal of the Qualified Lead Information",
        HOME_QUALIFICATION_MODAL_VISUALIZE_DISCARDED_LEAD:
          "Visualize Information of the Discarded Lead ",
        HOME_QUALIFICATION_MODAL_LOADING_DISCARDED_LEAD_INFO:
          "Loading Modal of the Discarded Lead Information",

        LEADS_LIST_LEADS_LIST: "Leads List",
        LEADS_LIST_VISUALIZE_LIST: "Visualize Lead",
        LEADS_LIST_VISUALIZE: "Visualize",
        LEADS_LIST_RESULTS_PER_PAGE: "Results Per Page",
        LEADS_LIST_SEARCH_A_LEAD: "Search a lead",
        LEADS_LIST_ADVANCED_SEARCH: "Advanced Search",
        LEADS_LIST_SEARCH_BY_DOMAIN: "Search by Domain",
        LEADS_LIST_SELECT_THE_DOMAIN: "Select the Domain",
        LEADS_LIST_SEARCH_BY_ORIGIN: "Search by Origin",
        LEADS_LIST_SELECT_THE_ORIGIN: "Select the Origin",
        LEADS_LIST_SEARCH_BY_AGENT: "Search by Agent",
        LEADS_LIST_SELECT_THE_AGENT: "Select the Agent",
        LEADS_LIST_DATE: "Date",
        LEADS_LIST_FROM: "From:",
        LEADS_LIST_TO: "To:",
        LEADS_SEARCH_FOR_BROKER: "Search for Real Estate",
        LEADS_LIST_SELECT_THE_BROKER: "Select the Real Estate",
        LEADS_LIST_BY_PHONE: "By Phone",
        LEADS_LIST_ENTER_PHONE: "Enter the Phone",
        LEADS_LIST_SEARCH_BY_NAME: "Search by Name",
        LEADS_LIST_ENTER_THE_NAME: "Enter the Name",
        LEADS_LIST_SEARCH: "Search",
        LEADS_LIST_CLEAN: "Clean",
        LEADS_LIST_NUMBER: "Nº",
        LEADS_LIST_NAME: "Name",
        LEADS_LIST_EMAIL: "E-mail",
        LEADS_LIST_PHONE: "Phone",
        LEADS_LIST_ORIGIN: "Origin",
        LEADS_LIST_SCORE: "Score",
        LEADS_LIST_SEGMENTATION: "Segmentation",
        LEADS_LIST_ENTRY_DATE: "Entry Date",

        LEADS_DATA_LEADS_LIST: "Leads List",
        LEADS_DATA_INFORMATIONS_ABOUT_THE_LEAD: "Informations About the Lead",
        LEADS_DATA_INFO: "Info",
        LEADS_DATA_TIMELINE: "Timeline",
        LEADS_DATA_BANNERS: "Banners",
        LEADS_DATA_EMAILS: "E-mails",
        LEADS_DATA_AGENTS: "Agents",
        LEADS_DATA_SITE_VISITS: "Visits To Websites",
        LEADS_DATA_STAGE_OF_FUNNEL: "Funnel Stage",
        LEADS_DATA_AUDIT: "Audit",
        LEADS_DATA_LEADS_INFORMATIONS: "Lead Informations",
        LEADS_DATA_OPEN_LEAD_4_0: "Open Lead 4.0",
        LEADS_DATA_LEAD_SCORE: "Lead score",
        LEADS_DATA_LEARNING: "Learning...",
        LEADS_DATA_PRIME_TIME: "Prime time",
        LEADS_DATA_DANGER_TIME: "Danger time",
        LEADS_DATA_CALCULATING: "Calculating...",
        LEADS_DATA_DATE_AND_HOUR_OF_CREATION: "Date and hour of Creation",
        LEADS_DATA_RESPONSE_TIME: "Response Time",
        LEADS_DATA_NAVIGATION_TIME: "Navigation Time",
        LEADS_DATA_LAST_NAVIGATION: "Last Navigation",
        LEADS_DATA_SEGMENTATION: "Segmentation",
        LEADS_DATA_PUBLIC: "Public",
        LEADS_DATA_JOURNEY_MOMENT: "Journey Moment",
        LEADS_DATA_CURRENT_AGENT: "Current Agent",
        LEADS_DATA_PAST_AGENT: "Past Agent",
        LEADS_DATA_CURRENT_FUNNEL_STAGE: "Funnel Current Stage",
        LEADS_DATA_LEADS: "Leads",
        LEADS_DATA_CONVERSIONS: "Conversions",
        LEADS_DATA_FIRST_CONVERSION: "First Conversion",
        LEADS_DATA_LAST_CONVERSION: "Last Conversion",
        LEADS_DATA_LEAD_4_0: "Lead 4.0",
        LEADS_DATA_FORM_FILLS: "Form Fills",
        LEADS_DATA_LEAD: "Lead",
        LEADS_DATA_BUSINESS: "Business",
        LEADS_DATA_VISUALIZED_THE_BANNER: "Visualized the Banner",
        LEADS_DATA_EMAIL: "E-mail",
        LEADS_DATA_RECEIVED: "Received",
        LEADS_DATA_AGENT_NAME: "Agent Name",
        LEADS_DATA_BROKER: "Real Estate",
        LEADS_DATA_PHONE: "Phone",
        LEADS_DATA_INTERACTION: "Interaction",
        LEADS_DATA_PREVIOUS_AGENT: "Previous Agent",
        LEADS_DATA_FUNNEL_STAGE: "Funnel Stage",
        LEADS_DATA_VISUALIZE_THIS_LEAD_NAVIGATION:
          "Visualize this Lead Navigation",
        LEADS_DATA_FEEDBACK_NOTE_CREATED_BY: "Feedback Note created By",
        LEADS_DATA_FEEDBACK_NOTE: "Feedback Note",
        LEADS_DATA_FOLLOW_UP_NOTE_CREATED_BY: "Follow-up Note created By",
        LEADS_DATA_FOLLOW_UP_NOTE: "Follow-ups Note",
        LEADS_DATA_TASK_CREATED_BY: "Task created By",
        LEADS_DATA_CREATE_NEW_NOTE: "Create new Note",
        LEADS_DATA_CREATE_NEW_TASK: "Create new Task",
        LEADS_DATA_NOTE_TYPE: "Note Type",
        LEADS_DATA_FEEDBACK: "Feedback",
        LEADS_DATA_FOLLOW_UP: "Follow-up",
        LEADS_DATA_POSITIVE: "Positive",
        LEADS_DATA_NEGATIVE: "Negative",
        LEADS_DATA_WRITE_YOUR_NOTES: "Write your Notes..",
        LEADS_DATA_CALL: "Call",
        LEADS_DATA_VISIT: "Visit",
        LEADS_DATA_TERM: "Term",
        LEADS_DATA_TYPE: "Type",
        LEADS_DATA_MANAGEMENT: "Management",
        LEADS_DATA_CLIENT: "Client",
        LEADS_DATA_MEETING: "Meeting",
        LEADS_DATA_HOUR: "Hour",
        LEADS_DATA_DATE: "Date",
        LEADS_DATA_DURATION: "Duration",
        LEADS_DATA_NOTES: "Notes",

        TRIGGERED_EMAIL_SELECT_THE_BROKER: "Select the Real Estate",
        TRIGGERED_EMAIL_SELECT_THE_AGENT: "Select the Agent",

        TRIGGERED_SMS_SELECT_THE_BROKER: "Select the Real Estate",
        TRIGGERED_SMS_SELECT_THE_AGENT: "Select the Agent",
      },
    };

    this.supermarket = {
      "pt-BR": {
        BROKERS_BROKERS: "Gerentes Comerciais",
        BROKERS_LIST_OF_BROKERS: "Lista de Gerentes Comerciais",
        BROKERS_REGISTER_BROKER: "Adicionar Gerente Comercial",
        BROKERS_ADJUST_PERCENTAGE: "Ajuste Porcentagem",
        BROKERS_LIST_LIST_OF_BROKERS: "Lista de Gerentes Comerciais",
        BROKERS_LIST_TABLE_ACCESS_BROKER_TITLE: "Acessar Gerente Comercial",
        BROKERS_LIST_TABLE_ACCESS_BROKER_BUTTON: "Acessar",
        BROKERS_LIST_NAME_OF_BROKER: "Nome do Gerente Comercial",
        BROKERS_LIST_EMAIL_OF_BROKER: "E-mail do Gerente Comercial",
        BROKERS_LIST_PHONE_OF_BROKER: "Telefone do Gerente Comercial",
        BROKERS_LIST_STATUS: "Status",
        BROKERS_LIST_PERCENTAGE: "Porcentagem",
        BROKERS_LIST_NUMBER_OF_CALLS: "Qtd. Atendimentos",
        BROKERS_LIST_ACTIONS: "Ações",
        BROKERS_PERCENTAGE_WAIT_WE_ARE_ADJUSTING_THE_PERCENTAGES:
          "Aguarde Estamos Ajustando as Porcentagens.",
        BROKERS_PERCENTAGE_PERCENTAGE_ADJUSTED_WITH_SUCCESS:
          "Porcentagem Ajustada Com Sucesso!",
        BROKERS_PERCENTAGE_ADJUST_PERCENTAGE: "Ajustar Porcentagem",
        BROKERS_PERCENTAGE_NAME_OF_BROKER: "Nome do Gerente Comercial",
        BROKERS_PERCENTAGE_PERCENTAGE: "Porcentagem",
        BROKERS_PERCENTAGE_SAVE_PERCENTAGE: "Salvar Porcentagem",
        BROKERS_REGISTER_REGISTER_BROKER: "Cadastrar Gerente Comercial",
        BROKERS_REGISTER_ADD_BROKER: "Adicionar Gerente Comercial",
        BROKERS_REGISTER_CHOOSE_THE_BROKER: "Escolha o Gerente Comercial",
        BROKERS_REGISTER_SELECT_THE_BROKER: "Selecione o Gerente Comercial",
        BROKERS_REGISTER_CHOOSE_EXSISTING_BROKER:
          "Escolher o Gerente Comercial",
        BROKERS_REGISTER_LABEL_NAME: "Nome",
        BROKERS_REGISTER_PLACEHOLDER_NAME_OF_BROKER:
          "Nome do Gerente Comercial",
        BROKERS_REGISTER_LABEL_DDI: "DDI",
        BROKERS_REGISTER_PLACEHOLDER_DDI: "DDI",
        BROKERS_REGISTER_LABEL_PHONE: "Telefone",
        BROKERS_REGISTER_PLACEHOLDER_PHONE_OF_BROKER:
          "Telefone do Gerente Comercial",
        BROKERS_REGISTER_LABEL_EMAIL: "Email",
        BROKERS_REGISTER_PLACEHOLDER_EMAIL_OF_BROKER:
          "Email do Gerente Comercial",
        BROKERS_REGISTER_REGISTER_NEW_BROKER:
          "Cadastrar Novo Gerente Comercial",
        BROKERS_REGISTER_REGISTER_STATUS_WAIT:
          "Aguarde Estamos Cadastrando o Gerente Comercial",
        BROKERS_REGISTER_REGISTER_STATUS_SUCCESS:
          "Gerente Comercial Cadastrado Com Sucesso!",

        AGENTS_AGENTS: "Atendentes Comerciais",
        AGENTS_LIST_AGENTS: "Lista de Atendentes Comerciais",
        AGENTS_LIST_DELETE_AGENT_TITLE: "Deletar Atendente Comercial",
        AGENTS_LIST_LIST_OF_AGENTS: "Lista de Atendentes Comerciais",
        AGENTS_LIST_NAME_OF_AGENT: "Nome do Atendente Comercial",
        AGENTS_LIST_EMAIL_OF_AGENT: "E-mail Atendente Comercial",
        AGENTS_LIST_PHONE_OF_AGENT: "Telefone do Atendente Comercial",
        AGENTS_LIST_NUMBER_OF_CALLS: "Qtd. Atendimentos",
        AGENTS_LIST_DATE_LAST_LEAD: "Data Ultimo Lead",
        AGENTS_LIST_BROKER: "Gerente Comercial",
        AGENTS_REGISTER_AGENT: "Cadastrar Atendente Comercial",
        AGENTS_REGISTER_WAIT_STATUS:
          "Aguarde Estamos Cadastrando o Atendente Comercial.",
        AGENTS_REGISTER_SUCCESS_STATUS:
          "Atendente Comercial Cadastrado Com Sucesso!",
        AGENTS_REGISTER_LABEL_NAME_OF_AGENT: "Nome do Atendente Comercial",
        AGENTS_REGISTER_LABEL_OFFICE_OF_AGENT: "Cargo do Atendente Comercial",
        AGENTS_REGISTER_LABEL_DDI_OF_AGENT: "DDI",
        AGENTS_REGISTER_LABEL_PHONE_OF_AGENT: "Telefone",
        AGENTS_REGISTER_LABEL_EMAIL_OF_AGENT: "E-mail",
        AGENTS_REGISTER_PLACEHOLDER_NAME_OF_AGENT:
          "Nome do Atendente Comercial",
        AGENTS_REGISTER_PLACEHOLDER_OFFICE_OF_AGENT:
          "Cargo do Atendente Comercial",
        AGENTS_REGISTER_PLACEHOLDER_DDI_OF_AGENT: "DDI",
        AGENTS_REGISTER_PLACEHOLDER_PHONE_OF_AGENT:
          "Telefone do Atendente Comercial",
        AGENTS_REGISTER_PLACEHOLDER_EMAIL_OF_AGENT:
          "E-mail do Atendente Comercial",
        AGENTS_REGISTER_REGISTER_AGENT: "Cadastrar Novo Atendente Comercial",

        ADMIN_COMPANY_TITLE: "Admin Marketplace",
        ADMIN_COMPANY_LIST_COMPANIES: "Listar Marketplaces",
        ADMIN_COMPANY_REGISTER_COMPANY: "Cadastrar Marketplace",
        ADMIN_COMPANY_LIST_LIST_OF_COMPANIES: "Listar Marketplaces",
        ADMIN_COMPANY_LIST_OF_COMPANIES: "Lista de Marketplaces",
        ADMIN_COMPANY_LIST_ACCESS_BUTTON: "Acessar Supermercado",
        ADMIN_COMPANY_LIST_NAME_OF_COMPANY: "Nome do Marketplace",
        ADMIN_COMPANY_LIST_NUMBER_OF_BUSINESS: "Qtd. de Supermercados",
        ADMIN_COMPANY_REGISTER_WAIT_STATUS:
          "Aguarde Estamos Cadastrando o Marketplace.",
        ADMIN_COMPANY_REGISTER_SUCCESS_STATUS:
          "Marketplace Cadastrado Com Sucesso!",
        ADMIN_COMPANY_REGISTER_LABEL_NAME: "Nome",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_NAME: "Nome do Marketplace",
        ADMIN_COMPANY_REGISTER_LABEL_CNPJ: "CNPJ",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_CNPJ: "CNPJ do Marketplace",
        ADMIN_COMPANY_REGISTER_LABEL_DDI: "DDI",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_DDI: "DDI",
        ADMIN_COMPANY_REGISTER_LABEL_PHONE: "Telefone",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_PHONE: "Telefone do Marketplace",
        ADMIN_COMPANY_REGISTER_LABEL_EMAIL: "E-mail",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_EMAIL: "E-mail do Marketplace",
        ADMIN_COMPANY_REGISTER_LABEL_COUNTRY: "País",
        ADMIN_COMPANY_REGISTER_SELECT_OPTION_1:
          "Selecione o País do Marketplace",
        ADMIN_COMPANY_REGISTER_SELECT_OPTION_2: "Brasil",
        ADMIN_COMPANY_REGISTER_SELECT_OPTION_3: "Estados Unidos da América",
        ADMIN_COMPANY_REGISTER_LABEL_CEP: "CEP",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_CEP: "CEP do Marketplace",
        ADMIN_COMPANY_REGISTER_LABEL_STATE: "Estado",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_STATE: "Estado do Marketplace",
        ADMIN_COMPANY_REGISTER_LABEL_CITY: "Cidade",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_CITY: "Cidade do Marketplace",
        ADMIN_COMPANY_REGISTER_LABEL_NEIGHBORHOOD: "Bairro",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_NEIGHBORHOOD:
          "Bairro do Marketplace",
        ADMIN_COMPANY_REGISTER_LABEL_ADDRESS: "Logradouro",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_ADDRESS: "Logradouro do Marketplace",
        ADMIN_COMPANY_REGISTER_LABEL_NUMBER: "Número",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_NUMBER: "Número do Marketplace",
        ADMIN_COMPANY_REGISTER_LABEL_TOTAL_OF_BUSINESS:
          "Total de Supermercados",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_TOTAL_OF_BUSINESS:
          "Informe a quantidade!",
        ADMIN_COMPANY_REGISTER_REGISTER_COMPANY: "Cadastrar Novo Marketplace",

        ADMIN_BUSINESS_TITLE: "Admin Supermercados",
        ADMIN_BUSINESS_LIST_BUSINESS: "Listar Supermercados",
        ADMIN_BUSINESS_REGISTER_BUSINESS: "Cadastrar Supermercado",
        ADMIN_BUSINESS_LIST_TABLE_VISUALIZE_BUTTON: "Visualizar Companhia",
        ADMIN_BUSINESS_LIST_LIST_OF_BUSINESS: "Lista de Supermercados",
        ADMIN_BUSINESS_LIST_NAME_OF_BUSINESS: "Nome do Supermercado",
        ADMIN_BUSINESS_REGISTER_WAIT_STATUS:
          "Aguarde, Estamos Cadastrando o Supermercado.",
        ADMIN_BUSINESS_REGISTER_SUCCESS_STATUS:
          "Supermercado Cadastrado Com Sucesso!",
        ADMIN_BUSINESS_REGISTER_LABEL_NAME: "Nome",
        ADMIN_BUSINESS_REGISTER_PLACEHOLDER_NAME: "Nome do Supermercado",
        ADMIN_BUSINESS_REGISTER_LABEL_EMAIL: "E-mail",
        ADMIN_BUSINESS_REGISTER_PLACEHOLDER_EMAIL: "E-mail do Supermercado",
        ADMIN_BUSINESS_REGISTER_LABEL_DDI: "DDI",
        ADMIN_BUSINESS_REGISTER_PLACEHOLDER_DDI: "DDI do Supermercado",
        ADMIN_BUSINESS_REGISTER_LABEL_PHONE: "Telefone",
        ADMIN_BUSINESS_REGISTER_PLACEHOLDER_PHONE: "Telefone do Supermercado",
        ADMIN_BUSINESS_REGISTER_LABEL_ADDRESS: "Endereço do Supermercado",
        ADMIN_BUSINESS_REGISTER_LABEL_TYPE: "Tipo de Supermercado",
        ADMIN_BUSINESS_REGISTER_PLACEHOLDER_TYPE: "Tipo de Supermercado",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_1:
          "Selecione o Tipo do Supermercado",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTGROUP_APARTMENT: "Apartamento",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_APARTMENT_DEFAULT:
          "Apartamento Padrão",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_APARTMENT_PENTHOUSE: "Cobertura",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_APARTMENT_FLAT: "Flat",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_APARTMENT_KITNET_STUDIO:
          "Kitnet / Studio",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_APARTMENT_LOFT: "Loft",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTGROUP_HOUSES: "Casas",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_HOUSE_DISTRICT: "Casa Bairro",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_HOUSE_CONDOMINIUM:
          "Casa de Condomínio",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_HOUSE_LOFT: "Sobrado",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_HOUSE_VILLA: "Casa de Vila",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_LAND: "Casa de Terreno",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTGROUP_LANDS: "Terrenos",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_LAND_CONDOMINIUM:
          "Terreno em Condomínio",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_LAND_DISTRICT:
          "Terreno em Bairro Aberto",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTGROUP_RURALS: "Rurais",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_RURAL_SMALL_FARM: "Chácara",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_RURAL_FARM: "Fazenda",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_RURAL_LAND: "Terreno",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTGROUP_COMMERCIAL: "Comercial",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_HOUSE:
          "Casa Comercial",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_BOX_GARAGE:
          "Box/Garagem",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_OFFICE:
          "Conjunto Comercial/Sala",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_WAREHOUSE:
          "Galpão/Depósito/Armazém",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_HOTEL: "Hotel",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_INDUSTRY: "Indústria",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_SHOPPING:
          "Loja Shopping / Centro Comercial",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_STORE: "Loja / Salão",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_MOTEL: "Motel",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_HOSTEL:
          "Pousada/Chalé",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_BUILDING:
          "Prédio Inteiro",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_STUDIO: "Studio",
        ADMIN_BUSINESS_REGISTER_REGISTER_BUSINESS:
          "Cadastrar Novo Supermercado",

        BUSINESS_COMPETITORS_LIST_VISUALIZE_BUTTON_TITLE:
          "Visualizar Concorrente",
        BUSINESS_COMPETITORS_LIST_VISUALIZE_BUTTON: "Visualizar",
        BUSINESS_COMPETITORS_WAIT_STATUS:
          "Aguarde, Estamos Cadastrando o Concorrente.",
        BUSINESS_COMPETITORS_SUCCESS_STATUS:
          "Concorrente cadastrado com sucesso!",
        BUSINESS_COMPETITORS_REGISTER_REGISTER_COMPETITOR:
          "Cadastrar Concorrente",
        BUSINESS_COMPETITORS_REGISTER_COMPANY_NAME: "Nome do Marketplace",
        BUSINESS_COMPETITORS_REGISTER_BUSINESS_NAME: "Nome do Supermercado",
        BUSINESS_COMPETITORS_REGISTER_BUSINESS_ADDRESS: "Endereço",
        BUSINESS_COMPETITORS_REGISTER_REGISTER_NEW_COMPETITOR:
          "Cadastrar Novo Concorrente",
        BUSINESS_COMPETITORS_LIST_LIST_OF_COMPETITORS: "Lista de Concorrente",
        BUSINESS_COMPETITORS_LIST_COMPANY: "Marketplace",
        BUSINESS_COMPETITORS_LIST_BUSINESS: "Supermercado",
        BUSINESS_COMPETITORS_LIST_ADDRESS: "Endereço",
        BUSINESS_COMPETITORS_MODAL_VISUALIZE_COMPETITOR:
          "Visualizar Concorrente",
        BUSINESS_COMPETITORS_MODAL_COMPETITOR_INFO:
          "Informações do Concorrente",
        BUSINESS_COMPETITORS_MODAL_COMPETITOR_INFO_NAME: "Nome",
        BUSINESS_COMPETITORS_MODAL_COMPETITOR_INFO_ORIGIN_NAME: "Supermercado",
        BUSINESS_COMPETITORS_MODAL_COMPETITOR_INFO_DESTINATION_NAME:
          "Concorrente",

        HOME_QUALIFICATION_LEADS_QUALIFICATION: "Qualificação Leads",
        HOME_QUALIFICATION_LEADS_SUCCESS_STATUS: "Sucesso ao editar o Lead!",
        HOME_QUALIFICATION_STATUS_SUCCESS_STATUS: "Sucesso ao editar o status!",
        HOME_QUALIFICATION_LEADS_SUCCESS_DISTRIBUTION_STATUS:
          "Sucesso ao enviar o lead para distribuição!",
        HOME_QUALIFICATION_LEADS_SUCCESS_DISCARD_STATUS:
          "Sucesso ao descatar o lead!",
        HOME_QUALIFICATION_EDIT_LEAD_BUTTON: "Editar Lead",
        HOME_QUALIFICATION_EDIT_STATUS_BUTTON: "Editar Status",
        HOME_QUALIFICATION_SEND_AGENT_BUTTON:
          "Enviar para o Atendente Comercial",
        HOME_QUALIFICATION_DISCARD_BUTTON: "Descartar",
        HOME_QUALIFICATION_VISUALIZE_BUTTON: "Visualizar",
        HOME_QUALIFICATION_IN_QUALIFICATION: "Em Qualificação",
        HOME_QUALIFICATION_IN_QUALIFICATION_NAME: "Nome",
        HOME_QUALIFICATION_IN_QUALIFICATION_EMAIL: "E-mail",
        HOME_QUALIFICATION_IN_QUALIFICATION_PHONE: "Telefone",
        HOME_QUALIFICATION_IN_QUALIFICATION_RESPONSIBLE_QUALIFIER:
          "Qualificador Responsável",
        HOME_QUALIFICATION_IN_QUALIFICATION_ENTRY_DATE: "Data de Entrada",
        HOME_QUALIFICATION_IN_QUALIFICATION_BUSINESS: "Supermercado",
        HOME_QUALIFICATION_QUALIFIED_LEADS: "Leads Qualificados",
        HOME_QUALIFICATION_QUALIFIED_LEADS_NAME: "Nome",
        HOME_QUALIFICATION_QUALIFIED_LEADS_EMAIL: "E-mail",
        HOME_QUALIFICATION_QUALIFIED_LEADS_PHONE: "Telefone",
        HOME_QUALIFICATION_QUALIFIED_LEADS_RESPONSIBLE_QUALIFIER:
          "Qualificador Responsável",
        HOME_QUALIFICATION_QUALIFIED_LEADS_AGENT: "Atendente Comercial",
        HOME_QUALIFICATION_QUALIFIED_LEADS_AGENT_PHONE: "Telefone do A. C.",
        HOME_QUALIFICATION_QUALIFIED_LEADS_AGENT_EMAIL: "E-mail do do A. C.",
        HOME_QUALIFICATION_QUALIFIED_LEADS_ENTRY_DATE: "Data de Entrada",
        HOME_QUALIFICATION_QUALIFIED_LEADS_BUSINESS: "Supermercado",
        HOME_QUALIFICATION_DISCARDED_LEADS: "Leads Descartados",
        HOME_QUALIFICATION_DISCARDED_LEADS_NAME: "Nome",
        HOME_QUALIFICATION_DISCARDED_LEADS_EMAIL: "E-mail",
        HOME_QUALIFICATION_DISCARDED_LEADS_PHONE: "Telefone",
        HOME_QUALIFICATION_DISCARDED_LEADS_RESPONSIBLE_QUALIFIER:
          "Qualificador Responsável",
        HOME_QUALIFICATION_DISCARDED_LEADS_ENTRY_DATE: "Data de Entrada",
        HOME_QUALIFICATION_DISCARDED_LEADS_BUSINESS: "Supermercado",
        HOME_QUALIFICATION_DISCARDED_LEADS_DISCARD_REASON: "Motivo Descarte",
        HOME_QUALIFICATION_MODAL_EDIT_LEAD: "Editar Lead",
        HOME_QUALIFICATION_MODAL_EDIT_LEAD_RESPONSIBLE_QUALIFIER:
          "Qualificador Responsavel",
        HOME_QUALIFICATION_MODAL_EDIT_LEAD_SELECT_RESPONSIBLE_QUALIFIER:
          "Selecione o Qualificador",
        HOME_QUALIFICATION_MODAL_EDIT_LEAD_NAME: "Nome do Lead",
        HOME_QUALIFICATION_MODAL_EDIT_LEAD_EMAIL: "E-mail do Lead",
        HOME_QUALIFICATION_MODAL_EDIT_LEAD_PHONE: "Telefone do Lead",
        HOME_QUALIFICATION_MODAL_EDIT_LABEL_ADDITIONAL_INFO:
          "Informações Adicionais",
        HOME_QUALIFICATION_MODAL_EDIT_PLACEHOLDER_ADDITIONAL_INFO:
          "Adicione a informação que deseja ao lead.",
        HOME_QUALIFICATION_MODAL_EDIT_NEW_INFO: "Novas Informações",
        HOME_QUALIFICATION_MODAL_EDIT_REGISTER_NEW_INFO: "Salvar Informações",
        HOME_QUALIFICATION_MODAL_EDIT_STATUS_LEAD: "Editar Status do Lead",
        HOME_QUALIFICATION_MODAL_EDIT_STATUS_LEAD_OPTION_SELECT_STATUS:
          "Selecione o Status",
        HOME_QUALIFICATION_MODAL_EDIT_STATUS_LEAD_OPTION_NO_CONTACT:
          "Sem contato",
        HOME_QUALIFICATION_MODAL_EDIT_STATUS_LEAD_OPTION_TASK_SCHEDULED:
          "Tarefa agendada",
        HOME_QUALIFICATION_MODAL_EDIT_STATUS_LEAD_CHANGE_STATUS:
          "Alterar Status",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION:
          "Enviar Lead Para Distribuição",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_OPTION_CHOOSE_DISTRIBUTION_TYPE:
          "Escolha o Tipo de Distribuição",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_OPTION_SELECT_DISTRIBUTION_TYPE:
          "Selecione o Tipo de Distribuição",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_OPTION_NORMAL_DISTRIBUTION:
          "Distribuição Normal",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_OPTION_SPECIFIC_AGENT:
          "Atendente Comercial Específico",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_CHOOSE_RESPONSIBLE_AGENT:
          "Escolha o Atendente Comercial Responsável",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_OPTION_SELECT_AGENT:
          "Selecione o Atendente Comercial",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_SEND_DISTRIBUTION:
          "Enviar para Distribuição",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD: "Descartar Lead",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_SELECT_DISCARD_REASON:
          "Selecione o Motivo de Descarte",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_NEVER_HEARD:
          "Nunca ouviu falar",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_IMPOSSIBLE_TO_CONTACT:
          "Impossível contatar",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_NOT_A_CUSTOMER:
          "Não é cliente",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_HAS_NO_INTEREST:
          "Não tem interesse",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_WITHOUT_PROFILE:
          "Sem perfil",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_SERVICE_PROVIDER:
          "Prestador de serviço",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_DUPLICATED_EMAIL:
          "Email em duplicidade",
        HOME_QUALIFICATION_MODAL_VISUALIZE_QUALIFIED_LEAD:
          "Visualizar Informações do Lead Qualificado",
        HOME_QUALIFICATION_MODAL_LOADING_QUALIFIED_LEAD_INFO:
          "Carregando Modal de Visualizar Informações do Lead Qualificado...",
        HOME_QUALIFICATION_MODAL_VISUALIZE_DISCARDED_LEAD:
          "Visualizar Informações do Lead Desqualificado",
        HOME_QUALIFICATION_MODAL_LOADING_DISCARDED_LEAD_INFO:
          "Carregando Modal de Visualizar Informações do Lead Desqualificado...",

        LEADS_LIST_LEADS_LIST: "Lista de Leads",
        LEADS_LIST_VISUALIZE_LIST: "Visualizar lead",
        LEADS_LIST_VISUALIZE: "Visualizar",
        LEADS_LIST_RESULTS_PER_PAGE: "Resultados por página",
        LEADS_LIST_SEARCH_A_LEAD: "Procure um lead",
        LEADS_LIST_ADVANCED_SEARCH: "Busca avançada",
        LEADS_LIST_SEARCH_BY_ORIGIN: "Busque por origem",
        LEADS_LIST_SELECT_THE_ORIGIN: "Selecione a Origem",
        LEADS_LIST_SEARCH_BY_AGENT: "Busque por Atendente Comercial",
        LEADS_LIST_SELECT_THE_AGENT: "Selecione o Atendente Comercial",
        LEADS_LIST_DATE: "Data",
        LEADS_LIST_FROM: "De:",
        LEADS_LIST_TO: "Até:",
        LEADS_SEARCH_FOR_BROKER: "Busque por Gerente Comercial",
        LEADS_LIST_SELECT_THE_BROKER: "Selecione o Gerente Comercial",
        LEADS_LIST_BY_PHONE: "Por telefone",
        LEADS_LIST_ENTER_PHONE: "Informe o Telefone",
        LEADS_LIST_SEARCH_BY_NAME: "Busque Por Nome",
        LEADS_LIST_ENTER_THE_NAME: "Informe o Nome",
        LEADS_LIST_SEARCH: "Buscar",
        LEADS_LIST_CLEAN: "Limpar",
        LEADS_LIST_NUMBER: "Nº",
        LEADS_LIST_NAME: "Nome",
        LEADS_LIST_EMAIL: "E-mail",
        LEADS_LIST_PHONE: "Telefone",
        LEADS_LIST_ORIGIN: "Origem",
        LEADS_LIST_SCORE: "Score",
        LEADS_LIST_SEGMENTATION: "Segmentação",
        LEADS_LIST_ENTRY_DATE: "Data de Entrada",

        LEADS_DATA_LEADS_LIST: "Lista de Leads",
        LEADS_DATA_INFORMATIONS_ABOUT_THE_LEAD: "Informações sobre o lead",
        LEADS_DATA_INFO: "Info",
        LEADS_DATA_TIMELINE: "Timeline",
        LEADS_DATA_BANNERS: "Banners",
        LEADS_DATA_EMAILS: "E-mails",
        LEADS_DATA_AGENTS: "Atendentes Comerciais",
        LEADS_DATA_SITE_VISITS: "Visitas ao site",
        LEADS_DATA_STAGE_OF_FUNNEL: "Etapa do funil",
        LEADS_DATA_AUDIT: "Auditoria",
        LEADS_DATA_LEADS_INFORMATIONS: "Informações do lead",
        LEADS_DATA_OPEN_LEAD_4_0: "Abrir Lead 4.0",
        LEADS_DATA_LEAD_SCORE: "Lead score",
        LEADS_DATA_LEARNING: "Aprendendo...",
        LEADS_DATA_PRIME_TIME: "Prime time",
        LEADS_DATA_DANGER_TIME: "Danger time",
        LEADS_DATA_CALCULATING: "Calculando...",
        LEADS_DATA_DATE_AND_HOUR_OF_CREATION: "Data e hora da criação",
        LEADS_DATA_RESPONSE_TIME: "Tempo de resposta",
        LEADS_DATA_NAVIGATION_TIME: "Tempo de navegação",
        LEADS_DATA_LAST_NAVIGATION: "Última Navegação",
        LEADS_DATA_SEGMENTATION: "Segmentação",
        LEADS_DATA_PUBLIC: "Público",
        LEADS_DATA_JOURNEY_MOMENT: "Momento da Jornada",
        LEADS_DATA_CURRENT_AGENT: "Atendente Comercial atual",
        LEADS_DATA_PAST_AGENT: "Atendente Comercial Passado",
        LEADS_DATA_CURRENT_FUNNEL_STAGE: "Etapa Atual do Fúnil",
        LEADS_DATA_LEADS: "Leads",
        LEADS_DATA_CONVERSIONS: "Conversões",
        LEADS_DATA_FIRST_CONVERSION: "Primeira conversão",
        LEADS_DATA_LAST_CONVERSION: "Última conversão",
        LEADS_DATA_LEAD_4_0: "Lead 4.0",
        LEADS_DATA_FORM_FILLS: "Preenchimentos de Formulário",
        LEADS_DATA_LEAD: "Lead",
        LEADS_DATA_BUSINESS: "Supermercado",
        LEADS_DATA_VISUALIZED_THE_BANNER: "Visualizou o Banner",
        LEADS_DATA_EMAIL: "E-mail",
        LEADS_DATA_RECEIVED: "Recebido",
        LEADS_DATA_AGENT_NAME: "Nome do Atendente Comercial",
        LEADS_DATA_BROKER: "Gerente Comercial",
        LEADS_DATA_PHONE: "Telefone",
        LEADS_DATA_INTERACTION: "Interação",
        LEADS_DATA_PREVIOUS_AGENT: "Atendente Comercial Prévio",
        LEADS_DATA_FUNNEL_STAGE: "Etapa no funil",
        LEADS_DATA_VISUALIZE_THIS_LEAD_NAVIGATION:
          "Visualizar Essa Navegação do Lead",
        LEADS_DATA_FEEDBACK_NOTE_CREATED_BY: "Nota de Feedback criada por",
        LEADS_DATA_FEEDBACK_NOTE: "Nota de Feedback",
        LEADS_DATA_FOLLOW_UP_NOTE_CREATED_BY: "Nota de Follow-up criada por",
        LEADS_DATA_FOLLOW_UP_NOTE: "Nota de Follow-ups",
        LEADS_DATA_TASK_CREATED_BY: "Tarefa criada por",
        LEADS_DATA_CREATE_NEW_NOTE: "Criar nova nota",
        LEADS_DATA_CREATE_NEW_TASK: "Criar nova Tarefa",
        LEADS_DATA_NOTE_TYPE: "Tipo de Nota",
        LEADS_DATA_FEEDBACK: "Feedback",
        LEADS_DATA_FOLLOW_UP: "Follow-up",
        LEADS_DATA_POSITIVE: "Positivo",
        LEADS_DATA_NEGATIVE: "Negativo",
        LEADS_DATA_WRITE_YOUR_NOTES: "Escreva suas notas...",
        LEADS_DATA_CALL: "Ligar",
        LEADS_DATA_VISIT: "Visitar",
        LEADS_DATA_TERM: "Prazo",
        LEADS_DATA_TYPE: "Tipo",
        LEADS_DATA_MANAGEMENT: "Gestão",
        LEADS_DATA_CLIENT: "Cliente",
        LEADS_DATA_MEETING: "Reunião",
        LEADS_DATA_HOUR: "Hora",
        LEADS_DATA_DATE: "Data",
        LEADS_DATA_DURATION: "Duração",
        LEADS_DATA_NOTES: "Notas",

        TRIGGERED_EMAIL_SELECT_THE_BROKER: "Selecione o Gerente Comercial",
        TRIGGERED_EMAIL_SELECT_THE_AGENT: "Selecione o Atendente Comercial",

        TRIGGERED_SMS_SELECT_THE_BROKER: "Selecione o Gerente Comercial",
        TRIGGERED_SMS_SELECT_THE_AGENT: "Selecione o Atendente Comercial",
      },
      "en-US": {
        BROKERS_BROKERS: "Commercial Managers",
        BROKERS_LIST_OF_BROKERS: "List of Commercial Managers",
        BROKERS_REGISTER_BROKER: "Add Commercial Manager",
        BROKERS_ADJUST_PERCENTAGE: "Adjust Percentage",
        BROKERS_LIST_LIST_OF_BROKERS: "List of Commercial Managers",
        BROKERS_LIST_TABLE_ACCESS_BROKER_TITLE: "Access Commercial Manager",
        BROKERS_LIST_TABLE_ACCESS_BROKER_BUTTON: "Access",
        BROKERS_LIST_NAME_OF_BROKER: "Commercial Manager Name",
        BROKERS_LIST_EMAIL_OF_BROKER: "Commercial Manager E-mail",
        BROKERS_LIST_PHONE_OF_BROKER: "Commercial Manager Phone",
        BROKERS_LIST_STATUS: "Status",
        BROKERS_LIST_PERCENTAGE: "Percentage",
        BROKERS_LIST_NUMBER_OF_CALLS: "Number of Calls",
        BROKERS_LIST_ACTIONS: "Actions",
        BROKERS_PERCENTAGE_WAIT_WE_ARE_ADJUSTING_THE_PERCENTAGES:
          "Wait, We're Adjusting the Percentages.",
        BROKERS_PERCENTAGE_PERCENTAGE_ADJUSTED_WITH_SUCCESS:
          "Percentage Adjusted With Success!",
        BROKERS_PERCENTAGE_ADJUST_PERCENTAGE: "Adjust Percentage",
        BROKERS_PERCENTAGE_NAME_OF_BROKER: "Commercial Manager Name",
        BROKERS_PERCENTAGE_PERCENTAGE: "Percentage",
        BROKERS_PERCENTAGE_SAVE_PERCENTAGE: "Save Percentage",
        BROKERS_REGISTER_REGISTER_BROKER: "Register Commercial Manager",
        BROKERS_REGISTER_ADD_BROKER: "Add Commercial Manager",
        BROKERS_REGISTER_CHOOSE_THE_BROKER: "Choose the Commercial Manager",
        BROKERS_REGISTER_SELECT_THE_BROKER: "Select the Commercial Manager",
        BROKERS_REGISTER_CHOOSE_EXSISTING_BROKER:
          "Choose Existing Commercial Manager",
        BROKERS_REGISTER_LABEL_NAME: "Name",
        BROKERS_REGISTER_PLACEHOLDER_NAME_OF_BROKER: "Commercial Manager Name",
        BROKERS_REGISTER_LABEL_DDI: "Country Code",
        BROKERS_REGISTER_PLACEHOLDER_DDI: "Country Code",
        BROKERS_REGISTER_LABEL_PHONE: "Phone",
        BROKERS_REGISTER_PLACEHOLDER_PHONE_OF_BROKER:
          "Commercial Manager Phone",
        BROKERS_REGISTER_LABEL_EMAIL: "Email",
        BROKERS_REGISTER_PLACEHOLDER_EMAIL_OF_BROKER:
          "Commercial Manager Email",
        BROKERS_REGISTER_REGISTER_NEW_BROKER: "Register New Commercial Manager",
        BROKERS_REGISTER_REGISTER_STATUS_WAIT:
          "Wait, We're registering the Commercial Manager.",
        BROKERS_REGISTER_REGISTER_STATUS_SUCCESS:
          "Commercial Manager registered with Success!",

        AGENTS_AGENTS: "Commercial Attendants",
        AGENTS_LIST_AGENTS: "List Commercial Attendants",
        AGENTS_LIST_DELETE_AGENT_TITLE: "Delete Commercial Attendant",
        AGENTS_LIST_LIST_OF_AGENTS: "List of Commercial Attendants",
        AGENTS_LIST_NAME_OF_AGENT: "Commercial Attendant Name",
        AGENTS_LIST_EMAIL_OF_AGENT: "Commercial Attendant E-mail",
        AGENTS_LIST_PHONE_OF_AGENT: "Commercial Attendant Phone",
        AGENTS_LIST_NUMBER_OF_CALLS: "Number of Calls",
        AGENTS_LIST_DATE_LAST_LEAD: "Last Lead Date",
        AGENTS_LIST_BROKER: "Commercial Manager",
        AGENTS_REGISTER_AGENT: "Register Commercial Attendant",
        AGENTS_REGISTER_WAIT_STATUS:
          "Wait, We're registering the Commercial Attendant.",
        AGENTS_REGISTER_SUCCESS_STATUS:
          "Commercial Attendant registered with Success!",
        AGENTS_REGISTER_LABEL_NAME_OF_AGENT: "Commercial Attendant Name",
        AGENTS_REGISTER_LABEL_OFFICE_OF_AGENT: "Commercial Attendant Office",
        AGENTS_REGISTER_LABEL_DDI_OF_AGENT: "Country Code",
        AGENTS_REGISTER_LABEL_PHONE_OF_AGENT: "Phone",
        AGENTS_REGISTER_LABEL_EMAIL_OF_AGENT: "E-mail",
        AGENTS_REGISTER_PLACEHOLDER_NAME_OF_AGENT: "Commercial Attendant Name",
        AGENTS_REGISTER_PLACEHOLDER_OFFICE_OF_AGENT:
          "Commercial Attendant Office",
        AGENTS_REGISTER_PLACEHOLDER_DDI_OF_AGENT: "Country Code",
        AGENTS_REGISTER_PLACEHOLDER_PHONE_OF_AGENT:
          "Commercial Attendant Phone",
        AGENTS_REGISTER_PLACEHOLDER_EMAIL_OF_AGENT:
          "Commercial Attendant E-mail",
        AGENTS_REGISTER_REGISTER_AGENT: "Register new Commercial Attendant",

        ADMIN_COMPANY_TITLE: "Admin Marketplace",
        ADMIN_COMPANY_LIST_COMPANIES: "List Marketplaces",
        ADMIN_COMPANY_REGISTER_COMPANY: "Register Marketplace",
        ADMIN_COMPANY_LIST_LIST_OF_COMPANIES: "List of Marketplaces",
        ADMIN_COMPANY_LIST_OF_COMPANIES: "List of Marketplaces",
        ADMIN_COMPANY_LIST_ACCESS_BUTTON: "Access Marketplace",
        ADMIN_COMPANY_LIST_NAME_OF_COMPANY: "Name of Marketplace",
        ADMIN_COMPANY_LIST_NUMBER_OF_BUSINESS: "Number of Supermarkets",
        ADMIN_COMPANY_REGISTER_WAIT_STATUS:
          "Wait, We're Registering the Marketplace.",
        ADMIN_COMPANY_REGISTER_SUCCESS_STATUS:
          "Marketplace Registered with Success!",
        ADMIN_COMPANY_REGISTER_LABEL_NAME: "Name",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_NAME: "Marketplace Name",
        ADMIN_COMPANY_REGISTER_LABEL_CNPJ: "CNPJ",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_CNPJ: "Marketplace CNPJ",
        ADMIN_COMPANY_REGISTER_LABEL_DDI: "Country Code",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_DDI: "Country Code",
        ADMIN_COMPANY_REGISTER_LABEL_PHONE: "Phone",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_PHONE: "Marketplace Phone",
        ADMIN_COMPANY_REGISTER_LABEL_EMAIL: "E-mail",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_EMAIL: "Marketplace E-mail",
        ADMIN_COMPANY_REGISTER_LABEL_COUNTRY: "Country",
        ADMIN_COMPANY_REGISTER_SELECT_OPTION_1: "Select the Country",
        ADMIN_COMPANY_REGISTER_SELECT_OPTION_2: "Brazil",
        ADMIN_COMPANY_REGISTER_SELECT_OPTION_3: "United States Of America",
        ADMIN_COMPANY_REGISTER_LABEL_CEP: "Postcode",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_CEP: "Marketplace Postcode",
        ADMIN_COMPANY_REGISTER_LABEL_STATE: "State",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_STATE: "Marketplace State",
        ADMIN_COMPANY_REGISTER_LABEL_CITY: "City",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_CITY: "Marketplace City",
        ADMIN_COMPANY_REGISTER_LABEL_NEIGHBORHOOD: "Neighborhood",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_NEIGHBORHOOD:
          "Marketplace Neighborhood",
        ADMIN_COMPANY_REGISTER_LABEL_ADDRESS: "Address",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_ADDRESS: "Marketplace Address",
        ADMIN_COMPANY_REGISTER_LABEL_NUMBER: "Number",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_NUMBER: "Marketplace Number",
        ADMIN_COMPANY_REGISTER_LABEL_TOTAL_OF_BUSINESS: "Total of Supermarkets",
        ADMIN_COMPANY_REGISTER_PLACEHOLDER_TOTAL_OF_BUSINESS:
          "Insert the quantity!",
        ADMIN_COMPANY_REGISTER_REGISTER_COMPANY: "Register new Marketplace",

        ADMIN_BUSINESS_TITLE: "Admin Supermarkets",
        ADMIN_BUSINESS_LIST_BUSINESS: "List Supermarkets",
        ADMIN_BUSINESS_REGISTER_BUSINESS: "Register Supermarket",
        ADMIN_BUSINESS_LIST_TABLE_VISUALIZE_BUTTON: "Visualize Supermarkets",
        ADMIN_BUSINESS_LIST_LIST_OF_BUSINESS: "List of Supermarkets",
        ADMIN_BUSINESS_LIST_NAME_OF_BUSINESS: "Supermarket Name ",
        ADMIN_BUSINESS_REGISTER_WAIT_STATUS:
          "Wait, We're Registering the Supermarket.",
        ADMIN_BUSINESS_REGISTER_SUCCESS_STATUS:
          "Supermarket Registered With Success!",
        ADMIN_BUSINESS_REGISTER_LABEL_NAME: "Name",
        ADMIN_BUSINESS_REGISTER_PLACEHOLDER_NAME: "Supermarket Name",
        ADMIN_BUSINESS_REGISTER_LABEL_EMAIL: "E-mail",
        ADMIN_BUSINESS_REGISTER_PLACEHOLDER_EMAIL: "Supermarket E-mail",
        ADMIN_BUSINESS_REGISTER_LABEL_DDI: "Country Code",
        ADMIN_BUSINESS_REGISTER_PLACEHOLDER_DDI: "Country Code",
        ADMIN_BUSINESS_REGISTER_LABEL_PHONE: "Phone",
        ADMIN_BUSINESS_REGISTER_PLACEHOLDER_PHONE: "Supermarket Phone",
        ADMIN_BUSINESS_REGISTER_LABEL_ADDRESS: "Supermarket Address",
        ADMIN_BUSINESS_REGISTER_LABEL_TYPE: "Supermarket Type",
        ADMIN_BUSINESS_REGISTER_PLACEHOLDER_TYPE: "Supermarket Type",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_1: "Select the Supermarket Type",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTGROUP_APARTMENT: "Apartment",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_APARTMENT_DEFAULT:
          "Default Apartment",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_APARTMENT_PENTHOUSE: "Penthhouse",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_APARTMENT_FLAT: "Flat",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_APARTMENT_KITNET_STUDIO:
          "Kitnet / Studio",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_APARTMENT_LOFT: "Loft",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTGROUP_HOUSES: "Houses",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_HOUSE_DISTRICT: "House District",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_HOUSE_CONDOMINIUM:
          "House of Condominium",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_HOUSE_LOFT: "Loft",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_HOUSE_VILLA: "Casa de Vila",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_LAND: "Casa de Terreno",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTGROUP_LANDS: "Lands",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_LAND_CONDOMINIUM:
          "Land in Condominium",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_LAND_DISTRICT:
          "Land In Open District",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTGROUP_RURALS: "Rurals",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_RURAL_SMALL_FARM: "Small Farm",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_RURAL_FARM: "Farm",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_RURAL_LAND: "Land",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTGROUP_COMMERCIAL: "Commercial",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_HOUSE:
          "Commercial House",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_BOX_GARAGE:
          "Box/Garage",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_OFFICE:
          "Commercial / Living Room",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_WAREHOUSE:
          "Shed / Warehouse / Warehouse",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_HOTEL: "Hotel",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_INDUSTRY: "Industry",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_SHOPPING:
          "Shopping / Mall",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_STORE: "Shop / Lounge",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_MOTEL: "Motel",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_HOSTEL:
          "Hostel/Chalet",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_BUILDING:
          "Entire Building",
        ADMIN_BUSINESS_REGISTER_SELECT_OPTION_COMMERCIAL_STUDIO: "Studio",
        ADMIN_BUSINESS_REGISTER_REGISTER_BUSINESS: "Register New Supermarket",

        BUSINESS_COMPETITORS_LIST_VISUALIZE_BUTTON_TITLE:
          "Visualize Competitor",
        BUSINESS_COMPETITORS_LIST_VISUALIZE_BUTTON: "Visualize",
        BUSINESS_COMPETITORS_WAIT_STATUS:
          "Wait, We're Registering the Competitor.",
        BUSINESS_COMPETITORS_SUCCESS_STATUS:
          "Competitor Registered With Success!",
        BUSINESS_COMPETITORS_REGISTER_REGISTER_COMPETITOR:
          "Register Competitor",
        BUSINESS_COMPETITORS_REGISTER_COMPANY_NAME: "Name of the Marketplace",
        BUSINESS_COMPETITORS_REGISTER_BUSINESS_NAME: "Name of the Supermarket",
        BUSINESS_COMPETITORS_REGISTER_BUSINESS_ADDRESS: "Address",
        BUSINESS_COMPETITORS_REGISTER_REGISTER_NEW_COMPETITOR:
          "Register New Competitor",
        BUSINESS_COMPETITORS_LIST_LIST_OF_COMPETITORS: "List of Competitors",
        BUSINESS_COMPETITORS_LIST_COMPANY: "Marketplace",
        BUSINESS_COMPETITORS_LIST_BUSINESS: "Supermarket",
        BUSINESS_COMPETITORS_LIST_ADDRESS: "Address",
        BUSINESS_COMPETITORS_MODAL_VISUALIZE_COMPETITOR: "Visualize Competitor",
        BUSINESS_COMPETITORS_MODAL_COMPETITOR_INFO: "Competitor Information",
        BUSINESS_COMPETITORS_MODAL_COMPETITOR_INFO_NAME: "Name",
        BUSINESS_COMPETITORS_MODAL_COMPETITOR_INFO_ORIGIN_NAME: "Supermarket",
        BUSINESS_COMPETITORS_MODAL_COMPETITOR_INFO_DESTINATION_NAME:
          "Competitor",

        HOME_QUALIFICATION_LEADS_QUALIFICATION: "Leads Qualification",
        HOME_QUALIFICATION_LEADS_SUCCESS_STATUS: "Lead Edited with Success!",
        HOME_QUALIFICATION_STATUS_SUCCESS_STATUS: "Status Edited with Success!",
        HOME_QUALIFICATION_LEADS_SUCCESS_DISTRIBUTION_STATUS:
          "Lead Sent to the Distribution With Success!",
        HOME_QUALIFICATION_LEADS_SUCCESS_DISCARD_STATUS:
          "Lead Discarded With Success!",
        HOME_QUALIFICATION_EDIT_LEAD_BUTTON: "Edit Lead",
        HOME_QUALIFICATION_EDIT_STATUS_BUTTON: "Edit Status",
        HOME_QUALIFICATION_SEND_AGENT_BUTTON: "Send to Commercial Attendant",
        HOME_QUALIFICATION_DISCARD_BUTTON: "Discard",
        HOME_QUALIFICATION_VISUALIZE_BUTTON: "Visualize",
        HOME_QUALIFICATION_IN_QUALIFICATION: "In Qualification",
        HOME_QUALIFICATION_IN_QUALIFICATION_NAME: "Name",
        HOME_QUALIFICATION_IN_QUALIFICATION_EMAIL: "E-mail",
        HOME_QUALIFICATION_IN_QUALIFICATION_PHONE: "Phone",
        HOME_QUALIFICATION_IN_QUALIFICATION_RESPONSIBLE_QUALIFIER:
          "Responsible Qualifier",
        HOME_QUALIFICATION_IN_QUALIFICATION_ENTRY_DATE: "Entry Date",
        HOME_QUALIFICATION_IN_QUALIFICATION_BUSINESS: "Supermarket",
        HOME_QUALIFICATION_QUALIFIED_LEADS: "Qualified Leads",
        HOME_QUALIFICATION_QUALIFIED_LEADS_NAME: "Name",
        HOME_QUALIFICATION_QUALIFIED_LEADS_EMAIL: "E-mail",
        HOME_QUALIFICATION_QUALIFIED_LEADS_PHONE: "Phone",
        HOME_QUALIFICATION_QUALIFIED_LEADS_RESPONSIBLE_QUALIFIER:
          "Responsible Qualifier",
        HOME_QUALIFICATION_QUALIFIED_LEADS_AGENT: "Commercial Attendant",
        HOME_QUALIFICATION_QUALIFIED_LEADS_AGENT_PHONE: "C. A. Phone",
        HOME_QUALIFICATION_QUALIFIED_LEADS_AGENT_EMAIL: "C. A. E-mail",
        HOME_QUALIFICATION_QUALIFIED_LEADS_ENTRY_DATE: "Entry Date",
        HOME_QUALIFICATION_QUALIFIED_LEADS_BUSINESS: "Supermarket",
        HOME_QUALIFICATION_DISCARDED_LEADS: "Discarded Leads",
        HOME_QUALIFICATION_DISCARDED_LEADS_NAME: "Name",
        HOME_QUALIFICATION_DISCARDED_LEADS_EMAIL: "E-mail",
        HOME_QUALIFICATION_DISCARDED_LEADS_PHONE: "Phone",
        HOME_QUALIFICATION_DISCARDED_LEADS_RESPONSIBLE_QUALIFIER:
          "Responsible Qualifier",
        HOME_QUALIFICATION_DISCARDED_LEADS_ENTRY_DATE: "Entry Date",
        HOME_QUALIFICATION_DISCARDED_LEADS_BUSINESS: "Supermarket",
        HOME_QUALIFICATION_DISCARDED_LEADS_DISCARD_REASON: "Discard Reason",
        HOME_QUALIFICATION_MODAL_EDIT_LEAD: "Edit Lead",
        HOME_QUALIFICATION_MODAL_EDIT_LEAD_RESPONSIBLE_QUALIFIER:
          "Responsible Qualifier",
        HOME_QUALIFICATION_MODAL_EDIT_LEAD_SELECT_RESPONSIBLE_QUALIFIER:
          "Select the Qualifier",
        HOME_QUALIFICATION_MODAL_EDIT_LEAD_NAME: "Lead Name",
        HOME_QUALIFICATION_MODAL_EDIT_LEAD_EMAIL: "Lead E-mail",
        HOME_QUALIFICATION_MODAL_EDIT_LEAD_PHONE: "Lead Phone",
        HOME_QUALIFICATION_MODAL_EDIT_LABEL_ADDITIONAL_INFO:
          "Additional Information",
        HOME_QUALIFICATION_MODAL_EDIT_PLACEHOLDER_ADDITIONAL_INFO:
          "Add to the lead the information you want.",
        HOME_QUALIFICATION_MODAL_EDIT_NEW_INFO: "New Information",
        HOME_QUALIFICATION_MODAL_EDIT_REGISTER_NEW_INFO: "Register Information",
        HOME_QUALIFICATION_MODAL_EDIT_STATUS_LEAD: "Edit Lead Status",
        HOME_QUALIFICATION_MODAL_EDIT_STATUS_LEAD_OPTION_SELECT_STATUS:
          "Select the Status",
        HOME_QUALIFICATION_MODAL_EDIT_STATUS_LEAD_OPTION_NO_CONTACT:
          "No contact",
        HOME_QUALIFICATION_MODAL_EDIT_STATUS_LEAD_OPTION_TASK_SCHEDULED:
          "Task Scheduled",
        HOME_QUALIFICATION_MODAL_EDIT_STATUS_LEAD_CHANGE_STATUS:
          "Change Status",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION: "Send Lead to Distribution",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_OPTION_CHOOSE_DISTRIBUTION_TYPE:
          "Choose the Distribution Type",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_OPTION_SELECT_DISTRIBUTION_TYPE:
          "Select the Distribution Type",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_OPTION_NORMAL_DISTRIBUTION:
          "Normal Distribution",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_OPTION_SPECIFIC_AGENT:
          "Specific Commercial Attendant",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_CHOOSE_RESPONSIBLE_AGENT:
          "Choose the Responsible Commercial Attendant",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_OPTION_SELECT_AGENT:
          "Select the Commercial Attendant",
        HOME_QUALIFICATION_MODAL_SEND_DISTRIBUTION_SEND_DISTRIBUTION:
          "Send to Distribution",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD: "Discard Lead",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_SELECT_DISCARD_REASON:
          "Select the Discard Reason",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_NEVER_HEARD:
          "Never heard about",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_IMPOSSIBLE_TO_CONTACT:
          "Impossible to Contat",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_NOT_A_CUSTOMER:
          "Not a Customer",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_HAS_NO_INTEREST:
          "Has no Interest",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_WITHOUT_PROFILE:
          "Without Profile",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_SERVICE_PROVIDER:
          "Service Provider",
        HOME_QUALIFICATION_MODAL_DISCARD_LEAD_OPTION_DUPLICATED_EMAIL:
          "Duplicated E-mail",
        HOME_QUALIFICATION_MODAL_VISUALIZE_QUALIFIED_LEAD:
          "Visualize the Qualified Lead Information",
        HOME_QUALIFICATION_MODAL_LOADING_QUALIFIED_LEAD_INFO:
          "Loading Modal of the Qualified Lead Information",
        HOME_QUALIFICATION_MODAL_VISUALIZE_DISCARDED_LEAD:
          "Visualize Information of the Discarded Lead ",
        HOME_QUALIFICATION_MODAL_LOADING_DISCARDED_LEAD_INFO:
          "Loading Modal of the Discarded Lead Information",

        LEADS_LIST_LEADS_LIST: "Leads List",
        LEADS_LIST_VISUALIZE_LIST: "Visualize Lead",
        LEADS_LIST_VISUALIZE: "Visualize",
        LEADS_LIST_RESULTS_PER_PAGE: "Results Per Page",
        LEADS_LIST_SEARCH_A_LEAD: "Search a lead",
        LEADS_LIST_ADVANCED_SEARCH: "Advanced Search",
        LEADS_LIST_SEARCH_BY_ORIGIN: "Search by Origin",
        LEADS_LIST_SELECT_THE_ORIGIN: "Select the Origin",
        LEADS_LIST_SEARCH_BY_AGENT: "Search by Commercial Attendant",
        LEADS_LIST_SELECT_THE_AGENT: "Select the Commercial Attendant",
        LEADS_LIST_DATE: "Date",
        LEADS_LIST_FROM: "From:",
        LEADS_LIST_TO: "To:",
        LEADS_SEARCH_FOR_BROKER: "Search by Commercial Manager",
        LEADS_LIST_SELECT_THE_BROKER: "Select the Commercial Manager",
        LEADS_LIST_BY_PHONE: "By Phone",
        LEADS_LIST_ENTER_PHONE: "Enter the Phone",
        LEADS_LIST_SEARCH_BY_NAME: "Search by Name",
        LEADS_LIST_ENTER_THE_NAME: "Enter the Name",
        LEADS_LIST_SEARCH: "Search",
        LEADS_LIST_CLEAN: "Clean",
        LEADS_LIST_NUMBER: "Nº",
        LEADS_LIST_NAME: "Name",
        LEADS_LIST_EMAIL: "E-mail",
        LEADS_LIST_PHONE: "Phone",
        LEADS_LIST_ORIGIN: "Origin",
        LEADS_LIST_SCORE: "Score",
        LEADS_LIST_SEGMENTATION: "Segmentation",
        LEADS_LIST_ENTRY_DATE: "Entry Date",

        LEADS_DATA_LEADS_LIST: "Leads List",
        LEADS_DATA_INFORMATIONS_ABOUT_THE_LEAD: "Informations About the Lead",
        LEADS_DATA_INFO: "Info",
        LEADS_DATA_TIMELINE: "Timeline",
        LEADS_DATA_BANNERS: "Banners",
        LEADS_DATA_EMAILS: "E-mails",
        LEADS_DATA_AGENTS: "Commercial Attendants",
        LEADS_DATA_SITE_VISITS: "Visits To Websites",
        LEADS_DATA_STAGE_OF_FUNNEL: "Funnel Stage",
        LEADS_DATA_AUDIT: "Audit",
        LEADS_DATA_LEADS_INFORMATIONS: "Lead Informations",
        LEADS_DATA_OPEN_LEAD_4_0: "Open Lead 4.0",
        LEADS_DATA_LEAD_SCORE: "Lead score",
        LEADS_DATA_LEARNING: "Learning...",
        LEADS_DATA_PRIME_TIME: "Prime time",
        LEADS_DATA_DANGER_TIME: "Danger time",
        LEADS_DATA_CALCULATING: "Calculating...",
        LEADS_DATA_DATE_AND_HOUR_OF_CREATION: "Date and hour of Creation",
        LEADS_DATA_RESPONSE_TIME: "Response Time",
        LEADS_DATA_NAVIGATION_TIME: "Navigation Time",
        LEADS_DATA_LAST_NAVIGATION: "Last Navigation",
        LEADS_DATA_SEGMENTATION: "Segmentation",
        LEADS_DATA_PUBLIC: "Public",
        LEADS_DATA_JOURNEY_MOMENT: "Journey Moment",
        LEADS_DATA_CURRENT_AGENT: "Current Commercial Attendant",
        LEADS_DATA_PAST_AGENT: "Past Commercial Attendant",
        LEADS_DATA_CURRENT_FUNNEL_STAGE: "Funnel Current Stage",
        LEADS_DATA_LEADS: "Leads",
        LEADS_DATA_CONVERSIONS: "Conversions",
        LEADS_DATA_FIRST_CONVERSION: "First Conversion",
        LEADS_DATA_LAST_CONVERSION: "Last Conversion",
        LEADS_DATA_LEAD_4_0: "Lead 4.0",
        LEADS_DATA_FORM_FILLS: "Form Fills",
        LEADS_DATA_LEAD: "Lead",
        LEADS_DATA_BUSINESS: "Supermarket",
        LEADS_DATA_VISUALIZED_THE_BANNER: "Visualized the Banner",
        LEADS_DATA_EMAIL: "E-mail",
        LEADS_DATA_RECEIVED: "Received",
        LEADS_DATA_AGENT_NAME: "Commercial Attendant Name",
        LEADS_DATA_BROKER: "Commercial Manager",
        LEADS_DATA_PHONE: "Phone",
        LEADS_DATA_INTERACTION: "Interaction",
        LEADS_DATA_PREVIOUS_AGENT: "Previous Commercial Attendant",
        LEADS_DATA_FUNNEL_STAGE: "Funnel Stage",
        LEADS_DATA_VISUALIZE_THIS_LEAD_NAVIGATION:
          "Visualize this Lead Navigation",
        LEADS_DATA_FEEDBACK_NOTE_CREATED_BY: "Feedback Note created By",
        LEADS_DATA_FEEDBACK_NOTE: "Feedback Note",
        LEADS_DATA_FOLLOW_UP_NOTE_CREATED_BY: "Follow-up Note created By",
        LEADS_DATA_FOLLOW_UP_NOTE: "Follow-ups Note",
        LEADS_DATA_TASK_CREATED_BY: "Task created By",
        LEADS_DATA_CREATE_NEW_NOTE: "Create new Note",
        LEADS_DATA_CREATE_NEW_TASK: "Create new Task",
        LEADS_DATA_NOTE_TYPE: "Note Type",
        LEADS_DATA_FEEDBACK: "Feedback",
        LEADS_DATA_FOLLOW_UP: "Follow-up",
        LEADS_DATA_POSITIVE: "Positive",
        LEADS_DATA_NEGATIVE: "Negative",
        LEADS_DATA_WRITE_YOUR_NOTES: "Write your Notes..",
        LEADS_DATA_CALL: "Call",
        LEADS_DATA_VISIT: "Visit",
        LEADS_DATA_TERM: "Term",
        LEADS_DATA_TYPE: "Type",
        LEADS_DATA_MANAGEMENT: "Management",
        LEADS_DATA_CLIENT: "Client",
        LEADS_DATA_MEETING: "Meeting",
        LEADS_DATA_HOUR: "Hour",
        LEADS_DATA_DATE: "Date",
        LEADS_DATA_DURATION: "Duration",
        LEADS_DATA_NOTES: "Notes",

        TRIGGERED_EMAIL_SELECT_THE_BROKER: "Select the Commercial Manager",
        TRIGGERED_EMAIL_SELECT_THE_AGENT: "Select the Commercial Attendant",

        TRIGGERED_SMS_SELECT_THE_BROKER: "Select the Commercial Manager",
        TRIGGERED_SMS_SELECT_THE_AGENT: "Select the Commercial Attendant",
      },
    };

    this.general = {
      "pt-BR": {
        MODAL_CONFIRM_TITLE: "Confirmar Ação",
        MODAL_CONFIRM_YES: "Sim",
        MODAL_CONFIRM_NO: "Não",

        DATATABLE_LANGUAGE_URL:
          "//cdn.datatables.net/plug-ins/1.10.13/i18n/Portuguese-Brasil.json",
        AGENTS_LIST_CHANGE_STATUS_BUTTON: "Alterar Status",
        TABLE_DELETE_BUTTON: "Deletar",
        TABLE_STATUS_ROW: "Status",
        TABLE_ACTIONS_ROW: "Ações",
        MODAL_STATUS_STATUS: "Status",
        TABLE_ACTIONS_ACCESS_BUTTON: "Acessar",
        TABLE_ACTIONS_VISUALIZE_BUTTON: "Visualizar",
        FORM_SELECT_SELECT: "Selecione",

        BUTTON_WAIT: "Aguardar",
        BUTTON_REGISTER: "Salvar",
        ICON_LOADING: "Carregando",
        ICON_WAIT: "Aguardar",
        ICON_REGISTER: "Salvar",
        ICON_ADD: "Adicionar",

        ADMIN_BUSINESS_EDIT_GOALS: "Metas",
        ADMIN_BUSINESS_EDIT_MEDIA: "Plano de Mídia",
        ADMIN_BUSINESS_EDIT_VISIT: "Visita",
        ADMIN_BUSINESS_EDIT_BID: "Proposta",
        ADMIN_BUSINESS_EDIT_DEAL: "Negociação",
        ADMIN_BUSINESS_EDIT_SALES: "Vendas",
        ADMIN_BUSINESS_EDIT_POS_BUSINESS: "Compra Futura",
        ADMIN_BUSINESS_EDIT_COMPETITORS: "Concorrentes",
        ADMIN_BUSINESS_EDIT_NETWORKS: "Redes Sociais",
        ADMIN_BUSINESS_EDIT_LEADS: "Leads",
        ADMIN_BUSINESS_EDIT_CONFIG: "Configurações",

        BUSINESS_GOALS_WAIT_STATUS: "Aguarde, estamos registrando a Meta.",
        BUSINESS_GOALS_SUCCESS_STATUS: "Meta Registrada com Sucesso!",
        BUSINESS_GOALS_SALES_FUNNEL: "Funil de Vendas",
        BUSINESS_GOALS_VGV: "Meta VGV",
        BUSINESS_GOALS_GOAL_SALES_CONTROL_PLACEHOLDER: "Meta",
        BUSINESS_GOALS_REGISTER_GOALS: "Salvar Metas Funil de Vendas",
        BUSINESS_GOALS_PREVIOUS_MONTHS_VGV: "Metas VGV Meses Anteriores",
        BUSINESS_GOALS_TABLE_MONTH: "Mês",
        BUSINESS_GOALS_TABLE_VALUE: "Valor",

        BUSINESS_MEDIA_PLAN_WAIT_STATUS:
          "Aguarde, Estamos Salvando o Plano de Midia.",
        BUSINESS_MEDIA_PLAN_SUCCESS_STATUS: "Plano de midia salvo com sucesso!",
        BUSINESS_MEDIA_PLAN_ERROR_STATUS:
          "Houve uma falha ao salvar o plano de midia, entre em contato com o suporte.",
        BUSINESS_MEDIA_PLAN_PAYMENT_METHOD_CARD: "Cartão",
        BUSINESS_MEDIA_PLAN_PAYMENT_METHOD_BILLET: "Boleto",
        BUSINESS_MEDIA_PLAN_PAYMENT_METHOD_CREDIT_LINE: "Linha de Crédito",
        BUSINESS_MEDIA_PLAN_PAYMENT_METHOD_OTHERS: "Outros",
        BUSINESS_MEDIA_PLAN_MEDIA_PLAN: "Plano de Mídia",
        BUSINESS_MEDIA_PLAN_CURRENT_MONTH: "Mês Atual",
        BUSINESS_MEDIA_PLAN_NEW_MEDIA_PLAN_PLACEHOLDER:
          "Informe o nome da nova midia!",
        BUSINESS_MEDIA_PLAN_TABLE_MEDIA_NAME: "Nome da Midia",
        BUSINESS_MEDIA_PLAN_TABLE_PLANNED_INVESTMENT: "Investimento Previsto",
        BUSINESS_MEDIA_PLAN_TABLE_MADE_INVESTMENT: "Investimento Realizado",
        BUSINESS_MEDIA_PLAN_TABLE_PAYMENT_METHOD: "Forma de Pagamento",
        BUSINESS_MEDIA_PLAN_TABLE_PLAY_PAUSE: "Play / Pause",
        BUSINESS_MEDIA_PLAN_RENEW: "Renovar",
        BUSINESS_MEDIA_PLAN_REGISTER_MEDIA_PLAN: "Salvar Plano de Midia",
        BUSINESS_MEDIA_PLAN_RENEW_MONTH_OPTION_DEFAULT: "Qtd. Meses",
        BUSINESS_MEDIA_PLAN_RENEW_MONTH_OPTION_1: "1 Mês",
        BUSINESS_MEDIA_PLAN_RENEW_MONTH_OPTION_2: "2 Mêses",
        BUSINESS_MEDIA_PLAN_RENEW_MONTH_OPTION_3: "3 Mêses",

        BUSINESS_VISIT_WAIT_STATUS: "Aguarde, Estamos Cadastrando a Visita.",
        BUSINESS_VISIT_SUCCESS_STATUS: "Visita cadastrada com sucesso!",
        BUSINESS_VISIT_REGISTER_VISIT: "Cadastrar Visita",
        BUSINESS_VISIT_REGISTER_NAME: "Nome",
        BUSINESS_VISIT_REGISTER_EMAIL: "E-mail",
        BUSINESS_VISIT_REGISTER_PHONE: "Telefone",
        BUSINESS_VISIT_REGISTER_LABEL_DATE: "Data",
        BUSINESS_VISIT_REGISTER_PLACEHOLDER_DATE: "Data da Visita",
        BUSINESS_VISIT_REGISTER_LABEL_STATUS: "Status",
        BUSINESS_VISIT_REGISTER_OPTION_DEFAULT: "Selecione o Status",
        BUSINESS_VISIT_REGISTER_OPTION_TO_SCHEDULE: "Agendar",
        BUSINESS_VISIT_REGISTER_OPTION_ACCOMPLISHED: "Realizada",
        BUSINESS_VISIT_REGISTER_OPTION_NOT_ACCOMPLISHED: "Não Realizada",
        BUSINESS_VISIT_REGISTER_OPTION_CANCELED: "Desmarcada",
        BUSINESS_VISIT_REGISTER_NEW_VISIT: "Cadastrar Nova Visita",
        BUSINESS_VISIT_LIST_LIST_OF_VISITS: "Lista de Visitas",
        BUSINESS_VISIT_LIST_NAME: "Nome",
        BUSINESS_VISIT_LIST_VISIT_DATE: "Data Visita",
        BUSINESS_VISIT_LIST_REGISTER_DATE: "Data Cadastro",

        BUSINESS_BID_WAIT_STATUS: "Aguarde Estamos Cadastrando a Proposta.",
        BUSINESS_BID_SUCCESS_STATUS: "Proposta Cadastrada com Sucesso!",
        BUSINESS_BID_REGISTER_REGISTER_BID: "Cadastrar Proposta",
        BUSINESS_BID_REGISTER_NAME: "Nome",
        BUSINESS_BID_REGISTER_EMAIL: "E-mail",
        BUSINESS_BID_REGISTER_PHONE: "Telefone",
        BUSINESS_BID_REGISTER_UNITY: "Unidade",
        BUSINESS_BID_REGISTER_VALUE: "Valor da Proposta",
        BUSINESS_BID_LIST_BIDS_LIST: "Lista de Propostas",
        BUSINESS_BID_LIST_NAME: "Nome",
        BUSINESS_BID_LIST_EMAIL: "E-mail",
        BUSINESS_BID_LIST_PHONE: "Telefone",
        BUSINESS_BID_LIST_UNITY: "Unidade",
        BUSINESS_BID_LIST_BIDS_VALUE: "Valor da Proposta",
        BUSINESS_BID_LIST_BIDS_REGISTER_DATE: "Data de Cadastro",

        BUSINESS_DEAL_WAIT_STATUS: "Aguarde Estamos Cadastrando a Negociação.",
        BUSINESS_DEAL_SUCCESS_STATUS: "Negociação cadastrada com sucesso!",
        BUSINESS_DEAL_REGISTER_REGISTER_DEAL: "Cadastrar Negociação",
        BUSINESS_DEAL_REGISTER_NAME: "Nome",
        BUSINESS_DEAL_REGISTER_EMAIL: "E-mail",
        BUSINESS_DEAL_REGISTER_PHONE: "Telefone",
        BUSINESS_DEAL_REGISTER_UNITY: "Unidade",
        BUSINESS_DEAL_REGISTER_DEAL_VALUE: "Valor da Negociação",
        BUSINESS_DEAL_LIST_DEALS_LIST: "Lista de Negociação",
        BUSINESS_DEAL_LIST_NAME: "Nome",
        BUSINESS_DEAL_LIST_EMAIL: "E-mail",
        BUSINESS_DEAL_LIST_PHONE: "Telefone",
        BUSINESS_DEAL_LIST_UNITY: "Unidade",
        BUSINESS_DEAL_LIST_DEAL_VALUE: "Valor da Negociação",
        BUSINESS_DEAL_LIST_REGISTER_DATE: "Data de Cadastro",

        BUSINESS_SALES_CONTROL_VISUALIZE_BUTTON_TITLE: "Visualizar Venda",
        BUSINESS_SALES_CONTROL_VISUALIZE_BUTTON: "Visualizar",
        BUSINESS_SALES_CONTROL_WAIT_STATUS:
          "Aguarde Estamos Cadastrando a Venda!",
        BUSINESS_SALES_CONTROL_SUCCESS_STATUS: "Venda cadastrada com sucesso!",
        BUSINESS_SALES_CONTROL_ADD_BUYERS_STATUS:
          "Você precisa adicionar compradores antes de cadastrar a venda.",
        BUSINESS_SALES_CONTROL_REGISTER_REGISTER_SALE: "Cadastrar Venda",
        BUSINESS_SALES_CONTROL_REGISTER_BUYER_NAME: "Nome Comprador",
        BUSINESS_SALES_CONTROL_REGISTER_BUYER_EMAIL: "E-mail Comprador",
        BUSINESS_SALES_CONTROL_REGISTER_BUYER_PHONE: "Telefone Comprador",
        BUSINESS_SALES_CONTROL_REGISTER_BUYER_CPF: "CPF",
        BUSINESS_SALES_CONTROL_REGISTER_BUYER_ADD_BUYER: "Adicionar Comprador",
        BUSINESS_SALES_CONTROL_LIST_BUYER_NAME: "Nome",
        BUSINESS_SALES_CONTROL_LIST_BUYER_EMAIL: "E-mail",
        BUSINESS_SALES_CONTROL_LIST_BUYER_PHONE: "Telefone",
        BUSINESS_SALES_CONTROL_LIST_BUYER_CPF: "CPF",
        BUSINESS_SALES_CONTROL_REGISTER_UNITY: "Unidade",
        BUSINESS_SALES_CONTROL_REGISTER_VALUE: "Valor",
        BUSINESS_SALES_CONTROL_REGISTER_SALES_UPLOAD:
          "Upload de Arquivo de Vendas",
        BUSINESS_SALES_CONTROL_REGISTER_REGISTER_NEW_SALE:
          "Cadastrar Nova Venda",
        BUSINESS_SALES_CONTROL_LIST_LIST_OF_SALES: "Lista de Vendas",
        BUSINESS_SALES_CONTROL_LIST_BUYERS_QUANTITY: "Qtd. Compradores",
        BUSINESS_SALES_CONTROL_LIST_MAIN_BUYER: "Comprador Principal",
        BUSINESS_SALES_CONTROL_LIST_UNITY: "Unidade",
        BUSINESS_SALES_CONTROL_LIST_VALUE: "Valor",
        BUSINESS_SALES_CONTROL_MODAL_SALES_UPLOAD:
          "Upload de Arquivo de Vendas",
        BUSINESS_SALES_CONTROL_MODAL_SUBMIT_BUTTON: "Enviar",
        BUSINESS_SALES_CONTROL_MODAL_COLUMN_BUTTON: "Coluna",
        BUSINESS_SALES_CONTROL_MODAL_ROW_BUTTON: "Linha",
        BUSINESS_SALES_CONTROL_MODAL_REGISTER_BUTTON: "Cadastrar",
        BUSINESS_SALES_CONTROL_MODAL_SALES_INFO: "Informações da Venda",
        BUSINESS_SALES_CONTROL_MODAL_SALES_UNITY: "Unidade",
        BUSINESS_SALES_CONTROL_MODAL_SALES_VALUE: "Valor",
        BUSINESS_SALES_CONTROL_MODAL_SALES_BUYER: "Comprador",
        BUSINESS_SALES_CONTROL_MODAL_SALES_BUYER_NAME: "Nome",
        BUSINESS_SALES_CONTROL_MODAL_SALES_BUYER_EMAIL: "E-mail",
        BUSINESS_SALES_CONTROL_MODAL_SALES_BUYER_PHONE: "Telefone",
        BUSINESS_SALES_CONTROL_MODAL_SALES_BUYER_CPF: "CPF",

        BUSINESS_POS_WAIT_STATUS:
          "Aguarde, Estamos Cadastrando a Compra Futura.",
        BUSINESS_POS_SUCCESS_STATUS: "Compra Futura cadastrada com sucesso!",
        BUSINESS_POS_REGISTER_REGISTER_FUTURE_PURCHASE:
          "Cadastrar Compra Futura",
        BUSINESS_POS_REGISTER_NAME: "Nome",
        BUSINESS_POS_REGISTER_EMAIL: "E-mail",
        BUSINESS_POS_REGISTER_PHONE: "Telefone",
        BUSINESS_POS_REGISTER_UNITY: "Unidade",
        BUSINESS_POS_REGISTER_VALUE: "Valor",
        BUSINESS_POS_LIST_LIST_OF_FUTURE_PURCHASES: "Lista de Compras Futuras",
        BUSINESS_POS_LIST_NAME: "Nome",
        BUSINESS_POS_LIST_EMAIL: "E-mail",
        BUSINESS_POS_LIST_PHONE: "Telefone",
        BUSINESS_POS_LIST_UNITY: "Unidade",
        BUSINESS_POS_LIST_VALUE: "Valor",
        BUSINESS_POS_LIST_REGISTER_DATE: "Data Cadastro",

        GOOGLE_DIRECTIONS_PHYSICAL_INFO: "Informações físicas",
        GOOGLE_DIRECTIONS_PHYSICAL_ADDRESS_NOT_FOUND_STATE:
          "Não foi possível localizar os endereços cadastrados",
        GOOGLE_DIRECTIONS_ADDRESS_OF: "Endereço do",
        GOOGLE_DIRECTIONS_DISTANCE: "Distância",
        GOOGLE_DIRECTIONS_DRIVING_TIME: "Tempo na direção",

        BUSINESS_SOCIAL_NETWORKS_WAIT_STATUS:
          "Aguarde, Estamos Salvando a Configuração de Rede Social.",
        BUSINESS_SOCIAL_NETWORKS_SUCCESS_STATUS:
          "Configuração de Rede Social salva com sucesso!",
        BUSINESS_SOCIAL_FACEBOOK_PLACEHOLDER:
          "Informe o link do perfil no Facebook!",
        BUSINESS_SOCIAL_INSTAGRAM_PLACEHOLDER:
          "Informe o link do perfil no Instagram!",
        BUSINESS_SOCIAL_TWITTER_PLACEHOLDER:
          "Informe o link do perfil no Twitter!",
        BUSINESS_SOCIAL_LINKEDIN_PLACEHOLDER:
          "Informe o link do perfil no Linkedin!",
        BUSINESS_SOCIAL_PINTEREST_PLACEHOLDER:
          "Informe o link do perfil no Pinterest!",
        BUSINESS_SOCIAL_YOUTUBE_PLACEHOLDER:
          "Informe o link do perfil no Youtube!",
        BUSINESS_SOCIAL_REGISTER_SOCIAL_NETWORKS: "Salvar Redes Sociais",

        BUSINESS_LEADS_WAIT_STATUS: "Aguarde Estamos Cadastrando o Leads.",
        BUSINESS_LEADS_SUCCESS_STATUS: "Lead Cadastrado com Sucesso!",
        BUSINESS_LEADS_ERROR_STATUS: "Falha ao cadastrar o Lead!",
        BUSINESS_LEADS_REGISTER_REGISTER_LEAD: "Cadastrar Lead",
        BUSINESS_LEADS_REGISTER_NAME: "Nome do Lead",
        BUSINESS_LEADS_REGISTER_EMAIL: "E-mail do Lead",
        BUSINESS_LEADS_REGISTER_PHONE: "Telefone do Lead",
        BUSINESS_LEADS_REGISTER_ORIGIN_OF_LEAD: "Origem do Lead",
        BUSINESS_LEADS_REGISTER_SELECT_ORIGIN_OF_LEAD:
          "Selecione a Origem do Lead",
        BUSINESS_LEADS_REGISTER_COMMENT: "Comentário do Lead",
        BUSINESS_LEADS_REGISTER_NEW_LEAD: "Cadastrar Novo Lead",

        BUSINESS_CONFIG_CONFIG_WAIT_STATUS:
          "Aguarde, Estamos Salvando a Configuração de Qualificação.",
        BUSINESS_CONFIG_CONFIG_SUCCESS_STATUS:
          "Configuração de Qualificação salva com sucesso!",
        BUSINESS_CONFIG_LEAD_SCORE_WAIT_STATUS:
          "Aguarde, Estamos Salvando o Lead Score.",
        BUSINESS_CONFIG_LEAD_SCORE_SUCCESS_STATUS:
          "Configuração do Score do Lead salva com sucesso!",
        BUSINESS_CONFIG_SUSPECT_SCORE_WAIT_STATUS:
          "Aguarde, Estamos Salvando o Suspect Score.",
        BUSINESS_CONFIG_SUSPECT_SCORE_SUCCESS_STATUS:
          "Configuração do Score do Suspect salva com sucesso!",
        BUSINESS_CONFIG_QUALIFICATION_QUALIFICATION: "Qualificação",
        BUSINESS_CONFIG_QUALIFICATION_QUALIFICATION_SYSTEM:
          "Sistema de Qualificação",
        BUSINESS_CONFIG_DEACTIVATED: "Desativado",
        BUSINESS_CONFIG_ACTIVATED: "Ativado",
        BUSINESS_CONFIG_QUALIFICATION_QUALIFICATION_MANAGEMENT:
          "Gestor de Qualificação.",
        BUSINESS_CONFIG_QUALIFICATION_REGISTER_QUALIFICATION:
          "Salvar Qualificação",
        BUSINESS_CONFIG_LEAD_SCORE_LEAD_SCORE: "Lead Score",
        BUSINESS_CONFIG_LEAD_SCORE_SEGMENTATION: "Segmentação",
        BUSINESS_CONFIG_LEAD_SCORE_SEGMENTATION_CHOOSE_SEGMENTATION:
          "Escolher Segmentação",
        BUSINESS_CONFIG_LEAD_SCORE_SEGMENTATION_CHOOSE_PUBLIC:
          "Escolher Público",
        BUSINESS_CONFIG_NO_SEGMENTATION_REGISTERED:
          "Nenhuma Segmentação Cadastrada",
        BUSINESS_CONFIG_NO_PUBLIC_REGISTERED: "Nenhum público foi cadastrado",
        BUSINESS_CONFIG_LEAD_SCORE_SEGMENTATION_CHOOSE_ACTION: "Escolher Ação",
        BUSINESS_CONFIG_INCREASE: "Aumentar",
        BUSINESS_CONFIG_DECREASE: "Diminuir",
        BUSINESS_CONFIG_LEAD_SCORE_SEGMENTATION_PONTUATION_NUMBER:
          "Número da Pontuaçao",
        BUSINESS_CONFIG_LEAD_SCORE_REGISTER_SEGMENTATION:
          "Adicionar Regra de Segmentação",
        BUSINESS_CONFIG_LEAD_SCORE_MEDIA: "Mídia",
        BUSINESS_CONFIG_LEAD_SCORE_MEDIA_CHOOSE_MEDIA: "Escolher Mídia",
        BUSINESS_CONFIG_LEAD_SCORE_MEDIA_CHOOSE_ACTION: "Escolher Ação",
        BUSINESS_CONFIG_LEAD_SCORE_MEDIA_PONTUATION_NUMBER:
          "Número da Pontuaçao",
        BUSINESS_CONFIG_LEAD_SCORE_MEDIA_ADD_MEDIA_RULE:
          "Adicionar Regra de Mídia",
        BUSINESS_CONFIG_LEAD_SCORE_MEDIA_REGISTER_LEAD_SCORE:
          "Salvar Lead Score",
        BUSINESS_CONFIG_SUSPECT_SCORE_SUSPECT_SCORE: "Suspect Score",
        BUSINESS_CONFIG_SUSPECT_SCORE_SEGMENTATION: "Segmentação",
        BUSINESS_CONFIG_SUSPECT_SCORE_CHOOSE_SEGMENTATION:
          "Escolher Segmentação",
        BUSINESS_CONFIG_SUSPECT_SCORE_SEGMENTATION_CHOOSE_PUBLIC:
          "Escolher Público",
        BUSINESS_CONFIG_SUSPECT_SCORE_SEGMENTATION_CHOOSE_ACTION:
          "Escolher Ação",
        BUSINESS_CONFIG_SUSPECT_SCORE_SEGMENTATION_PONTUATION_NUMBER:
          "Número da Pontuaçao",
        BUSINESS_CONFIG_SUSPECT_SCORE_SEGMENTATION_ADD_SEGMENTATION_RULE:
          "Adicionar Regra de Segmentação",
        BUSINESS_CONFIG_SUSPECT_SCORE_MEDIA_MEDIA: "Mídia",
        BUSINESS_CONFIG_SUSPECT_SCORE_MEDIA_CHOOSE_MEDIA: "Escolher Mídia",
        BUSINESS_CONFIG_SUSPECT_SCORE_MEDIA_CHOOSE_ACTION: "Escolher Ação",
        BUSINESS_CONFIG_SUSPECT_SCORE_MEDIA_PONTUATION_NUMBER:
          "Número da Pontuação",
        BUSINESS_CONFIG_SUSPECT_SCORE_MEDIA_ADD_MEDIA_RULE:
          "Adicionar Regra de Mídia",
        BUSINESS_CONFIG_INTERACTION_TIME_INTERACTION_TIME: "Tempo de Interação",
        BUSINESS_CONFIG_INTERACTION_TIME_INTERACTION_SECONDS: "Seg.",
        BUSINESS_CONFIG_INTERACTION_TIME_SUSPECT_SCORE_CHOOSE_ACTION:
          "Escolher Ação",
        BUSINESS_CONFIG_INTERACTION_TIME_SUSPECT_SCORE_PONTUATION_NUMBER:
          "Número da Pontuação",
        BUSINESS_CONFIG_INTERACTION_TIME_ADD_INTERACTION_TIME_RULE:
          "Adicionar Regra de Tempo de Interação",
        BUSINESS_CONFIG_INTERACTION_TIME_REGISTER_SUSPECT_SCORE:
          "Salvar Suspect Score",

        QUALIFICATION_QUALIFICATION_TEAM: "Equipe de Qualificação",
        QUALIFICATION_MANAGERS: "Gestores",
        QUALIFICATION_QUALIFIERS: "Qualificadores",
        MANAGERS_WAIT_STATUS: "Aguarde, Estamos Cadastrando o Gestor.",
        MANAGERS_SUCCESS_STATUS: "Gestor cadastrado com sucesso!",
        MANAGERS_REGISTER_REGISTER_MANAGER: "Cadastrar Gestor",
        MANAGERS_REGISTER_LABEL_NAME: "Nome",
        MANAGERS_REGISTER_PLACEHOLDER_NAME: "Nome Gestor",
        MANAGERS_REGISTER_LABEL_EMAIL: "E-mail",
        MANAGERS_REGISTER_PLACEHOLDER_EMAIL: "E-mail Gestor",
        MANAGERS_REGISTER_LABEL_PHONE: "Telefone",
        MANAGERS_REGISTER_PLACEHOLDER_PHONE: "Telefone Gestor",
        MANAGERS_REGISTER_REGISTER_NEW_MANAGER: "Cadastrar Novo Gestor",
        MANAGERS_LIST_LIST_MANAGER: "Lista de Gestores",
        MANAGERS_LIST_NAME: "Nome",
        MANAGERS_LIST_EMAIL: "E-mail",
        MANAGERS_LIST_PHONE: "Telefone",

        QUALIFIERS_WAIT_STATUS: "Aguarde, Estamos Cadastrando o Qualificador.",
        QUALIFIERS_SUCCESS_STATUS: "Qualificador cadastrado com sucesso!",
        QUALIFIERS_REGISTER_REGISTER_QUALIFIER: "Cadastrar Qualificador",
        QUALIFIERS_REGISTER_LABEL_NAME: "Nome",
        QUALIFIERS_REGISTER_PLACEHOLDER_NAME: "Nome Qualificador",
        QUALIFIERS_REGISTER_LABEL_EMAIL: "E-mail",
        QUALIFIERS_REGISTER_PLACEHOLDER_EMAIL: "E-mail Qualificador",
        QUALIFIERS_REGISTER_LABEL_PHONE: "Telefone",
        QUALIFIERS_REGISTER_PLACEHOLDER_PHONE: "Telefone Qualificador",
        QUALIFIERS_REGISTER_REGISTER_NEW_QUALIFIER:
          "Cadastrar Novo Qualificador",
        QUALIFIERS_LIST_LIST_QUALIFIERS: "Lista de Qualificadores",
        QUALIFIERS_LIST_NAME: "Nome",
        QUALIFIERS_LIST_EMAIL: "E-mail",
        QUALIFIERS_LIST_PHONE: "Telefone",

        TOOLS_TOOLS: "Ferramentas",
        TOOLS_ADMINISTRATE_SEGMENTATIONS: "Administrar Segmentações",
        TOOLS_LEAD_4_0: "Lead 4.0",
        TOOLS_ADMINISTRATE_LEAD_4_0: "Administrar Lead 4.0",
        TOOLS_LINK_BUILDER: "Link Builder",

        LEAD_PLUS_LEAD_4_0: "Lead 4.0",
        LEAD_PLUS_WAIT_ICON: "Aguardar",
        LEAD_PLUS_REGISTER_ICON: "Salvar",
        LEAD_PLUS_UPDATE_ICON: "Atualizar",
        LEAD_PLUS_ADD_NEW_PARAGRAPH: "Adicionar Novo Parágrafo",
        LEAD_PLUS_REFERENCE_TAGS: "TAG's de Referência",
        LEAD_PLUS_TAG_LEAD_NAME: "Adiciona o nome do lead.",
        LEAD_PLUS_TAG_LEAD_AGE: "Adiciona a idade do lead.",
        LEAD_PLUS_TAG_LEAD_AGE_RANGE:
          "Adiciona a faixa de idade do lead contendo o prefixo e a informação.",
        LEAD_PLUS_TAG_INCOME_RANGE:
          "Adiciona a faixa de renda do lead contendo o prefixo e a informação.",
        LEAD_PLUS_TAG_OCCUPATION:
          "Adiciona o cargo atual do lead contendo o prefixo e a informação.",
        LEAD_PLUS_TAG_ACTUAL_JOB:
          "Adiciona o emprego atual do lead contendo o prefixo e a informação.",
        LEAD_PLUS_TAG_BUSINESSMAN:
          "Adiciona se o lead é empresario(a) contendo o prefixo e a informação.",
        LEAD_PLUS_TAG_AMOUNT_BUSINESS:
          "Adiciona a quantidade e empresas na qual o lead tem participação contendo prefixo, informação e sufixo.",
        LEAD_PLUS_TAG_BUSINESS_NAME: "Adiciona o nome do Empreendimento.",
        LEAD_PLUS_TAG_BUSINESS_DISTANCE:
          "Adiciona a distância entre o lead e o Empreendimento contendo prefixo, informação e sufixo.",
        LEAD_PLUS_TAG_ENTRY_DATE:
          "Adiciona a data de Entrada/Preenchimento/Acontecimento no formato dia/mês/ano.",
        LEAD_PLUS_TAG_ENTRY_TIME:
          "Adiciona a hora e minutos de Entrada/Preenchimento/Acontecimento no formato hora:minuto.",
        LEAD_PLUS_TAG_FORM_NAME:
          "Adiciona o nome do formulario de preenchimento.",
        LEAD_PLUS_TAG_SOURCE_MEDIA:
          "Adiciona o prefixo de origem seguido da origem do lead.",
        LEAD_PLUS_TAG_PITCH_SEGMENTATION:
          "Adiciona o pitch de segmentação configurado no momento da criação da segmentação.",
        LEAD_PLUS_TAG_PITCH_CONTENTS:
          "Adiciona o pitch de conteudo configurado no momento da criação do Link.",
        LEAD_PLUS_TAG_PITCH_CAMPAIGN:
          "Adiciona o pitch de campanha configurado no momento da criação do Link.",
        LEAD_PLUS_TAG_PITCH_CLICK:
          "Adiciona o pitch de click configurado no momento da criação do Link.",
        LEAD_PLUS_TAG_PITCH_LANDING_PAGE:
          "Adiciona o pitch de landing page configurado no momento da criação da landing page.",
        LEAD_PLUS_TAG_PITCH_EMAIL_MARKETING:
          "Adiciona o pitch de e-mail marketing configurado no momento da criação do E-mail Marketing.",
        LEAD_PLUS_TAG_PITCH_BANNER:
          "Adiciona o pitch de banner configurado no momento da criação do Banner.",
        LEAD_PLUS_TAG_PITCH_SMS:
          "Adiciona o pitch de SMS configurado no momento da criação do SMS.",
        LEAD_PLUS_TAG_CHAMPION_INTERACTION:
          "Adiciona qual foi a maior interação do lead contendo, informação e sufixo.",
        LEAD_PLUS_SUCCESS: "Sucesso",
        LEAD_PLUS_UPDATE_PARAGRAPH: "em atualizar os parágrafos do Lead 4.0.",
        LEAD_PLUS_ERROR: "Falha",
        LEAD_PLUS_UPDATE: "Atualizar",
        LEAD_PLUS_NO_PARAGRAPH: "Nenhum Parágrafo Configurado Ainda!",
        LEAD_PLUS_MODAL_NEW_PARAGRAPH: "Adicionar Novo Parágrafo",
        LEAD_PLUS_MODAL_LABEL_PARAGRAPH_NAME:
          "Nome de Controle Interno do Parágrafo",
        LEAD_PLUS_MODAL_PLACEHOLDER_PARAGRAPH_NAME: "Nome do Parágrafo",
        LEAD_PLUS_MODAL_LABEL_LINK_TYPE: "Tipo do Link Gerado",
        LEAD_PLUS_MODAL_OPTION_SELECT_TYPE: "Selecione o Tipo",
        LEAD_PLUS_MODAL_OPTION_NEW_LEAD: "Novo Lead",
        LEAD_PLUS_MODAL_OPTION_APPROACH_LEAD: "Como Abordar o Lead",
        LEAD_PLUS_MODAL_OPTION_NEW_VIEW: "Nova Visita Landing",
        LEAD_PLUS_MODAL_OPTION_CLICKED_EMAIL_LINK:
          "Clicou Link E-mail Marketing",
        LEAD_PLUS_MODAL_REGISTER: "Salvar",
        LEAD_PLUS_TYPE: "tipo",

        LINK_BUILDER_REQUIRED_INFORM_SEGMENTATION:
          "É necessário selecionar pelo menos uma segmentação!",
        LINK_BUILDER_WAIT: "Aguardar",
        LINK_BUILDER_REGISTER: "Salvar",
        LINK_BUILDER_LEAD_INFO: "Informações do Lead",
        LINK_BUILDER_VISUALIZE_LINK_BUTTON: "Visualizar Link",
        LINK_BUILDER_COPY_LINK_BUTTON: "Copiar Link",
        LINK_BUILDER_CREATE_NEW_LINK: "Criar Novo Link",
        LINK_BUILDER_TABLE_NUMBER: "N°",
        LINK_BUILDER_TABLE_NAME: "Nome",
        LINK_BUILDER_TABLE_MEDIA: "Mídia",
        LINK_BUILDER_TABLE_SEGMENTATION: "Segmentação",
        LINK_BUILDER_TABLE_PUBLIC: "Público",
        LINK_BUILDER_TABLE_ORIGINAL_URL: "URL Original",
        LINK_BUILDER_TABLE_SHAREABLE_URL: "URL Compartilhavel",
        LINK_BUILDER_TABLE_DESTINATION_URL: "URL Destino",
        LINK_BUILDER_MODAL_LABEL_LINK_NAME: "Nome de Controle Interno do Link",
        LINK_BUILDER_MODAL_PLACEHOLDER_LINK_NAME: "Nome do Link",
        LINK_BUILDER_MODAL_LABEL_MEDIA: "Mídia",
        LINK_BUILDER_MODAL_OPTION_SELECTED_MEDIAS: "Mídias Selecionadas",
        LINK_BUILDER_MODAL_OPTION_SELECT_MEDIAS: "Selecione as Mídias",
        LINK_BUILDER_MODAL_ALL_MEDIAS: "Todas as Mídias",
        LINK_BUILDER_MODAL_THERE_ARENT_MORE_MEDIAS: "Não há mais mídias",
        LINK_BUILDER_MODAL_SELECT_MEDIA:
          "É necessário selecionar pelo menos uma mídia",
        LINK_BUILDER_MODAL_SELECT_PUBLIC:
          "É necessário selecionar pelo menos um público",
        LINK_BUILDER_MODAL_LABEL_SEGMENTATION: "Segmentação",
        LINK_BUILDER_MODAL_LABEL_ALL_SEGMENTATIONS: "Todas as Segmentações",
        LINK_BUILDER_MODAL_LABEL_SELECTED_SEGMENTATIONS:
          "Segmentações Selecionadas",
        LINK_BUILDER_MODAL_THERE_ARENT_MORE_SEGMENTATIONS:
          "Não há mais Segmentações",
        LINK_BUILDER_MODAL_OPTION_SELECT_SEGMENTATION:
          "Selecione a Segmentação",
        LINK_BUILDER_MODAL_NO_SEGMENTATION_CLICK_TO_ADD:
          "Nenhuma Segmentação Cadastrada - Clique para Adicionar",
        LINK_BUILDER_MODAL_LABEL_PUBLIC: "Público",
        LINK_BUILDER_MODAL_LABEL_ALL_PUBLICS: "Todos os Públicos",
        LINK_BUILDER_MODAL_LABEL_SELECTED_PUBLICS: "Públicos Selecionados",
        LINK_BUILDER_MODAL_OPTION_SELECT_PUBLICS: "Selecione os Públicos",
        LINK_BUILDER_MODAL_NO_PUBLIC:
          "Nenhum público foi cadastrado para essas segmentações",
        LINK_BUILDER_MODAL_LABEL_LEAD_JOURNEY_MOMENT:
          "Momento da Jornada do Lead",
        LINK_BUILDER_MODAL_OPTION_SELECT_LEAD_JOURNEY_MOMENT:
          "Selecione o Momento da Jornada do Lead",
        LINK_BUILDER_MODAL_OPTION_LEAD_JOURNEY_MOMENT_START: "Início",
        LINK_BUILDER_MODAL_OPTION_LEAD_JOURNEY_MOMENT_MIDDLE: "Meio",
        LINK_BUILDER_MODAL_OPTION_LEAD_JOURNEY_MOMENT_END: "Fim",
        LINK_BUILDER_MODAL_CAMPAIGN_PITCH: "Pitch de Campanha",
        LINK_BUILDER_MODAL_CONTENT_PITCH: "Pitch de Conteúdo",
        LINK_BUILDER_MODAL_CLICK_PITCH: "Pitch de Click",
        LINK_BUILDER_MODAL_LABEL_GENERATED_LINK_TYPE: "Tipo do Link Gerado",
        LINK_BUILDER_MODAL_OPTION_BUYLITICS_URL_SHORTENER:
          "Encurtador de URL Buylitics",
        LINK_BUILDER_MODAL_OPTION_ADD_TRACE_PARAMETERS:
          "Adicionar Parâmetros de Rastreio",
        LINK_BUILDER_MODAL_DESTINATION_LINK: "Link de Destino",
        LINK_BUILDER_MODAL_INFO_LINK_BUILDER_INFO:
          "Informações do Link Builder",
        LINK_BUILDER_MODAL_INFO_NAME: "Nome",
        LINK_BUILDER_MODAL_INFO_CONTENT_PITCH: "Pitch de Conteúdo",
        LINK_BUILDER_MODAL_INFO_CAMPAIGN_PITCH: "Pitch de Campanha",
        LINK_BUILDER_MODAL_INFO_SEGMENTATION_PITCH: "Pitch de Segmentação",
        LINK_BUILDER_MODAL_INFO_PUBLIC_PITCH: "Pitch de Público",
        LINK_BUILDER_MODAL_UNKNOWN: "N/C",
        LINK_BUILDER_MODAL_INFO_MEDIA_PITCH: "Pitch de Mídia",
        LINK_BUILDER_MODAL_INFO_MEDIA: "Mídia",
        LINK_BUILDER_MODAL_INFO_SEGMENTATION: "Segmentação",
        LINK_BUILDER_MODAL_INFO_PUBLIC: "Público",
        LINK_BUILDER_MODAL_INFO_CLICK_PITCH: "Pitch de Clique",
        LINK_BUILDER_MODAL_INFO_ORIGINAL_LINK: "URL Original",
        LINK_BUILDER_MODAL_INFO_SHAREABLE_LINK: "URL Compartilhável",
        LINK_BUILDER_MODAL_INFO_DESTINATION_LINK: "URL Destino",

        SEGMENTATION_LIST_ADMINISTRATE_SEGMENTATIONS:
          "Administrar Segmentações",
        SEGMENTATION_LIST_WAIT: "Aguardar",
        SEGMENTATION_LIST_REGISTER: "Salvar",
        SEGMENTATION_LIST_VISUALIZE_SEGMENTATION: "Visualizar Segmentação",
        SEGMENTATION_LIST_EDIT_SEGMENTATION: "Editar Segmentação",
        SEGMENTATION_LIST_CREATE_NEW_SEGMENTATION: "Criar Nova Segmentação",
        SEGMENTATION_LIST_TABLE_NUMBER: "Nº",
        SEGMENTATION_LIST_TABLE_SEGMENTATION_NAME: "Nome Da Segmentação",
        SEGMENTATION_LIST_TABLE_INDENTIFIER: "Identificador",
        SEGMENTATION_LIST_MODAL_LABEL_SEGMENTATION_NAME:
          "Nome de Controle Interno da Segmentação",
        SEGMENTATION_LIST_MODAL_PLACEHOLDER_SEGMENTATION_NAME:
          "Nome da Segmentação",
        SEGMENTATION_LIST_MODAL_LABEL_SEGMENTATION_DESCRIPTION:
          "Descrição de uso da Segmentação",
        SEGMENTATION_LIST_MODAL_PLACEHOLDER_SEGMENTATION_DESCRIPTION:
          "Descrição da Segmentação",
        SEGMENTATION_LIST_MODAL_SEGMENTATION_PITCH: "Pitch de Segmentação",
        SEGMENTATION_LIST_MODAL_SEGMENTATION_PUBLIC: "Público de Segmentação",
        SEGMENTATION_LIST_MODAL_NEW_SEGMENTATION_PUBLICS:
          "Novos Públicos de Segmentação",
        SEGMENTATION_LIST_MODAL_CLICK_TO_ADD_NEW_SEGMENTATION_PUBLICS:
          "Clique para adicionar novos Públicos de Segmentação",
        SEGMENTATION_LIST_MODAL_SEGMENTATION_PUBLIC_NAME: "Nome do Público",
        SEGMENTATION_LIST_MODAL_SEGMENTATION_PUBLIC_PITCH: "Pitch do Público",
        SEGMENTATION_LIST_MODAL_SEGMENTATION_ADD_PUBLIC: "Adicionar Público",
        SEGMENTATION_LIST_MODAL_SEGMENTATION_REMOVE_PUBLIC: "Remover Público",
        SEGMENTATION_LIST_MODAL_SEGMENTATION_INDENTIFIER:
          "Identificador da Segmentação",
        SEGMENTATION_LIST_MODAL_DETAILS_SEGMENTATION_DETAILS:
          "Detalhes da Segmentação.",
        SEGMENTATION_LIST_MODAL_DETAILS_SEGMENTATION_INFO:
          "Informações da Segmentação.",
        SEGMENTATION_LIST_MODAL_DETAILS_SEGMENTATION_NAME: "Nome",
        SEGMENTATION_LIST_MODAL_DETAILS_SEGMENTATION_CODE: "Código",
        SEGMENTATION_LIST_MODAL_DETAILS_SEGMENTATION_DESCRIPTION: "Descrição",
        SEGMENTATION_LIST_MODAL_DETAILS_SEGMENTATION_PITCH:
          "Pitch da Segmentação",
        SEGMENTATION_LIST_MODAL_DETAILS_SEGMENTATION_PUBLICS:
          "Públicos da Segmentação",
        SEGMENTATION_LIST_MODAL_DETAILS_SEGMENTATION_PUBLIC_NAME:
          "Nome do Publico",
        SEGMENTATION_LIST_MODAL_DETAILS_SEGMENTATION_PUBLIC_PITCH:
          "Pitch do Publico",
        SEGMENTATION_LIST_MODAL_DETAILS_SEGMENTATION_PUBLIC_CODE:
          "Código do Publico",

        CONTENT_CONTENT: "Conteúdo",
        CONTENT_LANDING_PAGE: "Landing Page",
        CONTENT_VIEW_MORE: "Ver mais",
        CONTENT_CREATED: "Criados",
        CONTENT_ACTIVE: "Ativos",
        CONTENT_EMAIL_MARKETING: "Email Marketing",
        CONTENT_SMS: "SMS",

        LANDING_PAGE_LANDING_PAGES: "Landing Pages",
        LANDING_PAGE_WAIT: "Aguardar",
        LANDING_PAGE_REGISTER: "Salvar",
        LANDING_PAGE_VIEW_LANDING_PAGE: "Ver Landing Page",
        LANDING_PAGE_CREATE_NEW_LANDING_PAGE: "Criar Nova Landing Page",
        LANDING_PAGE_TABLE_NAME: "Nome",
        LANDING_PAGE_TABLE_URL: "URL",
        LANDING_PAGE_MODAL_CREATE_NEW_LANDING_PAGE: "Criar Nova Landing Page",
        LANDING_PAGE_MODAL_LABEL_NAME: "Nome de Controle da Landing Page",
        LANDING_PAGE_MODAL_PLACEHOLDER_NAME: "Nome da Landing Page",
        LANDING_PAGE_MODAL_LABEL_PITCH: "Pitch da Landing Page",
        LANDING_PAGE_MODAL_PLACEHOLDER_PITCH: "Pitch da Landing Page",
        LANDING_PAGE_MODAL_LABEL_URL: "URL da Landing Page",
        LANDING_PAGE_MODAL_PLACEHOLDER_URL: "URL da Landing Page",
        LANDING_PAGE_MODAL_LABEL_LANDING_PAGE_TYPE: "Tipo da Landing Page",
        LANDING_PAGE_MODAL_OPTION_SELECT_LANDING_PAGE:
          "Selecione o tipo da landing page",
        LANDING_PAGE_MODAL_OPTION_EXTERNAL_LINK: "Link Externo",
        LANDING_PAGE_MODAL_OPTION_BUYLITICS_LANDING_PAGE:
          "Buylitics - Landing Page",
        LANDING_PAGE_MODAL_REGISTER: "Salvar",

        EMAIL_MARKETING_LIST_EMAIL_MARKETING: "E-mail Marketing",
        EMAIL_MARKETING_LIST_WAIT: "Aguardar",
        EMAIL_MARKETING_LIST_REGISTER: "Salvar",
        EMAIL_MARKETING_LIST_SUCCESS_ADDING_TO_SUBMISSION_QUEUE:
          "Sucesso ao adicionar o e-mail de teste a fila de envios!",
        EMAIL_MARKETING_LIST_SEND: "Enviar",
        EMAIL_MARKETING_LIST_SUCCESS_ERROR_TO_SUBMISSION_QUEUE:
          "Houve uma falha ao adicionar o e-mail de teste a fila de envios!",
        EMAIL_MARKETING_LIST_EMAIL_VISUALIZE: "Visualizar E-mail",
        EMAIL_MARKETING_LIST_EMAIL_EDIT: "Editar E-mail",
        EMAIL_MARKETING_LIST_EMAIL_SEND_TEST: "Enviar E-mail de Teste",
        EMAIL_MARKETING_CREATE_NEW_EMAIL_MARKETING:
          "Criar Novo Email Marketing",
        EMAIL_MARKETING_TABLE_NAME: "Nome",
        EMAIL_MARKETING_TABLE_SUBJECT: "Assunto",
        EMAIL_MARKETING_MODAL_LABEL_EMAIL_NAME: "Nome de Controle do Email",
        EMAIL_MARKETING_MODAL_PLACEHOLDER_EMAIL_NAME: "Nome do E-mail",
        EMAIL_MARKETING_MODAL_LABEL_PITCH_EMAIL_MARKETING:
          "Pitch do E-mail Marketing",
        EMAIL_MARKETING_MODAL_PLACEHOLDER_PITCH_EMAIL_MARKETING:
          "Pitch do E-mail Marketing",
        EMAIL_MARKETING_MODAL_LABEL_SUBJECT_EMAIL: "Assunto no Envio do E-mail",
        EMAIL_MARKETING_MODAL_PLACEHOLDER_SUBJECT_EMAIL: "Assunto do E-mail",
        EMAIL_MARKETING_MODAL_LABEL_CONTENT_EMAIL: "Conteudo no Corpo do Email",
        EMAIL_MARKETING_MODAL_EDIT_EMAIL_MARKETING: "Editar Email Marketing",
        EMAIL_MARKETING_MODAL_RECIPIENT_NAME: "Nome do Destinatário",
        EMAIL_MARKETING_MODAL_RECIPIENT_EMAIL: "E-mail do Destinatário",

        TRIGGERED_EMAIL_TRIGGERED_EMAIL_CREATED_WITH_SUCCESS:
          "Disparo de E-mail Criado com sucesso",
        TRIGGERED_EMAIL_TRIGGERED_EMAIL_CREATED_FAILED:
          "Houve uma falha ao cadastrar o disparo de E-mail, tente novamenta mais tarde!",
        TRIGGERED_EMAIL_TRIGGERED_EMAILS: "Disparo de E-mails",
        TRIGGERED_EMAIL_TRIGGERS_MADE: "Disparos Realizados",
        TRIGGERED_EMAIL_NEW_TRIGGER: "Novo Disparo",
        TRIGGERED_EMAIL_TRIGGER_NAME: "Nome do Disparo",
        TRIGGERED_EMAIL_TRIGGER_DATE: "Data do Disparo",
        TRIGGERED_EMAIL_TOTAL: "Total",
        TRIGGERED_EMAIL_DELIVER: "Entrega",
        TRIGGERED_EMAIL_OPENING: "Abertura",
        TRIGGERED_EMAIL_CLICKS: "Cliques",
        TRIGGERED_EMAIL_TRIGGER_BASE: "Base de Disparo",
        TRIGGERED_EMAIL_CHOOSE_EXISTENT_TRIGGER_BASE:
          "Escolher Base de Disparo Existente",
        TRIGGERED_EMAIL_UPLOAD_TRIGGER_BASE_FILE:
          "Fazer Upload de Arquivo de Base de Disparo",
        TRIGGERED_EMAIL_SELECT_THE_ORIGIN: "Selecione a Origem",
        TRIGGERED_EMAIL_BASE_FILE_UPLOADED_WITH_SUCCESS:
          "Upload de Arquivo de Base feito com sucesso",
        TRIGGERED_EMAIL_EDIT_TRIGGER_BASE_FILE:
          "Editar Arquivo de Base de Disparo",
        TRIGGERED_EMAIL_CLEAN: "Limpar",
        TRIGGERED_EMAIL_REGISTER_NEW_TRIGGER: "Cadastrar Novo Disparo",
        TRIGGERED_EMAIL_BASE_FILE_UPLOAD: "Upload de Arquivo de Base",
        TRIGGERED_EMAIL_UPLOAD: "Fazer Upload",
        TRIGGERED_EMAIL_CUSTOM: "customizavel",
        TRIGGERED_EMAIL_ADD_TRIGGER_BASE_FILE:
          "Adicionar Arquivo de Base de Disparo",

        TRIGGERED_SMS_TRIGGERED_SMS_CREATED_WITH_SUCCESS:
          "Disparo de SMS Criado com sucesso",
        TRIGGERED_SMS_TRIGGERED_SMSS: "Disparo de SMS's",
        TRIGGERED_SMS_TRIGGERS_MADE: "Disparos Realizados",
        TRIGGERED_SMS_NEW_TRIGGER: "Novo Disparo",
        TRIGGERED_SMS_TRIGGER_NAME: "Nome do Disparo",
        TRIGGERED_SMS_TRIGGER_DATE: "Data do Disparo",
        TRIGGERED_SMS_TOTAL: "Total",
        TRIGGERED_SMS_DELIVER: "Entrega",
        TRIGGERED_SMS_OPENING: "Abertura",
        TRIGGERED_SMS_CLICKS: "Cliques",
        TRIGGERED_SMS_TRIGGER_BASE: "Base de Disparo",
        TRIGGERED_SMS_CHOOSE_EXISTENT_TRIGGER_BASE:
          "Escolher Base de Disparo Existente",
        TRIGGERED_SMS_UPLOAD_TRIGGER_BASE_FILE:
          "Fazer Upload de Arquivo de Base de Disparo",
        TRIGGERED_SMS_SELECT_THE_ORIGIN: "Selecione a Origem",
        TRIGGERED_SMS_BASE_FILE_UPLOADED_WITH_SUCCESS:
          "Upload de Arquivo de Base feito com sucesso",
        TRIGGERED_SMS_EDIT_TRIGGER_BASE_FILE:
          "Editar Arquivo de Base de Disparo",
        TRIGGERED_SMS_CLEAN: "Limpar",
        TRIGGERED_SMS_REGISTER_NEW_TRIGGER: "Cadastrar Novo Disparo",
        TRIGGERED_SMS_BASE_FILE_UPLOAD: "Upload de Arquivo de Base",
        TRIGGERED_SMS_UPLOAD: "Fazer Upload",
        TRIGGERED_SMS_CUSTOM: "customizavel",
        TRIGGERED_SMS_ADD_TRIGGER_BASE_FILE:
          "Adicionar Arquivo de Base de Disparo",

        BANNER_LIST_BANNERS: "Banners",
        BANNER_LIST_WAIT: "Aguardar",
        BANNER_LIST_REGISTER: "Salvar",
        BANNER_LIST_VIEW_BANNER: "Ver Banner",
        BANNER_LIST_UPLOAD_NEW_BANNER: "Fazer upload de novo Banner",
        BANNER_LIST_TABLE_NAME: "Nome",
        BANNER_LIST_TABLE_TEXT: "Texto",
        BANNER_LIST_MODAL_LABEL_BANNER_NAME: "Nome de Controle do Banner",
        BANNER_LIST_MODAL_PLACEHOLDER_BANNER_NAME: "Nome do Banner",
        BANNER_LIST_MODAL_BANNER_TEXT: "Texto do Banner",
        BANNER_LIST_MODAL_BANNER_PITCH: "Pitch do Banner",
        BANNER_LIST_MODAL_BANNER_TYPE: "Tipo de Banner",
        BANNER_LIST_MODAL_OPTION_CHOOSE_A_TYPE: "Escolha um Tipo",
        BANNER_LIST_MODAL_OPTION_MEDIA: "Mídia",
        BANNER_LIST_MODAL_OPTION_LANDING_PAGE: "Landing Page",
        BANNER_LIST_MODAL_BANNER_FILE: "Arquivo Banner",

        SMS_LIST_SMS: "SMS",
        SMS_LIST_SMS_SMS_TRIGGER: "Disparo de SMS",
        SMS_LIST_WAIT: "Aguardar",
        SMS_LIST_REGISTER: "Salvar",
        SMS_LIST_SUCCESS_ADDING_TO_SUBMISSION_QUEUE:
          "Sucesso ao adicionar a mensagem de teste a fila de envios!",
        SMS_LIST_SEND: "Enviar",
        SMS_LIST_ERROR_ADDING_TO_SUBMISSION_QUEUE:
          "Falha ao adicionar a mensagem de teste a fila de envios.",
        SMS_LIST_EDIT_SMS: "Editar SMS",
        SMS_LIST_SEND_SMS_TEST: "Enviar SMS de Teste",
        SMS_LIST_CREATE_NEW_SMS: "Criar Novo SMS",
        SMS_LIST_TABLE_NAME: "Nome",
        SMS_LIST_TABLE_TEXT: "Texto",
        SMS_LIST_MODAL_LABEL_SMS_NAME: "Nome de Controle do SMS",
        SMS_LIST_MODAL_PLACEHOLDER_SMS_NAME: "Nome do SMS",
        SMS_LIST_MODAL_SMS_PITCH: "Pitch do SMS",
        SMS_LIST_MODAL_SMS_TEXT: "Texto do SMS",
        SMS_LIST_MODAL_RECIPIENT_NUMBER: "Número do Destinatário",

        ATTENDANCE_ROOM_ATTENDANCE_ROOM: "Sala de atendimento",
        ATTENDANCE_ROOM_SHORT_YEAR: "a",
        ATTENDANCE_ROOM_SHORT_MONTH: "m",
        ATTENDANCE_ROOM_SHORT_WEEK: "sem",
        ATTENDANCE_ROOM_SHORT_DAY: "d",
        ATTENDANCE_ROOM_SHORT_TODAY: "Hoje",
        ATTENDANCE_ROOM_NAME_NOT_INFORMED: "Nome Não Informado",
        ATTENDANCE_ROOM_IN_ATTENDANCE: "Em atendimento",
        ATTENDANCE_ROOM_ARCHIVED: "Arquivado",
        ATTENDANCE_ROOM_FINISHED: "Finalizado",
        ATTENDANCE_ROOM_FILE_MUST_BE_VALID:
          "É preciso escolher um arquivo válido.",
        ATTENDANCE_ROOM_IT_WASNT_POSSIBLE_TO_SEND_FILE:
          "Não foi possível enviar o arquivo.",
        ATTENDANCE_ROOM_IT_WASNT_POSSIBLE_TO_SEND_AUDIO:
          "Não foi possível enviar o áudio.",
        ATTENDANCE_ROOM_IT_WASNT_POSSIBLE_TO_SEND_VIDEO:
          "Não foi possível enviar o vídeo.",
        ATTENDANCE_ROOM_AN_ERROR_OCURRED_SENDING_YOUR_MESSAGE_TRY_AGAIN:
          "Houve uma falha ao enviar sua mensagem, tente novamente em alguns segundos...",
        ATTENDANCE_ROOM_COMMERCIAL_TEAM_UPDATED_WITH_SUCCESS:
          "Equipe Comercial Alterada com Sucesso!",
        ATTENDANCE_ROOM_LEAD_MANAGEMENT: "Gerenciamento do Lead",
        ATTENDANCE_ROOM_LEAD_ANOTHER_INFO: "Outras Informações",
        ATTENDANCE_ROOM_LEAD_PHONES: "Telefones",
        ATTENDANCE_ROOM_LEAD_EMAILS: "E-mails",
        ATTENDANCE_ROOM_LEAD_THERE_ARENT_OTHER_PHONES_REGISTERED:
          "Não há outros Telefones cadastrados",
        ATTENDANCE_ROOM_LEAD_THERE_ARENT_OTHER_EMAILS_REGISTERED:
          "Não há outros E-mails cadastrados",
        ATTENDANCE_ROOM_MERGE_LEAD: "Realizar Mesclagem do Lead",
        ATTENDANCE_ROOM_SEND: "ENVIAR",
        ATTENDANCE_ROOM_RECORD: "GRAVAR",
        ATTENDANCE_ROOM_TAB_NEW_ATTENDANCES: "Novos Atendimentos",
        ATTENDANCE_ROOM_CANCEL_CALL: "Cancelar",
        ATTENDANCE_ROOM_START_CALL: "Ligar",
        ATTENDANCE_ROOM_CALL: "Ligação",
        ATTENDANCE_ROOM_ACCEPT_CALL: "Aceitar",
        ATTENDANCE_ROOM_END_CALL: "Encerrar Ligação",
        ATTENDANCE_ROOM_CALL_IN_PROGRESS: "Ligação em andamento",
        ATTENDANCE_ROOM_DO_YOU_WANT_TO_BLOCK_THIS_CART:
          "Você deseja bloquear este carrinho?",
        ATTENDANCE_ROOM_DO_YOU_WANT_TO_UNBLOCK_THIS_CART:
          "Você deseja desbloquear este carrinho?",
        ATTENDANCE_ROOM_DO_YOU_WANT_TO_PAUSE_THIS_PURCHASE:
          "Você deseja pausar esta compra?",
        ATTENDANCE_ROOM_DO_YOU_WANT_TO_PLAY_THIS_PURCHASE:
          "Você deseja retomar esta compra?",
        ATTENDANCE_ROOM_DO_YOU_WANT_TO_REMOVE_THIS_PURCHASE:
          "Você deseja remover esta compra?",
        ATTENDANCE_ROOM_BLOCK_CART: "Bloquear Carrinho",
        ATTENDANCE_ROOM_UNBLOCK_CART: "Desbloquear Carrinho",
        ATTENDANCE_ROOM_REMOVE_PURCHASE: "Remover Compra",
        ATTENDANCE_ROOM_PAUSE_PURCHASE: "Pausar Compra",
        ATTENDANCE_ROOM_PLAY_PURCHASE: "Retomar Compra",
        ATTENDANCE_ROOM_DO_YOU_WANT_TO_CALL_TO: "Você deseja ligar para",
        ATTENDANCE_ROOM_TAB_ATTENDANCES: "Atendimentos",
        ATTENDANCE_ROOM_TAB_SHOPPER: "Shopper",
        ATTENDANCE_ROOM_TAB_SCREENWRITER: "Roteirizador",
        ATTENDANCE_ROOM_TAB_DELIVERYMAN: "Entregador",
        ATTENDANCE_ROOM_TAB_FINISHED: "Finalizados",
        ATTENDANCE_ROOM_TAB_ARCHIVED: "Arquivados",
        ATTENDANCE_ROOM_THERE_ARE_NO_ENTRIES: "Não há registros",
        ATTENDANCE_ROOM_BROWSER_DOESNT_SUPPORT_VIDEO:
          "O seu navegador não suporta o player de vídeo.",
        ATTENDANCE_ROOM_BROWSER_DOESNT_SUPPORT_AUDIO:
          "O seu navegador não suporta o player de áudio.",
        ATTENDANCE_ROOM_START_CHATTING_WITH: "Inicie a conversa",
        ATTENDANCE_ROOM_WRITE_YOUR_MESSAGE: "Escreva sua mensagem...",
        ATTENDANCE_ROOM_LOWERCASE_SEND: "Enviar",
        ATTENDANCE_ROOM_SELECT_A_ATTENDANCE: "Selecione um Atendimento",
        ATTENDANCE_ROOM_TAB_TOOLBAR: "Toolbar",
        ATTENDANCE_ROOM_TAB_SHOPPING_CART: "Carrinho de Compras",
        ATTENDANCE_ROOM_TAB_TEAM: "Equipe",
        ATTENDANCE_ROOM_TAB_LOGS: "Logs",
        ATTENDANCE_ROOM_TAB_OPTIONS: "Opções",
        ATTENDANCE_ROOM_ATTENDANCE_TOOLS: "Ferramentas do Atendimento",
        ATTENDANCE_ROOM_CURRENT_STATUS: "Status Atual",
        ATTENDANCE_ROOM_LEAD_INFO: "Informações do Lead",
        ATTENDANCE_ROOM_LEAD_INFO_REGISTERED_WITH_SUCCESS:
          "As informações do lead foram salvas com sucesso",
        ATTENDANCE_ROOM_LEAD_INFO_NOT_POSSIBLE_TO_REGISTER:
          "Não foi possível salvar as informações do lead",
        ATTENDANCE_ROOM_IT_WASNT_POSSIBLE_TO_EXECUTE_OPERATION_TRY_AGAIN_LATER:
          "Não foi possível realizar a operação, tente novamente mais tarde.",
        ATTENDANCE_ROOM_TABLE_LABEL_NAME: "Nome",
        ATTENDANCE_ROOM_TABLE_PLACEHOLDER_NAME: "Informe o nome do Lead",
        ATTENDANCE_ROOM_TABLE_LABEL_EMAIL: "E-mail",
        ATTENDANCE_ROOM_TABLE_PLACEHOLDER_EMAIL: "Informe o e-mail do Lead",
        ATTENDANCE_ROOM_TABLE_LABEL_PHONE: "Telefone",
        ATTENDANCE_ROOM_TABLE_PLACEHOLDER_PHONE: "Informe o telefone do Lead",
        ATTENDANCE_ROOM_LEAD_REGISTER_INFO: "Salvar Informações",
        ATTENDANCE_ROOM_CREATE_NEW_ATTENDANCE: "Criar Novo Atendimento",
        ATTENDANCE_ROOM_CURRENT_ATTENDANCE: "Atendimento Atual",
        ATTENDANCE_ROOM_LABEL_SHOPPER: "Shopper",
        ATTENDANCE_ROOM_LABEL_OPTION_CHOOSE_RESPONSIBLE_SHOPPER:
          "Selecione o Shopper Responsável",
        ATTENDANCE_ROOM_LABEL_DELIVERYMAN: "Deliveryman",
        ATTENDANCE_ROOM_LABEL_OPTION_CHOOSE_RESPONSIBLE_DELIVERYMAN:
          "Selecione o Deliveryman Responsável",
        ATTENDANCE_ROOM_LABEL_ATTENDANCE_RESPONSIBLE: "Responsável Atendimento",
        ATTENDANCE_ROOM_OPTION_PRE_ATTENDANCE: "Pré Atendimento",
        ATTENDANCE_ROOM_OPTION_SHOPPER: "Shopper",
        ATTENDANCE_ROOM_OPTION_DELIVERYMAN: "Deliveryman",
        ATTENDANCE_ROOM_LABEL_STATUS: "Status",
        ATTENDANCE_ROOM_LEAD_REGISTER: "Salvar",
        ATTENDANCE_ROOM_MODAL_SEND_IMAGE_OR_VIDEO: "Enviar Imagem ou vídeo",
        ATTENDANCE_ROOM_MODAL_CHOOSE_THE_FILE: "Escolha o Arquivo",
        ATTENDANCE_ROOM_MODAL_SEND_THE_FILE: "Enviar o Arquivo",
        ATTENDANCE_ROOM_MODAL_RECORD_VIDEO: "Gravar vídeo",
        ATTENDANCE_ROOM_REASON_FOR_CONTACT_SAVED_WITH_SUCCESS:
          "Motivo do contato salvo com sucesso.",
        ATTENDANCE_ROOM_REASON_FOR_CONTACT_ERROR_REGISTERING:
          "Houve uma falha ao salvar o motivo do contato",
        ATTENDANCE_ROOM_CHAT_TAB_CLIENT: "Cliente",
        ATTENDANCE_ROOM_CHAT_TAB_PERSONAL_SHOPPER: "Personal Shopper",
        ATTENDANCE_ROOM_CHAT_TAB_DELIVERYMAN: "Entregador",
        ATTENDANCE_ROOM_CHAT_TAB_TEAM: "Equipe",
        ATTENDANCE_ROOM_CLIENT_INFORMATIONS: "Informações do Cliente",
        ATTENDANCE_ROOM_NUMBER_OF_ATTENDANCES: "Qtd. de Atendimentos",
        ATTENDANCE_ROOM_DATE_LAST_ATTENDANCE: "Data Último Atendimento",
        ATTENDANCE_ROOM_CURRENT_COMMUNICATION_CHANNEL:
          "Canal de Comunicação Atual",
        ATTENDANCE_ROOM_ATTENDANCE_TIME: "Tempo de Atendimento",
        ATTENDANCE_ROOM_TOTAL: "Total",
        ATTENDANCE_ROOM_ON_HOLD: "Em Espera",
        ATTENDANCE_ROOM_CAMPAIGN: "Campanha",
        ATTENDANCE_ROOM_TYPE: "Tipo",
        ATTENDANCE_ROOM_USED_CHANNELS: "Canais Utilizados",
        ATTENDANCE_ROOM_REASON_FOR_CONTACT: "Motivo de Contato",
        ATTENDANCE_ROOM_CONTACT_REASON: "Motivo do Contato",
        ATTENDANCE_ROOM_SELECT_THE_CONTACT_REASON:
          "Selecione o Motivo do Contato",
        ATTENDANCE_ROOM_OPTION_QUESTIONS: "Dúvidas",
        ATTENDANCE_ROOM_OPTION_SUGGESTIONS: "Sugestões",
        ATTENDANCE_ROOM_OPTION_COMPLAINTS: "Reclamações",
        ATTENDANCE_ROOM_OPTION_OTHERS: "Outros",
        ATTENDANCE_ROOM_LABEL_CONTACT_DESCRIPTION: "Descrição do Contato",
        ATTENDANCE_ROOM_PLACEHOLDER_CONTACT_DESCRIPTION:
          "Descreva o motivo do Contato",
        ATTENDANCE_ROOM_REASON: "Motivo",
        ATTENDANCE_ROOM_DESCRIPTION: "Descrição",
        ATTENDANCE_ROOM_DATE: "Data",
        ATTENDANCE_ROOM_START_PURCHASE: "Iniciar Compra",
        ATTENDANCE_ROOM_VIEW_SHOPPING_CART: "Visualizar Carrinho",
        ATTENDANCE_ROOM_CLIENT_PURCHASES: "Compras do Cliente",
        ATTENDANCE_ROOM_START_NEW_PURCHASE: "Iniciar Nova Compra",
        ATTENDANCE_ROOM_LABEL_CLIENT_DOCUMENT: "CPF do Cliente",
        ATTENDANCE_ROOM_PLACEHOLDER_CLIENT_DOCUMENT: "Insira o CPF do Cliente",
        ATTENDANCE_ROOM_LABEL_CLIENT_POSTAL_CODE: "CEP do Cliente",
        ATTENDANCE_ROOM_PLACEHOLDER_CLIENT_POSTAL_CODE:
          "Insira o CEP do Cliente",
        ATTENDANCE_ROOM_LABEL_CLIENT_STATE: "Estado",
        ATTENDANCE_ROOM_PLACEHOLDER_CLIENT_STATE: "Insira o Estado do Cliente",
        ATTENDANCE_ROOM_LABEL_CLIENT_CITY: "Cidade",
        ATTENDANCE_ROOM_PLACEHOLDER_CLIENT_CITY: "Insira a Cidade do Cliente",
        ATTENDANCE_ROOM_LABEL_CLIENT_STREET: "Rua",
        ATTENDANCE_ROOM_PLACEHOLDER_CLIENT_STREET: "Insira a Rua do Cliente",
        ATTENDANCE_ROOM_LABEL_CLIENT_NEIGHBORHOOD: "Bairro",
        ATTENDANCE_ROOM_PLACEHOLDER_CLIENT_NEIGHBORHOOD:
          "Insira o Bairro do Cliente",
        ATTENDANCE_ROOM_LABEL_CLIENT_ADDRESS_NUMBER: "Número Residencial",
        ATTENDANCE_ROOM_PLACEHOLDER_CLIENT_ADDRESS_NUMBER:
          "Insira o Número Residencial do Cliente",
        ATTENDANCE_ROOM_LABEL_CLIENT_ADDRESS_COMPLEMENT:
          "Complemento do Endereço",
        ATTENDANCE_ROOM_PLACEHOLDER_CLIENT_ADDRESS_COMPLEMENT:
          "Insira o Complemento do Endereço do Cliente",
        ATTENDANCE_ROOM_LABEL_CHOOSE_SUPERMARKET: "Escolher o Supermercado",
        ATTENDANCE_ROOM_OPTION_SELECT_THE_SUPERMARKET:
          "Selecione o Supermercado",
        ATTENDANCE_ROOM_START_SHOPPING_CART: "Iniciar Carrinho de Compras",
        ATTENDANCE_ROOM_PURCHASE: "Compra",
        ATTENDANCE_ROOM_REPURCHASE: "Recomprar",
        ATTENDANCE_ROOM_TEAM: "Equipe",
        ATTENDANCE_ROOM_THE_ATTENDANCE_WAS_SENT_TO_SHOPPER:
          "O Atendimento foi enviado para o Shopper",
        ATTENDANCE_ROOM_NEED_SHOPPER_HELP: "Precisa de Ajuda da Shopper?",
        ATTENDANCE_ROOM_YES: "Sim",
        ATTENDANCE_ROOM_NO: "Não",
        ATTENDANCE_ROOM_COMMENT_TO_ATTENDANCE_GROUP:
          "Comentário para o Grupo de Atendimento",
        ATTENDANCE_ROOM_WRITE_THE_COMMENT: "Escreva o Comentário",
        ATTENDANCE_ROOM_SELECT_THE_SHOPPER: "Escolha o Shopper",
        ATTENDANCE_ROOM_SELECT_THE_DELIVERYMAN: "Escolha o Deliveryman",
        ATTENDANCE_ROOM_DELIVERY_TYPE: "Tipo de Entrega",
        ATTENDANCE_ROOM_CHOOSE_DELIVERY_TYPE: "Ecolha o Tipo de Entrega",
        ATTENDANCE_ROOM_POP: "Pop",
        ATTENDANCE_ROOM_PROGRAMMED: "Programada",
        ATTENDANCE_ROOM_PRIME: "Prime",
        ATTENDANCE_ROOM_DELIVERY_DATE: "Data de Entrega",
        ATTENDANCE_ROOM_SEND_TO_SHOPPER: "Enviar para o Shopper",
        ATTENDANCE_ROOM_UPDATE_COMMERCIAL_TEAM: "Alterar Equipe Comercial",
        ATTENDANCE_ROOM_UPDATE_DELIVERYMAN_AND_STATUS:
          "Transferir para o Deliveryman",
        ATTENDANCE_ROOM_CHAT_ALLOWED_ONLY_BETWEEN_CLIENT_AND_SHOPPER:
          "Essa conversa se refere ao contato entre o Cliente e o Shopper",
        ATTENDANCE_ROOM_CHAT_ALLOWED_ONLY_BETWEEN_CLIENT_AND_DELIVERYMAN:
          "Essa conversa se refere ao contato entre o Cliente e o Entregador",
        ATTENDANCE_ROOM_CHAT_UNKNOWN_CLIENT: "Cliente não indentificado",
        ATTENDANCE_ROOM_YESTERDAY: "Ontem",

        ATTENDANCE_CART_SHOPPING_CART: "Carrinho de Compras",
        ATTENDANCE_CART_TOTAL_PURCHASE: "Total da Compra",
        ATTENDANCE_CART_CLIENT_CART_URL: "URL do Carrinho do Cliente",
        ATTENDANCE_CART_PRODUCTS_ADDITION: "Adição de Produtos",
        ATTENDANCE_CART_SEARCH_PRODUCTS: "Pesquisar Produtos",
        ATTENDANCE_CART_NO_RESULT_WITH_THESE_TERMS:
          "Nenhum resultado encontrado com esses termos",
        ATTENDANCE_CART_THERE_ARE_NO_ITEMS_IN_YOUR_CART:
          "Não há itens em seu carrinho",
        ATTENDANCE_CART_ATTENDANCE_TEAM: "Equipe de Atendimento",
        ATTENDANCE_CART_TOOLS: "Ferramentas",

        ATTENDANCE_LEAD_MERGE_MERGE_SUCCESS: "Leads mesclados com sucesso!",
        ATTENDANCE_LEAD_MERGE_MERGE_ERROR: "Não foi possível mesclar os Leads",
        ATTENDANCE_LEAD_MERGE_LEAD_MERGE: "Mesclagem do Lead",
        ATTENDANCE_LEAD_MERGE_SEARCH_LEAD: "Pesquisar Lead",
        ATTENDANCE_LEAD_MERGE_SEARCH_FOR_THE_LEAD_TO_BE_MERGED:
          "Pesquise pelo lead a ser mesclado",
        ATTENDANCE_LEAD_MERGE_CLICK_IN_THE_FIELDS_THAT_SHOULD_BE_KEPT:
          "Clique nos campos que devem ser mantidos",
        ATTENDANCE_LEAD_MERGE_LEADS: "Mesclar Leads",
        ATTENDANCE_LEAD_MERGE_NO_LEADS: "Não há leads a serem exibidos",

        ATTENDANCE_LOGS_ATTENDANCE_LOG: "Log do Atendimento",
        ATTENDANCE_LOGS_NO_LOGS: "Não há Logs a serem exibidos",

        ATTENDANCE_OPTIONS_OPTIONS: "Opções",
        ATTENDANCE_OPTIONS_QUICK_MESSAGES: "Mensagens Rápidas",
        ATTENDANCE_OPTIONS_NO_QUICK_MESSAGES:
          "Não há mensagens rápidas a serem exibidas",
        ATTENDANCE_OPTIONS_DO_YOU_WANT_TO_SEND: "Deseja Enviar",

        ATTENDANCE_CONFIG_TITLE: "Configurações do Atendimento",
        ATTENDANCE_CONFIG_OPENING_HOURS: "Horário de Atendimento",
        ATTENDANCE_CONFIG_AUTOMATIC_ANSWER: "Resposta Automática",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS: "Opções de Configuração",

        ATTENDANCE_CONFIG_OPENING_HOURS_MONDAY: "Segunda",
        ATTENDANCE_CONFIG_OPENING_HOURS_TUESDAY: "Terça",
        ATTENDANCE_CONFIG_OPENING_HOURS_WEDNESDAY: "Quarta",
        ATTENDANCE_CONFIG_OPENING_HOURS_THURSDAY: "Quinta",
        ATTENDANCE_CONFIG_OPENING_HOURS_FRIDAY: "Sexta",
        ATTENDANCE_CONFIG_OPENING_HOURS_SATURDAY: "Sábado",
        ATTENDANCE_CONFIG_OPENING_HOURS_SUNDAY: "Domingo",
        ATTENDANCE_CONFIG_OPENING_HOURS_TO: "até",
        ATTENDANCE_CONFIG_OPENING_HOURS_REGISTER_OPENING_HOURS:
          "Salvar Horários de Atendimento",
        ATTENDANCE_CONFIG_OPENING_HOURS_OPENING_HOURS_REGISTERED_WITH_SUCCESS:
          "Horários de Atendimento salvo com sucesso!",
        ATTENDANCE_CONFIG_OPENING_HOURS_IT_WASNT_POSSIBLE_TO_REGISTER_OPENING_HOURS:
          "Não foi possível salvar os horários de atendimentos.",
        ATTENDANCE_CONFIG_OPENING_HOURS_CANT_HAVE_SIMILAR_HOURS_IN_THE_SAME_DAY:
          "Não é possível colocar horários semelhantes em um mesmo dia, ajuste os campos em vermelho e depois tente novamente.",

        ATTENDANCE_CONFIG_AUTOMATIC_ANSWER_REGISTER_OUTSIDE_OPENING_HOURS:
          "Salvar Fora do Horário de Atendimento",
        ATTENDANCE_CONFIG_AUTOMATIC_ANSWER_OPENING_HOURS:
          "Horário de Atendimento",
        ATTENDANCE_CONFIG_AUTOMATIC_ANSWER_OUTSIDE_OPENING_HOURS:
          "Fora do Horário de Atendimento",
        ATTENDANCE_CONFIG_AUTOMATIC_ANSWER_ENTER_THE_AUTOMATIC_ANSWER:
          "Insira a Resposta Automática para quando fora do horário de atendimento",
        ATTENDANCE_CONFIG_AUTOMATIC_ANSWER_AUTOMATIC_ANSWER_REGISTERED_WITH_SUCCESS:
          "Reposta Automática salva com sucesso!",
        ATTENDANCE_CONFIG_AUTOMATIC_ANSWER_IT_WASNT_POSSIBLE_TO_REGISTER_AUTOMATIC_ANSWER:
          "Não foi possível salvar a Resposta Automática.",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_REGISTER_WAITING_TIME:
          "Salvar o Tempo de Espera",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_WAITING_TIME: "Tempo de Espera",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_WAITING_TIME_REGISTERED_WITH_SUCCESS:
          "Tempo de espera salvo com sucesso!",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_IT_WASNT_POSSIBLE_TO_REGISTER_WAITING_TIME:
          "Não foi possível salvar o Tempo de Espera.",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_ATTENDANCE_STATUS:
          "Status do Atendimento",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_IN_ATTENDANCE: "Em Atendimento",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_SENT_TO_DELIVERYMAN:
          "Enviado pro deliverymen",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_MAXIMUM_WAITING_TIME:
          "Tempo Máximo de Espera",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_ENTER_THE_MAXIMUM_WAITING_TIME:
          "Insira o Tempo Máximo de Espera",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_ACTION: "Ação",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_SELECT_THE_ACTION: "Selecione a Ação",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_QUICK_MESSAGES: "Mensagens Rápidas",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_QUICK_MESSAGE: "Mensagem Rápida",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_ENTER_THE_QUICK_MESSAGE:
          "Insira a Mensagem Rápida",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_IT_WASNT_POSSIBLE_TO_EDIT_THE_MESSAGE:
          "Não Foi possível editar a mensagem.",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_IT_WASNT_POSSIBLE_TO_REMOVE_THE_MESSAGE:
          "Não Foi possível remover a mensagem.",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_IT_WASNT_POSSIBLE_TO_CREATE_THE_MESSAGE:
          "Não Foi possível criar a mensagem.",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_QUICK_MESSAGE_CREATED_WITH_SUCCESS:
          "Mensagem Rápida Criada com Sucesso!",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_QUICK_MESSAGE:
          "Tem certeza que deseja remover esta mensagem rápida?",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_ADD_A_QUICK_MESSAGE:
          "Adicionar uma Mensagem Rápida",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_CREATE_QUICK_MESSAGE:
          "Criar Mensagem Rápida",

        JOURNEY_RELATIONSHIP_RULER_LIST: "Lista de Régua de Relacionamento",
        JOURNEY_WAIT: "Aguardar",
        JOURNEY_REGISTER: "Salvar",
        JOURNEY_EDIT: "Editar Jornada",
        JOURNEY_NEW_RELATIONSHIP_RULER: "Nova Régua de Relacionamento",
        JOURNEY_TABLE_NAME: "Nome",
        JOURNEY_TABLE_CREATED: "Criado",
        JOURNEY_TABLE_CHANGED: "Alterado",
        JOURNEY_TABLE_RULER_TYPE: "Tipo da Régua",
        JOURNEY_TABLE_FUNNEL_STAGE: "Etapa do Funil",
        JOURNEY_LABEL_INTERNAL_RULER_CONTROL_NAME:
          "Nome de Controle Interno da Régua",
        JOURNEY_PLACEHOLDER_INTERNAL_RULER_CONTROL_NAME:
          "Nome Régua de Relacionamento",
        JOURNEY_LABEL_RELATIONSHIP_RULER_PITCH:
          "Pitch da Régua de Relacionamento",
        JOURNEY_PLACEHOLDER_RELATIONSHIP_RULER_PITCH:
          "Pitch da Régua de Relacionamento",
        JOURNEY_LABEL_RELATIONSHIP_RULER_TYPE:
          "Tipo da Régua de Relacionamento",
        JOURNEY_OPTION_SELECT_THE_TYPE: "Selecione o Tipo",
        JOURNEY_OPTION_LEAD: "Lead",
        JOURNEY_OPTION_SUSPECT: "Suspect",
        JOURNEY_LABEL_FUNNEL_POSITION: "Posicionamento no Funil",
        JOURNEY_OPTION_SELECT_FUNNEL_POSITION: "Selecione o Posicionamento",
        JOURNEY_JOURNEY_FINAL_DATE: "Data Final Jornada (Opcional)",

        JOURNEY_DESK_CLICK_TO_INTERACT: "Clique para Interagir",
        JOURNEY_DESK_PUBLISHED_BUT_CANT_EDIT_ANYMORE:
          "Jornada Publicada, você não tem mais permissão para editar.",
        JOURNEY_DESK_PUBLISH_JOURNEY: "Publicar Jornada",
        JOURNEY_DESK_UNPUBLISH_JOURNEY: "Despublicar Jornada",
        JOURNEY_DESK_PUBLISHED: "Publicado",
        JOURNEY_DESK_TRIGGERS: "Triggers",
        JOURNEY_DESK_ACTIONS: "Actions",
        JOURNEY_DESK_CONDITIONS: "Conditions",
        JOURNEY_DESK_CONFIG: "Configurar",

        VISITED_PAGE_ERROR_REGISTERING_TRIGGER:
          "Houve uma falha ao salvar a Trigger Página Visitada.",
        VISITED_PAGE_SUCCESS_REGISTERING_TRIGGER:
          "Sucesso ao salvar a Trigger Página Visitada!",
        VISITED_PAGE_ANY_PAGE: "Qualquer Página",
        VISITED_PAGE_SPECIFIC_PAGE: "Página Específica",
        VISITED_PAGE_PLACEHOLDER_URL_SPECIFIC_PAGE: "URL da Página Específica",
        VISITED_PAGE_LOADING_VISITED_PAGE_FORM:
          "Carregando Formulario de Configuração Página Visitada...",

        FACEBOOK_LEADS_ERROR_REGISTERING_TRIGGER:
          "Houve uma falha ao salvar o Trigger Facebook Leads.",
        FACEBOOK_LEADS_SUCCESS_REGISTERING_TRIGGER:
          "Sucesso ao salvar o Trigger Facebook Leads!",
        FACEBOOK_LEADS_ANY_FORM: "Qualquer Formulário",
        FACEBOOK_LEADS_SPECIFIC_FORM: "Formulário Específica",
        FACEBOOK_LEADS_PLACEHOLDER_URL_SPECIFIC_FORM:
          "URL do Formulário Específico",
        FACEBOOK_LEADS_OPTION_SELECT_FORM: "Selecione o Formulário",
        FACEBOOK_LEADS_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração Facebook Leads...",

        EXPORTED_LEADS_ERROR_REGISTERING_TRIGGER:
          "Houve uma falha ao salvar o Trigger dos Leads Exportados.",
        EXPORTED_LEADS_SUCCESS_REGISTERING_TRIGGER:
          "Sucesso ao salvar o Trigger dos Leads Exportados!",
        EXPORTED_LEADS_NO_NEED_TO_CONFIG:
          "Não é necessario configurar o Trigger Leads Exportados...",
        EXPORTED_LEADS_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração dos Leads Exportados...",

        LEADS_99VENDAS_ERROR_REGISTERING_TRIGGER:
          "Houve uma falha ao salvar o Trigger dos Leads 99vendas.",
        LEADS_99VENDAS_SUCCESS_REGISTERING_TRIGGER:
          "Sucesso ao salvar o Trigger dos Leads 99vendas!",
        LEADS_99VENDAS_NO_NEED_TO_CONFIG:
          "Não é necessario configurar o Trigger Leads 99vendas...",
        LEADS_99VENDAS_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração dos Leads 99vendas...",

        INPUT_LEADS_PORTALS_ERROR_REGISTERING_TRIGGER:
          "Houve uma falha ao salvar o Trigger Entrada Leads Portais!",
        INPUT_LEADS_PORTALS_SUCCESS_REGISTERING_TRIGGER:
          "Sucesso ao salvar o Trigger Entrada Leads Portais!",
        INPUT_LEADS_PORTALS_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração do Trigger Entrada Leads Portais...",

        ENTRY_RULER_ERROR_REGISTERING_TRIGGER:
          "Houve uma falha ao salvar o Trigger Entrada Régua!",
        ENTRY_RULER_SUCCESS_REGISTERING_TRIGGER:
          "Sucesso ao salvar o Trigger Entrada Régua!",
        ENTRY_RULER_LABEL_ENTRY_RULER_NAME: "Nome de Entrada Régua",
        ENTRY_RULER_PLACEHOLDER_ENTRY_RULER_NAME: "Nome de Entrada Régua...",
        ENTRY_RULER_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração do Trigger Entrada Régua...",

        ALERT_VIEW_ERROR_REGISTERING_ACTION:
          "Houve uma falha ao salvar a Action Alert View!",
        ALERT_VIEW_SUCCESS_REGISTERING_ACTION:
          "Sucesso ao salvar a Action Action Alert View!",
        ALERT_VIEW_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração da Action Alert View...",

        REPORT_BROKER_ERROR_REGISTERING_ACTION:
          "Houve uma falha ao salvar a Action Comunicar Corretor!",
        REPORT_BROKER_SUCCESS_REGISTERING_ACTION:
          "Sucesso ao salvar a Action Comunicar Corretor!",
        REPORT_BROKER_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração da Action Comunicar Corretor...",
        REPORT_BROKER_LABEL_BROKER_EMAIL: "E-mail do comunicado",
        REPORT_BROKER_OPTION_SELECT_EMAIL: "Selecione o E-mail",
        REPORT_BROKER_LABEL_BROKER_SMS: "SMS do comunicado",
        REPORT_BROKER_OPTION_SELECT_SMS: "Selecione o SMS",

        ADD_DELAY_ERROR_REGISTERING_ACTION:
          "Houve uma falha ao salvar a Action Add Delay!",
        ADD_DELAY_SUCCESS_REGISTERING_ACTION:
          "Sucesso ao salvar a Action Add Delay!",
        ADD_DELAY_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração da Action Add Delay...",
        ADD_DELAY_AMOUNT_OF_TIME: "Quantidade de tempo",
        ADD_DELAY_DELAY_PERIOD: "Periodo do Delay",
        ADD_DELAY_OPTION_SELECT_TIME_MEASUREMENTS:
          "Selecione as medidas de tempo",
        ADD_DELAY_OPTION_SECONDS: "Segundos",
        ADD_DELAY_OPTION_MINUTES: "Minutos",
        ADD_DELAY_OPTION_HOURS: "Horas",
        ADD_DELAY_OPTION_DAYS: "Dias",

        ADD_TAG_ERROR_REGISTERING_ACTION:
          "Houve uma falha ao salvar a Action Add Tag!",
        ADD_TAG_SUCCESS_REGISTERING_ACTION:
          "Sucesso ao salvar a Action Add Tag!",
        ADD_TAG_OPTION_CHOOSE_THE_ACTION: "Escolha a Ação",
        ADD_TAG_OPTION_SELECT_THE_ACTION: "Selecione a Ação",
        ADD_TAG_OPTION_CHOOSE_THE_TAG: "Escolha a TAG",
        ADD_TAG_OPTION_REGISTER_THE_TAG: "Cadastrar a TAG",
        ADD_TAG_OPTION_CHOOSE_THE_DESIRED_TAG: "Escolha a TAG desejada",
        ADD_TAG_OPTION_SELECT_THE_TAG: "Selecione a TAG",
        ADD_TAG_LABEL_TAG_NAME: "Nome da TAG",
        ADD_TAG_PLACEHOLDER_TAG_NAME: "Informe o nome da TAG",
        ADD_TAG_LABEL_TAG_TYPE: "Tipo da TAG",
        ADD_TAG_OPTION_SELECT_THE_TAG_TYPE: "Selecionar o Tipo da TAG",
        ADD_TAG_OPTION_COMMON_TO_ALL_BUSINESS: "Comum a todos Empreendimentos",
        ADD_TAG_OPTION_COMMON_TO_ONLY_THIS_BUSINESS:
          "Apenas esse Empreendimento",
        ADD_TAG_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração da Action Add Tag...",

        LEAD_SCORE_ERROR_REGISTERING_ACTION:
          "Houve uma falha ao salvar a Action Lead Score!",
        LEAD_SCORE_SUCCESS_REGISTERING_ACTION:
          "Sucesso ao salvar a Action Lead Score!",
        LEAD_SCORE_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração da Action Lead Score...",
        LEAD_SCORE_OPTION_SELECT_THE_ACTION_TYPE: "Selecione o tipo de ação",
        LEAD_SCORE_OPTION_INCREASE_SCORE: "Aumentar Score",
        LEAD_SCORE_OPTION_DECREASE_SCORE: "Diminuir Score",
        LEAD_SCORE_LABEL_SCORE_VALUE: "Valor do Score",
        LEAD_SCORE_PLACEHOLDER_SCORE_VALUE: "Informe o valor do score",

        DYNAMIC_IMAGE_ERROR_REGISTERING_ACTION:
          "Houve uma falha ao salvar a Action Imagem Dinâmica!",
        DYNAMIC_IMAGE_SUCCESS_REGISTERING_ACTION:
          "Sucesso ao salvar a Action Imagem Dinâmica!",
        DYNAMIC_IMAGE_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração da Action Imagem Dinâmica...",
        DYNAMIC_IMAGE_LABEL_ID_ACTION: "ID Action",
        DYNAMIC_IMAGE_PLACEHOLDER_ID_ACTION: "ID Action...",

        MODAL_BANNER_ERROR_REGISTERING_ACTION:
          "Houve uma falha ao salvar a Action Modal Banner!",
        MODAL_BANNER_SUCCESS_REGISTERING_ACTION:
          "Sucesso ao salvar a Action Modal Banner!",
        MODAL_BANNER_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração da Action Modal Banner...",

        SEND_EMAIL_ERROR_REGISTERING_ACTION:
          "Houve uma falha ao salvar a Action Enviar E-mail!",
        SEND_EMAIL_SUCCESS_REGISTERING_ACTION:
          "Sucesso ao salvar a Action Enviar E-mail!",
        SEND_EMAIL_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração da Action Enviar E-mail...",
        SEND_EMAIL_LABEL_EMAIL_THAT_WILL_BE_SENT: "E-mail que Será Enviado",
        SEND_EMAIL_OPTION_SELECT_THE_EMAIL: "Selecione o E-mail",

        SEND_SMS_ERROR_REGISTERING_ACTION:
          "Houve uma falha ao salvar a Action Enviar SMS!",
        SEND_SMS_SUCCESS_REGISTERING_ACTION:
          "Sucesso ao salvar a Action Enviar SMS!",
        SEND_SMS_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração da Action Enviar SMS...",
        SEND_SMS_LABEL_SMS_THAT_WILL_BE_SENT: "SMS que Será Enviado",
        SEND_SMS_OPTION_SELECT_THE_SMS: "Selecione o SMS",

        CAPTURE_FORM_ERROR_REGISTERING_ACTION:
          "Houve uma falha ao salvar a Action Capturar Formulário!",
        CAPTURE_FORM_SUCCESS_REGISTERING_ACTION:
          "Sucesso ao salvar a Action Capturar Formulário!",
        CAPTURE_FORM_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração da Action Capturar Formulário...",
        CAPTURE_FORM_LABEL_FORM_IDENTIFIER_TYPE:
          "Tipo de identificador do Formulário",
        CAPTURE_FORM_OPTION_SELECT_THE_IDENTIFIER: "Selecione o Identificador",
        CAPTURE_FORM_OPTION_FORM_ID: "ID do Formulario",
        CAPTURE_FORM_OPTION_FORM_CLASS: "Class do Formulario",
        CAPTURE_FORM_LABEL_FORM_TYPE: "Tipo do Formulario",
        CAPTURE_FORM_OPTION_SELECT_THE_TYPE: "Selecione o tipo",
        CAPTURE_FORM_OPTION_NEW_LEAD: "Novo Lead",
        CAPTURE_FORM_OPTION_NEW_VISIT: "Nova Visita",
        CAPTURE_FORM_OPTION_NEW_CONTACT: "Novo Contato",
        CAPTURE_FORM_FORM_IDENTIFIER: "Identificador do Formulário",
        CAPTURE_FORM_FOLLOW_UP_EMAIL: "E-mail de Acompanhamento",
        CAPTURE_FORM_REDIRECT_PAGE: "Página de Redirecionamento",

        LEAD_ACTIONS_ERROR_REGISTERING_ACTION:
          "Houve uma falha ao salvar a Action Ações do Lead!",
        LEAD_ACTIONS_SUCCESS_REGISTERING_ACTION:
          "Sucesso ao salvar a Action Ações do Lead!",
        LEAD_ACTIONS_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração da Action Ações do Lead...",
        LEAD_ACTIONS_ACTION_IN_CREATION_WAIT_MAINTENANCE:
          "Action em Criação. Aguarde a finalização da manutenção...",

        NEXT_JOURNEY_ERROR_REGISTERING_ACTION:
          "Houve uma falha ao salvar a Action Próxima Jornada!",
        NEXT_JOURNEY_SUCCESS_REGISTERING_ACTION:
          "Sucesso ao salvar a Action Próxima Jornada!",
        NEXT_JOURNEY_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração da Action Próxima Jornada...",
        NEXT_JOURNEY_LABEL_SELECT_THE_NEXT_JOURNEY:
          "Selecione a Próxima Jornada",
        NEXT_JOURNEY_OPTION_SELECT_THE_NEXT_JOURNEY: "Selecione a Jornada",
        NEXT_JOURNEY_LABEL_SELECT_THE_ENTRY_TRIGGER:
          "Selecione o Trigger de Entrada",
        NEXT_JOURNEY_OPTION_SELECT_THE_ENTRY_TRIGGER: "Selecione o Trigger",

        QUEST_OF_INTEREST_ERROR_REGISTERING_ACTION:
          "Houve uma falha ao salvar a Action Questionário de Interesse!",
        QUEST_OF_INTEREST_SUCCESS_REGISTERING_ACTION:
          "Sucesso ao salvar a Action Questionário de Interesse!",
        QUEST_OF_INTEREST_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração da Action Questionário de Interesse...",
        QUEST_OF_INTEREST_LABEL_ACTION_ID: "ID do Action",
        QUEST_OF_INTEREST_PLACEHOLDER_ACTION_ID: "ID do Action...",

        TEST_AB_ERROR_REGISTERING_ACTION:
          "Houve uma falha ao salvar a Action Teste A/B!",
        TEST_AB_SUCCESS_REGISTERING_ACTION:
          "Sucesso ao salvar a Action Teste A/B!",
        TEST_AB_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração da Action Teste A/B...",
        TEST_AB_WHICH_CONTENT_TYPE_WILL_BE_TESTED:
          "Qual tipo de conteúdo será testado",
        TEST_AB_OPTION_SELECT_THE_TYPE: "Selecione o Tipo",
        TEST_AB_OPTION_EMAIL_MARKETING: "E-mail Marketing",
        TEST_AB_WHICH_CONTENT_METHOD_WILL_BE_TESTED:
          "Qual método de conteúdo será testado",
        TEST_AB_OPTION_SELECT_THE_METHOD: "Selecione o Método",
        TEST_AB_OPTION_QUANTITY_OF_OPENING: "Qtd. Abertura",
        TEST_AB_OPTION_QUANTITY_OF_CLICKS: "Qtd. Cliques",
        TEST_AB_LABEL_FOR_HOW_MUCH_TIME: "Por Quanto Tempo",
        TEST_AB_PLACEHOLDER_FOR_HOW_MUCH_TIME:
          "Informe a Qtd. de tempo que será testado!",
        TEST_AB_LABEL_TEST_PERIOD: "Período do Teste",
        TEST_AB_OPTION_SELECT_THE_TIME_MEASUREMENT:
          "Selecione a Medida de Tempo",
        TEST_AB_OPTION_SECONDS: "Segundos",
        TEST_AB_OPTION_MINUTES: "Minutos",
        TEST_AB_OPTION_HOURS: "Horas",
        TEST_AB_OPTION_DAYS: "Dias",

        PUT_ON_HOLD_ERROR_REGISTERING_ACTION:
          "Houve uma falha ao salvar a Action Colocar em Espera!",
        PUT_ON_HOLD_SUCCESS_REGISTERING_ACTION:
          "Sucesso ao salvar a Action Colocar em Espera!",
        PUT_ON_HOLD_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração da Action Colocar em Espera...",
        PUT_ON_HOLD_PAUSE: "Pause",
        PUT_ON_HOLD_PLAY: "Play",

        PITCH_RELATIONSHIP_RULER_ERROR_REGISTERING_ACTION:
          "Houve uma falha ao salvar a Action Pitch Régua de Relacionamento!",
        PITCH_RELATIONSHIP_RULER_SUCCESS_REGISTERING_ACTION:
          "Sucesso ao salvar a Action Pitch Régua de Relacionamento!",
        PITCH_RELATIONSHIP_RULER_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração da Action Pitch Régua de Relacionamento...",
        PITCH_RELATIONSHIP_RULER_LABEL_RELATIONSHIP_RULER_PITCH:
          "Pitch Régua de Relacionamento",
        PITCH_RELATIONSHIP_RULER_PLACEHOLDER_RELATIONSHIP_RULER_PITCH:
          "Informe o Pitch de Relacionamento...",

        SCHEDULED_VISIT_ERROR_REGISTERING_CONDITION:
          "Houve uma falha ao salvar a Condition Agendou Visita!",
        SCHEDULED_VISIT_SUCCESS_REGISTERING_CONDITION:
          "Sucesso ao salvar a Condition Agendou Visita!",
        SCHEDULED_VISIT_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração da Condition Agendou Visita...",
        SCHEDULED_VISIT_DONT_REMEMBER_LEAD_OF_THE_VISIT:
          "Não lembrar o Lead da Visita!",
        SCHEDULED_VISIT_REMEMBER_LEAD_OF_THE_VISIT: "Lembrar o Lead da Visita!",
        SCHEDULED_VISIT_LABEL_HOURS_BEFORE: "Horas antes da Visita",
        SCHEDULED_VISIT_PLACEHOLDER_HOURS_BEFORE:
          "Lembrar quantas horas antes da Visita",
        SCHEDULED_VISIT_LABEL_EMAIL: "E-mail",
        SCHEDULED_VISIT_OPTION_SELECT_THE_EMAIL: "Selecione o E-mail",
        SCHEDULED_VISIT_LABEL_SMS: "SMS",
        SCHEDULED_VISIT_OPTION_SELECT_THE_SMS: "Selecione o SMS",

        CHECK_VISIT_ERROR_REGISTERING_CONDITION:
          "Houve uma falha ao salvar a Condition Conferir Visita!",
        CHECK_VISIT_SUCCESS_REGISTERING_CONDITION:
          "Sucesso ao salvar a Condition Conferir Visita!",
        CHECK_VISIT_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração da Condition Conferir Visita...",
        CHECK_VISIT_NO_NEED_TO_CONFIG:
          "Não é necessario configurar a Condition Conferir Visita...",

        CHECK_PROPOSAL_ERROR_REGISTERING_CONDITION:
          "Houve uma falha ao salvar a Condition Conferir Proposta!",
        CHECK_PROPOSAL_SUCCESS_REGISTERING_CONDITION:
          "Sucesso ao salvar a Condition Conferir Proposta!",
        CHECK_PROPOSAL_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração da Condition Conferir Proposta...",
        CHECK_PROPOSAL_NO_NEED_TO_CONFIG:
          "Não é necessario configurar a Condition Conferir Proposta...",

        PROPOSED_STATUS_ERROR_REGISTERING_CONDITION:
          "Houve uma falha ao salvar a Condition Status Proposta!",
        PROPOSED_STATUS_SUCCESS_REGISTERING_CONDITION:
          "Sucesso ao salvar a Condition Status Proposta!",
        PROPOSED_STATUS_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração da Condition Status Proposta...",
        PROPOSED_STATUS_CONDITION_BEING_DEVELOPED:
          "Esta Condition está em desenvolvimento, por favor aguarde o fim da manutenção...",

        EMAIL_STATUS_ERROR_REGISTERING_CONDITION:
          "Houve uma falha ao salvar a Condition Status E-mail!",
        EMAIL_STATUS_SUCCESS_REGISTERING_CONDITION:
          "Sucesso ao salvar a Condition Status E-mail!",
        EMAIL_STATUS_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração da Condition Status E-mail...",
        EMAIL_STATUS_LABEL_STATUS_TYPE: "Tipo do status de checagem",
        EMAIL_STATUS_OPTION_SELECT_STATUS: "Selecione o Status",
        EMAIL_STATUS_OPTION_EMAIL_OPENED: "E-mail Aberto",
        EMAIL_STATUS_OPTION_EMAIL_CLICKED: "E-mail Clicado",
        EMAIL_STATUS_LABEL_EMAIL_TO_ARRIVE: "E-mail a ser chegado",
        EMAIL_STATUS_OPTION_SELECT_THE_EMAIL: "Selecione o E-mail",

        CHECK_CONTACT_ERROR_REGISTERING_CONDITION:
          "Houve uma falha ao salvar a Condition Conferir Contato!",
        CHECK_CONTACT_SUCCESS_REGISTERING_CONDITION:
          "Sucesso ao salvar a Condition Conferir Contato!",
        CHECK_CONTACT_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração da Condition Conferir Contato...",
        CHECK_CONTACT_NO_NEED_TO_CONFIG:
          "Não é necessario configurar a Condition Conferir Contato...",

        CHECK_SALE_ERROR_REGISTERING_CONDITION:
          "Houve uma falha ao salvar a Condition Conferir Venda!",
        CHECK_SALE_SUCCESS_REGISTERING_CONDITION:
          "Sucesso ao salvar a Condition Conferir Venda!",
        CHECK_SALE_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração da Condition Conferir Venda...",
        CHECK_SALE_NO_NEED_TO_CONFIG:
          "Não é necessario configurar a Condition Conferir Venda...",

        CHECK_REFERRALS_ERROR_REGISTERING_CONDITION:
          "Houve uma falha ao salvar a Condition Conferir Indicações!",
        CHECK_REFERRALS_SUCCESS_REGISTERING_CONDITION:
          "Sucesso ao salvar a Condition Conferir Indicações!",
        CHECK_REFERRALS_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração da Condition Conferir Indicações...",
        CHECK_REFERRALS_NO_NEED_TO_CONFIG:
          "Não é necessario configurar a Condition Conferir Indicações...",

        CHECK_LEAD_ERROR_REGISTERING_CONDITION:
          "Houve uma falha ao salvar a Condition Verifica Lead!",
        CHECK_LEAD_SUCCESS_REGISTERING_CONDITION:
          "Sucesso ao salvar a Condition Verifica Lead!",
        CHECK_LEAD_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração da Condition Verifica Lead...",
        CHECK_LEAD_NO_NEED_TO_CONFIG:
          "Não é necessario configurar a Condition Verifica Lead...",

        CHECK_FEEDBACK_ERROR_REGISTERING_CONDITION:
          "Houve uma falha ao salvar a Condition Conferir Feedback!",
        CHECK_FEEDBACK_SUCCESS_REGISTERING_CONDITION:
          "Sucesso ao salvar a Condition Conferir Feedback!",
        CHECK_FEEDBACK_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração da Condition Conferir Feedback...",
        CHECK_FEEDBACK_NO_NEED_TO_CONFIG:
          "Não é necessario configurar a Condition Conferir Feedback...",

        VISITED_PAGE_CONDITION_ERROR_REGISTERING_CONDITION:
          "Houve uma falha ao salvar a Condition Visitou Página!",
        VISITED_PAGE_CONDITION_SUCCESS_REGISTERING_CONDITION:
          "Sucesso ao salvar a Condition Visitou Página!",
        VISITED_PAGE_CONDITION_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração da Condition Visitou Página...",
        VISITED_PAGE_CONDITION_CONDITION_BEING_DEVELOPED:
          "Esta Condition está em desenvolvimento, por favor aguarde o fim da manutenção...",

        VIEW_SOURCE_ERROR_REGISTERING_CONDITION:
          "Houve uma falha ao salvar a Condition Origem View!",
        VIEW_SOURCE_SUCCESS_REGISTERING_CONDITION:
          "Sucesso ao salvar a Condition Origem View!",
        VIEW_SOURCE_LOADING_CONFIG_FORM:
          "Carregando Formulário de Configuração da Condition Origem View...",
        VIEW_SOURCE_CONDITION_BEING_DEVELOPED:
          "Esta Condition está em desenvolvimento, por favor aguarde o fim da manutenção...",

        HOME_INTEGRATION_INTEGRATIONS: "Integrações",

        INTEGRATION_FACEBOOK_DELETE_PAGE: "Deletar Página",
        INTEGRATION_FACEBOOK_FACEBOOK: "Facebook",
        INTEGRATION_FACEBOOK_FACEBOOK_PAGES: "Facebook Pages",
        INTEGRATION_FACEBOOK_CONFIGURE_FACEBOOK_PAGES:
          "Configurar Facebook Pages",
        INTEGRATION_FACEBOOK_FACEBOOK_ADS: "Facebook Ads",
        INTEGRATION_FACEBOOK_CONFIGURE_FACEBOOK_ADS: "Configurar Facebook Ads",
        INTEGRATION_FACEBOOK_FACEBOOK_FORMS: "Facebook Forms",
        INTEGRATION_FACEBOOK_CONFIGURE_FACEBOOK_FORMS:
          "Configurar Facebook Forms",
        INTEGRATION_FACEBOOK_ADD_FACEBOOK_PAGE: "Adicionar Página do Facebook",
        INTEGRATION_FACEBOOK_LOG_IN_ON_FACEBOOK: "Realizar Login no Facebook",
        INTEGRATION_FACEBOOK_SELECT_FACEBOOK_PAGE:
          "Selecione a Página do Facebook",
        INTEGRATION_FACEBOOK_SELECT_PAGE: "Selecione a Página",
        INTEGRATION_FACEBOOK_WAIT_LOADING: "Aguarde. Carregando...",
        INTEGRATION_FACEBOOK_PAGE_NAME: "Nome da Página",
        INTEGRATION_FACEBOOK_ADD_FACEBOOK_ADS_ACCOUNT:
          "Adicionar Conta de Anúncios do Facebook",
        INTEGRATION_FACEBOOK_SELECT_FACEBOOK_ADS_ACCOUNT:
          "Selecione a Conta de Anúncios do Facebook",
        INTEGRATION_FACEBOOK_SELECT_ACCOUNT: "Selecione a Conta",
        INTEGRATION_FACEBOOK_ADS_ACCOUNT_NAME: "Nome da Conta de Anúncio",
        INTEGRATION_FACEBOOK_ADD_FACEBOOK_FORMS: "Adicionar Forms do Facebook",
        INTEGRATION_FACEBOOK_SELECT_FACEBOOK_FORM:
          "Selecione o Form do Facebook",
        INTEGRATION_FACEBOOK_SELECT_FORM: "Selecione o Form",
        INTEGRATION_FACEBOOK_SEGMENTATION: "Segmentação",
        INTEGRATION_FACEBOOK_SELECT_SEGMENTATION: "Selecione a Segmentação",
        INTEGRATION_FACEBOOK_NO_SEGMENTATION_REGISTERED_CLICK_TO_ADD:
          "Nenhuma Segmentação Cadastrada - Clique para Adicionar",
        INTEGRATION_FACEBOOK_CAMPAIGN_PITCH: "Pitch de Campanha",
        INTEGRATION_FACEBOOK_CONTENT_PITCH: "Pitch de Conteúdo",
        INTEGRATION_FACEBOOK_CLICK_PITCH: "Pitch de Click",
        INTEGRATION_FACEBOOK_NO_PAGES_FOUND_INTEGRATE_THE_PAGES_FIRST:
          "Nenhuma Página Encontrada. Faça a integração das páginas primeiro!",
        INTEGRATION_FACEBOOK_FORM_NAME: "Nome do Form",

        INTEGRATION_CRM_CRMS: "CRMs",
        INTEGRATION_CRM_HYPNOBOX: "Hypnobox",
        INTEGRATION_CRM_CONFIGURE_HYPNOBOX_ACCOUNT: "Configurar Conta Hypnobox",
        INTEGRATION_CRM_SUA_HOUSE: "Sua House",
        INTEGRATION_CRM_CONFIGURE_SUA_HOUSE_ACCOUNT:
          "Configurar Conta Sua House",
        INTEGRATION_CRM_ANAPRO: "Anapro",
        INTEGRATION_CRM_CONFIGURE_ANAPRO_ACCOUNT: "Configurar Conta Anapro",
        INTEGRATION_CRM_PIPEDRIVE: "Pipedrive",
        INTEGRATION_CRM_CONFIGURE_PIPEDRIVE_ACCOUNT:
          "Configurar Conta Pipedrive",
        INTEGRATION_CRM_SALES_FUNNEL: "CRM Funil de Vendas",
        INTEGRATION_CRM_CONFIGURE_SALES_FUNNEL_ACCOUNT:
          "Configurar Conta Funil de Vendas",
        INTEGRATION_CRM_LOPES: "CRM Lopes",
        INTEGRATION_CRM_CONFIGURE_LOPES_ACCOUNT: "Configurar Conta Lopes",
        INTEGRATION_CRM_CONSTRUTOR_DE_VENDAS: "CRM Construtor de Vendas",
        INTEGRATION_CRM_CONFIGURE_CRM_CONSTRUTOR_DE_VENDAS_ACCOUNT:
          "Configurar Conta Construtor de Vendas",

        HYPNOBOX_REMOVE_INTEGRATION: "Remover Integração",
        HYPNOBOX_ADD_HYPNOBOX_ACCOUNT: "Adicionar Conta Hypnobox",
        HYPNOBOX_HYPNOBOX_ACCOUNT_URL: "URL Conta Hypnobox",
        HYPNOBOX_PRODUCT_ID: "ID do Produto",
        HYPNOBOX_SELECT_BROKER: "Selecione a Imobiliária",
        HYPNOBOX_BROKER: "Imobiliária",

        SUA_HOUSE_REMOVE_INTEGRATION: "Remover Integração",
        SUA_HOUSE_ADD_SUA_HOUSE_ACCOUNT: "Adicionar Conta Sua house",
        SUA_HOUSE_ACCOUNT_SUA_HOUSE_URL: "URL da Conta Sua House",
        SUA_HOUSE_BUSINESS_CODE: "Código do Empreendimento",
        SUA_HOUSE_SELECT_BROKER: "Selecione a Imobiliária",
        SUA_HOUSE_BROKER: "Imobiliária",

        ANAPRO_REMOVE_INTEGRATION: "Remover Integração",
        ANAPRO_ADD_ANAPRO_ACCOUNT: "Adicionar Conta Anapro",
        ANAPRO_KEY: "Key",
        ANAPRO_INTEGRATING_KEY: "KeyIntegradora",
        ANAPRO_AGENCY_KEY: "KeyAgencia",
        ANAPRO_CAMPAIGN_KEY: "Código da Campanha",
        ANAPRO_PRODUCT_KEY: "ProdutoKey do empreendimento",
        ANAPRO_CHANNEL_KEY: "CanalKey",
        ANAPRO_SELECT_BROKER: "Selecione a Imobiliária",
        ANAPRO_BROKER: "Imobiliária",

        PIPEDRIVE_NOTIFY_THE_DEVELOPMENT_TEAM_AN_ERROR_OCURRED:
          "Avise a equipe de desenvolvimento que houve um erro na hora de cadastrar uma conta Pipedrive!",
        PIPEDRIVE_REMOVE_INTEGRATION: "Remover Integração",
        PIPEDRIVE_ADD_PIPEDRIVE_ACCOUNT: "Adicionar Conta Pipedrive",
        PIPEDRIVE_FILL_IN_WITH_THE_TOKEN_PROVIDED_BY_PIPEDRIVE:
          "Preencha com o token fornecido pelo pipedrive",
        PIPEDRIVE_ACCESS_INFORMATIONS: "Acessar Informações",
        PIPEDRIVE_SELECT_PIPEDRIVE_FUNNEL:
          "Selecione o Funil Pipedrive(Empreendimento)",
        PIPEDRIVE_SELECT_FUNNEL_STAGE: "Selecione a Etapa do Funil",
        PIPEDRIVE_SELECT_BROKER: "Selecione a Imobiliária",
        PIPEDRIVE_BROKER: "Imobiliária",

        FUNIL_DE_VENDAS_REMOVE_INTEGRATION: "Remover Integração",
        FUNIL_DE_VENDAS_ADD_SALES_FUNNEL_CRM_ACCOUNT:
          "Adicionar Conta CRM Funil De Vendas",
        FUNIL_DE_VENDAS_SALESMAN: "Vendedor",
        FUNIL_DE_VENDAS_FUNNEL: "Funil",
        FUNIL_DE_VENDAS_STAGE: "Etapa",
        FUNIL_DE_VENDAS_SALES_CHANNEL: "Canal de vendas",
        FUNIL_DE_VENDAS_INTEGRATION_KEY: "Integration Key",
        FUNIL_DE_VENDAS_SELECT_BROKER: "Selecione a Imobiliária",
        FUNIL_DE_VENDAS_BROKER: "Imobiliária",

        LOPES_REMOVE_INTEGRATION: "Remover Integração",
        LOPES_ADD_LOPES_ACCOUNT: "Adicionar Conta Lopes",
        LOPES_COMPANY_CODE: "Código da Empresa",
        LOPES_PRODUCT_CODE: "Código do Produto",
        LOPES_SELECT_BROKER: "Selecione a Imobiliária",
        LOPES_BROKER: "Imobiliária",

        CONSTRUTOR_DE_VENDAS_REMOVE_INTEGRATION: "Remover Integração",
        CONSTRUTOR_DE_VENDAS_ADD_CONSTRUTOR_DE_VENDAS_ACCOUNT:
          "Adicionar Conta Construtor de Vendas",
        CONSTRUTOR_DE_VENDAS_COMPANY_URL: "Sub Dominio do CRM",
        CONSTRUTOR_DE_VENDAS_COMPANY_TOKEN: "Token de acesso",
        CONSTRUTOR_DE_VENDAS_CRM_MODULE: "Modulo",
        CONSTRUTOR_DE_VENDAS_PRODUCT_ID: "ID do empreendimento",
        CONSTRUTOR_DE_VENDAS_SELECT_BROKER: "Selecione a Imobiliária",
        CONSTRUTOR_DE_VENDAS_BROKER: "Imobiliária",

        INTEGRATION_WEB_SITES_WEBSITES: "Websites",
        INTEGRATION_WEB_SITES_MONITORING_CODE: "Código de Monitoramento",
        INTEGRATION_WEB_SITES_VISUALIZE_CODE: "Visualizar Código",
        INTEGRATION_WEB_SITES_SMARTLOOK: "Smartlook",
        INTEGRATION_WEB_SITES_CONFIGURE_SMARTLOOK: "Configurar Smartlook",
        INTEGRATION_WEB_SITES_PORTALS: "Portais",
        INTEGRATION_WEB_SITES_CONFIGURE_PORTALS: "Configurar Portais",
        INTEGRATION_WEB_SITES_EMAIL_NOTIFICATIONS: "Notificação por E-mail",
        INTEGRATION_WEB_SITES_MANAGE_NOTIFICATIONS: "Gerenciar Notificações",

        INTEGRATION_CHATBOT_TITLE: "Chatbots",
        INTEGRATION_CHATBOT_WEBHOOK_LEADSTER: "Webhook Leadster",
        INTEGRATION_CHATBOT_VISUALIZE_URL: "Visualizar URL",
        INTEGRATION_CHATBOT_URL_WEBHOOK_LEADSTER: "URL Webhook Leadster",

        SMARTLOOK_REGISTER_SMARTLOOK_INTEGRATION: "Salvar Integração Smartlook",
        SMARTLOOK_CONFIGURE_SMARTLOOK_INTEGRATION:
          "Configurar Integração Smartlook",
        SMARTLOOK_CHOOSE_THE_PROJECT_YOU_WANT_TO_USE:
          "Escolha o projeto que deseja utilizar...",
        SMARTLOOK_SELECT_THE_PROJECT: "Selecione o Projeto",

        PORTALS_REMOVE_INTEGRATION: "Remover Integração",
        PORTALS_REGISTER_PORTALS: "Cadastrar Portais",
        PORTALS_ADD_NEW_PORTAL: "Adicionar Novo Portal",
        PORTALS_NAME_USED_IN_THE_PORTAL_SUBJECT:
          "Nome Utilizado no Assunto do Portal",
        PORTALS_SELECT_PORTAL: "Selecione o Portal",
        PORTALS_PORTAL: "Portal",

        EMAIL_NOTIFICATION_FORM_ID: "ID do Form",
        EMAIL_NOTIFICATION_DEPARTMENT: "Departamento",
        EMAIL_NOTIFICATION_SELECT_THE_DEPARTMENT: "Selecione o Departamento",
        EMAIL_NOTIFICATION_EMAILS: "E-mails",
        EMAIL_NOTIFICATION_EMAIL_TO_BE_NOTIFIED: "E-mail a ser notificado",
        EMAIL_NOTIFICATION_ENTER_THE_EMAIL: "Insira o e-mail",
        EMAIL_NOTIFICATION_ADD_EMAIL: "Adicionar E-mail",
        EMAIL_NOTIFICATION_ADD_EMAIL_NOTIFICATION:
          "Adicionar Notificação por E-mail",
        EMAIL_NOTIFICATION_REMOVE_NOTIFICATION: "Remover Notificação",
        EMAIL_NOTIFICATION_REGISTER_EMAIL_NOTIFICATION:
          "Registrar Notificação por E-mail",
        EMAIL_NOTIFICATION_ADD_NEW_NOTIFICATION: "Adicionar nova Notificação",

        SUSPECT_LIST_VISUALIZE_SUSPECT: "Visualizar o Suspect",
        SUSPECT_LIST_VISUALIZE: "Visualizar",
        SUSPECT_LIST_SUSPECTS_LIST: "Lista de Suspects",
        SUSPECT_LIST_RESULTS_PER_PAGE: "Resultados por página",
        SUSPECT_LIST_SEARCH_A_SUSPECT: "Procure um Suspect",
        SUSPECT_LIST_SEARCH_FOR_DOMAIN: "Busque por Domínio",
        SUSPECT_LIST_SELECT_THE_DOMAIN: "Selecione o Domínio",
        SUSPECT_LIST_NUMBER: "Nº",
        SUSPECT_LIST_ORIGIN: "Origem",
        SUSPECT_LIST_SEGMENTATION: "Segmentação",
        SUSPECT_LIST_ENTRY_DATE: "Data de entrada",
        SUSPECT_LIST_ADVANCED_SEARCH: "Busca avançada",

        SUSPECT_MODAL_DATA_LOADING: "Carregando...",
        SUSPECT_MODAL_VISUALIZE_SUSPECT: "Visualizar o Suspect",
        SUSPECT_MODAL_SUSPECT: "Suspect",
        SUSPECT_MODAL_UNKNOWN: "N/C",
        SUSPECT_MODAL_SUSPECT_INFORMATIONS: "informações do Suspect",
        SUSPECT_MODAL_LAST_CONVERSION: "Última Conversão",
        SUSPECT_MODAL_FIRST_ACTION: "Primeira ação",
        SUSPECT_MODAL_SEGMENTATION: "Segmentação",
        SUSPECT_MODAL_WEBSITE_OR_LANDING_PAGE: "Website ou landing page",
        SUSPECT_MODAL_BROWSER: "Navegador",
        SUSPECT_MODAL_DEVICE: "Dispositivo",
        SUSPECT_MODAL_LAST_NAVIGATION: "Última Navegação",
        SUSPECT_MODAL_COOKIES: "Cookies",
        SUSPECT_MODAL_IPS: "IP's",
        SUSPECT_MODAL_SUSPECT_ACCESSES: "Acessos do suspect",
        SUSPECT_MODAL_VISUALIZE_NAVIGATION: "Visualizar Navegação",

        HEADER_MAIN_CURRENT_MONTH: "Mês atual",
        HEADER_MAIN_LAST_MONTH: "Último mês",
        HEADER_MAIN_TODAY: "Hoje",
        HEADER_MAIN_YESTERDAY: "Ontem",
        HEADER_MAIN_LAST_7_DAYS: "Últimos 7 dias",
        HEADER_MAIN_LAST_30_DAYS: "Últimos 30 dias",
        HEADER_MAIN_LAST_90_DAYS: "Últimos 90 dias",
        HEADER_MAIN_CHOOSE_DATES: "Escolher datas",
        HEADER_MAIN_DATES: "Datas",
        HEADER_MAIN_INITIAL_DATE: "Data Inicial",
        HEADER_MAIN_FINAL_DATE: "Data Final",
        HEADER_MAIN_CONSULT: "Consultar",

        LIST_COMPANIES_COMPANY: "Companhia",
        LIST_BUSINESS_BUSINESS: "Negócio",

        MENU_MAIN_BUYLITICS: "Buylitics",
        MENU_MAIN_CHANGE: "Trocar",
        MENU_MAIN_MAIN: "Main",
        MENU_MAIN_IN_MAINTENANCE_WAIT: "Em Manutenção, Aguarde!",
        MENU_MAIN_CONFIG: "Config",
        MENU_MAIN_COMING_SOON: "Em Breve!",
        MENU_MAIN_SUPPORT_COMING_SOON: "Suporte (em breve)",
        MENU_MAIN_SIGN_OUT: "Sair",

        DASHBOARD_ATTENDANCES_CARD_TOTAL: "total",
        DASHBOARD_ATTENDANCES_CARD_AVERAGE: "médio",
        DASHBOARD_ATTENDANCES_CARD_MAXIMUM: "máximo",
        DASHBOARD_ATTENDANCES_CARD_MINIMUM: "mínimo",
        DASHBOARD_ATTENDANCES_CARD_SCORE: "Nota",
        DASHBOARD_ATTENDANCES_CARD_CLIENTS: "clientes",
        DASHBOARD_ATTENDANCES_CARD_CHANNEL: "Canal",
        DASHBOARD_ATTENDANCES_CARD_ACCOUNTS: "contas",
        DASHBOARD_ATTENDANCES_CARD_NUMBERS: "números",
        DASHBOARD_ATTENDANCES_CARD_PURCHASES: "compras",
        DASHBOARD_ATTENDANCES_CARD_WAITING: "aguardando",
        DASHBOARD_ATTENDANCES_CARD_DELIVERIES: "entregas",
        DASHBOARD_ATTENDANCES_CARD_ON_HOLD: "em espera",
        DASHBOARD_ATTENDANCES_CARD_ATTENDANCES_DASHBOARD:
          "Dashboard de Atendimentos",
        DASHBOARD_ATTENDANCES_CARD_PRE_PURCHASE_AND_ATTENDANCE:
          "Pré Compra e Atendimento",
        DASHBOARD_ATTENDANCES_CARD_ATTENDANCES_TOTAL: "Total de Atendimentos",
        DASHBOARD_ATTENDANCES_CARD_ATTENDANCE_AVERAGE_TIME:
          "Tempo Médio de Atendimento",
        DASHBOARD_ATTENDANCES_CARD_AVERAGE_PURCHASE_TIME:
          "Tempo Médio de Compra",
        DASHBOARD_ATTENDANCES_CARD_MAXIMUM_ATTENDANCE_TIME:
          "Tempo Máximo de Atendimento",
        DASHBOARD_ATTENDANCES_CARD_MAXIMUM_PURCHASE_TIME:
          "Tempo Máximo de Compra",
        DASHBOARD_ATTENDANCES_CARD_AVERAGE_EVALUATION_SCORE:
          "Nota de Avaliação Média",
        DASHBOARD_ATTENDANCES_CARD_NEW_CLIENTS: "Novos Clientes",
        DASHBOARD_ATTENDANCES_CARD_LOYAL_CLIENTS: "Clientes Fidelizados",
        DASHBOARD_ATTENDANCES_CARD_CHANNELS: "Canais",
        DASHBOARD_ATTENDANCES_CARD_CHANNEL_MOST_USED: "Canal mais Utilizado",
        DASHBOARD_ATTENDANCES_CARD_ATTENDANCES_BY_FACEBOOK:
          "Atendimentos por Facebook",
        DASHBOARD_ATTENDANCES_CARD_ATTENDANCES_BY_INSTAGRAM:
          "Atendimentos por Instagram",
        DASHBOARD_ATTENDANCES_CARD_ATTENDANCES_BY_WHATSAPP:
          "Atendimentos por Whatsapp",
        DASHBOARD_ATTENDANCES_CARD_ATTENDANCES_BY_TWITTER:
          "Atendimentos por Twitter",
        DASHBOARD_ATTENDANCES_CARD_ATTENDANCES_BY_EMAIL:
          "Atendimentos por E-mail",
        DASHBOARD_ATTENDANCES_CARD_ATTENDANCES_BY_CHAT: "Atendimentos por Chat",
        DASHBOARD_ATTENDANCES_CARD_ATTENDANCES_BY_PHONE:
          "Atendimentos por Telefone",
        DASHBOARD_ATTENDANCES_CARD_PURCHASE: "Purchase",
        DASHBOARD_ATTENDANCES_CARD_PURCHASES_TOTAL: "Total de Compras",
        DASHBOARD_ATTENDANCES_CARD_SHOPPER_MAXIMUM_TIME:
          "Tempo Máximo do Shopper",
        DASHBOARD_ATTENDANCES_CARD_SHOPPER_AVERAGE_TIME:
          "Tempo Médio do Shopper",
        DASHBOARD_ATTENDANCES_CARD_AVERAGE_TICKET: "Ticket Médio",
        DASHBOARD_ATTENDANCES_CARD_MAXIMUM_TICKET: "Ticket Máximo",
        DASHBOARD_ATTENDANCES_CARD_MINIMUM_TICKET: "Ticket Mínimo",
        DASHBOARD_ATTENDANCES_CARD_ALL_WAITING_DELIVERY:
          "Todos - Aguardando Entrega",
        DASHBOARD_ATTENDANCES_CARD_POP_WAITING_DELIVERY:
          "Pop - Aguardando Entrega",
        DASHBOARD_ATTENDANCES_CARD_PRIME_WAITING_DELIVERY:
          "Prime - Aguardando Entrega",
        DASHBOARD_ATTENDANCES_CARD_PROGRAMMED_WAITING_DELIVERY:
          "Programada - Aguardando Entrega",
        DASHBOARD_ATTENDANCES_CARD_POP_DELIVERY: "Entrega Pop",
        DASHBOARD_ATTENDANCES_CARD_POP_DELIVERY_TOTAL:
          "Pop - Total de Entregas",
        DASHBOARD_ATTENDANCES_CARD_POP_AVERAGE_KILOMETER:
          "Pop - Quilometragem Média",
        DASHBOARD_ATTENDANCES_CARD_POP_AVERAGE_SHIPPING_TICKET:
          "Pop - Ticket Médio de Frete",
        DASHBOARD_ATTENDANCES_CARD_POP_DELIVERY_AVERAGE_TIME:
          "Pop - Tempo Médio de Entrega",
        DASHBOARD_ATTENDANCES_CARD_POP_MAXIMUM_DELIVERY_TIME:
          "Pop - Tempo Máximo de Entrega",
        DASHBOARD_ATTENDANCES_CARD_POP_DELIVERY_MINIMUM_TIME:
          "Pop - Tempo Mínimo de Entrega",
        DASHBOARD_ATTENDANCES_CARD_PRIME_DELIVERY: "Entrega Prime",
        DASHBOARD_ATTENDANCES_CARD_PRIME_DELIVERY_TOTAL:
          "Prime - Total de Entregas",
        DASHBOARD_ATTENDANCES_CARD_PRIME_AVERAGE_KILOMETER:
          "Prime - Quilometragem Média",
        DASHBOARD_ATTENDANCES_CARD_PRIME_AVERAGE_SHIPPING_TICKET:
          "Prime - Ticket Médio de Frete",
        DASHBOARD_ATTENDANCES_CARD_PRIME_DELIVERY_AVERAGE_TIME:
          "Prime - Tempo Médio de Entrega",
        DASHBOARD_ATTENDANCES_CARD_PRIME_DELIVERY_MAXIMUM_TIME:
          "Prime - Tempo Máximo de Entrega",
        DASHBOARD_ATTENDANCES_CARD_PRIME_DELIVERY_MINIMUM_TIME:
          "Prime - Tempo Mínimo de Entrega",
        DASHBOARD_ATTENDANCES_CARD_PROGRAMMED_DELIVERY: "Entrega Programada",
        DASHBOARD_ATTENDANCES_CARD_PROGRAMMED_DELIVERY_TOTAL:
          "Programada - Total de Entregas",
        DASHBOARD_ATTENDANCES_CARD_PROGRAMMED_AVERAGE_KILOMETER:
          "Programada - Quilometragem Média",
        DASHBOARD_ATTENDANCES_CARD_PROGRAMMED_AVERAGE_SHIPPING_TICKET:
          "Programada - Ticket Médio de Frete",
        DASHBOARD_ATTENDANCES_CARD_PROGRAMMED_DELIVERY_AVERAGE_TIME:
          "Programada - Tempo Médio de Entrega",
        DASHBOARD_ATTENDANCES_CARD_PROGRAMMED_DELIVERY_MAXIMUM_TIME:
          "Programada - Tempo Máximo de Entrega",
        DASHBOARD_ATTENDANCES_CARD_PROGRAMMED_DELIVERY_MINIMUM_TIME:
          "Programada - Tempo Mínimo de Entrega",
        DASHBOARD_ATTENDANCES_CARD_WAIT: "Espera",
        DASHBOARD_ATTENDANCES_CARD_AVERAGE_WAITING_TIME_PER_ATTENDANCE:
          "Tempo Médio de Espera por Atendimento",
        DASHBOARD_ATTENDANCES_CARD_MAXIMUM_WAITING_TIME_PER_ATTENDANCE:
          "Tempo Máximo de Espera por Atendimento",
        DASHBOARD_ATTENDANCES_CARD_MINIMUM_WAITING_TIME_PER_ATTENDANCE:
          "Tempo Mínimo de Espera por Atendimento",
        DASHBOARD_ATTENDANCES_CARD_TOTAL_OF_PRE_PURCHASES_ON_HOLD:
          "Total de Pré-compras em Espera",
        DASHBOARD_ATTENDANCES_CARD_TOTAL_OF_SHOPPERS_ON_HOLD:
          "Total de Shoppers em Espera",
        DASHBOARD_ATTENDANCES_CARD_TOTAL_OF_DELIVERIES_ON_HOLD:
          "Total de Entregas em Espera",
        // ANDERSON
        //DASHBOARD_ATTENDANCES_CARD_SHIPPING_STATUS_IN_ATTENDANCE_TOTAL: "Entregas em Atendimento",
        DASHBOARD_ATTENDANCES_CARD_SHIPPING_STATUS_IN_ATTENDANCE_TOTAL:
          "Pedidos com a Shopper",
        DASHBOARD_ATTENDANCES_CARD_SHIPPING_STATUS_AWAITING_SCRIPTING_TOTAL:
          "Aguardando Roteirização",
        DASHBOARD_ATTENDANCES_CARD_SHIPPING_STATUS_SENT_TO_DELIVERYMAN_TOTAL:
          "Entregador Escolhido",
        DASHBOARD_ATTENDANCES_CARD_SHIPPING_STATUS_AWAITING_DELIVERY_START_TOTAL:
          "Aguardando Início da Entrega",
        DASHBOARD_ATTENDANCES_CARD_SHIPPING_STATUS_ON_DELIVERY_TOTAL:
          "Entrega em Andamento",
        DASHBOARD_ATTENDANCES_CARD_SHIPPING_STATUS_FINISHED_TOTAL:
          "Entrega Realizada",

        ADMIN_USERS_TITLE: "Admin Usuários",
        ADMIN_USERS_LIST_USERS: "Listar Usuários",
        ADMIN_USERS_LIST_LIST_OF_USERS: "Listar Usuários",
        ADMIN_USERS_LIST_OF_USERS: "Lista de Usuários",
        ADMIN_USERS_LIST_ACCESS_BUTTON: "Acessar Usuário",
        ADMIN_USERS_USER_NAME: "Nome do Usuário",
        ADMIN_USERS_USER_EMAIL: "E-mail do Usuário",
        ADMIN_USERS_USER_ACCESS_LEVEL: "Nível de Acesso do Usuário",
        ADMIN_USERS_REGISTER_WAIT_STATUS:
          "Aguarde Estamos Cadastrando o Usuário.",
        ADMIN_USERS_REGISTER_SUCCESS_STATUS: "Usuário Cadastrado Com Sucesso!",
        ADMIN_USERS_REGISTER_USER: "Cadastrar Usuários",
        ADMIN_USERS_REGISTER_LABEL_NAME: "Nome",
        ADMIN_USERS_REGISTER_PLACEHOLDER_NAME: "Nome do Usuário",
        ADMIN_USERS_REGISTER_LABEL_ACCESS_LEVEL: "Nível de Acesso",
        ADMIN_USERS_REGISTER_SELECT_ACCESS_LEVEL: "Selecione o Nível de Acesso",
        ADMIN_USERS_REGISTER_SELECT_ADMIN: "Admin",
        ADMIN_USERS_REGISTER_LABEL_EMAIL: "E-mail",
        ADMIN_USERS_REGISTER_PLACEHOLDER_EMAIL: "E-mail do Usuário",
        ADMIN_USERS_REGISTER_LABEL_PASSWORD: "Password",
        ADMIN_USERS_REGISTER_PLACEHOLDER_PASSWORD: "Senha do Usuário",
        ADMIN_USERS_REGISTER_REGISTER_USER: "Cadastrar Novo Usuário",

        COMMERCIAL_EMKSA_TITLE: "Time Emksa",
        COMMERCIAL_EMKSA_PRE_ATTENDANCE: "Pré-Atendimento",
        COMMERCIAL_EMKSA_SHOPPER: "Shopper",
        COMMERCIAL_EMKSA_DELIVERYMAN: "Deliveryman",
        COMMERCIAL_EMKSA_EDIT: "Editar",
        COMMERCIAL_EMKSA_SUPERMARKET: "Supermercado",
        COMMERCIAL_EMKSA_SELECT_THE_SUPERMARKET: "Selecione o Supermercado",
        COMMERCIAL_EMKSA_ALL_THE_SUPERMARKET: "Todos os Supermercados",
        COMMERCIAL_EMKSA_ADD_SUPERMARKET: "Adicionar Supermercado",

        COMMERCIAL_PRE_ATTENDANCE_REGISTERED_WITH_SUCCESS:
          "Pré-Atendimento Salvo com Sucesso",
        COMMERCIAL_PRE_ATTENDANCE_IT_WASNT_POSSIVLE_TO_REGISTER_PRE_ATTENDANCE:
          "Não foi possível Registrar o Pré-Atendimento",
        COMMERCIAL_PRE_ATTENDANCE_PRE_ATTENDANCE_LIST:
          "Lista de Pré-Atendimento",
        COMMERCIAL_PRE_ATTENDANCE_ADD_PRE_ATTENDANCE:
          "Adicionar Pré-Atendimento",
        COMMERCIAL_PRE_ATTENDANCE_EDIT_PRE_ATTENDANCE: "Editar Pré-Atendimento",
        COMMERCIAL_PRE_ATTENDANCE_NAME: "Nome",
        COMMERCIAL_PRE_ATTENDANCE_EMAIL: "E-mail",
        COMMERCIAL_PRE_ATTENDANCE_PHONE: "Telefone",
        COMMERCIAL_PRE_ATTENDANCE_SUPERMARKETS: "Supermercados",
        COMMERCIAL_PRE_ATTENDANCE_LABEL_NAME: "Insira o Nome",
        COMMERCIAL_PRE_ATTENDANCE_LABEL_EMAIL: "Insira o E-mail",
        COMMERCIAL_PRE_ATTENDANCE_LABEL_PHONE: "Insira o Telefone",

        COMMERCIAL_DELIVERYMAN_REGISTERED_WITH_SUCCESS:
          "Deliveryman Salvo com Sucesso",
        COMMERCIAL_DELIVERYMAN_IT_WASNT_POSSIVLE_TO_REGISTER_DELIVERYMAN:
          "Não foi possível Registrar o Deliveryman",
        COMMERCIAL_DELIVERYMAN_DELIVERYMAN_LIST: "Lista de Deliveryman",
        COMMERCIAL_DELIVERYMAN_ADD_DELIVERYMAN: "Adicionar Deliveryman",
        COMMERCIAL_DELIVERYMAN_EDIT_DELIVERYMAN: "Editar Deliveryman",
        COMMERCIAL_DELIVERYMAN_NAME: "Nome",
        COMMERCIAL_DELIVERYMAN_EMAIL: "E-mail",
        COMMERCIAL_DELIVERYMAN_PHONE: "Telefone",
        COMMERCIAL_DELIVERYMAN_SUPERMARKETS: "Supermercados",
        COMMERCIAL_DELIVERYMAN_LABEL_NAME: "Insira o Nome",
        COMMERCIAL_DELIVERYMAN_LABEL_EMAIL: "Insira o E-mail",
        COMMERCIAL_DELIVERYMAN_LABEL_PHONE: "Insira o Telefone",

        COMMERCIAL_SHOPPER_REGISTERED_WITH_SUCCESS: "Shopper Salvo com Sucesso",
        COMMERCIAL_SHOPPER_IT_WASNT_POSSIVLE_TO_REGISTER_SHOPPER:
          "Não foi possível Registrar o Shopper",
        COMMERCIAL_SHOPPER_SHOPPER_LIST: "Lista de Shopper",
        COMMERCIAL_SHOPPER_ADD_SHOPPER: "Adicionar Shopper",
        COMMERCIAL_SHOPPER_EDIT_SHOPPER: "Editar Shopper",
        COMMERCIAL_SHOPPER_NAME: "Nome",
        COMMERCIAL_SHOPPER_EMAIL: "E-mail",
        COMMERCIAL_SHOPPER_PHONE: "Telefone",
        COMMERCIAL_SHOPPER_SUPERMARKETS: "Supermercados",
        COMMERCIAL_SHOPPER_LABEL_NAME: "Insira o Nome",
        COMMERCIAL_SHOPPER_LABEL_EMAIL: "Insira o E-mail",
        COMMERCIAL_SHOPPER_LABEL_PHONE: "Insira o Telefone",

        DASHBOARD_PERFORMANCE_DASHBOARD_PERFORMANCE: "Dashboard Performance",
        DASHBOARD_PERFORMANCE_GO_BACK: "Voltar",

        DASHBOARD_PERFORMANCE_CARD_VIEW_NOT_FOUND: "View Não Encontrada",
        DASHBOARD_PERFORMANCE_CARD_ENTER_THE_CARDS_TITLE:
          "Insira o Título do Card",
        DASHBOARD_PERFORMANCE_CARD_ENTER_THE_CARDS_VALUE:
          "Insira o Valor do Card",
        DASHBOARD_PERFORMANCE_CARD_ENTER_THE_CARDS_SHORT_DESCRIPTION:
          "Insira o Resumo do Card",

        DASHBOARD_PERFORMANCE_VIEW_THERE_ARENT_ANY_REGISTERS:
          "Não Há Registros",
        DASHBOARD_PERFORMANCE_VIEW_POSITION: "Posição",
        DASHBOARD_PERFORMANCE_VIEW_DATE: "Data",
        DASHBOARD_PERFORMANCE_VIEW_QUANTITY: "Quantidade",

        DASHBOARD_PERFORMANCE_COMPARISON_COMPARISON_MODAL:
          "Modal de Comparação",
        DASHBOARD_PERFORMANCE_COMPARISON_CARDS_COMPARISON:
          "Comparação de Cards",
        DASHBOARD_PERFORMANCE_COMPARISON_METRIC_COMPARISON_IN_BAR_CHART:
          "Comparação de Métricas em Gráfico de Barra",

        DASHBOARD_PERFORMANCE_CARD_CARD_NOT_FOUND: "Card Não Encontrado",
        DASHBOARD_PERFORMANCE_CARD_NOT_FOUND: "não foi encontrado",
        DASHBOARD_PERFORMANCE_CARD_ADD_STAGE: "Adicionar Etapa",
        DASHBOARD_PERFORMANCE_CARD_ADD_CARD: "Adicionar Card",
        DASHBOARD_PERFORMANCE_CARD_CLICK_TO_ADD_CARD:
          "Clique para Adicionar Card",
        DASHBOARD_PERFORMANCE_CARD_CHANGE_CARD_MAIN_METRIC:
          "Editar Métrica do Card",
        DASHBOARD_PERFORMANCE_CARD_REMOVE_DASHBOARD: "Remover Dashboard",
        DASHBOARD_PERFORMANCE_CARD_EDIT_DASHBOARD: "Editar Dashboard",
        DASHBOARD_PERFORMANCE_CARD_ENTER_THE_DASHBOARD_TITLE:
          "Insira o Título da Dashboard",
        DASHBOARD_PERFORMANCE_CARD_ENTER_THE_DASHBOARD_SHORT_DESCRIPTION:
          "Insira o Resumo da Dashboard",
        DASHBOARD_PERFORMANCE_CARD_EDIT_CARD: "Editar Card",
        DASHBOARD_PERFORMANCE_CARD_COMPARE_CARD: "Comparar Card",
        DASHBOARD_PERFORMANCE_CARD_REMOVE_FROM_COMPARISON:
          "Remover da Comparação",
        DASHBOARD_PERFORMANCE_CARD_REMOVE_CARD: "Remover Card",
        DASHBOARD_PERFORMANCE_CARD_CONFIRM_CHANGES: "Confirmar Edição",
        DASHBOARD_PERFORMANCE_CARD_EDIT_STAGE: "Editar a Etapa",
        DASHBOARD_PERFORMANCE_CARD_REMOVE_STAGE: "Remover a Etapa",
        DASHBOARD_PERFORMANCE_CARD_VISUALIZE_METRIC: "Visualizar Métrica",
        DASHBOARD_PERFORMANCE_CARD_ADD_METRIC: "Adicionar Métrica",
        DASHBOARD_PERFORMANCE_CARD_REMOVE_METRIC: "Remover Métrica",
        DASHBOARD_PERFORMANCE_CARD_SELECTED_VIEWS: "Views Escolhidas",
        DASHBOARD_PERFORMANCE_CARD_THERE_ARENT_ANY_SELECTED_VIEWS:
          "Não há Views Selecionadas",
        DASHBOARD_PERFORMANCE_CARD_SELECT_VIEWS: "Selecionar Views",
        DASHBOARD_PERFORMANCE_CARD_THERE_ARENT_ANY_VIEWS_TO_BE_SELECTED:
          "Não há Views para serem Selecionadas",
        DASHBOARD_PERFORMANCE_CARD_VISUALIZE_VIEW: "Visualizar View",
        DASHBOARD_PERFORMANCE_CARD_ADD_VIEW: "Adicionar View",
        DASHBOARD_PERFORMANCE_CARD_REMOVE_VIEW: "Remover View",

        CHOOSE_DASHBOARD_PERFORMANCE_CHOOSE_DASHBOARD_PERFORMANCE:
          "Escolha a Dashboard Performance",
        CHOOSE_DASHBOARD_PERFORMANCE_THERE_ARENT_ANY_DASHBOARDS_REGISTERED:
          "Não há Dashboards Cadastradas",
        CHOOSE_DASHBOARD_PERFORMANCE_ACCESS_DASHBOARD: "Acessar Dashboard",
        CHOOSE_DASHBOARD_PERFORMANCE_CREATE_DASHBOARD_PERFORMANCE:
          "Criar Dashboard",
        CHOOSE_DASHBOARD_PERFORMANCE_CLICK_TO_CREATE_DASHBOARD_PERFORMANCE:
          "Clique para Criar uma Dashboard",
        CHOOSE_DASHBOARD_PERFORMANCE_ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_DASHBOARD:
          "Você tem certeza que deseja remover esta Dashboard?",
        CHOOSE_DASHBOARD_PERFORMANCE_AN_ERROR_OCURRED_WHILE_TRYING_TO_CREATE_THE_DASHBOARD:
          "Ocorreu um erro ao adicionar a Dashboard.",

        CREATE_DASHBOARD_PERFORMANCE_IT_WASNT_POSSIBLE_TO_LOAD_THE_DASHBOARD:
          "Não foi possível Carregar a Dashboard",
        CREATE_DASHBOARD_PERFORMANCE_CREATE_DASHBOARD_PERFORMANCE:
          "Criar Dashboard Performance",
        CREATE_DASHBOARD_PERFORMANCE_UPDATE_DASHBOARD_PERFORMANCE:
          "Atualizar Dashboard Performance",
        CREATE_DASHBOARD_PERFORMANCE_ENTER_THE_NAME_OF_THE_DASHBOARD:
          "Insira o nome da Dashboard",
        CREATE_DASHBOARD_PERFORMANCE_ENTER_THE_SHORT_DESCRIPTION_OF_THE_DASHBOARD:
          "Insira o resumo da Dashboard",
        CREATE_DASHBOARD_PERFORMANCE_ENTER_THE_NAME_THE_STAGE_NAME:
          "Insira o nome da Etapa",
        CREATE_DASHBOARD_PERFORMANCE_GO_BACK: "Voltar",
        CREATE_DASHBOARD_PERFORMANCE_ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_CARD:
          "Você tem certeza que deseja remover este Card?",
        CREATE_DASHBOARD_PERFORMANCE_ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_STAGE:
          "Você tem certeza que deseja remover esta Etapa?",
        CREATE_DASHBOARD_PERFORMANCE_ADD_CARD: "Adicionar Card",
        CREATE_DASHBOARD_PERFORMANCE_EDIT_CARD: "Editar Card",
        CREATE_DASHBOARD_PERFORMANCE_MAIN_METRIC: "Métrica Principal",
        CREATE_DASHBOARD_PERFORMANCE_SELECT_THE_MAIN_METRIC:
          "Selecione a Métrica Principal",
        CREATE_DASHBOARD_PERFORMANCE_SELECT_THE_CARD_TYPE:
          "Selecione o Tipo do Card",
        CREATE_DASHBOARD_PERFORMANCE_EDIT_DASHBOARD_TITLE:
          "Editar o Título da Dashboard",
        CREATE_DASHBOARD_PERFORMANCE_CONFIRM_CHANGES: "Confirmar Mudanças",
        CREATE_DASHBOARD_DASHBOARD_TITLE_CANT_BE_EMPTY:
          "O Título da Dashboard não pode estar vazio",
        CREATE_DASHBOARD_DASHBOARD_ICON_COLOR_CANT_BE_EMPTY:
          "É necessário escolher a Cor do ícone da Dashboard",
        CREATE_DASHBOARD_DASHBOARD_SHORT_DESCRIPTION_CANT_BE_EMPTY:
          "O Resumo da Dashboard não pode estar vazio",
        CREATE_DASHBOARD_THERE_ARE_UNSELECTED_STAGES:
          "Há Etapas que não foram selecionadas, todas precisam ser escolhidas.",
        CREATE_DASHBOARD_DASHBOARD_MUST_HAVE_AT_LEAST_ONE_STAGE:
          "A Dashboard Precisa ter Pelo Menos uma Etapa",
        CREATE_DASHBOARD_DASHBOARD_STAGES_CANT_BE_EMPTY:
          "As Etapas da Dashboard não podem estar vazias",
        CREATE_DASHBOARD_THERE_ARE_CARDS_WITH_INCOMPLETE_INFORMATION:
          "Há Cards com campos vazios, é necessário preencher todos os campos",
        CREATE_DASHBOARD_THERE_ARE_FIELDS_INCOMPLETE_INFORMATION:
          "Há Informações Incompletas, é necessário preencher todas as Informações",
        CREATE_DASHBOARD_CARDS_MUST_HAVE_AT_LEAST_ONE_METRIC:
          "Cards precisam ter pelo menos uma Métrica",
        CREATE_DASHBOARD_METRICS_MUST_HAVE_AT_LEAST_ONE_VIEW:
          "As Métricas precisam ter pelo menos uma View",
        CREATE_DASHBOARD_METRICS_ITS_REQUIRED_TO_SELECT_THE_STAGE_BEFORE_ADDING_A_CARD:
          "É necessário escolher a etapa antes de adicionar o card",

        METRICS_MODAL_METRICS_MODAL: "Modal de Métricas",
        METRICS_MODAL_THERE_ARENT_METRICS_REGISTERED_FOR_THIS_CARD:
          "Não Há Métricas Cadastradas para este Card",
        METRICS_MODAL_THERE_ARENT_VIEWS_REGISTERED_FOR_THIS_METRIC:
          "Não Há Views Cadastradas para esta Métrica",
        METRICS_MODAL_DASHBOARD_WITHOUT_TITLE: "Dashboard sem Título",
        METRICS_MODAL_CARD_WITHOUT_TITLE: "Card sem Título",
        METRICS_MODAL_SELECTED_METRICS: "Métricas Selecionadas",
        METRICS_MODAL_SELECT_METRICS: "Selecionar Métricas",
        METRICS_MODAL_SELECT_DESIRED_METRICS: "Selecione as Métricas Desejadas",
        METRICS_MODAL_THERE_ARENT_ANY_METRICS_TO_BE_SELECTED:
          "Não há métricas pra serem selecionadas",
      },
      "en-US": {
        MODAL_CONFIRM_TITLE: "Confirm Action",
        MODAL_CONFIRM_YES: "Yes",
        MODAL_CONFIRM_NO: "No",

        DATATABLE_LANGUAGE_URL:
          "//cdn.datatables.net/plug-ins/1.10.13/i18n/English.json",
        AGENTS_LIST_CHANGE_STATUS_BUTTON: "Change Status",
        TABLE_DELETE_BUTTON: "Delete",
        TABLE_STATUS_ROW: "Status",
        TABLE_ACTIONS_ROW: "Actions",
        MODAL_STATUS_STATUS: "Status",
        TABLE_ACTIONS_ACCESS_BUTTON: "Access",
        TABLE_ACTIONS_VISUALIZE_BUTTON: "Visualize",
        FORM_SELECT_SELECT: "Select",

        BUTTON_WAIT: "Wait",
        BUTTON_REGISTER: "Register",
        ICON_WAIT: "Wait",
        ICON_LOADING: "Loading",
        ICON_REGISTER: "Register",
        ICON_ADD: "Add",

        ADMIN_BUSINESS_EDIT_GOALS: "Goals",
        ADMIN_BUSINESS_EDIT_MEDIA: "Media Plan",
        ADMIN_BUSINESS_EDIT_VISIT: "Visit",
        ADMIN_BUSINESS_EDIT_BID: "Bid",
        ADMIN_BUSINESS_EDIT_DEAL: "Deal",
        ADMIN_BUSINESS_EDIT_SALES: "Sales",
        ADMIN_BUSINESS_EDIT_POS_BUSINESS: "Future Purchase",
        ADMIN_BUSINESS_EDIT_COMPETITORS: "Competitors",
        ADMIN_BUSINESS_EDIT_NETWORKS: "Social Networks",
        ADMIN_BUSINESS_EDIT_LEADS: "Leads",
        ADMIN_BUSINESS_EDIT_CONFIG: "Configurations",

        BUSINESS_GOALS_WAIT_STATUS: "Wait, We're Registering the Goal.",
        BUSINESS_GOALS_SUCCESS_STATUS: "Goal Registered with Success!",
        BUSINESS_GOALS_SALES_FUNNEL: "Sales Funnel",
        BUSINESS_GOALS_VGV: "Goal VGV (General Sales Value)",
        BUSINESS_GOALS_GOAL_SALES_CONTROL_PLACEHOLDER: "Goal",
        BUSINESS_GOALS_REGISTER_GOALS: "Save Sales Funnel Goals",
        BUSINESS_GOALS_PREVIOUS_MONTHS_VGV: "VGV Goals from Previous Months",
        BUSINESS_GOALS_TABLE_MONTH: "Month",
        BUSINESS_GOALS_TABLE_VALUE: "Value",

        BUSINESS_MEDIA_PLAN_WAIT_STATUS:
          "Wait, We're registering the Media Plan.",
        BUSINESS_MEDIA_PLAN_SUCCESS_STATUS:
          "Media Plan Registered With Success!",
        BUSINESS_MEDIA_PLAN_ERROR_STATUS:
          "Something went wrong when trying to register the Media Plan, please contact the support.",
        BUSINESS_MEDIA_PLAN_PAYMENT_METHOD_CARD: "Card",
        BUSINESS_MEDIA_PLAN_PAYMENT_METHOD_BILLET: "Billet",
        BUSINESS_MEDIA_PLAN_PAYMENT_METHOD_CREDIT_LINE: "Credit Line",
        BUSINESS_MEDIA_PLAN_PAYMENT_METHOD_OTHERS: "Others",
        BUSINESS_MEDIA_PLAN_MEDIA_PLAN: "Media Plan",
        BUSINESS_MEDIA_PLAN_CURRENT_MONTH: "Current Month",
        BUSINESS_MEDIA_PLAN_NEW_MEDIA_PLAN_PLACEHOLDER:
          "Inform the Name of the New Media!",
        BUSINESS_MEDIA_PLAN_TABLE_MEDIA_NAME: "Media Name",
        BUSINESS_MEDIA_PLAN_TABLE_PLANNED_INVESTMENT: "Planned Investment",
        BUSINESS_MEDIA_PLAN_TABLE_MADE_INVESTMENT: "Made Investment",
        BUSINESS_MEDIA_PLAN_TABLE_PAYMENT_METHOD: "Payment Method",
        BUSINESS_MEDIA_PLAN_TABLE_PLAY_PAUSE: "Play / Pause",
        BUSINESS_MEDIA_PLAN_RENEW: "Renew",
        BUSINESS_MEDIA_PLAN_REGISTER_MEDIA_PLAN: "Save Media Plan",
        BUSINESS_MEDIA_PLAN_RENEW_MONTH_OPTION_DEFAULT: "Quantity of Months",
        BUSINESS_MEDIA_PLAN_RENEW_MONTH_OPTION_1: "1 Month",
        BUSINESS_MEDIA_PLAN_RENEW_MONTH_OPTION_2: "2 Months",
        BUSINESS_MEDIA_PLAN_RENEW_MONTH_OPTION_3: "3 Months",

        BUSINESS_VISIT_WAIT_STATUS: "Wait, We're Registering the Visit.",
        BUSINESS_VISIT_SUCCESS_STATUS: "Visit Registered With Success!",
        BUSINESS_VISIT_REGISTER_VISIT: "Register Visit",
        BUSINESS_VISIT_REGISTER_NAME: "Name",
        BUSINESS_VISIT_REGISTER_EMAIL: "E-mail",
        BUSINESS_VISIT_REGISTER_PHONE: "Phone",
        BUSINESS_VISIT_REGISTER_LABEL_DATE: "Date",
        BUSINESS_VISIT_REGISTER_PLACEHOLDER_DATE: "Visit Date",
        BUSINESS_VISIT_REGISTER_LABEL_STATUS: "Status",
        BUSINESS_VISIT_REGISTER_OPTION_DEFAULT: "Select the Status",
        BUSINESS_VISIT_REGISTER_OPTION_TO_SCHEDULE: "To Schedule",
        BUSINESS_VISIT_REGISTER_OPTION_ACCOMPLISHED: "Accomplished",
        BUSINESS_VISIT_REGISTER_OPTION_NOT_ACCOMPLISHED: "Not Accomplished",
        BUSINESS_VISIT_REGISTER_OPTION_CANCELED: "Canceled",
        BUSINESS_VISIT_REGISTER_NEW_VISIT: "Register New Visit",
        BUSINESS_VISIT_LIST_LIST_OF_VISITS: "List of Visits",
        BUSINESS_VISIT_LIST_NAME: "Name",
        BUSINESS_VISIT_LIST_VISIT_DATE: "Visit Date",
        BUSINESS_VISIT_LIST_REGISTER_DATE: "Register Date",

        BUSINESS_BID_WAIT_STATUS: "Wait, We're Registering the Bid.",
        BUSINESS_BID_SUCCESS_STATUS: "Bid Registered With Success!",
        BUSINESS_BID_REGISTER_REGISTER_BID: "Register Bid",
        BUSINESS_BID_REGISTER_NAME: "Name",
        BUSINESS_BID_REGISTER_EMAIL: "E-mail",
        BUSINESS_BID_REGISTER_PHONE: "Phone",
        BUSINESS_BID_REGISTER_UNITY: "Unity",
        BUSINESS_BID_REGISTER_VALUE: "Bid Value",
        BUSINESS_BID_LIST_BIDS_LIST: "List of Bids",
        BUSINESS_BID_LIST_NAME: "Name",
        BUSINESS_BID_LIST_EMAIL: "E-mail",
        BUSINESS_BID_LIST_PHONE: "Phone",
        BUSINESS_BID_LIST_UNITY: "Unity",
        BUSINESS_BID_LIST_BIDS_VALUE: "Bid Value",
        BUSINESS_BID_LIST_BIDS_REGISTER_DATE: "Register Date",

        BUSINESS_DEAL_WAIT_STATUS: "Wait, We're Registering the Deal.",
        BUSINESS_DEAL_SUCCESS_STATUS: "Deal Registered With Success!",
        BUSINESS_DEAL_REGISTER_REGISTER_DEAL: "Register Deal",
        BUSINESS_DEAL_REGISTER_NAME: "Name",
        BUSINESS_DEAL_REGISTER_EMAIL: "E-mail",
        BUSINESS_DEAL_REGISTER_PHONE: "Phone",
        BUSINESS_DEAL_REGISTER_UNITY: "Unity",
        BUSINESS_DEAL_REGISTER_DEAL_VALUE: "Deal Value",
        BUSINESS_DEAL_LIST_DEALS_LIST: "List of Deals",
        BUSINESS_DEAL_LIST_NAME: "Name",
        BUSINESS_DEAL_LIST_EMAIL: "E-mail",
        BUSINESS_DEAL_LIST_PHONE: "Phone",
        BUSINESS_DEAL_LIST_UNITY: "Unity",
        BUSINESS_DEAL_LIST_DEAL_VALUE: "Deal Value",
        BUSINESS_DEAL_LIST_REGISTER_DATE: "Register Date",

        BUSINESS_SALES_CONTROL_VISUALIZE_BUTTON_TITLE: "Visualize Sale",
        BUSINESS_SALES_CONTROL_VISUALIZE_BUTTON: "Visualize",
        BUSINESS_SALES_CONTROL_WAIT_STATUS: "Wait, We're Registering the Sale.",
        BUSINESS_SALES_CONTROL_SUCCESS_STATUS: "Sale Registered With Success!",
        BUSINESS_SALES_CONTROL_ADD_BUYERS_STATUS:
          "You need to add at least one buyer before registering the Sale.",
        BUSINESS_SALES_CONTROL_REGISTER_REGISTER_SALE: "Register Sale",
        BUSINESS_SALES_CONTROL_REGISTER_BUYER_NAME: "Buyer Name",
        BUSINESS_SALES_CONTROL_REGISTER_BUYER_EMAIL: "Buyer E-mail",
        BUSINESS_SALES_CONTROL_REGISTER_BUYER_PHONE: "Buyer Phone",
        BUSINESS_SALES_CONTROL_REGISTER_BUYER_CPF: "CPF",
        BUSINESS_SALES_CONTROL_REGISTER_BUYER_ADD_BUYER: "Add Buyer",
        BUSINESS_SALES_CONTROL_LIST_BUYER_NAME: "Name",
        BUSINESS_SALES_CONTROL_LIST_BUYER_EMAIL: "E-mail",
        BUSINESS_SALES_CONTROL_LIST_BUYER_PHONE: "Phone",
        BUSINESS_SALES_CONTROL_LIST_BUYER_CPF: "CPF",
        BUSINESS_SALES_CONTROL_REGISTER_UNITY: "Unity",
        BUSINESS_SALES_CONTROL_REGISTER_VALUE: "Value",
        BUSINESS_SALES_CONTROL_REGISTER_SALES_UPLOAD: "Sales File Upload",
        BUSINESS_SALES_CONTROL_REGISTER_REGISTER_NEW_SALE: "Register New Sale",
        BUSINESS_SALES_CONTROL_LIST_LIST_OF_SALES: "List of Sales",
        BUSINESS_SALES_CONTROL_LIST_BUYERS_QUANTITY: "Quantity of Buyers",
        BUSINESS_SALES_CONTROL_LIST_MAIN_BUYER: "Main Buyer",
        BUSINESS_SALES_CONTROL_LIST_UNITY: "Unity",
        BUSINESS_SALES_CONTROL_LIST_VALUE: "Value",
        BUSINESS_SALES_CONTROL_MODAL_SALES_UPLOAD: "Sale File Upload",
        BUSINESS_SALES_CONTROL_MODAL_SUBMIT_BUTTON: "Submit",
        BUSINESS_SALES_CONTROL_MODAL_COLUMN_BUTTON: "Column",
        BUSINESS_SALES_CONTROL_MODAL_ROW_BUTTON: "Row",
        BUSINESS_SALES_CONTROL_MODAL_REGISTER_BUTTON: "Register",
        BUSINESS_SALES_CONTROL_MODAL_SALES_INFO: "Sales Information",
        BUSINESS_SALES_CONTROL_MODAL_SALES_UNITY: "Unity",
        BUSINESS_SALES_CONTROL_MODAL_SALES_VALUE: "Value",
        BUSINESS_SALES_CONTROL_MODAL_SALES_BUYER: "Buyer",
        BUSINESS_SALES_CONTROL_MODAL_SALES_BUYER_NAME: "Name",
        BUSINESS_SALES_CONTROL_MODAL_SALES_BUYER_EMAIL: "E-mail",
        BUSINESS_SALES_CONTROL_MODAL_SALES_BUYER_PHONE: "Phone",
        BUSINESS_SALES_CONTROL_MODAL_SALES_BUYER_CPF: "CPF",

        BUSINESS_POS_WAIT_STATUS:
          "Wait, We're Registering the Future Purchase.",
        BUSINESS_POS_SUCCESS_STATUS:
          "Future Purchase  Registered With Success!",
        BUSINESS_POS_REGISTER_REGISTER_FUTURE_PURCHASE:
          "Register Future Purchase",
        BUSINESS_POS_REGISTER_NAME: "Name",
        BUSINESS_POS_REGISTER_EMAIL: "E-mail",
        BUSINESS_POS_REGISTER_PHONE: "Phone",
        BUSINESS_POS_REGISTER_UNITY: "Unity",
        BUSINESS_POS_REGISTER_VALUE: "Value",
        BUSINESS_POS_LIST_LIST_OF_FUTURE_PURCHASES: "List of Future Purchases",
        BUSINESS_POS_LIST_NAME: "Name",
        BUSINESS_POS_LIST_EMAIL: "E-mail",
        BUSINESS_POS_LIST_PHONE: "Phone",
        BUSINESS_POS_LIST_UNITY: "Unity",
        BUSINESS_POS_LIST_VALUE: "Value",
        BUSINESS_POS_LIST_REGISTER_DATE: "Register Date",

        GOOGLE_DIRECTIONS_PHYSICAL_INFO: "Physical Information",
        GOOGLE_DIRECTIONS_PHYSICAL_ADDRESS_NOT_FOUND_STATE:
          "It wasn't possible to find the addresses",
        GOOGLE_DIRECTIONS_ADDRESS_OF: "Address of the",
        GOOGLE_DIRECTIONS_DISTANCE: "Distance",
        GOOGLE_DIRECTIONS_DRIVING_TIME: "Driving Time",

        BUSINESS_SOCIAL_NETWORKS_WAIT_STATUS:
          "Wait, We're Registered the Social Network Configuration.",
        BUSINESS_SOCIAL_NETWORKS_SUCCESS_STATUS:
          "Social Network Configuration Registered with Success!",
        BUSINESS_SOCIAL_FACEBOOK_PLACEHOLDER:
          "Insert the profile url on Facebook!",
        BUSINESS_SOCIAL_INSTAGRAM_PLACEHOLDER:
          "Insert the profile url on Instagram!",
        BUSINESS_SOCIAL_TWITTER_PLACEHOLDER:
          "Insert the profile url on Twitter!",
        BUSINESS_SOCIAL_LINKEDIN_PLACEHOLDER:
          "Insert the profile url on Linkedin!",
        BUSINESS_SOCIAL_PINTEREST_PLACEHOLDER:
          "Insert the profile url on Pinterest!",
        BUSINESS_SOCIAL_YOUTUBE_PLACEHOLDER:
          "Insert the profile url on Youtube!",
        BUSINESS_SOCIAL_REGISTER_SOCIAL_NETWORKS: "Register Social Networks",

        BUSINESS_LEADS_WAIT_STATUS: "Wait, We're Registered the Lead.",
        BUSINESS_LEADS_SUCCESS_STATUS: "Lead Registered With Success!",
        BUSINESS_LEADS_ERROR_STATUS:
          "An Error occurred when trying to load the Lead.",
        BUSINESS_LEADS_REGISTER_REGISTER_LEAD: "Register Lead",
        BUSINESS_LEADS_REGISTER_NAME: "Lead Name",
        BUSINESS_LEADS_REGISTER_EMAIL: "Lead E-mail",
        BUSINESS_LEADS_REGISTER_PHONE: "Lead Phone",
        BUSINESS_LEADS_REGISTER_ORIGIN_OF_LEAD: "Lead Origin",
        BUSINESS_LEADS_REGISTER_SELECT_ORIGIN_OF_LEAD: "Select the Lead Origin",
        BUSINESS_LEADS_REGISTER_COMMENT: "Lead Comment",
        BUSINESS_LEADS_REGISTER_NEW_LEAD: "Register New Lead",

        BUSINESS_CONFIG_CONFIG_WAIT_STATUS:
          "Wait, We're Registering the Qualification Config.",
        BUSINESS_CONFIG_CONFIG_SUCCESS_STATUS:
          "Qualification Config Registered With Success!",
        BUSINESS_CONFIG_LEAD_SCORE_WAIT_STATUS:
          "Wait, We're Registering the Lead Score.",
        BUSINESS_CONFIG_LEAD_SCORE_SUCCESS_STATUS:
          "Lead Score Configuration Registered With Success!",
        BUSINESS_CONFIG_SUSPECT_SCORE_WAIT_STATUS:
          "Wait, We're Registering the Suspect Score.",
        BUSINESS_CONFIG_SUSPECT_SCORE_SUCCESS_STATUS:
          "Suspect Score Configuration Registered With Success!",
        BUSINESS_CONFIG_QUALIFICATION_QUALIFICATION: "Qualification",
        BUSINESS_CONFIG_QUALIFICATION_QUALIFICATION_SYSTEM:
          "Qualification System",
        BUSINESS_CONFIG_DEACTIVATED: "Deactivated",
        BUSINESS_CONFIG_ACTIVATED: "Activated",
        BUSINESS_CONFIG_QUALIFICATION_QUALIFICATION_MANAGEMENT:
          "Qualification Management",
        BUSINESS_CONFIG_QUALIFICATION_REGISTER_QUALIFICATION:
          "Register Qualification",
        BUSINESS_CONFIG_LEAD_SCORE_LEAD_SCORE: "Lead Score",
        BUSINESS_CONFIG_LEAD_SCORE_SEGMENTATION: "Segmentation",
        BUSINESS_CONFIG_LEAD_SCORE_SEGMENTATION_CHOOSE_SEGMENTATION:
          "Choose Segmentation",
        BUSINESS_CONFIG_LEAD_SCORE_SEGMENTATION_CHOOSE_PUBLIC: "Choose Public",
        BUSINESS_CONFIG_NO_SEGMENTATION_REGISTERED:
          "No Segmentation Registered",
        BUSINESS_CONFIG_NO_PUBLIC_REGISTERED: "No Public Registered",
        BUSINESS_CONFIG_LEAD_SCORE_SEGMENTATION_CHOOSE_ACTION: "Choose Action",
        BUSINESS_CONFIG_INCREASE: "Increase",
        BUSINESS_CONFIG_DECREASE: "Decrease",
        BUSINESS_CONFIG_LEAD_SCORE_SEGMENTATION_PONTUATION_NUMBER:
          "Pontuation Number",
        BUSINESS_CONFIG_LEAD_SCORE_REGISTER_SEGMENTATION:
          "Add Segmentation Rule",
        BUSINESS_CONFIG_LEAD_SCORE_MEDIA: "Media",
        BUSINESS_CONFIG_LEAD_SCORE_MEDIA_CHOOSE_MEDIA: "Choose Media",
        BUSINESS_CONFIG_LEAD_SCORE_MEDIA_CHOOSE_ACTION: "Choose Action",
        BUSINESS_CONFIG_LEAD_SCORE_MEDIA_PONTUATION_NUMBER: "Pontuation Number",
        BUSINESS_CONFIG_LEAD_SCORE_MEDIA_ADD_MEDIA_RULE: "Add Media Rule",
        BUSINESS_CONFIG_LEAD_SCORE_MEDIA_REGISTER_LEAD_SCORE:
          "Register Lead Score",
        BUSINESS_CONFIG_SUSPECT_SCORE_SUSPECT_SCORE: "Suspect Score",
        BUSINESS_CONFIG_SUSPECT_SCORE_SEGMENTATION: "Segmentation",
        BUSINESS_CONFIG_SUSPECT_SCORE_CHOOSE_SEGMENTATION:
          "Choose Segmentation",
        BUSINESS_CONFIG_SUSPECT_SCORE_SEGMENTATION_CHOOSE_PUBLIC:
          "Choose Public",
        BUSINESS_CONFIG_SUSPECT_SCORE_SEGMENTATION_CHOOSE_ACTION:
          "Choose Action",
        BUSINESS_CONFIG_SUSPECT_SCORE_SEGMENTATION_PONTUATION_NUMBER:
          "Pontuation Number",
        BUSINESS_CONFIG_SUSPECT_SCORE_SEGMENTATION_ADD_SEGMENTATION_RULE:
          "Add Segmentation Rule",
        BUSINESS_CONFIG_SUSPECT_SCORE_MEDIA_MEDIA: "Media",
        BUSINESS_CONFIG_SUSPECT_SCORE_MEDIA_CHOOSE_MEDIA: "Choose Media",
        BUSINESS_CONFIG_SUSPECT_SCORE_MEDIA_CHOOSE_ACTION: "Choose Action",
        BUSINESS_CONFIG_SUSPECT_SCORE_MEDIA_PONTUATION_NUMBER:
          "Pontuation Number",
        BUSINESS_CONFIG_SUSPECT_SCORE_MEDIA_ADD_MEDIA_RULE: "Add Media Rule",
        BUSINESS_CONFIG_INTERACTION_TIME_INTERACTION_TIME: "Interaction Time",
        BUSINESS_CONFIG_INTERACTION_TIME_INTERACTION_SECONDS: "Sec.",
        BUSINESS_CONFIG_INTERACTION_TIME_SUSPECT_SCORE_CHOOSE_ACTION:
          "Choose Action",
        BUSINESS_CONFIG_INTERACTION_TIME_SUSPECT_SCORE_PONTUATION_NUMBER:
          "Pontuation Number",
        BUSINESS_CONFIG_INTERACTION_TIME_ADD_INTERACTION_TIME_RULE:
          "Add Interaction Time Rule",
        BUSINESS_CONFIG_INTERACTION_TIME_REGISTER_SUSPECT_SCORE:
          "Register Suspect Score",

        QUALIFICATION_QUALIFICATION_TEAM: "Qualification Team",
        QUALIFICATION_MANAGERS: "Managers",
        QUALIFICATION_QUALIFIERS: "Qualifiers",
        MANAGERS_WAIT_STATUS: "Wait, We're Registering the Manager.",
        MANAGERS_SUCCESS_STATUS: "Manager Registered With Success!",
        MANAGERS_REGISTER_REGISTER_MANAGER: "Register Manager",
        MANAGERS_REGISTER_LABEL_NAME: "Name",
        MANAGERS_REGISTER_PLACEHOLDER_NAME: "Manager Name",
        MANAGERS_REGISTER_LABEL_EMAIL: "E-mail",
        MANAGERS_REGISTER_PLACEHOLDER_EMAIL: "Manager E-mail",
        MANAGERS_REGISTER_LABEL_PHONE: "Phone",
        MANAGERS_REGISTER_PLACEHOLDER_PHONE: "Manager Phone",
        MANAGERS_REGISTER_REGISTER_NEW_MANAGER: "Register New Manager",
        MANAGERS_LIST_LIST_MANAGER: "List of Managers",
        MANAGERS_LIST_NAME: "Name",
        MANAGERS_LIST_EMAIL: "E-mail",
        MANAGERS_LIST_PHONE: "Phone",

        QUALIFIERS_WAIT_STATUS: "Wait, We're Registering the Qualifier.",
        QUALIFIERS_SUCCESS_STATUS: "Qualifier Registered With Success.",
        QUALIFIERS_REGISTER_REGISTER_QUALIFIER: "Register Qualifier",
        QUALIFIERS_REGISTER_LABEL_NAME: "Name",
        QUALIFIERS_REGISTER_PLACEHOLDER_NAME: "Qualifier Name",
        QUALIFIERS_REGISTER_LABEL_EMAIL: "E-mail",
        QUALIFIERS_REGISTER_PLACEHOLDER_EMAIL: "Qualifier E-mail",
        QUALIFIERS_REGISTER_LABEL_PHONE: "Phone",
        QUALIFIERS_REGISTER_PLACEHOLDER_PHONE: "Qualifier Phone",
        QUALIFIERS_REGISTER_REGISTER_NEW_QUALIFIER: "Register New Qualifier",
        QUALIFIERS_LIST_LIST_QUALIFIERS: "List of Qualifiers",
        QUALIFIERS_LIST_NAME: "Name",
        QUALIFIERS_LIST_EMAIL: "E-mail",
        QUALIFIERS_LIST_PHONE: "Phone",

        TOOLS_TOOLS: "Tools",
        TOOLS_ADMINISTRATE_SEGMENTATIONS: "Administrate Segmentations",
        TOOLS_LEAD_4_0: "Lead 4.0",
        TOOLS_ADMINISTRATE_LEAD_4_0: "Administrate Lead 4.0",
        TOOLS_LINK_BUILDER: "Link Builder",

        LEAD_PLUS_LEAD_4_0: "Lead 4.0",
        LEAD_PLUS_WAIT_ICON: "Wait",
        LEAD_PLUS_REGISTER_ICON: "Register",
        LEAD_PLUS_UPDATE_ICON: "Update",
        LEAD_PLUS_ADD_NEW_PARAGRAPH: "Add a New Paragraph",
        LEAD_PLUS_REFERENCE_TAGS: "Reference Tag's",
        LEAD_PLUS_TAG_LEAD_NAME: "Add the name of the Lead.",
        LEAD_PLUS_TAG_LEAD_AGE: "Add the age of the Lead.",
        LEAD_PLUS_TAG_LEAD_AGE_RANGE:
          "Adds the lead age range containing the prefix and information.",
        LEAD_PLUS_TAG_INCOME_RANGE:
          "Adds the lead income range containing the prefix and information.",
        LEAD_PLUS_TAG_OCCUPATION:
          "Adds the current lead position containing the prefix and information.",
        LEAD_PLUS_TAG_ACTUAL_JOB:
          "Adds the current job of the lead containing the prefix and information.",
        LEAD_PLUS_TAG_BUSINESSMAN:
          "Adds saying if the lead is an entrepreneur containing the prefix and information.",
        LEAD_PLUS_TAG_AMOUNT_BUSINESS:
          "Adds the quantity and companies in which the lead has participation containing prefix, information and suffix.",
        LEAD_PLUS_TAG_BUSINESS_NAME: "Add the name of the Business.",
        LEAD_PLUS_TAG_BUSINESS_DISTANCE:
          "Adds the distance between the lead and the Business containing prefix, information and suffix.",
        LEAD_PLUS_TAG_ENTRY_DATE:
          "Adds the Entry/Completion/Event Date in the Y-m-d format.",
        LEAD_PLUS_TAG_ENTRY_TIME:
          "Adds the hours and minutes of the Entry/Completion/Event date in hour:minute format.",
        LEAD_PLUS_TAG_FORM_NAME: "Adds the name of the filling form.",
        LEAD_PLUS_TAG_SOURCE_MEDIA:
          "Adds the origin prefix followed by the lead's origin.",
        LEAD_PLUS_TAG_PITCH_SEGMENTATION:
          "Adds the segmentation pitch set when creating the segmentation.",
        LEAD_PLUS_TAG_PITCH_CONTENTS:
          "Adds the content pitch set when creating the Link.",
        LEAD_PLUS_TAG_PITCH_CAMPAIGN:
          "Adds the campaign pitch set when creating the Link.",
        LEAD_PLUS_TAG_PITCH_CLICK:
          "Adds the click pitch set when creating the Link.",
        LEAD_PLUS_TAG_PITCH_LANDING_PAGE:
          "Adds the landing page pitch set when creating the landing page.",
        LEAD_PLUS_TAG_PITCH_EMAIL_MARKETING:
          "Adds the email marketing pitch set at the time of creating the Email Marketing.",
        LEAD_PLUS_TAG_PITCH_BANNER:
          "Add banner pitch set at the time of creation of the Banner.",
        LEAD_PLUS_TAG_PITCH_SMS:
          "Adds the SMS pitch set when creating the SMS.",
        LEAD_PLUS_TAG_CHAMPION_INTERACTION:
          "Adds what was the biggest interaction of the lead containing, information and suffix.",
        LEAD_PLUS_SUCCESS: "Success",
        LEAD_PLUS_UPDATE_PARAGRAPH: "Updating the Paragraphs of the Lead 4.0",
        LEAD_PLUS_ERROR: "Error",
        LEAD_PLUS_UPDATE: "Update",
        LEAD_PLUS_NO_PARAGRAPH: "No Paragraph have been configured yet!",
        LEAD_PLUS_MODAL_NEW_PARAGRAPH: "Add a New Paragraph",
        LEAD_PLUS_MODAL_LABEL_PARAGRAPH_NAME: "Paragraph Internal Control Name",
        LEAD_PLUS_MODAL_PLACEHOLDER_PARAGRAPH_NAME: "Paragraph Name",
        LEAD_PLUS_MODAL_LABEL_LINK_TYPE: "Generated Link Type",
        LEAD_PLUS_MODAL_OPTION_SELECT_TYPE: "Select the Type",
        LEAD_PLUS_MODAL_OPTION_NEW_LEAD: "New Lead",
        LEAD_PLUS_MODAL_OPTION_APPROACH_LEAD: "How to Approach the Lead",
        LEAD_PLUS_MODAL_OPTION_NEW_VIEW: "Nova Landing Visit",
        LEAD_PLUS_MODAL_OPTION_CLICKED_EMAIL_LINK:
          "Clicked on Email Marketing Link",
        LEAD_PLUS_MODAL_REGISTER: "Register",
        LEAD_PLUS_TYPE: "type",

        LINK_BUILDER_REQUIRED_INFORM_SEGMENTATION:
          "It's required to inform a segmentation!",
        LINK_BUILDER_WAIT: "Wait",
        LINK_BUILDER_REGISTER: "Register",
        LINK_BUILDER_LEAD_INFO: "Lead Info",
        LINK_BUILDER_VISUALIZE_LINK_BUTTON: "Visualize Link",
        LINK_BUILDER_COPY_LINK_BUTTON: "Copy Link",
        LINK_BUILDER_CREATE_NEW_LINK: "Create a New Link",
        LINK_BUILDER_TABLE_NUMBER: "N°",
        LINK_BUILDER_TABLE_NAME: "Name",
        LINK_BUILDER_TABLE_MEDIA: "Media",
        LINK_BUILDER_TABLE_SEGMENTATION: "Segmentation",
        LINK_BUILDER_TABLE_PUBLIC: "Public",
        LINK_BUILDER_TABLE_ORIGINAL_URL: "Original URL",
        LINK_BUILDER_TABLE_SHAREABLE_URL: "Shareable URL",
        LINK_BUILDER_TABLE_DESTINATION_URL: "Destination URL",
        LINK_BUILDER_MODAL_LABEL_LINK_NAME: "Internal Control Link Name",
        LINK_BUILDER_MODAL_PLACEHOLDER_LINK_NAME: "Link Name",
        LINK_BUILDER_MODAL_LABEL_MEDIA: "Media",
        LINK_BUILDER_MODAL_OPTION_SELECTED_MEDIAS: "Selected Medias",
        LINK_BUILDER_MODAL_OPTION_SELECT_MEDIAS: "Select Medias",
        LINK_BUILDER_MODAL_ALL_MEDIAS: "All Medias",
        LINK_BUILDER_MODAL_THERE_ARENT_MORE_MEDIAS: "There aren't more Medias",
        LINK_BUILDER_MODAL_SELECT_MEDIA:
          "It's required to select at least one media.",
        LINK_BUILDER_MODAL_SELECT_PUBLIC:
          "It's required to select at least one public",
        LINK_BUILDER_MODAL_LABEL_SEGMENTATION: "Segmentation",
        LINK_BUILDER_MODAL_LABEL_ALL_SEGMENTATIONS: "All Segmentations",
        LINK_BUILDER_MODAL_LABEL_SELECTED_SEGMENTATIONS:
          "Selected Segmentations",
        LINK_BUILDER_MODAL_THERE_ARENT_MORE_SEGMENTATIONS:
          "There aren't more Segmentations",
        LINK_BUILDER_MODAL_OPTION_SELECT_SEGMENTATION:
          "Select the Segmentation",
        LINK_BUILDER_MODAL_NO_SEGMENTATION_CLICK_TO_ADD:
          "No Segmentation has been registered - Click here to add it",
        LINK_BUILDER_MODAL_LABEL_PUBLIC: "Public",
        LINK_BUILDER_MODAL_LABEL_ALL_PUBLICS: "All Publics",
        LINK_BUILDER_MODAL_LABEL_SELECTED_PUBLICS: "Selected Publics",
        LINK_BUILDER_MODAL_OPTION_SELECT_PUBLICS: "Select the Publics",
        LINK_BUILDER_MODAL_NO_PUBLIC:
          "No Public has been registered for these Segmentations",
        LINK_BUILDER_MODAL_LABEL_LEAD_JOURNEY_MOMENT: "Lead Journey Moment",
        LINK_BUILDER_MODAL_OPTION_SELECT_LEAD_JOURNEY_MOMENT:
          "Select the Lead Journey Moment",
        LINK_BUILDER_MODAL_OPTION_LEAD_JOURNEY_MOMENT_START: "Start",
        LINK_BUILDER_MODAL_OPTION_LEAD_JOURNEY_MOMENT_MIDDLE: "Middle",
        LINK_BUILDER_MODAL_OPTION_LEAD_JOURNEY_MOMENT_END: "End",
        LINK_BUILDER_MODAL_CAMPAIGN_PITCH: "Campaign Pitch",
        LINK_BUILDER_MODAL_CONTENT_PITCH: "Content Pitch",
        LINK_BUILDER_MODAL_CLICK_PITCH: "Click Pitch",
        LINK_BUILDER_MODAL_LABEL_GENERATED_LINK_TYPE: "Generated Link Type",
        LINK_BUILDER_MODAL_OPTION_BUYLITICS_URL_SHORTENER:
          "Buylitics URL Shortener",
        LINK_BUILDER_MODAL_OPTION_ADD_TRACE_PARAMETERS: "Add Trace Parameters",
        LINK_BUILDER_MODAL_DESTINATION_LINK: "Destination Link",
        LINK_BUILDER_MODAL_INFO_LINK_BUILDER_INFO: "Link Builder Info",
        LINK_BUILDER_MODAL_INFO_NAME: "Name",
        LINK_BUILDER_MODAL_INFO_CONTENT_PITCH: "Content Pitch",
        LINK_BUILDER_MODAL_INFO_CAMPAIGN_PITCH: "Campaign Pitch",
        LINK_BUILDER_MODAL_INFO_SEGMENTATION_PITCH: "Segmentation Pitch",
        LINK_BUILDER_MODAL_INFO_PUBLIC_PITCH: "Public Pitch",
        LINK_BUILDER_MODAL_UNKNOWN: "Unknown",
        LINK_BUILDER_MODAL_INFO_MEDIA_PITCH: "Media Pitch",
        LINK_BUILDER_MODAL_INFO_MEDIA: "Media",
        LINK_BUILDER_MODAL_INFO_SEGMENTATION: "Segmentation",
        LINK_BUILDER_MODAL_INFO_PUBLIC: "Public",
        LINK_BUILDER_MODAL_INFO_CLICK_PITCH: "Click Pitch",
        LINK_BUILDER_MODAL_INFO_ORIGINAL_LINK: "Original URL",
        LINK_BUILDER_MODAL_INFO_SHAREABLE_LINK: "Shareable URL",
        LINK_BUILDER_MODAL_INFO_DESTINATION_LINK: "Destination URL",

        SEGMENTATION_LIST_ADMINISTRATE_SEGMENTATIONS:
          "Administrate Segmentations",
        SEGMENTATION_LIST_WAIT: "Wait",
        SEGMENTATION_LIST_REGISTER: "Register",
        SEGMENTATION_LIST_VISUALIZE_SEGMENTATION: "Visualize Segmentation",
        SEGMENTATION_LIST_EDIT_SEGMENTATION: "Edit Segmentation",
        SEGMENTATION_LIST_CREATE_NEW_SEGMENTATION: "Create a New Segmentation",
        SEGMENTATION_LIST_TABLE_NUMBER: "Nº",
        SEGMENTATION_LIST_TABLE_SEGMENTATION_NAME: "Segmentation Name",
        SEGMENTATION_LIST_TABLE_INDENTIFIER: "Indentifier",
        SEGMENTATION_LIST_MODAL_LABEL_SEGMENTATION_NAME:
          "Segmentation Internal Control Name",
        SEGMENTATION_LIST_MODAL_PLACEHOLDER_SEGMENTATION_NAME:
          "Segmentation Name",
        SEGMENTATION_LIST_MODAL_LABEL_SEGMENTATION_DESCRIPTION:
          "Segmentation Use Description",
        SEGMENTATION_LIST_MODAL_PLACEHOLDER_SEGMENTATION_DESCRIPTION:
          "Segmentation Description",
        SEGMENTATION_LIST_MODAL_SEGMENTATION_PITCH: "Segmentation Pitch",
        SEGMENTATION_LIST_MODAL_SEGMENTATION_PUBLIC: "Segmentation Public",
        SEGMENTATION_LIST_MODAL_NEW_SEGMENTATION_PUBLICS:
          "New Segmentation Publics",
        SEGMENTATION_LIST_MODAL_CLICK_TO_ADD_NEW_SEGMENTATION_PUBLICS:
          "Click to add New Segmentation Publics",
        SEGMENTATION_LIST_MODAL_SEGMENTATION_PUBLIC_NAME: "Public Name",
        SEGMENTATION_LIST_MODAL_SEGMENTATION_PUBLIC_PITCH: "Public Pitch",
        SEGMENTATION_LIST_MODAL_SEGMENTATION_ADD_PUBLIC: "Add Public",
        SEGMENTATION_LIST_MODAL_SEGMENTATION_REMOVE_PUBLIC: "Remove Public",
        SEGMENTATION_LIST_MODAL_SEGMENTATION_INDENTIFIER:
          "Segmentation Indentifier",
        SEGMENTATION_LIST_MODAL_DETAILS_SEGMENTATION_DETAILS:
          "Segmentation Details.",
        SEGMENTATION_LIST_MODAL_DETAILS_SEGMENTATION_INFO:
          "Segmentation Informations.",
        SEGMENTATION_LIST_MODAL_DETAILS_SEGMENTATION_NAME: "Name",
        SEGMENTATION_LIST_MODAL_DETAILS_SEGMENTATION_CODE: "Code",
        SEGMENTATION_LIST_MODAL_DETAILS_SEGMENTATION_DESCRIPTION: "Description",
        SEGMENTATION_LIST_MODAL_DETAILS_SEGMENTATION_PITCH:
          "Segmentation Pitch",
        SEGMENTATION_LIST_MODAL_DETAILS_SEGMENTATION_PUBLICS:
          "Publics of Segmentation",
        SEGMENTATION_LIST_MODAL_DETAILS_SEGMENTATION_PUBLIC_NAME:
          "Public Name ",
        SEGMENTATION_LIST_MODAL_DETAILS_SEGMENTATION_PUBLIC_PITCH:
          "Public Pitch ",
        SEGMENTATION_LIST_MODAL_DETAILS_SEGMENTATION_PUBLIC_CODE:
          "Public Code ",

        CONTENT_CONTENT: "Content",
        CONTENT_LANDING_PAGE: "Landing Page",
        CONTENT_VIEW_MORE: "View more",
        CONTENT_CREATED: "Created",
        CONTENT_ACTIVE: "Active",
        CONTENT_EMAIL_MARKETING: "Email Marketing",
        CONTENT_SMS: "SMS",

        LANDING_PAGE_LANDING_PAGES: "Landing Pages",
        LANDING_PAGE_WAIT: "Wait",
        LANDING_PAGE_REGISTER: "Register",
        LANDING_PAGE_VIEW_LANDING_PAGE: "View Landing Page",
        LANDING_PAGE_CREATE_NEW_LANDING_PAGE: "Create new Landing Page",
        LANDING_PAGE_TABLE_NAME: "Name",
        LANDING_PAGE_TABLE_URL: "URL",
        LANDING_PAGE_MODAL_CREATE_NEW_LANDING_PAGE: "Create new Landing Page",
        LANDING_PAGE_MODAL_LABEL_NAME: "Landing Page Control Name",
        LANDING_PAGE_MODAL_PLACEHOLDER_NAME: "Landing Page Name",
        LANDING_PAGE_MODAL_LABEL_PITCH: "Landing Page Pitch",
        LANDING_PAGE_MODAL_PLACEHOLDER_PITCH: "Landing Page Pitch",
        LANDING_PAGE_MODAL_LABEL_URL: "Landing Page URL",
        LANDING_PAGE_MODAL_PLACEHOLDER_URL: "Landing Page URL",
        LANDING_PAGE_MODAL_LABEL_LANDING_PAGE_TYPE: "Landing Page Type",
        LANDING_PAGE_MODAL_OPTION_SELECT_LANDING_PAGE:
          "Select the Landing Page Type",
        LANDING_PAGE_MODAL_OPTION_EXTERNAL_LINK: "External Link",
        LANDING_PAGE_MODAL_OPTION_BUYLITICS_LANDING_PAGE:
          "Buylitics - Landing Page",
        LANDING_PAGE_MODAL_REGISTER: "Register",

        EMAIL_MARKETING_LIST_EMAIL_MARKETING: "E-mail Marketing",
        EMAIL_MARKETING_LIST_WAIT: "Wait",
        EMAIL_MARKETING_LIST_REGISTER: "Register",
        EMAIL_MARKETING_LIST_SUCCESS_ADDING_TO_SUBMISSION_QUEUE:
          "Success adding the e-mail of test to the submission queue!",
        EMAIL_MARKETING_LIST_SEND: "Send",
        EMAIL_MARKETING_LIST_SUCCESS_ERROR_TO_SUBMISSION_QUEUE:
          "Error adding the e-mail of test to the submission queue!",
        EMAIL_MARKETING_LIST_EMAIL_VISUALIZE: "Visualize E-mail",
        EMAIL_MARKETING_LIST_EMAIL_EDIT: "Edit E-mail",
        EMAIL_MARKETING_LIST_EMAIL_SEND_TEST: "Send E-mail of Test",
        EMAIL_MARKETING_CREATE_NEW_EMAIL_MARKETING:
          "Create new Email Marketing",
        EMAIL_MARKETING_TABLE_NAME: "Name",
        EMAIL_MARKETING_TABLE_SUBJECT: "Subject",
        EMAIL_MARKETING_MODAL_LABEL_EMAIL_NAME: "E-mail Control Name",
        EMAIL_MARKETING_MODAL_PLACEHOLDER_EMAIL_NAME: "E-mail Name",
        EMAIL_MARKETING_MODAL_LABEL_PITCH_EMAIL_MARKETING:
          "E-mail Marketing Pitch",
        EMAIL_MARKETING_MODAL_PLACEHOLDER_PITCH_EMAIL_MARKETING:
          "E-mail Marketing Pitch",
        EMAIL_MARKETING_MODAL_LABEL_SUBJECT_EMAIL: "Subject E-mail contains",
        EMAIL_MARKETING_MODAL_PLACEHOLDER_SUBJECT_EMAIL: "E-mail Subject",
        EMAIL_MARKETING_MODAL_LABEL_CONTENT_EMAIL: "E-mail Body's Content",
        EMAIL_MARKETING_MODAL_EDIT_EMAIL_MARKETING: "Edit Email Marketing",
        EMAIL_MARKETING_MODAL_RECIPIENT_NAME: "Recipient Name",
        EMAIL_MARKETING_MODAL_RECIPIENT_EMAIL: "Recipient E-mail",

        TRIGGERED_EMAIL_TRIGGERED_EMAIL_CREATED_WITH_SUCCESS:
          "Triggered E-mail Created With Success",
        TRIGGERED_EMAIL_TRIGGERED_EMAIL_CREATED_FAILED:
          "There was a failure to register the E-mail trigger, try again later!",
        TRIGGERED_EMAIL_TRIGGERED_EMAILS: "Triggered E-mails",
        TRIGGERED_EMAIL_TRIGGERS_MADE: "Triggers Made",
        TRIGGERED_EMAIL_NEW_TRIGGER: "New Trigger",
        TRIGGERED_EMAIL_TRIGGER_NAME: "Trigger Name",
        TRIGGERED_EMAIL_TRIGGER_DATE: "Trigger Date",
        TRIGGERED_EMAIL_TOTAL: "Total",
        TRIGGERED_EMAIL_DELIVER: "Deliver",
        TRIGGERED_EMAIL_OPENING: "Opening",
        TRIGGERED_EMAIL_CLICKS: "Clicks",
        TRIGGERED_EMAIL_TRIGGER_BASE: "Trigger Base",
        TRIGGERED_EMAIL_CHOOSE_EXISTENT_TRIGGER_BASE:
          "Choose Existent Trigger Base",
        TRIGGERED_EMAIL_UPLOAD_TRIGGER_BASE_FILE: "Upload Trigger Base File",
        TRIGGERED_EMAIL_SELECT_THE_ORIGIN: "Select the Origin",
        TRIGGERED_EMAIL_BASE_FILE_UPLOADED_WITH_SUCCESS:
          "Base File Uploaded With Success",
        TRIGGERED_EMAIL_EDIT_TRIGGER_BASE_FILE: "Edit Trigger Base File",
        TRIGGERED_EMAIL_CLEAN: "Clean",
        TRIGGERED_EMAIL_REGISTER_NEW_TRIGGER: "Register New Trigger",
        TRIGGERED_EMAIL_BASE_FILE_UPLOAD: "Base File Upload",
        TRIGGERED_EMAIL_UPLOAD: "Upload",
        TRIGGERED_EMAIL_CUSTOM: "custom",
        TRIGGERED_EMAIL_ADD_TRIGGER_BASE_FILE: "Add Trigger Base File",

        TRIGGERED_SMS_TRIGGERED_SMS_CREATED_WITH_SUCCESS:
          "Triggered SMS Created With Success",
        TRIGGERED_SMS_TRIGGERED_SMSS: "Triggered SMS's",
        TRIGGERED_SMS_TRIGGERS_MADE: "Triggers Made",
        TRIGGERED_SMS_NEW_TRIGGER: "New Trigger",
        TRIGGERED_SMS_TRIGGER_NAME: "Trigger Name",
        TRIGGERED_SMS_TRIGGER_DATE: "Trigger Date",
        TRIGGERED_SMS_TOTAL: "Total",
        TRIGGERED_SMS_DELIVER: "Deliver",
        TRIGGERED_SMS_OPENING: "Opening",
        TRIGGERED_SMS_CLICKS: "Clicks",
        TRIGGERED_SMS_TRIGGER_BASE: "Trigger Base",
        TRIGGERED_SMS_CHOOSE_EXISTENT_TRIGGER_BASE:
          "Choose Existent Trigger Base",
        TRIGGERED_SMS_UPLOAD_TRIGGER_BASE_FILE: "Upload Trigger Base File",
        TRIGGERED_SMS_SELECT_THE_ORIGIN: "Select the Origin",
        TRIGGERED_SMS_BASE_FILE_UPLOADED_WITH_SUCCESS:
          "Base File Uploaded With Success",
        TRIGGERED_SMS_EDIT_TRIGGER_BASE_FILE: "Edit Trigger Base File",
        TRIGGERED_SMS_CLEAN: "Clean",
        TRIGGERED_SMS_REGISTER_NEW_TRIGGER: "Register New Trigger",
        TRIGGERED_SMS_BASE_FILE_UPLOAD: "Base File Upload",
        TRIGGERED_SMS_UPLOAD: "Upload",
        TRIGGERED_SMS_CUSTOM: "custom",
        TRIGGERED_SMS_ADD_TRIGGER_BASE_FILE: "Add Trigger Base File",

        BANNER_LIST_BANNERS: "Banners",
        BANNER_LIST_WAIT: "Wait",
        BANNER_LIST_REGISTER: "Register",
        BANNER_LIST_VIEW_BANNER: "View Banner",
        BANNER_LIST_UPLOAD_NEW_BANNER: "Upload New Banner",
        BANNER_LIST_TABLE_NAME: "Name",
        BANNER_LIST_TABLE_TEXT: "Text",
        BANNER_LIST_MODAL_LABEL_BANNER_NAME: "Banner Control Name",
        BANNER_LIST_MODAL_PLACEHOLDER_BANNER_NAME: "Banner Name",
        BANNER_LIST_MODAL_BANNER_TEXT: "Banner Text",
        BANNER_LIST_MODAL_BANNER_PITCH: "Banner Pitch",
        BANNER_LIST_MODAL_BANNER_TYPE: "Banner Type",
        BANNER_LIST_MODAL_OPTION_CHOOSE_A_TYPE: "Choose a Type",
        BANNER_LIST_MODAL_OPTION_MEDIA: "Media",
        BANNER_LIST_MODAL_OPTION_LANDING_PAGE: "Landing Page",
        BANNER_LIST_MODAL_BANNER_FILE: "Banner File",

        SMS_LIST_SMS: "SMS",
        SMS_LIST_SMS_SMS_TRIGGER: "SMS Trigger",
        SMS_LIST_WAIT: "Wait",
        SMS_LIST_REGISTER: "Register",
        SMS_LIST_SUCCESS_ADDING_TO_SUBMISSION_QUEUE:
          "Success adding the message of test to the submission queue!",
        SMS_LIST_SEND: "Send",
        SMS_LIST_ERROR_ADDING_TO_SUBMISSION_QUEUE:
          "Error adding the message of test to the submission queue.",
        SMS_LIST_EDIT_SMS: "Edit SMS",
        SMS_LIST_SEND_SMS_TEST: "Send SMS of Test",
        SMS_LIST_CREATE_NEW_SMS: "Create a new SMS",
        SMS_LIST_TABLE_NAME: "Name",
        SMS_LIST_TABLE_TEXT: "Text",
        SMS_LIST_MODAL_LABEL_SMS_NAME: "SMS Control Name",
        SMS_LIST_MODAL_PLACEHOLDER_SMS_NAME: "SMS Name",
        SMS_LIST_MODAL_SMS_PITCH: "SMS Pitch",
        SMS_LIST_MODAL_SMS_TEXT: "SMS Text",
        SMS_LIST_MODAL_RECIPIENT_NUMBER: "Recipient Number",

        ATTENDANCE_ROOM_ATTENDANCE_ROOM: "Attendance Room",
        ATTENDANCE_ROOM_SHORT_YEAR: "y",
        ATTENDANCE_ROOM_SHORT_MONTH: "m",
        ATTENDANCE_ROOM_SHORT_WEEK: "w",
        ATTENDANCE_ROOM_SHORT_DAY: "d",
        ATTENDANCE_ROOM_SHORT_TODAY: "Today",
        ATTENDANCE_ROOM_NAME_NOT_INFORMED: "Name not Informed",
        ATTENDANCE_ROOM_IN_ATTENDANCE: "In Attendance",
        ATTENDANCE_ROOM_ARCHIVED: "Archived",
        ATTENDANCE_ROOM_FINISHED: "Finished",
        ATTENDANCE_ROOM_FILE_MUST_BE_VALID:
          "It is required to choose a valid file.",
        ATTENDANCE_ROOM_IT_WASNT_POSSIBLE_TO_SEND_FILE:
          "It wasn't possible to send the file.",
        ATTENDANCE_ROOM_IT_WASNT_POSSIBLE_TO_SEND_AUDIO:
          "It wasn't possible to send the audio.",
        ATTENDANCE_ROOM_IT_WASNT_POSSIBLE_TO_SEND_VIDEO:
          "It wasn't possible to send the video.",
        ATTENDANCE_ROOM_AN_ERROR_OCURRED_SENDING_YOUR_MESSAGE_TRY_AGAIN:
          "An Error Ocurred Sending Your Message. Try Again in a few seconds.",
        ATTENDANCE_ROOM_COMMERCIAL_TEAM_UPDATED_WITH_SUCCESS:
          "Commercial Team Updated With Success!",
        ATTENDANCE_ROOM_LEAD_MANAGEMENT: "Lead Management",
        ATTENDANCE_ROOM_LEAD_ANOTHER_INFO: "Another Info",
        ATTENDANCE_ROOM_LEAD_PHONES: "Phones",
        ATTENDANCE_ROOM_LEAD_EMAILS: "E-mails",
        ATTENDANCE_ROOM_LEAD_THERE_ARENT_OTHER_PHONES_REGISTERED:
          "There aren't other Phones registered",
        ATTENDANCE_ROOM_LEAD_THERE_ARENT_OTHER_EMAILS_REGISTERED:
          "There aren't other E-mails registered",
        ATTENDANCE_ROOM_MERGE_LEAD: "Merge Lead",
        ATTENDANCE_ROOM_SEND: "SEND",
        ATTENDANCE_ROOM_RECORD: "RECORD",
        ATTENDANCE_ROOM_TAB_NEW_ATTENDANCES: "New Attendances",
        ATTENDANCE_ROOM_CANCEL_CALL: "Cancel",
        ATTENDANCE_ROOM_START_CALL: "Start Calling",
        ATTENDANCE_ROOM_CALL: "Call",
        ATTENDANCE_ROOM_ACCEPT_CALL: "Accept",
        ATTENDANCE_ROOM_END_CALL: "End Call",
        ATTENDANCE_ROOM_CALL_IN_PROGRESS: "Call in progress",
        ATTENDANCE_ROOM_DO_YOU_WANT_TO_BLOCK_THIS_CART:
          "Do you want to block this cart?",
        ATTENDANCE_ROOM_DO_YOU_WANT_TO_UNBLOCK_THIS_CART:
          "Do you want to unblock this cart?",
        ATTENDANCE_ROOM_DO_YOU_WANT_TO_PAUSE_THIS_PURCHASE:
          "Do you want to pause this purchase?",
        ATTENDANCE_ROOM_DO_YOU_WANT_TO_PLAY_THIS_PURCHASE:
          "Do you want to play this purchase?",
        ATTENDANCE_ROOM_DO_YOU_WANT_TO_REMOVE_THIS_PURCHASE:
          "Do you want to remove this purchase?",
        ATTENDANCE_ROOM_BLOCK_CART: "Block Cart",
        ATTENDANCE_ROOM_UNBLOCK_CART: "Unblock Cart",
        ATTENDANCE_ROOM_REMOVE_PURCHASE: "Remove Purchase",
        ATTENDANCE_ROOM_PAUSE_PURCHASE: "Pause Purchase",
        ATTENDANCE_ROOM_PLAY_PURCHASE: "Play Purchase",
        ATTENDANCE_ROOM_TAB_ATTENDANCES: "Attendances",
        ATTENDANCE_ROOM_TAB_SHOPPER: "Shopper",
        ATTENDANCE_ROOM_TAB_SCREENWRITER: "Screenwriter",
        ATTENDANCE_ROOM_TAB_DELIVERYMAN: "Deliveryman",
        ATTENDANCE_ROOM_TAB_FINISHED: "Finished",
        ATTENDANCE_ROOM_TAB_ARCHIVED: "Archived",
        ATTENDANCE_ROOM_THERE_ARE_NO_ENTRIES: "There are no Entries",
        ATTENDANCE_ROOM_BROWSER_DOESNT_SUPPORT_VIDEO:
          "Your Web Browser doesn't support the video player.",
        ATTENDANCE_ROOM_BROWSER_DOESNT_SUPPORT_AUDIO:
          "Your Web Browser doesn't support the audio player.",
        ATTENDANCE_ROOM_START_CHATTING_WITH: "Start chatting",
        ATTENDANCE_ROOM_WRITE_YOUR_MESSAGE: "Write your message...",
        ATTENDANCE_ROOM_LOWERCASE_SEND: "Send",
        ATTENDANCE_ROOM_SELECT_A_ATTENDANCE: "Select an Attendance",
        ATTENDANCE_ROOM_TAB_TOOLBAR: "Toolbar",
        ATTENDANCE_ROOM_TAB_SHOPPING_CART: "Shopping Cart",
        ATTENDANCE_ROOM_TAB_TEAM: "Team",
        ATTENDANCE_ROOM_TAB_LOGS: "Logs",
        ATTENDANCE_ROOM_TAB_OPTIONS: "Options",
        ATTENDANCE_ROOM_ATTENDANCE_TOOLS: "Attendance Tools",
        ATTENDANCE_ROOM_CURRENT_STATUS: "Current Status",
        ATTENDANCE_ROOM_LEAD_INFO: "Lead Info",
        ATTENDANCE_ROOM_LEAD_INFO_REGISTERED_WITH_SUCCESS:
          "The lead info was updated with success",
        ATTENDANCE_ROOM_LEAD_INFO_NOT_POSSIBLE_TO_REGISTER:
          "It wasn't possible to register the lead info",
        ATTENDANCE_ROOM_IT_WASNT_POSSIBLE_TO_EXECUTE_OPERATION_TRY_AGAIN_LATER:
          "It wasn't possible to execute the operation, try again later.",
        ATTENDANCE_ROOM_TABLE_LABEL_NAME: "Name",
        ATTENDANCE_ROOM_TABLE_PLACEHOLDER_NAME: "Enter the Lead Name",
        ATTENDANCE_ROOM_TABLE_LABEL_EMAIL: "E-mail",
        ATTENDANCE_ROOM_TABLE_PLACEHOLDER_EMAIL: "Enter the Lead E-mail",
        ATTENDANCE_ROOM_TABLE_LABEL_PHONE: "Phone",
        ATTENDANCE_ROOM_TABLE_PLACEHOLDER_PHONE: "Enter the Lead Phone",
        ATTENDANCE_ROOM_LEAD_REGISTER_INFO: "Register Info",
        ATTENDANCE_ROOM_CREATE_NEW_ATTENDANCE: "Create a new Attendance",
        ATTENDANCE_ROOM_CURRENT_ATTENDANCE: "Current Attendance",
        ATTENDANCE_ROOM_LABEL_SHOPPER: "Shopper",
        ATTENDANCE_ROOM_LABEL_OPTION_CHOOSE_RESPONSIBLE_SHOPPER:
          "Select the Responsible Shopper ",
        ATTENDANCE_ROOM_LABEL_DELIVERYMAN: "Deliveryman",
        ATTENDANCE_ROOM_LABEL_OPTION_CHOOSE_RESPONSIBLE_DELIVERYMAN:
          "Select the Responsible Deliveryman",
        ATTENDANCE_ROOM_LABEL_ATTENDANCE_RESPONSIBLE: "Attendance Responsible",
        ATTENDANCE_ROOM_OPTION_PRE_ATTENDANCE: "Pre Attendance",
        ATTENDANCE_ROOM_OPTION_SHOPPER: "Shopper",
        ATTENDANCE_ROOM_OPTION_DELIVERYMAN: "Deliveryman",
        ATTENDANCE_ROOM_LABEL_STATUS: "Status",
        ATTENDANCE_ROOM_LEAD_REGISTER: "Register",
        ATTENDANCE_ROOM_MODAL_SEND_IMAGE_OR_VIDEO: "Send Image or Vídeo",
        ATTENDANCE_ROOM_MODAL_CHOOSE_THE_FILE: "Choose the File",
        ATTENDANCE_ROOM_MODAL_SEND_THE_FILE: "Send the File",
        ATTENDANCE_ROOM_MODAL_RECORD_VIDEO: "Record Video",

        ATTENDANCE_ROOM_REASON_FOR_CONTACT_SAVED_WITH_SUCCESS:
          "Reason for Contact Saved With Success.",
        ATTENDANCE_ROOM_REASON_FOR_CONTACT_ERROR_REGISTERING:
          "There was an error registering the Reason for contact",
        ATTENDANCE_ROOM_CHAT_TAB_CLIENT: "Client",
        ATTENDANCE_ROOM_CHAT_TAB_PERSONAL_SHOPPER: "Personal Shopper",
        ATTENDANCE_ROOM_CHAT_TAB_DELIVERYMAN: "Deliveryman",
        ATTENDANCE_ROOM_CHAT_TAB_TEAM: "Team",
        ATTENDANCE_ROOM_CLIENT_INFORMATIONS: "Client Informations",
        ATTENDANCE_ROOM_NUMBER_OF_ATTENDANCES: "Number of Attendances",
        ATTENDANCE_ROOM_DATE_LAST_ATTENDANCE: "Last Attendance Date",
        ATTENDANCE_ROOM_CURRENT_COMMUNICATION_CHANNEL:
          "Current Communication Channel",
        ATTENDANCE_ROOM_ATTENDANCE_TIME: "Attendance Time",
        ATTENDANCE_ROOM_TOTAL: "Total",
        ATTENDANCE_ROOM_ON_HOLD: "On Hold",
        ATTENDANCE_ROOM_CAMPAIGN: "Campaign",
        ATTENDANCE_ROOM_TYPE: "Type",
        ATTENDANCE_ROOM_USED_CHANNELS: "Used Channels",
        ATTENDANCE_ROOM_REASON_FOR_CONTACT: "Reason for Contact",
        ATTENDANCE_ROOM_CONTACT_REASON: "Contact Reason",
        ATTENDANCE_ROOM_SELECT_THE_CONTACT_REASON:
          "Select the Reason for Contact",
        ATTENDANCE_ROOM_OPTION_QUESTIONS: "Questions",
        ATTENDANCE_ROOM_OPTION_SUGGESTIONS: "Suggestions",
        ATTENDANCE_ROOM_OPTION_COMPLAINTS: "Complaints",
        ATTENDANCE_ROOM_OPTION_OTHERS: "Others",
        ATTENDANCE_ROOM_LABEL_CONTACT_DESCRIPTION: "Contact Description",
        ATTENDANCE_ROOM_PLACEHOLDER_CONTACT_DESCRIPTION:
          "Describe the reason for the Contact",
        ATTENDANCE_ROOM_REASON: "Reason",
        ATTENDANCE_ROOM_DESCRIPTION: "Description",
        ATTENDANCE_ROOM_DATE: "Date",
        ATTENDANCE_ROOM_START_PURCHASE: "Start Purchase",
        ATTENDANCE_ROOM_VIEW_SHOPPING_CART: "View Shopping Cart",
        ATTENDANCE_ROOM_CLIENT_PURCHASES: "Client Purchases",
        ATTENDANCE_ROOM_START_NEW_PURCHASE: "Start New Purchase",
        ATTENDANCE_ROOM_LABEL_CLIENT_DOCUMENT: "Client Document (CPF)",
        ATTENDANCE_ROOM_PLACEHOLDER_CLIENT_DOCUMENT:
          "Enter the Client Document",
        ATTENDANCE_ROOM_LABEL_CLIENT_POSTAL_CODE: "Client Postal Code",
        ATTENDANCE_ROOM_PLACEHOLDER_CLIENT_POSTAL_CODE:
          "Enter the Client Postal Code",
        ATTENDANCE_ROOM_LABEL_CLIENT_STATE: "State",
        ATTENDANCE_ROOM_PLACEHOLDER_CLIENT_STATE: "Enter the Client State",
        ATTENDANCE_ROOM_LABEL_CLIENT_CITY: "City",
        ATTENDANCE_ROOM_PLACEHOLDER_CLIENT_CITY: "Enter the Client City",
        ATTENDANCE_ROOM_LABEL_CLIENT_STREET: "Street",
        ATTENDANCE_ROOM_PLACEHOLDER_CLIENT_STREET: "Enter the Client Street",
        ATTENDANCE_ROOM_LABEL_CLIENT_NEIGHBORHOOD: "Neighborhood",
        ATTENDANCE_ROOM_PLACEHOLDER_CLIENT_NEIGHBORHOOD:
          "Enter the Client Neighborhood",
        ATTENDANCE_ROOM_LABEL_CLIENT_ADDRESS_NUMBER: "Address Number",
        ATTENDANCE_ROOM_PLACEHOLDER_CLIENT_ADDRESS_NUMBER:
          "Enter the Client Address Number",
        ATTENDANCE_ROOM_LABEL_CLIENT_ADDRESS_COMPLEMENT: "Address Complement",
        ATTENDANCE_ROOM_PLACEHOLDER_CLIENT_ADDRESS_COMPLEMENT:
          "Enter the Client Address Complement",
        ATTENDANCE_ROOM_LABEL_CHOOSE_SUPERMARKET: "Choose Supermarket",
        ATTENDANCE_ROOM_OPTION_SELECT_THE_SUPERMARKET: "Select the Supermarket",
        ATTENDANCE_ROOM_START_SHOPPING_CART: "Start Shopping Cart",
        ATTENDANCE_ROOM_PURCHASE: "Purchase",
        ATTENDANCE_ROOM_REPURCHASE: "Repurchase",
        ATTENDANCE_ROOM_TEAM: "Team",
        ATTENDANCE_ROOM_THE_ATTENDANCE_WAS_SENT_TO_SHOPPER:
          "The Attendance was sent to Shopper",
        ATTENDANCE_ROOM_NEED_SHOPPER_HELP: "Need Shopper Help?",
        ATTENDANCE_ROOM_YES: "Yes",
        ATTENDANCE_ROOM_NO: "No",
        ATTENDANCE_ROOM_COMMENT_TO_ATTENDANCE_GROUP:
          "Comment to Attendance Group",
        ATTENDANCE_ROOM_WRITE_THE_COMMENT: "Write the Comment",
        ATTENDANCE_ROOM_SELECT_THE_SHOPPER: "Select the Shopper",
        ATTENDANCE_ROOM_SELECT_THE_DELIVERYMAN: "Select the Deliveryman",
        ATTENDANCE_ROOM_DELIVERY_TYPE: "Delivery Type",
        ATTENDANCE_ROOM_CHOOSE_DELIVERY_TYPE: "Choose Delivery Type",
        ATTENDANCE_ROOM_POP: "Pop",
        ATTENDANCE_ROOM_PROGRAMMED: "Programmed",
        ATTENDANCE_ROOM_PRIME: "Prime",
        ATTENDANCE_ROOM_DELIVERY_DATE: "Delivery Date",
        ATTENDANCE_ROOM_SEND_TO_SHOPPER: "Send to Shopper",
        ATTENDANCE_ROOM_UPDATE_COMMERCIAL_TEAM: "Update Commercial Team",
        ATTENDANCE_ROOM_UPDATE_DELIVERYMAN_AND_STATUS:
          "Transfer to Deliveryman",
        ATTENDANCE_ROOM_CHAT_ALLOWED_ONLY_BETWEEN_CLIENT_AND_SHOPPER:
          "This conversation refers to the contact between the Client and the Shopper",
        ATTENDANCE_ROOM_CHAT_ALLOWED_ONLY_BETWEEN_CLIENT_AND_DELIVERYMAN:
          "This conversation refers to the contact between the Client and the Deliveryman",
        ATTENDANCE_ROOM_CHAT_UNKNOWN_CLIENT: "Unknown client",
        ATTENDANCE_ROOM_YESTERDAY: "yesterday",

        ATTENDANCE_CART_SHOPPING_CART: "Shopping Cart",
        ATTENDANCE_CART_TOTAL_PURCHASE: "Total Purchase",
        ATTENDANCE_CART_CLIENT_CART_URL: "Client Cart URL",
        ATTENDANCE_CART_PRODUCTS_ADDITION: "Products Addition",
        ATTENDANCE_CART_SEARCH_PRODUCTS: "Search Products",
        ATTENDANCE_CART_NO_RESULT_WITH_THESE_TERMS:
          "No Results With These Terms",
        ATTENDANCE_CART_THERE_ARE_NO_ITEMS_IN_YOUR_CART:
          "There are no items in your Cart",
        ATTENDANCE_CART_ATTENDANCE_TEAM: "Attendance Team",
        ATTENDANCE_CART_TOOLS: "Tools",

        ATTENDANCE_LEAD_MERGE_MERGE_SUCCESS: "Leads Merged with Sucess!",
        ATTENDANCE_LEAD_MERGE_MERGE_ERROR:
          "It wasn't possible to merge the leads.",
        ATTENDANCE_LEAD_MERGE_LEAD_MERGE: "Lead Merge",
        ATTENDANCE_LEAD_MERGE_SEARCH_LEAD: "Search Lead",
        ATTENDANCE_LEAD_MERGE_SEARCH_FOR_THE_LEAD_TO_BE_MERGED:
          "Search for the Lead to be Merged",
        ATTENDANCE_LEAD_MERGE_CLICK_IN_THE_FIELDS_THAT_SHOULD_BE_KEPT:
          "Click in the fields that should be kept",
        ATTENDANCE_LEAD_MERGE_LEADS: "Merge Leads",
        ATTENDANCE_LEAD_MERGE_NO_LEADS: "There aren't any Leads",

        ATTENDANCE_LOGS_ATTENDANCE_LOG: "Attendance Log",
        ATTENDANCE_LOGS_NO_LOGS: "There are no Logs",

        ATTENDANCE_OPTIONS_OPTIONS: "Options",
        ATTENDANCE_OPTIONS_QUICK_MESSAGES: "Quick Messages",
        ATTENDANCE_OPTIONS_NO_QUICK_MESSAGES: "There aren't any Quick Messages",
        ATTENDANCE_OPTIONS_DO_YOU_WANT_TO_SEND: "Do you want to send",

        ATTENDANCE_CONFIG_TITLE: "Attendance Configurations",
        ATTENDANCE_CONFIG_OPENING_HOURS: "Opening Hours",
        ATTENDANCE_CONFIG_AUTOMATIC_ANSWER: "Automatic Answer",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS: "Configuration Options",

        ATTENDANCE_CONFIG_OPENING_HOURS_MONDAY: "Monday",
        ATTENDANCE_CONFIG_OPENING_HOURS_TUESDAY: "Tuesday",
        ATTENDANCE_CONFIG_OPENING_HOURS_WEDNESDAY: "Wednesday",
        ATTENDANCE_CONFIG_OPENING_HOURS_THURSDAY: "Thursday",
        ATTENDANCE_CONFIG_OPENING_HOURS_FRIDAY: "Friday",
        ATTENDANCE_CONFIG_OPENING_HOURS_SATURDAY: "Saturday",
        ATTENDANCE_CONFIG_OPENING_HOURS_SUNDAY: "Sunday",
        ATTENDANCE_CONFIG_OPENING_HOURS_TO: "to",
        ATTENDANCE_CONFIG_OPENING_HOURS_REGISTER_OPENING_HOURS:
          "Register Opening Hours",
        ATTENDANCE_CONFIG_OPENING_HOURS_OPENING_HOURS_REGISTERED_WITH_SUCCESS:
          "Opening Hours Registered with Success",
        ATTENDANCE_CONFIG_OPENING_HOURS_IT_WASNT_POSSIBLE_TO_REGISTER_OPENING_HOURS:
          "It wasn't possible to register Opening Hours",
        ATTENDANCE_CONFIG_OPENING_HOURS_CANT_HAVE_SIMILAR_HOURS_IN_THE_SAME_DAY:
          "It's not possible to have similar hours in the same day, adjust the fields in red, then try again.",

        ATTENDANCE_CONFIG_AUTOMATIC_ANSWER_REGISTER_OUTSIDE_OPENING_HOURS:
          "Register Outside Opening Hours",
        ATTENDANCE_CONFIG_AUTOMATIC_ANSWER_OPENING_HOURS: "Opening Hours",
        ATTENDANCE_CONFIG_AUTOMATIC_ANSWER_OUTSIDE_OPENING_HOURS:
          "Outside Opening Hours",
        ATTENDANCE_CONFIG_AUTOMATIC_ANSWER_ENTER_THE_AUTOMATIC_ANSWER:
          "Enter the Automatic Answer for when outside Opening Hours",
        ATTENDANCE_CONFIG_AUTOMATIC_ANSWER_AUTOMATIC_ANSWER_REGISTERED_WITH_SUCCESS:
          "Automatic Answer Registered with Success",
        ATTENDANCE_CONFIG_AUTOMATIC_ANSWER_IT_WASNT_POSSIBLE_TO_REGISTER_AUTOMATIC_ANSWER:
          "It wasn't possible to register the Automatic Answer",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_REGISTER_WAITING_TIME:
          "Register Waiting Time",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_WAITING_TIME: "Waiting Time",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_WAITING_TIME_REGISTERED_WITH_SUCCESS:
          "Waiting Time Registered With Success!",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_IT_WASNT_POSSIBLE_TO_REGISTER_WAITING_TIME:
          "It wasn't possible to Register Waiting Time",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_ATTENDANCE_STATUS: "Attendance Status",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_IN_ATTENDANCE: "In Attendance",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_SENT_TO_DELIVERYMAN:
          "Sent to Deliveryman",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_MAXIMUM_WAITING_TIME:
          "Maximum Waiting Time",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_ENTER_THE_MAXIMUM_WAITING_TIME:
          "Enter the Maximum Waiting Time",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_ACTION: "Action",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_SELECT_THE_ACTION: "Select the Action",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_QUICK_MESSAGES: "Quick Messages",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_QUICK_MESSAGE: "Quick Message",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_ENTER_THE_QUICK_MESSAGE:
          "Enter the Quick Message",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_IT_WASNT_POSSIBLE_TO_EDIT_THE_MESSAGE:
          "It wasn't possible to edit the message.",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_IT_WASNT_POSSIBLE_TO_REMOVE_THE_MESSAGE:
          "It wasn't possible to remove the message.",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_IT_WASNT_POSSIBLE_TO_CREATE_THE_MESSAGE:
          "It wasn't possible to create the message.",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_QUICK_MESSAGE_CREATED_WITH_SUCCESS:
          "Quick Message Created with Success!",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_QUICK_MESSAGE:
          "Are you sure you want to remove this Quick Message?",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_ADD_A_QUICK_MESSAGE:
          "Add a Quick Message",
        ATTENDANCE_CONFIG_CONFIG_OPTIONS_CREATE_QUICK_MESSAGE:
          "Create Quick Message",

        JOURNEY_RELATIONSHIP_RULER_LIST: "Relationship Ruler List",
        JOURNEY_WAIT: "Wait",
        JOURNEY_REGISTER: "Register",
        JOURNEY_EDIT: "Edit Journey",
        JOURNEY_NEW_RELATIONSHIP_RULER: "New Relationship Ruler",
        JOURNEY_TABLE_NAME: "Name",
        JOURNEY_TABLE_CREATED: "Created",
        JOURNEY_TABLE_CHANGED: "Changed",
        JOURNEY_TABLE_RULER_TYPE: "Ruler Type",
        JOURNEY_TABLE_FUNNEL_STAGE: "Funnel Stage",
        JOURNEY_LABEL_INTERNAL_RULER_CONTROL_NAME:
          "Internal Ruler Control Name",
        JOURNEY_PLACEHOLDER_INTERNAL_RULER_CONTROL_NAME:
          "Relationship Ruler Name",
        JOURNEY_LABEL_RELATIONSHIP_RULER_PITCH: "Relationship Ruler Pitch",
        JOURNEY_PLACEHOLDER_RELATIONSHIP_RULER_PITCH:
          "Relationship Ruler Pitch",
        JOURNEY_LABEL_RELATIONSHIP_RULER_TYPE: "Relationship Ruler Type",
        JOURNEY_OPTION_SELECT_THE_TYPE: "Select the Type",
        JOURNEY_OPTION_LEAD: "Lead",
        JOURNEY_OPTION_SUSPECT: "Suspect",
        JOURNEY_LABEL_FUNNEL_POSITION: "Funnel Position",
        JOURNEY_OPTION_SELECT_FUNNEL_POSITION: "Select the Position",
        JOURNEY_JOURNEY_FINAL_DATE: "Final Journey Date (Optional)",

        JOURNEY_DESK_CLICK_TO_INTERACT: "Click to Interact",
        JOURNEY_DESK_PUBLISHED_BUT_CANT_EDIT_ANYMORE:
          "Journey Published, you don't have permission to edit it anymore.",
        JOURNEY_DESK_PUBLISH_JOURNEY: "Publish Journey",
        JOURNEY_DESK_UNPUBLISH_JOURNEY: "Unpublish Journey",
        JOURNEY_DESK_PUBLISHED: "Published",
        JOURNEY_DESK_TRIGGERS: "Triggers",
        JOURNEY_DESK_ACTIONS: "Actions",
        JOURNEY_DESK_CONDITIONS: "Conditions",
        JOURNEY_DESK_CONFIG: "Configure",

        VISITED_PAGE_ERROR_REGISTERING_TRIGGER:
          "An error occurred when trying to register the Visited Page Trigger .",
        VISITED_PAGE_SUCCESS_REGISTERING_TRIGGER:
          "Success when registering the Visited Page Trigger !",
        VISITED_PAGE_ANY_PAGE: "Any Page",
        VISITED_PAGE_SPECIFIC_PAGE: "Specific Page",
        VISITED_PAGE_PLACEHOLDER_URL_SPECIFIC_PAGE: "Specific Page URL",
        VISITED_PAGE_LOADING_VISITED_PAGE_FORM:
          "Loading Visited Page Configuration form...",

        FACEBOOK_LEADS_ERROR_REGISTERING_TRIGGER:
          "An error occurred when trying to register the Facebook Leads Trigger .",
        FACEBOOK_LEADS_SUCCESS_REGISTERING_TRIGGER:
          "Success when registering the Facebook Leads Trigger!",
        FACEBOOK_LEADS_ANY_FORM: "Any Form",
        FACEBOOK_LEADS_SPECIFIC_FORM: "Specific Form",
        FACEBOOK_LEADS_PLACEHOLDER_URL_SPECIFIC_FORM: "Specific Form URL",
        FACEBOOK_LEADS_OPTION_SELECT_FORM: "Select the Form",
        FACEBOOK_LEADS_LOADING_CONFIG_FORM:
          "Loading Facebook Leads Configuration form...",

        EXPORTED_LEADS_ERROR_REGISTERING_TRIGGER:
          "An error occurred when trying to register the Exported Leads Trigger",
        EXPORTED_LEADS_SUCCESS_REGISTERING_TRIGGER:
          "Success when registering the Exported Leads Trigger!",
        EXPORTED_LEADS_NO_NEED_TO_CONFIG:
          "There is no need to configure the Exported Leads Trigger..",
        EXPORTED_LEADS_LOADING_CONFIG_FORM:
          "Loading Exported Leads Configuration form...",

        LEADS_99VENDAS_ERROR_REGISTERING_TRIGGER:
          "An error occurred when trying to register the 99vendas Leads Trigger",
        LEADS_99VENDAS_SUCCESS_REGISTERING_TRIGGER:
          "Success when registering the 99vendas Leads Trigger!",
        LEADS_99VENDAS_NO_NEED_TO_CONFIG:
          "There is no need to configure the 99vendas Leads Trigger..",
        LEADS_99VENDAS_LOADING_CONFIG_FORM:
          "Loading 99vendas Leads Trigger Configuration form...",

        INPUT_LEADS_PORTALS_ERROR_REGISTERING_TRIGGER:
          "An error occurred when trying to register the Entry Leads Portals Trigger",
        INPUT_LEADS_PORTALS_SUCCESS_REGISTERING_TRIGGER:
          "Success when registering the Entry Leads Portals Trigger!",
        INPUT_LEADS_PORTALS_LOADING_CONFIG_FORM:
          "Loading Entry Leads Portals Trigger Configuration form...",

        ENTRY_RULER_ERROR_REGISTERING_TRIGGER:
          "An error occurred when trying to register the Entry Ruler Trigger",
        ENTRY_RULER_SUCCESS_REGISTERING_TRIGGER:
          "Success when registering the Entry Ruler Trigger!",
        ENTRY_RULER_LOADING_CONFIG_FORM:
          "Loading Entry Ruler Trigger Configuration form...",
        ENTRY_RULER_LABEL_ENTRY_RULER_NAME: "Entry Ruler Name",
        ENTRY_RULER_PLACEHOLDER_ENTRY_RULER_NAME: "Entry Ruler Name...",

        ALERT_VIEW_ERROR_REGISTERING_ACTION:
          "An error occurred when trying to register the Alert View Action",
        ALERT_VIEW_SUCCESS_REGISTERING_ACTION:
          "Success when registering the Alert View Action!",
        ALERT_VIEW_LOADING_CONFIG_FORM:
          "Loading Alert View Action Configuration form...",

        REPORT_BROKER_ERROR_REGISTERING_ACTION:
          "An error occurred when trying to register the Report Broker Action.",
        REPORT_BROKER_SUCCESS_REGISTERING_ACTION:
          "Success when registering the Report Broker Action!",
        REPORT_BROKER_LOADING_CONFIG_FORM:
          "Loading Report Broker Action Configuration form...",
        REPORT_BROKER_LABEL_BROKER_EMAIL: "Announcement E-mail",
        REPORT_BROKER_OPTION_SELECT_EMAIL: "Select the E-mail",
        REPORT_BROKER_LABEL_BROKER_SMS: "Announcement SMS",
        REPORT_BROKER_OPTION_SELECT_SMS: "Select the SMS",

        ADD_DELAY_ERROR_REGISTERING_ACTION:
          "An error occurred when trying to register the Add Delay Action.",
        ADD_DELAY_SUCCESS_REGISTERING_ACTION:
          "Success when registering the Add Delay Action!",
        ADD_DELAY_LOADING_CONFIG_FORM:
          "Loading Add Delay Action Configuration form...",
        ADD_DELAY_AMOUNT_OF_TIME: "Amount of Time",
        ADD_DELAY_DELAY_PERIOD: "Delay Period",
        ADD_DELAY_OPTION_SELECT_TIME_MEASUREMENTS: "Select time Measurements",
        ADD_DELAY_OPTION_SECONDS: "Seconds",
        ADD_DELAY_OPTION_MINUTES: "Minutes",
        ADD_DELAY_OPTION_HOURS: "Hours",
        ADD_DELAY_OPTION_DAYS: "Days",

        ADD_TAG_ERROR_REGISTERING_ACTION:
          "An error occurred when trying to register the Add Tag Action!",
        ADD_TAG_SUCCESS_REGISTERING_ACTION:
          "Success when registering the Add Tag Action!",
        ADD_TAG_OPTION_CHOOSE_THE_ACTION: "Choose the Action",
        ADD_TAG_OPTION_SELECT_THE_ACTION: "Select the Action",
        ADD_TAG_OPTION_CHOOSE_THE_TAG: "Choose the TAG",
        ADD_TAG_OPTION_REGISTER_THE_TAG: "Register the TAG",
        ADD_TAG_OPTION_CHOOSE_THE_DESIRED_TAG: "Choose the desired TAG",
        ADD_TAG_OPTION_SELECT_THE_TAG: "Select the TAG",
        ADD_TAG_LABEL_TAG_NAME: "TAG Name",
        ADD_TAG_PLACEHOLDER_TAG_NAME: "Enter the TAG Name",
        ADD_TAG_LABEL_TAG_TYPE: "TAG Type",
        ADD_TAG_OPTION_SELECT_THE_TAG_TYPE: "Select the TAG Type",
        ADD_TAG_OPTION_COMMON_TO_ALL_BUSINESS: "Common to All Business",
        ADD_TAG_OPTION_COMMON_TO_ONLY_THIS_BUSINESS: "Only this Business",
        ADD_TAG_LOADING_CONFIG_FORM:
          "Loading Add Tag Action Configuration form...",

        LEAD_SCORE_ERROR_REGISTERING_ACTION:
          "An error occurred when trying to register the Lead Score Action!",
        LEAD_SCORE_SUCCESS_REGISTERING_ACTION:
          "Success when registering the Lead Score Action!",
        LEAD_SCORE_LOADING_CONFIG_FORM:
          "Loading Lead Score Action Configuration form...",
        LEAD_SCORE_OPTION_SELECT_THE_ACTION_TYPE: "Select the Action Type",
        LEAD_SCORE_OPTION_INCREASE_SCORE: "Increase Score",
        LEAD_SCORE_OPTION_DECREASE_SCORE: "Decrease Score",
        LEAD_SCORE_LABEL_SCORE_VALUE: "Score Value",
        LEAD_SCORE_PLACEHOLDER_SCORE_VALUE: "Enter the Score Value",

        DYNAMIC_IMAGE_ERROR_REGISTERING_ACTION:
          "An error occurred when trying to register the Dynamic Image Action!",
        DYNAMIC_IMAGE_SUCCESS_REGISTERING_ACTION:
          "Success when registering the Dynamic Image Action!",
        DYNAMIC_IMAGE_LOADING_CONFIG_FORM:
          "Loading Dynamic Image Action Configuration form...",
        DYNAMIC_IMAGE_LABEL_ID_ACTION: "Action ID",
        DYNAMIC_IMAGE_PLACEHOLDER_ID_ACTION: "ACtion ID...",

        MODAL_BANNER_ERROR_REGISTERING_ACTION:
          "An error occurred when trying to register the Modal Banner Action!",
        MODAL_BANNER_SUCCESS_REGISTERING_ACTION:
          "Success when registering the Modal Banner Action!",
        MODAL_BANNER_LOADING_CONFIG_FORM:
          "Loading Modal Banner Action Configuration form...",

        SEND_EMAIL_ERROR_REGISTERING_ACTION:
          "An error occurred when trying to register the Send E-mail Action!",
        SEND_EMAIL_SUCCESS_REGISTERING_ACTION:
          "Success when registering the Send E-mail Action!",
        SEND_EMAIL_LOADING_CONFIG_FORM:
          "Loading Send E-mail Action Configuration form...",
        SEND_EMAIL_LABEL_EMAIL_THAT_WILL_BE_SENT: "E-mail that will be sent",
        SEND_EMAIL_OPTION_SELECT_THE_EMAIL: "Select the E-mail",

        SEND_SMS_ERROR_REGISTERING_ACTION:
          "An error occurred when trying to register the Send SMS Action!",
        SEND_SMS_SUCCESS_REGISTERING_ACTION:
          "Success when registering the Send SMS Action!",
        SEND_SMS_LOADING_CONFIG_FORM:
          "Loading Send SMS Action Configuration form...",
        SEND_SMS_LABEL_SMS_THAT_WILL_BE_SENT: "SMS that will be sent",
        SEND_SMS_OPTION_SELECT_THE_SMS: "Select the SMS",

        CAPTURE_FORM_ERROR_REGISTERING_ACTION:
          "An error occurred when trying to register the Capture Form Action!",
        CAPTURE_FORM_SUCCESS_REGISTERING_ACTION:
          "Success when registering the Capture Form Action!",
        CAPTURE_FORM_LOADING_CONFIG_FORM:
          "Loading Capture Form Action Configuration form...",
        CAPTURE_FORM_LABEL_FORM_IDENTIFIER_TYPE: "Form Identifier Type",
        CAPTURE_FORM_OPTION_SELECT_THE_IDENTIFIER: "Select the Identifier",
        CAPTURE_FORM_OPTION_FORM_ID: "Form ID",
        CAPTURE_FORM_OPTION_FORM_CLASS: "Form Class",
        CAPTURE_FORM_LABEL_FORM_TYPE: "Form Type",
        CAPTURE_FORM_OPTION_SELECT_THE_TYPE: "Select the Type",
        CAPTURE_FORM_OPTION_NEW_LEAD: "New Lead",
        CAPTURE_FORM_OPTION_NEW_VISIT: "New Visit",
        CAPTURE_FORM_OPTION_NEW_CONTACT: "New Contact",
        CAPTURE_FORM_FORM_IDENTIFIER: "Form Identifier Type",
        CAPTURE_FORM_FOLLOW_UP_EMAIL: "Follow up E-mail",
        CAPTURE_FORM_REDIRECT_PAGE: "Redirect Page",

        LEAD_ACTIONS_ERROR_REGISTERING_ACTION:
          "An error occurred when trying to register the Lead Actions Action!",
        LEAD_ACTIONS_SUCCESS_REGISTERING_ACTION:
          "Success when registering the Lead Actions Action!",
        LEAD_ACTIONS_LOADING_CONFIG_FORM:
          "Loading Lead Actions Action Configuration form...",
        LEAD_ACTIONS_ACTION_IN_CREATION_WAIT_MAINTENANCE:
          "Action in Creation. Wait for the maintenance to finish...",

        NEXT_JOURNEY_ERROR_REGISTERING_ACTION:
          "An error occurred when trying to register the Next Journey Action!",
        NEXT_JOURNEY_SUCCESS_REGISTERING_ACTION:
          "Success when registering the Next Journey Action!",
        NEXT_JOURNEY_LOADING_CONFIG_FORM:
          "Loading Next Journey Action Configuration form...",
        NEXT_JOURNEY_LABEL_SELECT_THE_NEXT_JOURNEY: "Select the Next Journey",
        NEXT_JOURNEY_OPTION_SELECT_THE_NEXT_JOURNEY: "Select the Journey",
        NEXT_JOURNEY_LABEL_SELECT_THE_ENTRY_TRIGGER: "Select the Entry Trigger",
        NEXT_JOURNEY_OPTION_SELECT_THE_ENTRY_TRIGGER: "Select the Trigger",

        QUEST_OF_INTEREST_ERROR_REGISTERING_ACTION:
          "An error occurred when trying to register the Questionnaire of Interest Action!",
        QUEST_OF_INTEREST_SUCCESS_REGISTERING_ACTION:
          "Success when registering the Questionnaire of Interest Action!",
        QUEST_OF_INTEREST_LOADING_CONFIG_FORM:
          "Loading Questionnaire of Interest Action Configuration form...",
        QUEST_OF_INTEREST_LABEL_ACTION_ID: "Action ID",
        QUEST_OF_INTEREST_PLACEHOLDER_ACTION_ID: "Action ID...",

        TEST_AB_ERROR_REGISTERING_ACTION:
          "An error occurred when trying to register the Test A/B Action!",
        TEST_AB_SUCCESS_REGISTERING_ACTION:
          "Success when registering the Test A/B Action!",
        TEST_AB_LOADING_CONFIG_FORM:
          "Loading Test A/B Action Configuration form...",
        TEST_AB_WHICH_CONTENT_TYPE_WILL_BE_TESTED:
          "Which type of Content will be tested?",
        TEST_AB_OPTION_SELECT_THE_TYPE: "Select the Type",
        TEST_AB_OPTION_EMAIL_MARKETING: "E-mail Marketing",
        TEST_AB_WHICH_CONTENT_METHOD_WILL_BE_TESTED:
          "Which Content Method will be tested?",
        TEST_AB_OPTION_SELECT_THE_METHOD: "Select the Method",
        TEST_AB_OPTION_QUANTITY_OF_OPENING: "Opening Quantity",
        TEST_AB_OPTION_QUANTITY_OF_CLICKS: "Clicks Quantity",
        TEST_AB_LABEL_FOR_HOW_MUCH_TIME: "For How Much Time",
        TEST_AB_PLACEHOLDER_FOR_HOW_MUCH_TIME:
          "Enter the amount of Time that will be tested",
        TEST_AB_LABEL_TEST_PERIOD: "Test Period",
        TEST_AB_OPTION_SELECT_THE_TIME_MEASUREMENT:
          "Select the Time Measurement",
        TEST_AB_OPTION_SECONDS: "Seconds",
        TEST_AB_OPTION_MINUTES: "Minutes",
        TEST_AB_OPTION_HOURS: "Hours",
        TEST_AB_OPTION_DAYS: "Days",

        PUT_ON_HOLD_ERROR_REGISTERING_ACTION:
          "An error occurred when trying to register the Put on Hold Action!",
        PUT_ON_HOLD_SUCCESS_REGISTERING_ACTION:
          "Success when registering the Put on Hold Action!",
        PUT_ON_HOLD_LOADING_CONFIG_FORM:
          "Loading Put on Hold Action Configuration form...",
        PUT_ON_HOLD_PAUSE: "Pause",
        PUT_ON_HOLD_PLAY: "Play",

        PITCH_RELATIONSHIP_RULER_ERROR_REGISTERING_ACTION:
          "An error occurred when trying to register the Relationship Ruler Pitch Action!",
        PITCH_RELATIONSHIP_RULER_SUCCESS_REGISTERING_ACTION:
          "Success when registering the Relationship Ruler Pitch Action!",
        PITCH_RELATIONSHIP_RULER_LOADING_CONFIG_FORM:
          "Loading Relationship Ruler Pitch Action Configuration form...",
        PITCH_RELATIONSHIP_RULER_LABEL_RELATIONSHIP_RULER_PITCH:
          "Relationship Ruler Pitch",
        PITCH_RELATIONSHIP_RULER_PLACEHOLDER_RELATIONSHIP_RULER_PITCH:
          "Enter the Relationship Ruler Pitch...",

        SCHEDULED_VISIT_ERROR_REGISTERING_CONDITION:
          "An error occurred when trying to register the Scheduled Visit Condition!",
        SCHEDULED_VISIT_SUCCESS_REGISTERING_CONDITION:
          "Success when registering the Scheduled Visit Condition!",
        SCHEDULED_VISIT_LOADING_CONFIG_FORM:
          "Loading Scheduled Visit Condition Configuration form...",
        SCHEDULED_VISIT_DONT_REMEMBER_LEAD_OF_THE_VISIT:
          "Don't Remember Lead of the Visit!",
        SCHEDULED_VISIT_REMEMBER_LEAD_OF_THE_VISIT:
          "Remember Lead of the Visit!",
        SCHEDULED_VISIT_LABEL_HOURS_BEFORE: "Hours Before the Visit",
        SCHEDULED_VISIT_PLACEHOLDER_HOURS_BEFORE:
          "Remember How Many Hours Before the Visit",
        SCHEDULED_VISIT_LABEL_EMAIL: "E-mail",
        SCHEDULED_VISIT_OPTION_SELECT_THE_EMAIL: "Select the E-mail",
        SCHEDULED_VISIT_LABEL_SMS: "SMS",
        SCHEDULED_VISIT_OPTION_SELECT_THE_SMS: "Select the SMS",

        CHECK_VISIT_ERROR_REGISTERING_CONDITION:
          "An error occurred when trying to register the Check Visit Condition!",
        CHECK_VISIT_SUCCESS_REGISTERING_CONDITION:
          "Success when registering the Check Visit Condition!",
        CHECK_VISIT_LOADING_CONFIG_FORM:
          "Loading Check Visit Condition Configuration form...",
        CHECK_VISIT_NO_NEED_TO_CONFIG:
          "There is No Need to configure the Check Visit Condition...",

        CHECK_PROPOSAL_ERROR_REGISTERING_CONDITION:
          "An error occurred when trying to register the Check Proposal Condition!",
        CHECK_PROPOSAL_SUCCESS_REGISTERING_CONDITION:
          "Success when registering the Check Proposal Condition!",
        CHECK_PROPOSAL_LOADING_CONFIG_FORM:
          "Loading Check Proposal Condition Configuration form...",
        CHECK_PROPOSAL_NO_NEED_TO_CONFIG:
          "There is No Need to configure the Check Proposal Condition...",

        PROPOSED_STATUS_ERROR_REGISTERING_CONDITION:
          "An error occurred when trying to register the Proposed Status Condition!",
        PROPOSED_STATUS_SUCCESS_REGISTERING_CONDITION:
          "Success when registering the Proposed Status Condition!",
        PROPOSED_STATUS_LOADING_CONFIG_FORM:
          "Loading Proposed Status Condition Configuration form...",
        PROPOSED_STATUS_CONDITION_BEING_DEVELOPED:
          "This Condition is being developed, wait for it to be finished...",

        EMAIL_STATUS_ERROR_REGISTERING_CONDITION:
          "An error occurred when trying to register the E-mail Status Condition!",
        EMAIL_STATUS_SUCCESS_REGISTERING_CONDITION:
          "Success when registering the E-mail Status Condition!",
        EMAIL_STATUS_LOADING_CONFIG_FORM:
          "Loading E-mail Status Condition Configuration form...",
        EMAIL_STATUS_LABEL_STATUS_TYPE: "Checking Status Type",
        EMAIL_STATUS_OPTION_SELECT_STATUS: "Select the Status",
        EMAIL_STATUS_OPTION_EMAIL_OPENED: "E-mail Opened",
        EMAIL_STATUS_OPTION_EMAIL_CLICKED: "E-mail Clicked",
        EMAIL_STATUS_LABEL_EMAIL_TO_ARRIVE: "E-mail To Arrive",
        EMAIL_STATUS_OPTION_SELECT_THE_EMAIL: "Select the E-mail",

        CHECK_CONTACT_ERROR_REGISTERING_CONDITION:
          "An error occurred when trying to register the Check Contact Condition!",
        CHECK_CONTACT_SUCCESS_REGISTERING_CONDITION:
          "Success when registering the Check Contact Condition!",
        CHECK_CONTACT_LOADING_CONFIG_FORM:
          "Loading Check Contact Condition Configuration form...",
        CHECK_CONTACT_NO_NEED_TO_CONFIG:
          "There is No Need to configure the Check Contact Condition...",

        CHECK_SALE_ERROR_REGISTERING_CONDITION:
          "An error occurred when trying to register the Check Sale Condition!",
        CHECK_SALE_SUCCESS_REGISTERING_CONDITION:
          "Success when registering the Check Sale Condition!",
        CHECK_SALE_LOADING_CONFIG_FORM:
          "Loading Check Sale Condition Configuration form...",
        CHECK_SALE_NO_NEED_TO_CONFIG:
          "There is No Need to configure the Check Sale Condition...",

        CHECK_REFERRALS_ERROR_REGISTERING_CONDITION:
          "An error occurred when trying to register the Check Referrals Condition!",
        CHECK_REFERRALS_SUCCESS_REGISTERING_CONDITION:
          "Success when registering the Check Referrals Condition!",
        CHECK_REFERRALS_LOADING_CONFIG_FORM:
          "Loading Check Referrals Condition Configuration form...",
        CHECK_REFERRALS_NO_NEED_TO_CONFIG:
          "There is No Need to configure the Check Referrals Condition...",

        CHECK_LEAD_ERROR_REGISTERING_CONDITION:
          "An error occurred when trying to register the Check Lead Condition!",
        CHECK_LEAD_SUCCESS_REGISTERING_CONDITION:
          "Success when registering the Check Lead Condition!",
        CHECK_LEAD_LOADING_CONFIG_FORM:
          "Loading Check Lead Condition Configuration form...",
        CHECK_LEAD_NO_NEED_TO_CONFIG:
          "There is No Need to configure the Check Lead Condition...",

        CHECK_FEEDBACK_ERROR_REGISTERING_CONDITION:
          "An error occurred when trying to register the Check Feedback Condition!",
        CHECK_FEEDBACK_SUCCESS_REGISTERING_CONDITION:
          "Success when registering the Check Feedback Condition!",
        CHECK_FEEDBACK_LOADING_CONFIG_FORM:
          "Loading Check Feedback Condition Configuration form...",
        CHECK_FEEDBACK_NO_NEED_TO_CONFIG:
          "There is No Need to configure the Check Feedback Condition...",

        VISITED_PAGE_CONDITION_ERROR_REGISTERING_CONDITION:
          "An error occurred when trying to register the Visited Page Condition!",
        VISITED_PAGE_CONDITION_SUCCESS_REGISTERING_CONDITION:
          "Success when registering the Visited Page Condition!",
        VISITED_PAGE_CONDITION_LOADING_CONFIG_FORM:
          "Loading Visited Page Condition Configuration form...",
        VISITED_PAGE_CONDITION_CONDITION_BEING_DEVELOPED:
          "This Condition is being developed, wait for it to be finished...",

        VIEW_SOURCE_ERROR_REGISTERING_CONDITION:
          "An error occurred when trying to register the View Source Condition!",
        VIEW_SOURCE_SUCCESS_REGISTERING_CONDITION:
          "Success when registering the View Source Condition!",
        VIEW_SOURCE_LOADING_CONFIG_FORM:
          "Loading View Source Condition Configuration form...",
        VIEW_SOURCE_CONDITION_BEING_DEVELOPED:
          "This Condition is being developed, wait for it to be finished...",

        HOME_INTEGRATION_INTEGRATIONS: "Integrations",

        INTEGRATION_FACEBOOK_DELETE_PAGE: "Delete Page",
        INTEGRATION_FACEBOOK_FACEBOOK: "Facebook",
        INTEGRATION_FACEBOOK_FACEBOOK_PAGES: "Facebook Pages",
        INTEGRATION_FACEBOOK_CONFIGURE_FACEBOOK_PAGES:
          "Configure Facebook Pages",
        INTEGRATION_FACEBOOK_FACEBOOK_ADS: "Facebook Ads",
        INTEGRATION_FACEBOOK_CONFIGURE_FACEBOOK_ADS: "Configure Facebook Ads",
        INTEGRATION_FACEBOOK_FACEBOOK_FORMS: "Facebook Forms",
        INTEGRATION_FACEBOOK_CONFIGURE_FACEBOOK_FORMS:
          "Configure Facebook Forms",
        INTEGRATION_FACEBOOK_ADD_FACEBOOK_PAGE: "Add Facebook Page",
        INTEGRATION_FACEBOOK_LOG_IN_ON_FACEBOOK: "Log in on Facebook",
        INTEGRATION_FACEBOOK_SELECT_FACEBOOK_PAGE: "Select the Facebook Page",
        INTEGRATION_FACEBOOK_SELECT_PAGE: "Select the Page",
        INTEGRATION_FACEBOOK_WAIT_LOADING: "Wait. Loading...",
        INTEGRATION_FACEBOOK_PAGE_NAME: "Page Name",
        INTEGRATION_FACEBOOK_ADD_FACEBOOK_ADS_ACCOUNT:
          "Add Facebook Ads Account",
        INTEGRATION_FACEBOOK_SELECT_FACEBOOK_ADS_ACCOUNT:
          "Select the Facebook Ads Account",
        INTEGRATION_FACEBOOK_SELECT_ACCOUNT: "Select the Account",
        INTEGRATION_FACEBOOK_ADS_ACCOUNT_NAME: "Facebook Ads Account Name",
        INTEGRATION_FACEBOOK_ADD_FACEBOOK_FORMS: "Add Facebook Forms",
        INTEGRATION_FACEBOOK_SELECT_FACEBOOK_FORM: "Select the Facebook Form",
        INTEGRATION_FACEBOOK_SELECT_FORM: "Select the Form",
        INTEGRATION_FACEBOOK_SEGMENTATION: "Segmentation",
        INTEGRATION_FACEBOOK_SELECT_SEGMENTATION: "Select the Segmentation",
        INTEGRATION_FACEBOOK_NO_SEGMENTATION_REGISTERED_CLICK_TO_ADD:
          "No Segmentation Registered - Click to Add",
        INTEGRATION_FACEBOOK_CAMPAIGN_PITCH: "Campaign Pitch",
        INTEGRATION_FACEBOOK_CONTENT_PITCH: "Content Pitch",
        INTEGRATION_FACEBOOK_CLICK_PITCH: "Click Pitch",
        INTEGRATION_FACEBOOK_NO_PAGES_FOUND_INTEGRATE_THE_PAGES_FIRST:
          "No Page Found. Integrate the Pages First!",
        INTEGRATION_FACEBOOK_FORM_NAME: "Form Name",

        INTEGRATION_CRM_CRMS: "CRMs",
        INTEGRATION_CRM_HYPNOBOX: "Hypnobox",
        INTEGRATION_CRM_CONFIGURE_HYPNOBOX_ACCOUNT:
          "Configure Hypnobox Account",
        INTEGRATION_CRM_SUA_HOUSE: "Sua House",
        INTEGRATION_CRM_CONFIGURE_SUA_HOUSE_ACCOUNT:
          "Configure Sua House Account",
        INTEGRATION_CRM_ANAPRO: "Anapro",
        INTEGRATION_CRM_CONFIGURE_ANAPRO_ACCOUNT: "Configure Anapro Account",
        INTEGRATION_CRM_PIPEDRIVE: "Pipedrive",
        INTEGRATION_CRM_CONFIGURE_PIPEDRIVE_ACCOUNT:
          "Configure Pipedrive Account",
        INTEGRATION_CRM_SALES_FUNNEL: "CRM Sales Funnel",
        INTEGRATION_CRM_CONFIGURE_SALES_FUNNEL_ACCOUNT:
          "Configure Sales Funnel Account",
        INTEGRATION_CRM_LOPES: "CRM Lopes",
        INTEGRATION_CRM_CONFIGURE_LOPES_ACCOUNT: "Configure Lopes Account",
        INTEGRATION_CRM_CONSTRUTOR_DE_VENDAS: "CRM Construtor de Vendas",
        INTEGRATION_CRM_CONFIGURE_CRM_CONSTRUTOR_DE_VENDAS_ACCOUNT:
          "Configure Construtor de Vendas Account",

        HYPNOBOX_REMOVE_INTEGRATION: "Remove Integration",
        HYPNOBOX_ADD_HYPNOBOX_ACCOUNT: "Add Hypnobox Account",
        HYPNOBOX_HYPNOBOX_ACCOUNT_URL: "Hypnobox Account URL",
        HYPNOBOX_PRODUCT_ID: "Product ID",
        HYPNOBOX_SELECT_BROKER: "Select the Broker",
        HYPNOBOX_BROKER: "Broker",

        SUA_HOUSE_REMOVE_INTEGRATION: "Remove Integration",
        SUA_HOUSE_ADD_SUA_HOUSE_ACCOUNT: "Add Sua House Account",
        SUA_HOUSE_ACCOUNT_SUA_HOUSE_URL: "Sua House Account URL",
        SUA_HOUSE_BUSINESS_CODE: "Business Code",
        SUA_HOUSE_SELECT_BROKER: "Select the Broker",
        SUA_HOUSE_BROKER: "Broker",

        ANAPRO_REMOVE_INTEGRATION: "Remove Integration",
        ANAPRO_ADD_ANAPRO_ACCOUNT: "Add Anapro Account",
        ANAPRO_KEY: "Key",
        ANAPRO_INTEGRATING_KEY: "Integrating Key",
        ANAPRO_AGENCY_KEY: "Agency Key",
        ANAPRO_CAMPAIGN_KEY: "Campaign Code",
        ANAPRO_PRODUCT_KEY: "Business Product Key",
        ANAPRO_CHANNEL_KEY: "Channel key",
        ANAPRO_SELECT_BROKER: "Select the Broker",
        ANAPRO_BROKER: "Broker",

        PIPEDRIVE_NOTIFY_THE_DEVELOPMENT_TEAM_AN_ERROR_OCURRED:
          "Notify the development team that there was an error when registering a Pipedrive account!",
        PIPEDRIVE_REMOVE_INTEGRATION: "Remove Integration",
        PIPEDRIVE_ADD_PIPEDRIVE_ACCOUNT: "Add Pipedrive Account",
        PIPEDRIVE_FILL_IN_WITH_THE_TOKEN_PROVIDED_BY_PIPEDRIVE:
          "Fill in with the token provided by pipedrive",
        PIPEDRIVE_ACCESS_INFORMATIONS: "Access Informations",
        PIPEDRIVE_SELECT_PIPEDRIVE_FUNNEL:
          "Select the Pipedrive Funnel (Business)",
        PIPEDRIVE_SELECT_FUNNEL_STAGE: "Select the Funnel Stage",
        PIPEDRIVE_SELECT_BROKER: "Selecione the Broker",
        PIPEDRIVE_BROKER: "Broker",

        FUNIL_DE_VENDAS_REMOVE_INTEGRATION: "Remove Integration",
        FUNIL_DE_VENDAS_ADD_SALES_FUNNEL_CRM_ACCOUNT:
          "Add Sales Funnel CRM Account",
        FUNIL_DE_VENDAS_SALESMAN: "Salesman",
        FUNIL_DE_VENDAS_FUNNEL: "Funnel",
        FUNIL_DE_VENDAS_STAGE: "Stage",
        FUNIL_DE_VENDAS_SALES_CHANNEL: "Sales Channel",
        FUNIL_DE_VENDAS_INTEGRATION_KEY: "Integration Key",
        FUNIL_DE_VENDAS_SELECT_BROKER: "Select the Broker",
        FUNIL_DE_VENDAS_BROKER: "Broker",

        LOPES_REMOVE_INTEGRATION: "Remove Integration",
        LOPES_ADD_LOPES_ACCOUNT: "Add Lopes Account",
        LOPES_COMPANY_CODE: "Company Code",
        LOPES_PRODUCT_CODE: "Product Code",
        LOPES_SELECT_BROKER: "Select the Broker",
        LOPES_BROKER: "Broker",

        CONSTRUTOR_DE_VENDAS_REMOVE_INTEGRATION: "Remove Integration",
        CONSTRUTOR_DE_VENDAS_ADD_CONSTRUTOR_DE_VENDAS_ACCOUNT:
          "Add Construtor de Vendas Account",
        CONSTRUTOR_DE_VENDAS_COMPANY_URL: "Subdomain CRM",
        CONSTRUTOR_DE_VENDAS_COMPANY_TOKEN: "Token",
        CONSTRUTOR_DE_VENDAS_CRM_MODULE: "Module",
        CONSTRUTOR_DE_VENDAS_PRODUCT_ID: "Product ID",
        CONSTRUTOR_DE_VENDAS_SELECT_BROKER: "Select the Broker",
        CONSTRUTOR_DE_VENDAS_BROKER: "Broker",

        INTEGRATION_WEB_SITES_WEBSITES: "Websites",
        INTEGRATION_WEB_SITES_MONITORING_CODE: "Monitoring Code",
        INTEGRATION_WEB_SITES_VISUALIZE_CODE: "Visualize Code",
        INTEGRATION_WEB_SITES_SMARTLOOK: "Smartlook",
        INTEGRATION_WEB_SITES_CONFIGURE_SMARTLOOK: "Configure Smartlook",
        INTEGRATION_WEB_SITES_PORTALS: "Portals",
        INTEGRATION_WEB_SITES_CONFIGURE_PORTALS: "Configure Portals",
        INTEGRATION_WEB_SITES_EMAIL_NOTIFICATIONS: "E-mail Notifications",
        INTEGRATION_WEB_SITES_MANAGE_NOTIFICATIONS: "Manage Notifications",

        SMARTLOOK_REGISTER_SMARTLOOK_INTEGRATION:
          "Register Smartlook Integration",
        SMARTLOOK_CONFIGURE_SMARTLOOK_INTEGRATION:
          "Configure Smartlook Integration",
        SMARTLOOK_CHOOSE_THE_PROJECT_YOU_WANT_TO_USE:
          "Choose the project you want to use...",
        SMARTLOOK_SELECT_THE_PROJECT: "Select the Project",

        PORTALS_REMOVE_INTEGRATION: "Remove Integration",
        PORTALS_REGISTER_PORTALS: "Register Portals",
        PORTALS_ADD_NEW_PORTAL: "Add new Portal",
        PORTALS_NAME_USED_IN_THE_PORTAL_SUBJECT:
          "Name Used In the Portal Subject",
        PORTALS_SELECT_PORTAL: "Select the Portal",
        PORTALS_PORTAL: "Portal",

        EMAIL_NOTIFICATION_FORM_ID: "Form ID",
        EMAIL_NOTIFICATION_DEPARTMENT: "Department",
        EMAIL_NOTIFICATION_SELECT_THE_DEPARTMENT: "Select the Department",
        EMAIL_NOTIFICATION_EMAILS: "E-mails",
        EMAIL_NOTIFICATION_EMAIL_TO_BE_NOTIFIED: "E-mail to be notified",
        EMAIL_NOTIFICATION_ENTER_THE_EMAIL: "Enter the e-mail",
        EMAIL_NOTIFICATION_ADD_EMAIL: "Add E-mail",
        EMAIL_NOTIFICATION_ADD_EMAIL_NOTIFICATION: "Add E-mail Notification",
        EMAIL_NOTIFICATION_REMOVE_NOTIFICATION: "Remove Notification",
        EMAIL_NOTIFICATION_REGISTER_EMAIL_NOTIFICATION:
          "Register E-mail Notification",
        EMAIL_NOTIFICATION_ADD_NEW_NOTIFICATION: "Add new Notification",

        SUSPECT_LIST_VISUALIZE_SUSPECT: "Visualize Suspect",
        SUSPECT_LIST_VISUALIZE: "Visualize",
        SUSPECT_LIST_SUSPECTS_LIST: "Suspects List",
        SUSPECT_LIST_RESULTS_PER_PAGE: "Results per Page",
        SUSPECT_LIST_SEARCH_A_SUSPECT: "Search a Suspect",
        SUSPECT_LIST_SEARCH_FOR_DOMAIN: "Search for a Domain",
        SUSPECT_LIST_SELECT_THE_DOMAIN: "Select the Domain",
        SUSPECT_LIST_NUMBER: "Nº",
        SUSPECT_LIST_ORIGIN: "Origin",
        SUSPECT_LIST_SEGMENTATION: "Segmentation",
        SUSPECT_LIST_ENTRY_DATE: "Entry Date",
        SUSPECT_LIST_ADVANCED_SEARCH: "Advanced Search",

        SUSPECT_MODAL_DATA_LOADING: "Loading...",
        SUSPECT_MODAL_VISUALIZE_SUSPECT: "Visualize Suspect",
        SUSPECT_MODAL_SUSPECT: "Suspect",
        SUSPECT_MODAL_UNKNOWN: "Unkown",
        SUSPECT_MODAL_SUSPECT_INFORMATIONS: "Suspect Informations",
        SUSPECT_MODAL_LAST_CONVERSION: "Last Conversion",
        SUSPECT_MODAL_FIRST_ACTION: "First Action",
        SUSPECT_MODAL_SEGMENTATION: "Segmentation",
        SUSPECT_MODAL_WEBSITE_OR_LANDING_PAGE: "Website or landing page",
        SUSPECT_MODAL_BROWSER: "Browser",
        SUSPECT_MODAL_DEVICE: "Device",
        SUSPECT_MODAL_LAST_NAVIGATION: "Last Navigation",
        SUSPECT_MODAL_COOKIES: "Cookies",
        SUSPECT_MODAL_IPS: "IP's",
        SUSPECT_MODAL_SUSPECT_ACCESSES: "Suspect Accesses",
        SUSPECT_MODAL_VISUALIZE_NAVIGATION: "Visualize Navigation",

        HEADER_MAIN_CURRENT_MONTH: "Current Month",
        HEADER_MAIN_LAST_MONTH: "Last month",
        HEADER_MAIN_TODAY: "Today",
        HEADER_MAIN_YESTERDAY: "Yesterday",
        HEADER_MAIN_LAST_7_DAYS: "Last 7 days",
        HEADER_MAIN_LAST_30_DAYS: "Last 30 days",
        HEADER_MAIN_LAST_90_DAYS: "Last 90 days",
        HEADER_MAIN_CHOOSE_DATES: "Choose dates",
        HEADER_MAIN_DATES: "Dates",
        HEADER_MAIN_INITIAL_DATE: "Initial Date",
        HEADER_MAIN_FINAL_DATE: "Final Date",
        HEADER_MAIN_CONSULT: "Consult",

        LIST_COMPANIES_COMPANY: "Company",
        LIST_BUSINESS_BUSINESS: "Business",

        MENU_MAIN_BUYLITICS: "Buylitics",
        MENU_MAIN_CHANGE: "Change",
        MENU_MAIN_MAIN: "Main",
        MENU_MAIN_IN_MAINTENANCE_WAIT: "In Maintenance, wait!",
        MENU_MAIN_CONFIG: "Config",
        MENU_MAIN_COMING_SOON: "Coming Soon!",
        MENU_MAIN_SUPPORT_COMING_SOON: "Support (Coming Soon)",
        MENU_MAIN_SIGN_OUT: "Sign Out",

        DASHBOARD_ATTENDANCES_CARD_TOTAL: "total",
        DASHBOARD_ATTENDANCES_CARD_AVERAGE: "average",
        DASHBOARD_ATTENDANCES_CARD_MAXIMUM: "maximum",
        DASHBOARD_ATTENDANCES_CARD_MINIMUM: "minimum",
        DASHBOARD_ATTENDANCES_CARD_SCORE: "Score",
        DASHBOARD_ATTENDANCES_CARD_CLIENTS: "clients",
        DASHBOARD_ATTENDANCES_CARD_CHANNEL: "Channel",
        DASHBOARD_ATTENDANCES_CARD_ACCOUNTS: "accounts",
        DASHBOARD_ATTENDANCES_CARD_NUMBERS: "numbers",
        DASHBOARD_ATTENDANCES_CARD_PURCHASES: "purchases",
        DASHBOARD_ATTENDANCES_CARD_WAITING: "waiting",
        DASHBOARD_ATTENDANCES_CARD_DELIVERIES: "deliveries",
        DASHBOARD_ATTENDANCES_CARD_ON_HOLD: "on hold",
        DASHBOARD_ATTENDANCES_CARD_ATTENDANCES_DASHBOARD:
          "Attendances Dashboard",
        DASHBOARD_ATTENDANCES_CARD_PRE_PURCHASE_AND_ATTENDANCE:
          "Pre-Purchase and Attendance",
        DASHBOARD_ATTENDANCES_CARD_ATTENDANCES_TOTAL: "Total of Attendances",
        DASHBOARD_ATTENDANCES_CARD_ATTENDANCE_AVERAGE_TIME:
          "Attendance Average Time",
        DASHBOARD_ATTENDANCES_CARD_AVERAGE_PURCHASE_TIME:
          "Purchase Average Time",
        DASHBOARD_ATTENDANCES_CARD_MAXIMUM_ATTENDANCE_TIME:
          "Attendance Maximum Time",
        DASHBOARD_ATTENDANCES_CARD_MAXIMUM_PURCHASE_TIME:
          "Purchase Maximum Time",
        DASHBOARD_ATTENDANCES_CARD_AVERAGE_EVALUATION_SCORE:
          "Average Evaluation Score",
        DASHBOARD_ATTENDANCES_CARD_NEW_CLIENTS: "New Clients",
        DASHBOARD_ATTENDANCES_CARD_LOYAL_CLIENTS: "Loyal Clients",
        DASHBOARD_ATTENDANCES_CARD_CHANNELS: "Channels",
        DASHBOARD_ATTENDANCES_CARD_CHANNEL_MOST_USED: "Most Used Channel",
        DASHBOARD_ATTENDANCES_CARD_ATTENDANCES_BY_FACEBOOK:
          "Attendances by Facebook",
        DASHBOARD_ATTENDANCES_CARD_ATTENDANCES_BY_INSTAGRAM:
          "Attendances by Instagram",
        DASHBOARD_ATTENDANCES_CARD_ATTENDANCES_BY_WHATSAPP:
          "Attendances by Whatsapp",
        DASHBOARD_ATTENDANCES_CARD_ATTENDANCES_BY_TWITTER:
          "Attendances by Twitter",
        DASHBOARD_ATTENDANCES_CARD_ATTENDANCES_BY_EMAIL:
          "Attendances by E-mail",
        DASHBOARD_ATTENDANCES_CARD_ATTENDANCES_BY_CHAT: "Attendances by Chat",
        DASHBOARD_ATTENDANCES_CARD_ATTENDANCES_BY_PHONE: "Attendances by Phone",
        DASHBOARD_ATTENDANCES_CARD_PURCHASE: "Purchase",
        DASHBOARD_ATTENDANCES_CARD_PURCHASES_TOTAL: "Total of Purchases",
        DASHBOARD_ATTENDANCES_CARD_SHOPPER_MAXIMUM_TIME: "Shopper Maximum Time",
        DASHBOARD_ATTENDANCES_CARD_SHOPPER_AVERAGE_TIME: "Shopper Average Time",
        DASHBOARD_ATTENDANCES_CARD_AVERAGE_TICKET: "Average Ticket",
        DASHBOARD_ATTENDANCES_CARD_MAXIMUM_TICKET: "Maximum Ticket",
        DASHBOARD_ATTENDANCES_CARD_MINIMUM_TICKET: "Minimum Ticket",
        DASHBOARD_ATTENDANCES_CARD_ALL_WAITING_DELIVERY:
          "All - Waiting Delivery",
        DASHBOARD_ATTENDANCES_CARD_POP_WAITING_DELIVERY:
          "Pop - Waiting Delivery",
        DASHBOARD_ATTENDANCES_CARD_PRIME_WAITING_DELIVERY:
          "Prime - Waiting Delivery",
        DASHBOARD_ATTENDANCES_CARD_PROGRAMMED_WAITING_DELIVERY:
          "Programmed - Waiting Delivery",
        DASHBOARD_ATTENDANCES_CARD_POP_DELIVERY: "Pop Delivery",
        DASHBOARD_ATTENDANCES_CARD_POP_DELIVERY_TOTAL:
          "Pop - Total of Deliveries",
        DASHBOARD_ATTENDANCES_CARD_POP_AVERAGE_KILOMETER:
          "Pop - Average Kilometers",
        DASHBOARD_ATTENDANCES_CARD_POP_AVERAGE_SHIPPING_TICKET:
          "Pop - Shipping Average Ticket",
        DASHBOARD_ATTENDANCES_CARD_POP_DELIVERY_AVERAGE_TIME:
          "Pop - Delivery Average Time",
        DASHBOARD_ATTENDANCES_CARD_POP_MAXIMUM_DELIVERY_TIME:
          "Pop - Delivery Maximum Time",
        DASHBOARD_ATTENDANCES_CARD_POP_DELIVERY_MINIMUM_TIME:
          "Pop - Delivery Minimum Time",
        DASHBOARD_ATTENDANCES_CARD_PRIME_DELIVERY: "Prime Delivery",
        DASHBOARD_ATTENDANCES_CARD_PRIME_DELIVERY_TOTAL:
          "Prime - Total of Deliveries",
        DASHBOARD_ATTENDANCES_CARD_PRIME_AVERAGE_KILOMETER:
          "Prime - Average Kilometers",
        DASHBOARD_ATTENDANCES_CARD_PRIME_AVERAGE_SHIPPING_TICKET:
          "Prime - Shipping Average Ticket",
        DASHBOARD_ATTENDANCES_CARD_PRIME_DELIVERY_AVERAGE_TIME:
          "Prime - Delivery Average Time",
        DASHBOARD_ATTENDANCES_CARD_PRIME_DELIVERY_MAXIMUM_TIME:
          "Prime - Delivery Maximum Time",
        DASHBOARD_ATTENDANCES_CARD_PRIME_DELIVERY_MINIMUM_TIME:
          "Prime - Delivery Minimum Time",
        DASHBOARD_ATTENDANCES_CARD_PROGRAMMED_DELIVERY: "Programmed Delivery",
        DASHBOARD_ATTENDANCES_CARD_PROGRAMMED_DELIVERY_TOTAL:
          "Programmed - Total of Deliveries",
        DASHBOARD_ATTENDANCES_CARD_PROGRAMMED_AVERAGE_KILOMETER:
          "Programmed - Average Kilometers",
        DASHBOARD_ATTENDANCES_CARD_PROGRAMMED_AVERAGE_SHIPPING_TICKET:
          "Programmed - Shipping Average Ticket",
        DASHBOARD_ATTENDANCES_CARD_PROGRAMMED_DELIVERY_AVERAGE_TIME:
          "Programmed - Delivery Average Time",
        DASHBOARD_ATTENDANCES_CARD_PROGRAMMED_DELIVERY_MAXIMUM_TIME:
          "Programmed - Delivery Maximum Time",
        DASHBOARD_ATTENDANCES_CARD_PROGRAMMED_DELIVERY_MINIMUM_TIME:
          "Programmed - Delivery Minimum Time",
        DASHBOARD_ATTENDANCES_CARD_WAIT: "On Hold",
        DASHBOARD_ATTENDANCES_CARD_AVERAGE_WAITING_TIME_PER_ATTENDANCE:
          "Average Time on Hold per Attendance",
        DASHBOARD_ATTENDANCES_CARD_MAXIMUM_WAITING_TIME_PER_ATTENDANCE:
          "Maximum Time on Hold per Attendance",
        DASHBOARD_ATTENDANCES_CARD_MINIMUM_WAITING_TIME_PER_ATTENDANCE:
          "Minimum Time on Hold per Attendance",
        DASHBOARD_ATTENDANCES_CARD_TOTAL_OF_PRE_PURCHASES_ON_HOLD:
          "Total of Pre-purchases on Hold",
        DASHBOARD_ATTENDANCES_CARD_TOTAL_OF_SHOPPERS_ON_HOLD:
          "Total of Shoppers on Hold",
        DASHBOARD_ATTENDANCES_CARD_TOTAL_OF_DELIVERIES_ON_HOLD:
          "Total of Deliveries on Hold",
        // ANDERSON
        //DASHBOARD_ATTENDANCES_CARD_SHIPPING_STATUS_IN_ATTENDANCE_TOTAL: "In Attendance",
        DASHBOARD_ATTENDANCES_CARD_SHIPPING_STATUS_IN_ATTENDANCE_TOTAL:
          "Shopper",
        DASHBOARD_ATTENDANCES_CARD_SHIPPING_STATUS_AWAITING_SCRIPTING_TOTAL:
          "Waiting for Routing",
        DASHBOARD_ATTENDANCES_CARD_SHIPPING_STATUS_SENT_TO_DELIVERYMAN_TOTAL:
          "Delivery Man Chosen",
        DASHBOARD_ATTENDANCES_CARD_SHIPPING_STATUS_AWAITING_DELIVERY_START_TOTAL:
          "Waiting for Delivery to Begin",
        DASHBOARD_ATTENDANCES_CARD_SHIPPING_STATUS_ON_DELIVERY_TOTAL:
          "Ongoing Delivery",
        DASHBOARD_ATTENDANCES_CARD_SHIPPING_STATUS_FINISHED_TOTAL: "Delivered",

        ADMIN_USERS_TITLE: "Admin Users",
        ADMIN_USERS_LIST_USERS: "List Users",
        ADMIN_USERS_LIST_LIST_OF_USERS: "List Users",
        ADMIN_USERS_LIST_OF_USERS: "Lista de Users",
        ADMIN_USERS_LIST_ACCESS_BUTTON: "Access User",
        ADMIN_USERS_USER_NAME: "User Name",
        ADMIN_USERS_USER_EMAIL: "User E-mail",
        ADMIN_USERS_USER_ACCESS_LEVEL: "User Access Level",
        ADMIN_USERS_REGISTER_WAIT_STATUS: "Wait, We're Registering the User.",
        ADMIN_USERS_REGISTER_SUCCESS_STATUS: "User Registered with Success!",
        ADMIN_USERS_REGISTER_USER: "Register Users",
        ADMIN_USERS_REGISTER_LABEL_NAME: "Name",
        ADMIN_USERS_REGISTER_PLACEHOLDER_NAME: "User Name",
        ADMIN_USERS_REGISTER_LABEL_ACCESS_LEVEL: "Access Level",
        ADMIN_USERS_REGISTER_SELECT_ACCESS_LEVEL: "Select the Access Level",
        ADMIN_USERS_REGISTER_SELECT_ADMIN: "Admin",
        ADMIN_USERS_REGISTER_LABEL_EMAIL: "E-mail",
        ADMIN_USERS_REGISTER_PLACEHOLDER_EMAIL: "User E-mail",
        ADMIN_USERS_REGISTER_REGISTER_USER: "Register New User",

        COMMERCIAL_EMKSA_TITLE: "Emksa Team",
        COMMERCIAL_EMKSA_PRE_ATTENDANCE: "Pre-Attendance",
        COMMERCIAL_EMKSA_SHOPPER: "Shopper",
        COMMERCIAL_EMKSA_DELIVERYMAN: "Deliveryman",
        COMMERCIAL_EMKSA_EDIT: "Edit",
        COMMERCIAL_EMKSA_SUPERMARKET: "Supermarket",
        COMMERCIAL_EMKSA_SELECT_THE_SUPERMARKET: "Select the Supermarket",
        COMMERCIAL_EMKSA_ALL_THE_SUPERMARKET: "All the Supermarket",
        COMMERCIAL_EMKSA_ADD_SUPERMARKET: "Add Supermarket",

        COMMERCIAL_PRE_ATTENDANCE_REGISTERED_WITH_SUCCESS:
          "Pre-Attendance Registered With Success!",
        COMMERCIAL_PRE_ATTENDANCE_IT_WASNT_POSSIVLE_TO_REGISTER_PRE_ATTENDANCE:
          "It wasn't Possible to Register the Pre-Attendance",
        COMMERCIAL_PRE_ATTENDANCE_PRE_ATTENDANCE_LIST: "Pre-Attendance List",
        COMMERCIAL_PRE_ATTENDANCE_ADD_PRE_ATTENDANCE: "Add Pre-Attendance",
        COMMERCIAL_PRE_ATTENDANCE_EDIT_PRE_ATTENDANCE: "Edit Pre-Attendance",
        COMMERCIAL_PRE_ATTENDANCE_NAME: "Name",
        COMMERCIAL_PRE_ATTENDANCE_EMAIL: "E-mail",
        COMMERCIAL_PRE_ATTENDANCE_PHONE: "Phone",
        COMMERCIAL_PRE_ATTENDANCE_SUPERMARKETS: "Supermarkets",
        COMMERCIAL_PRE_ATTENDANCE_LABEL_NAME: "Enter the Name",
        COMMERCIAL_PRE_ATTENDANCE_LABEL_EMAIL: "Enter the E-mail",
        COMMERCIAL_PRE_ATTENDANCE_LABEL_PHONE: "Enter the Phone",

        COMMERCIAL_DELIVERYMAN_REGISTERED_WITH_SUCCESS:
          "Deliveryman Registered With Success!",
        COMMERCIAL_DELIVERYMAN_IT_WASNT_POSSIVLE_TO_REGISTER_DELIVERYMAN:
          "It wasn't Possible to Register the Deliveryman",
        COMMERCIAL_DELIVERYMAN_DELIVERYMAN_LIST: "Deliveryman List",
        COMMERCIAL_DELIVERYMAN_ADD_DELIVERYMAN: "Add Deliveryman",
        COMMERCIAL_DELIVERYMAN_EDIT_DELIVERYMAN: "Edit Deliveryman",
        COMMERCIAL_DELIVERYMAN_NAME: "Name",
        COMMERCIAL_DELIVERYMAN_EMAIL: "E-mail",
        COMMERCIAL_DELIVERYMAN_PHONE: "Phone",
        COMMERCIAL_DELIVERYMAN_SUPERMARKETS: "Supermarkets",
        COMMERCIAL_DELIVERYMAN_LABEL_NAME: "Enter the Name",
        COMMERCIAL_DELIVERYMAN_LABEL_EMAIL: "Enter the E-mail",
        COMMERCIAL_DELIVERYMAN_LABEL_PHONE: "Enter the Phone",

        COMMERCIAL_SHOPPER_REGISTERED_WITH_SUCCESS:
          "Shopper Registered With Success!",
        COMMERCIAL_SHOPPER_IT_WASNT_POSSIVLE_TO_REGISTER_SHOPPER:
          "It wasn't Possible to Register the Shopper",
        COMMERCIAL_SHOPPER_SHOPPER_LIST: "Shopper List",
        COMMERCIAL_SHOPPER_ADD_SHOPPER: "Add Shopper",
        COMMERCIAL_SHOPPER_EDIT_SHOPPER: "Edit Shopper",
        COMMERCIAL_SHOPPER_NAME: "Name",
        COMMERCIAL_SHOPPER_EMAIL: "E-mail",
        COMMERCIAL_SHOPPER_PHONE: "Phone",
        COMMERCIAL_SHOPPER_SUPERMARKETS: "Supermarkets",
        COMMERCIAL_SHOPPER_LABEL_NAME: "Enter the Name",
        COMMERCIAL_SHOPPER_LABEL_EMAIL: "Enter the E-mail",
        COMMERCIAL_SHOPPER_LABEL_PHONE: "Enter the Phone",

        DASHBOARD_PERFORMANCE_DASHBOARD_PERFORMANCE: "Dashboard Performance",
        DASHBOARD_PERFORMANCE_GO_BACK: "Go Back",

        DASHBOARD_PERFORMANCE_CARD_VIEW_NOT_FOUND: "View Not Found",
        DASHBOARD_PERFORMANCE_CARD_ENTER_THE_CARDS_TITLE:
          "Enter the Card's Name",
        DASHBOARD_PERFORMANCE_CARD_ENTER_THE_CARDS_VALUE:
          "Enter the Card's Value",
        DASHBOARD_PERFORMANCE_CARD_ENTER_THE_CARDS_SHORT_DESCRIPTION:
          "Enter Card's Short Description",

        DASHBOARD_PERFORMANCE_VIEW_THERE_ARENT_ANY_REGISTERS:
          "There aren't any Registers",
        DASHBOARD_PERFORMANCE_VIEW_POSITION: "Position",
        DASHBOARD_PERFORMANCE_VIEW_DATE: "Date",
        DASHBOARD_PERFORMANCE_VIEW_QUANTITY: "Quantity",

        DASHBOARD_PERFORMANCE_COMPARISON_COMPARISON_MODAL: "Comparisons Modal",
        DASHBOARD_PERFORMANCE_COMPARISON_CARDS_COMPARISON: "Cards Comparison",
        DASHBOARD_PERFORMANCE_COMPARISON_METRIC_COMPARISON_IN_BAR_CHART:
          "Metric Comparison in Bar Chart",

        DASHBOARD_PERFORMANCE_CARD_CARD_NOT_FOUND: "Card Not Found",
        DASHBOARD_PERFORMANCE_CARD_NOT_FOUND: "was not found",
        DASHBOARD_PERFORMANCE_CARD_ADD_STAGE: "Add Stage",
        DASHBOARD_PERFORMANCE_CARD_ADD_CARD: "Add Card",
        DASHBOARD_PERFORMANCE_CARD_CLICK_TO_ADD_CARD: "Click to add a Card",
        DASHBOARD_PERFORMANCE_CARD_CHANGE_CARD_MAIN_METRIC:
          "Change Card Main Metric",
        DASHBOARD_PERFORMANCE_CARD_REMOVE_DASHBOARD: "Remove Dashboard",
        DASHBOARD_PERFORMANCE_CARD_EDIT_DASHBOARD: "Edit Dashboard",
        DASHBOARD_PERFORMANCE_CARD_ENTER_THE_DASHBOARD_TITLE:
          "Enter the Dashboard Title",
        DASHBOARD_PERFORMANCE_CARD_ENTER_THE_DASHBOARD_SHORT_DESCRIPTION:
          "Enter the Dashboard Short Description",
        DASHBOARD_PERFORMANCE_CARD_EDIT_CARD: "Edit Card",
        DASHBOARD_PERFORMANCE_CARD_COMPARE_CARD: "Compare Card",
        DASHBOARD_PERFORMANCE_CARD_REMOVE_FROM_COMPARISON:
          "Remove from Comparison",
        DASHBOARD_PERFORMANCE_CARD_REMOVE_CARD: "Remove Card",
        DASHBOARD_PERFORMANCE_CARD_CONFIRM_CHANGES: "Confirm Changes",
        DASHBOARD_PERFORMANCE_CARD_EDIT_STAGE: "Edit Stage",
        DASHBOARD_PERFORMANCE_CARD_REMOVE_STAGE: "Remove Stage",
        DASHBOARD_PERFORMANCE_CARD_VISUALIZE_METRIC: "Visualize Metric",
        DASHBOARD_PERFORMANCE_CARD_ADD_METRIC: "Add Metric",
        DASHBOARD_PERFORMANCE_CARD_REMOVE_METRIC: "Remove Metric",
        DASHBOARD_PERFORMANCE_CARD_SELECTED_VIEWS: "Selected Views",
        DASHBOARD_PERFORMANCE_CARD_THERE_ARENT_ANY_SELECTED_VIEWS:
          "There aren't any Selected Views",
        DASHBOARD_PERFORMANCE_CARD_SELECT_VIEWS: "Select Views",
        DASHBOARD_PERFORMANCE_CARD_THERE_ARENT_ANY_VIEWS_TO_BE_SELECTED:
          "There aren't Any Views to be Selected",
        DASHBOARD_PERFORMANCE_CARD_VISUALIZE_VIEW: "Visualize View",
        DASHBOARD_PERFORMANCE_CARD_ADD_VIEW: "Add View",
        DASHBOARD_PERFORMANCE_CARD_REMOVE_VIEW: "Remove View",

        CHOOSE_DASHBOARD_PERFORMANCE_CHOOSE_DASHBOARD_PERFORMANCE:
          "Choose Dashboard Performance",
        CHOOSE_DASHBOARD_PERFORMANCE_THERE_ARENT_ANY_DASHBOARDS_REGISTERED:
          "There aren't any Dashboards Registered",
        CHOOSE_DASHBOARD_PERFORMANCE_ACCESS_DASHBOARD: "Access Dashboard",
        CHOOSE_DASHBOARD_PERFORMANCE_CREATE_DASHBOARD_PERFORMANCE:
          "Create Dashboard",
        CHOOSE_DASHBOARD_PERFORMANCE_CLICK_TO_CREATE_DASHBOARD_PERFORMANCE:
          "Click to Create a Dashboard",
        CHOOSE_DASHBOARD_PERFORMANCE_ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_DASHBOARD:
          "Are you sure you want to remove this dashboard?",
        CHOOSE_DASHBOARD_PERFORMANCE_AN_ERROR_OCURRED_WHILE_TRYING_TO_CREATE_THE_DASHBOARD:
          "An Error Ocurred While Trying to Create the Dashboard",

        CREATE_DASHBOARD_PERFORMANCE_IT_WASNT_POSSIBLE_TO_LOAD_THE_DASHBOARD:
          "It wasn't possible to load the dashboard",
        CREATE_DASHBOARD_PERFORMANCE_CREATE_DASHBOARD_PERFORMANCE:
          "Create Dashboard Performance",
        CREATE_DASHBOARD_PERFORMANCE_UPDATE_DASHBOARD_PERFORMANCE:
          "Update Dashboard Performance",
        CREATE_DASHBOARD_PERFORMANCE_ENTER_THE_NAME_OF_THE_DASHBOARD:
          "Enter the name of the Dashboard",
        CREATE_DASHBOARD_PERFORMANCE_ENTER_THE_SHORT_DESCRIPTION_OF_THE_DASHBOARD:
          "Enter the short description of the Dashboard",
        CREATE_DASHBOARD_PERFORMANCE_ENTER_THE_NAME_THE_STAGE_NAME:
          "Enter the Stage Name",
        CREATE_DASHBOARD_PERFORMANCE_GO_BACK: "Go Back",
        CREATE_DASHBOARD_PERFORMANCE_ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_CARD:
          "Are you sure you want to remove this Card?",
        CREATE_DASHBOARD_PERFORMANCE_ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_STAGE:
          "Are you sure you want to remove this Stage?",
        CREATE_DASHBOARD_PERFORMANCE_ADD_CARD: "Add Card",
        CREATE_DASHBOARD_PERFORMANCE_EDIT_CARD: "Edit Card",
        CREATE_DASHBOARD_PERFORMANCE_MAIN_METRIC: "Main Metric",
        CREATE_DASHBOARD_PERFORMANCE_SELECT_THE_MAIN_METRIC:
          "Select the Main Metric",
        CREATE_DASHBOARD_PERFORMANCE_SELECT_THE_CARD_TYPE:
          "Select the Card Type",
        CREATE_DASHBOARD_PERFORMANCE_EDIT_DASHBOARD_TITLE:
          "Edit Dashboard Title",
        CREATE_DASHBOARD_PERFORMANCE_CONFIRM_CHANGES: "Confirm Changes",
        CREATE_DASHBOARD_DASHBOARD_TITLE_CANT_BE_EMPTY:
          "Dashboard Title Can't be Empty",
        CREATE_DASHBOARD_DASHBOARD_ICON_COLOR_CANT_BE_EMPTY:
          "Dashboard Icon Color Can't be Empty",
        CREATE_DASHBOARD_DASHBOARD_SHORT_DESCRIPTION_CANT_BE_EMPTY:
          "Dashboard Short Description Can't be Empty",
        CREATE_DASHBOARD_THERE_ARE_UNSELECTED_STAGES:
          "There are Unselected Stages, all of them need to be selected",
        CREATE_DASHBOARD_DASHBOARD_MUST_HAVE_AT_LEAST_ONE_STAGE:
          "Dashboard Must have At Least One Stage",
        CREATE_DASHBOARD_DASHBOARD_STAGES_CANT_BE_EMPTY:
          "Dashboard Stages can't be empty",
        CREATE_DASHBOARD_THERE_ARE_CARDS_WITH_INCOMPLETE_INFORMATION:
          "There are Cards with Incomplete information, all of them need to be fulfilled",
        CREATE_DASHBOARD_THERE_ARE_FIELDS_INCOMPLETE_INFORMATION:
          "There are Fields with Incomplete information, you must fulfill them",
        CREATE_DASHBOARD_CARDS_MUST_HAVE_AT_LEAST_ONE_METRIC:
          "Cards Must have at least one Metric",
        CREATE_DASHBOARD_METRICS_MUST_HAVE_AT_LEAST_ONE_VIEW:
          "Metrics Must have at least one View",
        CREATE_DASHBOARD_METRICS_ITS_REQUIRED_TO_SELECT_THE_STAGE_BEFORE_ADDING_A_CARD:
          "It's required to select the stage before adding a card",

        METRICS_MODAL_METRICS_MODAL: "Metrics Modal",
        METRICS_MODAL_THERE_ARENT_METRICS_REGISTERED_FOR_THIS_CARD:
          "There aren't Metrics Registered for This Card",
        METRICS_MODAL_THERE_ARENT_VIEWS_REGISTERED_FOR_THIS_METRIC:
          "There aren't Views Registered for this Metric",
        METRICS_MODAL_DASHBOARD_WITHOUT_TITLE: "Dashboard without Title",
        METRICS_MODAL_CARD_WITHOUT_TITLE: "Card without Title",
        METRICS_MODAL_SELECTED_METRICS: "Selected Metrics",
        METRICS_MODAL_SELECT_METRICS: "Select Metrics",
        METRICS_MODAL_SELECT_DESIRED_METRICS: "Select Desired Metrics",
        METRICS_MODAL_THERE_ARENT_ANY_METRICS_TO_BE_SELECTED:
          "There aren't any Metrics to be Selected",
      },
    };
  };

  translate = (keyword) => {
    let translation = this[this.type][this.language][keyword];
    translation = translation || this["general"][this.language][keyword];
    return translation || keyword;
  };
}

export default new Translator().translate;
