import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { isAuthenticated } from "./components/Auth/AuthConfig";

import Login from "./components/Auth/Login";
import Logout from "./components/Auth/Logout";

import translate from "./components/Helpers/Translator";

import ChooseCompany from "./components/Home/ChooseCompany";
import ChooseBusiness from "./components/Home/ChooseBusiness";

import DashboardHome from "./components/Dashboard/DashboardHome";
import DashboardLeads from "./components/Dashboard/DashboardLeads";
import DashboardBusiness from "./components/Dashboard/DashboardBusiness";
import DashboardAttendances from "./components/Dashboard/DashboardAttendances";

import LeadsList from "./components/Pages/Leads/LeadsList";
import LeadsFeedbacks from "./components/Pages/Leads/LeadsFeedbacks";
import LeadsConversion from "./components/Pages/Leads/LeadsConversion";
import LeadsMidia from "./components/Pages/Leads/LeadsMidia";
import LeadData from "./components/Pages/Leads/LeadData";

import SuspectList from "./components/Pages/Suspects/SuspectList";

import HomeIntegration from "./components/Pages/Integration/HomeIntegration";

import AdminCompany from "./components/Pages/Admin/AdminCompany";
import AdminBusiness from "./components/Pages/Admin/AdminBusiness";
import AdminBusinessEdit from "./components/Pages/Admin/AdminBusinessEdit";

import AdminUsers from "./components/Pages/Admin/AdminUsers";

import AttendanceRoom from "./components/Pages/Attendance/AttendanceRoom";

import Logs from "./components/Pages/Logs/Logs";

import Qualification from "./components/Pages/Commercial/Qualification/Qualification";

import CommercialEmksa from "./components/Pages/Commercial/Emksa/CommercialEmksa";

import Brokers from "./components/Pages/Commercial/Management/Brokers";
import Agents from "./components/Pages/Commercial/Management/Agents";

import Config from "./components/Pages/Config/Config";

import Maintenance from "./components/Errors/Maintenance";
import Page404 from "./components/Errors/Page404";

import Journey from "./components/Pages/Journey/Journey";
import JourneyDesk from "./components/Pages/Journey/JourneyDesk";

import Contents from "./components/Pages/Contents/Contents";
import LandingPageList from "./components/Pages/Contents/Components/LandingPageList";
import EmailMarketingList from "./components/Pages/Contents/Components/EmailMarketingList";
import TriggeredEmails from "./components/Pages/Contents/Components/TriggeredEmails";
import TriggeredSMS from "./components/Pages/Contents/Components/TriggeredSMS";
import EmailMarketingPreview from "./components/Pages/Contents/Components/EmailMarketingPreview";
import BannerList from "./components/Pages/Contents/Components/BannerList";
import SMSList from "./components/Pages/Contents/Components/SMSList";

import Tools from "./components/Pages/Tools/Tools";
import LinkBuilder from "./components/Pages/Tools/LinkBuilder";
import LeadPlus from "./components/Pages/Tools/LeadPlus";

import SegmentationList from "./components/Pages/Segmentation/SegmentationList";

import HomeQualification from "./components/Pages/Qualification/HomeQualification";

import Teste from "./components/Debug/Teste";
import AttendanceConfig from "./components/Pages/Attendance/AttendanceConfig";

import DashboardPerformance from "./components/Pages/DashboardPerformance/DashboardPerformance.jsx";
import ChooseDashboardPerformance from "./components/Pages/DashboardPerformance/ChooseDashboardPerformance";
import UpdateDashboardPerformance from "./components/Pages/DashboardPerformance/UpdateDashboardPerformance.jsx";

import ReportAttendance from "./components/Pages/Reports/ReportsAttendance";

import Reverse from "./components/Pages/Orders/Reverse/Reverse";

import BigLogger from "./components/Pages/Data/Logger/BigLogger";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} {...rest} translate={translate} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Login} />
      <PrivateRoute path="/choose_company" component={ChooseCompany} />
      <PrivateRoute path="/choose_business" component={ChooseBusiness} />
      <PrivateRoute path="/admin_company" component={AdminCompany} />
      <PrivateRoute path="/admin_business" component={AdminBusiness} />
      <PrivateRoute path="/admin_business_edit" component={AdminBusinessEdit} />
      <PrivateRoute path="/admin_users" component={AdminUsers} />
      <PrivateRoute path="/attendance_room" component={AttendanceRoom} />
      <PrivateRoute path="/attendance_config" component={AttendanceConfig} />
      <PrivateRoute path="/logs" component={Logs} />
      <PrivateRoute path="/dashboard" component={DashboardHome} />
      <PrivateRoute path="/leads" component={DashboardLeads} />
      <PrivateRoute path="/leads_list" component={LeadsList} />
      <PrivateRoute path="/leads_feedback" component={LeadsFeedbacks} />
      <PrivateRoute path="/leads_conversion" component={LeadsConversion} />
      <PrivateRoute path="/leads_midia" component={LeadsMidia} />
      <PrivateRoute path="/lead_data" component={LeadData} />
      <PrivateRoute path="/dashboard_business" component={DashboardBusiness} />
      <PrivateRoute
        path="/dashboard_attendances"
        component={DashboardAttendances}
      />
      <PrivateRoute path="/suspects_list" component={SuspectList} />
      <PrivateRoute path="/realtime" component={Maintenance} />
      <PrivateRoute path="/integration" component={HomeIntegration} />
      <PrivateRoute path="/content" component={Contents} />
      <PrivateRoute path="/landing_page_list" component={LandingPageList} />
      <PrivateRoute
        path="/email_marketing_list"
        component={EmailMarketingList}
      />
      <PrivateRoute path="/triggered_emails" component={TriggeredEmails} />
      <PrivateRoute path="/triggered_sms" component={TriggeredSMS} />
      <PrivateRoute
        path="/email_marketing_preview"
        component={EmailMarketingPreview}
      />
      <PrivateRoute path="/banner_list" component={BannerList} />
      <PrivateRoute path="/sms_list" component={SMSList} />
      <PrivateRoute path="/journey" component={Journey} />
      <PrivateRoute path="/journey_desk" component={JourneyDesk} />
      <PrivateRoute path="/tools" component={Tools} />
      <PrivateRoute path="/tools_link_builder" component={LinkBuilder} />
      <PrivateRoute path="/tools_lead_plus" component={LeadPlus} />
      <PrivateRoute path="/bigdata" component={Maintenance} />
      <PrivateRoute
        path="/commercial_qualification"
        component={Qualification}
      />
      <PrivateRoute path="/commercial_emksa" component={CommercialEmksa} />
      <PrivateRoute path="/commercial_management" component={Brokers} />
      <PrivateRoute path="/commercial_management_agents" component={Agents} />
      <PrivateRoute path="/qualification_leads" component={HomeQualification} />
      <PrivateRoute path="/social_media_facebook" component={Maintenance} />
      <PrivateRoute path="/targets" component={SegmentationList} />
      <PrivateRoute path="/config" component={Config} />
      <PrivateRoute path="/support" component={Maintenance} />
      <PrivateRoute path="/debug" component={Teste} />
      <PrivateRoute
        path="/dashboard_performance"
        component={DashboardPerformance}
      />
      <PrivateRoute
        path="/choose_dashboard_performance"
        component={ChooseDashboardPerformance}
      />
      <PrivateRoute
        path="/update_dashboard_performance"
        component={UpdateDashboardPerformance}
      />
      <PrivateRoute path="/report/attendance" component={ReportAttendance} />
      <PrivateRoute path="/orders/reverse" component={Reverse} />
      <PrivateRoute path="/data/mapping" component={BigLogger} />
      <Route path="/logout" component={Logout} />
      <Route component={Page404} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
