import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import api from "../Services/Api";
import { setToken, setCompany, setUserCompanies } from "../Auth/AuthConfig";

export class ChooseCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: [],
      company_id: null,
      company_name: null,
      is_loading: true,
    };
  }

  componentDidMount() {
    this.loadCompanies();
  }

  loadCompanies = async () => {
    this.setState({ is_loading: true });
    let response = await api.get("/companies");
    console.log(response);
    if (response && response.data && response.data.length) {
      const companies = response.data;
      setUserCompanies(companies);
      if (companies.length === 1) {
        let company = companies[0];
        this.redirectToBusiness({
          company_id: company.id,
          company_name: company.name,
        });
      } else {
        this.setState({ companies, is_loading: false });
      }
    }
  };

  handleClick = async (e) => {
    e.preventDefault();
    this.redirectToBusiness({
      company_id: e.currentTarget.dataset.company_id,
      company_name: e.currentTarget.dataset.company_name,
    });
  };

  redirectToBusiness = async (company) => {
    const { company_id, company_name } = company;
    const response = await api.post("/company/setid", { company_id });
    if (response.data.success) {
      setToken(response.data.token);
      setCompany(company_id, company_name);
      this.props.history.push("/choose_business");

      // Simular o clique do Component Link do react-router
    }
  };

  render() {
    return (
      <div id="content" className="app-content box-shadow-0 dark" role="main">
        {this.state.companies &&
        this.state.companies.length &&
        !this.state.is_loading ? (
          <React.Fragment>
            <div className="menu-admin-init col-md-12 col-sm-12 col-lg-12 box-shadow dk rounded-top">
              <ul>
                <li>
                  <a href="/admin_company">Admin Incorporadoras</a>
                </li>
              </ul>
            </div>

            <div className="content-main row row-no-margin" id="content-main">
              <div
                className="row no-gutters col-sm-12 col-md-12 col-lg-12"
                style={{ marginBottom: "70px" }}
              >
                <div className="col-md-12 col-sm-12 col-lg-12 box-shadow dk rounded-top mb20">
                  <div className="padding">
                    <h1 className="text-center fwhite">
                      Escolha a Incorporadora
                    </h1>
                    <h5 className="text-muted text-center f300">
                      Cada incorporadora possui um conjunto de empreendimentos,
                      com suas respectivas informações.
                    </h5>
                  </div>
                </div>

                <React.Fragment>
                  {this.state.companies.map((company) => (
                    <div
                      key={company.id}
                      className="escolhaEmpreendimento col-sm-12 col-md-4 col-lg-4"
                    >
                      <div className="padding no-box-shadow">
                        <div className="white box-shadow">
                          <div className="item">
                            <div className="item-bg height250 rounded-top">
                              <a href="/">
                                <img src={company.profile_picture} alt="." />
                              </a>
                            </div>
                            <div className="height250 pos-rlt text-center"></div>
                          </div>
                          <div className="p-3 text-center">
                            <a
                              href="/"
                              data-company_id={company.id}
                              data-company_name={company.name}
                              onClick={this.handleClick}
                              className="text-md mt-1 d-block f700"
                            >
                              {company.name}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </React.Fragment>
              </div>
            </div>
          </React.Fragment>
        ) : this.state.is_loading ? (
          <p className="p-2 lead">
            <i className="fa fa-spinner fa-spin"></i>&nbsp; Carregando...
          </p>
        ) : (
          <p className="p-2 lead">
            <i className="fa fa-spinner fa-spin"></i>&nbsp; Não há
            Empreendimentos disponíveis
          </p>
        )}
      </div>
    );
  }
}

export default withRouter(ChooseCompany);
