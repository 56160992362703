import $ from 'jquery'
import 'jquery-mask-plugin'

export const maskPhone = (element, value) => {
    let maskBehavior = value.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00000'
    element.value = value
    $(element).mask(maskBehavior)
    if (value.length > 15) return false
    return value
}

export const maskPostalCode = (element, value) => {
    let maskBehavior = '00000-000'
    element.value = value
    $(element).mask(maskBehavior)
    if (value.length > 9) return false
    return value
}


export const unmaskPhone = (value) => value.replace(/\D/g, '')

export const unmaskPostalCode = (value) => value.replace("-", "")