import React, {Component} from 'react';

import PeriodBoxTool from '../../Structure/PeriodBoxTool';

export class EcommerceTotalSales extends Component{

    constructor(props){
        super(props);
        this.cardCustom = false;
        this.props.fields.periodName = this.props.periodName
    }

    handleChange = (periodName, values) => {
        this.cardCustom = true
        this.props.fields.value = values.value;
        this.props.fields.periodName = periodName
        this.forceUpdate();
    }

    render(){
        (this.cardCustom === false)?(
            this.props.fields.periodName = this.props.periodName
        ):(
            this.cardCustom = false
        )
        return(
            <div className="col-6 col-sm-6 col-md-3">
                <div className="padding position10">
                    <div className="d-flex">
                        <p className="uppercase text-muted f10">Total de Vendas</p>
                    </div>
                    <i className="fa fa-users text-center f32 text-success text-shadow width100 mt20"></i>
                    <div className="py-3 text-center text-lg text-success f700 f32 text-shadow">
                        {this.props.fields.value}
                    </div>
                    <div className="d-flex width100 text-center">
                        <span className="flex f16 width100 fwhite">Vendas</span> 
                    </div>
                    <div className="d-flex width100 text-center">
                        <span className="flex f10 width100">
                           ({this.props.fields.periodName})
                        </span> 
                    </div>
                </div>
                <PeriodBoxTool onChange={this.handleChange} type={this.props.type}/>
            </div>
        )
    }
}

export default EcommerceTotalSales;