import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

export class FooterMain extends Component{
    render(){
        return(
            <div className="row no-gutters footer">
                <div className="col-12 content-footer white d-block" id="content-footer">
                    <div className=" d-flex p-3">
                        <span className="text-sm text-muted flex">&copy; Copyright. Buylitics.</span>
                        <div className="text-sm text-muted">Version 1.0.0</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(FooterMain);