import React, { Component } from 'react'
import Chart from 'chart.js'
import {app, hexToRGB} from '../../../../Structure/Configs/Theme'

export default class MonthlyLineChart extends Component{

    constructor(props){
        super(props)
    }

    componentDidMount(){
        const myChart = document.getElementById('chart_monthly_line_chart').getContext('2d')

        new Chart(myChart, {
            type: "line",
            data: {
                labels: this.props.viewValues.label,
                datasets: [
                    {
                        label: this.props.viewName,
                        type: 'line',
                        data: this.props.viewValues.values,
                        fill: true,
                        backgroundColor: hexToRGB(app.color.primary, 0.2),
                        borderColor: hexToRGB(app.color.primary, 1),
                        borderWidth: 2,
                        borderJoinStyle: 'miter',
                        pointBorderColor: hexToRGB(app.color.primary, 1),
                        pointBackgroundColor: '#fff',
                        pointBorderWidth: 2,
                        pointHoverRadius: 2,
                        pointHoverBackgroundColor: hexToRGB(app.color.primary, 1),
                        pointHoverBorderColor: '#fff',
                        pointHoverBorderWidth: 1,
                        pointRadius: 3
                    }
                ]
            },
            options: {}
        })

    }

    render() {
        return (

            <div className="col-12">
                <div className="padding bg-blur_w position10">
                    <div className="d-flex">
                        <p className="uppercase text-muted f10">{this.props.viewName}</p>
                    </div>
                    <canvas id="chart_monthly_line_chart" width={624} height={300} className="chartjs-render-monitor position10"/>
                </div>
            </div>
        )
    }
    
}


