import React, { Component } from 'react';

export class WithHigherScoresLeadList extends Component {
    render() {
        return (
            <div className="col-md-6">
                <div className="padding">
                    <span className="badge danger float-right">{this.props.fields.count_leads}</span>
                    <h6 className="mb-3"><i className="fa fa-line-chart"></i> Últimas com maior score</h6>
                    <div className="streamline streamline-dotted" id="countMoreScoreLeads">
                        {
                            this.props.fields.values.map(
                                (value, i) => 
                                    <div key={i} className="sl-item  b-">
                                        <div className="sl-content">
                                            <span className="sl-date text-muted">{value.hourly}</span>
                                            <div>
                                                <a href="/" className="text-primary">{value.name_lead}</a>&nbsp;
                                                mostrou interesse em&nbsp;
                                                <span className="text-primary">{value.business}</span>&nbsp;
                                                com <span className="text-primary">{value.score_lead} de score</span>&nbsp;
                                            </div>
                                        </div>
                                    </div>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default WithHigherScoresLeadList;