import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Chart from "chart.js";
import { configChart, optionsChart } from "./_Configs/ChartConfigs";

import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-bs4";
import "datatables.net-responsive";
import "datatables.net-responsive-bs4";

import { ajaxUrl, ajaxToken } from "../../Services/Ajax";

import api from "../../Services/Api";

class StageMarket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableLoad: null,
    };
  }

  renderAddinfo = (data) => {
    if (data.info) {
      let table =
        '<table class="table-striped table-bordered dt-responsive nowrap dataTable no-footer dtr-inline" style="width: 100%; padding-left:50px;">';

      for (const [key, value] of Object.entries(data.info)) {
        table += "<tr>";
        for (const k in value) {
          table += "<td>" + value[k] + "</td>";
        }
        table += "</tr>";
      }

      table += "</table>";

      return table;
    } else {
      return "";
    }
  };

  componentDidMount = async () => {
    await api
      .get("/dashboard/funnel/marketing/market/chart/monthly")
      .then(async (response) => {
        const myChartMonthly = document
          .getElementById("stage_market_chart_monthly")
          .getContext("2d");
        new Chart(myChartMonthly, {
          type: "bar",
          data: {
            labels: response.data.monthly,
            datasets: [
              configChart(
                "Total Identificado",
                "primary",
                response.data.values.total_identified,
                true
              ),
              configChart(
                "Interesse",
                "accent",
                response.data.values.interest,
                true
              ),
              configChart("Jornada", "warn", response.data.values.journey),
            ],
          },
          options: optionsChart(),
        });
      });

    await api
      .get("/dashboard/funnel/marketing/market/chart/daily")
      .then(async (response) => {
        const myChartDaily = document
          .getElementById("stage_market_chart_daily")
          .getContext("2d");
        new Chart(myChartDaily, {
          type: "bar",
          data: {
            labels: response.data.daily,
            datasets: [
              configChart(
                "Total Identificado",
                "primary",
                response.data.values.total_identified,
                true
              ),
              configChart(
                "Interesse",
                "accent",
                response.data.values.interest,
                true
              ),
              configChart("Jornada", "warn", response.data.values.journey),
            ],
          },
          options: optionsChart(),
        });
      });

    let tableLoad = this.state.tableLoad;
    tableLoad = $("#stage_market_table").DataTable({
      searching: false,
      paging: false,
      info: false,
      responsive: true,
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.13/i18n/Portuguese-Brasil.json",
      },
      ajax: {
        url: ajaxUrl("/dashboard/funnel/marketing/market/table"),
        type: "GET",
        beforeSend: function (request) {
          request.setRequestHeader("Authorization", "Bearer " + ajaxToken());
        },
      },
      columns: [
        {
          className: "dt-control",
          orderable: false,
          data: null,
          defaultContent:
            "<div class='text-center'><i class='fa fa-plus-circle fa-2x text-success'></i></div>",
        },
        { data: "total" },
        { data: "value_interest" },
        { data: "journey" },
        { data: "date" },
      ],
      order: [[3, "asc"]],
    });

    $("#stage_market_table tbody").on(
      "click",
      "td.dt-control",
      function (e) {
        e.preventDefault();
        var tr = $(e.target).closest("tr");
        var row = tableLoad.row(tr);

        if (row.child.isShown()) {
          row.child.hide();
          tr.removeClass("shown");

          tr.find("svg").attr("data-icon", "plus-circle");
        } else {
          row.child(this.renderAddinfo(row.data())).show();
          tr.addClass("shown");
          tr.find("svg").attr("data-icon", "minus-circle");
        }
      }.bind(this)
    );

    console.log(tableLoad);
  };

  handleClose = (e) => {
    e.preventDefault();
    this.props.onChange("close");
  };

  render() {
    return (
      <div className="d-flex flex-column h-100 bg-blur_w2">
        <div className="p-3 b-b d-flex no-shrink green text-center position10">
          <h5 className="h5 m-0 text-center width100 line-height-38px">
            <i className="fa fa-clone"></i> Mercado
          </h5>
          <button
            onClick={this.handleClose}
            className="btn btn-transparent ml-auto"
          >
            <span className="text-lg l-h-1x">×</span>
          </button>
        </div>
        <div className="row position10" style={{ padding: "15px" }}>
          <div className="col-12 col-md-6">
            <div className="padding bg-blur_w position10">
              <div className="d-flex">
                <p className="uppercase text-muted f10">Grafico Mensal</p>
              </div>
              <canvas
                id="stage_market_chart_monthly"
                width={624}
                height={300}
                className="chartjs-render-monitor position10"
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="padding bg-blur_w position10">
              <div className="d-flex">
                <p className="uppercase text-muted f10">Grafico Diário</p>
              </div>
              <canvas
                id="stage_market_chart_daily"
                width={624}
                height={300}
                className="chartjs-render-monitor position10"
              />
            </div>
          </div>
          <div className="col-12 col-md-12" style={{ overflowY: "scroll" }}>
            <div className="bootstrap-table">
              <div className="fixed-table-container">
                <div className="fixed-table-body">
                  <table
                    id="stage_market_table"
                    className="table table-striped table-bordered dt-responsive nowrap"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr className="text-left">
                        <th></th>
                        <th>Total Identificado</th>
                        <th>Interesse</th>
                        <th>Jornada de Compra</th>
                        <th>Data Captura</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(StageMarket);
