import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'
import { v4 } from 'uuid'

//import $ from 'jquery';
import 'jquery-mask-plugin';

import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4'

//import {ajaxUrl, ajaxToken} from '../../../Services/Ajax';
import api from '../../../Services/Api';

import Modal from 'react-bootstrap/Modal';

class BusinessConfigs extends Component {

    constructor(props){
        super(props);
        this.state = {
            modalResponseShow: false,
            targets:[],
            medias: [],
            formQualification: {
                qualification_active: null,
                qualification_management: null
            },
            formLeadScore: {
                segmentation:[],
                media:[],
            },
            formSuspectScore: {
                segmentation:[],
                media:[],
                interaction_time: []
            },
        }
        this.handleCloseModalResponse =  this.handleCloseModalResponse.bind(this)
        this.formSubmitQualificationConfig =  this.formSubmitQualificationConfig.bind(this)
        this.changePublics =  this.changePublics.bind(this)

        this.handleFormLeadScoreSubmit = this.handleFormLeadScoreSubmit.bind(this)
        this.addSegmentationLeadScore =  this.addSegmentationLeadScore.bind(this)
        this.changeSegmentationLeadScoreByIndex = this.changeSegmentationLeadScoreByIndex.bind(this)
        this.addMediaLeadScore =  this.addMediaLeadScore.bind(this)
        this.changeMediaLeadScoreByIndex = this.changeMediaLeadScoreByIndex.bind(this)

        
        this.handleFormSuspectScoreSubmit = this.handleFormSuspectScoreSubmit.bind(this)
        this.addSegmentationSuspectScore =  this.addSegmentationSuspectScore.bind(this)
        this.changeSegmentationSuspectScoreByIndex = this.changeSegmentationSuspectScoreByIndex.bind(this)
        this.addMediaSuspectScore =  this.addMediaSuspectScore.bind(this)
        this.changeMediaSuspectScoreByIndex = this.changeMediaSuspectScoreByIndex.bind(this)
        this.addInteractionTimeSuspectScore =  this.addInteractionTimeSuspectScore.bind(this)
        this.changeInteractionTimeSuspectScoreByIndex = this.changeInteractionTimeSuspectScoreByIndex.bind(this)
        this.translate = this.props.translate
    }

    getUniqueId(){
        return v4() 
    }
    componentDidMount = async () => {
        await api.get("/business/" + this.props.idBusiness + "/qualification/get").then(async response => {
            this.setState({
                formQualification: response.data
            })
        })
        this.loadTargets()
        this.loadMedias()

        this.forceUpdate()

    }

    componentDidUpdate() {
        this.disableLeadForm()
        this.disableSuspectForm()
    }

    disableLeadForm(){
        let disabled = true
        let {segmentation, media} = this.state.formLeadScore
        if(segmentation.length > 0 ){
            let checkSegmentationIsEmpty = segmentation.filter((segmentationFields)=> this.checkThereAreEmptyFields(segmentationFields)).length > 0 ? true : false
            disabled = !checkSegmentationIsEmpty ? false : true
        }
        if(media.length > 0){
            let checkMediaIsEmpty = media.filter((mediaFields)=> this.checkThereAreEmptyFields(mediaFields)).length > 0 ? true : false
            disabled = !checkMediaIsEmpty ? false : true
        } 
        document.getElementById("submit_button_lead_score").disabled = disabled
    }

    disableSuspectForm(){
        let disabled = true
        let {segmentation, media, interaction_time} = this.state.formSuspectScore
        if(segmentation.length > 0 ){
            let checkSegmentationIsEmpty = segmentation.filter((segmentationFields)=> this.checkThereAreEmptyFields(segmentationFields)).length > 0 ? true : false
            disabled = !checkSegmentationIsEmpty ? false : true
        }
        if(media.length > 0){
            let checkMediaIsEmpty = media.filter((mediaFields)=> this.checkThereAreEmptyFields(mediaFields)).length > 0 ? true : false
            disabled = !checkMediaIsEmpty ? false : true
        }
        if(interaction_time.length > 0){
            console.log(interaction_time)
            let checkInteractionTimeIsEmpty = interaction_time.filter((interactionTimeFields)=> this.checkThereAreEmptyFields(interactionTimeFields)).length > 0 ? true : false
            disabled = !checkInteractionTimeIsEmpty ? false : true
        } 
        document.getElementById("submit_button_suspect_score").disabled = disabled
        
    }

    checkThereAreEmptyFields(fields){
        let isEmpty = false
        Object.values(fields).forEach((value)=>{
            if(typeof value !== Object) {
                if(value === null || value === ""){
                    isEmpty = true
                }
                
            }
        })
        return isEmpty
    }
    async loadTargets(){
        try{
            const response = await api.get(`/business/${this.props.idBusiness}/segmentation/json`);
            this.setState({
                targets: response.data
            })
        }catch(err){
            console.log(err);
        }
    }

    async loadMedias(){
        try{
            const response = await api.get(`/business/${this.props.idBusiness}/media/json`);
            this.setState({
                medias: response.data
            })
        }catch(err){
            console.log(err);
        }
    }

    handleCloseModalResponse(e = null){
        if (e) e.preventDefault()
        this.setState({modalResponseShow: false})
    }

    async formSubmitQualificationConfig(e){
        e.preventDefault();
        this.setState({ msgResponseShow: this.translate("BUSINESS_CONFIG_CONFIG_WAIT_STATUS"), modalResponseShow: true });
        const data = this.state.formQualification;
        try{
            const response = await api.post("/business/" + this.props.idBusiness + "/qualification/register", data);
            if(response.data.success === true){
                this.setState({msgResponseShow: this.translate("BUSINESS_CONFIG_CONFIG_SUCCESS_STATUS")});
            }else{
                this.setState({msgResponseShow: response.data.msg});
            }
        }catch(err){
            this.setState({msgResponseShow: err});
        }
    }

    async handleFormLeadScoreSubmit(e){
        e.preventDefault()
        this.setState({ msgResponseShow: this.translate("BUSINESS_CONFIG_LEAD_SCORE_WAIT_STATUS"), modalResponseShow: true });
        let data = this.state.formLeadScore;
        data["segmentation"] = this.removeNCFromSegmentationPublicCode(data["segmentation"])
        /*try{
            const response = await api.post(`/business/${this.props.idBusiness}/qualification/register`, data);
            if(response.data.success === true){
                this.setState({msgResponseShow: this.translate("BUSINESS_CONFIG_LEAD_SCORE_SUCCESS_STATUS")});
            }else{
                this.setState({msgResponseShow: response.data.msg});
            }
        }catch(err){
            this.setState({msgResponseShow: err});
        }*/
        
    }

    removeNCFromSegmentationPublicCode(segmentation){
        segmentation = segmentation.map((element)=>{
            element.public_code = element.public_code === "N/C" ? null : element.public_code
            return element
        })
        return segmentation
    }
    changeSegmentationLeadScoreByIndex(e, i, key){
        const {formLeadScore} = this.state
        let value = e.target.value
        if(key === "segmentation_code"){
            value = this.changePublics(e)

            formLeadScore["segmentation"][i]["publics"] = value.publics
            formLeadScore["segmentation"][i]["segmentation_code"] = value.segmentation_code
            formLeadScore["segmentation"][i]["public_code"] = value.public_code
        } else {
            formLeadScore["segmentation"][i][key] = value
        }
        this.setState({formLeadScore: formLeadScore}) 
    }

    changePublics(e){
        const values = e.target.value === "" ? {code: null, publics: []} : JSON.parse(e.target.value)
        let public_code = values.code !== null && values.publics.length === 0 ? "N/C" : null
        return {segmentation_code: values.code, publics: values.publics, public_code}
    }

    addSegmentationLeadScore(){
        const {formLeadScore} = this.state
        formLeadScore["segmentation"].push({_id:this.getUniqueId(), segmentation_code: null, public_code: null, action: null, score: null, publics:[]})
        this.setState({formLeadScore: formLeadScore}) 
    }
    
    removeElementFromLeadScoreByIndex(elementType, index){
        const {formLeadScore} = this.state
        if(elementType === "segmentation"){
            let segmentation = this.state.formLeadScore["segmentation"].filter((element, i)=>i !== index)
            formLeadScore["segmentation"] = segmentation     
        } else if(elementType === "media"){
            let media = this.state.formLeadScore["media"].filter((element, i)=>i !== index)
            formLeadScore["media"] = media   
        }
        this.setState({formLeadScore: formLeadScore})
    }
    changeMediaLeadScoreByIndex(e, i, key){
        const {formLeadScore} = this.state
        formLeadScore["media"][i][key] = e.target.value
        if (e.target.value === "") formLeadScore["media"][i]["media_code"] = null
        this.setState({formLeadScore: formLeadScore}) 
    }

    addMediaLeadScore(){
        const {formLeadScore} = this.state
        formLeadScore["media"].push({_id:this.getUniqueId(), media_code:null, action: null, score: null})
        this.setState({formLeadScore: formLeadScore}) 
    }


    async handleFormSuspectScoreSubmit(e){
        e.preventDefault()
        this.setState({ msgResponseShow: this.translate("BUSINESS_CONFIG_SUSPECT_SCORE_WAIT_STATUS"), modalResponseShow: true });
        const data = this.state.formSuspectScore;
        data["segmentation"] = this.removeNCFromSegmentationPublicCode(data["segmentation"])
        console.log(data)
        /*try{
            const response = await api.post(`/business/${this.props.idBusiness}/qualification/register`, data);
            if(response.data.success === true){
                this.setState({msgResponseShow: this.translate("BUSINESS_CONFIG_SUSPECT_SCORE_SUCCESS_STATUS")});
            }else{
                this.setState({msgResponseShow: response.data.msg});
            }
        }catch(err){
            this.setState({msgResponseShow: err});
        }*/
        
    }

    changeSegmentationSuspectScoreByIndex(e, i, key){
        const {formSuspectScore} = this.state
        let value = e.target.value
        if(key === "segmentation_code"){
            value = this.changePublics(e)

            formSuspectScore["segmentation"][i]["publics"] = value.publics
            formSuspectScore["segmentation"][i]["segmentation_code"] = value.segmentation_code
            formSuspectScore["segmentation"][i]["public_code"] = value.public_code
        } else {
            formSuspectScore["segmentation"][i][key] = value
        }
        this.setState({formSuspectScore: formSuspectScore}) 
    }

    removeElementFromSuspectScoreByIndex(elementType, index){
        const {formSuspectScore} = this.state
        if(elementType === "segmentation"){
            let segmentation = this.state.formSuspectScore["segmentation"].filter((element, i)=>i !== index)
            formSuspectScore["segmentation"] = segmentation     
        } else if(elementType === "media"){
            let media = this.state.formSuspectScore["media"].filter((element, i)=>i !== index)
            formSuspectScore["media"] = media   
        } else if(elementType ==="interaction_time"){
            let interaction_time = this.state.formSuspectScore["interaction_time"].filter((element, i)=>i !== index)
            formSuspectScore["interaction_time"] = interaction_time 
        }
        this.setState({formSuspectScore: formSuspectScore})
    }

    addSegmentationSuspectScore(){
        const {formSuspectScore} = this.state
        formSuspectScore["segmentation"].push({_id:this.getUniqueId(), segmentation_code: null, public_code: null, action: null, score: null, publics:[]})
        this.setState({formSuspectScore: formSuspectScore}) 
    }

    changeMediaSuspectScoreByIndex(e, i, key){
        const {formSuspectScore} = this.state
        formSuspectScore["media"][i][key] = e.target.value
        if (e.target.value === "") formSuspectScore["media"][i]["media_code"] = null
        this.setState({formSuspectScore: formSuspectScore}) 
    }

    addMediaSuspectScore(){
        const {formSuspectScore} = this.state
        formSuspectScore["media"].push({_id:this.getUniqueId(), media_code:null, action: null, score: null})
        this.setState({formSuspectScore: formSuspectScore}) 
    }

    changeInteractionTimeSuspectScoreByIndex(e, i, key){
        const {formSuspectScore} = this.state
        formSuspectScore["interaction_time"][i][key] = e.target.value
        this.setState({formSuspectScore: formSuspectScore}) 
    }

    addInteractionTimeSuspectScore(){
        const {formSuspectScore} = this.state
        formSuspectScore["interaction_time"].push({_id:this.getUniqueId(), interaction_time_start: null, interaction_time_end: null, action: null, score: null})
        this.setState({formSuspectScore: formSuspectScore}) 
    }

    render() {
        return (
            <React.Fragment>
                <div id="accordion-configs">
                    <div className="card mb-3">
                        <div className="card-header btn-cursor-pointer dark" id="headingQualificationConfig">
                            <h5 className="mb-0" onClick={e=>this.props.toggleCollapse("#collapseQualificationConfig")} aria-expanded="true" aria-controls="collapseQualificationConfig">
                                {this.translate("BUSINESS_CONFIG_QUALIFICATION_QUALIFICATION")}
                                <i className="fa fa-caret-down pull-right"></i>
                            </h5>
                        </div>
                        <div id="collapseQualificationConfig" className="collapse dark" aria-labelledby="headingQualificationConfig" data-parent="#accordion-configs">
                            <div className="card-body">
                                <form onSubmit={this.formSubmitQualificationConfig}>
                                    <div className="row">
                                        <div className="col-12">
                                            <h6>{this.translate("BUSINESS_CONFIG_QUALIFICATION_QUALIFICATION_SYSTEM")}</h6>
                                        </div>
                                        <div className="col-6" style={{lineHeight: '30px'}}>
                                            <input type="radio" onClick={e => this.setState({formQualification: {...this.state.formQualification, qualification_active: false}})} className="mr-1" id="qualification_active_true" name="qualification_active" defaultChecked={!this.state.formQualification.qualification_active}/>
                                            <label htmlFor="qualification_active_true">
                                                {this.translate("BUSINESS_CONFIG_DEACTIVATED")}
                                            </label>
                                        </div>
                                        <div className="col-6">
                                            <input type="radio" onClick={e => this.setState({formQualification: {...this.state.formQualification, qualification_active: true}})} className="mr-1" id="qualification_active_false" name="qualification_active" defaultChecked={this.state.formQualification.qualification_active}/>
                                            <label htmlFor="qualification_active_false">
                                                {this.translate("BUSINESS_CONFIG_ACTIVATED")}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <h6>{this.translate("BUSINESS_CONFIG_QUALIFICATION_QUALIFICATION_MANAGEMENT")}</h6>
                                        </div>
                                        <div className="col-6" style={{lineHeight: '30px'}}>
                                            <input type="radio" onClick={e => this.setState({formQualification: {...this.state.formQualification, qualification_management: false}})} className="mr-1" id="qualification_management_true" name="qualification_management" defaultChecked={!this.state.formQualification.qualification_management}/>
                                            <label htmlFor="qualification_management_true" className="mr-3">
                                                {this.translate("BUSINESS_CONFIG_DEACTIVATED")}
                                            </label>
                                        </div>
                                        <div className="col-6">
                                            <input type="radio" onClick={e => this.setState({formQualification: {...this.state.formQualification, qualification_management: true}})} className="mr-1" id="qualification_management_false" name="qualification_management" defaultChecked={this.state.formQualification.qualification_management}/>
                                            <label htmlFor="qualification_management_false">
                                                {this.translate("BUSINESS_CONFIG_ACTIVATED")}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="box-divider m0 mt20 width100"></div>
                                    <div className="col-sm-12 col-md-12 mt20 mb-4">
                                        <button type="submit" className="btn btn-info px-5 btn-rounded btn-shadow pull-right">
                                            <i className="fa fa-save"></i> {this.translate("BUSINESS_CONFIG_QUALIFICATION_REGISTER_QUALIFICATION")}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    
                    <div className="card mb-3">
                        <div className="card-header btn-cursor-pointer dark" id="headingLeadScoreConfig">
                            <h5 className="mb-0" onClick={e=>this.props.toggleCollapse("#collapseLeadScoreConfig")} aria-expanded="true" aria-controls="collapseLeadScoreConfig">
                                {this.translate("BUSINESS_CONFIG_LEAD_SCORE_LEAD_SCORE")}
                                <i className="fa fa-caret-down pull-right"></i>
                            </h5>
                        </div>
                        <div id="collapseLeadScoreConfig" className="collapse dark" aria-labelledby="headingLeadScoreConfig" data-parent="#accordion-configs">
                            <div className="card-body">
                                <form id="form_lead_score" onSubmit={e=>this.handleFormLeadScoreSubmit(e)}>
                                    <div className="row">
                                        <div className="col-12">
                                            <h6>{this.translate("BUSINESS_CONFIG_LEAD_SCORE_SEGMENTATION")}</h6>
                                        </div>
                                        {this.state.formLeadScore.segmentation.map((data, i)=>
                                            <div key={`${data._id}`} className="row col-12 my-2">
                                                <div className="col-12 col-sm-6 col-md-3" style={{lineHeight: '30px'}}>
                                                    <label htmlFor={`choose_segmentation_lead_score${i}`} className="mr-3">{this.translate("BUSINESS_CONFIG_LEAD_SCORE_SEGMENTATION_CHOOSE_SEGMENTATION")}</label>
                                                    {
                                                        this.state.targets.length?(
                                                            <select onChange={e=>this.changeSegmentationLeadScoreByIndex(e, i, "segmentation_code")} id={`choose_segmentation_lead_score${i}`} className="form-control box-shadow cool-select" required="required">
                                                                <option value="">{this.translate("BUSINESS_CONFIG_LEAD_SCORE_SEGMENTATION_CHOOSE_SEGMENTATION")}</option>
                                                                {
                                                                    this.state.targets.map((data, index) => 
                                                                        (
                                                                            <option value={JSON.stringify({code: data.code, publics: data.public_data})} key={`optionSegmentation${index}`}>{data.name}</option>
                                                                        )
                                                                    )
                                                                }
                                                            </select>
                                                        ):(
                                                            <div>
                                                                {this.translate("BUSINESS_CONFIG_NO_SEGMENTATION_REGISTERED")}
                                                            </div>
                                                        )
                                                    }
                                                    
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-3" style={{lineHeight: '30px'}}>
                                                    <label htmlFor={`choose_public_segmentation_lead_score${i}`} className="mr-3">{this.translate("BUSINESS_CONFIG_LEAD_SCORE_SEGMENTATION_CHOOSE_PUBLIC")}</label>
                                                    {
                                                        data.publics.length?(
                                                            <select onChange={e=>this.changeSegmentationLeadScoreByIndex(e, i, "public_code")} id={`choose_public_segmentation_lead_score${i}`} className="form-control box-shadow cool-select" required="required">
                                                                <option value="">{this.translate("BUSINESS_CONFIG_LEAD_SCORE_SEGMENTATION_CHOOSE_PUBLIC")}</option>
                                                                {
                                                        
                                                                    data.publics.map((anotherData, anotherIndex) => 
                                                                        (
                                                                            <option value={anotherData.public_code} key={anotherIndex}>{anotherData.public_name}</option>
                                                                        )
                                                                    )
                                                                }
                                                            </select>
                                                        ):(
                                                            <div>
                                                                {this.translate("BUSINESS_CONFIG_NO_PUBLIC_REGISTERED")}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-3" style={{lineHeight: '30px'}}>
                                                    <label htmlFor={`choose_action_segmentation_lead_score${i}`} className="mr-3">{this.translate("BUSINESS_CONFIG_LEAD_SCORE_SEGMENTATION_CHOOSE_ACTION")}</label>
                                                    <select onChange={e=>this.changeSegmentationLeadScoreByIndex(e, i, "action")} id={`choose_action_segmentation_lead_score${i}`} className="form-control cool-select">
                                                        <option value="">{this.translate("BUSINESS_CONFIG_LEAD_SCORE_SEGMENTATION_CHOOSE_ACTION")}</option>
                                                        <option value="increase">{this.translate("BUSINESS_CONFIG_INCREASE")}</option>
                                                        <option value="decrease">{this.translate("BUSINESS_CONFIG_DECREASE")}</option>
                                                    </select>
                                                </div>
                                                <div className="col-12 col-sm-5 col-md-2" style={{lineHeight: '30px'}}>
                                                    <label htmlFor={`score_segmentation_lead_score${i}`} className="mr-3">{this.translate("BUSINESS_CONFIG_LEAD_SCORE_SEGMENTATION_PONTUATION_NUMBER")}</label>
                                                    <input onChange={e=>this.changeSegmentationLeadScoreByIndex(e, i, "score")} id={`score_segmentation_lead_score${i}`} type="number" placeholder={this.translate("BUSINESS_CONFIG_LEAD_SCORE_SEGMENTATION_PONTUATION_NUMBER")} min="0" className="form-control"/>
                                                </div>
                                                <div className="col-12 col-sm-1 my-3 my-sm-0 text-center">
                                                    <button onClick={e=>this.removeElementFromLeadScoreByIndex("segmentation", i)} type="button" style={{height: "100%"}} className="btn rounded btn-outline-danger"><i className="fa fa-times-circle"></i></button>
                                                </div>
                                            </div>
                                        )}

                                        <div className="col-sm-12 col-md-12 mt20 mb-4">
                                            <button onClick={this.addSegmentationLeadScore} type="button" className="btn btn-info px-5 btn-rounded btn-shadow">
                                                <i className="fa fa-plus"></i> {this.translate("BUSINESS_CONFIG_LEAD_SCORE_REGISTER_SEGMENTATION")}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="box-divider m0 mt20 width100"></div>
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            <h6>{this.translate("BUSINESS_CONFIG_LEAD_SCORE_MEDIA")}</h6>
                                        </div>
                                        {this.state.formLeadScore.media.map((data, i)=>
                                            <div key={`${data._id}`} className="row col-12">
                                                <div className="col-12 col-sm-4" style={{lineHeight: '30px'}}>
                                                    <label htmlFor={`choose_media_lead_score${i}`} className="mr-3">{this.translate("BUSINESS_CONFIG_LEAD_SCORE_MEDIA_CHOOSE_MEDIA")}</label>
                                                    {
                                                        this.state.medias.length &&(
                                                            <select onChange={e=>this.changeMediaLeadScoreByIndex(e, i, "media_code")} id={`choose_media_lead_score${i}`} className="form-control box-shadow cool-select" required="required">
                                                                <option value="">{this.translate("BUSINESS_CONFIG_LEAD_SCORE_MEDIA_CHOOSE_MEDIA")}</option>
                                                                {
                                                                    this.state.medias.map((anotherData, index) => 
                                                                        (
                                                                            <option value={anotherData.code} key={index}>{anotherData.name}</option>
                                                                        )
                                                                    )
                                                                }
                                                            </select>
                                                        )
                                                    }
                                                    
                                                </div>
                                                <div className="col-12 col-sm-4" style={{lineHeight: '30px'}}>
                                                    <label htmlFor={`choose_action_media_lead_score${i}`} className="mr-3">{this.translate("BUSINESS_CONFIG_LEAD_SCORE_MEDIA_CHOOSE_ACTION")}</label>
                                                    <select onChange={e=>this.changeMediaLeadScoreByIndex(e, i, "action")} id={`choose_action_media_lead_score${i}`} className="form-control cool-select">
                                                        <option value="">{this.translate("BUSINESS_CONFIG_LEAD_SCORE_MEDIA_CHOOSE_ACTION")}</option>
                                                        <option value="increase">{this.translate("BUSINESS_CONFIG_INCREASE")}</option>
                                                        <option value="decrease">{this.translate("BUSINESS_CONFIG_DECREASE")}</option>
                                                    </select>
                                                </div>
                                                <div className="col-12 col-sm-3" style={{lineHeight: '30px'}}>
                                                    <label htmlFor={`score_media_lead_score${i}`} className="mr-3">{this.translate("BUSINESS_CONFIG_LEAD_SCORE_MEDIA_PONTUATION_NUMBER")}</label>
                                                    <input onChange={e=>this.changeMediaLeadScoreByIndex(e, i, "score")} id={`score_media_lead_score${i}`} type="number" placeholder={this.translate("BUSINESS_CONFIG_LEAD_SCORE_MEDIA_PONTUATION_NUMBER")} min="0" className="form-control"/>
                                                </div>
                                                <div className="col-12 col-sm-1 my-3 my-sm-0 text-center">
                                                    <button onClick={e=>this.removeElementFromLeadScoreByIndex("media", i)} type="button" style={{height: "100%"}} className="btn rounded btn-outline-danger"><i className="fa fa-times-circle"></i></button>
                                                </div>
                                            </div>
                                        )}
                                        
                                        <div className="col-sm-12 col-md-12 mt20 mb-4">
                                            <button onClick={this.addMediaLeadScore} type="button" className="btn btn-info px-5 btn-rounded btn-shadow">
                                                <i className="fa fa-plus"></i> {this.translate("BUSINESS_CONFIG_LEAD_SCORE_MEDIA_ADD_MEDIA_RULE")}
                                            </button>
                                        </div>
                                    </div>  
                                    <div className="box-divider m0 mt20 width100"></div>
                                    <div className="col-sm-12 col-md-12 mt20 mb-4">
                                        <button  id="submit_button_lead_score" type="submit" disabled={true} className=" btn btn-info px-5 btn-rounded btn-shadow pull-right">
                                            <i className="fa fa-save"></i> {this.translate("BUSINESS_CONFIG_LEAD_SCORE_MEDIA_REGISTER_LEAD_SCORE")}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header btn-cursor-pointer dark" id="headingSuspectScoreConfig">
                            <h5 className="mb-0" onClick={e=>this.props.toggleCollapse("#collapseSuspectScoreConfig")} aria-expanded="true" aria-controls="collapseSuspectScoreConfig">
                                {this.translate("BUSINESS_CONFIG_SUSPECT_SCORE_SUSPECT_SCORE")}
                                <i className="fa fa-caret-down pull-right"></i>
                            </h5>
                        </div>
                        <div id="collapseSuspectScoreConfig" className="collapse dark" aria-labelledby="headingSuspectScoreConfig" data-parent="#accordion-configs">
                            <div className="card-body">
                                <form id="form_suspect_score" onSubmit={e=>this.handleFormSuspectScoreSubmit(e)}>
                                    <div className="row">
                                        <div className="col-12">
                                            <h6>{this.translate("BUSINESS_CONFIG_SUSPECT_SCORE_SEGMENTATION")}</h6>
                                        </div>
                                        {this.state.formSuspectScore.segmentation.map((data, i)=>
                                            <div key={`suspect${data._id}`} className="row col-12 my-2">
                                                <div className="col-12 col-sm-6 col-md-3" style={{lineHeight: '30px'}}>
                                                    <label htmlFor={`choose_segmentation_suspect_score${i}`} className="mr-3">{this.translate("BUSINESS_CONFIG_SUSPECT_SCORE_CHOOSE_SEGMENTATION")}</label>
                                                    {
                                                        this.state.targets.length?(
                                                            <select onChange={e=>this.changeSegmentationSuspectScoreByIndex(e, i, "segmentation_code")} id={`choose_segmentation_suspect_score${i}`} className="form-control box-shadow cool-select" required="required">
                                                                <option value="">{this.translate("BUSINESS_CONFIG_SUSPECT_SCORE_CHOOSE_SEGMENTATION")}</option>
                                                                {
                                                                    this.state.targets.map((data, index) => 
                                                                        (
                                                                            <option value={JSON.stringify({code: data.code, publics: data.public_data})} key={`optionSuspectSegmentation${index}`}>{data.name}</option>
                                                                        )
                                                                    )
                                                                }
                                                            </select>
                                                        ):(
                                                            <div>
                                                                {this.translate("BUSINESS_CONFIG_NO_SEGMENTATION_REGISTERED")}
                                                            </div>
                                                        )
                                                    }
                                                    
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-3" style={{lineHeight: '30px'}}>
                                                    <label htmlFor={`choose_public_segmentation_suspect_score${i}`} className="mr-3">{this.translate("BUSINESS_CONFIG_SUSPECT_SCORE_SEGMENTATION_CHOOSE_PUBLIC")}</label>
                                                    {
                                                        data.publics.length?(
                                                            <select onChange={e=>this.changeSegmentationSuspectScoreByIndex(e, i, "public_code")} id={`choose_public_segmentation_suspect_score${i}`} className="form-control box-shadow cool-select" required="required">
                                                                <option value="">{this.translate("BUSINESS_CONFIG_SUSPECT_SCORE_SEGMENTATION_CHOOSE_PUBLIC")}</option>
                                                                {
                                                                    data.publics.map((anotherData, anotherIndex) => 
                                                                        (
                                                                            <option value={anotherData.public_code} key={`suspect${anotherIndex}`}>{anotherData.public_name}</option>
                                                                        )
                                                                    )
                                                                }
                                                            </select>
                                                        ):(
                                                            <div>
                                                                {this.translate("BUSINESS_CONFIG_NO_PUBLIC_REGISTERED")}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                
                                                <div className="col-12 col-sm-6 col-md-3" style={{lineHeight: '30px'}}>
                                                    <label htmlFor={`choose_action_segmentation_suspect_score${i}`} className="mr-3">{this.translate("BUSINESS_CONFIG_SUSPECT_SCORE_SEGMENTATION_CHOOSE_ACTION")}</label>
                                                    <select onChange={e=>this.changeSegmentationSuspectScoreByIndex(e, i, "action")} id={`choose_action_segmentation_suspect_score${i}`} className="form-control cool-select">
                                                        <option value="">{this.translate("BUSINESS_CONFIG_SUSPECT_SCORE_SEGMENTATION_CHOOSE_ACTION")}</option>
                                                        <option value="increase">{this.translate("BUSINESS_CONFIG_INCREASE")}</option>
                                                        <option value="decrease">{this.translate("BUSINESS_CONFIG_DECREASE")}</option>
                                                    </select>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-2" style={{lineHeight: '30px'}}>
                                                    <label htmlFor={`score_segmentation_suspect_score${i}`} className="mr-3">{this.translate("BUSINESS_CONFIG_SUSPECT_SCORE_SEGMENTATION_PONTUATION_NUMBER")}</label>
                                                    <input onChange={e=>this.changeSegmentationSuspectScoreByIndex(e, i, "score")} id={`score_segmentation_suspect_score${i}`} type="number" placeholder={this.translate("BUSINESS_CONFIG_SUSPECT_SCORE_SEGMENTATION_PONTUATION_NUMBER")} min="0" className="form-control"/>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-1 my-3 my-md-0 text-center">
                                                    <button onClick={e=>this.removeElementFromSuspectScoreByIndex("segmentation", i)} type="button" style={{height: "100%"}} className="btn rounded btn-outline-danger"><i className="fa fa-times-circle"></i></button>
                                                </div>
                                            </div>
                                        )}

                                        <div className="col-sm-12 col-md-12 mt20 mb-4">
                                            <button onClick={this.addSegmentationSuspectScore} type="button" className="btn btn-info px-5 btn-rounded btn-shadow">
                                                <i className="fa fa-plus"></i> {this.translate("BUSINESS_CONFIG_SUSPECT_SCORE_SEGMENTATION_ADD_SEGMENTATION_RULE")}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="box-divider m0 mt20 width100"></div>
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            <h6>{this.translate("BUSINESS_CONFIG_SUSPECT_SCORE_MEDIA_MEDIA")}</h6>
                                        </div>
                                        {this.state.formSuspectScore.media.map((data, i)=>
                                            <div key={`${data._id}`} className="row col-12">
                                                <div className="col-12 col-sm-4" style={{lineHeight: '30px'}}>
                                                    <label htmlFor={`choose_media_suspect_score${i}`} className="mr-3">{this.translate("BUSINESS_CONFIG_SUSPECT_SCORE_MEDIA_CHOOSE_MEDIA")}</label>
                                                    {
                                                        this.state.medias.length &&(
                                                            <select onChange={e=>this.changeMediaSuspectScoreByIndex(e, i, "media_code")} id={`choose_media_suspect_score${i}`} className="form-control box-shadow cool-select" required="required">
                                                                <option value="">{this.translate("BUSINESS_CONFIG_SUSPECT_SCORE_MEDIA_CHOOSE_MEDIA")}</option>
                                                                {
                                                                    this.state.medias.map((anotherData, index) => 
                                                                        (
                                                                            <option value={anotherData.code} key={index}>{anotherData.name}</option>
                                                                        )
                                                                    )
                                                                }
                                                            </select>
                                                        )
                                                    }
                                                    
                                                </div>
                                                <div className="col-12 col-sm-4" style={{lineHeight: '30px'}}>
                                                    <label htmlFor={`choose_action_media_suspect_score${i}`} className="mr-3">{this.translate("BUSINESS_CONFIG_SUSPECT_SCORE_MEDIA_CHOOSE_ACTION")}</label>
                                                    <select onChange={e=>this.changeMediaSuspectScoreByIndex(e, i, "action")} id={`choose_action_media_suspect_score${i}`} className="form-control cool-select">
                                                        <option value="">{this.translate("BUSINESS_CONFIG_SUSPECT_SCORE_MEDIA_CHOOSE_ACTION")}</option>
                                                        <option value="increase">{this.translate("BUSINESS_CONFIG_INCREASE")}</option>
                                                        <option value="decrease">{this.translate("BUSINESS_CONFIG_INCREASE")}</option>
                                                    </select>
                                                </div>
                                                <div className="col-12 col-sm-3" style={{lineHeight: '30px'}}>
                                                    <label htmlFor={`score_media_suspect_score${i}`} className="mr-3">{this.translate("BUSINESS_CONFIG_SUSPECT_SCORE_MEDIA_PONTUATION_NUMBER")}</label>
                                                    <input onChange={e=>this.changeMediaSuspectScoreByIndex(e, i, "score")} id={`score_media_suspect_score${i}`} type="number" placeholder={this.translate("BUSINESS_CONFIG_SUSPECT_SCORE_MEDIA_PONTUATION_NUMBER")} min="0" className="form-control"/>
                                                </div>
                                                <div className="col-12 col-sm-1 my-3 my-sm-0 text-center">
                                                    <button onClick={e=>this.removeElementFromSuspectScoreByIndex("media", i)} type="button" style={{height: "100%"}} className="btn rounded btn-outline-danger"><i className="fa fa-times-circle"></i></button>
                                                </div>
                                            </div>
                                        )}
                                        
                                        <div className="col-sm-12 col-md-12 mt20 mb-4">
                                            <button onClick={this.addMediaSuspectScore} type="button" className="btn btn-info px-5 btn-rounded btn-shadow">
                                                <i className="fa fa-plus"></i> {this.translate("BUSINESS_CONFIG_SUSPECT_SCORE_MEDIA_ADD_MEDIA_RULE")}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="box-divider m0 mt20 width100"></div>
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            <h6>{this.translate("BUSINESS_CONFIG_INTERACTION_TIME_INTERACTION_TIME")}</h6>
                                        </div>
                                        {this.state.formSuspectScore.interaction_time.map((data, i)=>
                                            <div key={`${data._id}`} className="row col-12">
                                                <div className="col-12 col-sm-6 col-md-4" style={{lineHeight: '30px'}}>
                                                    <label htmlFor={`interaction_time_start${i}`} className="mr-3">{this.translate("BUSINESS_CONFIG_INTERACTION_TIME_INTERACTION_TIME")}</label>
                                                    <div>
                                                    <input onChange={e=>this.changeInteractionTimeSuspectScoreByIndex(e, i, "interaction_time_start")} id={`interaction_time_start${i}`} type="number" placeholder={this.translate("BUSINESS_CONFIG_INTERACTION_TIME_INTERACTION_SECONDS")} min="0" className="form-control col-4 d-inline-block"/>
                                                     &nbsp; a &nbsp; <input onChange={e=>this.changeInteractionTimeSuspectScoreByIndex(e, i, "interaction_time_end")} id={`interaction_time_end${i}`} type="number" placeholder={this.translate("BUSINESS_CONFIG_INTERACTION_TIME_INTERACTION_SECONDS")} min="0" className="form-control col-4 d-inline-block"/>
                                                    </div>
                                                    
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-4" style={{lineHeight: '30px'}}>
                                                    <label htmlFor={`choose_action_interaction_time_suspect_score${i}`} className="mr-3">{this.translate("BUSINESS_CONFIG_INTERACTION_TIME_SUSPECT_SCORE_CHOOSE_ACTION")}</label>
                                                    <select onChange={e=>this.changeInteractionTimeSuspectScoreByIndex(e, i, "action")} id={`choose_action_interaction_time_suspect_score${i}`} className="form-control cool-select">
                                                        <option value="">{this.translate("BUSINESS_CONFIG_INTERACTION_TIME_SUSPECT_SCORE_CHOOSE_ACTION")}</option>
                                                        <option value="increase">{this.translate("BUSINESS_CONFIG_INCREASE")}</option>
                                                        <option value="decrease">{this.translate("BUSINESS_CONFIG_DECREASE")}</option>
                                                    </select>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-3" style={{lineHeight: '30px'}}>
                                                    <label htmlFor={`score_interaction_time_suspect_score${i}`} className="mr-3">{this.translate("BUSINESS_CONFIG_INTERACTION_TIME_SUSPECT_SCORE_PONTUATION_NUMBER")}</label>
                                                    <input onChange={e=>this.changeInteractionTimeSuspectScoreByIndex(e, i, "score")} id={`score_interaction_time_suspect_score${i}`} type="number" placeholder={this.translate("BUSINESS_CONFIG_INTERACTION_TIME_SUSPECT_SCORE_PONTUATION_NUMBER")} min="0" className="form-control" required/>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-1 my-3 my-md-0 text-center">
                                                    <button onClick={e=>this.removeElementFromSuspectScoreByIndex("interaction_time", i)} type="button" style={{height: "100%"}} className="btn rounded btn-outline-danger"><i className="fa fa-times-circle"></i></button>
                                                </div>
                                            </div>
                                        )}
                                        
                                        <div className="col-sm-12 col-md-12 mt20 mb-4">
                                            <button onClick={this.addInteractionTimeSuspectScore} type="button" className="btn btn-info px-5 btn-rounded btn-shadow">
                                                <i className="fa fa-plus"></i> {this.translate("BUSINESS_CONFIG_INTERACTION_TIME_ADD_INTERACTION_TIME_RULE")}
                                            </button>
                                        </div>
                                    </div> 
                                    <div className="box-divider m0 mt20 width100"></div>
                                    <div className="col-sm-12 col-md-12 mt20 mb-4">
                                        <button  id="submit_button_suspect_score" type="submit" disabled={true} className=" btn btn-info px-5 btn-rounded btn-shadow pull-right">
                                            <i className="fa fa-save"></i> {this.translate("BUSINESS_CONFIG_INTERACTION_TIME_REGISTER_SUSPECT_SCORE")}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.modalResponseShow} onHide={this.handleCloseModalResponse} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-bell"></i> {this.translate("MODAL_STATUS_STATUS")}</h5>
                                <a href="/" onClick={e=>this.handleCloseModalResponse(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                <span className="col-12 text-center py-2 lead">
                                    {this.state.msgResponseShow}
                                </span>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(BusinessConfigs);