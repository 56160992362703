import React, {Component} from 'react';
import translate from "../Helpers/Translator"
/*
    Todos os cards presentes podem ser utilizados para composição de qualquer tela
    Porem para uma organização mais simples, esta comentado a posição principal deles
    Separados por TELA.
*/

/* Cards Presentes No Dashboard Main */
import {TotalPeriodLeads} from '../Cards/TotalPeriodLeads';
import {TotalPeriodSuspects} from '../Cards/TotalPeriodSuspects';
import {SpeedometerSales} from '../Cards/SpeedometerSales';
import {FunilMarketing} from '../Cards/FunilMarketing';
import {FunilSales} from '../Cards/FunilSales';
import {FunilMKTSales} from '../Cards/FunilMKTSales';
import {TotalPeriodInvestment} from '../Cards/TotalPeriodInvestment';
import {CostPerLeadPeriod} from '../Cards/CostPerLeadPeriod';
import {ConversionRatePeriod} from '../Cards/ConversionRatePeriod';
import {VGVSoldPeriod} from '../Cards/VGVSoldPeriod';
import {ChartMonthlyLeadsVisits} from '../Cards/ChartMonthlyLeadsVisits';
import {ChartMonthlySales} from '../Cards/ChartMonthlySales';
import {LastLeadGeneratedList} from '../Cards/LastLeadGeneratedList';
import {WithHigherScoresLeadList} from '../Cards/WithHigherScoresLeadList';

/* Cards Presentes No Dashboard Main E-commerce */
 
import {EcommerceTotalSales} from '../Cards/DashboardMainEcommerce/EcommerceTotalSales';
import {EcommerceTotalSections} from '../Cards/DashboardMainEcommerce/EcommerceTotalSections';
import {EcommerceSpeedometerSales} from '../Cards/DashboardMainEcommerce/EcommerceSpeedometerSales';
import {EcommerceFunilMarketing} from '../Cards/DashboardMainEcommerce/EcommerceFunilMarketing';
import {EcommerceFunilSales} from '../Cards/DashboardMainEcommerce/EcommerceFunilSales';
import {EcommerceFunilMKTSales} from '../Cards/DashboardMainEcommerce/EcommerceFunilMKTSales';
import {EcommerceTotalPeriodInvestment} from '../Cards/DashboardMainEcommerce/EcommerceTotalPeriodInvestment';
import {EcommerceCostPerSalePeriod} from '../Cards/DashboardMainEcommerce/EcommerceCostPerSalePeriod';
import {EcommerceSectionsPerSalePeriod} from '../Cards/DashboardMainEcommerce/EcommerceSectionsPerSalePeriod';
import {EcommerceTotalSoldPeriod} from '../Cards/DashboardMainEcommerce/EcommerceTotalSoldPeriod';
import {EcommerceChartMonthlySalesVisits} from '../Cards/DashboardMainEcommerce/EcommerceChartMonthlySalesVisits';
import {EcommerceChartMonthlySections} from '../Cards/DashboardMainEcommerce/EcommerceChartMonthlySections';
import {EcommerceLastSalesGeneratedList} from '../Cards/DashboardMainEcommerce/EcommerceLastSalesGeneratedList';
import {EcommerceLastOpenSectionsList} from '../Cards/DashboardMainEcommerce/EcommerceLastOpenSectionsList'; 


/* Cards Presentes no Dashboard Leads */
import LeadsActionBar from '../Cards/LeadsActionBar';
import LeadsSummaryFeedbacks from '../Cards/LeadsSummaryFeedbacks';
import LeadsSummaryAuditedFeedbacks from '../Cards/LeadsSummaryAuditedFeedbacks';
import SummaryLandingsPagesAndListAllModal from '../Cards/SummaryLandingsPagesAndListAllModal';
import SummaryLastWithOriginAndListAllModal from '../Cards/SummaryLastWithOriginAndListAllModal';
import SummaryLastReturnedToSiteAndListAllModal from '../Cards/SummaryLastReturnedToSiteAndListAllModal';
import SummaryLastInteractedJourneyAndListAllModal from '../Cards/SummaryLastInteractedJourneyAndListAllModal';
import ChartLastInteractedJourney from '../Cards/ChartLastInteractedJourney';
import ChartRadarOrigin from '../Cards/ChartRadarOrigin';
import SummaryAverageResponseTimeMoreCustomerProfile from '../Cards/SummaryAverageResponseTimeMoreCustomerProfile';
import SummaryMidiaActiveCampaignsAndListAllMoreSegmentsAndListAllMoreActiveOriginAndListAll from '../Cards/SummaryMidiaActiveCampaignsAndListAllMoreSegmentsAndListAllMoreActiveOriginAndListAll';
import SummaryConversionAndListAll from '../Cards/SummaryConversionAndListAll';
import SummaryJourneyWithNumberOfLeadsAndListAll from '../Cards/SummaryJourneyWithNumberOfLeadsAndListAll';
import SummaryCommercialFunnel from '../Cards/SummaryCommercialFunnel';

/* Cards Presentes no Dashboard Business */
import BusinessFGECOC from '../Cards/BusinessFGECOC';
import SummaryBusinessLandingPages from '../Cards/SummaryBusinessLandingPages';
import SummaryBusinessMediaChannels from '../Cards/SummaryBusinessMediaChannels';
import SummaryBusinessContentPortals from '../Cards/SummaryBusinessContentPortals';
import SummaryBusinessSegments from '../Cards/SummaryBusinessSegments';
import SummaryBusinessPromotionalCampaigns from '../Cards/SummaryBusinessPromotionalCampaigns';
import SummaryBusinessAdverts from '../Cards/SummaryBusinessAdverts';
import SummaryBusinessEmails from '../Cards/SummaryBusinessEmails';
import SummaryBusinessRelationshipRulers from '../Cards/SummaryBusinessRelationshipRulers';
import SummaryBusinessSmss from '../Cards/SummaryBusinessSmss';
import SummaryBusinessBanners from '../Cards/SummaryBusinessBanners';
import SummaryBusinessVideos from '../Cards/SummaryBusinessVideos';
import SummaryBusinessPostsSocialMedia from '../Cards/SummaryBusinessPostsSocialMedia';
import SummaryBusinessCalls from '../Cards/SummaryBusinessCalls';
import SummaryBusinessCommercialDivisorTitle from '../Cards/SummaryBusinessCommercialDivisorTitle';
import SummaryBusinessCallsToAgents from '../Cards/SummaryBusinessCallsToAgents';
import SummaryBusinessCallsToLeads from '../Cards/SummaryBusinessCallsToLeads';
import SummaryBusinessQualificationOfTheAgent from '../Cards/SummaryBusinessQualificationOfTheAgent';
import SummaryBusinessLeadQualification from '../Cards/SummaryBusinessLeadQualification';
import SummaryBusinessAgentFeedback from '../Cards/SummaryBusinessAgentFeedback';
import SummaryBusinessCampaignFeedback from '../Cards/SummaryBusinessCampaignFeedback';
import SummaryBusinessDigitalPresenceTitle from '../Cards/SummaryBusinessDigitalPresenceTitle';
import SummaryBusinessTotalSimulations from '../Cards/SummaryBusinessTotalSimulations';
import SummaryBusinessTotalChats from '../Cards/SummaryBusinessTotalChats';
import SummaryBusinessScheduledContacts from '../Cards/SummaryBusinessScheduledContacts';
import SummaryBusinessScheduledVisits from '../Cards/SummaryBusinessScheduledVisits';
import SummaryBusinessReach from '../Cards/SummaryBusinessReach';
import SummaryBusinessPerformingActions from '../Cards/SummaryBusinessPerformingActions';
import SummaryBusinessSuspects from '../Cards/SummaryBusinessSuspects';
import SummaryBusinessLeads from '../Cards/SummaryBusinessLeads';

/* Cards Presentes no Dashboard Attendances */
import AttendancesFGECOC from '../Cards/DashboardAttendances/AttendancesFGECOC';
import PrePurchaseAndAttendanceDivisorTitle from '../Cards/DashboardAttendances/PrePurchaseAndAttendanceDivisorTitle';
import PrePurchaseAndAttendanceTotal from '../Cards/DashboardAttendances/PrePurchaseAndAttendanceTotal';
import PrePurchaseAndAttendanceAttendanceAverageTime from '../Cards/DashboardAttendances/PrePurchaseAndAttendanceAttendanceAverageTime';
import PrePurchaseAndAttendancePurchaseAverageTime from '../Cards/DashboardAttendances/PrePurchaseAndAttendancePurchaseAverageTime';
import PrePurchaseAndAttendanceAttendanceMaximumTime from '../Cards/DashboardAttendances/PrePurchaseAndAttendanceAttendanceMaximumTime';
import PrePurchaseAndAttendancePurchaseMaximumTime from '../Cards/DashboardAttendances/PrePurchaseAndAttendancePurchaseMaximumTime';
import PrePurchaseAndAttendanceAverageEvaluationScore from '../Cards/DashboardAttendances/PrePurchaseAndAttendanceAverageEvaluationScore';
import PrePurchaseAndAttendanceNewClients from '../Cards/DashboardAttendances/PrePurchaseAndAttendanceNewClients';
import PrePurchaseAndAttendanceLoyalClients from '../Cards/DashboardAttendances/PrePurchaseAndAttendanceLoyalClients';

import ChannelsDivisorTitle from '../Cards/DashboardAttendances/ChannelsDivisorTitle';
import ChannelsChannelMostUsed from '../Cards/DashboardAttendances/ChannelsChannelMostUsed';
import ChannelsCountFacebook from '../Cards/DashboardAttendances/ChannelsCountFacebook';
import ChannelsCountInstagram from '../Cards/DashboardAttendances/ChannelsCountInstagram';
import ChannelsCountWhatsapp from '../Cards/DashboardAttendances/ChannelsCountWhatsapp';
import ChannelsCountTwitter from '../Cards/DashboardAttendances/ChannelsCountTwitter';
import ChannelsCountEmail from '../Cards/DashboardAttendances/ChannelsCountEmail';
import ChannelsCountChat from '../Cards/DashboardAttendances/ChannelsCountChat';
import ChannelsCountPhone from '../Cards/DashboardAttendances/ChannelsCountPhone';

import PurchaseDivisorTitle from '../Cards/DashboardAttendances/PurchaseDivisorTitle';
import PurchasePurchasesTotal from '../Cards/DashboardAttendances/PurchasePurchasesTotal';
import PurchaseNewClients from '../Cards/DashboardAttendances/PurchaseNewClients';
import PurchaseShopperMaximumTime from '../Cards/DashboardAttendances/PurchaseShopperMaximumTime';
import PurchaseShopperAverageTime from '../Cards/DashboardAttendances/PurchaseShopperAverageTime';
import PurchaseAverageTicket from '../Cards/DashboardAttendances/PurchaseAverageTicket';
import PurchaseMaximumTicket from '../Cards/DashboardAttendances/PurchaseMaximumTicket';
import PurchaseMinimumTicket from '../Cards/DashboardAttendances/PurchaseMinimumTicket';
import PurchaseAllWaitingDelivey from '../Cards/DashboardAttendances/PurchaseAllWaitingDelivey';
import PurchasePopWaitingDelivey from '../Cards/DashboardAttendances/PurchasePopWaitingDelivey';
import PurchasePrimeWaitingDelivey from '../Cards/DashboardAttendances/PurchasePrimeWaitingDelivey';
import PurchaseProgrammedWaitingDelivey from '../Cards/DashboardAttendances/PurchaseProgrammedWaitingDelivey';

import DeliveryPopDivisorTitle from '../Cards/DashboardAttendances/DeliveryPopDivisorTitle';
import DeliveryPopTotal from '../Cards/DashboardAttendances/DeliveryPopTotal';
import DeliveryPopAverageKilometer from '../Cards/DashboardAttendances/DeliveryPopAverageKilometer';
import DeliveryPopAverageShippingTicket from '../Cards/DashboardAttendances/DeliveryPopAverageShippingTicket';
import DeliveryPopAverageTime from '../Cards/DashboardAttendances/DeliveryPopAverageTime';
import DeliveryPopMaximumTime from '../Cards/DashboardAttendances/DeliveryPopMaximumTime';
import DeliveryPopMinimumTime from '../Cards/DashboardAttendances/DeliveryPopMinimumTime';

import DeliveryPrimeDivisorTitle from '../Cards/DashboardAttendances/DeliveryPrimeDivisorTitle';
import DeliveryPrimeTotal from '../Cards/DashboardAttendances/DeliveryPrimeTotal';
import DeliveryPrimeAverageKilometer from '../Cards/DashboardAttendances/DeliveryPrimeAverageKilometer';
import DeliveryPrimeAverageShippingTicket from '../Cards/DashboardAttendances/DeliveryPrimeAverageShippingTicket';
import DeliveryPrimeAverageTime from '../Cards/DashboardAttendances/DeliveryPrimeAverageTime';
import DeliveryPrimeMaximumTime from '../Cards/DashboardAttendances/DeliveryPrimeMaximumTime';
import DeliveryPrimeMinimumTime from '../Cards/DashboardAttendances/DeliveryPrimeMinimumTime';

import DeliveryProgrammedDivisorTitle from '../Cards/DashboardAttendances/DeliveryProgrammedDivisorTitle';
import DeliveryProgrammedTotal from '../Cards/DashboardAttendances/DeliveryProgrammedTotal';
import DeliveryProgrammedAverageKilometer from '../Cards/DashboardAttendances/DeliveryProgrammedAverageKilometer';
import DeliveryProgrammedAverageShippingTicket from '../Cards/DashboardAttendances/DeliveryProgrammedAverageShippingTicket';
import DeliveryProgrammedAverageTime from '../Cards/DashboardAttendances/DeliveryProgrammedAverageTime';
import DeliveryProgrammedMaximumTime from '../Cards/DashboardAttendances/DeliveryProgrammedMaximumTime';
import DeliveryProgrammedMinimumTime from '../Cards/DashboardAttendances/DeliveryProgrammedMinimumTime';

import WaitDivisorTitle from '../Cards/DashboardAttendances/WaitDivisorTitle';
import WaitAttendanceAverageTime from '../Cards/DashboardAttendances/WaitAttendanceAverageTime';
import WaitAttendanceMaximumTime from '../Cards/DashboardAttendances/WaitAttendanceMaximumTime';
import WaitAttendanceMinimumTime from '../Cards/DashboardAttendances/WaitAttendanceMinimumTime';
import WaitPrePurchaseTotal from '../Cards/DashboardAttendances/WaitPrePurchaseTotal';
import WaitShopperTotal from '../Cards/DashboardAttendances/WaitShopperTotal';
import WaitDeliverTotal from '../Cards/DashboardAttendances/WaitDeliverTotal';

// ANDERSON
import ShippingStatusInAttendanceTotal from "../Cards/DashboardAttendances/ShippingStatusInAttendanceTotal";
import ShippingStatusAwaitingScripting from "../Cards/DashboardAttendances/ShippingStatusAwaitingScripting";
import ShippingStatusSentToDeliveryman from "../Cards/DashboardAttendances/ShippingStatusSentToDeliveryman";
import ShippingStatusAwaitingDeliveryStart from "../Cards/DashboardAttendances/ShippingStatusAwaitingDeliveryStart";
import ShippingStatusOnDelivery from "../Cards/DashboardAttendances/ShippingStatusOnDelivery";
import ShippingStatusFinished from "../Cards/DashboardAttendances/ShippingStatusFinished";
import ShippingTotalByPeriodChart from "../Cards/DashboardAttendances/ShippingTotalByPeriodChart";
import ShippingSellerRanking from "../Cards/DashboardAttendances/ShippingSellerRanking";
import ShippingSourceRanking from "../Cards/DashboardAttendances/ShippingSourceRanking";
import ShippingDeliveryTypeRanking from "../Cards/DashboardAttendances/ShippingDeliveryTypeRanking";
import ShippingOrderStatusPaymentPendingTotal from "../Cards/DashboardAttendances/ShippingOrderStatusPaymentPendingTotal";
import ShippingOrderStatusPaymentApprovedTotal from "../Cards/DashboardAttendances/ShippingOrderStatusPaymentApprovedTotal";
import ShippingOrderStatusInvoicedTotal from "../Cards/DashboardAttendances/ShippingOrderStatusInvoicedTotal";

const nameClassCard = {
    card_ecommerce_total_sales: EcommerceTotalSales,
    card_ecommerce_sections: EcommerceTotalSections,
    card_ecommerce_speedometer_sales: EcommerceSpeedometerSales,
    card_ecommerce_funil_mkt: EcommerceFunilMarketing,
    card_ecommerce_funil_sales: EcommerceFunilSales,
    card_ecommerce_funil_mkt_sales: EcommerceFunilMKTSales,
    card_ecommerce_total_investment: EcommerceTotalPeriodInvestment,
    card_ecommerce_cost_per_sale: EcommerceCostPerSalePeriod,
    card_ecommerce_sections_per_sale: EcommerceSectionsPerSalePeriod,
    card_ecommerce_total_sold: EcommerceTotalSoldPeriod,
    card_ecommerce_chart_monthly_sales_x_visits: EcommerceChartMonthlySalesVisits,
    card_ecommerce_chart_monthly_sections: EcommerceChartMonthlySections,
    card_ecommerce_last_generated_sales: EcommerceLastSalesGeneratedList,
    card_ecommerce_last_open_sections: EcommerceLastOpenSectionsList,

    card_leads: TotalPeriodLeads,
    card_suspects: TotalPeriodSuspects,
    card_speedometer_sales: SpeedometerSales,
    card_funil_mkt: FunilMarketing,
    card_funil_sales: FunilSales,
    card_funil_mkt_sales: FunilMKTSales,
    card_total_investment: TotalPeriodInvestment,
    card_cost_per_lead: CostPerLeadPeriod,
    card_conversion_rate: ConversionRatePeriod,
    card_vgv_sold: VGVSoldPeriod,
    card_chart_monthly_leads_x_visits: ChartMonthlyLeadsVisits,
    card_chart_monthly_sales: ChartMonthlySales,
    card_last_generated_leads: LastLeadGeneratedList,
    card_with_higher_scores: WithHigherScoresLeadList,
    card_leads_action_bar: LeadsActionBar,
    card_leads_summary_feedbacks: LeadsSummaryFeedbacks,
    card_leads_summary_audited_feedbacks: LeadsSummaryAuditedFeedbacks,
    card_summary_landings_pages_and_list_all_modal: SummaryLandingsPagesAndListAllModal,
    card_summary_last_with_origin_and_list_all_modal: SummaryLastWithOriginAndListAllModal,
    card_summary_last_returned_to_site_and_list_all_modal: SummaryLastReturnedToSiteAndListAllModal,
    card_summary_last_interacted_journey_and_list_all_modal: SummaryLastInteractedJourneyAndListAllModal,
    card_chart_last_interacted_journey: ChartLastInteractedJourney,
    card_chart_radar_origin: ChartRadarOrigin,
    card_summary_average_response_time_more_customer_profile: SummaryAverageResponseTimeMoreCustomerProfile,
    card_summary_midia_active_campaigns_and_list_all_more_segments_and_list_all_more_active_origin_and_list_all: SummaryMidiaActiveCampaignsAndListAllMoreSegmentsAndListAllMoreActiveOriginAndListAll,
    card_summary_conversion_and_list_all: SummaryConversionAndListAll,
    card_summary_journey_with_number_of_leads_and_list_all: SummaryJourneyWithNumberOfLeadsAndListAll,
    card_summary_commercial_funnel: SummaryCommercialFunnel,
    card_business_fgecoc: BusinessFGECOC,
    card_summary_business_landing_pages: SummaryBusinessLandingPages,
    card_summary_business_media_channels: SummaryBusinessMediaChannels,
    card_summary_business_content_portals: SummaryBusinessContentPortals,
    card_summary_business_segments: SummaryBusinessSegments,
    card_summary_business_promotional_campaigns: SummaryBusinessPromotionalCampaigns,
    card_summary_business_adverts: SummaryBusinessAdverts,
    card_summary_business_emails: SummaryBusinessEmails,
    card_summary_business_relationship_rulers: SummaryBusinessRelationshipRulers,
    card_summary_business_smss: SummaryBusinessSmss,
    card_summary_business_banners: SummaryBusinessBanners,
    card_summary_business_videos: SummaryBusinessVideos,
    card_summary_business_posts_social_media: SummaryBusinessPostsSocialMedia,
    card_summary_business_calls: SummaryBusinessCalls,
    card_summary_business_commercial_divisor_title: SummaryBusinessCommercialDivisorTitle,
    card_summary_business_calls_to_agents: SummaryBusinessCallsToAgents,
    card_summary_business_calls_to_leads: SummaryBusinessCallsToLeads,
    card_summary_business_qualification_of_the_agent: SummaryBusinessQualificationOfTheAgent,
    card_summary_business_lead_qualification: SummaryBusinessLeadQualification,
    card_summary_business_agent_feedback: SummaryBusinessAgentFeedback,
    card_summary_business_campaign_feedback: SummaryBusinessCampaignFeedback,
    card_summary_business_digital_presence_title: SummaryBusinessDigitalPresenceTitle,
    card_summary_business_total_simulations: SummaryBusinessTotalSimulations,
    card_summary_business_total_chats: SummaryBusinessTotalChats,
    card_summary_business_scheduled_contacts: SummaryBusinessScheduledContacts,
    card_summary_business_scheduled_visits: SummaryBusinessScheduledVisits,
    card_summary_business_reach: SummaryBusinessReach,
    card_summary_business_performing_actions: SummaryBusinessPerformingActions,
    card_summary_business_suspects: SummaryBusinessSuspects,
    card_summary_business_leads: SummaryBusinessLeads,

    card_attendances_fgecoc: AttendancesFGECOC,
    card_pre_purchase_and_attendance_divisor_title: PrePurchaseAndAttendanceDivisorTitle,
    card_pre_purchase_and_attendance_total: PrePurchaseAndAttendanceTotal,
    card_pre_purchase_and_attendance_attendance_average_time: PrePurchaseAndAttendanceAttendanceAverageTime,
    card_pre_purchase_and_attendance_purchase_average_time: PrePurchaseAndAttendancePurchaseAverageTime,

    card_pre_purchase_and_attendance_attendance_maximum_time: PrePurchaseAndAttendanceAttendanceMaximumTime,
    card_pre_purchase_and_attendance_purchase_maximum_time: PrePurchaseAndAttendancePurchaseMaximumTime,
    card_pre_purchase_and_attendance_average_evaluation_score: PrePurchaseAndAttendanceAverageEvaluationScore,
    card_pre_purchase_and_attendance_new_clients: PrePurchaseAndAttendanceNewClients,
    card_pre_purchase_and_attendance_loyal_clients: PrePurchaseAndAttendanceLoyalClients,

    card_channels_divisor_title: ChannelsDivisorTitle,
    card_channels_channel_most_used: ChannelsChannelMostUsed,
    card_channels_count_facebook: ChannelsCountFacebook,
    card_channels_count_instagram: ChannelsCountInstagram,
    card_channels_count_whatsapp: ChannelsCountWhatsapp,
    card_channels_count_twitter: ChannelsCountTwitter,
    card_channels_count_email: ChannelsCountEmail,
    card_channels_count_chat: ChannelsCountChat,
    card_channels_count_phone: ChannelsCountPhone,

    card_purchase_divisor_title: PurchaseDivisorTitle,
    card_purchase_purchases_total: PurchasePurchasesTotal,
    card_purchase_new_clients: PurchaseNewClients,
    card_purchase_shopper_maximum_time: PurchaseShopperMaximumTime,
    card_purchase_shopper_average_time: PurchaseShopperAverageTime,
    card_purchase_average_ticket: PurchaseAverageTicket,
    card_purchase_maximum_ticket: PurchaseMaximumTicket,
    card_purchase_minimum_ticket: PurchaseMinimumTicket,
    card_purchase_all_waiting_delivery: PurchaseAllWaitingDelivey,
    card_purchase_pop_waiting_delivery: PurchasePopWaitingDelivey,
    card_purchase_prime_waiting_delivery: PurchasePrimeWaitingDelivey,
    card_purchase_programmed_waiting_delivery: PurchaseProgrammedWaitingDelivey,

    card_delivery_pop_divisor_title: DeliveryPopDivisorTitle,
    card_delivery_pop_total: DeliveryPopTotal,
    card_delivery_pop_average_kilometer: DeliveryPopAverageKilometer,
    card_delivery_pop_average_shipping_ticket: DeliveryPopAverageShippingTicket,
    card_delivery_pop_average_time: DeliveryPopAverageTime,
    card_delivery_pop_maximum_time: DeliveryPopMaximumTime,
    card_delivery_pop_minimum_time: DeliveryPopMinimumTime,

    card_delivery_prime_divisor_title: DeliveryPrimeDivisorTitle,
    card_delivery_prime_total: DeliveryPrimeTotal,
    card_delivery_prime_average_kilometer: DeliveryPrimeAverageKilometer,
    card_delivery_prime_average_shipping_ticket: DeliveryPrimeAverageShippingTicket,
    card_delivery_prime_average_time: DeliveryPrimeAverageTime,
    card_delivery_prime_maximum_time: DeliveryPrimeMaximumTime,
    card_delivery_prime_minimum_time: DeliveryPrimeMinimumTime,

    card_delivery_programmed_divisor_title: DeliveryProgrammedDivisorTitle,
    card_delivery_programmed_total: DeliveryProgrammedTotal,
    card_delivery_programmed_average_kilometer: DeliveryProgrammedAverageKilometer,
    card_delivery_programmed_average_shipping_ticket: DeliveryProgrammedAverageShippingTicket,
    card_delivery_programmed_average_time: DeliveryProgrammedAverageTime,
    card_delivery_programmed_maximum_time: DeliveryProgrammedMaximumTime,
    card_delivery_programmed_minimum_time: DeliveryProgrammedMinimumTime,

    card_wait_divisor_title: WaitDivisorTitle,
    card_wait_attendance_average_time: WaitAttendanceAverageTime,
    card_wait_attendance_maximum_time: WaitAttendanceMaximumTime,
    card_wait_attendance_minimum_time: WaitAttendanceMinimumTime,
    card_wait_pre_purchase_total: WaitPrePurchaseTotal,
    card_wait_shopper_total: WaitShopperTotal,
    card_wait_deliver_total: WaitDeliverTotal,
    // ANDERSON
    card_shipping_status_in_attendance_total: ShippingStatusInAttendanceTotal,
    card_shipping_status_awaiting_scripting_total: ShippingStatusAwaitingScripting,
    card_shipping_status_sent_to_deliveryman_total: ShippingStatusSentToDeliveryman,
    card_shipping_status_awaiting_delivery_start_total: ShippingStatusAwaitingDeliveryStart,
    card_shipping_status_on_delivery_total: ShippingStatusOnDelivery,
    card_shipping_status_finished_total: ShippingStatusFinished,
    card_shipping_total_by_period: ShippingTotalByPeriodChart,
    card_shipping_seller_ranking: ShippingSellerRanking,
    card_shipping_source_ranking: ShippingSourceRanking,
    card_shipping_delivery_type_ranking: ShippingDeliveryTypeRanking,
    card_shipping_order_status_payment_pending_total: ShippingOrderStatusPaymentPendingTotal,
    card_shipping_order_status_payment_approved_total: ShippingOrderStatusPaymentApprovedTotal,
    card_shipping_order_status_invoiced_total: ShippingOrderStatusInvoicedTotal,
}

export class MountStructureCard extends Component{

    renderCards(card, periodName, cardCustom){
        let CardCall = nameClassCard[card.type];
        let cardMounted;
        switch(card.type){
            case 'card_ecommerce_total_sales':
            case 'card_ecommerce_sections':
            case 'card_ecommerce_speedometer_sales':
            case 'card_ecommerce_funil_mkt':
            case 'card_ecommerce_funil_sales':
            case 'card_ecommerce_funil_mkt_sales':
            case 'card_ecommerce_total_investment':
            case 'card_ecommerce_cost_per_sale':
            case 'card_ecommerce_sections_per_sale':
            case 'card_ecommerce_total_sold':
            case 'card_ecommerce_chart_monthly_sales_x_visits':
            case 'card_ecommerce_chart_monthly_sections':
            case 'card_ecommerce_last_generated_sales':
            case 'card_ecommerce_last_open_sections':
            case 'card_leads':
            case 'card_suspects':
            case 'card_speedometer_sales':
            case 'card_funil_mkt':
            case 'card_funil_sales':
            case 'card_funil_mkt_sales':
            case 'card_total_investment':
            case 'card_cost_per_lead':
            case 'card_conversion_rate':
            case 'card_vgv_sold':
            case 'card_chart_monthly_leads_x_visits':
            case 'card_chart_monthly_sales':
            case 'card_last_generated_leads':
            case 'card_with_higher_scores':
            case 'card_leads_action_bar':
            case 'card_leads_summary_feedbacks':
            case 'card_leads_summary_audited_feedbacks':
            case 'card_summary_landings_pages_and_list_all_modal':
            case 'card_summary_last_with_origin_and_list_all_modal':
            case 'card_summary_last_returned_to_site_and_list_all_modal':
            case 'card_summary_last_interacted_journey_and_list_all_modal':
            case 'card_chart_last_interacted_journey':
            case 'card_chart_radar_origin':
            case 'card_summary_average_response_time_more_customer_profile':
            case 'card_summary_midia_active_campaigns_and_list_all_more_segments_and_list_all_more_active_origin_and_list_all':
            case 'card_summary_conversion_and_list_all':
            case 'card_summary_journey_with_number_of_leads_and_list_all':
            case 'card_summary_commercial_funnel':
            case 'card_business_fgecoc':
            case 'card_summary_business_landing_pages':
            case 'card_summary_business_media_channels':
            case 'card_summary_business_content_portals':
            case 'card_summary_business_segments':
            case 'card_summary_business_promotional_campaigns':
            case 'card_summary_business_adverts':
            case 'card_summary_business_emails':
            case 'card_summary_business_relationship_rulers':
            case 'card_summary_business_smss':
            case 'card_summary_business_banners':
            case 'card_summary_business_videos':
            case 'card_summary_business_posts_social_media':
            case 'card_summary_business_calls':
            case 'card_summary_business_commercial_divisor_title':
            case 'card_summary_business_calls_to_agents':
            case 'card_summary_business_calls_to_leads':
            case 'card_summary_business_qualification_of_the_agent':
            case 'card_summary_business_lead_qualification':
            case 'card_summary_business_agent_feedback':
            case 'card_summary_business_campaign_feedback':
            case 'card_summary_business_digital_presence_title':
            case 'card_summary_business_total_simulations':
            case 'card_summary_business_total_chats':
            case 'card_summary_business_scheduled_contacts':
            case 'card_summary_business_scheduled_visits':
            case 'card_summary_business_reach':
            case 'card_summary_business_performing_actions':
            case 'card_summary_business_suspects':
            case 'card_summary_business_leads':
            case 'card_pre_purchase_and_attendance_divisor_title':
            case 'card_pre_purchase_and_attendance_total':
            case 'card_pre_purchase_and_attendance_attendance_average_time':
            case 'card_pre_purchase_and_attendance_purchase_average_time':
            case 'card_attendances_fgecoc':
            case 'card_pre_purchase_and_attendance_attendance_maximum_time':
            case 'card_pre_purchase_and_attendance_purchase_maximum_time':
            case 'card_pre_purchase_and_attendance_average_evaluation_score':
            case 'card_pre_purchase_and_attendance_new_clients':
            case 'card_pre_purchase_and_attendance_loyal_clients':
            case 'card_channels_divisor_title':
            case 'card_channels_channel_most_used':
            case 'card_channels_count_facebook':
            case 'card_channels_count_instagram':
            case 'card_channels_count_whatsapp':
            case 'card_channels_count_twitter':
            case 'card_channels_count_email':
            case 'card_channels_count_chat':
            case 'card_channels_count_phone':
            case 'card_purchase_divisor_title':
            case 'card_purchase_purchases_total':
            case 'card_purchase_new_clients':
            case 'card_purchase_shopper_maximum_time':
            case 'card_purchase_shopper_average_time':
            case 'card_purchase_average_ticket':
            case 'card_purchase_maximum_ticket':
            case 'card_purchase_minimum_ticket':
            case 'card_purchase_all_waiting_delivery':
            case 'card_purchase_pop_waiting_delivery':
            case 'card_purchase_prime_waiting_delivery':
            case 'card_purchase_programmed_waiting_delivery':
            case 'card_delivery_pop_divisor_title':
            case 'card_delivery_pop_total':
            case 'card_delivery_pop_average_kilometer':
            case 'card_delivery_pop_average_shipping_ticket':
            case 'card_delivery_pop_average_time':
            case 'card_delivery_pop_maximum_time':
            case 'card_delivery_pop_minimum_time':
            case 'card_delivery_prime_divisor_title':
            case 'card_delivery_prime_total':
            case 'card_delivery_prime_average_kilometer':
            case 'card_delivery_prime_average_shipping_ticket':
            case 'card_delivery_prime_average_time':
            case 'card_delivery_prime_maximum_time':
            case 'card_delivery_prime_minimum_time':
            case 'card_delivery_programmed_divisor_title':
            case 'card_delivery_programmed_total':
            case 'card_delivery_programmed_average_kilometer':
            case 'card_delivery_programmed_average_shipping_ticket':
            case 'card_delivery_programmed_average_time':
            case 'card_delivery_programmed_maximum_time':
            case 'card_delivery_programmed_minimum_time':
            case 'card_wait_divisor_title':
            case 'card_wait_attendance_average_time':
            case 'card_wait_attendance_maximum_time':
            case 'card_wait_attendance_minimum_time':
            case 'card_wait_pre_purchase_total':
            case 'card_wait_shopper_total':
            case 'card_wait_deliver_total':
            // ANDERSON
            case 'card_shipping_status_in_attendance_total':
            case 'card_shipping_status_awaiting_scripting_total':
            case 'card_shipping_status_sent_to_deliveryman_total':
            case 'card_shipping_status_awaiting_delivery_start_total':
            case 'card_shipping_status_on_delivery_total':
            case 'card_shipping_status_finished_total':
            case 'card_shipping_total_by_period':
            case 'card_shipping_seller_ranking':
            case 'card_shipping_source_ranking':
            case 'card_shipping_delivery_type_ranking':
            case 'card_shipping_order_status_payment_pending_total':
            case 'card_shipping_order_status_payment_approved_total':
            case 'card_shipping_order_status_invoiced_total':
                cardMounted = <CardCall type={card.type} fields={card.fields} periodName={periodName} cardCustom={cardCustom} handleCardActionClick={this.props.handleCardActionClick} translate={translate}/>
                break;
            default:
                cardMounted = <div className="col-6 p-5 text-center bg-blue_w">Estrutura do Card não encontrada.</div>
                break;
        }
        return(cardMounted)
        
    }

    render(){
        return(
            <React.Fragment>
                {
                    this.renderCards(this.props.card, this.props.periodName, this.props.cardCustom)
                }
            </React.Fragment>
        )
    }
}

export default MountStructureCard;