import React, {Component} from 'react';
import MetricsModal from "../MetricsModal"

import $ from "jquery"

export default class RankingMedium extends Component{

    constructor(props){
        super(props)
        this.state = {
            isMetricsModalVisible: false, 
            isEditingCard: false,
            iconFilter: "",
            isIconDropdownVisible: false
        }
    }

    handleCloseMetricsModal = (e = null) => {
        if(e) e.preventDefault()
        this.setState({isMetricsModalVisible: false})
    }
    
    handleShowMetricsModal = (e = null) => {
        if(this.state.isEditingCard) return
        if(e) e.preventDefault()
        this.setState({isMetricsModalVisible: true})
    }
    

    handleCardEditButtonClick = () => {
        this.setState({isMetricsModalVisible: false})
    }

    disposeTooltip = () => $('[data-toggle="tooltip"]').tooltip('dispose')

    componentDidMount = () => {
        $('[data-toggle="tooltip"]').tooltip('dispose').tooltip({placement: "bottom", trigger: "hover"})
        
    }

    componentDidUpdate = () => {
        $('[data-toggle="tooltip"]').tooltip('dispose').tooltip({placement: "bottom", trigger: "hover"})
    }
    
    
    render = () =>{
        
        return(
            <React.Fragment>
                <div className={`col-6 col-md-6 btn-cursor-pointer hover-shadow active-dark-background ${this.props.className ? this.props.className : ""}`}>
                    <div className="dashboard_performance_card position10">
                        {this.props.isEditing ?
                            <div className="d-flex align-items-center">
                                <p className="uppercase text-muted f10 flex-grow-1 m-0">{this.props.values.title || this.props.translate("DASHBOARD_PERFORMANCE_CARD_ENTER_THE_CARDS_TITLE")}</p>
                                <div>
                                    {this.state.isEditingCard ?
                                        <React.Fragment>
                                            <button onClick={e=>this.props.handleCardChangeMainMetric(this.props.id, this.props.stageId)} className="btn btn-sm ml-1 btn-warning btn-cursor-pointer" data-toggle="tooltip" title={this.props.translate("DASHBOARD_PERFORMANCE_CARD_CHANGE_CARD_MAIN_METRIC")}>
                                                <i className="fa fa-line-chart"></i>
                                            </button>

                                            <button onClick={e=>this.props.handleCardRemove(this.props.id, this.props.stageId)} className="btn btn-sm ml-1 btn-danger btn-cursor-pointer" data-toggle="tooltip" title={this.props.translate("DASHBOARD_PERFORMANCE_CARD_REMOVE_CARD")}>
                                                <i className="fa fa-trash"></i>
                                            </button>

                                            <button onClick={e=>{this.disposeTooltip();this.setState({isEditingCard: false})}} className="btn btn-sm ml-1 btn-success btn-cursor-pointer" data-toggle="tooltip" title={this.props.translate("DASHBOARD_PERFORMANCE_CARD_CONFIRM_CHANGES")}>
                                                <i className="fa fa-check"></i>
                                            </button>
                                        </React.Fragment>
                                    
                                    :
                                        <button onClick={e=>{this.disposeTooltip();this.setState({isEditingCard: true})}} className="btn btn-sm btn-warning btn-cursor-pointer" data-toggle="tooltip" title={this.props.translate("DASHBOARD_PERFORMANCE_CARD_EDIT_CARD")}>
                                            <i className="fa fa-edit"></i>
                                        </button>
                                    }
                                        
                                </div>
                            </div>
                        :
                            <div className="d-flex align-items-center">
                                <p className="uppercase text-muted f10 flex-grow-1 m-0">{this.props.values.title || this.props.translate("DASHBOARD_PERFORMANCE_CARD_ENTER_THE_CARDS_TITLE")}</p>
                                {this.props.checkIsAlreadyBeingCompared ?
                                    <button onClick={e=>{this.disposeTooltip();this.props.handleRemoveFromComparison(this.props.id, this.props.stageId)}} className="btn btn-sm btn-danger btn-cursor-pointer" data-toggle="tooltip" title={this.props.translate("DASHBOARD_PERFORMANCE_CARD_REMOVE_FROM_COMPARISON")}>
                                        <i className="fa fa-pie-chart"></i>
                                    </button>
                                :
                                    this.props.checkIfCardCanBeCompared &&
                                        <button onClick={e=>{this.disposeTooltip();this.props.handleAddToComparison(this.props.id, this.props.stageId, this.props.main_metric_key, this.props.values, this.props.comparison)}} className="btn btn-sm btn-primary btn-cursor-pointer" data-toggle="tooltip" title={this.props.translate("DASHBOARD_PERFORMANCE_CARD_COMPARE_CARD")}>
                                            <i className="fa fa-pie-chart"></i>
                                        </button>
                                }
                                
                            </div>
                        }

                        <div className="card-content" onClick={()=>{
                            if(!this.props.onClick) {
                                this.handleShowMetricsModal()
                            } else {
                                this.props.onClick()
                            }
                        }}>
                            <div className="d-flex width100 text-center">
                                {this.state.isEditingCard &&
                                    <div className="flex-grow-1">
                                        <input 
                                            type="text" 
                                            value={this.props.values.title} 
                                            onChange={e=>this.props.handleCardChange(e, "title", this.props.id, this.props.stageId)}
                                            placeholder={this.props.translate("DASHBOARD_PERFORMANCE_CARD_ENTER_THE_CARDS_TITLE")} 
                                            className="form-control col-12 flex-grow-1 mb-2"
                                        />
                                    </div>
                                }
                            </div>
                            <div className="table-responsive custom_scroll" style={{maxHeight: 200}}>
                                {this.props.values.value && this.props.values.value.length > 0 &&
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>N°</th>
                                                {Object.keys(this.props.values.value[0]).map((key, index)=>
                                                    <th key={index + "ranking"}>{key}</th>
                                                    
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.values.value.map((value, index)=>
                                                <tr key={`ranking${index}`}>
                                                    <td>{index + 1}</td>
                                                    {Object.values(value).map((singleValue, indexSingleValue)=>
                                                        <td key={`rankingitem${index}${indexSingleValue}`}>{singleValue}</td>
                                                    )}
                                                </tr>
                                            )}
                                        </tbody>
                                        
                                    </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <MetricsModal handleCloseMetricsModal={this.handleCloseMetricsModal} isMetricsModalVisible={this.state.isMetricsModalVisible} cardTitle={this.props.values.title} {...this.props} />
            </React.Fragment>
        )
    }
}



