import React, {Component} from 'react';

import MountStructureCard from './MountStructureCard';

export class MountStructureApp extends Component{

    state = {
        classRow: {
            none: '',
            simples: 'row',
            dashboardMiniCard: 'row no-gutters',
            dashboardFunis: 'white special-box bg-blur_w3',
            dashboardRowMt20: 'row mt20',
            dashboardBannerPb10Pt20Mb20: 'row banner pb10 pt20 mb20'
        }
    }

    render(){
        return(
            <React.Fragment>
                {
                    this.props.cards && this.props.cards.map(
                        (card, i) => 
                            <div key={i} className={this.state.classRow[card.row]}>
                                {
                                    card.cards.map(
                                        (card, i) => (
                                            <MountStructureCard key={i} card={card} periodName={this.props.periodName} handleCardActionClick={this.props.handleCardActionClick} cardCustom={this.props.cardCustom}/>
                                        )
                                    )
                                }
                            </div>
                    )
                }
            </React.Fragment>
        )
    }
}

export default MountStructureApp;