import React, { Component } from 'react';

import AverageResponseTime from './AverageResponseTime';
import CustomerProfile from './CustomerProfile';

class SummaryAverageResponseTimeMoreCustomerProfile extends Component {
    render() {
        return (
            <div className="col-md-3 col-sm-12 mt20">
                <div className="box p-3 box-shadow2 height320">
                    <AverageResponseTime fields={this.props.fields} type={this.props.type}/>
                    <hr/>
                    <CustomerProfile fields={this.props.fields} type={this.props.type}/>
                </div>
            </div>
        );
    }
}

export default SummaryAverageResponseTimeMoreCustomerProfile;