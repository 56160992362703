import React, { Component, Fragment } from "react";
import MenuMain from "../../Structure/MenuMain";
import HeaderMain from "../../Structure/HeaderMain";
import FeedMain from "../../Structure/FeedMain";
import { withRouter } from "react-router-dom";
import api from "../../Services/Api";

import $ from "jquery";
import "jquery-mask-plugin";
import "bootstrap";
import "datatables.net-dt";
import "datatables.net-bs4";
import "datatables.net-responsive";
import jzip from "jszip";
import "datatables-buttons";
import "datatables.net-buttons";
import "datatables.net-buttons/js/buttons.html5.min";
import "datatables.net-responsive-bs4";
import { getLeadsDownload } from "../../Auth/AuthConfig";

import { ajaxUrl, ajaxToken } from "../../Services/Ajax";

window.JSZip = jzip;

class LeadsList extends Component {
  constructor(props) {
    super(props);

    this.translate = this.props.translate;
    document.title = this.translate("LEADS_LIST_LEADS_LIST");
    this.state = {
      leadsUrl: "/leads",
      tableLoadLeads: null,
      brokersSelectList: [],
      domainsSelectList: [],
      agentsSelectList: [],
      originSelectList: [],
      formLeadsAdvancedSearch: {
        agent: "",
        broker: "",
        origin: "",
        date_start: "",
        date_end: "",
        phone: "",
        name: "",
      },
    };
  }

  toggleCollapse = (selector) => $(selector).collapse("toggle");

  updateBrokersSelect = (e) => {
    let brokerId = e.target.value;

    if (brokerId) {
      this.loadAgentsSelectList(brokerId);
      this.setState({
        formLeadsAdvancedSearch: {
          ...this.state.formLeadsAdvancedSearch,
          agent: "",
          broker: brokerId,
        },
      });
    } else {
      this.setState({
        agentsSelectList: [],
        formLeadsAdvancedSearch: {
          ...this.state.formLeadsAdvancedSearch,
          agent: "",
          broker: "",
        },
      });
    }
  };

  loadDomainsList = async () => {
    try {
      //const response = await api.get("/brokers/domains")
      let response = {
        data: {
          success: true,
          data: [
            /*  {"name": "Domínio de teste", code: "teste_1"},
                         {"name": "Domínio de teste 2", code: "teste_2"},
                         {"name": "Domínio de teste 3", code: "teste_3"},
                         {"name": "Domínio de teste 4", code: "teste_4"}, */
          ],
        },
      };
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.length
      ) {
        this.setState({ domainsSelectList: response.data.data });
      }
    } catch (err) {
      console.log(err);
    }
  };

  loadBrokersSelectList = async () => {
    try {
      const response = await api.get("/brokers/table");
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.length
      ) {
        this.setState({ brokersSelectList: response.data.data });
      }
    } catch (err) {
      console.log(err);
    }
  };

  loadAgentsSelectList = async (brokerId) => {
    try {
      const response = await api.get(`/agents/${brokerId}/table`);
      console.log(response.data.data);
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.length
      ) {
        this.setState({ agentsSelectList: response.data.data });
      } else {
        this.setState({ agentsSelectList: [] });
      }
    } catch (err) {
      console.log(err);
    }
  };

  loadOriginSelectList = async () => {
    try {
      const response = await api.get(
        `/business/${localStorage.getItem("@buylitics-business_id")}/media/json`
      );
      if (response && response.data && response.data.length) {
        this.setState({ originSelectList: response.data });
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleClearAdvancedSearch = (e) => {
    let urlSearch = this.state.leadsUrl.split("?")[0];

    this.setState(
      {
        formLeadsAdvancedSearch: {
          domain: "",
          agent: "",
          broker: "",
          origin: "",
          date_start: "",
          date_end: "",
          phone: "",
          name: "",
        },
        agentsSelectList: [],
        leadsUrl: urlSearch,
      },
      this.reloadDatatable
    );
  };

  handleSubmitAdvancedSearch = (e) => {
    e.preventDefault();
    let { formLeadsAdvancedSearch } = this.state;
    console.log(formLeadsAdvancedSearch);
    let urlSearch = this.state.leadsUrl.split("?")[0];
    let i = 0;
    for (let [key, value] of Object.entries(formLeadsAdvancedSearch)) {
      if (value) {
        urlSearch += i === 0 ? "?" : "&";
        urlSearch += `${key}=${value}`;
        i++;
      }
    }
    urlSearch = encodeURI(urlSearch);
    this.setState({ leadsUrl: urlSearch }, this.reloadDatatable);
  };

  reloadDatatable() {
    $("#lista_leads").DataTable().ajax.url(ajaxUrl(this.state.leadsUrl)).load();
  }

  componentDidMount = async () => {
    let tableLoadLeads = this.state.tableLoadLeads;
    tableLoadLeads = $("#lista_leads").DataTable({
      dom: "Bfrtip",
      buttons:
        getLeadsDownload() == 1
          ? [
              {
                extend: "csvHtml5",
                className: "btn btn-default btn-outline-success m-2",
                text: '<i class="fa fa-file-excel-o" aria-hidden="true"></i> CSV',
                exportOptions: {
                  columns: [1, 2, 3, 4, 5, 6, 7],
                },
              },
              {
                extend: "excelHtml5",
                className: "btn btn-default btn-outline-success m-2",
                text: '<i class="fa fa-file-excel-o" aria-hidden="true"></i> Excel',
                exportOptions: {
                  columns: [1, 2, 3, 4, 5, 6, 7],
                },
              },
            ]
          : [],
      searching: true,
      paging: true,
      info: false,
      responsive: true,
      language: {
        url: this.translate("DATATABLE_LANGUAGE_URL"),
      },
      ajax: {
        url: ajaxUrl(this.state.leadsUrl),
        type: "GET",
        beforeSend: function (request) {
          request.setRequestHeader("Authorization", "Bearer " + ajaxToken());
        },
      },
      columnDefs: [
        {
          targets: -1,
          data: null,
          defaultContent: `<div class="btn-group btn-shadow">
                        <button class="visualizar btn btn-sm success" title="${this.translate(
                          "LEADS_LIST_VISUALIZE_LIST"
                        )}">
                            <i class="fa fa-user-circle" title="${this.translate(
                              "LEADS_LIST_VISUALIZE"
                            )}"></i> ${this.translate("LEADS_LIST_VISUALIZE")}
                        </button>
                    </div>`,
        },
      ],
    });
    $("#search_lead").on("keyup change", function () {
      tableLoadLeads.search(this.value).draw();
    });
    $("#length_change_lead").on("keyup change", function () {
      tableLoadLeads.page.len($(this).val()).draw();
    });
    $("#lista_leads tbody").on("click", ".visualizar", function (event) {
      event.preventDefault();
      //var data = tableLoadLeads.row($(this).parents('tr')).data();
      let data = [];
      if ($(this).parents("tr").hasClass("child")) {
        data = tableLoadLeads.row($(this).parents("tr").prev(".parent")).data();
      } else {
        data = tableLoadLeads.row($(this).parents("tr")).data();
      }
      let leadView = window.open("/lead_data#" + data[8]);
      leadView.focus();
      //props.history.push('/lead_data#' + data[8]);
    });

    var SPMaskBehavior = function (val) {
        return val.replace(/\D/g, "").length === 11
          ? "(00) 00000-0000"
          : "(00) 0000-00009";
      },
      spOptions = {
        onComplete: function (val) {
          var phone = $("#advanced_search_phone").val();
          this.setState({
            formLeadsAdvancedSearch: {
              ...this.state.formLeadsAdvancedSearch,
              phone: phone,
            },
          });
        }.bind(this),
        onKeyPress: function (val, e, field, options) {
          field.mask(SPMaskBehavior.apply({}, arguments), options);
        },
      };

    $("#advanced_search_phone").mask(SPMaskBehavior, spOptions);

    this.loadBrokersSelectList();
    this.loadDomainsList();
    this.loadOriginSelectList();
  };

  render() {
    return (
      <Fragment>
        <MenuMain />
        <div id="content" className="app-content box-shadow-0 dark" role="main">
          <HeaderMain
            title={this.translate("LEADS_LIST_LEADS_LIST")}
            onChange={this.handleChange}
          />
          <div className="content-main row row-no-margin" id="content-main">
            <div
              className=" col-lg-10 col-md-10 col-sm-12 col-xs-12 no-gutters"
              id="leads"
            >
              <div className="row mt20 mb20">
                <div className="col-sm-12 col-md-6">
                  <select
                    id="length_change_lead"
                    className="custom-select w-sm d-inline v-middle btn-shadow"
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  <label className="">
                    &nbsp;&nbsp;{this.translate("LEADS_LIST_RESULTS_PER_PAGE")}
                  </label>
                </div>
                <div className="col-sm-12 col-md-6 advanced_leads_filter">
                  <div className="input-group light">
                    <input
                      type="text"
                      id="search_lead"
                      className="form-control text-dark border-none"
                      placeholder={this.translate("LEADS_LIST_SEARCH_A_LEAD")}
                    />
                    <button
                      className="btn rounded-0 btn-info"
                      type="button"
                      onClick={(e) => this.toggleCollapse("#collapseLeads")}
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      <span className="fa fa-chevron-down"></span>{" "}
                      {this.translate("LEADS_LIST_ADVANCED_SEARCH")}
                    </button>
                  </div>
                  <div className="collapse" id="collapseLeads">
                    <form
                      id="search_lead_adv"
                      onSubmit={this.handleSubmitAdvancedSearch}
                    >
                      <div className="well light p-4 box-shadow">
                        <div className="row">
                          <div className="col-12">
                            <p className="m0">
                              {this.translate("LEADS_LIST_SEARCH_BY_DOMAIN")}:
                            </p>
                            <select
                              name="domain"
                              className="form-control cool-select"
                              value={this.state.formLeadsAdvancedSearch.domain}
                              onChange={(e) =>
                                this.setState({
                                  formLeadsAdvancedSearch: {
                                    ...this.state.formLeadsAdvancedSearch,
                                    domain: e.target.value,
                                  },
                                })
                              }
                            >
                              <option value="">
                                {this.translate("LEADS_LIST_SELECT_THE_DOMAIN")}
                              </option>
                              {this.state.domainsSelectList.map(
                                (domain, index) => (
                                  <option
                                    key={"domain" + index + domain.code}
                                    value={domain.code}
                                  >
                                    {domain.name}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                          <hr className="d-block w-100 mb10 mt10"></hr>
                          <div className="col-12">
                            <p className="m0">
                              {this.translate("LEADS_LIST_SEARCH_BY_ORIGIN")}:
                            </p>
                            <select
                              name="origin"
                              className="form-control cool-select"
                              value={this.state.formLeadsAdvancedSearch.origin}
                              onChange={(e) =>
                                this.setState({
                                  formLeadsAdvancedSearch: {
                                    ...this.state.formLeadsAdvancedSearch,
                                    origin: e.target.value,
                                  },
                                })
                              }
                            >
                              <option value="">
                                {this.translate("LEADS_LIST_SELECT_THE_ORIGIN")}
                              </option>
                              {this.state.originSelectList.map(
                                (origin, index) => (
                                  <option
                                    key={index + origin.code}
                                    value={origin.code}
                                  >
                                    {origin.name}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                          <hr className="d-block w-100 mb10 mt10"></hr>
                          <div className="col-md-6 col-sm-12 mt-2">
                            <p className="m0">
                              {this.translate("LEADS_SEARCH_FOR_BROKER")}:
                            </p>
                            <select
                              name="broker"
                              className="form-control cool-select"
                              onChange={this.updateBrokersSelect}
                              value={this.state.formLeadsAdvancedSearch.broker}
                            >
                              <option value="">
                                {this.translate("LEADS_LIST_SELECT_THE_BROKER")}
                              </option>
                              {this.state.brokersSelectList.map(
                                (broker, index) => (
                                  <option
                                    key={index + broker[6]}
                                    value={broker[6]}
                                  >
                                    {broker[0]}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                          <div className="col-md-6 col-sm-12 mt-2">
                            <p className="m0">
                              {this.translate("LEADS_LIST_SEARCH_BY_AGENT")}:
                            </p>
                            <select
                              name="agent"
                              className="form-control cool-select"
                              value={this.state.formLeadsAdvancedSearch.agent}
                              onChange={(e) =>
                                this.setState({
                                  formLeadsAdvancedSearch: {
                                    ...this.state.formLeadsAdvancedSearch,
                                    agent: e.target.value,
                                  },
                                })
                              }
                            >
                              <option value="">
                                {" "}
                                {this.translate("LEADS_LIST_SELECT_THE_AGENT")}
                              </option>
                              {this.state.agentsSelectList.map(
                                (agent, index) => (
                                  <option
                                    key={index + agent[7]}
                                    value={agent[7]}
                                  >
                                    {agent[0]}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                          <hr className="d-block w-100 mb10 mt10"></hr>
                          <div className="col-12">
                            <div className="form-row">
                              <div className="form-group col-6">
                                <label
                                  className="m-0 pr-1"
                                  htmlFor="advanced_search_date_start"
                                >
                                  {this.translate("LEADS_LIST_FROM")}
                                </label>
                                <input
                                  type="datetime-local"
                                  pattern="[0-9]/[0-9]{2}/[0-9]{4}T[0-9]{2}:[0-9]{2}"
                                  id="advanced_search_date_start"
                                  className="form-control fblack"
                                  data-plugin="datepicker"
                                  value={
                                    this.state.formLeadsAdvancedSearch
                                      .date_start
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      formLeadsAdvancedSearch: {
                                        ...this.state.formLeadsAdvancedSearch,
                                        date_start: e.target.value,
                                      },
                                    })
                                  }
                                />
                              </div>
                              <div className="form-group col-6">
                                <label
                                  className="m-0 px-1"
                                  htmlFor="advanced_search_date_end"
                                >
                                  {this.translate("LEADS_LIST_TO")}
                                </label>
                                <input
                                  type="datetime-local"
                                  pattern="[0-9]/[0-9]{2}/[0-9]{4}T[0-9]{2}:[0-9]{2}"
                                  id="advanced_search_date_end"
                                  className="form-control fblack"
                                  data-plugin="datepicker"
                                  value={
                                    this.state.formLeadsAdvancedSearch.date_end
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      formLeadsAdvancedSearch: {
                                        ...this.state.formLeadsAdvancedSearch,
                                        date_end: e.target.value,
                                      },
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <hr className="d-block w-100 mb10 mt10" />
                          <div className="col-md-4 col-sm-12">
                            <p className="m0">
                              {this.translate("LEADS_LIST_BY_PHONE")}:
                            </p>
                            <input
                              name="phone"
                              id="advanced_search_phone"
                              className="form-control"
                              type="text"
                              placeholder={this.translate(
                                "LEADS_LIST_ENTER_PHONE"
                              )}
                              value={this.state.formLeadsAdvancedSearch.phone}
                              onChange={(e) =>
                                this.setState({
                                  formLeadsAdvancedSearch: {
                                    ...this.state.formLeadsAdvancedSearch,
                                    phone: e.target.value,
                                  },
                                })
                              }
                            />
                          </div>
                          <div className="col-md-8 col-sm-12">
                            <p className="m0">
                              {this.translate("LEADS_LIST_SEARCH_BY_NAME")}:
                            </p>
                            <input
                              name="name"
                              className="form-control"
                              type="text"
                              placeholder={this.translate(
                                "LEADS_LIST_ENTER_THE_NAME"
                              )}
                              value={this.state.formLeadsAdvancedSearch.name}
                              onChange={(e) =>
                                this.setState({
                                  formLeadsAdvancedSearch: {
                                    ...this.state.formLeadsAdvancedSearch,
                                    name: e.target.value,
                                  },
                                })
                              }
                            />
                          </div>
                          <hr className="d-block w-100 mb10 mt10" />
                          <div className="col-12 d-flex justify-content-end">
                            <button
                              type="button"
                              className="btn btn-danger btn-rounded btn-shadow btn-cursor-pointer"
                              onClick={this.handleClearAdvancedSearch}
                            >
                              <i className="fa fa-trash"></i>{" "}
                              {this.translate("LEADS_LIST_CLEAN")}{" "}
                            </button>
                            <button
                              type="submit"
                              className="btn btn-success btn-rounded btn-shadow ml-2 btn-cursor-pointer"
                            >
                              <i className="fa fa-search"></i>{" "}
                              {this.translate("LEADS_LIST_SEARCH")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="">
                    <div className=" box-shadow">
                      <div className="bootstrap-table">
                        <div className="fixed-table-container">
                          <div className="fixed-table-body min-height100">
                            <table
                              id="lista_leads"
                              className="table table-striped table-bordered dt-responsive nowrap"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr className="text-left">
                                  <th>{this.translate("LEADS_LIST_NUMBER")}</th>
                                  <th>{this.translate("LEADS_LIST_NAME")}</th>
                                  <th>{this.translate("LEADS_LIST_EMAIL")}</th>
                                  <th>{this.translate("LEADS_LIST_PHONE")}</th>
                                  <th>{this.translate("LEADS_LIST_ORIGIN")}</th>
                                  <th>{this.translate("LEADS_LIST_SCORE")}</th>
                                  <th>
                                    {this.translate("LEADS_LIST_SEGMENTATION")}
                                  </th>
                                  <th>
                                    {this.translate("LEADS_LIST_ENTRY_DATE")}
                                  </th>
                                  <th>{this.translate("TABLE_ACTIONS_ROW")}</th>
                                </tr>
                              </thead>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FeedMain />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(LeadsList);
