import React, { Component, Fragment } from 'react'

import api from '../../../../Services/Api'
import Status from '../../../../Templates/Status'
import { toggleAccordionPannel } from '../../../../Helpers/Collapse'

export default class ItemsComponent extends Component {

    constructor(props) {
        super(props)

        this.stageNumber = 2
        this.translate = this.props.translate
        this.state = {
            isLoading: true,
            status: false,
            buyer_name: false,
            order_date: false,
            items: [],
            selectedItems: []
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.orderId !== this.props.orderId && this.props.currentStage === this.stageNumber) {
            this.loadItems(this.props.orderId)
        }
    }

    loadItems = async (orderId) => {
        if (!orderId) {
            this.setState({ isLoading: false, status: { success: false, message: "Compra não encontrada" } })
            return
        }
        this.props.checkStageIsCorrect(this.stageNumber)

        let response = await this.getItems(orderId)

        if (!response || (response && !response.order)) {
            this.setState({ isLoading: false, status: { success: false, message: "Não foi possível consultar os itens da compra" } })
            return
        }
        let order = response.order
        let items = this.formatItems(order.items)
        if (response.success) {
            this.setState({
                buyer_name: order.buyer_name,
                order_date: order.date,
                items: items,
                isLoading: false
            })
        } else {
            this.setState({
                status: {
                    success: response.success,
                    message: response.message
                },
                isLoading: false
            })
        }

    }

    getItems = async (orderId) => {
        let response = false
        try {
            //let response = await api.post("/order/reverse/items",{ orderId: orderId })
            response = this.getItemsMockup()
            if (response && response.data.message) {
                response = response.data
            }
        } catch (error) {
            console.log(error)
        }
        return response
    }

    getItemsMockup = () => {
        return {
            data: {
                success: true,
                message: "Itens da compra consultados com sucesso!",
                "order": {
                    "_id": 'adasdasd',
                    "date": "27/10/2020 01:56:38",
                    "idOrder": "1071962536746",
                    "buyer_name": "Julio marcos piotronni",
                    "stores": "TNG",
                    "items": [
                        {
                            "_id": "sdf34aa24sdf",
                            "date": "27/10/2020 01:56:38",
                            "idOrder": "10719625asd36746",
                            "idSubOrder": "10719625aa36746",
                            "seller_name": "lojaa_x",
                            "quantity": 2,
                            "description": "Papel Higienico",
                        },
                        {
                            "_id": "sdf3424ssddf",
                            "date": "27/10/2020 01:56:38",
                            "idOrder": "10719sd162536746",
                            "idSubOrder": "107ad1962536746",
                            "seller_name": "lojaa_y",
                            "quantity": 12,

                            "description": "Chiclete",
                        },
                        {
                            "_id": "sdf34sds24sdf",
                            "date": "27/10/2020 01:56:38",
                            "idOrder": "1071962v12536746",
                            "idSubOrder": "10714353962536746",
                            "seller_name": "lojaa_z",
                            "quantity": 23,
                            "description": "Leite condensado",
                        },
                        {
                            "_id": "sdf34sds24sdf",
                            "date": "27/10/2020 01:56:38",
                            "idOrder": "107196fgh2536746",
                            "idSubOrder": "10719sdf62536746",
                            "seller_name": "lojaa_x",
                            "quantity": 22,
                            "description": "Brigadeiro de padaria",
                        }
                    ]
                }
            }
        }
    }

    formatItems = (items) => {
        if (!items) return []
        let formatedItems = []
        let sellers = new Set(items.map(item => item.seller_name))
        for (let seller of sellers) {
            let sellerObject = { seller_name: seller }
            sellerObject["items"] = items.filter(item => item.seller_name === seller)
            formatedItems.push(sellerObject)
        }
        return formatedItems
    }

    toggleSelectedItem = (item) => {
        let found = false
        let newSelectedItems = [...this.state.selectedItems]
        for (let selectedItem of newSelectedItems) {
            if (selectedItem.idOrder === item.idOrder && selectedItem.idSubOrder === item.idSubOrder) {
                selectedItem.value = !selectedItem.value
                found = true
                break
            }
        }
        if (!found) {
            newSelectedItems.push({ idOrder: item.idOrder, idSubOrder: item.idSubOrder, value: true })
        }
        this.setState({ selectedItems: newSelectedItems })
    }

    checkItemIsSelected = (item) => {
        let filtered = this.state.selectedItems.filter(selected => {
            return (selected.idOrder === item.idOrder && selected.idSubOrder === item.idSubOrder
                && selected.value === true)
        })

        return filtered.length > 0
    }

    checkHasAtLeastOneSelectedItem = () => {
        let filtered = this.state.selectedItems.filter(selected => selected.value === true)
        return filtered.length > 0
    }

    handleSubmit = () => {
        if (!this.checkHasAtLeastOneSelectedItem()) return
        this.props.checkStageIsCorrect(this.stageNumber)
        let selectedItems = this.state.selectedItems.map(selected => {
            let obj = { ...selected }
            delete obj.value
            return obj
        })
        this.props.handleGoToNextStage(this.stageNumber + 1, { selectedItems })
    }

    render = () =>
        this.props.currentStage === this.stageNumber &&

        <Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="box p-3">
                        <h3>Itens da Compra {this.props.orderId}</h3>
                        <hr />
                        <Status status={this.state.status} />
                        {this.state.isLoading ?
                            <p>
                                <i className="fa fa-spin fa-spinner"></i> Carregando
                            </p>
                            :
                            (this.state.buyer_name && this.state.order_date) ?
                                <Fragment>
                                    <div className="row">
                                        <div className="col-12 my-2 d-flex justify-content-between">
                                            <span className="lead">{this.state.buyer_name}</span>
                                            <span className="lead">{this.state.order_date}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {(this.state.items && this.state.items.length) ?
                                            <Fragment>
                                                {this.state.items.map((seller, index) =>
                                                    <div key={`seller_div_${index}_${seller.seller_name}`} className="col-12" id={`collape_header_${seller.seller_name}`}>
                                                        <div className="card mb-3">

                                                            <div className="card-header btn-cursor-pointer dark" onClick={() => toggleAccordionPannel(`#collapse_body_${seller.seller_name}`)} id={`heading_${seller.seller_name}`}>
                                                                <h5 className="mb-0" data-toggle="collapse" aria-expanded="true" aria-controls={`collapse_body_${seller.seller_name}`}>
                                                                    {seller.seller_name}
                                                                    <i className="fa fa-caret-down pull-right"></i>
                                                                </h5>
                                                            </div>

                                                            <div id={`collapse_body_${seller.seller_name}`} className="collapse dark show" aria-labelledby={`heading_${seller.seller_name}`} data-parent={`#collape_header_${seller.seller_name}`}>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <table className="table">
                                                                            <thead>
                                                                                <tr className="grid_4">
                                                                                    <th>Selecionar</th>
                                                                                    <th>Descrição</th>
                                                                                    <th>Quantidade</th>
                                                                                    <th>Id de subcompra</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {seller.items.map((item, indexItem) =>
                                                                                    <tr className="grid_4" key={`seller_div_item_${item.idOrder}_${item.idSubOrder}`}>
                                                                                        <td className="first_grid_item" onClick={() => this.toggleSelectedItem(item)}>
                                                                                            {this.checkItemIsSelected(item) ?
                                                                                                <i className="fa fa-check-square-o text-md btn-cursor-pointer"></i>
                                                                                                :
                                                                                                <i className="fa fa-square-o text-md btn-cursor-pointer"></i>
                                                                                            }
                                                                                        </td>
                                                                                        <td className="btn-cursor-pointer" onClick={() => this.toggleSelectedItem(item)}>{item.description}</td>
                                                                                        <td>{item.quantity}</td>
                                                                                        <td>{item.idSubOrder}</td>
                                                                                    </tr>
                                                                                )}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {this.checkHasAtLeastOneSelectedItem() ?
                                                    <button className="btn btn-fw blue btn-rounded btn-shadow ml-3" onClick={this.handleSubmit} type="button">
                                                        <i className="fa fa-check"></i>&nbsp;
                                                        Avançar
                                                    </button>
                                                    :
                                                    <button className="btn btn-cursor-pointer btn-fw blue btn-rounded btn-shadow ml-3 disabled" type="button">
                                                        <i className="fa fa-check"></i>&nbsp;
                                                        Avançar
                                                    </button>
                                                }

                                            </Fragment>
                                            :
                                            <p className="lead">Não há itens a serem mostrados</p>
                                        }
                                    </div>
                                </Fragment>
                                :
                                <p className="lead">Não há itens a serem mostrados</p>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
}
