import React, { Component } from 'react';

import BackgroundVideoBuylitics1 from '../Backgrounds/BackgroundVideoBuylitics1';

class MountStructureBackground extends Component {
    
    renderBackground(background){
        let backgroundElement = '';
        switch (background){
            case 'card_background_buylitics_1':
                backgroundElement = <BackgroundVideoBuylitics1 />
                break;
            default:
                backgroundElement = <div></div>
                break;
        }
        return (backgroundElement);
    }
    
    render() {
        return (
            <React.Fragment>
                {this.renderBackground(this.props.background)}
            </React.Fragment>
        );
    }
}

export default MountStructureBackground;