import React, { Component } from 'react';

class Checkbox extends Component {

    constructor(props){
        super(props);
        this.handleChangeInputs = this.handleChangeInputs.bind(this)
    }

    handleChangeInputs(e, i){
        this.props.onChange(e, i)
    }

    render(){
        return (
            <React.Fragment>
                <input
                    onChange={e => this.handleChangeInputs(e, this.props.index)}
                    type="checkbox"
                    id={"playpause"+ this.props.is + this.props.index}
                    defaultChecked={this.props.data.status}
                    disabled={(this.props.data.editable)?false:true}
                    required
                />
                <label htmlFor={"playpause"+ this.props.is + this.props.index}></label> 
            </React.Fragment>
        );
    }
}

export default Checkbox;