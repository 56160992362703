import React, { Component, Fragment } from 'react'

import api from '../../../../Services/Api'

export default class StatusComponent extends Component {

    constructor(props) {
        super(props)

        this.stageNumber = 6
        this.translate = this.props.translate
    }

    componentDidUpdate() {
        console.log(this.props.reverseStatus)
    }



    render = () =>
        this.props.currentStage === this.stageNumber &&
        <Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="box p-3">
                        <h3>Status do Agendamento</h3>
                        <hr />
                        {this.props.isLoadingReverse ?
                            <p>
                                <i className="fa fa-spin fa-spinner"></i> Carregando
                            </p>
                            :
                            (this.props.reverseStatus) ?
                                <div className="row">
                                    <div className="col">
                                        {this.props.reverseStatus.message}
                                    </div>
                                </div>
                                :
                                <p>
                                    Ocorreu um erro ao submeter a Reversa
                                </p>

                        }
                    </div>
                </div>
            </div>
        </Fragment>
}
