import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';

import MenuMain from '../../Structure/MenuMain';
import HeaderMain from '../../Structure/HeaderMain';
import FooterMain from '../../Structure/FooterMain';

import BusinessRegister from './Components/BusinessRegister';
import BusinessList from './Components/BusinessList';

class AdminBusiness extends Component {
    constructor(props){
        super(props)
        this.translate = this.props.translate
        document.title = this.translate("ADMIN_BUSINESS_TITLE")
    }
    render(){
        return(
            <React.Fragment>
                <MenuMain />
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    <HeaderMain title={this.translate("ADMIN_BUSINESS_TITLE")} onChange={this.handleChange}/>
                    <div className="content-main admin" id="content-main">
                        <div className="padding">
                            <div className="row pt20">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">

                                    <ul className="nav nav-pills nav-sm mb10" id="admin_tab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-target="#tab_list_business" data-toggle="tab" href="#tab_list_business" role="tab" aria-controls="list" aria-selected="false">{this.translate("ADMIN_BUSINESS_LIST_BUSINESS")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-target="#tab_register_business" data-toggle="tab" href="#tab_register_business" role="tab" aria-controls="register" aria-selected="true">{this.translate("ADMIN_BUSINESS_REGISTER_BUSINESS")}</a>
                                        </li>
                                    </ul>

                                    <div className="tab-content" id="admin_tab_content">
                                        <div className="tab-pane pb40 fade show active p-3 white box-shadow" id="tab_list_business" role="tabpanel" aria-labelledby="">
                                            <BusinessList translate={this.translate} idCompany={document.location.hash.replace('#','')}/>
                                        </div>
                                        <div className="tab-pane pb40 fade p-3 white box-shadow" id="tab_register_business" role="tabpanel" aria-labelledby="">
                                            <BusinessRegister translate={this.translate} idCompany={document.location.hash.replace('#','')}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterMain />
                </div>
            </React.Fragment>
        );
    }
}  

export default withRouter(AdminBusiness);