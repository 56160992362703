import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";

import MenuMain from "../../Structure/MenuMain";
import HeaderMain from "../../Structure/HeaderMain";
import FooterMain from "../../Structure/FooterMain";

import IntegrationWebSites from "./Components/IntegrationWebSites";
import IntegrationChatbot from "./Components/IntegrationChatbot";
import IntegrationFacebook from "./Components/IntegrationFacebook";
import IntegrationCRMs from "./Components/IntegrationCRMs";
import IntegrationMarketingTools from "./Components/IntegrationMarketingTools";

class HomeIntegration extends Component {
	constructor(props) {
		super(props);
		this.translate = this.props.translate;
		document.title = this.translate("HOME_INTEGRATION_INTEGRATIONS");
		this.state = {};
	}

	render() {
		return (
			<Fragment>
				<MenuMain />
				<div
					id="content"
					className="app-content box-shadow-0 dark"
					role="main"
				>
					<HeaderMain
						title={document.title}
						onChange={this.handleChange}
					/>
					<div
						className="content-main row row-no-margin"
						id="content-main"
					>
						<div
							className=" col-lg-12 col-md-12 col-sm-12 col-xs-12 no-gutters"
							id="integracao"
						>
							<IntegrationFacebook translate={this.translate} />
							<IntegrationCRMs translate={this.translate} />
							<IntegrationWebSites translate={this.translate} />
							<IntegrationChatbot translate={this.translate} />
							<IntegrationMarketingTools
								translate={this.translate}
							/>
						</div>
					</div>
					<FooterMain />
				</div>
			</Fragment>
		);
	}
}

export default withRouter(HomeIntegration);
