import React, {Component} from 'react';

import CommentsBoxTool from './CommentsBoxTool';

import Modal from 'react-bootstrap/Modal';

import api from '../Services/Api';

export class PeriodBoxTool extends Component{

    constructor(props){
        super(props)
        this.handleShow =  this.handleShow.bind(this)
        this.handleClose =  this.handleClose.bind(this)
        this.state = {
            show: false,
            period_initial: '',
            period_end: ''
        }
    }

    async getValues(period, card){
        return await api.post('/build/card/value', {period: period, card: card})
        .then(async response => {
            return response.data;
        })
    }

    handleChange = e =>{
        e.preventDefault();
        const period = e.currentTarget.dataset.period;
        const periodName = e.currentTarget.dataset.period_name;
        this.getValues(period, this.props.type).then((data) => {
            this.props.onChange(periodName, data.values)
        });
    }

    handleChangeCustom = e => {
        e.preventDefault();
        this.handleShow();
    }

    handleClose(){
        this.setState({show: false})
    }

    handleShow(){
        this.setState({show: true})
    }

    handleSubmit = async e => {
        e.preventDefault();
        const { period_initial, period_end } = this.state;
        await api.post('/build/card/value/custom_period', {period_initial: period_initial, period_end: period_end, card: this.props.type})
        .then(async response => {
            this.props.onChange(response.data.periodName, response.data.values)
            this.handleClose();
        })
    }

    render(){

        const styleElement = {
            form: {
                width: '80%',
                marginLeft: '10%'
            },
            div: {
                padding: '20px'
            }
        }

        return(
            <React.Fragment>
                <div className="box-tool">
                    <ul className="nav nav-xs">
                        {
                            (this.props.comment_box)?(
                                <CommentsBoxTool />
                            ):(
                                false
                            )
                        }
                        <li className="nav-item dropdown">
                            <a href="/" className="nav-link text-muted" data-toggle="dropdown">
                                <i className="fa fa-bars"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-scale pull-right light f10 box-shadow">
                                <a onClick={this.handleChange} data-period="current_month" data-period_name='Mês atual' className="dropdown-item" href="/">Mês atual</a> 
                                <div className="dropdown-divider"></div>
                                <a onClick={this.handleChange} data-period="last_month" data-period_name="Último mês" className="dropdown-item" href="/">Último mês</a> 
                                <a onClick={this.handleChange} data-period="today" data-period_name="Hoje" className="dropdown-item" href="/">Hoje</a>
                                <a onClick={this.handleChange} data-period="yesterday" data-period_name="Ontem" className="dropdown-item" href="/">Ontem</a>
                                <a onClick={this.handleChange} data-period="last_7days" data-period_name="Últimos 7 dias" className="dropdown-item" href="/">Últimos 7 dias</a>
                                <a onClick={this.handleChange} data-period="last_30days" data-period_name="Últimos 30 dias" className="dropdown-item" href="/">Últimos 30 dias</a>
                                <a onClick={this.handleChange} data-period="last_90days" data-period_name="Últimos 90 dias" className="dropdown-item" href="/">Últimos 90 dias</a>
                                <div className="dropdown-divider"></div>
                                <a onClick={this.handleChangeCustom} data-period="custom_date" data-period_name="Data Personalizada" href="/" value="modalescolherdatas" className="dropdown-item">Escolher datas</a>
                            </div>
                        </li>
                    </ul>
                </div>
                <Modal show={this.state.show} onHide={this.handleClose} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink green text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-clone"></i> Datas</h5>
                                <a href="/" onClick={this.handleClose} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10" style={styleElement.div}>
                                <form onSubmit={this.handleSubmit} style={styleElement.form}>
                                    <div className="col-sm-12 col-md-10 col-lg-12 mb20">
                                        <label>Data Inicial</label>
                                        <input
                                            onChange={e => this.setState({ period_initial: e.target.value })}
                                            type="datetime-local"
                                            className="form-control"
                                            name="period_initial"
                                            required
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-10 col-lg-12 mb20">
                                        <label>Data Final</label>
                                        <input
                                            onChange={e => this.setState({ period_end: e.target.value })}
                                            type="datetime-local"
                                            className="form-control"
                                            name="period_end"
                                            required
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-10 col-lg-12 mb20">
                                        <button type="submit" className="btn btn-success">Consultar</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}

export default PeriodBoxTool;