import React, { Component, Fragment } from 'react';

import $ from "jquery"
import "bootstrap"

import api from "../../../Services/Api"

export default class AttendanceCart extends Component {

    constructor(props){
        super(props)
        this.state = {
            searchInput: "",
            shoppingCartUrl: null,
            searchProducts: [],
            searchProductsQuantity: {},
            isLoadingProducts: false,
            isLoadingCart: true,
            
            shoppingCart: [],
            suggestionsIsVisible: false, 
            totalShoppingCart: 0,
            
            updatingProducts: false,
            typingProductsQuantity: false,

            shoppingCartButtonsDisabled: false,
            shoppingCartInputAndRemoveButtonDisabled: false,
            shoppingCartIdOfProductBeingUpdated: "",

            business_type: null,

            cartValuesOrder: {
                request_number: '-',
                purchase_date: '00/00/0000',
                delivery_method: 'POP',
                order_status: '-'
            },

            allSubOrdersInvoiced: false,
            storesArray: []

        }
        this.translate = this.props.translate
        this.productsUpdatingInterval = null
    }


    getProductsSupermarket = async (idAttendance) => {

        let searchProducts = []

        const data = {
            search: this.state.searchInput
        }
        this.setState({isLoadingProducts: true})
        try{
            await api.post(`/attendance/supermarket/products/list/${idAttendance}`, data)
                .then((response) => response.data)
                .then((response) => {
                    if(response.success && Array.isArray(response.products))  {
                        searchProducts = response.products
                        this.updateSearchProducts(searchProducts)
                    } else {
                        this.setState({searchProducts, searchProductsQuantity: {}, suggestionsIsVisible: true}) 
                    }
                })
                .catch(error => console.log(error))
        }catch(error){
            console.log("error", error)
        }
        this.setState({isLoadingProducts: false})
    }

    updateSearchProducts = (searchProducts) => {
        let searchProductsQuantity = {}
        //this controls the amount of products in the searchProductList
        searchProducts.forEach(product=>{
            let shoppingCartProduct = this.state.shoppingCart.filter(cartProduct=>cartProduct.product_id === product.product_id)
            if(shoppingCartProduct.length > 0) {
                searchProductsQuantity[product.product_id] = shoppingCartProduct[0].quantity
            } else {
                searchProductsQuantity[product.product_id] = 0
            }
        })
        this.setState({searchProducts, searchProductsQuantity, suggestionsIsVisible: true}) 
    }

    getCartClient = async (idAttendance) => {
        
        let shoppingCart = []
        let shoppingCartUrl = null
        this.setState({isLoadingCart: true})
        try{
            await api.get(`attendance/client/cart/get/${idAttendance}`)
                .then((response) => response.data)
                .then((response) => {
                    if(response["success"]){
                        if(response.shoppingCartUrl)  shoppingCartUrl = response.shoppingCartUrl
                        if(response.shoppingCart && Array.isArray(response.shoppingCart))  shoppingCart = response.shoppingCart

                        let allSubOrdersInvoiced = false
                        let storesArray = []

                        if(response.business_type && response.business_type === 'shopping'){
                            if(response.values.sub_orders && Array.isArray(response.values.sub_orders)){
                                
                                let checkSubOrdersStatus = response.values.sub_orders.filter(sub_order => sub_order.status != "invoiced" && sub_order.status != 'cancelled' && sub_order.status != 'canceled');
                                if(Array.isArray(checkSubOrdersStatus) && checkSubOrdersStatus.length === 0){
                                    allSubOrdersInvoiced = true
                                    this.props.onChangeSubOrders(allSubOrdersInvoiced)
                                }

                                for(let sub_order of response.values.sub_orders){

                                    let itens = (response.shoppingCart && Array.isArray(response.shoppingCart))? response.shoppingCart.filter(item => item.corridor && item.corridor == sub_order.seller) : []

                                    storesArray.push({
                                        store: sub_order.seller_name,
                                        seller: sub_order.seller,
                                        qtd_itens: (itens && Array.isArray(itens) && itens.length) ? itens.reduce(function(quantity, item){return quantity + item.quantity}, 0) : 0,
                                        status: sub_order.status,
                                        idAttendance: response.idAttendance,
                                        idRequest: response.idRequest,
                                        idOrder: sub_order.orderId
                                    })

                                }

                            }
                        }else{
                            allSubOrdersInvoiced = true
                        }

                        this.setState({
                            shoppingCart,
                            shoppingCartUrl,
                            business_type: response['business_type'],
                            cartValuesOrder: {...this.state.cartValuesOrder, ...response['values']},
                            allSubOrdersInvoiced: allSubOrdersInvoiced,
                            storesArray: storesArray
                        })

                    }
                })
                .catch((error) => console.log(error))
        }catch(error){
            console.log("error", error)
        }
        this.setState({isLoadingCart: false})
    }

    getCategoryByKey = (key) => {
        let category
        switch (key){
            case "healthy":
                category = "SAUDÁVEIS"
                break
            case "frozen":
                category = "FRIOS"
                break
            case "grocery":
                category = "MERCEARIA"
                break
            default:
                category = (typeof key !== 'undefined') ? key.toUpperCase() : ''
                break
        }
        return category
    }

    checkCartHasProductAndSum = (shoppingCart, searchProduct, quantity) => {
        
        let check = false
        let newQuantity = quantity

        quantity = parseInt(quantity)

        shoppingCart.forEach((cartProduct, index) => {

            if(cartProduct.product_id === searchProduct.product_id){
                cartProduct.quantity =  parseInt(cartProduct.quantity) + quantity
                cartProduct.total += quantity * parseFloat(searchProduct.price)
                newQuantity = cartProduct.quantity
                check = 1 
            }

        })

        return check ? {shoppingCart, newQuantity} : false
    }

    addButtonLoading = (button, icon, iconClass) => {
        button.disabled = true
        icon.classList.remove("fa-times")
        icon.classList.add("fa-spinner", "fa-spin")
    }

    removeButtonLoading = (button, icon, iconClass) => {
        icon.classList.remove("fa-spin")
        icon.classList.remove("fa-spinner")
        icon.classList.add(iconClass)
        button.disabled = false
    }

    removeProductFromCart = async (productToRemove, e = null) => {
        let idAttendance = this.props.idAttendance
        let button
        let icon
        if(e){
            button = e.currentTarget
            icon = button.querySelector("i")
    
            this.addButtonLoading(button, icon, "fa-times")
        }
        let {shoppingCart, searchProductsQuantity} = this.state
        this.setState({shoppingCartButtonsDisabled: true, shoppingCartIdOfProductBeingUpdated: "", shoppingCartInputAndRemoveButtonDisabled: true})
        await api.post(`attendance/client/cart/send/${idAttendance}/remove`, {product_id: productToRemove.product_id, quantity: 0})
            .then((response) => response.data)
            .then((response) => {
                if(response["success"]){
                    shoppingCart = shoppingCart.filter(product => product.product_id !== productToRemove.product_id)
                    if(searchProductsQuantity[productToRemove.product_id]){
                        searchProductsQuantity[productToRemove.product_id] = 0
                    }
                    this.setState({shoppingCart, searchProductsQuantity})

                } else {
                    if(e){
                        this.removeButtonLoading(button, icon, "fa-times")
                    }
                }
            })
            .catch((error) => {
                console.log(error)
                if(e){
                    this.removeButtonLoading(button, icon, "fa-times")
                }
            })
            this.setState({shoppingCartButtonsDisabled: false, shoppingCartInputAndRemoveButtonDisabled: false})
    }


    getCointMasked = (value) => {
        value = parseFloat(value);
        let country = "brazil"
        if(country === "brazil"){
            value = value.toFixed(2)
            value = value.replace(".",",");
            value = `R$ ${value}`
        } else {
            value = value.toFixed(2)
            value = `$ ${value}`
        }
        return value
    }

    updateSearchProductQuantityInput = (quantity, searchProduct, searchProducts) => {
        quantity = parseInt(quantity)
        let {searchProductsQuantity, shoppingCartIdOfProductBeingUpdated} = this.state
        shoppingCartIdOfProductBeingUpdated = searchProduct.product_id

        if (quantity <= 0 || !Number.isInteger(quantity)) quantity = 0
        
        searchProducts.forEach((product, index)=>{
            if(product.product_id === searchProduct.product_id){
                product.quantity = parseInt(quantity)
                product.total = parseInt(quantity) * product.price
            }
        })

        searchProductsQuantity[searchProduct.product_id] = quantity
        
        this.setState({ searchProducts, searchProductsQuantity, shoppingCartButtonsDisabled: true, shoppingCartInputAndRemoveButtonDisabled: true, shoppingCartIdOfProductBeingUpdated}, ()=> {
            clearTimeout(this.productsUpdatingInterval)
            this.productsUpdatingInterval = setTimeout(()=>this.changeSearchProductQuantity(quantity, searchProduct), 2000)
        })
    }

    addOneProductFromSearch = (e, searchProduct) => {
        let {searchProducts, searchProductsQuantity} = this.state
        let quantity = parseInt(searchProductsQuantity[searchProduct.product_id])
        let shoppingCartIdOfProductBeingUpdated = searchProduct.product_id
        
        quantity += 1
        searchProductsQuantity[searchProduct.product_id] = quantity

        searchProducts.forEach((product, index)=>{
            if(product.product_id === searchProduct.product_id){
                product.quantity = parseInt(quantity)
                product.total = parseInt(quantity) * product.price
            }
        })

        
        this.setState({ searchProducts, searchProductsQuantity, shoppingCartButtonsDisabled: true, shoppingCartIdOfProductBeingUpdated, shoppingCartInputAndRemoveButtonDisabled: true}, ()=> {
            clearTimeout(this.productsUpdatingInterval)
            this.productsUpdatingInterval = setTimeout(()=>this.changeSearchProductQuantity(quantity, searchProduct), 2000)
        })
    }

    removeOneProductFromSearch = (e, searchProduct) => {
        let {searchProducts, searchProductsQuantity} = this.state
        let quantity = parseInt(searchProductsQuantity[searchProduct.product_id])
        let shoppingCartIdOfProductBeingUpdated = searchProduct.product_id
        
        quantity -= 1
        quantity = quantity <=0 ? 0 : quantity
        searchProductsQuantity[searchProduct.product_id] = quantity

        searchProducts.forEach((product, index)=>{
            if(product.product_id === searchProduct.product_id){
                product.quantity = parseInt(quantity)
                product.total = parseInt(quantity) * product.price
            }
        })

        
        this.setState({ searchProducts, searchProductsQuantity, shoppingCartButtonsDisabled: true, shoppingCartIdOfProductBeingUpdated, shoppingCartInputAndRemoveButtonDisabled: true}, ()=> {
            clearTimeout(this.productsUpdatingInterval)
            this.productsUpdatingInterval = setTimeout(()=>this.changeSearchProductQuantity(quantity, searchProduct), 2000)
        })
    }

    changeSearchProductQuantity = async (quantity, searchProduct) => {
        
        let idAttendance = this.props.idAttendance
        
        quantity = parseInt(quantity)
        if (quantity < 0 || isNaN(quantity)) quantity = 0
        
        let {searchProductsQuantity} = this.state
        searchProductsQuantity[searchProduct.product_id] = quantity

        this.setState({searchProductsQuantity, shoppingCartButtonsDisabled: true, shoppingCartInputAndRemoveButtonDisabled: true, shoppingCartIdOfProductBeingUpdated: null})
        
        if(quantity > 0) {
            let {shoppingCart, check} =  this.checkProductExistsInShoppingCartAndGetIt(searchProduct, quantity)
           
            searchProduct["total"] = quantity * searchProduct.price
            searchProduct["quantity"] = quantity
            if(!check){
                shoppingCart.push(searchProduct)
            }
            await api.post(`attendance/client/cart/send/${idAttendance}/add`, {product_id: searchProduct.product_id, quantity})
            .then((response) => response.data)
            .then((response) => {
                if(response["success"]){
                    this.setState({shoppingCart})
                }
                this.setState({shoppingCartButtonsDisabled: false, shoppingCartInputAndRemoveButtonDisabled: false})
            })
            .catch((error) => {
                console.log(error)
                this.setState({shoppingCartButtonsDisabled: false, shoppingCartInputAndRemoveButtonDisabled: false})
            })

        } else {
            await this.removeProductFromCart(searchProduct)
        }
    }
    
    checkProductExistsInShoppingCartAndGetIt = (searchProduct, quantity) => {
        let {shoppingCart} = this.state
        let check = false
        
        shoppingCart.forEach((cartProduct, index)=>{
            if(cartProduct.product_id === searchProduct.product_id){
                cartProduct.quantity = quantity
                cartProduct.total = quantity * parseFloat(searchProduct.price)
                check = 1 
            } 
        })
        return {shoppingCart, check}
    }

    updateCartProductQuantityInput = (quantity, cartProduct, e) => {
        quantity = parseInt(quantity)
        if (quantity < 0 || isNaN(quantity)) quantity = 0

        let {shoppingCart} = this.state
        let shoppingCartIdOfProductBeingUpdated = cartProduct.product_id
        shoppingCart.forEach(product=>{
            if(cartProduct.product_id === product.product_id){
                product.quantity = quantity
                product.total = quantity * parseFloat(product.price)
            }
        })

        this.setState({shoppingCart, shoppingCartButtonsDisabled: true, shoppingCartIdOfProductBeingUpdated, shoppingCartInputAndRemoveButtonDisabled: true}, ()=> {
            clearTimeout(this.productsUpdatingInterval)
            this.productsUpdatingInterval = setTimeout(()=>this.changeCartProductQuantity(quantity, cartProduct), 2000)
        })

    }

    addOneProductToCart = (cartProduct) => {
        let {shoppingCart} = this.state
        let quantity = parseInt(cartProduct.quantity)
        let shoppingCartIdOfProductBeingUpdated = cartProduct.product_id
        quantity += 1

       
        shoppingCart.forEach((product, index)=>{
            if(product.product_id === cartProduct.product_id){
                product.quantity = parseInt(quantity)
                product.total = parseInt(quantity) * product.price
            }
        })
        
        this.setState({ shoppingCart, shoppingCartButtonsDisabled: true, shoppingCartIdOfProductBeingUpdated, shoppingCartInputAndRemoveButtonDisabled: true}, ()=> {
            clearTimeout(this.productsUpdatingInterval)
            this.productsUpdatingInterval = setTimeout(()=>this.changeCartProductQuantity(quantity, cartProduct), 2000)
        })
    }

    removeOneProductToCart = (cartProduct) => {
        let {shoppingCart} = this.state
        let quantity = parseInt(cartProduct.quantity)
        let shoppingCartIdOfProductBeingUpdated = cartProduct.product_id
        quantity -= 1
        if(quantity <= 0) return
       
        shoppingCart.forEach((product, index)=>{
            if(product.product_id === cartProduct.product_id){
                product.quantity = parseInt(quantity)
                product.total = parseInt(quantity) * product.price
            }
        })
        
        this.setState({ shoppingCart, shoppingCartButtonsDisabled: true, shoppingCartIdOfProductBeingUpdated, shoppingCartInputAndRemoveButtonDisabled: true}, ()=> {
            clearTimeout(this.productsUpdatingInterval)
            this.productsUpdatingInterval = setTimeout(()=>this.changeCartProductQuantity(quantity, cartProduct), 2000)
        })
    }

    changeCartProductQuantity = async (quantity, cartProduct) => {
        let {shoppingCart, searchProductsQuantity} = this.state
 

        quantity = parseInt(quantity)
        if (quantity < 1 || isNaN(quantity)) quantity = 1
        
        let idAttendance = this.props.idAttendance
        
        shoppingCart.forEach(product=>{
            if(cartProduct.product_id === product.product_id){
                product.quantity = quantity
                product.total = quantity * parseFloat(product.price)
            }
        })

        if(searchProductsQuantity[cartProduct.product_id]) searchProductsQuantity[cartProduct.product_id] = quantity

        this.setState({shoppingCartButtonsDisabled: true, shoppingCartInputAndRemoveButtonDisabled: true, shoppingCartIdOfProductBeingUpdated: null})
        try {
            await api.post(`attendance/client/cart/send/${idAttendance}/add`, {product_id: cartProduct.product_id, quantity})
            .then((response) => response.data)
            .then((response) => {
                if(response["success"]){
                    this.setState({shoppingCart, searchProductsQuantity})
                }
            })
            .catch((error) => console.log(error))
        } catch (error) {
            console.log(error)
        }
        
        this.setState({shoppingCartButtonsDisabled: false, shoppingCartInputAndRemoveButtonDisabled: false})
    }

    sendPriceToClient = () => {
        let totalPrice = 0
        if(this.state.shoppingCart && this.state.shoppingCart.length > 0){
            totalPrice = this.state.shoppingCart.reduce((accumulator, currentValue)=>accumulator + currentValue.total, 0).toFixed(3).slice(0,-1)
        }
        this.props.handleMessageSubmit(`O valor total do seu carrinho está em R$ ${totalPrice}`, "clientHistory")
    }

    checkShoppingCartProductDisabled = (buttonGroupName, productId = null) => {
        let {shoppingCartIdOfProductBeingUpdated, shoppingCartButtonsDisabled, shoppingCartInputAndRemoveButtonDisabled} = this.state
        let buttonGroupDisabled = buttonGroupName === "shoppingCartButtons" ? shoppingCartButtonsDisabled : shoppingCartInputAndRemoveButtonDisabled
        let checkIsDisabled = false

        if(buttonGroupDisabled && shoppingCartIdOfProductBeingUpdated !== productId) {
            checkIsDisabled = true
        }
        return checkIsDisabled
    }

    returnStatusBT = (status) => {
        let newStatus
        switch(status){
            case 'in_attendance':
                newStatus = 'Em Atendimento'
                break;
            case 'awaiting_scripting':
                newStatus = 'Aguardando Roteirização'
                break;
            case 'sent_to_deliveryman':
                newStatus = 'Enviado para o Deliveryman'
                break;
            case 'awaiting_delivery_start':
                newStatus = 'Aguardando Inicio da Entrega'
                break;
            case 'on_delivery':
                newStatus = 'Em rota para Entrega'
                break;
            case 'finished':
                newStatus = 'Entrega Realizada'
                break;
            default:
                newStatus = status
                break;
        }
        return newStatus
    }

    componentDidMount(){
        this.getCartClient(this.props.idAttendance)
        $("body").click((e) => {
            if (e.target.id !== "attendance_cart_suggestions" && !$(e.target).parents("#attendance_cart_suggestions").length) {
                if (e.target.id !== "attendance_cart_search_form" && !$(e.target).parents("#attendance_cart_search_form").length) {
                    this.setState({suggestionsIsVisible: false})
                } else{
                    if(this.state.searchInput.length > 2) {
                        this.setState({suggestionsIsVisible: true})
                    }
                }
            }
        })

        this.getCartClient(this.props.idAttendance)
        this.getProductsSupermarket(this.props.idAttendance) 
    }   

    componentDidUpdate(prevProps, prevState){
        
        if(prevState.searchInput !== this.state.searchInput){
            if(this.state.searchInput.length > 2){
                this.getProductsSupermarket(this.props.idAttendance)
            } else {
                this.setState({searchProducts: []})
            }
        }

        if((prevProps.idAttendance !== this.props.idAttendance) || (prevProps.initCart !== this.props.initCart && this.props.initCart === true)){
            this.getCartClient(this.props.idAttendance)
            this.getProductsSupermarket(this.props.idAttendance)
        }
        

    }

    render = () => 
        <Fragment>
            <div className="row">
                <div className="col">
                    <div className="row">
                        <div className="col attendance_main_tab">
                            <div className="row px-2">
                                <div className="col text-left">
                                    <span className="main_tab_title text-left">{this.translate('ATTENDANCE_CART_SHOPPING_CART')}</span>
                                </div>
                                <div className="col d-flex justify-content-end">
                                    {(this.props.isCartStatusLoaded && this.props.cartStatus === "in_attendance" ) &&
                                        <React.Fragment>
                                            {(this.props.pausePurchase === null || this.props.pausePurchase === false) ?
                                                <button data-toggle="tooltip" title={this.translate("ATTENDANCE_ROOM_PAUSE_PURCHASE")} className="align-self-center d-flex justify-content-center btn btn-warning btn-cursor-pointer mr-2" onClick={()=>this.props.handleTogglePausePurchase()}>
                                                    {<i className="fa fa-stop"></i>}
                                                </button>
        
                                            :
                                                <button data-toggle="tooltip" title={this.translate("ATTENDANCE_ROOM_PLAY_PURCHASE")} className="align-self-center d-flex justify-content-center btn btn-success btn-cursor-pointer mr-2" onClick={()=>this.props.handleTogglePausePurchase()}>
                                                    {<i className="fa fa-play"></i>}
                                                </button>
                                            }
        
                                            {(this.props.blockCart === null || this.props.blockCart === false) ?
                                                <button data-toggle="tooltip" title={this.translate("ATTENDANCE_ROOM_BLOCK_CART")} className="align-self-center d-flex justify-content-center btn btn-danger btn-cursor-pointer mr-2" onClick={()=>this.props.handleToggleBlockCart()}>
                                                    <i className="fa fa-lock"></i>
                                                </button>
                                            :
                                                <button data-toggle="tooltip" title={this.translate("ATTENDANCE_ROOM_UNBLOCK_CART")} className="align-self-center d-flex justify-content-center btn btn-primary btn-cursor-pointer mr-2" onClick={()=>this.props.handleToggleBlockCart()}>
                                                    <i className="fa fa-unlock-alt"></i>
                                                </button>
                                            }
                                            
                                        </React.Fragment>
                                    }

                                    <button data-toggle="tooltip" title={this.translate("ATTENDANCE_ROOM_REMOVE_PURCHASE")} className="align-self-center d-flex justify-content-center btn btn-danger btn-cursor-pointer" onClick={e=>this.props.handleToggleRemovePurchase()}>
                                        <i className="fa fa-trash text-white" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row p-2">
                <div className="col-12">
                    <div className="row">
                        <div className="col-12">
                            <div className="row d-flex text-left">
                                <div className="w-100"><b>Numero do pedido:</b> {`${this.state.cartValuesOrder.request_number}`}</div>
                                <div className="w-100"><b>Data da Compra:</b> {`${this.state.cartValuesOrder.purchase_date}`}</div>
                                <div className="w-100"><b>Modalidade de Entrega:</b> {`${this.state.cartValuesOrder.delivery_method}`}</div>
                                <div className="w-100"><b>Status do Pedido:</b> {`${this.returnStatusBT(this.state.cartValuesOrder.order_status)}`}</div>
                                {
                                    (this.state.storesArray && this.state.storesArray.length > 0) && (
                                        <div className="w-100 mt-1">
                                            <table className="col-12 table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <td className="p-0 pb-1" style={{border: "none"}}  colSpan="2"><b>Lojas</b></td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>
                                                            Nome da Loja
                                                        </th>
                                                        <th>
                                                            Qtd. Itens
                                                        </th>
                                                        <th>
                                                            Status
                                                        </th>
                                                        <th>
                                                            Etiqueta
                                                        </th>
                                                    </tr>
                                                    {this.state.storesArray.map((store, index) => 
                                                        <React.Fragment key={index}>
                                                            <tr>
                                                                <td>{`${store.store}`}</td>
                                                                <td>{`${store.qtd_itens}`}</td>
                                                                <td>{`${store.status}`}</td>
                                                                <td>
                                                                    <a
                                                                        href={`${process.env.REACT_APP_API_URL_BASE}/v1/attendance/barcode/format/html?a=${store.idAttendance}&r=${store.idRequest}&o=${store.idOrder}`}
                                                                        className="btn theme-accent btn-shadow btn-cursor-pointer"
                                                                        style={{ width: '100%' }}
                                                                        target={`_blank`}
                                                                    >
                                                                        <i className="fa fa-barcode"></i>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">

                <div className="col-12 d-flex flex-column">

                    <div className="row p-0">
                        <div className="col-12 py-2 bg-dark">
                            <div className="row px-1">
                                <label htmlFor="">{this.translate('ATTENDANCE_CART_TOTAL_PURCHASE')}</label>
                                <div className="col-12 d-flex justify-content-between">
                                    {!this.state.isLoadingCart ? (
                                        <React.Fragment>
                                            <span className="lead lg">R$ {(this.state.shoppingCart && this.state.shoppingCart.length > 0 ? (
                                                this.state.shoppingCart.reduce((accumulator, currentValue)=>accumulator + currentValue.total, 0).toFixed(3).slice(0,-1)
                                            ) : (
                                                0
                                            )) }</span>
                                            <button className="align-self-center d-flex justify-content-center btn btn-outline-info btn-cursor-pointer ml-1" style={{width:"20px"}} onClick={e=>this.sendPriceToClient()}>
                                                <i className="fa fa-paper-plane-o text-white" data-iconname="fa-plus" />
                                            </button>
                                        </React.Fragment>

                                    ) : (
                                        <div className="py-2">
                                            <i className="fa fa-spinner fa-spin" /> {this.translate('ICON_LOADING')}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row p-0">
                        <div id="accordion_attendance_cart_tools" className="accordion col px-0 my-2">
                            <div className="card bg-transparent">
                                <div className="card-header btn-cursor-pointer bg-transparent" id="attendance_cart_tools_option_1" onClick={()=>this.props.toggleAccordionPannel("#collapse_attendance_cart_options_1")} aria-expanded="true" aria-controls="collapse_attendance_cart_options_1">
                                    <h6 className="mb-0 text-left collapsed">
                                        {this.translate('ATTENDANCE_CART_TOOLS')}
                                        <span className="float-right">
                                            <i className="fa fa-caret-down"></i>
                                        </span>
                                    </h6>
                                </div>
                                <div id="collapse_attendance_cart_options_1" className="collapse" aria-labelledby="attendance_cart_tools_option_1" data-parent="#accordion_attendance_cart_tools">
                                    <div className="card-body">
                                        <div className="row">
                                            {(this.props.pausePurchase === null || this.props.pausePurchase === false) ?
                                                <button className="btn btn-warning btn-shadow btn-cursor-pointer col-12 my-2" onClick={()=>this.props.changeCartStatus("pause", true)}>
                                                    {<i className="fa fa-stop"></i>} {this.translate("ATTENDANCE_ROOM_PAUSE_PURCHASE")}
                                                </button>

                                            :
                                                <button className="btn btn-success btn-shadow btn-cursor-pointer col-12 my-2" onClick={()=>this.props.changeCartStatus("pause", false)}>
                                                    {<i className="fa fa-play"></i>} {this.translate("ATTENDANCE_ROOM_PLAY_PURCHASE")}
                                                </button>
                                            }

                                            {(this.props.blockCart === null || this.props.blockCart === false) ?
                                                <button className="btn btn-danger btn-shadow btn-cursor-pointer col-12 my-2" onClick={()=>this.props.changeCartStatus("block", true)}>
                                                    <i className="fa fa-lock"></i> {this.translate("ATTENDANCE_ROOM_BLOCK_CART")}
                                                </button>
                                            :
                                                <button className="btn btn-primary btn-shadow btn-cursor-pointer col-12 my-2" onClick={()=>this.props.changeCartStatus("block", false)}>
                                                    <i className="fa fa-unlock-alt"></i> {this.translate("ATTENDANCE_ROOM_UNBLOCK_CART")}
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {
                        (this.state.business_type === 'shopping') ? (
                            <Fragment></Fragment>
                        ) : (
                            <div className="row px-1">
                        
                                <div className="col-12 py-2">
                                    <form className="" onSubmit={(e) => e.preventDefault()}>
                                        <div className="row">
                                            <label htmlFor="">{this.translate('ATTENDANCE_CART_CLIENT_CART_URL')}</label>
                                            <input 
                                                type="text" 
                                                className="form-control col-12" 
                                                readOnly
                                                placeholder={`${this.translate('ICON_LOADING')}...`}
                                                defaultValue={this.state.shoppingCartUrl} 
                                            />
                                        </div>
                                    </form>
                                </div>
                                <div className="col-12">
                                    <form id="attendance_cart_search_form" onSubmit={(e) => e.preventDefault()}>
                                        <div className="row">
                                            <label htmlFor="attendance_cart_search_bar_input">{this.translate('ATTENDANCE_CART_PRODUCTS_ADDITION')}</label>
                                            <input 
                                                type="text" 
                                                className="form-control col-12"
                                                id="attendance_cart_search_bar_input"  
                                                placeholder={this.translate('ATTENDANCE_CART_SEARCH_PRODUCTS')} onChange={e=>this.setState({searchInput: e.target.value})} 
                                                defaultValue={this.state.searchInput} 
                                            />
                                        </div>
                                    </form>
                                    <div id="attendance_cart_suggestions" className={`list-group search_bar_suggestions bg-white text-dark ${this.state.suggestionsIsVisible ? "d-block" : "d-none"}`}>
                                        {this.state.searchProducts.length > 0 ? (
                                            this.state.searchProducts.map((product, index, products)=>
                                                <div className="col-12 list-group-item" key={product.product_id}>
                                                    <div className="row">
                                                        <i className="col-1 p-0 d-flex fa fa-check align-self-center justify-content-center" style={{ fontSize: "20px", color: this.state.searchProductsQuantity[product.product_id] > 0 ? "#28a745" :"#ededed"}} />
                                                        <div className="col d-flex flex-column text-left">
                                                            <div className="row">
                                                                <div className="col flex-grow-1">
                                                                    <b>{product.name}</b>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col flex-grow-1">
                                                                    {this.getCategoryByKey(product.category)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="row">
                                                                <div className="col pr-1 text-right">
                                                                    <span><b>{this.getCointMasked(product.price)}</b></span>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12 d-flex p-0 pr-1 justify-content-end">
                                                                    <button disabled={`${this.checkShoppingCartProductDisabled("shoppingCartButtons", product.product_id) ? "disabled" : ""}`} className="align-self-center d-flex justify-content-center btn btn-danger btn-cursor-pointer mr-1" onClick={e=>this.removeOneProductFromSearch(e, product)} style={{width:"20px"}}>
                                                                        <i className="fa fa-minus text-white" data-iconname="fa-minus" />
                                                                    </button>
                                                                    <form onSubmit={(e) => e.preventDefault()}>
                                                                        <input disabled={`${this.checkShoppingCartProductDisabled("shoppingCartInputAndRemoveButton", product.product_id) ? "disabled" : ""}`} type="number" value={this.state.searchProductsQuantity[product.product_id]} onChange={e=>this.updateSearchProductQuantityInput(e.target.value, product, products)} className="form-control attendance_cart_search_quantity" min={0} style={{padding: "6px 0", minWidth: 32, maxWidth: 40}} />
                                                                    </form>
                                                                    <button disabled={`${this.checkShoppingCartProductDisabled("shoppingCartButtons", product.product_id) ? "disabled" : ""}`} className="align-self-center d-flex justify-content-center btn btn-danger btn-cursor-pointer ml-1" onClick={e=>this.addOneProductFromSearch(e, product)} style={{width:"20px"}}>
                                                                        <i className="fa fa-plus text-white" data-iconname="fa-plus" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        ) : (
                                            this.state.searchInput.length > 2 && (
                                                this.state.isLoadingProducts ? (
                                                    <div className="col-12 list-group-item text-muted">{this.translate('ICON_LOADING')}...</div>

                                                ) : (
                                                    <div className="col-12 list-group-item text-muted">{this.translate('ATTENDANCE_CART_NO_RESULT_WITH_THESE_TERMS')}</div>
                                                )
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    
                    <div className="row">
                        <div className="col-12 mt-2 px-0 text-center">
                            {this.state.shoppingCart.length > 0 ? (
                                <div className="list-group">
                                    {this.state.shoppingCart.map((product, index)=>
                                        <div className="col-12 list-group-item" key={"cart" + index + product.product_id}>
                                            <div className="row">
                                                <div className={`col-2 ${this.props.checkScreenWidthIsOnInterval(766, 1275) ? "d-none" : "d-flex"} pl-1`}>
                                                    <img className="img img-fluid align-self-center" src={product.image} alt=""/>
                                                </div>
                                                <div className="col d-flex flex-column text-left">
                                                    <div className="row">
                                                        <div className="col pl-0 flex-grow-1">
                                                            <b>{(product.quantity) ? `${product.quantity}x - ` : ``}{product.name}</b>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col pl-0 flex-grow-1">
                                                            {this.getCategoryByKey(product.category)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="row">
                                                        <div className="col p-0 pr-2 text-right">
                                                            <span><b>{this.getCointMasked(product.total)}</b></span>
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.business_type !== 'shopping' && (
                                                            <div className="row">
                                                                <div className="col-12 d-flex p-0 pr-2 justify-content-end">
                                                                    <button disabled={`${this.checkShoppingCartProductDisabled("shoppingCartButtons", product.product_id) ? "disabled" : ""}`} className="align-self-center d-flex justify-content-center btn btn-danger btn-cursor-pointer mr-1" onClick={e=>this.removeOneProductToCart(product)} style={{width:"20px"}}>
                                                                        <i className="fa fa-minus text-white" data-iconname="fa-minus" />
                                                                    </button>
                                                                    <form onSubmit={(e) => e.preventDefault()}>
                                                                        <input disabled={`${this.checkShoppingCartProductDisabled("shoppingCartInputAndRemoveButton", product.product_id) ? "disabled" : ""}`} type="number" value={product.quantity} onChange={e=>this.updateCartProductQuantityInput(e.target.value, product, e)} className="form-control attendance_cart_search_quantity" min={0} style={{padding: "6px 0", minWidth: 32, maxWidth: 40}} />
                                                                    </form>
                                                                    <button disabled={`${this.checkShoppingCartProductDisabled("shoppingCartButtons", product.product_id) ? "disabled" : ""}`} className="align-self-center d-flex justify-content-center btn btn-danger btn-cursor-pointer ml-1" onClick={e=>this.addOneProductToCart(product)} style={{width:"20px"}}>
                                                                        <i className="fa fa-plus text-white" data-iconname="fa-plus" />
                                                                    </button>
                                                                    <button disabled={`${(this.state.shoppingCartButtonsDisabled || this.state.shoppingCartInputAndRemoveButtonDisabled) ? "disabled" : ""}`} className="align-self-center d-flex justify-content-center btn btn-danger btn-cursor-pointer pull-right ml-2" onClick={e=>this.removeProductFromCart(product, e)} style={{width:"20px"}}>
                                                                        <i className="fa fa-times text-white" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                this.state.isLoadingCart ? (
                                    <div className="py-2">
                                        <i className="fa fa-spinner fa-spin"></i> {this.translate('ICON_LOADING')}
                                    </div>
                                ) : (
                                    <div className="py-2">
                                        {this.translate('ATTENDANCE_CART_THERE_ARE_NO_ITEMS_IN_YOUR_CART')}
                                    </div>
                                )
                            )}
                            {(!this.state.isLoadingCart && this.state.allSubOrdersInvoiced)&& (
                                <button onClick={()=>this.props.toggleCurrentTab("teamTab")} className="btn btn-info btn-shadow btn-cursor-pointer" style={{ width: '100%', borderRadius: 0 }}>
                                    <i className="fa fa-shopping-cart"></i> {this.translate('ATTENDANCE_CART_ATTENDANCE_TEAM')}
                                </button>
                            )}
                        </div>
                    </div>

                    
                </div>
            </div>
        </Fragment>
}
    
   