import React, { Component, Fragment } from "react"
import Chart from 'chart.js'
import {app, hexToRGB} from '../../../../Structure/Configs/Theme'

export default class MetricComparisonTotalBarChart extends Component {
    constructor(props){
        super(props)

        this.state = {}
        this.translate = this.props.translate
    }

    getLabels = () => {
        let { comparisonJsonStructure } = this.props
        let labels = comparisonJsonStructure.map(comparison=>comparison.values.title)
        return labels
    }

    getData = () => {
        let { comparisonJsonStructure } = this.props
        let data = comparisonJsonStructure.map(comparison=>comparison.values.value)
        return data
    }

    componentDidMount = () => {
        const myChart = document.getElementById('chart_metric_comparison_total_bar_chart').getContext('2d')

        new Chart(myChart, {
            type: "bar",
            data: {
                labels: this.getLabels(),
                datasets: [
                    {
                        label: "Total",
                        type: 'bar',
                        data: this.getData(),
                        fill: true,
                        backgroundColor: hexToRGB(app.color.primary, 0.2),
                        borderColor: hexToRGB(app.color.primary, 1),
                        borderWidth: 2,
                        borderJoinStyle: 'miter',
                        pointBorderColor: hexToRGB(app.color.primary, 1),
                        pointBackgroundColor: '#fff',
                        pointBorderWidth: 2,
                        pointHoverRadius: 2,
                        pointHoverBackgroundColor: hexToRGB(app.color.primary, 1),
                        pointHoverBorderColor: '#fff',
                        pointHoverBorderWidth: 1,
                        pointRadius: 3
                    }
                ]
            },
            options: {}
        })
    }

    
    render = () => 
    <div className="col-12">
        <div className="padding bg-blur_w position10">
            <div className="d-flex">
                <p className="uppercase text-muted f10">{this.translate("DASHBOARD_PERFORMANCE_COMPARISON_METRIC_COMPARISON_IN_BAR_CHART")}</p>
            </div>
            <canvas id="chart_metric_comparison_total_bar_chart" width={624} height={300} className="chartjs-render-monitor position10"/>
        </div>
    </div>
}