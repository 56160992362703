import React, { Component, Fragment } from 'react';
import {withRouter} from 'react-router-dom'

import Modal from 'react-bootstrap/Modal';

import api from '../../../Services/Api';

import Portals from './Portals/Portals';

import Smartlook from './Smartlook/Smartlook';

import $ from "jquery"
import EmailNotification from './Emails/EmailNotification';

class IntegrationWebSites extends Component {

    constructor(props){
        super(props)
        this.state = {
            showCodigo: false,
            showPortais: false,
            showSmartlook: false,
            showEmailNotification: false,
            code: null,
        }
        this.handleShowCodigo = this.handleShowCodigo.bind(this)
        this.handleCloseCodigo = this.handleCloseCodigo.bind(this)

        this.handleShowPortais = this.handleShowPortais.bind(this)
        this.handleClosePortais = this.handleClosePortais.bind(this)

        this.handleShowSmartlook = this.handleShowSmartlook.bind(this)
        this.handleCloseSmartlook = this.handleCloseSmartlook.bind(this)
        this.translate = this.props.translate
    }

    handleShowCodigo(){
        this.setState({showCodigo: true})
    }

    handleCloseCodigo(){
        this.setState({showCodigo: false})
    }

    handleShowPortais(){
        this.setState({showPortais: true})
    }
    
    handleClosePortais(){
        this.setState({showPortais: false})
    }
    
    handleShowEmailNotification = () => this.setState({showEmailNotification: true})

    handleCloseEmailNotification = () => this.setState({showEmailNotification: false})
    
    handleShowSmartlook(){
        this.setState({showSmartlook: true})
    }

    handleCloseSmartlook(){
        this.setState({showSmartlook: false})
    }

    toggleCollapse = (selector) => $(selector).collapse("toggle")

    componentDidMount(){
        api.get('/company/bussines/code/monitoring').then(async response => {
            this.setState({code: response.data.code});
        })
    }

    render() {
        return (
            <Fragment>
                <div className="row pb40">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <h4 className="text-center mt20 mb20">
                            <i className="fa fa-cubes"></i> {this.translate('INTEGRATION_WEB_SITES_WEBSITES')}
                        </h4>
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <div className="text-center box-shadow border-radius warning ">
                            <div className="p-4 ">
                                <p className="f64 m0 text-white">
                                    <i className="fa fa-list-alt"></i>
                                </p>
                                <h5 className="text-md f24 d-block f600 fwhite mb20">
                                    {this.translate('INTEGRATION_WEB_SITES_MONITORING_CODE')}
                                </h5>
                                <button onClick={this.handleShowCodigo} className="btn btn-info info btn-rounded text-white btn-shadow">
                                    <i className="fa fa-plus"></i> {this.translate('INTEGRATION_WEB_SITES_VISUALIZE_CODE')}
                                </button>
                                <br/><br/>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <div className="text-center box-shadow border-radius blue">
                            <div className="p-4 ">
                                <p className="f64 m0 text-white">
                                    <i className="fa fa-eye"></i>
                                </p>
                                <h5 className="text-md f24 d-block f600 fwhite mb20">
                                    {this.translate('INTEGRATION_WEB_SITES_SMARTLOOK')}
                                </h5>
                                <button onClick={this.handleShowSmartlook} className="btn btn-info info btn-rounded text-white btn-shadow">
                                    <i className="fa fa-cogs"></i> {this.translate('INTEGRATION_WEB_SITES_CONFIGURE_SMARTLOOK')}
                                </button>
                                <br/><br/>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <div className="text-center box-shadow border-radius red">
                            <div className="p-4 ">
                                <p className="f64 m0 text-white">
                                    <i className="fa fa-plus-square-o"></i>
                                </p>
                                <h5 className="text-md f24 d-block f600 fwhite mb20">
                                    {this.translate('INTEGRATION_WEB_SITES_PORTALS')}
                                </h5>
                                <button onClick={this.handleShowPortais} className="btn btn-info info btn-rounded text-white btn-shadow">
                                    <i className="fa fa-cogs"></i> {this.translate('INTEGRATION_WEB_SITES_CONFIGURE_PORTALS')}
                                </button>
                                <br/><br/>
                            </div>
                        </div>
                    </div>
                    
                </div>

                <div className="row pb40">
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <div className="text-center box-shadow border-radius info">
                            <div className="p-4 ">
                                <p className="f64 m0 text-white">
                                    <i className="fa fa-envelope-o"></i>
                                </p>
                                <h5 className="text-md f24 d-block f600 fwhite mb20">
                                    {this.translate('INTEGRATION_WEB_SITES_EMAIL_NOTIFICATIONS')}
                                </h5>
                                <button onClick={this.handleShowEmailNotification} className="btn blue btn-rounded text-white btn-shadow">
                                    <i className="fa fa-bell"></i> {this.translate('INTEGRATION_WEB_SITES_MANAGE_NOTIFICATIONS')}
                                </button>
                                <br/><br/>
                            </div>
                        </div>
                    </div>

                </div>

                <Modal show={this.state.showCodigo} onHide={this.handleCloseCodigo} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink warning text-center position10">
                                <h5 className="h5 m-0 text-center width100 line-height-38px">
                                    <i className="fa fa-clone"></i> {this.translate('INTEGRATION_WEB_SITES_MONITORING_CODE')}
                                </h5>
                                <button onClick={this.handleCloseCodigo} className="btn btn-transparent ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </button>
                            </div>
                            <div className="p-3 b-b d-flex no-shrink text-center position10">
                                <textarea className="form-control" rows="3" defaultValue={this.state.code} disabled></textarea>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showSmartlook} onHide={this.handleCloseSmartlook} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <Smartlook translate={this.translate} handleCloseSmartlook={this.handleCloseSmartlook}/>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showPortais} onHide={this.handleClosePortais} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <Portals translate={this.translate} toggleCollapse={this.toggleCollapse} handleClosePortais={this.handleClosePortais}/>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showEmailNotification} onHide={this.handleCloseEmailNotification} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <EmailNotification translate={this.translate} toggleCollapse={this.toggleCollapse} handleCloseEmailNotification={this.handleCloseEmailNotification}/>
                    </Modal.Body>
                </Modal>

            </Fragment>
        );
    }
}

export default withRouter(IntegrationWebSites);