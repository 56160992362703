import React, {Component} from 'react';

import {getCompanyName} from '../Auth/AuthConfig';

export class ListCompanies extends Component{

    constructor(props){
        super(props);
        this.state = {
            company_name: getCompanyName()
        }
        this.translate = this.props.translate
    }

    render(){
        return(
            <ul className="navbar-nav mt-2 mt-lg-0 mx-0 mx-lg-2 text-hover-primary">
                <li>
                    <b>{this.translate('LIST_COMPANIES_COMPANY')}:</b> {this.state.company_name}
                </li>
            </ul>
        )
    }
}

export default ListCompanies;