import "./Assets/AttendanceRoom.css"
import React, { Component, Fragment } from 'react';
import MenuMain from '../../Structure/MenuMain';
import HeaderMain from '../../Structure/HeaderMain';
import {withRouter} from 'react-router-dom';

import $ from "jquery"
import 'jquery-mask-plugin';
import "bootstrap"

import api from '../../Services/Api';

import {attendanceUrlWebSocket, attendanceUrlBase} from '../../Services/Config';

import photoVideo from "./Assets/photo-video-solid.png"
import Modal from 'react-bootstrap/Modal';

import AttendanceCart from './Components/AttendanceCart';
import AttendanceChat from './Components/AttendanceChat';
import AttendanceContacts from './Components/AttendanceContacts';
import AttendanceLeadMerge from './Components/AttendanceLeadMerge';
import AttendanceLog from './Components/AttendanceLog';
import AttendanceOptions from './Components/AttendanceOptions';
import notificationAudio from "./Assets/notification.mp3"
import loudRingtone from "./Assets/loud_ringtone.mp3"
import discreteRingtone from "./Assets/discrete_ringtone.mp3"

//const Device = window.Twilio.Device;

var device;

class AttendanceRoom extends Component {

    constructor(props){
        super(props)
        this.translate = this.props.translate
        document.title = this.translate('ATTENDANCE_ROOM_ATTENDANCE_ROOM')
        this.state = {
            users: [],
            newAttendanceContacts: [],
            inAttendanceContacts: [],
            finishedContacts: [],
            archivedContacts: [],
            messages: {
                userId: null,
                userRequest: null,
                userName: null,
                userPicture: null,
                userHistory: [],
                call_status: false,
            },
            currentChannel: null,
            clientHistory: [],
            shopperHistory: [],
            deliverymanHistory: [],
            teamHistory: [],
            chatTabs: {
                currentTab: null,
                visibleTabs: []
            },
            clientInfo: {
                attendancesQuantity: null,
                lastAttendanceDate: null,
                currentChannel: null,
                attendanceTime: {
                    onHold: null,
                    total: null
                },
                campaign: {
                    type: null
                },

                usedChannels: []
            },
            clientBuyer: {
                document: "",
                postalCode: "",
                street: "",
                neighborhood: "",
                city: "",
                state: "",
                addressNumber: "",
                addressComplement: "",
                supermarketSelectedId: "",
                allFulfilled: false,
                addressInfoFulfilled: false,
                supermarketList: []
            },
            clientBuyerHistory: {
                isClient: false,
                isLoading: false,
                supermarkets: []

            },
            clientTeam: {
                needShopperHelp: "",
                commentToTeam: "",
                shopperSelected: "",
                deliverymanSelected: "",
                deliveryType: "",
                deliveryDate: "",
            },

            formUpdateCommercialTeam: {
                shopper: "",
                deliveryman: "",
            },

            formUpdateDeliverymanAndStatus: {
                deliveryman: "",
            },

            initBuy: false,
            initCart: false,
            initTeam: false,

            lead_data: {
                name: "",
                email: "",
                phone: "",
                emails: [],
                phones: []
            },
            tools: {
                idAttendance: null,
                postalCode: "",
                businessCode:null,
                businessList: []
            },
            request: null,
            requests: [],
            requestTools: {
                postalCode: "",
                businessCode:null,
                businessList: []
            },
            supermarkets: [],
            formRequestCurrent: {
                shopper: null,
                deliveryman: null
            },
            formSendFeedback: {
                contact_description: null,
                contact_reason: null
            },
            feedbacks: [],
            modalImageShow: false,
            modalVideoShow: false,
            audioIsRecording: false,
            videoIsRecording: false,
            
            mediaAudioRecorder: {},
            mediaVideoRecorder: {},
            videoStreamRecorder: null,

            isModalLeadMergeVisible: false,

            modalResponseShow: false,
            msgResponseShow: "",

            isModalCallVisible: false,
            isLeadInCall: false,
            contactsMessagesCount: {},
            notificationTabs: [],
            
            attendanceContactsRefreshToggler: false,

            cartStatus: null,
            blockCart: null,
            pausePurchase: null,
            isCartStatusLoaded: false,

            isConfirmModalVisible: false,
            confirmModalMessage: "",

            usedChannelsMockup: [
                /* {
                    origin: "whatsapp",
                    expiration_date: "22/02/2020",
                    last_used_date: "21/02/2020"
                },
                {
                    origin: "chat",
                    last_used_date: "21/02/2019",
                },
                {
                    origin: "facebook",
                    last_used_date: "21/02/2019",
                },
                {
                    origin: "twitter",
                    last_used_date: "21/02/2019",
                },
                {
                    origin: "instagram",
                    last_used_date: "21/02/2019",
                }, */
            ],

            statusRequestCheck: null,
            
            allSubOrdersInvoiced: false

        }
        this.confirmModalCallback = null
        this.loadUsers = this.loadUsers.bind(this)

        this.loadAttendanceContacts = this.loadAttendanceContacts.bind(this)
        this.loadAttendanceMessages = this.loadAttendanceMessages.bind(this)

        //this.socket = new WebSocket('wss://attendance.development.buylitics.in/wss/')
        //this.socket = new WebSocket(`${attendanceUrlWebSocket}`)
        this.socket = null

        this.reloadMSG = null

        this.chatHeight = document.getElementById("attendance_row_messages_chat")
        this.scrollTimeInterval = null
        this.canScroll = true

        this.ringtoneAudio = null
        this.notification = null

    }

    handleCloseModalResponse = (e = null) => {
        if (e) e.preventDefault()
        this.setState({modalResponseShow: false})
    }
    
    handleCloseModalCall = (e = null) => {
        if (e) e.preventDefault()
        this.setState({isModalCallVisible: false})
    }
    
    handleCloseConfirmModal = (e = null) => {
        if (e) e.preventDefault()
        this.setState({isConfirmModalVisible: false})
    }

    handleShowMessageModalResponse = (message) => {
        this.setState({modalResponseShow: true, msgResponseShow: message})
    }

    scrollChatBottom = (time = 500, withInterval = true) => {
        let chat = document.querySelector("#attendance_row_messages_chat")
        
        this.scrollTimeInterval = setInterval(()=>{
            if(!chat){
                clearInterval(this.scrollTimeInterval)
                return
            }else if(this.chatHeight !== chat.scrollHeight){
                if(this.canScroll){
                    this.chatHeight = chat.scrollHeight
                    chat.scrollTop = chat.scrollHeight
                }
                clearInterval(this.scrollTimeInterval)
            }
        }, time)
    
        
    }

    scrollToHeight = () => {
        
        setTimeout(()=> {
            if(this.canScroll){
                let chat = document.querySelector("#attendance_row_messages_chat")
                if(chat){
                    this.chatHeight = chat.scrollHeight
                    chat.scrollTop = chat.scrollHeight
                }
            }
        }, 1000)

        setTimeout(()=> {
            if(this.canScroll){
                let chat = document.querySelector("#attendance_row_messages_chat")
                if(!chat) return
                this.chatHeight = chat.scrollHeight
                chat.scrollTop = chat.scrollHeight
            }
        }, 4000)
    }
    
    getDate = (dateValue) => {
        let dateString = ""
        //dateValue = "2020-08-10T03:00:00+00:00"
        let date = new Date(dateValue)
        let now = new Date()

        let timeDifference = now.getTime() - date.getTime(); 
        let dayDifference = timeDifference / (1000 * 3600 * 24); 

        if(dayDifference < 1){
            let newDate = this.formatDateToBrazilianFormat(dateValue, true)
            dateString = newDate.split(" ")[1]
    
        } else if(dayDifference > 1 && dayDifference < 2){
            dateString = this.translate("ATTENDANCE_ROOM_YESTERDAY")
        
        } else {
            dateString = this.formatDateToBrazilianFormat(dateValue)
        }
    
        return dateString 
    }

    handleContactClick = (e, id) => {
        
        if(this.reloadMSG !== null){
            clearInterval(this.reloadMSG)
        }
        
        this.clearUserMessages()
        this.activeContactRow(e)
        this.loadAttendanceMessages(id)
        this.loadAttendanceContacts()

        this.reloadMSG = setInterval(() => {
            this.loadAttendanceMessagesHistory(id)
        }, 10000)
    }

    clearUserMessages = () => {
        this.clearVisibleTabs()
        this.setState({
            allSubOrdersInvoiced: false,
            messages: {
                userId: null,
                userRequest: null,
                userName: null,
                userPicture: null,
                userHistory: [],
                call_status: false
            }
        })
    }

    addClientMessage = (data) => {
        
        const msgAppend = {
            date: new Date(),
            is_client: true,
            is_support: false,
            msg: data.message,
            user_name: this.translate('ATTENDANCE_ROOM_NAME_NOT_INFORMED')
        }

        let history
        switch(this.state.currentChannel){
            case "clientHistory":
                let {clientHistory} = this.state
                history = clientHistory
                break
            case "shopperHistory":
                let {shopperHistory} = this.state
                history = shopperHistory
                break
            case "deliverymanHistory":
                let {deliverymanHistory} = this.state
                history = deliverymanHistory
                break
            case "teamHistory":
                let {teamHistory} = this.state
                history = teamHistory
                break
            default:
                break;
        }
        
        if(history.length){
            history[(history.length - 1)]['msgs'].push(msgAppend)
        }else{
            history.push({
                date: new Date().toLocaleDateString(),
                msgs: [
                    msgAppend
                ]
            })
        }

        switch(this.state.currentChannel){
            case "clientHistory":
                this.setState({clientHistory: history})
                break
            case "shopperHistory":
                this.setState({shopperHistory: history})
                break
            case "deliverymanHistory":
                this.setState({deliverymanHistory: history})
                break
            case "teamHistory":
                this.setState({teamHistory: history})
                break
            default:
                break;
        }
        this.scrollChatBottom()

    }

    handleMessageSubmit = async (value = null, currentChannel = null) => {

        if(typeof this.socket === 'undefined' || this.socket.readyState !== 1){
            //this.socket = new WebSocket(`${attendanceUrlWebSocket}`)
            this.connectWebsocketAttendance()
            setTimeout(() => {
                if(this.socket.readyState === 1){
                    this.socket.send(
                        JSON.stringify({
                            environment: 'attendance_app',
                            action: 'connect',
                            idAttendance: this.state.messages.userId,
                            idRequest: this.state.messages.userRequest,
                            userType: 'attendance'
                        })
                    );
                }
            }, 1000)
            this.handleShowMessageModalResponse(this.translate("ATTENDANCE_ROOM_AN_ERROR_OCURRED_SENDING_YOUR_MESSAGE_TRY_AGAIN"))
            return;
        }

        let message
        let channel
        if(!value) {
            message = document.querySelector("#attendance_chat_textarea").value
            channel = this.state.currentChannel
        } else {
            channel = currentChannel
            message = value
        }

        const msgAppend = {
            date: new Date(),
            is_client: false,
            is_support: true,
            msg: message,
            user_name: this.translate('ATTENDANCE_ROOM_NAME_NOT_INFORMED')
        }

        let history
        switch(channel){
            case "clientHistory":
                let {clientHistory} = this.state
                history = clientHistory
                break
            case "shopperHistory":
                let {shopperHistory} = this.state
                history = shopperHistory
                break
            case "deliverymanHistory":
                let {deliverymanHistory} = this.state
                history = deliverymanHistory
                break
            case "teamHistory":
                let {teamHistory} = this.state
                history = teamHistory
                break
            default:
                break;
        }

        if(history.length){
            history[(history.length - 1)]['msgs'].push(msgAppend)
        }else{
            history.push({
                date: new Date().toLocaleDateString(),
                msgs: [
                    msgAppend
                ]
            })
        }
        
        
        switch(channel){
            case "clientHistory":
                this.setState({clientHistory: history})
                channel = "client"
                break
            case "shopperHistory":
                this.setState({shopperHistory: history})
                channel = "service_team"
                break
            case "deliverymanHistory":
                this.setState({deliverymanHistory: history})
                channel = "service_team"
                break
            case "teamHistory":
                this.setState({teamHistory: history})
                channel = "service_team"
                break
            default:
                break;
        }

        this.socket.send(
            JSON.stringify({
                environment: 'attendance_app',
                action: 'send_message',
                channel: channel,
                typeContent: 'message',
                messageContent: message,
                idAttendance: this.state.messages.userId,
                idRequest: this.state.messages.userRequest,
                userType: 'attendance'
            })
        );

        if(!value) document.querySelector("#attendance_chat_textarea").value = ""
        
        this.scrollChatBottom()

    }

    activeContactRow = (e) => {

        let contacts = document.querySelectorAll(".active_contact")
        for(let i = 0; i < contacts.length; i++){
            contacts[i].classList.remove("active_contact")
        }
        e.currentTarget.classList.add("active_contact")

    }

    getStatus = (status) => {

        let statusMessage = ""

        switch (status){
            case "in_attendance":
                statusMessage = this.translate('ATTENDANCE_ROOM_IN_ATTENDANCE')
                break
            case "archived":
                statusMessage = this.translate('ATTENDANCE_ROOM_ARCHIVED')
                break
            default:
                statusMessage = this.translate('ATTENDANCE_ROOM_FINISHED')
                break
        }
        
        return statusMessage

    }

    getOriginIcon = (contactOrigin) => {
        let icon = ""

        switch (contactOrigin){
            case "whatsapp":
                icon = <i className="fa fa-whatsapp" aria-hidden="true"></i>
                break
            case "facebook":
                icon = <i className="fa fa-facebook" aria-hidden="true"></i>
                    break
            case "instagram":
                icon = <i className="fa fa-instagram" aria-hidden="true"></i>
                break
            case "twitter":
                icon = <i className="fa fa-twitter" aria-hidden="true"></i>
                break
            case "email":
                icon = <i className="fa fa-envelope-o" aria-hidden="true"></i>
                break
            case "phone":
                    icon = <i className="fa fa-phone" aria-hidden="true"></i>
                    break
            default:
                icon = <i className="fa fa-comment-o" aria-hidden="true"></i>
                break
        }

        return icon

    }

    getFeedback = async (e) => {
        await api.get(`/attendance/feedback/get/${this.state.messages.userId}`)
            .then((response) => response.data)
            .then((data) => {
                if(data.feedbacks && data.feedbacks.length){
                    data.feedbacks.sort(function(a, b){
                        if(a.index > b.index)
                            return -1
                        if(a.index < b.index)
                            return 1;
                        return 0;
                    })
    
                    this.setState({
                        feedbacks: data.feedbacks
                    })
                }

            })
            .catch((error) => {
                console.log(error)
            })
    }

    checkFeedbackHasEmptyFields = () => {
        let {contact_description, contact_reason} = this.state.formSendFeedback
        let check = false

        if (!contact_description) check = true
        if (!contact_reason) check = true
        return check
    }
    
    sendFeedback = async (e) => {
        e.preventDefault()
        if(this.checkFeedbackHasEmptyFields()) return
        const data = this.state.formSendFeedback

        let element = e.target

        try{
            await api.post(`/attendance/feedback/create/${this.state.messages.userId}`, data)
                .then((response) => response.data)
                .then((data) => {
                    if(typeof data.success !== 'undefined' && data.success){
                        this.handleShowMessageModalResponse(this.translate('ATTENDANCE_ROOM_REASON_FOR_CONTACT_SAVED_WITH_SUCCESS'))

                        const {feedbacks} = this.state

                        feedbacks.push({
                            index: new Date().getTime(),
                            reason: this.state.formSendFeedback.contact_reason,
                            description: this.state.formSendFeedback.contact_description,
                            created_at: `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`
                        })
                        
                        feedbacks.sort(function(a, b){
                            if(a.index > b.index)
                                return -1
                            if(a.index < b.index)
                                return 1;
                            return 0;
                        })

                        this.setState({
                            formSendFeedback: {
                                contact_reason: null,
                                contact_description: null
                            },
                            feedbacks: feedbacks
                        })

                        element.reset()
                        document.getElementById('textarea-attendance-feedback').value = ''

                    }else{
                        this.handleShowMessageModalResponse(this.translate('ATTENDANCE_ROOM_REASON_FOR_CONTACT_ERROR_REGISTERING'))
                        console.log(data)
                    }
                })
                .catch((error) => {
                    this.handleShowMessageModalResponse(this.translate('ATTENDANCE_ROOM_REASON_FOR_CONTACT_ERROR_REGISTERING'))
                    console.log(error)
                })
        }catch(error){
            this.handleShowMessageModalResponse(this.translate('ATTENDANCE_ROOM_REASON_FOR_CONTACT_ERROR_REGISTERING'))
            console.log(error)
        }
    }

    createNewRequest = (e) => {

        e.preventDefault()

        const data = {business_code: this.state.tools.businessCode}
        
        try{
            api.post(`/attendance/status/create/${this.state.messages.userId}`, data)
            .then((response) => response.data)
            .then((data) => {
                if(typeof data['success'] !== 'undefined' && data.success){
                    this.loadAttendanceMessages(this.state.messages.userId)
                }else{
                    if(typeof data['error'] !== 'undefined' && data.error) {
                        this.setState({tools: {...this.state.tools, postalCode: "", businessCode: "", businessList: []}})
                        this.handleShowMessageModalResponse(data.error) 
                    }
                }
                console.log(data)
            })
            .catch((error) => {
                console.log(error)
            })
        }catch(error){
            console.log(error)
        }

    }

    setStatusAttendance = (e, status) => {

        e.preventDefault()

        const {messages} = this.state

        messages['userStatus'] = status

        const data = {
            status: messages['userStatus']
        }
        
        try{
            api.post(`/attendance/status/set/value/${this.state.messages.userId}`, data)
            .then((response) => response.data)
            .then((data) => {
                this.loadAttendanceContacts()
                this.loadAttendanceMessages(this.state.messages.userId)
                console.log(data)
            })
            .catch((error) => {
                console.log(error)
            })
        }catch(error){
            console.log(error)
        }

    }

    checkValuesLeadDataHasEmptyFields = () => {
        let {name, email, phone} = this.state.lead_data
        let check = false

        if(!name) check = true
        if(!email) check = true
        if(!phone) check = true

        return check
    }

    submitValuesLeadData = (e) => {

        e.preventDefault()
        if(this.checkValuesLeadDataHasEmptyFields()) return
        const data = this.state.lead_data
        
        try{
            api.post(`/attendance/lead_data/set/values/${this.state.messages.userId}`, data)
            .then((response) => response.data)
            .then((data) => {
                this.handleShowMessageModalResponse(this.translate('ATTENDANCE_ROOM_LEAD_INFO_REGISTERED_WITH_SUCCESS'))
            })
            .catch((error) => {
                this.handleShowMessageModalResponse(this.translate('ATTENDANCE_ROOM_LEAD_INFO_NOT_POSSIBLE_TO_REGISTER'))
                console.log(error)
            })
        }catch(error){
            this.handleShowMessageModalResponse(this.translate('ATTENDANCE_ROOM_LEAD_INFO_NOT_POSSIBLE_TO_REGISTER'))
            console.log(error)
        }

    }

    changeCommercialTeam = (i, key, value) => {
        
        const {requests} = this.state
        requests[i][key] = value
        this.setState({
            requests: requests
        })

    }

    submitValuesRequest = (e, i, idAttendance, idRequest) => {
        e.preventDefault()

        const {requests} = this.state

        const data = requests[i]
        
        const status = data.status

        try{
            api.post(`/attendance/request/set/values/${idAttendance}/${idRequest}`, data)
            .then((response) => response.data)
            .then((data) => {
                console.log(data)
                if(typeof data['success'] !== 'undefined' && data.success){
                    if(status === 'is_finished') requests[i]['status'] = 'finished'
                    this.setState({
                        requests: requests
                    })
                }
            })
            .catch((error) => {
                console.log(error)
            })
        }catch(error){
            console.log(error)
        }

    }

    loadUsers = async () => {
        await api.get("/attendance/users")
        .then(response => response.data)
        .then(response => {
            this.setState(
                {
                    users: response.users
                }
            )
        })
    }

    refreshAttendanceContacts = () => {
        this.setState({attendanceContactsRefreshToggler: !this.state.attendanceContactsRefreshToggler})
    }

    loadAttendanceContacts = async () => {
        await api.get("/attendance/contacts")
        .then(response => response.data)
        .then(response => {
            console.log(response)
            this.setState(
                {
                    newAttendanceContacts: response.attendance_new_attendance,
                    inAttendanceContacts: response.attendance_in_attendance,
                    inShopperContacts: response.attendance_in_shopper,
                    inScreenwriterContacts: response.attendance_in_screenwriter,
                    inDeliveryContacts: response.attendance_in_deliveryman,
                    finishedContacts: response.attendance_finished,
                    archivedContacts: response.attendance_archived,
                    attendanceOtherFilters: response.attendance_other_filters
                }, () => {
                    this.checkContactsNewMessages(response)
                    this.checkIsReceivingCall(response)
                }
            )
        })
        .catch((error) => {
            console.log(error)
        })
    }

    loadAttendanceMessages = async (id) => {

        await api.get(`/attendance/get/message/${id}`)
            .then(response => response.data)
            .then(response => {

                let idRequest = null

                for(var i = 0; i < response.requests.length; i++){
                    if(response.requests[i].status !== 'in_attendance')
                        continue
                    
                    idRequest = response.requests[i].request
                    break;
                }

                let userName = (response.lead_data.name) ? response.lead_data.name : ''
                let userPhone = (response.lead_data.phone) ? response.lead_data.phone : ''
                let userEmail = (response.lead_data.email) ? response.lead_data.email : ''

                let userEmails = (response.lead_data.emails) ? response.lead_data.emails : []
                let userPhones = (response.lead_data.phones) ? response.lead_data.phones : []

                this.getCartStatus(response.id)
                this.setState(
                    {
                        tools: {
                            ...this.state.tools,
                            postalCode: "",
                            businessCode: "",
                            businessList: [],
                            idAttendance: response.id
                        },
                        messages: {
                            userId: response.id,
                            userRequest: idRequest,
                            userName: response.user_name,
                            userStatus: response.user_status,
                            userContactOrigin: response.user_contact_origin,
                            userImage: response.profile_image,
                            userHistory: response.user_history,
                            userNumber: response.user_number,
                            call_status: response.call_status
                        },
                        currentChannel: "clientHistory",
                        clientHistory: response.clientHistory,
                        shopperHistory: response.shopperHistory,
                        deliverymanHistory: response.deliverymanHistory,
                        teamHistory: response.teamHistory,
                        lead_data: {
                            name: userName,
                            phone: userPhone,
                            email: userEmail,
                            phones: userPhones,
                            emails: userEmails
                        },
                        requests: response.requests,
                        requestTools: {
                            postalCode: "",
                            businessCode: "",
                            businessList: [],
                        }
                    }, ()=>{
                        this.getCommercialTeamFieldsFromRequest()
                    }
                )
                
                this.socket.send(
                    JSON.stringify({
                        environment: 'attendance_app',
                        action: 'connect',
                        idAttendance: response.id,
                        idRequest: idRequest,
                        userType: 'attendance'
                    })
                );

                this.loadAudioFunction()
                this.loadVideoFunction()
                
                console.log(response.inits)

                this.fetchAndloadTabsAccordingToInitStatus({
                    initBuy: response.inits.initBuy,
                    initCart: response.inits.initCart,
                    initTeam: response.inits.initTeam,
                    clientInfo: response.clientInfo,
                    success: true,
                })
                this.scrollToHeight()
                this.scrollChatBottom(4000)
            })
            .catch( error => {

                console.log(error)

                this.setState({
                    tools: {
                        ...this.state.tools,
                        postalCode: "",
                        businessCode: "",
                        businessList: [],
                        idAttendance: null
                    },
                    messages:{
                        userId: null,
                        userName: null,
                        userPicture: null,
                        userHistory: [],
                        call_status: false
                    },
                    clientHistory: [],
                    shopperHistory: [],
                    deliverymanHistory: [],
                    teamHistory: [],
                    lead_data: {
                        name: "",
                        email: "",
                        phone: "",
                        emails: [],
                        phones: [],
                    },
                    requests: [],
                    requestTools: {
                        postalCode: "",
                        businessCode: "",
                        businessList: [],
                    }
                })

                this.fetchAndloadTabsAccordingToInitStatus({
                    initBuy: false,
                    initCart: false,
                    initTeam: false,
                    clientInfo: {
                        attendancesQuantity: 0,
                        lastAttendanceDate: "00/00/0000",
                        currentChannel: "N/C",
                        attendanceTime: {
                            onHold: "N/C",
                            total: "N/C"
                        },
                        campaign: {
                            type: "N/C"
                        },
                        usedChannels: ["N/C"]
                    },
                    success: true,
                })

            })
    }

    loadAttendanceMessagesHistory = async (id) => {
        await api.get(`/attendance/get/message/${id}`)
            .then(response => response.data)
            .then(response => {

                let idRequest = null
                if(response.requests.length > 0){
                    for(var i = 0; i < response.requests.length; i++){
                        if(response.requests[i].status !== 'in_attendance')
                            continue
                        
                        idRequest = response.requests[i].request
                        break;
                    }

                }

                this.setState(
                    {
                        clientHistory: response.clientHistory,
                        shopperHistory: response.shopperHistory,
                        deliverymanHistory: response.deliverymanHistory,
                        teamHistory: response.teamHistory,
                        messages: {...this.state.messages, call_status: response.call_status}
                    }, this.scrollToHeight
                )

            })
            .catch( error => {
                console.log(error)
                this.setState({
                    clientHistory: [],
                    shopperHistory: [],
                    deliverymanHistory: [],
                    teamHistory: [],
                    messages: {...this.state.messages, call_status: false}
                })
            })
    }

    sendMediaWebsocket = async (url, type) => {
        let msgAppend = {
            date: new Date(),
            is_client: false,
            is_support: true,
            user_name: this.translate('ATTENDANCE_ROOM_NAME_NOT_INFORMED')
        }
        msgAppend[type] = url
        let history
        switch(this.state.currentChannel){
            case "clientHistory":
                let {clientHistory} = this.state
                history = clientHistory
                break
            case "shopperHistory":
                let {shopperHistory} = this.state
                history = shopperHistory
                break
            case "deliverymanHistory":
                let {deliverymanHistory} = this.state
                history = deliverymanHistory
                break
            case "teamHistory":
                let {teamHistory} = this.state
                history = teamHistory
                break
            default:
                break;
        }

        if(history.length){
            history[(history.length - 1)]['msgs'].push(msgAppend)
        }else{
            history.push({
                date: new Date().toLocaleDateString(),
                msgs: [
                    msgAppend
                ]
            })
        }

        switch(this.state.currentChannel){
            case "clientHistory":
                this.setState({clientHistory: history})
                break
            case "shopperHistory":
                this.setState({shopperHistory: history})
                break
            case "deliverymanHistory":
                this.setState({deliverymanHistory: history})
                break
            case "teamHistory":
                this.setState({teamHistory: history})
                break
            default:
                break;
        }
        
        this.scrollChatBottom(3000)

        this.socket.send(
            JSON.stringify({
                environment: 'attendance_app',
                action: 'send_message',
                channel: 'client',
                typeContent: type,
                messageContent: url,
                idAttendance: this.state.messages.userId,
                idRequest: this.state.messages.userRequest,
                userType: 'attendance'
            })
        );

        
    }
    
    sendHelpdeskMediaAndGetLink = async (formData) => {
        let data = false
        try {
            //let response = await fetch('https://attendance.development.buylitics.in/v1/attendance/client/send/media', {
            let response = await fetch(`${attendanceUrlBase}/v1/attendance/client/send/media`, {
                method: 'POST',
                body: formData
            })
            response = await response.json()
            if(response["success"] && response["media_data"]) data = response.media_data.url
        } catch (error) {
            console.log(error)
        }
        return data
    }


    handleSendImage = () => {
        this.setState({modalImageShow: true})
    }

    handleCloseModalImage = (e = null) =>{
        if (e) e.preventDefault()
        this.setState({modalImageShow: false})
    }
    
    handleCloseModalLeadMerge = (e = null) =>{
        if (e) e.preventDefault()
        this.setState({isModalLeadMergeVisible: false})
    }

    handleFileSubmit = async (e) => {
        e.preventDefault()
        const fileInput = document.querySelector("#attendance_room_input_send_image")
        
        let file = fileInput.files[0]
        let fileType = file['type'].split('/')[0]
        if(fileType !== "image" && fileType !== "video" ){
            this.handleShowMessageModalResponse(this.translate('ATTENDANCE_ROOM_FILE_MUST_BE_VALID'))
            return
        }
        let formData = new FormData()
        formData.append("file", fileInput.files[0])
        let mediaLink = await this.sendHelpdeskMediaAndGetLink(formData)
        if(mediaLink) {
            this.sendMediaWebsocket(mediaLink, fileType)
            this.handleCloseModalImage()
        } else {
            this.handleShowMessageModalResponse(this.translate('ATTENDANCE_ROOM_IT_WASNT_POSSIBLE_TO_SEND_FILE')) 
        }  
    }

    sendHelpdeskChatAudio = async (blob) => {
        let formData = new FormData()
        formData.append('file', blob, `${new Date().toISOString()}.mp3`)
 
        let mediaLink = await this.sendHelpdeskMediaAndGetLink(formData)
       
        if(mediaLink) {
            this.sendMediaWebsocket(mediaLink, "audio")
        } else {
            this.handleShowMessageModalResponse(this.translate('ATTENDANCE_ROOM_IT_WASNT_POSSIBLE_TO_SEND_AUDIO')) 
        }  
    } 

    loadAudioFunction = () => {
        if (!navigator.mediaDevices) return
        let mediaAudioRecorder
        let constraints = { audio: true }
        let chunks = []
        let newThis = this
        navigator.mediaDevices.getUserMedia(constraints)
        .then(function(stream) {
            mediaAudioRecorder = new MediaRecorder(stream)
            document.querySelector("#attendance_chat_microphone_icon").classList.remove("d-none")
        
            mediaAudioRecorder.onstop = function(e) {
                
                let blob = new Blob(chunks, { 'type' : 'audio/ogg codecs=opus' })
                chunks = []
                //let audioURL = URL.createObjectURL(blob)
                newThis.sendHelpdeskChatAudio(blob)
            }

            mediaAudioRecorder.ondataavailable = function(e) {
                chunks.push(e.data)
            }
            newThis.setState({mediaAudioRecorder})
        })
        .catch(function(err) {
            console.log('The following error occurred: ' + err)
            let microphone = document.querySelector("#attendance_chat_microphone_icon")
            if(microphone) microphone.classList.add("d-none")

        })

    }
    handleMicrophoneClickIcon = () => {
        if(!this.state.audioIsRecording) {
            this.state.mediaAudioRecorder.start()
            document.querySelector("#attendance_chat_microphone_icon").classList.add("text-danger")
        } else {
            this.state.mediaAudioRecorder.stop()
            document.querySelector("#attendance_chat_microphone_icon").classList.remove("text-danger")
        }
        this.setState({audioIsRecording: !this.state.audioIsRecording})
    }

    sendHelpdeskChatVideo = async (blob) => {
        let formData = new FormData()
        formData.append('file', blob, `${new Date().toISOString()}.mp4`)
 
        let mediaLink = await this.sendHelpdeskMediaAndGetLink(formData)
       
        if(mediaLink) {
            this.sendMediaWebsocket(mediaLink, "video")
        } else {
            this.handleShowMessageModalResponse(this.translate('ATTENDANCE_ROOM_IT_WASNT_POSSIBLE_TO_SEND_VIDEO'))   
        }  
    } 

    loadVideoFunction = () => {
        if (!navigator.mediaDevices) return
        let mediaVideoRecorder
        let ua = navigator.userAgent.toLowerCase()
        let isMobile = ua.indexOf("mobile") > -1
        let constraints = isMobile ? { video: { facingMode: { exact: "environment" } }, audio: true } : { video: true, audio: true }

        let chunks = []
        let newThis = this
        navigator.mediaDevices.getUserMedia(constraints)
        .then(function(stream) {
            
            mediaVideoRecorder = new MediaRecorder(stream, {mimeType: 'video/webm;codecs=vp9'})
            if(document.querySelector("#attendance_chat_video_icon")) document.querySelector("#attendance_chat_video_icon").classList.remove("d-none")
        
            mediaVideoRecorder.onstop = function(e) {
                
                let blob = new Blob(chunks, { 'type' : 'video/webm' })
                chunks = []
                //let videoURL = URL.createObjectURL(blob)
               
                newThis.sendHelpdeskChatVideo(blob)
            }

            mediaVideoRecorder.ondataavailable = function(e) {
                chunks.push(e.data)
            }
            newThis.setState({mediaVideoRecorder, videoStreamRecorder: stream})
        })
        .catch(function(err) {
            console.log('The following error occurred: ' + err)
            if(document.querySelector("#attendance_chat_video_icon")) document.querySelector("#attendance_chat_video_icon").classList.add("d-none")

        })

    }

    handleVideoClickIcon = (e) => {
        e.preventDefault()
        let toggleVideoLink = document.querySelector("#modal_toggle_video_link")
        let toggleVideoIcon = document.querySelector("#modal_toggle_video_link i")
        let toggleVideoSpan = document.querySelector("#modal_toggle_video_link span")
        if(!this.state.videoIsRecording) {
            this.state.mediaVideoRecorder.start()
            toggleVideoLink.classList.remove("bg-danger")
            toggleVideoLink.classList.add("bg-success")
            toggleVideoIcon.classList.remove("fa-video")
            toggleVideoIcon.classList.add("fa-paper-plane")
            toggleVideoSpan.innerHTML = this.translate('ATTENDANCE_ROOM_SEND')
        } else {
            this.state.mediaVideoRecorder.stop()
            toggleVideoLink.classList.remove("bg-success")
            toggleVideoLink.classList.add("bg-danger")
            toggleVideoIcon.classList.remove("fa-paper-plane")
            toggleVideoIcon.classList.add("fa-video")
            toggleVideoSpan.innerHTML = this.translate('ATTENDANCE_ROOM_RECORD')
            this.handleCloseModalVideo()
        }
        this.setState({videoIsRecording: !this.state.videoIsRecording}) 
    }

    handleCloseModalVideo = (e = null) => {
        if (e) e.preventDefault()
        this.setState({modalVideoShow: false})
    }

    handleToolsClientPostalCodeChange = (e) => {
        let postalCode = e.target.value
        if(postalCode.length <= 7) {
            this.setState({tools: {...this.state.tools, postalCode, businessCode: "" }})
        } else {
            this.setState({ tools: { ...this.state.tools, postalCode } })
            this.fetchAndSetToolsClientBusinessList(postalCode)
        }
    }

    fetchAndSetToolsClientBusinessList = async (postalCode) => {
        let data = null
        try{
            let response = await api.get(`/attendance/supermarket/list/${postalCode}`)
            data = response.data
            if(typeof data['success'] !== 'undefined' && data.success && data.supermarkets && data.supermarkets.length > 0){
                this.setState({tools: {...this.state.tools, businessList: data.supermarkets }})
            }else{
                if(typeof data['error'] !== 'undefined' && data.error) console.log(data.error)
            }
        }catch(error){
            console.log("error", error)
        }
        
    }
    
    handleRequestToolsClientPostalCodeChange = (e) => {
        let postalCode = e.target.value
        if(postalCode.length <= 7) {
            this.setState({requestTools: {...this.state.requestTools, postalCode, businessCode: "" }})
        } else {
            this.setState({ requestTools: { ...this.state.requestTools, postalCode } })
            this.fetchAndSetRequestToolsClientBusinessList(postalCode)
        }
    }

    fetchAndSetRequestToolsClientBusinessList = async (postalCode) => {
        let data = null
        try{
            let response = await api.get(`/attendance/supermarket/list/${postalCode}`)
            data = response.data
            if(typeof data['success'] !== 'undefined' && data.success && data.supermarkets && data.supermarkets.length > 0){
                this.setState({requestTools: {...this.state.requestTools, businessList: data.supermarkets }})
            }else{
                if(typeof data['error'] !== 'undefined' && data.error) console.log(data.error)
            }
        }catch(error){
            console.log("error", error)
        }
        
    }

    fetchAndloadTabsAccordingToInitStatus = (data) => {


        try{

            if(typeof data['success'] !== 'undefined' && data.success){
                
                let {initBuy, initCart, initTeam, clientInfo} = data
                this.setState({initBuy, initCart, initTeam })
                this.loadTabAccordingToInit(initBuy, initCart, initTeam, clientInfo)
            
            }else{
                
                if(typeof data['error'] !== 'undefined' && data.error) console.log(data.error)
            
            }
        }catch(error){
            console.log("error", error)
        }
    }

    loadTabAccordingToInit = (initBuy, initCart, initTeam, clientInfo) => {
        let visibleTabs = []
        if(initBuy && initCart){
            this.loadUsers()
            visibleTabs = ["toolbarTab", "shoppingCartTab", "teamTab"]
            this.toggleCurrentTab("shoppingCartTab")

        } else if(initBuy && !initCart){
            this.fetchClientTabBuyersHistory()
            visibleTabs = ["toolbarTab", "clientTab"]
            this.toggleCurrentTab("clientTab")

        } else if(!initBuy && !initCart){
            visibleTabs = ["toolbarTab"]
            this.toggleCurrentTab("toolbarTab")
        } 

        //Load with Toolbar
        this.fetchToolbarTabClientInfo(clientInfo)
        this.fetchRequest(clientInfo)
        this.getFeedback()

        this.addVisibleTabs(visibleTabs)

    }

    
    toggleCurrentTab = (tabName, e = null) => {
        if(e) e.preventDefault()

        let {chatTabs} = this.state

        if(chatTabs.visibleTabs.indexOf(tabName) === -1) chatTabs.visibleTabs.push(tabName)
        chatTabs.currentTab = tabName

        this.setState({chatTabs})
    }

    closeTab = (tabName) => {
        let {chatTabs} = this.state
        let visibleTabs = chatTabs.visibleTabs
        visibleTabs = visibleTabs.filter(tab=>tab !== tabName)
        chatTabs.visibleTabs = visibleTabs
        this.setState({chatTabs})
    }

    fetchToolbarTabClientInfo = async (clientInfo) => {
        try{

           this.setState({clientInfo: clientInfo})

        }catch(error){
            console.log("error", error)
        }
        
    }
    
    fetchClientTabBuyersHistory = async () => {
        let error = false
        try{
            
            this.setState({clientBuyerHistory: {...this.state.clientBuyerHistory, isLoading: true}})
            let idAttendance = this.state.messages.userId
            let response = await api.get(`attendance/client/order/list/${idAttendance}`)
            let data = response.data
            if(typeof data['success'] !== 'undefined' && data.success){
                this.setState({clientBuyerHistory: {isClient: true, isLoading: false, supermarkets: data.supermarkets} })
            }else{
                if(typeof data['error'] !== 'undefined' && data.error)  {
                    console.log(data.error)
                    error = true
                }
            }
        }catch(err){
            console.log("error", err)
            error = true
        }

        if(error) this.handleShowMessageModalResponse(this.translate('ATTENDANCE_ROOM_IT_WASNT_POSSIBLE_TO_EXECUTE_OPERATION_TRY_AGAIN_LATER'))     
    }

    addVisibleTabs = (arrayWithTabs) => {
        let {chatTabs} = this.state
        chatTabs.visibleTabs = Array.from(new Set([...chatTabs.visibleTabs, ...arrayWithTabs])) 
        this.setState({chatTabs})
    }
    
    clearVisibleTabs = () => {
        let {chatTabs} = this.state
        chatTabs.visibleTabs = []
        chatTabs.currentTab = null
        this.setState({chatTabs})
    }
    
    fetchRequest = async () => {
        try{

            //let response = await api.get(``)
            //let data = response.data
            
            let data = {
                success: true,
                request: false,
            }
            if(typeof data['success'] !== 'undefined' && data.success){
                this.setState({request: data.request })
            }else{
                if(typeof data['error'] !== 'undefined' && data.error) console.log(data.error)
            }
        }catch(error){
            console.log("error", error)
        }
        
    }

    updateClientBuyerDocument = async (e) => {
        $(e.target).mask('000.000.000-00')
        let document = e.target.value
        if(document.length > 14) return

        let {clientBuyer} = this.state
        clientBuyer.document = document

        this.setState({clientBuyer}, this.updateAllFulfilled )
    }

    updateClientBuyerPostalCode = async (e) => {
        $(e.target).mask('00000-000')
        let postalCode = e.target.value
        if(postalCode.length > 9) return

        let {clientBuyer} = this.state
        clientBuyer.postalCode = postalCode

        if(postalCode.length < 9){
            clientBuyer.state = ""
            clientBuyer.city = ""
            clientBuyer.street = ""
            clientBuyer.neighborhood = ""
            clientBuyer.supermarketSelectedId = ""
            clientBuyer.supermarketList = []
        } else {
            let fetchedAddress = await this.fetchAddressPostalCode(postalCode)
            if(fetchedAddress && !fetchedAddress.errors){
                let {state, city, street, neighborhood} = fetchedAddress
                clientBuyer.state = state
                clientBuyer.city = city
                clientBuyer.street = street
                clientBuyer.neighborhood = neighborhood
            }
        }
        this.setState({clientBuyer}, this.updateAllFulfilled)
    }

    fetchAddressPostalCode = async (postalCode) => {
        let response = null
        if(postalCode.length >= 9){
            try {
                response = await fetch(`https://api.pagar.me/1/zipcodes/${postalCode.replace("-", "")}`)
                response = await response.json()
            } catch (error) {
                response = null
                console.log(error) 
            }   
        }
        return response
    }

    updateClientBuyerField = (e, fieldName) => {
        let value = e.target.value
        let {clientBuyer} = this.state
        clientBuyer[fieldName] = value
        this.setState({clientBuyer}, this.updateAllFulfilled)
    }

    updateAllFulfilled = () => {
        let {clientBuyer} = this.state
        let {allFulfilled, addressInfoFulfilled} = this.checkClientBuyerFieldsAreEmptyOrInvalid(clientBuyer)
        
        clientBuyer.allFulfilled = allFulfilled
        clientBuyer.addressInfoFulfilled = addressInfoFulfilled

        if(addressInfoFulfilled) this.fetchAndSetClientBuyerSupermarketList()
        else {
            clientBuyer["supermarketSelectedId"] = ""
            clientBuyer["supermarketList"] = []
        }
        this.setState({clientBuyer})
    }

    checkClientBuyerFieldsAreEmptyOrInvalid = (clientBuyer) => {
        let allFulfilled = true
        let addressInfoFulfilled = true
        let {document, postalCode, state, city, street, neighborhood, addressNumber, supermarketSelectedId} = clientBuyer

        if(document === "" || document.length < 14) addressInfoFulfilled = false
        if(postalCode === "") addressInfoFulfilled = false
        if(state === "") addressInfoFulfilled = false
        if(city === "") addressInfoFulfilled = false
        if(street === "") addressInfoFulfilled = false
        if(neighborhood === "") addressInfoFulfilled = false
        if(addressNumber === "") addressInfoFulfilled = false
        
        if(!addressInfoFulfilled || supermarketSelectedId === "") allFulfilled = false

        return {allFulfilled, addressInfoFulfilled}
    }

    fetchAndSetClientBuyerSupermarketList = async () => {
        let {postalCode} = this.state.clientBuyer
        let {clientBuyer} = this.state
        postalCode = postalCode.replace("-", "")
        try{
            let response = await api.get(`/attendance/supermarket/list/${postalCode}`)
            let data = response.data
            if(typeof data['success'] !== 'undefined' && data.success && data.supermarkets && data.supermarkets.length > 0){
                clientBuyer["supermarketList"] = data.supermarkets
                this.setState({clientBuyer})
            }else{
                if(typeof data['error'] !== 'undefined' && data.error) console.log(data.error)
            }
        }catch(error){
            console.log("error", error)
        } 
    }

    handleClientBuyerStartOtherShoppingCart = async (e) => {
        e.preventDefault()
        let {clientBuyer} = this.state
        
        if(!clientBuyer.allFulfilled) return
        
        let element = e.currentTarget
        let buttonValue = element.innerHTML
        element.disabled = true
        element.innerHTML = `${this.translate('ICON_LOADING')} <i class="fa fa-spinner fa-spin"></i>`
         
        try{
            await api.post(`/attendance/status/create/${this.state.messages.userId}`, clientBuyer)
            .then((response) => response.data)
            .then((data) => {
                if(typeof data['success'] !== 'undefined' && data.success){
                    this.loadAttendanceMessages(this.state.messages.userId)
                    this.toggleCurrentTab("shoppingCartTab")
                    this.closeTab("clientTab")
                }else{
                    if(typeof data['error'] !== 'undefined' && data.error) {
                        //this.setState({tools: {...this.state.tools, postalCode: "", businessCode: "", businessList: []}})
                        this.handleShowMessageModalResponse(data.error)
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
        }catch(error){
            console.log(error)
        } 

        element.innerHTML = buttonValue
        element.disabled = false
    }
    
    handleClientBuyerRebuyShoppingCart = async (e, order_id) => {
        let idAttendance = this.state.messages.userId
        let element = e.currentTarget
        let buttonValue = element.innerHTML
        element.disabled = true
        element.innerHTML = `${this.translate('ICON_LOADING')} <i class="fa fa-spinner fa-spin"></i>`
        try{
            let response = await api.post(`/attendance/status/re_create/${idAttendance}`, {order_id})
            let data = response.data
            if(typeof data['success'] !== 'undefined' && data.success){
                
                this.loadAttendanceMessages(idAttendance)
                this.toggleCurrentTab("shoppingCartTab")
                this.closeTab("clientTab")
            }else{
                if(typeof data['error'] !== 'undefined' && data.error) {
                    this.handleShowMessageModalResponse(data.error)
                }
            } 
        }catch(error){
            console.log("error", error)
        }
        element.innerHTML = buttonValue
        element.disabled = false
    }

    handleClientTeamSendToShopper = async (e) => {
        e.preventDefault()

        let error = false
        
        let element = e.currentTarget
        let buttonValue = element.innerHTML
        element.disabled = true
        element.innerHTML = `${this.translate('ICON_LOADING')} <i class="fa fa-spinner fa-spin"></i>`

        let {clientTeam} = this.state
        let idAttendance = this.state.messages.userId
        
        try{
            await api.post(`/attendance/request/new/set/values/${idAttendance}`, clientTeam)
            .then((response) => response.data)
            .then((data) => {
                if(typeof data['success'] !== 'undefined' && data.success){
                    
                    this.setState({
                        initTeam: true,
                        clientTeam: {
                            needShopperHelp: "",
                            commentToTeam: "",
                            shopperSelected: "",
                            deliverymanSelected: "",
                            deliveryType: "",
                            deliveryDate: "",
                        },
                        formUpdateCommercialTeam: {
                            shopper: clientTeam.shopperSelected,
                            deliveryman: clientTeam.deliverymanSelected
                        },
                        formUpdateDeliverymanAndStatus: {
                            deliveryman: clientTeam.deliverymanSelected
                        }
                    }, ()=> {
                        if(clientTeam.needShopperHelp === "yes" && clientTeam.commentToTeam){
                            this.handleMessageSubmit(clientTeam.commentToTeam, "teamHistory")
                        }
                    })
                } else error = true
            })
            .catch((error) => {
                console.log(error)
                error = true
            })
        }catch(err){
            console.log(err)
            error = true
        } 

        if(error) this.handleShowMessageModalResponse(this.translate('ATTENDANCE_ROOM_IT_WASNT_POSSIBLE_TO_EXECUTE_OPERATION_TRY_AGAIN_LATER'))

        element.disabled = false
        element.innerHTML = buttonValue
    }

    updateDeliverymanAndStatusField = (e, fieldName) => {
        let value = e.target.value
        let {formUpdateDeliverymanAndStatus} = this.state
        
        formUpdateDeliverymanAndStatus[fieldName] = value
        this.setState({formUpdateDeliverymanAndStatus})
    }

    updateCommercialTeamField = (e, fieldName) => {
        let value = e.target.value
        let {formUpdateCommercialTeam} = this.state
        
        formUpdateCommercialTeam[fieldName] = value
        this.setState({formUpdateCommercialTeam})
    }

    checkCommercialTeamAttendanceStatus = status => {
        let check = false
        switch(status){
            case "in_attendance":
            case "awaiting_scripting":
            case "sent_to_deliveryman":
            case "awaiting_delivery_start":
            case "on_delivery":
                check = true
                break
            default:
                break
        }
        return check
    }

    getCommercialTeamFieldsFromRequest = () => {
        console.log(this.state.requests)
        if (this.state.requests && this.state.requests.length && this.state.requests.find(request=>this.checkCommercialTeamAttendanceStatus(request.status))) {
            this.setState({
                formUpdateCommercialTeam: {
                    shopper: this.state.requests.find(request=>this.checkCommercialTeamAttendanceStatus(request.status)).shopper,
                    deliveryman: this.state.requests.find(request=>this.checkCommercialTeamAttendanceStatus(request.status)).deliveryman,
                },
                formUpdateDeliverymanAndStatus: {
                    deliveryman: this.state.requests.find(request=>this.checkCommercialTeamAttendanceStatus(request.status)).deliveryman,
                },
                statusRequestCheck: this.state.requests.find(request=>this.checkCommercialTeamAttendanceStatus(request.status)).status
            })
        }
    }

    getCartStatusIcon = (action, status = null) => {
        let icon, pausePurchase, blockCart

        if(status){
            pausePurchase = status.pause
            blockCart = status.block_cart
        } else {
            pausePurchase = this.state.pausePurchase
            blockCart = this.state.blockCart
        }
        
        if(action === "block"){
            if(blockCart){
                icon = <i className="fa fa-lock text-danger" aria-hidden="true"></i>
            } else {
                icon = <i className="fa fa-unlock-alt text-primary" aria-hidden="true"></i>
            }
        } else {
            if(pausePurchase){
                icon = <i className="fa fa-stop-circle-o text-warning" aria-hidden="true"></i>
            } else {
                icon = <i className="fa fa-play-circle-o text-success" aria-hidden="true"></i>
            }
        }
        

        return icon

    }

    getCartStatus = async (idAttendance) => {
        try{
            let response = await api.get(`/attendance/get/request/block_and_pause/${idAttendance}`)   
            if(response && response.data.success)  {
                console.log("status", response.data.status.status)
                this.setState({isCartStatusLoaded: true, cartStatus: response.data.status.status, blockCart: response.data.status.block_cart, pausePurchase: response.data.status.pause})
            } 
        }catch(error){
            console.log(error)
        }
    }

    handleTogglePausePurchase = () => {
        let confirmQuestion = !this.state.pausePurchase ? this.translate("ATTENDANCE_ROOM_DO_YOU_WANT_TO_PAUSE_THIS_PURCHASE")
            : this.translate("ATTENDANCE_ROOM_DO_YOU_WANT_TO_PLAY_THIS_PURCHASE")
        this.confirmModalCallback = this.togglePausePurchase
        this.setState({isConfirmModalVisible: true, confirmModalMessage: confirmQuestion})
       
    }
    
    togglePausePurchase = async () => {
        let status = !this.state.pausePurchase
        let idAttendance = this.state.tools.idAttendance
        try{
            let response = await api.post("/attendance/update/request/status", {idAttendance: idAttendance, status: status, action: "pause"})
            console.log(response.data)
            if(response && response.data && response.data.success){
                this.confirmModalCallback = null
                this.setState({pausePurchase: status, confirmModalMessage: "", isConfirmModalVisible: false})
                this.loadAttendanceContacts()
            } else {
                
            }
        } catch(error){
            console.log(error)
        } 
    }
    
    handleToggleBlockCart = () => {
        let confirmQuestion = !this.state.blockCart ? this.translate("ATTENDANCE_ROOM_DO_YOU_WANT_TO_BLOCK_THIS_CART")
            : this.translate("ATTENDANCE_ROOM_DO_YOU_WANT_TO_UNBLOCK_THIS_CART")
        this.confirmModalCallback = this.toggleBlockCart
        this.setState({isConfirmModalVisible: true, confirmModalMessage: confirmQuestion})
    }

    toggleBlockCart = async () => {
        let status = !this.state.blockCart
        let idAttendance = this.state.tools.idAttendance
        try{
            let response = await api.post("/attendance/update/request/status", {idAttendance: idAttendance, status: status, action: "block"})
            console.log(response.data)
            if(response && response.data && response.data.success){
                this.confirmModalCallback = null
                this.setState({blockCart: status, confirmModalMessage: "", isConfirmModalVisible: false})
                this.loadAttendanceContacts()
            } else {
                
            }
        } catch(error){
            console.log(error)
        } 
    }

    handleToggleRemovePurchase = () => {
        let confirmQuestion = this.translate("ATTENDANCE_ROOM_DO_YOU_WANT_TO_REMOVE_THIS_PURCHASE")
        this.confirmModalCallback = this.removePurchase
        this.setState({isConfirmModalVisible: true, confirmModalMessage: confirmQuestion})
    }

    removePurchase = async () => {
        this.confirmModalCallback = null
        this.setState({confirmModalMessage: "", isConfirmModalVisible: false})
        
        let idAttendance = this.state.tools.idAttendance
        try{
            let response = await api.post("/attendance/update/request/status", {idAttendance: idAttendance, status: "finished", action: "remove"})
            console.log(response.data)
            if(response && response.data && response.data.success){
                this.confirmModalCallback = null
                this.setState({confirmModalMessage: "", isConfirmModalVisible: false, blockCart: null, pausePurchase: null})
                this.clearVisibleTabs()
                this.loadAttendanceMessages(idAttendance)
                this.loadAttendanceContacts()
            } else {
                
            }
        } catch(error){
            console.log(error)
        } 
    }
    
    handleUpdateDeliveryAndStatus = async (e) => {
        e.preventDefault()

        let {formUpdateDeliverymanAndStatus} = this.state
 
        let error = false
        
        let element = e.target.querySelector("button")
        let buttonValue = element.innerHTML
        element.disabled = true
        element.innerHTML = `${this.translate('ICON_LOADING')} <i class="fa fa-spinner fa-spin"></i>`

        let idAttendance = this.state.messages.userId
        
        try{
            await api.post(`/attendance/update/deliveryman_and_status/${idAttendance}`, formUpdateDeliverymanAndStatus)
            .then((response) => response.data)
            .then((data) => {
                if(typeof data['success'] !== 'undefined' && data.success){
                    this.handleShowMessageModalResponse(this.translate('ATTENDANCE_ROOM_COMMERCIAL_TEAM_UPDATED_WITH_SUCCESS')) 
                } else error = true
            })
            .catch((err) => {
                console.log(err)
                error = true
            })
        }catch(err){
            console.log(err)
            error = true
        } 

        if(error) this.handleShowMessageModalResponse(this.translate('ATTENDANCE_ROOM_IT_WASNT_POSSIBLE_TO_EXECUTE_OPERATION_TRY_AGAIN_LATER')) 

        element.disabled = false
        element.innerHTML = buttonValue

    }

    handleUpdateCommercialTeam = async (e) => {
        e.preventDefault()
        
        let {formUpdateCommercialTeam} = this.state
 
        let error = false
        
        let element = e.target.querySelector("button")
        let buttonValue = element.innerHTML
        element.disabled = true
        element.innerHTML = `${this.translate('ICON_LOADING')} <i class="fa fa-spinner fa-spin"></i>`

        let idAttendance = this.state.messages.userId
        
        try{
            await api.post(`/attendance/update/team/${idAttendance}`, formUpdateCommercialTeam)
            .then((response) => response.data)
            .then((data) => {
                if(typeof data['success'] !== 'undefined' && data.success){
                    this.handleShowMessageModalResponse(this.translate('ATTENDANCE_ROOM_COMMERCIAL_TEAM_UPDATED_WITH_SUCCESS')) 
                } else error = true
            })
            .catch((err) => {
                console.log(err)
                error = true
            })
        }catch(err){
            console.log(err)
            error = true
        } 

        if(error) this.handleShowMessageModalResponse(this.translate('ATTENDANCE_ROOM_IT_WASNT_POSSIBLE_TO_EXECUTE_OPERATION_TRY_AGAIN_LATER')) 

        element.disabled = false
        element.innerHTML = buttonValue
    }


    updateClientTeamField = (e, fieldName) => {
        let value = e.target.value
        let {clientTeam} = this.state

        if(fieldName === "needShopperHelp" && value !== "yes") clientTeam["commentToTeam"] = ""
        if(fieldName === "deliveryType") clientTeam["deliveryDate"] = ""
        
        clientTeam[fieldName] = value
        this.setState({clientTeam}, this.updateClientTeamAllFulfilled)
    }

    updateClientTeamAllFulfilled = () => {
        let {clientTeam} = this.state
        let allFulfilled = this.checkClientTeamFieldsAreEmpty(clientTeam)
        
        clientTeam.allFulfilled = allFulfilled

        this.setState({clientTeam})
    }

    checkClientTeamFieldsAreEmpty = (clientTeam) => {
        let allFulfilled = true
        let {needShopperHelp, commentToTeam, shopperSelected, deliverymanSelected, deliveryType, deliveryDate} = clientTeam

        if(needShopperHelp === "") allFulfilled = false
        if(needShopperHelp === "yes" && commentToTeam === "") allFulfilled = false
        if(shopperSelected === "") allFulfilled = false
        if(deliverymanSelected === "") allFulfilled = false
        if(deliveryType === "") allFulfilled = false
        if(deliveryDate === "") allFulfilled = false

        return allFulfilled
    }

    getClientTeamDeliveryMinDate = () => {
        let {deliveryType} = this.state.clientTeam
        let choosenDate
        if(deliveryType === "pop"){
            choosenDate = this.doOperationWithDate("sum", "day", 1)
        }else if(deliveryType === "programmed"){
            choosenDate = this.doOperationWithDate("sum", "hours", 4)
        }else if(deliveryType === "prime"){
            choosenDate = new Date()
        }
        const date = (new Date(choosenDate).toLocaleDateString()).split('/')
        const time = (new Date(choosenDate).toLocaleTimeString()).split(':')
        let minDate = `${date[2]}-${date[1]}-${date[0]}T${time[0]}:${time[1]}`

        return minDate 
    }

    doOperationWithDate = (operation, dateType, valueOfOperation) => {
        let currentDate = new Date()
        let year = currentDate.getFullYear()
        let month = currentDate.getMonth()
        let day = currentDate.getDate()
        let hours = currentDate.getHours()

        if(dateType === "year"){
            if(operation === "sum") year += valueOfOperation
            else if(operation === "sub") year -= valueOfOperation

        } else if(dateType === "month") {
            if(operation === "sum") month += valueOfOperation
            else if(operation === "sub") month -= valueOfOperation

        } else if(dateType === "day"){
            if(operation === "sum") day += valueOfOperation
            else if(operation === "sub") day -= valueOfOperation
            
        } else if(dateType === "hours"){
            if(operation === "sum") hours += valueOfOperation
            else if(operation === "sub") hours -= valueOfOperation
        }

        let newDate = new Date(year, month, day, hours)
        return newDate
    }

    formatDateToBrazilianFormat = (dateValue, hasTime = false) => {
        const addZeroToLeft = (value) => {
            value = parseInt(value)
            if(parseInt(value) < 10){
                value = `${"0" + value}`
                
            }
    
            return value
        }
    
        if(!dateValue.includes("T")) dateValue = `${dateValue}T00:00:00`
        let date = (new Date(dateValue).toLocaleDateString()).split('/')
        let time = (new Date(dateValue).toLocaleTimeString()).split(':')
        
        date = date.map(singleDate=>addZeroToLeft(singleDate))
        time = time.map(singleTime=>addZeroToLeft(singleTime))
        
        let newDate
        if(hasTime) {
            newDate = `${date[0]}/${date[1]}/${date[2]} ${time[0]}:${time[1]}`
        } else {
            newDate = `${date[0]}/${date[1]}/${date[2]}`
        }
        return newDate
    }

    toggleAccordionPannel = (selector) => {
        $(selector).collapse("toggle")
    }

    getAttendanceRoomHeight = () => {
        if(document.body.clientWidth <= 991){
            return document.body.clientHeight - 56
        } else if(document.body.clientWidth < 768){
            return 500
        }
    }

    checkScreenWidthIsOnInterval = (minWidth, maxWidth) => {
        return (document.body.clientWidth >= minWidth && document.body.clientWidth <= maxWidth) ? true : false
    }

    startInitBuy = async (e) => {
        let buttonValue = e.currentTarget.innerHTML
        let element = e.currentTarget
        element.disabled = true
        element.innerHTML = `${this.translate('ICON_LOADING')} <i class="fa fa-spinner fa-spin"></i>`
        await this.fetchClientTabBuyersHistory()
        this.toggleCurrentTab("clientTab")
        element.disabled = false
        element.innerHTML = buttonValue
    }

    getTokenCall = async () => {
        await api.get(`/integration/twilio/get/access_token`)
            .then((response) => response.data)
            .then((data) => {
                if(typeof data.success != undefined && data.success){
                    
                    device = new window.Twilio.Device(data.token, {
                        codecPreferences: ["opus", "pcmu"],
                        fakeLocalDTMF: true,
                        enableRingingState: true,
                        //debug: true
                    });

                    device.on("ready", function(device) {
                        console.log("Twilio.Device Ready!");
                        //document.getElementById("call-controls").style.display = "block";
                    });

                    device.on("error", (error) => {
                        if(error && error.code){
                            if(error.code === 31208){
                                alert("É necessário fornecer permissão de acesso ao microfone do seu dispositivo.")
                            } else if(error.code >= 31201 && error.code <= 31207){
                                this.getTokenCall()
                            }
                        }
                        console.log("Twilio.Device Error: " + error.message);
                    });

                    device.on("connect", function(conn) {
                        console.log("Successfully established call!");
                        //document.getElementById("button-call").style.display = "none";
                        //document.getElementById("button-hangup").style.display = "inline";
                        //volumeIndicators.style.display = "block";
                        //bindVolumeIndicators(conn);
                    });

                    device.on("disconnect", (conn) => {
                        console.log("Call ended.");
                        this.setState({isLeadInCall: false})
                        //document.getElementById("button-call").style.display = "inline";
                        //document.getElementById("button-hangup").style.display = "none";
                        //volumeIndicators.style.display = "none";
                    });

                    device.on("incoming", function(conn) {
                        console.log("Incoming connection from " + conn.parameters.From);
                        var archEnemyPhoneNumber = "+12093373517";
                        if(conn.parameters.From === archEnemyPhoneNumber){
                            conn.reject();
                            console.log("It's your nemesis. Rejected call.");
                        }else{
                            conn.accept();
                        }
                    });

                    //setClientNameUI(data.identity);

                    //device.audio.on("deviceChange", updateAllDevices.bind(device));

                    // Show audio selection UI if it is supported by the browser.
                    if (device.audio.isOutputSelectionSupported) {
                        //document.getElementById("output-selection").style.display = "block";
                    }

                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    initCall = (idAttendance, to = '+551940422269', callMode = "conference") => {
        console.log(idAttendance, to, callMode)
        var params = {
            To: to,
            idAttendance: idAttendance,
            callMode: callMode,
        };
    
        console.log("Calling " + params.To + "...");
        if(device){
            var outgoingConnection = device.connect(params);
            outgoingConnection.on("ringing", () => {
                console.log("Ringing...");
                if(callMode === "conference") this.setState({messages: {...this.state.messages, call_status: "in_call"}})
            });
        }

    }

    disconnectCall(){
        console.log("Hanging up...");
        if(device){
            device.disconnectAll();
        }
    }

    connectWebsocketAttendance = () => {

        let ws = new WebSocket(`${attendanceUrlWebSocket}`)
        
        this.socket = ws

        ws.onopen = () => {
            console.log('Websocket Open: Conexão com o Websocket estabelecida com sucesso.');
            if(typeof this.state.messages.userId !== 'undefined' && this.state.messages.userId){
                this.socket.send(
                    JSON.stringify({
                        environment: 'attendance_app',
                        action: 'connect',
                        idAttendance: this.state.messages.userId,
                        idRequest: this.state.messages.userRequest,
                        userType: 'attendance'
                    })
                );
            }
        }

        ws.onclose = (e) => {
            console.log('Websocket Close: Conexão com o Websocket caiu, a reconexão ira acontecer em 2 segundo.', e.reason);
            setTimeout( () => {
                this.connectWebsocketAttendance()
            }, 2000);
        }

        ws.onerror = (e) => {
            console.log('Websocket Error: ', e)
        }

        ws.onmessage = (e) => {
            let data = JSON.parse(e.data)
            this.addClientMessage(data)
        }

    }

    playNotificationRingtone = (type) => {
        if(type === "loud"){
            this.playAudio(loudRingtone, 0.05)
        } else {
            this.playAudio(discreteRingtone, 0.01)
        }
    }

    playAudio = (audio, volume, loop = true) => {
        this.stopNotificationRingtone()
        this.ringtoneAudio = new Audio(audio)
        this.ringtoneAudio.volume = volume
        this.ringtoneAudio.loop = loop
        this.ringtoneAudio.play()

    }

    stopNotificationRingtone = () => {
        if(this.ringtoneAudio) {
            this.ringtoneAudio.pause()
            this.ringtoneAudio.currentTime = 0
        }
    }

    checkIsReceivingCall = (response) => {
        let {attendance_new_attendance, attendance_in_attendance, attendance_finished, attendance_archived} = response
        let contacts = [ ...attendance_new_attendance,  ...attendance_in_attendance,  ...attendance_finished, ...attendance_archived ]

        if(!contacts || !contacts.length) return

        if(contacts.filter(contact=> contact.call_status === "calling").length){
            this.checkIsCallingAndBlinkTabs(attendance_new_attendance, attendance_in_attendance, attendance_finished, attendance_archived)
        }

        if(contacts.filter(contact=> contact.call_status === "in_call").length && contacts.filter(contact=> contact.call_status === "calling").length){
            this.playNotificationRingtone("discrete")

        }else if(contacts.filter(contact=> contact.call_status === "calling").length){
            this.playNotificationRingtone("loud")
        } else {
            this.stopNotificationRingtone()
        }
    }

    checkIsCallingAndBlinkTabs = (...contactTabs) => {
        this.checkIsCallingInTab(contactTabs[0], "newAttendanceContacts")
        this.checkIsCallingInTab(contactTabs[1], "inAttendanceContacts")
        this.checkIsCallingInTab(contactTabs[2], "finishedContacts")
        this.checkIsCallingInTab(contactTabs[3], "archivedContacts")

    }

    checkIsCallingInTab = (tab, tabName) => {
        if(!tab || !tab.length ) return

        let {notificationTabs} = this.state

        let calling = tab.filter((contact)=>contact.call_status === "calling").length

        if(calling){
            notificationTabs.push(tabName)
            notificationTabs = [...new Set(notificationTabs)]
            this.setState({notificationTabs})
        }
        
    }

    checkContactsNewMessages = (response) => {

        let {attendance_new_attendance, attendance_in_attendance, attendance_finished, attendance_archived} = response
        let contacts = [ ...attendance_new_attendance,  ...attendance_in_attendance,  ...attendance_finished, ...attendance_archived ]
        
        if(!contacts || !contacts.length) return

        let {contactsMessagesCount} = this.state
        let controlNewAttendances = false
        let controlIDsAttendances = []
        for(let contact of contacts){
            let id = contact.id
            let count = contact.count
            
            if(contactsMessagesCount[id]){
                if(count > contactsMessagesCount[id]){
                    if(!document.hasFocus()){
                        this.playNotification()
                    } else if(id !== this.state.messages.userId){
                        this.playNotification()
                    }

                    this.blinkTabWithNewNotification(id, attendance_new_attendance, attendance_in_attendance, attendance_finished, attendance_archived)
                    contactsMessagesCount[id] = count
                }
            }else{
                if(contact.new_attendance){
                    controlNewAttendances = true
                    controlIDsAttendances.push(id)
                    this.blinkTabWithNewNotification(id, attendance_new_attendance, attendance_in_attendance, attendance_finished, attendance_archived)
                }
                contactsMessagesCount[id] = count
            }
        }

        if(controlNewAttendances){
            if(!document.hasFocus()){
                this.playNotification()
            }else if(!controlIDsAttendances.includes(this.state.messages.userId)){
                this.playNotification()
            }
        }

        /*
        contacts.forEach(contact => {

            let id = contact.id
            let count = contact.count
            
            console.log(contact)

            if(contactsMessagesCount[id]){
                if(count > contactsMessagesCount[id]){
                    if(!document.hasFocus()){
                        this.playNotification()
                    } else if(id !== this.state.messages.userId){
                        this.playNotification()
                    }

                    this.blinkTabWithNewNotification(id, attendance_new_attendance, attendance_in_attendance, attendance_finished, attendance_archived)
                    contactsMessagesCount[id] = count
                }
            } else {
                contactsMessagesCount[id] = count
            }
        })
        */
        this.setState({contactsMessagesCount})
    }

    blinkTabWithNewNotification = (id, ...contactTabs) => {
        if(this.checkNotificationInTab(id, contactTabs[0], "newAttendanceContacts")) return
        else if(this.checkNotificationInTab(id, contactTabs[1], "inAttendanceContacts")) return
        else if(this.checkNotificationInTab(id, contactTabs[2], "finishedContacts")) return
        else if(this.checkNotificationInTab(id, contactTabs[3], "archivedContacts")) return
    }

    checkNotificationInTab = (id, tab, tabName) => {
        if(!tab || !tab.length ) return
        let found = false

        let {notificationTabs} = this.state


        tab.forEach(contact=>{
            if(id === contact.id){
                found = true
                return false
                
            } 
        })

        if(found){
            notificationTabs.push(tabName)
            notificationTabs = [...new Set(notificationTabs)]
            this.setState({notificationTabs})
        }

        return found
    
    }

    clearNotificationTab = (tabName) => {
        let {notificationTabs} = this.state
        let newNotificationTabs = notificationTabs.filter(tab=>tab !== tabName)
        this.setState({notificationTabs: newNotificationTabs})
    }

    playNotification = () => {
        this.notification = new Audio(notificationAudio)
        this.notification.volume = 1
        this.notification.play()
    }

    onChangeSubOrders = (allSubOrdersInvoiced) => {
        this.setState({
            allSubOrdersInvoiced: allSubOrdersInvoiced
        })
    }

    componentDidMount = () => {
        this.loadUsers()

        this.loadAttendanceContacts()

        setInterval(() => {
            this.loadAttendanceContacts()
        }, 120000)

        this.connectWebsocketAttendance()

        /*
        this.socket.onopen = () => {
            console.log('Websocket Open: Conexão com o Websocket estabelecida com sucesso.');
        }

        this.socket.onclose = (e) => {
            console.log('Websocket Close: Conexão com o Websocket caiu, a reconexão ira acontecer em 2 segundo.', e.reason);
            setTimeout( () => {
                //this.socket = new WebSocket('wss://attendance.development.buylitics.in/wss/')
                this.socket = new WebSocket(`${attendanceUrlWebSocket}`)
            }, 2000);
        }

        this.socket.onerror = (e) => {
            console.log('Websocket Error: ', e)
        }

        this.socket.onmessage = (e) => {
            let data = JSON.parse(e.data)
            this.addClientMessage(data)
        }
        */

        // Call
        this.getTokenCall()
        
        $(document).ready(() => {
            $(document).on("load", "video, audio, image", this.scrollToHeight)
        })
        
    }

    componentDidUpdate = (prevProps, prevState) => {

        if(document.querySelector("#attendance_row_messages_chat")){
            let chat = document.querySelector("#attendance_row_messages_chat")
            $("#attendance_row_messages_chat").on("scroll", (e) => {
               
                if($(e.target).scrollTop() + $(e.target).innerHeight() + 1 >= chat.scrollHeight) {
                    this.canScroll = true
                } else {
                    this.canScroll = false
                }
            });
        }

        //$('[data-toggle="tooltip"]').tooltip({placement: "bottom", trigger: "hover"})
        $('[data-toggle="tooltip"]').tooltip('dispose').tooltip({placement: "bottom", trigger: "hover"})
        
        if(this.state.modalVideoShow && this.state.videoStreamRecorder){
            let video = document.getElementById("modal_video_player")
            
            if ("srcObject" in video) {
                video.srcObject = this.state.videoStreamRecorder;
            } else {
                // Avoid using this in new browsers, as it is going away.
                video.src = window.URL.createObjectURL(this.state.videoStreamRecorder);
            }
            video.onloadedmetadata = (e) =>{
                video.play();
            };
        }
        
        if(prevState.initBuy !== this.state.initBuy && this.state.initBuy === true) this.fetchClientTabBuyersHistory()

        $(document).ready(() => {
            $(document).on("load", "video, audio, image", this.scrollToHeight)
        })

        if(prevState.attendanceContactsRefreshToggler !== this.state.attendanceContactsRefreshToggler){
            this.loadAttendanceContacts()
        }

    }
    

    render() {
        return (
            <Fragment>
                <MenuMain addClass="folded"/>
                <div id="content" className="app-content box-shadow-0 dark attendance_room_page" role="main">
                    <HeaderMain title={this.translate('ATTENDANCE_ROOM_ATTENDANCE_ROOM')} onChange={this.handleChange}/>
                    <div className="content-main row row-no-margin d-flex flex-grow-1" id="content-main">
                        <div className="col-12 mx-auto px-sm-4 d-flex flex-column flex-grow-1">
                            <div className="row flex-grow-1">
                                <div id="attendance_chat" className ="col-12 d-flex flex-column flex-grow-1">
                                    <div className="row flex-grow-1">
                                        <AttendanceContacts 
                                            notificationTabs={this.state.notificationTabs}
                                            clearNotificationTab={this.clearNotificationTab}
                                            getAttendanceRoomHeight={this.getAttendanceRoomHeight} 
                                            handleContactClick={this.handleContactClick} 
                                            getDate={this.getDate} 
                                            contacts={{
                                                newAttendanceContacts: this.state.newAttendanceContacts, 
                                                inAttendanceContacts: this.state.inAttendanceContacts, 
                                                inShopperContacts: this.state.inShopperContacts, 
                                                inScreenwriterContacts: this.state.inScreenwriterContacts, 
                                                inDeliveryContacts: this.state.inDeliveryContacts, 
                                                finishedContacts: this.state.finishedContacts, 
                                                archivedContacts: this.state.archivedContacts,
                                                attendanceOtherFilters: this.state.attendanceOtherFilters
                                            }}
                                            getCartStatusIcon={this.getCartStatusIcon}
                                            translate={this.props.translate}
                                        />
                                        <div id="attendance_messages" className="col-12 col-md-9 border-top-1 d-flex flex-column flex-grow-1">
                                            <div className="row flex-grow-1">
                                                <div className={`col-12 col-md-7 d-flex  flex-column flex-grow-1`}>
                                                    {this.state.messages.userId !== null && (
                                                        <div className="row flex-grow-1">
                                                            <div className="col-12 d-flex flex-column flex-grow-1">
                                                                <div className="row py-1">
                                                                    {/* <div className="col-3 col-lg-1 pr-lg-0">
                                                                        <img alt="" height={50} className="rounded-circle fluid mt-1" src={this.state.messages.userImage} />
                                                                    </div> */}
                                                                    <div className="col">
                                                                        <div className="row">
                                                                            <div className="p-0 col">
                                                                                <div className="lead attendance_message_info_title">
                                                                                    {this.state.messages.userName}
                                                                                    {this.state.blockCart && <React.Fragment>&nbsp;{this.getCartStatusIcon("block")}</React.Fragment>}
                                                                                    {this.state.pausePurchase && <React.Fragment>&nbsp;{this.getCartStatusIcon("pause")}</React.Fragment>}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="p-0 col d-flex align-items-start">
                                                                                <div className="attendance_message_info_status">{this.getStatus(this.state.messages.userStatus)}</div>
                                                                            </div>
                                                                            <div className="p-0 col text-right pr-4 d-flex justify-content-end align-items-start">
                                                                                
                                                                                <div className="d-flex align-items-start text-md">{this.getOriginIcon(this.state.messages.userContactOrigin)}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <nav style={{display: "flex"}}>
                                                                    <div className="nav nav-tabs attendance_room_chat_nav" id="nav-tab" role="tablist" style={{flexGrow: 1}}>
                                                                        <button className={`attendance_room_chat_nav_item nav-item nav-link ${this.state.currentChannel === "clientHistory" ? "active" : ""}`} onClick={(e)=>this.setState({currentChannel: "clientHistory"}, this.scrollToHeight)} >{this.translate('ATTENDANCE_ROOM_CHAT_TAB_CLIENT')}</button>
                                                                        <button className={`attendance_room_chat_nav_item nav-item nav-link ${this.state.currentChannel === "shopperHistory" ? "active" : ""}`} onClick={(e)=>this.setState({currentChannel: "shopperHistory"}, this.scrollToHeight)}>{this.translate('ATTENDANCE_ROOM_CHAT_TAB_PERSONAL_SHOPPER')}</button>
                                                                        <button className={`attendance_room_chat_nav_item nav-item nav-link ${this.state.currentChannel === "deliverymanHistory" ? "active" : ""}`} onClick={(e)=>this.setState({currentChannel: "deliverymanHistory"}, this.scrollToHeight)}>{this.translate('ATTENDANCE_ROOM_CHAT_TAB_DELIVERYMAN')}</button>
                                                                        <button className={`attendance_room_chat_nav_item nav-item nav-link ${this.state.currentChannel === "teamHistory" ? "active" : ""}`} onClick={(e)=>this.setState({currentChannel: "teamHistory"}, this.scrollToHeight)}>{this.translate('ATTENDANCE_ROOM_CHAT_TAB_TEAM')}</button>
                                                                    </div>
                                                                    <div style={{justifyContent: "flex-end", alignSelf: "center"}}>
                                                                        {this.state.messages.call_status === "calling" && (
                                                                            <button className="btn btn-outline-success text-white btn-cursor-pointer" onClick={(e)=>this.initCall(this.state.messages.userId)}>
                                                                                <i className="fa fa-phone"></i>&nbsp;
                                                                                Atender Ligação
                                                                            </button>
                                                                        )}
                                                                        {this.state.messages.call_status === "in_call" && (
                                                                            <button className="btn btn-outline-danger text-white btn-cursor-pointer" onClick={(e)=>this.disconnectCall()}>
                                                                                <i className="fa fa-phone"></i>&nbsp;
                                                                                Encerrar Ligação
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                </nav>
                                                                <AttendanceChat formatDateToBrazilianFormat={this.formatDateToBrazilianFormat} getOriginIcon={this.getOriginIcon} currentChannel={this.state.currentChannel} translate={this.translate} messages={{clientHistory:this.state.clientHistory, shopperHistory: this.state.shopperHistory, deliverymanHistory: this.state.deliverymanHistory, teamHistory: this.state.teamHistory}} />
                                                                <div id="attendance_row_messages_submit" className="row">
                                                                    <div className="col-12 px-0">
                                                                        {(this.state.currentChannel === "shopperHistory" || this.state.currentChannel === "deliverymanHistory") ? (
                                                                            <div className="div text-center py-3">
                                                                                {this.state.currentChannel === "shopperHistory" ? this.translate('ATTENDANCE_ROOM_CHAT_ALLOWED_ONLY_BETWEEN_CLIENT_AND_SHOPPER')
                                                                                : this.translate('ATTENDANCE_ROOM_CHAT_ALLOWED_ONLY_BETWEEN_CLIENT_AND_DELIVERYMAN')}
                                                                            </div>
                                                                        ) : (
                                                                            <form onSubmit={(e) => e.preventDefault()}>
                                                                                <div className="d-flex flex-column">
                                                                                    <div className="d-flex mx-3 pt-2" style={{borderBottom: "solid 1px"}}>
                                                                                        <img alt="" src={photoVideo} className="btn-cursor-pointer mr-4 my-2" width="18" onClick={()=>this.handleSendImage()}/>
                                                                                        <i className="fa fa-video btn-cursor-pointer d-none mr-4 my-2" id="attendance_chat_video_icon" onClick={()=>this.setState({modalVideoShow: true})}></i>
                                                                                        <i className="fa fa-microphone btn-cursor-pointer mr-4 my-2" id="attendance_chat_microphone_icon" onClick={()=>this.handleMicrophoneClickIcon()}></i>
                                                                                    </div>
                                                                                    <div className="d-flex mx-3 pb-1">
                                                                                        <textarea className="form-control flex-grow-1" style={{border: "none", backgroundColor: "inherit"}} id="attendance_chat_textarea" placeholder={this.translate('ATTENDANCE_ROOM_WRITE_YOUR_MESSAGE')}></textarea>
                                                                                        <i id="attendance_chat_submit" className="fa fa-chevron-right text-md text-right" onClick={e=>this.handleMessageSubmit()} aria-hidden="true"></i>
                                                                                    </div>
                                                                                </div>
                                                                            </form>

                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>  
                                                            </div>
                                                    )}
                                                    {this.state.messages.userId === null && (
                                                        
                                                        <p className="no_message_status lead text-center">{this.translate('ATTENDANCE_ROOM_SELECT_A_ATTENDANCE')}</p>
                                                        
                                                    )}
                                                </div>
                                                {this.state.messages.userId !== null ? (
                                                    <div id="attendance_row_messages_tools" className="col-12 col-md-5 text-center d-flex flex-column" style={{ height: this.getAttendanceRoomHeight()}}>
                                                        <div className="row">
                                                            <div className="col">
                                                                <ul className="row nav nav-pills" id="toolbar_tab" style={{background: "#1e2835"}}role="tablist">
                                                                    {this.state.chatTabs.visibleTabs.indexOf("toolbarTab") !== -1 && (
                                                                        <li className="nav-item" data-toggle="tooltip" title={this.translate('ATTENDANCE_ROOM_TAB_TOOLBAR')}>
                                                                            <a className={`btn py-2 rounded-0 dark nav-link ${this.state.chatTabs.currentTab === "toolbarTab" ? "active" : ""}`}  href="/#" onClick={(e)=>this.toggleCurrentTab("toolbarTab", e)} role="tab" aria-controls="attendance_toolbar" aria-selected={this.state.chatTabs.currentTab === "toolbarTab" ? true : false}>
                                                                                <i className="fa fa-info"></i>
                                                                            </a>
                                                                        </li>
                                                                    )}

                                                                    {this.state.chatTabs.visibleTabs.indexOf("clientTab") !== -1 && (
                                                                        <li className="nav-item" data-toggle="tooltip" title="Cliente">
                                                                            <a className={`btn py-2 rounded-0 dark nav-link ${this.state.chatTabs.currentTab === "clientTab" ? "active" : ""}`} href="/#" onClick={(e)=>this.toggleCurrentTab("clientTab", e)}  role="tab" aria-controls="client" aria-selected={this.state.chatTabs.currentTab === "clientTab" ? true : false}>
                                                                                <i className="fa fa-user" ></i>
                                                                            </a>
                                                                        </li>
                                                                    )}

                                                                    {this.state.chatTabs.visibleTabs.indexOf("shoppingCartTab") !== -1 && (
                                                                        <li className="nav-item" data-toggle="tooltip" title={this.translate('ATTENDANCE_ROOM_TAB_SHOPPING_CART')}>
                                                                            <a className={`btn py-2 rounded-0 dark nav-link ${this.state.chatTabs.currentTab === "shoppingCartTab" ? "active" : ""}`} href="/#" onClick={(e)=>this.toggleCurrentTab("shoppingCartTab", e)} role="tab" aria-controls="shopping" aria-selected={this.state.chatTabs.currentTab === "shoppingCartTab" ? true : false}>
                                                                                <i className="fa fa-shopping-cart" ></i>
                                                                            </a>
                                                                        </li>
                                                                    )}
                                                                    
                                                                    {(this.state.chatTabs.visibleTabs.indexOf("teamTab") !== -1 && this.state.allSubOrdersInvoiced)&& (
                                                                        <li className="nav-item" data-toggle="tooltip" title={this.translate('ATTENDANCE_ROOM_TAB_TEAM')}>
                                                                            <a className={`btn py-2 rounded-0 dark nav-link ${this.state.chatTabs.currentTab === "teamTab" ? "active" : ""}`} href="/#" onClick={(e)=>this.toggleCurrentTab("teamTab", e)}  role="tab" aria-controls="team" aria-selected={this.state.chatTabs.currentTab === "teamTab" ? true : false}>
                                                                                <i className="fa fa-users" ></i>
                                                                            </a>
                                                                        </li>
                                                                    )}
                                                                    
                                                                    <li className="nav-item" data-toggle="tooltip" title={this.translate('ATTENDANCE_ROOM_TAB_LOGS')}>
                                                                        <a className={`btn py-2 rounded-0 dark nav-link ${this.state.chatTabs.currentTab === "logTab" ? "active" : ""}`} href="/#" onClick={(e)=>this.toggleCurrentTab("logTab", e)}  role="tab" aria-controls="logs" aria-selected={this.state.chatTabs.currentTab === "logTab" ? true : false}>
                                                                            <i className="fa fa-list" ></i>
                                                                        </a>
                                                                    </li>
                                                                    
                                                                    <li className="nav-item" data-toggle="tooltip" title={this.translate('ATTENDANCE_ROOM_TAB_OPTIONS')}>
                                                                        <a className={`btn py-2 rounded-0 dark nav-link ${this.state.chatTabs.currentTab === "optionsTab" ? "active" : ""}`} href="/#" onClick={(e)=>this.toggleCurrentTab("optionsTab", e)}  role="tab" aria-controls="options" aria-selected={this.state.chatTabs.currentTab === "optionsTab" ? true : false}>
                                                                            <i className="fa fa-cogs" ></i>
                                                                        </a>
                                                                    </li>
                                                                    
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="row flex-grow-1">
                                                            <div className="col-12 tab-content" id="attendance_tab_content">
                                                                <div className={`row tab-pane fade white ${this.state.chatTabs.currentTab === "toolbarTab" ? "active show" : ""}`} id="tab_toolbar_attendance_toolbar" role="tabpanel" aria-labelledby="">
                                                                    <div className="col px-0">
                                                                        <div className="row">
                                                                            <div className="col attendance_main_tab">
                                                                                <span className="main_tab_title">{this.translate('ATTENDANCE_ROOM_ATTENDANCE_TOOLS')}</span>
                                                                            </div>
                                                                        </div>
                                                                       
                                                                        {/* <div className="row no-gutters">
                                                                            <div className="col-12 align-self-center">
                                                                                <h6>
                                                                                    <b>{this.translate('ATTENDANCE_ROOM_CURRENT_STATUS')}:</b> {(this.state.messages.userStatus === 'in_attendance') ? this.translate('ATTENDANCE_ROOM_IN_ATTENDANCE') : (this.state.messages.userStatus === 'finished') ? this.translate('ATTENDANCE_ROOM_FINISHED') : this.translate('ATTENDANCE_ROOM_ARCHIVED')}
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                        <br />
                                                                        <div className="row no-gutters">

                                                                            {
                                                                                ['in_attendance', 'finished', 'archived'].map((status, i) =>
                                                                                    <Fragment key={i}>
                                                                                        {
                                                                                            this.state.messages.userStatus !== status ? (
                                                                                                <div className="col-6 align-self-center">
                                                                                                    <button className={`btn btn-cursor-pointer ${((status === 'in_attendance') ? 'btn-success' : (status === 'finished') ? 'btn-warning' : 'btn-danger')} `} style={{ width: '90%' }} onClick={(e) => this.setStatusAttendance(e, status)}>
                                                                                                        {
                                                                                                            (status === 'in_attendance') ? this.translate('ATTENDANCE_ROOM_IN_ATTENDANCE') : (status === 'finished') ? this.translate('ATTENDANCE_ROOM_FINISHED') : this.translate('ATTENDANCE_ROOM_ARCHIVED')
                                                                                                        }
                                                                                                    </button>
                                                                                                </div>
                                                                                            ) : (false)
                                                                                        }
                                                                                    </Fragment>
                                                                                )
                                                                            }
                                                                        </div> */}
                                                                        
                                                                        <div id="accordion-tools" className="accordion">
                                                                            <div className="card" style={{ background: 'transparent' }}>
                                                                                <div style={{ cursor: 'pointer', background: 'transparent' }} className="card-header" id="headingClientInfo" onClick={()=>this.toggleAccordionPannel("#collapseClientInfo")} aria-expanded="true" aria-controls="collapseClientInfo">
                                                                                    <h6 className="mb-0 text-left">
                                                                                        {this.translate('ATTENDANCE_ROOM_CLIENT_INFORMATIONS')}
                                                                                        <span className="float-right">
                                                                                            <i className="fa fa-caret-down"></i>
                                                                                        </span>
                                                                                    </h6>
                                                                                </div>
                                                                                <div id="collapseClientInfo" className="collapse show" aria-labelledby="headingClientInfo" data-parent="#accordion-tools">
                                                                                    <div className="card-body text-left">
                                                                                        <div className="row py-2">
                                                                                            <div className="col">
                                                                                                {this.translate('ATTENDANCE_ROOM_NUMBER_OF_ATTENDANCES')}
                                                                                            </div>
                                                                                            <div className="col text-white">
                                                                                                {this.state.clientInfo.attendancesQuantity}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row py-2">
                                                                                            <div className="col">
                                                                                                {this.translate('ATTENDANCE_ROOM_DATE_LAST_ATTENDANCE')}
                                                                                            </div>
                                                                                            <div className="col text-white">
                                                                                                {this.state.clientInfo.lastAttendanceDate}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row py-2">
                                                                                            <div className="col">
                                                                                                {this.translate('ATTENDANCE_ROOM_CURRENT_COMMUNICATION_CHANNEL')}
                                                                                            </div>
                                                                                            <div className="col text-white">
                                                                                                {this.state.clientInfo.currentChannel}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row pt-1">
                                                                                            <div className="col">
                                                                                                <table id="attendance_room_client_info_table" className="col-12 table table-bordered">
                                                                                                    <thead>
                                                                                                        <tr>
                                                                                                            <td className="p-0 pb-1" style={{border: "none"}}  colSpan="2">{this.translate('ATTENDANCE_ROOM_ATTENDANCE_TIME')}</td>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                        
                                                                                                        <tr>
                                                                                                            <th>
                                                                                                                {this.translate('ATTENDANCE_ROOM_TOTAL')}
                                                                                                            </th>
                                                                                                            <th>
                                                                                                                {this.translate('ATTENDANCE_ROOM_ON_HOLD')}
                                                                                                            </th>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td className="text-white">{this.state.clientInfo.attendanceTime.total}</td>
                                                                                                            <td className="text-white">{this.state.clientInfo.attendanceTime.onHold}</td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="row">
                                                                                            <div className="col">
                                                                                                <table  className="col-12 table table-bordered" style={{border: "none"}}>
                                                                                                    <thead>
                                                                                                        <tr>
                                                                                                            <td className="p-0 pb-1" style={{border: "none"}}  colSpan="2">{this.translate('ATTENDANCE_ROOM_CAMPAIGN')}</td>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <th>{this.translate('ATTENDANCE_ROOM_TYPE')}</th>
                                                                                                            <td className="text-white">{this.state.clientInfo.campaign.type}</td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                    
                                                                                                </table>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row py-2 px-2">
                                                                                            <div id="accordion-attendance-client-info" className="accordion col px-0">
                                                                                                <div className="card" style={{ background: 'transparent' }}>
                                                                                                    <div style={{ cursor: 'pointer', background: 'transparent' }} className="card-header" id="headingOneClient" onClick={()=>this.toggleAccordionPannel("#collapseOneClient")} aria-expanded="true" aria-controls="collapseOneClient">
                                                                                                        <h6 className="mb-0 text-left collapsed">
                                                                                                            {this.translate('ATTENDANCE_ROOM_LEAD_INFO')}
                                                                                                            <span className="float-right">
                                                                                                                <i className="fa fa-caret-down"></i>
                                                                                                            </span>
                                                                                                        </h6>
                                                                                                    </div>
                                                                                                    <div id="collapseOneClient" className="collapse" aria-labelledby="headingOneClient" data-parent="#accordion-attendance-client-info">
                                                                                                        <div className="card-body">
                                                                                                            
                                                                                                            <div id="accordion_attendance_lead_management" className="accordion col px-0 my-2">
                                                                                                                <div className="card bg-transparent">
                                                                                                                    <div className="card-header btn-cursor-pointer bg-transparent" id="lead_management_option_1" onClick={()=>this.toggleAccordionPannel("#collapse_lead_management_1")} aria-expanded="true" aria-controls="collapse_lead_management_1">
                                                                                                                        <h6 className="mb-0 text-left collapsed">
                                                                                                                            {this.translate('ATTENDANCE_ROOM_LEAD_MANAGEMENT')}
                                                                                                                            <span className="float-right">
                                                                                                                                <i className="fa fa-caret-down"></i>
                                                                                                                            </span>
                                                                                                                        </h6>
                                                                                                                    </div>
                                                                                                                    <div id="collapse_lead_management_1" className="collapse" aria-labelledby="lead_management_option_1" data-parent="#accordion_attendance_lead_management">
                                                                                                                        <div className="card-body">
                                                                                                                            <button className="btn btn-info btn-shadow btn-cursor-pointer" style={{ width: '100%' }} onClick={()=>this.setState({isModalLeadMergeVisible: true})}>
                                                                                                                                <i className="fa fa-compress"></i> {this.translate('ATTENDANCE_ROOM_MERGE_LEAD')}
                                                                                                                            </button>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <form onSubmit={this.submitValuesLeadData}>
                                                                                                                <div className="form-group">
                                                                                                                    <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_TABLE_LABEL_NAME')}</label>
                                                                                                                    <input type="text" name="name" onChange={e => this.setState({ lead_data: { ...this.state.lead_data, name: e.target.value } })} value={this.state.lead_data.name} placeholder={this.translate('ATTENDANCE_ROOM_TABLE_PLACEHOLDER_NAME')} className="form-control box-shadow dark-important" required />
                                                                                                                </div>
                                                                                                                <div className="form-group">
                                                                                                                    <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_TABLE_LABEL_EMAIL')}</label>
                                                                                                                    <input type="text" name="email" onChange={e => this.setState({ lead_data: { ...this.state.lead_data, email: e.target.value } })} value={this.state.lead_data.email} placeholder={this.translate('ATTENDANCE_ROOM_TABLE_PLACEHOLDER_EMAIL')} className="form-control box-shadow dark-important" required />
                                                                                                                </div>
                                                                                                                <div className="form-group">
                                                                                                                    <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_TABLE_LABEL_PHONE')}</label>
                                                                                                                    <div style={{position: "relative"}}>
                                                                                                                        <input type="text" name="phone" onChange={e => this.setState({ lead_data: { ...this.state.lead_data, phone: e.target.value } })} value={this.state.lead_data.phone} placeholder={this.translate('ATTENDANCE_ROOM_TABLE_PLACEHOLDER_PHONE')} className="form-control box-shadow dark-important" required />
                                                                                                                        {this.state.lead_data.phone &&
                                                                                                                            !this.state.isLeadInCall ? 
                                                                                                                                <div style={{position: "absolute", top: 0, right: 5, bottom: 0, display: "flex", alignItems: "center"}} onClick={e=>this.setState({isModalCallVisible: true})}>
                                                                                                                                    <i className="fa fa-phone-square text-success text-lg btn-cursor-pointer"></i>
                                                                                                                                </div>
                                                                                                                            : 
                                                                                                                                <div style={{position: "absolute", top: 0, right: 5, bottom: 0, display: "flex", alignItems: "center"}} onClick={e=>{this.disconnectCall(); this.setState({isLeadInCall: false})}}>
                                                                                                                                    <i className="fa fa-phone-square text-danger text-lg btn-cursor-pointer"></i>
                                                                                                                                </div>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="form-group">
                                                                                                                    <button type="submit" disabled={this.checkValuesLeadDataHasEmptyFields() ? "disabled" : ""} className="btn btn-info btn-shadow btn-cursor-pointer" style={{ width: '100%' }}>
                                                                                                                        <i className="fa fa-save"></i> {this.translate('ATTENDANCE_ROOM_LEAD_REGISTER_INFO')}
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            </form>

                                                                                                            <div id="accordion_attendance_lead_another_info" className="accordion col px-0 my-2">
                                                                                                                <div className="card bg-transparent">
                                                                                                                    <div className="card-header btn-cursor-pointer bg-transparent" id="attendance_lead_another_info_1" onClick={()=>this.toggleAccordionPannel("#collapse_attendance_lead_another_info_1")} aria-expanded="true" aria-controls="collapse_attendance_lead_another_info_1">
                                                                                                                        <h6 className="mb-0 text-left collapsed">
                                                                                                                            {this.translate('ATTENDANCE_ROOM_LEAD_ANOTHER_INFO')}
                                                                                                                            <span className="float-right">
                                                                                                                                <i className="fa fa-caret-down"></i>
                                                                                                                            </span>
                                                                                                                        </h6>
                                                                                                                    </div>
                                                                                                                    <div id="collapse_attendance_lead_another_info_1" className="collapse" aria-labelledby="attendance_lead_another_info_1" data-parent="#accordion_attendance_lead_another_info">
                                                                                                                        <div className="card-body">
                                                                                                                           <h6>{this.translate('ATTENDANCE_ROOM_LEAD_PHONES')}</h6>
                                                                                                                           {(this.state.lead_data && this.state.lead_data.phones && this.state.lead_data.phones.length > 0) ?
                                                                                                                                this.state.lead_data.phones.map((phone, index)=>
                                                                                                                                    <p key={`${index}_phone_another_info`}>{phone}</p>
                                                                                                                                )
                                                                                                                            :
                                                                                                                                <p>{this.translate('ATTENDANCE_ROOM_LEAD_THERE_ARENT_OTHER_PHONES_REGISTERED')}</p>
                                                                                                                            }   
                                                                                                                           <hr/>
                                                                                                                           <h6>{this.translate('ATTENDANCE_ROOM_LEAD_EMAILS')}</h6>
                                                                                                                           {(this.state.lead_data && this.state.lead_data.emails && this.state.lead_data.emails.length > 0) ?
                                                                                                                                this.state.lead_data.emails.map((email, index)=>
                                                                                                                                    <p key={`${index}_email_another_info`}>{email}</p>
                                                                                                                                )
                                                                                                                            :
                                                                                                                                <p>{this.translate('ATTENDANCE_ROOM_LEAD_THERE_ARENT_OTHER_EMAILS_REGISTERED')}</p>
                                                                                                                            }  
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="card" style={{ background: 'transparent' }}>
                                                                                                    <div style={{ cursor: 'pointer', background: 'transparent' }} className="card-header" id="headingTwoClient" onClick={()=>this.toggleAccordionPannel("#collapseTwoClient")} aria-expanded="true" aria-controls="collapseTwoClient">
                                                                                                        <h6 className="mb-0 text-left collapsed">
                                                                                                            {this.translate('ATTENDANCE_ROOM_USED_CHANNELS')}
                                                                                                            <span className="float-right">
                                                                                                                <i className="fa fa-caret-down"></i>
                                                                                                            </span>
                                                                                                        </h6>
                                                                                                    </div>
                                                                                                    <div id="collapseTwoClient" className="collapse" aria-labelledby="headingTwoClient" data-parent="#accordion-attendance-client-info">
                                                                                                        <div className="card-body">
                                                                                                            <div className="row">
                                                                                                                {/* {this.state.clientInfo.usedChannels.length > 0 && this.state.clientInfo.usedChannels.map((channel, index)=> */}
                                                                                                                {(this.state.usedChannelsMockup.length > 0 && this.state.usedChannelsMockup.map((channel, index)=>
                                                                                                                    <div className="col-12" key={`usedChannels${index}`}>
                                                                                                                        <h6 className="text-capitalize">{channel.origin}</h6>
                                                                                                                        <div className="row">
                                                                                                                            {channel.expiration_date &&
                                                                                                                                <React.Fragment>
                                                                                                                                    <div className="col-6 py-1">
                                                                                                                                        Expira em:
                                                                                                                                    </div>
                                                                                                                                    <div className="col-6 py-1">
                                                                                                                                        <span className="text-white">{channel.expiration_date}</span>
                                                                                                                                    </div>
                                                                                                                                </React.Fragment>
                                                                                                                            }
                                                                                                                            {channel.last_used_date && 
                                                                                                                                <React.Fragment>
                                                                                                                                    <div className="col-6 py-1">
                                                                                                                                        Utilizado em:
                                                                                                                                    </div>
                                                                                                                                    <div className="col-6">
                                                                                                                                        <span className="text-white">{channel.last_used_date}</span>
                                                                                                                                    </div>
                                                                                                                                </React.Fragment>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                        <hr />
                                                                                                                    </div>
                                                                                                                ))}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="card" style={{ background: 'transparent' }}>
                                                                                                    <div style={{ cursor: 'pointer', background: 'transparent' }} className="card-header" id="headingThreeClient" onClick={()=>this.toggleAccordionPannel("#collapseThreeClient")} aria-expanded="true" aria-controls="collapseThreeClient">
                                                                                                        <h6 className="mb-0 text-left collapsed">
                                                                                                            {this.translate('ATTENDANCE_ROOM_REASON_FOR_CONTACT')}
                                                                                                            <span className="float-right">
                                                                                                                <i className="fa fa-caret-down"></i>
                                                                                                            </span>
                                                                                                        </h6>
                                                                                                    </div>
                                                                                                    <div id="collapseThreeClient" className="collapse" aria-labelledby="headingThreeClient" data-parent="#accordion-attendance-client-info">
                                                                                                        <div className="card-body">
                                                                                                            <form onSubmit={this.sendFeedback}>
                                                                                                                
                                                                                                                <div className="form-group">
                                                                                                                    <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_CONTACT_REASON')}</label>
                                                                                                                    <select name="contact_reason" onChange={e => this.setState({formSendFeedback: {...this.state.formSendFeedback, contact_reason: e.target.value}})} defaultValue={this.state.formSendFeedback.contact_reason} className="form-control box-shadow cool-select" required>
                                                                                                                        <option value="">{this.translate('ATTENDANCE_ROOM_SELECT_THE_CONTACT_REASON')}</option>
                                                                                                                        <option value="question">{this.translate('ATTENDANCE_ROOM_OPTION_QUESTIONS')}</option>
                                                                                                                        <option value="suggestion">{this.translate('ATTENDANCE_ROOM_OPTION_SUGGESTIONS')}</option>
                                                                                                                        <option value="complaint">{this.translate('ATTENDANCE_ROOM_OPTION_COMPLAINTS')}</option>
                                                                                                                        <option value="other">{this.translate('ATTENDANCE_ROOM_OPTION_OTHERS')}</option>
                                                                                                                    </select>
                                                                                                                </div>

                                                                                                                <div className="form-group">
                                                                                                                    <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_LABEL_CONTACT_DESCRIPTION')}</label>
                                                                                                                    <textarea name="contact_description" id="textarea-attendance-feedback" onChange={e => this.setState({formSendFeedback: {...this.state.formSendFeedback, contact_description: e.target.value}})} defaultValue={this.state.formSendFeedback.contact_description} placeholder={this.translate('ATTENDANCE_ROOM_PLACEHOLDER_CONTACT_DESCRIPTION')} className="form-control box-shadow dark-important" required />
                                                                                                                </div>
                                                                                                                
                                                                                                                <div className="form-group">
                                                                                                                    <button type="submit" disabled={this.checkFeedbackHasEmptyFields() ? "disabled" : ""} className="btn btn-info btn-shadow btn-cursor-pointer" style={{ width: '100%' }}>
                                                                                                                        <i className="fa fa-save"></i> {this.translate('ATTENDANCE_ROOM_LEAD_REGISTER_INFO')}
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            </form>

                                                                                                            <div className="row">
                                                                                                                {
                                                                                                                    (typeof this.state.feedbacks !== 'undefined' && this.state.feedbacks.length) ? (
                                                                                                                        this.state.feedbacks.map((feedback, i) => 
                                                                                                                            <div key={i} className="col-12">
                                                                                                                                <p>
                                                                                                                                    <b>{this.translate('ATTENDANCE_ROOM_REASON')}:</b> {feedback.reason}
                                                                                                                                </p>
                                                                                                                                <p>
                                                                                                                                    <b>{this.translate('ATTENDANCE_ROOM_DESCRIPTION')}:</b> {feedback.description}
                                                                                                                                </p>
                                                                                                                                <p>
                                                                                                                                    <b>{this.translate('ATTENDANCE_ROOM_DATE')}:</b> {feedback.created_at}
                                                                                                                                </p>
                                                                                                                                <hr />
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    ):(false)
                                                                                                                }
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row py-2">
                                                                                            <div className="col">
                                                                                                {(this.state.initBuy === false) ? (
                                                                                                    <button onClick={(e)=>this.startInitBuy(e)} className="btn btn-info btn-shadow btn-cursor-pointer" style={{ width: '100%' }}>
                                                                                                        <i className="fa fa-shopping-cart"></i> {this.translate('ATTENDANCE_ROOM_START_PURCHASE')}
                                                                                                    </button>
                                                                                                )  : (
                                                                                                    this.state.initCart === true && (
                                                                                                        <button onClick={()=>this.toggleCurrentTab("shoppingCartTab")} className="btn btn-info btn-shadow btn-cursor-pointer" style={{ width: '100%' }}>
                                                                                                            <i className="fa fa-shopping-cart"></i> {this.translate('ATTENDANCE_ROOM_VIEW_SHOPPING_CART')}
                                                                                                        </button>
                                                                                                    )
                                                                                                )}
                                                                                                                
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                            {/*
                                                                            <div className="card" style={{ background: 'transparent' }}>
                                                                                <div style={{ cursor: 'pointer', background: 'transparent' }} className="card-header" id="headingTwo" onClick={()=>this.toggleAccordionPannel("#collapseTwo")} aria-expanded="false" aria-controls="collapseTwo">
                                                                                    <h6 className="mb-0 text-left collapsed">
                                                                                        {this.translate('ATTENDANCE_ROOM_TAB_ATTENDANCES')}
                                                                                        <span className="float-right">
                                                                                            <i className="fa fa-caret-down"></i>
                                                                                        </span>
                                                                                    </h6>
                                                                                </div>
                                                                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion-tools">
                                                                                    <div className="card-body">
                                                                                        <form onSubmit={(e) => e.preventDefault()}>
                                                                                            <div className="form-group">
                                                                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">Digite o CEP do Cliente</label>
                                                                                                <input type="text" name="tools_postal_code" onChange={e => this.handleToolsClientPostalCodeChange(e)} value={this.state.tools.postalCode} placeholder="Insira o CEP do cliente" className="form-control box-shadow dark-important" required />
                                                                                            </div>
                                                                                            {this.state.tools.postalCode && this.state.tools.postalCode.length > 7 && this.state.tools.businessList.length > 0 && (
                                                                                                <div className="form-group">
                                                                                                    <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">Nome do Supermercado</label>
                                                                                                    <select className="form-control box-shadow dark-important" onChange={e => this.setState({ tools: { ...this.state.tools, businessCode: e.target.value } })}>
                                                                                                        <option value="">Selecione o Nome do Supermercado</option>
                                                                                                    {
                                                                                                        this.state.tools.businessList.map((business, index) =>
                                                                                                            <option key={index} value={`${business.business_code}`}>{business.business_name}</option>
                                                                                                        )
                                                                                                    } 
                                                                                                    </select>
                                                                                                </div>
                                                                                            )}
                                                                                        </form>
                                                                                        <div>
                                                                                            
                                                                                            {this.state.tools.postalCode && this.state.tools.postalCode.length > 7  && this.state.tools.businessCode && (
                                                                                                <button type="button" onClick={this.createNewRequest} className="btn btn-info btn-shadow btn-cursor-pointer" style={{ width: '100%' }}>
                                                                                                    <i className="fa fa-save"></i> {this.translate('ATTENDANCE_ROOM_CREATE_NEW_ATTENDANCE')}
                                                                                                </button>
                                                                                            )}
                                                                                        </div>
                                                                                        <hr />
                                                                                        {
                                                                                            this.state.requests.length ? (
                                                                                                <div id="accordion-tools-requests">
                                                                                                    {
                                                                                                        this.state.requests.map((request, i) =>
                                                                                                            <div key={i} className="card" style={{ background: 'transparent' }}>
                                                                                                                <div style={{ cursor: 'pointer', background: 'transparent' }} className="card-header" id={`headingRequest${i}`} onClick={()=>this.toggleAccordionPannel(`#collapseRequest${i}`)} aria-expanded="false" aria-controls={`collapseRequest${i}`}>
                                                                                                                    <h6 className="mb-0 text-left">
                                                                                                                        {
                                                                                                                            (request.status === 'in_attendance' || request.status === 'is_finished') ? (
                                                                                                                                <Fragment>
                                                                                                                                    {this.translate('ATTENDANCE_ROOM_CURRENT_ATTENDANCE')}
                                                                                                                                </Fragment>
                                                                                                                            ) : (
                                                                                                                                    <Fragment>
                                                                                                                                        {new Date(parseInt(request.created_at.$date.$numberLong)).toLocaleString()}
                                                                                                                                    </Fragment>
                                                                                                                                )
                                                                                                                        }
                                                                                                                        <span className="float-right">
                                                                                                                            <i className="fa fa-caret-down"></i>
                                                                                                                        </span>
                                                                                                                    </h6>
                                                                                                                </div>
                                                                                                                <div id={`collapseRequest${i}`} className={`p-1 collapse ${(i === 0) ? 'show' : false}`} aria-labelledby={`headingRequest${i}`} data-parent="#accordion-tools-requests">

                                                                                                                    <br />

                                                                                                                    <form onSubmit={(e) => e.preventDefault()}>
                                                                                                                        {(request.status === 'in_attendance' ) ? (
                                                                                                                            request.business_code && request.business_code !== "0" ? (
                                                                                                                                <div className="form-group">
                                                                                                                                    <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">Supermercado</label>
                                                                                                                                    <select className="form-control box-shadow dark-important" onChange={e => this.changeCommercialTeam(i, 'business_code', e.target.value)} value={`${this.state.requests[i].business_code}`} disabled={(request.status === 'in_attendance' || request.status === 'is_finished') ? false : true} required>
                                                                                                                                        <option value={`${this.state.requests[i].business_code}`}>{this.state.requests[i].business_code}</option>

                                                                                                                                        {// {
                                                                                                                                         //   this.state.requestTools.businessList.map((business, index) =>
                                                                                                                                         //       <option key={index} value={`${business.business_code}`}>{business.business_name}</option>
                                                                                                                                         //   )
                                                                                                                                        } }

                                                                                                                                    </select>
                                                                                                                                </div>
                                                                                                                            ) : (
                                                                                                                                <React.Fragment>
                                                                                                                                    <div className="form-group">
                                                                                                                                        <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">Digite o CEP do Cliente</label>
                                                                                                                                        <input type="text" name="tools_postal_code" onChange={e => this.handleRequestToolsClientPostalCodeChange(e)} value={this.state.requestTools.postalCode} placeholder="Insira o CEP do cliente" className="form-control box-shadow dark-important" required />
                                                                                                                                    </div>
                                                                                                                                    {this.state.requestTools.postalCode && this.state.requestTools.postalCode.length > 7 && this.state.requestTools.businessList.length > 0 && (

                                                                                                                                        <div className="form-group">
                                                                                                                                            <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">Supermercado</label>
                                                                                                                                            <select className="form-control box-shadow dark-important" onChange={e => this.changeCommercialTeam(i, 'business_code', e.target.value)} value={`${this.state.requests[i].business_code}`} disabled={(request.status === 'in_attendance' || request.status === 'is_finished') ? false : true} required>
                                                                                                                                                <option value="0">Selecione o Supermercado</option>
                                                                                                                                                {
                                                                                                                                                    this.state.requestTools.businessList.map((business, index) =>
                                                                                                                                                        <option key={index} value={`${business.business_code}`}>{business.business_name}</option>
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                            </select>
                                                                                                                                        </div>
                                                                                                                                    )}
                                                                                                                                </React.Fragment>
                                                                                                                            )
                                                                                                                        ) : (
                                                                                                                            <div className="form-group">
                                                                                                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">Supermercado</label>
                                                                                                                                <select className="form-control box-shadow dark-important" onChange={e => this.changeCommercialTeam(i, 'business_code', e.target.value)} value={`${this.state.requests[i].business_code}`} disabled={(request.status === 'in_attendance' || request.status === 'is_finished') ? false : true} required>
                                                                                                                                    <option value="0">Selecione o Supermercado</option>
                                                                                                                                    {
                                                                                                                                        this.state.supermarkets.map((supermarket, ism) =>
                                                                                                                                            <option key={ism} value={`${supermarket.id}`}>{supermarket.name}</option>
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                </select>
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                        <div className="form-group">
                                                                                                                            <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_LABEL_SHOPPER')}</label>
                                                                                                                            <select className="form-control box-shadow dark-important" onChange={e => this.changeCommercialTeam(i, 'shopper', e.target.value)} value={`${this.state.requests[i].shopper}`} disabled={(request.status === 'in_attendance' || request.status === 'is_finished') ? false : true} required>
                                                                                                                                <option value="0">{this.translate('ATTENDANCE_ROOM_LABEL_OPTION_CHOOSE_RESPONSIBLE_SHOPPER')}</option>
                                                                                                                                {
                                                                                                                                    this.state.users.map((user, iu) =>
                                                                                                                                        <option key={iu} value={`${user.id}`}>{user.name}</option>
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            </select>
                                                                                                                        </div>
                                                                                                                        <div className="form-group">
                                                                                                                            <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_LABEL_DELIVERYMAN')}</label>
                                                                                                                            <select className="form-control box-shadow dark-important" onChange={e => this.changeCommercialTeam(i, 'deliveryman', e.target.value)} value={`${this.state.requests[i].deliveryman}`} disabled={(request.status === 'in_attendance' || request.status === 'is_finished') ? false : true} required>
                                                                                                                                <option value="0">{this.translate('ATTENDANCE_ROOM_LABEL_OPTION_CHOOSE_RESPONSIBLE_DELIVERYMAN')}</option>
                                                                                                                                {
                                                                                                                                    this.state.users.map((user, iu) =>
                                                                                                                                        <option key={iu} value={`${user.id}`}>{user.name}</option>
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            </select>
                                                                                                                        </div>

                                                                                                                        <div className="form-group">
                                                                                                                            <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_LABEL_ATTENDANCE_RESPONSIBLE')}</label>
                                                                                                                            <select className="form-control box-shadow dark-important" onChange={e => this.changeCommercialTeam(i, 'channel', e.target.value)} value={`${this.state.requests[i].channel}`} disabled={(request.status === 'in_attendance' || request.status === 'is_finished') ? false : true} required>
                                                                                                                                <option value="attendance">{this.translate('ATTENDANCE_ROOM_OPTION_PRE_ATTENDANCE')}</option>
                                                                                                                                <option value="shopper">{this.translate('ATTENDANCE_ROOM_OPTION_SHOPPER')}</option>
                                                                                                                                <option value="deliveryman">{this.translate('ATTENDANCE_ROOM_OPTION_DELIVERYMAN')}</option>
                                                                                                                            </select>
                                                                                                                        </div>

                                                                                                                        <div className="form-group">
                                                                                                                            <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_LABEL_STATUS')}</label>
                                                                                                                            <select className="form-control box-shadow dark-important" onChange={e => this.changeCommercialTeam(i, 'status', e.target.value)} value={`${this.state.requests[i].status}`} disabled={(request.status === 'in_attendance' || request.status === 'is_finished') ? false : true} required>
                                                                                                                                <option value="in_attendance">{this.translate('ATTENDANCE_ROOM_IN_ATTENDANCE')}</option>
                                                                                                                                <option value={(request.status === 'in_attendance') ? 'is_finished' : 'finished'}>{this.translate('ATTENDANCE_ROOM_FINISHED')}</option>
                                                                                                                            </select>
                                                                                                                        </div>
                                                                                                                        {
                                                                                                                            (request.status === 'in_attendance' || request.status === 'is_finished') ? (
                                                                                                                                <div className="form-group">
                                                                                                                                    <button type="submit" onClick={(e) => this.submitValuesRequest(e, i, this.state.messages.userId, request.request)} className="btn btn-info btn-shadow" style={{ width: '100%' }}>
                                                                                                                                        <i className="fa fa-save"></i> {this.translate('ATTENDANCE_ROOM_LEAD_REGISTER')}
                                                                                                                                    </button>
                                                                                                                                </div>
                                                                                                                            ) : (false)
                                                                                                                        }
                                                                                                                    </form>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                            ) : (false)
                                                                                        }

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            */}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={`row tab-pane fade white box-shadow p-0 h-100 ${this.state.chatTabs.currentTab === "clientTab" ? "active show" : ""}`} id="tab_toolbar_client" role="tabpanel" aria-labelledby="">
                                                                    <div id="accordion-client-buyers" className="accordion">
                                                                        <div className="col p-0">
                                                                            <div className="row">
                                                                                <div className="col attendance_main_tab">
                                                                                    <span className="main_tab_title">{this.translate('ATTENDANCE_ROOM_CLIENT_PURCHASES')}</span>
                                                                                </div>
                                                                            </div>
                                                                            {this.state.clientBuyerHistory.isLoading ? (
                                                                                <div className="row">
                                                                                    <div className="col-12 text-center">
                                                                                        <i className="fa fa-spinner fa-spin" /> {this.translate('ICON_LOADING')}
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    <div className="row">
                                                                                        <div className="col p-0">
                                                                                            <div className="card" style={{ background: 'transparent' }}>
                                                                                                <div style={{ cursor: 'pointer', background: 'transparent' }} className="card-header" id="headingOtherSupermarket" onClick={()=>this.toggleAccordionPannel("#collapseOtherSupermarket")} aria-expanded="true" aria-controls="collapseOtherSupermarket">
                                                                                                    <h6 className="mb-0 text-left collapsed">
                                                                                                        {this.translate('ATTENDANCE_ROOM_START_NEW_PURCHASE')}
                                                                                                        <span className="float-right">
                                                                                                            <i className="fa fa-caret-down"></i>
                                                                                                        </span>
                                                                                                    </h6>
                                                                                                </div>
                                                                                                <div id="collapseOtherSupermarket" className={`collapse ${this.state.clientBuyerHistory.supermarkets.length === 0 ? "show" : ""}`} aria-labelledby="headingOtherSupermarket" data-parent="#accordion-client-buyers">
                                                                                                    <div className="card-body">
                                                                                                        <form>
                                                                                                            <div className="form-group">
                                                                                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_LABEL_CLIENT_DOCUMENT')}</label>
                                                                                                                <input type="text" placeholder={this.translate('ATTENDANCE_ROOM_PLACEHOLDER_CLIENT_DOCUMENT')} onChange={e=>this.updateClientBuyerDocument(e)} className="form-control box-shadow dark-important" />
                                                                                                            </div>

                                                                                                            <div className="form-group">
                                                                                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_LABEL_CLIENT_POSTAL_CODE')}</label>
                                                                                                                <input type="text" placeholder={this.translate('ATTENDANCE_ROOM_PLACEHOLDER_CLIENT_POSTAL_CODE')} onChange={e=>this.updateClientBuyerPostalCode(e)} className="form-control box-shadow dark-important" />
                                                                                                            </div>
                                                                                                            
                                                                                                            <div className="form-group">
                                                                                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_LABEL_CLIENT_STATE')}</label>
                                                                                                                <input type="text" placeholder={this.translate('ATTENDANCE_ROOM_PLACEHOLDER_CLIENT_STATE')} onChange={e=>this.updateClientBuyerField(e, "state")} value={this.state.clientBuyer.state} className="form-control box-shadow dark-important" disabled={this.state.clientBuyer.postalCode.length === 9 ? "" : "disabled"} />
                                                                                                            </div>
                                                                                                            
                                                                                                            <div className="form-group">
                                                                                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_LABEL_CLIENT_CITY')}</label>
                                                                                                                <input type="text" placeholder={this.translate('ATTENDANCE_ROOM_PLACEHOLDER_CLIENT_CITY')} onChange={e=>this.updateClientBuyerField(e, "city")} value={this.state.clientBuyer.city} className="form-control box-shadow dark-important" disabled={this.state.clientBuyer.postalCode.length === 9 ? "" : "disabled"} />
                                                                                                            </div>
                                                                                                            
                                                                                                            <div className="form-group">
                                                                                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_LABEL_CLIENT_STREET')}</label>
                                                                                                                <input type="text" placeholder={this.translate('ATTENDANCE_ROOM_PLACEHOLDER_CLIENT_STREET')} onChange={e=>this.updateClientBuyerField(e, "street")} value={this.state.clientBuyer.street} className="form-control box-shadow dark-important" disabled={this.state.clientBuyer.postalCode.length === 9 ? "" : "disabled"} />
                                                                                                            </div>
                                                                                                        
                                                                                                            <div className="form-group">
                                                                                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_LABEL_CLIENT_NEIGHBORHOOD')}</label>
                                                                                                                <input type="text" placeholder={this.translate('ATTENDANCE_ROOM_PLACEHOLDER_CLIENT_NEIGHBORHOOD')} onChange={e=>this.updateClientBuyerField(e, "neighborhood")} value={this.state.clientBuyer.neighborhood} className="form-control box-shadow dark-important" disabled={this.state.clientBuyer.postalCode.length === 9 ? "" : "disabled"} />
                                                                                                            </div>
                                                                                                            
                                                                                                            <div className="form-group">
                                                                                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_LABEL_CLIENT_ADDRESS_NUMBER')}</label>
                                                                                                                <input type="text" placeholder={this.translate('ATTENDANCE_ROOM_PLACEHOLDER_CLIENT_ADDRESS_NUMBER')} onChange={e=>this.updateClientBuyerField(e, "addressNumber")} value={this.state.clientBuyer.addressNumber} className="form-control box-shadow dark-important" disabled={this.state.clientBuyer.postalCode.length === 9 ? "" : "disabled"} />
                                                                                                            </div>
                                                                                                            
                                                                                                            <div className="form-group">
                                                                                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_LABEL_CLIENT_ADDRESS_COMPLEMENT')}</label>
                                                                                                                <input type="text" placeholder={this.translate('ATTENDANCE_ROOM_PLACEHOLDER_CLIENT_ADDRESS_COMPLEMENT')} onChange={e=>this.updateClientBuyerField(e, "addressComplement")} value={this.state.clientBuyer.addressComplement} className="form-control box-shadow dark-important" disabled={this.state.clientBuyer.postalCode.length === 9 ? "" : "disabled"} />
                                                                                                            </div>
                                                                                                            
                                                                                                            <div className={`form-group ${(this.state.clientBuyer.addressInfoFulfilled && this.state.clientBuyer.supermarketList.length > 0) ? "" : "d-none"}`}>
                                                                                                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_LABEL_CHOOSE_SUPERMARKET')}</label>
                                                                                                                <select name="client_choose_supermarket" onChange={e=>this.updateClientBuyerField(e, "supermarketSelectedId")} className="form-control box-shadow cool-select">
                                                                                                                    <option value="">{this.translate('ATTENDANCE_ROOM_OPTION_SELECT_THE_SUPERMARKET')}</option>
                                                                                                                    {
                                                                                                                        this.state.clientBuyer.supermarketList.map((supermarket, index) =>
                                                                                                                            <option key={"supermarket" + supermarket.business_code + index} value={`${supermarket.business_code}`}>{supermarket.business_name}</option>
                                                                                                                        )
                                                                                                                    }
                                                                                                                </select>
                                                                                                            </div> 
                                                                                                            
                                                                                                            <div className="form-group">
                                                                                                                <button className="btn btn-info btn-shadow btn-cursor-pointer" disabled={`${this.state.clientBuyer.allFulfilled ? "" : "disabled"}`} style={{ width: '100%' }} onClick={e=>this.handleClientBuyerStartOtherShoppingCart(e)}>
                                                                                                                    <i className="fa fa-shopping-cart"></i> {this.translate('ATTENDANCE_ROOM_START_SHOPPING_CART')}
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </form>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {this.state.clientBuyerHistory.supermarkets.length > 0 && this.state.clientBuyerHistory.supermarkets.map((supermarket, index)=>
                                                                                        <div className="row" key={`${supermarket.name}${index}`}>
                                                                                            <div className="col p-0">
                                                                                                <div className="card" style={{ background: 'transparent' }}>
                                                                                                    <div style={{ cursor: 'pointer', background: 'transparent' }} className="card-header" id={`headingSupermarket${index}`} onClick={()=>this.toggleAccordionPannel(`#collapseSupermarket${index}`)} aria-expanded="true" aria-controls={`collapseSupermarket${index}`}>
                                                                                                        <h6 className="mb-0 text-left collapsed">
                                                                                                            {supermarket.name}
                                                                                                            <span className="float-right">
                                                                                                                <i className="fa fa-caret-down"></i>
                                                                                                            </span>
                                                                                                        </h6>
                                                                                                    </div>
                                                                                                    <div id={`collapseSupermarket${index}`} className={`collapse ${index === 0 ? "show" : ""} `} aria-labelledby={`headingSupermarket${index}`} data-parent="#accordion-client-buyers">
                                                                                                        <div className="card-body">
                                                                                                            <div className="accordion" id={`accordion-purchases${index}`}>
                                                                                                                {supermarket.purchases.length > 0 && supermarket.purchases.map((purchase, pIndex)=>
                                                                                                                    <div className="col p-0" key={`purchase${purchase}${pIndex}`}>
                                                                                                                        <div className="card" style={{ background: 'transparent' }}>
                                                                                                                            <div style={{ cursor: 'pointer', background: 'transparent' }} className="card-header" id={`heading-purchases-${index}-${pIndex}`} onClick={()=>this.toggleAccordionPannel(`#collapse-purchases-${index}-${pIndex}`)} aria-expanded="true" aria-controls={`collapse-purchases-${index}-${pIndex}`}>
                                                                                                                                <h6 className="mb-0 text-left collapsed">
                                                                                                                                    {this.translate('ATTENDANCE_ROOM_PURCHASE')} {this.formatDateToBrazilianFormat(purchase.date)}
                                                                                                                                    <span className="float-right">
                                                                                                                                        <i className="fa fa-caret-down"></i>
                                                                                                                                    </span>
                                                                                                                                </h6>
                                                                                                                            </div>
                                                                                                                            <div id={`collapse-purchases-${index}-${pIndex}`} className={`collapse ${(index === 0 && pIndex === 0) ? "show" : ""}`} aria-labelledby={`heading-purchases-${index}-${pIndex}`} data-parent={`#accordion-purchases${index}`}>
                                                                                                                                <div className="card-body">
                                                                                                                                    <button className="btn btn-info btn-shadow mb-2 btn-cursor-pointer" style={{ width: '100%' }} onClick={e=>this.handleClientBuyerRebuyShoppingCart(e, purchase.order_id)}>
                                                                                                                                        <i className="fa fa-shopping-cart "></i> {this.translate('ATTENDANCE_ROOM_REPURCHASE')}
                                                                                                                                    </button>
                                                                                                                                    <div className="history-buyer-products-list">
                                                                                                                                    {purchase.products.length > 0 && purchase.products.map((product, productIndex)=>
                                                                                                                                            <div className="row text-left" key={`product${index}${pIndex}${productIndex}`}>
                                                                                                                                                <hr className="col-12" />
                                                                                                                                                <div className="col">
                                                                                                                                                    {product.name}
                                                                                                                                                </div>
                                                                                                                                                <div className="col">
                                                                                                                                                    {product.quantity} un
                                                                                                                                                </div>
                                                                                                                                                <div className="col">
                                                                                                                                                    R$ {product.price}
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        )}
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </React.Fragment>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={`row tab-pane fade py-0 white box-shadow h-100 ${this.state.chatTabs.currentTab === "shoppingCartTab" ? "active show" : ""}`} id="tab_toolbar_shopping_cart" role="tabpanel" aria-labelledby="">
                                                                    <div className="col">
                                                                        <AttendanceCart 
                                                                            toggleAccordionPannel={this.toggleAccordionPannel} 
                                                                            handleMessageSubmit={this.handleMessageSubmit} 
                                                                            idAttendance={this.state.tools.idAttendance} 
                                                                            toggleCurrentTab={this.toggleCurrentTab} 
                                                                            initCart={this.state.initCart}
                                                                            blockCart={this.state.blockCart}
                                                                            cartStatus={this.state.cartStatus}
                                                                            pausePurchase={this.state.pausePurchase}
                                                                            isCartStatusLoaded={this.state.isCartStatusLoaded}
                                                                            handleTogglePausePurchase={this.handleTogglePausePurchase}
                                                                            handleToggleBlockCart={this.handleToggleBlockCart}
                                                                            handleToggleRemovePurchase={this.handleToggleRemovePurchase}
                                                                            getCartStatusIcon={this.getCartStatusIcon}
                                                                            removePurchase={this.removePurchase}
                                                                            checkScreenWidthIsOnInterval={this.checkScreenWidthIsOnInterval}
                                                                            onChangeSubOrders={this.onChangeSubOrders}
                                                                            translate={this.translate}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className={`row tab-pane fade py-0 white box-shadow h-100 ${this.state.chatTabs.currentTab === "teamTab" ? "active show" : ""}`} id="tab_toolbar_team" role="tabpanel" aria-labelledby="">
                                                                    <div className="col">
                                                                        <div className="row">
                                                                            <div className="col attendance_main_tab">
                                                                                <span className="main_tab_title">{this.translate('ATTENDANCE_ROOM_TEAM')}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row pt-2">
                                                                            <div className="col px-1">
                                                                                {this.state.initTeam ? ( 
                                                                                    <React.Fragment>
                                                                                        {
                                                                                            
                                                                                            (this.state.statusRequestCheck === "awaiting_scripting") ? (
                                                                                                <React.Fragment>
                                                                                                    <p>Roteirização</p>
                                                                                                    <div>
                                                                                                        <a
                                                                                                            href={`${process.env.REACT_APP_API_URL_BASE}/v1/attendance/barcode/format/html`}
                                                                                                            className="btn btn-info btn-shadow btn-cursor-pointer"
                                                                                                            style={{ width: '100%' }}
                                                                                                            target={`_blank`}
                                                                                                        >
                                                                                                            <i className="fa fa-barcode"></i> Gerar Etiqueta
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <hr />
                                                                                                    <form onSubmit={this.handleUpdateDeliveryAndStatus}>

                                                                                                        <div className={`form-group ${this.state.users.length > 0 ? "" : "d-none"}`}>
                                                                                                            <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_SELECT_THE_DELIVERYMAN')}</label>
                                                                                                            <select onChange={e=>this.updateDeliverymanAndStatusField(e, "deliveryman")} value={this.state.formUpdateDeliverymanAndStatus.deliveryman} className="form-control box-shadow cool-select" required>
                                                                                                                <option value="">{this.translate('ATTENDANCE_ROOM_SELECT_THE_DELIVERYMAN')}</option>
                                                                                                                {
                                                                                                                    this.state.users.map((deliveryman, index) =>
                                                                                                                        <option key={"deliveryman" + deliveryman.id + index} value={`${deliveryman.id}`}>{deliveryman.name}</option>
                                                                                                                    )
                                                                                                                }
                                                                                                            </select>
                                                                                                        </div>
                                                                                                        
                                                                                                        <div className="form-group">
                                                                                                            <button type="submit" className="btn btn-info btn-shadow btn-cursor-pointer" style={{ width: '100%' }} >
                                                                                                                <i className="fa fa-paper-plane"></i> {this.translate('ATTENDANCE_ROOM_UPDATE_DELIVERYMAN_AND_STATUS')} 
                                                                                                            </button>
                                                                                                        </div>

                                                                                                    </form>
                                                                                                </React.Fragment>
                                                                                            ):(
                                                                                                <React.Fragment> 
                                                                                                    
                                                                                                    <p>{this.translate('ATTENDANCE_ROOM_THE_ATTENDANCE_WAS_SENT_TO_SHOPPER')}</p>
                                                                                                    <hr />
                                                                                                    <p>Equipe Comercial</p>
                                                                                                    <form onSubmit={this.handleUpdateCommercialTeam}>
                                                                                                        <div className={`form-group ${this.state.users.length > 0 ? "" : "d-none"}`}>
                                                                                                            <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_SELECT_THE_SHOPPER')}</label>
                                                                                                            <select onChange={e=>this.updateCommercialTeamField(e, "shopper")} value={this.state.formUpdateCommercialTeam.shopper} className="form-control box-shadow cool-select" required>
                                                                                                                <option value="">{this.translate('ATTENDANCE_ROOM_SELECT_THE_SHOPPER')}</option>
                                                                                                                {
                                                                                                                    this.state.users.map((shopper, index) =>
                                                                                                                        <option key={"shopper" + shopper.id + index} value={`${shopper.id}`}>{shopper.name}</option>
                                                                                                                    )
                                                                                                                }
                                                                                                            </select>
                                                                                                        </div>
                                                                                                        
                                                                                                        <div className={`form-group ${this.state.users.length > 0 ? "" : "d-none"}`}>
                                                                                                            <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_SELECT_THE_DELIVERYMAN')}</label>
                                                                                                            <select onChange={e=>this.updateCommercialTeamField(e, "deliveryman")} value={this.state.formUpdateCommercialTeam.deliveryman} className="form-control box-shadow cool-select" required>
                                                                                                                <option value="">{this.translate('ATTENDANCE_ROOM_SELECT_THE_DELIVERYMAN')}</option>
                                                                                                                {
                                                                                                                    this.state.users.map((deliveryman, index) =>
                                                                                                                        <option key={"deliveryman" + deliveryman.id + index} value={`${deliveryman.id}`}>{deliveryman.name}</option>
                                                                                                                    )
                                                                                                                }
                                                                                                            </select>
                                                                                                        </div>
                                                                                                        
                                                                                                        <div className="form-group">
                                                                                                            <button type="submit" className="btn btn-info btn-shadow btn-cursor-pointer" style={{ width: '100%' }} >
                                                                                                                <i className="fa fa-paper-plane"></i> {this.translate('ATTENDANCE_ROOM_UPDATE_COMMERCIAL_TEAM')} 
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    </form>     

                                                                                                    <hr />
                                                                                                </React.Fragment>
                                                                                            )
                                                                                        }
                                                                                        
                                                                                    </React.Fragment>
                                                                                ) : (

                                                                                    <form>
                                                                                        <div className={`form-group`}>
                                                                                            <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_NEED_SHOPPER_HELP')}</label>
                                                                                            <select onChange={e=>this.updateClientTeamField(e, "needShopperHelp")} value={this.state.clientTeam.needShopperHelp} className="form-control box-shadow cool-select">
                                                                                                <option value="">{this.translate('ATTENDANCE_ROOM_NEED_SHOPPER_HELP')}</option>
                                                                                                <option value="yes">{this.translate('ATTENDANCE_ROOM_YES')}</option>
                                                                                                <option value="no">{this.translate('ATTENDANCE_ROOM_NO')}</option>
                                                                                            </select>
                                                                                        </div> 
                                                                                        
                                                                                        <div className={`form-group ${this.state.clientTeam.needShopperHelp === "yes" ? "" : "d-none"}`}>
                                                                                            <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_COMMENT_TO_ATTENDANCE_GROUP')}</label>
                                                                                            <textarea placeholder={this.translate('ATTENDANCE_ROOM_WRITE_THE_COMMENT')} onChange={e=>this.updateClientTeamField(e, "commentToTeam")} value={this.state.clientTeam.commentToTeam} className="form-control box-shadow dark-important" />
                                                                                        </div>

                                                                                        <div className={`form-group ${this.state.users.length > 0 ? "" : "d-none"}`}>
                                                                                            <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_SELECT_THE_SHOPPER')}</label>
                                                                                            <select onChange={e=>this.updateClientTeamField(e, "shopperSelected")} value={this.state.clientTeam.shopperSelected} className="form-control box-shadow cool-select">
                                                                                                <option value="">{this.translate('ATTENDANCE_ROOM_SELECT_THE_SHOPPER')}</option>
                                                                                                {
                                                                                                    this.state.users.map((shopper, index) =>
                                                                                                        <option key={"shopper" + shopper.id + index} value={`${shopper.id}`}>{shopper.name}</option>
                                                                                                    )
                                                                                                }
                                                                                            </select>
                                                                                        </div>
                                                                                        
                                                                                        <div className={`form-group ${this.state.users.length > 0 ? "" : "d-none"}`}>
                                                                                            <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_SELECT_THE_DELIVERYMAN')}</label>
                                                                                            <select onChange={e=>this.updateClientTeamField(e, "deliverymanSelected")} value={this.state.clientTeam.deliverymanSelected} className="form-control box-shadow cool-select">
                                                                                                <option value="">{this.translate('ATTENDANCE_ROOM_SELECT_THE_DELIVERYMAN')}</option>
                                                                                                {
                                                                                                    this.state.users.map((deliveryman, index) =>
                                                                                                        <option key={"deliveryman" + deliveryman.id + index} value={`${deliveryman.id}`}>{deliveryman.name}</option>
                                                                                                    )
                                                                                                }
                                                                                            </select>
                                                                                        </div>

                                                                                        <div className={`form-group`}>
                                                                                            <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_DELIVERY_TYPE')}</label>
                                                                                            <select onChange={e=>this.updateClientTeamField(e, "deliveryType")} value={this.state.clientTeam.deliveryType} className="form-control box-shadow cool-select">
                                                                                                <option value="">{this.translate('ATTENDANCE_ROOM_CHOOSE_DELIVERY_TYPE')}</option>
                                                                                                <option value="pop">{this.translate('ATTENDANCE_ROOM_POP')}</option>
                                                                                                <option value="programmed">{this.translate('ATTENDANCE_ROOM_PROGRAMMED')}</option>
                                                                                                <option value="prime">{this.translate('ATTENDANCE_ROOM_PRIME')}</option>
                                                                                            </select>
                                                                                        </div> 

                                                                                        <div className={`form-group ${this.state.clientTeam.deliveryType !== "" ? "" : "d-none"}`}>
                                                                                            <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">{this.translate('ATTENDANCE_ROOM_DELIVERY_DATE')}</label>
                                                                                            <input type="datetime-local" onChange={e=>this.updateClientTeamField(e, "deliveryDate")} min={this.getClientTeamDeliveryMinDate()} value={this.state.clientTeam.deliveryDate} pattern="[0-9]/[0-9]{2}/[0-9]{4}T[0-9]{2}:[0-9]{2}" required className="form-control box-shadow dark-important" />
                                                                                        </div>
                                                                                        
                                                                                        <div className="form-group">
                                                                                            <button className="btn btn-info btn-shadow btn-cursor-pointer" style={{ width: '100%' }} disabled={this.state.clientTeam.allFulfilled ? "" : "disabled"} onClick={e=>this.handleClientTeamSendToShopper(e)}>
                                                                                                <i className="fa fa-paper-plane"></i> {this.translate('ATTENDANCE_ROOM_SEND_TO_SHOPPER')}
                                                                                            </button>
                                                                                        </div>
                                                                                    </form>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={`row tab-pane fade py-0 white box-shadow h-100 ${this.state.chatTabs.currentTab === "logTab" ? "active show" : ""}`} id="tab_toolbar_log" role="tabpanel" aria-labelledby="">
                                                                    <AttendanceLog toggleAccordionPannel={this.toggleAccordionPannel} translate={this.translate} idAttendance={this.state.tools.idAttendance} />
                                                                </div>
                                                                
                                                                <div className={`row tab-pane fade py-0 white box-shadow h-100 ${this.state.chatTabs.currentTab === "optionsTab" ? "active show" : ""}`} id="tab_toolbar_option" role="tabpanel" aria-labelledby="">
                                                                    <AttendanceOptions handleMessageSubmit={this.handleMessageSubmit} toggleAccordionPannel={this.toggleAccordionPannel} translate={this.translate} idAttendance={this.state.tools.idAttendance} />
                                                                </div>

                                                            </div>

                                                        </div>

                                                        
                                                    </div>
                                                ):(
                                                    <div id="attendance_row_messages_tools" className="col-12 col-md-5 p-0 text-center">
                                                        
                                                    </div> 
                                                )}
                                            </div>
                                        </div>
                                    </div>
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="attendance_room_media_modal" className="attendance_room_media_modal">
                    <span id="attendance_room_media_modal_close_button">&times;</span>
                    <img  alt="" className="attendance_room_media_modal_content" />
                </div>
                <Modal show={this.state.modalImageShow} onHide={this.handleCloseModalImage} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                    <div className="d-flex flex-column h-100 bg-blur_w2">
                        <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                            <h5 className="h5 m-0 text-center width100">
                            <i className="fa fa-image"></i> {this.translate('ATTENDANCE_ROOM_MODAL_SEND_IMAGE_OR_VIDEO')}</h5>
                            <a href="/" onClick={e=>this.handleCloseModalImage(e)} className="ml-auto">
                                <span className="text-lg l-h-1x">×</span>
                            </a>
                        </div>
                        <div className="row position10 padding-20px">
                        <form id="attendance_room_form_send_image" onSubmit={e=>this.handleFileSubmit(e)} className="col-5 mx-auto">
                            <div className="row form-group">
                                <label htmlFor="attendance_room_input_send_image">{this.translate('ATTENDANCE_ROOM_MODAL_CHOOSE_THE_FILE')}</label>
                                <input type="file" id="attendance_room_input_send_image" accept="image/*, video/*" name="file" className="form-control" required />
                            </div>
                            <div className="row form-group">
                                <button id="attendance_room_button_send_image" className="btn btn-default blue" type="submit">{this.translate('ATTENDANCE_ROOM_MODAL_SEND_THE_FILE')}</button>
                            </div>
                        </form>
                        </div>
                    </div>
                    </Modal.Body>
                </Modal>
               
                <Modal show={this.state.modalVideoShow} onHide={this.handleCloseModalVideo} dialogAs={'div'} size="lg" style={{ overflowX: "hidden", overflowY: "hidden"}} dialogClassName="modal-dialog modal-fullscreen white b-t">
                    <Modal.Body className="h-100 d-flex flex-column">
                        <div className="d-flex flex-column h-100 bg-blur_w2 ">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-video"></i> {this.translate('ATTENDANCE_ROOM_MODAL_RECORD_VIDEO')}</h5>
                                <a href="/" onClick={e=>this.handleCloseModalVideo(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="flex-grow-1 d-flex justify-content-center">
                                <video id="modal_video_player" className="" muted="muted"></video> 
                            </div>
                            <div id="modal_fullscreen_video_controls" className="col-12 d-flex justify-content-center text-center">
                                <span></span>
                                <a href="/" id="modal_toggle_video_link" onClick={e=>this.handleVideoClickIcon(e)}  type="button" style={{width: "30%", maxWidth: "100px"}} className=" btn btn-default bg-danger text-white d-flex justify-content-around">
                                    <i  className="fa fa-video align-self-center" />
                                    <span>{this.translate('ATTENDANCE_ROOM_RECORD')}</span>
                                </a>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.modalResponseShow} onHide={this.handleCloseModalResponse} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-bell"></i> {this.translate("MODAL_STATUS_STATUS")}</h5>
                                <a href="/" onClick={e=>this.handleCloseModalResponse(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                <span className="col-12 text-center py-2 lead">
                                    {this.state.msgResponseShow}
                                </span>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.isModalCallVisible} onHide={this.handleCloseModalCall} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-phone"></i> {this.translate("ATTENDANCE_ROOM_CALL")}</h5>
                                <a href="/" onClick={e=>this.handleCloseModalCall(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                {!this.state.isLeadInCall ? 
                                    <div className="col-12">
                                        <h3 className="py-2 text-center"> {this.translate("ATTENDANCE_ROOM_DO_YOU_WANT_TO_CALL_TO")} {this.state.lead_data.name}?</h3>
                                        <div className="row">
                                            <div className="col-4 mx-auto">
                                                <button className="btn btn-danger btn-cursor-pointer" style={{ width: '100%' }} onClick={e=>this.handleCloseModalCall(e)}>
                                                    <i className="fa fa-phone-square"></i> {this.translate("ATTENDANCE_ROOM_CANCEL_CALL")}
                                                </button>
                                            </div>
                                            <div className="col-4 mx-auto">
                                                <button className="btn btn-success btn-cursor-pointer" style={{ width: '100%' }} onClick={(e)=>{this.setState({isLeadInCall: true}); this.initCall(this.state.messages.userId, this.state.lead_data.phone, "direct")}}>
                                                    <i className="fa fa-phone-square"></i> {this.translate("ATTENDANCE_ROOM_START_CALL")}
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                :
                                    <div className="col-12">
                                        <h3 className="py-2 text-center">{this.translate("ATTENDANCE_ROOM_CALL_IN_PROGRESS")}</h3>
                                        <div className="row">
                                            <div className="col-4 mx-auto">
                                                <button className="btn btn-danger btn-cursor-pointer" style={{ width: '100%' }} onClick={e=>{this.disconnectCall(); this.handleCloseModalCall(); this.setState({isLeadInCall: false}) }}>
                                                    <i className="fa fa-phone-square"></i> {this.translate("ATTENDANCE_ROOM_END_CALL")}
                                                </button>
                                            </div>
                                           
                                        </div>
                                    </div>
                                }
                               
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.isConfirmModalVisible} onHide={this.handleCloseConfirmModal} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                <i className="fa fa-check"></i> {this.translate("MODAL_CONFIRM_TITLE")}</h5>
                                <a href="/" onClick={e=>this.handleCloseConfirmModal(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                <div className="col-12">
                                    <h3 className="py-2 text-center">{this.state.confirmModalMessage}</h3>
                                </div>
                                <div className="col-4 mx-auto">
                                    <button className="btn btn-danger btn-cursor-pointer" style={{ width: '100%' }} onClick={e=>this.handleCloseConfirmModal(e)}>
                                        {this.translate("MODAL_CONFIRM_NO")}
                                    </button>
                                </div>
                                <div className="col-4 mx-auto">
                                    <button className="btn btn-success btn-cursor-pointer" style={{ width: '100%' }} onClick={(e)=>this.confirmModalCallback()}>
                                        {this.translate("MODAL_CONFIRM_YES")}
                                    </button>

                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <AttendanceLeadMerge refreshAttendanceContacts={this.refreshAttendanceContacts} handleShowMessageModalResponse={this.handleShowMessageModalResponse} isModalLeadMergeVisible={this.state.isModalLeadMergeVisible} idAttendance={this.state.tools.idAttendance} lead_data={this.state.lead_data} handleCloseModalLeadMerge={this.handleCloseModalLeadMerge} translate={this.translate} />
            </Fragment>
        );
    }
}

export default withRouter(AttendanceRoom);