import React, { Component } from 'react';

import FunilMarketing from './FunilMarketing';
import FunilSales from './FunilSales';

export class FunilMKTSales extends Component {
    render() {
        return (
            <div className="row no-gutters position10">
                <FunilMarketing fields={this.props.fields} type={this.props.type}/>
                <FunilSales fields={this.props.fields} type={this.props.type}/>
            </div>
        );
    }
}

export default FunilMKTSales;