import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';

import MenuMain from '../../Structure/MenuMain';
import HeaderMain from '../../Structure/HeaderMain';
import FooterMain from '../../Structure/FooterMain';

class Config extends Component {

    constructor(props){
        super(props)
        this.state = {}
        document.title = 'Configurações'
    }

    render() {
        return (
            <React.Fragment>
                <MenuMain />
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    <HeaderMain title={document.title} onChange={this.handleChange}/>
                    <div className="content-main admin" id="content-main">
                        Configurações...
                    </div>
                    <FooterMain />
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(Config);