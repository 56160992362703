import React, { Component, Fragment } from 'react'

import api from '../../../../Services/Api'
import Status from '../../../../Templates/Status'

export default class ScheduleComponent extends Component {

    constructor(props) {
        super(props)

        this.stageNumber = 5
        this.translate = this.props.translate
        this.state = {
            isLoading: true,
            status: false,
            schedulingTimes: []
        }
    }

    componentDidMount() {
        this.loadScheduleInformation()
    }

    /* componentDidUpdate(prevProps) {
        if (prevProps.refundContactOptions !== this.props.refundContactOptions && this.props.currentStage === this.stageNumber) {
            this.loadScheduleInformation()
        }
    } */

    loadScheduleInformation = async () => {
        this.setState({ isLoading: true })
        let response = await this.getSchedulingTimes()
        if (response && response.success) {
            let schedulingTimes = this.formatScheduleTimes(response.scheduling_times)
            this.setState({
                isLoading: false,
                status: false,
                schedulingTimes
            })
        } else {
            let message = (response && response.message) ? response.message : "Não foi possível consultar os Horários de Agendamento"
            this.setState({
                isLoading: false,
                status: {
                    success: false,
                    message: message
                }
            })
        }
    }

    formatScheduleTimes = (schedulingTimes) => {
        if (!schedulingTimes) return []

        schedulingTimes = schedulingTimes.sort((a, b) => b.date - a.date)

        return schedulingTimes.map(time => {
            let formatted = new Date(time)
            formatted = formatted.toLocaleString('pt', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                hour: "2-digit",
                minute: "2-digit"
            })
            return { original: time, formatted: formatted, selected: false }
        })
    }

    getSchedulingTimes = async () => {
        let response = false
        try {
            //let response = await api.get("/order/scheduling_times")
            response = this.getSchedulingTimesMockup()
            if (response && response.data.message) {
                response = response.data
            }
        } catch (error) {
            console.log(error)
        }
        return response
    }

    getSchedulingTimesMockup = () => {
        return {
            data: {
                success: true,
                message: "Horários de agendamento consultados com sucesso!",
                scheduling_times: [
                    '2020-02-01T12:00:00Z',
                    '2020-02-02T14:00:00Z',
                    '2020-03-03T22:00:00Z',
                ]
            }
        }
    }

    selectSchedulingTime = (selectedTime) => {
        let newSchedulingTimes = [...this.state.schedulingTimes]
        newSchedulingTimes = newSchedulingTimes.map(time => {
            if (time.original === selectedTime.original) {
                time.selected = true
            } else {
                time.selected = false
            }
            return time
        })
        this.setState({ schedulingTimes: newSchedulingTimes })
    }

    checkSchedulingTime = () => {
        return this.state.schedulingTimes.filter((time => time.selected)).length
    }

    getSchedulingTime = () => {
        let times = this.state.schedulingTimes.filter((time => time.selected))
        return times.length > 0 ? times[0] : false
    }


    handleScheduleSubmit = () => {
        let time = this.getSchedulingTime()
        if (!time) return false
        this.props.checkStageIsCorrect(this.stageNumber)
        this.props.handleGoToNextStage(this.stageNumber + 1, {
            schedulingTime: time.original
        })
    }

    render = () =>
        this.props.currentStage === this.stageNumber &&

        <Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="box p-3">
                        <h3>Agendamento</h3>
                        <hr />
                        <Status status={this.state.status} />
                        <h5>Selecione o horário desejado para o agendamento</h5>
                        {this.state.isLoading ?
                            <p>
                                <i className="fa fa-spin fa-spinner"></i> Carregando
                            </p>
                            :
                            (this.state.schedulingTimes && this.state.schedulingTimes.length > 0) ?
                                <div className="row scheduler_row mt-3">
                                    {this.state.schedulingTimes.map((time, index) =>
                                        <div className="col-12 btn-cursor-pointer my-1" onClick={() => this.selectSchedulingTime(time)} key={`time_index_${index}`}>
                                            {time.selected ?
                                                <i className="fa fa-check-square pr-2"></i>
                                                :
                                                <i className="fa fa-square pr-2"></i>
                                            }
                                            {time.formatted}
                                        </div>
                                    )}
                                </div>
                                :
                                <p>
                                    Não há horários disponíveis para o agendamento.
                                </p>

                        }
                        <div className="row">
                            <div className="col-12 pt-3">
                                <button
                                    className="btn btn-cursor-pointer btn-fw blue btn-rounded btn-shadow"
                                    disabled={!this.checkSchedulingTime()}
                                    onClick={() => this.handleScheduleSubmit()}
                                >
                                    <i className="fa fa-calendar"></i>&nbsp;
                                    Agendar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
}
