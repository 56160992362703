import React, { Component } from 'react';

class SummaryLandingsPagesAndListAllModal extends Component {
    render() {
        return (
            <div className="col-md-3 col-sm-12">
                <div className="padding box box-shadow">
                    <div className="d-flex">
                        <span className="text-muted text-center w-100">Landing pages</span>
                    </div>
                    <div className="py-3 text-center text-lg text-warn lh" data-toggle="modal" data-target="#">
                        <a href="/" className="">
                            <i className="fa fa-laptop f48 opacity90"></i>
                            <br/>
                            <span className="f20 f700">
                                {this.props.fields.total_landings_pages}
                            </span>
                            <br/>
                        </a>
                    </div>
                    <React.Fragment>
                        {
                            this.props.fields.list_landings_pages.slice(0, 2).map((data, i) => 
                                <div key={i} className="py-2 b-b">
                                    <div className="float-right lh">
                                        <span className="text-md text-info f700 d-block">
                                            <a href={data.landing_page_url} target="_blank" rel="noopener noreferrer">
                                                <i className="fa fa-external-link"></i>
                                            </a>
                                        </span>
                                        <br/>
                                    </div>
                                    <span className="_600 d-block"> {data.landing_page_name}</span>
                                    <small className="text-info">
                                        <a href={data.landing_page_url} target="_blank" rel="noopener noreferrer">
                                            {data.landing_page_pretty_link}
                                        </a>
                                    </small>
                                </div>
                            )
                        }
                    </React.Fragment>
                    <div className="p-2">
                        <a href="/leads_conversion#tab_3" className="btn btn-sm btn-block white">Ver todas</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default SummaryLandingsPagesAndListAllModal;