import React, { Component } from 'react'

import ViewBuilder from "../ViewBuilder"

export default class GenericMetricComponent extends Component{
    constructor(props){
        super(props)
        this.state = {
            viewValues: null,
            viewName: null,
            viewCode: null
        }
        this.translate = this.props.translate
    }
    
    handleViewChangeClick = (e, view) => {
        e.preventDefault()
        this.setState({
            viewValues: view.view_values,
            viewName: view.view_name,
            viewCode: view.view_code
        })
        this.props.handleChangeMetricTitle(view.view_name)
    }

    selectFirstView = () => {
        let {views} = this.props
        if(!views || !views.length) return

        this.setState({
            viewValues: views[0].view_values,
            viewName: views[0].view_name,
            viewCode: views[0].view_code
        })
        this.props.handleChangeMetricTitle(views[0].view_name)
    }

    componentDidMount = () => {
        this.selectFirstView()
    }


    renderView = () => {
        let {viewCode, viewName, viewValues} = this.state
        return <ViewBuilder translate={this.props.translate} viewCode={viewCode} viewName={viewName} viewValues={viewValues} />

       
    }

    render = () => {
        let {views} = this.props
        return (
            views && views.length ?
                <React.Fragment>
                    <div className="row">
                        <div className="col-12 col-sm-4">
                            <h3>Views</h3>
                            <hr/>
                            <div className="list-group" style={{maxHeight: 300, overflowY: "auto"}}>
                                {views.map((view, index)=> 
                                    <a key={`${view.view_code}${index}`} href="/" onClick={e=>this.handleViewChangeClick(e, view)} className={`list-group-item ${this.state.viewCode === view.view_code ? "active" : ""}`}>{view.view_name}</a>
                                )}
                            </div>
                        </div>
                        <div className="col-12 col-sm-8">
                            {this.state.viewCode &&
                                <div className="row">
                                    {this.renderView()}
                                </div>
                            }
                        </div>
                    </div>
                </React.Fragment>
                
                :
                    <p className="my-2">{this.translate("METRICS_MODAL_THERE_ARENT_VIEWS_REGISTERED_FOR_THIS_METRIC")}</p>
                
        )
    }
    
}
