import React, { Component } from 'react';
import Chart from 'chart.js';

import {app, hexToRGB} from '../../Structure/Configs/Theme';

export default class ShippingDeliveryTypeRanking extends Component {

    activeChart = null;

    loadChart() {
        const myChart = document.getElementById('shipping_delivery_type_ranking_chart').getContext('2d');

        const { list } = this.props.fields;
        let chartLabels = [];
        let chartData = [];
        if (list) for(const item of list) {
            chartLabels.push(item['delivery_type']);
            chartData.push(item['total']);
        }

        if (this.activeChart !== null) {
            this.activeChart.destroy();
        }

        this.activeChart = new Chart(myChart, {
            type: "horizontalBar",
            data: {
                labels: chartLabels,
                datasets: [
                    {
                        label: 'Pedidos',
                        // type: 'line',
                        data: chartData,
                        fill: true,
                        backgroundColor: hexToRGB(app.color.primary, 0.2),
                        borderColor: hexToRGB(app.color.primary, 1),
                        borderWidth: 2,
                        borderJoinStyle: 'miter',
                        pointBorderColor: hexToRGB(app.color.primary, 1),
                        pointBackgroundColor: '#fff',
                        pointBorderWidth: 2,
                        pointHoverRadius: 2,
                        pointHoverBackgroundColor: hexToRGB(app.color.primary, 1),
                        pointHoverBorderColor: '#fff',
                        pointHoverBorderWidth: 1,
                        pointRadius: 3
                    },
                    // {
                    //     label: 'Pedidos',
                    //     type: 'line',
                    //     data: chartData,
                    //     fill: false,
                    //     borderDash: [3, 3],
                    //     backgroundColor: hexToRGB(app.color.accent, 0.2),
                    //     borderColor: hexToRGB(app.color.accent, 1),
                    //     borderWidth: 2,
                    //     borderJoinStyle: 'miter',
                    //     pointBorderColor: '#fff',
                    //     pointBackgroundColor: hexToRGB(app.color.accent, 1),
                    //     pointBorderWidth: 2,
                    //     pointHoverRadius: 2,
                    //     pointHoverBackgroundColor: hexToRGB(app.color.accent, 1),
                    //     pointHoverBorderColor: '#fff',
                    //     pointHoverBorderWidth: 1,
                    //     pointRadius: 3
                    // }
                ]
            },
            options: {
                legend: {
                    display: false,
                }
            }
        })

        // const myChartSuspect = document.getElementById('chart-monthly-sales').getContext('2d');
        // new Chart(myChartSuspect, {
        //     type: "bar",
        //     data: {
        //         labels: this.props.fields.suspects.months,
        //         datasets: [{
        //                 label: 'Suspects',
        //                 type: 'line',
        //                 data: this.props.fields.suspects.values,
        //                 fill: false,
        //                 borderDash: [3, 3],
        //                 backgroundColor: hexToRGB(app.color.accent, 0.2),
        //                 borderColor: hexToRGB(app.color.accent, 1),
        //                 borderWidth: 2,
        //                 borderJoinStyle: 'miter',
        //                 pointBorderColor: '#fff',
        //                 pointBackgroundColor: hexToRGB(app.color.accent, 1),
        //                 pointBorderWidth: 2,
        //                 pointHoverRadius: 2,
        //                 pointHoverBackgroundColor: hexToRGB(app.color.accent, 1),
        //                 pointHoverBorderColor: '#fff',
        //                 pointHoverBorderWidth: 1,
        //                 pointRadius: 3
        //             }
        //         ]
        //     },
        //     options: {}
        // })
    }

    componentDidMount(){
        this.loadChart();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // if (this.myChart !== null) {
        //     this.myChart.update();
        // }
        this.loadChart();
    }

    render() {
        return (
            <div className="col-md-6 col-sm-12">
                <div className="padding bg-blur_w position10">
                    <div className="d-flex">
                        <p className="uppercase text-muted f10">Ranking por Tipo de Entrega</p>
                    </div>
                    <canvas id="shipping_delivery_type_ranking_chart" width={624} height={300} className="chartjs-render-monitor position10"/>
                </div>
            </div>
        )
    }
}