import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import $ from "jquery";

import "datatables.net-dt";
import "datatables.net-bs4";
import "datatables.net-responsive";
import "datatables.net-responsive-bs4";

import { ajaxUrl, ajaxToken } from "../../../Services/Ajax";

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableLoadUser: null,
    };
    this.translate = this.props.translate;
  }

  componentDidMount = async () => {
    let tableLoadUser = this.state.tableLoadUser;
    // console.log(this.props);
    //const props = this.props
    tableLoadUser = $("#list_user").DataTable({
      searching: true,
      paging: true,
      info: false,
      responsive: true,
      language: {
        url: this.translate("DATATABLE_LANGUAGE_URL"),
      },
      ajax: {
        url: ajaxUrl("/admin/user/list"),
        type: "GET",
        beforeSend: function (request) {
          request.setRequestHeader("Authorization", "Bearer " + ajaxToken());
          console.log(request);
        },
      },
      /* "columnDefs": [{
                "targets": -1,
                "data": null,
                "defaultContent":   
                    `<div class="btn-group btn-shadow">
                        <button class="view btn btn-sm success" title="${this.translate("ADMIN_USERS_LIST_ACCESS_BUTTON")}">
                            <i class="fa fa-user-circle" title="${this.translate("TABLE_ACTIONS_ACCESS_BUTTON")}"></i> ${this.translate("TABLE_ACTIONS_ACCESS_BUTTON")}
                        </button>
                    </div>`
            }] */
    });
    $("#list_user tbody").on("click", ".view", function (event) {
      event.preventDefault();
      let data = [];
      if ($(this).parents("tr").hasClass("child")) {
        data = tableLoadUser.row($(this).parents("tr").prev(".parent")).data();
      } else {
        data = tableLoadUser.row($(this).parents("tr")).data();
      }
      alert(data[3]);
      // console.log(data)
      //props.history.push('/admin_business#' + data[2])
    });
  };

  render = () => (
    <React.Fragment>
      <h5 className="fwhite">
        {this.translate("ADMIN_USERS_LIST_LIST_OF_USERS")}
      </h5>
      <div className="bootstrap-table">
        <div className="fixed-table-container">
          <div className="fixed-table-body min-height100">
            <table
              id="list_user"
              className="table table-striped table-bordered dt-responsive nowrap"
              style={{ width: "100%" }}
            >
              <thead>
                <tr className="text-left">
                  <th>{this.translate("ADMIN_USERS_USER_NAME")}</th>
                  <th>{this.translate("ADMIN_USERS_USER_EMAIL")}</th>
                  <th>{this.translate("ADMIN_USERS_USER_ACCESS_LEVEL")}</th>

                  {/* <th>{this.translate("TABLE_ACTIONS_ROW")}</th> */}
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withRouter(UserList);
