import React, { Component, Fragment } from 'react';

import $ from 'jquery';

import api from '../../../Services/Api';

class TestAB extends Component{

    constructor(props){
        super(props)
        this.state = {
            idAction: this.props.values.idAction,
            loaded: false,
            dependencies: false,
            alertResponse: null,
            listEmails: [],
            formValuesAction: {
                type: null,
                emails: [],
                email_definition_method: null,
                time: null,
                type_time: null
            }
        }
        this.loadDependencies = this.loadDependencies.bind(this)
        this.onChangeEmails = this.onChangeEmails.bind(this)
        this.formSubmitSave = this.formSubmitSave.bind(this)
        this.translate = this.props.translate
    }

    async loadDependencies(){
        const emails = await api.get('/email_marketing/all_to_select').then(async response => {
            let responseEmails = [];
            response.data.map(data => {
                responseEmails.push({
                    value: data.value,
                    name: data.name,
                    isChecked: false
                })
                return responseEmails
            })
            return responseEmails;
        })
        this.setState({listEmails: emails, dependencies: true})
        return
    }

    async componentDidMount(){
        await this.loadDependencies()
        await api.get('/journey/action/' + this.props.values.idAction).then(async response => {
            let emails = this.state.listEmails
            response.data.emails.map(data => {
                emails.forEach(email => {
                    if(email.value === data.value){
                        email.isChecked = true
                    }
                })
                return emails
            })
            this.setState({listEmails: emails, formValuesAction: response.data, loaded: true})
        })
    }

    onChangeEmails(e){
        let emails = this.state.listEmails
        emails.forEach(email => {
            if (email.value === e.target.value){
                email.isChecked = e.target.checked
            }
        })
        const formEmails = emails.filter(email => {
            return email.isChecked === true
        })
        this.setState({listEmails: emails, formValuesAction: {...this.state.formValuesAction, emails: formEmails}})
    }

    async formSubmitSave(e){
        e.preventDefault()
        $('#button_submit_form_action').prop('disabled', true).html(`<i className="fa fa-spinner"></i> ${this.translate('BUTTON_WAIT')}...`)
        this.setState({alertResponse: null})
        const data = this.state.formValuesAction
        try{
            const response = await api.post("/journey/action/" + this.props.values.idAction, data);
            if(response.data.success){
                this.setState({alertResponse: true})
                $('#button_submit_form_action').prop('disabled', false).html(`<i className="fa fa-save"></i> ${this.translate('BUTTON_REGISTER')}`)
            }else{
                this.setState({alertResponse: false})
            }
        }catch(err){
            console.log(err)
        }
    }

    render() {
        return (
            <Fragment>
                {
                    (this.state.alertResponse === false)?(
                        <div className="alert alert-danger alert-dismissible" role="alert">
                            {this.translate('TEST_AB_ERROR_REGISTERING_ACTION')}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    ):(
                        <React.Fragment>
                            {
                                (this.state.alertResponse)?(
                                    <div className="alert alert-success alert-dismissible" role="alert">
                                        {this.translate('TEST_AB_SUCCESS_REGISTERING_ACTION')}
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                ):(false)
                            }
                        </React.Fragment>
                    )
                }
                {
                    (this.state.loaded && this.state.dependencies)?(
                        <form onSubmit={this.formSubmitSave}>
                            <div className="form-group">
                                <label htmlFor="action_teste_a_b_type">{this.translate('TEST_AB_WHICH_CONTENT_TYPE_WILL_BE_TESTED')}</label>
                                <select onChange={e => this.setState({formValuesAction: {...this.state.formValuesAction, type:  e.target.value}})} defaultValue={this.state.formValuesAction.type} className="form-control cool-select" id="action_teste_a_b_type" required>
                                    <option value="">{this.translate('TEST_AB_OPTION_SELECT_THE_TYPE')}</option>
                                    <option value="email_marketing">{this.translate('TEST_AB_OPTION_EMAIL_MARKETING')}</option>
                                </select>
                            </div>
                            {
                                (this.state.formValuesAction.type === 'email_marketing')?(
                                    <React.Fragment>
                                        {
                                            this.state.listEmails.map((data, i) => (
                                                <div key={i} className="form-group">
                                                    <label htmlFor={"action_teste_a_b_emails" + data.value}>
                                                        <input type="checkbox" onChange={this.onChangeEmails} id={"action_teste_a_b_emails" + data.value} defaultValue={data.value} className="mr-1" checked={data.isChecked}/>
                                                        {data.name}
                                                    </label>
                                                </div>
                                            ))
                                        }
                                        <div className="form-group">
                                            <label htmlFor="action_teste_a_b_email_definition_method">{this.translate('TEST_AB_WHICH_CONTENT_METHOD_WILL_BE_TESTED')}</label>
                                            <select onChange={e => this.setState({formValuesAction: {...this.state.formValuesAction, email_definition_method:  e.target.value}})} defaultValue={this.state.formValuesAction.email_definition_method} className="form-control cool-select" id="action_teste_a_b_email_definition_method" required>
                                                <option value="">{this.translate('TEST_AB_OPTION_SELECT_THE_METHOD')}</option>
                                                <option value="opening">{this.translate('TEST_AB_OPTION_QUANTITY_OF_OPENING')}</option>
                                                <option value="clicks">{this.translate('TEST_AB_OPTION_QUANTITY_OF_CLICKS')}</option>
                                            </select>
                                        </div>
                                    </React.Fragment>
                                ):(false)
                            }
                            <div className="form-group">
                                <label htmlFor="action_teste_a_b_time">{this.translate('TEST_AB_LABEL_FOR_HOW_MUCH_TIME')}</label>
                                <input type="text" onChange={e => this.setState({formValuesAction: {...this.state.formValuesAction, time:  e.target.value}})} id="action_teste_a_b_time" className="form-control" placeholder={this.translate('TEST_AB_PLACEHOLDER_FOR_HOW_MUCH_TIME')} defaultValue={this.state.formValuesAction.time} required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="action_teste_a_b_type_time">{this.translate('TEST_AB_LABEL_TEST_PERIOD')}</label>
                                <select onChange={e => this.setState({formValuesAction: {...this.state.formValuesAction, type_time:  e.target.value}})} defaultValue={this.state.formValuesAction.type_time} className="form-control cool-select" id="action_teste_a_b_type_time" required>
                                    <option value="">{this.translate('TEST_AB_OPTION_SELECT_THE_TIME_MEASUREMENT')}</option>
                                    <option value="seconds">{this.translate('TEST_AB_OPTION_SECONDS')}</option>
                                    <option value="minutes">{this.translate('TEST_AB_OPTION_MINUTES')}</option>
                                    <option value="hours">{this.translate('TEST_AB_OPTION_HOURS')}</option>
                                    <option value="days">{this.translate('TEST_AB_OPTION_DAYS')}</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <button type="submit" id="button_submit_form_action" className="btn btn-cursor-pointer btn-success float-right" style={{width: '100%'}}>
                                    <i className="fa fa-save"></i> {this.translate('BUTTON_REGISTER')}
                                </button>
                            </div>
                        </form>
                    ):(
                        <div>
                            {this.translate('TEST_AB_LOADING_CONFIG_FORM')}
                        </div>
                    )
                }
            </Fragment>
        );
    }
}

export default TestAB;