import React, { Component } from 'react';

class SummaryBusinessDigitalPresenceTitle extends Component {
    render(){
        return (
            <div className="col-sm-12 col-md-12 col-lg-12 mt20">
                <button className="btn btn-fw dark text-center w-100 box mt20 mb10">Presença digital:</button>
            </div>
        );
    }
}

export default SummaryBusinessDigitalPresenceTitle;