import React, { Component } from 'react'

export default class ViewNotFound extends Component{

    render = () =>
        <div className="col-12">
            <p>{this.props.translate("DASHBOARD_PERFORMANCE_CARD_VIEW_NOT_FOUND")}</p>
        </div>
    
}


