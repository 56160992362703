import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'

import MenuMain from '../../../Structure/MenuMain'
import HeaderMain from '../../../Structure/HeaderMain'
import FooterMain from '../../../Structure/FooterMain'
import OrderComponent from './Components/OrderComponent'
import ItemsComponent from './Components/ItemsComponent'
import RefundComponent from './Components/RefundComponent'
import ContactComponent from './Components/ContactComponent'
import ScheduleComponent from './Components/ScheduleComponent'
import StatusComponent from './Components/StatusComponent'

class Reverse extends Component {

    constructor(props) {
        super(props)

        this.translate = this.props.translate
        this.state = {
            currentStage: 1,
            orderId: false,
            selectedItems: [],
            refundGeneralOptions: {},
            refundContactOptions: {},
            schedulingTime: false,
            reverseStatus: false,
            isLoadingReverse: false
        }
    }

    changeCurrentStage = (e, stageNumber) => {
        if (e) e.preventDefault()
        if (this.state.currentStage > 5) return
        this.setState({ currentStage: stageNumber })
    }

    checkStageIsCorrect = (stageNumber) => {
        if (stageNumber !== this.state.currentStage) this.changeCurrentStage(null, stageNumber)
    }

    handleGoToNextStage = (nextStageNumber, data) => {
        switch (nextStageNumber) {
            case 2:
                this.setState({ orderId: data.orderId, currentStage: nextStageNumber })
                break
            case 3:
                this.setState({ selectedItems: [...data.selectedItems], currentStage: nextStageNumber })
                break
            case 4:
                this.setState({ refundGeneralOptions: { ...data.refundGeneralOptions }, currentStage: nextStageNumber })
                break
            case 5:
                this.setState({ refundContactOptions: { ...data.refundContactOptions }, currentStage: nextStageNumber })
                break
            case 6:
                this.setState({ schedulingTime: data.schedulingTime, currentStage: nextStageNumber }, () => {
                    this.handlePostReverse()
                })
                break
        }
    }

    handlePostReverse = () => {
        let status
        this.setState({ isLoadingReverse: true })
        let data = {
            orderId: this.state.orderId,
            selected_items: this.state.selectedItems,
            refund_general_options: this.state.refundGeneralOptions,
            refund_Contact_options: this.state.refundContactOptions,
            scheduling_time: this.state.schedulingTime
        }
        console.log(JSON.stringify(data))
        try {
            /* let response = await api.post("/order/reverse",{ 
                orderId: this.state.orderId,
                selected_items: this.state.selectedItems,
                refund_general_options: this.state.refundGeneralOptions,
                refund_Contact_options: this.state.refundContactOptions,
                scheduling_time: this.state.schedulingTime 
            })
            */
            let response = this.getReverseMockup()
            if (response && response.data.message) {
                status = response.data
            } else {
                status = {
                    success: false,
                    message: "Não foi possível agendar Reverso"
                }
            }
            this.setState({ reverseStatus: status, isLoadingReverse: false })
        } catch (error) {
            console.log(error)
        }
    }

    getReverseMockup = () => {
        return {
            data: {
                success: true,
                message: "Aguardando a confirmação do Emksa"
            }
        }
    }

    render() {
        return (
            <Fragment>
                <MenuMain />
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    <HeaderMain title={'Reversa'} />
                    <div className="content-main" id="content-main">
                        <div className="padding">
                            <div className="row pt20">
                                <div className="col-12">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">Encontrar Compra</li>
                                            {this.state.currentStage > 1 &&
                                                <li className="breadcrumb-item"><a href="/#" onClick={e => this.changeCurrentStage(e, 2)}>Itens da Compra</a></li>
                                            }
                                            {this.state.currentStage > 2 &&
                                                <li className="breadcrumb-item"><a href="/#" onClick={e => this.changeCurrentStage(e, 3)}>Reembolso</a></li>
                                            }
                                            {this.state.currentStage > 3 &&
                                                <li className="breadcrumb-item"><a href="/#" onClick={e => this.changeCurrentStage(e, 4)}>Contato</a></li>
                                            }
                                            {this.state.currentStage > 4 &&
                                                <li className="breadcrumb-item"><a href="/#" onClick={e => this.changeCurrentStage(e, 5)}>Agendamento</a></li>
                                            }
                                            {this.state.currentStage > 5 &&
                                                <li className="breadcrumb-item"><a href="/#">Status</a></li>
                                            }
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <OrderComponent
                                translate={this.translate}
                                checkStageIsCorrect={this.checkStageIsCorrect}
                                handleGoToNextStage={this.handleGoToNextStage}
                                currentStage={this.state.currentStage}
                            />

                            <ItemsComponent
                                translate={this.translate}
                                checkStageIsCorrect={this.checkStageIsCorrect}
                                handleGoToNextStage={this.handleGoToNextStage}
                                currentStage={this.state.currentStage}
                                orderId={this.state.orderId}
                            />

                            <RefundComponent
                                translate={this.translate}
                                checkStageIsCorrect={this.checkStageIsCorrect}
                                handleGoToNextStage={this.handleGoToNextStage}
                                currentStage={this.state.currentStage}
                                selectedItems={this.state.selectedItems}
                            />

                            <ContactComponent
                                translate={this.translate}
                                checkStageIsCorrect={this.checkStageIsCorrect}
                                handleGoToNextStage={this.handleGoToNextStage}
                                currentStage={this.state.currentStage}
                                orderId={this.state.orderId}
                                refundGeneralOptions={this.state.refundGeneralOptions}
                            />

                            <ScheduleComponent
                                translate={this.translate}
                                checkStageIsCorrect={this.checkStageIsCorrect}
                                handleGoToNextStage={this.handleGoToNextStage}
                                currentStage={this.state.currentStage}
                                refundContactOptions={this.state.refundContactOptions}
                            />

                            <StatusComponent
                                translate={this.translate}
                                currentStage={this.state.currentStage}
                                reverseStatus={this.state.reverseStatus}
                                isLoadingReverse={this.state.isLoadingReverse}
                            />
                        </div>
                    </div>
                    <FooterMain />
                </div>
            </Fragment>
        )
    }
}

export default withRouter(Reverse)