import React, { Component } from 'react';

export default class ShippingStatusAwaitingScripting extends Component {

    render = () =>
        <div className="col-6 col-md-6 col-lg-4">
            <div className="box p-3 box-shadow2">
                <div className="d-flex">
                    <span className="text-muted">{this.props.translate('DASHBOARD_ATTENDANCES_CARD_SHIPPING_STATUS_AWAITING_SCRIPTING_TOTAL')}</span>
                </div>
                <div className="py-3 text-center text-lg text-warning lh">
                    <a href="/" onClick={(e) => { e.preventDefault(); this.props.handleCardActionClick && this.props.handleCardActionClick({type: this.props.type, title: this.props.translate('DASHBOARD_ATTENDANCES_CARD_SHIPPING_STATUS_AWAITING_SCRIPTING_TOTAL')}) }}>
                        <i className="fa fa-clock-o f48"></i>
                        <br />
                        <span className="f16">{this.props.fields.count_total}</span>
                        <br />
                    </a>
                </div>
            </div>
            {/*<div className="box-tool">*/}
            {/*    <ul className="nav nav-xs mr-1">*/}
            {/*        <li className="nav-item dropdown">*/}
            {/*            <a href="/" className="nav-link text-muted" onClick={(e) => { e.preventDefault(); this.props.handleCardActionClick({type: this.props.type, title: this.props.translate('DASHBOARD_ATTENDANCES_CARD_SHIPPING_STATUS_AWAITING_SCRIPTING_TOTAL')}) }}>*/}
            {/*                <i className="fa fa-info"></i>*/}
            {/*            </a>*/}

            {/*        </li>*/}
            {/*    </ul>*/}
            {/*</div>*/}
        </div>
}
