import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';

import api from '../../../Services/Api';

class SuspectModalData extends Component{

    constructor(props){
        super(props)
        this.translate = this.props.translate

        this.state = {
            id: this.props.idSuspect,
            suspect: {
                last_conversion: this.translate('SUSPECT_MODAL_DATA_LOADING'),
                first_action: this.translate('SUSPECT_MODAL_DATA_LOADING'),
                segmentation: this.translate('SUSPECT_MODAL_DATA_LOADING'),
                landing_page: this.translate('SUSPECT_MODAL_DATA_LOADING'),
                browser: this.translate('SUSPECT_MODAL_DATA_LOADING'),
                device: this.translate('SUSPECT_MODAL_DATA_LOADING'),
                cookies: [],
                ips: [],
                access: [],
                last_smartlook_shared_url: null
            }
        }
        this.closeModal = this.closeModal.bind(this)
    }

    async loadSuspect(){
        api.get('/suspects/' + this.state.id).then(async response => {
            
            this.setState({
                suspect: response.data
            })
            
        })
        console.log(this.state.suspect)
    }

    closeModal(){
        this.props.onChange(false)
    }

    componentDidMount(){
        this.loadSuspect()
    }

    render() {
        return (
            <div className="d-flex flex-column h-100 bg-blur_w2">
                <div className="p-3 b-b d-flex no-shrink accent text-center position10">
                    <h5 className="h5 m-0 text-center width100"><i className="fa fa-clone"></i> {this.translate('SUSPECT_MODAL_VISUALIZE_SUSPECT')}</h5>
                    <span data-dismiss="modal" className="ml-auto" onClick={this.closeModal}>
                        <span className="text-lg l-h-1x">×</span>
                    </span>
                </div>
                <div className="row position10 p0">
                    
                    <div className="col-sm-12 col-md-12 col-lg-12 p0">
                        <div className="item">
                            <div className="item-bg">
                                <img src="/assets/app/img/dash_clients_bg04.png" alt="." className="blur" />
                            </div>
                            <div className="p-2">
                                <div className="row">
                                    <div className="col-sm-7">
                                        <div className="media">
                                            <div className="media-body text-left">
                                                <h4 className="fwhite">
                                                    {this.translate('SUSPECT_MODAL_SUSPECT')}
                                                </h4>
                                                <p className="m0 p0 fwhite opacity50">
                                                    <i className="fa fa-map-marker mr-2"></i> {this.translate('SUSPECT_MODAL_UNKNOWN')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-sm-12 col-md-6 col-lg-6">
                                                                                        
                        <div className="box box-shadow mt-4">
                            <div className="box-header blue">
                                <h2 className="fwhite">{this.translate('SUSPECT_MODAL_SUSPECT_INFORMATIONS')}:</h2>
                            </div>
                            <div className="box-divider"></div>
                            <ul className="list no-border">

                                <li className="list-item text-left">
                                    <div className="list-body">
                                        <div className="f16 fwhite">
                                            <i className="fa fa-calendar-check-o"></i> {this.translate('SUSPECT_MODAL_LAST_CONVERSION')}:
                                        </div>
                                    </div>
                                    <div className="list-body text-right">
                                        <div className="f16 text-info">
                                            {this.state.suspect.last_conversion}
                                        </div>
                                    </div>
                                </li>
                                <div className="box-divider"></div>
                                <li className="list-item text-left">
                                    <div className="list-body">
                                        <div className="f16 fwhite">
                                            <i className="fa fa-check"></i> {this.translate('SUSPECT_MODAL_FIRST_ACTION')}:
                                        </div>
                                    </div>
                                    <div className="list-body text-right">
                                        <div className="f16 text-info">
                                            {this.state.suspect.first_action}
                                        </div>
                                    </div>
                                </li>
                                <div className="box-divider"></div>
                                <li className="list-item text-left">
                                    <div className="list-body">
                                        <div className="f16 fwhite">
                                            <i className="fa fa-users"></i> {this.translate('SUSPECT_MODAL_SEGMENTATION')}:
                                        </div>
                                    </div>
                                    <div className="list-body text-right">
                                        <div className="f16 text-info">
                                            {this.state.suspect.segmentation}
                                        </div>
                                    </div>
                                </li>
                                <div className="box-divider"></div>
                                <li className="list-item text-left">
                                    <div className="list-body">
                                        <div className="f16 fwhite">
                                            <i className="fa fa-list-alt"></i> {this.translate('SUSPECT_MODAL_WEBSITE_OR_LANDING_PAGE')}: 
                                        </div>
                                    </div>
                                    <div className="list-body text-right">
                                        <div className="f16 text-info">
                                            {this.state.suspect.landing_page}
                                        </div>
                                    </div>
                                </li>
                                <div className="box-divider"></div>
                                <li className="list-item text-left">
                                    <div className="list-body">
                                        <div className="f16 fwhite">
                                            <i className="fa fa-laptop"></i> {this.translate('SUSPECT_MODAL_BROWSER')}: 
                                        </div>
                                    </div>
                                    <div className="list-body text-right">
                                        <div className="f16 text-info">
                                            {this.state.suspect.browser}
                                        </div>
                                    </div>
                                </li>
                                <div className="box-divider"></div>
                                <li className="list-item text-left">
                                    <div className="list-body">
                                        <div className="f16 fwhite">
                                            <i className="fa fa-window-restore"></i> {this.translate('SUSPECT_MODAL_DEVICE')}: 
                                        </div>
                                    </div>
                                    <div className="list-body text-right">
                                        <div className="f16 text-info">
                                            {this.state.suspect.device}
                                        </div>
                                    </div>
                                </li>
                                <div className="box-divider"></div>
                                {
                                    (this.state.suspect.last_smartlook_shared_url && this.state.suspect.last_smartlook_shared_url !== null)?(
                                        <React.Fragment>
                                            <div className="box-divider"></div>
                                            <li className="list-item">
                                                <div className="list-body">
                                                    <div className="f16 fwhite">
                                                        <i className="fa fa-eye"></i> {this.translate('SUSPECT_MODAL_LAST_NAVIGATION')}
                                                    </div>
                                                </div>
                                                <div className="list-body text-right">
                                                    <div className="f16 fwhite">
                                                        <a className="btn btn-rounded red" href={this.state.suspect.last_smartlook_shared_url} target="_blank" rel="noopener noreferrer">
                                                            <i className="fa fa-play"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </li>
                                        </React.Fragment>
                                    ):(false)
                                }
                            </ul>
                        </div>
                        
                        <div className="box box-shadow mt-4">
                            <div className="box-header red">
                                <h2 className="fwhite">{this.translate('SUSPECT_MODAL_COOKIES')}:</h2>
                            </div>
                            <div className="box-divider"></div>
                            <ul className="list no-border">
                                {
                                    this.state.suspect.cookies.map((data, i) => (
                                        <li key={i} className="list-item text-left">
                                            <div className="list-body">
                                                <div className="f12 fwhite">
                                                    <i className="fa fa-code"></i> {data.date}
                                                </div>
                                            </div>
                                            <div className="list-body text-right">
                                                <div className="f12 fwhite opacity75">
                                                    {data.cookie}
                                                </div>
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        
                        <div className="box box-shadow mt-4">
                            <div className="box-header teal">
                                <h2 className="fwhite">{this.translate('SUSPECT_MODAL_IPS')}:</h2>
                            </div>
                            <div className="box-divider"></div>
                            <ul className="list no-border">
                                {
                                    this.state.suspect.ips.map((data, i) => (
                                        <li key={i} className="list-item text-left">
                                            <div className="list-body">
                                                <div className="f12 fwhite">
                                                    <i className="fa fa-signal"></i> {data.date}
                                                </div>
                                            </div>
                                            <div className="list-body text-right">
                                                <div className="f12 fwhite opacity75">
                                                    {data.ip}
                                                </div>
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>

                    </div>
                    
                    <div className="col-sm-12 col-md-6 col-lg-6 text-left">
                        <div className="box box-shadow mt-4">
                            <div className="box-header orange">
                                <h2 className="fwhite">{this.translate('SUSPECT_MODAL_SUSPECT_ACCESSES')}:</h2>
                            </div>
                            <div className="box-divider"></div>
                            <ul className="list no-border">
                                <div className="streamline fwhite p-4">
                                    {
                                        this.state.suspect.access.map((data, i) => (
                                            <div key={i} className="sl-item b-primary">
                                                <div className="sl-content">
                                                    <div className="sl-date text-muted">{data.date}</div>
                                                    <h6>{data.title}</h6>
                                                    {
                                                        (typeof data['smartlook_shared_url'] !== 'undefined' && data.smartlook_shared_url)?(
                                                            <p>
                                                                <a className="btn btn-rounded red" href={data.smartlook_shared_url} target="_blank" rel="noopener noreferrer">
                                                                    <i className="fa fa-play"></i> {this.translate('SUSPECT_MODAL_VISUALIZE_NAVIGATION')}
                                                                </a>
                                                            </p>
                                                        ):(false)
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default withRouter(SuspectModalData);