import $ from "jquery"
import React, { Component, Fragment } from 'react';
import api from "../../../Services/Api";

export default class AttendanceContacts extends Component {
    constructor(props){
        super(props)
        this.state = {
            userStatus: null,
            isuserStatusModalVisible: false,
            userStatusModalLoadedOnce: false,
            contacts: [],
            allContact: {},
            currentContactType: "newAttendanceContacts",
            showOptionInAttendanceContacts: false,
            optionInAttendanceContacts: "all",
            contactsMessagesCount: {}
        }
        this.translate = this.props.translate
    }

    updateContactType = () => {
        switch(this.state.currentContactType){
            case "newAttendanceContacts": 
                this.setState({contacts: this.props.contacts.newAttendanceContacts, allContact: this.props.contacts})
                break
            case "inAttendanceContacts": 
                if(this.state.optionInAttendanceContacts === 'all'){
                    this.setState({contacts: this.props.contacts.inAttendanceContacts, allContact: this.props.contacts})
                }else{
                    this.setState({contacts: this.props.contacts.attendanceOtherFilters.contacts[this.state.optionInAttendanceContacts], allContact: this.props.contacts})
                }
                break
            case "inShopperContacts": 
                this.setState({contacts: this.props.contacts.inShopperContacts, allContact: this.props.contacts})
                break
            case "inScreenwriterContacts": 
                this.setState({contacts: this.props.contacts.inScreenwriterContacts, allContact: this.props.contacts})
                break
            case "inDeliveryContacts": 
                this.setState({contacts: this.props.contacts.inDeliveryContacts, allContact: this.props.contacts})
                break
            case "finishedContacts":
                this.setState({contacts: this.props.contacts.finishedContacts, allContact: this.props.contacts})
                break
            case "archivedContacts":
                this.setState({contacts: this.props.contacts.archivedContacts, allContact: this.props.contacts})
                break
            default:
                break;
        }
    }

    sliceAccordingToBreakPoints = (string) => {
        let xl = 1410
        let md = 1117
        let addAfterString = false
        if(document.body.clientWidth >= xl){
            if(string.length >= 40) addAfterString = true
            string = string.slice(0, 40)
        } else if(document.body.clientWidth >= md) {
            if(string.length >= 20) addAfterString = true
            string = string.slice(0, 20)
        } else {
            if(string.length >= 15) addAfterString = true
            string = string.slice(0, 15)
        }
        string = `${string}${addAfterString ? "..." : ""}`
        return string
    }

    blinkContatCallBackgroundColor() {
        var $callBackground = $('.call_background')
        $callBackground.addClass('active_call_background')

        setTimeout(function () {
            $callBackground.removeClass('active_call_background')
        }, 750)

    }
    
    animateContatCallIcon() {
        var $rotateIcon = $('.call_phone')
        var $inCallPhonerotateIcon = $('.in_call_phone')
        var $blinkingTab = $('.blinking_tab')

        $rotateIcon.addClass('active_call_phone')
        $inCallPhonerotateIcon.addClass('active_in_call_phone')
        $blinkingTab.addClass('active_blinking_tab')
        setTimeout(function () {
            $rotateIcon.removeClass('active_call_phone')
            $inCallPhonerotateIcon.removeClass('active_in_call_phone')
            $blinkingTab.removeClass('active_blinking_tab')
        }, 750)
    }



    loadUserStatus = async () => {
        try {
            let response = await api.get("/attendance/load/user/status")
            if(response && response.data && response.data.success){
                this.setState({userStatus: response.data.status})
            }
        } catch (error) {
            console.log(error)
        } 
    }

    handleStatusChange = async (statusName) => {
        if(statusName !== "online" && statusName !== "busy" && statusName !== "offline") return
        try {
            let response = await api.post("/attendance/update/user/status", {status: statusName})
            if(response && response.data && response.data.success){
                this.setState({userStatus: statusName, isuserStatusModalVisible: false})
            }
        } catch (error) {
            console.log(error)
        }
        
    }
    
    getStatusColor = () => {
        let {userStatus} = this.state
        let color = ""
        switch(userStatus){
            case "online":
                color = "text-success"
                break
            case "busy":
                color = "text-warning"
                break
            default:
                color = "text-danger"
                break
        } 
        return color  
    }

    checkIsBlinking = (tabName) => {
        if(this.props.notificationTabs && this.props.notificationTabs.length){
            if(this.props.notificationTabs.filter(tab=>tab === tabName).length){
                if(tabName === this.state.currentContactType) {
                    this.props.clearNotificationTab(tabName)
                    return ""
                } else return "blinking_tab"
                
            } else {
                return ""
            }
        } else {
            return ""
        }
    }

    componentDidMount(){
        this.updateContactType()
        window.onload = () => {
            setInterval(()=>{
                this.blinkContatCallBackgroundColor()
                this.animateContatCallIcon()
            }, 2000)
        }
        this.loadUserStatus()

    }

    componentDidUpdate(prevProps, prevState){
        if((prevProps.contacts !== this.props.contacts) || (prevState.currentContactType !== this.state.currentContactType)) {
            this.updateContactType()
        }

        if(prevState.isuserStatusModalVisible !== this.state.isuserStatusModalVisible && this.state.isuserStatusModalVisible){
            
            $(document).click(() => {
                this.setState({isuserStatusModalVisible: false})
            });
            
            $("#attendance_contacts_status_list").click((e) => {
                e.stopPropagation();
                let statusName = e.target.dataset.status
                if(!statusName) statusName = $(e.target).closest("button").data("status")

                this.handleStatusChange(statusName)
            });

        }

    } 

    render = () =>
        <div id="attendance_contacts" className="col-12 col-md-3 d-flex flex-column flex-grow-1" style={{backgroundColor: "#243140", height: this.props.getAttendanceRoomHeight()}}>
            <div className="row">
                <div className="col">
                    <ul className="row nav nav-pills nav-sm" style={{backgroundColor: "#1e2835"}}>
                        
                        <li className="nav-item" title={this.translate('ATTENDANCE_ROOM_TAB_NEW_ATTENDANCES')}>
                            <button className={`btn py-2 rounded-0 dark nav-link ${this.state.currentContactType === "newAttendanceContacts" ? "active" : ""} ${this.checkIsBlinking("newAttendanceContacts")}`}  onClick={()=>this.setState({currentContactType: "newAttendanceContacts", showOptionInAttendanceContacts: false}, ()=>this.props.clearNotificationTab("newAttendanceContacts"))}>
                                <i className="fa fa-user-plus"></i>
                            </button>
                        </li>

                        <li className="nav-item" title={this.translate('ATTENDANCE_ROOM_TAB_ATTENDANCES')}>
                            {
                                (
                                    this.state.allContact.attendanceOtherFilters && 
                                    this.state.allContact.attendanceOtherFilters.status && 
                                    Array.isArray(this.state.allContact.attendanceOtherFilters.status) && 
                                    this.state.allContact.attendanceOtherFilters.status.length > 0
                                ) ? (
                                    <button className={`btn py-2 rounded-0 dark nav-link ${this.state.currentContactType === "inAttendanceContacts" ? "active" : ""} ${this.checkIsBlinking("inAttendanceContacts")}`}  onClick={()=>this.setState({currentContactType: "inAttendanceContacts", showOptionInAttendanceContacts: !this.state.showOptionInAttendanceContacts}, () => this.props.clearNotificationTab("inAttendanceContacts"))}>
                                        <i className="fa fa-users"></i>
                                    </button>
                                ): (
                                    <button className={`btn py-2 rounded-0 dark nav-link ${this.state.currentContactType === "inAttendanceContacts" ? "active" : ""} ${this.checkIsBlinking("inAttendanceContacts")}`}  onClick={()=>this.setState({currentContactType: "inAttendanceContacts"}, ()=>this.props.clearNotificationTab("inAttendanceContacts"))}>
                                        <i className="fa fa-users"></i>
                                    </button>
                                )
                            }
                        </li>

                        <li className="nav-item" title={this.translate('ATTENDANCE_ROOM_TAB_SHOPPER')}>
                            <button className={`btn py-2 rounded-0 dark nav-link ${this.state.currentContactType === "inShopperContacts" ? "active" : ""} ${this.checkIsBlinking("inShopperContacts")}`}  onClick={()=>this.setState({currentContactType: "inShopperContacts", showOptionInAttendanceContacts: false}, ()=>this.props.clearNotificationTab("inShopperContacts"))}>
                                <i className="fa fa-shopping-basket"></i>
                            </button>
                        </li>

                        <li className="nav-item" title={this.translate('ATTENDANCE_ROOM_TAB_SCREENWRITER')}>
                            <button className={`btn py-2 rounded-0 dark nav-link ${this.state.currentContactType === "inScreenwriterContacts" ? "active" : ""} ${this.checkIsBlinking("inScreenwriterContacts")}`}  onClick={()=>this.setState({currentContactType: "inScreenwriterContacts", showOptionInAttendanceContacts: false}, ()=>this.props.clearNotificationTab("inScreenwriterContacts"))}>
                                <i className="fa fa-sitemap"></i>
                            </button>
                        </li>

                        <li className="nav-item" title={this.translate('ATTENDANCE_ROOM_TAB_DELIVERYMAN')}>
                            <button className={`btn py-2 rounded-0 dark nav-link ${this.state.currentContactType === "inDeliveryContacts" ? "active" : ""} ${this.checkIsBlinking("inDeliveryContacts")}`}  onClick={()=>this.setState({currentContactType: "inDeliveryContacts", showOptionInAttendanceContacts: false}, ()=>this.props.clearNotificationTab("inDeliveryContacts"))}>
                                <i className="fa fa-paper-plane"></i>
                            </button>
                        </li>

                        <li className="nav-item" title={this.translate('ATTENDANCE_ROOM_TAB_FINISHED')}>
                            <button className={`btn py-2 rounded-0 dark nav-link ${this.state.currentContactType === "finishedContacts" ? "active" : ""} ${this.checkIsBlinking("finishedContacts")}`} onClick={()=>this.setState({currentContactType: "finishedContacts", showOptionInAttendanceContacts: false}, ()=>this.props.clearNotificationTab("finishedContacts"))}>
                                <i className="fa fa-check-circle" ></i>
                            </button>
                        </li>
                        
                        {/*
                        <li className="nav-item" title={this.translate('ATTENDANCE_ROOM_TAB_ARCHIVED')}>
                            <button className={`btn py-2 rounded-0 dark nav-link ${this.state.currentContactType === "archivedContacts" ? "active" : ""} ${this.checkIsBlinking("archivedContacts")}`} onClick={()=>this.setState({currentContactType: "archivedContacts"}, ()=>this.props.clearNotificationTab("archivedContacts"))}>
                                <i className="fa fa-archive"></i>
                            </button>
                        </li>
                        */}
                        {this.state.userStatus !== null &&
                            <li className="nav-item ml-auto" title={"Escolher Status"}>
                                {this.state.isuserStatusModalVisible && (
                                    <ul className="list-group" id="attendance_contacts_status_list">
                                        {this.state.userStatus !== "online" &&
                                            <li className="">
                                                <button className={`btn py-2 rounded-0 btn-cursor-pointer`} data-status="online">
                                                    <i className="fa fa-circle text-success" ></i> Disponível
                                                </button>
                                            </li>
                                        }
                                        {this.state.userStatus !== "busy" &&
                                            <li className="">
                                                <button className={`btn py-2 rounded-0 btn-cursor-pointer`} data-status="busy">
                                                    <i className="fa fa-circle text-warning" ></i> Ocupado
                                                </button>
                                            </li>
                                        }
                                        {this.state.userStatus !== "offline" && 
                                            <li className="">
                                                <button className={`btn py-2 rounded-0 btn-cursor-pointer`} data-status="offline">
                                                    <i className="fa fa-circle text-danger" ></i> Não Disponível
                                                </button>
                                            </li>
                                        }
                                    </ul>
                                )}
                                <button id="attendance_contact_user_status_button" className={`btn py-2 rounded-0 btn-cursor-pointer nav-link dark ${this.getStatusColor()}`} onClick={()=>{this.setState({isuserStatusModalVisible: !this.state.isuserStatusModalVisible})}}>
                                    <i className="fa fa-circle"></i>
                                </button>
                            </li>
                        }
                    </ul>
                </div>
            </div>
            <div className='row dark' style={{paddingTop: '15px', display: (this.state.showOptionInAttendanceContacts) ? 'block' : 'none'}}>
                <div className="form-group">
                    <select className="form-control" onChange={e => this.setState({optionInAttendanceContacts: e.target.value}, () => this.props.clearNotificationTab("inAttendanceContacts"))} defaultValue={`all`}>
                        <option style={{color: "#000"}} value="all">Todos os Contatos</option>
                        {
                            (
                                this.state.allContact.attendanceOtherFilters && 
                                this.state.allContact.attendanceOtherFilters.status && 
                                Array.isArray(this.state.allContact.attendanceOtherFilters.status) && 
                                this.state.allContact.attendanceOtherFilters.status.length > 0
                            ) && (
                                <Fragment>
                                    {
                                        this.state.allContact.attendanceOtherFilters.status.map((data, index) => 
                                            <option  style={{color: "#000"}} key={index} value={data}>{data}</option>
                                        )
                                    }
                                </Fragment>
                            )
                        }
                    </select>
                </div>
            </div>
            <div className="row  d-flex flex-grow-1">
                <div className="col-12 tab-content  d-flex flex-grow-1 flex-column" id="attendance_tab_content" style={{backgroundColor: "#243140"}}>
                    <div className="row active tab-pane fade show white d-flex flex-grow-1 flex-column" id="tab_attendance_in_attendance" role="tabpanel" aria-labelledby="">
                        <div className="col-12 d-flex flex-column">
                            {this.state.contacts && this.state.contacts.length > 0 ? (
                                this.state.contacts.map((contact, icc) =>
                                    <div className={`row row_contact ${contact.call_status === "calling" ? "call_background" : ""} ${contact.call_status === "in_call" ? "in_call_background" : ""}`} onClick={e=>this.props.handleContactClick(e, contact.id)} key={`${contact.id}-${icc}`}>
                                        <div className="col-12 px-0 py-2 d-flex flex-row align-items-center">
                                            {/* <img alt="profile" height={40} className={`pl-1 rounded-circle fluid ${(document.body.clientWidth <= 1200 && document.body.clientWidth > 751 ) ? "d-none" : ""}`} src={contact.profile_image} /> */}
                                            <div className=" d-flex flex-column justify-content-center flex-grow-1">
                                                <div className="text-white text-md pl-1">
                                                    {contact.user_name || this.translate('ATTENDANCE_ROOM_CHAT_UNKNOWN_CLIENT')}
                                                    {contact.status && (
                                                        <React.Fragment>
                                                            {contact.status.pause && <React.Fragment>&nbsp;{this.props.getCartStatusIcon("pause", contact.status)}</React.Fragment>}
                                                            {contact.status.block_cart && <React.Fragment>&nbsp;{this.props.getCartStatusIcon("block", contact.status)}</React.Fragment>}
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                                <div className="pl-1">
                                                    {(typeof contact.msg_short === 'string')? this.sliceAccordingToBreakPoints(contact.msg_short) : contact.msg_short}
                                                </div>
                                            </div>

                                            <div className="d-flex flex-column justify-content-center flex-grow-1">
                                                <div className="text-white text-md pl-1 attendance_contact_date">
                                                    {contact.call_status === "calling" && (<i className="fa mt-2 fa-phone call_phone mr-3" style={{fontSize: 25}}></i>)} 
                                                    {contact.call_status === "in_call" && (<i className="fa mt-1 fa-volume-control-phone in_call_phone mr-2 text-black" style={{fontSize: 30}}></i>)}
                                                    {(contact.call_status !== "calling" && contact.call_status !== "in_call" && !contact.attendance_read) && <i className="fa fa-bell text-warning mt-2 pt-2 pr-1" style={{fontSize: 15}}></i>}
                                                    
                                                </div>
                                                <div className="pl-1 attendance_contact_date pb-2" >
                                                    <small style={{alignSelf: "flex-end"}}>{this.props.getDate(contact.date)}</small>
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </div>    
                                )
                            ) :(
                                <p className="py-2 lead no_message_status">{this.translate('ATTENDANCE_ROOM_THERE_ARE_NO_ENTRIES')}</p>
                            )}
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

}