import React, { Component } from 'react'

export default class MonthlyRankingList extends Component{

    render() {

        return (
            
            <div className="col-12">
                <div className="d-flex">
                    <p className="uppercase text-muted f10">{this.props.viewName}</p>
                </div>
                <div className="padding bg-blur_w position10 table-responsive custom_scroll" style={{maxHeight: 200}}>
                    {this.props.viewValues && this.props.viewValues.length > 0 &&
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>N°</th>
                                    {Object.keys(this.props.viewValues[0]).map((key, index)=>
                                        <th key={index + "ranking"}>{key}</th>
                                        
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.viewValues.map((value, index)=>
                                    <tr key={`ranking${index}`}>
                                        <td>{index + 1}</td>
                                        {Object.values(value).map((singleValue, indexSingleValue)=>
                                            <td key={`rankingitem${index}${indexSingleValue}`}>{singleValue}</td>
                                        )}
                                    </tr>
                                )}
                            </tbody>
                            
                        </table>
                    }
                </div>
            </div>
        )
    }
    
}


