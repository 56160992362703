import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import { logout } from "../Auth/AuthConfig";

export class Logout extends Component{
    render() {
        return (
            //{logout()}
            <div onLoad={logout()}></div>
        )
   }
};

export default withRouter(Logout);