import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'

import $ from "jquery"

import MenuMain from '../../Structure/MenuMain';
import HeaderMain from '../../Structure/HeaderMain';
import FooterMain from '../../Structure/FooterMain';

import AttendanceConfigOpeningHours from './Components/AttendanceConfigOpeningHours';
import AttendanceConfigAutomaticAnswer from './Components/AttendanceConfigAutomaticAnswer';
import AttendanceConfigOptions from './Components/AttendanceConfigOptions';


class AttendanceConfig extends Component{

    constructor(props){
        super(props)
        this.state = {

        }
        this.translate = this.props.translate
    }

    toggleAccordionPannel = (selector) => {
        $(selector).collapse("toggle")
    }
    
    render() {
        return (
            <React.Fragment>
                <MenuMain />
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    <HeaderMain title={this.translate("ATTENDANCE_CONFIG_TITLE")} onChange={this.handleChange}/>
                    <div className="content-main" id="content-main">
                        <div className="padding">
                            <div className="row pt20">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">

                                    <ul className="nav nav-pills nav-sm mb10" id="logs_tab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-target="#tab_attendance_config_opening_hours" data-toggle="tab" href="#tab_attendance_config_opening_hours" role="tab" aria-controls="opening_hours" aria-selected="true">{this.translate("ATTENDANCE_CONFIG_OPENING_HOURS")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-target="#tab_attendance_config_automatic_answer" data-toggle="tab" href="#tab_attendance_config_automatic_answer" role="tab" aria-controls="automatic_answer" aria-selected="true">{this.translate("ATTENDANCE_CONFIG_AUTOMATIC_ANSWER")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-target="#tab_attendance_config_config_options" data-toggle="tab" href="#tab_attendance_config_config_options" role="tab" aria-controls="config_options" aria-selected="true">{this.translate("ATTENDANCE_CONFIG_CONFIG_OPTIONS")}</a>
                                        </li>
                                    </ul>

                                    <div className="tab-content" id="logs_tab_content">
                                        <div className="tab-pane pb40 fade show active p-3 white box-shadow" id="tab_attendance_config_opening_hours" role="tabpanel" aria-labelledby="">
                                            <AttendanceConfigOpeningHours  toggleAccordionPannel={this.toggleAccordionPannel} translate={this.props.translate}/>
                                        </div>
                                        <div className="tab-pane pb40 fade show p-3 white box-shadow" id="tab_attendance_config_automatic_answer" role="tabpanel" aria-labelledby="">
                                            <AttendanceConfigAutomaticAnswer  toggleAccordionPannel={this.toggleAccordionPannel} translate={this.props.translate}/>
                                        </div>
                                        <div className="tab-pane pb40 fade show p-3 white box-shadow" id="tab_attendance_config_config_options" role="tabpanel" aria-labelledby="">
                                            <AttendanceConfigOptions  toggleAccordionPannel={this.toggleAccordionPannel} translate={this.props.translate}/>
                                        </div>
                                    </div>
        
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterMain />
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(AttendanceConfig);