export const statusClass = (status) => {
    const statusObj = {
        positive: 'text-success',
        negative: 'text-danger',
        default: 'text-primary'
    }
    return statusObj[status];
}
export const statusIcon = (status) => {
    const statusObj = {
        positive: 'fa-caret-up',
        negative: 'fa-caret-down',
        default: 'fa-circle'
    }
    return statusObj[status];
}