import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';

import $ from 'jquery';
import 'jquery-mask-plugin';

import api from '../../../../Services/Api';

import Modal from 'react-bootstrap/Modal';

class AgentsRegister extends Component {

    constructor(props){
        super(props);
        this.state = {
            modalResponseShow: false,
            msgResponseShow: false,
            formRegister: {
                name: null,
                email: null,
                ddi: null,
                phone: null,
                broker: this.props.idBroker
            }
        }
        this.handleRegisterAgent = this.handleRegisterAgent.bind(this);
        this.handleCloseModalResponse = this.handleCloseModalResponse.bind(this);
        this.reloadDatatable = this.reloadDatatable.bind(this)
        this.translate = this.props.translate

    }

    componentDidMount(){
        var SPMaskBehavior = function (val){
            var ddi = $('#register-ddi').val();
            if(ddi === "+55"){
                return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
            }else{
                return val.replace(/\D/g, '').length === 11 ? '(000) 000-0000' : '(000) 000-0000';
            }
        },
        spOptions = {
            onComplete: function(val){
                var phone = $('#register-phone').val();
                this.setState({formRegister: {...this.state.formRegister, phone: phone}})
            }.bind(this),
            onKeyPress: function(val, e, field, options) {
                field.mask(SPMaskBehavior.apply({}, arguments), options);
            }
        };

        $('#register-ddi').mask('+099');
        $('#register-phone').mask(SPMaskBehavior, spOptions);
    }

    reloadDatatable(){
        $('#list_agents').DataTable().ajax.reload();
    }

    handleRegisterAgent = async (e) => {
        e.preventDefault();
        this.setState({ msgResponseShow: this.translate("AGENTS_REGISTER_WAIT_STATUS"), modalResponseShow: true });
        const data = this.state.formRegister;
        try{
            const response = await api.post("/agent/register", {data});
            if(response.data.success === true){
                this.setState({msgResponseShow: this.translate("AGENTS_REGISTER_SUCCESS_STATUS")});
                document.getElementById('register-agent-new').reset();
                this.reloadDatatable()
            }else{
                this.setState({msgResponseShow: response.data.msg});
            }
        }catch(err){
            this.setState({msgResponseShow: err});
        }
    }

    handleCloseModalResponse(e = null){
        if (e) e.preventDefault()
        this.setState({modalResponseShow: false})
    }

    render() {
        return (
            <React.Fragment>
                <h5 className="fwhite">{this.translate("AGENTS_REGISTER_AGENT")}</h5>
                <form id="register-agent-new" onSubmit={this.handleRegisterAgent} className="width100">
                    <div className="row p-3 white box-shadow pb40">
                        <div className="col-sm-12 col-md-9 col-lg-9">
                            <div className="form-group">
                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                    {this.translate("AGENTS_REGISTER_LABEL_NAME_OF_AGENT")}:
                                </label>
                                <input type="text" onChange={e => this.setState({ formRegister: {...this.state.formRegister, name:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("AGENTS_REGISTER_PLACEHOLDER_NAME_OF_AGENT")} required/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3">
                            <div className="form-group">
                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                    {this.translate("AGENTS_REGISTER_LABEL_OFFICE_OF_AGENT")}:
                                </label>
                                <input type="text" onChange={e => this.setState({ formRegister: {...this.state.formRegister, office:  e.target.value}})} className="form-control box-shadow" placeholder={this.translate("AGENTS_REGISTER_PLACEHOLDER_OFFICE_OF_AGENT")} required/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-2">
                            <div className="form-group">
                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                    {this.translate("AGENTS_REGISTER_LABEL_DDI_OF_AGENT")}:
                                </label>
                                <input type="text" onChange={e => this.setState({formRegister: {...this.state.formRegister, ddi: e.target.value}})} id="register-ddi" className="form-control box-shadow" placeholder={this.translate("AGENTS_REGISTER_PLACEHOLDER_DDI_OF_AGENT")} required/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <div className="form-group">
                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                    {this.translate("AGENTS_REGISTER_LABEL_PHONE_OF_AGENT")}:
                                </label>
                                <input type="text" onChange={e => this.setState({formRegister: {...this.state.formRegister, phone: e.target.value}})} id="register-phone" className="form-control box-shadow" placeholder={this.translate("AGENTS_REGISTER_PLACEHOLDER_PHONE_OF_AGENT")} required/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label className="col-sm-12 col-md-12 col-lg-12 col-form-label text-left p0 mb5">
                                    {this.translate("AGENTS_REGISTER_LABEL_EMAIL_OF_AGENT")}:
                                </label>
                                <input type="email" onChange={e => this.setState({formRegister: {...this.state.formRegister, email: e.target.value}})} className="form-control box-shadow" placeholder={this.translate("AGENTS_REGISTER_PLACEHOLDER_EMAIL_OF_AGENT")} required/>
                            </div>
                        </div>
                        <div className="box-divider m0 mt20 width100"></div>
                        <div className="col-sm-12 col-md-12 mt20">
                            <button type="submit" className="btn btn-info px-5 btn-rounded btn-shadow pull-right">
                                <i className="fa fa-save"></i> {this.translate("AGENTS_REGISTER_REGISTER_AGENT")}
                            </button>
                        </div>
                    </div>
                </form>
                <Modal show={this.state.modalResponseShow} onHide={this.handleCloseModalResponse} dialogAs={'div'} dialogClassName="modal-dialog modal-bottom white b-t">
                    <Modal.Body>
                        <div className="d-flex flex-column h-100 bg-blur_w2">
                            <div className="p-3 b-b d-flex no-shrink blue text-center position10">
                                <h5 className="h5 m-0 text-center width100">
                                    <i className="fa fa-bell"></i> {this.translate("MODAL_STATUS_STATUS")}</h5>
                                <a href="/" onClick={e=>this.handleCloseModalResponse(e)} className="ml-auto">
                                    <span className="text-lg l-h-1x">×</span>
                                </a>
                            </div>
                            <div className="row position10 padding-20px">
                                <span className="col-12 text-center py-2 lead">
                                    {this.state.msgResponseShow}
                                </span>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(AgentsRegister);