import React, { Component, Fragment } from 'react'

import { withRouter } from 'react-router-dom'

import api from '../Services/Api'
import { setToken, setBusiness, COMPANY_ID } from '../Auth/AuthConfig'

export class ChooseBusiness extends Component {

    constructor(props) {
        super(props)
        this.state = {
            business: [],
            business_id: null,
            business_code: null,
            business_name: null,
            is_loading: true,
        }
        this.handleClick = this.handleClick.bind(this)
    }

    componentDidMount() {
        const company_id = localStorage.getItem(COMPANY_ID)
        if (!company_id) this.props.history.push('/')
        else this.loadBusiness(company_id)
    }

    loadBusiness = async (company_id) => {
        this.setState({ is_loading: true })
        let response = await api.get('/company/' + company_id + '/business')
        if (response && response.data && response.data.length) {
            const business = response.data
            if (business.length === 1) {
                let singleBusiness = business[0]
                this.redirectToDashboard({
                    business_id: singleBusiness.id,
                    business_name: singleBusiness.name,
                    business_code: singleBusiness.code
                })
            } else {
                this.setState({ business, is_loading: false })
            }
        }

    }

    handleClick = e => {
        e.preventDefault()
        this.redirectToDashboard({ business_id: e.currentTarget.dataset.business_id, business_name: e.currentTarget.dataset.business_name, business_code: e.currentTarget.dataset.business_code })

    }

    redirectToDashboard = async (business) => {
        const { business_id, business_name, business_code } = business
        console.log(business_id, business_name, business_code)
        const response = await api.post("/business/setid", { business_id })
        if (response.data.success) {
            setToken(response.data.token)
            setBusiness(business_id, business_name, business_code)
            this.props.history.push('/dashboard')
            //this.props.history.push(this.props.match.url)
        }
    }

    render() {
        return (
            <div id="content" className="app-content box-shadow-0 dark" role="main">
                {(this.state.business && this.state.business.length > 0 && !this.state.is_loading) ?
                    <React.Fragment>
                        <div className="menu-admin-init col-md-12 col-sm-12 col-lg-12 box-shadow dk rounded-top">
                            <ul>
                                <li>
                                    <a href="/admin_company">Admin Incorporadoras</a>
                                </li>
                            </ul>
                        </div>

                        <div className="content-main row row-no-margin" id="content-main">
                            <div className="row no-gutters col-sm-12 col-md-12 col-lg-12" style={{ marginBottom: '70px' }}>
                                <div className="col-md-12 col-sm-12 col-lg-12 box-shadow dk rounded-top mb20">
                                    <div className="padding">
                                        <h1 className="text-center fwhite">Escolha o Empreendimento</h1>
                                        <h5 className="text-muted text-center f300">Cada empreendimento possui um dashboard específico, com suas respectivas informações.</h5>
                                    </div>
                                </div>

                                <React.Fragment>
                                    {
                                        this.state.business.map(
                                            (business) =>
                                                <div key={business.id} className="escolhaEmpreendimento col-sm-12 col-md-4 col-lg-4">
                                                    <div className="padding no-box-shadow">
                                                        <div className="white box-shadow">
                                                            <div className="item">
                                                                <div className="item-bg height250 rounded-top">
                                                                    <a href="/">
                                                                        <img src={business.profile_picture} alt="." />
                                                                    </a>
                                                                </div>
                                                                <div className="height250 pos-rlt text-center"></div>
                                                            </div>
                                                            <div className="p-3 text-center">
                                                                <a href="/" data-business_id={business.id} data-business_name={business.name} data-business_code={business.code} onClick={this.handleClick} className="text-md mt-1 d-block f700">{business.name}</a>
                                                            </div>
                                                            <div className="row no-gutters b-t">
                                                                <div className="col-4 b-r">
                                                                    <a href="/" className="p-3 d-block text-center">
                                                                        <i className="fa text-md fa-users text-primary d-inline"></i>
                                                                        <h4 className="f700 m0">{business.countLeads}</h4>
                                                                        <span className="d-block text-muted">Leads</span>
                                                                    </a>
                                                                </div>
                                                                <div className="col-4 b-r">
                                                                    <a href="/" className="p-3 d-block text-center">
                                                                        <i className="fa text-md fa-eye text-danger d-inline"></i>
                                                                        <h4 className="f700 m0">{business.countSuspects}</h4>
                                                                        <span className="d-block text-muted">Suspects</span>
                                                                    </a>
                                                                </div>
                                                                <div className="col-4">
                                                                    <a href="/" className="p-3 d-block text-center">
                                                                        <i className="fa text-md fa-dollar text-success d-inline"></i>
                                                                        <h4 className="f700 m0">{business.countSales}</h4>
                                                                        <span className="d-block text-muted">Vendas</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        )
                                    }
                                </React.Fragment>

                            </div>
                        </div>
                    </React.Fragment>

                    :
                    this.state.is_loading ?
                        <p className="p-2 lead">
                            <i className="fa fa-spinner fa-spin"></i>&nbsp;
                            Carregando...
                        </p>
                        :
                        <p className="p-2 lead">
                            <i className="fa fa-spinner fa-spin"></i>&nbsp;
                            Não há Incorporadoras disponíveis
                        </p>
                }
            </div>
        )
    }
}

export default withRouter(ChooseBusiness)