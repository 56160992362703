import React, { Component } from 'react';

import $ from 'jquery';

import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4'

import api from '../../../../Services/Api';
import {ajaxUrl, ajaxToken} from '../../../../Services/Ajax';

class Portals extends Component {

    constructor(props){
        super(props)
        this.state = {
            tablePortals: null,
            formSavePortals: {
                subject: null,
                portal: null
            }
        }

        this.formSubmitSavePortals = this.formSubmitSavePortals.bind(this)
        this.translate = this.props.translate
    }

    reloadDatatablePortals(){
        $('#portals_integration').DataTable().ajax.reload();
    }

    async deleteCRM(id){
        const data = {id: id}
        try{
            const response = await api.post("/portals/update", data);
            if(response.data.success === true){
                this.reloadDatatablePortals()
            }else{
                console.log('error');
            }
        }catch(err){
            console.log(err);
        }
    }

    async formSubmitSavePortals(e){
        e.preventDefault();
        $('#add_portal').prop('disabled', true).html(`<i className="fa fa-spinner"></i> ${this.translate('ICON_WAIT')}`)
        const data = this.state.formSavePortals;
        try{
            const response = await api.post("/portals/create/" + data['portal'], data);
            if(response.data.success === true){
                this.reloadDatatablePortals()
                $('#add_portal').prop('disabled', false).html(`<i className="fa fa-save"></i> ${this.translate('ICON_ADD')}`)
            }else{
                this.setState({msgResponseShow: response.data.msg});
            }
        }catch(err){
            this.setState({msgResponseShow: err});
        }
    }

    componentDidMount(){
        let tablePortals = this.state.tablePortals
        tablePortals = $('#portals_integration').DataTable({
            "searching": true,
            "paging": false,
            "info": false,
            "responsive": true,
            "language": {
                "url": this.translate('DATATABLE_LANGUAGE_URL')
            },
            "ajax": {
                "url": ajaxUrl('/portals/table'),
                "type": 'GET',
                "beforeSend": function (request) {
                    request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                }
            },
            "columnDefs": [{
                "targets": -1,
                "data": null,
                "defaultContent": 
                    `<div class="btn-group btn-shadow">
                        <button class="delete btn btn-sm danger btn-cursor-pointer" title="${this.translate('PORTALS_REMOVE_INTEGRATION')}">
                            <i class="fa fa-trash" title="${this.translate('PORTALS_REMOVE_INTEGRATION')}"></i>
                        </button>
                    </div>`
            }]
        });
        
        $('#portals_integration tbody').on( 'click', '.delete', function (e){
            
            e.preventDefault();

            let data = [];
            
            $(e.target).prop('disabled', true).html('<i className="fa fa-spinner"></i>')

            if($(e.target).parents('tr').hasClass('child')){
                data = tablePortals.row($(e.target).parents('tr').prev('.parent')).data();
            }else{
                data = tablePortals.row($(e.target).parents('tr')).data();
            }
            
            this.deleteCRM(data[1]);
            
        }.bind(this));
        
    }

    render() {
        return (
            <React.Fragment>
                <div className="d-flex flex-column h-100">
                    <div className="p-3 b-b d-flex no-shrink red text-center position10">
                        <h5 className="h5 m-0 text-center width100 line-height-38px">
                            <i className="fa fa-plus-square-o"></i> {this.translate('PORTALS_REGISTER_PORTALS')}
                        </h5>
                        <button onClick={this.props.handleClosePortais} className="btn btn-transparent ml-auto">
                            <span className="text-lg l-h-1x">×</span>
                        </button>
                    </div>
                    
                    <div className="row">
                        <div id="accordionPortals" style={{width: '100%'}}>
                            <div className="panel box no-border mb-2" style={{width: '100%'}}>

                                <div className="box-header b-b p-y-sm fwhite">
                                    <button style={{width: '100%'}} onClick={(e)=>this.props.toggleCollapse("#formportal")} data-parent="#accordionPortals" className="btn btn-transparent btn-cursor-pointer" aria-expanded="true">
                                        {this.translate('PORTALS_ADD_NEW_PORTAL')}
                                        <span className="float-right badge text-sm">
                                            <i className="fa fa-angle-down"></i>
                                        </span>
                                    </button>
                                </div>
                                <div id="formportal" className="in collapse">

                                    <div className="col-sm-12 col-md-12 col-lg-12">
                                        <form onSubmit={this.formSubmitSavePortals} id="form_add_portal">
                                            <br/>
                                            <div>
                                                <div className="col-sm-12 col-md-12 mb20">
                                                    <input type="text" onChange={e => this.setState({ formSavePortals: {...this.state.formSavePortals, subject:  e.target.value}})} name="name_business" id="name_business" placeholder={this.translate('PORTALS_NAME_USED_IN_THE_PORTAL_SUBJECT')} className="form-control" required="required" />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="col-sm-12 col-md-12 mb20">
                                                    <select onChange={e => this.setState({ formSavePortals: {...this.state.formSavePortals, portal:  e.target.value}})} name="portal" id="idportal" className="form-control box-shadow cool-select" required="required">
                                                        <option value="">{this.translate('PORTALS_SELECT_PORTAL')}</option>
                                                        <option value="apto_vc">Apto.vc</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="col-sm-12 col-md-12 mb20">
                                                    <button id="add_portal" type="submit" className="pull-right btn btn-success px-5 btn-rounded btn-shadow">
                                                        <i className="fa fa-plus"></i> {this.translate('ICON_ADD')}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="col-12 mt-3 p-3 text-left dark">
                        <table id="portals_integration" className="table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                            <thead className="dark">
                                <tr>
                                    <th>{this.translate('PORTALS_PORTAL')}</th>
                                    <th>{this.translate('TABLE_ACTIONS_ROW')}</th>
                                </tr>
                            </thead>
                        </table>
                    </div>

                </div>
            </React.Fragment>
        );
    }
}

export default Portals;