/* global google */

import React, { Component } from 'react'

import Script from 'react-load-script'

class GoogleDirections extends Component {
    constructor(props){
        super(props)
        this.state = {
            start_address: [],
            success: false,
            end_address: [],
            distance: [],
            duration: [],
            routeColors: []
        }
        this.coordinates = []
        this.translate = this.props.translate
    }
    initMap = async () => {
        let directionsService = new google.maps.DirectionsService()
        
        let origins = (Array.isArray(this.props.origin)) ? this.props.origin : [this.props.origin]
        let destinations = (Array.isArray(this.props.destination)) ? this.props.destination : [this.props.destination]
        let map = new google.maps.Map(document.getElementById('mapDirections'))
        
    
        for(let i = 0; i < origins.length; i++){
            let directionsRenderer = new google.maps.DirectionsRenderer({ preserveViewport: true, polylineOptions: { strokeColor: this.getColors() }})
            directionsRenderer.setMap(map)
            this.renderMap(i, directionsRenderer, origins, destinations, map, directionsService)
        }
        
    }
    
    renderMap = (i, directionsRenderer, origins, destinations, map, directionsService) => {
        directionsService.route({
            origin: origins[i],
            destination: destinations[i],
            travelMode: 'DRIVING'
        }, (response, status) => {
            if (status === 'OK') {
                this.setNewStateInfo(response)
                directionsRenderer.setDirections(response)
                if(i === 0) {
                    let lat = response.routes[0].legs[0].end_location.lat() 
                    let lng = response.routes[0].legs[0].end_location.lng() 
                    map.setCenter(new google.maps.LatLng(lat, lng))
                    map.setZoom(13)
                    map.panTo(new google.maps.LatLng(lat, lng)) 
                }
            } else {
                this.setState({success: false})
            }
        })
    }

    setNewStateInfo = (response) => {
        this.setState({
            success: true,
            start_address: [...this.state.start_address, response.routes[0].legs[0].start_address],
            end_address: [...this.state.end_address, response.routes[0].legs[0].end_address],
            distance: [...this.state.distance, response.routes[0].legs[0].distance.text],
            duration: [...this.state.duration, response.routes[0].legs[0].duration.text],
        })
    }
    getColors = () => {
        let color = '#'+(0x1000000+(Math.random())*0xffffff).toString(16).substr(1,6)
        this.setState({routeColors: [...this.state.routeColors, color]})
        return color
    }
    checkThereAreMoreThanOneDestinations = (destinations) =>{
        destinations = [...new Set(destinations)]
        return destinations.length > 1 ? true : false
    }
    componentDidUpdate(prevProps){
        if(prevProps.destination !== this.props.destination){
            this.initMap()
        }
    }
    componentDidMount(){
        if(this.props.hasAnotherGoogleImported !== false) this.initMap()
    }
    render() {
        
        return (
            <React.Fragment>
                <p>
                    <b>{this.translate("GOOGLE_DIRECTIONS_PHYSICAL_INFO")}:</b>
                </p>
                <p className={`${this.state.success === true ? "d-none" : "d-block"}`}>
                    {this.translate("GOOGLE_DIRECTIONS_PHYSICAL_ADDRESS_NOT_FOUND_STATE")}
                </p>
                <div className={`col-sm-12 col-md-12 mt20 ${this.state.success === true ? "d-block" : "d-none"}`}>
                    {this.state.start_address.map((start_address, index)=>
                        <React.Fragment key={`info${index}`}>
                            <p>
                                <b style={{color: this.state.routeColors[index]}}>{this.translate("GOOGLE_DIRECTIONS_ADDRESS_OF")} {this.props.originName}:</b> {this.state.start_address[index]}
                                
                            </p>
                            {this.checkThereAreMoreThanOneDestinations(this.state.end_address) && (
                                <p>
                                    <b>{this.translate("GOOGLE_DIRECTIONS_ADDRESS_OF")} {this.props.destinationName}:</b> {this.state.end_address[index]}
                                </p>
                                
                            )}
                            <p>
                                <b>{this.translate("GOOGLE_DIRECTIONS_DISTANCE")}:</b> {this.state.distance[index]}
                            </p>
                            <p>
                                <b>{this.translate("GOOGLE_DIRECTIONS_DRIVING_TIME")}:</b> {this.state.duration[index]}
                            </p>
                            <hr/>
                            {!this.checkThereAreMoreThanOneDestinations(this.state.end_address) &&
                                (index + 1) === this.state.end_address.length && (   
                                    <React.Fragment>
                                        <p>
                                            <b>{this.translate("GOOGLE_DIRECTIONS_ADDRESS_OF")} {this.props.destinationName}:</b> {this.state.end_address[index]}
                                        </p>
                                        <hr />
                                    </React.Fragment>
                                )
                            }
                        </React.Fragment>
                    )}
                    
                    <div id="mapDirections" style={{display: 'block', width: '100%', height: '400px'}}></div>
                </div>
                {this.props.hasAnotherGoogleImported === false && (
                    <Script
                    url="https://maps.googleapis.com/maps/api/js?key=AIzaSyAuuqnU2YqXgdy6OY7ZuNdS77CR4B9iu18"
                    onLoad={this.initMap}
                    />
                )}
                
            </React.Fragment>
        )
    }
}

export default GoogleDirections