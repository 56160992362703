import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'

import MenuMain from '../../../Structure/MenuMain';
import HeaderMain from '../../../Structure/HeaderMain';
import FooterMain from '../../../Structure/FooterMain';

import AgentsRegister from './Components/AgentsRegister';
import AgentsList from './Components/AgentsList';

class Agents extends Component {

    constructor(props){
        super(props);
        this.translate = this.props.translate

        document.title = this.translate("AGENTS_AGENTS")
    }

    render() {
        return (
            <React.Fragment>
                <MenuMain />
                <div id="content" className="app-content box-shadow-0 dark" role="main">
                    <HeaderMain title={this.translate("AGENTS_AGENTS")} onChange={this.handleChange}/>
                    <div className="content-main admin" id="content-main">
                        <div className="padding">
                            <div className="row pt20">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">

                                    <ul className="nav nav-pills nav-sm mb10" id="admin_tab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-target="#tab_list_agent" data-toggle="tab" href="#tab_list_agent" role="tab" aria-controls="list" aria-selected="false">{this.translate("AGENTS_LIST_AGENTS")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-target="#tab_register_agent" data-toggle="tab" href="#tab_register_agent" role="tab" aria-controls="register" aria-selected="true">{this.translate("AGENTS_REGISTER_AGENT")}</a>
                                        </li>
                                    </ul>

                                    <div className="tab-content" id="admin_tab_content">
                                        <div className="tab-pane pb40 fade show active p-3 white box-shadow" id="tab_list_agent" role="tabpanel" aria-labelledby="">
                                            <AgentsList idBroker={document.location.hash.replace('#','')} translate={this.translate}/>
                                        </div>
                                        <div className="tab-pane pb40 fade p-3 white box-shadow" id="tab_register_agent" role="tabpanel" aria-labelledby="">
                                            <AgentsRegister idBroker={document.location.hash.replace('#','')} translate={this.translate}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterMain />
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(Agents);