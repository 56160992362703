import React, { Component } from 'react';

import $ from 'jquery';

import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4'

import api from '../../../../Services/Api';
import {ajaxUrl, ajaxToken} from '../../../../Services/Ajax';

class Pipedrive extends Component {

    constructor(props){
        super(props);
        this.state = {
            tablePipedrive: null,
            imobs: [],
            emppipedrive: [],
            stagepipedrive: [],
            formSavePipedrive: {
                token: null,
                emppipedrive: null,
                stagepipedrive: null,
                broker: null
            }
        }

        this.checkConfigPipedrive = this.checkConfigPipedrive.bind(this)
        this.changeEmpPipeDrive = this.changeEmpPipeDrive.bind(this)
        this.changeStagePipedrive = this.changeStagePipedrive.bind(this)

        this.formSubmitSavePipedrive = this.formSubmitSavePipedrive.bind(this)
        this.deleteCRM = this.deleteCRM.bind(this)
        this.translate = this.props.translate
    }

    async checkConfigPipedrive(){
        const token = this.state.formSavePipedrive.token
        try{
            const response = await api.get("https://api.pipedrive.com/v1/pipelines?api_token=" + token);
            if(response.data.success){
                this.setState({emppipedrive: response.data.data})
                $('.emppipedrive_div').css('display', 'block');
            }else{
                alert(this.translate('PIPEDRIVE_NOTIFY_THE_DEVELOPMENT_TEAM_AN_ERROR_OCURRED'));
            }
        }catch(err){
            console.log(err)
        }
    }

    async changeEmpPipeDrive(e){
        e.preventDefault()
        const token = this.state.formSavePipedrive.token
        const pipeline_id = e.target.value
        try{
            const response = await api.get("https://api.pipedrive.com/v1/stages?api_token=" + token + "&pipeline_id=" + pipeline_id);
            if(response.data.success){
                this.setState(
                    {
                        stagepipedrive: response.data.data,
                        formSavePipedrive: {
                            ...this.state.formSavePipedrive, emppipedrive: pipeline_id
                        }
                    }
                )
                $('.stagepipedrive_div').css('display', 'block');
            }else{
                alert(this.translate('PIPEDRIVE_LET_THE_DEVELOPMENT_KNOW_AN_ERROR_OCURRED'));
            }
        }catch(err){
            console.log(err)
        }
    }

    changeStagePipedrive(e){
        this.setState({formSavePipedrive: {...this.state.formSavePipedrive, stagepipedrive: e.target.value}})
        $('.imobpipedrive_div').css('display', 'block');
    }

    async loadImobs(){
        try{
            const response = await api.get("/brokers/selects");
            this.setState({imobs: response.data})
        }catch(err){
            console.log(err)
        }
    }

    async deleteCRM(id){
        const data = {id: id}
        try{
            const response = await api.post("/crms/update", data);
            if(response.data.success === true){
                this.reloadDatatableCRMs()
            }else{
                console.log('error');
            }
        }catch(err){
            console.log(err);
        }
    }

    reloadDatatableCRMs(){
        $('#brokers_pipedrive').DataTable().ajax.reload();
    }

    async formSubmitSavePipedrive(e){
        e.preventDefault();
        $('#add_pipedrive').prop('disabled', true).html(`<i className="fa fa-spinner"></i> ${this.translate('ICON_WAIT')}`)
        const data = this.state.formSavePipedrive;
        try{
            const response = await api.post("/crms/create/pipedrive", data);
            if(response.data.success === true){
                this.reloadDatatableCRMs()
                $('#add_pipedrive').prop('disabled', false).html(`<i className="fa fa-save"></i> ${this.translate('ICON_ADD')}`)
            }else{
                console.log('error');
            }
        }catch(err){
            console.log(err);
        }
    }

    componentDidMount(){

        this.loadImobs();

        let tablePipedrive = this.state.tablePipedrive
        tablePipedrive = $('#brokers_pipedrive').DataTable({
            "searching": true,
            "paging": false,
            "info": false,
            "responsive": true,
            "language": {
                "url": this.translate('DATATABLE_LANGUAGE_URL')
            },
            "ajax": {
                "url": ajaxUrl('/crms/table/all/pipedrive/active'),
                "type": 'GET',
                "beforeSend": function (request) {
                    request.setRequestHeader("Authorization", 'Bearer ' + ajaxToken());
                }
            },
            "columnDefs": [{
                "targets": -1,
                "data": null,
                "defaultContent": 
                    `<div class="btn-group btn-shadow">
                        <button class="delete btn btn-sm danger btn-cursor-pointer" title="${this.translate('PIPEDRIVE_REMOVE_INTEGRATION')}">
                            <i class="fa fa-trash" title="${this.translate('PIPEDRIVE_REMOVE_INTEGRATION')}"></i>
                        </button>
                    </div>`
            }]
        });

        $('#brokers_pipedrive tbody').on( 'click', '.delete', function (e){
            
            e.preventDefault();

            let data = [];
            
            $(e.target).prop('disabled', true).html('<i className="fa fa-spinner"></i>')

            if($(e.target).parents('tr').hasClass('child')){
                data = tablePipedrive.row($(e.target).parents('tr').prev('.parent')).data();
            }else{
                data = tablePipedrive.row($(e.target).parents('tr')).data();
            }
            
            this.deleteCRM(data[1]);
            
        }.bind(this));
    }
    
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div id="accordionPipedrive" style={{width: '100%'}}>
                        <div className="col-12 panel box no-border mb-2" style={{width: '100%'}}>

                            <div className="box-header b-b p-y-sm fwhite">
                                <button type="button" style={{width: '100%'}} className="btn btn-transparent" onClick={(e)=>this.props.toggleCollapse("#formpipedrive")} data-parent="#accordionPipedrive" aria-expanded="true">
                                    {this.translate('PIPEDRIVE_ADD_PIPEDRIVE_ACCOUNT')}
                                    <span className="float-right badge text-sm">
                                        <i className="fa fa-angle-down"></i>
                                    </span>
                                </button>
                            </div>

                            <div id="formpipedrive" className="in collapse">

                                <div className="col-sm-12 col-md-12 col-lg-12">

                                    <form onSubmit={this.formSubmitSavePipedrive} id="form_config_hypnobox">
                                        <br/>

                                        <div>
                                            <div className="col-sm-12 col-md-12 mb20">
                                                <input type="text" onChange={e => this.setState({ formSavePipedrive: {...this.state.formSavePipedrive, token:  e.target.value}})} name="token" id="tokenpipedrive" placeholder={this.translate('PIPEDRIVE_FILL_IN_WITH_THE_TOKEN_PROVIDED_BY_PIPEDRIVE')} className="form-control" required="required"/>
                                                <br/>
                                                <button onClick={this.checkConfigPipedrive} type="button" className="btn btn-success" id="tokenpipedrive_button" style={{width: '100%'}}>{this.translate('PIPEDRIVE_ACCESS_INFORMATIONS')}</button>
                                            </div>
                                        </div>
                                        <div className="emppipedrive_div" style={{display: 'none'}}>
                                            <div className="col-sm-12 col-md-12 mb20">
                                                <select onChange={this.changeEmpPipeDrive} name="emppipedrive" id="emppipedrive" className="form-control box-shadow cool-select" required="required">
                                                    <option value="">{this.translate('PIPEDRIVE_SELECT_PIPEDRIVE_FUNNEL')}</option>
                                                    {
                                                        this.state.emppipedrive.map((data, i) => (
                                                            <option key={i} value={data.id}>{data.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="stagepipedrive_div" style={{display: 'none'}}>
                                            <div className="col-sm-12 col-md-12 mb20">
                                                <select onChange={this.changeStagePipedrive} name="stagepipedrive" id="stagepipedrive" className="form-control box-shadow cool-select" required="required">
                                                    <option value="">{this.translate('PIPEDRIVE_SELECT_FUNNEL_STAGE')}</option>
                                                    {
                                                        this.state.stagepipedrive.map((data, i) => (
                                                            <option key={i} value={data.id}>{data.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                        <div className="imobpipedrive_div" style={{display: 'none'}}>
                                            <div className="col-sm-12 col-md-12 mb20">
                                                <select onChange={e => this.setState({ formSavePipedrive: {...this.state.formSavePipedrive, broker:  e.target.value}})} name="broker" id="imobhypnobox" className="form-control box-shadow cool-select" required="required">
                                                    <option value="">{this.translate('PIPEDRIVE_SELECT_BROKER')}</option>
                                                    {
                                                        this.state.imobs.map((data, i) => (
                                                            <option key={i} value={data.id}>{data.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="imobpipedrive_div" style={{display: 'none'}}>
                                            <div className="col-sm-12 col-md-12 mb20">
                                                <button id="add_pipedrive" type="submit" className="pull-right btn btn-success px-5 btn-rounded btn-shadow">
                                                    <i className="fa fa-plus"></i> {this.translate('ICON_ADD')}
                                                </button>
                                            </div>
                                        </div>
                                    </form>

                                </div>

                            </div>

                        </div>
                    </div>

                    <div className="col-12 p-1 text-left dark">

                        <table id="brokers_pipedrive" className="table table-striped table-bordered dt-responsive nowrap" style={{width:"100%"}}>
                            <thead className="dark">
                                <tr className="text-left">
                                    <th>{this.translate('PIPEDRIVE_BROKER')}</th>
                                    <th>{this.translate('TABLE_ACTIONS_ROW')}</th>
                                </tr>
                            </thead>
                        </table>

                    </div>

                </div>
            </React.Fragment>
        );
    }
}

export default Pipedrive;